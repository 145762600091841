const moduleMixin = {
    data: function() {
        return {
            open: true,
        };
    },

    methods: {
        toggle: function() {
            if (!this.open) {
                this.$refs.body.classList.remove('collapsed');
                this.open = true;
            } else {
                this.$refs.body.classList.add('collapsed');
                this.open = false;
            }
        },
    },
};

export default moduleMixin;
