<style>
    .pa-btn-group {
        display: flex;
        flex-direction: row;
    }

    .pa-btn-group > .pa-tooltip2 > .trigger > .pa-btn {
        border: 1px solid #dcdcdc;
        border-radius: 0;
        cursor: pointer;
        padding: 7px 15px;
        margin: 0;        
        font-size: 13px;
        line-height: 14.25px;
    }


    .pa-btn-group > .pa-tooltip2 > .trigger > .pa-btn + .pa-btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        /* Hide overlapping border */
        margin-left: -1px;
    }

    .pa-btn-group > .pa-tooltip2:first-child > .trigger > .pa-btn {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }

    .pa-btn-group > .pa-tooltip2:last-child > .trigger > .pa-btn {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    .pa-btn-group > .pa-tooltip2 > .trigger > .pa-btn:disabled {
        opacity: 0.4;
        pointer-events: none;
    }

    .pa-btn-group > .pa-tooltip2 > .trigger > .pa-btn:disabled:hover {
        background: initial;
    }
</style>

<template id="pa-btn-group">
    <div class="pa-btn-group">
        <slot></slot>
    </div>
</template>

<script>
    import Vue from 'vue';

    const ButtonGroup = Vue.extend({});

    export default ButtonGroup;
</script>
