<template>
    <span class="pa-txt_medium">
        {{ title }}
    </span>
</template>

<script>
    import Vue from 'vue';

    const DynamicTitle = Vue.extend({
        props: {
            title: {
                type: String,
                'default': ''
            },
            identifier: {
                type: String,
                'default': 'dynamicTitle'
            }
        },

        events: {
           "dynamicTitle:update": function(data) {
               if (data.identifier == this.identifier) {
                   this.title = data.title;
               }
           }
        }
    });

    export default DynamicTitle;
</script>
