<template>
    <p-widget-shell
        :id="id"
        :editing="editing"
        :config="config"
        :content="content"
        :loading="isLoading"
        :configuration-modules="getConfigModules()"
        :option-modules="getOptionModules()"
        :valid="valid"
        :error-message="error_message"
        :background-color="backgroundColor"
        :allowedExpand="true"
    >
        <div
            slot="body"
            class="widget-body"
            :style="inModalData ? {'height': '85vh'}  : {'flex': '1', 'margin-bottom': '10px','height':' 0'}"
        >
            <p-geomap
                v-if="hasInstances"
                ref="geomap"
                :markers="content.geomapData"
                :popup-options="{ minWidth: 225 }"
                :popup-props="{
                    metricTextkeys: metricTextkeysList,
                    metricTags: localConfig.custom_controls.metrics_tags,
                    metricTagsAll: localConfig.custom_controls.metrics_tag_match === 'all',
                }"
                show-flyout-graphs
                :from-widget="true"
                :severity-filter="content.severity_filter"
            >
            </p-geomap>
            <span :style="inModalData ? {'height':  '80vh'} : {'height':  '0'}" v-if="!hasInstances && !isLoading" class="block">
                No instances found.
            </span>
        </div>
        <div slot="unconfigured-message">
            Please configure your widget.
        </div>
    </p-widget-shell>
</template>

<script>
import Vue from 'vue';
import _ from "lodash";
import widgetMixin from '.././widgetMixin';
import WidgetShell from './WidgetShell.vue';

export default Vue.extend({
    mixins: [widgetMixin],

    components: {
        'p-widget-shell': WidgetShell,
    },

    data() {
        return {
            content: {
                success: true,
            },
            inModalData: false,
        };
    },

    props: {
        editing: {
            type: Boolean,
            'default': false,
        }
    },

    computed: {
        metricTextkeysList() {
            const agentTextkeys = Object.values(
                _.get(this.localConfig, "metric_textkeys.agent", {})
            );
            const networkTextkeys = Object.values(
                _.get(this.localConfig, "metric_textkeys.network", {})
            );
            return _.flattenDeep([agentTextkeys, networkTextkeys]);
        },
        hasInstances: function() {
            return (
                this.content &&
                this.content.geomapData &&
                this.content.geomapData.length
            );
        },
    },

    methods: {
        initialize(config, content) {
            this.content = {...content};
            this.localConfig = {...config};
            // Give DOM a chance to swap in/out configure messages
            this.reconfig();
            this.rendered = this.content.success;
        },

        reconfig() {
            window.app.rootVue.$broadcast('widget-content:ready', this.id);
            
            if (!this.content.success) {
                this.isLoading = false;
                return;
            }

            this.isLoading = false;
        },

        configure() {
            window.app.rootVue.$broadcast('dashboard:open_config', this);
        },

        update(content) {
            this.content = {...content};
            if (!this.content.success
                || this.content.geomapData === '[]'
                || !this.content.geomapData.length) {
                return;
            }
         },

        getConfigModules() {
            return [
                { type: 'p-overview-module' },
                { type: 'p-instance-module' },
                { type: 'p-metric-module', options: { multiple: true, allSelectable: true } },
                {
                    type: 'p-custom-module',
                    options: {
                        title: '',
                        controls: [
                            {
                                id: 'backgroundColor',
                                label: 'Background Color',
                                key: 'backgroundColor',
                                type: 'color-picker',
                                default: '#ffffff'
                            },
                            {
                                id: 'severity',
                                label: 'Severity Levels',
                                key: 'severity',
                                type: 'p-select',
                                multiple: false,
                                options: [
                                    {value: 'critical', label: 'Critical'},
                                    {value: 'warning', label: 'Warning'},
                                    {value: 'all', label: 'All'}
                                ],
                                default: 'all'
                            }
                        ]
                    }
                },
            ];
        },

        getOptionModules() {
            return this.optionModules;
        },

    },

    events: {
        'dashboardmodal:close'() {
            this.inModalData = false
        },
        'dashboardmodal:open'(idData) {
            if (this.id === idData) {
                this.inModalData = true
            }
        },
    },

    mounted() {
        this.pendNewConfig();
        if (this.id < 0) { this.configure(); };
    },
});
</script>
