<style>
@-webkit-keyframes kf_el_u37JDTCGAp_an_TBvQ61330 {
    0%,
    to {
        -webkit-transform: translate(108.75px, 25.5px)
            translate(-108.75px, -25.5px) translate(2px, 1px);
        transform: translate(108.75px, 25.5px) translate(-108.75px, -25.5px)
            translate(2px, 1px);
    }
}
@keyframes kf_el_u37JDTCGAp_an_TBvQ61330 {
    0%,
    to {
        -webkit-transform: translate(108.75px, 25.5px)
            translate(-108.75px, -25.5px) translate(2px, 1px);
        transform: translate(108.75px, 25.5px) translate(-108.75px, -25.5px)
            translate(2px, 1px);
    }
}
@-webkit-keyframes kf_el_WAHTpEUmz-_an_oN07hHzEx {
    0%,
    to {
        -webkit-transform: translate(114.25px, 35.50618934631348px)
            translate(-114.25px, -35.50618934631348px) translate(2px, 1px);
        transform: translate(114.25px, 35.50618934631348px)
            translate(-114.25px, -35.50618934631348px) translate(2px, 1px);
    }
}
@keyframes kf_el_WAHTpEUmz-_an_oN07hHzEx {
    0%,
    to {
        -webkit-transform: translate(114.25px, 35.50618934631348px)
            translate(-114.25px, -35.50618934631348px) translate(2px, 1px);
        transform: translate(114.25px, 35.50618934631348px)
            translate(-114.25px, -35.50618934631348px) translate(2px, 1px);
    }
}
@-webkit-keyframes kf_el_WAHTpEUmz-_an_LEV2mGI89C {
    0%,
    5% {
        stroke-dasharray: 2, 16;
    }
    25%,
    to {
        stroke-dasharray: 17.75;
    }
}
@keyframes kf_el_WAHTpEUmz-_an_LEV2mGI89C {
    0%,
    5% {
        stroke-dasharray: 2, 16;
    }
    25%,
    to {
        stroke-dasharray: 17.75;
    }
}
@-webkit-keyframes kf_el_WAHTpEUmz-_an_n-e2heSkD {
    0%,
    5% {
        opacity: 0;
    }
    25%,
    to {
        opacity: 1;
    }
}
@keyframes kf_el_WAHTpEUmz-_an_n-e2heSkD {
    0%,
    5% {
        opacity: 0;
    }
    25%,
    to {
        opacity: 1;
    }
}
@-webkit-keyframes kf_el_u37JDTCGAp_an_Y9zFdC0xW {
    0% {
        opacity: 0;
    }
    20%,
    to {
        opacity: 1;
    }
}
@keyframes kf_el_u37JDTCGAp_an_Y9zFdC0xW {
    0% {
        opacity: 0;
    }
    20%,
    to {
        opacity: 1;
    }
}
#el_y6Pr_TXs7 * {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
}
</style>

<template>
    <svg
        :width="width"
        :height="height"
        viewBox="108.5 24.5 25 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        id="el_y6Pr_TXs7"
    >
        <g fill-rule="evenodd" fill="none">
            <g
                style="-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards;-webkit-animation-name:kf_el_u37JDTCGAp_an_TBvQ61330;animation-name:kf_el_u37JDTCGAp_an_TBvQ61330;-webkit-animation-timing-function:cubic-bezier(0,0,1,1);animation-timing-function:cubic-bezier(0,0,1,1)"
            >
                <circle
                    cx="119.25"
                    cy="36"
                    r="10.5"
                    style="-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards;-webkit-animation-name:kf_el_u37JDTCGAp_an_Y9zFdC0xW;animation-name:kf_el_u37JDTCGAp_an_Y9zFdC0xW;-webkit-animation-timing-function:cubic-bezier(.42,0,.58,1);animation-timing-function:cubic-bezier(.42,0,.58,1)"
                    :stroke="color"
                    stroke-width="3"
                    data-animator-group="true"
                    data-animator-type="0"
                />
            </g>
            <g
                style="-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards;-webkit-animation-name:kf_el_WAHTpEUmz-_an_oN07hHzEx;animation-name:kf_el_WAHTpEUmz-_an_oN07hHzEx;-webkit-animation-timing-function:cubic-bezier(0,0,1,1);animation-timing-function:cubic-bezier(0,0,1,1)"
            >
                <path
                    style="-webkit-animation-fill-mode:forwards,forwards;animation-fill-mode:forwards,forwards;-webkit-animation-name:kf_el_WAHTpEUmz-_an_n-e2heSkD,kf_el_WAHTpEUmz-_an_LEV2mGI89C;animation-name:kf_el_WAHTpEUmz-_an_n-e2heSkD,kf_el_WAHTpEUmz-_an_LEV2mGI89C;-webkit-animation-timing-function:cubic-bezier(.42,0,1,1),cubic-bezier(0,0,1,1);animation-timing-function:cubic-bezier(.42,0,1,1),cubic-bezier(0,0,1,1)"
                    :stroke="color"
                    stroke-width="2.5"
                    d="M114.25 36.03l4.482 4.482 5.482-10.012"
                    data-animator-group="true"
                    data-animator-type="0"
                />
            </g>
        </g>
    </svg>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    props: {
        height: {
            type: String,
            default: "25"
        },
        width: {
            type: String,
            default: "25"
        },
        color: {
            type: String,
            default: "#3954bf",
        },
    },
});
</script>
