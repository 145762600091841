<style>
    /* TODO */
    .editmetric-drawer-footer {
        position: absolute;
        bottom: 0px;
        left: 0px;
        height: 80px;
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.12);
        text-align: center;
        background: #fff;
    }
    .pa-drawer-with-sticky-footer {
        height: 100%;
        max-height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        right: 0;
    }
    
    .pa-drawer-bd {
        overflow-y: auto;
    }

</style>

<template>
    <div>
        <div @click="toggle" :class="tabClasses" style="cursor: pointer;">
            <slot name="trigger">
                <div class="pa-drawer-tab">
                    <a style="" class="pa-btn">{{title}}</a>
                </div>
            </slot>
        </div>
        <div ref="drawer" class="pa-drawer" :id="id" :class="drawerClasses" :style="drawerStyles">
            <div v-if="loadingSpinner && loading" class="drawer-spinner">
                <div class="pa-loader"></div>
            </div>
            <template v-if="loadedAsync" ref="async"-body>
                <div class="async-contents" :style="{ width: ['left', 'right'].includes(side) ? this.iWidth + 'px' : 'unset' }" ref="asyncBody"></div>
                <button @click="close" type="button" class="pa-drawer-close" :style="indexStyles">
                    <p-icon icon="x_naked" size="xxxxl" color="grey-400">
                    </p-icon>
                </button>
            </template>
            <template v-if="!loadedAsync && stickyFooter">
                <div class="pa-drawer-with-sticky-footer">
                    <div class="pa-drawer-hd">
                        <slot name="head"></slot>
                        <button @click="close" type="button" class="pa-drawer-close" :style="indexStyles">
                            <p-icon icon="x_naked" size="xxxxl" color="grey-400">
                            </p-icon>
                        </button>
                    </div>
                    <div class="pa-drawer-bd" :style="`height: calc(100% - ${bodyOffset}px);`">
                        <slot name="body"></slot>
                    </div>
                    <slot name="footer"></slot>
                </div>
            </template>
            <template v-if="!loadedAsync && !stickyFooter">
                <div class="pa-drawer-hd">
                    <h3 v-if="asyncTitle" class="pa-hdg pa-hdg_3">
                        <a v-if="asyncTitleLink" target="_blank" :href="asyncTitleLink">{{ asyncTitle }}</a>
                        <span v-else>{{ asyncTitle }}</span>
                    </h3>
                    <slot name="head"></slot>
                    <button
                        @click="close"
                        type="button"
                        class="pa-drawer-close"
                        :style="indexStyles"
                    >
                        <p-icon icon="x_naked" size="xxxxl" color="grey-400">
                        </p-icon>
                    </button>
                </div>
                <div class="pa-drawer-bd">
                    <div v-if="asyncTitle && !asyncFailed" style="width: 100%; margin-top: 50px;">
                        <p-loading-spinner-2 style="display: block; margin: auto;"></p-loading-spinner-2>
                    </div>
                    <p-alert v-if="asyncTitle && asyncFailed" variant="red" :full-width="true">
                        <p class="pa-txt_13">
                            There was an error loading the data. Please try again or contact support
                        </p>
                    </p-alert>
                    <slot name="body"></slot>
                </div>
                <slot name="footer"></slot>
            </template>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import createScrollLocker from '../utils/scrollLocker';

    // if a response contains this key, then the modal
    // should close after it submits a form within it
    const CUSTOM_MODAL_HEADER = {
        CLOSE: 'X-CP-ModalAction-Close',
        LOAD: 'X-CP-ModalAction-Load',
        RELOAD: 'X-CP-ModalAction-Reload',
    };
    const MOBILE_WIDTH = 700;
    let COUNT = 0;

    const Drawer = Vue.extend({
        components: {
        },

        events: {
            // Commands to open the drawer
            'drawer:open': function(data) {
                let id = data.id || data;
                if (this.id !== id || this.isOpen) {
                    return;
                }
                this.open(data);
            },
            'drawer:close': function(id) {
                if (this.id !== id || !this.isOpen || Boolean(window.active_vue_modal)) {
                    return;
                }
                this.close();
            },
            'drawer:load': function(details) {
                try {
                    event.preventDefault();
                } catch(err) {
                    let event;
                }
                if (this.id !== details.id) {
                    return;
                }
                if (typeof details.width === 'number') {
                    this.oldWidth = this.iWidth;
                    this.iWidth = details.width;
                }
                if (details.child) {
                    // This pane opened from another pane
                    this.childPane = true;
                }
                if (details.title) {
                    this.asyncTitle = details.title;
                    this.asyncTitleLink = details.titleLink;
                }
                this.load(details.url, details.anonymous);
            },
            'drawer:close': function(id) {
                if (this.id !== id || !this.isOpen || Boolean(window.active_vue_modal)) {
                    return;
                }
                this.close();
            },
            // Informational drawer broadcasts
            'drawer-open': function(id) {
                if (this.id !== id && this.isOpen) {
                    this.drawersAbove += 1;
                }
            },
            'drawer-close': function(id) {
                if (this.id !== id && this.isOpen) {
                    this.drawersAbove -= 1;
                }
            },
            'drawer:toggle': function(data) {
                let id = data.id || data;
                if (this.id !== id) {
                    return;
                }
                this.isOpen ? this.close() : this.open(data);
            },
        },

        data() {
            return {
                isOpen: false,
                drawersAbove: 0,
                loadedAsync: false,
                loading: false,
                childVue: null,
                isMobile: false,
                oldWidth: null,
                oldScrollLock: null,
                childPane: false,
                iWidth: this.width,
                asyncTitle: '',
                asyncTitleLink: '',
                asyncFailed: false,
                modalAbove: false,
            };
        },

        watch: {
            width: function(newVal) {
                this.iWidth = newVal;
            }
        },

        props: {
            id: {
                type: String,
                default: function() {
                    return 'drawer_' + COUNT++;
                }
            },
            side: {
                type: String,
                default: 'right',
            },
            title: {
                type: String,
                default: 'OPEN',
            },
            width: {
                type: Number,
                default: 400,
            },
            focusSelector: {
                type: String,
                default: null,
            },
            resizable: {
                type: Boolean,
                default: false,
            },
            additionalClasses: {
                type: Array,
                default: () => [],
            },
            loadingSpinner: {
                type: Boolean,
                'default': false,
            },
            scrollLock: {
                type: Boolean,
                'default': false
            },
            mobileMode: {
                type: Boolean,
                'default': true,
            },
            zIndex: {
                type: Number,
                'default': 0,
            },
            bodyOffset: {
                type: Number,
                'default': 65,
            },
            stickyFooter: Boolean,
        },

        computed: {
            drawerClasses() {
                const retVal = {
                    open: this.isOpen,
                    'no-scroll': this.noScroll,
                    left: this.side === 'left',
                    right: this.side === 'right',
                    bottom: this.side === 'bottom',
                    mobile: this.isMobile,
                };
                for (const c of this.additionalClasses) {
                    retVal[c] = true;
                }
                return retVal;
            },
            drawerStyles() {
                const styles = {};
                if (['left', 'right'].includes(this.side)) {
                    styles.width = `${this.iWidth}px`;

                    if (this.isOpen) {
                        if (this.side === 'right') {
                            styles.left = `calc(100% - ${this.iWidth}px)`;
                        } else {
                            styles.right = `calc(100% - ${this.iWidth}px)`;
                        }
                    }
                } else if (this.side === 'bottom') {
                    styles.height = `${this.iWidth}px`;

                    if (this.isOpen) {
                        styles.top = `calc(100% - ${this.iWidth}px)`;
                    }
                }

                return styles;
            },
            tabClasses() {
                return {
                    'open': this.isOpen,
                    'left-trigger': this.side === 'left',
                    'right-trigger': this.side === 'right',
                    'bottom-trigger': this.side === 'bottom',
                };
            },
            indexStyles() {
                return {
                    'z-index': this.zIndex,
                }
            }
        },

        methods: {
            checkForModalAbove(event) {
                this.modalAbove = (
                    event.key === 'Escape' &&
                    this.isOpen &&
                    window.active_vue_modal
                );
            },
            handleKeyDown(event) {
                this.checkForModalAbove(event);
            },
            handleKeyUp(event) {
                if (
                    event.key === 'Escape' &&
                    this.isOpen &&
                    this.drawersAbove === 0 &&
                    !this.modalAbove
                ) {
                    event.stopImmediatePropagation();
                    this.close();
                }

                this.checkForModalAbove(event);
            },
            toggle() {
                if (!this.isOpen) {
                    this.open();
                } else {
                    this.close();
                }
            },
            open(data) {
                this.isOpen = true;
                if (this.focusSelector) {
                    $(this.focusSelector).focus();
                }
                if (this.resizable) {
                    setTimeout(() => {
                        this.$refs.drawer.classList.add('no-transition');
                    }, 550);
                }
                if (this.scrollLock === 'force') {
                    this.scrollLocker.settings.force = true;
                    this.scrollLocker.on();
                } else if (this.scrollLock) {
                    this.scrollLocker.settings.force = false;
                    this.scrollLocker.on();
                }
                if (this.isMobile) {
                    this.iWidth = window.innerWidth;
                }
                this.drawersAbove = 0;
                this.eventHub.$emit('drawer-open', this.id, data);
            },
            close() {
                if (this.$els.drawer.classList.contains('no-transition')) {
                    this.$els.drawer.classList.remove('no-transition');
                }
                this.isOpen = false;
                if (this.scrollLock && !this.childPane) {
                    this.scrollLocker.off();
                }
                this.drawersAbove = 0;
                Vue.nextTick(() => {
                    this.asyncTitle = '';
                    this.asyncTitleLink = '';
                    if (this.$refs.asyncBody) {
                        this.$refs.asyncBody.innerHTML = '';
                    }
                    if (this.childVue) {
                        this.childVue.$destroy(false);
                        this.childVue = null;
                    }

                    if (this.oldWidth) {
                        this.iWidth = this.oldWidth;
                        this.oldWidth = null;
                    }
                    if (typeof this.oldScrollLock !== undefined && this.oldScrollLock !== null) {
                        this.scrollLock = this.oldScrollLock;
                        this.oldScrollLock = null;
                    }
                    this.eventHub.$emit('drawer-close', this.id);
                });
            },
            _load(url, data, anonymous, callback) {
                this.loading = true;
                const options = {
                    url: url,
                    method: 'get',
                };
                if (data) {
                    options.data = data;
                }
                return $.ajax(options).then((html, textStatus, jqXHR) => {
                    this.asyncFailed = false;
                    const shouldContinue = this._processReturnHeaders(html, textStatus, jqXHR);
                    if (shouldContinue) {
                        this._insertBody(html, anonymous);
                        this.loading = false;
                        if (typeof callback === 'function') {
                            callback();
                        }
                    }
                })
                .fail(() => {
                    this.asyncFailed = true;
                });
            },
            load(url, anonymous, callback) {
                if (this.asyncTitle) {
                    this.open();
                }
                this._load(url, null, anonymous, callback);
            },
            loadWithData(url, data, anonymous, callback) {
                this._load(url, data, anonymous, callback);
            },

            _processReturnHeaders(html, textStatus, jqXHR) {
                const shouldReloadPage = jqXHR.getResponseHeader(CUSTOM_MODAL_HEADER.RELOAD);
                let pathname = jqXHR.getResponseHeader(CUSTOM_MODAL_HEADER.LOAD);
                const shouldDrawerClose = jqXHR.getResponseHeader(CUSTOM_MODAL_HEADER.CLOSE);

                if ((typeof html === 'string' ||
                    html instanceof String) &&
                    html.includes('</p-auth>'))
                {
                    window.location.reload();
                }

                if (shouldReloadPage) {
                    console.debug('Reloading page!');
                    window.location.reload();
                    return false;
                }

                if (pathname) {
                    pathname = decodeURIComponent(pathname);
                    console.debug('Setting location to ', pathname);
                    window.location = pathname;
                    return false;
                }

                if (shouldDrawerClose) {
                    console.debug('Closing modal');
                    this.close();
                    return false;
                }

                return true;
            },
            _insertBody(html, anonymous) {
                if (this.childVue) {
                    this.childVue.$destroy(false);
                }

                this.loadedAsync = true;
                Vue.nextTick(() => {
                    this.$refs.asyncBody.innerHTML = html;

                    // Load setting from async dom
                    const paDrawerAsync = this.$refs.asyncBody.querySelector('.pa-drawer_async');
                    if (paDrawerAsync) {
                        const asyncWidth = paDrawerAsync.getAttribute('width');
                        if (asyncWidth) {
                            this.oldWidth = this.iWidth;
                            this.iWidth = Number(asyncWidth);
                        }
                        const scrollLock = paDrawerAsync.getAttribute('scroll-lock');
                        if (scrollLock !== null) {
                            this.oldScrollLock = this.scrollLock;
                            this.scrollLock = true;
                            if (scrollLock === 'force') {
                                this.scrollLock = 'force';
                            } else {
                                this.scrollLock = 'lock';
                            }
                        }
                    }

                    const scripts = this.$refs.asyncBody.getElementsByTagName('SCRIPT');
                    if (scripts.length) {
                        // Force non-anonymous if scripts included
                        // TODO: Make this more explicit?
                        anonymous = false; // eslint-disable-line
                    }

                    if (anonymous) {
                        this.childVue = new Vue({
                            el: this.$refs.asyncBody,
                            parent: this,
                        });
                    } else {
                        Vue.nextTick(() => {
                            for (const origScriptTag of scripts) {
                                origScriptTag.parentNode.removeChild(origScriptTag);
                                const newScriptEl = document.createElement('script');
                                const script = document.createTextNode(origScriptTag.textContent);
                                newScriptEl.appendChild(script);
                                document.head.appendChild(newScriptEl);
                            }
                        });
                    }

                    this.open();
                });
            },
            _onResize(event, ui) {
                if (['left', 'right'].includes(this.side)) {
                    this.iWidth = ui.size.width;
                } else {
                    this.iWidth = ui.size.height;
                }
            },

            scrollToTop() {
                const body = this.$el.querySelector('.pa-drawer-bd');
                if (!body) { return }
                body.scrollTo(0, 0);
            },
        },

        beforDestroy() {
            window.removeEventListener('keydown', this.handleKeyDown, false);
            window.removeEventListener('keyup', this.handleKeyUp, false);
        },

        mounted() {
            if (this.resizable) {
                let handles = '';
                switch (this.side) {
                    case 'left':
                        handles = 'e';
                        break;
                    case 'right':
                        handles = 'w';
                        break;
                    case 'bottom':
                        handles = 'n';
                        break;
                }

                $(this.$refs.drawer).resizable({
                    handles: handles,
                    minWidth: 250,
                    resize: this._onResize,
                });
            }

            this.scrollLocker = window.app.scrollLocker;

            if (!this.scrollLocker) {
                this.scrollLocker = createScrollLocker({ hideScrollbar: true });
                $(this.$refs.drawer).resizable({handles: "e", minWidth: 250});
            }

            // Mobile mode
            if (this.mobileMode && window.innerWidth < MOBILE_WIDTH) {
                this.isMobile = true;
            }

            window.addEventListener('keydown', this.handleKeyDown, false);
            window.addEventListener('keyup', this.handleKeyUp, false);
        }
    });
    export default Drawer;
</script>
