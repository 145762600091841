<template>
    <th v-if="isHeader">
        {{ title }}
    </th>
    <td v-else>
        <div v-if="outage || maintenance">
            <a v-if="outage" v-p-flyout-open :href="outage.flyout.trigger_url" :template="outage.flyout.url" :width="outage.flyout.width">
                <span :class="outage_class">{{ outage.text }}</span>
            </a>

            <a v-if="maintenance" href="#" @click="showMaintenance">
                <div v-p-flyout-open :template="maintenance.flyout.url" :width="maintenance.flyout.width">
                    <span class="pa-badge pa-badge_teal pa-px-4 pa-ml-2">Maint</span>
                </div>
            </a>
        </div>
        <span v-else class="pa-ml-2 pa-txt_capitalize"> {{text}} </span>
    </td>
</template>

<script>
    import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin.vue';

    export default {
        name: 'badge',

        mixins: [VuetableFieldMixin],

        data() {
            return {
            };
        },

        props: {
        },

        computed: {
            outage() {
                return (this.rowData && this.rowData.badge && this.rowData.badge.outage)? this.rowData.badge.outage: '';
            },

            maintenance() {
                return (this.rowData && this.rowData.badge && this.rowData.badge.maintenance)? this.rowData.badge.maintenance: '';
            },

            text() {
                return (this.rowData && this.rowData.badge && this.rowData.badge.text)? this.rowData.badge.text: '';
            },

            outage_class() {
                let common = "pa-txt_capitalize pa-px-4 pa-ml-2 pa-badge"

                if (this.outage.badge_type == 'critical') {
                    return common + " pa-badge_red";
                }
                else {
                    return common + " pa-badge_orange";
                }
            },
        },

        methods: {
            showMaintenance() {
                let url = "/config/EditMaintenanceSchedule?maintenance_schedule_id=" + this.maintenance.flyout.trigger_schedule;
                window.app.eventHub.$emit('drawer:load', {id: 'dynamic-drawer', url: url});
            }
        },
    };
</script>
