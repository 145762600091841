<style>
    .pa-vuetable_loading {
        padding-top: 12px;
    }
    .pa-sortable-icon {
        height: 16px;
        width: 16px;
        background: url(/static/assets/newux/media/svgs/sort.svg) no-repeat;
    }
    .pa-ascending-icon {
        height: 16px;
        width: 16px;
        background: url(/static/assets/newux/media/svgs/sort-active-asc.svg) no-repeat;
    }
    .pa-descending-icon {
        height: 16px;
        width: 16px;
        background: url(/static/assets/newux/media/svgs/sort-active.svg) no-repeat;
    }
</style>

<template>
    <div class="pa-vuetable" :class="classes">
        <vuetable ref="vuetable"
            :api-mode="apiMode"
            :api-url="apiUrl"
            :data="localData"
            :fields="iFields"
            track-by="id"
            pagination-path="pagination"
            detail-row-component="detail-row-table"
            :no-data-template="loadedEmptyText"
            :css="tableSortCss">
        </vuetable>
        <div v-if="loadingIndicator && loading" class="pa-vuetable_loading">
            <p-loading-spinner-2 style="display: block; margin: auto;"></p-loading-spinner-2>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    import Vuetable from 'vuetable-2';

    import TableDetailRow from './vuetable/TableDetailRow.vue';
    Vue.component('detail-row-table', TableDetailRow);

    import ChevronColumn from './vuetable/ChevronColumn.vue';
    import DrawerColumn from './vuetable/DrawerColumn.vue';
    import GraphColumn from './vuetable/GraphColumn.vue';
    import IncidentsColumn from './vuetable/IncidentsColumn.vue';
    import UpDownColumn from './vuetable/UpDownColumn.vue';
    import GroupColumn from './vuetable/GroupColumn.vue';
    import BadgeColumn from './vuetable/BadgeColumn.vue';

    const COL_TYPES = {
        chevron: ChevronColumn,
        drawer: DrawerColumn,
        graph: GraphColumn,
        incidents: IncidentsColumn,
        'up-down': UpDownColumn,
        group: GroupColumn,
        badge: BadgeColumn,
    };

    export default Vue.extend({
        components: {
            Vuetable,
        },

        data() {
            return {
                iFields: [],
                loading: false,
                initialLoad: false,
                tableSortCss: {
                    ascendingClass: 'pa-sorted-asc',
                    descendingClass: 'pa-sorted-desc',
                    sortableIcon: 'pa-sortable-icon',
                    ascendingIcon: 'pa-ascending-icon',
                    descendingIcon: 'pa-descending-icon',
                },
            };
        },

        props: {
            apiUrl: String,

            fields: {
                type: Array,
                'default': () => [],
            },

            localData: {
                type: Array,
                'default': null,
            },

            refreshInterval: Number,

            indent: {
                type: Number,
                'default': 0,
            },

            emptyText: {
                type: String,
                'default': '',
            },

            loadingIndicator: {
                type: Boolean,
                'default': true,
            },
        },

        computed: {
            apiMode() {
                return Boolean(this.apiUrl);
            },

            classes() {
                const classes = [];

                if (this.indent > 0) {
                    classes.push(`indent-${this.indent}`);
                }

                return classes;
            },

            loadedEmptyText() {
                return this.loading ? '' : this.emptyText;
            },
        },

        events: {
            'chevron-col:clicked'(rowData) {
                debugger;
            },
        },

        methods: {
            reload() {
                this.$refs.vuetable.reload();
                this.eventHub.$emit('table-detail-row:reload');
            },

            chevronClicked(rowData) {
                if (rowData.expanded === true) {
                    this.$refs.vuetable.hideDetailRow(rowData.id);
                    Vue.set(rowData, 'expanded', false);
                } else {
                    this.$refs.vuetable.showDetailRow(rowData.id);
                    Vue.set(rowData, 'expanded', true);
                }
            },
        },

        created() {
            for (const field of this.fields) {
                if (typeof field === 'object' && field.name in COL_TYPES) {
                    field.name = COL_TYPES[field.name];
                }
                this.iFields.push(field);
            };
        },

        vueReady() {
            // For loading
            if (!this.localData) {
                this.loading = true;
            }
            this.$refs.vuetable.$on('vuetable:loading', () => {
                if (!this.initialLoad) {
                    this.loading = true;
                }
            });
            this.$refs.vuetable.$on('vuetable:loaded', () => {
                this.loading = false;
                this.initialLoad = true;
            });

            if (this.refreshInterval) {
                window.setInterval(this.reload, 1000 * this.refreshInterval);
            }
        },
    });
</script>

