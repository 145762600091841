<style>
</style>

<template>
    <div class="module oncall-module" :class="{'open': open}">
        <div class="module-header" @click="toggle">
            <span class="pa-txt">On-Call Schedule</span>
            <svg class="pa-icon"><use xlink:href="#chevron-down"></use></svg>
        </div>
        <div ref="body" v-show="open" transition="module-expand" class="module-body">
                <div class="pa-field pa-field_vertical">
                    <div class="pa-field-hd">
                        <label class="pa-label">
                            Select On-Call Schedule
                        </label>
                    </div>
                    <div class="pa-field-bd">
                        <p-select
                            :options="scheduleOptions"
                            :model="selected"
                            :multiple="true"
                            :searchable="true">
                        </p-select>
                    </div>
                    <div class="pa-field-ft">
                    </div>
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const OnCallModule = Vue.extend({
        events: {
        },

        data() {
            return {
                ready: false,
                scheduleOptions: [],
                selected: [],
            };
        },

        props: {
            options: {
                type: Object,
                'default': function() {
                    return {};
                },
            },
            open: {
                type: Boolean,
                'default': true,
            },
        },

        methods: {
            toggle() {
                this.open = !this.open;
            },

            sendConfig() {
                if (!this.ready) { return; }
                const payload = {
                    widget_id: this.$parent.editingWidget.id,
                    schedules: this.selected,
                };
                $.ajax({
                    url: '/dashboardv2/setOnCallSchedules',
                    traditional: true,
                    method: 'POST',
                    data: payload,
                });
                this.$parent.editingWidget.pendNewConfig();
            },

            getAvailableSchedules(callback) {
                const widgetId = this.$parent.editingWidget.id;

                const payload = {
                    widgetId: widgetId,
                };

                $.ajax({
                    url: '/dashboardv2/getAvailableOnCallSchedules',
                    method: 'GET',
                    context: this,
                    data: payload,
                })
                    .done(function(data) {
                        if (!data.success) { return; }

                        this.scheduleOptions = [...data.schedule_options];

                        // Call the callback first
                        if (typeof callback !== 'undefined') {
                            callback();
                        }
                    });
            },
        },

        watch: {
            'selected': function(val, oldVal) {
                if (!this.ready) { return; }
                this.sendConfig();
            },
        },

        computed: {
        },

        vueReady() {
            this.getAvailableSchedules(() => {
                const w = this.$parent.editingWidget;

                const cc = w.config.custom_controls || {};
                if (cc.oncall_schedules) {
                    this.selected = [...cc.oncall_schedules];
                } else {
                    // Precheck all
                    const all = [];
                    this.scheduleOptions.forEach(s => {
                        all.push(s.value);
                    });
                    this.selected = [...all];
                }

                Vue.nextTick(() => {
                    this.ready = true;
                });
            });
        },
    });

    export default OnCallModule;
</script>
