<style lang="scss">
    .pa-tooltip2 {
        .trigger {
            outline: none;
        }

        .content {
            display: none;

            background: white;
            border-radius: 4px;
            color: #333333;
            font-size: 14px;
            
            margin: 5px;
            padding: 8px 20px;
            box-shadow: 0 2px 5px 1px rgba(86, 89, 90, 0.4);
        }

        &.isActive {
            .content {
                display: block;
            }
        }

        &.inline {
            .trigger {
                display: inline;
            }
        }
    }

    .html-editor-content {
        
        .trigger {
            outline: none;
        }

        .content {
            width: 457px;
            font-size: 13px;
            color: #394449;
            padding: 10px 19px;
            border-radius: 5px;
            box-shadow: 0 2px 5px 1px rgba(86, 89, 90, 0.4);
            background-color: white;
        }

    }

    

</style>

<template>
    <div tabindex="-1" class='pa-tooltip2' :class="[htmlClass, computedClass, { isActive: iIsActive }]" @mouseleave="closeWithDelay">
        <div ref="trigger"
             v-if="clickOnly"
             class="trigger"
             type="button"
             tabindex="-1"
             v-on:click="_onClick">
            <slot name="trigger"></slot>
        </div>
        <div ref="trigger"
             v-else
             class="trigger"
             type="button"
             tabindex="-1"
             v-on:mouseenter="onHover()"
             v-on:click="_onClick">
            <slot name="trigger"></slot>
        </div>

        <div class="content" ref="content" :class="[placement, 'arrow-' + arrowPercent]" :style="computedStyles">
            <template v-if="clicked">
                <slot name="clickContent"></slot>
            </template>
            <template v-else>
                <slot></slot>
            </template>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Popper from 'popper.js';

    let COUNT = 0;

    const Tooltip2 = Vue.extend({
        events: {
            'tooltip-opening': function(tooltipId) {
                if (this.id === tooltipId) {
                    return;
                }
                this.close();
            },
        },

        props: {
            id: {
                type: String,
                'default': function() {
                    return `tooltip_${COUNT++}`;
                },
            },

            isActive: {
                type: Boolean,
                'default': false,
            },

            clickOnly: {
                type: Boolean,
                'default': false,
            },

            hover: {
                type: Boolean,
                'default': true,
            },

            waitTime: {
                type: Number,
                'default': 300,
            },

            hoverTimeout: {
                type: Number,
                'default': 400,
            },

            placement: {
                type: String,
                'default': 'top',
            },

            arrowPercent: {
                type: Number,
                'default': 50,
            },

            preventOverflow: {
                type: Boolean,
                'default': true,
            },

            additionalContentStyle: {
                type: Object,
                default: () => {},
            },

            previousSibling: Boolean,

            triggerEl: Object,

            htmlClass: String,

            fixed: Boolean,

            noTransforms: Boolean,

            width: Number,

            normalWhiteSpace: Boolean,

            variant: {
                type: String,
                'default': null,
            },
        },

        data() {
            return {
                iIsActive: false,
                clicked: false,
                waitTimer: null,
                _triggerEl: null,
            };
        },

        computed: {
            computedStyles() {
                const styles = Object.assign({},
                    this.additionalContentStyle,
                    {
                        whiteSpace: this.width || this.normalWhiteSpace ? 'normal' : 'nowrap',
                        width: this.width ? this.width + 'px' : null,
                        zIndex: 11,
                    }
                );
                return styles;
            },
            computedClass: function() {
                let classNames = [];

                if (this.variant) {
                    classNames.push("pa-tooltip2-" + this.variant);
                } 

                return classNames.join(" ");
            },
            trigger() {
                return this._triggerEl || this.$refs.trigger;
            }
        },

        methods: {
            onHover() {
                if (this.waitTime > 0) {
                    this.waitTimer = setTimeout(this.open, this.waitTime);
                    this.trigger.addEventListener('mouseleave', event => {
                        clearTimeout(this.waitTimer);
                    });
                } else {
                    this.open();
                }
            },

            open() {
                this.iIsActive = true;
                if (window.app && window.app.rootVue) {
                    this.eventHub.$emit('tooltip-opening', this.id);
                }

                this.popper = new Popper(this.trigger, this.$refs.content, {
                    placement: this.placement,
                    positionFixed: this.fixed,
                    modifiers: {
                        preventOverflow: {
                            enabled: this.preventOverflow,
                        },
                        hide: {
                            enabled: this.preventOveflow,
                        },
                        computeStyle: {
                            gpuAcceleration: !this.noTransform,
                        },
                    },
                });

                document.body.addEventListener('click', this._onFocusLeaveTooltip);
                document.body.addEventListener('focus', this._onFocusLeaveTooltip, true);
            },

            close() {
                this.iIsActive = false;

                document.body.removeEventListener('click', this._onFocusLeaveTooltip);
                document.body.removeEventListener('focus', this._onFocusLeaveTooltip, true);
            },

            closeWithDelay() {
                if (!this.hover) {
                    return;
                }
                window.setTimeout(this.close, this.hoverTimeout);
            },

            toggle() {
                this.iIsActive = !this.iIsActive;
                if (!this.iIsActive) {
                    this.close();

                    return;
                }

                this.open();
            },

            _onClick($event) {
                if (this.$slots.clickContent) {
                    this.clicked = true;

                    Vue.nextTick(() => {
                        this.popper.update();
                    });

                    window.setTimeout(() => {
                        this.clicked = false;
                        this.toggle();
                    }, 700);
                } else {
                    this.toggle();
                }
            },

            _onFocusLeaveTooltip(event) {
                const target = event.target;
                const tooltipContainsTarget = this.$el.contains(target);
                const triggerContainsTarget = this._triggerEl && this._triggerEl.contains(target);

                if (tooltipContainsTarget || triggerContainsTarget) {
                    return;
                }

                this.close();
            },
        },

        vueReady() {
            this.iIsActive = this.isActive;

            this._triggerEl = this.triggerEl;

            if (this.previousSibling) {
                this._triggerEl = this.$el.previousElementSibling;
            }

            if (this._triggerEl) {
                this._triggerEl.addEventListener('click', this._onClick);
                this._triggerEl.addEventListener('mouseenter', this.onHover);
            }
        },
    });

    export default Tooltip2;
</script>
