let offsetTop;
let scrollbarWidth = null;
let scrollIsLocked = false;
let modalScrollIsLocked = false;
const NO_SCROLL_CLASS = 'is-scroll-locked';
const MODAL_NO_SCROLL_CLASS = 'is-modal-scroll-locked';
const HIDDEN_SCROLLBAR_CLASS = 'no-scrollbar';

const DEFAULT_SETTINGS = {
    inModal: false,
    hideScrollbar: false,
    force: false,
};

export default function createScrollLocker(config) {
    const settings = Object.assign({}, DEFAULT_SETTINGS, config);
    const htmlElement = document.documentElement;
    let bodyElement = document.body;
    // TODO: use a js-hook here
    const siteNavElement = document.querySelector('.pa-page-hd');

    return {
        settings: settings,
        on: function on() {
            if (!bodyElement) {
                bodyElement = document.body;
            }
            if (settings.inModal) {
                if (modalScrollIsLocked) {
                    return;
                }
                modalScrollIsLocked = true;
                htmlElement.classList.add(MODAL_NO_SCROLL_CLASS);
                return;
            }

            if (scrollIsLocked === true || (!settings.force && !pageHasScrollbar())) {
                return;
            }

            if (scrollbarWidth === null) {
                scrollbarWidth = getScrollbarWidth();
            }

            offsetTop = (window.pageYOffset || document.scrollTop);
            bodyElement.style.width = `calc(100vw - ${scrollbarWidth}px)`;
            bodyElement.style.position = 'fixed';
            bodyElement.style.top = `${offsetTop ? -offsetTop : 0}px`;
            if (settings.hideScrollbar) {
                bodyElement.style.overflowY = 'hidden';
                bodyElement.classList.add(HIDDEN_SCROLLBAR_CLASS);
            } else {
                bodyElement.style.overflowY = 'scroll';
            }
            bodyElement.classList.add(NO_SCROLL_CLASS);

            scrollIsLocked = true;
        },
        off: function off() {
            if (!bodyElement) {
                bodyElement = document.body;
            }
            if (settings.inModal) {
                if (!modalScrollIsLocked) {
                    return;
                }
                modalScrollIsLocked = false;
                htmlElement.classList.remove(MODAL_NO_SCROLL_CLASS);
                return;
            }

            if (scrollIsLocked === false && !settings.force && !pageHasScrollbar()) {
                return;
            }

            bodyElement.style.width = '';
            bodyElement.style.position = '';
            bodyElement.style.top = '';
            bodyElement.style.overflowY = '';
            bodyElement.classList.remove(NO_SCROLL_CLASS);
            bodyElement.classList.remove(HIDDEN_SCROLLBAR_CLASS);

            //
            // Scroll to the position we saved so we dont jump to the top of
            // the page when a modal is closed
            //
            // Sometimes scrollLocker.off will be called when the scroll isnt
            // locked, so we shouldnt scroll anywhere if thats the case.
            //
            if (scrollIsLocked) {
                window.scrollTo(0, offsetTop);
            }

            scrollIsLocked = false;
        },
    };
}

function pageHasScrollbar() {
    return document.body.scrollHeight > window.innerHeight;
}

function getScrollbarWidth() {
    const div = document.createElement('div');

    div.style.width = '100px';
    div.style.height = '100px';
    div.style.position = 'absolute';
    div.style.top = '-9999px';
    div.style.left = '-9999px';
    div.style.overflow = 'scroll';

    document.body.appendChild(div);

    const scrollbarWidth = div.offsetWidth - div.scrollWidth;

    document.body.removeChild(div);

    return scrollbarWidth;
}
