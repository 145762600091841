<template>
    <p-badge :id="'tag-' + tag" role="button" variant="tag" :class="computedClass">
        {{ tag }}
        <button
            v-if="canRemove"
            class="close"
            type="button"
            @click="onRemove(tag)"
        >
            ×
        </button>
    </p-badge>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        // The tag name
        tag: String,
        variant: String,
        onRemove: Function,
        canRemove: Boolean,
    },
    computed: {
        computedClass() {
            const classNames = {
                'taggle': true,
                ['taggle_' + this.variant]: this.variant && this.variant !== '',
            };

            return classNames;
        },
    },
});
</script>
