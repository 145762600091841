<template>
    <th v-if="isHeader">
        {{ title }}
    </th>
    <td v-else>
        <p-up-down v-if="status !== null" :status="status"></p-up-down>
    </td>
</template>

<script>
    import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin.vue';

    export default {
        name: 'up-down',

        mixins: [VuetableFieldMixin],

        data() {
            return {
            };
        },

        props: {
        },

        computed: {
            status() {
                if (this.isHeader) {
                    return null;
                }

                let key = 'up-down';
                if (this.rowField.key) {
                    key = this.rowField.key;
                }
                const val = this.rowData[key];
                return  val || null;
            },
        },
    };
</script>
