<style lang="scss">
.pa-secondary-nav__top-level-items {
    overflow-y: auto;
}
</style>

<template>
    <nav class="pa-secondary-nav__top-level-items">
        <ul class="pa-txt_xs">
            <p-secondary-nav-top-level-item
                v-for="navItem in navItems"
                :key="navItem.id"
                :id="navItem.id"
                :action="navItem.action"
                :text="navItem.text"
                :href="navItem.href"
                :to="navItem.to"
                :left-icons="navItem.leftIcons"
                :right-icons="navItem.rightIcons"
                :items="navItem.items"
                :open="navItem.open"
                :badge="navItem.badge"
                :exact-path="navItem.exactPath"
                :trigger="navItem.trigger"
                :active="navItem.active"
                @menu:toggled="handleMenuToggled"
                @item:active="handleItemActive"
            >
            </p-secondary-nav-top-level-item>
        </ul>
    </nav>
</template>

<script setup>
import PSecondaryNavTopLevelItem from './SecondaryNavTopLevelItem.vue'

defineProps({
  navItems: Array,
});

const emit = defineEmits(["menu:toggled", "item:active"]);

const handleMenuToggled = (data) => {
    emit('menu:toggled', data);
};

const handleItemActive = (data) => {
    emit('item:active', data);
};
</script>
