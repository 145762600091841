<style>
    .synthetic-screenshots {
        .steps-table {
            text-align: left;

            th {
                border-left: none;
                border-right: none;
                border-top: none;
                padding: 8px 10px;
            }

            td {
                border-left: none;
                border-right: none;
            }
        }

        .step-screenshot {
            margin-top: 15px;
            max-height: 47vh;
            max-width: 40vw
        }

        .nav-btn {
            background: #f2f3f5;
            color: #3954bf;
        }
    }
</style>

<template>
    <div class="synthetic-screenshots">
        <p-modal
            ref="modal"
            id="synthetic-screenshots-modal"
        >
            <div class="pa-panel" style="width: 50vw;">
                <div class="pa-panel-hd">
                    <h2 class="pa-hdg pa-hdg_3" slot="header">Browser Synthetic Check - Step {{ stepIndex + 1 }}</h2>
                </div>
                <div class="pa-panel-bd" style="text-align: center; max-height: 75vh">
                    <div class="pa-vuetable steps-table">
                        <vuetable
                            v-if="currentStep"
                            :api-mode="false"
                            ref="vuetable"
                            :data="[currentStep]"
                            :fields="fields"
                            pagination-path=""
                        >
                            <div slot="pass-fail-slot" slot-scope="props">
                                <div v-if="props.rowData.success === true" class="pass-badge">Pass</div>
                                <div v-if="props.rowData.success === false" class="fail-badge">Fail</div>
                                <div v-if="props.rowData.success === null" class="not-run-badge">Not Run</div>
                            </div>
                            <div slot="command-slot" slot-scope="props">
                                <div style="display: inline-block; background: #f2f3f5; padding: 5px; border-radius: 2px; font-family: monospace;">
                                    <span>{{ props.rowData.command }}</span>
                                </div>
                            </div>
                        </vuetable>
                    </div>

                    <img
                        v-if="currentStep && currentStep.screenshot"
                        class="step-screenshot"
                        :src="`${screenshotsUrl}/${currentStep.screenshot}`"
                        @error="screenshotError" />
                    <img
                        v-else
                        class="step-screenshot"
                        src="/static/assets/newux/media/screenshot-placeholder.png" />

                    <div class="pa-flex" style="justify-content: space-between; margin-top: 15px;">
                        <button
                            v-if="stepIndex > 0"
                            class="pa-btn nav-btn"
                            @click="stepIndex -= 1"
                        >
                            &lt; Previous Step
                        </button>
                        <div v-else></div>

                        <button
                            v-if="stepIndex < steps.length - 1"
                            class="pa-btn nav-btn"
                            @click="stepIndex += 1"
                        >
                            Next Step &gt;
                        </button>
                        <div v-else></div>
                    </div>
                </div>
            </div>
        </p-modal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Vuetable from 'vuetable-2';
    import moment from 'moment';

    export default Vue.extend({
        components: {
            Vuetable,
        },
        data() {
            return {
                stepIndex: 0,
                fields: [{
                    name: 'pass-fail-slot',
                    title: 'STATUS',
                    width: '10%',
                }, {
                    name: 'command-slot',
                    title: 'COMMAND',
                    width: '40%',
                }, {
                    name: 'target',
                    title: 'TARGET',
                    width: '30%',
                }, {
                    name: 'value',
                    title: 'VALUE',
                    width: '20%',
                }],
                imgTimeouts: {},
            };
        },
        props: {
            steps: Array,
            screenshotsUrl: String,
        },
        computed: {
            currentStep() {
                return this.steps[this.stepIndex];
            },
        },
        methods: {
            renderTimestamp(timestamp) {
                const tstamp = timestamp * 1000;
                const tz = moment.tz.guess();
                const utc = moment.tz(tstamp, 'UTC');
                const local = utc.clone().tz(tz);
                return local.format('MM-DD-YY - HH:MM (z)');
            },
            openAtIndex(index) {
                this.stepIndex = index;
                this.$refs.modal.open();
            },
            screenshotError(evt) {
                const target = evt.target;
                const origSrc = target.src;
                let t = 2000;
                if (origSrc in this.imgTimeouts) {
                    t = this.imgTimeouts[origSrc] * 2;
                }
                this.imgTimeouts[origSrc] = t;
                target.src = '/static/assets/newux/media/screenshot-placeholder.png';
                window.setTimeout(() => {
                    target.src = origSrc;
                }, t);
            },
        },
    });
</script>
