<template>
    <input type="color" name="color" @input="change" />
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    methods: {
        change(e) {
            this.$emit('input', e)
        },
    },
});
</script>
