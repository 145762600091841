<style lang="scss">
    .metric-text {
        p {
            color: #2c3438;
            font-size: 66px;
        }
    }
</style>

<template>
    <div class="metric-text">
        <p>{{ value }}</p>
    </div>
</template>

<script>
    import Vue from 'vue';

    const MAX_DATA_TRIES = 3;

    const MetricTextComponent = Vue.extend({
        data() {
            return {
                loading: true,

                value: 0.0,

                retryTimeout: 10000,
                tries: 0,
            };
        },

        props: {
            id: {
                type: String,
                'default': '',
            },

            metric: Number,


            initialValue: {
                type: Number,
                'default': 0,
            },

            unit: {
                type: String,
                'default': '',
            },

        },

        events: {
            'metric-values:new': function(metricId, values) {
                if (metricId !== this.metric) {
                    return;
                }
                const last = values.slice(-1)[0];
                this.updateValue(last[1]);
            },
        },

        methods: {
            fetchValue() {
                $.ajax({
                    url: '/report/gauge_data',
                    data: {
                        metric_id: this.metric,
                    },
                }).done(data => {
                    if (data.success) {
                        this.value = data.value;
                        this.retryTimeout = 10000;
                    } else {
                        console.error(data);
                        this.value = '?';
                    }
                })
                .fail(() => {
                    if (this.tries < MAX_DATA_TRIES) {
                        this.tries += 1;
                        setTimeout(() => {
                            this.fetchValue();
                        }, 800);
                    } else {
                        // Switch to exponential backoff
                        setTimeout(() => {
                            this.fetchValue();
                        }, this.retryTimeout);
                        this.retryTimeout *= 2;

                        if (window.app.Sentry) {
                            window.app.Sentry.captureMessage(`All ${MAX_DATA_TRIES} attempts at gauge_data failed`, {
                                extra: {
                                    metric_id: this.metric,
                                },
                                fingerprint: 'gauge-fail',
                                tags: {
                                    url: window.location.href,
                                },
                            });
                        }
                    }
                });
            },

            updateValue(value) {
                this.value = value;
            },
        },

        mounted() {
            this.value = this.initialValue;

            if (this.metric) {
                this.fetchValue();
            }
        },
    });

    export default MetricTextComponent;
</script>
