<style scoped>
    .section .pa-expando-box-bd {
        opacity: unset;
        transition: unset;
    }
    .section .pa-expando-hd {
        cursor: default;
    }
    .section-badge {
        background: #F7E5BD;
        border-radius: 2px;
        color: #553D0F;
        font-size: 13px;
        font-weight: 500;
        margin-left: 5px;
        padding: 5px;
    }
</style>

<template>
    <div
        class="section pa-expando"
        :id="id"
        v-on:keydown.esc="close()">
        <div
            class="pa-expando-hd"
            type="button"
            v-el:trigger
            :tabindex="isFocusable ? 0 : -1">

            <div class="pa-expando-hd-wrapper">
                <input v-model="title"
                    v-el:title-input
                    @click.stop=""
                    v-if="editingTitle"
                    class="pa-input"
                    style="width: 200px;" />
                <span v-else class="pa-txt_medium">{{ title }}</span>
                <span v-if="badge" class="section-badge">{{ badge }}</span>
                <template v-if="editableTitle">
                    <svg v-if="editingTitle"
                            @click.stop="finishTitleEdit"
                            class="pa-icon"
                            style="height: 19px; vertical-align: text-top; margin-left: 5px;">
                        <use xlink:href="#check"></use>
                    </svg>
                    <svg v-else
                            @click.stop="editingTitle=true"
                            class="pa-icon"
                            style="height: 19px; vertical-align: text-top; margin-left: 5px;">
                        <use xlink:href="#pencil"></use>
                    </svg>
                </template>

                <slot name="headerControls"></slot>

            </div>

        </div>
        <div class="pa-expando-box-bd">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    let COUNT = 0;

    const Section = Vue.extend({
        data() {
            return {
                isFocusable: true,
                domObserver: null,
                editingTitle: false,
            };
        },

        events: {},

        props: {
            id: {
                type: String,
                'default': () => {
                    return `section_${COUNT++}`;
                },
            },

            title: String,

            editableTitle: Boolean,

            badge: String,

            titleChangeCallback: {
                type: Function,
                'default': () => { },
            },

        },

        methods: {
            finishTitleEdit() {
                this.editingTitle = false;
                this.titleChangeCallback(this.title);
            },
        },

        vueReady() {

        },
    });

    export default Section;
</script>
