<style lang="scss">
    .detail-row-table .vuetable > thead {
        display: none;
    }
</style>

<template>
    <div class="detail-row-table">
        <p-vue-table ref="pVueTable"
                     :fields="$parent.fields"
                     :local-data="typeof(rowData.children) === 'object' ? rowData.children : []"
                     :api-url="rowData.childrenUrl || ''"
                     :indent="$parent.$parent.indent + 1"
                     :empty-text="rowData.emptyText">
        </p-vue-table>

    </div>
</template>

<script>
    import Vue from 'vue';

    import Vuetable from 'vuetable-2';
    
    export default Vue.extend({
        components: {
            Vuetable,
        },

        data() {
            return {};
        },

        events: {
            'table-detail-row:reload'() {
                this.$refs.pVueTable.$refs.vuetable.reload();
            },
        },

        props: {
            rowData: {
                type: Object,
                required: true,
            },

            rowIndex: {
                type: Number,
            },

            options: {
                type: Object,
            },
        },
    });
</script>
