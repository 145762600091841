var menuMixin = {
    props: {
        icon: String,

        iconColor: String,

        inversed: Boolean,

        isAbsolute: Boolean,

        isPinned: Boolean,

        isMaterial: Boolean,

        text: String,

        cssclass: String,

        menuClass: String,

        disabled: Boolean,

        variant: String,

        preferredXDirection: {
            type: String,
            default: 'left',
        },

        color: {
            type: String,
            default: '',
        },

        size: {
            type: String,
            default: '',
        },
    },
};

module.exports = menuMixin;
