// History listener
// Source: https://vueschool.io/articles/vuejs-tutorials/creating-your-own-router/

const listeners = [];

export const push = route => {
    const previousRoute = `${window.location.pathname}${window.location.search}`;
    const nextRoute = `${route}${window.location.search}`;
    window.history.pushState(null, null, nextRoute);
    listeners.forEach(listener => listener(nextRoute, previousRoute));
};

export const listen = fn => {
    listeners.push(fn);
};