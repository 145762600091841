<style lang="scss">
.pa-side-nav__top-level-items {
    overflow-y: auto;
    overflow-x: hidden;
}
</style>

<template>
    <nav class="pa-side-nav__top-level-items" :class="[isCollapsed && 'collapsed_state']" @mouseleave="onMouseLeave">
        <ul @mouseover="onMouseOver">
            <p-side-nav-top-level-item
                v-for="navItem in navItems"
                :key="navItem.id"
                :id="navItem.id"
                :action="navItem.action"
                :text="navItem.text"
                :href="navItem.href"
                :badge="navItem.badge"
                :left-icons="navItem.leftIcons"
                :right-icons="navItem.rightIcons"
                :items="navItem.items"
                :is-collapsed="isCollapsed"
                :open="navItem.open"
                :exact-path="navItem.exactPath"
                :trigger="navItem.trigger"
                :active="navItem.active"
                :has-active-child="navItem.hasActiveChild"
                :button="navItem.button"
                @menu:toggled="handleMenuToggled"
                @item:active="handleItemActive"
            >
            </p-side-nav-top-level-item>
        </ul>
    </nav>
</template>

<script setup>
import PSideNavTopLevelItem from './SideNavTopLevelItem.vue'

defineProps({
  navItems: Array,
  isCollapsed: Boolean,
});

const emit = defineEmits(["menu:toggled", "item:active", "mouseover", "mouseleave"]);

const handleMenuToggled = (data) => {
    emit('menu:toggled', data);
};

const handleItemActive = (data) => {
    emit('item:active', data);
};

const onMouseOver = (data) => {
    emit('mouseover', data);
}

const onMouseLeave = (data) => {
    emit('mouseleave', data);
}

</script>
