<template>
    <ul class="drawer-active-incident-list pa-vList">
        <slot></slot>
        <li v-if="!incidents || incidents.length === 0" class="pa-txt_13">
            No active incidents
        </li>
        <template v-if="advanced">
            <p-drawer-active-incident-list-item-advanced
                :key="index"
                v-for="(incident, index) in incidents"
                :incident="incident"
            >
            </p-drawer-active-incident-list-item-advanced>
        </template>
        <template v-else>
            <p-drawer-active-incident-list-item
                :key="index"
                v-for="(incident, index) in incidents"
                :incident="incident"
            >
            </p-drawer-active-incident-list-item>
        </template>
    </ul>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            incidents: Array,
            advanced: Boolean,
        },
    });
</script>
