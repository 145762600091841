<style lang="scss" scoped>
.pointer-link {
    cursor: pointer;
}

</style>

<template>
    <a
        :class="computedClass"
        :rel="isExternal ? 'noopener noreferrer' : ''"
        :target="isExternal ? '_blank' : ''"
    >
        <p-icon
            v-if="leftIcon"
            :icon="leftIcon"
            :size="iconSize"
            class="pa-pr-4"
        >
        </p-icon>
        <slot></slot>
        <p-icon
            v-if="rightIcon"
            :icon="rightIcon"
            :size="iconSize"
            class="pa-pl-4"
        >
        </p-icon>
    </a>
</template>

<script>
import Vue from "vue";

import styleMixin from "../mixins/styleMixin";

const BASE_CLASSNAME = "pa-anchor";

export default Vue.extend({
    mixins: [styleMixin],
    props: {
        leftIcon: {
            type: String,
            default: '',
        },
        rightIcon: {
            type: String,
            default: '',
        },
        isExternal: {
            type: Boolean,
            default: false,
        },
        addPointer: {
            type: Boolean,
            default: false,
        },
        iconSize: {
            type: String,
            default: '',
        },
    },
    computed: {
        computedClass() {
            const classNames = {
                [BASE_CLASSNAME]: true,
                'pointer-link': this.addPointer,
            };

            return {
                ...this.computedMixinClass,
                ...classNames
            };
        },
    },
});
</script>
