<template>
    <div>
        <label
            v-if="label && labelPosition === 'top'"
            :for="id"
            class="pa-label pa-pb-2"
        >
            {{ label }}
        </label>
        <ul class="pa-hList">

            <label
                v-if="label && labelPosition === 'left'"
                :for="id"
                class="pa-label"
            >
                {{ label }}
            </label>
            <p-tooltip2
                :prevent-overflow="true"
                placement="bottom"
                v-if="tooltipText != ''"
                variant="steel"
            >
                <span slot="trigger">
                    <span class="block">
                        <p-icon
                            icon="question-circle-outline"
                            size="sm"
                            class="pa-p-0"
                            color="blue-500"
                        >
                        </p-icon>
                    </span>
                </span>
                {{ tooltipText }}
            </p-tooltip2>
            <p-flyout
                v-if="hasFlyoutSlot"
                no-arrow
                hover
                :width="450"
                direction="bottom"
            >
                <span slot="trigger">
                    <span class="block">
                        <p-icon
                            icon="question-circle-outline"
                            size="sm"
                            class="pa-p-0"
                            color="blue-500"
                        >
                        </p-icon>
                    </span>
                </span>
                <slot slot="content" name="flyout">
                </slot>
            </p-flyout>
            <label :class="computedClassnames" :style="style">
                <input
                    :id="id"
                    type="checkbox"
                    class="pa-switch-input"
                    v-model="model"
                    v-on:change="_onChange" />
                <span class="pa-switch-knob"></span>
                <span class="pa-switch-backdrop"></span>
            </label>
            <label
                v-if="label && labelPosition === 'right'"
                :for="id"
                class="pa-label"
            >
                {{ label }}
            </label>
            
        </ul>
    </div>
</template>

<script>
    import Vue from 'vue';
    import generateID from '../utils/generateID';

    export default Vue.extend({
        methods: {
            _onChange() {
                this.onChange({
                    value: this.model
                });
                if( this.dataUrl) {
                    this.load(this.dataUrl);
                }
            },

            load(url) {
                return $.getJSON(url).then((response) => response);
            }
        },

        computed: {
            hasFlyoutSlot() {
                return !!this.$slots["flyout"];
            },
            computedClassnames() {
                const classNames = {
                    "pa-switch": true,
                    [`pa-switch--${this.variant}`]: this.variant,
                };

                return classNames;
            },

            style() {
                if(this.size == 'sm') {
                    return "height: 10px; width: 20px";
                }

                return "";
            },
        },

        props: {
            model: Boolean,

            onChange: {
                type: Function,
                default: function() {}
            },

            dataUrl: {
                type: String,
                default: ""
            },
            label: {
                type: String,
                default: ""
            },
            labelPosition: {
                type: String,
                default: "left",
            },
            tooltipText: {
                type: String,
                default: "",
            },
            size: {
                type: String,
                default: "",
            },
            variant: String,
            id: {
                type: String,
                default: () => generateID('switch_'),
            },
        }
    });
</script>
