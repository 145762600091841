<template>
    <p-menu
        :is-material="true"
        :inversed="true"
        :html-id="htmlId"
        :min-height="minHeight"
        :disabled="disabled"
        preferred-x-direction="right">
        <p-button
            slot="trigger"
            :disabled="disabled"
            :narrow="btnNarrow"
            :color="btnColor"
            :variant="btnVariant"
            :class="cssclass"
        >
            <p-icon
                icon="dots-vertical"
                size="xxxl"
            >
            </p-icon>
            <span class="pa-isVisuallyHidden">open menu</span>
        </p-button>
        <slot></slot>
    </p-menu>
</template>

<script>
    import Vue from 'vue';

    const menuMixin = require('../mixins/menuMixin');

    const NavMenu = Vue.extend({
        mixins: [menuMixin],
        props: {
            htmlId: String,
            minHeight: Number,
            btnNarrow: {
                type: Boolean,
                default: true,
            },
            btnVariant: {
                type: String,
                default: "dot-menu"
            },
            btnColor: String,
        },
    });

    export default NavMenu;
</script>
