<template>
    <th v-if="isHeader">
        {{ title }}
    </th>
    <td v-else class="graph">
        <div>
            <div class="graph-block" @click="popupGraph" :style="{ cursor: enablePopup ? 'pointer': '' }">
                <p-graph v-if="monitors"
                    ref="graph"
                    :monitors="monitors"
                    :highcharts-config="chartConfig"
                    :latest-value.sync="latestVal"
                    :unit.sync="unit"
                    timescale="hour"
                    :min-height="0"
                    :resize="false"
                    height="30px">
                </p-graph>
            </div>

            <div class="value-block">
                <p class="pa-txt">{{ ![null, undefined].includes(latestVal) ? abbrevNum(latestVal) : '-' }}{{ unit === '%' ? unit : ' ' + unit }}</p>
            </div>
        </div>
    </td>
</template>

<script>
    import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin.vue';
    import HighchartsConfigs from '../../../lib/highcharts-configs';

    import _ from 'lodash';

    export default {
        name: 'graph',

        mixins: [VuetableFieldMixin],

        data() {
            return {
                chartConfig: _.merge({}, HighchartsConfigs.sparkline, {
                    chart: {
                        width: 135,
                    },
                    yAxis: {
                        max: this.rowField.percent ? 100 : undefined,
                    },
                }),

                latestVal: null,
                unit: '',
            };
        },

        props: {
            enablePopup: {
                type: Boolean,
                'default': true,
            },
        },

        computed: {
            monitors() {
                let key = 'graph';
                if (this.rowField.key) {
                    key = this.rowField.key;
                }
                const config = this.rowData[key] || null;
                if (!config) {
                    return null;
                }
                return config.monitors;
            },
        },

        methods: {
            abbrevNum(num) {
                return window.helpers.abbrevNum(num);
            },

            popupGraph() {
                if (!this.enablePopup) { return; }

                let queryString = '';
                this.monitors.forEach(mId => {
                    queryString = `${queryString}&metric_ids[]=${mId}`;
                });
                queryString = `${queryString}&title=${this.title}`;

                const url = `/report/MetricPopup?${queryString}`;
                this.$root.$broadcast('modal:load', {
                    id: 'dynamic_modal',
                    url: url,
                    open: true,
                });
            },
        },
    };
</script>
