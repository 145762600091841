<script setup>
import { ref, watch, watchEffect, computed } from "vue";
import _ from "lodash";
import { LOGICAL_OPERATORS_MAP } from "../constants/logicalOperators";
import { FILTER_CONDITIONS_MAP } from "../constants/filterConditions";

const emit = defineEmits([
    'remove',
    'update:value',
    'update:condition',
    'update:filter',
    'update:operator',
]);

const props = defineProps({
    index: {
        type: Number,
        default: null,
    },
    filters: {
        type: Array,
        default: () => [],
        required: true,
    },
    value: {
        type: [String, Number, Array, Boolean],
        default: null,
    },
    operator: {
        type: String,
        default: "and",
    },
    condition: {
        type: String,
        default: "is",
    },
    error: {
        type: String,
        default: null,
    },
    filter: {
        type: String,
        default: null,
    },
    isValid: {
        type: Boolean,
        default: true,
    },
    conditions: {
        type: Array,
        required: false,
    },
});

const emitUpdate = (propName, value) => {
    emit(`update:${propName}`, props.index, value);
};

const filterOptions = computed(() => {
    if (!props.filters) {
        return undefined;
    }

    return props.filters.map((_filter) => {
        return {
            label: _filter.label,
            value: _filter.id,
        };
    });
});

const localFilter = ref(props.filter);
const localValue = ref(props.value);
const localCondition = ref(props.condition);
const localOperator = ref(props.operator);

const matchedFilter = computed(() => {
    if (!localFilter.value) {
        return null;
    }

    return props.filters.find((_filter) => {
        return _filter.id === localFilter.value;
    });
});

const valueSearchable = computed(() => {
    if(matchedFilter && matchedFilter.value.searchable) {
        return matchedFilter.value.searchable;
    }

    return false;
});

const configuredConditions = computed(() => {
    if(matchedFilter && matchedFilter.value.conditions) {
        return matchedFilter.value.conditions;
    }

    return props.conditions;
});

const useOptGroups = computed(() => {
    if(matchedFilter && matchedFilter.value.optgroups) {
        return true
    }

    return false;
});

const isTagsInput = computed(() => matchedFilter.value.input === 'tags');
const isSelectInput = computed(() => matchedFilter.value.input === 'select');

watchEffect(() => {
    localFilter.value = props.filter;
    localValue.value = Array.isArray(props.value) ? [...props.value] : props.value;
    localCondition.value = props.condition;
    localOperator.value = props.operator;
});

watch(localFilter, (val) => emitUpdate('filter', val));
watch(localValue, (val) => emitUpdate('value', val), { deep: true });
watch(localCondition, (val) => emitUpdate('condition', val));
watch(localOperator, (val) => emitUpdate('operator', val));

const allowMultiple = computed(() => {
    return (
        isSelectInput.value &&
        [
            FILTER_CONDITIONS_MAP.IS_ANY_OF,
            FILTER_CONDITIONS_MAP.IS_NONE_OF,
        ].includes(localCondition.value)
    );
});

watchEffect(() => {
    if (isSelectInput.value) {
        const isArrayOfValues = Array.isArray(localValue.value);

        if (isArrayOfValues && !allowMultiple.value) {
            localValue.value = localValue.value[0];
        } else if (!isArrayOfValues && allowMultiple.value) {
            localValue.value = [localValue.value];
        } else if (!isArrayOfValues && !allowMultiple.value) {
            localValue.value = localValue.value;
        }
    }
});

const removeRule = () => {
    emit('remove', props.index);
};

const classNames = computed(() => {
    return {
        'filter-rule': true,
        'filter-rule--invalid': !props.isValid,
    };
});

const onTextInputBlur = (value) => {
    localValue.value = value;
};

const tagsSelectText = computed(() => {
    if (!localValue.value.length) {
        return 'Select';
    }

    return localValue.value.join(', ');
});
</script>

<template>
    <li>
        <p-text v-if="error" font-size="sm" color="crimson" class="pa-mb-4 pa-pt-4">
            {{ error }}
        </p-text>

        <p-row
            align-center
            :class="classNames"
        >
            <p-column style="width: 13.2%;">
                <span v-if="index === 0" class="pa-pl-2">
                    Where
                </span>
                <p-logical-operator-picker
                    v-if="index === 1"
                    class="pa-pl-2"
                    :operator.sync="localOperator"
                    :operators="[
                        LOGICAL_OPERATORS_MAP.AND,
                        LOGICAL_OPERATORS_MAP.OR,
                    ]"
                    size="xxxxxxs"
                >
                </p-logical-operator-picker>
                <span v-if="index > 1" class="pa-pl-2">
                    {{ localOperator }}
                </span>
            </p-column>
            <p-column cols="9">
                <p-flex>
                    <p-select
                        :model.sync="localFilter"
                        :options="filterOptions"
                        :list-width="200"
                        size="xxxs"
                        class="pa-mr-6"
                    >
                    </p-select>

                    <p-condition-picker
                        :condition.sync="localCondition"
                        :conditions="configuredConditions"
                        class="pa-mr-6"
                    >
                    </p-condition-picker>

                    <template v-if="localFilter && matchedFilter">

                        <p-select
                            v-if="isSelectInput && !useOptGroups"
                            :model.sync="localValue"
                            :options="matchedFilter.values"
                            size="xxs"
                            :multiple="allowMultiple"
                            :list-width="200"
                            :searchable="valueSearchable"
                        >
                        </p-select>

                        <p-select
                            v-if="isSelectInput && useOptGroups"
                            :model.sync="localValue"
                            :optgroups="matchedFilter.optgroups"
                            size="xxs"
                            :multiple="allowMultiple"
                            :list-width="200"
                            :searchable="valueSearchable"
                        >
                        </p-select>

                        <p-select
                            v-if="isTagsInput"
                            class="filter-rule-tags-select"
                            :model.sync="localValue"
                            searchable
                            :options="$root.customerConfig.allTags.map(tag => ({ label: tag, value: tag }))"
                            multiple
                            :show-icon="false"
                            :wrap-labels="true"
                            :hide-button-titles="true"
                            :list-width="180"
                            :title="tagsSelectText"
                        >
                            <div slot="display-text">
                                <span class="pa-txt_truncate block" style="max-width: 150px;">
                                    {{ tagsSelectText }}
                                </span>
                            </div>
                        </p-select>

                        <p-input
                            v-if="matchedFilter.input === 'text'"
                            type="text"
                            class="pa-input_xs"
                            :value="localValue"
                            @blur="onTextInputBlur"
                        >
                        </p-input>

                    </template>
                </p-flex>
            </p-column>
            <p-column cols="1">
                <p-button unstyled @click="removeRule">
                    <p-icon icon="x"></p-icon>
                </p-button>
            </p-column>
        </p-row>
    </li>
</template>

<style lang="scss">
.filter-rule {
    margin-bottom: 8px;

    &--invalid {
        background-color: hsl(0deg 85.31% 88.04%);
        padding: 4px 8px;
    }

    .filter-rule-tags-select {
        width: 180px;
    }
}
</style>