<template>
    <div style="display: block;">
    <ul class="counter-measure-title">
        <li style="width: 100%; border-bottom: 1px solid #dcdcdc;" class="pa-pb-4">
            <p-icon icon="flag-filled"></p-icon>
            <h4 style="display: inline-block; font-size: 13px;">CounterMeasures</h4>
        </li>
        <li class="pa-txt_xs pa-mt-12">
            CounterMeasures are automated actions that range from simple debugging commands to complex actions such as
            API calls or restarting a service. They can be triggered using thresholds.
        </li>
    </ul>
    <!-- Hidden input type for forms using the countermeasure component -->
    <input type="hidden" v-model="jsonCountermeasures" name="countermeasures"/>
    <div v-for="(counterMeasure, cmIndex) in countermeasures" transition="slide" :id="Math.abs(counterMeasure.countermeasure_id)"
        :style="{ marginTop: cmIndex > 0 ? '20px' : ''}">
        <div class="counter-measure-box">
            <div v-show="counterMeasure.countermeasure_id < 0" class="pa-message pa-message_error"
                style="margin-right: 30px; border-left-width: 1px;">
                <p style="font-size: 11px; font-weight: bold;">Warning: Missing CounterMeasure</p>
                <p style="font-size: 11px;">This Incident CounterMeasure has been configured to use a CounterMeasure
                    plugin that is not present on this instance</p>
            </div>
            <div v-if="!customerAckdCmPricing" transition="slide" class="pa-message pa-message_info"
                style="margin-right: 30px; border-left-width: 1px;">
                <p style="font-size: 11px; font-weight: bold;">CounterMeasure Pricing</p>
                <p style="font-size: 11px;">There is an additional cost for utilizing CounterMeasures. See our <a
                        href="https://www.panopta.com/pricing/">pricing page</a> for more details or <a
                        href="https://docs.fortimonitor.forticloud.com/user-guide/46227/get-support">contact
                        support</a> with any questions</p>
                <button @click="dismissCMPricing" class="pa-btn naked_blue" type="button">Got it</button>
            </div>
            <div class="counter-measure-remove" @click="removeCounterMeasure(cmIndex)">
                <svg class="pa-icon">
                    <use xlink:href="#close"></use>
                </svg>
            </div>
            <div class="pa-field">
                <div class="pa-field-hd">
                    <label for="cm_delay" class="pa-label">Delay</label>
                </div>
                <div class="pa-field-bd">
                    <p-select :model.sync="counterMeasure.countermeasure_delay" :options="countermeasure_delays">
                    </p-select>
                </div>
                <div class="pa-field-ft">
                    <p class="pa-hint">
                    </p>
                </div>
            </div>
            <div class="pa-field" v-show="counterMeasure.countermeasure_delay === 'custom'">
                <div class="pa-field-hd">
                    <label for="cm_delay" class="pa-label">Custom Delay (minutes)</label>
                </div>
                <div class="pa-field-bd">
                    <input v-model="counterMeasure.countermeasure_delay_custom" type="number" placeholder="Minutes"
                        class="pa-input" style="width: 15.625rem;"/>
                </div>
                <div class="pa-field-ft">
                    <p class="pa-hint">
                    </p>
                </div>
            </div>
            <div class="pa-field">
                <div class="pa-field-hd">
                    <label for="cm_action" class="pa-label">Action</label>
                </div>
                <div class="pa-field-bd">
                    <p-select
                        :model.sync="counterMeasure.countermeasure_id"
                        :options="localCountermeasureOptions"
                        :descriptions="true"
                        :hide-selected-description="true"
                    >
                    </p-select>
                </div>
                <div class="pa-field-ft">
                    <p v-show="missingCMActions.includes(missingIndex + cmIndex)"
                        class="pa-hint pa-hint_error">
                        Please specify an action.
                    </p>
                </div>
            </div>
            <div class="pa-field">
                <div class="pa-field-bd">
                    <label class="pa-option">
                        <input v-model="counterMeasure.needs_approval" type="checkbox" class="pa-option-input" />
                        <span class="pa-option-icon"></span>
                        <span class="pa-option-txt" style=""
                            title="CounterMeasure will not run until it has been approved.">
                            Require Approval
                        </span>
                    </label>
                </div>
            </div>
            <div class="pa-field" v-if="hasSlackApp">
                <div class="pa-field-bd">
                    <label class="pa-option">
                        <input v-model="counterMeasure.slack_output" type="checkbox" class="pa-option-input" />
                        <span class="pa-option-icon"></span>
                        <span class="pa-option-txt" style=""
                            title="CounterMeasure output will be sent to previously notified Slack channels">
                            Send output to Slack
                        </span>
                    </label>
                </div>
            </div>
            <div v-if="!customFilter">
                <button v-if="canAddCountermeasures" v-show="cmIndex === countermeasures.length - 1"
                    class="pa-btn_link pa-txt_medium pa-txt_sm pa-mb-4" style="display: block;"
                    v-on:click="addCounterMeasure">
                    <p-icon icon="plus-circle-outline" color="blue"></p-icon>
                    Add CounterMeasure
                </button>
                <div v-else>
                    <span v-if="showOnsightInstallInstructions" class="pa-txt_xs">
                        <a href="https://docs.fortimonitor.forticloud.com/user-guide/683943/onsight-based-countermeasures"
                            target="_blank"
                        >
                            OnSight CounterMeasures
                        </a> must be enabled.
                    </span>
                    <p-install-agent-link-menu
                        v-else
                        link-text="Monitoring Agent Required"
                        :instance-id="serverId"
                    >
                    </p-install-agent-link-menu>
                </div>
            </div>
        </div>
    </div>
    <div v-show="showAddButton" class="add-countermeasure">
        <button v-if="canAddCountermeasures" class="pa-btn_link pa-txt_medium pa-txt_sm pa-mb-8" style="display: block;"
            v-on:click="addCounterMeasure" type="button">
            <p-icon icon="plus-circle-outline" color="blue"></p-icon>
            Add CounterMeasure
        </button>
        <div v-else>
            <span v-if="showOnsightInstallInstructions" class="pa-txt_xs">
                <a href="https://docs.fortimonitor.forticloud.com/user-guide/683943/onsight-based-countermeasures"
                    target="_blank"
                >
                    OnSight CounterMeasures
                </a> must be enabled.
            </span>
            <p-install-agent-link-menu
                v-else
                link-text="Monitoring Agent Required"
                :instance-id="serverId"
            >
            </p-install-agent-link-menu>
        </div>
    </div>
</div>
</template>

<script>
    import Vue from 'vue';

    const EditCountermeasure = Vue.extend({
        data() {
            return {
                countermeasure_delays: [
                    { "value": "0", "label": "0 minutes" },
                    { "value": "60", "label": "1 minute" },
                    { "value": "120", "label": "2 minutes" },
                    { "value": "300", "label": "5 minutes" },
                    { "value": "900", "label": "15 minutes" },
                    { "value": "1800", "label": "30 minutes" },
                    { "value": "3600", "label": "1 hour" },
                    { "value": "7200", "label": "2 hours" },
                    { "value": "custom", "label": "Custom" },
                ],
                errors: [],
                filterCountermeasures: [],
                localCountermeasureOptions: this.countermeasureOptions,
            };
        },

        props: {
            threshold: {
                type: Object,
                default: () => {
                    countermeasures: []
                }
            },

            thresholdIndex: {
                type: Number,
            },
            customerAckdCmPricing: {
                type: Boolean,
                default: true
            },

            canAddCountermeasures: {
                type: Boolean,
                default: false,
            },

            hasSlackApp: {
                type: Boolean,
                default: false
            },

            countermeasureOptions: {
                type: Array,
                default: () => []
            },

            serverId: {
                type: Number,
                default: null
            },

            customFilter: {
                type: Boolean,
                default: false,
            },

            customFilterId: {
                type: Number,
                default: null
            },

            showOnsightInstallInstructions: {
                type: Boolean,
                default: false,
            }
        },

        computed: {
            hasExistingCountermeasures: function() {
                return this.localCountermeasureOptions
                    && this.localCountermeasureOptions.length;
            },

            missingCMActions: function() {
                var val = [];
                for (var j = 0; j < this.countermeasures.length; j++) {
                    if (this.thresholdIndex) {
                        var key = 'error__threshold_' + this.thresholdIndex + '_countermeasures_' + j + '_countermeasure_id';
                    } else if (this.customFilterId) {
                        var key = 'error__threshold_' + this.customFilterId + '_countermeasures_' + j + '_countermeasure_id';
                    }
                    if (this.errors[key]) {
                        val.push(String(i) + j);
                    }
                }
                return val;
            },

            showAddButton: function() {
                if (this.customFilter) {
                    return true;
                }
                if (!this.threshold.countermeasures.length) {
                    return true;
                }
            },

            countermeasures: function() {
                if (this.threshold) {
                    return this.threshold.countermeasures;
                } else if (this.customFilter) {
                    return this.filterCountermeasures;
                }
            },

            missingIndex: function () {
                if (this.thresholdIndex) {
                    return this.thresholdIndex.toString();
                } else if (this.customerFilter) {
                    return 'custom_filter';
                }
            },

            jsonCountermeasures: function() {
                return JSON.stringify(this.countermeasures);
            }

        },

        methods: {
            close() {
                this.$parent.$parent.$parent.close();
            },
            dismissCMPricing() {
                $.ajax({
                    url: '/config/monitoring/acknowledge_cm_pricing',
                    type: 'POST',
                    context: this,
                })
                    .done(function(data) {
                        if (data.success) {
                            this.customer_ackd_cm_pricing = true;
                        } else {
                            console.error(data);
                        }
                    });
            },

            addCounterMeasure() {
                if (!this.canAddCountermeasures) { return; }
                this.countermeasures.push({
                    countermeasure_id: null,
                    countermeasure_delay: '0',
                    needs_approval: false,
                    slack_output: false
                });
            },

            removeCounterMeasure(index) {
                this.countermeasures.splice(index, 1);
            },

            getFilterCountermeasureData() {
                let data = {
                    server_id: this.serverId,
                    filter_id: this.customFilterId,
                }
                $.ajax({
                    url: '/config/custom_incident/get_countermeasure_data',
                    type: 'GET',
                    context: this,
                    data: data
                }).done(function(data){
                    if (data.success) {
                        Vue.set(this, 'localCountermeasureOptions', data.countermeasure_options);
                        Vue.set(this, 'filterCountermeasures', data.countermeasures);
                    } else {
                        console.log(data.msg);
                    }
                })
            }
        },

        vueReady() {

            if (this.customFilter) {
                this.getFilterCountermeasureData();
            }

        }
    });

    export default EditCountermeasure;
</script>
