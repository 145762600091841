<style>
    .legend-item {
        display: inline-block;
        vertical-align: bottom;
        max-width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    #control-tableRows .pa-select-list .scrollable {
        max-height: inherit;
    }
    .linegraph-widget-wrapper {
        height: 100%;
        display: grid;
        grid-template-rows: 32px auto;
    }
    .linegraph-table-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
    }
    .linegraph-table-wrapper--right {
        grid-template-rows: 1fr;
        grid-template-columns: auto 300px;
    }
    .linegraph-table-wrapper > .pa-tableWrapper {
        margin: 0;
    }
</style>

<template>
    <div :id="id"
        :class="!inModal ? 'grid-stack-item' : 'modal-view'"
        :data-gs-x="localConfig.col.toString()"
        :data-gs-y="localConfig.row.toString()"
        :data-gs-width="localConfig.width.toString()"
        :data-gs-height="localConfig.height.toString()"
        :data-gs-auto-position="false"
        :data-gs-locked="true">
        <div
            :class="!inModal ? 'grid-stack-item-content' : 'modal-view-content'"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div class="linegraph-widget-wrapper">
                <div :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered, 'expand-mode' : !$parent.editing && isHovered && !inModal, 'in-modal': inModal}">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_9of12 pa-align-center" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                            </span>
                            <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: textColor}"><use xlink:href="#spinner-spin-naked"></use></svg>
                            <p-tooltip2 :normal-white-space="true">
                                <span v-if="!isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: textColor }">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span v-if="isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }}</span>
                            </p-tooltip2>
                            <div v-if="graphUnitOptions.length > 0" class="pa-pl-4">
                                <p-graph-unit-select
                                    :graph-id="id"
                                    :graph-unit="graphUnitOptions[0].value"
                                    :options="graphUnitOptions"
                                >
                                </p-graph-unit-select>
                            </div>
                        </div>
                        <div class="pa-grid-col pa-grid-col_3of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger"  v-show="!$parent.editing && isHovered && !inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#arrow-expand"></use>
                                    </svg>
                                </button>
                                <span> Expand </span>
                            </p-tooltip2>
                            <button slot="trigger"  v-show="!$parent.editing && inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                <svg class="pa-icon remove-icon">
                                    <use xlink:href="#close"></use>
                                </svg>
                            </button>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button                                
                                    v-show="showWidgetControls && !inModal"
                                    type="button"
                                    slot="trigger"
                                    @click="cloneWidget"
                                    class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mr-6 pa-mt-6"
                                >
                                    <p-icon icon="copy"></p-icon>
                                </button>
                                <span> Clone this Widget </span>                                
                            </p-tooltip2>
                        </div>
                    </div>
                </div>
                <div
                    v-if="!needsConfig"
                    class="linegraph-table-wrapper"
                    :class="{'linegraph-table-wrapper--right' : floatingRight && isAdvancedLinegraph, 'pa-pt-40': inModal}"
                >
                        <div
                            v-show="content.success && valid"
                            class="widget-body"
                            ref="body"
                            :style="{'height': inModal ? '70vh' : `${getHeight()}px`}"
                        >
                        </div>
                        <p-synthetics-table
                            v-if="isAdvancedLinegraph"
                            :headers="tableHeaders"
                            :data="tableData"
                            :sort-dir="sortDirection"
                            :sort-column="sortColumn"
                            :max-items="localConfig.custom_controls.tableRows"
                            :float-right="floatingRight"
                            :parent-height="chartHeight"
                            :column-order="content.column_order"
                            :inModal="inModal"
                        >
                        </p-synthetics-table>
                </div>
                <div v-if="needsConfig" class="widget-body" style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center;" ref="temp_body">
                    <div class="vertical-center">
                        <button v-if="$parent.allowEditing" @click="configure" class="pa-btn pa-btn_naked_light_grey_no_fill pa-btn_lg" style="padding: 12px 35px" type="button">
                            <svg class="pa-icon">
                                <use xlink:href="#plus-circle-outline"></use>
                            </svg>
                            <span>Configure</span>
                        </button>
                        <p class="widget-unconfigured centered">
                            Please select a metric and instance filter.
                        </p>
                    </div>
                </div>
                <div v-if="!valid" class="widget-body widget-body_error" :title="error_message">
                    <p class="pa-message pa-message_error">
                        <svg class="pa-icon pa-icon_xl"><use xlink:href="#alert-circle"></use></svg>
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
    import Vue from 'vue';
    import widgetMixin from './../widgetMixin';
    import moment from 'moment-timezone';
    import _ from 'lodash';
    const Highcharts = require('highcharts');
    Highcharts.seriesTypes.line.prototype.drawLegendSymbol = Highcharts.seriesTypes.column.prototype.drawLegendSymbol;

    const timeouts = {
        hour: 10,
        '6hr': 30,
        '12hr': 30,
        day: 60,
    };

    const LineGraphWidget = Vue.extend({
        mixins: [widgetMixin],

        data() {
            return {
                content: {
                    graph_type: 'line',
                    title: '',
                    series: [],
                    show_legend: true,
                    zones: [],
                    label_incr_count: 0,
                    success: true,
                },
                chart: null,
                default_colors: [
                    "#3D39BF","#E246EC","#10BCE8","#F25959","#F5AA0D",
                    "#B1B0AF","#39CA89","#5C98E3","#EF9F71","#79869F"
                ],
                rendered: false,
                zoomed: false,
                recreateTimeout: null,
                hiddenOptions: [],
                hoveredPoint: null,
                sortColumn: '',
                sortColumnIndex: -1,
                sortDirection: 'asc',
                selectedGraphUnit: null,
                allHaveMaxValue: false,
                graphUnitOptions: [],
                localConfig: this.config,
                chartHeight: 0,
                inModal: false,
            };
        },

        events: {
            'graph:changeUnit'(data) {
                if (data.id === this.id) {
                    this.selectedGraphUnit = data.unit;

                    Vue.nextTick(() => {
                        this.reconfig();
                    });
                }
            },
        },

        props: {
        },

        computed: {
            isAdvancedLinegraph() {
                return this.localConfig.vue_type === 'p-linegraph-table-widget';
            },
            showTable() {
                return (
                    this.content.success &&
                    this.valid &&
                    Boolean(this.tableData) &&
                    this.isAdvancedLinegraph
                );
            },
            floatingRight() {
                return (
                    Number(this.localConfig.width) > 9 &&
                    this.localConfig &&
                    this.localConfig.format_options.indexOf('float_legend_right') >= 0
                );
            },
            tableHeaders() {
                const defaultHeaders = [
                    { id: 'color', name: '', align: 'left' },
                    { id: 'metric', name: 'Metric', sortable: true, align: 'left' },
                    { id: 'value', name: 'Value', sortable: true, align: 'right' },
                    { id: 'min', name: 'Min', sortable: true, align: 'right' },
                    { id: 'max', name: 'Max', sortable: true, align: 'right' },
                    { id: 'availability', name: 'Availability', sortable: true, align: 'right' },
                    { id: 'incidents', name: 'Incidents', sortable: true, align: 'right' },
                ].map((header) => {
                    let visible = true;

                    if (
                        header.name &&
                        this.content &&
                        this.content.columns &&
                        this.content.columns[header.name]
                    ) {
                        visible = this.content.columns[header.name].visible;
                    }

                    return {
                        ...header,
                        visible: visible,
                    };
                });
                return defaultHeaders;
            },
            tableData() {
                if (
                    !this.chart ||
                    !this.chart.series
                ) {
                    return;
                }

                const minute = 1000 * 60;
                const hour = minute * 60;
                const day = hour * 24;
                const timescaleMap = {
                    '15min': minute * 15,
                    'hour': hour,
                    '6hr': hour * 6,
                    '12hr': hour * 12,
                    'day': day,
                    'custom': 0,
                };

                const availabilities = this.content.availabilities;
                const tableData = this.chart.series.map((_series, index) => {
                    const serverId = _.get(this.content.series[index], 'server_id', null);
                    const applianceId = _.get(this.content.series[index], 'appliance_id', null);
                    const resourceId = _.get(this.content.series[index], 'id', null);
                    const lastPoint = _.last(_series.data);

                    let value = 'N/A';

                    if (this.hoveredPoint && _series.data[this.hoveredPoint.index]) {
                        value = _series.data[this.hoveredPoint.index].y;
                    } else if (lastPoint) {
                        const timeframe = lastPoint.x - timescaleMap[this.content.timescale];

                        if (lastPoint.isNull) {
                            const matchedPoint = _.findLast(_series.data, (point) => {
                                return (
                                    !point.isNull &&
                                    point.x > timeframe
                                );
                            });

                            if (matchedPoint && matchedPoint.y) {
                                value = matchedPoint.y;
                            }
                        } else {
                            value = lastPoint.y;
                        }
                    }

                    let availability = 'N/A';
                    let incidents = 'N/A';

                    if (availabilities && resourceId && availabilities[resourceId]) {
                        availability = availabilities[resourceId].availability + '%';
                        incidents = availabilities[resourceId].outages;
                    }

                    return {
                        index,
                        color: _series.color,
                        value,
                        metric: this.content.aggregation ? 'Aggregated Metric' : _series.name.trim() || '-',
                        min: _series.dataMin,
                        max: _series.dataMax,
                        availability,
                        incidents,
                        visible: _series.visible,
                        serverId,
                        applianceId,
                        resourceId,
                    };
                });

                const sortFunc = row => {
                    const val = row[this.sortColumn.toLowerCase()];
                    if (val === 'N/A') {
                        return 0;
                    }
                    return val;
                };

                return _.orderBy(tableData, sortFunc, this.sortDirection);
            },
        },

        methods: {
            getHeight () {
                if (this.$el) {
                    const widgetHeader = this.$el.querySelector('.widget-header');
                    
                    const widgetHeight = this.$el.clientHeight;
                    const widgetHeaderHeight = widgetHeader ? widgetHeader.clientHeight : 28;
                    const widgetPadding = 10;

                    const syntheticTable = this.$el.querySelector('.synthetics-table');
                    const syntheticTableHeight = syntheticTable ? syntheticTable.clientHeight : 121;
                    const graphHeight = (widgetHeight - widgetHeaderHeight - widgetPadding) - (this.isAdvancedLinegraph && syntheticTableHeight);
                    return graphHeight
                }
               
            },
            expandSelf() {
                this.inModal = !this.inModal
            },
            setSort(column, direction) {
                return {
                    columns: this.content.columns,
                    column_order: this.content.column_order,
                    sort_column: column,
                    sort_direction: direction,
                };
            },
            toggleColumn(column) {
                const visible = this.content.columns[column].visible;
                this.content.columns[column].visible = !visible;
                const toggleColumn = {
                    columns: this.content.columns,
                    column_order: this.content.column_order,
                };
                return toggleColumn;
            },
            getDataPoints() {
                if (!this.tableHeaders) {
                    return [];
                }

                return this.tableHeaders
                    .filter(header => header.name != "Metric")
                    .map(header => header.name)
                    .filter(header => header);
            },
            getConfigModules() {
                return [
                    {type: 'p-overview-module'},
                    {type: 'p-instance-module'},
                    {
                        type: 'p-metric-module',
                        options: {
                            multiple: true,
                            sameUnit: true
                       },
                       open: true
                    },
                    {type: 'p-sorting-module'},
                ]
            },
            getOptionModules() {
                const optionModules = [
                    {
                        type: 'p-custom-module',
                        options: {
                            ...(this.$parent.isScoping() ? {"ignore-scope-module": {}} : {}),
                            "timerange-module": {},
                            "formatting-module": {},
                            title: 'Graph Options',
                            controls: [
                                {
                                    label: 'Show Data Point Labels',
                                    key: 'pointLabels',
                                    type: 'p-switch',
                                    'default': false,
                                },
                                {
                                    label: 'Show Crosshairs',
                                    key: 'crosshairs',
                                    type: 'p-switch',
                                    'default': true,
                                },
                                {
                                    label: 'Aggregate Metrics',
                                    key: 'group_series',
                                    type: 'p-switch',
                                    'default': false,
                                },
                                {
                                    label: 'Metric Calculation',
                                    key: 'aggregation',
                                    type: 'p-select',
                                    options: [
                                        {value: 'average', label: 'Average (Default)'},
                                        {value: 'min', label: 'Min'},
                                        {value: 'max', label: 'Max'},
                                        {value: 'sum', label: 'Sum'}
                                    ],
                                    'default': 'average',
                                },
                                {
                                    label: 'Time Comparison',
                                    key: 'comparison',
                                    type: 'p-select',
                                    options: [{value: '', label: 'None'},
                                        {value: 'yesterday', label: 'Same Time Yesterday'},
                                        {value: 'last_week', label: 'Same Time Last Week'},
                                        {value: 'four_weeks', label: 'Four Weeks Prior'}],
                                },
                                {
                                    id: 'backgroundColor',
                                    label: 'Background Color',
                                    key: 'backgroundColor',
                                    type: 'color-picker',
                                    default: '#ffffff'
                                },
                            ]
                        },
                    },
                ];

                if (this.isAdvancedLinegraph) {
                    const maxTableRows = 20;

                    const customModule = _.find(optionModules,
                        (module) => module.type === 'p-custom-module'
                    );
                    const tableRowControl = {
                        label: 'Table Rows',
                        key: 'tableRows',
                        type: 'p-select',
                        options: Array.from({ length: maxTableRows }, (v, k) => ({
                            value: k+1,
                            label: k+1,
                        })),
                        default: 5,
                        id: 'control-tableRows',
                    };

                    customModule.options.controls.push(tableRowControl);

                    optionModules.unshift({
                        type: 'p-columngroups-module',
                        options: {
                            hideReorder: true,
                        }
                    });
                }

                return optionModules;
            },
            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this);
            },
            initialize(config, content) {
                if (this.$refs.temp_body) {
                    this.$refs.temp_body.style.filter = '';
                } else {
                    this.$refs.body.style.filter = '';
                }
                if (content.success) {
                    this.content = content;
                    this.localConfig = { ...config };
                    
                    if (this.content.aggregation && this.content.series.length == 1) {
                        this.selectedGraphUnit = this.content.series[0].unit;
                    }

                    if (config.custom_controls.sort_column && config.custom_controls.sort_direction) {
                        this.sortColumn = config.custom_controls.sort_column;
                        this.sortDirection = config.custom_controls.sort_direction;
                    }

                    // Give DOM a chance to swap in/out configure messages
                    Vue.nextTick(() => {
                        this.reconfig();
                        const data_points = this.getDataPoints();
                        window.app.rootVue.$broadcast('columngroups:set_groups', data_points, this.localConfig.custom_controls.data_point_config, this.sortColumn, this.sortDirection, this.id);
                    });
                    this.rendered = this.content.success;
                } else if(this.chart){
                    this.chart.series = [];
                }         
            },
            zoomIn(event) {
                this.zoomed = true;
                event.preventDefault();
                const utcstart = (event.xAxis[0].min / 1000);
                const utcend = (event.xAxis[0].max/ 1000);
                const payload = {
                    widget_id: this.id,
                    start_time: utcstart,
                    end_time: utcend,
                };
                $.ajax('/dashboardv2/getZoomData', {
                    method: 'GET',
                    data: payload,
                    context: this,
                })
                .done(function(data) {
                    if (!data.success) {
                        console.log(data.msg);
                        return;
                    }
                    // Do 2 renders here to make it flow right: one to change the series, one to change the bounds
                    const yMax = this.selectedGraphUnit === "%" ? 100 : this.chart.yAxis[0].max;
                    const yMin = this.selectedGraphUnit === "%" ? 0 : this.chart.yAxis[0].min;

                    this.chart.xAxis[0].setExtremes(this.chart.xAxis[0].min, this.chart.xAxis[0].max, false, false);
                    this.chart.yAxis[0].setExtremes(yMin, yMax, false, false);
                    for (let i = 0; i < data.series.length; i++) {
                        const newSeries = data.series[i];
                        const match = this.chart.series.findIndex(s => s.options.id === newSeries.id);

                        let chartData = [];

                        if (this.selectedGraphUnit && this.selectedGraphUnit === "%") {
                            chartData = newSeries.data.map(
                                ([timestamp, value]) => ([
                                    timestamp,
                                    Boolean(value)
                                        ? (value / this.maxValue) * 100
                                        : value
                                ])
                            );
                        } else {
                            chartData = [...newSeries.data];
                        }

                        if (match > -1) {
                            this.chart.series[match].setData(chartData, false);
                        }
                    }
                    this.chart.redraw(true);
                    const _yMax = this.selectedGraphUnit === "%" ? 100 : data.max;
                    const _yMin = this.selectedGraphUnit === "%" ? 0 : data.min;
                    this.chart.xAxis[0].setExtremes(data.xmin, data.xmax, false, false);
                    this.chart.yAxis[0].setExtremes(_yMin, _yMax, false, false);
                    this.chart.redraw(true);
                    this.chart.showResetZoom();
                });
            },
            requestRecreate() {
                const timeout = timeouts[this.content.timescale];
                if (!timeout) { return; }
                console.log(`${timeout}min have passed. Performing scheduled refresh`);
                this.$parent.requestRecreate(this.id);
            },
            reconfig() {
                const self = this;

                if (this.content.timescale &&
                    this.content.timescale !== this.localConfig.timescale) {
                    $(this.$refs.timeOverride).show();
                } else {
                    $(this.$refs.timeOverride).hide();
                }

                if (this.reloadTimer) {
                    window.clearTimeout(this.reloadTimer);
                }
                const timeout = timeouts[this.content.timescale];
                if (timeout) {
                    if (this.recreateTimeout) {
                        clearInterval(this.recreateInterval);
                    }
                    this.recreateTimeout = window.setTimeout(this.requestRecreate, Number(timeout) * 60000);
                }

                let isAllPercentAxis = true;
                let same_unit_resources_y = true;
                let first_resource_unit = null;
                for (let i = 0; i < this.content.series.length; i++) {
                    if (!this.content.series[i].unit) {
                        // Highcharts requires a unit. If we don't have one, use a space.
                        this.content.series[i].unit = ' ';
                    }
                    if (this.content.series[i].unit.toLowerCase() !== 'percent' && this.content.series[i].unit !== '%') {
                        isAllPercentAxis = false;
                    }
                    if (first_resource_unit == null) {
                        first_resource_unit = this.content.series[i].unit;
                    }
                    if (this.content.series[i].unit !== first_resource_unit) {
                        same_unit_resources_y = false;
                    }
                }

                let colors = this.default_colors;
                if ('series_colors' in this.localConfig.custom_controls && this.localConfig.custom_controls.series_colors.length) {
                    colors = this.localConfig.custom_controls.series_colors.split(',');
                }

                const allHaveMaxValue = (
                    this.content.series && this.content.series.every((value) => Boolean(value.max_value))
                );

                this.allHaveMaxValue = allHaveMaxValue;

                if (allHaveMaxValue) {
                    this.graphUnitOptions = [...new Set(
                        this.content.series
                            .map(value => value.unit)
                            .filter(unit => unit && unit.toLowerCase() !== "percent" && unit !== "%")
                    )].map(unit => ({ label: unit, value: unit }));
                }

                // Set comparison series to be .9 opacity of regular series' color
                const compSeries = this.content.series.filter(s => {
                    return s.comparison;
                });
                compSeries.forEach(comp => {
                    const nameComponents = comp.name.split(' - ');
                    const name = nameComponents[nameComponents.length - 1];
                    const matchIndex = this.content.series.findIndex(s => {
                        return s.name === name;
                    });
                    if (matchIndex > -1) {
                        const origColor = this.default_colors[matchIndex];
                        const compColor = hexToRgba(origColor, .9);
                        // eslint-disable-next-line no-param-reassign
                        comp.color = compColor;
                    }
                });

                let showCrosshairs = this.localConfig.custom_controls.crosshairs;
                if (typeof showCrosshairs === 'undefined') {
                    showCrosshairs = true;
                }

                let timezone = this.$parent.timezone;//window.userTZ;
                if(timezone == "UTC/UTC"){
                    timezone = "UTC";
                }
                if (!timezone) {
                    const guessedTimezone = moment.tz.guess();

                    if (guessedTimezone) {
                        timezone = guessedTimezone;
                    }
                }
                if (typeof timezone !== 'undefined') {
                    Highcharts.setOptions({
                        global: {
                            timezone: timezone,
                        },
                    });
                }

                const widgetHeader = this.$el.querySelector('.widget-header');
                const widgetHeight = this.$el.clientHeight;
                const widgetWidth = this.$el.clientWidth;
                const widgetHeaderHeight = widgetHeader ? widgetHeader.clientHeight : 28;
                const widgetPadding = 10;

                let zoomType = 'x';
                if (this.localConfig && this.localConfig.timescale === '15min') {
                    // Disable zooming
                    zoomType = '';
                }
                if (this.$parent.timescale_override
                        && !['none', '15min'].includes(this.$parent.timescale_override)) {
                    // Allow zooming if global timescale > 15min
                    zoomType = 'x';
                }
                let itemWidth = null;

                let legendAlign = 'left';
                let legendVerticalAlign = 'bottom';
                let legendLayout = 'horizontal';
                let legendMaxHeight = 50;

                let legendStyle = {
                            fontWeight: 'normal',
                            textOverflow: null,
                            overflow: 'hidden',
                            width: null,
                };

                if (this.floatingRight) {
                    legendAlign = 'right';
                    legendVerticalAlign = 'middle';
                    legendLayout = 'vertical';
                    legendMaxHeight = undefined;
                    itemWidth = 160;
                    legendStyle = {
                        fontWeight: 'normal',
                        fontSize: '11px',
                        overflow: 'hidden',
                        width: '100px',
                        textOverflow: 'ellipsis',
                    };
                }

                const availableWidgetBodyHeight = (widgetHeight - widgetHeaderHeight - widgetPadding);

                if (!this.isAdvancedLinegraph) {
                    this.$refs.body.style.height = `${availableWidgetBodyHeight}px`;
                }

                // Deep copy the series so we keep the original
                let series = JSON.parse(JSON.stringify(this.content.series));

                if (this.selectedGraphUnit === "%") {
                    if (this.content.show_unit) {
                        series = series.map((item) => ({
                            ...item, name: Boolean(true) && !item.name.includes(`(${this.selectedGraphUnit})`) ? this.updateName(item.name, this.selectedGraphUnit) : item.name
                        }));
                    }
                                       
                    
                    series = series.map(
                        (item) => ({
                            ...item,
                            data: item.data.map(([timestamp, value]) => ([
                                timestamp,
                                (Boolean(value) && Boolean(item.max_value))
                                    ? (value / item.max_value) * 100
                                    : value
                            ]))
                        })
                    );
                }

                this.chart = new Highcharts.Chart({
                    chart: {
                        type: 'line',
                        backgroundColor: null,
                        borderColor: null,
                        plotShadow: false,
                        animation: true,
                        zoomType: zoomType,
                        renderTo: this.$refs.body,
                        spacingLeft: 0,
                        events: {
                            selection: function(event) {
                                if (event.resetSelection) {
                                    this.zoomed = false;
                                    event.preventDefault();
                                    window.setTimeout(() => {
                                        this.chart.destroy();
                                        this.reconfig();
                                    }, 50);
                                } else {
                                    this.zoomIn(event);
                                }
                            }.bind(this),
                        },
                    },
                    colors: colors,
                    credits: {
                        enabled: false,
                    },
                    title: {
                        text: null,
                    },
                    exporting: {
                        enabled: false,
                    },
                    legend: {
                        symbolHeight: 8,
                        symbolWidth: 8,
                        symbolRadius: 8,
                        enabled: !this.isAdvancedLinegraph ? this.content['show_legend'] : false,
                        verticalAlign: legendVerticalAlign,
                        padding: 12,
                        align: legendAlign,
                        layout: legendLayout,
                        floating: false,
                        itemStyle: legendStyle,
                        // 20px for series marker
                        itemWidth: itemWidth,
                        useHTML: false,
                        maxHeight: legendMaxHeight,
                        labelFormatter: function() {
                            const yData = this.yData;
                            const yDataLength = this.yData ? this.yData.length : 0;
                            const name = this.name;
                            let y = 0.0;
                            if (this.chart.hoverPoint) {
                                y = this.point.y;
                                if (typeof y === 'undefined' || y === null) {
                                    y = 0.0;
                                }
                            } else if (yData && yDataLength) {
                                y = yData[yDataLength - 1];
                            }
                            if (typeof(y) === 'number') {
                                y = y.toFixed(2);
                            } else {
                                y = 0.0;
                            }
                            return `<span>${name}:</span> <b style="font-weight: bold">${y}</b>`;
                        },
                    },

                    tooltip: {
                        shadow: {
                            color: 'rgb(62, 64, 65)',
                            opacity: 0.1,
                            offsetX: 0,
                            offsetY: 1,
                            width: 4,
                        },
                        backgroundColor: '#ffffff',
                        borderWidth: 0,
                        borderRadius: 5,
                        padding: 6,
                        useHTML: true,
                        enabled: true,
                        split: false,
                        headerFormat: '',
                        formatter: function() {
                            const hasDec = (this.y % 1) !== 0;
                            let y = this.y;
                            if (hasDec) {
                                y = y.toFixed(2);
                            }
                            const ret = `
                            <div class='pa-flex'>
                                <span style='font-size: 22px; color: ${this.series.color}; line-height: 11px;'>
                                    \u25CF
                                </span>
                                <div style='padding-left: 6px; padding-right: 20px;'>
                                    <p class='pa-txt_medium' style='color: #3c3d3e; margin-bottom: 4px;'>
                                        ${this.point.series.name.replace("\n", " ")}: ${y}
                                    </p>
                                    <p class='pa-txt_normal' style='color: #95999b; font-size: 11px;'>
                                        ${Highcharts.dateFormat("%m/%d/%Y %H:%M", new Date(this.x))}
                                    </p>
                                </div>
                            </div>
                            `;
                            return ret;
                        },
                        dateTimeLabelFormats: {
                            millisecond: '%m/%d/%Y %H:%M',
                            second: '%m/%d/%Y %H:%M',
                            minute: '%m/%d/%Y %H:%M',
                            hour: '%m/%d/%Y %H:%M',
                            day: '%m/%d/%Y %Y',
                            week: 'Week from %A, %b %e, %Y',
                            month: '%B %Y',
                            year: '%Y',
                        },
                    },

                    xAxis: {
                        type: 'datetime',
                        lineWidth: 1,
                        lineColor: '#ddd',
                        tickLength: 0,
                        // labels: {
                        //     useHTML: true,
                        //     align: "center",
                        //     style: {
                        //         textAlign: "center"
                        //     },
                        // },
                        plotBands: this.content.zones,
                        tickInterval: null,
                        // minorTickInterval: this.content['label_incr_count'] * 60 * 1000,
                        minTickInterval: 60 * 1000,
                        startOnTick: false,
                        endOnTick: false,
                        minPadding: 0,
                        maxPadding: 0,
                        crosshair: {
                            dashStyle: 'shortdot',
                            width: showCrosshairs && 1.5 || 0,
                            color: '#999999',
                            snap: true,
                        },
                    },
                    yAxis: {
                        max: isAllPercentAxis ? 100 : null,
                        gridLineColor: '#e6e6e6',
                        tickWidth: 1,
                        tickLength: 0,
                        lineWidth: 0,
                        gridLineColor: '#BDBDBD',
                        gridLineDashStyle : 'Dot',
                        lineColor: '#ddd',
                        title: {
                            style: {
                                fontSize: 11,
                            },                            
                            text: this.selectedGraphUnit,
                            margin: 5,
                        },
                    },
                    plotOptions: {
                        series: {
                            marker: {
                                enabled: false,
                                states: {
                                    hover: {
                                        enabled: false,
                                    },
                                },
                            },
                            states: {
                                hover: {
                                    lineWidthPlus: 0,
                                },
                            },
                            point: {
                                events: {
                                    mouseOver: function(e) {
                                        if (e && e.target) {
                                            self.hoveredPoint = Object.assign({}, e.target);
                                        }
                                        try {
                                            const thisChart = e.target.series.chart;
                                            for (let i = 0; i < Highcharts.charts.length; i++) {
                                                const mChart = Highcharts.charts[i];
                                                if (typeof mChart === 'undefined') {
                                                    // This happens if chart is reconstructed
                                                    continue;
                                                }
                                                if (!mChart.options.legend.enabled) {
                                                    continue;
                                                }
                                                if (window.dashboard.disableCrosshairSync &&
                                                        mChart !== thisChart) {
                                                    continue;
                                                }
                                                let found = false;
                                                if (e.target.x > mChart.xAxis[0].min
                                                        && e.target.x < mChart.xAxis[0].max) {
                                                    for (let j = 0; j < mChart.series.length; j++) {
                                                        const data = mChart.series[j].data;
                                                        if (!data || !data.length) { continue; }
                                                        let low = 0;
                                                        let high = data.length;
                                                        while (low <= high) {
                                                            const mid = Math.floor((low + high) / 2);
                                                            if (mid > data.length) {
                                                                break;
                                                            }
                                                            if (!data[mid]) {
                                                                low = mid + 1;
                                                                continue;
                                                            }
                                                            if (data[mid].x > e.target.x + 60000) {
                                                                high = mid - 1;
                                                            } else if (data[mid].x <
                                                                    e.target.x - 60000) {
                                                                low = mid + 1;
                                                            } else {
                                                                // TODO: Investigate highcharts-legend-plugin.js file, I think
                                                                // that is the source of the custom highlight function.
                                                                // Commenting this out for now to stop all the errors from being thrown.
                                                                // data[mid].highlight();
                                                                found = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                }
                                                if (!found) {
                                                    if (typeof mChart.hoverPoint !== 'undefined'
                                                            && mChart.hoverPoint !== null) {
                                                        mChart.hoverPoint.setState('');
                                                        mChart.xAxis[0].hideCrosshair();
                                                        mChart.yAxis[0].hideCrosshair();
                                                    }
                                                }
                                            }
                                        } catch (error) {
                                            console.error(error);
                                        }
                                    },
                                },
                            },
                            events: {
                                mouseOut: function(e) {
                                    if (e && e.target) {
                                        self.hoveredPoint = null;
                                    }
                                    try {
                                        if (window.dashboard.disableCrosshairSync) {
                                            return;
                                        }

                                        for (let i = 0; i < Highcharts.charts.length; i++) {
                                            const mChart = Highcharts.charts[i];
                                            if (typeof mChart === 'undefined') {
                                                // This happens if chart is reconstructed
                                                continue;
                                            }
                                            if (mChart === this.chart) { continue; }
                                            if (typeof mChart.hoverPoint !== 'undefined'
                                                    && mChart.hoverPoint !== null) {
                                                mChart.xAxis[0].hideCrosshair();
                                                mChart.yAxis[0].hideCrosshair();
                                                mChart.hoverPoint.onMouseOut();
                                                mChart.tooltip.hide();
                                            }
                                        }

                                        window.setTimeout(() =>  {
                                            for (let i = 0; i < Highcharts.charts.length; i++) {
                                                const mChart = Highcharts.charts[i];
                                                if (mChart && mChart.legend && mChart.legend.enabled
                                                        && mChart.options.chart.type  === 'line') {
                                                    mChart.legend.render();
                                                }
                                            }
                                        }, 200);
                                    } catch (error) {
                                        console.error(error);
                                    }
                                },
                            },
                        },
                        areaspline: {
                            fillOpacity: this.content.graph_type,
                        },
                        line: {
                            dataLabels: {
                                enabled: this.localConfig.custom_controls.pointLabels,
                                formatter: function() {
                                    return this.y.toFixed(2);
                                },
                            },
                            lineWidth: 1.25,
                            shadow: false,
                            connectNulls: this.localConfig.custom_controls.should_connect_nulls || false
                        },
                    },

                    series: series,
                });

                if (this.chart && this.chart.legend && this.chart.legend.enabled) {
                    this.chart.legend.render();
                }

                // Update our part of the global series count
                this.updateSeriesCount();

                setTimeout(() => {
                    const chartHeight = this.chart.chartHeight;
                    this.chartHeight = chartHeight;
                }, 250);
            },

            updateName(name, unit) {        
                let nameWithoutUnits = name.split('(');
                nameWithoutUnits.pop();
                nameWithoutUnits = nameWithoutUnits.join('(');
                name = `${nameWithoutUnits} (${unit})`;
                return name                
            },

            update(content) {
                if (!content.success) {
                    return;
                }
                const debug = this.$parent.debug;
                if (debug) {
                    console.log(`Widget ${this.localConfig.title} updating:`);
                }
                let oldestRemoved = Infinity;
                let newMin = Infinity;
                seriesLoop: for (let i = 0; i < content.series.length; i++) {
                    // Check if this is a series we already have
                    const updatedSeries = content.series[i];
                    let matchingSeries = this.content.series.find(s => {
                        return s.id === updatedSeries.id
                            && s.comparison === updatedSeries.comparison;
                    });

                    // Gather some data on the existing series
                    let lastTime;
                    let lastVal;
                    let closestRange;
                    let highchartsSeries;
                    if (matchingSeries) {
                        // Get the matching series that Highcharts has
                        highchartsSeries = this.chart.series.find(s => {
                            return s.options.id === matchingSeries.id &&
                                s.options.comparison === matchingSeries.comparison;
                        });
                        if (highchartsSeries && highchartsSeries.data.length) {
                            lastTime = highchartsSeries.data[highchartsSeries.data.length - 1].x;
                            lastVal = highchartsSeries.data[highchartsSeries.data.length - 1].y;
                            closestRange = highchartsSeries.closestPointRange;
                            if (!closestRange) {
                                closestRange = 0;
                            }
                        } else if (highchartsSeries) {
                            // No data in this series yet
                            lastTime = 0;
                            lastVal = 0;
                            closestRange = 0;
                        } else {
                            console.log('ERROR: Series ${matchingSeries.name} not in Highcharts');
                            // Treat as new
                            matchingSeries = null;
                        }

                        if (debug) {
                            console.log(`Updating series ${content.series[i].name}:`);
                            const lastTimeDate = new Date(lastTime);
                            console.log(`\tLast point time: ${lastTimeDate}`);
                            console.log(`\tClosest point range: ${closestRange}`);
                            console.log(`\tNew data length: ${content.series[i].data.length}`);
                        }
                    } else {
                        lastTime = 0;
                        lastVal = 0;
                        closestRange = 0;
                        if (debug && updatedSeries.data.length) {
                            console.log(`Adding new series ${content.series[i].name}:`);
                        }
                    }

                    seriesDataLoop: for (let j = 0; j < content.series[i].data.length; j++) {
                        const newTime = content.series[i].data[j][0];
                        const newVal = content.series[i].data[j][1];
                        // Only add a point if it's sufficiently newer than our last known point
                        if (newTime > lastTime && newTime - lastTime > closestRange - 30000) {
                            if (newVal === null) {
                                if (this.$parent.debug) {
                                    const newTimeDate = new Date(newTime);
                                    console.log(`\tNot adding null at time: ${newTimeDate}`);
                                }
                                continue;
                            }

                            if (matchingSeries) {
                                // Remove any trailing nulls
                                // Otherwise it'll leave a gap
                                if (lastVal === null) {
                                    let removedCount = 0;
                                    let k = matchingSeries.data.length - 1;
                                    while (matchingSeries.data[k][1] === null) {
                                        matchingSeries.data.splice(k, 1);
                                        highchartsSeries.removePoint(k);
                                        removedCount += 1;
                                        k -= 1;
                                    }
                                    if (this.$parent.debug) {
                                        console.log(`\tTrimmed ${removedCount} nulls from end.`);
                                    }
                                }

                                const removedPoint = matchingSeries.data[0];
                                let possibleMin = Infinity;
                                if (matchingSeries.data.length > 1) {
                                    possibleMin = matchingSeries.data[1][0];
                                }
                                if (possibleMin < newMin) {
                                    newMin = possibleMin;
                                }
                                if (this.$parent.debug) {
                                    const newTimeDate = new Date(newTime);
                                    console.log(`\tAdding point: ${newTimeDate}\t\t${newVal}`);
                                    console.log(`\tRemoving point: ${new Date(removedPoint[0])}\t\t${removedPoint[1]}\n`);
                                }

                                // Check if our new update would change the unit completely. If it does, redraw the
                                // whole graph.
                                if (content.series[i].unit != matchingSeries.unit) {
                                    this.$parent.requestRecreate(this.id);
                                    return;
                                }

                                // Update our local copy
                                matchingSeries.data.push(content.series[i].data[j]);
                                matchingSeries.data.shift();

                                // Finally add point to Highcharts
                                if (!this.zoomed) {
                                    highchartsSeries.addPoint(content.series[i].data[j], false, false);
                                }
                            } else {
                                // New series
                                this.chart.addSeries(updatedSeries, false);
                                this.content.series.push(updatedSeries);
                                continue seriesLoop;
                            }
                        } else if (this.$parent.debug) {
                            console.log(`Discarding point with timestamp ${newTime}. Last time was ${lastTime}.`);
                        }
                    }
                }
                if (newMin !== Infinity) {
                    const minTimeDate = new Date(newMin);
                    if (debug) {
                        console.log(`Setting new min to ${minTimeDate}`);
                    }
                    if (!this.zoomed) {
                        this.chart.xAxis[0].setExtremes(newMin);
                    }
                    this.chart.series.forEach(s => {
                        const data = s.data;
                        if (!data.length) {
                            return;
                        }
                        let x = data[0].x;
                        while (x < newMin) {
                            if (debug) {
                                console.log(`Removed point from ${s.name}`);
                            }
                            s.removePoint(0, false);
                            x = s.data[0];
                        }
                    });
                    this.content.series.forEach(s => {
                        const data = s.data;
                        if (!data.length) {
                            return;
                        }
                        let x = data[0][0];
                        while (x < newMin) {
                            if (debug) {
                                console.log(`Removed point from ${s.name}`);
                            }
                            s.data.splice(0, 1);
                            if (s.data[0]) {
                                x = s.data[0][0];
                            } else {
                                x = newMin + 1;
                            }
                        }
                    });
                }

                // Remove any series that no longer exist
                for (let i = 0; i < this.chart.series.length; i++) {
                    const series = this.chart.series[i];
                    const match = content.series.find(s => {
                        return series.options.id === s.id &&
                            series.options.comparison === s.comparison;
                    });
                    if (!match) {
                        series.remove(false);
                    }
                }

                if (content.zones.length) {
                    const bands = this.chart.xAxis[0].plotLinesAndBands;
                    bands.forEach(e => {
                        e.destroy();
                    });
                    content.zones.forEach($.proxy(function(e) {
                        this.chart.xAxis[0].addPlotBand(e);
                    }, this));
                }

                if (this.chart && this.chart.legend && this.chart.legend.enabled) {
                    this.chart.legend.render();
                }

                this.chart.redraw();

                // Update our part of the global series count
                this.updateSeriesCount();
            },
            updateSeriesCount() {
                const count = this.content.series.length;
                if (this.inModal) {
                    this.$parent.$parent.setSeriesCount(this.id, count)
                } else {
                    this.$parent.setSeriesCount(this.id, count) 
                }
                    
            },
            dumpState() {
                function censor(key, value) {
                    if (key === 'chart') {
                        return undefined;
                    }
                    return value;
                }
                const obj = Object();
                obj.data = this.$data;
                obj.config = {...this.config};
                console.log(JSON.stringify(obj, censor));
            },
        },
        watch: {
            "tableData": function(curr, prev) {
                if (curr && (!prev || (curr.length !== prev.length))) {
                    this.chart.reflow();
                }
            }
        },
        mounted() {
            this.pendNewConfig();
            if (this.id < 0) { this.configure(); };
        },
    });


    function hexToRgba(inHex,opacity) {
        if (!inHex) { return; }

        let hex = inHex;
        hex = hex.replace('#', '');
        const r = parseInt(hex.substring(0,2), 16);
        const g = parseInt(hex.substring(2,4), 16);
        const b = parseInt(hex.substring(4,6), 16);

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }

    export default LineGraphWidget;
</script>
