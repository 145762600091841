<template>
    <li class="pa-navigation-action_menu-item">
        <p-navigation-action-item
            :id="id"
            :action="action"
            :text="text"
            :href="href"
            :to="to"
            :left-icons="leftIcons"
            :right-icons="rightIcons"
            :is-collapsed="isCollapsed"
            :badge="badge"
            :exact-path="exactPath"
            :active="active"
            :secondary-active="secondaryActive"
            :button="button"
            @item:active="handleItemActive"
        >
        </p-navigation-action-item>
    </li>
</template>

<script setup>
import PNavigationActionItem from "./NavigationActionItem.vue";

defineProps({
    id: String,
    action: String,
    text: String,
    href: String,
    to: String,
    leftIcons: Array,
    rightIcons: Array,
    isCollapsed: Boolean,
    badge: Object,
    exactPath: Boolean,
    active: Boolean,
    secondaryActive: Boolean,
    button: Object,
});

const emit = defineEmits(["item:active"]);

const handleItemActive = (data) => {
    emit('item:active', data);
};
</script>
