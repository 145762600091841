import DOM from './DOM';
const DEFAULT_OFFSET = 0;
const MIN_PADDING = 10;

const unitify = num => {
    return `${num}px`;
};

function getXDirection(element, options) {
    // Get dom-related sizing
    const viewportWidth = DOM.getViewportWidth();
    const clientRect = element.getBoundingClientRect();

    // Grab relevant options
    const preferred = options.preferredXDirection;
    const popupWidth = options.popupWidth;

    if ((clientRect.right + popupWidth) >= viewportWidth) {
        return 'right';
    }

    if ((clientRect.left - popupWidth) <= 0) {
        return 'left';
    }

    return preferred;
}

function getPopupXStyle(element, options) {
    const viewportWidth = DOM.getViewportWidth();
    const clientRect = element.getBoundingClientRect();

    const direction = getXDirection(element, options);
    const position = clientRect[direction];

    let resultCSS = direction === 'left' ? position : viewportWidth - position;
    resultCSS = unitify(resultCSS);

    return {
        [direction]: resultCSS,
    };
}

function getYDirection(element, options) {
    // Get dom-related sizing
    const viewportHeight = DOM.getViewportHeight();
    const clientRect = element.getBoundingClientRect();

    // Grab relevant options
    const preferred = options.preferredYDirection;
    const minHeight = options.minHeight;
    const popupHeight = options.popupHeight;
    if (options.shrinkable) {
        // Get shrinkable y direction
        if (preferred === 'bottom') {
            const canFitBottom = (clientRect.top + minHeight) > (0 + MIN_PADDING);
            if (canFitBottom) {
                return 'bottom';
            }
            return 'top';
        }
        if (preferred === 'top') {
            const canFitTop = (clientRect.bottom + minHeight) < (viewportHeight - MIN_PADDING);
            if (canFitTop) {
                return 'top';
            }
            return 'bottom';
        }
    } else {
        const percentage = (clientRect.top + clientRect.height) / viewportHeight;
        // Get y direction
        if ((clientRect.bottom + popupHeight) >= viewportHeight) {
            return 'bottom';
        }

        if ((clientRect.top - popupHeight) <= 0) {
            return 'top';
        }

        return preferred;
    }
}

function getPopupYStyle(element, options) {
    const viewportHeight = DOM.getViewportHeight();
    const clientRect = element.getBoundingClientRect();

    const height = clientRect.height;

    const direction = getYDirection(element, options);
    const position = clientRect[direction];

    let resultCSS = direction === 'top'
            ? (position + height) + DEFAULT_OFFSET
            : ((viewportHeight - position) + height) + DEFAULT_OFFSET;
    resultCSS = unitify(resultCSS);

    const result = {
        [direction]: resultCSS,
    };

    if (options.shrinkable) {
        let fittedHeight = direction === 'top'
                ? viewportHeight - (position + height + MIN_PADDING)
                : (position - height) - MIN_PADDING;
        if (options.maxHeight) {
            fittedHeight = Math.min(fittedHeight, options.maxHeight);
        }
        fittedHeight = unitify(fittedHeight);
        result['max-height'] = fittedHeight;
        if (options.minHeight) {
            result['min-height'] = options.minHeight;
        }
    }

    return result;
}

function getPopupStyle(element, optionsIn) {
    const options = {
        popupWidth: 0,
        popupHeight: 0,
        preferredXDirection: 'left',
        preferredYDirection: 'top',
        shrinkable: true,
        minHeight: 200,
        maxHeight: null,
    };
    Object.assign(options, optionsIn);

    const x = getPopupXStyle(element, options);
    const y = getPopupYStyle(element, options);

    return { ...x, ...y };
}

// Returns styling for child popups
function getSubPopupStyle(element, optionsIn) {
    const options = {
        popupWidth: 0,
        popupHeight: 0,
        preferredXDirection: 'left',
        preferredYDirection: 'top',
        shrinkable: true,
        minHeight: 200,
        maxHeight: null,
    };
    Object.assign(options, optionsIn);

    const viewportWidth = DOM.getViewportWidth();
    const viewportHeight = DOM.getViewportHeight();
    const clientRect = element.getBoundingClientRect();
    const xDirection = getXDirection(element, options);

    const percentage = (clientRect.top + clientRect.height) / viewportHeight;
    const yDirection = getYDirection(element, options);

    let maxHeight = null;
    if (options.shrinkable) {
        const position = clientRect[yDirection];
        let fittedHeight = yDirection === 'top'
                ? viewportHeight - (position + clientRect.height + MIN_PADDING)
                : (position - clientRect.height) - MIN_PADDING;
        if (options.maxHeight) {
            fittedHeight = Math.min(fittedHeight, options.maxHeight);
        }
        fittedHeight = unitify(fittedHeight);
        maxHeight = fittedHeight;
    } else {
        const yClientRectTop = clientRect.top;
        const maxHeightOffset = yDirection === 'top'
            ? yClientRectTop + clientRect.height
            : viewportHeight - yClientRectTop;
        maxHeight = `calc(100vh - ${maxHeightOffset}px)`;
    }

    return {
        maxHeight,
        [xDirection]: '100%',
        [yDirection]: '0',
    };
}

export default {
    getPopupStyle,
    getSubPopupStyle,
};
