<template>
    <div class="pa-navigation-action pa-navigation-action__tab">
        <button @click="handleClick" class="pa-navigation-action__tab-button">
            <div class="pa-navigation-action__icons pa-navigation-action__icons--left">
                <p-icon
                    v-for="icon in leftIcons"
                    :key="icon"
                    :icon="icon"
                    color="silver"
                    :size="[isCollapsed && 'xxl']"
                >
                </p-icon>
            </div>
            <span class="pa-navigation-action__text" :title="text">
                {{ text }}
            </span>
            <div class="pa-navigation-action__icons pa-navigation-action__icons--right">
                <p-icon
                    v-for="icon in rightIcons"
                    :key="icon"
                    :icon="icon"
                    color="silver"
                >
                </p-icon>
                <span v-if="badge && badge.html" class="pa-navigation-action-item__badge">
                <p-badge
                    v-bind="badge.props"
                    v-html="badge.html"
                >
                </p-badge>
            </span>
            </div>
        </button>
    </div>
</template>

<script setup>
const props = defineProps({
    id: String,
    text: String,
    leftIcons: Array,
    rightIcons: Array,
    isCollapsed: Boolean,
    badge: Object,
});

const emit = defineEmits(['item:active']);

const handleClick = () => {
    emit('item:active', { id: props.id });
};
</script>
