var COUNT = 0;

var dialogMixin = {
    props: {
        id: {
            type: String,
            default: 'dialog_' + (COUNT++)
        },

        isActive: {
            type: Boolean,
            default: false
        }
    },

    data: function() {
        return {
            localIsActive: this.isActive,
        };
    },

    methods: {
        open() {
            this.localIsActive = true;
        },

        close() {
            this.localIsActive = false;
        },

        toggle() {
            if (this.localIsActive) {
                this.close();

                return;
            }

            this.open();
        }
    },

    events: {
        'dialog:open': function(id) {
            if (this.id !== id) {
                return;
            }

            this.open();
        },

        'dialog:close': function(id) {
            if (this.id !== id) {
                return;
            }

            this.close();
        },

        'dialog:reload-page': function(id) {
            if (this.id !== id) {
                return;
            }

            location.reload();
        }
    }
};

module.exports = dialogMixin;
