const clickListeners = {
    'span.pa-badge_tag': function(event) {
        if (!window.app || !window.app.rootVue) { return; }

        if (event.target.className.includes('tag-no-listener')) {
            return;
        }

        let tagName = '';
        const tagId = event.target.id;
        if (tagId && tagId.startsWith('tag-')) {
            tagName = tagId.substr('tag-'.length);
        } else {
            tagName = event.target.innerText;
        }
        if (!tagName) { return; }

        const url = `/config/TagUsage?tag_name=${tagName}`;

        window.app.rootVue.$broadcast('drawer:load', {
            id: 'dynamic-drawer',
            url: url,
            anonymous: false,
            width: 350,
        });
        window.app.rootVue.$broadcast('drawer:open', 'dynamic-drawer');
    },
};

function eventMatchesSelector(event, selector) {
    return event.target.matches(selector);
}

export function bindGlobalClickListeners() {
    for (const prop in clickListeners) {
        if (clickListeners.hasOwnProperty(prop)) {
            const selector = prop;
            const handler = clickListeners[selector];

            window.addEventListener('click', event => {
                if (eventMatchesSelector(event, prop)) {
                    handler(event);
                }
            });
        }
    }
}
