import { requests } from "../requests";

export const getCustomerSupportData = () => {
    return requests.get('/onboarding/getCustomerSupportData');
};

export const getTemplates = ({ device_type }) => {
    return requests.get('/onboarding/getTemplates', { params: { device_type } });
};

export const checkPrivateIPs = ({ fqdns }) => {
    return requests.post('/onboarding/checkPrivateIPs', null, { params: { fqdns }});
};

export const validateFqdns = ({ fqdns }) => {
    return requests.post('/onboarding/validateFqdns', null, { params: { fqdns }});
};

export const addNewServers = ({
    public_ips,
    private_ips,
    label,
    tags,
    parent_group,
    new_group_name,
    templates,
    monitoring_location,
    private_location,
    notification_timeline,
    device_type,
    new_obf,
    new_customer,
}) => {
    return requests.post('/onboarding/addNewServers', null, { params: {
        public_ips,
        private_ips,
        label,
        tags,
        parent_group,
        new_group_name,
        templates,
        monitoring_location,
        private_location,
        notification_timeline,
        device_type,
        new_obf,
        new_customer,
    } });
};

export const getDevicePorts = ({server_id}) => {
    return requests.get('/onboarding/getDevicePorts', {params:{server_id}});
};