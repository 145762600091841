
<template>
    <p-input
        placeholder="Search"
        style="height: 32px;"
        theme="search"
        v-on:input="debounceSearch"
        v-model="searchModel"
    >
    </p-input>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';

export default Vue.extend({
    props: {
        table: Object,
        broadcast: {
            type: Boolean,
            default: false,
        },
    },
    data: function() {
        return {
            searchModel: null,
        }
    },
    methods: {
        debounceSearch: _.debounce(function () {
            if(this.table) {
                this.table.search(this.searchModel);
            }
            if(this.broadcast) {
                this.eventHub.$emit('custom-search-changed', this.searchModel);
            }
        }, 500),

        getSearchValue: function() {
            return this.searchModel;
        },
    }
});
</script>
