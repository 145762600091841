<style lang="scss">

    #incident-lead-component-wrapper {
        .incident-lead-container--no-lead {
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }
        }

        .add-incident-lead-btn {
            color: #3c3d3e;

            &:hover {
                text-decoration: underline;
            }
        }
    }

</style>

<template>
	<div id="incident-lead-component-wrapper">
        <p v-if="isAcknowledgingMultiple && !allIncidentsHaveLead" class="pa-mb-8">
            Some incidents already have a lead set, setting yourself as the lead will overwrite them.
        </p>
        <p-row
            align-center
            :class="`incident-lead-container${!iIncidentLead ? '--no-lead' : ''} pa-txt_sm`"
            style="margin: 0;"
        >
            <p-avatar
                class="pa-mr-8"
                :src="iIncidentLead ? iIncidentLead.avatarUrl : null"
                rounded-full
                gravatar-size="22"
                :initials="iIncidentLead ? iIncidentLead.initials : null"
            >
            </p-avatar>
            <p v-if="iIncidentLead">
                {{ iIncidentLead.displayName }}
                <span v-if="iIncidentLead.isCurrentUser" class="pa-ml-2">
                    (you)
                </span>
            </p>
            <p-button
                v-else
                variant="link"
                class="add-incident-lead-btn"
                @click="becomeIncidentLead"
            >
                {{ isAcknowledgingMultiple ? "Become Leader of Incidents" : "Become Leader of Incident" }}
            </p-button>
            <p-tooltip2 v-if="iIncidentLead" :hover="true">
                <p-button
                    slot="trigger"
                    unstyled
                    no-base-class
                    @click="removeIncidentLead"
                >
                    <p-icon icon="x"></p-icon>
                </p-button>
                Remove incident lead
            </p-tooltip2>
        </p-row>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
	props: {
        incidentLead: {
            type: Object,
            default: () => {}
        },
        incidentLeads: {
        	type: Object,
            default: () => {}
        },
        currentUser: {
            type: Object,
            default: () => {}
        },
        alertAmount: {
            type: Number,
            default: null,
        },
        hasRemovedIncidentLead: {
            type: Boolean,
            default: false,
        },
    },
    watch:{
        iIncidentLead: function(newValue){
            this.$emit('update:incidentLead', newValue)
        },

        iIncidentLeads: function(newValue){
            this.$emit('update:incidentLeads', newValue)
        },

        iHasRemovedIncidentLead: function(newValue){
            this.$emit('update:hasRemovedIncidentLead', newValue)
        }
    },
    data() {
        return {
            iIncidentLead: _.clone(this.incidentLead),
            iIncidentLeads: _.clone(this.incidentLeads),
            iHasRemovedIncidentLead: this.hasRemovedIncidentLead,
        };
    },
    methods: {
        becomeIncidentLead() {
            this.iIncidentLead = {...this.currentUser};

            if (this.iHasRemovedIncidentLead) {
                this.iHasRemovedIncidentLead = false;
            }
        },
        removeIncidentLead() {
            this.iIncidentLead = null;
            this.iHasRemovedIncidentLead = true;
        },
        isAcknowledgingMultiple() {
            return this.alertAmount > 1;
        },
        allIncidentsHaveLead() {
            return (
                this.iIncidentLeads &&
                this.iIncidentLeads.length === this.alertAmount
            );
        },
    }
});
</script>