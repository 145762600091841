<template>
    <p-menu :max-height="maxHeight" :min-height="minHeight"
        :close-box-on-click-item="closeBoxOnClickItem"
        :searchable="searchable">
        <div
            slot="trigger"
            class="pa-btnGroup" :class="[theme]">
            <div class="pa-btnGroup-slot" v-on:click="_onClick($event)">
                <slot name="primary"></slot>
            </div>
            <button
                type="button"
                class="pa-btnGroup-trigger"
                :disabled="disabled"
                :class="[theme]">
                <svg class="pa-icon pa-icon_light">
                    <use xlink:href="#chevron-down"></use>
                </svg>
            </button>
        </div>
        <slot></slot>
    </p-menu>
</template>

<script>
    import Vue from 'vue';

    const menuMixin = require('../mixins/menuMixin');

    const GroupedMenu = Vue.extend({
        mixins: [menuMixin],

        methods: {
            _onClick($event) {
                $event.stopPropagation();
            },
        },

        props: {
            theme: {
                type: String,
                default: 'primary'
            },
            closeBoxOnClickItem: {
                type: Boolean,
                default: true,
            },
            maxHeight: Number,
            minHeight: Number,
            searchable: Boolean,
        },
    });

    export default GroupedMenu;
</script>
