const promptOpen = (() => {
    // params: ['target', 'title', 'body', 'callback', 'page', 'args'],
    const state = new WeakMap();

    function _onClick(event) {
        event.preventDefault();

        const target = this.params.target;

        if (target && target !== '#') {
            const callback = typeof this.params.callback !== 'undefined' && this.params.callback || null;
            const page = typeof this.params.page !== 'undefined' && this.params.page || null;
            const href = typeof this.params.page !== 'undefined' && this.params.href || null;
            const args = typeof this.params.args !== 'undefined' && this.params.args || null;

            window.app.eventHub.$emit('prompt:load', {
                id: target,
                title: this.params.title,
                body: this.params.body,
                callback: callback,
                args: args,
                page: page,
                href: this.el.getAttribute('href'),
            });

            return;
        }

        window.app.eventHub.$emit('dialog:open', this.params.target);
    }

    return {
        bind(el, binding, vnode) {
            const self = {};
            state.set(el, self);

            self.el = el;
            self.params = Object.assign({}, el.attributes, vnode.data.attrs);

            if (typeof self.params.target === 'undefined' ||
                typeof self.params.title === 'undefined' ||
                typeof self.params.body === 'undefined') {
                return;
            }

            self._onClick = _onClick.bind(self);

            self.el.addEventListener('click', self._onClick);
        },

        update(el, binding, vnode) {
            const self = state.get(el);

            self.params = Object.assign({}, el.attributes, vnode.data.attrs);
        },

        unbind(el) {
            self = state.get(el);
            self.el.removeEventListener('click', self._onClick);
        },
    };
})();

export default promptOpen;
