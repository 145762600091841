<style lang="scss">
    .pa-concat {
        display: inline-block;

        .pa-concat-a, .pa-concat-b {
            width: 300px;
        }
    }
</style>

<template>
    <div class="pa-concat">
        <input type="text"
            v-model="iA"
            @input="update"
            class="pa-input pa-concat-a" 
            :placeholder="aPlaceholder"
            />
        <input type="text"
            v-model="iB"
            @input="update"
            class="pa-input pa-concat-b"
            :placeholder="bPlaceholder"
            />
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            a: String,
            b: String,
            combined: String,
            delimeter: {
                type: String,
                default: ',',
            },
            aPlaceholder: String,
            bPlaceholder: String,
            onChangeCallback: {
                type: Function,
                default: () => {},
            },
        },

        data() {
            return {
                iA: this.a,
                iB: this.b,
                iCombined: '',
            };
        },

        watch: {
            combined(val, oldVal) {
                this.updateFromCombined();
            },
        },

        methods: {
            update() {
                if (!this.iA || !this.iA.length || !this.iB || !this.iB.length) {
                    this.iCombined = '';
                    this.$emit('update:combined', this.iCombined);
                    this.onChangeCallback(this.iCombined);

                    return;
                }

                this.iCombined = [this.iA, this.iB].join(this.delimeter);
                this.$emit('update:combined', this.iCombined);
                this.onChangeCallback(this.iCombined);
            },

            updateFromCombined() {
                if (!this.combined) {
                    this.iA = null;
                    this.iB = null;
                    this.iCombined = '';
                }

                try {
                    const split = this.combined.split(this.delimeter);
                    this.iA = split[0];
                    this.iB = split[1];
                    this.iCombined = this.combined;
                } catch (e) {
                    console.error(e);
                }
            },
        },

        vueReady() {
            if (this.combined) {
                this.updateFromCombined();
            }
        },
    });
</script>
