<style>
    .widget-table {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .widget-body.incident-details {
        overflow: hidden;
        height: 100%;
        margin-bottom: 0px;
    }
    .widget-body.incident-details > div {
        height: 100%;
    }
    .widget-body.incident-details #table-filters, .widget-body.incident-details #table-buttons {
        margin-bottom: 0px !important;
    }
    .widget-body.incident-details .dataTables_wrapper {
        height: 100%;
    }
    .widget-body.incident-details .dataTables_scroll {
        height: 100%;
    }
    .widget-body.incident-details .dataTables_wrapper .dataTables_paginate {
        padding-top: 10px !important;
        padding-bottom: 0px !important;
        border-top: .5px solid #edeff0;
        display: none;
    }
    .widget-body.incident-details td {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    th.hidden, td.hidden {
        padding: 0;
        width: 0px !important;
    }
    .incident-details {
        margin-left: 20px;
        margin-right: 20px;
    }
    th.eventid-column {
        width: 100px;
    }
    th.countermeasures-column {
        width: 25px;
    }
    th.countermeasures-column .sort-icon {
        display: none !important;
    }

    .img_custom{
        width:15px;
        height:15px
    }

    .ack-btn {
        background: none;
        border: .5px solid grey;
        box-shadow: none;
        color: grey;
        height: 15px;               
        border: none;        
        border-radius: 1px; 
        text-decoration: none;
        line-height: 14.25px;
        cursor: pointer;
        outline: none;
        transition: background .1s;
    }
    .ack-btn:focus {
        color: grey;
    }
    .ack-btn:hover, .ack-btn:active {
        background: grey;
        color: white;
    }
    .ack-avatar {
        height: 28px;
        padding: 0 10px;
        display: block;
        margin: 0 auto;
    }
    .ack-text {
        padding: 2px;
        margin-bottom: 0px;
        font-size: 8px;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
    }

    .ack-user-initials {
        height: 10px;
        width: 15px;
        background-color: #3954bf;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
    }

    .ack-user-initials-text {
        color: white;
        font-size: 8px;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
    }
    .incident-details .dataTables_scrollFoot a {
        cursor: pointer;
    }
    .incident-details .dts_label {
        display: none !important;
    }
    .incident-details table.dataTable {
        border-collapse: collapse;
    }
    .incident-details table.dataTable thead th.hidden-column {
        border: none;
    }
    .incident-details table.dataTable thead th,
    .incident-details table.dataTable tfoot th {
        border-top: 1px solid #e6e6e6;
        box-shadow: 1px 0 0 0 #e6e6e6;
        padding: 7px;
    }
    .incident-details table.dataTable tbody tr td {
        border-left: 1px solid #e6e6e6;
        padding: 8px;
    }
    .incident-details table.dataTable tbody tr:first-of-type td {
        border-top: none !important;
    }
    .incident-details table.dataTable thead tr th {
        border-bottom: 1px solid #e6e6e6;
        box-shadow: -1px 0 0 0 #e6e6e6;
    }
    .incident-details table.dataTable thead tr th:not(.hidden) ~ th {
        border-left: none;
    }
    .incident-details table.dataTable .hidden {
        display: none;
    }
    .incident-details .dataTables_wrapper .dataTables_scroll div.dataTables_scrollHead {
        width: 100% !important;
    }
    .incident-details .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
        width: 99% !important;
        padding-bottom: 5px;        
    }
    .incident-details .dataTables_wrapper .dataTables_scroll div.dataTables_scrollHead table {
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
    }
    .incident-details .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody table {
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        box-sizing: border-box;
    }
    .incident-details table.dataTable th.countermeasures-column,
    .incident-details table.dataTable td.countermeasures {
        border-left-color: white !important;
        box-shadow: none;
    }
    .incident-details table.dataTable.hidden-event-id th.countermeasures-column,
    .incident-details table.dataTable.hidden-event-id td.countermeasures {
        border-left-color: #e6e6e6 !important
    }

    .pa-badge-no-click {
        background-color: var(--p-blue-200, #c8cfed);
        color: var(--p-blue-700, #21316f);
        font-size: 0.7058823529rem;
        line-height: 1.33;
        letter-spacing: normal;
        font-stretch: normal;
        padding: 4px;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-align: center;
        align-items: center;
        font-weight: normal;
        border-radius: 0.1176470588rem;
    }
</style>
<template>
    <div :id="id"
         :class="!inModal ? 'grid-stack-item' : 'modal-view'"
         :data-gs-x="localConfig.col.toString()"
         :data-gs-y="localConfig.row.toString()"
         :data-gs-width="localConfig.width.toString()"
         :data-gs-height="localConfig.height.toString()"
         :data-gs-auto-position="false"
         :data-gs-locked="true">
        <div
            :class="!inModal ? 'grid-stack-item-content' : 'modal-view-content'"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;">
                <div :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered, 'expand-mode' : !$parent.editing && isHovered && !inModal, 'in-modal': inModal}">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_9of12" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                            </span>
                            <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: textColor}"><use xlink:href="#spinner-spin-naked"></use></svg>                            
                            <p-tooltip2 :normal-white-space="true">
                                <span v-if="!isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: textColor }">
                                    <span v-if="localConfig.title">{{ localConfig.title }}</span>
                                </span>
                                <span v-if="isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate">
                                    <span v-if="localConfig.title">{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }}</span>
                            </p-tooltip2>
                        </div>
                        <div class="pa-grid-col pa-grid-col_3of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger"  v-show="!$parent.editing && isHovered && !inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#arrow-expand"></use>
                                    </svg>
                                </button>
                                <span> Expand </span>
                            </p-tooltip2>
                            <button slot="trigger"  v-show="!$parent.editing && inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                <svg class="pa-icon remove-icon">
                                    <use xlink:href="#close"></use>
                                </svg>
                            </button>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls  && !inModal" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon" >
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls  && !inModal" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon edit-icon" >
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span> 
                            </p-tooltip2>
                            <p-tooltip2>
                                <button 
                                    v-show="showWidgetControls  && !inModal" 
                                    type="button"
                                    slot="trigger"
                                    @click="cloneWidget" 
                                    class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mr-6 pa-mt-6"
                                >
                                    <p-icon icon="copy"></p-icon>
                                </button>
                                <span> Clone this Widget </span>                             
                            </p-tooltip2>            
                        </div>
                    </div>
                </div>
                <div
                    v-if="valid"
                    id="body"
                    class="widget-body incident-details"
                    style="flex-grow: 1; margin-bottom: 20px;"
                    :style="{'height': inModal && '74vh'}"

                    ref="body"
                >
                    <div :style="`height: ${bodyHeight}px;`">
                        <p-table2
                            :column-renderers="columnRenderers"
                            :column-classes="{
                                0: 'hidden',
                                2:'countermeasures'
                            }"
                            :column-callbacks="columnCallbacks"
                            :empty-message="'No ' + incidentType + ' incidents during this period.'"
                            :paging="true"
                            :avoid-first-table="true"
                            :scroll-y="`${bodyHeight}px`"
                            ref="data_table"
                            :ordering="true"
                            :sort-column="sortColumnIndex"
                            :sort-direction="sortDirection"
                            :scroller="true"
                            :scroll-collapse="true"
                            :defer-render="true"
                        >
                            <table slot="table" class="pa-table pa-table widget-table" :class="{ 'hidden-event-id': !visibleEventId }">
                                <thead id="table_headers">
                                    <tr>
                                        <th :key="column" v-for="column in columns" :class="classify(column) + '-column'">
                                            {{ headlessColumns.includes(column) ? '' : column}}
                                        </th>
                                    </tr>
                                </thead>
                                <tfoot style="display: none;">
                                    <tr ref="more-row">
                                        <td :colspan="columns.length" style="text-align: center;">Plus {{ content.all_count - content.rows.length }} more events. For more information, visit the <a href="../../incidents" target="_blank">Incidents page</a>.</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </p-table2>
                    </div>
                </div>
                <div v-if="!valid" class="widget-body widget-body_error" :style="{'height': inModal && '85vh'}" :title="error_message" ref="body">
                    <p class="pa-message pa-message_error">
                        <svg class="pa-icon pa-icon_xl"><use xlink:href="#alert-circle"></use></svg>
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Vue from 'vue';
    import moment from 'moment';
    import $ from 'jquery';
    import widgetMixin from './../widgetMixin';

    const IncidentDetailsWidget = Vue.extend({
        mixins: [widgetMixin],

        data() {
            return {
                content: {
                    columns: {},
                    column_order: [],
                    rows: {},
                    all_count: 0,
                },
                columns: [],
                headlessColumns: ['Countermeasures'],
                columnRenderers: {
                    0: 'hidden',
                    'service-column': this.renderLinkColumn,
                    'incidenttags-column': this.renderIncidentTagsColumn,
                    'countermeasures-column': this.renderIconColumn,
                    'age-column': this.renderAgeColumn,
                    'eventid-column': this.renderLinkColumn,                    
                    'server-column': this.renderLinkColumn,
                    'fqdn-column': this.renderLinkColumn,
                    'instance-column': this.renderLinkColumn,
                    'ack-column': this.renderAckColumn,
                    'severity-column': this.renderSeverityColumn,
                    'starttime-column': 'datetime',
                    'endtime-column': 'datetime',
                    'duration-column': this.renderDurationColumn,
                    'assignedto-column': this.renderAssignedToColumn,
                },
                columnCallbacks: {
                    'ack-column': (td) => {
                        this.compileEl(td);
                    },
                    'countermeasures-column': (td) => {
                        this.compileEl(td);
                    },
                },
                sortColumn: '',
                sortColumnIndex: -1,
                sortDirection: '',
                hasMore: false,
                bodyHeight: 0,
                inModal: false,
            };
        },

        computed: {
            hideCounterMeasuresColumn: function() {
                return !this.visibleEventId && !this.hasCounterMeasuresInData;
            },
            hasCounterMeasuresInData: function() {
                if (!this.content.rows || _.isEmpty(this.content.rows)) {
                    return false;
                }

                const counterMeasuresIndex = this.columns.indexOf('Countermeasures');

                return this.content.rows.some(
                    column => !_.isEmpty(column[counterMeasuresIndex])
                );
            },
            visibleEventId: function() {
                if (this.content.columns['Event ID']) {
                    return this.content.columns['Event ID'].visible;
                }
            },
            incidentType: function() {
                if (this.$options._componentTag === 'p-resolved-incidentdetails-widget') {
                    return 'resolved';
                } else {
                    return 'active';
                }
            },
        },

        events: {
            // Mixin: 'widget:new_data' -> this.initialize(config, content)
            // Mixin: 'widget:updated_data' -> this.update(content)
            // Mixin: 'widget:pend_config' -> this.pendNewConfig()

            'outage-ackd': function() {
                // Sent via the Ack drawer.
                // If we're an active incident detail widget, we'll need to reload
                if (this.incidentType === 'active') {
                    this.makePriority();
                }
            },

            'last-cm-approved': function() {
                // Sent via CM approve modal when all remaining CMs have been approved
                // If we're an active outage widget, we'll need to update
                if (this.incidentType === 'active') {
                    this.makePriority();
                }
            },
        },

        methods: {
            // Mixin: configure() -- opens configure modal
            initialize(config, content) {
                if (this.$refs.body) {
                    this.$refs.body.style.filter = '';
                }
                this.showAllColumns();
                this.localConfig = {...config};
                this.content = {...content};
                this.columns = content.column_order.slice(0);
                if (config.custom_controls.sort_column && config.custom_controls.sort_direction) {
                    this.sortColumn = config.custom_controls.sort_column;
                    this.sortDirection = config.custom_controls.sort_direction;
                    for (var i = 0; i < this.columns.length; i++) {
                        var col = this.columns[i];
                        if (col == config.custom_controls.sort_column) {
                            this.sortColumnIndex = i;
                            break
                        }
                    }
                }
                    if (this.hideCounterMeasuresColumn) {
                        this.content.columns['Countermeasures'].visible = false;
                    }
                    if (window.dashboard
                            && !window.dashboard.customerAddons['notification.escalation']
                            && this.content.columns['Ack']) {
                        this.content.columns['Ack'].visible = false;
                    }
                this.$nextTick(() => {
                    this.$refs.data_table.createTable();
                    this.insertData();

                    this.$nextTick(() => {
                        this.getBodyHeight();
                        this.hideColumns();
                        if (this.sortColumnIndex >= 0 && this.sortDirection) {
                            this.$refs.data_table.setSort(this.sortColumnIndex, this.sortDirection);
                        }
                        this.changePageLen();
                        const columns = this.getDataPoints();
                        window.app.rootVue.$broadcast('datapoints:set_points', columns, this.content.columns, this.sortColumn, this.sortDirection);
                    });
                    if (this.$refs.data_table.dataTable) {
                        // Add a span element to attach the sort icon to
                        this.$refs.data_table.dataTable.columns().iterator('column', function(ctx, idx) {
                            if (idx != 0) {
                                let header = $(this.$refs.data_table.dataTable.column(idx).header());
                                if (!header.find("span").length) {
                                    header.append('<span class="sort-icon"/>')
                                }
                            }
                        }.bind(this));
                        this.$nextTick(() => {
                            this.$refs.data_table.dataTable.scroller.measure();
                        });
                        this.$refs.data_table.dataTable.on('page', () => {
                            this.updateHasMore();
                        });
                    }
                });
            },

            expandSelf() {
                this.inModal = !this.inModal
                setTimeout(() => {
                    this.getBodyHeight();
                    this.update(this.content)
                }, "400");
                
            },

            classify: function(column) {
                if (column === '') {
                    return 'hidden';
                }
                let retVal = column;
                retVal = retVal.replace(' ', '');
                retVal = retVal.toLocaleLowerCase();
                return retVal;
            },

            renderTimeColumn: function(data, type, row) {
                if (type === 'display') {
                    return data;
                } else {
                    return -data;
                }
            },

            renderDuration: function(duration) {
                if (!duration) {
                    return null;
                }
                const years = duration.years();
                const months = duration.months();
                const days = duration.days();
                const hours = duration.hours();
                const minutes = duration.minutes();
                const seconds = duration.seconds();
                if (years > 0) {
                    if (months > 0) {
                        return `${years}y ${months}mo`;
                    } else {
                        return `${years}y`;
                    }
                } else if (months > 0) {
                    if (days > 0) {
                        return `${months}mo ${days}d`;
                    } else {
                        return `${months}mo`;
                    }
                } else if (days > 0) {
                    if (hours > 0) {
                        return `${days}d ${hours}h`;
                    } else {
                        return `${days}d`;
                    }
                } else if (minutes > 0) {
                    if (seconds > 0) {
                        return `${minutes}m ${seconds}s`;
                    } else {
                        return `${minutes}m`;
                    }
                } else if (seconds > 0) {
                    return `${seconds}s`;
                } else {
                    return null;
                }
            },

            renderAgeColumn: function(data, type, row) {
                if (type === 'display') {
                    return Boolean(data.display) ? data.display : null;
                } else {
                    // data is unix timestamp of start_time
                    // asc sort by negative value gives newest first
                    return -data.sort;
                }
            },

            renderDurationColumn: function(data, type, row) {
                if (type === 'display') {
                    const duration = moment.duration(data);
                    return this.renderDuration(duration);
                } else {
                    // data is unix timestamp of start_time
                    // asc sort by negative value gives newest first
                    return data;
                }
            },

            renderLinkColumn(data, type, row) {
                if (_.isEmpty(data)) {
                    return '';
                }

                if (type === 'display' && this.$parent.showLinks) {
                    if (Array.isArray(data)) {
                        return data.map(
                            item => item.url && (item.acl ? this.$parent.userCanView(item.acl) : true)
                                ? `<a href="${item.url}" target="_blank">${item.title}</a>`
                                : item.title
                        ).join(', ');
                    }

                    if (!data.title) {
                        return '';
                    }

                    if (!data.url) {
                        return data.title;
                    }

                    if (data.acl) {
                        // Our entry came with an acl check we need to verify before rendering the link.
                        // console.log(data.title + ' ' + data.url + ' ' + data.acl + ' ' + this.$parent.userCanView(data.acl))
                        if (!this.$parent.userCanView(data.acl)){
                            return data.title;
                        }
                    }

                    return `<a href="${data.url}" target="_blank">${data.title}</a>`;
                } else {
                    let retVal = '';
                    if(Array.isArray(data)){
                        retVal = data[0].title;    
                    }else{
                        retVal = data.title;
                    }                    
                    if (typeof retVal === 'undefined') {
                        retVal = '';
                    }
                    return retVal;
                }
            },

            renderIncidentTagsColumn(data, type, row) {
                let tagMarkup = '';

                if (_.isEmpty(data)) {
                    return tagMarkup;
                }
                
                if (type === 'display') {
                    for (const tagName of data) {
                        tagMarkup += `<span class="pa-badge-no-click pa-mr-2">${tagName}</span>`;
                    }
                }

                return tagMarkup;
                
            },

            renderAssignedToColumn(data, type, row) {
                if (type === 'display') {
                    if (data.user_initials) {
                        return `
                        <div class="pa-pl-12">
                            <p-tooltip :hover="true" :fixed="true">
                                <div slot="trigger" class="ack-user-initials">
                                    <span class="ack-user-initials-text">
                                        ${data.user_initials}
                                    </span>
                                </div>
                                <p class="pa-txt pa-m-0">
                                    ${data.display_name}
                                </p>
                            </p-tooltip>
                        </div>`;
                    } else if (data.avatar_url) {
                        const classes = 'img_custom pa-img_avatar';
                        let avatar = `<img class="${classes}" title="${data.user_name}" src="${data.avatar_url}" />`; // eslint-disable-line max-len
                        return `<span class="pa-pl-12">${avatar}</span>`;
                    } else if (data.text) {
                        return `<span class="pa-badge pa-badge_info ack-text">${data.text}</span>`;
                    } else {
                        return '';
                    }
                } else {
                    return false;
                }
            },

            renderAckColumn(data, type, row) {
                if (type === 'display') {
                    const acked = data.acked || false;
                    if (acked && data.user_initials) {
                        return `                        
                        <div class="pa-pl-12">
                            <p-tooltip :hover="true" :fixed="true">
                                <div slot="trigger" class="ack-user-initials">
                                    <span class="ack-user-initials-text">
                                        ${data.user_initials}
                                    </span>
                                </div>
                                <p class="pa-txt pa-m-0">
                                    ${data.display_name}
                                </p>
                            </p-tooltip>
                        </div>`;
                    } else if (acked && data.avatar_url) {
                        const classes = 'img_custom pa-img_avatar';
                        let avatar = `<img class="${classes}" title="${data.user_name}" src="${data.avatar_url}" />`; // eslint-disable-line max-len
                        if (this.$parent.userIncidentCollab && data.action) {
                            avatar = `<a ${data.action} class="pa-pl-12">${avatar}</a>`;
                        }
                        return avatar;
                    } else if (acked && data.text) {
                        return `<span class="pa-badge pa-badge_info ack-text">${data.text}</span>`;
                    } else if (this.$parent.userIncidentCollab) {
                        const button = `<button v-p-drawer-open target="acknowledge-incident-drawer" target-args='{ "incidentIds": ${data.alert_id} }' class="pa-btn ack-btn pa-ml-12 ack-text">Ack</button>`; // eslint-disable-line max-len
                        return button;
                    } else {
                        return '';
                    }
                } else {
                    return data.acked || false;
                }
            },

            renderSeverityColumn(data, type, row) {
                if (data && type === 'display') {
                    const lower = data.toLowerCase();
                    return `<span class="pa-badge pa-badge_${lower}" style="margin: 0">${data}</span>`;
                } else {
                    return data;
                }
            },

            renderIconColumn: function(data, type, row) {
                const icon = data.icon;
                if (!icon) {
                    return '';
                }

                if (type === 'display') {
                    let fill = '';
                    let addClass = '';
                    if (data.fill) {
                        fill = `style="fill: ${data.fill};"`;
                    }
                    if (data.class) {
                        addClass = data.class;
                    }
                    let title = '';
                    if (data.title) {
                        title = `<title>${data.title}</title>`;
                    }
                    let markup = `<svg class="pa-icon ${addClass}" ${fill}>${title}<use xlink:href="#${icon}"></use></svg>`; // eslint-disable-line max-len
                    if (!this.$parent.userIncidentCollab) {
                        return markup;
                    }

                    if (data.link) {
                        markup = `<a href="${data.link}">${markup}</a>`;
                    } else if (data.drawer) {
                        const drawer = data.drawer;
                        let anonymous = '';
                        if (drawer.anonymous === false) {
                            anonymous = 'anonymous="false"';
                        }
                        markup = `<a href="#" v-p-drawer-open target="${drawer.id}" template="${drawer.link}" ${anonymous}>${markup}</a>`; // eslint-disable-line max-len
                    } else if (data.modal) {
                        const modal = data.modal;
                        markup = `<a href="#" v-p-dialog-open target="${modal.id}" template="${modal.link}">${markup}</a>`; // eslint-disable-line max-len
                    }
                    return markup;
                } else {
                    return icon;
                }
            },

            showAllColumns() {
                if (!this.$refs.data_table.dataTable) {
                    return;
                }
                for (var col in this.content.columns) {
                    const column_id = this.content.column_order.indexOf(col);
                    this.$refs.data_table.showColumn(column_id, false);
                }
                this.$refs.data_table.dataTable.columns.adjust().draw(false);
            },

            hideColumns() {
                for (var col in this.content.columns) {
                    const column_id = this.content.column_order.indexOf(col);
                    if (this.content.columns[col].visible) {
                        this.$refs.data_table.showColumn(column_id);
                    } else {
                        this.$refs.data_table.hideColumn(column_id);
                    }
                }
                this.$refs.data_table.dataTable.columns.adjust().draw(false);
            },

            toggleColumn(column) {
                const visible = this.content.columns[column].visible;
                this.content.columns[column].visible = !visible;
                return {
                    columns: this.content.columns,
                    column_order: this.content.column_order,
                };
            },

            setSort(column, direction) {
                return {
                    columns: this.content.columns,
                    column_order: this.content.column_order,
                };
            },

            moveColumn(actual_index, desired_index) {
                // Take into account one static columns.
                actual_index += 1;
                desired_index += 1;
                const temp = this.content.column_order[desired_index];
                this.content.column_order[desired_index] = this.content.column_order[actual_index];
                this.content.column_order[actual_index] = temp;

                // Workaround for datatables bug
                // Remove the footer from the DOM so it doesn't try to process it in the swap
                const moreRowParent = this.$refs['more-row'].parentNode;
                this.$refs['more-row'].remove();
                // Also move the column within datatables
                // Otherwise it doesn't recompute renderers
                this.$refs.data_table.moveColumn(actual_index, desired_index);
                moreRowParent.appendChild(this.$refs['more-row']);

                return {
                    columns: this.content.columns,
                    column_order: this.content.column_order,
                };
            },

            getDataPoints() {
                return this.content.column_order.filter(column => column);
            },

            insertData() {
                this.$refs.data_table.reloadTableData(this.content.rows);
            },

            changePageLen() {
                this.$refs.data_table.changePageLen(this.calculateTableRows());
                this.updateHasMore();
            },

            ackFinished(alertId) {
                if (!this.content || !this.content.rows) { return; }
                const rowIndex = this.content.rows.findIndex(r => {
                    return r[0] === alertId;
                });
                if (rowIndex > -1) {
                    const newContent = {
                        acked: true,
                        text: 'Ack\'d',
                    };
                    const row = this.content.rows[rowIndex];
                    const colIndex = row.findIndex(c => {
                        return typeof c.acked === 'boolean';
                    });
                    if (colIndex > -1) {
                        this.content = {
                            ...this.content,
                            rows: this.content.rows.map((row, i) => {
                                if (i === rowIndex) {
                                    return row.map((col, j) => {
                                        if (colIndex === j) {
                                            return newContent;
                                        }

                                        return col;
                                    });
                                }

                                return row;
                            })
                        };
                        this.update(this.content);
                    }
                }
            },

            update(content) {
                this.content = {...content};
                this.$refs.data_table.reloadTableData(this.content.rows);
                this.$nextTick(() => {
                    if (this.$refs.data_table.dataTable) {
                        this.$refs.data_table.dataTable.scroller.measure();
                    }
                });
            },

            getColumns() {
                return this.content.data_points;
            },

            reconfig() {
                this.$refs.data_table.changePageLen(this.calculateTableRows());
                if (this.$refs.data_table.dataTable) {
                    this.$refs.data_table.dataTable.scroller.measure();
                }
            },

            calculateTableRows() {
                const tableArea = this.$el.querySelectorAll('.dataTables_scrollBody')[0];
                if (!tableArea) { return this.localConfig.height * 1.7; }
                const tableHeight = tableArea.offsetHeight;
                let rowHeight = 26; // TODO: reliable?
                if (this.columns && this.columns.includes('Ack')) {
                    rowHeight = 36;
                }

                return Math.floor(tableHeight / rowHeight);
            },

            updateHasMore() {
                this.hasMore = false;
                if (this.content.all_count && this.content.rows
                        && this.content.all_count > this.content.rows.length) {
                    this.hasMore = true;
                }
                if (!this.hasMore) {
                    return;
                }
                if (!this.$refs.data_table.dataTable) {
                    return;
                }
                const table = this.$refs.data_table.dataTable;
                if (!table.table() || !table.table().footer()) {
                    return;
                }
                const pageInfo = table.page.info();
                if (pageInfo.page + 1 >= pageInfo.pages) {
                    table.table().footer().style.display = '';
                } else {
                    table.table().footer().style.display = 'none';
                }
            },

            getConfigModules() {
                return [
                    {type: 'p-overview-module'},
                    {type: 'p-instance-module', options: {defaultType: 'none'}},
                    {type: 'p-metric-module', options: {multiple: true}},
                    {type: 'p-incident-module'},
                ]
            },

            getOptionModules() {
                if (this.incidentType === 'resolved') {
                    return [
                        {
                            type: 'p-custom-module',
                            options: {
                                ...(this.$parent.isScoping() ? {"ignore-scope-module": {}} : {}),
                                "timerange-module": { options: {defaultValue: 'day'} },
                                title: 'Severity',
                                controls: [
                                    {
                                        label: 'Incident Severity',
                                        key: 'severity',
                                        type: 'p-select',
                                        options: [{value: 'all', label: 'All'},
                                            {value: 'critical', label: 'Critical'},
                                            {value: 'warning', label: 'Warning'}],
                                        'default': 'all',
                                    },
                                    {
                                        id: 'backgroundColor',
                                        label: 'Background Color',
                                        key: 'backgroundColor',
                                        type: 'color-picker',
                                        default: '#ffffff'
                                    },
                                ]
                            }
                        },
                        {type: 'p-datapoints-module', options: {}},
                    ];
                }

                return [
                    {type: 'p-datapoints-module', options: {}},
                    {
                        type: 'p-custom-module',
                        options: {
                            ...(this.$parent.isScoping() ? {"ignore-scope-module": {}} : {}),
                            title: 'Severity',
                            controls: [
                                {
                                    label: 'Incident Severity',
                                    key: 'severity',
                                    type: 'p-select',
                                    options: [{value: 'all', label: 'All'},
                                        {value: 'critical', label: 'Critical'},
                                        {value: 'warning', label: 'Warning'}],
                                    'default': 'all',
                                },
                                {
                                    id: 'backgroundColor',
                                    label: 'Background Color',
                                    key: 'backgroundColor',
                                    type: 'color-picker',
                                    default: '#ffffff'
                                },
                            ]
                        }
                    },
                    {
                        type: 'p-custom-module',
                        options: {
                            hideHeader: true,
                            title: 'Hide Incidents',
                            controls: [
                                {
                                    label: 'Hide Acknowledged Incidents',
                                    key: 'hide_acknowledged',
                                    type: 'p-switch',
                                    'default': false,
                                }, {
                                    label: 'Hide Incidents Under Maintenance',
                                    key: 'hide_maintenance',
                                    type: 'p-switch',
                                    'default': false,
                                },
                            ]
                        }
                    },
                ];
            },

            configure() {

                window.app.rootVue.$broadcast('dashboard:open_config', this);

                // Configuration events need to be sent on next tick, to give
                // time to Vue to update the DOM
                this.$nextTick(() => {
                    const columns = this.getDataPoints();
                    if (columns) {
                        window.app.rootVue.$broadcast('datapoints:set_points', columns, this.content.columns, this.sortColumn, this.sortDirection);
                    }
                });
            },

            dumpState() {
                const obj = Object();
                obj.data = this.$data;
                obj.config = {...this.config};
                console.log(JSON.stringify(obj));
            },

            getBodyHeight() {
                if (this.$refs.body) {
                    const tableHeader = document.querySelector(".dataTables_scrollHead");
                    const tableHeaderHeight = tableHeader ? tableHeader.clientHeight: 0;
                    const offset = 20;
                    const bodyHeight = (this.inModal ?  400 : this.$refs.body.clientHeight) - tableHeaderHeight - offset;
                    this.bodyHeight = this.inModal ? '74vh' : bodyHeight;
                }
            },

        },

        watch: {
            'content': function() {
                if (this.$refs.data_table && this.$refs.data_table.dataTable) {
                    if (this.hideCounterMeasuresColumn) {
                        this.$refs.data_table.hideColumn(2);
                    } else {
                        this.$refs.data_table.showColumn(2);
                    }
                }
            },
            'localConfig.height'() {
                this.getBodyHeight();
            },
            bodyHeight(curr) {
                this.$nextTick(() => {
                    if (this.$refs.data_table.dataTable) {
                        const scrollBody = this.$el.querySelector(".dataTables_scrollBody");
                        if (scrollBody) {
                            scrollBody.style.maxHeight = this.inModal ? '75vh' : `${curr}px`;
                        }
                    }
                });
            },
        },

        props: {
            // Mixin: id:Number, config:Object, title:String
        },

        mounted() {
            // Mixin: dispatch('widget:domready')
            this.pendNewConfig();
            if (this.id < 0) {
                this.configure();
            }
        },
    });

    export default IncidentDetailsWidget;
</script>
