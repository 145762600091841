<style scoped>
    .pa-btn.blue {
        background-color: #396abf;
    }
</style>

<template>
    <div class="module">
        <div ref="body" v-show="open" transition="module-expand" class="module-body">
            <p-field-group
                label="HTML Block"
                :error="errors['html']"
            >
                <textarea 
                    v-model="custom_controls.markup" 
                    ref="input-field" name="template" 
                    class="edit-html"
                ></textarea>
            </p-field-group>
            
        </div>
        <div class="module-footer right">
            <button class="pa-btn" @click="doneClicked">Save</button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const HTMLModule = Vue.extend({
        events: {
        },

        data() {
            return {
                format_options: [
                    {value: "hide_server_name", label: "Hide Server Name"},
                    {value: "hide_units", label: "Hide Units"},
                    {value: "hide_server_fqdn", label: "Hide Server FQDN"},
                    {value: "hide_metric_type", label: "Hide Metric Type"},
                    {value: "hide_option_name", label: "Hide Option Name"},
                ],
                chosen: [],
                errors: {},
            }
        },

        props: {
            options: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            open: {
                type: Boolean,
                default: true
            },
            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        },


        watch: {

        },

        computed: {
            custom_controls() {
                // Without backend help we need to parse JSON ourself
                const cc = this.editingWidget.config.custom_controls;
                if (typeof cc === 'undefined' || cc === null) {
                    return Object();
                } else if (typeof cc === 'string') {
                    Vue.set('editingWidget.config.custom_controls', JSON.parse(cc));
                    return this.editingWidget.config.custom_controls.markup;
                } else {
                    return cc;
                }
            },

            markup() {
                const m = this.editingWidget.config.custom_controls.markup;
                if (typeof m === 'undefined') {
                    return '';
                } else {
                    return m;
                }
            },
        },
        methods: {
            doneClicked() {
                const val = tinymce.activeEditor.getContent();
                this.custom_controls.markup = this.formatMarkup(val);
                
                const payload = {
                    widget_id: this.editingWidget.id,
                    controls: JSON.stringify(this.custom_controls),
                };
                $.ajax({ url: '/dashboardv2/setWidgetCustomControls',
                    method: 'POST',
                    data: payload,
                }).done(data => {
                    if(data.success) {
                        window.app.rootVue.$broadcast(
                            'html-module:update_markup', 
                            this.custom_controls.markup, 
                            this.editingWidget.id
                        );
                    } else {
                        this.errors['html'] = data.msg;
                    }
                });
            },
            toggle() {
                this.open = !this.open;
            },
            sendConfig() {
                this.$parent.updatePending(this.editingWidget.id);

                var payload = {
                                widget_id: this.editingWidget.id,
                                format_options: this.chosen
                              }
                $.ajax({
                    url: '/dashboardv2/setWidgetFormatting',
                    method: 'POST',
                    data: payload,
                })
                    .done(data => {
                        if (data.success && data.newConfig) {
                            this.editingWidget.config = data.newConfig;
                        }
                    });
                this.editingWidget.pendNewConfig();
            },
            updateChosen() {
                var preset = this.editingWidget.config.format_options;
                if (preset === null || typeof preset === 'undefined') {
                    preset = [];
                }
                this.chosen = [...preset];
            },
            formatMarkup(html) {
                let dom = document.createElement('span');
                dom.innerHTML = html;
                var items = dom.getElementsByTagName('A');
                //automatically update link with the target if
                //they didn't include it.
                for (var i = items.length; i--;) {
                    if(!items[i].getAttribute('target')) {
                        items[i].setAttribute('target', '_new')
                    }
                }

                return dom.innerHTML;
            },
        },

        vueReady() {

            tinymce.init({
                target: this.$refs['input-field'],
                height: 250,
                width: '100%',
                plugins: 'code',
                toolbar: 'undo redo | styleselect | bold italic | alignleft alignright aligncenter alignjustify | bullist numlist outdent ident | code',
                valid_elements: '*[*]',
                extended_valid_elements: '*[*]',
                valid_children: '+body[style]',
            });

            if (this.id < 0) {
                const payload = {
                    dashboard_id: this.$parent.id,
                    connection_id: this.$parent.cId,
                    widget_type: this.$options._componentTag,
                    width: this.config.width,
                    height: this.config.height,
                    row: this.config.row,
                    col: this.config.col,
                };
                $.ajax('/dashboardv2/addWidget', {
                    method: 'GET',
                    data: payload,
                    context: this,
                })
                .done($.proxy(function(data) {
                    if (!data.success) {
                        console.log(data.msg);
                        return;
                    }
                    this.id = data.new_id;
                    this.$parent.widgets.slice(-1)[0].id = data.new_id;
                }), this);
            }

        }
    });

    export default HTMLModule;
</script>
