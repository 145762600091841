<template>
    <li class="pa-panels-item" :class="{ isActive: localActive }">
        <template v-if="!lazyLoad || rendered || isActive">
            <div class="pa-panels-item-hd">
                <button
                    type="button"
                    class="pa-tab pa-tab_stacked"
                    :class="{ isActive: localActive }"
                    v-on:click="onClick">{{ title }}</button>
            </div>
            <div class="pa-panels-item-bd">
                <div :class="{ 'pa-wrapper': wrapper !== false }">
                    <slot></slot>
                </div>
            </div>
        </template>
    </li>
</template>

<script>
    import Vue from 'vue';
    import generateID from './../utils/generateID';
    import generateKebabCaseID from './../utils/generateKebabCaseID';

    // These are needed incase we need to resize
    // an expando after switching panel tabs.
    const CUSTOM_EVENTS = {
        ACTIVATED: 'panel:activated',
        DEACTIVATED: 'panel:deactivated',
    };

    const Panel = Vue.extend({
        created() {
            this.$parent._addPanel(this);
        },

        beforeDestroy() {
            try {
                this.$parent._removePanel(this);
            } catch (e) {
                console.error(e);
            }
        },

        data() {
            return {
                localActive: this.isActive,
                rendered: false,
                id: generateKebabCaseID(this.title),
                sort_id: this.order > 0 ? this.order : parseInt(generateID('')),
            };
        },

        methods: {
            onClick() {
                this.$parent.setActivePanelById(this.id);
            },
        },

        props: {
            isActive: {
                type: Boolean,
                default: false,
            },

            hasError: {
                type: Boolean,
                default: false
            },

            notification: [String, Number],

            title: {
                type: String,
                default: ''
            },

            order: {
                type: Number,
                default: -1
            },

            wrapper: {
                type: Boolean,
                default: true
            },

            onActivate: {
                type: Function,
                default: function() {}
            },

            target: {
                type: Number,
                default: undefined,
            },

            lazy: Boolean,

            lazyOnce: {
                type: Boolean,
                default: true,
            },

            badge: {
                type: Object,
                default: function() {},
            },
        },

        computed: {
            lazyLoad() {
                return this.lazy || (this.$parent && this.$parent.lazy);
            },
            lazyLoadOnce() {
                return [
                    this.lazyOnce,
                    (this.$parent && this.$parent.lazyOnce)
                ].every(Boolean);
            },
        },

         watch: {
            localActive: function(val, oldVal) {
                let id = "";

                if (this.$attrs && this.$attrs.id) {
                    id = this.$attrs.id;
                } else {
                    id = this.id;
                }

                if (val) {
                    this.rendered = true;
                }

                if (val !== true) {
                    this.eventHub.$emit(CUSTOM_EVENTS.DEACTIVATED, {
                        id: id,
                        title: this.title,
                        parent: this.$parent,
                    });

                    if (this.lazyLoad && !this.lazyLoadOnce) {
                        this.rendered = false;
                    }

                    return;
                }

                this.eventHub.$emit(CUSTOM_EVENTS.ACTIVATED, {
                    id: id,
                    title: this.title,
                    parent: this.$parent,
                });

                if (this.$parent.rememberActivePanel) {
                    if (!window.location.hash.includes(id)) {
                        window.location.hash = id;
                    }
                }
            }
        }
    });

    export default Panel;
</script>
