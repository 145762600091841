const WAIT_TIME = 500;
const SHOW_TIME = 200;

const hoverText = (() => {
    // params: ['mode', 'waitTime', 'hoverTimeout', 'align'],
    const state = new WeakMap();

    return {
        bind() {
        },

        inserted(el, binding, vnode) {
            const self = {};
            state.set(el, self);

            self.el = el;
            self.params = vnode.data.attrs || {};
            self.value = binding.value;

            const waitTime = self.params.waitTime || WAIT_TIME;
            const hoverTimeout = self.params.hoverTimeout || SHOW_TIME;
            const mode = self.params.mode;
            const align = self.params.align;

            // TODO: Handle updates to text?
            self.el.style.borderBottom = '1px dashed #cecece';
            self.el.style.cursor = 'help';
            self.el.setAttribute('slot', 'trigger');

            const trigger = self.el.cloneNode(true);
            const content = document.createElement('span');
            content.innerHTML = self.value;

            let tooltip;
            if (mode === 'tooltip2') {
                tooltip = document.createElement('p-tooltip2');
            } else {
                tooltip = document.createElement('p-tooltip');
            }
            tooltip.setAttribute('v-bind:hover', 'true');
            tooltip.setAttribute('v-bind:wait-time', waitTime);
            tooltip.setAttribute('v-bind:hover-timeout', hoverTimeout);
            if (mode === 'fixed') {
                tooltip.setAttribute('v-bind:fixed', 'true');
            } else if (mode === 'advanced') {
                tooltip.setAttribute('v-bind:advanced', 'true');
            }
            if (align) {
                tooltip.setAttribute('trigger-align', align);
            }
            tooltip.appendChild(trigger);
            tooltip.appendChild(content);

            self.el.parentNode.replaceChild(tooltip, self.el);

            vnode.context.$compile(tooltip);
        },

        unbind() {
        },
    };
})();

export default hoverText;
