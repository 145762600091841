<!--

    This component is used with DataPointBtn to dynamically set the wrapping element of content
    so that it can either be a clickable button or a div.

    The child elements of this component should be wrapped with the slot name "body"
    to match the same position of DataPointBtn.

-->
<template>
    <div class="pa-dataPoint">
        <slot name="body"></slot>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({});
</script>