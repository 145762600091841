<style lang="scss">
    @import "~styles/core/helpers/_variables.scss";

    #auth {
        background: white;
        box-shadow: 1px 0 8px 0 rgba(47, 69, 157, 0.3), -3px 0 8px 0 rgba(57, 84, 191, 0.5);
        min-height: calc(100vh - 102px);
        left: 0;
        padding: 51px;
        width: 409px;

        .auth-header {
            text-align: center;

            .logo {
                height: 61px;
                width: 108.4px;
            }

            .title {
                color: #494c4e;
                font-size: 28px;
                font-weight: bold;
                margin-top: 26px;
            }
        }

        .pa-alert--full-width {
            position: relative;
            left: -51px;
            width: calc(100% + 102px);

            .pa-alert-wrapper {
                padding-left: 48px;
                padding-right: 48px;
            }
        }

        .submit-button {
            line-height: 25px;
            padding: 14px 0;
        }
    }
</style>

<template>
    <div id="auth">
        <p-login v-if="iType === 'login'"
            :brand_name="brand_name"
            :brand_textkey="brand_textkey"
            :message="message"
            :message-type="messageType"
            :previous-url="previousUrl"
            :forticloud-url="forticloudUrl">
        </p-login>

        <p-forgot-password v-if="iType === 'forgot_password'"
            :brand_name="brand_name"
            :brand_textkey="brand_textkey"
            :message="message"
            :message-type="messageType"
            :fields="iFields"
            :errors="iErrors">
        </p-forgot-password>

        <p-reset-password v-if="iType === 'reset_password'"
            :brand_name="brand_name"
            :brand_textkey="brand_textkey"
            :new-user-invite="newUserInvite"
            :message="message"
            :message-type="messageType"
            :fields="iFields"
            :errors="iErrors">
        </p-reset-password>

        <p-trial-signup v-if="iType === 'signup'"
            :brand_name="brand_name"
            :brand_textkey="brand_textkey"
            :message="message"
            :message-type="messageType">
        </p-trial-signup>
    </div>
</template>

<script>
    import Vue from 'vue';

    import Login from './Login.vue';
    import ForgotPassword from './ForgotPassword.vue';
    import ResetPassword from './ResetPassword.vue';
    import TrialSignup from './TrialSignup.vue';

    export default Vue.extend({
        components: {
            'p-login': Login,
            'p-forgot-password': ForgotPassword,
            'p-reset-password': ResetPassword,
            'p-trial-signup': TrialSignup,
        },

        props: {
            brand_name: String,
            brand_textkey: String,
            message: String,
            messageType: String,
            previousUrl: String,
            fields: {
                type: Object,
                'default': () => Object(),
            },
            errors: {
                type: Object,
                'default': () => Object(),
            },
            type: {
                type: String,
                'default': 'login',
            },
            newUserInvite: Boolean,
            forticloudUrl: {
              type: String,
              'default': '/sso/forticloud'
            }
        },

        data() {
            return {
                iType: this.type,
                iFields: _.cloneDeep(this.fields),
                iErrors: _.cloneDeep(this.errors),
            };
        },

        watch: {
            'iType'(val) {
                for (const member in this.iFields) {
                    if (this.iFields.hasOwnProperty(member)) {
                        this.$delete(this.iFields, member);
                    }
                }
                for (const member in this.iErrors) {
                    if (this.iErrors.hasOwnProperty(member)) {
                        this.$delete(this.iErrors, member);
                    }
                }
            },
        },

        methods: {
        },

        events: {
            'auth:switchType'(type) {
                this.iType = type;
            },
        },
    });
</script>
