<script setup>
import { ref, computed, onBeforeMount } from "vue";
import MarkdownIt from 'markdown-it'; 
import * as DOMPurify from 'dompurify';

const props = defineProps({
    content: {
        type: String,
        default: '',
    },
    sanitize: {
        type: Boolean,
        default: true,
    },
});

const md = ref(null);

const setupMarkdownIt = () => {
    md.value = new MarkdownIt;

    md.value.set({
        html: true,
        xhtmlOut: true,
        breaks: true,
        linkify: true,
        typographer: true,
        langPrefix: 'language-',
        quotes: '“”‘’',
    });
};

const getHTML = (value) => {
    return md.value.render(value);
};

const sanitize = (value) => {
    return DOMPurify.sanitize(value);
};

const computedContent = computed(() => {
    let parsedContent = getHTML(props.content);

    if (props.sanitize) {
        return sanitize(parsedContent);
    }

    return parsedContent;
});

onBeforeMount(() => {
    setupMarkdownIt();
});
</script>

<template>
    <div v-html="computedContent"></div>
</template>
