<template>
    <svg :class="computedClass" :style="computedStyle">
        <use v-bind="{ 'xlink:href': '#' + icon }">
            <title v-if="iconTitle">{{ iconTitle }}</title>
        </use>
    </svg>
</template>

<script>
    import Vue from 'vue';

    const sizes = [
        // Empty is valid
        "",
        "xxsm",
        "xsm",
        "sm",
        "md",
        "lg",
        "xl",
        "xxl",
        "xxxl",
        "xxxxl",
        "xxxxxl",
        "huge",
        "28",
    ];

    const colors = [
        // Empty is valid
        "",
        "light",
        "red",
        "blue",
        "green",
        "orange",
        "blue-grey",
        "cloudy-blue",
        "dark-grey-blue",
        "tundora",
        "yellow",
        "blue-100",
        "blue-500",
        "grey-200",
        "grey-300",
        "grey-400",
        "grey-500",
        "grey-600",
        "grey-700",
        "grey-800",
        "grey-900",
        "inactive-grey",
        "black",
        "white",
        "silver",
    ];

    const titles = {
        "instance": "Instance",
        "instance-agent": "Advanced Instance",
        "onsight": "OnSight",
        "template": "Template",
        "network-device_filled": "Network Device",
        "k8s-pod": "Pod",
        "k8s-node": "Node",
        "k8s-cluster": "Cluster",
        "vmw-host": "Host",
        "vmw-cluster": "Cluster",
        "vmw-datastore": "Datastore",
        "docker": "Docker",
        "beaker": "CounterMeasure",
        "acknowledge": "Acknowledge",
        "fortigate_vm": "FortiGate",
        "fortiap": "FortiAP",
        "fortiswitch": "FortiSwitch",
        "fortiextender": "FortiExtender",
    };

    const Icon = Vue.extend({
        props: {
            icon: {
                type: String,
                required: true,
            },
            iconTitle: {
                type: String,
                required: false,
            },
            size: {
                type: String,
                validator: (size) => isValidOption(size, sizes),
            },
            color: {
                type: String,
                validator: (color) => isValidOption(color, colors),
            },
            block: Boolean,
            centered: Boolean,
            middle: Boolean,
            noBaseClass: Boolean,
            className: String,
        },
        computed: {
            computedClass: function() {
                let classNames = [];

                if (!this.noBaseClass) {
                    classNames.push("pa-icon");
                }

                if (this.color) {
                    classNames.push("pa-icon_" + this.color);
                }

                if (this.size) {
                    classNames.push("pa-icon_" + this.size);
                }

                if (this.block) {
                    classNames.push("pa-icon_block");
                }

                if (this.centered) {
                    classNames.push("pa-icon_centered");
                }

                if (this.middle) {
                    classNames.push("middle");
                }

                if (this.className) {
                    classNames.push(this.className);
                }

                return classNames.join(" ");
            },
            computedStyle: function() {
                const styles = {};

                if (this._events && this._events.click) {
                    styles.cursor = "pointer";
                }

                return styles;
            },
        },
    });

    const isValidOption = (option, options) => {
        const isValid = options.indexOf(option) !== -1;

        if (!isValid) {
            console.error(
                "'" +
                option +
                "' is not a valid option. Available options: " +
                options.join(", ")
            );
        }

        return isValid;
    };

    export default Icon;
</script>
