<style lang="scss">
    .synthetic-realtime {
        .realtime-top, .realtime-bottom {
            padding: 0 24px;
        }

        .steps-table {
            text-align: left;

            th {
                border-left: none;
                border-right: none;
                border-top: none;
                padding: 8px 10px;
            }

            td {
                border-left: none;
                border-right: none;
            }
        }

        .realtime-runtime {
            background: #f2f3f5;
            margin-top: 9px;
            padding: 8px 20px;
        }

        .vuetable {
            table-layout: fixed;

        }
        .vuetable-td-target {
            word-break: break-all;
        }

        .screenshot-thumb {
            width: 85px !important;
        }
    }
</style>

<template>
    <div class="synthetic-realtime">
        <div class="realtime-top">
            <p class="pa-txt pa-txt_16 pa-txt_medium">Select a checker location and test your {{ args.check_type }} Synthetic check</p>

            <button
                @click="testCheck"
                class="pa-btn"
            >
                <p-icon
                    icon="play-circle-outline"
                    style="fill: #fff;"
                >
                </p-icon>
                Test Check
            </button>

            <p-select
                style="display: inline-block; vertical-align: middle; margin-left: 8px; width: 140px; height: 35.63px;"
                :options="nodeOptions"
                :model.sync="args.location"
            >
            </p-select>
        </div>

        <p-alert v-if="result.done && result.success" variant="green" full-width class="pa-mt-8">
            <p-icon icon="check-circle">
            </p-icon>
            <span class="pa-txt pa-txt_16 pa-txt_medium pa-ml-8">Test Check ran successfully</span>
        </p-alert>
        <div v-show="result.done && result.success === false" style="margin-bottom: 10px;margin-top: 10px;margin-left: 20px;">
                <span class="pa-text">
                    <a @click="toggleOutputSource" style="cursor: pointer;">{{showOutputSource && 'Hide' || 'Show'}} output source</a> | <a @click="copySource" style="cursor:pointer;">Copy Source</a>
                </span>
            </div>
            <textarea
                id="output-source"
                v-show="showOutputSource"
                rows="10" cols="50"
                style="margin-top: 10px; margin-bottom: 15px;margin-left: 20px; background-color: #fff; font-size: 12px;"
                disabled="disabled"
                v-model="outputSource">
            </textarea>
        <p-alert v-if="result.done && result.success === false && !error" variant="red" full-width class="pa-mt-8">
            <p-icon icon="alert-circle">
            </p-icon>
            <span class="pa-txt pa-txt_16 pa-txt_medium pa-ml-8">Test Check failed</span>
        </p-alert>
        <p-alert v-if="error" variant="red" full-width class="pa-mt-8">
            <p-icon icon="alert-circle">
            </p-icon>
            <span class="pa-txt pa-txt_16 pa-txt_medium pa-ml-8">There was a problem running your check</span>
        </p-alert>
        <p-alert v-if="!result.done && testUuid" variant="blue" full-width class="pa-mt-8">
            <p-icon icon="information">
            </p-icon>
            <span class="pa-txt pa-txt_16 pa-txt_medium pa-ml-8">Test Check is currently running, please wait...</span>
        </p-alert>

        <p-synthetic-results
            v-if="testUuid"
            :checks="checks"
            :screenshots-url="screenshotsUrl"
            :selected-node="args.location"
            :timestamps="false"
            :check-type="checkType"
            :loading="loadingTestResults"
            class="pa-mt-8">
        </p-synthetic-results>

        <div v-if="result.done && !error" class="realtime-bottom pa-mt-8">
            <div class="realtime-runtime">
                <span class="pa-txt pa-txt_sm">{{ args.check_type }} Synthetic Check Total Run Time: {{ result.metadata.duration.toFixed(2) }}s</span>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Vuetable from 'vuetable-2';
    import moment from 'moment';
    import { v1 as uuidv1 } from 'uuid';
    import _ from 'lodash';

    export default Vue.extend({
        components: {
            Vuetable,
        },
        data() {
            return {
                result: {
                    done: false,
                    success: false,
                    metadta: Object(),
                },
                fetchInterval: null,
                fetchStart: null,
                error: false,
                testUuid: null,
                outputSource: "",
                showOutputSource: false,
                loadingTestResults: false,
            };
        },
        props: {
            args: Object,
            nodeOptions: Array,
            screenshotsUrl: String,
        },
        computed: {
            checks() {
                if (this.result.done) {
                    const meta = _.cloneDeep(this.result.metadata);
                    meta.node_id = this.args.location;
                    return [meta];
                }
                return [];
            },

            checkType() {
                if (this.args.check_type == 'JavaScript') {
                    return 'l3';
                } else {
                    return 'l2';
                }
            }
        },
        methods: {
            testCheck() {
                this.loadingTestResults = true;
                this.result.done = false;
                this.result.success = false;
                this.result.metadata = Object();
                this.error = false;
                this.testUuid = null;

                if (this.fetchInterval) {
                    window.clearInterval(this.fetchInterval);
                    this.fetchInterval = null;
                }

                const params = _.cloneDeep(this.args);                
                this.testUuid = uuidv1();
                params.test_uuid = this.testUuid;

                $.ajax({
                    url: '/config/testServiceOnChecker',
                    data: params,
                }).done(data => {                    
                    if (data.result === -1) {
                        this.result.done = true;
                        this.result.success = false;
                        this.testUuid = null;
                        this.error = true;
                        window.clearInterval(this.fetchInterval);
                        this.fetchInterval = null;
                    }
                    if (data.response_details) {
                        this.loadingTestResults = false;
                        this.outputSource = data.response_details;
                    }                
            });
            this.fetchStart = new Date();
            this.fetchInterval = window.setInterval(this.fetchResult, 10000);
            },
            fetchResult() {
                $.ajax({
                    url: '/config/fetchRealtimeTestResult',
                    data: {
                        uuid: this.testUuid,
                    },
                }).done(data => {                    
                    if (data.done) {
                        this.result = data;
                        window.clearInterval(this.fetchInterval);
                        this.fetchInterval = null;
                    } else if (this.fetchStart
                            && (new Date() - this.fetchStart) > 300000
                            && this.fetchInterval) {
                        window.clearInterval(this.fetchInterval);
                        this.fetchInterval = null;
                    }
                })
                .fail(data => {
                    window.clearInterval(this.fetchInterval);
                    this.fetchInterval = null;                    
                });
            },
            toggleOutputSource: function() {
                this.showOutputSource = !this.showOutputSource;
            },
            copySource: function() {
                var source = document.querySelector('#output-source');
                source.spellcheck = false;
                source.disabled = false;
                source.select();
                document.execCommand('copy');
                source.disabled = true;
            },
        },
    });
</script>
