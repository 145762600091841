<style lang="scss" scoped>

    .mask-control {
        margin-left: -32px;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .full-width {
        width: 100%;
    }

    .not-full-width {
        width: 95%;
    }

    .pa-input-xl {
        width: 365px;
    }

    .pa-input-xl-no-cancel {
        width: 315px;
    }


</style>

<template>
    <div :class="inputSize">
        <div v-if="isEditEnabled" class="pa-flex">
            <div v-if="isMasked" :class="hasCancelButton ? 'not-full-width' : 'full-width'">
                <input
                    class="pa-input"
                    :class="theme ? `pa-input_${theme}` : ''"
                    type="password"
                    :name="name"
                    v-bind:value="value"
                    data-lpignore="true"
                    v-on:input="$emit('input', $event.target.value)"
                    v-on:keyup.enter="enter"
                    v-on:blur="$emit('blur', $event.target.value)"
                    :placeholder="placeholder"
                />
                <span 
                    class="mask-control cursor-pointer"
                    @click="toggleMask"
                >
                    <p-icon
                        class="pa-pb-8"
                        icon="eye"
                        color="grey-500"
                    >
                    </p-icon>
                </span>
            </div>
            <div v-else :class="hasCancelButton ? 'not-full-width' : 'full-width'">
                <input
                    class="pa-input"
                    :class="theme ? `pa-input_${theme}` : ''"
                    v-bind:value="value"
                    v-on:input="$emit('input', $event.target.value)"
                    v-on:keyup.enter="enter"
                    v-on:blur="$emit('blur', $event.target.value)"
                    :placeholder="placeholder"
                    :name="name"
                />
                <span 
                    class="mask-control cursor-pointer"
                    @click="toggleMask"
                >
                    <p-icon
                        class="pa-pb-8"
                        icon="eye-off"
                        color="grey-500"
                    >
                    </p-icon>
                </span>
            </div>
            <span 
                @click="toggleEdit"
                v-if="hasCancelButton"
                class="pa-pl-8 pa-pt-8 pa-txt_sm pa-txt_grey-500 cursor-pointer" 
            >
                Cancel
            </span>
        </div>
        <div class="pa-mt-6" v-else>
            <span>**********</span>
            <span 
                @click="toggleEdit" 
                class="cursor-pointer"
            >
                <p-icon
                    class="pa-pb-4"
                    icon="pencil"
                    color="blue"
                >
                </p-icon>
            </span>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        value: String,
        enter: {
            type: Function,
            'default': () => {},
        },
        theme:String,
        name: String,
        showSave: {
            type: Boolean,
            'default': false,
        },
        saveData: {
            type: String,
            'default': ''
        },
        placeholder: {
            type: String,
            'default': ''
        },
        hasValueSaved: {
            type: Boolean,
            'default': false
        },
        size: {
            type: String,
            'default': ''
        },
    },

    computed: {
        hasCancelButton() {
            if(this.hasValueSaved) {
                return true;
            }

            return this.originalValue != '' && this.originalValue != null;
        },

        inputSize() {
            if(this.size == 'xl') {
                if(this.hasCancelButton) {
                    return 'pa-input-xl';
                } else {
                    return 'pa-input-xl-no-cancel';
                }
            }
        }
    },

    data: function() {
        return {
            isEditEnabled: false,
            isMasked: true,
            originalValue: this.value,
        }
    },

    methods: {
        toggleEdit() {
            this.isEditEnabled = !this.isEditEnabled;
            if(this.isEditEnabled == false) {
                Vue.nextTick(() => {
                    this.isMasked = true;
                    this.$emit('input', this.originalValue)
                    this.$emit('blur', this.originalValue)
                });
            } else {
                this.value = '';
            }
        },
        
        toggleMask() {
            this.isMasked = !this.isMasked;
        },

        broadcastSave() {
            if(this.saveData != '') {
                window.app.rootVue.$broadcast('masked-input:saved', this.saveData);
            } else {
                window.app.rootVue.$broadcast('masked-input:saved');
            }
        },

        getIsEditEnabled() {
            return this.isEditEnabled;
        }
    },

    mounted: function() {
        if((this.value == null || this.value == '') && !this.hasValueSaved) {
            this.toggleEdit();
        }
    }
});
</script>
