<style>
    .counter-widget {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .counter-widget--no-chart {
        flex-grow: 1;
        align-self: center;
        overflow: hidden;
    }
    .counter-widget-chart-container {
        height: 40%;
        width: 100%;
        position: absolute;
        bottom: 0;
    }
    .fitty {
        display: inline-block;
        white-space: nowrap;
        line-height: 1;
        margin: 0;
    }
    .white-resizeable-icon .ui-resizable-se, .white-resizeable-icon .ui-resizable-sw {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgIDxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiLz4gICAgPHBhdGggZD0iTTIuMjUgOC4zMjV2LTUuODVjMC0uMTUuMDc1LS4yMjUuMjI1LS4yMjVIOC40Yy4wNzUgMCAuMjI1LjA3NS4yMjUuMjI1VjMuNmMwIC4wNzUtLjA3NS4yMjUtLjIyNS4yMjVMMy43NSAzLjc1djQuNTc1YzAgLjA3NS0uMDc1LjIyNS0uMjI1LjIyNWgtMS4wNWMtLjE1IDAtLjIyNS0uMDc1LS4yMjUtLjIyNXptMTcuOTI3IDEydi0xLjM1TDUuMTEgMy43NzIgMy43NSAzLjc1VjUuMWwxNS4yMjUgMTUuMjI1aDEuMjAyem0xLjE4My00LjhoLS45NmMtLjA3NSAwLS4yMjUuMDc1LS4yMjUuMjI1djQuNTc1aC00LjY1Yy0uMDc1IDAtLjIyNS4wNzUtLjIyNS4yMjV2MS4xMjVjMCAuMDc1LjA3NS4yMjUuMjI1LjIyNWg1LjkyNWMuMTUgMCAuMjI1LS4wNzUuMjI1LS4yMjVWMTUuNzVjMC0uMjI2LS4xNjUtLjIyNS0uMzE1LS4yMjV6IiBmaWxsPSIjZmZmZmZmIi8+ICA8L2c+PC9zdmc+) !important;
        margin: 6px !important;
        transform: scale(.75) !important;    
    }
    .counter-widget a.fitty, .metric-value-wrapper a.fitty {
        color: inherit;
    }


</style>

<template>
    <div :id="id"
        class="grid-stack-item white-resizeable-icon"
        :data-gs-x="localConfig.col.toString()"
        :data-gs-y="localConfig.row.toString()"
        :data-gs-width="localConfig.width.toString()"
        :data-gs-height="localConfig.height.toString()"
        :data-gs-auto-position="false"
        :data-gs-locked="true">
        <div
            class="grid-stack-item-content"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div
                style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;"
                :style="{
                    backgroundColor: !hasSelectedBackgroundColor ? widgetBackgroundColor : null,
                    color: hasSelectedBackgroundColor ? textColor : content.count === 0 ? 'black' : 'white',
                }"
            >
                <div class="pa-m-0" :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered}">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_9of12" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <p-icon
                                    icon="drag-vertical"
                                    class="drag-icon"
                                    color="light"
                                >
                                </p-icon>
                            </span>
                            <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: (hasSelectedBackgroundColor || isEditingAndHovered) ? textColor : content.count === 0 ? 'black' : 'white'}">
                                <use xlink:href="#spinner-spin-naked"></use>
                            </svg>
                            <p-tooltip2 :normal-white-space="true"> 
                                <span slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: (hasSelectedBackgroundColor || isEditingAndHovered) ? textColor : content.count === 0 ? 'black' : 'white' }">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }}</span>
                            </p-tooltip2>
                        </div>
                        <div class="pa-grid-col pa-grid-col_3of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button                                 
                                    v-show="showWidgetControls" 
                                    type="button"
                                    slot="trigger"
                                    @click="cloneWidget" 
                                    class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mr-6 pa-mt-6"
                                >
                                    <p-icon icon="copy" ></p-icon>
                                </button>
                                <span> Clone this Widget</span>
                            </p-tooltip2>
                        </div>
                    </div>
                </div>
                <div v-show="displayChart" :style="widgetBodyStyle" ref="body">
                    <div class='counter-widget' :style="{ 'height': '50%', 'width': '100%' }">
                        <span
                            class="pa-txt_medium"
                            :style="{
                                'font-size': 2.125 * localConfig.height + 'em',
                                'line-height': '1em',
                                color: !hasSelectedBackgroundColor ? null : textColor
                            }"
                        >
                            <a :href="incidentHubFiltersUrl" target="_blank" class="fitty pa-txt_medium">
                                {{ incidentCount }}
                            </a>
                        </span>
                    </div>
                    <div class="counter-widget-chart-container">
                        <div ref="chart" style="height: 100%; width: 100%; position: absolute;"></div>
                    </div>
                </div>
                <div
                    v-if="valid && !displayChart"
                    class="widget-body pa-mx-10 pa-flex"
                    style="overflow-y: hidden; height: 100%; background-color: inherit;"
                    ref="body2"
                >
                    <div class="metric-widget">
                        <div class="metric-value-wrapper block" :style="{ color: !hasSelectedBackgroundColor ? null : textColor }">
                            <a :href="incidentHubFiltersUrl" target="_blank" ref="incident_count" class="fitty pa-txt_medium">
                                {{ incidentCount }}
                            </a>
                        </div>
                    </div>
                </div>
                <div v-if="!valid" class="widget-body widget-body_error" :title="error_message" ref="body">
                    <p class="pa-message pa-message_error">
                        <svg class="pa-icon pa-icon_xl"><use xlink:href="#alert-circle"></use></svg>
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
    import Vue from 'vue';
    import fitty from "fitty";
    import widgetMixin from './../widgetMixin';

    const Highcharts = require('highcharts');
    require('highcharts/modules/no-data-to-display')(Highcharts);

    const OutageCounterWidget = Vue.extend({
        mixins: [widgetMixin],

        data() {
            return {
                chart: null,
                displayChart: false,
                content: {
                    count: 0,
                    background: '#ffffff',
                    color: '#000000',
                    hasCritical: false,
                },
                fitty: null,
                localConfig: this.config,
            };
        },

        events: {
            // Mixin: 'widget:new_data' -> this.initialize(config, content)
            // Mixin: 'widget:updated_data' -> this.update(content)
            // Mixin: 'widget:pend_config' -> this.pendNewConfig().

        },

        watch: {
            displayChart: function(val, oldVal) {
                if (oldVal !== val && this.chart) {
                    this.chart.reflow();
                    Vue.nextTick(() => {
                        this.initFitty();
                    });
                }
            },
            chart: function(val, oldVal) {
                if (!oldVal && val && this.chart) {
                    this.chart.reflow();
                    Vue.nextTick(() => {
                        this.initFitty();
                    });
                }
            },
            incidentCount() {
                Vue.nextTick(() => {
                    this.initFitty();
                });
            },
        },

        computed: {
            incidentHubFiltersUrl() {
                const filters = {
                    outage_status: this.isResolvedOutageWidget ? "resolved" : "confirmed",
                    metric_textkeys: [],
                    instance_tags: [],
                    severity: this.localConfig.custom_controls.severity,
                    incident_tags: this.localConfig.custom_controls.incident_tags,
                    server_group_id: this.localConfig.server_groups,
                    end_date:''
                };  

                if (this.isResolvedOutageWidget && this.localConfig.timescale) {
                    let hours = 0;
                    switch(this.localConfig.timescale) {
                        case '15min':
                            hours = 0.25;
                            break;
                        case 'hour':
                            hours = 1;
                            break;
                        case '6hr':
                            hours = 6;
                            break;
                        case '12hr':
                            hours = 12;
                            break;
                        case 'day':
                            hours = 24;
                            break;
                        case 'week':
                            hours = 7 * 24;
                            break;
                        case 'month':
                            hours = 30 * 24;
                            break;
                        case 'year':
                            hours = 365 * 24;
                            break;
                    }

                    if(hours > 0) {
                        let currentDate = this.getCurrentDate();
                        let start_date = currentDate.subtract(hours, 'h');
                        start_date = start_date.format('MM/DD/YYYYTHH:mm');
                        filters.start_date = start_date;
                    }
                }

                if (this.localConfig.custom_controls.server_tags) {
                    filters.instance_tags = [...this.localConfig.custom_controls.server_tags];
                }

                if (this.localConfig.metric_textkeys) {
                    filters.metric_textkeys = Object.values(this.localConfig.metric_textkeys).reduce(
                        (acc, curr) => {
                            const entries = Object.entries(curr);

                            if (entries.length) {
                                for(let i in entries) {
                                    const [category, values] = entries[i];
                                    acc.push(...values.map(value => `${category}#${value}`));
                                }
                            }

                            return acc;
                        }, []
                    ).flat();
                }

                const urlIfyFilters = Object.entries(filters)
                    .map(([key, value]) => {
                        if (value && value.length) {
                            return `${encodeURIComponent(key)}=${encodeURIComponent(
                                value && Array.isArray(value) ? value.join(",") : value
                            )}`;
                        } else {
                            return undefined;
                        }
                    })
                    .filter(value => value)
                    .join("&");

                return `/incidents/?${urlIfyFilters}`;
            },
            widgetBackgroundColor() {
                return this.content.count === 0
                    ? "white"
                    : this.isResolvedOutageWidget
                    ? "#3cb588"
                    : this.content.hasCritical
                    ? "#e31212"
                    : "#ffd666";
            },

            isResolvedOutageWidget() {
                return this.$options._componentTag === "p-resolvedoutage-widget";
            },

            incidentCount() {
                return this.content.count;
            },

            widgetBodyStyle: function() {
                return {
                    height: "100%",
                    width: "100%",
                };
            }
        },

        methods: {
            initFitty() {
                if (!this.$refs.body2) {
                    return;
                }

                if (this.localConfig.height === 1) {
                    this.fitty = null;
                    this.fitty = fitty(this.$refs.incident_count, {
                        maxSize: 16
                    });
                } else {
                    this.fitty = null;
                    const metricWidget = this.$refs.body2;
                    const maxSize = Math.min(Math.min(metricWidget.clientHeight, metricWidget.clientWidth), 512);
                    this.fitty = fitty(this.$refs.incident_count, { maxSize });
                }

                Vue.nextTick(() => {
                    this.fitText();
                });
            },

            openIHubwithEndDate(end_date) {
                var current_date = this.getCurrentDate();
                var current_year = current_date.year();
                current_date = current_date.format('DD/MM/YYYY HH:mm');                                
                if (end_date) {
                    window.open(this.incidentHubFiltersUrl + "&end_date="+end_date.point.category+"/"+current_year,"blank")
                }
            },

            fitText() {
                // Need to force reset the font size so it calculates
                // the size better when we call .fit()
                const resetFontSize = "font-size: 16px;";

                if (this.$el && this.fitty && !this.displayChart) {
                    this.fitty.element.style = resetFontSize;
                    this.fitty.fit();
                }
            },

            maxIncidentCountSize() {
                if (this.$el && this.localConfig) {
                    const el = this.$el.querySelector('.widget-body');
                    return el.clientHeight - 50;
                }
            },

            getConfigModules() {
                return [
                    {type: 'p-overview-module'},
                    {type: 'p-instance-module', options: {defaultType: 'none'}},
                    {type: 'p-metric-module', options: {multiple: true, allSelectable: true}},
                    {type: 'p-incident-module'}
                ];
            },

            getOptionModules() {
                const custom_controls = [
                    {
                        label: 'Severity Levels',
                        key: 'severity',
                        type: 'p-select',
                        multiple: false,
                        options: [
                            {value: 'critical', label: 'Critical'},
                            {value: 'warning', label: 'Warning'},
                            {value: 'all', label: 'All'}
                        ],
                        default: 'all',
                    },
                    {
                        id: 'backgroundColor',
                        label: 'Background Color',
                        key: 'backgroundColor',
                        type: 'color-picker',
                    },
                ];
                if (this.$options._componentTag === 'p-activeoutage-widget') {
                    return [
                        {
                            type: 'p-custom-module',
                            options: {
                                ...(this.$parent.isScoping() ? {"ignore-scope-module": {}} : {}),
                                title: 'Incident Filter', 
                                controls: custom_controls
                            }
                        },
                        {
                            type: 'p-custom-module',
                            options: {
                                hideHeader: true,
                                title: 'Widget Formatting',
                                controls: [{
                                    label: 'Hide Acknowledged Incidents',
                                    key: 'hide_acknowledged',
                                    type: 'p-switch',
                                    'default': false,
                                }, {
                                    label: 'Hide Incidents Under Maintenance',
                                    key: 'hide_maintenance',
                                    type: 'p-switch',
                                    'default': false,
                                }, {
                                    label: 'Hide Incident History',
                                    key: 'hide_incident_history',
                                    type: 'p-switch',
                                    'default': true,
                                }]
                            }
                        },
                    ]
                } else {
                    return [
                        {
                            type: 'p-custom-module',
                            options: {
                                ...(this.$parent.isScoping() ? {"ignore-scope-module": {}} : {}),
                                "timerange-module": {},
                                title: 'Widget Formatting',
                                controls: custom_controls
                            }
                        },
                    ];
                }
            },

            // Open configure drawer
            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this);
            },            

            createChart() {
                const chart_colors = {
                    border: '#e6e6e6',
                    critical: '#f47676',
                    warning: '#ffd666',
                };

                let categories = [];
                let criticals = [];
                let warnings = [];

                if (this.content.history_events) {
                    this.displayChart = true;

                    this.content.history_events.forEach((event, index) => {
                        const [date, incidents] = event;

                        let { critical, warning } = incidents;

                        if (critical < 1 && warning > 0) {
                            critical = null;
                        }

                        if (warning < 1) {
                            warning = null;
                        }

                        categories.push(this.formatDate(date));
                        criticals.push(critical);
                        warnings.push(warning);
                    });
                } else if (this.displayChart) {
                    this.displayChart = false;
                }

                const chart_data = [
                    {
                        name: 'Criticals',
                        data: criticals,
                        color: chart_colors.critical,
                        borderWidth: 1,
                        borderColor: '#e31212',
                    },
                    {
                        name: 'Warnings',
                        data: warnings,
                        color: chart_colors.warning,
                        borderWidth: 1,
                        borderColor: '#ffbb00',
                    }
                ];
                const chart_config = {                    
                    chart: {
                        type: 'column',
                        spacing: [0, 18, 18, 18],
                        backgroundColor: this.widgetBackgroundColor,        
                    },
                    plotOptions: {
                        timezone: this.getTimeZone(),  
                    },
                    exporting: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: ''
                    },
                    xAxis: {
                        categories: categories,
                        labels: {
                            enabled: false
                        },
                        lineWidth: 0,
                        tickWidth: 0,
                        title: {
                            enabled: true,
                            text: 'Incidents',
                            style: {
                                fontSize: '13px',
                                color: 'white',
                                fontWeight: 'bold',
                            }
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            enabled: false,
                        },
                        gridLineColor: null,
                        labels: {
                            enabled: false
                        },
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                            pointWidth: null,
                        },
                        series: {
                            borderWidth: 0,
                            groupPadding: 0,
                            pointPadding: 0.1,
                            minPointLength: 2,
                            cursor:'pointer',
                            events: {
                                click: (e) => {
                                    this.openIHubwithEndDate(e);
                                },
                            },
                        }
                    },
                    tooltip: {
                        shadow: {
                            color: 'rgb(62, 64, 65)',
                            opacity: 0.1,
                            offsetX: 0,
                            offsetY: 1,
                            width: 4,
                        },
                        backgroundColor: '#ffffff',
                        borderWidth: 0,
                        borderRadius: 5,
                        padding: 6,
                        useHTML: true,
                        headerFormat: '',
                        formatter: function() {
                            const ret = `
                            <div class='pa-flex'>
                                <span style='font-size: 22px; color: ${this.point.color}; line-height: 11px;'>
                                    \u25CF
                                </span>
                                <div style='padding-left: 6px; padding-right: 20px;'>
                                    <p class='pa-txt_medium' style='color: #3c3d3e; margin-bottom: 4px;'>
                                        ${this.x}
                                    </p>
                                    <p class='pa-txt_normal'>
                                        ${this.series.name}: ${this.y}<br/>Total: ${this.point.stackTotal}
                                    </p>
                                </div>
                            </div>
                            `;
                            return ret;
                        },
                    },
                    series: chart_data
                };

                // The columns would overlap each other and be uneven when the widget got to this width
                if (this.config.width === 2) {
                    chart_config.plotOptions.column.pointWidth = 1;
                    chart_config.plotOptions.series.pointPadding = 0;
                }

                if (this.$refs.chart) {
                    setTimeout(() => {
                        this.chart = Highcharts.chart(this.$refs.chart, chart_config);                     
                    }, 1000);
                }
            },
            formatDate(date) {
                // Just to make it look a little nicer in the graph
                date = moment.tz(date, this.getTimeZone()).format("YYYY-MM-DD");
                return date.split('-').slice(1).join('/');
            },
            // Mixin: configure() -- opens configure modal
            initialize(config, content) {
                if (this.$refs && this.$refs.body) {
                    this.$refs.body.style.filter = '';
                }
                this.localConfig = {...config};
                this.content = content;
                this.reconfig();
            },
            reconfig() {
                this.createChart();
                if (!this.displayChart) {
                    Vue.nextTick(() => {
                        this.initFitty();
                    });
                }
            },
            update(content) {
                this.content = content;
                Vue.nextTick(() => {
                    this.initFitty();
                });
            },
            dumpState() {
                const obj = Object();
                obj.data = this.$data;
                obj.config = {...this.localConfig};
                console.log(JSON.stringify(obj));
            },
            getCurrentDate() {
                let timezone = this.getTimeZone();
                let currentDate = window.moment().tz(timezone);
                return currentDate;
            },

            getTimeZone() {
                let timezone = this.$parent.timezone;//window.userTZ;
                if(timezone == "UTC/UTC"){
                    timezone = "UTC";
                }
                if (!timezone) {
                    if (window.userTZ) {
                        timezone = window.userTZ;
                    } else {
                        timezone = moment.tz.guess();
                    }
                }
                timezone = timezone == 'UTC/UTC' ? 'UTC' : timezone;
                return timezone;
            }
        },

        props: {
            // Mixin: id:Number, config:Object, title:String
        },

        mounted() {
            Vue.nextTick(() => {
                this.initFitty();
            });
            // Mixin: dispatch('widget:domready')            
            this.pendNewConfig();
            if (this.id < 0) { this.configure(); };
        },
    });

export default OutageCounterWidget;
</script>
