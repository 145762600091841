<style>
.no-sort::after { display: none!important; }

.no-sort { pointer-events: none!important; cursor: default!important; }
 .widget-table {
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
 }
 .widget-body.summary-table {
     overflow: hidden;
     height: 100%;
     margin-bottom: 0px;
 }
 .widget-body.summary-table > div {
     height: 100%;
 }
 .widget-body.summary-table #table-filters, .widget-body.summary-table #table-buttons {
     margin-bottom: 0px !important;
 }
 .widget-body.summary-table .dataTables_wrapper {
     height: 100%;
 }
 .widget-body.summary-table .dataTables_scroll {
     height: 100%;
 }
 .widget-body.summary-table .dataTables_wrapper .dataTables_paginate {
     padding-top: 10px !important;
     padding-bottom: 0px !important;
     border-top: .5px solid #edeff0;
     display: none;
 }
 .widget-body.summary-table td {
     padding-top: 5px;
     padding-bottom: 5px;
 }
 th.hidden, td.hidden {
     padding: 0;
     width: 0px !important;
 }
.summary-table {
    margin-left: 20px;
    margin-right: 20px;
}
.summary-table .dataTables_filter {
    display: none;
}
.summary-table-actions .pa-search {
    height: 2rem;
}
.summary-table-actions .pa-search-box {
    border: none;
    border-radius: 0;
    height: 2rem;
}
.summary-table-actions .pa-search-box-input {
    background-color: white;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    height: 32px;
    max-width: 224px;
    width: 100%;
}
.summary-table-actions .pa-search-btn {
    border: none;
}
.summary-table-actions .pa-search-btn-icon {
    fill: #848e99;
}
.summary-table .dts_label {
    display: none !important;
}
.summary-table table.dataTable {
    border-collapse: collapse;
}
.summary-table table.dataTable thead th.hidden-column {
    border: none;
}
.summary-table table.dataTable thead th,
.summary-table table.dataTable tfoot th {
    border-top: 1px solid #e6e6e6;
    box-shadow: 1px 0 0 0 #e6e6e6;
    padding: 7px;
    }
.summary-table table.dataTable tbody tr td {
    border-left: 1px solid #e6e6e6;
    padding: 8px;
}
.summary-table table.dataTable tbody tr:first-of-type td {
    border-top: none !important;
}
.summary-table table.dataTable thead tr th {
    border-bottom: 1px solid #e6e6e6;
    box-shadow: -1px 0 0 0 #e6e6e6;
}
.summary-table table.dataTable thead tr th:not(.hidden) ~ th {
    border-left: none;    
}
.summary-table table.dataTable .hidden {
    display: none;
}
.summary-table .status-column,
.summary-table .short-width-column{
    /*
        This width keeps these cells as small as possible
        with a little extra padding.
    */
    text-align: center;
}
.summary-table .dataTables_wrapper .dataTables_scroll div.dataTables_scrollHead {
    width: 100% !important;
}
.summary-table .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
    width: 99% !important;
    padding-bottom: 5px;
    height: auto !important;
}
.summary-table .dataTables_wrapper .dataTables_scroll div.dataTables_scrollHead table {
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
}
.summary-table .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody table {
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    box-sizing: border-box;
}

</style>
<template>
    <div :id="id"
         :class="!inModal ? 'grid-stack-item' : 'modal-view'"
         :data-gs-x="localConfig.col.toString()"
         :data-gs-y="localConfig.row.toString()"
         :data-gs-width="localConfig.width.toString()"
         :data-gs-height="localConfig.height.toString()"
         :data-gs-auto-position="false"
         :data-gs-locked="true">
        <div
            :class="!inModal ? 'grid-stack-item-content' : 'modal-view-content'"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
                'z-index': 'auto !important',
            }"
        >
            <div style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;">
                <div :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered, 'expand-mode' : !$parent.editing && isHovered && !inModal, 'in-modal': inModal}">
                    <div class="pa-grid pa-align-center">
                        
                            <div  class="pa-grid-col pa-grid-col_7of12" style="flex-direction: row;">
                                <span v-if="$parent.editing">
                                    <svg class="pa-icon drag-icon">
                                        <use xlink:href="#drag-vertical"></use>
                                    </svg>
                                </span>
                                <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: textColor}"><use xlink:href="#spinner-spin-naked"></use></svg>
                                <p-tooltip2 :normal-white-space="true">
                                    <span v-if="!isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: textColor }">
                                        <span v-if="localConfig.title">{{ localConfig.title }}</span>
                                    </span>
                                    <span v-if="isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate">
                                        <span v-if="localConfig.title">{{ localConfig.title }}</span>
                                    </span>
                                    <span> {{ localConfig.title }}</span>
                                </p-tooltip2>
                            </div>
                            
                        
                        <div class="pa-grid-col pa-grid-col_5of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger"  v-show="!$parent.editing && isHovered && !inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#arrow-expand"></use>
                                    </svg>
                                </button>
                                <span> Expand </span>
                            </p-tooltip2>
                            <button slot="trigger"  v-show="!$parent.editing && inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                <svg class="pa-icon remove-icon">
                                    <use xlink:href="#close"></use>
                                </svg>
                            </button>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mr-6 pa-mt-6">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#trashcan"></use>
                                    </svg>                                    
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mt-6">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button 
                                    v-show="showWidgetControls && !inModal" 
                                    type="button"
                                    slot="trigger"
                                    @click="cloneWidget" 
                                    class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mr-6 pa-mt-6"
                                >
                                    <p-icon icon="copy"></p-icon>
                                </button>
                                <span>Clone this Widget</span>
                            </p-tooltip2>
                            <p-search
                                style="width:100%;margin-right:8px;"
                                :model="searchValue"
                                :on-change="handleSearchChange"
                                :is-active="true"
                                placeholder="">
                            </p-search>
                        </div>
                    </div>
                </div>
                
                <div 
                    v-show="valid && !needsConfig" 
                    id="body" 
                    class="widget-body summary-table" 
                    style="flex-grow: 1; margin-bottom: 20px;"
                    :style="{'height': inModal && '74vh'}"
                    ref="body"
                >
                    <div :style="`height: ${bodyHeight}px;`">
                        <p-table2 
                            :column-renderers="columnRenderers"
                            :column-classes="{
                                0: 'hidden',
                                'status-column': 'short-width-column',
                                'metricstatus-column': 'short-width-column',
                            }"
                            :column-callbacks="columnCallbacks"
                            :empty-message="tableType === 'server' ? 'No instances match your filter.' : ''"
                            :paging="true"
                            :avoid-first-table="true"                            
                            :scroll-y="`${bodyHeight}px`"
                            ref="data_table"
                            :ordering="true"
                            :sort-column="sortColumnIndex"
                            :sort-direction="sortDirection"
                            :scroller="true"
                            :defer-render="true"
                            :scroll-collapse="false"
                            :filtering="true">
                            <table slot="table" class="pa-table widget-table">
                                <thead id="table_headers">
                                    <tr>
                                        <th :key="column" :id="classify_coumn_id(column)+'_'+index" v-for="(column,index) in columns" :class="classify(column) + '-column'">
                                            {{column}}
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </p-table2>
                    </div>                                        
                </div>
                <div v-if="needsConfig" :style="{'height': inModal && '74vh'}" class="widget-body" style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center;" ref="temp_body">
                    <div class="vertical-center">
                        <button v-if="$parent.allowEditing" @click="configure" class="pa-btn pa-btn_naked_light_grey_no_fill pa-btn_lg" style="padding: 12px 35px" type="button">
                            <svg class="pa-icon">
                                <use xlink:href="#plus-circle-outline"></use>
                            </svg>
                            <span>Configure</span>
                        </button>
                        <p 
                            v-if="tableType === 'offenders'" 
                            class="widget-unconfigured centered"
                        >
                            Please select a Metric.
                        </p>
                        <p 
                            v-if="tableType === 'userIncidents'" 
                            class="widget-unconfigured centered"
                        >
                            Please select Users.
                        </p>
                        <p v-if="tableType !== 'userIncidents' && tableType !== 'offenders'" 
                            class="widget-unconfigured centered"
                        >
                            <template v-if="configMessage">
                                {{ configMessage }}
                            </template>
                            <template v-else>
                                Please select an instance filter.
                            </template>
                        </p>
                    </div>
                </div>
                <div v-if="!valid" :style="{'height': inModal && '85vh'}" class="widget-body widget-body_error" :title="error_message" ref="body">
                    <p class="pa-message pa-message_error">
                        <svg class="pa-icon pa-icon_xl"><use xlink:href="#alert-circle"></use></svg>
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Vue from 'vue';
    import $ from 'jquery';
    import widgetMixin from './../widgetMixin';

    const SummaryTableWidget = Vue.extend({
        mixins: [widgetMixin],

        data() {
            return {
                searchValue: '',
                content: {
                    columns: {},
                    column_order: [],
                    rows: {},
                },
                columns: [],
                columnCallbacks: {
                    'status-column': (td) => {
                        this.compileEl(td);
                    },
                    'metricstatus-column': (td) => {
                        this.compileEl(td);
                    },
                },
                columnDefs: [],
                sortColumn: '',
                sortColumnIndex: -1,
                sortDirection: '',
                localConfig: this.config,
                configMessage: null,
                bodyHeight: 0,
                inModal: false,
            };
        },

        computed: {            
            tableType: function () {
                if (this.$options._componentTag === 'p-serversummary-widget') {
                    return 'server';
                } else if (this.$options._componentTag === 'p-rollup-widget') {
                    return 'rollup';
                } else if (this.$options._componentTag === 'p-metricsummary-widget') {
                    return 'metric';
                } else if (this.$options._componentTag === 'p-worst-offenders-table-widget') {
                    return 'offenders';
                } else if (this.$options._componentTag === 'p-incidents-by-user-table-widget') {
                    return 'userIncidents';
                }
            },

            columnRenderers() {
                let result = {}
                // To Do also handle bandwidth - testing bandwidth capacity here for sorting
                const dynamicColumn = this.content.has_bandwidth_capacity_cols ? 'bandwidth_capacity' : this.renderNumericColumn;                    
                    result = {
                        0: 'hidden',
                        'service-column': this.renderServiceColumn,
                        'server-column': this.renderLinkColumn,
                        'fqdn-column': this.renderLinkColumn,
                        'instance-column': this.renderLinkColumn,
                        'servergroup-column': this.renderLinkColumn,
                        'instancegroup-column': this.renderLinkColumn,
                        'status-column': this.renderStatusColumn,
                        'metric-column': this.renderLinkColumn,
                        'metricstatus-column': this.renderStatusColumn,

                        'value-column': this.renderNumericColumn,
                        'metricvalue-column': this.renderNumericColumn,
                        'avgvalue24hours-column': dynamicColumn,
                        'avgvalue7days-column': dynamicColumn,
                        'minvalue24hours-column': dynamicColumn,
                        'minvalue7days-column': dynamicColumn,
                        'maxvalue24hours-column': dynamicColumn,
                        'maxvalue7days-column': dynamicColumn,

                        'availability24hours-column': 'availability',
                        'availability7days-column': 'availability',
                        'availability30days-column': 'availability',
                    }                

                const d = new Date();
                const thisYear = d.getFullYear();

                const years = [thisYear, thisYear - 1];
                const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july',
                    'august', 'september', 'october', 'november', 'december'];

                years.forEach((year) => {
                    months.forEach((month) => {
                        result['availability' + month + year + '-column'] = 'availability';
                    });

                    for (let i = 1; i < 5; i++) {
                        result['availabilityq' + i + year + '-column'] = 'availability';
                    }
                });

                return result;                
            }
        },

        events: {
            // Mixin: 'widget:new_data' -> this.initialize(config, content)
            // Mixin: 'widget:updated_data' -> this.update(content)
            // Mixin: 'widget:pend_config' -> this.pendNewConfig()
        },

        methods: {
            // Mixin: configure() -- opens configure modal
            initialize(config, content) {

                if (this.$refs.temp_body) {
                    this.$refs.temp_body.style.filter = '';
                } else {
                    this.$refs.body.style.filter = '';
                }
                this.showAllColumns();
                this.localConfig = {...config};                
                this.content = content;                

                if (!this.content.success) {
                    this.configMessage = null;
                    if(this.content.message) {
                        this.configMessage = this.content.message;
                    }
                    return;
                }                
                this.columns = content.column_order.slice(0);            
                let sortColumn = config.custom_controls.sort_column;
                let sortDirection = config.custom_controls.sort_direction;

                if(this.tableType === 'offenders' && 
                    (typeof sortColumn === 'undefined' || sortColumn == 'Value')) {
                        sortColumn = 'Value'
                        sortDirection = config.custom_controls.item_sort_order
                }
                if (sortColumn && sortDirection) {
                    this.sortDirection = sortDirection;
                    this.sortColumn = sortColumn;
                    for (var i = 0; i < this.columns.length; i++) {
                        var col = this.columns[i];
                        if (col == sortColumn) {
                            this.sortColumnIndex = i;
                            break
                        }
                    }
                }
                this.$nextTick(function() {
                    this.$refs.data_table.createTable();
                    this.hideColumns();
                    if (this.sortColumnIndex >= 0 && this.sortDirection) {
                        this.$refs.data_table.setSort(this.sortColumnIndex, this.sortDirection);
                    }
                    this.insertData();
                    this.changePageLen();
                    const data_points = this.getDataPoints();
                    window.app.rootVue.$broadcast('datapoints:set_points', data_points, this.content.columns, this.sortColumn, this.sortDirection);
                    window.app.rootVue.$broadcast('columngroups:set_groups', data_points, this.localConfig.custom_controls.data_point_config, this.sortColumn, this.sortDirection, this.id);
                    // Add a span element to attach the sort icon to
                    if(content.disable_sort_indexes) {
                        this.$refs.data_table.dataTable.columns().iterator('column', function (ctx, idx) {
                            if (idx != 0 && content.disable_sort_indexes.indexOf(idx)== -1) {
                                let header = $(this.$refs.data_table.dataTable.column(idx).header());
                                if (!header.find("span").length) {
                                    header.append('<span class="sort-icon"/>')
                                }
                                if (this.columns[idx]) {
                                    let id = '#'+this.classify_coumn_id(this.columns[idx])+'_'+idx
                                    const body = document.querySelector(id);
                                    if (body) {
                                        body.classList.remove('no-sort');
                                    }
                                }
                            } else {
                                if (this.columns[idx]) {
                                    let header = $(this.$refs.data_table.dataTable.column(idx).header());
                                    let id = '#'+this.classify_coumn_id(this.columns[idx])+'_'+idx
                                    const element = document.querySelector(id);
                                    if (element, id.includes(this.config.id)) {
                                        element.classList.add('no-sort');
                                    }
                                }
                            }
                        }.bind(this));
                    }
                    this.$refs.data_table.dataTable.scroller.measure();                    

                    if (config.custom_controls.item_limit_count) {
                        this.setPageLen(config.custom_controls.item_limit_count);
                    }

                    this.$nextTick(function() {
                        this.getBodyHeight();
                    });
                });
            },            

            handleSearchChange: function(value) {
                this.searchValue = value;
                this.$refs.data_table.search(value);
            },

            expandSelf() {
               this.inModal = !this.inModal
               setTimeout(() => {
                    this.getBodyHeight();
                    this.update(this.content)
                }, "400");
            },

            classify: function(column) {
                if (column === '') {
                    return 'hidden';
                }
                let retVal = column;
                retVal = retVal.replace(/ /g, '');
                retVal = retVal.replace(/:/, '');
                retVal = retVal.toLocaleLowerCase();                
                return retVal;
            },

            classify_coumn_id: function(column) {
                if (column === '') {
                    return 'hidden';
                }
                let retVal = column;
                retVal = retVal.replace(/ /g, '');
                retVal = retVal.replace(/:/, '');
                retVal = retVal.toLocaleLowerCase();                
                return retVal+'_'+String(this.config.id);
            },

            renderServiceColumn(...args) {
                // Metric summary table renders a link, rollup and server summary dont
                if (this.tableType === "metric") {
                    return this.renderLinkColumn(...args);
                } else {
                    const [data] = args;
                    return data;
                }
            },

            renderLinkColumn(data, type, row) {
                if (_.isNil(data) || data === 0 || data.url == "") {
                    return "";
                }

                if (type === 'display' && this.$parent.showLinks) {
                    if (Array.isArray(data)) {
                        return data.map(
                            ({ url, title = "" }) => url && (acl ? this.$parent.userCanView(acl) : true)
                                ? `<a href="${url}" target="_blank">${title}</a>`
                                : title
                        ).join(', ');
                    }

                    if (!data.title) {
                        return data || "";
                    }

                    if (!data.url) {
                        return data.title;
                    }

                    if (data.acl) {
                        // Our entry came with an acl check we need to verify before rendering the link.
                        // console.log(data.title + ' ' + data.url + ' ' + data.acl + ' ' + this.$parent.userCanView(data.acl))
                        if (!this.$parent.userCanView(data.acl)){
                            return data.title;
                        }
                    }

                    return `<a href="${data.url}" target="_blank">${data.title}</a>`;
                } else {
                    let retVal = data.title;
                    if (typeof retVal === 'undefined') {
                        retVal = '';
                    }
                    return retVal;
                }
            },

            renderStatusColumn: function(data, type, row) {
                if (type !== 'display') {
                    const statusMap = {
                        UP: 0,
                        WARN: 1,
                        CRITICAL: 2,
                    };
                    return statusMap[data.text] || 0;
                }
                if (data.text === 'UP') {
                    return '<span class="pa-badge_success pa-p-2">up</span>';
                }
                const badge = `<span class="pa-badge ${data.class}">${data.text}</span>`;
                let result = badge;
                if (data.template) {
                    result = `<a href="#" v-p-flyout-open template="${data.template}" :width="375">${badge}</a>`; // eslint-disable-line max-len

                    if (data.text === 'WARN' || data.text === 'CRITICAL') {
                        const iconClassName = data.text === 'WARN' ? 'warning' : 'critical';
                        return (
                            `<a href="#" v-p-flyout-open template="${data.template}" :width="375">
                                <svg class="pa-icon outage-icon ${iconClassName}">
                                    <use xlink:href="#${iconClassName}"></use>
                                </svg>
                            </a>`
                        );
                    }
                }
                return result;
            },

            renderNumericColumn: function(data, type, row) {
                if (type === 'display') {
                    return data;
                } else {
                    try {
                        if (typeof data === 'number') {
                            return data;
                        } else if (typeof data === 'string') {
                            const num = data.match(/[0-9.]+/);
                            if (num) {
                                return Number(num);
                            }
                            return data;
                        } else {
                            return data;
                        }
                    } catch (e) {
                        console.debug(e);
                        return data;
                    }
                }
            },

            showAllColumns() {
                for (var col in this.content.columns) {
                    
                    const column_id = this.content.column_order.indexOf(col);
                    this.$refs.data_table.showColumn(column_id);
                }
            },

            hideColumns() {
                let data_table_ref = this.$refs.data_table
                for (var col in this.content.columns) {
                    const column_id = this.content.column_order.indexOf(col);
                    if (this.content.columns[col].visible) {
                        data_table_ref.showColumn(column_id);
                    } else {
                        data_table_ref.hideColumn(column_id);
                    }
                }
            },

            toggleColumn(column) {
                const visible = this.content.columns[column].visible;
                this.content.columns[column].visible = !visible;
                return {
                    columns: this.content.columns,
                    column_order: this.content.column_order,
                };
            },

            setSort(column, direction) {
                this.sortColumn = column;
                this.sortDirection = direction;
                return {
                    columns: this.content.columns,
                    column_order: this.content.column_order,
                    sort_column: column,
                    sort_direction: direction,
                };
            },

            moveColumn(inActualIndex, inDesiredIndex) {
                // Take into account the two static columns.
                let staticOffset = 1;
                if (this.$options._componentTag === 'p-serversummary-widget') {
                    staticOffset = 2;
                }
                const actualIndex = inActualIndex + staticOffset;
                const desiredIndex = inDesiredIndex + staticOffset;
                const temp = this.content.column_order[desiredIndex];
                this.content.column_order[desiredIndex] = this.content.column_order[actualIndex];
                this.content.column_order[actualIndex] = temp;

                // Also move the column within datatables
                // Otherwise it doesn't recompute renderers
                this.$refs.data_table.moveColumn(actualIndex, desiredIndex);

                return {
                    columns: this.content.columns,
                    column_order: this.content.column_order,
                };
            },

            setColumnOrder(order) {
                if (this.$options._componentTag === 'p-serversummary-widget') {
                    var new_indexes = [0,1];
                    var untouchable_columns = ['', 'Instance'];
                } else if (this.$options._componentTag === 'p-metricsummary-widget') {
                    var new_indexes = [0];
                    var untouchable_columns = [''];
                }

                for (var i in order) {
                    var col = order[i];
                    new_indexes.push(this.content.column_order.indexOf(col));
                }
                this.$refs.data_table.setColumnOrder(new_indexes);

                this.content.column_order = untouchable_columns.concat(order);

                return {
                    columns: this.content.columns,
                    column_order: this.content.column_order,
                };
            },

            getDataPoints() {
                let columns = [];
                if (['p-serversummary-widget', 'p-metricsummary-widget'].includes(this.$options._componentTag)) {
                    columns = this.localConfig.custom_controls ? this.localConfig.custom_controls.data_point_order : null;
                } else if (
                    [
                        'p-rollup-widget', 
                        'p-worst-offenders-table-widget', 
                        'p-incidents-by-user-table-widget'
                    ].includes(this.$options._componentTag)
                ) {
                    columns = this.content.column_order.slice(1);
                }

                return columns;
            },

            insertData() {
               this.$refs.data_table.reloadTableData(this.content.rows);
            },

            changePageLen() {
                this.$refs.data_table.changePageLen(this.calculateTableRows());
            },

            setPageLen(length) {
                this.$refs.data_table.changePageLen(length);
            },

            update(content) {
                this.content = content;
                if (!this.content.success) {
                    this.configMessage = null;
                    if(this.content.message) {
                        this.configMessage = this.content.message;
                    }
                    return;
                }
                this.$refs.data_table.reloadTableData(this.content.rows);
            },
            getColumns() {
                return this.content.data_points;
            },

            reconfig() {
                if (!this.content.success) {
                    this.configMessage = null;
                    if(this.content.message) {
                        this.configMessage = this.content.message;
                    }
                    return;
                }
                let data_table_ref = this.$refs.data_table
                data_table_ref.changePageLen(this.calculateTableRows());
                data_table_ref.dataTable.scroller.measure();
            },

            calculateTableRows() {
                const tableArea = this.$el.querySelectorAll('.dataTables_scrollBody')[0];
                if (!tableArea) { return this.localConfig.height * 2; }
                const tableHeight = tableArea.offsetHeight;
                const rowHeight = 38; // TODO: reliable?

                return Math.floor(tableHeight / rowHeight);
            },

            _getOpenWidgetId() {
                return this.$parent.$refs.configdrawer.editingWidget.id;
            },

            getConfigModules() {
                const isScoping = this.$parent.isScoping();
                const severity_control = {
                    label: 'Severity Levels',
                    key: 'severity',
                    type: 'p-select',
                    multiple: false,
                    options: [
                        {value: 'critical', label: 'Critical'},
                        {value: 'warning', label: 'Warning'},
                        {value: 'all', label: 'All'}
                    ],
                    default: 'all',
                };

                const color_control = {
                    id: 'backgroundColor',
                    label: 'Background Color',
                    key: 'backgroundColor',
                    type: 'color-picker',
                    default: '#ffffff'
                };

                let custom_controls = [];
                if (this.tableType === 'userIncidents') {
                    custom_controls.push(severity_control);
                }
                custom_controls.push(color_control);
                
                const customModule = {
                    type: 'p-custom-module',
                    options: {
                        title: '',
                        controls: custom_controls,
                    }
                };

                if (this.tableType === 'server' || this.tableType === 'metric') {
                    let modules = [
                        { type: 'p-overview-module' },
                        { type: 'p-instance-module', open: true },
                        { type: 'p-metric-module', options: { multiple: true }, open: true },
                    ];

                    if (this.tableType === 'metric') {
                        modules.push({ type: 'p-sorting-module' });
                    }

                    if (isScoping) {
                        modules.push({ "ignore-scope-module": {} });
                    }

                    modules.push(customModule);

                    return modules;
                }

                if (this.tableType === 'offenders') {
                    let modules = [
                        { type: 'p-overview-module' },
                        { type: 'p-instance-module', open: true },
                        { 
                            type: 'p-metric-module', 
                            options: {
                                multiple: true,
                                sameUnit: true,
                            },
                            open: true 
                        },
                        { type: 'p-sorting-module' },
                    ];

                    if (isScoping) {
                        modules.push({ "ignore-scope-module": {} });
                    }

                    modules.push({ type: 'p-timerange-module', options: { includeYear: false } });
                    modules.push(customModule);

                    return modules;
                }

                if (this.tableType === 'userIncidents') {
                    return [
                        { type: 'p-overview-module' },
                        { type: 'p-instance-module', open: true },
                        { type: 'p-users-module',  options: {includeAllOption: true}},
                        { 
                            type: 'p-metric-module', 
                            options: {
                                multiple: false
                            },
                            open: true 
                        },
                        {type: 'p-incident-module'},
                        customModule
                    ];
                }

                let modules = [
                    {type: 'p-overview-module'},
                ];

                if (isScoping) {
                    modules.push({ type: "p-ignore-scope-module" });
                }

                modules.push(customModule);

                return modules;
            },

            getOptionModules() {
                if (this.tableType === 'server' || this.tableType === 'metric') {
                    return [
                        {type: 'p-columngroups-module', options: {}},
                    ];
                } else if (this.tableType === 'offenders' || this.tableType == 'userIncidents') {
                    return [
                        {type: 'p-datapoints-module', options: {}},
                    ];
                } else if (this.tableType === 'rollup') {
                    return [
                        {type: 'p-rollup-module', options: {}, open: true},
                        {type: 'p-datapoints-module', options: {}},
                    ];
                }
                return [];
            },

            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this);

                // Configuration events need to be sent on next tick, to give
                // time to Vue to update the DOM
                Vue.nextTick(() => {
                    const data_points = this.getDataPoints();
                    if (data_points) {
                        window.app.rootVue.$broadcast('datapoints:set_points', data_points, this.content.columns, this.sortColumn, this.sortDirection);
                        window.app.rootVue.$broadcast('columngroups:set_groups', data_points, this.localConfig.custom_controls.data_point_config, this.sortColumn, this.sortDirection);
                    }
                });
            },

            dumpState() {
                const obj = Object();
                obj.data = this.$data;
                obj.config = {...this.config};
                console.log(JSON.stringify(obj));
            },

            getBodyHeight() {
                if (this.$refs.body) {
                    const tableHeader = document.querySelector(".dataTables_scrollHead");
                    const tableHeaderHeight = tableHeader ? tableHeader.clientHeight: 0;
                    const offset = 20;
                    const bodyHeight = this.$refs.body.clientHeight - tableHeaderHeight - offset;
                    this.bodyHeight = this.inModal ? '74vh' : bodyHeight;
                }
            },


        },

        watch: {
            'localConfig.height'() {
                this.getBodyHeight();
            },
            bodyHeight(curr) {
                this.$nextTick(() => {
                    if (this.$refs.data_table.dataTable) {
                        const scrollBody = this.$el.querySelector(".dataTables_scrollBody");
                        if (scrollBody) {
                            scrollBody.style.maxHeight = this.inModal ? '75vh' : `${curr}px`;
                        }
                    }
                });
            },
        },

        props: {
            // Mixin: id:Number, config:Object, title:String
        },

        mounted() {
            this.pendNewConfig();
            if (this.id < 0) {
                this.configure();                
            };
            
        },
    });

    export default SummaryTableWidget;
</script>
