import VueRouter from 'vue-router';
import { routes } from "./routes";
import { useUserStore } from "../stores/userStore";


export const router = new VueRouter({
    mode: 'history',
    routes,
});

export const RouterPlugin = VueRouter;
