<style lang="scss">
    .dashboard-scoping {
        font-size: 0;

        .attr-pill {
            height: 24px;
            display: inline-flex;
            margin-left: 8px;

            .attr-name {
                background-color: #656f83;
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
                color: white;
                font-size: 12px;
                padding: 5px 8px;

            }

            .attr-value {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
                height: 24px;
                width: 100px;
                border-left-color: transparent;

                .pa-select-icon:before {
                    right: 0;
                }
            }
        }

        .attribute-scoping, .tag-scoping, .apply-scope {
            display: inline-block;
            vertical-align: middle;
        }

        .tag-scoping {
            border: solid 1px #ddd;

            .selected-tags {
                display: inline-block;
                margin-top: 5px;
            }

            .selected-tag {
                background: #c8cfed;
                color: #21316f;
                font-size: 12px;
                line-height: 15px;
                margin-left: 5px;
                padding: 5px 4px 5px 6px;
            }

            .awesomeplete {
                margin-left: 5px;
            }

            .tag-input {
                border: none;
                height: 21px;
                outline: none;
                padding: 5px 7px;
                width: 150px;
            }

            .add-tag-btn {
                display: inline-block;
                font-size: 12px;
                height: 25px;
                margin-left: 5px;
                padding: 5px 4px;
            }
        }

        .apply-scope {
            margin-left: 8px;
        }
    }
</style>

<template>
    <div class="dashboard-scoping">
        <div v-if="scopeType === 'attributes'" class="attribute-scoping">
            <div
                :key="attrType.id"
                v-for="(attrType, index) in attrTypes"
                class="attr-pill"
            >
                <div class="attr-name">
                    <span class="pa-txt_truncate inline-block">{{ attrType.name }}</span>
                </div>
                <p-select
                    v-if="attrType.count < typeAheadLimit"
                    class="attr-value"
                    :options="attrType.options"
                    :model="attrType.selected"
                    :multiple="true"
                    :on-change-callback="updateStrippedAttrs"
                    :searchable="true"
                    :list-width="300"
                    :glob-option="true"
                    :sticky-selected="true"
                    sticky-pos="top"
                >
                </p-select>
                <p-attribute-search
                    v-else
                    class="attr-value"
                    :options="attrTypeOptions[attrType.id].options"
                    :display-limit="typeAheadLimit"
                    :model="attrType.selected"
                    :on-change-callback="updateStrippedAttrs"
                    :list-width="300"
                    :glob-option="true"
                    :on-search="onAttributeSearch.bind(this, attrType, index)"
                    :is-fetching="attrTypeOptions[attrType.id].isFetching"
                    :on-apply-filter="applyFilter.bind(this, attrType, index)"
                    :on-clear-filter="clearFilter.bind(this, attrType, index)"
                >
                </p-attribute-search>
            </div>
        </div>
        <div v-show="scopeType === 'tags'" class="tag-scoping">
            <div v-if="tags.length" class="selected-tags">
                <ul class="pa-flex">
                    <div
                        v-for="(tag, index) in tags"
                        class="selected-tag inline-flex"
                    >
                        <span>{{ tag }}</span>
                        <p-button @click="removeTag(index)" unstyled>
                            <p-icon
                                icon="x"
                                size="sm"
                            >
                            </p-icon>
                        </p-button>
                    </div>
                </ul>
            </div>
            <input 
                class="tag-input"
                v-model="newTag"
                ref="tag-input"
                v-on:keydown.enter="addTag"
                v-on:keydown.tab="tagTab"
                placeholder="Scope Dashboard by Tag"
            />
        </div>
        <div class="apply-scope">
            <p-button
                v-if="dirty"
                @click="save"
                variant="blue-secondary"
                class="pa-btn_xs"
                style="padding: 8px 16px;"
            >
                <span class="pa-txt" style="font-size: 14px;">Apply Changes</span>
            </p-button>
        </div>
    </div>
</template>

<script>
    import Awesomplete from './../shared/vendor/awesomplete/awesomplete.js';
    import Vue from 'vue';
    import _ from 'lodash';

    export default Vue.extend({
        props: {
            scopeType: String,
            attrTypes: Array,
            tags: {
                type: Array,
                'default': () => [],
            },
        },

        data() {
            return {
                editing: false,
                tagSuggestions: [],
                newTag: '',
                attrTypeOptions: (this.attrTypes || []).reduce(
                    (acc, curr) => ({
                        ...acc,
                        [curr.id]: {
                            selected: [...curr.selected],
                            options: [],
                            isFetching: false,
                        },
                    }
                ), {}),
                typeAheadLimit: 2500,
                strippedAttrs: '',
                dirty: false,
                awesomplete: null,
            };
        },

        events: {
            'customerConfig:update'() {
                this.tagSuggestions = [...this.$root.customerConfig.allTags];
                        this.updateSuggestions();
            },
        },

        methods: {
            applyFilter(attributeType, index, data) {
                this.updateStrippedAttrs({
                    type: 'apply',
                    query: data.query,
                    attributeType: attributeType,
                });
            },

            clearFilter(attributeType, index, data) {
                this.updateStrippedAttrs({
                    type: 'clear',
                    query: data.query,
                    attributeType: attributeType,
                });
            },

            onAttributeSearch(attributeType, index, query) {
                let hasValidQuery = !(
                    !query ||
                    (query && query.trim() === "") ||
                    query.length < 3
                );

                if (query && query.trim() === "*") {
                    hasValidQuery = true;
                }

                this.attrTypeOptions = {
                    ...this.attrTypeOptions,
                    [attributeType.id]: {
                        ...this.attrTypeOptions[attributeType.id],
                        isFetching: true,
                    },
                };

                if (!hasValidQuery) {
                    this.attrTypeOptions = {
                        ...this.attrTypeOptions,
                        [attributeType.id]: {
                            ...this.attrTypeOptions[attributeType.id],
                            options: [],
                            isFetching: false,
                        },
                    };
                } else {
                    this.fetchAttributeOptions(attributeType, index, query);
                }
            },

            fetchAttributeOptions: _.debounce(function(attributeType, index, query) {
                $.ajax({
                    url: "/dashboardv2/fetchAttributeOptions",
                    type: 'GET',
                    data: {
                        dashboard_id: this.$parent.id,
                        query: query,
                    }
                }).done((response) => {
                    let options = [];

                    if (response && response.data && response.data.results) {
                        options = [...response.data.results];
                    }

                    this.attrTypeOptions = {
                        ...this.attrTypeOptions,
                        [attributeType.id]: {
                            ...this.attrTypeOptions[attributeType.id],
                            options: [...options],
                            isFetching: false,
                        },
                    };
                });
            }, 1000),

            updateStrippedAttrs(data) {
                const strippedAttrs = [];

                this.attrTypes.forEach(attr => {
                    if (attr && attr.id) {
                        const strippedAttr = {
                            id: attr.id,
                        };

                        if (attr.count < this.typeAheadLimit) {
                            strippedAttr.selected = [...attr.selected];
                        } else {
                            if (!_.isArray(data) && attr.id === data.attributeType.id) {
                                strippedAttr.pattern = (
                                    data.type === "clear" ||
                                    data.query === "*"
                                )
                                    ? ""
                                    : data.query;
                            } else {
                                strippedAttr.pattern = attr.selected.length
                                    ? attr.selected[0]
                                    : "";
                            }
                        }

                        strippedAttrs.push(strippedAttr);
                    }
                });
                this.strippedAttrs = strippedAttrs;
                this.setDirty();
            },

            setDirty() {
                this.dirty = true;
            },

            setClean() {
                this.dirty = false;
            },

            save() {
                if(this.scope_type == "tags") {
                    this.attrTypes = [];
                }
                this.$parent.setScopingData(this.scopeType, this.attrTypes, this.tags);
                this.$parent.setSessionScope();
            },

            addTag(val) {
                if (this.awesomplete.selected) {
                    return;
                }
                if (typeof val === 'string') {
                    this.tags.push(val);
                } else {
                    this.tags.push(this.newTag);
                }
                this.newTag = '';
                if (this.awesomplete) {
                    this.awesomplete.close();
                }
                this.setDirty();
            },

            tagTab() {
                if (this.awesomplete.selected) {
                    this.awesomplete.select();
                    return;
                }
                this.addTag();
            },

            removeTag(index) {
                this.tags.splice(index, 1);
                this.setDirty();
            },

            initializeAwesomplete() {
                if (this.awesomplete) {
                    return;
                }
                this.awesomplete = new Awesomplete(this.$refs['tag-input'], {
                    list: this.tagSuggestions,
                });
                this.$refs['tag-input'].addEventListener('awesomplete-selectcomplete', data => {
                    this.addTag(data.text.value);
                });
            },

            updateSuggestions() {
                if (!this.awesomplete) {
                    return;
                }
                this.awesomplete.list = this.tagSuggestions;
            },
        },

        vueReady() {
            setTimeout(() => {
                if (this.$root.customerConfig) {
                    this.tagSuggestions = [...this.$root.customerConfig.allTags];
                }
                this.initializeAwesomplete();
            }, 500);
        },
    });
</script>
