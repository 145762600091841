<template>
    <component :is="as" :class="computedClass">
        <slot></slot>
    </component>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        spaceBetween: Boolean,
        spaceAround: Boolean,
        alignCenter: Boolean,
        alignStart: Boolean,
        alignEnd: Boolean,
        justifyEnd: Boolean,
        justifyCenter: Boolean,
        justifyBetween: Boolean,
        as: {
            type: String,
            default: "div",
        },
        dir: {
            type: String,
            default: "row",
        },
        wrap: Boolean,
        noWrap: Boolean,
    },
    computed: {
        computedClass() {
            const className = {
                "pa-flex": true,
                "pa-space-between": this.spaceBetween,
                "pa-space-around": this.spaceAround,
                "pa-align-center": this.alignCenter,
                "pa-align-start": this.alignStart,
                "pa-align-end": this.alignEnd,
                "pa-justify-center": this.justifyCenter,
                "pa-justify-end": this.justifyEnd,
                "pa-justify-between": this.justifyBetween,
                "pa-flex-column": this.dir && this.dir === "column",
                "pa-flex-wrap": this.wrap,
                "pa-flex-nowrap": this.noWrap,
            };

            return className;
        },
    }
});
</script>
