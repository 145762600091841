<style>
    .pa-icon.sort-active {
        -webkit-transform: rotateX(180deg);
        -ms-transform: rotateX(180deg);
        transform: rotateX(180deg);
    }
</style>

<template>
    <div class="module">
        <div ref="body" v-show="open" transition="module-expand" class="module-body">
            <div class="pa-field pa-field_vertical">
                <div class="pa-field-hd">
                    <label for="template" class="pa-label">Data Points</label>
                </div>
                <div class="pa-field-bd">
                    <ol class="pa-vList">
                        <li :key="point" v-for="(point, index) in dataPoints" class="pa-txt_13">
                            <div style="text-align: left;">
                                {{point}}
                                <svg v-if="!options.hideReorder" @click="indexDown(index)" class="pa-icon pa-clickable">
                                    <use xlink:href="#chevron-up"></use>
                                </svg>
                                <svg v-if="!options.hideReorder" @click="indexUp(index)" class="pa-icon pa-clickable">
                                    <use xlink:href="#chevron-down"></use>
                                </svg>
                                <svg
                                    v-if="!options.hideToggle"
                                    @click="togglePoint(point)"
                                    class="pa-icon pa-clickable"
                                    :style="`fill: ${current_status[point] && current_status[point].visible ? '#4f5766' : '#d5d8df'};`"
                                >
                                    <use xlink:href="#eye"></use>
                                </svg>
                                <img v-if="!options.hideSort" :src="'/static/assets/newux/media/icons/sort_desc' + (sortColumn === point && sortDirection === 'desc' ? '' : '_disabled') + '.png'"
                                    class="pa-icon pa-clickable" style="cursor: pointer; margin-bottom: 3px;"
                                    @click="setSort(point, 'asc')"/>
                                <img v-if="!options.hideSort" :src="'/static/assets/newux/media/icons/sort_asc' + (sortColumn === point && sortDirection === 'asc' ? '' : '_disabled') + '.png'"
                                    class="pa-icon pa-clickable" style="cursor: pointer; margin-bottom: -3px;"
                                    @click="setSort(point, sortDirection === 'asc' ? 'desc' : 'asc')"/>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
 import Vue from 'vue';
 import moduleMixin from './../moduleMixin.js';

 const DataPoints = Vue.extend({
     mixins: [moduleMixin],

     data() {
         return {
             dataPoints: [],
             current_status: {},
             sortColumn: '',
             sortDirection: '',
         }
     },

     props: {
        options: {
            type: Object,
            default: function() {
                return {};
            },
        },
        open: {
            type: Boolean,
            default: true
        },
        editingWidget: {
            type: Object,
            default: function() {
                return {};
            },
        },
     },

     events: {
         'datapoints:set_points': function(points, columns, sortColumn, sortDirection) {
             this.dataPoints = points;
             this.current_status = columns;
             if (sortColumn.length && sortDirection.length) {
                 this.sortColumn = sortColumn;
                 this.sortDirection = sortDirection;
             }
         }
     },

     methods: {
         togglePoint: function(column){
             var actual_points = this.editingWidget.toggleColumn(column);
             this.current_status = actual_points.columns;
             this.sendConfig(actual_points);
         },

         indexDown: function(index){
             if (index === 0){
                 return undefined;
             }
             var result = this.editingWidget.moveColumn(index, index - 1);
             this.sendConfig(result);
             this.dataPoints = this.editingWidget.getDataPoints();
         },

         indexUp: function(index){
             if (index + 1 === this.dataPoints.length){
                 return undefined;
             }
             var result = this.editingWidget.moveColumn(index, index + 1);
             this.sendConfig(result);
             this.dataPoints = this.editingWidget.getDataPoints();
         },

         setSort: function(point, direction) {
             this.sortColumn = point;
             this.sortDirection = direction;
             var result = this.editingWidget.setSort(point, direction);
             this.sendConfig(result);
         },

         sendConfig: function(dataPoints) {
             this.$parent.updatePending(this.editingWidget.id);

             if (this.sortColumn && this.sortDirection) {
                 dataPoints.sort_column = this.sortColumn;
                 dataPoints.sort_direction = this.sortDirection;
             }
             var payload = {
                 widget_id: this.editingWidget.id,
                 data_points: JSON.stringify(dataPoints),
             }

             $.ajax({
                 url: '/dashboardv2/setDataPoints',
                 method: 'GET',
                 data: payload
             }).done(function(result){
                 if (result.success) {
                     window.app.rootVue.$broadcast('widget:pend_config', this.editingWidget.id);
                     if (result.newConfig) {
                         this.editingWidget.config = result.newConfig;
                     }
                 }
             }.bind(this))
         }
     },
     vueReady() {
         this.dataPoints = this.editingWidget.getDataPoints();
         if (window.dashboard && !window.dashboard.customerAddons['notification.escalation']) {
            const index = this.dataPoints.indexOf('Ack');
            if (index > -1) {
                this.dataPoints.splice(index, 1);
            }
         }
         this.current_status = this.editingWidget.content.columns;
         if (this.editingWidget.sortColumn.length && this.editingWidget.sortDirection.length) {
             this.sortColumn = this.editingWidget.sortColumn;
             this.sortDirection = this.editingWidget.sortDirection;
         }

         Vue.nextTick(() => {
             this.ready = true;
         });
     }
 });
 export default DataPoints;
</script>
