<template>
    <table class="catalog-table">

        <tr v-if="!noCategories">
            <th :style="{
                width: isTemplate
                    ? '41%'
                    : isOutbound
                    ? '39%'
                    : '34%'
            }">
                <label v-if="showCheckboxes" class="pa-option pa-mr-8" style="vertical-align: text-bottom;">
                    <input @click="checkAll" type="checkbox" class="pa-option-input" />
                    <span class="pa-option-icon"></span>
                </label>
                Metric
            </th>
            <th v-if="!isTemplate" style="width: 7%;">Status</th>
            <th style="width: 6%;">Freq</th>
            <th style="width: 38%;">Alerts</th>
            <th style="width: 10%;">Tags</th>
            <th v-if="!isOutbound" style="width: 5%;">Edit</th>
        </tr>

        <tr v-if="noCategories">
            <td :colspan="isTemplate ? 5: 6" style="border-top: solid 1px #dbdee0;">
                <p-text v-if="isOutbound" class="pa-txt_light pa-txt_centered" font-size="sm">
                    No outbound synthetics
                </p-text>
                <p v-if="!isOutbound && canAddMetrics" class="pa-txt pa-txt_light" style="text-align: center; margin: 10px 0;">Click <button style="margin:0px 7px 0px 7px;" class="pa-btn pa-btn_secondary pa-btn_secondary_inverse" @click="openServiceCard">Add Monitoring</button> to get started with monitoring this instance.</p>
            </td>
        </tr>

        <p-monitoring-catalog-table-body
            :key="c.name"
            v-for="c in filteredCategories"
            :server-id="serverId"
            :category="c"
            :is-template="isTemplate"
            :hasAgent="hasAgent"
            :is-container="isContainer"
            :can-add-metrics="canAddMetrics"
            :opened-categories="openedCategories"
            :checked="checked"
            :search-query="searchQuery"
            :show-checkboxes="showCheckboxes"
            @checked="handleChecked"
            @toggle-category="toggleCategory"
            @category-check="checkCategory"
            @show-docs="showDocs"
            @dismiss-category="dismissCategory"
            :is-outbound="isOutbound"
            :is-inbound="isInbound"
        >
        </p-monitoring-catalog-table-body>

    </table>
</template>

<script>
import Vue from 'vue';

import MonitoringCatalogTableBody from './MonitoringCatalogTableBody.vue';

export default Vue.extend({
    components: {
        'p-monitoring-catalog-table-body': MonitoringCatalogTableBody,
    },
    props: {
        isTemplate: {
            type: Boolean,
            default: false,
        },
        hasAgent: {
            type: Boolean,
            default: false,
        },
        isContainer: {
            type: Boolean,
            default: false,
        },
        canAddMetrics: {
            type: Boolean,
            default: false,
        },
        filteredCategories: {
            type: Array,
            default: () => [],
        },
        openedCategories: {
            type: Array,
            default: () => [],
        },
        checked: {
            type: Array,
            default: () => [],
        },
        serverId: {
            type: Number,
            default: null,
        },
        applications: {
            type: Object,
            default: () => {},
        },
        check: {
            type: Function,
            default: () => {},
        },
        searchQuery: {
            type: String,
            default: '',
        },
        showCheckboxes: {
            type: Boolean,
            default: false,
        },
        isOutbound: {
            type: Boolean,
            default: false,
        },
        isInbound: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        noCategories() {
            return (
                !this.filteredCategories ||
                !this.filteredCategories.length
            );
        },
    },
    methods: {
        checkAll(event) {
            this.$emit('check-all', event);
        },
        handleChecked(value) {
            this.$emit('checked', value);
        },
        toggleCategory(event, name) {
            this.$emit('toggle-category', event, name);
        },
        checkCategory(event, category) {
            this.$emit('category-check', event, category);
        },
        showDocs(category) {
            this.$emit('show-docs', category);
        },
        dismissCategory(textkey) {
            this.$emit('dismiss-category', textkey);
        },
        openServiceCard() {
            this.$broadcast('service-card:open');
        },
    },
});
</script>