<style lang="scss" scoped>
    .chip-list {
       justify-content: start;
    }
</style>

<template>
    <ul id="chip-list" class="pa-hList pa-mt-0 chip-list">
        <li
            :key="index"
            v-for="(chip, index) in filteredChips"
            class="pa-pr-4 pa-mt-0 pa-pt-10"
        >
            <p-toggle-chip
                :chip="chip"
            >
            </p-toggle-chip>
        </li>
    </ul>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        chips: Array,
        model: Array,
        chipType: {
            type: String,
            'default': () => 'chip',
        },
    },
    data: function() {
        return {
            localModel: this.model,
        }
    },
    watch: {
        localModel(curr, prev) {
            this.$emit('change', curr, prev);
        },
    },
    computed: {
        filteredChips() {
            return this.chips.filter(chip => chip && Boolean(chip.label));
        },
    },
    methods: {
        addModelValue(value) {
            this.localModel.push(value);
        },

        removeModelValue(value) {
            let newModel = []
            for(let i in this.localModel) {
                if(this.localModel[i] != value) {
                    newModel.push(this.localModel[i]);
                    
                }
            }
            this.localModel = newModel;
        }
    }
});
</script>