<script setup>
    import { ref, computed, onMounted, nextTick, watch } from 'vue';
    import _ from 'lodash';
    import {
        customerOptionsFilter,
        userOptionsFilter,
        setAdminBrand,
        setSuperuserCustomer,
        setSuperuserUser,
        bigBrandSelectCustomer,
        bigBrandServerSearch,
        getMasterAccount,
    } from "../api/services/RootService.js";

    const props = defineProps({
        brandData: Array,
        secondaryCustomerData: Array,
        bigBrand: Boolean,

        identityAnonymous: Boolean,
        canPerformBrand: Boolean,
        canPerformSite: Boolean,
        canPerformCustomer: Boolean,
        hasSecondaryUsers: Boolean,
        isSecondaryUser: Boolean,
        
        brandJinja: Number,
        customerId: Number,
        userId: Number,
    });

    const brand_data = ref(props.brandData);
    const customer_data = ref([]);
    const big_brand_customer_data = ref([]);
    const customer_filters = ref([
        {
            value: 'customer_id',
            label: 'By Customer ID'
        },
        {
            value: 'ip_fqdn',
            label: 'By IP/FQDN'
        }
    ]);
    const user_data = ref([]);
    const brand_desired = ref(props.brandJinja);
    const customer_desired = ref();
    const big_brand_customer_desired = ref();
    const customer_filter = ref('customer_id');
    const customer_query = ref('');
    const search_error = ref(false);
    const user_desired = ref(null);
    const user_hard_switch = ref(false);
    const customer_hard_switch = ref(false);
    const errors = ref({});

    // Watchers
    watch(brand_desired, (new_brand, old_brand) => {
        customer_desired.value = null;
        user_desired.value = null;
        user_data.value = [];
        getCustomerOptions(new_brand);
    });

    watch(customer_desired, (new_customer, old_customer) => {
        if (!customer_desired.value) {
            return;
        }
        getUserOptions(new_customer);
    });

    watch(big_brand_customer_desired, (new_customer, old_customer) => {
        if (props.bigBrand && new_customer) {
            big_brand_customer_data.value = [];
            getUserOptions(new_customer);
        }
    });

    watch(customer_query, (newVal, oldVal) => {
        big_brand_customer_data.value = [];
        if (!customerDynamicQuery.value || newVal == '') {
            search_error.value = false;
        }
    });

    watch(customer_filter, () => {
        big_brand_customer_data.value = [];
        big_brand_customer_desired.value = null;
        user_data.value = [];
        user_desired.value = null;
        search_error.value = false;
    });

    // Computed
    const showBigBrandCustomerResults = computed(() => {
        return big_brand_customer_data.value != []
    });

    const hasErrors = computed(() => {
        return _.size(errors.value) > 0;
    });

    const renderErrorTitle = computed(() => {
        let title = 'Switch unsuccessful, please make new selection';
        if(errors.value.source) {
            title.replace('Switch', errors.value.source + ' switch');
        }
        return title;
    });

    const brandOptions = computed(() => {
        if(!brand_data.value) {
            return [];
        }                
        let options_arr = [];
        brand_data.value.forEach(el => {
            let options_obj = {};
            options_obj['value'] = el[0];
            options_obj['label'] = `${el[1]}`;
            options_arr.push(options_obj);
        })

        return options_arr;
    });

    const customerSelectDisabled = computed(() => {
        return customer_data.value.length == 0 && !props.bigBrand ? true : false;
    });

    const userSelectDisabled = computed(() => {
        return user_data.value.length == 0 || customer_data.value.length == 0 && !big_brand_customer_desired.value ? true:false;
    });

    const userSelectDefaultText = computed(() => {
        return userSelectDisabled || !customer_desired.value ? 'No users available': 'Select';
    });

    const secondaryCustomerOptions = computed(() => {
        let options_arr = [];
        props.secondaryCustomerData.forEach(el => {
            let options_obj = {};
            options_obj['value'] = el[0];
            options_obj['label'] = `${el[1]}`;
            options_arr.push(options_obj);
        })
        return options_arr
    });

    const switchDisabled = computed(() => {
        const select_arr = [brand_desired.value, customer_desired.value, user_desired.value];
        if (select_arr.every(el => el)) {
            return false;
        } else if (brand_desired.value && customer_data.value.length == 0 && user_data.value.length == 0) {
            if (props.bigBrand && !big_brand_customer_desired.value) {
                return true;
            }
            return false;
        } else if (brand_desired.value && customer_desired.value && user_data.value.length == 0) {
            return false;
        } else if (!big_brand_customer_desired.value) {
            return true;
        } else {
            return false;
        }
    });

    const selectUserClass = computed(() => {
        return props.bigBrand ? 'width-auto' : '';
    });

    const buttonDivClass = computed(() => {
        let button_class = 'switch-customer-btn-div';
        return props.bigBrand ? button_class+' pa-justify-end' : button_class+' pa-justify-between';
    });

    const customerResultsClass = computed(() => {
        let results_class = 'pa-select-list scrollable isActive switch-customer-bg-white';
        return customer_filter.value == 'ip_fqdn' ? results_class+' width-auto' : results_class;
    });

    const customerDynamicQuery = computed(() => {
        return customer_filter.value == 'customer_id' ? false : true;
    });

    const customerFormClassNames = computed(() => {
        let classNames = ['form-horizontal'];
        if (props.canPerformSite) {
            classNames.push('site-customer-field-pt');
        } else {
            classNames.push('pa-pt-6');
        }
        return classNames;
    });
    
    // Ajax
    const switchToMasterAccount = async () => {
        search_error.value = false;
        try {
            const response = await getMasterAccount(brand_desired.value);
            if (response.data.success == true) {
                modalClose();
            } else {
                big_brand_customer_data.value = [];
                search_error.value = true;
            }
        } catch (error) {
            console.log(error);
        }  
    };

    const selectBigBrandCustomer = async () => {
        search_error.value = false;
        try {
            const response = await bigBrandSelectCustomer(customer_query.value, brand_desired.value);
            if (response.data.success) {
                big_brand_customer_data.value = response.data.customer;
            } else {
                big_brand_customer_data.value = [];
                search_error.value = true;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getBigBrandServer = async (searchVal) => {
        search_error.value = false;
        if (searchVal != '') {
                try {
                    const response = await bigBrandServerSearch(searchVal);
                    if (response.data.success) {
                        big_brand_customer_data.value = response.data.results;
                    } else {
                        big_brand_customer_data.value = [];
                        customer_query.value = searchVal;
                        search_error.value = true;
                    }
                } catch (error) {
                    console.log(error);
                }
        } else {
            big_brand_customer_data.value = [];
            search_error.value = true;
        }
    };

    const getCustomerOptions = async (new_brand) => {
        if (props.bigBrand) {
            return;
        }
        errors.value = {};
        try {
            customer_data.value = [];
            user_data.value = [];
            let options_arr = [];
            const response = await customerOptionsFilter(new_brand);
                let customer_options = response.data.customer_options;
                customer_options.forEach(el => {
                    let options_obj = {};
                    options_obj['value'] = el[0];
                    options_obj['label'] = `${el[1]}`;
                    options_arr.push(options_obj);
                });
                customer_data.value = options_arr;
                if (props.customerId && brand_desired.value === props.brandJinja) {
                    customer_desired.value = props.customerId;
                }
        } catch (error) {
            console.log(error);
            customer_data.value = [];
        }
    };

    const getUserOptions = async (new_customer) => {
        user_desired.value = null;
        try {
            let options_arr = [];
            const response = await userOptionsFilter(new_customer)
                let user_options = response.data.user_options;
                user_options.forEach(el => {
                    let options_obj = {};
                    options_obj['value'] = el[0];
                    options_obj['label'] = `${el[1]}`;
                    options_arr.push(options_obj);
                });
                user_data.value = options_arr;
                if (
                    brand_desired.value === props.brandJinja &&
                    customer_desired.value === props.customerId
                ) {
                    user_desired.value = props.userId;
                } else if (user_data.value.length) {
                    // if user options has data, pre-select master user
                    user_desired.value = user_data.value[0].value;
                }
        } catch (error) {
            console.log(error);
            user_data.value = [];
        }
    };

    const handleBrandSwitchSubmit = async () => {
        if(props.canPerformSite) {
            if(brand_desired.value && brand_desired.value != '') {
                try {
                    const response = await setAdminBrand(brand_desired.value);
                        if (response.data.success) {
                            handleCustomerChangeSubmit();
                        } else {
                            console.log(response.data);
                            errors.value = {
                                'Brand switch server error: ': response.data
                            };
                        }
                } catch (e) {
                    console.log(e);
                    errors.value = {
                        'message': e,
                        'source': 'Brand'
                    };
                }
            }
        } else {
            handleCustomerChangeSubmit();
        }
    };

    const handleCustomerChangeSubmit = async () => {
        if(props.canPerformBrand) {
            let new_customer;
            if(customer_desired.value && customer_desired.value != '') {
                new_customer = customer_desired.value;
            } else if(big_brand_customer_desired.value && big_brand_customer_desired.value != '') {
                new_customer = big_brand_customer_desired.value;
            }
            if(new_customer) {
                try {
                    const response = await setSuperuserCustomer(new_customer, customer_hard_switch.value);
                        if (response.data.success) {
                            handleUserChangeSubmit();
                        } else {
                            console.log(response.data);
                            errors.value = {
                                'Customer switch server error: ': response.data
                            };
                        }
                } catch (e) {
                    console.log(e);
                    errors.value = {
                        'message': e,
                        'source': 'Customer'
                    };
                }
            } else {
                modalClose();
            }
        } else {
            handleUserChangeSubmit();
        }
    };

    const handleUserChangeSubmit = async () => {
        if(props.canPerformCustomer) {
            if(user_desired.value && user_desired.value != '') {
                try {
                    const response = await setSuperuserUser(user_desired.value, user_hard_switch.value);
                        if (response.data.success) {
                            modalClose();
                        } else {
                            console.log(response.data);
                            errors.value = {
                                'message': e,
                                'source': 'User'
                            };
                        }
                } catch (e) {
                    console.log(e);

                    errors.value = {
                        'message': e,
                        'source': 'User'
                    };
                } 
            } else {
                modalClose();
            }
        }
    };

    // Methods
    const debounceServerSearch = _.debounce(function(input) {
            if (input.trim() != "") {
                getBigBrandServer(input);
            } else {
                big_brand_customer_data.value = [];
                search_error.value = false;
            }
        }, 500);

    const bigBrandCustomerChosen = function (result) {
        big_brand_customer_desired.value = result.customer_id;
        if (customerDynamicQuery.value) {
            customer_query.value = result.fqdn || result.ip || result.customer;
        }
    };
        
    const modalClose = function (reload=true) {
        window.app.rootVue.$broadcast('dialog:close', 'dynamic_modal');
        if (reload) {
            location.reload();
        }
    };

    // Hooks
    onMounted(() => {
        nextTick(() => {
            getCustomerOptions(brand_desired.value);
        });    
    });

</script>

<template>

    <div id="select-customer">

        <p-alert
            v-if="hasErrors"
            id="error-alert"
            variant="red"
            class="pa-mx-auto pa-alert-abs"
            no-shadow
            :title="renderErrorTitle"
        >
            <ul v-if="errors.message" class="pa-uList">
                <li
                    class="pa-txt_13"
                >
                    {{ errors.message }}
                </li>
            </ul>
        </p-alert>
        
        <div class="pa-panel-bd pa-vList pa-vList_x2">
            <form v-if="identityAnonymous && canPerformBrand">

                <div v-if="canPerformSite" class="form-horizontal">
                    <input type="hidden" name="brand_id" id="brand_id" v-model="brand_desired" />

                    <div class="pa-field">
                        <div class="pa-field-hd">
                            <label class="pa-label inline">Select Brand</label>
                        </div>
                        <div class="pa-field-bd">

                        <ul class="pa-hList">
                            <li class="switch-customer-li">
                                <p-select 
                                    class="width-auto"
                                    :autocomplete="false" 
                                    :searchable="true" 
                                    :model.sync="brand_desired" 
                                    :options="brandOptions"
                                    >
                                </p-select>
                            </li>                        
                        </ul>

                        </div>
                    </div>
                </div>

                <div v-if="canPerformBrand" :class="customerFormClassNames" id="customer-form">
                    <input type="hidden" name="customer_id" id="customer_id" v-model="customer_desired" />
                    <input type="hidden" name="hard-switch" v-model="customer_hard_switch" />

                    <div v-if="bigBrand" class="pa-field">
                        <div class="pa-field-hd pa-flex pa-space-between">
                            <label class="pa-label inline">Select Customer</label>
                            <div v-if="canPerformBrand">
                                <a class="big-brand-add-customer" v-p-dialog-open target="nav_modal" href='/config/EditCustomer'>
                                <p-icon icon="plus" color="blue-500"></p-icon>
                                    Add Customer
                                </a>
                            </div>
                        </div>
                        <div class="pa-field-bd">

                            <ul class="pa-hList">
                                <li class="pa-flex pa-space-between switch-customer-li">
                                    <p-select 
                                        id="set-customer-filter" 
                                        class="pa-select_xxs"
                                        :autocomplete="false" 
                                        :model.sync="customer_filter" 
                                        :options="customer_filters"
                                        :disabled="customerSelectDisabled"
                                        :defaultemptytext="customerSelectDisabled? 'No customers available':'Select'"
                                        >
                                    </p-select>
                                    <div>
                                        <p-input 
                                            v-if="customerDynamicQuery"
                                            v-on:input="debounceServerSearch"
                                            v-model="customer_query"
                                            placeholder="Start typing ip/fqdn"
                                        ></p-input>
                                        <p-input
                                            v-else
                                            placeholder="Enter customer id"
                                            v-model="customer_query"
                                            theme="search"
                                            :enter="selectBigBrandCustomer"
                                        ></p-input>
                                        <div v-if="showBigBrandCustomerResults" class="pa-select-list isActive">
                                                <div :class="customerResultsClass">
                                                    <div v-for="(result, index) in big_brand_customer_data" :key="index">
                                                        <button 
                                                            v-if="customerDynamicQuery"
                                                            type="button"
                                                            class="pa-select-list-item"
                                                            @click="bigBrandCustomerChosen(result)"
                                                        >
                                                            <span class="ip-span" v-if="result.fqdn">{{ result.fqdn }}</span><span class="ip-span" v-if="result.ip"> | {{ result.ip }}</span>
                                                            
                                                            <div class="customer-name">{{ result.customer }}</div>
                                                            <p-icon icon="chevron-right" class="pa-select-icon absolute" size="lg" middle></p-icon>
                                                        </button>
                                                        <button
                                                            v-else
                                                            type="button"
                                                            class="pa-select-list-item"
                                                            @click="bigBrandCustomerChosen(result)"
                                                        >
                                                            {{ result.name }}
                                                        </button>
                                                        <hr v-if="big_brand_customer_data.length>1 && index!=big_brand_customer_data.length-1" class="list-item-hr">
                                                    </div>
                                                    
                                                    
                                                </div>    
                                                
                                        </div>
                                        <p v-if="search_error" class="width-auto pa-hint pa-hint_error absolute">
                                            "{{ customer_query }}" not found
                                        </p>
                                        <p-button
                                            v-if="!customerDynamicQuery"
                                            @click="selectBigBrandCustomer"
                                            class="absolute switch-customer-btn-left"
                                            no-base-class
                                            variant="link"
                                        >
                                            Search
                                        </p-button>
                                    </div>
                                    
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div v-else class="pa-field">
                        <div class="pa-field-hd">
                            <label class="pa-label inline">Select Customer</label>
                        </div>
                        <div class="pa-field-bd">

                            <ul class="pa-hList">
                                <li class="switch-customer-li">
                                    <p-select 
                                        id="set-customer-select"
                                        class="width-auto"
                                        :autocomplete="false" 
                                        :searchable="true" 
                                        :model.sync="customer_desired" 
                                        :options="customer_data"
                                        :disabled="customerSelectDisabled"
                                        :defaultemptytext="customerSelectDisabled? 'No customers available':'Select'"
                                        >
                                    </p-select>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div v-if="canPerformBrand" name="user-form" class="form-horizontal" id="user-form" style="padding-top: 30px">
                    <input type="hidden" name="user_id" v-model="user_desired" />
                    <input type="hidden" name="hard-switch" v-model="user_hard_switch" />

                    <div class="pa-field">
                        <div class="pa-field-hd">
                            <label class="pa-label inline">Select User</label> 
                        </div>
                        <div class="pa-field-bd">

                            <ul class="pa-hList">
                                <li class="switch-customer-li">
                                <p-select 
                                    id="user-select" 
                                    :class="selectUserClass"
                                    :autocomplete="false" 
                                    :searchable="true" 
                                    :model.sync="user_desired" 
                                    :options="user_data"
                                    :disabled="userSelectDisabled"
                                    :defaultemptytext="userSelectDefaultText"
                                    >
                                </p-select>
                                </li>
                                
                            </ul>

                            <div class="pa-txt_13" style="margin-top: 7px;">
                                <input 
                                    :disabled="userSelectDisabled" 
                                    style="margin-top: 5px; margin-left: 0" 
                                    type="checkbox" 
                                    id="user-hard-switch" 
                                    v-model="user_hard_switch" />
                                <label for="user-hard-switch">Assume user access rights</label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <form v-else-if="identityAnonymous && canPerformCustomer && hasSecondaryUsers || isSecondaryUser"
                action='/setSecondaryCustomer' class="form-horizontal">

                <div>
                    <input type="hidden" name="customer_id" v-model="customer_desired" />
                    <input type="hidden" name="is_modal" value="1" />

                    <div class="pa-field">
                        <div class="pa-field-hd">
                        <label class="pa-label">Select Customer</label>
                        </div>
                        <div class="pa-field-bd">

                        <ul class="pa-hList">
                            <li>
                            <p-select 
                                :searchable="true" 
                                :model.sync="customer_desired" 
                                :options="secondaryCustomerOptions">
                            </p-select>
                            </li>
                            <li>
                            <button class="pa-btn pa-btn_secondary pa-btn_sm" type="submit">Switch</button>
                            </li>
                        </ul>

                        </div>
                    </div>
                </div>

            </form>

        </div>

        <div class="pa-panel-ft panel-bottom">
            <ul class="pa-split">
                <li v-if="canPerformBrand && !bigBrand">
                    <a style="font-size: 0.85rem; display: flex;" v-p-dialog-open target="nav_modal" href='/config/EditCustomer'>
                    <p-icon icon="plus" color="blue-500"></p-icon>
                        Add Customer
                    </a>
                </li>
                <li v-else-if="canPerformBrand && bigBrand">
                    <p-button
                        id="master-account-button"
                        @click="switchToMasterAccount"
                        class="pa-flex _pa-txt_sm_switch"
                        unstyled
                        no-base-class
                        variant="link"
                    >
                        Switch to master account
                    </p-button>
                </li>
                <div :class="buttonDivClass">
                    <li>
                        <button
                        class="pa-btn pa-btn_cancel pa-btn_sm switch-customer-mr"
                        type="button"
                        @click="modalClose(false)"
                        target="dynamic_modal">Cancel</button>
                    </li>
                    <li v-if="canPerformBrand">
                        <button 
                        :disabled="switchDisabled" 
                        id="user-button" 
                        class="pa-btn pa-btn_sm" 
                        @click="handleBrandSwitchSubmit"
                        >
                        Switch
                        </button>
                    </li>
                </div>
                
            </ul>
        </div>

    </div>


</template>

<style scoped lang="scss">

    .switch-customer-btn-div {
        display: flex;
        width: 54%;
        align-items: center;
    }

    .switch-customer-li {
        width: 100%;
    }

    .big-brand-add-customer {
        font-size: 0.8rem; 
        display: flex;
    }

    .switch-customer-bg-white {
        background-color: #ffffff;
    }

    .customer-name {
        font-weight: normal;
    }

    .pa-select-icon.absolute {
        right: 0.5rem;
    }

    .width-auto {
        width: auto !important;
    }

    .pa-select-list-item {
        display: grid;
        padding: 0.5rem;
        margin-right: 2rem;
        line-height: 1.2rem;;
    }

    .list-item-hr {
        color: rgba(117, 141, 153, 0.2);
        border-style: solid;
        border-top-width: 0;
        width: 93%;
        margin: 0 auto;
    }

    .ip-span {
        display: contents;
    }

    ._pa-txt_sm_switch {
        font-size: .85rem !important;
    }

    .site-customer-field-pt {
        padding-top: 30px;
    }

    .switch-customer-mr {
        margin-right: .3rem;
    }

    .switch-customer-btn-left {
        right: 0.25rem;
        top: .45rem;
        font-size: .8rem !important;
    }

    @media screen and (max-width: 600px) {
        .switch-customer-btn-left {
            position: static;
        }
    }

    .panel-bottom {
        padding-top: .25rem;
        padding-bottom: .25rem;
        height: 3rem;
        border-radius: 0 0 0.1176470588rem 0.1176470588rem;
    }


</style>