<template>
    <div v-if="tablePagination && lastPage > firstPage" class="vuetable-pagination dataTables_paginate">
        <div class="pa-mr-16 pa-flex" style="align-items: center;">
            <span class="pa-txt pa-mr-8 dataTables_info">Rows per page:</span>
            <p-select :model="internalPerPage"
                :options="perPageOptions"
                :on-change-callback="changedPerPage"
                style="width: 75px;">
            </p-select>
        </div>

        <div class="dataTables_info" style="align-self: center;">
            <p class="pa-txt">
                <span>{{ currentRange[0] }}-{{ currentRange[1] }} of {{ tablePagination.total }}</span>
                <span v-if="tablePagination.total_overall">
                (filtered from {{ tablePagination.total_overall }} total entries)
                </span>
            </p>
        </div>

        <a @click="loadPage('prev')"
            class="paginate_button previous">
            <svg class="pa-icon">
                <use xlink:href="#chevron-left"></use>
            </svg>
        </a>

        <span>
            <template v-if="tablePagination.current_page < numOnEnds">
                <template v-for="(n, i) in allPages.slice(0, numOnEnds)">
                    <a @click="loadPage(n)" :key="i"
                        class="paginate_button"
                        :class="{ current: isCurrentPage(n) }"
                        v-html="n">
                    </a>
                </template>
                <template v-if="totalPage > numOnEnds + 1">
                    <span style="display: inline-block; cursor: unset;"
                        class="paginate_button"
                        >...</span>
                    <a @click="loadPage(totalPage)"
                        class="paginate_button"
                        :class="{ current: isCurrentPage(totalPage) }"
                        v-html="totalPage">
                    </a>
                </template>
                <template v-if="totalPage === numOnEnds + 1">
                    <a @click="loadPage(totalPage)"
                        class="paginate_button"
                        :class="{ current: isCurrentPage(totalPage) }"
                        v-html="totalPage">
                    </a>
                </template>
            </template>
            <template v-if="tablePagination.current_page >= numOnEnds && tablePagination.current_page > (1 + totalPage - numOnEnds)">
                <template v-if="totalPage > (numOnEnds + 1)">
                    <a @click="loadPage(1)"
                        class="paginate_button"
                        :class="{ current: isCurrentPage(1) }"
                        v-html="1">
                    </a>
                    <span style="display: inline-block; cursor: unset;"
                        class="paginate_button"
                        >...</span>
                </template>
                <template v-if="totalPage === (numOnEnds + 1)">
                    <a @click="loadPage(1)"
                        class="paginate_button"
                        :class="{ current: isCurrentPage(1) }"
                        v-html="1">
                    </a>
                </template>
                <template v-for="(n, i) in allPages.slice(totalPage - numOnEnds, totalPage)">
                    <a @click="loadPage(n)" :key="i"
                        class="paginate_button"
                        :class="{ current: isCurrentPage(n) }"
                        v-html="n">
                    </a>
                </template>
            </template>
            <template v-if="tablePagination.current_page >= numOnEnds && tablePagination.current_page <= (1 + totalPage - numOnEnds)">
                <a @click="loadPage(1)"
                    class="paginate_button"
                    :class="{ current: isCurrentPage(1) }"
                    v-html="1">
                </a>
                <span style="display: inline-block; cursor: unset;"
                    class="paginate_button"
                    >...</span>
                <template v-for="(n, i) in [tablePagination.current_page - 1, tablePagination.current_page, tablePagination.current_page + 1]">
                    <a @click="loadPage(n)" :key="i"
                        class="paginate_button"
                        :class="{ current: isCurrentPage(n) }"
                        v-html="n">
                    </a>
                </template>
                <span style="display: inline-block; cursor: unset;"
                    class="paginate_button"
                    >...</span>
                <a @click="loadPage(totalPage)"
                    class="paginate_button"
                    :class="{ current: isCurrentPage(totalPage) }"
                    v-html="totalPage">
                </a>
            </template>
        </span>

        <a @click="loadPage('next')"
            class="paginate_button next">
            <svg class="pa-icon">
                <use xlink:href="#chevron-right"></use>
            </svg>
        </a>
    </div>
</template>

<script>
    import PaginationMixin from 'vuetable-2/src/components/VuetablePaginationMixin.vue';

    export default {
        mixins: [PaginationMixin],

        props: {
            numOnEnds: {
                type: Number,
                'default': 5,
            },
            perPageOptions: {
                type: Array,
                'default': () => {
                    return [
                        { value: 25, label: '25' },
                        { value: 50, label: '50' },
                        { value: 100, label: '100' },
                    ];
                },
            },
        },

        data() {
            return {
                internalPerPage: 0,
            };
        },

        watch: {
            'tablePagination.per_page'(val) {
                this.internalPerPage = val;
            },
        },

        computed: {
            allPages() {
                if (!this.totalPage) {
                    return [1];
                }
                const pages = [];
                let i = 1;
                while (i <= this.totalPage) {
                    pages.push(i);
                    i += 1;
                }
                return pages;
            },
            currentRange() {
                if (!this.tablePagination.current_page) {
                    return [0, 0];
                }
                const start = (this.tablePagination.current_page - 1)
                    * this.tablePagination.per_page
                    + 1;
                const end = start + this.tablePagination.per_page - 1;
                return [start, end];
            },
        },

        methods: {
            changedPerPage(num) {
                this.$emit(`${this.eventPrefix}change-per-page`, num);
            },
        },
    };
</script>
<style lang="scss">
    .vuetable-pagination {
        .page-block {
            cursor: pointer;
        }
    }
</style>
