<style lang="scss">
</style>

<template>
    <div>
        <template v-if="colData">
            <div class="pa-pl-2 pa-pb-2 pa-txt_bold">{{ colData.action_time }}</div>
            <div class="pa-flex pa-align-center">
                <span v-for="(channel, index) in colData.channels" class="pa-flex pa-align-center">
                    <template v-if="index < 2">
                        <p-icon 
                            :icon="channel.icon" 
                            size="lg"
                            color="grey-300"
                            class="pa-pr-2"
                        ></p-icon>
                        <span v-if="channel.type != 'group'">{{ channel.text }}</span>
                        <p-link :add-pointer="true" v-else>
                            <span @click.prevent="openGroupDrawer(channel.text, channel.group_list)">
                                {{ channel.text }}
                            </span>
                        </p-link>
                        <span
                            class="pa-px-6"
                            v-if="index < colData.channels.length - 1"
                        >
                            &#9642;
                        </span>
                    </template>
                </span>
                <template v-if="colData.channels.length > 2">
                    <p-tooltip2
                        :prevent-overflow="true"
                        placement="bottom"
                        :hover="false"
                        :wait-time="300"
                        :advanced="true"
                    >
                        <span slot="trigger">
                           <p-link :add-pointer="true">{{ moreText }}</p-link>
                        </span>
                        <span>
                            <template v-for="(channel, index) in colData.channels">
                                <p v-if="index > 1" class="pa-flex pa-align-center">
                                    <p-icon 
                                        :icon="channel.icon" 
                                        size="lg"
                                        color="grey-300"
                                        class="pa-pr-4"
                                    ></p-icon>
                                    <span v-if="channel.type != 'group'">{{ channel.text }}</span>
                                    <p-link :add-pointer="true" v-else>
                                        <span @click.prevent="openGroupDrawer(channel.text, channel.group_list)">
                                            {{ channel.text }}
                                        </span>
                                    </p-link>
                                </p>
                            </template>
                        </span>
                    </p-tooltip2>
                    
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
       data: String,
    },

    data() {
        return {
            colData: null,
        };
    },

    computed: {
        moreText() {
            let number = this.colData.channels.length - 2;
            return `+ ${number} more`
        },
    },

    methods: {
        openGroupDrawer(groupName, groupList){
            this.eventHub.$emit(
                'alert-group-drawer:open',
                groupName,
                groupList
            );
        },
    },

    mounted() {
        this.colData = JSON.parse(this.data);
    },
});
</script>
