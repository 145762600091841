<script>
import Vue from "vue";

export default Vue.extend({
    render(createElement) {
        const children = [];
        const slotContent = this.$slots.default;

        if (slotContent && slotContent.length) {
            for (let i = 0; i < slotContent.length; i++) {
                const child = slotContent[i];
                const isLast = slotContent.length - 1 === i;

                if (child && child.data && !isLast && this.spacing) {
                    child.data.staticClass += ` pa-mr-${this.spacing}`;
                }

                children.push(child);
            }
        }

        return createElement(
            "div",
            {
                class: {
                    "pa-grid": true,
                    "pa-flex-nowrap": this.noWrap,
                    "pa-space-between": this.spaceBetween,
                    "pa-align-center": this.alignCenter,
                    "pa-align-end": this.alignEnd,
                    "pa-align-start": this.alignStart,
                    "pa-justify-center": this.justifyCenter,
                    "pa-justify-end": this.justifyEnd
                }
            },
            children
        );
    },
    props: {
        noWrap: Boolean,
        spaceBetween: Boolean,
        alignCenter: Boolean,
        alignEnd: Boolean,
        justifyEnd: Boolean,
        justifyCenter: Boolean,
        spacing: [String, Number]
    }
});
</script>
