<style lang="scss">
    .graph-legend-container {
        font-size: 12px;
        text-align: center;
        padding-top: 4px;

        dt {
            font-weight: 700;
            width: 15%;

            &:after {
                content: ":";
                display: inline;
                padding-right: 0.30em;
            }
        }

        dd {
            margin: 0;
            width: 85%;
        }
    }
</style>

<template>
    <div class="incident-details-graph-list pa-grid">

        <div v-if="status === 'loading'" class="pa-grid-col">
            <p class="pa-txt_centered">Loading graphs...</p>
        </div>

        <div v-if="!graphs.length && status === 'ready'" class="pa-grid-col">
            <p class="pa-txt_centered pa-txt_sm pa-py-8">There are no metrics to graph on this server.</p>
        </div>

        <div v-if="totalMetrics >= 100 && status === 'ready'" style="text-align: left;" class="pa-grid-col pa-txt_13">
            <span>Note:</span> Due to the large number of metrics on this server, we've disabled real-time syncing across graphs to improve performance
        </div>

        <div :key="subCategory.sub_category_name + index" v-for="(subCategory, index) in filteredGraphSubCategories" class="pa-grid-col pa-grid-col_4of12">
            <p-incident-details-graph
                :key="subCategoryIndex"
                v-for="(metric, subCategoryIndex) in subCategory.metrics"
                :graph-title="subCategory.sub_category_name"
                :graph-unit="metric[0]"
                :monitors="metric[1]"
                :graph-description="subCategory.description ? subCategory.description : ''"
                :all-graphs-loaded="allGraphsLoaded"
                :max-value="subCategory.max_value"
                :graph-id="subCategory.graphId"
                :date-range="date_range"
                :timescale="timescale"
                :location-legend="locationLegend"
                :extra-data="extraData"
                :loading="loading"
            >
                <div
                    class="graph-legend-container"
                    v-if="subCategory.graph_legend"
                >
                    <p-tooltip2 variant="steel" placement="bottom" :preventOverflow="false">
                        <span slot="trigger" style="cursor: pointer;">Possible States</span>
                        <span style="max-width: 200px; display:inline-block;" v-html="subCategory.graph_legend"></span>
                    </p-tooltip2>
                </div>
                <p-graph
                    slot="graph"
                    :id="subCategory.graphId"
                    ref="graphs"
                    :monitors="metric[1]"
                    :server_id="instance.id"
                    :auto-load="autoloadGraphs"
                    :timescale="timescale"
                    :crosshairs="true"
                    :sync-crosshairs="totalMetrics < 100"
                    width="100%"
                    :short-legend="subCategory.short_legend || false"
                    :load-timeout="Math.floor(subCategoryIndex / 10) * 2"
                    :exporting="false"
                    height="200px"
                    :min-height="subCategory.graph_legend ? 185 : 200"
                    :display-y-axis-title="false"
                    :max-legend-height="40"
                    align-legend="left"
                    :resize="true"
                    :set-legend-item-width="false"
                    :polling="true"
                    :should-connect-nulls="instance.shouldConnectNulls"
                    :highcharts-config="{
                        yAxis: {
                            lineWidth: 0,
                        },
                    }"
                    :date_range="date_range"
                    :max-value="subCategory.max_value"
                    :location-legend="locationLegend"
                    :location-filter="locationFilter"
                    :extra-data="extraData"
                    @loading="handleLoading"
                ></p-graph>
            </p-incident-details-graph>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        instance: Object,
        filteredGraphSubCategories: Array,
        totalMetrics: Number,
        autoloadGraphs: Boolean,
        graphs: Array,
        isLoading: Boolean,
        allGraphsLoaded: Boolean,
        timescale: String,
        date_range: String,
        locationLegend: {
            type: Boolean,
            default: false
        },
        extraData: {
            type: String,
            default: null
        },
        locationFilter: {
            type: Array,
            default: () => []
        },
        status: "idle",
    },

    data() {
        return {
            startTimescale: this.timescale,
            startDateRange: this.date_range,
            loading: true,
        };
    },

    watch: {
        isLoading(isLoading) {
            if (isLoading) {
                this.status = "loading";
            }
        },
        allGraphsLoaded(allGraphsLoaded) {
            if (allGraphsLoaded) {
                this.status = "ready";
            }
        },
    },

    methods: {
        handleLoading(value) {
            this.loading = value;
        },
        resizeGraphs() {
            if (this.$refs.graphs) {
                this.$refs.graphs.forEach(graph => {
                    graph.fitWidth();
                });
            }
        },
    },

    created() {
        this.eventHub.$on('panel:activated', msg => {
            if (msg.title === 'Performance') {
                Vue.nextTick(() => {
                    this.resizeGraphs();
                });
            }
        });


    }
});
</script>
