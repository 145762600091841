<style lang="scss">
    .switcher-button {
        padding: 4px 0;
        background: none;
        border: none;
        border-radius: 3px;
        outline: none;
        cursor: pointer;
        display: grid;
        grid-template-columns: 1fr 23px;
        align-items: center;
    }
    .switcher-body {
        position: absolute;
        z-index: 200;
        width: 700px;
        height: 505px;
        padding: 7px 0 0 0;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 1px 4px 5px 2px rgba(0, 0, 0, 0.2);
        border-radius: 0px 3px 3px 0px;
    }
    .switcher-footer {
        padding: 7px 0 0 0;
        display: table;
        margin: 0 auto;
    }
    .dashboard-listing {
        height: 79%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        white-space: nowrap;
        border-bottom: 1px solid #eee;
    }
    .dashboard-listing .pa-label {
        background: #eee;
        padding: 5px;
        margin: 0px !important;
    }
    .dashboard-listing .pa-vList {
        margin: 0 20px 0 20px;
    }
    .dashboard-entry {
        width: 100%;
        padding: 5px 10px;
        background: none;
        border: none;
        outline: none;
        white-space: nowrap;
    }
    .dashboard-entry a {
        color: #000;
    }
    .dashboard-entry:hover {
    }

    /* transitions */
    .switcher-body-transition {
        transition: all .3s ease;
    }
    .switcher-body-enter, .switcher-body-leave {
        height: 0px;
    }
    .entry-transition {
        min-height: 32px;
        max-height: 200px;
        transition: all .3s ease;
    }
    .entry-enter, .entry-leave {
        transform: translateX(550px);
        max-height: 0px;
        opacity: 0;
    }
    .dashboard-listing .pa-expando-hd {
        background: #FFF;
    }
    .dashboard-listing .pa-expando-box-bd {
        background: #f0f4f7;
        padding: 3px;
    }
    .dashboard-listing .pa-hList .pa-txt_lg {
        color: #000;
    }
    .switcher-button .dashboard-name {
        height: 17px;
        display: inline-block;
        
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2c3438;

        &.dashboard-name-header {
            font-size: 28px;
            height: 100%;
        }
    }
    .tag-indicator {
        width: 44px;
        height: 15px;
        
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #848e99;
    }
    .dashboard-listing .entry-transition {
        margin-top: 0;
    }
</style>

<template>
    <div>
        <button type="button" class="switcher-button" @click="toggle">
            <span
                :title="currentDashboard.name"
                class="dashboard-name dashboard-name-header pa-txt_truncate"
            >
                {{ currentDashboard.name }}
            </span>
            <p-icon icon="chevron-down" size="xxl" />
        </button>
        <div class="switcher-body" v-show="isOpen" transition="switcher-body">
            <div style="padding: 10px;">
                <div class="pa-input pa-input_phony pa-input_stretch">
                    <label for="search" class="pa-isVisuallyHidden">Search</label>
                    <input type="search" id="search" placeholder="Search Dashboards" v-model="search"/>
                </div>
            </div>
            <div class="dashboard-listing">
                <label class="pa-label pa-label-light" style="margin: 15px 0px 0px 0px;">Favorite</label>
                <ul class="pa-vList">
                    <li v-for="dashboard in favoriteDashboards" :key="dashboard.id" transition="entry">
                        <p-expando :is-active="false" :no-content="dashboard.tags.length == 0">
                            <span slot="headerControls">
                                <ul class="pa-split" style="flex-wrap: nowrap;">
                                    <li>                                        
                                        <ul v-if="dashboard.favorite" class="pa-hList" style="flex-wrap: nowrap;">
                                            <p-tooltip2 variant="steel" :width="125">
                                            <div slot="trigger">
                                                <li style="margin-top: 6px">
                                                    <svg v-if="isLoadingDashboard(dashboard.id)" class="pa-icon pa-icon_xl spin-8step"><use xlink:href="#spinner-spin"></use></svg>
                                                    <svg v-else class="pa-icon pa-icon_xxl" @click="unfavorite($event)" :data-id="dashboard.id">
                                                        <use xlink:href="#star"></use>
                                                    </svg>
                                                </li></div>Remove from favorites
                                            </p-tooltip2>
                                            <li>
                                                <a class="dashboard-name" @click="switchDashboard(dashboard.id)" :href="dashboard.href">{{dashboard.name}}</a>
                                                <span class="pa-txt pa-txt_xs pa-txt_secondary" style="margin-left: 5px;" v-if="dashboard.id == currentId">( viewing )</span>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="tag-indicator" v-if="dashboard.tags.length > 0">
                                        ({{ dashboard.tags.length }} tags)
                                    </li>
                                </ul>
                            </span>
                            <ul v-if="dashboard.tags.length > 0" class="pa-hList">
                                <li v-for="tag in dashboard.tags">
                                    <span class="pa-badge pa-badge_info" style="margin-bottom: 0px">{{tag}}</span>
                                </li>
                            </ul>
                        </p-expando>
                    </li>
                    <li v-if="!favoriteDashboards.length && !search.length" transition="entry" style="padding-top: 5px;">
                        <span class="pa-txt pa-txt_s pa-txt_secondary" style="color:black;font-size:13px;">No favorites yet</span>
                    </li>
                </ul>
                <label class="pa-label pa-label-light" style="margin: 15px 0px 0px 0px;">All</label>
                <ul class="pa-vList">
                    <li v-for="dashboard in otherDashboards" :key="dashboard.id" transition="entry">
                        <p-expando :is-active="false" :no-content="dashboard.tags.length == 0">
                            <span slot="headerControls">
                                <ul class="pa-split" style="flex-wrap: nowrap;">
                                    <li>
                                        <ul class="pa-hList" style="flex-wrap: nowrap;">
                                            <p-tooltip2 variant="steel" :width="95">
                                                <div slot="trigger">
                                                <li style="margin-top: 6px">
                                                    <svg v-if="isLoadingDashboard(dashboard.id)" class="pa-icon pa-icon_xl spin-8step"><use xlink:href="#spinner-spin"></use></svg>
                                                    <svg v-else class="pa-icon pa-icon_xxl" @click="favorite($event)" :data-id="dashboard.id">  
                                                        <use xlink:href="#star-outline"></use>
                                                    </svg>
                                                </li></div>Add to favorites
                                            </p-tooltip2>
                                            <li>
                                                <a class="dashboard-name" @click="switchDashboard(dashboard.id)" :href="dashboard.href">{{dashboard.name}}</a>
                                                <span class="pa-txt pa-txt_xs pa-txt_secondary" style="margin-left: 5px;" v-if="dashboard.id == currentId">( viewing )</span>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="tag-indicator" v-if="dashboard.tags.length > 0">
                                        ({{ dashboard.tags.length }} tags)
                                    </li>
                                </ul>
                            </span>
                            <ul v-if="dashboard.tags.length > 0" class="pa-hList">
                                <li v-for="tag in dashboard.tags">
                                    <span class="pa-badge pa-badge_info" style="margin-bottom: 0px">{{tag}}</span>
                                </li>
                            </ul>
                        </p-expando>
                    </li>
                </ul>
            </div>
            <div v-if="userDashboardAddAccess" class="switcher-footer">
                <button @click="createDashboard" type="button" class="pa-btn pa-btn_blue">
                    Create New Dashboard
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const DashboardSwitcher = Vue.extend({

        events: {
        },

        data() {
            return {
                isOpen: false,
                search: '',
                isSwitching: false,
                nextDashboardId: null,
                others: Array,
                refreshKey: 0,
            };
        },

        props: {
            currentId: Number,
            dashboards: Array,
            userDashboardAddAccess: Boolean,
        },

        watch:{
            others(){       
                this.$forceUpdate();
            }
        },

        computed: {
            currentDashboard() {
                const cur = this.dashboards.find(d => {
                    return d.id === this.currentId;
                });
                if (cur) { return cur; }
                return { id: 0, name: 'Null', href: '' };
            },
            favoriteDashboards() {
                this.refreshKey;          
                const favs = this.dashboards.filter(d => {
                    if (this.search.length) {
                        const lowerName = d.name.toLowerCase();
                        const lowerSearch = this.search.toLowerCase();
                        const tagMatches = d.tags.some(t => {
                            return t.toLowerCase().includes(lowerSearch);
                        });
                        return d.favorite &&
                            (lowerName.includes(lowerSearch) ||
                             tagMatches);
                    }
                    return d.favorite;
                });                
                return favs;
            },
            otherDashboards() {
                this.refreshKey;
                const others = this.dashboards.filter(d => {
                    if (this.search.length) {
                        const lowerName = d.name.toLowerCase();
                        const lowerSearch = this.search.toLowerCase();
                        const tagMatches = d.tags.some(t => {
                            return t.toLowerCase().includes(lowerSearch);
                        });
                        return !d.favorite &&
                            (lowerName.includes(lowerSearch) ||
                             tagMatches);
                    }
                    return !d.favorite;
                });
                this.others = others;
                return others;
            },
        },

        methods: {
            toggle() {
                if (this.isOpen) {
                    this.close();
                } else {
                    this.open();
                }
            },
            open() {
                this.isOpen = true;
                $(document).on('click.dashboard', event  => {
                    if (!$(event.target).closest('.switcher-body').length &&
                        !$(event.target).closest('.switcher-button').length) {
                        this.close();
                    }
                });
            },
            close() {
                this.isOpen = false;
                $(document).off('click.dashboard');
            },            
            favorite(event) {
                this.updateRefreshKey();
                event.stopPropagation();
                let dashboardId = event.currentTarget.dataset.id;
                try {
                    dashboardId = parseInt(dashboardId);
                } catch (e) {
                    return;
                }
                const payload = {
                    dashboard_id: dashboardId,
                    favorite: true,
                };
                $.ajax({ url: '/dashboardv2/favoriteDashboard',
                    method: 'GET',
                    data: payload,
                });
                const dashboard = this.dashboards.find(d => {
                    return d.id === dashboardId;
                });
                if (dashboard) { 
                    dashboard.favorite = true;
                    window.app.toastManager.showToast({text: 'Added to favorites'});
                }
            },
            unfavorite(event) {
                this.updateRefreshKey();
                event.stopPropagation();
                let dashboardId = event.currentTarget.dataset.id;
                try {
                    dashboardId = parseInt(dashboardId);
                } catch (e) {
                    return;
                }
                const payload = {
                    dashboard_id: dashboardId,
                    favorite: false,
                };
                $.ajax({ url: '/dashboardv2/favoriteDashboard',
                    method: 'GET',
                    data: payload,
                });
                const dashboard = this.dashboards.find(d => {
                    return d.id === dashboardId;
                });
                if (dashboard) { 
                    dashboard.favorite = false;    
                    window.app.toastManager.showToast({
                        text: 'Removed from favorites'
                    });
                }
            },
            createDashboard() {
                window.app.rootVue.$broadcast('dashboard-properties-drawer:open', null);
                this.toggle();
            },
            switchDashboard(id) {
                this.isSwitching = true;
                this.nextDashboardId = id;
            },
            isLoadingDashboard(id) {
                return this.isSwitching && this.nextDashboardId === id;
            },
            updateRefreshKey(){
                this.refreshKey +=1;
            }
        },
    });

    export default DashboardSwitcher;
</script>
