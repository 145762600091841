<style>
 .centered {
     width: 75%;
     margin: 0 auto;
 }
 .in-modal-centered {
    width: 100%;
    margin: 0 auto;
 }
 .updown-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
 }
 .updown-container .pa-vList {
     padding-bottom: 6px;
     padding-top: 4px;
 }
 .updown-widget-title {
     
     padding-top: 6px;
 }
 .updown-outage-id {
     
     padding-bottom: 6px;
 }
 .updown-svg-container {
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    margin-bottom: 12px;
    width: calc(100% - 40px);
 }
</style>

<template>
    <div :id="id"
         class="grid-stack-item"
         :data-gs-x="localConfig.col.toString()"
         :data-gs-y="localConfig.row.toString()"
         :data-gs-width="localConfig.width.toString()"
         :data-gs-height="localConfig.height.toString()"
         :data-gs-auto-position="false"
         :data-gs-locked="true">
        <div
            class="grid-stack-item-content"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;">
                <div :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered}" :style="{ 'padding-bottom': localConfig.height === 1 ? 0 : 8 }">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_9of12" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                            </span>
                            <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: textColor}"><use xlink:href="#spinner-spin-naked"></use></svg>
                            <p-tooltip2 :normal-white-space="true"> 
                                <span slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: textColor }">
                                    <span v-if="localConfig.title">{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }}</span>
                            </p-tooltip2>
                        </div>
                        <div class="pa-grid-col pa-grid-col_3of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger"  v-show="showWidgetControls" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger"  v-show="showWidgetControls" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button                                 
                                    v-show="showWidgetControls" 
                                    type="button"
                                    slot="trigger"
                                    @click="cloneWidget" 
                                    class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mr-6 pa-mt-6"
                                >
                                    <p-icon icon="copy"></p-icon>
                                </button>
                                <span> Clone this Widget</span>
                            </p-tooltip2>
                        </div>
                    </div>
                </div>
                <div v-if="valid" id="body" class="widget-body" style="overflow-y: auto; flex-grow: 1; margin-bottom: 10px; margin-left: 0; margin-right: 0;" ref="body">
                    <div class="updown-container">
                        <div v-if="!needsConfig" class="updown-svg-container">
                            <template v-if="content.down">
                                <a
                                    v-if="content.last_outage_id"
                                    :href="`/outage/Outage?outage_id=${content.last_outage_id}`"
                                    target="_blank"
                                    class="updown-outage-id"
                                    :class="{ 'pa-message_error': content.down }"
                                >
                                    <svg viewBox="0 0 85 104" :width="computedUpDownWidth" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="down-gradient">
                                                <stop stop-color="#d92121" offset="0%"/>
                                                <stop stop-color="#f53131" offset="100%"/>
                                            </linearGradient>
                                        </defs>
                                        <path
                                            d="M89 27.337V97a7 7 0 0 1-7 7h-2a7 7 0 0 1-7-7V28.018l-20.65 20.65a6.993 6.993 0 0 1-9.89 0l-1.412-1.412a6.993 6.993 0 0 1 0-9.89L76.366 2.048a6.993 6.993 0 0 1 9.89 0l.029.03.03-.03 35.318 35.318a6.993 6.993 0 0 1 0 9.89l-1.413 1.412a6.993 6.993 0 0 1-9.89 0L89 27.338z"
                                            transform="translate(-87, -26) rotate(180, 105, 65)"
                                            fill="url(#down-gradient)"
                                            fill-rule="evenodd"
                                        />
                                    </svg>
                                </a>
                                <svg v-else viewBox="0 0 85 104" :width="computedUpDownWidth" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="down-gradient">
                                            <stop stop-color="#d92121" offset="0%"/>
                                            <stop stop-color="#f53131" offset="100%"/>
                                        </linearGradient>
                                    </defs>
                                    <path
                                        d="M89 27.337V97a7 7 0 0 1-7 7h-2a7 7 0 0 1-7-7V28.018l-20.65 20.65a6.993 6.993 0 0 1-9.89 0l-1.412-1.412a6.993 6.993 0 0 1 0-9.89L76.366 2.048a6.993 6.993 0 0 1 9.89 0l.029.03.03-.03 35.318 35.318a6.993 6.993 0 0 1 0 9.89l-1.413 1.412a6.993 6.993 0 0 1-9.89 0L89 27.338z"
                                        transform="translate(-87, -26) rotate(180, 105, 65)"
                                        fill="url(#down-gradient)"
                                        fill-rule="evenodd"
                                    />
                                </svg>
                            </template>
                            <svg v-if="content.up" viewBox="0 0 85 104" :width="computedUpDownWidth" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="up-gradient">
                                        <stop stop-color="#5FEDC7" offset="0%"/>
                                        <stop stop-color="#2BD9B0" offset="100%"/>
                                    </linearGradient>
                                </defs>
                                <path
                                    d="M89 27.337V97a7 7 0 0 1-7 7h-2a7 7 0 0 1-7-7V28.018l-20.65 20.65a6.993 6.993 0 0 1-9.89 0l-1.412-1.412a6.993 6.993 0 0 1 0-9.89L76.366 2.048a6.993 6.993 0 0 1 9.89 0l.029.03.03-.03 35.318 35.318a6.993 6.993 0 0 1 0 9.89l-1.413 1.412a6.993 6.993 0 0 1-9.89 0L89 27.338z"
                                    transform="translate(-39)"
                                    fill="url(#up-gradient)"
                                    fill-rule="evenodd"
                                />
                            </svg>
                        </div>
                        <ul class="pa-vList">
                            <div class="pa-txt pa-txt_sm">
                                 {{ content.name }}
                            </div>
                            <div v-if="content.down || content.up" class="pa-txt pa-txt_xs">
                                <a
                                    v-if="content.last_outage_id"
                                    :href="`/outage/Outage?outage_id=${content.last_outage_id}`"
                                    target="_blank"
                                    class="updown-outage-id"
                                    :class="{ 'pa-message_error': content.down }"
                                >
                                    Incident ID: {{ content.last_outage_id }}
                                </a>
                                <span v-else style="color: #69717d;">
                                    No Prior Incidents
                                </span>
                            </div>
                        </ul>
                    </div>
                </div>
                <div v-if="needsConfig" class="widget-body" style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center;" ref="temp_body">
                    <div class="vertical-center">
                        <button v-if="$parent.allowEditing" @click="configure" class="pa-btn pa-btn_naked_light_grey_no_fill pa-btn_lg" style="padding: 12px 35px" type="button">
                            <svg class="pa-icon">
                                <use xlink:href="#plus-circle-outline"></use>
                            </svg>
                            <span>Configure</span>
                        </button>
                        <p class="widget-unconfigured centered">
                            Please configure the widget to only track a single metric.
                        </p>
                        <p v-if="accessError" class="widget-unconfigured centered">
                            You do not have access to this metric.
                        </p>
                    </div>
                </div>
                <div v-if="!valid" class="widget-body widget-body_error" :title="error_message" ref="body">
                    <p class="pa-message pa-message_error">
                        <svg class="pa-icon pa-icon_xl"><use xlink:href="#alert-circle"></use></svg>
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import $ from 'jquery';
    import widgetMixin from './../widgetMixin';
   
    const UpDownWidget = Vue.extend({
        mixins: [widgetMixin],
   
        data() {
            return {
                content: {
                    down: false,
                    critical: false,
                    warning: false,
                    success: true,
                },
                localConfig: this.config,
            };
        },

        computed: {
            computedUpDownWidth: function() {
                // Derived from the smallest width that doesnt cause the
                // parent container from overflowing
                const baseWidth = 35;

                if (this.localConfig.height === 1) {
                    return Math.floor(baseWidth / 2);
                }

                if (this.localConfig.width === 1) {
                    return baseWidth;
                }

                return baseWidth * (this.localConfig.height - 1);
            }
        },

        methods: {
            initialize(config, content) {
                this.contentReady();
                this.localConfig = {...config};
                this.content = content;
            },

            getConfigModules() {
                return [
                    { type: 'p-overview-module'},
                    { type: 'p-instance-metric-module' },
                    ...(this.$parent.isScoping() ? [{ type: "p-ignore-scope-module" }] : []),
                    {
                        type: 'p-custom-module',
                        options: {
                            title: '',
                            controls: [
                                {
                                    id: 'backgroundColor',
                                    label: 'Background Color',
                                    key: 'backgroundColor',
                                    type: 'color-picker',
                                    default: '#ffffff'
                                },
                            ]
                        }
                    },
                ];
            },

            getOptionModules() {
                return []
            },

            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this);
            },
   
            update(content) {
                this.content = content;
            },
   
            reconfig() {
   
            },
   
            dumpState() {
                const obj = Object();
                obj.data = this.$data;
                obj.config = {...this.localConfig};
                console.log(JSON.stringify(obj));
            },
   
        },
   
        props: {
            config: {
                type: Object,
                default() {
                    return {
                        custom_controls: {},
                    };
                },
            },
        },
   
        mounted() {
            // this.pendNewConfig();
            if (this.id < 0) {
                this.configure();
            };
        },
    });
   
    export default UpDownWidget;
   
</script>
