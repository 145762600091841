<style lang="scss">
    .dynamic-metric-value {
        padding: .4705882353rem;
    }
</style>

<template>
    <span class="dynamic-metric-value"
        @mouseover="hover = true"
        @mouseleave="hover = false"
    >

        {{ value }}
        <a
            v-if="hover"
            v-p-dialog-open 
            target="dynamic_modal"
            style="margin-left: auto;"
            :href="href"
        >
            <p-icon
                icon="arrow-expand"
            >
            </p-icon>
        </a>
    </span>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            id: String,
            title: String,
            value: String,
        },

        data: function() {
            return {
                hover: false,
            };
        },

        computed: {
            href: function() {
                return `/report/MetricPopup?&metric_ids[]=-${this.id}&title=${this.title}`
            },
        },
        
    });
</script>
