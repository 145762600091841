<style lang="scss">
@import "~styles/core/helpers/_variables.scss";
.drawer-active-incident-list-item {
    & > a {
        &:hover {
            .drawer-active-incident-list-item-description {
                text-decoration: underline;
            }
        }
    }

    &-description {
        color: initial;
    }

    &-badge {
        min-width: 100px;
    }

    .pa-anchor {
        .pa-icon {
            @include colorVar(fill, "grey", 400);
            @include colorVar(color, "grey", 400);

        }
    }
}
</style>

<template>
    <li class="drawer-active-incident-list-item pa-mt-8">
        <p-link
            is-external
            :href="`/outage/IncidentDetails?incident_id=${incident.alert_id}`"
            class="p-width-full inline"
        >
            <p-flex class="pa-pb-4">
                <div class="pa-txt_centered">
                    <p-severity-badge
                        :severity="incident.severity"
                    >
                    </p-severity-badge>
                </div>
                <div class="pa-pt-4 pa-pl-4">
                    <p-icon color="grey-400" icon="external-link" size="sm">
                    </p-icon>
                </div>
            </p-flex>
            <div style="width: 80%;">
                <p-text font-size="sm" class="drawer-active-incident-list-item-description pa-mb-4">
                    {{ incident.description }}
                </p-text>
                <p-text font-size="sm" class="pa-txt_secondary pa-mb-4">
                    Start time: {{ incident.startTime }}<br/>
                </p-text>
                <p-text font-size="sm" class="pa-txt_secondary">
                    Duration: {{ incident.duration }}
                </p-text>
            </div>
        </p-link>
    </li>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            incident: Object,
        },
    });
</script>
