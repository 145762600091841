<template>
    <p-select
        v-show="hasOptions"
        html-class="pa-graph-unit-select pa-select--unstyled"
        :options="computedOptions"
        :model.sync="selectedGraphUnit"
    >
    </p-select>
</template>

<script>
    import Vue from "vue";
    import _ from "lodash";

    export default Vue.extend({
        data() {
            return {
                selectedGraphUnit: this.graphUnit,
            };
        },
        props: {
            options: {
                type: Array,
                default: () => [],
            },
            graphId: {
                type: [Number, String],
                default: "",
            },
            graphUnit: {
                type: String,
                default: "",
            },
        },
        watch: {
            selectedGraphUnit(curr) {
                if (this.hasOptions) {
                    this.eventHub.$emit("graph:changeUnit", {
                        id: this.graphId,
                        unit: curr,
                    });
                }
            },
        },
        computed: {
            hasOptions() {
                return this.computedOptions.length > 1;
            },
            computedOptions() {
                const options = _.uniqBy([
                    ...this.options,
                    { label: "%", value: "%" },
                ].map(
                    (option) => ({
                        ...option,
                        label: `(${option.label})`,
                    })
                ), "value");

                return options;
            },
        },
        mounted() {
            this.selectedGraphUnit = this.graphUnit;
        }
    });
</script>
