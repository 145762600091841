

class DatetimeManager {
    constructor(datetime) {
        this.datetime = datetime
    }
}

window.datetimes = {};

DatetimeManager.addInstance = function(datetimeInstance) {
    window.datetimes[datetimeInstance.name] = datetimeInstance;
}

DatetimeManager.getInstance = function(datetimeId) {
    return window.datetimes[datetimeId];
}

export default DatetimeManager;
