<style>
 .widget-table {
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
 }
 .widget-body.summary-table {
     overflow: hidden;
     height: 100%;
     margin-bottom: 0px;
 }
 .widget-body.summary-table > div {
     height: 100%;
 }
 .widget-body.summary-table #table-filters, .widget-body.summary-table #table-buttons {
     margin-bottom: 0px !important;
 }
 .widget-body.summary-table .dataTables_wrapper {
     height: 100%;
 }
 .widget-body.summary-table .dataTables_scroll {
     height: 100%;
 }
 .widget-body.summary-table .dataTables_wrapper .dataTables_paginate {
     padding-top: 10px !important;
     padding-bottom: 0px !important;
     border-top: .5px solid #edeff0;
     display: none;
 }
 .widget-body.summary-table td {
     padding-top: 5px;
     padding-bottom: 5px;
 }
 th.hidden, td.hidden {
     padding: 0;
     width: 0px !important;
 }
.summary-table {
    margin-left: 20px;
    margin-right: 20px;
}
.summary-table .dataTables_filter {
    display: none;
}
.summary-table-actions .pa-search {
    height: 2rem;
}
.summary-table-actions .pa-search-box {
    border: none;
    border-radius: 0;
    height: 2rem;
}
.summary-table-actions .pa-search-box-input {
    background-color: white;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    height: 32px;
    max-width: 224px;
    width: 100%;
}
.summary-table-actions .pa-search-btn {
    border: none;
}
.summary-table-actions .pa-search-btn-icon {
    fill: #848e99;
}
.summary-table .dts_label {
    display: none !important;
}
.summary-table table.dataTable {
    border-collapse: collapse;
}
.summary-table table.dataTable thead th.hidden-column {
    border: none;
}
.summary-table table.dataTable thead th,
.summary-table table.dataTable tfoot th {
    border-top: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    padding: 7px;
}
.summary-table table.dataTable tbody tr td {
    border-left: 1px solid #e6e6e6;
    padding: 8px;
}

.summary-table table.dataTable tbody tr:first-of-type td {
    border-top: 1px solid #e6e6e6;
}

.summary-table table.dataTable thead tr th:last-of-type,
.summary-table table.dataTable tbody tr td:last-of-type {
    border-right: 1px solid #e6e6e6;
}

.summary-table table.dataTable .hidden {
    display: none;
}
.summary-table .status-column,
.summary-table .short-width-column{
    /*
        This width keeps these cells as small as possible
        with a little extra padding.
    */
    text-align: center;
}

</style>
<template>
    <div :id="id"
         class="grid-stack-item"
         :data-gs-x="localConfig.col.toString()"
         :data-gs-y="localConfig.row.toString()"
         :data-gs-width="localConfig.width.toString()"
         :data-gs-height="localConfig.height.toString()"
         :data-gs-auto-position="false"
         :data-gs-locked="true">
        <div
            class="grid-stack-item-content"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;">
                <div :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered}">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_7of12" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                            </span>
                            <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: textColor}"><use xlink:href="#spinner-spin-naked"></use></svg>                            
                            <p-tooltip2 :normal-white-space="true">
                                <span slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: textColor }">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }}</span>
                            </p-tooltip2>
                        </div>
                        <div class="pa-grid-col pa-grid-col_5of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>                                
                            </p-tooltip2>
                            <p-tooltip2>
                                <button 
                                    v-show="showWidgetControls" 
                                    type="button"
                                    slot="trigger"
                                    @click="cloneWidget" 
                                    class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mr-6 pa-mt-6"
                                >
                                    <p-icon icon="copy"></p-icon>
                                </button>
                                <span> Clone this Widget </span>
                            </p-tooltip2>
                                
                            <p-search
                                style="width:100%;margin-right:8px;"
                                :model="searchValue"
                                :on-change="handleSearchChange"
                                :is-active="true"
                                placeholder="">
                            </p-search>
                        </div>
                    </div>
                </div>
                <div v-show="valid" id="body" class="widget-body summary-table" style="overflow-y: auto; flex-grow: 1; margin-bottom: 20px;" ref="body">
                    <p-table2
                        :column-renderers="columnRenderers"
                        :column-classes="{
                            0: 'hidden',
                        }"
                        :column-callbacks="columnCallbacks"
                        :empty-message="this.tableType === 'server' ? 'No pods match your filter.' : ''"
                        :paging="true"
                        :avoid-first-table="true"
                        scroll-y="calc(100% - 40px)"
                        ref="data_table"
                        :ordering="true"
                        :sort-column="sortColumnIndex"
                        :sort-direction="sortDirection"
                        :scroller="true"
                        :defer-render="true"
                        :scroll-collapse="true"
                        :filtering="true"
                    >
                        <table slot="table" class="pa-table widget-table">
                            <thead id="table_headers">
                                <tr>
                                    <th :key="column" v-for="column in columns" :class="classify(column) + '-column'">
                                        {{column}}
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </p-table2>
                </div>
                <div v-if="needsConfig" class="widget-body" style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center;" ref="temp_body">
                    <div class="vertical-center">
                        <button v-if="$parent.allowEditing" @click="configure" class="pa-btn pa-btn_naked_light_grey_no_fill pa-btn_lg" style="padding: 12px 35px" type="button">
                            <svg class="pa-icon">
                                <use xlink:href="#plus-circle-outline"></use>
                            </svg>
                            <span>Configure</span>
                        </button>
                        <p class="widget-unconfigured centered">
                            Please select an instance filter.
                        </p>
                    </div>
                </div>
                <div v-if="!valid" class="widget-body widget-body_error" :title="error_message" ref="body">
                    <p class="pa-message pa-message_error">
                        <svg class="pa-icon pa-icon_xl"><use xlink:href="#alert-circle"></use></svg>
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Vue from 'vue';
    import $ from 'jquery';
    import widgetMixin from './../widgetMixin';

    export default Vue.extend({
        mixins: [widgetMixin],

        data() {
            return {
                searchValue: '',
                content: {
                    columns: {},
                    column_order: [],
                    rows: {},
                },
                columns: [],
                columnCallbacks: {
                },
                sortColumn: '',
                sortColumnIndex: -1,
                sortDirection: '',
            };
        },

        computed: {
            tableType: function() {
                if (this.$options._componentTag === 'p-pod-phase-table-widget') {
                    return 'pod-phase';
                } else if (this.$options._componentTag === 'p-container-termination-table-widget') {
                    return 'container-termination';
                } else if (this.$options._componentTag === 'p-container-waiting-table-widget') {
                    return 'container-waiting';
                }
            },

            columnRenderers() {
                const result = {
                    0: 'hidden',
                    // Pod Phase Table
                    'podname-column': this.renderLinkColumn,
                    'cluster-column': this.renderLinkColumn,
                    'namespaces-column': this.renderLinkColumn,
                    'nodes-column': this.renderLinkColumn,
                    'clusters-column': this.renderLinkColumn,

                    // Container Termination Table
                    'containername-column': this.renderLinkColumn,
                    'pod-column': this.renderLinkColumn,
                };

                return result;
            },
        },

        events: {
            // Mixin: 'widget:new_data' -> this.initialize(config, content)
            // Mixin: 'widget:updated_data' -> this.update(content)
            // Mixin: 'widget:pend_config' -> this.pendNewConfig()
        },

        methods: {
            // Mixin: configure() -- opens configure modal
            initialize(config, content) {
                this.$refs.body.style.filter = '';
                this.localConfig = {...config};
                this.content = content;

                if (!this.content.success) {
                    return;
                }

                if (this.$refs.data_table && this.$refs.data_table.dataTable) {
                    this.$refs.data_table.dataTable.clear();
                    this.$refs.data_table.dataTable.destroy();
                    this.$refs.data_table.dataTable = null;
                    // const thead = this.$refs.data_table.$el.querySelector('thead');
                    // if (thead) {
                    //     thead.remove();
                    // }
                    // const tbody = this.$refs.data_table.$el.querySelector('tbody');
                    // if (tbody) {
                    //     tbody.remove();
                    // }
                }
                this.columns = content.column_order.slice(0);

                this.$nextTick(function() {
                    this.$refs.data_table.createTable();
                    if (this.sortColumnIndex >= 0 && this.sortDirection) {
                        this.$refs.data_table.setSort(this.sortColumnIndex, this.sortDirection);
                    }
                    this.insertData();
                    this.changePageLen();
                    const data_points = this.getDataPoints();
                    window.app.rootVue.$broadcast('datapoints:set_points', data_points, this.content.columns, this.sortColumn, this.sortDirection);
                    window.app.rootVue.$broadcast('columngroups:set_groups', data_points, this.localConfig.custom_controls.data_point_config, this.sortColumn, this.sortDirection, this.id);
                    // Add a span element to attach the sort icon to
                    this.$refs.data_table.dataTable.columns().iterator('column', function(ctx, idx) {
                        if (idx != 0) {
                            let header = $(this.$refs.data_table.dataTable.column(idx).header());
                            if (!header.find("span").length) {
                                header.append('<span class="sort-icon"/>')
                            }
                        }
                    }.bind(this));
                    this.$refs.data_table.dataTable.scroller.measure();

                    if (config.custom_controls.item_limit_count) {
                        this.setPageLen(config.custom_controls.item_limit_count);
                    }
                });
            },

            handleSearchChange: function(value) {
                this.searchValue = value;
                this.$refs.data_table.search(value);
            },

            classify: function(column) {
                if (column === '') {
                    return 'hidden';
                }
                let retVal = column;
                retVal = retVal.replace(/ /g, '');
                retVal = retVal.replace(/:/, '');
                retVal = retVal.toLocaleLowerCase();
                return retVal;
            },

            renderLinkColumn(data, type, row) {
                if (_.isNil(data) || data === 0) {
                    return "";
                }

                if (type === 'display' && this.$parent.showLinks) {
                    if (Array.isArray(data)) {
                        return data.map(
                            ({ url, title = "" }) => url && (acl ? this.$parent.userCanView(acl) : true)
                                ? `<a href="${url}" target="_blank">${title}</a>`
                                : title
                        ).join(', ');
                    }

                    if (!data.title) {
                        return data || "";
                    }

                    if (!data.url) {
                        return data.title;
                    }

                    if (data.acl) {
                        // Our entry came with an acl check we need to verify before rendering the link.
                        // console.log(data.title + ' ' + data.url + ' ' + data.acl + ' ' + this.$parent.userCanView(data.acl))
                        if (!this.$parent.userCanView(data.acl)){
                            return data.title;
                        }
                    }

                    return `<a href="${data.url}" target="_blank">${data.title}</a>`;
                } else {
                    let retVal = data.title;
                    if (typeof retVal === 'undefined') {
                        retVal = '';
                    }
                    return retVal;
                }
            },

            renderNumericColumn: function(data, type, row) {
                if (type === 'display') {
                    return data;
                } else {
                    try {
                        if (typeof data === 'number') {
                            return data;
                        } else if (typeof data === 'string') {
                            const num = data.match(/[0-9.]+/);
                            if (num) {
                                return Number(num);
                            }
                            return data;
                        } else {
                            return data;
                        }
                    } catch (e) {
                        console.debug(e);
                        return data;
                    }
                }
            },

            getDataPoints() {
                return this.localConfig.custom_controls ? this.localConfig.custom_controls.data_point_order : null;
            },

            insertData() {
                this.$refs.data_table.reloadTableData(this.content.rows);
            },

            changePageLen() {
                this.$refs.data_table.changePageLen(this.calculateTableRows());
            },

            setPageLen(length) {
                this.$refs.data_table.changePageLen(length);
            },

            update(content) {
                this.content = content;
                if (!this.content.success) {
                    return;
                }
                this.$refs.data_table.reloadTableData(this.content.rows);
            },
            getColumns() {
                return this.content.data_points;
            },

            reconfig() {
                if (!this.content.success) {
                    return;
                }
                this.$refs.data_table.changePageLen(this.calculateTableRows());
                this.$refs.data_table.dataTable.scroller.measure();
            },

            calculateTableRows() {
                const tableArea = this.$el.querySelectorAll('.dataTables_scrollBody')[0];
                if (!tableArea) { return this.localConfig.height * 2; }
                const tableHeight = tableArea.offsetHeight;
                const rowHeight = 38; // TODO: reliable?

                return Math.floor(tableHeight / rowHeight);
            },

            _getOpenWidgetId() {
                return this.$parent.$refs.configdrawer.editingWidget.id;
            },

            getConfigModules() {
                if (this.tableType === 'pod-phase') {
                    return [
                    {type: 'p-overview-module'},
                    {
                        type: 'p-custom-module',
                        options: {
                            ...(this.$parent.isScoping() ? {"ignore-scope-module": {}} : {}),
                            title: 'Options',
                            controls: [
                                {
                                    label: 'Pod Phase Filter',
                                    key: 'pod_phases',
                                    type: 'p-select',
                                    multiple: true,
                                    'default': [],
                                    options: [
                                        { label: 'Pending', value: 'Pending' },
                                        { label: 'Running', value: 'Running' },
                                        { label: 'Succeeded', value: 'Succeeded' },
                                        { label: 'Failed', value: 'Failed' },
                                        { label: 'Unknown', value: 'Unknown' },
                                    ],
                                },
                                {
                                    label: 'Group By',
                                    key: 'group_by',
                                    type: 'p-select',
                                    'default': 'none',
                                    options: [
                                        { label: 'None', value: 'none' },
                                        { label: 'Namespace', value: 'namespace' },
                                        { label: 'Node', value: 'node' },
                                        { label: 'Cluster', value: 'cluster' },
                                    ],
                                },
                                {
                                    id: 'backgroundColor',
                                    label: 'Background Color',
                                    key: 'backgroundColor',
                                    type: 'color-picker',
                                    default: '#ffffff'
                                },
                            ],
                        },
                    }];
                } else if (this.tableType === 'container-termination') {
                    return [
                    {type: 'p-overview-module'},
                    {
                        type: 'p-custom-module',
                        options: {
                            ...(this.$parent.isScoping() ? {"ignore-scope-module": {}} : {}),
                            title: 'Options',
                            controls: [
                                {
                                    label: 'Reason Filter',
                                    key: 'reasons',
                                    type: 'p-select',
                                    multiple: true,
                                    'default': [],
                                    options: [
                                        { label: 'OOMKilled', value: 'OOMKilled' },
                                        { label: 'Error', value: 'Error' },
                                        { label: 'Completed', value: 'Completed' },
                                        { label: 'ContainerCannotRun', value: 'ContainerCannotRun' },
                                        { label: 'DeadlineExceeded', value: 'DeadlineExceeded' },
                                    ],
                                },
                                {
                                    label: 'Group By',
                                    key: 'group_by',
                                    type: 'p-select',
                                    'default': 'none',
                                    options: [
                                        { label: 'None', value: 'none' },
                                        { label: 'Namespace', value: 'namespace' },
                                        { label: 'Node', value: 'node' },
                                        { label: 'Cluster', value: 'cluster' },
                                    ],
                                },
                                {
                                    id: 'backgroundColor',
                                    label: 'Background Color',
                                    key: 'backgroundColor',
                                    type: 'color-picker',
                                    default: '#ffffff'
                                },
                            ],
                        },
                    }];
                } else if (this.tableType === 'container-waiting') {
                    return [{
                        type: 'p-overview-module',
                    }, {
                        type: 'p-custom-module',
                        options: {
                            ...(this.$parent.isScoping() ? {"ignore-scope-module": {}} : {}),
                            title: 'Options',
                            controls: [
                                {
                                    label: 'Reason Filter',
                                    key: 'reasons',
                                    type: 'p-select',
                                    multiple: true,
                                    'default': [],
                                    options: [
                                        { label: 'ContainerCreating', value: 'ContainerCreating' },
                                        { label: 'CrashLoopBackOff', value: 'CrashLoopBackOff' },
                                        { label: 'ErrImagePull', value: 'ErrImagePull' },
                                        { label: 'ImagePullBackOff', value: 'ImagePullBackOff' },
                                        { label: 'CreateContainerConfigError', value: 'CreateContainerConfigError' },
                                        { label: 'InvalidImageName', value: 'InvalidImageName' },
                                        { label: 'CreateContainerError', value: 'CreateContainerError' },
                                    ],
                                },
                                {
                                    label: 'Group By',
                                    key: 'group_by',
                                    type: 'p-select',
                                    'default': 'none',
                                    options: [
                                        { label: 'None', value: 'none' },
                                        { label: 'Namespace', value: 'namespace' },
                                        { label: 'Node', value: 'node' },
                                        { label: 'Cluster', value: 'cluster' },
                                    ],
                                },
                                {
                                    id: 'backgroundColor',
                                    label: 'Background Color',
                                    key: 'backgroundColor',
                                    type: 'color-picker',
                                    default: '#ffffff'
                                },
                            ],
                        },
                    }];
                }
            },

            getOptionModules() {
                return [
                     // {type: 'p-columngroups-module', options: {}},
                ];
            },

            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this);

                // Configuration events need to be sent on next tick, to give
                // time to Vue to update the DOM
                Vue.nextTick(() => {
                    const data_points = this.getDataPoints();
                    if (data_points) {
                        window.app.rootVue.$broadcast('datapoints:set_points', data_points, this.content.columns, this.sortColumn, this.sortDirection);
                        window.app.rootVue.$broadcast('columngroups:set_groups', data_points, this.localConfig.custom_controls.data_point_config, this.sortColumn, this.sortDirection);
                    }
                });
            },

            dumpState() {
                const obj = Object();
                obj.data = this.$data;
                obj.config = {...this.localConfig};
                console.log(JSON.stringify(obj));
            },

        },

        props: {
            // Mixin: id:Number, config:Object, title:String
        },

        mounted() {
            // Mixin: dispatch('widget:domready')
            this.pendNewConfig();
            if (this.id < 0) {
                this.configure();
            };
        },
    });
</script>
