<template>
    <div style='background-color: #fafafa; box-shadow: 2px 2px grey;'>
        <div v-for="graph in data.graphs" :style="'float: left; position: relative; width:' + graphColWidth + ';'">
            <div class="pa-m-4 pa-p-8 pa-border-solid" style="background-color: white; border-radius: 4px; position: relative;">
                <div class="pa-p-8">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_11of12">
                            <p-dynamic-title 
                                :title="`${graph.truncatedTitle} (${graph.units})`" 
                                identifier="graph.identifier">        
                            </p-dynamic-title>
                        </div>
                        <div class="pa-grid-col pa-grid-col_1of12 pa-align-end">
                            <a @click="openModal(graph.url)">
                                <p-icon
                                    slot="trigger"
                                    icon="arrow-expand"
                                    size="sm"
                                    block
                                    class="pa-ml-auto"
                                >
                                </p-icon>
                            </a>
                        </div>
                    </div>
                </div>
                <p-graph 
                    :monitors="graph.ids" 
                    :server_id="graph.server_id"
                    :auto-load="true"
                    :network-port="true"
                    :exporting="false"
                    :timescale="graph.timescale"
                    :crosshairs="true"
                    background-color="#ffffff"
                    :sync-crosshairs="true"
                    width="100%;"
                    :min-height="200"
                    :display-y-axis-title="false"
                    align-legend="left"
                    :max-legend-height="40"
                    :date_range="date_range"
                    height="200px;">        
                </p-graph>
           </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        data: Object,
        date_range: String,
    },

    methods: {
        openModal: function(url) {
            this.$root.$broadcast(
                'modal:load', 
                {
                    id: 'dynamic_modal', 
                    url:url, 
                    open:true
                }
            );
        }
    },

    computed: {
        graphColWidth: function() {
            let length = this.data.graphs.length

            if(length === 1) {
                return "100%";
            }

            if(length === 2) {
                return "50%";
            }

            return "33%";
        },
    },


});
</script>