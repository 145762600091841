<style>
</style>

<template>
    <div class="module">
        <div class="module-header">
            <span class="pa-txt">Dashboard Scoping Adherence</span>
        </div>
        <div ref="body" v-show="open" transition="module-expand" class="module-body">
            <ul class="pa-vList">
                <li>
                    <label class="pa-option">
                        <input type="checkbox" class="pa-option-input" v-model="ignore_dashboard_scope" />
                        <span class="pa-option-icon"></span>
                        <span class="pa-option-txt">Ignore dashboard scoping filters</span>
                    </label>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const IgnoreScopeModule = Vue.extend({
        data() {
            return {
                ignore_dashboard_scope: false,
            };
        },

        props: {
            open: {
                type: Boolean,
                default: true
            },
            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        },

        methods: {
            sendConfig() {
                this.$parent.updatePending(this.editingWidget.id);

                var payload = {
                    widget_id: this.editingWidget.id,
                    ignore_dashboard_scope: this.ignore_dashboard_scope
                };

                $.ajax({
                    url: '/dashboardv2/setWidgetIgnoreDashboardScope',
                    method: 'POST',
                    data: payload,
                }).done(data => {
                    if (data.success && data.newConfig) {
                        this.editingWidget.config = data.newConfig;
                    }
                });
                this.editingWidget.pendNewConfig();
            },
            updateChosen() {
                var preset = this.editingWidget.config.custom_controls.ignore_dashboard_scope;
                if (preset === null || typeof preset === 'undefined' || preset === "false") {
                    preset = false;
                }
                this.ignore_dashboard_scope = preset;
            },
        },

        watch: {
            ignore_dashboard_scope() {
                this.sendConfig();
            },
        },

        vueReady() {
            this.updateChosen();
            this.$once('dashboard:new_config', this.updateChosen);
        }
    });

    export default IgnoreScopeModule;
</script>
