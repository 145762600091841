const styleProps = {
    spacing: ["m", "mx", "my", "mr", "ml", "mt", "mb", "p", "px", "py", "pr", "pl", "pt", "pb"],
    spacingAmounts: [
      "auto",
      "0",
      "1",
      "2",
      "3",
      "4",
      "6",
      "8",
      "10",
      "12",
      "14",
      "16",
      "18",
      "20",
      "24",
      "32",
      "40",
      "48",
      "56",
      "64",
    ],
    displays: ["block", "inline-block", "flex", "inline-flex", "none", "inline"],
  };
  
  const styleMixinProps = {
    ...styleProps.spacing.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: { type: String, default: "" },
      }),
      {}
    ),
    ...styleProps.displays.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: { type: String, default: "" },
      }),
      {}
    ),
  };
  
  const styleMixin = {
    props: styleMixinProps,
    computed: {
      computedMixinClass() {
        const classNames = {};
  
        styleProps.spacing.forEach((space) => {
          const value = this[space];
          if (styleProps.spacingAmounts.includes(value)) {
            classNames[`pa-${space}-${value}`] = true;
          }
        });
  
        styleProps.displays.forEach((display) => {
          const value = this[display];
          if (styleProps.displays.includes(value)) {
            classNames[value] = true;
          }
        });
  
        return classNames;
      },
    },
  };
  
  export default styleMixin;
  