const helpers = {
    jsonifyForm: function(form) {
        if (typeof form !== 'object' || form.tagName !== 'FORM') {
            return {};
        }

        const data = {};

        for (const element of form.elements) {
            if (!element.name) {
                continue;
            }
            switch (element.nodeName) {
                case 'INPUT':
                    switch (element.type) {
                        case 'file':
                        case 'submit':
                        case 'button':
                            break;
                        case 'checkbox':
                        case 'radio':
                            if (element.value === 'on') {
                                data[element.name] = element.checked;
                            } else if (element.checked) {
                                data[element.name] = element.value;
                            }
                            break;
                        default:
                            data[element.name] = element.value;
                            break;
                    }
                    break;
                case 'TEXTAREA':
                    data[element.name] = element.value;
                    break;
                case 'SELECT':
                    switch (element.type) {
                        case 'select-one':
                            data[element.name] = element.value;
                            break;
                        case 'select-multiple': {
                            const selected = [];
                            for (const opt of element.options) {
                                if (opt.selected) {
                                    selected.push(opt.value);
                                }
                            }
                            data[element.name] = selected;
                            break;
                        }
                    }
                    break;
            }
        }

        return data;
    },

    roundMetricValue(num) {
        if (num === 0) {
            return num.toFixed(1);
        } else if (Math.abs(num) >= 10) {
            return Math.floor(num);
        } else if (Math.abs(num) < 1) {
            const numZeros = Math.abs(Math.floor(Math.log10(Math.abs(num)))) - 1;
            return num.toFixed(numZeros + 2);
        }

        return num.toFixed(1);
    },

    abbrevNum(num) {
        if (Math.abs(num) >= 1000000) {
            const rounded = helpers.roundMetricValue(num / 1000000);
            return `${rounded}m`;
        }

        if (num >= 1000) {
            const rounded = helpers.roundMetricValue(num / 1000);
            return `${rounded}k`;
        }

        return helpers.roundMetricValue(num);
    },
};

export default helpers;
