<template>
    <div
        :id="id"
        :class="`onboarding-step onboarding-step-${step}`"
        :data-is-active="isActive ? 'actve' : 'inactive'"
        :data-step="step"
        :data-current-step="currentStep"
    >
        <slot v-if="isActive"></slot>
    </div>
</template>

<script>
import Vue from "vue";
import generateID from "../shared/utils/generateID";

export default Vue.extend({
    data() {
        return {
            id: "",
            step: 0,
            // currentStep gets updated from updateChildrensCurrentStep() in OnboardingSteps.vue
            currentStep: 0,
        };
    },
    computed: {
        isActive() {
            return this.step === this.currentStep;
        },
    },
    methods: {
        removeStep() {
            this.eventHub.$emit("remove-step", this);
        },
        addStep() {
            this.eventHub.$emit("add-step", this);
        },
    },
    destroyed() {
        this.removeStep();
    },
    mounted() {
        this.addStep();
    },
});
</script>
