<template>
    <div :id="id"
        :class="!inModal ? 'grid-stack-item' : 'modal-view'"
        :data-gs-x="localConfig.col.toString()"
        :data-gs-y="localConfig.row.toString()"
        :data-gs-width="localConfig.width.toString()"
        :data-gs-height="localConfig.height.toString()"
        :data-gs-auto-position="false"
        :data-gs-locked="true"
        >
        <div
            :class="!inModal ? 'grid-stack-item-content' : 'modal-view-content'"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;">
                <div :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered, 'expand-mode' : !$parent.editing && isHovered && !inModal, 'in-modal': inModal}">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_9of12 pa-align-center" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                            </span>
                            <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: textColor}"><use xlink:href="#spinner-spin-naked"></use></svg>
                            <p-tooltip2 :normal-white-space="true">
                                <span  v-if="!isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: textColor }">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span  v-if="isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }}</span>
                            </p-tooltip2>
                            <div v-if="graphUnitOptions.length > 0" class="pa-pl-4">
                                <p-graph-unit-select
                                    :graph-id="id"
                                    :graph-unit="graphUnitOptions[0].value"
                                    :options="graphUnitOptions"
                                >
                                </p-graph-unit-select>
                            </div>
                        </div>
                        <div class="pa-grid-col pa-grid-col_3of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger"  v-show="!$parent.editing && isHovered && !inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#arrow-expand"></use>
                                    </svg>
                                </button>
                                <span> Expand </span>
                            </p-tooltip2>
                            <button slot="trigger"  v-show="!$parent.editing && inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                <svg class="pa-icon remove-icon">
                                    <use xlink:href="#close"></use>
                                </svg>
                            </button>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="cloneWidget" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <p-icon icon="copy" ></p-icon>
                                </button>
                                <span> Clone this Widget </span>
                            </p-tooltip2>
                        </div>
                    </div>
                </div>
                <div v-show="content.success && valid" class="widget-body" :style="{'height': inModal && '85vh'}" style="flex-grow: 1; margin-bottom: 10px;" ref="body">
                </div>
                <div v-if="needsConfig" class="widget-body" :style="{'height': inModal && '85vh'}" style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center;" ref="temp_body">
                    <div class="vertical-center">
                        <button v-if="$parent.allowEditing || allowedEditing" @click="configure" class="pa-btn pa-btn_naked_light_grey_no_fill pa-btn_lg" style="padding: 12px 35px" type="button">
                            <svg class="pa-icon">
                                <use xlink:href="#plus-circle-outline"></use>
                            </svg>
                            <span>Configure</span>
                        </button>
                        <p class="widget-unconfigured centered">
                            Please select a metric and instance filter.
                        </p>
                    </div>
                </div>
                <div v-if="!valid" class="widget-body widget-body_error" :style="{'height': inModal && '85vh'}" :title="error_message">
                    <p class="pa-message pa-message_error">
                        <svg class="pa-icon pa-icon_xl"><use xlink:href="#alert-circle"></use></svg>
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
    import Vue from 'vue';
    import widgetMixin from './../widgetMixin';

    const Highcharts = require('highcharts');
    require('highcharts/modules/no-data-to-display')(Highcharts);

    const BarChartWidget = Vue.extend({
        mixins: [widgetMixin],

        data() {
            return {
                content: {
                    success: true,
                },
                chart: null,
                isAllPercentAxis: true,
                same_unit_resources_y: false,
                first_resource_unit: null,
                simillarity_counter: 0,
                default_colors: [
                    "#3D39BF","#E246EC","#10BCE8","#F25959","#F5AA0D",
                    "#B1B0AF","#39CA89","#5C98E3","#EF9F71","#79869F"
                ],
                rendered: false,
                allHaveMaxValue: false,
                selectedGraphUnit: null,
                graphUnitOptions: [],
                localConfig: this.config,
                allowedEditing: this.$parent.allowEditing || this.$parent.$parent.allowEditing,
                inModal: false,
            };
        },

        events: {
            'graph:changeUnit'(data) {
                if (data.id === this.id) {
                    this.selectedGraphUnit = data.unit;

                    Vue.nextTick(() => {
                        this.update(this.content);
                    });
                }
            },
        },

        props: {
            config: {
                type: Object,
                default() {
                    return {
                        graph_type: 'areagraph',
                        timescale: 'day',
                        data_source: 'monitor_point',
                        custom_controls: {},
                    };
                },
            },
        },

        watch: {

        },

        methods: {
            expandSelf() {
               this.inModal = !this.inModal;
                
            },
            getConfigModules() {
                return [
                    {type: 'p-overview-module'},
                    {type: 'p-instance-module'},
                    {
                        type: 'p-metric-module',
                        options: {
                            multiple: true,
                            sameUnit: true
                        },
                        open: true
                    },
                    {type: 'p-sorting-module'},
                ]
            },
            getOptionModules() {
                return [
                    {
                        type: 'p-timerange-module',
                        options: {liveOption: true}
                    },
                    {type: 'p-formatting-module'},
                    {
                        type: 'p-custom-module',
                        options: {
                            ...(this.$parent.isScoping() ? {"ignore-scope-module": {}} : {}),
                            title: 'Chart Options', controls: [
                                {
                                    label: 'Orientation',
                                    key: 'orientation',
                                    type: 'p-select',
                                    default: 'bar',
                                    multiple: false,
                                    options: [{value: 'bar', label: 'Rows'},
                                        {value: 'column', label: 'Columns'}],
                                },
                                {
                                    label: 'Aggregate Metrics',
                                    key: 'group_series',
                                    type: 'p-switch',
                                    default: false,
                                },
                                {
                                    label: 'Metric Calculation',
                                    key: 'aggregation',
                                    type: 'p-select',
                                    options: [
                                        {value: 'average', label: 'Average (Default)'},
                                        {value: 'min', label: 'Min'},
                                        {value: 'max', label: 'Max'},
                                        {value: 'sum', label: 'Sum'}],
                                    default: 'average',
                                },
                                {
                                    id: 'backgroundColor',
                                    label: 'Background Color',
                                    key: 'backgroundColor',
                                    type: 'color-picker',
                                    default: '#ffffff'
                                },
                            ]
                        }
                    },
                ]
            },
            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this);
            },
            initialize(config, content) {
                this.$refs.body.style.filter = '';
                this.content = content;
                this.localConfig = {...config};

                // Give DOM a chance to swap in/out configure messages
                Vue.nextTick(this.reconfig);
                this.rendered = this.content.success;
                this.isAllPercentAxis = content.values && content.values.every(v => {
                    return v.unit === 'percent';
                }) || false;

                const allHaveMaxValue = (
                    content.values && content.values.every((value) => Boolean(value.max_value))
                );

                this.allHaveMaxValue = allHaveMaxValue;

                if (allHaveMaxValue) {
                    this.graphUnitOptions = [...new Set(
                        content.values
                            .map(value => value.unit)
                            .filter(unit => unit && unit.toLowerCase() !== "percent" && unit !== "%")
                    )].map(unit => ({ label: unit, value: unit }));
                }
            },
            reconfig() {
                if (this.content.timescale &&
                    this.content.timescale !== this.localConfig.timescale) {
                    $(this.$refs.timeOverride).show();
                } else {
                    $(this.$refs.timeOverride).hide();
                }

                let data_values = [];
                let categories = [];

                if (!this.content.values) {
                    this.content.values = [];
                }

                $.each(this.content.values, (i, value) => {
                    let name = value.name;
                    if (false && name.length > 36) {
                        name = name.substr(0,17) + '...' + name.substr(name.length - 18, name.length);
                    }

                    const data_value = {
                        name: name,
                        y: value.data[0],
                        unit: value.unit,
                        tooltip_name: value.tooltip_name,
                        serverLink: value.serverLink,
                    };

                    if (this.selectedGraphUnit === "%" && Boolean(data_value.max_value)) {
                        data_value.originalUnit = data_value.unit;
                        data_value.unit = "percent";
                        data_value.originalY = data_value.y;
                        data_value.y = (data_value.y / data_value.max_value) * 100;
                    }

                    data_values.push(data_value);
                    categories.push(name);
                });

                let axis_label_rotation_angle = 0;
                if (this.content.graph_type === 'column') {
                    axis_label_rotation_angle = -45;
                };

                let colors = this.default_colors;
                if ('series_colors' in this.localConfig.custom_controls &&
                    this.localConfig.custom_controls.series_colors.length) {
                    colors = this.localConfig.custom_controls.series_colors.split(',');
                }

                let max = null;
                if (this.isAllPercentAxis || (this.allHaveMaxValue && this.selectedGraphUnit === "%")) {
                    max = '100';
                }

                let chartType = 'bar';

                if (this.content && this.content.graph_type) {
                    chartType = this.content.graph_type;
                }

                this.chart = Highcharts.chart(this.$refs.body,{
                    chart: {
                        type: chartType,
                        // height: this.get_body_height(),
                        backgroundColor: null,
                        borderColor: null,
                        plotShadow: false,
                        animation: true,
                        events: {
                            load: function() {
                                const axis = this.xAxis[0];

                                this.series[0].points.forEach(function(point, i) {
                                    if (axis.ticks[i]) {
                                        let label = axis.ticks[i].label.element;

                                        label.style.cursor = "pointer";

                                        label.onclick = function() {
                                            if (point.serverLink) {
                                                window.open(point.serverLink, "_blank");
                                            }
                                        };
                                    }
                                });
                            },
                        },
                    },
                    colors: colors,
                    credits: { enabled: false },
                    title: { text: null },
                    legend: {
                        enabled: false,
                    },
                    exporting: {
                        enabled: false,
                    },
                    tooltip: {
                        shadow: {
                            color: 'rgb(62, 64, 65)',
                            opacity: 0.1,
                            offsetX: 0,
                            offsetY: 1,
                            width: 4,
                        },
                        backgroundColor: '#ffffff',
                        borderWidth: 0,
                        borderRadius: 5,
                        padding: 6,
                        useHTML: true,
                        formatter() {
                            const y = Math.round(this.y);

                            let unit = this.point.unit || '' ;
                            let tip = '<b>' + this.point['tooltip_name'] + '</b><br/>' + y;

                            if (unit === 'percent') {
                                unit = '%';
                            }

                            tip += (unit ? ' ' + unit : '');

                            return tip;
                        },
                    },
                    xAxis: {
                        labels: {
                            autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
                            style: {
                                fontSize: '13px',
                                fontFamily: 'Verdana, sans-serif',
                            },
                            formatter() {
                                // The line break is causing the labels to overlap
                                // each other when they dont have enough room.
                                if (!this.value) {
                                    return '';
                                }
                                return this.value.replace("<br>", "");
                            },
                        },
                        categories: categories,
                    },
                    yAxis: {
                        floor: 0,
                        max: max,
                        gridLineColor: null,
                        tickWidth: 1,
                        tickLength: 0,
                        lineWidth: 1,
                        lineColor: '#ddd',
                        title: {
                            style: { fontSize: 11 },
                            text: this.content.title,
                        },
                    },
                    plotOptions: {
                        series: {
                            point: {
                                events: {
                                    click: function() {
                                        if (this.serverLink) {
                                            window.open(this.serverLink, "_blank")
                                        }
                                    },
                                },
                            },
                            marker: {
                                enabled: false,
                            },
                        },
                        column: {
                            shadow: false,
                        },
                        bar: {
                            shadow: false,
                        },
                    },
                    series: [
                        {
                            data: data_values,
                        },
                    ],
                });
            },
            update(content) {
                if (!content.success) {
                    return;
                }

                this.content = content;

                let data_values = [];

                $.each(this.content.values, (i, value) => {
                    let name = value.name;
                    if (false && name.length > 36) {
                        name = name.substr(0,17) + '...' + name.substr(name.length - 18, name.length);
                    }

                    const data_value = [
                        name,
                        value.data[0],
                    ];

                    if (this.selectedGraphUnit === "%" && Boolean(value.max_value)) {
                        data_value[1] = (data_value[1] / value.max_value) * 100;
                    }

                    data_values.push(data_value);
                });

                this.chart.series[0].setData(data_values, true, true, true);

                let yMax = null;

                if (this.selectedGraphUnit === "%") {
                    yMax = 100;
                }

                this.chart.yAxis[0].setExtremes(null, yMax, false, false);
                this.chart.redraw();
            },

            dumpState() {
                function censor(key, value) {
                    if (key === 'chart') {
                        return undefined;
                    }
                    return value;
                }
                const obj = Object();
                obj.data = this.$data;
                obj.config = this.config;
                console.log(JSON.stringify(obj, censor));
            },
        },
        mounted() {
            if (this.id < 0) { this.configure(); };
        },
    });

    export default BarChartWidget;
</script>
