<template>
    <p-icon
        :icon="instanceIcon"
        :size="size"
        :color="computedColor"
        :class="className"
    >
    </p-icon>
</template>

<script>
import Vue from 'vue';

const BASE_ICON = "instance";

const VMWARE_ICONS = {
    "vmware_host": "vmw-host",
    "vmware_cluster": "vmw-cluster",
    "vmware_datastore": "vmw-datastore",
};

const K8S_ICONS = {
    "cluster": "k8s-cluster",
    "node": "k8s-node",
    "pod": "k8s-pod",
    "service": "k8s-service",
    "default": "kubernetes"
};

export default Vue.extend({
    props: {
        size: {
            type: String,
            default: "xxxxl",
        },
        instance: Object,
        className: [String, Object, Array],
        color: {
            type: String,
            default: null,
        },
    },
    computed: {
        instanceIcon: function() {
            let icon = null;

            if (this.instance.isTemplate) {
                icon = "template";
            } else if(this.instance.deviceSubType == 'meraki.switch') {
                icon = "meraki-switch";
            } else if(this.instance.deviceSubType == 'meraki.appliance') {
                icon = "meraki-appliance";
            } else if(this.instance.deviceSubType == 'meraki.accesspoint') {
                icon = "meraki-access-point";
            } else if(this.instance.deviceSubType == 'meraki.cellular') {
                icon = "meraki-cellular";
            } else if(this.instance.deviceSubType == 'fortinet.fortigate') {
                icon = "fortigate_naked";
            } else if(this.instance.deviceSubType == 'fortinet.fortiswitch') {
                icon = "fortiswitch_naked";
            } else if(this.instance.deviceSubType == 'fortinet.fortiap') {
                icon = "fortiap_naked";
            } else if(this.instance.deviceSubType == 'sdwan-appliance') {
                icon = "sd-wan-appliance";
            } else if(this.instance.deviceSubType == 'fortinet.fortiextender') {
                icon = "fortiextender_naked";
            } else if(this.instance.deviceSubType == 'fortinet.fortimanager') {
                icon = "fortimanager_naked";
            } else if (this.instance.isNetworkDevice) {
                icon = "network-device_filled";
            } else if (
                this.instance.isCloudType &&
                this.instance.cloudInstance
            ) {
                icon = this.instance.cloudInstance.cloudService.logoUrl;
            } else if (this.instance.isContainerType) {
                icon = "docker";
            } else if (this.instance.isVmwareDevice) {
                icon = VMWARE_ICONS[this.instance.deviceSubType];
            } else if (this.instance.deviceType === "kubernetes") {
                if (this.instance.remoteAgentVersion) {
                    icon = "k8s-agent-pod";
                } else {
                    icon =
                        K8S_ICONS[this.instance.deviceSubType] ||
                        K8S_ICONS["default"];
                }
            } else if (this.instance.remoteAgentVersion) {
                icon = "instance-agent";
            } else if (this.instance.deviceModel === "compound_service_id") {
                icon = "compound-service"
            }

            return icon || BASE_ICON;
        },

        computedColor: function() {
            if(instance.remoteAgentVersion){
                return 'orange';
            }

            if(this.color) {
                return this.color;
            }

            return ''
        }
    },
});
</script>
