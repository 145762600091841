<style lang="scss" scoped>
    .pa-input_search {
        background: url(/static/assets/newux/media/svgs/magnifyingGlass.svg) no-repeat left;
        background-size: 20px;
        background-color: white;
        background-position: left 5px center;
        padding-left: 29px;
    }
</style>

<template>
    <input
        class="pa-input"
        :class="theme ? `pa-input_${theme}` : ''"
        :name="name"
        :type="type"
        v-bind:value="value"
        v-on:input="$emit('input', $event.target.value)"
        v-on:keyup.enter="enter"
        v-on:blur="$emit('blur', $event.target.value)"
        :placeholder="placeholder"
    />
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        name: String,
        value: [String, Number],
        theme: String,
        type: String,
        enter: {
            type: Function,
            'default': () => {}
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
});
</script>