<template>
    <div :class="computedClass">
        <slot></slot>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        cols: [Number, String],
        spaceBetween: Boolean,
        alignCenter: Boolean,
        alignEnd: Boolean,
        justifyEnd: Boolean,
        justifyCenter: Boolean,
        textCenter: Boolean,
    },
    computed: {
        computedClass: function() {
            const className = {
                'pa-grid-col': true,
                'pa-space-between': this.spaceBetween,
                'pa-align-center': this.alignCenter,
                'pa-align-end': this.alignEnd,
                'pa-justify-center': this.justifyCenter,
                'pa-justify-end': this.justifyEnd,
                'pa-txt_centered': this.textCenter,
            };

            if (this.cols) {
                const colClassname = 'pa-grid-col_#COL#of12'.replace(
                    '#COL#',
                    this.cols
                ); 
                className[colClassname] = true;
            }

            return className;
        },
    }
});
</script>
