<template>
    <component :is="as" :class="computedClass">
        <slot></slot>
    </component>
</template>

<script>
import Vue from "vue";

import styleMixin from "../mixins/styleMixin";

const FONT_SIZES = new Set([
    "xxs",
    "xs",
    "sm",
    "lg",
    "xl",
    "xxl",
]);

const FONT_WEIGHTS = new Set([
    "normal",
    "medium",
    "bold",
]);

const FONT_STYLES = new Set([
    "italic",
]);

const ALIGNMENTS = new Set([
    "left",
    "right",
    "center",
]);

const DECORATIONS = new Set([
    "underline",
]);

const TRANSFORMS = new Set([
    "capitalize",
    "lowercase",
    "uppercase",
]);

const COLORS = new Set([
    "crimson",
    "yellow",
]);

const BASE_CLASSNAME = "pa-txt";

export default Vue.extend({
    mixins: [styleMixin],
    props: {
        as: {
            type: String,
            default: "p",
        },
        fontSize: {
            type: String,
            default: "",
        },
        isTruncated: {
           type: Boolean,
           default: false,
        },
        weight: {
            type: String,
            default: "",
        },
        decoration: {
            type: String,
            default: "",
        },
        align: {
            type: String,
            default: "",
        },
        transform: {
            type: String,
            default: "",
        },
        color: {
            type: String,
            default: "",
        },
    },
    computed: {
        computedClass() {
            const classNames = {
                [`${BASE_CLASSNAME}_truncate`]: this.isTruncated,
            };

            if (FONT_SIZES.has(this.fontSize)) {
                classNames[`${BASE_CLASSNAME}_${this.fontSize}`] = true;
            }

            if (FONT_WEIGHTS.has(this.weight)) {
                classNames[`${BASE_CLASSNAME}_${this.weight}`] = true;
            }

            if (DECORATIONS.has(this.decoration)) {
                classNames[`${BASE_CLASSNAME}_${this.decoration}`] = true;
            }

            if (TRANSFORMS.has(this.transform)) {
                classNames[`${BASE_CLASSNAME}_${this.transform}`] = true;
            }

            if (ALIGNMENTS.has(this.align)) {
                classNames[`${BASE_CLASSNAME}_${this.align}`] = true;
            }

            if (FONT_STYLES.has(this.style)) {
                classNames[`${BASE_CLASSNAME}_${this.style}`] = true;
            }

            if (COLORS.has(this.color)) {
                classNames[`${BASE_CLASSNAME}_${this.color}`] = true;
            }

            return {
                ...this.computedMixinClass,
                ...classNames
            };
        },
    },
});
</script>
