<style>
.detected-categories-list {
    background-color: #edf5ff;
    border-radius: 4px;
}
.detected-categories-btn-wrapper {
    border-left: 1px solid #a6c6ff;
}
.detected-category {
    border: 1px solid #a6c6ff;
    border-radius: 2px;
    background-color: white;
}
</style>

<template>
    <p-row>
        <p-column>
            <div class="detected-categories pa-mb-12">
                <h5 class="pa-txt_13 pa-txt_medium pa-mb-1">Detected Applications</h5>
                <div class="detected-categories-list pa-px-12 pa-py-8">
                    <p-row>
                        <p-column cols="11">
                            <p-row>
                                <p-column
                                    :key="category.name + index"
                                    v-for="(category, index) in categories"
                                    cols="3"
                                >
                                    <div class="detected-category">
                                        <div class="pa-flex pa-align-center">
                                            <p-column
                                                cols="8"
                                                class="pa-p-0"
                                            >
                                                <p class="pa-txt_sm pa-txt_truncate pa-px-12">
                                                    {{ category.name }}
                                                </p>
                                            </p-column>
                                            <p-column
                                                cols="2"
                                                text-center
                                                class="detected-categories-btn-wrapper pa-p-0"
                                            >
                                                <p-tooltip :hover="true">
                                                    <p-button
                                                        slot="trigger"
                                                        @click="dismissCategory(category.textkey)"
                                                        unstyled
                                                        class="pa-py-8"
                                                    >
                                                        <p-icon
                                                            icon="eye-off"
                                                            color="blue"
                                                            size="lg"
                                                        >
                                                        </p-icon>
                                                    </p-button>
                                                    Dismiss
                                                </p-tooltip>
                                            </p-column>
                                            <p-column
                                                cols="2"
                                                text-center
                                                class="detected-categories-btn-wrapper pa-p-0"
                                            >
                                                <p-tooltip :hover="true">
                                                    <p-button
                                                        slot="trigger"
                                                        :id="'add-cat-' + classify(category.name)"
                                                        unstyled
                                                        @click="addService(category.textkey)"
                                                        class="pa-py-8"
                                                    >
                                                        <p-icon
                                                            icon="plus"
                                                            color="blue"
                                                            size="lg"
                                                        >
                                                        </p-icon>
                                                    </p-button>
                                                    Add metrics
                                                </p-tooltip>
                                            </p-column>
                                        </div>
                                    </div>
                                </p-column>
                            </p-row>
                        </p-column>
                        <p-column
                            cols="1"
                            align-end
                            justify-center
                        >
                            <p-tooltip :hover="true">
                                <p-button
                                    slot="trigger"
                                    unstyled
                                    @click="openOptOut"
                                    class="pa-py-4 pa-px-4"
                                >
                                    <p-icon
                                        icon="close"
                                        color="blue"
                                        size="xxl"
                                    >
                                    </p-icon>
                                </p-button>
                                Dismiss all
                            </p-tooltip>
                        </p-column>
                    </p-row>
                </div>
            </div>
        </p-column>
    </p-row>
</template>

<script>
import Vue from 'vue';

const DetectedApplications = Vue.extend({
    props: {
        categories: Array,
        addService: Function,
        openOptOut: Function,
        dismissCategory: Function,
    },
    methods: {
        classify: function(string) {
            return string.replace(/ /g, '-');
        },
    },
});

export default DetectedApplications;
</script>
