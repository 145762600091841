<style lang="scss">
.pa-side-nav__header {
    position: relative;
}
.pa-side-nav__header-logo-wrapper {
    display: inline-flex;
    align-items: center;
}
.pa-side-nav__header-brand {
    color: white;
    font-size: 18px;
    text-transform: uppercase;
    display: inline-block;
    max-width: 145px;
}
.pa-side-nav__header-customer {
    color: #b3b3b3;
    display: block;
    max-width: 145px;
}
.pa-side-nav__header-logo {
    height: auto;
    max-width: 24px;
}
.pa-side-nav__switch-customer {
    padding-left: 2px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    margin-left: 2px !important;
    border-radius: 4px;
    width: 100%;

    &:hover {
        background-color: #30475A !important;

        .pa-side-nav__header-customer {
            color: white;
        }

        .pa-icon {
            color: white !important;
        }
    }
}
.pa-side-nav__header-customer-tooltip {
    display: none;
}

.pa-side-nav__header-logo--collapsed {
    & + .pa-side-nav__header-customer-tooltip {
        background-color: #243746;
        padding: 8px 18px 8px 18px;
        position: fixed;
        left: 73px;
        top: 57px;
        z-index: 900;

        &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            border-top:  8px solid transparent;
            border-bottom:  8px solid transparent;
            border-right: 8px solid #243746;
            left: -8px;
            top: 7px;
        }

        &:before {
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0px;
            margin-left: -25px;
            margin-top: -5px;
            padding-right: 25px;
            padding-bottom: 5px;
        }

    }
    &:hover {
        & + .pa-side-nav__header-customer-tooltip {
            display: block;
        }
    }
}
.pa-side-nav--collapsed {
    .pa-side-nav__switch-customer {
        display: none;
    }
}
</style>

<template>
    <div 
        class="pa-side-nav__header pa-p-10 pa-pt-12"
        :class="!isOpen ? 'pa-ml-6' : ''"
    >
        <template v-if="hasLogo">
            <p-flex v-if="canSwitchCustomer" align-center>
                <div>
                    <img
                        :src="logoObj.src"
                        :style="{
                            width: logoObj.width,
                            height: logoObj.height,
                        }"
                        :class="[
                            'pa-img',
                            'pa-side-nav__header-logo',
                            !isOpen ? 'pa-side-nav__header-logo--collapsed' : ''
                        ]"
                    />
                    <div class="pa-side-nav__header-customer-tooltip">
                        <span class="pa-side-nav__header-brand pa-txt_truncate" :title="brandName">
                            {{ brandName }}
                        </span>
                        <span class="pa-side-nav__header-customer pa-txt_truncate" :title="customerName">
                            {{ customerName }}
                        </span>
                    </div>
                </div>
                <p-button
                    @click="switchCustomer"
                    unstyled
                    no-base-class
                    class="pa-side-nav__switch-customer pa-flex pa-align-center"
                >
                    <div class="pa-txt_left">
                        <span class="pa-side-nav__header-brand pa-txt_truncate" :title="brandName">
                            {{ brandName }}
                        </span>
                        <span class="pa-side-nav__header-customer pa-txt_truncate" :title="customerName">
                            {{ customerName }}
                        </span>
                    </div>
                    <p-icon icon="chevron-down" size="xxxl" class="pa-ml-auto"></p-icon>
                </p-button>
            </p-flex>
            <a v-else :href="logoLink" class="pa-side-nav__header-logo-wrapper" :title="brandName">
                <img
                    :src="logoObj.src"
                    :style="{
                        width: logoObj.width,
                        height: logoObj.height,
                    }"
                    :class="[
                        'pa-img',
                        'pa-side-nav__header-logo',
                        !isOpen ? 'pa-side-nav__header-logo--collapsed' : ''
                    ]"
                />
                <span v-if="isOpen" class="pa-side-nav__header-brand pa-ml-8 pa-txt_truncate">
                    {{ brandName }}
                </span>
            </a>
        </template>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    logoLink: String,
    logo: Object,
    logoSmall: Object,
    isCollapsed: Boolean,
    brandName: String,
    customerName: String,
    canSwitchCustomer: Boolean,
});

const isOpen = computed(() => !props.isCollapsed);

const hasLogo = computed(() => logoObj.value && logoObj.value.src);

const logoObj = computed(() => {
    let logo = props.logo;

    if (!isOpen.value && props.logoSmall) {
        logo = props.logoSmall;
    }

    return logo;
});

const switchCustomer = () => {
    window.app.rootVue.$broadcast('modal:load', {
        id: 'dynamic_modal',
        url: '/SelectCustomer',
        open: true,
    });
};
</script>