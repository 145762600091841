class ToastManager {
    constructor() {
        this.storage = window.sessionStorage;
        console.debug('PopupManager initialized');
        window.addEventListener('load', event => {
            this.popQueuedToasts();
        });
    }

    popQueuedToasts() {
        let toasts = this.sanitize(this.storage.getItem('toasts'));
        if (toasts && toasts.length) {
            try {
                toasts = JSON.parse(toasts);
            } catch (e) {
                toasts = [];
            }
        } else {
            toasts = [];
        }
        toasts.forEach(t => {
            this.showToast(t);
        });
        this.storage.setItem('toasts', []);
    }

    queueToast(t) {
        let toasts = this.sanitize(this.storage.getItem('toasts'));
        if (toasts && toasts.length) {
            try {
                toasts = JSON.parse(toasts);
            } catch (e) {
                toasts = [];
            }
        } else {
            toasts = [];
        }

        let toast = t;
        if (typeof toast === 'string') {
            toast = { text: toast };
        }
        toasts.push(toast);
        this.storage.setItem('toasts', JSON.stringify(toasts));
    }

    sanitize(string) {
        if (typeof str !== 'string') {
            return string;
        }
        
        const map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#x27;',
            "/": '&#x2F;',
        };
        const reg = /[&<>"'/]/ig;
        return string.replace(reg, (match)=>(map[match]));
    }

    showToast(t) {
        const body = document.getElementById('app');
        if (!body || !window.app || !window.app.rootVue) {
            return;
        }

        let toastBoxEl = document.getElementsByClassName('pa-toast-box');
        if (toastBoxEl.length) {
            toastBoxEl = toastBoxEl[0];
        } else {
            toastBoxEl = document.createElement('div');
            toastBoxEl.classList.add('pa-toast-box');
            body.appendChild(toastBoxEl);
        }

        const toastEl = document.createElement('p-toast');

        let toast = t;
        if (typeof t === 'string') {
            toast = { text: toast };
        }

        if (toast.type) {
            toastEl.setAttribute('type', toast.type);
        }

        if (toast.icon) {
            let iconName = toast.icon;
            if (!iconName.startsWith('#')) {
                iconName = `#${iconName}`;
            }

            const namespace = 'http://www.w3.org/2000/svg';
            const svgEl = document.createElementNS(namespace, 'svg');
            svgEl.slot = 'icon';
            svgEl.classList.add('pa-icon');
            svgEl.classList.add('pa-icon_light');

            if (toast.iconStyle) {
                svgEl.classList.add(`pa-icon_${toast.iconStyle}`);
            }

            const useEl = document.createElementNS(namespace, 'use');
            useEl.setAttribute('href', iconName);

            svgEl.appendChild(useEl);
            toastEl.appendChild(svgEl);
        }

        if (toast.text) {
            const spanEl = document.createElement('span');
            spanEl.innerHTML = this.sanitize(toast.text);
            toastEl.appendChild(spanEl);
        }

        if (toast.hreftext) {
            const spanEl = document.createElement('span');
            spanEl.innerHTML = this.sanitize(toast.hreftext);
            toastEl.appendChild(spanEl);
            const anchorEl = document.createElement('a');
            anchorEl.href = toast.hreflink;
            toastEl.appendChild(anchorEl);
            const link = document.createTextNode(toast.template_name);
            anchorEl.appendChild(link);
            const namespace = 'http://www.w3.org/2000/svg';
            const svgEl = document.createElementNS(namespace, 'svg');
            svgEl.classList.add('pa-icon');
            svgEl.classList.add('pa-icon_blue');
            svgEl.classList.add('open-in-new');
            svgEl.classList.add('pa-pl-4');
            const useEl = document.createElementNS(namespace, 'use');
            useEl.setAttribute('href', "#open-in-new");
            anchorEl.setAttribute('target', "_blank");
            svgEl.appendChild(useEl);
            anchorEl.appendChild(svgEl);
        }

        toastEl.setAttribute('v-bind:auto-open', true);
        toastBoxEl.appendChild(toastEl);
        window.app.rootVue.$compile(toastEl);
    }

    clearToasts() {
        let toastBoxEl = document.getElementsByClassName('pa-toast-box');
        if (!toastBoxEl.length) {
            return;
        }
        toastBoxEl = toastBoxEl[0];
        while (toastBoxEl.firstChild) {
            toastBoxEl.removeChild(toastBoxEl.firstChild);
        }
    }
}

export default ToastManager;
