<style>
    .rollup-module .module-body {
        max-height: unset;
    }
    .rollup-module .pa-expando-hd {
        border: 1px solid #dadada;
        background: #fcfcfc;
    }
    .rollup-module .pa-expando.isActive .pa-expando-hd {
        border-bottom: none;
    }
    .rollup-module .pa-expando-box {
        border: 1px solid #dadada;
        border-top: none;
        background: #fcfcfc;
    }
    .rollup-module .pa-input.typeahead-wrapper {
        background: #fff;
    }
    .rollup-module .pa-field-bd .pa-input_taggle {
        width: 100%;
        background: #fff;
    }
</style>

<template>
    <div class="module rollup-module" :class="{ 'open': open }">
        <div class="module-header" @click="toggle">
            <span class="pa-txt">Service</span>
            <svg class="pa-icon"><use xlink:href="#chevron-down"></use></svg>
        </div>
        <div ref="body" v-show="open" transition="module-expand" class="module-body">
            <ul class="pa-vList pa-vList_x2">
                <p-expando v-for="(service, index) in services"                        
                        ref="expandos"
                        :key="index"
                        :title="service.name"
                        :editable-title="true"
                        :title-change-callback="titleChange.bind(null, service)"
                        :removable="true"
                        :remove-callback="removeService.bind(null, service)">
                    <ul class="pa-vList pa-vList_x2">
                        <div class="pa-field pa-field_vertical">
                            <div class="pa-field-hd">
                                <label for="template" class="pa-label">
                                    Metrics Types
                                    <svg v-show="!ready" class="pa-icon pa-icon_xs"><use xlink:href="#spinner-spin"></use></svg>
                                </label>
                            </div>
                            <div class="pa-field-bd">
                                <p-typeahead-select
                                    ref="metricselect"
                                    :force-in-modal="true"
                                    :disabled="!ready"                                    
                                    :model.sync="service.metrics"
                                    :optgroups="metricOptions"
                                    placeholder="Add metrics by searching within this box - you can search by metric (e.g., CPU % Used) or category (CPU)">
                                </p-typeahead-select>
                            </div>
                            <div class="pa-field-ft">
                            </div>
                        </div>
                        <div class="pa-field pa-field_vertical">
                            <div class="pa-field-hd">
                                <label for="template" class="pa-label">
                                    Metric Tag Filter
                                </label>
                            </div>
                            <div class="pa-field-bd">
                                <p-tags-input ref="tags_input" name="tags[]" :model.sync="service.metricTags" :autocomplete="true" :suggestions="allTags"></p-tags-input>
                            </div>
                            <div class="pa-field-ft">
                            </div>
                        </div>
                        <div class="pa-field pa-field_vertical">
                            <div class="pa-field-hd">
                                <label for="template" class="pa-label">
                                    Instance Tag Filter
                                </label>
                            </div>
                            <div class="pa-field-bd">
                                <p-tags-input ref="tags_input" name="tags[]" :model.sync="service.serverTags" :autocomplete="true" :suggestions="allTags"></p-tags-input>
                            </div>
                            <div class="pa-field-ft">
                            </div>
                        </div>
                    </ul>
                </p-expando>
                <div>
                    <button @click="addService" class="pa-btn naked_blue">Add Service</button>
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

const RollupModule = Vue.extend({
        events: {
            'config:update_metrics': function() {
                this.getAvailableMetrics(() => {
                    Vue.nextTick(() => {
                        this.ready = true;
                    });
                });
            },
        },

        data() {
            return {
                ready: false,
                metricOptions: [],
                services: [],
            };
        },

    props: {            
            options: {
                type: Object,
                'default': function() {
                    return {};
                },
            },
            open: {
                type: Boolean,
                'default': true,
            },
            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        },

        methods: {
            toggle() {
                this.open = !this.open;
            },

            sendConfig() {
                this.$parent.updatePending(this.editingWidget.id);

                const payload = {
                    widget_id: this.editingWidget.id,
                    services: JSON.stringify(this.services),
                };
                $.ajax({
                    url: '/dashboardv2/setWidgetRollups',
                    method: 'POST',
                    data: payload,
                })
                    .done(data => {
                        if (data.success && data.newConfig) {
                            this.editingWidget.config = data.newConfig;
                        }
                    });
                this.editingWidget.pendNewConfig();
            },

            addService() {
                this.services.push({
                    name: 'New Service',
                    metrics: [],
                    serverTags: [],
                    metricTags: [],
                });
                Vue.nextTick(() => {
                    // Open new expando
                    const expandos = this.$refs.expandos;
                    expandos[expandos.length - 1].open();
                });
            },

            removeService(service) {
                this.services = this.services.filter(s => {
                    return s !== service;
                });
            },

            titleChange(service, title) {
                service.name = title; // eslint-disable-line no-param-reassign
            },

            getAvailableMetrics(callback) {                
                const widgetId = this.editingWidget.id;
                if (widgetId < 0) { return; }              

                // First grab our copy from local storage (if exists)
                const localAvailableMetrics =
                    window.app.localStorageManager.getObject('metrics.available', {});

                const payload = {
                    widgetId: widgetId,
                    resellerDashboard: window.dashboard.resellerDashboard,
                };
                // Include all the keys from our local copy, minus the actual options
                Object.entries(localAvailableMetrics).forEach(e => {
                    const key = e[0];
                    const val = e[1];
                    if (e[0] !== 'options') {
                        payload[key] = val;
                    }
                });

                $.ajax({
                    url: '/dashboardv2/getAvailableMetrics',
                    method: 'GET',
                    context: this,
                    data: payload,
                })
                 .done(function(data) {
                     if (!data.success) { return; }

                     if (data.new_value) {
                         this.metricOptions = data.metric_options.options;
                         // Store the new copy
                         window.app.localStorageManager
                             .setObject('metrics.available', data.metric_options);
                     } else if (localAvailableMetrics) {
                         this.metricOptions = localAvailableMetrics.options;
                     } else {
                         console.error('Cannot get metrics');
                         this.metricOptions = [];
                     }

                     // Call the callback first
                     if (typeof callback !== 'undefined') {
                         callback();
                     }                     
                 });
            },

            setupRollups() {
                const cc = this.editingWidget.config.custom_controls;
                if (!cc.rollups) {
                    return;
                }

                const rollups = cc.rollups.slice();
                rollups.forEach(rollup => {
                    const selectedMetrics = rollup.metrics;
                    const rollupSelected = [];
                    if(typeof rollup.metricTags === 'undefined') {
                        rollup.metricTags = [];
                    }
                    if (typeof selectedMetrics !== 'undefined') {
                        const agentMetrics = selectedMetrics.agent;
                        const networkMetrics = selectedMetrics.network;
                        if (typeof agentMetrics !== 'undefined') {
                            Object.entries(agentMetrics).forEach(entry => {
                                const category = entry[0];
                                const metrics = entry[1];
                                metrics.forEach(m => {
                                    rollupSelected.push(`${category}#${m}`);
                                });
                            });
                        } 
                        if (typeof networkMetrics !== 'undefined') {
                            Object.entries(networkMetrics).forEach(entry => {
                                const category = entry[0];
                                const metrics = entry[1];
                                metrics.forEach(m => {
                                    rollupSelected.push(`${category}#${m}`);
                                });
                            });
                        }
                        if(typeof networkMetrics == 'undefined' && typeof agentMetrics == 'undefined') {
                            Object.entries(selectedMetrics).forEach(entry => {                                
                                const category = entry[1].split('#')[0]
                                const metric = entry[1].split('#')[1]
                                rollupSelected.push(`${category}#${metric}`);                                
                            });
                        }
                        rollup.metrics = rollupSelected; // eslint-disable-line
                    }
                });                

                this.services = rollups;
            }
        },

        watch: {
            'services': {
                deep: true,
                handler: function(val, oldVal) {
                    if (this.ready) {
                        this.sendConfig();
                    }
                }
            },
        },

        computed: {
            allTags: function() {
                if (window.dashboard && window.dashboard.resellerDashboard) {
                    return [];
                } else if (this.$root.customerConfig) {
                    return this.$root.customerConfig.allTags;
                } else {
                    return [];
                }
            },
        },

        vueReady() {
            this.getAvailableMetrics(() => {
                const w = this.editingWidget;
                if (w.id < 0) { this.ready = true; return; }

                this.setupRollups();
                
                Vue.nextTick(() => {
                    this.ready = true;
                });
            });
        },
    });

    export default RollupModule;
</script>
