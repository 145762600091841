<template>
    <div class="module">
        <div ref="body" v-show="open" transition="module-expand" class="module-body">
            <div class="pa-field pa-field_vertical">
                <div class="pa-field-hd">
                    <label class="pa-label">
                        Widget Name
                    </label>
                </div>
                <div class="pa-field-bd">
                    <input type="text" class="pa-input" v-model="title" />
                </div>
                <div class="pa-field-ft">
                </div>
            </div>
            <ul class="pa-hList pa-hList_x3" style="display:none;">
                <li>
                    <div class="pa-field pa-field_vertical">
                        <div class="pa-field-hd">
                            <label class="pa-label">
                                Height
                            </label>
                        </div>
                        <div class="pa-field-bd">
                            <input type="text" class="pa-input pa-input_xxs" v-model="editingWidget.config.height" />
                        </div>
                    </div>
                </li>
                <li>
                    <div class="pa-field">
                        <div class="pa-field-hd">
                            <label class="pa-label">
                                Width
                            </label>
                        </div>
                        <div class="pa-field-bd">
                            <div class="pa-select" style="width: 4rem;">
                                <select class="pa-select-menu" v-model="editingWidget.config.width">
                                    <option v-for="n in 30">{{ n }}</option>
                                </select>
                                <span class="pa-select-icon"></span>
                            </div>
                        </div>
                    </div>
                </li>
                <li style="margin: 1.625rem 0 0 0.9375rem;">
                    <p>of 30 columns</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';


    const OverviewModule = Vue.extend({

        events: {
            'overview_module:save_title'() {
                if (this.titleChange) {
                    this.saveTitle();
                }
            }
        },

        watch: {
            'title': function (val, oldVal) {
                this.titleChange = true;
                this.debouncedTitleChangeCallback();
            },
            chosen() {
                this.sendConfig();
            },
        },

        data() {
            return {
                format_options: [
                    {value: "hide_server_name", label: "Hide Server Name"},
                    {value: "hide_units", label: "Hide Units"},
                    {value: "hide_server_fqdn", label: "Hide Server FQDN"},
                    {value: "hide_metric_type", label: "Hide Metric Type"},
                    {value: "hide_option_name", label: "Hide Option Name"},
                ],
                chosen: [],
                titleChange: false,
                title: "",
            }
        },

        props: {
            options: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            open: {
                type: Boolean,
                default: true
            },
            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
            features: {
                type: Array,
                'default': () => [],
            },
        },

        methods: {
            debouncedTitleChangeCallback: _.debounce(function() {
                this.saveTitle();
            }, 1000),

            close() {
                this.saveTitle();
                this.eventHub.$emit('dashboard:close_config');
            },

            toggle() {
                this.open = !this.open;
            },

            saveTitle() {
                const payload = {
                    widget_id: this.editingWidget.id,
                    title: this.title
                };

                $.ajax('/dashboardv2/setWidgetTitle', {
                    method: 'GET',
                    data: payload,
                    context: this,
                })
                .done(function(data) {
                    if (!data.success) {
                        console.log(data.msg);
                        return;
                    }

                    this.editingWidget.updateTitle(this.title);
                    this.$emit('title:update', this.title);
                });
            }
        },

        vueReady() {
            this.title = this.editingWidget.localConfig.title;
            this.$once('dashboard:new_config', this.updateChosen);
        }
    });

    export default OverviewModule;
</script>
