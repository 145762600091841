<template>
    <div class="module users-module">
        <div class="module-header">
            <span class="pa-txt">Fabric</span>
        </div>
        <div ref="body" v-if="ready" transition="module-expand" class="module-body">
                <div class="pa-field pa-field_vertical">
                    <div class="pa-field-hd">
                        <label class="pa-label">
                            Select Fabric Root
                        </label>
                    </div>
                    <div v-if="fabricRootsCount !== -1" class="pa-field-bd">
                        <span v-if="fabricRootsCount === 0">
                            There are no fabric roots found
                        </span>
                        <p-select
                            v-else
                            :options="computedFabricRoots"
                            :model.sync="selected"
                            :multiple="false"
                            class="pa-select_stretch"
                            :searchable="useTypeahead"
                            :on-search-change-callback="searchFabricRoots"
                            :mode="fabricSelectMode"
                            :autocomplete="false"
                            :min-search-change-length="2"
                        >
                        </p-select>
                    </div>
                    <div class="pa-field-ft">
                    </div>
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { getFabricRoots, getFabricRootsCount } from '../../api/services/UtilService';
    import { setWidgetFabricRoot } from '../../api/services/DashboardService';

    const FabricModule = Vue.extend({
        data() {
            return {
                ready: false,
                fabricRoots: [],
                selected: null,
                selectedDetails: null,
                fabricRootsCount: -1,
            };
        },

        props: {
            options: {
                type: Object,
                'default': function() {
                    return {};
                },
            },
            open: {
                type: Boolean,
                'default': true,
            },
            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        },

        computed: {
            useTypeahead() {
                return this.fabricRootsCount >= 20;
            },
            computedFabricRoots() {
                return _.uniqBy([
                    ...(this.selectedDetails ? [this.selectedDetails] : []),
                    ...this.fabricRoots,
                ], "value");
            },
            fabricSelectMode() {
                if (this.useTypeahead) {
                    return "typeahead";
                }

                return "select";
            },
        },

        methods: {
            toggle() {
                this.open = !this.open;
            },
            async searchFabricRoots(searchQuery) {
                await this.fetchFabricRoots({ searchQuery });
            },
            async sendConfig() {
                if (!this.ready) { return; }

                try {
                    const response = await setWidgetFabricRoot({
                        widgetId: this.editingWidget.id,
                        fabricConnectionId: this.selected,
                    });
                    if (response.data.newConfig) {
                        this.editingWidget.config = response.data.newConfig;
                    }
                } catch (error) {
                    console.error(error);
                }

                this.$parent.updatePending(this.editingWidget.id);
                this.editingWidget.pendNewConfig();
            },

            async fetchFabricRootsCount() {
                try {
                    const response = await getFabricRootsCount();
                    this.fabricRootsCount = response.data.count;
                } catch (error) {
                    console.error(error);
                }
            },

            async fetchFabricRoots({ ids, searchQuery }) {
                try {
                  const response = await getFabricRoots({
                    ids,
                    search_query: searchQuery,
                  });

                  if (response.data.success) {
                    this.fabricRoots = [...response.data.fabric_roots];
                  } else {
                    console.error("Failed to get fabric roots");
                  }
                } catch (error) {
                    console.error(error);
                }
            },
        },

        watch: {
            fabricRoots(curr) {
                if (!this.selectedDetails) {
                    const matched = curr.find(fabricRoot => fabricRoot.value === this.selected);
                    this.selectedDetails = matched ? {...matched} : null;
                }
            },
            selected() {
                if (!this.ready) { return; }
                this.sendConfig();
            },
            async fabricRootsCount(curr, prev) {
                if (prev === -1) {
                    const params = this.useTypeahead ? {
                        ids: String(this.selected),
                    } : {};
                    await this.fetchFabricRoots(params);
                }
            }
        },

        async vueReady() {
            const w = this.editingWidget;
            if (w.id < 0) {
                this.ready = true;
                return;
            }
            const cc = w.config.custom_controls;
            if (cc && cc.fabric_connection_id) {
                this.selected = w.config.custom_controls.fabric_connection_id;
            } 
            await this.fetchFabricRootsCount();
            Vue.nextTick(() => {
                this.ready = true; 
            });
        },
    });

    export default FabricModule;
</script>
