<style lang="scss">
    $charcoal-grey: #2c3438;
    $border-grey: #e6e6e6;
    $battleship-grey: #69717d;

    .sparklines {
        box-sizing: border-box;
        margin-top: 20px;
        max-height: 70vh;
        overflow-x: hidden;
        overflow-y: auto;

        div {
            min-height: 0px !important;
        }

        &__timeframe {
            border-bottom: 1px solid $border-grey;
            color: $battleship-grey;
            
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 0;
            padding-bottom: 4px;
        }
        
        .sparkline-group {
            align-items: center;
            border-bottom: 1px solid $border-grey;
            display: flex;
            padding: 6px 4px;
            
            &:hover {
                background-color: #f7f7f7;
            }
        }

        .sparkline-group, .option-sparkline {
            cursor: pointer;

            &:hover svg {
                fill: #3e92df;
            }

            &:hover path.highcharts-graph {
                stroke: #4e82df;
            }
        }

        .group-title {
            color: $charcoal-grey;
            font-size: 13px;
            margin-left: 0px;
            
            flex-basis: 40%;
            flex-shrink: 0;
            white-space: nowrap;
            margin-bottom: 0;
            vertical-align: middle;
        }

        .sparkline {
            flex-basis: 50%;
        }

        .expand-graph {
            cursor: pointer;
            flex-basis: 10%;
            position: static;
            text-align: right;

            svg {
                height: calc(16px/.75);
                margin-right: -4px;
                width: calc(16px/.75);
            }
        }

        .sparkline-opt-group {
            margin-top: 24px;

            .group-title {
                padding: 4px 4px;
                border-bottom: 1px solid #ccc;
                margin-left: 0;
            }

            p {
                margin-bottom: 0;
            }
        }

        .option-sparkline {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ccc;
            padding: 6px 4px;

            .option-title {
                color: $charcoal-grey;
                
                font-size: 13px;
                font-weight: normal;
                flex-basis: 40%;
                flex-shrink: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            div:not(.expand-graph):not(.pa-tooltip2) {
                flex-basis: 50%;
            }

            &:hover {
                background-color: #f7f7f7;
            }
        }

        .pa-graph {
            min-height: 30px !important;
        }
        .pa-loader {
            font-size: 20px;
            margin-left: -20px;
        }

    }
</style>

<template>
    <div class="sparklines">
        <p class="sparklines__timeframe">Last hour</p>
        <template v-for="config in configs">
            <div v-if="config.monitor_groups.length" class="sparkline-opt-group">
                <p class="group-title">{{ config.name }}</p>
                <div
                    class="option-sparkline"
                    v-for="gConfig in config.monitor_groups"
                    @click="eventHub.$emit('openChart', gConfig.monitors, config.name)"
                >
                    <p class="option-title">
                        {{ gConfig.option }}
                    </p>
                    <p-tooltip2 class="option-tooltip" :previous-sibling="true">
                        <span v-html="gConfig.option" style="white-space: nowrap;"></span>
                    </p-tooltip2>
                    <p-graph
                        :highcharts-config="highchartsConfig"
                        :monitors="gConfig.monitors"
                        :server_id="instance.id"
                        :auto-load="true"
                        timescale="hour"
                        width="100%;"
                        :exporting="false"
                        :resize="false"
                        height="150px;"
                    >
                    </p-graph>
                </div>
            </div>
            <div
                v-else
                class="sparkline-group"
                @click="eventHub.$emit('openChart', config.monitors, config.name)"
            >
                <p class="group-title">
                    {{ config.name }}
                </p>
                <p-tooltip2 v-if="config.fullName" :previous-sibling="true">
                    <span v-html="config.fullName" style="white-space: nowrap;"></span>
                </p-tooltip2>
                <div class="sparkline" v-if="config.monitors.length">
                    <p-graph
                        :highcharts-config="highchartsConfig"
                        :monitors="config.monitors"
                        :server_id="instance.id"
                        :auto-load="true"
                        timescale="hour"
                        width="100%;"
                        :exporting="false"
                        :resize="false"
                        height="150px;"
                    >
                    </p-graph>
                </div>
            </div>
        </template>
        <p-text v-if="!configs || !configs.length" class="pa-m-0" font-size="sm">
            There is nothing to report for this device.
        </p-text>
    </div>
</template>

<script>
    import Vue from 'vue';
    import HighchartsConfigs from '../../lib/highcharts-configs';

    export default Vue.extend({
        props: {
            instance: Object,
            configs: Array,
        },
        data() {
            return {
                highchartsConfig: {
                    ...HighchartsConfigs.sparkline
                },
            };
        },
    });
</script>
