<template>
    <div ref="container" class="pa-synthetic-checks-waterfall"></div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';

    var Highcharts = require('highcharts');

    export default Vue.extend({
        data() {
            return {
                chart: null,
                titleType: this.serviceType == 'selenium' ? 'Browser' : 'JavaScript'
            };
        },
        props: {
            steps: {
                type: Array,
                default: () => [],
                serviceType: String,
            },
        },
        watch: {
            steps(curr) {
                if (this.chart) {
                    this.chart.series[0].setData(curr);

                    const pointHeight = 24;
                    const totalPointsHeight = (curr.length * pointHeight);
                    const heightDifference = 100;

                    this.chart.setSize(undefined, totalPointsHeight + heightDifference, false);
                    this.chart.success = curr.success;
                    this.chart.redraw();
                }
            },
        },
        methods: {
            setupChart() {
                if (this.chart) {
                    return;
                }

                this.chart = new Highcharts.Chart({
                    chart: {
                        type: "waterfall",
                        inverted: true,
                        renderTo: this.$el,
                        borderRadius: 2,
                        borderWidth: 1,
                        borderColor: "#d8d8d8",
                        spacingBottom: 16,
                        spacingLeft: 18,
                        spacingRight: 16,
                        spacingTop: 16,
                        plotBorderColor: "#d8d8d8",
                        plotBorderWidth: 1,
                        plotBorderTopWidth: 0,
                        success: true
                    },

                    title: {
                        text: this.titleType + " Synthetic Output",
                        align: "left",
                        style: {
                            fontSize: 13,
                            color: "#393e49",
                            fontWeight: 600,
                        },
                    },

                    subtitle: {
                        text: null,
                        style: {
                            display: "none"
                        }
                    },

                    credits: {
                        enabled: false
                    },

                    exporting: {
                        enabled: false
                    },

                    legend: {
                        enabled: false
                    },

                    tooltip: {
                        enabled: false,
                    },

                    xAxis: {
                        type: "category",
                        tickLength: 0,
                        labels: {
                            style: {
                                fontSize: 13,
                                color: "#393e49",
                                fontWeight: 600,
                            },
                            useHTML: true,
                            verticalAlign: "middle",
                            formatter: function() {
                                const yData = this.axis.series[0].yData;
                                const currPos = this.pos;
                                const nextPos = this.pos + 1;
                                const stepData = this.chart.series[0].data[currPos];
                                const yAxisValue = yData[currPos];
                                const nextYAxisValue = yData[nextPos];
                                const successIcon = `<svg style="margin-bottom: -4px; margin-right: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                <path fill="#3CB588" fill-rule="evenodd" d="M8 .305a7.695 7.695 0 110 15.39A7.695 7.695 0 118 .305zM8 1.99C4.68 1.99 1.99 4.68 1.99 8S4.68 14.01 8 14.01s6.01-2.69 6.01-6.01S11.32 1.99 8 1.99zm2.763 2.274l1.47 1.149-3.72 4.759.004.003-1.148 1.47-.004-.003-.002.004-1.47-1.149.002-.003-1.958-1.53 1.15-1.47 1.957 1.53 3.719-4.76z"/>
                                </svg>`;
                                const failIcon = `<svg style="margin-bottom: -4px; margin-right: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17">
                                <path fill="#E31212" fill-rule="evenodd" d="M8 .805a7.695 7.695 0 110 15.39A7.695 7.695 0 118 .805zM8 2.49c-3.32 0-6.01 2.69-6.01 6.01S4.68 14.51 8 14.51s6.01-2.69 6.01-6.01S11.32 2.49 8 2.49zm2.632 2.171l1.212 1.213-2.63 2.629 2.625 2.623-1.213 1.213-2.623-2.624-2.624 2.624-1.212-1.213L6.79 8.502 4.16 5.874 5.374 4.66l2.628 2.63 2.63-2.629z"/>
                                </svg>`;

                                let success = true;
                                let icon = successIcon;
                                if (
                                    _.isNull(yAxisValue) ||
                                    _.isNull(nextYAxisValue) ||
                                    (stepData && stepData.success == false)
                                ) {
                                    success = false;
                                }

                                if (!success) {
                                    icon = failIcon;
                                }

                                return `${icon} Step ${this.value + 1}`;
                            },
                        },
                        alternateGridColor: "#f2f3f5",
                        lineColor: "#d8d8d8",
                    },

                    yAxis: {
                        title: {
                            text: null,
                        },
                        opposite: true,
                        gridLineColor: "#d8d8d8",
                    },

                    plotOptions: {
                        series: {
                            point: {
                                events: {
                                    mouseOver: function (event) {
                                        this.$emit("hover", {
                                            hoverState: "over",
                                            x: event.target.x,
                                            y: event.target.y
                                        });
                                    }.bind(this),
                                    mouseOut: function (event) {
                                        this.$emit("hover", {
                                            hoverState: "out",
                                            x: event.target.x,
                                            y: event.target.y
                                        });
                                    }.bind(this)
                                }
                            },
                        }
                    },

                    series: [{
                        groupPadding: 0,
                        maxPointWidth: 15,
                        pointPadding: 0,
                        lineWidth: 0,
                        borderWidth: 0,
                        borderRadius: 2,
                        data: [],
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return this.y.toFixed(2) + " s";
                            },
                            inside: false,
                            style: {
                                color: "#4f5766",
                                fontWeight: "normal",
                                textOutline: "none",
                            },
                            crop: false,
                            overflow: "allow",
                            position: "right",
                        },
                    }]
                });
            },
        },
        vueReady() {
            this.setupChart();
        },
    });
</script>
