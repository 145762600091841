<style>
    .pa-filter-icon {
        display: inline-flex;
    }

    .pa-filter-box .pa-flyout-box-content {
        padding: 0;
    }

    .filter-label {
        padding-left: 8px;
        line-height: 18px;
    }
</style>

<template>
    <div class="pa-filter-box" :id="id">
        <p-flyout
            ref="flyout"
            direction="bottom"
            :width="216"
            :lock-scroll="lockScroll"
            :positioning="positioning"
            :popper-direction="popperDirection"
            offset="-5%p, 5px"
        >
            <button
                slot="trigger"
                :disabled="disabled"
                class="pa-filter-button"
                :class="{ 'isActive': this.model && this.model.length }"
            >
                <span class="pa-filter-icon">
                    <p-icon
                        v-if="filterIcon"
                        :icon="filterIcon"
                        size="lg"
                        block
                        :color="filterIconColor"
                    >
                    </p-icon>
                    <span v-if="filterLabel" class="filter-label">
                        {{ filterLabel }}
                    </span>
                </span>
            </button>
            <div slot="content">
                <ul v-if="options.length" class="pa-filter-list">
                    <li v-for="option in options">
                        <label class="pa-option pa-flex pa-align-center">
                            <input @click="select(option.value)"
                                type="checkbox"
                                class="pa-option-input"
                                :value="option.value"
                                v-model="model"/>
                            <span class="pa-option-icon"></span>
                            <span class="pa-option-txt pa-txt_truncate">{{ option.label }}</span>
                        </label>
                    </li>
                </ul>
                <div v-if="optgroups.length" class="pa-filter-list_groups">
                    <div v-for="group in optgroups">
                        <div class="group-header">{{ group.label }}</div>
                        <ul class="pa-filter-list">
                            <li v-for="option in group.options">
                                <label class="pa-option">
                                    <input @click="select(option.value)"
                                        type="checkbox"
                                        class="pa-option-input"
                                        :value="option.value"
                                        v-model="model"/>
                                    <span class="pa-option-icon"></span>
                                    <span class="pa-option-txt">{{ option.label }}</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </p-flyout>
    </div>
</template>

<script>
import Vue from 'vue';
import isComponentInModal from '../utils/isComponentInModal';

const Filter = Vue.extend({
    data() {
        return {
            calculatedSizing: false,
            coords: {},
            isActive: false,
            triggerIsFocused: false,
        };
    },

    props: {
        options: {
            type: Array,
            'default': () => [],
        },

        optgroups: {
            type: Array,
            'default': () => [],
        },

        model: {
            type: Array,
            'default': function() {
                return [];
            },
        },

        forceInModal: Boolean,

        id: String,

        onChangeCallback: {
            type: Function,
            'default': () => {},
        },

        disabled: {
            type: Boolean,
            'default': false,
        },

        lockScroll: {
            type: Boolean,
            default: true,
        },

        positioning: {
            type: String,
            default: 'fixed',
        },

        popperDirection: String,

        filterIcon: {
            type: String,
            default: "filter"
        },

        filterLabel: String,

        isButton: Boolean,

        filterIconColor: {
            type: String,
            default: "blue",
        },
    },

    methods: {
        select(value) {
            const match = this.modelContains(value);

            if (match) {
                const index = this.getIndexOf(value);

                this.model.splice(index, 1);
                this.informListeners();

                return;
            }

            this.model.push(value);
            this.informListeners();
        },

        informListeners() {
            this.onChangeCallback(this.model);
            if (this.hiddenInputId) {
                const input = document.getElementById(this.hiddenInputId);
                if (!input) {
                    return;
                }
                input.value = this.model;
                const changeEvent = new Event('change');
                input.dispatchEvent(changeEvent);
            }
        },

        modelContains(value) {
            return this.model.filter(item => {
                return item == value; // eslint-disable-line eqeqeq
            }).length > 0;
        },

        getIndexOf(value) {
            let index = -1;

            this.model.some((item, i) => {
                const match = item == value; // eslint-disable-line eqeqeq

                if (match) {
                    index = i;
                }

                return match;
            });

            return index;
        },
    },

    computed: {
        isEmpty: function() {
            return _.isEmpty(this.options);
        },
    },

    vueReady() {
        const inModal = this.forceInModal || isComponentInModal(this);
    },
});

export default Filter;
</script>
