<template>
  <div>
  <div v-if="loading" class="pa-loader">Loading...</div>
  <div class="status_table">
    <div v-for="status in statuses">
      <p class="pa-txt pa-txt_sm">
          <span class="pa-badge" :style="{ background: status.color }">&nbsp;&nbsp;</span>&nbsp;{{ status.name}}
      </p>
    </div>
  </div>
  <div v-if="!loading" class="status_legend pa-txt pa-txt_sm">
    <strong>Legend:</strong>
    <ul class="pa-hList pa-hList_x3" style="padding-top: 3px;">
        <li v-for="x in status_legend"><span class="pa-badge" :style="{ background: x.color }">&nbsp;&nbsp;</span> {{x.label}}</li>
      <li><span class="pa-badge" style="background: #dbdbdb;">&nbsp;&nbsp;</span> Unknown</li>
    </ul>
  </div>
  </div>
</template>

<script>
    import Vue from 'vue';
    const StatusTable = Vue.extend({

    data() {
        return {statuses: [],
                status_legend: {},
                loading: true,
               }
    },
    props: {
        id: {
            type: String,
            default: ""
        },

        monitors: {
            type: Array,
            default: function() { return []; }
        },

        autoLoad: {
            type: Boolean,
            default: true
        }

    },

    vueReady() {
        console.log("Starting status table", this.metrics);
        $.ajax({url: "/report/get_snmp_status_data",
                data: {server_resource_ids: this.monitors},
                method: "POST",
                context: this}
               )
            .done(function(data) {
                this.loading = false;
                this.statuses = [...data.statuses];
                this.status_legend = {...data.status_legend};
            });
    },

    methods: {

    },

    events: {
    }

});

export default StatusTable;
</script>

<style scoped>
    div.status_table {
        padding: 10px;
       -webkit-column-width: 150px;
       -moz-column-width: 150px;
       column-width: 150px;
       -webkit-column-gap: 3em;
       -moz-column-gap: 3em;
        column-gap: 3em;
        overflow-x: none;
    }
    div.status_legend {
        padding: 10px;
    }
</style>
