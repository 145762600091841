<style lang="scss">

    .alert-block {
        .pa-tooltip-box-content {
            width:  275px;
        }
    }
</style>

<template>
    <table class="metric-table">
        <tr class="hidden-header">
            <th :style="{
                width: isTemplate
                ? '41%'
                : isOutbound
                ? '39%'
                : '34%'
            }">
            </th>
            <th v-if="!isTemplate" style="width: 7%"></th>
            <th style="width: 6%;"></th>
            <th style="width: 38%;"></th>
            <th style="width: 10%;"></th>
            <th v-if="!isOutbound" style="width: 5%;"></th>
        </tr>
        <tr v-bind:key="m.id" v-for="m in metricSlice" class="metric-row" :class="{'highlighted': m.highlighted}">
            <!-- $parent in v-for is this component NOT $parent -->
            <td class="metric-cell">
                <label v-show="showMetricCheckboxes" class="pa-option pa-mr-12">
                    <input :value="m.id"  type="checkbox" :checked="checkedModel.includes(m.id)" @change="onChecked(m.id)" class="pa-option-input" />
                    <span class="pa-option-icon"></span>
                </label>
                <div class="metric-name-block" style="display: inline-block;">
                    <span class="metric-name" :title="m.id" v-html="m.name"></span>
                </div>
                <div style="display: inline-block; vertical-align: top;">
                    <template v-if="m.tooltips && m.tooltips.length">
                        <div v-bind:key="tooltipIndex" v-for="(tooltip, tooltipIndex) in m.tooltips" style="margin-left: 3px; display: inline-block;">
                            <p-tooltip2 
                                :hover="tooltip[0] == 'content-copy' ? false : true" 
                                variant="steel"
                                :html-class="tooltip[0] == 'question-circle-outline' ? 'snmp-description' : ''"
                            >
                                <p-icon
                                    slot="trigger"
                                    :icon="tooltip[0]"
                                    size="sm"
                                    block
                                >
                                </p-icon>
                                <div v-html="tooltip[1]"></div>
                            </p-tooltip2>
                        </div>
                    </template>
                </div>
                <p-button
                    v-if="!isTemplate"
                    @click="openGraphModal(m.id)"
                    no-base-class
                    unstyled
                    style="margin-left: auto;"
                >
                    <p-icon
                        icon="arrow-expand"
                        class="expand-metric"
                        style="margin-left: auto;"
                    >
                    </p-icon>
                </p-button
            </td>
            <td v-if="!isTemplate">
                <div v-for="entry in m.status" class="pa-pr-4 pa-pb-4">
                    <a :href="'/outage/Outage?outage_id=' + entry.outage_id" target="_blank">
                        <div
                            v-if="entry"
                            v-p-flyout-open
                            :template="'/outage/OutageFlyout?outage_id=' + entry.outage_id"
                            :trigger-data="{m:m}"
                            direction="right"
                            :width="376"
                        >
                            <!-- Double v-if seems to be needed to get the trigger to compile -->

                            <span
                                v-if="entry"
                                :class="'pa-txt_capitalize pa-m-0 pa-badge pa-badge_' + (entry.severity == 'critical' ? 'red' : 'orange')"
                                style="margin: 2px 0 0 0; line-height: normal; padding: 0px 0px; cursor: pointer;"
                            >
                                {{ entry.severity.toUpperCase() }}
                            </span>
                        </div>
                    </a>
                </div>
                <a
                    v-if="m.flyout_icon"
                    href="#"
                    @click.prevent="loadSchedule(m.flyout_icon.schedule_id, $event)"
                    style="position: relative;"
                >
                    <div
                        v-if="m.flyout_icon"
                        v-p-flyout-open
                        direction="right"
                        :template="m.flyout_icon.trigger_url"
                        :width="m.flyout_icon.width"
                    >
                        <span
                            v-if="m.flyout_icon"
                            class="pa-badge pa-badge_outage_indicator pa-badge_maintenance"
                            style="margin: 2px 0 0 0; line-height: normal; padding: 0 5px; cursor: pointer;"
                        >
                            MAINT
                        </span>
                    </div>
                </a>
            </td>
            <td>
                <p v-html="m.frequency"></p>
            </td>
            <td>
                <div v-bind:key="alertIndex" v-for="(alert, alertIndex) in m.alert_items" style="margin-bottom: 0px; position: relative;" class="alert-block">
                    <p-tooltip
                        v-if="alert[4].length"
                        :advanced="true"
                        direction="right"
                        :hover="true"
                        :hover-timeout="300"
                        :hover-on-click="$parent.$parent.$parent.showCountermeasure.bind(null, m, alert[4][0][0], alert[4][0][1][0][0])"
                    >
                        <span slot="trigger">
                            <p-icon icon="beaker" middle color="green"></p-icon>
                        </span>
                        <div class="countermeasure-timeline">
                            <div class="countermeasure">
                                <div class="dot"></div>
                                <span>Incident Detected</span>
                            </div>
                            <div class="line"></div>
                            <template v-for="(countermeasure, countermeasureIndex) in alert[4]">
                                <div v-bind:key="countermeasureIndex" class="countermeasure">
                                    <div class="dot"></div>
                                    <span class="delay">{{ countermeasure[0] / 60 }}min</span>
                                    <div class="names" :class="{ 'intermediate': countermeasureIndex < alert[4].length - 1 }">
                                        <p
                                            v-bind:key="cmIndex"
                                            v-for="(cm, cmIndex) in countermeasure[1]"
                                            class="name pa-txt_truncate pa-pb-2"
                                            :style="{ color: cm[2] === 1 ? 'red': 'rgba(0, 0, 0, 0.86)' }"
                                        >
                                            {{ cm[1] }}
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </p-tooltip>
                    <span></span>
                    <span :class="'pa-badge pa-badge_' + alert[0] + '_naked pa-badge_sm'">{{ alert[1] }}</span> <span v-html="alert[2].length ? `to ${alert[2]}` : ''"></span> when <span v-html="alert[3]"></span>
                </div>
            </td>
            <td>
                <span
                    v-bind:key="tag"
                    v-for="tag in m.tags"
                    class='pa-badge pa-badge_tag'
                    style="margin-right: 3px;"
                >
                    {{ tag }}
                </span>
            </td>
            <td v-if="!isOutbound" style="text-align: center; padding-left: 10px;">
                <p-material-menu v-if="m.actions.length > 0">
                    <button type='button' slot='trigger' class='pa-btn pa-btn_naked pa-btn_narrow'>
                        <span class='pa-icon pa-icon_dots'></span>
                    </button>
                    <template v-for="(action, actionIndex) in m.actions">
                        <button v-bind:key="actionIndex" v-if="action.button_type === 'edit'" @click="editMetric(m)">
                            <p-icon v-if="action.icon" :icon="action.icon"></p-icon>
                            {{action.text}}
                        </button>
                        <button v-bind:key="actionIndex" v-if="action.button_type === 'modal'" v-p-dialog-open :template="action.href" target="dynamic_modal">
                            <p-icon v-if="action.icon" :icon="action.icon"></p-icon>
                            {{action.text}}
                        </button>
                        <button
                            v-bind:key="actionIndex"
                            v-if="action.button_type === 'prompt' && (!action.prompt_type || action.prompt_type !== 'callback')"
                            v-p-prompt-open target="dynamic_prompt"
                            :title="sanitize(action.href[0])"
                            :body="sanitize(action.href[1])"
                            :page="sanitize(action.href[2])"
                        >
                            <p-icon v-if="action.icon" :icon="action.icon"></p-icon>
                            {{action.text}}
                        </button>
                        <button v-bind:key="actionIndex" v-if="action.button_type === 'prompt' && action.prompt_type && action.prompt_type === 'callback'"
                            v-p-prompt-open target="dynamic_prompt"
                            :title="sanitize(action.href[0])"
                            :body="sanitize(action.href[1])"
                            :callback="genCallback(action.href[2])">
                            <p-icon v-if="action.icon" :icon="action.icon"></p-icon>
                            {{action.text}}
                        </button>
                        <button v-bind:key="actionIndex" v-if="action.button_type === 'onclick'" :onclick="action.href">
                            <p-icon v-if="action.icon" :icon="action.icon"></p-icon>
                            {{action.text}}
                        </button>
                        <button v-bind:key="actionIndex" v-if="action.button_type === 'drawer'" @click="openDrawer(action.href)">
                            <p-icon v-if="action.icon" :icon="action.icon"></p-icon>
                            {{action.text}}
                        </button>
                        </button>
                        <a v-bind:key="actionIndex" v-if="!['edit', 'modal', 'prompt', 'onclick', 'drawer'].includes(action.button_type)" :href="action.href">
                            <p-icon v-if="action.icon" :icon="action.icon"></p-icon>
                            {{action.text}}
                        </a>
                    </template>
                </p-material-menu>
            </td>
        </tr>
        <tr v-if="!metrics.length">
            <td :colspan="colspan" v-if="category.addable && !category.is_snmp">
              <p class="pa-txt pa-txt_light" style="text-align: center; margin: 10px 0;">
                No metrics configured.
                <span v-show="canAddMetrics">
                  Click <button style="margin:0px 7px 0px 7px;" class="pa-btn pa-btn_secondary pa-btn_secondary_inverse  compact add-metric-empty-row" @click="$parent.$parent.$parent.openDrawer($event, category.textkey, undefined, isInbound)">Add Metric</button> to add some. Or click <a class="remove-cat-empty-row" @click="$parent.$parent.$parent.deleteCategory(category)" style="cursor: pointer;">here</a> to remove this category.
                </span>
              </p>
            </td>
            <td :colspan="colspan" v-if="category.addable && category.is_snmp && category.name !== 'SNMP'">
                <p class="pa-txt pa-txt_light" style="text-align: center; margin: 10px 0; ">
                    {{ category.errors[0] }} <br/> Click the SNMP Catalog button to check configuration or click <a class="remove-cat-empty-row" @click="$parent.$parent.$parent.deleteCategory(category)" style="cursor: pointer;">here</a> to
                    <span v-if="!isTemplate">cancel discovery and </span> remove this metric.
                </p>
            </td>
            <td :colspan="colspan" v-if="category.addable && category.is_snmp && category.name === 'SNMP'">
                <p class="pa-txt pa-txt_light" style="text-align: center; margin: 10px 0;">No metrics configured. Click the SNMP Catalog button to add some. Or click <a class="remove-cat-empty-row" @click="$parent.$parent.$parent.deleteCategory(category)" style="cursor: pointer;">here</a> to remove this category.</p>
            </td>
        </tr>
        <tr v-if="metrics.length && !filteredMetrics.length">
            <td :colspan="colspan">
                <p class="pa-txt pa-txt_light" style="text-align: center;">All metrics filtered.</p>
            </td>
        </tr>
        <tr class="pages-row" v-if="filteredMetrics.length">
            <td :colspan="colspan" style="position: relative;">
                <div style="padding: 5px 0;">
                    <ul class="pa-vList" style="justify-content: center;">
                        <li style="text-align: center;">
                            <div v-if="showLeftArrow" style="display: inline-block; position: relative; margin-left: -16px;">
                                <p-icon icon="arrow-left" @click="offsetLeft" middle></p-icon>
                            </div>
                            <div style="display: inline-block;">
                                <ul class="pa-hList pa-hList_x2" style="justify-content: center;">
                                    <li v-bind:key="page" v-for="page in numPages" @click="goToPage(page);">
                                        <span
                                            class="page-num"
                                            :class="{ 'selected': pageNum === page }"
                                            :style="{ 'line-height': pageNum === page ? '' : '19px' }"
                                        >
                                            {{ page }}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="showRightArrow" style="display: inline-block; position: relative; margin-right: -19px;">
                                <p-icon icon="arrow-right" @click="offsetRight" middle></p-icon>
                            </div>
                        </li>
                        <li style="text-align: center;">
                            <div v-if="numPages.length > 1 || showAll">
                                <a style="cursor: pointer;" @click="toggleShowAll">{{ showAllText }}</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </td>
        </tr>
    </table>
</template>

<script>
    import Vue from 'vue';

    const NUM_PER_PAGE = 10;
    const LARGE_NUM_METRICS = 50;

    const MetricList2 = Vue.extend({
        components: {
        },

        events: {
            'highlight-metric': function(categoryName, instance) {
                if (categoryName !== this.category.name) {
                    return;
                }
                this.highlightMetric(instance.id);
                if (this.showAll) {
                    Vue.nextTick(() => {
                        this.updateShowAllHeight();
                    });
                }
            },
        },

        data() {
            return {
                metrics: [],
                pageNum: 1,
                numFiltered: 0,
                showAll: false,
                showLeftArrow: false,
                showRightArrow: false,
                pageOffset: 1,
            };
        },

        computed: {
            colspan: function() {
                if (this.isTemplate) {
                    return 5;
                } else {
                    return 6;
                }
            },
            filteredMetrics: function() {
                this.numFiltered = 0;
                let metrics = this.metrics;
                if (this.searchModel.length) {
                    metrics = metrics.filter((m) => {
                        const retVal = m.name.toLowerCase().includes(this.searchModel.toLowerCase());
                        if (!retVal) {
                            this.numFiltered += 1;
                        }
                        return retVal;
                    });
                }
                this.category.numFiltered = this.numFiltered;
                return metrics;
            },
            metricSlice: function() {
                let metrics = this.filteredMetrics;
                let start = (this.pageNum - 1) * NUM_PER_PAGE;
                let end = start + NUM_PER_PAGE;
                if (this.showAll && !this.largeMetricCount) {
                    start = 0;
                    end = this.filteredMetrics.length;
                } else if (this.showAll) {
                    start = (this.pageNum - 1) * LARGE_NUM_METRICS;
                    end = start + LARGE_NUM_METRICS;
                }
                if (end > metrics.length) {
                    end = metrics.length;
                }
                return metrics.slice(start, end).map((metric) => ({
                    ...metric,
                    status: !metric.status || typeof metric.status.filter === "undefined" ?
                        [] :
                        metric.status.filter((s) => Boolean(s.text)).slice(-3)
                }));
            },
            numPages: function() {
                if (this.showAll && !this.largeMetricCount) {
                    return [1];
                } else if (this.showAll) {
                    const num = Math.ceil(this.filteredMetrics.length / LARGE_NUM_METRICS);
                    const ret = [];
                    for (let i = 1; i <= num; i++) {
                        ret.push(i);
                    }
                    return ret;
                }

                const num = Math.ceil(this.filteredMetrics.length / NUM_PER_PAGE);
                const ret = [];
                if (num > 10) {
                    const showLeftArrow = this.pageOffset > 1;
                    let showRightArrow = true;
                    let end = this.pageOffset + 9;
                    if (end > num) {
                        end = num;
                        showRightArrow = false;
                    }
                    const start = this.pageOffset;
                    for (let i = start; i <= end; i++) {
                        ret.push(i);
                    }
                    this.showLeftArrow = showLeftArrow;
                    this.showRightArrow = showRightArrow;
                } else {
                    for (let i = 1; i <= num; i++) {
                        ret.push(i);
                    }
                }
                return ret;
            },
            largeMetricCount: function() {
                return this.metrics.length >= LARGE_NUM_METRICS;
            },
            showAllText: function() {
                if (this.showAll) {
                    return 'Paginate';
                }
                if (this.largeMetricCount) {
                    return 'Show More';
                }
                return 'Show All';
            },
        },

        props: {
            category: {
                type: Object,
                default: function() {
                    return {
                        metrics: [],
                        textkey: '',
                    };
                },
            },
            checkedModel: {
                type: Array,
                default: function() {
                    return [];
                },
            },
            searchModel: {
                type: String,
                default: '',
            },
            canAddMetrics: {
                type: Boolean,
                default: false,
            },
            showMetricCheckboxes: {
                type: Boolean,
                default: false,
            },
            isTemplate: Boolean,
            isOutbound: {
                type: Boolean,
                default: false,
            },
            isInbound: {
                type: Boolean,
                default: false,
            },
        },

        watch: {
            'showAll': function(val) {
                if (val) {
                    this.updateShowAllHeight();
                    this.showLeftArrow = false;
                    this.showRightArrow = false;
                } else {
                    this.$el.parentElement.style.maxHeight = '';
                }
            },
            'category.metrics': function(val) {
                this.metrics = [...val];
            },
            'pageNum': function(val) {
                if (this.showAll) {
                    this.updateShowAllHeight();
                }
            }
        },

        methods: {
            onChecked(value) {
                this.$emit('on-checked', value);
            },
            goToPage: function(i) {
                this.pageNum = i;
            },
            offsetRight: function() {
                this.pageOffset += 10;
            },
            offsetLeft: function() {
                this.pageOffset -= 10;
            },
            sanitize: function(i) {
                return i.replace("'", "&#39");
            },
            highlightMetric: function(metricId) {
                let index = this.metrics.findIndex((m) => {
                    return m.id === metricId;
                });
                if (index > -1) {
                    const page = Math.floor(index / NUM_PER_PAGE) + 1;
                    this.goToPage(page);
                    this.metrics = this.metrics.map((metric, i) => {
                        if (index === i) { 
                            metric.highlighted = true                                                   
                            return {
                                ...metric
                            };
                        }                                         
                        return metric
                    });
                    window.setTimeout(() => {
                        this.metrics = this.metrics.map((metric, i) => {
                            if (index === i) {
                                metric.highlighted = false
                                return {
                                    ...metric
                                };
                            }

                            return metric
                        });
                    }, 3000);
                }
            },
            genCallback: function(string) {
                return eval(string);
            },
            openDrawer: function(href) {
                window.app.rootVue.$broadcast('drawer:load', {
                    id: 'dynamic-drawer',
                    url: href
                })
            },
            editMetric: function(metric) {
                this.$parent.$parent.$parent.$refs["edit-metric-drawer"].openWithMetric(metric);
            },
            deleteService: function(serviceId) {
                var metricId = null;
                var payload = {
                    redirect_url: false,
                };
                if (serviceId.startsWith("sr")) {
                    metricId = serviceId.split("-")[1];
                    payload.server_resource_id = metricId;
                } else if (serviceId.startsWith("mp")) {
                    metricId = serviceId.split("-")[1];
                    payload.monitor_point_id = metricId;
                }
                $.ajax("/config/DeleteService", {
                    method: 'GET',
                    data: payload,
                    context: this
                })
                .done(data =>{
                    if (!data.success) {
                        return;
                    }
                    let index = this.category.metrics.findIndex((c) => {
                        return c.id == metricId || -c.id == metricId;
                    });
                    if (index > -1) {
                        const deletedMetric = this.category.metrics[index];
                        this.category.metrics.splice(index, 1);
                        this.$parent.$parent.$parent.showToast(`Successfully deleted "${deletedMetric.name}"`);
                    }
                    if (data.deleted_parent) {
                        this.$parent.$parent.$parent.removeCategoryFromLocalData(this.category);
                    }
                    window.app.rootVue.$broadcast('dialog:close', 'dynamic_prompt');
                });
            },
            toggleShowAll: function() {
                this.showAll = !this.showAll;
                this.pageNum = 1;
            },
            updateShowAllHeight: function() {
                setTimeout(() => {
                    let height = this.$el.clientHeight;
                    this.$el.parentElement.style.overflow = 'hidden';
                    this.$el.parentElement.style.maxHeight = `${height}px`;
                    // Once it's done animating we want to see tooltips again
                    this.$el.parentElement.style.overflow = 'visible';
                }, 800);
            },
            openGraphModal: function(metricId) {
                const url = `/report/MetricPopup?metric_ids[]=${metricId}`;
                window.app.rootVue.$broadcast('modal:load', {
                    id: 'dynamic_modal',
                    url: url,
                    open: true,
                });
            },
            loadSchedule: function(schedule_id, event) {
                event.preventDefault();
                event.stopPropagation();
                window.app.rootVue.$broadcast('drawer:load',
                {
                    id: 'dynamic-drawer',
                    url: '/config/EditMaintenanceSchedule?maintenance_schedule_id=' + schedule_id
                })
            }
        },

        mounted() {
            this.metrics = this.category.metrics;
        },
    });

    export default MetricList2;
</script>
