<style lang="scss" scoped>
    .label-col {
        width: 17%;
        align-items: center;
    }

    .field-col {
        width: 33%;

        .pa-select {
            width: auto;
        }        
    }
    @media only screen and (max-width: 800px) {
        .row-wrapper {
            flex-wrap: wrap;
        }
        .label-col {
            width: 100%;
        }

        .field-col {
            width: 100% !important;

        }
    } 
</style>

<template>
    <div class="pa-txt_normal pa-txt_xs pa-px-20 pa-flex row-wrapper">
        <div class="pa-flex label-col">
            <span :class="{'pa-txt_bold': boldLabel}">{{ label }}</span> <span v-if="required" class="pa-pl-4">*</span>
            <p-tooltip2
                v-if="hint"
            >
                <p-icon
                    icon="alert-circle"
                    color="grey-300"
                    size="md"
                    middle
                    class="pa-mr-6 pa-pl-4"
                    slot="trigger"
                />     
                <span>{{ hint }}</span>
            </p-tooltip2>
        </div>
        <div class="pa-py-12 field-col" :style="'width: ' + cols/12*100 + '%'">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    
    props: {
    
        label: String,
        
        required: {
            type: Boolean,
            default: false,
        },

        boldLabel: {
            type: Boolean,
            default: false,
        },

        cols: {
            type: Number,
            default: 4,
        },

        hint: {
            type: String,
            default: null,
        }
    
    },
    
    events: {
        
    },
    
    watch: {
        
    },
    
    methods: {
        
    },
    
    mounted() {
       
    },
});
</script>