var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pa-flyout",class:[ _vm.positioning, { isActive: _vm.active, 'pa-flyout--rounded': _vm.rounded }],attrs:{"tabindex":"-1"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==27)return null;return _vm._onKeydown($event)}}},[_c('span',{staticClass:"pa-flyout-box",on:{"mouseleave":function($event){return _vm._onMouseLeaveFlyout()}}},[(_vm.hover)?_c('div',[_c('button',{ref:"trigger",staticClass:"pa-flyout-box-btn",attrs:{"type":"button","tabindex":"-1"},on:{"mouseenter":function($event){return _vm.onHover()},"click":_vm.hoverOnClick}},[_vm._t("trigger")],2)]):_c('div',[_c('button',{ref:"trigger",staticClass:"pa-flyout-box-btn",attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){return _vm._onClick($event)}}},[_vm._t("trigger")],2)]),_vm._v(" "),_c('div',{ref:"content",staticClass:"pa-flyout-box-content",class:[
                _vm.idirection || _vm.direction,
                !_vm.noArrow ? 'arrow-' + _vm.arrowPercent : '',
                _vm.variant ? `pa-flyout-box-content--${_vm.variant}` : '',
            ],style:({
                width: _vm.width === 'auto'
                    ? 'auto'
                    : _vm.width + 'px',
                transform: _vm.noTransform ? 'none' : ''
            })},[(_vm.loading)?_c('div',{staticClass:"pa-loader"}):_vm._e(),_vm._v(" "),(_vm.stubborn)?_c('svg',{staticClass:"pa-icon close",on:{"click":_vm.close}},[_c('use',{attrs:{"xlink:href":"#close"}})]):_vm._e(),_vm._v(" "),_c('div',{ref:"contentSlot",staticClass:"content-slot"},[_vm._t("content")],2),_vm._v(" "),(_vm.loadedAsync)?_c('div',{ref:"asyncContent"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pa-flyout-hitbox"}),_vm._v(" "),(_vm.positioning === 'popper' && !_vm.noArrow)?_c('div',{staticClass:"arrow"}):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }