<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router/composables";

const route = useRoute();

const props = defineProps({
    currentCustomer: String,
    contentCustomer: String,
    contentCustomerId: String,
});

const url = computed(() => {
    return `/report/manageCustomer?customer_id=${props.contentCustomerId}&redirect_url=${route.fullPath}`;
});
</script>

<template>
    <div class="sub-account-info pa-mb-12">
        <p-alert variant="yellow" no-shadow icon="information-outline" icon-position="left">
            <p-text class="pa-m-o" font-size="sm">
                This belongs to {{ contentCustomer }} customer. However, the settings and options displayed when you try to edit details on this page are for {{ currentCustomer }} customer. To avoid this discrepancy, <a :href="url" class="pa-hover-underline">switch to {{ contentCustomer }} customer</a>
            </p-text>
        </p-alert>
    </div>
</template>
