<template>
    <div class="template-list-item pa-grid pa-align-center pa-txt_13">
        <p-badge variant="teal" chip>
            <a v-if="template.canView" :href="`/report/InstanceDetails?server_id=${template.id}`">
                {{ template.formattedName }}
            </a>
            <template v-if="!template.canView">
                {{ template.formattedName }}
            </template>
            <p-tooltip v-if="template.isContinuous" :hover="true" :width="200">
                 Remove automatic template re-application on metadata discovery changes
                <a
                    v-if="canEditInstance"
                    v-p-dialog-open
                    target="dynamic_modal"
                    slot="trigger"
                    style="cursor: pointer;"
                    :template="`/config/DeleteContinuousCollection?server_id=${instanceId}&template_id=${template.id}`"
                >
                    <p-icon slot="trigger" icon="autorenew" middle></p-icon>
                </a>
            </p-tooltip>
            <a
                v-if="canEditInstance"
                v-p-dialog-open
                target="dynamic_modal"
                class="pa-ml-4"
                style="cursor: pointer;"
                :template="`/config/DeleteServerTemplate?server_id=${instanceId}&template_id=${template.id}&source=badge`"
            >
                <p-icon
                    icon="remove"
                    middle
                    style="width: 10px; height: 10px;"
                >
                </p-icon>
            </a>
        </p-badge>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        template: Object,
        instanceId: [String, Number],
        canEditInstance: Boolean,
    },
});
</script>
