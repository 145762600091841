<style>
    #prompt-drawer .pa-drawer-bd {
        height: calc(100vh - 151px);
        overflow-y: auto;
    }
</style>

<template>
    <p-drawer id="prompt-drawer" ref="drawer" :width="450">
        <div slot="trigger" style="display: none;"></div>

        <h2 v-show="title" class="pa-hdg pa-hdg_2">{{ title }}</h2>

        <form ref="form" slot="body" style="height: 100%; padding: 20px; box-sizing: border-box;">
            <slot name="body"></slot>
        </form>

        <div slot="footer" class="pa-drawer-ft">
            <button @click="save" type="button" ref="saveBtn" class="pa-btn pa-btn_blue" style="width: 75%; height: 48px;">Save</button>
        </div>
    </p-drawer>
</template>

<script>
    import Vue from 'vue';

    const PromptDrawer = Vue.extend({
        data() {
            return {
                promise: null,
            };
        },

        props: {
            title: String,
        },

        computed: {
        },

        methods: {
            prompt() {
                this.$refs.drawer.open();
                return new Promise((resolve, reject) => {
                    this.promise = {
                        resolve,
                        reject,
                    };
                });
            },

            save(val) {
                const result = window.helpers.jsonifyForm(this.$refs.form);
                this.promise.resolve(result);
                this.$refs.drawer.close();
            },
        },
    });

    export default PromptDrawer;
</script>
