<style>
    .greyedOut {
        color: #999999 !important;
    }

    .pa-wrap-labels {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
      
        word-break: break-word;

        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
        white-space: normal;
    }
</style>

<template>
    <div
        v-on:keydown.esc="onEscape"
        v-p-on-focusout="isActive"
        :callback="close"
        class="pa-select"
        :class="selectClass">
        <select
            v-if="multiple"
            multiple
            v-model="iModel"
            tabindex="-1"
            class="pa-select-hiddenMenu"
            v-bind:required="required"
            v-bind:id="id">
            <template v-if="mode === 'typeahead'">
                <optgroup
                    v-bind:label="optgroup.label"
                    v-for="optgroup in optgroups">
                    <option
                        v-bind:value="option.value"
                        v-for="option in optgroup.options">{{ option.label }}</option>
                </optgroup>
                <option
                    v-bind:value="option.value"
                    v-for="option in options">{{ option.label }}</option>
            </template>
            <template v-else>
                <optgroup
                    v-bind:label="optgroup.label"
                    v-for="optgroup in optgroups">
                    <option
                        v-bind:value="option.value"
                        v-for="option in optgroup.options">{{ option.label }}</option>
                </optgroup>
                <option
                    v-bind:value="option.value"
                    v-for="option in options">{{ option.label }}</option>
            </template>
        </select>
        <select
            v-if="!multiple"
            v-model="iModel"
            tabindex="-1"
            class="pa-select-hiddenMenu"
            v-bind:id="id"
            v-bind:required="required">
            <optgroup
                v-bind:label="optgroup.label"
                v-for="optgroup in filteredOptionGroups">
                <option
                    v-bind:value="option.value"
                    v-for="option in _filterOptionsByText(optgroup.options, filterText)">{{ option.label }}</option>
            </optgroup>
            <option
                v-bind:value="option.value"
                v-for="option in _filterOptionsByText(options, filterText)">{{ option.label }}</option>
        </select>
        <p-tooltip2 v-if="showtooltip">
            <span slot="trigger">
                <button
                    ref="button"
                    v-p-is-focused="triggerIsFocused"
                    class="pa-select-hd pa-select-button"
                    type="button"
                    :disabled="disabled || loading"
                    :style="computedButtonStyles"
                    :class="buttonClass"
                    v-on:click="open">
                    <slot name="lead-icon"></slot>
                    <div v-if="loading">
                        <p-loading-spinner-2
                            width="25"
                            height="25"
                            class="pa-mr-7"
                            style="vertical-align: middle;">
                        </p-loading-spinner-2>
                        <span class="pa-txt_truncate">Loading...</span>
                    </div>
                    <div v-else class="pa-txt_truncate">
                        <template v-if="includeGroupInButtonLabel && optionGroupLabel">
                            <span class="pa-txt_grey-500">{{ optionGroupLabel }}</span> - {{ getDisplayText() }}
                        </template>
                        <template v-else>
                            {{ getDisplayText() }}
                        </template>
                        <slot name="display-text"></slot>
                        <p
                            v-if="descriptions && getDisplayDescription()"
                            class="display-description"
                        >
                            {{ getDisplayDescription() }}
                        </p>
                    </div>
                </button>
            </span>
            <div>
                <template v-if="includeGroupInButtonLabel && optionGroupLabel">
                    <span class="pa-txt_grey-500">{{ optionGroupLabel }}</span> - {{ getDisplayText() }}
                </template>
                <template v-else>
                    {{ getDisplayText() }}
                </template>
            </div>
        </p-tooltip2>
        <button v-if="!showtooltip"
                ref="button"
                v-p-is-focused="triggerIsFocused"
                class="pa-select-hd pa-select-button"
                type="button"
                :disabled="disabled || loading"
                :style="computedButtonStyles"
                :class="buttonClass"
                v-on:click="open">
                <slot name="lead-icon"></slot>
                <div v-if="loading">
                    <p-loading-spinner-2
                        width="25"
                        height="25"
                        class="pa-mr-7"
                        style="vertical-align: middle;">
                    </p-loading-spinner-2>
                    <span class="pa-txt_truncate">Loading...</span>
                </div>            
                <div v-else class="pa-txt_truncate">
                    <template v-if="includeGroupInButtonLabel && optionGroupLabel">
                        <span class="pa-txt_grey-500">{{ optionGroupLabel }}</span> - {{ getDisplayText() }}
                    </template>
                    <template v-else>
                        {{ getDisplayText() }}
                    </template>
                    <slot name="display-text"></slot>
                    <p
                        v-if="descriptions && getDisplayDescription()"
                        class="display-description"
                    >
                        {{ getDisplayDescription() }}
                    </p>
                </div>
        </button>     
        <span v-if="showIcon" class="pa-select-icon"></span>
        <div
            tabindex="-1"
            ref="popup"
            :class="{
                'isActive': isActive
            }"
            class="pa-select-list"
            :style="coords">
            <div
                class="pa-select-list-box"
                v-if="searchable"
            >
                <div class="pa-select-checkbox-div" v-if="selectGroupCheckbox">
                    <input 
                        class="pa-select-checkbox"
                        type="checkbox"
                        :checked="groupCheck"
                        @change="handleGroupCheck"
                        />
                    <span class="pa-option-txt groupcheck-label">
                        {{ groupcheckLabel }}
                    </span>
                </div>
                <span v-if="searchLabel" class="pa-select-search-label pa-mb-2">
                    {{ searchLabel }}
                </span>
                <div class="pa-select-list-search-wrapper pa-select-list-search-wrapper-dash-template">
                    <p-icon 
                        :class="selectGroupCheckbox ?
                        'pa-input_phony-icon-groupcheck' : 'pa-input_phony-icon-no-groupcheck'" 
                        icon="magnify" block size="xl" color="blue"
                    >
                    </p-icon>
                    <input
                        id="searchBox"
                        type="search"
                        class="pa-input"
                        v-model="filterText"
                        placeholder="Search"
                        :autocomplete="autocompleteVal"
                    />
                </div>
            </div>
            <div class="scrollable">
                <div v-if="filterText.length == 0 && mode == 'typeahead'">
                    <div class="pa-select-list-message">
                        <p>Start typing to get started...</p>
                    </div>
                </div>
                <div v-if="groupSelected && selectedOptions.length">
                    <div class="pa-select-list-hdg">
                        Selected
                    </div>
                    <button
                        :key="option.value"
                        v-for="option in selectedOptions"
                        v-on:click="select(option.value)"
                        class="pa-select-list-item isActive"
                        type="button"
                    >
                        <p-icon
                            v-if="option.icon"
                            class="pa-mr-4"
                            :icon="option.icon"
                        />
                       {{ option.label }}
                    </button>
                </div>
                <button
                    v-if="allOption"
                    v-on:click="toggleSelectAll()"
                    class="pa-select-list-item"
                    style="font-weight:bold;"
                    type="button"
                    :class="{
                        'isActive': allOption && allSelected
                    }">
                    {{ allOption }}
                </button>
                <template 
                    v-for="optgroup in filteredOptionGroups">
                    <div class="pa-select-list-hdg" :class="optgroup.label_type">
                        {{ optgroup.label }}
                    </div>
                    <button 
                        v-for="(option, index) in _filterOptionsByText(optgroup.options, filterText)"
                        v-on:click="select(option.value)"
                        class="pa-select-list-item"
                        type="button"
                        :title="!hideButtonTitles ? option.description || option.label : '' "
                        :disabled="option.disabled || (allSelected && disableOptionsOnAllSelected)"
                        :class="{
                            'isActive': allSelected || modelContains(option.value),
                            'greyedOut': (allSelected && disableOptionsOnAllSelected)
                        }">
                        <div v-if="option.displayInitials" class="pa-select-list-item-initials pa-ml-2 pa-mr-4">
                            <span class="pa-select-list-item-initials-text">
                                {{ getInitials(option.label) }}
                            </span>
                        </div>
                        <div v-if="option.icon">
                            <p-icon :icon="option.icon">
                            </p-icon>
                        </div>
                        <div
                            :class="{
                                'pa-ml-4': option.icon || option.displayInitials,
                                'pa-wrap-labels': wrapLabels,
                                'pa-txt_truncate': !wrapLabels
                            }"
                        >
                            {{ option.label }}
                            <p class="option-description" v-if="descriptions && option.description">
                                {{ option.description || '' }}
                            </p>
                        </div>
                    </button>                    
                    <span
                        v-if="optgroup.options.length === 0 && !hideNoneLabel"
                        class="pa-select-list-none"
                        disabled>
                        None
                    </span>
                </template>
                <div v-if="selectGrouped || groupCheck">
                    <div v-for="[key, value] in 
                    Object.entries(groupSort)"
                    >
                        <button 
                            v-if="groupHeaderCheck"
                            class="pa-select-list-item pa-select-list-item-select-grouped"
                            type="button"
                            v-on:click="selectGroupHeader(key, value);"
                            :class="{
                                'isActive': allSelected || groupModelContains(key),
                                'greyedOut': (allSelected && disableOptionsOnAllSelected)
                            }"
                        >
                            <div class="pa-txt_truncate pa-hdg_bold">
                                {{ key.toUpperCase() }} ({{ value.length }})
                                <span v-if="customerSpan && key.toLowerCase()==groupSortHeader.toLowerCase()"
                                    class="customer-span">&nbsp;(currently viewing)
                                </span>
                            </div>
                        </button>
                        
                        <div v-else class="select-grouped-header-div">
                            {{ key.toUpperCase() }}
                            <span v-if="customerSpan && key.toLowerCase()==groupSortHeader.toLowerCase()"
                                class="customer-span">&nbsp;(currently viewing)</span>
                        </div>

                        <button
                            v-for="template in value.slice().reverse()"
                            v-on:click="select(template.value);"
                            class="pa-select-list-item pa-select-list-item-select-grouped pa-select-list-item-grey"
                            type="button"
                            :title="!hideButtonTitles ? template.description || template.label : '' "
                            :disabled="template.disabled || (allSelected && disableOptionsOnAllSelected)"
                            :class="{
                                'isActive': allSelected || modelContains(template.value),
                                'greyedOut': (allSelected && disableOptionsOnAllSelected)
                            }"
                        >
                            <div v-if="template.displayInitials" class="pa-select-list-item-initials pa-ml-2 pa-mr-4">
                                <span class="pa-select-list-item-initials-text">
                                    {{ getInitials(template.label) }}
                                </span>
                            </div>
                            <div v-if="template.icon">
                                <p-icon :icon="template.icon">
                                </p-icon>
                            </div>
                            <div v-if="splitDescriptions" style="display: flex;">
                                <p class="pa-txt_medium" style="flex-basis: 45%; margin-bottom: 0;">{{ template.label }}</p>
                                <p class="pa-txt_secondary" style="flex-basis: 55%; text-align: right; margin-bottom: 0;">{{ template.description }}</p>
                            </div>
                            <div
                                v-else
                                :class="{
                                    'pa-ml-4': template.icon || template.displayInitials,
                                    'pa-wrap-labels': wrapLabels,
                                    'pa-txt_truncate': !wrapLabels
                                }"
                            >
                                {{ template.label }}
                                <p-badge
                                    v-if="template.badge"
                                    :variant="template.badge_variant"
                                    class="pa-ml-12"
                                >
                                    {{ template.badge_text }}
                                </p-badge>
                                <p class="option-description" v-if="descriptions">{{ template.description || '' }}</p>
                            </div>
                        </button>
                    </div>
                </div>
                <div v-else>
                    <button
                        v-for="(option, index) in _filterOptionsByText(options, filterText)"
                        v-on:click="select(option.value)"
                        class="pa-select-list-item"
                        type="button"
                        :title="!hideButtonTitles ? option.description || option.label : '' "
                        :disabled="option.disabled || (allSelected && disableOptionsOnAllSelected)"
                        :class="{
                            'isActive': allSelected || modelContains(option.value),
                            'greyedOut': (allSelected && disableOptionsOnAllSelected)
                        }">
                        <div v-if="option.displayInitials" class="pa-select-list-item-initials pa-ml-2 pa-mr-4">
                            <span class="pa-select-list-item-initials-text">
                                {{ getInitials(option.label) }}
                            </span>
                        </div>
                        <div v-if="option.icon">
                            <p-icon :icon="option.icon">
                            </p-icon>
                        </div>
                        <div v-if="splitDescriptions" style="display: flex;">
                            <p class="pa-txt_medium" style="flex-basis: 45%; margin-bottom: 0;">{{ option.label }}</p>
                            <p class="pa-txt_secondary" style="flex-basis: 55%; text-align: right; margin-bottom: 0;">{{ option.description }}</p>
                        </div>
                        <div
                            v-else
                            :class="{
                                'pa-ml-4': option.icon || option.displayInitials,
                                'pa-wrap-labels': wrapLabels,
                                'pa-txt_truncate': !wrapLabels
                            }"
                        >
                            {{ option.label }}
                            <p-badge
                                v-if="option.badge"
                                :variant="option.badge_variant"
                                class="pa-ml-12"
                            >
                                {{ option.badge_text }}
                            </p-badge>
                            <p class="option-description" v-if="descriptions">{{ option.description || '' }}</p>
                        </div>
                    </button>
                </div>
            </div>
            <div class="pa-select-sticky-box"
                v-if="stickySelected"
                ref="stickyBox">
                <button
                    v-for="option in selectedOptions"
                    v-on:click="select(option.value)"
                    class="pa-select-list-item sticky isActive"
                    type="button"
                >
                    <p-icon
                        v-if="option.icon"
                        class="pa-mr-4"
                        :icon="option.icon"
                    />
                    {{ option.label }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import debounce from './../utils/debounce';
import _ from 'lodash';
import { stringContains } from './../utils/filterUtils';
import createScrollLocker from '../utils/scrollLocker';
import popupPositioner from '../utils/popupPositioner';
import isComponentInModal from '../utils/isComponentInModal';

const SelectMenu = Vue.extend({
    beforeDestroy() {
        this.disable();
    },

    data() {
        return {
            iModel: [],
            groupModel: [],
            calculatedSizing: false,
            coords: {},
            isActive: false,
            filterText: '',
            triggerIsFocused: false,
            selectGrouped: false,
        };
    },

    props: {
        buttonClass: [String, Object, Array],

        options: {
            type: Array,
            'default': () => [],
        },

        optgroups: {
            type: Array,
            'default': () => [],
        },

        model: {
            type: [Array, String, Number],
            'default': function() {
                if (this.multiple) {
                    return [];
                }

                return '';
            },
        },

        // allows for selection of multiple options
        multiple: Boolean,

        size: String,

        descriptions: Boolean,

        //if you want to show description on the options
        //and not in the selected button.
        hideSelectedDescription: {
            type: Boolean,
            'default': () => false,
        },

        splitDescriptions: Boolean,

        minHeight: Number,

        required: Boolean,

        searchable: Boolean,

        // renders 'select all' option element
        allOption: String,

        stickySelected: Boolean,

        forceInModal: Boolean,

        hideNoneLabel: Boolean,

        hiddenInputId: String,

        id: String,

        htmlClass: String,

        // renders checkbox for optional grouping feature
        selectGroupCheckbox: {
            type: Boolean,
            'default': false,
        },

        // renders span text if current_customer == created_by_customer
        customerSpan: {
            type: Boolean,
            'default': false,
        },

        // activates options grouping (not user-activated; bypasses selectGroupCheckbox)
        groupCheck: {
            type: Boolean,
            'default': false,
        },

        // allows for grouping header option to be selected
        // in turn selects all options within that selected grouping
        groupHeaderCheck: {
            type: Boolean,
            'default': false,
        },

        groupSortHeader: String,

        groupcheckLabel: String,

        onChangeCallback: {
            type: Function,
            'default': () => {},
        },

        defaulttext: {
            type: String,
            'default': 'Select',
        },
        showtooltip: {
            type: Boolean,
            'default': false,
        },

        labelprefix: {
            type: String,
            'default': '',
        },

        defaultemptytext: {
            type: String,
            'default': 'Select',
        },

        disabled: {
            type: Boolean,
            'default': false,
        },

        wrapLabels: {
            type: Boolean,
            'default': false,
        },

        hideButtonTitles: {
            type: Boolean,
            'default': false,
        },

        loading: Boolean,

        searchLabel: String,

        listWidth: {
            type: Number,
            default: null,
        },

        maxButtonWidth: {
            type: Number,
            default: null,
        },

        showIcon: {
            type: Boolean,
            default: true,
        },

        leftHandSideIcon: String,

        // renders visible grouping (btns) of already selected options
        groupSelected: Boolean,

        autocomplete: {
            type: Boolean,
            default: true,
        },

        onSearchChangeCallback: {
            type: Function,
            'default': () => {},
        },

        minSearchChangeLength: {
            type: Number,
            default: 1
        },

        mode: {
            type: String,
            default: 'select'
        },

        disableOptionsOnAllSelected: {
            type: Boolean,
            'default': true,
        },

        includeGroupInButtonLabel: {
            type: Boolean,
            'default': false,
        },

        searchByGroupName: {
            type: Boolean,
            'default': false,
        }

    },

    methods: {
        handleGroupCheck(event) {
            this.$emit("update:groupCheck", event.target.check);
            this.selectGrouped=!this.selectGrouped;
        },

        getInitials(string) {
            const names = string.split(" ");
            let initials = names[0].substring(0, 1).toUpperCase();

            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }

            return initials;
        },

        enable() {
            window.addEventListener('resize', this.debouncedOnResize);
        },

        disable() {
            window.removeEventListener('resize', this.debouncedOnResize);
        },

        open() { 
            if (this.isActive) {                
                this.close();

                return;
            }

            this.getPopupSizing();
            const options = {
                popupWidth: this.popupWidth,
                popupHeight: this.popupHeight,
            };
            if (this.minHeight) {
                options.minHeight = this.minHeight;
            }
            if (this.maxHeight) {
                options.maxHeight = this.maxHeight;
            }
            const coords = popupPositioner.getPopupStyle(this.$el, options);

            // TODO: Can we do this from getPopupSizing()?
            if (this.stickySelected && coords.bottom) {
                const stickyHeight = this.$refs.stickyBox.clientHeight;
                const original = Number(coords.bottom.replace('px', ''));
                coords.bottom = `${original + stickyHeight}px`;
            }

            this.isActive = true;
            this.triggerIsFocused = false;
            if (this.listWidth) {
                this.coords = Object.assign({ width: `${this.listWidth}px` }, coords);
            } else {
                if (!this.splitDescriptions) {
                    this.coords = Object.assign({ width: `${this.$el.offsetWidth}px` }, coords);
                } else {
                    this.coords = Object.assign({ width: `${this.$el.offsetWidth * 2.5}px` }, coords);
                }
            }
            let lock = false;
            if (this.scrollLocker) {
                this.scrollLocker.on();
                lock = true;
            }
            this.eventHub.$emit('scroll-lock:on', this, lock);
            Vue.nextTick(() => {
                $('#searchBox', this.$el).focus();
            });
        },

        close() {            
            if (this.isActive) {
                this.isActive = false;                
                if (this.scrollLocker) {
                    this.scrollLocker.off();                
                }
                this.eventHub.$emit('scroll-lock:off', this, true);                          
                this.triggerIsFocused = true;
            }
            
        },

        informListeners(values) {
            Vue.nextTick(() => {
                this.onChangeCallback(this.iModel, values);
                if (this.hiddenInputId) {
                    const input = document.getElementById(this.hiddenInputId);
                    if (!input) {
                        return;
                    }
                    input.value = this.iModel;
                    const changeEvent = new Event('change');
                    input.dispatchEvent(changeEvent);
                }
            });
        },
        
        // checks if group header is selected
        // passes each option to select()        
        selectGroupHeader(key, value) {
            const values = [];
            value.forEach(val => {
                values.push(val.value);
            });
            const index = this.groupModel.findIndex(obj => {
                return obj.group == key;
            });
            if (index != -1) {
                this.groupModel.splice(index, 1);
            }
            let count = 0;
            values.forEach(val => {
                if (!this.iModel.includes(val)) {
                    this.select(val);
                } else {
                    count++
                }
                if (count == values.length) {
                    values.forEach(val => {
                        this.select(val);
                    });
                }
            });
        },

        select(value) {
            const values = { prev: this.iModel, curr: value };
            if (this.multiple) {
                const match = this.modelContains(value);

                if (
                    this.allSelected &&
                    !this.disableOptionsOnAllSelected &&
                    value !== "_all_"
                ) {
                    this.iModel = [];
                    this.options.forEach(o => {
                        if (value !== o.value) {
                            this.iModel.push(o.value);
                        }
                    });
                    this.optgroups.forEach(og => {
                        og.options.forEach(o => {
                            if (value !== o.value) {
                                this.iModel.push(o.value);
                            }
                        });
                    });

                    this.$emit('update:model', this.iModel);
                    this.informListeners();

                    return;
                }

                if (match) {
                    const index = this.getIndexOf(value);
                    this.iModel.splice(index, 1);
                    if (this.groupCheck) {
                        if (this.groupModel.length) {
                            const groupIndex = this.groupModel.findIndex(obj => {
                                return obj.values.includes(value);
                            });
                            if (groupIndex != -1) {
                                this.groupModel.splice(groupIndex, 1);
                            }
                        }
                    }
                } else {
                    this.iModel.push(value);
                    if (this.groupCheck) {
                        Object.entries(this.groupOptions).forEach(entry => {
                            const [k,v] = entry;
                            if (v.includes(value)) {
                                if (v.every(el => {
                                    return this.iModel.includes(el);
                                }) 
                                && !this.groupModel.filter(obj => {
                                        obj.group === k}).length
                                    ) {
                                    this.groupModel.push({
                                        'group': k,
                                        'values': v
                                    });
                                }
                            }
                        });
                    }
                }
                this.$emit('update:model', this.iModel);
                this.informListeners();

                return;
            } else {
                this.iModel = value;
            }

            this.$emit('update:model', value);
            this.informListeners(values);
            this.close();
        },

        getDisplayText() {
            if (!!this.$slots["display-text"]) {
                return;
            }

            if (this.allOption && this.allSelected) {
                return this.allOption;
            }
            
            if(Array.isArray(this.iModel) && this.iModel.length == 0) {
                return this.returnDefaultText();
            }

            if (this.iModel !== null && typeof this.iModel !== 'undefined')
            {
                if(this.multiple && this.iModel.length == 0){
                    if (this.labelprefix){
                        return this.labelprefix + this.getModelLabels().join(', ');
                    }
                }else if (this.multiple && this.iModel.length < 4) {                    
                    if (this.labelprefix){                        
                        return this.labelprefix + this.getModelLabels().join(', ');
                    }else{
                        return this.getModelLabels().join(', ');
                    }
                    
                } else if (this.multiple && this.iModel.length > 3 && this.defaulttext) {
                    return `${this.iModel.length} selected`;
                }               
                return this.getModelLabels()[0];
            } else {
                return this.returnDefaultText();
            }
        },

        returnDefaultText() {
            if (this.isEmpty) {
                return this.defaultemptytext;
            } else {
                return this.defaulttext;
            }
        },

        getDisplayDescription() {
            if (this.hideSelectedDescription) {
                return;
            }
            
            if (this.multiple || this.optgroups.length) {
                // Currently unsupported
                return;
            } else if (this.iModel) {
                const match = this.options.find(o => {
                    return this.iModel === o.value;
                });
                if (match && match.description) {
                    return match.description;
                } else {
                    return '';
                }
            }
            return '';
        },

        getModelLabels() {  
            if (this.optgroups.length) {
                return this.getModelLabelsFromOptgroups();
            }

            return this.getModelLabelsFromOptions();
        },

        getModelLabelsFromOptgroups() {
            const labels = [];            
            this.optgroups.forEach(optgroup => {
                optgroup.options.forEach(option => {                    
                    if (this.modelContains(option.value)) {
                        if(this.includeGroupInButtonLabel && optgroup.label != option.label &&
                            option.label.startsWith(optgroup.label)) {
                                labels.push(option.label.replace(`${optgroup.label} `, ''))

                        } else {
                            labels.push(option.label);
                        }
                    }
                });
            });

            return labels;
        },

        groupModelContains(key) {
            return this.groupModel.filter(item => {
                return item.group == key;
            }).length > 0;
        },

        modelContains(value) {
            if (!this.multiple) {
                // using the loose equality checker to
                // account for numbers being compared to strings (e.g. 1 to '1')
                return value == this.iModel; // eslint-disable-line eqeqeq
            }
            if (typeof this.iModel !== 'object') { return false; }

            return this.iModel.filter(item => {
                return item == value; // eslint-disable-line eqeqeq
            }).length > 0;
        },

        optionContainsText(option = {}, text = '') {
            if (text.trim() === '') {
                return true;
            }
            return stringContains(option.label, text) || stringContains(option.description, text);
        },

        optgroupContainsText(optgroup = [], text = '') {
            if (text.trim() === '') {
                return true;
            }            

            return optgroup.options.filter(option => {
                return this.optionContainsText(option, text);
            }).length > 0;
        },

        getIndexOf(value) {
            let index = -1;

            this.iModel.some((item, i) => {
                const match = item == value; // eslint-disable-line eqeqeq

                if (match) {
                    index = i;
                }

                return match;
            });

            return index;
        },

        getModelLabelsFromOptions() {
            const labels = [];

            this.options.forEach(option => {
                const match = this.modelContains(option.value);

                if (match) {
                    labels.push(option.label);
                }
            });

            return labels;
        },

        onEscape() {
            this.close();
        },

        _filterOptgroupsByText(optgroups = [], text = '') {
            if (text.trim() === '') {
                return optgroups;
            }

            if (this.searchByGroupName) {
                return optgroups.filter(optgroup => {
                    if (stringContains(optgroup.label, text)) {
                        return optgroup.options;
                    };
                });
            } else {
                return optgroups.filter(optgroup => {
                    return this.optgroupContainsText(optgroup, text);
                });
            }
        },

        _filterOptionsByText(options = [], text = '') {
            if (text.trim() === '' && this.mode === 'typeahead') {
                return [];
            } else if (text.trim() === '' && !this.stickySelected) {
                return options;
            } else if (this.searchByGroupName) {
                return options;
            }

            if (this.stickySelected) {
                return options.filter(option => {
                    return (this.optionContainsText(option, text) &&
                            !this.modelContains(option.value));
                });
            } else {
                return options.filter(option => {
                    return this.optionContainsText(option, text);
                });
            }
        },

        toggleSelectAll() {
            if (!this.allSelected) {
                if (this.multiple) {
                    // clear out array individually to keep reactivity
                    while (this.iModel.length) {
                        this.iModel.pop();
                    }
                    this.iModel.push('_all_');
                } else {
                    this.$emit('update:model', '_all_');
                }
            } else {
                if (this.multiple) {
                    // clear out array individually to keep reactivity
                    while (this.iModel.length) {
                        this.iModel.pop();
                    }
                } else {
                    this.$emit('update:model', '');
                }
            }
            this.informListeners();
        },

        getPopupSizing() {
            if (this.calculatedSizing) {
                return;
            }

            const popupClientRect = this.$refs.popup.getBoundingClientRect();
            const popupWidth = popupClientRect.width;
            let popupHeight = popupClientRect.height;

            if (this.stickySelected) {
                const stickyHeight = this.$refs.stickyBox.clientHeight;
                popupHeight += stickyHeight;
            }

            this.popupHeight = popupHeight;
            if (!this.splitDescriptions) {
                this.popupWidth = popupWidth - this.$el.offsetWidth;
            } else {
                this.popupWidth = popupWidth;
            }
            this.calculatedSizing = true;
        },

        onResize(event) {
            this.close();
        },
    },

    computed: {
        filteredOptionGroups() {
            return this._filterOptgroupsByText(this.optgroups, this.filterText);
        },
        groupOptions() {
            const optionsGrouped = {};
            this.options.forEach(option => {
                if (!optionsGrouped[option.grouping]) {
                    optionsGrouped[option.grouping] = [option.value]
                } else {
                    optionsGrouped[option.grouping].push(option.value)
                }
            })
            return optionsGrouped;
        },
        computedButtonStyles() {
            const styles = {};

            if (this.maxButtonWidth) {
                styles.maxWidth = `${this.maxButtonWidth}px`
            }

            return styles;
        },

        optionGroupLabel() {
            let label = null
            this.optgroups.forEach(optgroup => {
                optgroup.options.forEach(option => {
                    if (this.modelContains(option.value)) {
                        if(this.includeGroupInButtonLabel && optgroup.label != option.label) {
                            label = optgroup.label;
                        }
                    }
                });
            });
            return label;
        },

        groupSort() {
            const group_array = this._filterOptionsByText(this.options, this.filterText)
            const grouped = {}
            const group_array_copy = group_array.slice().sort((a, b) => (a.grouping>b.grouping) ? 1:-1);
            group_array_copy.forEach(obj => {
                if(!grouped[obj.grouping]) {
                    grouped[obj.grouping] = [obj];
                } else {
                    grouped[obj.grouping].push(obj);
                }
            });
            return grouped
        },

        isEmpty: function() {
            return _.isEmpty(this.options) && _.isEmpty(this.optgroups);
        },
        allSelected: function() {
            return (this.multiple && this.iModel[0] === '_all_')
                || (!this.multiple && this.iModel === '_all_');
        },
        selectedOptions: function() {
            const selected = [];
            this.options.forEach(o => {
                if (this.modelContains(o.value)) {
                    selected.push(o);
                }
            });
            this.optgroups.forEach(og => {
                og.options.forEach(o => {
                    if (this.modelContains(o.value)) {
                        selected.push(o);
                    }
                });
            });
            return selected.sort((a,b) => {
                const label = String(a.label);
                return label.localeCompare(String(b.label));
            });
        },
        selectClass: function() {
            const retVal = [];
            if (this.isActive) {
                retVal.push('isActive');
            }
            retVal.push(`pa-select_${this.size}`);
            if (this.descriptions) {
                retVal.push('descriptions');
            }
            if (this.htmlClass) {
                retVal.push(this.htmlClass);
            }
            return retVal;
        },
        autocompleteVal: function() {
            return this.autocomplete ? 'on' : 'off';
        }
    },

    watch: {
        model(val) {
            this.iModel = val;
        },
        onSearchChangeCallback(val) {
            this.debouncedOnSearchChangeCallback = _.debounce(val, 500);
        },
        filterText(curr) {
            if (this.mode == 'typeahead' && curr.length >= this.minSearchChangeLength) {
                if (typeof this.debouncedOnSearchChangeCallback === 'function') {
                    this.debouncedOnSearchChangeCallback(curr);
                } else {
                    this.onSearchChangeCallback(curr);
                }
            }
        }
    },

    vueReady() {
        this.iModel = this.model;        
        const inModal = this.forceInModal || isComponentInModal(this);
        this.scrollLocker = createScrollLocker({ inModal: inModal });
        this.debouncedOnResize = debounce(this.onResize, 400, true);
        this.debouncedOnSearchChangeCallback = _.debounce(this.onSearchChangeCallback, 500);

        this.enable();
    },
});

export default SelectMenu;
</script>
