<style lang="scss">
.pa-navigation-action__menu-flyout-title {
    display: none;
}
.pa-navigation-action__menu-flyout-divider {
    display: none;
}
</style>

<template>
    <ul class="pa-navigation-action__menu-items" :style="computedStyle">
        <li class="pa-navigation-action__menu-flyout-title">
            {{ title }}
        </li>

        <li class="pa-navigation-action__menu-flyout-divider">
            <p-divider></p-divider>
        </li>

        <p-navigation-action-menu-item
            v-for="item in items"
            :key="item.id"
            :id="item.id"
            :action="item.action"
            :to="item.to"
            :left-icons="item.leftIcons"
            :right-icons="item.rightIcons"
            :text="item.text"
            :href="item.href"
            :badge="item.badge"
            :exact-path="item.exactPath"
            :active="item.active"
            :secondary-active="item.secondaryActive"
            :button="item.button"
            @item:active="handleItemActive"
    >
        </p-navigation-action-menu-item>
    </ul>
</template>

<script setup>
import { ref, computed, getCurrentInstance, onMounted } from "vue";
import PNavigationActionMenuItem from "./NavigationActionMenuItem.vue";

const props = defineProps({
  items: Array,
  title: String,
  isCollapsed: Boolean,
});

const ready = ref(false);

const emit = defineEmits(["item:active"]);

const handleItemActive = (data) => {
    emit('item:active', data);
};

const computedStyle = computed(() => {
    if (!ready.value || !props.isCollapsed) {
        return undefined;
    }

    const instance = getCurrentInstance();
    const parentEl = instance.proxy.$parent.$el;
    const bounds = parentEl ? parentEl.getBoundingClientRect() : null;
    const triangleWidth = 8;
    const tx = `calc(var(--side-nav-collapsed-width) + ${triangleWidth}px)`;
    const ty = bounds ? bounds.top : 0;
    const tz = 0;
    const transform = `translate3d(${tx}, ${ty}px, ${tz}px)`;

    return {
        transform,
    };
});

onMounted(() => {
    // Give some time for layout shifts
    setTimeout(() => {
        ready.value = true;
    }, 500);
});
</script>
