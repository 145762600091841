<script setup>
import { computed } from "vue";
import { LOGICAL_OPERATORS } from "../constants/logicalOperators";

const emit = defineEmits(["change"]);

const props = defineProps({
    operators: {
        type: Array,
        default: () => LOGICAL_OPERATORS,
        validator: (propValue) => {
            return propValue.every((operator) => {
                return LOGICAL_OPERATORS.includes(operator);
            });
        },
    },
    labelFormatter: {
        type: Function,
        default: (value) => {
            return value.toUpperCase();
        },
    },
    size: {
        type: String,
        default: "xxxxxs",
    },
    operator: {
        type: String,
        value: null,
    },
});

const operator = computed({
    get() {
        return props.operator;
    },
    set(value) {
        emit('update:operator', value);
    },
});

const operatorOptions = computed(() => {
    return props.operators.map((operator) => {
        return {
            label: props.labelFormatter(operator),
            value: operator,
        };
    });
});
</script>

<template>
    <p-select
        :model.sync="operator"
        :options="operatorOptions"
        :size="size"
    >
    </p-select> 
</template>