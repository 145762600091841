<template>
    <div
        v-if="output"
        class="countermeasure-output-container"
    >
        <pre
            class="countermeasure-output pa-pt-48"
            v-html="output"
            :style="computedStyle"
            :id="uniqueId"
        >
        </pre>
        <button
            v-if="!hideExpand && !isOpen"
            class="expand-btn"
            @click="isOpen = !isOpen"
        >
            {{ expandText }}
        </button>
        <p-button
            :data-clipboard-target="`#${uniqueId}`"
            ref="copyBtn"
            variant="secondary"
            class="clipboard-btn pa-ml-auto pa-mr-48"
        >
            Copy data
        </p-button>
    </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import Clipboard from "clipboard";

let CLIPBOARD_COUNT = 0;

export default Vue.extend({
    props: {
        output: String,
        hideExpand: Boolean,
        debounceWait: {
            type: Number,
            default: 300,
        },
        expandText: {
            type: String,
            default: "+ Expand",
        },
        startOpen: Boolean,
    },
    data() {
        return {
            uniqueId: `pa-cm-output_id-${CLIPBOARD_COUNT}`,
            isOpen: this.startOpen,
            isVisible: false,
            debouncedScroll: null,
            clipboard: null,
        };
    },
    computed: {
        computedStyle() {
            const style = {
                maxHeight: "unset",
                willChange: "transform",
            };
            return style;
        },
    },
    methods: {
        toggleOpen() {
            this.isOpen = !this.isOpen;
        },
        handleScroll() {
            this.isVisible = this.checkIfVisible();
        },
        checkIfVisible() {
            if (!this.$el) {
                return false;
            }

            const bounding = this.$el.getBoundingClientRect();
            const bottom = bounding.bottom - this.debounceOffset;
            const isVisible = (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            );

            return isVisible;
        },
        initClipboard() {
            this.clipboard = new Clipboard(this.$refs.copyBtn.$el);

            this.clipboard.on("success", function(e) {
                e.trigger.innerText = "Copied!";

                setTimeout(() => {
                    e.trigger.innerText = "Copy data";
                }, 5000);
            });
        },
    },
    mounted() {
        this.debouncedScroll = _.debounce(this.handleScroll, this.debounceWait);
        window.addEventListener("scroll", this.debouncedScroll);
        this.handleScroll();
        this.initClipboard();
    },
    beforeCreate() {
        this.uniqueId = `pa-cm-output_id-${CLIPBOARD_COUNT}`;
        CLIPBOARD_COUNT += 1;
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.debouncedScroll);
        this.clipboard.destroy();
    },
});
</script>
