<style lang="scss">

    .delete-schedule-menu {
        .pa-icon {
            width: 0.7058823529rem;
            height: 0.7058823529rem;
        }
    }

    .edit-rotating-schedule-ellipses {
        float: right;
    }

</style>


<template>
    <div>
        <div class="edit-rotating-schedule-ellipses" :style="{marginTop: description ? '-21px' : '-29px'}">
            <p-material-menu v-if="canEdit || canDelete">
                <button 
                    v-p-drawer-open
                    v-if="canEdit"
                    target="dynamic-drawer"
                    :template='`/users/on_call/EditRotatingScheduleName?channel_id=${id}`'
                >
                <p-icon icon="pencil"></p-icon> Edit Details
                </button>
                <button v-p-prompt-open
                    v-if="canDelete"
                    class="delete-schedule-menu"
                    target="dynamic_prompt"
                    title="Confirm Delete"
                    body="Are you sure you want to delete this schedule?"
                    :href="`/users/on_call/deleteRotatingContact?rotating_contact_id=${id}`">
                    <p-icon size="xsm" icon="remove"></p-icon> Delete Schedule
                </button>
            </p-material-menu>
        </div>
        <p class="pa-txt pa-txt_pg-subhead">{{ description }}</p>
    </div>
</template>

<script>

    import Vue from 'vue';

    export default Vue.extend({

        props: {
            iName: String,
            id: String,
            iDescription: String,
            canEdit: Boolean,
            canDelete: Boolean,
        },

        events: {
            'calender-settings-updated'(name, description) {
                window.app.rootVue.$broadcast('drawer:close', 'dynamic-drawer');

                window.app.toastManager.clearToasts();
                window.app.toastManager.showToast({
                    icon: "check",
                    text: "On-Call Schedule saved successfully"
                });

                this.name = name;
                this.description = description;
            },
        },

        data() {
            return {
                description: '',
            };
        },

        created() {
            this.name = this.iName;
            this.description = this.iDescription;
        },

    });
</script>