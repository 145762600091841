<style>
    .dashboard-properties-footer {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        box-sizing: border-box;
        background: #f2f3f5;
        box-shadow: 0 -2px 0 0 rgba(132, 142, 153, 0.2);
        z-index: 10;
    }

    #dashboard-properties-drawer .pa-expando-hd {
        border: none;
        background: transparent;
        padding: 0;
    }

    #dashboard-properties-drawer .pa-expando-hd-wrapper {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e1e4e6;
        margin: 2px 24px 0;
        padding: 0.625rem 0 0.625rem 0;
    }

    #dashboard-properties-drawer .pa-expando-hd-wrapper .pa-expando-hd-icon {
        right: 1.625rem;
    }

    #dashboard-properties-drawer .pa-field-bd .pa-input_taggle {
        width: 100%;
    }

    #dashboard-properties-drawer .pa-alert {
        max-width: 456px;
    }

    #dashboard-properties-drawer .dashboard-name {
        padding: 1.1764705882rem;
        padding-bottom: 0;
    }

    .slide-enter {
        /* 495 = drawer width minus scrollbar */
        left: calc(100% - 495px);
    }

    #dashboard-properties-drawer .pa-btn_copy {
        margin: 0 1rem 0 0;
        display: block;
        float: left;
    }

    .dashboard-properties-drawer-body {
        height: 100%;
        overflow-y: auto;
    }

    .dashboard-properties-drawer .pa-code {
        display: block;
        padding-left: 1rem;
    }

    .locked {
        height: 100%;
        overflow-y: hidden;        
    }
    
    
</style>

<template>
    <p-drawer id="dashboard-properties-drawer" 
            ref="drawer" 
            side="right" 
            :width="500" 
            :scroll-lock="true" 
            sticky-footer
            :body-offset="145">
        <div slot="trigger" style="display: none;"></div>
        <div slot="head">
            {{ formData.title }}
        </div>
        <div slot="body" class="dashboard-properties-drawer-body">

            <div class="dashboard-name">
                <p-field-group name="dashboard_name"
                               label="Dashboard Name"
                               :error="errors.error__dashboard_name">
                    <p-input v-model="formData.name"></p-input>
                </p-field-group>
            </div>

            <p-section v-if="hasTemplateDashboardOptions"
                       id="template_dashboard"
                       title="Template">
                <p-field-group name="template_dashboard"
                               :error="errors.error__template_dashboard">
                    <p-select :options="templateDashboardOptionsFilter"
                              :model.sync="formData.templateDashboard"
                              :searchable="true"
                              :select-group-checkbox="groupSortCheckbox"
                              v-model:group-check="groupedCheckbox"
                              :group-sort-header="current_customer"
                              groupcheck-label="Group by customer"
                              class="pa-select_stretch"></p-select>
                </p-field-group>
            </p-section>

            <p-section v-show="formData.hasBrandAccess"
                       id="brands"
                       title="Multi-tenancy">
                <p-field-group name="brands"
                               :error="errors.error__brands"
                               hint="Enabling this allows the dashboard to access all the instances and metrics across your sub customer accounts. You can't edit this option after saving.">
                    <p-select v-if="formData.hasBrands"
                              :options="formData.brandOptions"
                              :multiple="true"
                              :model.sync="formData.brand"
                              :disabled="dashboardId ? true : false"></p-select>
                    <label v-else
                           class="pa-option">
                        <input v-model="formData.subCustomer"
                               :disabled="dashboardId"
                               type="checkbox"
                               class="pa-option-input"/>
                        <span class="pa-option-icon"></span>
                        <span class="pa-option-txt">
                              Allow dashboard to access sub customer data
                        </span>
                    </label>
                </p-field-group>
            </p-section>

            <p-section id="access_level"
                       title="Access Level">
                <p-field-group name="access_level"
                               :error="errors.error__access_level">
                    <p-select :options="formData.accessLevelOptions"
                              :model.sync="formData.accessLevel"></p-select>
                </p-field-group>

                <p-field-group name="public"
                               :error="errors.error__public"
                               hint="Allows users who are not logged in to view this dashboard via a special URL.">
                    <label class="pa-option">
                        <input v-model="formData.public"
                               type="checkbox"
                               class="pa-option-input"/>
                        <span class="pa-option-icon"></span>
                        <span class="pa-option-txt">
                              Public
                        </span>
                    </label>
                </p-field-group>

                <p-field-group v-show="formData.public">
                    <button class="pa-btn pa-btn_blue-secondary pa-btn_copy"
                        data-clipboard-target="#public-url">Copy</button>
                    <pre id="public-url" class="pa-code"><code>{{ formData.publicURL }}</code></pre>
                </p-field-group>

            </p-section>

            <p-section v-if="formData.accessLevel == 'limited_by_tag'"
                       id="tags"
                       title="Tags"
                       class="pa-mb-56">
                <p-field-group name="tags"
                               :error="errors.error__tags">
                    <p-tags-input name="tags[]"
                                  :model.sync="formData.tags"
                                  :autocomplete="true"></p-tags-input>
                </p-field-group>
            </p-section>

            <div class="dashboard-name">
                <p-field-group
                label="Timezone"
                name="timezone"
                :error="errors.error__timezone"
                hint="Set the timezone that will be used for widgets that depend on a specific time range">
                <p-select
                  :model.sync="formData.timezone"
                  :optgroups="formData.timezoneOptions"
                  :searchable="true"
                >
                </p-select>
            </p-field-group>
            </div>

            <p-section id="dashboard_scope"
                       title="Dashboard Scope">
                <p-field-group name="scoping_enabled"
                               hint="Dashboard-level scoping allows the ability to scope and control the way you filter your entire dashboard. Widgets can still be filtered further within their individual settings.">
                    <p-switch label="Enable Dashboard-level Scoping"
                              label-position="right"
                              :model="scopingEnabled"
                              :on-change="scopingToggled"></p-switch>
                </p-field-group>

                <template v-if="scopingEnabled">
                    <div class="pa-vList">
                        <label class="pa-option" style="display: block;">
                            <input name="dashboard_scope_type"
                                   v-model="formData.scopeType"
                                   value="tags"
                                   type="radio"
                                   class="pa-option-input" />
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt">
                                Scope dashboard by tags
                            </span>
                        </label>

                        <div v-if="formData.scopeType === 'tags'">
                            <p-field-group name="tags"
                               :error="errors.error__tags">
                                <p-tags-input name="scopeTags"
                                            :model.sync="formData.scopeTags"
                                            :autocomplete="true"
                                            ></p-tags-input>
                                <p class="pa-txt" style="font-size: 12px; color: #7f899c; margin: 4px 0 17px 0;">Press enter or tab after each tag.</p>
                            </p-field-group>
                        </div>

                        <label class="pa-option" style="display: block;">
                            <input name="dashboard_scope_type"
                                   v-model="formData.scopeType"
                                   value="attributes"
                                   type="radio"
                                   class="pa-option-input" />
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt">
                                Scope dashboard by key attributes
                            </span>
                        </label>

                        <p-field-group name="key_attribute_values"
                                       label="Key Attribute Value"
                                       style="margin-top: 5px;"
                                       v-if="formData.scopeType === 'attributes'"
                                       :error="errors.error__attributes">
                            <div class="pa-vList">
                                <template v-for="(keyAttribute, index) in formData.scopeAttributes">
                                    <div class="pa-flex" :key="index" style="align-items: center; justify-content: space-between;">
                                         <div style="flex-basis: 95%; justify-content: space-between;">
                                            <div style="width: 100%;">
                                                <div class="pa-flex" style="flex-basis: 100%; justify-content: space-between;">
                                                    <p-select :optgroups="attributeTypeOptions"
                                                              style="width: unset; flex-basis: 58%;"
                                                              :searchable="true"
                                                              :model.sync="keyAttribute.textkey"
                                                              :on-change-callback="resetAttr.bind(null, keyAttribute)">
                                                    </p-select>
                                                    <p-select :options='[{"value": "values", "label": "List Selection"}, {"value": "pattern", "label": "Free-form"}]'
                                                              style="width: unset; flex-basis: 40%;"
                                                              :searchable="true"
                                                              :model.sync="keyAttribute.type">
                                                    </p-select>
                                                </div>
                                            </div>
                                            <div style="width: 100%; margin-top: 8px;">
                                                <p-select v-if="keyAttribute.type === 'values'"
                                                          :options="attributeValueOptions[keyAttribute.textkey]"
                                                          style="width: 100%;"
                                                          :multiple="true"
                                                          :searchable="true"
                                                          :model.sync="keyAttribute.values">
                                                </p-select>
                                                <input v-else
                                                       v-model="keyAttribute.pattern"
                                                       type="text"
                                                       class="pa-input" />
                                            </div>
                                        </div>
                                        <div @click="formData.scopeAttributes.splice(index, 1)"
                                             class="pa-clickable"
                                             style="flex-basis: 4%; align-self: start;">
                                            <p-icon icon="x"
                                                    color="blue"
                                                    size="xxxxl">
                                            </p-icon>
                                        </div>
                                    </div>
                                    <hr :key="index" style="border: solid 1px #d5d8df; border-bottom: none; margin: 16px 0;" />
                                </template>
                                <div class="pa-clickable"
                                     style="color: #3954bf; font-size: 14px;"
                                     @click="formData.scopeAttributes.push({'textkey': '', 'type': 'values', 'values': [], 'pattern': null})">
                                    <p-icon icon="plus-circle-outline" color="blue"></p-icon>
                                    <span>Add new Value</span>
                                </div>
                            </div>
                        </p-field-group>
                    </div>
                </template>
            </p-section>

            <div class="pa-px-20 pa-pt-12">
                <h4 class="pa-txt_medium">Teammate Display</h4>
                <p-divider></p-divider>
                <p-hint>
                    When representing teammates - Ack'd, Incident Lead, etc. - you can utilize either their initials or their gravatar
                </p-hint>
                <div class="pa-vList">
                    <label class="pa-option" style="display: block;">
                        <input name="teammate_display"
                                v-model="formData.teammateDisplay"
                                value="user_initials"
                                type="radio"
                                class="pa-option-input" />
                        <span class="pa-option-icon pa-option-icon_radio"></span>
                        <span class="pa-option-txt">
                            User Initials
                        </span>
                    </label>

                    <label class="pa-option" style="display: block;">
                        <input name="teammate_display"
                                v-model="formData.teammateDisplay"
                                value="gravatar"
                                type="radio"
                                class="pa-option-input" />
                        <span class="pa-option-icon pa-option-icon_radio"></span>
                        <span class="pa-option-txt">
                            Gravatars
                        </span>
                    </label>
                </div>
            </div>


            <div v-if="isOpen" class="dashboard-properties-footer">
                <div class="dashboard-properties-footer-wrapper pa-grid pa-justify-end pa-py-12">
                    <button @click="close" type="button" class="pa-btn pa-btn_blue-secondary pa-mr-12">
                        Cancel
                    </button>
                    <button
                            @click="handleSubmit"
                            :disabled="loading"
                            type="button"
                            class="pa-btn pa-btn_blue pa-mr-24"
                            :style="{ opacity: loading ? 0.6 : 1 }"
                    >
                        {{ loading ? "Saving..." : "Save" }}
                    </button>
                </div>
            </div>


        </div>
    </p-drawer>
</template>

<script>
    import Vue from 'vue';
    import Clipboard from 'clipboard';

    const DashboardPropertiesDrawer = Vue.extend({
        events: {
            'dashboard-properties-drawer:open'(id=null) {
                this.dashboardId = id;
                this.open();
            },

            'drawer-close'() {
                this.isOpen = false;
            },

            'scroll-lock:on': function(comp, flag) {
                const body = document.querySelector('.dashboard-properties-drawer-body');
                
                if(body && flag) {
                    body.classList.add('locked');
                }  
            },
            
            'scroll-lock:off': function(comp, flag) {
                const body = document.querySelector('.dashboard-properties-drawer-body');
                
                if(body && flag) {
                    body.classList.remove('locked');
                }
            },
        },

        data() {
            return {
                dashboardId: null,
                isOpen: false,
                errors: {},
                loading: false,
                current_customer: null,
                formData: {
                    title: '',
                    name: '',
                    templateDashboardOptions: [],
                    templateDashboard: null,
                    subCustomer: false,
                    hasBrandAccess: false,
                    hasBrands: false,
                    brandOptions: [],
                    brand: [],
                    accessLevelOptions: [],
                    accessLevel: null,
                    public: false,
                    publicURL: '',
                    tags: [],
                    formSubmitText: '',
                    timezone: '',
                    timezoneOptions: [],
                    // Scoping
                    scopeType: '',
                    scopeAttributes: [],
                    teammateDisplay: 'gravatar',
                },   
                scopingEnabled: false,
                attributeTypeOptions: [],
                attributeValueOptions: {},
                groupedCheckbox: false,
            };
        },

        computed: {
            hasTemplateDashboardOptions() {
                if (this.formData.templateDashboardOptions) {
                    return this.formData.templateDashboardOptions.length;
                }
                return false;
            },

            templateDashboardOptionsFilter() {
                if (!this.groupedCheckbox) {
                    return this.formData.templateDashboardOptions.sort((a,b) =>
                        a.label.localeCompare(b.label)
                    )
                } else {
                    return this.formData.templateDashboardOptions.sort((a,b) => 
                        a.grouping.localeCompare(b.grouping)
                    )
                }
            },

            // if more than 1 unique created_by_customer for dash templates,
            // checkbox is rendered for customer grouping
            groupSortCheckbox() {
                const customer_list = []
                this.formData.templateDashboardOptions.forEach(obj => {
                if(!customer_list.includes(obj.grouping)) {
                    customer_list.push(obj.grouping);
                };
            });
                if(customer_list.length > 1) {
                    return true
                } else {
                    return false
                };
            }
        },

        watch: {
            'formData.scopeType'(val, oldVal) {
                if (val === 'tags') {
                    this.formData.scopeAttributes = [];
                    this.scopingEnabled = true;
                } else if (val === 'attributes') {
                    this.scopingEnabled = true;
                } else {
                    this.scopingEnabled = false;
                }
            },
            'formData.scopeAttributes': {
                deep: true,
                handler(val, oldVal) {
                    this.fetchScopeAttributeValues();
                }
            },
        },

        props: {
            pageData: Object,
        },

        methods: {
            load() {
                const payload = {};
                if (this.dashboardId) {
                    payload.dashboardId = this.dashboardId;
                }

                $.ajax({
                    url: '/dashboardv2/EditDashboardJSON',
                    method: 'GET',
                    context: this,
                    data: payload,
                }).done(response => {
                    if (response.success) {
                        this.formData = Object.assign({}, response.formData)
                        if (response.attributeTypeOptions) {
                            this.attributeTypeOptions = response.attributeTypeOptions;
                        }
                        this.current_customer = response.current_customer;
                    } else {
                        this.errors = response.errors
                    }
                }).fail(error => {
                    console.log(error);
                    this.errors['error'] = "There was a problem getting your account data. " +
                        "Please contact FortiMonitor Support if this occurs again."
                });
            },

            open() {
                this.$refs.drawer.open();
                this.isOpen = true;
                this.load();
            },

            close() {
                this.$refs.drawer.close();
                this.isOpen = false;
            },

            save() {
                this.formData.dashboardId = this.dashboardId;
                const formData = _.cloneDeep(this.formData);
                formData.scopeAttributes = JSON.stringify(formData.scopeAttributes);
                formData.scopeTags = JSON.stringify(formData.scopeTags);

                $.ajax({
                    url: '/dashboardv2/editDashboardJSON',
                    type: 'post',
                    data: formData,
                }).done(response => {
                    if (response.success) {
                        this.close();
                        window.location.href = response.redirect;
                    } else {
                        this.errors = response.errors
                    }
                }).fail(error => {
                    console.log(error);
                    this.errors['error'] = "There was a problem getting your account data." +
                        "Please contact Panopta Support if this occurs again."
                });
            },

            handleSubmit() {
                this.errors = {};

                if (this.formData.name.length < 1) {
                    this.errors["error__dashboard_name"] = ['You must enter a dashboard name.'];
                }

                if (this.formData.tags.length < 1 && this.formData.accessLevel === 'limited_by_tag') {
                    this.errors["error__tags"] = ['You must select at least one tag.'];
                }

                if (this.scopingEnabled) {
                    
                    if(this.formData.scopeType === 'tags' && this.formData.scopeTags.length === 0){
                        this.errors["error__tags"] = ['You must select at least one tag.'];
                    }
                    else if(this.formData.scopeType === 'attributes' && this.formData.scopeAttributes.length === 0){                        
                        this.errors["error__attributes"] = ['You must select at least one attribute.'];                        
                    }
                }

                if (_.isEmpty(this.errors)) {
                    this.save();
                }
            },

            scopingToggled(payload) {
                const enabled = payload.value;
                if (!enabled) {
                    this.formData.scopeType = '';
                    this.scopingEnabled = false;
                } else {
                    this.scopingEnabled = true;
                    this.formData.scopeType = 'attributes';
                }
            },

            resetAttr(attr) {
                if (attr.type === 'values') {
                    attr.values = [];
                }
            },

            fetchScopeAttributeValues() {
                this.formData.scopeAttributes.forEach(attr => {
                    if (attr.textkey && !(attr.textkey in this.attributeValueOptions)) {
                        $.ajax({
                            url: '/dashboardv2/fetchAttributeValues',
                            method: 'GET',
                            context: this,
                            data: {
                                attribute_textkey: attr.textkey,
                            },
                        }).done(response => {
                            console.log()
                            if (response.success) {
                                this.attributeValueOptions = {
                                    ...this.attributeValueOptions,
                                    [attr.textkey]: [...response.values],
                                };
                            } else {
                                console.log(response);
                            }
                        }).fail(error => {
                            console.log(error);
                        });
                    }
                    if (attr.type === 'pattern' && attr.values && attr.values.length) {
                        attr.values = [];
                    } else if (attr.type === 'values' && attr.pattern) {
                        attr.pattern = '';
                    }
                });
            },
        },

        created() {
            this.formData.scopeAttributes.forEach(attr => {
                this.attributeValueOptions[attr.textkey] = [];
            });
        },

        vueReady() {
            new Clipboard('.pa-btn_copy');
        },

    });

    export default DashboardPropertiesDrawer;
</script>
