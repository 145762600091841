<style lang="scss">
    .hover {
        background-color: var(--p-informational);
    }

</style>

<template>
    <p-flex style="min-height: 400px;">
        <p-card as="aside" class="sidebar pa-pt-12" :class="`${id}-sidebar`" :style="`width: ${width}%;`">
            <slot name="above-nav"></slot>
            <nav>
                <ul class="pa-txt_sm">
                    <li
                        class="pa-py-8"
                        v-for="option in options"
                        :class="computedClass(option)"
                        @click.prevent="setCurrentOption(option)"
                        @mouseleave="removeHover(option)"
                        @mouseenter="setHover(option)"
                    >
                        <div class="pa-mx-8">
                            <p-row>
                                <p-column :cols="10">
                                    <span>{{ option.label }}</span>
                                </p-column>
                                <p-column :cols="2" v-if="hoveredOption == option.value">
                                    <p-menu>
                                        <p-link slot="trigger" :add-pointer="true">
                                            <p-icon
                                                icon="dots-vertical"
                                                color="blue"
                                                size="xl"
                                                middle
                                                class="pa-mr-2"
                                            />
                                        </p-link>
                                        <a v-for="item in menuItems" @click.stop="eventHub.$emit('side-bar:menu', item, option)">
                                            {{ item }}
                                        </a>
                                    </p-menu>
                                </p-column>
                            </p-row>
                        </div>
                    </li>
                </ul>
            </nav>
            <slot name="under-nav"></slot>
        </p-card>
        <p-card :style="`width: ${rhsWidth}%; height: 100%;`">
            <slot name="rhs-content"></slot>
        </p-card>
    </p-flex>
</template>

<script>
    import Vue from 'vue';

    const SideBar = Vue.extend({

        props: {
            options: Array,

            defaultOption: {
                type: String,
                default: null,
            },

            menuItems: {
                type: Array,
                default: null,
            },

            id: {
                type: String,
                default: null,
            },

            width: {
                type: String,
                default: '15',
            },

        },

        data() {
            return {
                currentOption: null,
                hoveredOption: null,        
            };
        },

        methods: {

            setHover: function(option){
                this.hoveredOption = option.value;
            },

            removeHover: function(option){
                this.hoveredOption = null;
            },

            computedClass: function(option) {
                let classNames = [];

                if (this.currentOption == option.value) {
                    classNames.push("active");
                }

                if (this.hoveredOption == option.value) {
                    classNames.push("hover");
                }

                return classNames.join(" ");
            },

            setCurrentOption(option) {
                this.currentOption = option.value
            }
        },


        computed: {
            rhsWidth: function() {
                return 100 - parseInt(this.width);
            },
        },

        watch: {
            currentOption: function(oldVal, newVal) {
                this.eventHub.$emit("side-bar:updated", this.currentOption, this.id);
            },
        },

        vueReady() {
            if(this.defaultOption !== null) {
                this.currentOption = this.defaultOption;
            } else {
                this.currentOption = this.options[0].value;
            }
        },
    });

    export default SideBar;
</script>
