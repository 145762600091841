<style>
 #metric_tags {
     width: 100%;
 }
</style>

<template>
    <div class="module">
        <div ref="body" v-show="open" transition="module-expand" class="module-body">
            <div>
                <div class="pa-field pa-field_vertical">
                    <div class="pa-field-bd" :class="{ 'pa-pb-6 module-border': filterType === 'none' }">
                        <p-switch
                            label="Filter Ports"
                            label-position="right"
                            :model.sync="showFilter"
                            class="pa-pb-10"
                            variant="blue-500"
                            :on-change="toggleShowFilter"
                        >
                        </p-switch>
                        <span v-if="showFilter">
                            <label class="pa-option pa-mr-16 pa-pb-10">
                                <input class="pa-option-input" type="radio" value="tags" name="filterType" v-model="filterType">
                                <span class="pa-option-icon pa-option-icon_radio"></span>
                                <span class="pa-option-txt pa-txt-grey-700">By Tag</span>
                            </label>
                            <label class="pa-option pa-mr-16 pa-pb-10">
                                <input class="pa-option-input" type="radio" value="name" name="filterType" v-model="filterType">
                                <span class="pa-option-icon pa-option-icon_radio"></span>
                                <span class="pa-option-txt pa-txt-grey-700">By Port Name</span>
                            </label>
                        </span>
                    </div>
                </div>
                <div class="pa-field pa-field_vertical module-border pa-pt-16" v-if="filterByTags">
                    <div class="pa-field-hd">
                        <label for="template" class="pa-label">Match Tags by</label>
                    </div>
                    <div class="pa-field-bd" style="text-align: left;">
                        <label class="pa-option" style="padding-bottom: 10px; padding-right: 10px;">
                            <input v-model="tagsType" value="any" type="radio" class="pa-option-input"/>
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt">
                                Match Any
                            </span>
                        </label>
                        <label class="pa-option" style="padding-bottom: 10px">
                            <input v-model="tagsType" value="all" type="radio" class="pa-option-input" />
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt">
                                Match All
                            </span>
                        </label>
                        <p-tags-input
                            id="metric_tags"
                            ref="tags_input"
                            name="tags[]"
                            :model.sync="tags"
                            :autocomplete="true"
                            :suggestions="allTags"
                            placeholder="Enter tags"
                        >
                        </p-tags-input>
                    </div>
                    <div class="pa-field-ft pa-mt-0 pa-pb-24">
                        <p class="pa-hint">Hit enter or tab after each tag</p>
                    </div>
                </div>
                <div class="pa-field pa-field_vertical module-border pa-pb-8" v-if="filterByName">
                    <div class="pa-field-bd" style="text-align: left;">
                        <div style="margin-top: 5px;">
                            <input
                                v-model="portName"
                                debounce="1000"
                                type="text"
                                class="pa-input"
                                placeholder="Enter string"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';

    const InstanceModule = Vue.extend({
        events: {
        },

        data() {
            return {
                ready: false,
                app_textkeys: [],
                tagsType: 'any',
                tags: [],
                portName: '',
                showFilter: false,
                filterType: 'none',
            };
        },

        props: {
            open: {
                type: Boolean,
                default: true,
            },
            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        },

        computed: {
            filterByTags: function() {
                return this.filterType === 'tags';
            },
            filterByName: function() {
                return this.filterType === 'name';
            },
            allTags: function() {
                if (window.dashboard && window.dashboard.resellerDashboard) {
                    return [];
                } else if (this.$root.customerConfig) {
                    return this.$root.customerConfig.allTags;
                } else {
                    return [];
                }
            },
        },

        events: {
        },

        methods: {
            debouncedPortNameChange: _.debounce(function() {
                if (!this.ready) { return; }
                this.sendConfig();
            }, 500),
            toggleShowFilter() {
                this.showFilter = !this.showFilter;
            },
            toggle() {
                this.open = !this.open;
            },
            sendConfig() {
                if (!this.ready) { return; }

                this.$parent.updatePending(this.editingWidget.id);

                const payload = {
                    widget_id: this.editingWidget.id,
                    metrics: [],
                    tags_match: this.tagsType,
                    tags: this.tags,
                    filter_tags: this.filterByTags,
                    filter_metric_name: this.filterByName,
                    metric_name: this.portName,
                };
                $.ajax({
                    url: '/dashboardv2/setWidgetMetrics',
                    method: 'POST',
                    data: payload,
                })
                    .done(data => {
                        if (data.success && data.newConfig) {
                            this.editingWidget.config = data.newConfig;
                        }
                    });
                this.editingWidget.pendNewConfig();
            },
        },

        watch: {
            tags: function(value, prev) {
                if (!this.ready) { return; }
                this.sendConfig();
            },
            tagsType: function(value) {
                if (!this.ready) { return; }
                this.sendConfig();
            },
            portName: function(value) {
                this.debouncedPortNameChange();
            },
            filterType: function(value) {
                if (!this.ready) { return; }
                // Changing filter type
                // Clear out the models
                this.ready = false;
                this.tags = [];
                this.portName = '';
                if (value !== 'none') {
                    this.showFilter = true;
                }
                Vue.nextTick(() => {
                    this.ready = true;
                    this.sendConfig();
                });
            },
            showFilter: function(value) {
                if (!this.ready || value) { return; }
                this.ready = false;
                this.tags = [];
                this.portName = '';
                this.filterType = 'none';
                Vue.nextTick(() => {
                    this.ready = true;
                    this.sendConfig();
                });
            },
        },

        created() {
            const w = this.editingWidget;
            if (typeof w.config.custom_controls !== 'undefined') {
                if (w.config.custom_controls.metrics_filter_tags) {
                    this.showFilter = true;
                    this.filterType = 'tags';
                    this.tagsType = w.config.custom_controls.metrics_tag_match;
                    this.tags = w.config.custom_controls.metrics_tags;
                } else if (w.config.custom_controls.filter_metric_name) {
                    this.showFilter = true;
                    this.filterType = 'name';
                    this.portName = w.config.custom_controls.metric_name;
                }
            }
            Vue.nextTick(() => {
                this.ready = true;
            });
        },
    });

    export default InstanceModule;
</script>
