<style lang="scss">
.kubernetes-container-count-widget {
    .metric-widget {
        flex-grow: 1;
        align-self: center;
        overflow: hidden;
    }
    .fitty {
        display: inline-block;
        white-space: nowrap;
        line-height: 1;
        margin: 0;
    }
}
</style>

<template>
    <p-widget-shell
        :id="id"
        :editing="editing"
        :config="localConfig"
        :content="content"
        :loading="isLoading"
        :configuration-modules="getConfigModules()"
        :option-modules="getOptionModules()"
        :valid="valid"
        :error-message="error_message"
        class="kubernetes-container-count-widget"
        :background-color="backgroundColor"
    >
        <div
            v-if="valid"
            class="widget-body pa-mb-10 pa-flex"
            style="overflow-y: hidden; height: 100%; background-color: inherit;"
            ref="body"
            slot="body"
        >
            <div class="metric-widget">
                <div class="block">
                    <span ref="container_count" class="fitty pa-txt_medium" @click="openObjectListDrawer">
                        {{ count }}
                    </span>
                </div>
            </div>
        </div>
        <template slot="unconfigured-message">
            Please configure your widget.
        </template>
    </p-widget-shell>
</template>


<script>
    import Vue from 'vue';
    import fitty from "fitty";
    import widgetMixin from './../widgetMixin';
    import WidgetShell from './WidgetShell.vue'

    const CONTAINER_COUNTER_TYPE_SELECTS = {
        "ContainerState": {
            label: 'Container State',
            key: 'container_states',
            type: 'p-select',
            multiple: true,
            visible: false,
            options: [
                {
                    value: 'waiting',
                    label: 'Waiting',
                },
                {
                    value: 'running',
                    label: 'Running',
                },
                {
                    value: 'terminated',
                    label: 'Terminated',
                },
            ],
        },
        "ContainerWaitReason": {
            label: 'Container Wait Reason',
            key: 'container_wait_reasons',
            type: 'p-select',
            multiple: true,
            visible: false,
            options: [
                {
                    value: 'ContainerCreating',
                    label: 'Container Creating',
                },
                {
                    value: 'CrashLoopBackOff',
                    label: 'Crash Loop Back Off',
                },
                {
                    value: 'ErrImagePull',
                    label: 'Image Pull Error',
                },
                {
                    value: 'ImagePullBackOff',
                    label: 'Image Pull Back Off',
                },
                {
                    value: 'CreateContainerConfigError',
                    label: 'Create Container Config Error',
                },
                {
                    value: 'InvalidImageName',
                    label: 'Invalid Image Name',
                },
                {
                    value: 'CreateContainerError',
                    label: 'Create Container Error',
                },
            ],
        },
        "ContainerTerminationReason": {
            label: 'Container Termination Reason',
            key: 'container_termination_reasons',
            type: 'p-select',
            multiple: true,
            visible: false,
            options: [
                {
                    value: 'OOMKilled',
                    label: 'OOM Killed',
                },
                {
                    value: 'Error',
                    label: 'Error',
                },
                {
                    value: 'Completed',
                    label: 'Completed',
                },
                {
                    value: 'ContainerCannotRun',
                    label: 'Container Cannot Run',
                },
                {
                    value: 'Deadline',
                    label: 'Deadline',
                },
                {
                    value: 'Exceeded',
                    label: 'Exceeded',
                },
            ],
        },
    };

    export default Vue.extend({
        mixins: [widgetMixin],

        components: {
            'p-widget-shell': WidgetShell,
        },

        data() {
            return {};
        },

        events: {
            // Mixin: 'widget:new_data' -> this.initialize(config, content)
            // Mixin: 'widget:updated_data' -> this.update(content)
            // Mixin: 'widget:pend_config' -> this.pendNewConfig()
        },

        watch: {
            number() {
                Vue.nextTick(() => {
                    this.initFitty();
                });
            },
        },

        props: {
            editing: {
                type: Boolean,
                'default': false,
            },
            config: {
                type: Object,
                default() {
                    return {
                        custom_controls: {},
                    };
                },
            },
        },

        computed: {
            count: function() {
                return this.content.count || 0;
            },
        },

        methods: {
            openObjectListDrawer() {
                window.app.rootVue.$broadcast('drawer:load', {
                    id: 'dynamic-drawer',
                    url: '/report/KubernetesObjectListDrawer?widget_id=' + this.id + '&kind=container',
                });
            },
            initFitty() {
                if (!this.$el || !this.$refs.container_count) {
                    return;
                }

                if (this.localConfig.height === 1) {
                    this.fitty = null;
                    this.fitty = fitty(this.$refs.container_count, {
                        maxSize: 12
                    });
                } else {
                    const metricWidget = this.$refs.body;
                    let maxSize = Math.min(Math.min(metricWidget.clientHeight, metricWidget.clientWidth), 512);

                    this.fitty = null;
                    this.fitty = fitty(this.$refs.container_count, { maxSize });
                }

                Vue.nextTick(() => {
                    this.fitText();
                });
            },

            fitText() {
                // Need to force reset the font size so it calculates
                // the size better when we call .fit()
                const resetFontSize = "font-size: 16px;";

                if (this.$el) {
                    if (this.fitty) {
                        this.fitty.element.style = resetFontSize;
                        this.fitty.fit();
                    }
                }
            },

            getConfigModules() {
                return [
                    { type: 'p-overview-module' },
                    { type: 'p-instance-module', options: { defaultType: 'none' } },
                    
                ];
            },

            getOptionModules() {
                const { container_counter_type } = this.localConfig.custom_controls;
                let subOptions = [];

                Object.keys(CONTAINER_COUNTER_TYPE_SELECTS).forEach((container_counter_type) => {
                    let visible = false;

                    if (container_counter_type === this.localConfig.custom_controls.container_counter_type) {
                        visible = true;
                    }

                    subOptions.push({
                        ...CONTAINER_COUNTER_TYPE_SELECTS[container_counter_type],
                        visible
                    });
                });

                const options = [
                    {
                        type: 'p-custom-module',
                        options: {
                            ...(this.$parent.isScoping() ? {"ignore-scope-module": {}} : {}),
                            title: '',
                            controls: [
                                {
                                    label: 'Counter Type',
                                    key: 'container_counter_type',
                                    type: 'p-select',
                                    options: [
                                        {
                                            value: '',
                                            label: 'Select'
                                        },
                                        {
                                            value: 'ContainerState',
                                            label: 'Container State',
                                        },
                                        {
                                            value: 'ContainerWaitReason',
                                            label: 'Container Wait Reason',
                                        },
                                        {
                                            value: 'ContainerTerminationReason',
                                            label: 'Container Termination Reason',
                                        },
                                    ],
                                },
                                {
                                    id: 'backgroundColor',
                                    label: 'Background Color',
                                    key: 'backgroundColor',
                                    type: 'color-picker',
                                    default: '#ffffff'
                                },
                                ...subOptions,
                            ]
                        },
                    }
                ];

                return options;
            },

            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this);
            },

            initialize(config, content) {
                this.$refs.body.style.filter = '';
                this.content = content;
                this.localConfig = {...config};
                this.reconfig();
            },

            toggleControls() {
                Object.keys(CONTAINER_COUNTER_TYPE_SELECTS).forEach((container_counter_type) => {
                    const { key } = CONTAINER_COUNTER_TYPE_SELECTS[container_counter_type];

                    if (
                        container_counter_type &&
                        (container_counter_type === this.localConfig.custom_controls.container_counter_type)
                    ) {
                        this.$parent.$broadcast('customControl:showControl', key);
                    } else {
                        this.$parent.$broadcast('customControl:hideControl', key);
                    }
                });
            },

            reconfig() {
                Vue.nextTick(() => {
                    this.initFitty();
                });
            },

            update(content) {
                this.content = content;
                Vue.nextTick(() => {
                    this.initFitty();
                });
            },

            dumpState() {
                const obj = Object();
                obj.data = this.$data;
                obj.config = {...this.localConfig};
                console.log(JSON.stringify(obj));
            },
        },
        beforeDestroy() {
            this.fitty.unsubscribe();
            this.fitty = null;
        },
        mounted() {
            Vue.nextTick(() => {
                this.initFitty();
            });
            this.pendNewConfig();
            // Mixin: dispatch('widget:domready')
            if (this.id < 0) { this.configure(); };
        },
    });
</script>
