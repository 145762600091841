const hopscotchMixin = {
    props: {
        unseenFeatures: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    methods: {
        getUnseenFeature: function(htmlId) {
            const index = this.unseenFeatures.findIndex(f => {
                return f.htmlId === htmlId;
            });
            let feature = null;
            if (index > -1) {
                feature = this.unseenFeatures.pop(index);
            }
            return feature;
        },
        markFeatureSeen: function(htmlId) {
            const payload = {
                html_id: htmlId,
            };
            $.ajax({
                url: '/userconfig/markFeatureSeen',
                method: 'POST',
                data: payload,
            })
            .done(data => {
                if (!data.success && data.message) {
                    console.log(data.message);
                }
            });
        },
        showFeatureObj: function(featureObj) {
            const options = {};
            for (const key of ['placement', 'xOffset', 'yOffset', 'width']) {
                if (featureObj[key]) {
                    options[key] = featureObj[key];
                }
            }
            this.showFeature(featureObj.htmlId, featureObj.title, featureObj.content, options);
        },
        showFeature: function(htmlId, title, content, options = {}) {
            if (!window.hopscotch) {
                console.log.debug('Hopscotch not defined');
                return;
            }

            const defaults = {
                placement: 'top',
                xOffset: 0,
                yOffset: 0,
                width: 280,
            };
            Object.assign(defaults, options);

            setTimeout(() => {
                const calloutMgr = window.hopscotch.getCalloutManager();
                calloutMgr.removeAllCallouts();
                calloutMgr.createCallout({
                    id: 'feature-highlight',
                    target: htmlId,
                    placement: options.placement,
                    title: title,
                    content: content,
                    xOffset: options.xOffset,
                    yOffset: options.yOffset,
                    width: options.width,
                });
            }, 1000);

            this.markFeatureSeen(htmlId);
        },
    },

    events: {
        'hopscotch:show-feature': function(featureObj) {
            this.showFeatureObj(featureObj);
        },
    },
};

module.exports = hopscotchMixin;
