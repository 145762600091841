const WAIT_TIME = 500;
const SHOW_TIME = 200;

import Flyout from '../components/Flyout.vue';

const flyoutOpen = (() => {
    // params: ['template', 'width', 'direction', 'waitTime', 'hoverTimeout'],
    const state = new WeakMap();

    let applyFlyout = function(el, binding, vnode) {
        const self = {};
        state.set(el, self);

        self.el = el;
        self.params = vnode.data.attrs;

        if (!self.params.template) {
            return;
        }

        const width = self.params.width || null;
        const direction = self.params.direction || null;
        const waitTime = self.params.waitTime || WAIT_TIME;
        const hoverTimeout = self.params.hoverTimeout || SHOW_TIME;

        self.el.setAttribute('slot', 'trigger');

        const trigger = self.el.cloneNode(true);
        const content = document.createElement('span');
        content.setAttribute('slot', 'content');

        const flyoutEl = document.createElement('p-flyout');
        let parent = null;
        if (window.app && window.app.rootVue) {
            parent = window.app.rootVue;
        }

        const propsData = {
            hover: true,
            waitTime,
            hoverTimeout,
            url: self.params.template,
        };

        if (width) {
            propsData.width = width;
        }

        if (direction) {
            propsData.direction = direction;
        }

        const flyout = new Flyout({
            el: flyoutEl,
            name: 'p-flyout',
            parent: parent,
            propsData,
        });

        flyout.$nextTick(() => {
            flyout.$els.trigger.appendChild(trigger);
            if(self.el.parentNode) {
                self.el.parentNode.replaceChild(flyout.$el, self.el);
            }
        });
    }

    return {
        bind(el, binding, vnode) {
            applyFlyout(el, binding, vnode);
        },

        update(el, binding, vnode) {
            applyFlyout(el, binding, vnode);
        },

        unbind() {
        },
    };
})();

export default flyoutOpen;
