<template>
    <div
        v-on:focusout="_onFocusout($event)">
        <slot></slot>
    </div>
</template>

<script>
    import Vue from 'vue';

    const Accordion = Vue.extend({
        mounted() {
            let hasActiveChild = false;

            this.$children.forEach((expando, i) => {
                expando.isActive = hasActiveChild ? false : expando.isActive;
                expando.isFocusable = expando.isActive;

                if (!hasActiveChild && expando.isActive) {
                    this.focusIndex = i;
                    hasActiveChild = true;
                }
            });
        },

        data() {
            return {
                focusIndex: 0
            };
        },

        props: {
            singleOpen: {
                type: Boolean,
                'default': true,
            },
        },

        events: {
            ['expando:opened'](data) {
                const id = data.id;

                this._openExpandoById(id);
            }
        },

        methods: {
            _onFocusout($event) {
                const $relatedTarget = $event.relatedTarget;
                const accordionContainsRelatedTarget = this.$el.contains($relatedTarget);

                if (accordionContainsRelatedTarget) {
                    return;
                }

                const activeIndex = this._getIndexOfActiveExpando();

                this.$children.forEach((expando, i) => {
                    expando.isFocusable = false;
                });

                this.focusIndex = activeIndex === -1 ? 0 : activeIndex;
                this.$children[this.focusIndex].isFocusable = true;
            },

            _onKeydown($event) {
                $event.preventDefault();

                if ($event.key === 'ArrowDown') {
                    this.focusIndex++;
                } else {
                    this.focusIndex--;
                }

                this.focusIndex = this._validateFocusIndex(this.focusIndex);
                this._focusExpandoAt(this.focusIndex);
            },

            _focusExpandoAt(index) {
                this.$children.forEach((expando, i) => {
                    expando.isFocusable = index === i ? true : false;

                    if (expando.isFocusable) {
                        expando.$refs.trigger.focus();
                    }
                });
            },

            _getIndexOfActiveExpando() {
                let index = -1;

                this.$children.some((expando, i) => {
                    if (expando.isActive) {
                        index = i;

                        return true;
                    }

                    return false;
                });

                return index;
            },

            _openExpandoById(id) {
                this.$children.forEach((expando, i) => {
                    if (expando.id === id) {
                        expando.isFocusable = true;
                        this.focusIndex = i;

                        return;
                    } else if (this.singleOpen) {
                        // Close others
                        expando.close(false);
                        expando.isFocusable = false;
                    }
                });
            },

            _validateFocusIndex(focusIndex) {
                if (focusIndex < 0) {
                    return this.$children.length - 1;
                } else if (focusIndex >= this.$children.length) {
                    return 0;
                }

                return focusIndex;
            }
        }
    });

    export default Accordion;
</script>
