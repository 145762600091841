<template>
    <li>
        <span role="button" class="taggle_grey">
            {{ attribute.serverAttributeType.name }}: {{ attribute.value }}
        </span>
        <p-tooltip v-if="attribute.serverTemplate" :hover="true" :hover-timeout="300">
            <p-icon slot="trigger" icon="content-copy" size="xsm" block></p-icon>
            <a
                v-if="attribute.serverTemplate.canView"
                :href="`../report/InstanceDetails?server_id=${attribute.serverTemplate.id}`"
                target="_blank"
            >
                {{ attribute.serverTemplate.name }}
            </a>
            <span v-else>{{ attribute.serverTemplate.name }}</span>
        </p-tooltip>
        <p-menu v-if="canEditInstance" theme="naked" icon="dots-veritical">
            <button
                v-p-dialog-open
                :template="`../config/EditServerAttribute?server_attribute_id=${attribute.id}`"
                target="dynamic_modal"
            >
                Edit
            </button>
            <a
                :href="`../config/deleteServerAttribute?server_attribute_id=${attribute.id}`"
                onclick="return confirm('Do you really want to delete this attribute?')"
            >
                Delete
            </a>
        </p-menu>
    </li>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        attribute: Object,
        canEditInstance: Boolean,
    },
});
</script>
