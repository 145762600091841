<style lang="scss" scoped>
    .vertical-border {
        border-right: 1px solid var(--p-grey-200);
        height: 100%;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    span {
        font-size: 16px;
    }
</style>

<template>
    <div 
        class="pa-grid pa-align-center pa-badge pa-badge_grey-100"
        style="padding-right: 0px; padding-left: 6px;"
        @click="deleteCallback(chip)"
    >
        <span>
            {{ chip.label }}
        </span>

       
        <div class="vertical-border pa-pl-4"> 
            <p-tooltip2>
                <p-icon
                    icon="information-outline"
                    slot="trigger"
                    class="pa-ml-16 pa-pr-6"
                    size="sm"
                >
                </p-icon>
                <span v-html="chip.tooltip"></span>
            </p-tooltip2>
        </div>
        <p-button
            v-if="!noRemove"
            unstyled
            no-base-class
        >
            <p-icon icon="close" size="xxxl" middle></p-icon>
        </p-button>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        chip: Object,
        noRemove: Boolean,
        deleteCallback: Function,
    },


});
</script>