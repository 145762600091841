<style>
    #edit-port-tags-drawer .pa-drawer-hd h4 {
        color: #69717d;
    }
    #edit-port-tags-table {
        margin-bottom: 100px;
    }
    #edit-port-tags-table tr {
        border-bottom: 1px solid #e6e6e6;
        height: 46px;
    }
    #edit-port-tags-table tr td {
        vertical-align: middle;
        padding: 0 10px;
        min-height: 45px;
    }
    #edit-port-tags-table .select-all-header-cell {
        width: 20px;
    }
    #edit-port-tags-table .tags-cell {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-items: center;
    }
    #edit-port-tags-table .tags-cell .pa-badge_tag {
        cursor: initial;
        display: flex !important;
        margin: 4px 0 4px 8px;
    }
    .edit-port-tags-footer {
        bottom: 0;
        left: 100%;
        position: absolute;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
    .edit-port-tags-footer-wrapper {
        box-shadow: 0 -2px 0 0 rgba(132, 142, 153, 0.2);
        background: #f2f3f5;
        position: fixed;
        width: 550px;
        z-index: 2;
        bottom: 0;
        right: 0;
    }
    .slide-enter {
        /* 545 = drawer width minus scrollbar */
        left: calc(100% - 545px);
    }
</style>

<template>
    <p-drawer ref="drawer" side="right" id="edit-port-tags-drawer" :width="550">
        <div slot="trigger" style="display: none;">
        </div>
        <div slot="head">
            <h3 class="pa-txt_medium">Port Tags</h3>
            <h4 class="pa-txt_sm">{{ instanceName }}</h4>
        </div>
        <div slot="body">
            <p-loading-spinner v-if="isLoading"></p-loading-spinner>
            <p-row v-else>
                <p-column cols="12">
                    <div class="pa-p-12">
                        <p-row>
                            <p-column cols="12">
                                <div class="pa-input pa-input_phony">
                                    <input
                                        placeholder="Search Ports and Tags"
                                        v-model="searchTerm"
                                    />
                                    <div class="pa-input_phony-icon">
                                        <p-icon icon="magnify" size="xl" block>
                                        </p-icon>
                                    </div>
                                </div>
                            </p-column>
                        </p-row>
                        <p-row class="pa-my-8">
                            <p-column cols="12">
                                <div class="pa-flex">
                                    <p-tags-input
                                        ref="new-tags-taggle"
                                        :model.sync="newTags"
                                        :autocomplete="true"
                                        :suggestions="allTags"
                                    >
                                    </p-tags-input>
                                    <p-tooltip2
                                        :prevent-overflow="true"
                                        placement="bottom"
                                        variant="steel"
                                        v-if="selectedPorts.length === 0"
                                        style="width: 25%;"
                                    >
                                        <p-button
                                            slot="trigger"
                                            :disabled="true"
                                            variant="blue-secondary"
                                            class="pa-ml-8"
                                        >
                                            Apply Tags
                                        </p-button>   
                                        Select at least one port to apply the tag(s) to
                                    </p-tooltip2>
                                    <p-button
                                        v-else
                                        variant="blue-secondary"
                                        class="pa-ml-8"
                                        style="width: 25%;"
                                        @click="addTags"
                                    >
                                        Apply Tags
                                    </p-button>
                                </div>
                            </p-column>
                        </p-row>
                    </div>
                    <table id="edit-port-tags-table">
                        <thead>
                            <tr>
                                <td class="select-all-header-cell">
                                    <label class="pa-option">
                                        <input
                                            type="checkbox"
                                            class="pa-option-input"
                                            v-model="allPortsSelected"
                                            @click="selectAllPorts"
                                        />
                                        <span class="pa-option-icon"></span>
                                    </label>
                                </td>
                                <td class="pa-txt_medium">
                                    Port
                                </td>
                                <td class="pa-txt_medium pa-txt_right">
                                    Tags
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="filteredPorts.length === 0 && searchTerm !== ''">
                                <td colspan="3" class="pa-txt_centered">
                                    No matching ports
                                </td>
                            </tr>
                            <tr :key="portTag[0]" v-for="portTag in filteredPorts">
                                <td>
                                    <label class="pa-option">
                                        <input
                                            type="checkbox"
                                            class="pa-option-input"
                                            v-model="selectedPorts"
                                            :value="portTag[0]"
                                        />
                                        <span class="pa-option-icon"></span>
                                    </label>
                                </td>
                                <td class="pa-txt_sm">
                                    {{ portTag[0] }}
                                </td>
                                <td class="tags-cell pa-txt_right">
                                    <p-badge
                                        :key="tag.id"
                                        v-for="tag in portTags[portTag[0]]"
                                        variant="tag"
                                        class="tag-no-listener"
                                    >
                                        {{ tag.name }}
                                        <p-button
                                            unstyled
                                            @click="removeTagFromPort(portTag[0], tag.name)"
                                        >
                                            <p-icon icon="close"></p-icon>
                                        </p-button>
                                    </p-badge>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="edit-port-tags-footer" transition="slide">
                        <div class="edit-port-tags-footer-wrapper pa-py-12" justify-end>
                            <p-row justify-end>
                                <p-button
                                    @click="close"
                                    variant="white"
                                    class="pa-mr-12"
                                >
                                    Cancel
                                </p-button>
                                <span>
                                    <p-button
                                        :disabled="hasSaveDisabled"
                                        @click="handleSubmit"
                                        variant="blue"
                                        class="pa-mr-24"
                                    >
                                        {{ isSaving ? "Saving..." : "Save" }}
                                    </p-button>
                                </span>
                            </p-row>
                            <p-row justify-end>
                                <span 
                                    class="pa-txt_xs pa-pt-8 pa-pr-12"
                                    v-if="newTags.length"
                                >
                                    Remove or apply new tags before saving
                                </span>
                            </p-row>
                        </div>
                    </div>
                </p-column>
            </p-row>
        </div>
    </p-drawer>
</template>

<script>
    import Vue from 'vue';

    const DRAWER_ID = 'edit-port-tags-drawer';
    const LOCKED_BODY_CLASS = 'server-report-locked';

    export default Vue.extend({
        props: {
            instanceId: Number,
            instanceName: String,
            allTags: Array,
        },

        data: function() {
            return {
                isSaving: false,
                isLoading: true,
                portTags: null,
                searchTerm: "",
                newTags: [],
                allPortsSelected: false,
                selectedPorts: [],
            };
        },

        events: {
            [DRAWER_ID + ':open']: function() {
                this.$refs.drawer.open();
            },
            [DRAWER_ID + ':close']: function() {
                this.$refs.drawer.close();
            },
            'drawer-open': function(drawerId) {
                if (drawerId !== DRAWER_ID) {
                    return;
                }

                if (document.body.scrollHeight > window.innerHeight) {
                    document.documentElement.classList.add(LOCKED_BODY_CLASS);
                }

                this.getPortTags();
            },
            'drawer-close': function(drawerId) {
                if (drawerId !== DRAWER_ID) {
                    return;
                }

                if (document.documentElement.classList.contains(LOCKED_BODY_CLASS)) {
                    document.documentElement.classList.remove(LOCKED_BODY_CLASS);
                }

                this.resetDrawer();
            },
        },

        computed: {
            ports: function() {
                if (!this.portTags) {
                    return [];
                }

                return Object.keys(this.portTags);
            },
            filteredPorts: function() {
                if (!this.portTags) {
                    return [];
                }

                const searchTerm = this.searchTerm.trim().toLowerCase();

                return _.toPairs(this.portTags).filter(
                    ([port, tags]) =>
                        port.toLowerCase().includes(searchTerm) ||
                        tags.some(tag =>
                            tag.name.toLowerCase().includes(searchTerm)
                        )
                );
            },
            hasSaveDisabled: function() {
                if(this.saving || this.newTags.length) {
                    return true;
                }

                return false;
            }
        },

        methods: {
            close: function() {
                this.$refs.drawer.close();
            },
            handleSubmit: function() {
                this.isSaving = true;

                $.ajax({
                    url: "/config/editNetworkPortTags",
                    method: "POST",
                    context: this,
                    data: {
                        server_id: this.instanceId,
                        port_tags: JSON.stringify(this.portTags),
                    },
                }).done((response) => {
                    if (response.success) {
                        window.app.toastManager.showToast('Port tags successfully updated');
                        window.location.reload();
                    } else {
                        this.isSaving = false;
                    }
                });
            },
            addTags: function() {
                const newTags = this.newTags.map(tag => tag.trim());

                for (const tag of newTags) {
                    for (const port of this.selectedPorts) {
                        const hasTag = this.portTags[port].includes(tag);

                        if (!hasTag) {
                            this.portTags[port].push({ name: tag });
                        }
                    }
                }

                this.clearTagsInput();
            },
            clearTagsInput: function() {
                if (this.$refs && this.$refs['new-tags-taggle']) {
                    this.$refs['new-tags-taggle'].deleteAllTags();
                }
                this.newTags = [];
            },
            removeTagFromPort: function(port, tag) {
                this.portTags = {
                    ...this.portTags,
                    [port]: this.portTags[port].filter(
                        _tag => _tag.name !== tag
                    )
                };
            },
            selectAllPorts: function() {
                this.selectedPorts = [];

                if (!this.allPortsSelected) {
                    this.selectedPorts = [...this.ports];
                }
            },
            resetDrawer: function() {
                this.isSaving = false;
                this.isLoading = true;
                this.portTags = null;
                this.searchTerm = "";
                this.selectedPorts = [];
                this.allPortsSelected = false;
                this.clearTagsInput();
            },
            getPortTags: function() {
                this.isLoading = true;

                $.ajax({
                    url: "get_network_ports_with_tags",
                    method: "GET",
                    context: this,
                    data: {
                        server_id: this.instanceId,
                    },
                }).done((response) => {
                    if (response.success) {
                        this.portTags = {...response.data.port_tags};
                    }
                });

                this.isLoading = false;
            },
        },

        vueReady() {
            window.addEventListener('keydown', event => {
                if (event.key === 'Escape' && this.$refs.drawer.isOpen) {
                    this.$refs.drawer.close();
                }
            });
        },
    });
</script>
