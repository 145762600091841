const drawerClose = (() => {
    // params: ['target'],
    const state = new WeakMap;

    function _onClick(event) {
        event.preventDefault();

        window.app.eventHub.$emit('drawer:close', this.params.target);
    };

    return {
        bind(el, binding, vnode) {
            const self = {};
            state.set(el, self);

            self.el = el;
            self.params = Object.assign({}, el.attributes, vnode.data.attrs);

            if (typeof self.params.target === 'undefined') {
                return;
            }

            self._onClick = _onClick.bind(self);

            self.el.addEventListener('click', self._onClick);
        },

        unbind(el) {
            const self = state.get(el);
            self.el.removeEventListener('click', self._onClick);
        },
    };
})();

export default drawerClose;
