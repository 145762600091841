<template>
   <div class="p-internaladminicon">
        <p-tooltip2 v-bind="tooltipProps" >
            <div slot="trigger">
                <span v-if="iconPosition =='left'"><svg class="pa-icon"><use xlink:href="#lock_person_black_24dp" ></use></svg></span>
                <slot></slot>
                <span v-if="iconPosition =='right'"><svg class="pa-icon"><use xlink:href="#lock_person_black_24dp" ></use></svg></span>
            </div>
                This functionality is exposed to internal admins only, not for customers.
        </p-tooltip2>
    </div>
</template>

<script>
import Vue from 'vue';
import Tooltip2 from './Tooltip2.vue';
    const InternalAdminIcon = Vue.extend({
        props: {
            iconPosition: {
                type: String,
                default:'left',
                },
            tooltipProps:{
                type: Object
            },
            hideIcon:{
                type: Boolean,
                default : false
            }
            }
        });

    export default InternalAdminIcon;
</script>
