<style lang="scss" scoped>
    .pa-option {
        .disabled:hover {
            cursor:not-allowed
        }
    }
    .pa-option-input.disabled {
        & ~ .pa-option-txt {
            opacity: 0.6;
        }
    }
</style>


<template>
    <div class="pa-radio-option" :class="classes.split(/\s+/)">
        <div v-if="!vertical">
            <label v-for="opt in options" class="pa-option">
                <input
                    type="radio"
                    :value="opt.value"
                    v-model="iModel"
                    @change="onChange"
                    :name="name"
                    :disabled="opt.disabled"
                    class="pa-option-input"
                    :class="opt.disabled ? 'disabled': ''" />
                <span class="pa-option-icon pa-option-icon_radio"></span>
                <span class="pa-option-txt">
                   {{ opt.label }}
                </span>
            </label>
        </div>
        <template v-else>
            <label v-for="opt in options" class="pa-pb-8 pa-flex" style="align-items: center; position: relative;">
                <input
                    type="radio"
                    :value="opt.value"
                    v-model="iModel"
                    @change="onChange"
                    :name="name"
                    :disabled="opt.disabled"
                    class="pa-option-input"
                    :class="opt.disabled ? 'disabled': ''" />
                <span class="pa-option-icon pa-option-icon_radio"></span>
                <span class="pa-option-txt">
                   {{ opt.label }}
                </span>
            </label>
        </template>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                iModel: this.model,
            };
        },

        props: {
            options: {
                type: Array,
                'default': () => [],
            },

            name: String,

            model: '',

            classes: {
                type: String,
                'default': '',
            },

            onChange: {
                type: Function,
                'default': function() {},
            },

            vertical: {
                type: Boolean,
                'default': false,
            },

        },

        watch: {
            model(val) {
                this.iModel = val;
            },
            iModel(val) {
                this.$emit('update:model', val);
            },
        },

        methods: {
        },
    });

</script>
