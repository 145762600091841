<style lang="scss">

    .pa-drawer-bd .locked{
        overflow-y: hidden;
    }

    #metric-drawer .metric .pa-menu-box {
        width: initial;
        min-width: 250px;
        max-width: 400px;
        overflow: unset !important;
    }

    #metric-drawer .metric .pa-menu-box button {
        word-break: break-all;
        white-space: normal;
    }

    .metric-subcategory:not(.hidden) {
        margin-top: 0px;
        border-top: solid 1px #dbdee0;
    }

    #metric-drawer-title {
        min-height: 22px;
        font-size: 19px;
    }

    .quick-add-metric-menu {
        .content {
            div {
                width: 400px;
            }
        }
    }

</style>

<template>
    <p-drawer ref="drawer" side="right" id="metric-drawer" :width="550">
        <div slot="trigger" style="display: none;">
        </div>
        <div slot="head">
            <div v-if="showAdded" class="added-toast" transition="added-toast">
                <svg class="pa-icon pa-icon_xxl"><use xlink:href="#checkbox-circle"></use></svg>
                <span class="pa-txt pa-txt_bold" style="color: #00bbd3;">Metric Added</span>
                <a @click="highlightNew" class="pa-txt pa-txt_light pa-txt_xs"
                    style="margin-left: 7px; line-height: 19px; vertical-align: bottom; cursor: pointer;">
                    (view)
                </a>
            </div>
            <p id="metric-drawer-title">{{category.name}}</p>
        </div>
        <div slot="body">
            <div class="metric-drawer-controls">
                <ul class="pa-split" style="margin: 0;">
                    <li>
                        <p-select
                            id="metric-pane-category-filter"
                            size="xxxs"
                            :model.sync="selectedSubs"
                            :multiple="true"
                            :searchable="false"
                            :options="subOptions"
                            defaulttext="Filter"
                            :force-in-modal="true">
                        </p-select>
                    </li>
                    <li>
                        <input type="text" v-model="searchText" placeholder="Search metrics"
                            class="pa-input pa-input_xxxs" style="height: 40px;width:225px;"></input>
                    </li>
                </ul>
		<div v-if="hasPossibleMetrics" style="margin-top:10px;">
                        <label class="pa-option">
                            <input v-model="hideDisabled" type="checkbox" id="hide-disabled-metrics" class="pa-option-input" />
                            <span class="pa-option-icon"></span>
                            <span class="pa-option-txt" style="font-size:11px;color:black;">
                                Hide disabled metrics
                            </span>
                        </label>
                    </div>
                <div v-else></div>
            </div>
            <div v-if="loading">
                <div class="pa-loader"></div>
            </div>
            <div v-else style="overflow-y: auto; overflow-x: hidden; height: calc(100% - 108px);">
                <p style=" font-size: 19px;" class="pa-pl-14">Metrics</p>
                <div v-if="needsConfig" class="metric-drawer-warning">
                    <svg class="pa-icon"><use xlink:href="#alert-circle-outline"></use></svg>
                    <p v-if="category.documentation_url && category.documentation_url !== ''">
                        {{ category.name }} was not discovered on this instance. Check out our
                        docs <a target="_blank" :href="category.documentation_url">here</a>
                        where you can learn more about enabling {{ category.name }} monitoring.
                    </p>
                    <p v-else>
                        {{ category.name }} was not discovered on the instance. Check out our
                        <a target="_blank" href="https://docs.fortimonitor.forticloud.com/user-guide/407964/application-plugins">docs</a>
                        where you can learn more about enabling {{ category.name }} monitoring.
                    </p>
                </div>
                <ul ref="list" class="pa-vList">
                    <li v-for="subcat in filteredSubCategories" :class="subcat.className">
                        <div class="subcategory-header">
                            <span>{{subcat.name}}</span>
                        </div>
                        <ul class="pa-vList">
                            <li v-for="mt in filterMetrics(subcat)" class="metric">
                                <span :style="{color: !isTemplate && mt.possible ? '#777777' : ''}">{{mt.name}}</span>
                                <div>
                                    <p-tooltip v-if="!isTemplate && mt.status !== 0" :hover="true" :hover-timeout="300"
                                            :arrow-percent="75" :additional-content-style="{ 'left': 'calc(50% - 45px)' }">
                                        <div slot="trigger">
                                            <button disabled class="pa-btn pa-btn_secondary" style="padding-right: 36px;">
                                                <svg class="pa-icon pa-icon_light pa-icon_r"><use xlink:href="#plus"></use></svg> Add
                                            </button>
                                        </div>
                                        <span v-if="!hasAgent">Please install the Panopta Agent to monitor this metric.</span>
                                        <span v-if="hasAgent && mt.status_msg">{{ mt.status_msg }}</span>
                                        <span v-if="hasAgent && !mt.status_msg">We're unable to monitor this metric at this time. Please <a @click="intercomMetric(mt)" style="cursor: pointer;">chat with us</a> to help diagnose the problem.</span>
                                    </p-tooltip>
                                    <p-secondary-menu
                                        v-if="(isTemplate || mt.status == 0) && mt.options && mt.options.length"
                                        :max-height="400"
                                        :html-id="'add-metric-'+classify(mt.name)"
                                        text="Add"
                                        icon="plus"
                                        :force-in-modal="true"
                                        class="quick-add-metric-menu"
                                    >
                                        <div class="menu-tooltip">
                                            <p-flex style="align-items: center;">
                                                <div>Manual</div>
                                                <p-tooltip2 class="pa-pl-8">
                                                    <svg slot="trigger" class="pa-icon pa-icon_block" style="width: 10px; height: 10px;">
                                                        <use xlink:href="#question-circle-outline"></use>
                                                    </svg>
                                                    <div style="white-space: wrap; width 400px;">
                                                        Manually configure a new "{{mt.name}}" metric to monitor using parameters of your choosing.
                                                    </div>
                                                </p-tooltip2>
                                            </p-flex>
                                        </div>
                                        <button :id="'metric-pane-add-'+classify(category.name)+'-'+classify(mt.name)+'-manual'"
                                                @click="editNewMetric(mt)"
                                                style=" padding-right: 36px;">
                                                + Metric
                                        </button>
                                        <div v-if="mt.options && mt.options.length && mt.textkey != 'event_log:count'" class="menu-tooltip">
                                            <p-flex style="align-items: center;">
                                                <div>Quick Add</div>
                                                <p-tooltip2 class="pa-pl-8">
                                                    <svg slot="trigger" class="pa-icon pa-icon_block" style="width: 10px; height: 10px;">
                                                        <use xlink:href="#question-circle-outline"></use>
                                                    </svg>
                                                    <div style="white-space: wrap; width 400px;">
                                                        Add a "{{mt.name}}" metric to monitor using default parameters. You can edit it at any time.
                                                    </div>
                                                </p-tooltip2>
                                            </p-flex>
                                        </div>
                                        <div v-if="mt.textkey != 'event_log:count'">
                                            <button :id="'metric-pane-add-'+classify(category.name)+'-'+classify(mt.name)+'-check'"
                                                v-for="option in mt.options" @click="quick_add(subcat, mt, option)"
                                                :disabled="mt.active_options.indexOf(option) != -1"
                                                                        :title="option"
                                                style="">
                                            + {{option}}
                                            </button>
                                        </div>
                                    </p-secondary-menu>
                                    <button v-if="(isTemplate || mt.status == 0) && !(mt.options && mt.options.length)"
                                            @click="editNewMetric(mt)"
                                            class="pa-btn pa-btn_secondary" style="padding: 0.4375rem 27.5px;" id="'add-metric-'+classify(mt.name)">
                                        <svg class="pa-icon pa-icon_light"><use xlink:href="#plus"></use></svg>
                                        Add
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div v-if="additionalMessage" class="drawer-message">
                    <img class="drawer-message-icon" src="/static/assets/newux/media/logos/windows.svg" />
                    <span>Don't see what you're looking for? Learn more about configuring custom Perfmon metrics
                    <a target="_blank" href="/config/EditCustomPerfmonMetrics">here</a>.
                    </span>
                </div>
            </div>
        </div>
    </p-drawer>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';

    const MetricDrawer = Vue.extend({
        components: {
        },

        events: {
            'metric-drawer:open': function(serverId, textkey, subCategoryName, demFlow, isInbound) {
                this.serverId = serverId;
                this.categoryTextkey = textkey;
                this.subCategoryName = subCategoryName;
                this.loading = true;
                this.demFlow = demFlow;
                this.isInbound = isInbound;
                this.$nextTick(() => {
                    this.$refs.drawer.open();
                });
                
            },
            'drawer-open': function(drawerId) {
                if (drawerId !== 'metric-drawer') {
                    return;
                }
                if (document.body.scrollHeight > window.innerHeight) {
                    document.documentElement.classList.add('server-report-locked');
                }
                if (!this.serverId || !this.categoryTextkey) {
                    return;
                }
                setTimeout(() => {
                    var payload = {
                        server_id: this.serverId,
                        category_textkey: this.categoryTextkey,
                    };
                    $.ajax("/report/ManageCategoryVue2", {
                        method: 'GET',
                        data: payload,
                        context: this
                    })
                    .done(data =>{
                        if (!data.success) {
                            console.log(data.msg)
                            return;
                        }
                        this.category = data.category;
                        this.subCategories = data.sub_categories;
                        if (data.additional_message) {
                            this.additionalMessage = data.additional_message;
                        }
                        setTimeout(() => {
                            this.loading = false;
                            this.scrollToSubcategory();
                            //this.showTooltip();
                        }, 500);
                    });
                }, 800);
            },
            'drawer-close': function(drawerId) {
                if (drawerId !== 'metric-drawer') {
                    return;
                }
                this.cleanup();
            },
            'zero-state-metric-added'() {
                this.cleanup();
                this.$refs.drawer.close();
            },
            'scroll-lock:on': function(comp, flag) {
                const body = document.querySelector('#metric-drawer .pa-drawer-bd');
                if(body && flag) {
                    body.classList.add('locked');
                }  
            },
            'scroll-lock:off': function(comp, flag) {
                const body = document.querySelector('#metric-drawer .pa-drawer-bd');
                if(body && flag) {
                    body.classList.remove('locked');
                }
            },
        },

        data() {
            return {
                category: {},
                subCategoryName: '',
                subCategories: [],
                selectedSubs: [],
                searchText: '',
                serverId: null,
                categoryTextkey: null,
                showAdded: false,
                lastAdded: null,
                hideDisabled: false,
                additionalMessage: false,
                loading: false,
                demFlow: false,
                isInbound: false,
            };
        },

        computed: {
            subOptions: function() {
                return _.uniqBy(this.subCategories, "textkey").map((s) => ({
                    'label': s.name,
                    'value': s.textkey
                }));
            },
            mappedSubCategories: function() {
                return this.subCategories.map(
                    (subCategory) => {
                        const className = [
                            "metric-subcategory",
                            subCategory.name.replaceAll(" ", "-"),
                            this.filterMetrics(subCategory).length ? "" : "hidden",
                        ].join(" ");

                        return {
                            ...subCategory,
                            className,
                        };
                    }
                );
            },
            filteredSubCategories: function() {
                let filtered = this.mappedSubCategories.filter((s) => {
                    if (this.selectedSubs.length &&
                            !this.selectedSubs.includes(s.textkey)) {
                        return false;
                    }
                    return true;
                });
                return filtered;
            },
            hasAgent: function() {
                return this.$parent.hasAgent;
            },
            needsConfig: function() {
                return Boolean(!this.loading &&
                    !this.hasAddableMetrics);
            },
            hasAddableMetrics: function() {
                return this.filteredSubCategories.some(sc => {
                    return sc.metric_types.length > 0;
                });
            },
            hasPossibleMetrics: function() {
                return this.filteredSubCategories.some((sc) => {
                    return sc.possible_metric_types.length > 0;
                });
            },
        },

        props: {
            operatingSystem: String,
            isTemplate: Boolean,
        },

        methods: {
            filterMetrics: function(subcategory) {
                subcategory.possible_metric_types.forEach((pm) => {
                    pm.possible = true;
                });
                let metrics = subcategory.metric_types.concat(subcategory.possible_metric_types);
                metrics.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
                if (this.searchText.length &&
                        subcategory.name.toLowerCase().includes(this.searchText.toLowerCase())) {
                    // Include all metrics in this category
                    return metrics;
                }
                let filtered = metrics.filter((m) => {
                    if (this.searchText.length &&
                            !m.name.toLowerCase().includes(this.searchText.toLowerCase())) {
                        return false;
                    }
                    if (m.possible && this.hideDisabled) {
                        return false;
                    }
                    if (!this.isTemplate && m.platform && this.operatingSystem &&
                        m.platform.toLowerCase() != this.operatingSystem.toLowerCase()) {
                        return false;
                    }
                    return true;
                });
                return filtered;
            },
            editNewMetric: function(metricType) {
                this.$parent.$refs["edit-metric-drawer"].openWithMetricType(metricType, this.demFlow, this.isInbound);
            },
            quick_add: function(sub_category, metric_type, option) {
                // Make the right Ajax call to create a new metric and then update the tables
                if (['agent', 'prometheus', 'onsight.custom_metric', 'fabric'].includes(metric_type.check_method)) {
                    $.ajax({
                        url: '/config/monitoring/quickaddAgentMetric',
                        method: 'POST',
                        data: {
                            server_id: this.serverId,
                            textkey: metric_type.textkey,
                            option: option,
                            send_new: true
                        },
                        context: this
                    })
                      .done(function(data) {
                          if (!data.success) {
                            window.app.toastManager.showToast({
                                icon: 'alert-circle',
                                type: 'error',
                                text: data.message,
                            });
                          } else {
                            this.$broadcast('metric-added',
                                this.categoryTextkey,
                                data.category_name,
                                data.instance);
                            this.lastAdded = data.instance;
                            this.showAdded = true;
                            this.highlightNew();
                            window.setTimeout(() => {
                                this.showAdded = false;
                            }, 3000);
                            this.$parent.showToast('Metric successfully added');
                          }
                      })
                      .fail();
                } else if (metric_type.check_method == "network") {
                  // QUICKADD currently not supported for network checks
                }
            },
            highlightNew: function() {
                this.$broadcast('highlight-metric', this.category.name, this.lastAdded);
            },
            classify: function(string) {
                return string.replaceAll(' ', '-');
            },
            cleanup() {
                document.documentElement.classList.remove('server-report-locked');
                this.selectedSubs = [];
                this.searchText = '';
                this.category = {};
                this.$subCategories = [];
            },
            scrollToSubcategory: function() {
                if (!this.subCategoryName) {
                    return;
                }
                Vue.nextTick(() => {
                    const selector = `.metric-subcategory.${this.subCategoryName.replaceAll(" ", "-")}:not(.hidden)`;
                    const el = this.$refs.list ? this.$refs.list.querySelector(selector) : null;
                    if (el) {
                        el.scrollIntoView();
                    }
                });
            },
            intercomMetric: function(metric_type) {
                if (typeof Intercom === 'undefined') {
                    console.log("Intercom is not initialized");
                    return;
                }
                let metricName = `${this.category.name} - ${metric_type.name}`;
                Intercom('showNewMessage', `It says I can't monitor "${metricName}". Could you assist me?`);
            },
            showTooltip: function() {
                if (!this.$parent.seenMetricDrawer && window.hopscotch) {
                    this.$parent.seenMetricDrawer = true;
                    // Show a hopscotch flyout on the drawer title
                    setTimeout(() => {
                        const calloutMgr = window.hopscotch.getCalloutManager();
                        calloutMgr.createCallout({
                            id: 'feature-highlight',
                            target: 'metric-drawer-title',
                            placement: 'bottom',
                            title: 'Metric Drawer',
                            content: 'Select and configure the metrics you\'d like to monitor',
                            xOffset: 0,
                            yOffset: 0,
                            width: 280,
                        });
                    }, 1000);
                    // Mark the feature seen on our end
                    $.ajax('/users/users/markFeatureSeen', {
                        method: 'GET',
                        data: {
                            html_id: 'metric-drawer-title',
                        },
                        context: this,
                    })
                    .done(data => {
                        if (!data.success) {
                            console.log(data.message);
                        }
                    });
                }
            },
        },

        ready() {
            this.$nextTick(() => {
                window.addEventListener('keydown', event => {
                    if (event.key === 'Escape' && this.$refs.drawer.isOpen) {
                        this.$refs.drawer.close();
                    }
                });
            });
        },
    });

    export default MetricDrawer;
</script>
