<template>
    <div
        tabindex="-1"
        class="pa-tooltip"
        :class="{ isActive: isActive, advanced: advanced, fixed: fixed }"
        v-on:keydown.27="_onKeydown($event)">
        <span class="pa-tooltip-box" v-on:mouseleave="closeWithDelay()">
            <div v-if="hover">
                <button
                    ref="trigger"
                    class="pa-tooltip-box-btn"
                    :style="{ textAlign: triggerAlign }"
                    type="button"
                    tabindex="-1"
                    v-on:mouseenter="onHover()"
                    v-on:click="hoverOnClick">
                    <slot name="trigger"></slot>
                </button>
            </div>
            <div v-else>
                <button
                    ref="trigger"
                    class="pa-tooltip-box-btn"
                    :style="{ textAlign: triggerAlign }"
                    type="button"
                    tabindex="-1"
                    v-on:click="_onClick($event)">
                    <slot name="trigger"></slot>
                </button>
            </div>
            <span class="pa-tooltip-box-content" :style="computedStyles" ref="content" :class="[direction, 'arrow-' + arrowPercent]">
                <slot></slot>
                <div v-if="!advanced && !fixed" class="pa-tooltip-hitbox" style="width: 100%; height: 20px; position: absolute;">
                </div>
                <div v-if="advanced" style="position: relative;">
                    <div class="pa-tooltip-hitbox" style="width: 100%; height: 20px; position: absolute;">
                    </div>
                </div>
            </span>
        </span>
    </div>
</template>

<script>
    import Vue from 'vue';

    let COUNT = 0;

    var Tooltip = Vue.extend({
        events: {
            'tooltip-opening': function(tooltipId) {
                if (this.id === tooltipId) {
                    return;
                }
                this.close();
            },
        },

        props: {
            id: {
                type: String,
                default: function() {
                    return 'tooltip_' + COUNT++;
                },
            },
            isActive: {
                type: Boolean,
                default: false,
            },
            hover: {
                type: Boolean,
                default: true,
            },
            waitTime: {
                type: Number,
                default: 300,
            },
            hoverTimeout: {
                type: Number,
                default: 3000,
            },
            direction: {
                type: String,
                default: 'up',
            },
            arrowPercent: {
                type: Number,
                default: 50,
            },
            advanced: {
                type: Boolean,
                default: false,
            },
            fixed: {
                type: Boolean,
                default: false,
            },
            additionalContentStyle: {
                type: Object,
                default: () => {},
            },
            hoverOnClick: {
                type: Function,
                default: () => {},
            },
            triggerAlign: String,
            width: Number,
        },

        data() {
            return {
                waitTimer: null,
            };
        },

        computed: {
            computedStyles() {
                const styles = Object.assign({},
                    this.additionalContentStyle,
                    {
                        whiteSpace: this.width ? 'normal' : 'nowrap',
                        width: this.width ? this.width + 'px' : null,
                    }
                );
                return styles;
            },
        },

        methods: {
            onHover() {
                if (this.waitTime > 0) {
                    this.waitTimer = setTimeout(this.open, this.waitTime);
                    this.$refs.trigger.addEventListener('mouseleave', (event) => {
                        clearTimeout(this.waitTimer);
                    });
                } else {
                    this.open();
                }
            },
            open() {
                if (window.app && window.app.rootVue) {
                    this.eventHub.$emit('tooltip-opening', this.id);
                }
                this.isActive = true;
                if (this.advanced) {
                    Vue.nextTick(() => {
                        let contentHeight = 0;
                        for (var i = 0; i < this.$refs.content.children.length; i++) {
                            contentHeight += this.$refs.content.children[i].getBoundingClientRect().height;
                        }
                        // For padding
                        contentHeight += 20;
                        const triggerLeft = this.$refs.trigger.offsetLeft;
                        const triggerTop = this.$refs.trigger.offsetTop;
                        const triggerRect = this.$refs.trigger.getBoundingClientRect();
                        let left = 0;
                        let top = 0;
                        if (this.direction === 'up') {
                            left = triggerLeft + (triggerRect.width / 2);
                            top = triggerTop - contentHeight - (triggerRect.height / 2) - 5;
                        } else if (this.direction === 'right') {
                            left = triggerLeft + (triggerRect.width / 2) + 20;
                            top = triggerTop - (triggerRect.height / 2) - 10;
                        }
                        this.$refs.content.style.height = `${contentHeight}px`;
                        this.$refs.content.style.left = `${left}px`;
                        this.$refs.content.style.top = `${top}px`;
                    });
                } else if (this.fixed) {
                    Vue.nextTick(() => {
                        let contentHeight = 0;
                        for (var i = 0; i < this.$refs.content.children.length; i++) {
                            contentHeight += this.$refs.content.children[i].getBoundingClientRect().height;
                        }
                        // For padding
                        contentHeight += 20;
                        const triggerRect = this.$refs.trigger.getBoundingClientRect();
                        let triggerLeft = triggerRect.left;
                        let triggerTop = triggerRect.top;
                        let left = triggerLeft + (triggerRect.width / 2);
                        let top = triggerTop - contentHeight - 5;
                        this.$refs.content.style.height = `${contentHeight}px`;
                        this.$refs.content.style.left = `${left}px`;
                        this.$refs.content.style.top = `${top}px`;
                    });
                }

                document.body.addEventListener('click', this._onFocusLeaveTooltip);
                document.body.addEventListener('focus', this._onFocusLeaveTooltip, true);
            },

            close() {
                this.isActive = false;

                document.body.removeEventListener('click', this._onFocusLeaveTooltip);
                document.body.removeEventListener('focus', this._onFocusLeaveTooltip, true);
            },

            closeWithDelay() {
                if (!this.hover) {
                    return;
                }
                window.setTimeout(this.close, this.hoverTimeout);
            },

            toggle() {
                var isActive = !this.isActive;

                if (isActive) {
                    this.open();

                    return;
                }

                this.close();
            },

            _onClick($event) {
                this.toggle();
            },

            _onFocusLeaveTooltip(event) {
                var target = event.target;
                var tooltipContainsTarget = this.$el.contains(target);

                if (tooltipContainsTarget) {
                    return;
                }

                this.close();
            },

            _onKeydown($event) {
                this.close();
            }
        }
    });

    export default Tooltip;
</script>
