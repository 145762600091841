<!--

    This component is used with DataPointInfo to dynamically set the wrapping element
    of its child content so that it can either be a clickable button or a normal div.

    The child elements of this component should be wrapped with the slot name "body"
    to match the same position of DataPointInfo.

-->
<template>
    <button type="button" class="pa-btn_dataPoint" @click="handleClick($event)">
        <slot name="body"></slot>
        <div class="pa-btn_dataPoint-hover">
            <!--
                By default on hover display an edit icon, use the
                hover slot to change that to whatever you want.
            -->
            <slot name="hover">
                <p-icon
                    class="pa-btn_dataPoint-icon"
                    icon="pencil"
                    color="blue"
                    size="xl"
                >
                </p-icon>
            </slot>
        </div>
    </button>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    methods: {
        handleClick(event) {
            this.$emit('click', event);
        },
    },
});
</script>