<style>
    .gauge-label {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: baseline;
    }
    .gauge-value {
        font-size: 25px;
        padding-right: 5px;
    }
    .gauge-unit {
        font-size: 12px;
        color: silver;
    }
</style>

<template>
    <div :id="id"
        :class="!inModal ? 'grid-stack-item' : 'modal-view'"
        :data-gs-x="localConfig.col.toString()"
        :data-gs-y="localConfig.row.toString()"
        :data-gs-width="localConfig.width.toString()"
        :data-gs-height="localConfig.height.toString()"
        :data-gs-auto-position="false"
        :data-gs-locked="true"
        >
        <div
            :class="!inModal ? 'grid-stack-item-content' : 'modal-view-content'"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;">
                <div :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered, 'expand-mode' : !$parent.editing && isHovered && !inModal, 'in-modal': inModal}">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_9of12" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                            </span>
                            <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: textColor}"><use xlink:href="#spinner-spin-naked"></use></svg>
                            <p-tooltip2 :normal-white-space="true">
                                <span v-if="!isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: textColor }">
                                    <span v-if="localConfig.title">{{ localConfig.title }}</span>
                                </span>
                                <span v-if="isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate">
                                    <span v-if="localConfig.title">{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }}</span>
                            </p-tooltip2>
                        </div>
                        <div class="pa-grid-col pa-grid-col_3of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger"  v-show="!$parent.editing && isHovered && !inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#arrow-expand"></use>
                                    </svg>
                                </button>
                                <span> Expand </span>
                            </p-tooltip2>
                            <button slot="trigger"  v-show="!$parent.editing && inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                <svg class="pa-icon remove-icon">
                                    <use xlink:href="#close"></use>
                                </svg>
                            </button>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="cloneWidget" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <p-icon icon="copy" ></p-icon>
                                </button>
                                <span> Clone this Widget</span>
                            </p-tooltip2>
                        </div>
                    </div>
                </div>
                <div v-if="valid" :style="{'height': inModal && '85vh'}" class="widget-body" style="flex-grow: 1; margin-bottom: 10px;" ref="body">
                </div>
               <div v-if="needsConfig" class="widget-body" :style="{'height': inModal && '85vh'}" style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center;" ref="temp_body">
                    <div :class="!inModal && 'vertical-center'">
                        <button v-if="$parent.allowEditing" @click="configure" class="pa-btn pa-btn_naked_light_grey_no_fill pa-btn_lg" style="padding: 12px 35px" type="button">
                            <svg class="pa-icon">
                                <use xlink:href="#plus-circle-outline"></use>
                            </svg>
                            <span>Configure</span>
                        </button>
                        <p class="widget-unconfigured centered">
                            Please select a metric filter.
                        </p>
                        <p v-if="accessError" class="widget-unconfigured centered">
                            You do not have access to this metric.
                        </p>
                    </div>
                </div>
                <div v-if="!valid" class="widget-body widget-body_error" :style="{'height': inModal && '85vh'}" :title="error_message" ref="body">
                    <p class="pa-message pa-message_error">
                        <svg class="pa-icon pa-icon_xl"><use xlink:href="#alert-circle"></use></svg>
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import widgetMixin from './../widgetMixin';

    const Highcharts = require('highcharts');
    import More from 'highcharts/highcharts-more'
    More(Highcharts)
    require('highcharts/modules/solid-gauge')(Highcharts);

    const GaugeWidget = Vue.extend({
        mixins: [widgetMixin],

        data() {
            return {
                chart: null,
                isAllPercentAxis: true,
                same_unit_resources_y: false,
                first_resource_unit: null,
                simillarity_counter: 0,
                localConfig: this.config,
                inModal: false,
            };
        },

        events: {
        },

        props: {
            config: {
                type: Object,
                default() {
                    return {
                        graph_type: 'areagraph',
                        timescale: 'day',
                        data_source: 'monitor_point',
                        custom_controls: {},
                        rendered: false,
                    };
                },
            },
        },

        methods: {
            expandSelf() {
                this.inModal = !this.inModal
            },
            getConfigModules() {
                return [
                    { type: 'p-overview-module' },
                    { type: 'p-instance-metric-module' },
                ]
            },
            getOptionModules() {
                return [
                    {
                        type: 'p-custom-module',
                        options: {
                            ...(this.$parent.isScoping() ? {"ignore-scope-module": {}} : {}),
                            title: 'Gauge', controls: [
                                {
                                    label: 'Show metric option',
                                    key: 'show_option',
                                    type: 'p-switch',
                                },
                                {
                                    label: 'Max Value',
                                    key: 'max_value',
                                    type: 'input',
                                },
                                {
                                    id: 'backgroundColor',
                                    label: 'Background Color',
                                    key: 'backgroundColor',
                                    type: 'color-picker',
                                    default: '#ffffff'
                                },
                            ]
                        }
                    }
                ]
            },
            configure() {
                if(!this.inModal) {
                    window.app.rootVue.$broadcast('dashboard:open_config', this);
                }
            },
            initialize(config, content) {
                this.$refs.body.style.filter = '';
                this.content = content;
                this.localConfig = {...config};
                this.reconfig();
                this.rendered = true;
            },
            reconfig() {
                if (!this.content.success) {
                    return false;
                }
                const textColor = ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black');
                const bodyWidth = this.$refs.body.clientWidth;
                const labelFormat = `<div class="gauge-label" style="width:${bodyWidth * .3}px;">
                    <span class="gauge-value" style="color:${textColor};">${this.content.value}</span>
                    <span class="gauge-unit">${this.content.unit}</span>
                    </div>`;
                let size = '140%';
                if (this.$refs.body && this.$refs.body.clientWidth <= 150) {
                    size = '100%';
                }
                // Make sure stops are sorted first
                const stops = this.content.stops;
                stops.sort((a, b) => {
                    return a[0] > b[0];
                });
                const dataClasses = [];
                for (var i = 0; i < stops.length; i++) {
                    const [from, lightColor, darkColor] = stops[i];
                    const dataClass = {
                        from,
                        color: {
                            linearGradient: [0, 0, 300, 0],
                            stops: [
                                [0, lightColor],
                                [1, darkColor],
                            ],
                        },
                    };
                    if (i < stops.length - 1) {
                        dataClass.to = stops[i + 1][0];
                    } else {
                        dataClass.to = Infinity;
                    }
                    dataClasses.push(dataClass);
                }
                this.chart = Highcharts.chart(this.$refs.body,{
                    chart: {
                        type: 'solidgauge',
                        plotBorderWidth: 0,
                        borderColor: 'transparent',
                        backgroundColor: 'transparent',
                        plotBackgroundColor: 'transparent',
                        // height: this.get_body_height() - 35,
                        marginLeft: 0,
                        marginRight: 0,
                        marginTop: 0,
                        marginBottom: 0,
                        plotShadow: false,
                    },
                    title: null,
                    exporting: {
                        enabled: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                borderWidth: 0,
                                y: 5,
                                useHTML: true,
                            },
                        },
                    },
                    pane: {
                        center: ['50%', '75%'],
                        size: size,
                        startAngle: -90,
                        endAngle: 90,
                        borderWidth: 0,
                        borderColor: '#fff',
                        background: {
                            backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
                            innerRadius: '60%',
                            outerRadius: '100%',
                            shape: 'arc',
                        },
                    },
                    title: {
                        text: this.content['metric_option'],
                        align: 'center',
                        verticalAlign: 'bottom',
                        margin: 0,
                        y: 5,
                        style: {
                            
                            fontWeight: 'normal',
                            fontSize: '20px',
                            textOverflow: null,
                            overflow: 'hidden',
                        },
                    },
                    yAxis: {
                        min: this.content.min_value,
                        max: this.content.max_value,
                        lineWidth: 0,
                        tickWidth: 0,
                        minorTickInterval: null,
                        tickAmount: 2,
                        title: {
                            y: -70
                        },
                        labels: {
                            y: 16
                        },
                        dataClasses: dataClasses,
                    },
                    credits: {
                        enabled: false,
                    },
                    series: [{
                        name: this.content.name,
                        data: [this.content.value],
                        dataLabels: {
                            format: labelFormat,
                        },
                        enableMouseTracking: false,
                        tooltip: {
                            valueSuffix: ` ${this.content.unit}`,
                        },
                    }],
                });
            },
            update(content) {
                if (!content.success) {
                    return;
                }
                this.content = content;
                const data_values = [];
                this.chart.series[0].points[0].update(this.content.value);
            },
            dumpState() {
                function censor(key, value) {
                    if (key === 'chart') {
                        return undefined;
                    }
                    return value;
                }
                const obj = Object();
                obj.data = this.$data;
                obj.config = {...this.localConfig};
                console.log(JSON.stringify(obj, censor));
            },
        },
        mounted() {
            this.pendNewConfig();
            if (this.id < 0) { this.configure(); };
        },
    });

    export default GaugeWidget;
</script>
