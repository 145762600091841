<style>
</style>

<template>
    <div class="module users-module">
        <div class="module-header">
            <span class="pa-txt">Users</span>
        </div>
        <div ref="body" v-show="open" transition="module-expand" class="module-body">
                <div class="pa-field pa-field_vertical">
                    <div class="pa-field-hd">
                        <label class="pa-label">
                            Select Users
                        </label>
                    </div>
                    <div class="pa-field-bd">
                        <p-select
                            :options="userOptions"
                            :model.sync="selectedUsers"
                            :multiple="true"
                            all-option="All Users"
                            class="pa-select_stretch"
                            :searchable="true">
                        </p-select>
                    </div>
                    <div class="pa-field-ft">
                    </div>
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const UsersModule = Vue.extend({
        events: {
        },

        data() {
            return {
                ready: false,
                userOptions: [],
                selectedUsers: [],
                allUsers: [],
            };
        },

        props: {
            options: {
                type: Object,
                'default': function() {
                    return {};
                },
            },
            open: {
                type: Boolean,
                'default': true,
            },
            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        },

        methods: {
            toggle() {
                this.open = !this.open;
            },

            sendConfig() {
                if (!this.ready) { return; }
                let selectedUsers = this.selectedUsers;
                if(this.selectedUsers[0] == '_all_') {
                    selectedUsers = this.allUsers;
                }
                const payload = {
                    widget_id: this.editingWidget.id,
                    selected_users: selectedUsers,
                };
                $.ajax({
                    url: '/dashboardv2/setUserOptions',
                    traditional: true,
                    method: 'POST',
                    data: payload,
                });
                this.editingWidget.pendNewConfig();
            },

            getUserOptions(callback) {
                let widgetId = this.editingWidget.id;
                $.ajax({
                    url: `/dashboardv2/getUserOptions/${widgetId}`,
                    method: 'GET',
                    context: this,
                }).done(function(data) {
                    if (!data.success) { return; }

                    let userOptions = data.user_options;
                    userOptions = _.sortBy(data.user_options, [user => user.label.toLowerCase()]);

                    this.userOptions = userOptions;

                    for(let user of this.userOptions) {
                        this.allUsers.push(user.value);
                    }

                    // Call the callback first
                    if (typeof callback !== 'undefined') {
                        callback();
                    }
                });
            },
        },

        watch: {
            'selectedUsers': function(val, oldVal) {
                if (!this.ready) { return; }
                this.sendConfig();
            },
        },

        computed: {
        },

        vueReady() {
            this.getUserOptions(() => {
                const w = this.editingWidget;

                const cc = w.localConfig.custom_controls || {};
                if (cc.selected_users) {
                    this.selectedUsers = [...cc.selected_users];

                    if (cc.selected_users.length >= this.allUsers.length) {                        
                        this.selectedUsers = this.allUsers;
                    }  
                }
 
                Vue.nextTick(() => {
                    this.ready = true;
                });
            });
        },
    });

    export default UsersModule;
</script>
