<template>
    <div :id="id"
         :class="!inModal ? 'grid-stack-item' : 'modal-view'"
         :data-gs-x="localConfig.col.toString()"
         :data-gs-y="localConfig.row.toString()"
         :data-gs-width="localConfig.width.toString()"
         :data-gs-height="localConfig.height.toString()"
         :data-gs-auto-position="false"
         :data-gs-locked="true">
        <div
            :class="!inModal ? 'grid-stack-item-content' : 'modal-view-content'"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;">
                <div :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered, 'expand-mode' : !$parent.editing && isHovered && !inModal, 'in-modal': inModal}">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_9of12" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                            </span>
                            <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: textColor}"><use xlink:href="#spinner-spin-naked"></use></svg>
                            <p-tooltip2 :normal-white-space="true">
                                <span v-if="!isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: textColor }">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span v-if="isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }}</span>
                            </p-tooltip2>
                        </div>
                        <div class="pa-grid-col pa-grid-col_3of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger"  v-show="!$parent.editing && isHovered && !inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#arrow-expand"></use>
                                    </svg>
                                </button>
                                <span> Expand </span>
                            </p-tooltip2>
                            <button slot="trigger"  v-show="!$parent.editing && inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                <svg class="pa-icon remove-icon">
                                    <use xlink:href="#close"></use>
                                </svg>
                            </button>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls  && !inModal" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon" >
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls  && !inModal" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>                                    
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls  && !inModal" type="button" @click="cloneWidget" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <p-icon icon="copy" ></p-icon>
                                </button>
                                <span> Clone this Widget</span>
                            </p-tooltip2>
                        </div>
                    </div>
                </div>
                <div v-show="valid" id="body" class="widget-body" :style="{'height': inModal && '85vh'}" style="height: inherit; overflow-y: auto; flex-grow: 1; margin: 0px 6px 6px 6px !important;" ref="body">
                    <div
                        v-if="reachedThreshold"
                        class="pa-flex pa-align-center pa-justify-center p-height-full"
                    >
                        <span class="block pa-txt_sm">
                            Too many cells to display. Please filter down results.
                        </span>
                    </div>
                    <p-heatmap v-else v-bind:hidden="hideMap" :data-labels="false" :enable-legend="false" ref="heatmap"></p-heatmap>
                </div>
                <div v-if="needsConfig" class="widget-body" :style="{'height': inModal && '85vh'}" style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center;" ref="temp_body">
                    <div class="vertical-center">
                        <button v-if="$parent.allowEditing" @click="configure" class="pa-btn pa-btn_naked_light_grey_no_fill pa-btn_lg" style="padding: 12px 35px" type="button">
                            <svg class="pa-icon">
                                <use xlink:href="#plus-circle-outline"></use>
                            </svg>
                            <span>Configure</span>
                        </button>
                        <p class="widget-unconfigured centered">
                            Please select an instance filter.
                        </p>
                    </div>
                </div>
                <div v-if="!valid" class="widget-body widget-body_error" :style="{'height': inModal && '85vh'}" :title="error_message" ref="body">
                    <p class="pa-message pa-message_error">
                        <svg class="pa-icon pa-icon_xl"><use xlink:href="#alert-circle"></use></svg>
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
 import Vue from 'vue';
 import $ from 'jquery';
 import widgetMixin from './../widgetMixin';

 const HeatmapWidget = Vue.extend({
     mixins: [widgetMixin],

     data() {
         return {
             content: {},
             hideMap: true,
             heatmapThreshold: 2000,
             localConfig: this.config,
             inModal: false,
         };
     },

     computed: {
         reachedThreshold() {
             return (
                 this.content &&
                 this.content.heatmapData &&
                 this.content.heatmapData.length >= this.heatmapThreshold
            );
         },
     },

     methods: {
         initialize(config, content) {
             this.contentReady();
             this.localConfig = {...config};
             this.content = content;             

             if (!this.content.success) {
                 return;
             }

             this.hideMap = false;
             if (this.content.heatmapData === '[]') {
                 this.updateMap([]);
             } else {
                 this.updateMap(this.content.heatmapData);
             }
             Vue.nextTick(() => {
                 this.$refs.heatmap.reflowChart();
             });
         },

         expandSelf() {
            this.inModal = !this.inModal
        },

         update(content) {
             this.content = content;
             if (!this.content.success
                    || this.content.heatmapData === '[]'
                    || !this.content.heatmapData.length) {
                 return;
             }
             this.updateMap(this.content.heatmapData);
         },

         updateMap(data) {
             this.$refs.heatmap.insertData(data);
         },

         getConfigModules() {
             return [
                 { type: 'p-overview-module' },
                 { type: 'p-instance-module' },
                 { type: 'p-metric-module', options: { multiple: true, allSelectable: true } },
                ...(this.$parent.isScoping() ? [{ type: "p-ignore-scope-module" }] : []),
                {
                    type: 'p-custom-module',
                    options: {
                        title: '',
                        controls: [
                            {
                                id: 'backgroundColor',
                                label: 'Background Color',
                                key: 'backgroundColor',
                                type: 'color-picker',
                                default: '#ffffff'
                            },
                        ]
                    }
                },
             ]
         },

         getOptionModules() {
             return []
         },

         configure() {
             window.app.rootVue.$broadcast("dashboard:open_config", this);
         },

         reconfig() {
             if (!this.content.success) {
                 return;
             }
             this.$refs.heatmap.reflowChart();
         },
         dumpState() {
             const obj = Object();
             obj.data = this.$data;
             obj.config = {...this.localConfig};
             console.log(JSON.stringify(obj));
         },

         userCanView(textkey) {
             return this.$parent.userCanView(textkey);
         }

     },

     props: {
     },

     events: {
    },

     mounted() {
         this.pendNewConfig();
         if (this.id < 0) { this.configure(); };
     },
 });

 export default HeatmapWidget;
</script>
