
var breakpoints = {
    mobile: '(max-width: 767px)'
};

var isMobile = function() {
    return window.matchMedia(breakpoints.mobile).matches;
};

var matchMedia = function(breakpoint) {
    if (!breakpoints[breakpoint]) {
        throw new Error('Breakpoint ' + breakpoint + ' does not exist');

        return;
    }

    return window.matchMedia(breakpoints[breakpoint]);
};

module.exports = {
    breakpoints: breakpoints,
    isMobile: isMobile,
    matchMedia: matchMedia
};