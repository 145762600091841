<style lang="scss">
.pa-description-list {

    .pa-descripton-list-item {

        .pa-descripton-list_label {
            padding-bottom: 4px;
        }

        &.inline-list {
            display: flex;

            .pa-descripton-list_label {
                margin-right: 4px;
            }
        }

    }
}
</style>

<template>
    <dl :class="computedClass">
        <slot></slot>
        <p-description-list-item
            v-for="item in list"
            :key="item.label"
            :item="item"
            :inline="inline"
        >
        </p-description-list-item>
    </dl>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        list: Array,
        inline: Boolean,
        noGrid: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        computedClass() {
            const className = {
                "pa-description-list": true,
                "pa-grid": !this.noGrid,
            };
            return className;
        },
    },
});
</script>
