<template>
    <div>
        <div v-show="loading" class="pa-loader" style="position: absolute; left: 50%;"></div>

        <div class="pa-gauge"
             :id="`p-graph-${_uid}`" 
             ref="container"
             :style="{ height: `${height || 400}px` }">
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const Highcharts = require('highcharts');
    import More from 'highcharts/highcharts-more'
    More(Highcharts)
    require('highcharts/modules/solid-gauge')(Highcharts);

    const MAX_DATA_TRIES = 3;

    const GaugeComponent = Vue.extend({
        data() {
            return {
                loading: true,

                value: 0.0,
                minValue: 0.0,
                maxValue: 0.0,

                retryTimeout: 10000,
                tries: 0,
            };
        },

        props: {
            id: {
                type: String,
                'default': '',
            },

            metric: Number,

            title: {
                type: String,
                'default': '',
            },

            initialValue: {
                type: Number,
                'default': 0,
            },

            initialMinValue: {
                type: Number,
                'default': 0,
            },

            initialMaxValue: {
                type: Number,
                'default': 0,
            },

            unit: {
                type: String,
                'default': '',
            },

            height: Number,

            autoLoad: {
                type: Boolean,
                'default': true,
            },
        },

        events: {
            'metric-values:new': function(metricId, values) {
                if (metricId !== this.metric) {
                    return;
                }
                const last = values.slice(-1)[0];
                this.updateValue(last[1]);
            },
        },

        methods: {
            fetchValue() {
                $.ajax({
                    url: '/report/gauge_data',
                    data: {
                        metric_id: this.metric,
                    },
                }).done(data => {
                    if (data.success) {
                        this.value = data.value;
                        this.renderGauge();
                        this.retryTimeout = 10000;
                    } else {
                        console.error(data);
                        this.value = null;
                        this.renderGauge();
                    }
                })
                .fail(() => {
                    if (this.tries < MAX_DATA_TRIES) {
                        this.tries += 1;
                        setTimeout(() => {
                            this.fetchValue();
                        }, 800);
                    } else {
                        // Switch to exponential backoff
                        setTimeout(() => {
                            this.fetchValue();
                        }, this.retryTimeout);
                        this.retryTimeout *= 2;

                        if (window.app.Sentry) {
                            window.app.Sentry.captureMessage(`All ${MAX_DATA_TRIES} attempts at gauge_data failed`, {
                                extra: {
                                    metric_id: this.metric,
                                },
                                fingerprint: 'gauge-fail',
                                tags: {
                                    url: window.location.href,
                                },
                            });
                        }
                    }
                });
            },

            updateValue(value) {
                this.value = value;

                if (this.chart) {
                    this.chart.series[0].setData([this.value]);
                } else {
                    this.renderGauge(this.value);
                }
            },

            renderGauge(data) {
                this.loading = false;

                const container = this.$refs.container;
                this.chart = Highcharts.chart(container, {
                    chart: {
                        type: 'solidgauge',
                        plotBorderWidth: 0,
                        borderColor: 'transparent',
                        backgroundColor: 'transparent',
                        plotBackgroundColor: 'transparent',
                        // height: this.get_body_height() - 35,
                        marginLeft: 0,
                        marginRight: 0,
                        marginTop: 0,
                        marginBottom: 0,
                        plotShadow: false,
                    },
                    title: null,
                    exporting: {
                        enabled: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                borderWidth: 0,
                                y: -35,
                                useHTML: true,
                                formatter: function() {
                                    const fixed = this.y.toFixed(1);
                                    return `${fixed}%`;
                                },
                                style: {
                                    
                                    fontSize: '31px',
                                    color: '#2c4163',
                                },
                            },
                        },
                    },
                    pane: {
                        center: ['50%', '75%'],
                        size: '100%',
                        startAngle: -90,
                        endAngle: 90,
                        borderWidth: 0,
                        borderColor: '#fff',
                        background: {
                            backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
                            innerRadius: '60%',
                            outerRadius: '100%',
                            shape: 'arc',
                        },
                    },
                    title: {
                        text: this.title,
                        align: 'center',
                        verticalAlign: 'bottom',
                        margin: 0,
                        y: 5,
                        style: {
                            
                            fontSize: '14px',
                            color: '#2c3438',
                            textOverflow: null,
                            'font-weight': "normal",
                            overflow: 'hidden',
                        },
                    },
                    yAxis: {
                        min: this.minValue,
                        max: this.maxValue,
                        stops: [
                            [0, '#7cb5ec'], //pastel blue
                            [1, '#7cb5ec']
                        ],
                        lineWidth: 0,
                        tickWidth: 0,
                        minorTickInterval: null,
                        tickAmount: 2,
                        title: {
                            y: -70
                        },
                        labels: {
                            enabled: false,
                            y: 16
                        }
                    },
                    credits: {
                        enabled: false,
                    },
                    series: [{
                        name: this.title,
                        data: [this.value],
                        dataLabels: {
                            // format: labelFormat,
                        },
                        enableMouseTracking: false,
                        tooltip: {
                            valueSuffix: ` ${this.unit}`,
                        },
                    }],
                });

                if (window.app.rootVue && window.app.rootVue.metricManager) {
                    window.app.rootVue.metricManager.registerMetric(this.metric);
                }
            },
        },

        mounted() {
            this.value = this.initialValue;
            this.minValue = this.initialMinValue;
            this.maxValue = this.initialMaxValue;

            if (this.autoLoad) {
                if (this.metric) {
                    this.fetchValue();
                } else {
                    this.renderGauge();
                }
            }
        },
    });

    export default GaugeComponent;
</script>

<style>
.highcharts-data-label-hidden{
    opacity:1 !important;
}
</style>