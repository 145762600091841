<template>
    <div style="">
        <div ref="arrowbox" class="arrow_box animated fadeIn" style="display:none;">
            <div class="pa-select">
                <select class="pa-select-menu">
                    <option value="live">Live</option>
                    <option value="15min">Past 15 min</option>
                    <option value="hour">Past hour</option>
                    <option value="day">Past 24 hours</option>
                    <option value="week">Past week</option>
                    <option value="month">Past month</option>
                </select>
                <span class="pa-select-icon"></span>
            </div>
            <div @click="close" class="pa-modal-overlay"></div>
        </div>
        <a ref="icon" @click="show"><svg class="pa-icon"><use xlink:href="#clock"></use></svg></a>
    </div>
</template>

<script>
    import Vue from 'vue';
    import $ from 'jquery';

    const WidgetHeader = Vue.extend({

        data() {
            return {
            }
        },

        events: {
        },

        methods: {
            show: function() {
                this.$refs.arrowbox.style.display = "";
                var left = (this.$refs.arrowbox.getBoundingClientRect().width/-2 + 8) + "px";
                this.$refs.arrowbox.style.left = left;
                $(this.$refs.arrowbox).find("select").one("change", this.close);
            },
            close: function(e) {
                this.$refs.arrowbox.style.display = "none";
            },
            value_change: function() {
                this.timescale = $(this.$refs.arrowbox).find("select").val();
                var override = {type: "timescale", value: this.timescale};
                window.app.rootVue.$broadcast("widget:override_change", this.$parent.id, override);
            }
        },

        props: {
            timescale: {
                type: String,
                default: ""
            }
        },

        computed: {
        },

        vueReady() {
            $(this.$refs.arrowbox).find("select").on("change", this.value_change);
        }
    });

    export default WidgetHeader;
</script>
