<style lang="scss">
.pa-banner {
    background-color: white;
    border-bottom: 2px solid #e4e6eb;
    color: #2c3438;
    position: relative;

    .pa-icon { margin-right: 16px; }
    .pa-banner_btn-close {
        position: absolute;
        bottom: 0;
        right: 20px;
        top: 0;
    }

    &.complete {
        background-color: #ceefe3;
    }

    &.inprogress {
        background-color: #c8cfed;
    }

    &.new {
        background-color: #c8cfed;
    }

    &.warning {
        background-color: #fbe5b8;
    }

    &.error {
        background-color: #eb919b;
    }
}
</style>

<template>
    <section v-if="this.showBanner" :class="`pa-banner ${status} pa-py-14 pa-px-40 pa-txt_sm pa-txt_medium`">
        <p-icon
            v-if="icon"
            className="pa-ml-auto"
            size="lg"
            :icon="icon"
        >
        </p-icon>
        <slot></slot>
        <p-button class="pa-banner_btn-close" v-if="isCloseable" variant="link" @click="handleClose">
            <p-icon
                class="pa-ml-auto"
                icon="remove"
                color="blue"
            >
            </p-icon>
        </p-button>
    </section>
</template>

<script>
import Vue from 'vue';
import { dismissDEMAsyncTask } from "../../api/services/UtilService";

export default Vue.extend({
    data: () => ({
      showBanner: true,  
    }),
    props: {
        status: {
            type: String,
            default: "default",
        },
        task: {
            type: Object,
            default: () => {},
        },
        icon: String,
        isCloseable: {
            type: Boolean,
            default: false,
        },        
    },
    methods: {
        async handleClose(){
            this.showBanner = false;
            const response = await dismissDEMAsyncTask({
                async_task_id: this.task.id,
            });
        },
    }
});
</script>
