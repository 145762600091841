<style lang="scss">

</style>

<template>
    <div class="module">
        <div ref="body" v-show="open" transition="module-expand" class="module-body pa-pb-24">
            <div class="pa-field pa-field_vertical">
                <div class="pa-field-hd">
                    <label class="pa-label">
                        Incident Tags
                    </label>
                </div>
                <div class="pa-field-ft">
                </div>
            </div>
            <div v-if="tagsLoaded" class="module-border pa-pb-6" style="text-align: left;">
                <label class="pa-option" style="padding: 10px;">
                    <input v-model="tagsType" value="any" type="radio" class="pa-option-input" />
                    <span class="pa-option-icon pa-option-icon_radio"></span>
                    <span class="pa-option-txt">
                        Match Any
                    </span>
                </label>
                <label class="pa-option">
                    <input v-model="tagsType" value="all" type="radio" class="pa-option-input" />
                    <span class="pa-option-icon pa-option-icon_radio"></span>
                    <span class="pa-option-txt">
                        Match All
                    </span>
                </label>
                <p-tags-input
                    id="incident_tags"
                    ref="tags_input"
                    name="tags[]"
                    :model.sync="tags"
                    :autocomplete="true"
                    :suggestions="allTags"
                    placeholder="Enter tags"
                >
                </p-tags-input>
                <p-hint>Hit enter or tab after each tag</p-hint>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const IncidentModule = Vue.extend({
        events: {
        },

        data() {
            return {
                ready: false,
                tags: [],
                tagsType: 'any',
                tagsLoaded: false,
            };
        },

        props: {
            options: {
                type: Object,
                'default': function() {
                    return {};
                },
            },
            open: {
                type: Boolean,
                'default': true,
            },
            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        },

        methods: {
            toggle() {
                this.open = !this.open;
            },
            sendConfig() {
                if (!this.ready) { return; }
               
                const payload = {
                    widget_id: this.editingWidget.id,
                    selection: this.tags,
                    tag_match: this.tagsType,
                };
                $.ajax({
                    url: '/dashboardv2/setWidgetIncidents',
                    method: 'POST',
                    data: payload,
                }).done(data => {
                    if (data.newConfig) {
                        this.editingWidget.config = data.newConfig;
                    }
                });

                this.$parent.updatePending(this.editingWidget.id);
                this.editingWidget.pendNewConfig();
            },

            onSelect(model) {
                this.sendConfig();
            },
        },

        watch: {          
            tags: function(val) {
                this.sendConfig();
                this.ready = true;
            },
            tagsType: function(val) {
                this.sendConfig();
            },
        },

        computed: {
            allTags: function() {
                if (window.dashboard && window.dashboard.resellerDashboard) {
                    return [];
                } else if (this.$root.customerConfig) {
                    return this.$root.customerConfig.allTags;
                } else {
                    return [];
                }
            },
        },

        vueReady() {
            const w = this.editingWidget;
            if (w.id < 0) { this.ready = true; return; }
            const cc = w.config.custom_controls;
            if (cc && cc.incident_tags && cc.incident_tags.length) {
                this.tags = w.config.custom_controls.incident_tags;
                this.tagsType = w.config.custom_controls.incident_tag_match;
            } 
            Vue.nextTick(() => {
                this.ready = true; 
                this.tagsLoaded = true;
            });
        },
    });

    export default IncidentModule;
</script>
