<style>
 .widget-table {
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
 }
 .widget-body.scorecard {
     overflow: hidden;
     height: 100%;
     margin-bottom: 20px;
 }
 .widget-body.scorecard > div {
     height: 100%;
 }
 .widget-body.scorecard #table-filters, .widget-body.scorecard #table-buttons {
     margin-bottom: 0px !important;
 }
 .widget-body.scorecard .dataTables_wrapper {
     height: 100%;
 }
 .widget-body.scorecard .dataTables_scroll {
     height: 100%;
 }
 .widget-body.scorecard .dataTables_wrapper .dataTables_paginate {
     padding-top: 10px !important;
     padding-bottom: 0px !important;
     border-top: .5px solid #edeff0;
     display: none;
 }
 .widget-body.scorecard td {
     padding-top: 5px;
     padding-bottom: 5px;
 }
 th.hidden, td.hidden {
     padding: 0;
     width: 0px !important;
 }
 .scorecard-metric {
     margin-bottom: 4px !important;

     font-family: 'Inter', sans-serif;
     font-size: 13px;
 }
 .option-tooltip {
     border-bottom: 1px dashed #cecece;
     cursor: help;
 }
 .scorecard-metric .pa-tooltip-box {
     top: 3px;
 }
 .metric-column .pa-tooltip-box-content {
     min-width: 13.125rem;
     width: initial !important;
 }
 .scorecard-table td {
     vertical-align: top !important;
     overflow: hidden;
     text-overflow: ellipsis;
 }
 .scorecard .dts_label {
    display: none !important;
 }
 .scorecard table.dataTable {
    border-collapse: collapse;
 }
 .scorecard table.dataTable thead th.hidden-column {
    border: none;
 }
 .scorecard table.dataTable thead th,
 .scorecard table.dataTable tfoot th {
    border-top: 1px solid #e6e6e6;
    box-shadow: 1px 0 0 0 #e6e6e6;
    padding: 7px;
 }
 .scorecard table.dataTable tbody tr td {
    border-left: 1px solid #e6e6e6;
    padding: 8px;
 }
 .scorecard table.dataTable tbody tr:first-of-type td {
    border-top: none !important;
 }
 .scorecard table.dataTable thead tr th {
    border-bottom: 1px solid #e6e6e6;
    box-shadow: -1px 0 0 0 #e6e6e6;
 }
 .scorecard table.dataTable thead tr th:not(.hidden) ~ th {
    border-left: none;
 }

 .scorecard table.dataTable .hidden {
    display: none;
 }
 .scorecard .status-column,
 .scorecard .short-width-column{
    /*
        This width keeps these cells as small as possible
        with a little extra padding.
    */
    text-align: center;
 }
 .scorecard .net-io-table td {
    background: white !important;
    border-left-width: 0px !important;
    border-right-width: 0px !important;
    border-top-width: 0px !important;
    border-bottom-width: 0px !important;
 }
 .scorecard tr:hover .net-io-table td {
    background: #f2f3f5 !important;
 }
.scorecard .dataTables_wrapper .dataTables_scroll div.dataTables_scrollHead {
    width: 100% !important;
}
.scorecard .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
    width: 99% !important;
    padding-bottom: 5px;
}
.scorecard .dataTables_wrapper .dataTables_scroll div.dataTables_scrollHead table {
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
}
.scorecard .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody table {
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    box-sizing: border-box;
}

</style>
<template>
    <div :id="id"
         :class="!inModal ? 'grid-stack-item' : 'modal-view'"
         :data-gs-x="localConfig.col.toString()"
         :data-gs-y="localConfig.row.toString()"
         :data-gs-width="localConfig.width.toString()"
         :data-gs-height="localConfig.height.toString()"
         :data-gs-auto-position="false"
         :data-gs-locked="true">
        <div
            :class="!inModal ? 'grid-stack-item-content' : 'modal-view-content'"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;">
                <div :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered, 'expand-mode' : !$parent.editing && isHovered && !inModal, 'in-modal': inModal}">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_9of12" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                            </span>
                            <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: textColor}"><use xlink:href="#spinner-spin-naked"></use></svg>
                            <p-tooltip2 :normal-white-space="true"> 
                                <span v-if="!isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: textColor }">
                                    <span v-if="localConfig.title">{{ localConfig.title }}</span>
                                </span>
                                <span v-if="isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate">
                                    <span v-if="localConfig.title">{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }}</span>
                            </p-tooltip2>
                        </div>
                        <div class="pa-grid-col pa-grid-col_3of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger"  v-show="!$parent.editing && isHovered && !inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#arrow-expand"></use>
                                    </svg>
                                </button>
                                <span> Expand </span>
                            </p-tooltip2>
                            <button slot="trigger"  v-show="!$parent.editing && inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                <svg class="pa-icon remove-icon">
                                    <use xlink:href="#close"></use>
                                </svg>
                            </button>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon" >
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button 
                                    v-show="showWidgetControls && !inModal" 
                                    type="button"
                                    slot="trigger"
                                    @click="cloneWidget" 
                                    class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mr-6 pa-mt-6"
                                >
                                    <p-icon icon="copy"></p-icon>
                                </button>
                                <span> Clone this Widget </span>
                            </p-tooltip2>
                        </div>
                    </div>
                </div>
                <div v-show="valid" id="body" class="widget-body scorecard" ref="body" :style="{'height': inModal && '85vh'}">
                    <p-table2 :column-renderers="columnRenderers" :column-callbacks="columnCallbacks"
                            :column-classes="{0: 'server-column', 1: 'status-column', _all: 'metric-column', 'status-column': 'short-width-column' }"
                            empty-message="No servers match your filter."
                            :paging="true" :avoid-first-table="true" scroll-y="calc(100% - 40px)" ref="data_table"
                            :ordering="true" :sort-column="sortColumnIndex" :sort-direction="sortDirection"
                            :scroller="true" :scroll-collapse="false" :defer-render="true">
                        <table slot="table" class="pa-table pa-table widget-table scorecard-table">
                            <thead id="table_headers">
                                <tr>
                                    <th :key="column" v-for="column in columns">
                                        {{column}}
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </p-table2>
                </div>
                <div v-if="needsConfig" class="widget-body" :style="{'height': inModal && '85vh'}" style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center;" ref="temp_body">
                    <div class="vertical-center">
                        <button v-if="$parent.allowEditing" @click="configure" class="pa-btn pa-btn_naked_light_grey_no_fill pa-btn_lg" style="padding: 12px 35px" type="button">
                            <svg class="pa-icon">
                                <use xlink:href="#plus-circle-outline"></use>
                            </svg>
                            <span>Configure</span>
                        </button>
                        <p class="widget-unconfigured centered">
                            Please select an instance filter.
                        </p>
                    </div>
                </div>
                <div v-if="!valid" class="widget-body widget-body_error" :style="{'height': inModal && '85vh'}" :title="error_message" ref="body">
                    <p class="pa-message pa-message_error">
                        <svg class="pa-icon pa-icon_xl"><use xlink:href="#alert-circle"></use></svg>
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Vue from 'vue';
    import $ from 'jquery';
    import widgetMixin from './../widgetMixin';

    const ScorecardWidget = Vue.extend({
        mixins: [widgetMixin],

        data() {
            return {
                content: {
                    columns: [],
                    column_order: [],
                    rows: {},
                },
                columns: [],
                columnRenderers: {
                    0: this.renderLinkColumn,
                    1: this.renderStatusColumn,
                    _all: this.renderMetricColumn,
                },
                columnCallbacks: {
                    _all: function(td) {
                        window.dashboard.$compile(td);
                    },
                },
                sortColumn: '',
                sortColumnIndex: -1,
                sortDirection: '',
                localConfig: this.config,
                inModal: false,
            };
        },

        computed: {
            pageLength: function() {
                const new_length = this.calculateTableRows();
                return new_length;
            },
        },

        events: {
            // Mixin: 'widget:new_data' -> this.initialize(config, content)
            // Mixin: 'widget:updated_data' -> this.update(content)
            // Mixin: 'widget:pend_config' -> this.pendNewConfig()
        },

        methods: {
            // Mixin: configure() -- opens configure modal
            initialize(config, content) {
                this.$refs.body.style.filter = '';
                this.localConfig = {...config};

                this.content = content;

                if (!this.content.success) {
                    return;
                }

                this.columns = content.columns;
                if (config.custom_controls.sort_column && config.custom_controls.sort_direction) {
                    this.sortColumn = config.custom_controls.sort_column;
                    this.sortDirection = config.custom_controls.sort_direction;
                    for (var i = 0; i < this.columns.length; i++) {
                        const col = this.columns[i];
                        if (col === config.custom_controls.sort_column) {
                            this.sortColumnIndex = i;
                            break;
                        }
                    }
                }
                this.$nextTick(function() {
                    this.$refs.data_table.createTable();
                    if (this.sortColumnIndex >= 0 && this.sortDirection) {
                        this.$refs.data_table.setSort(this.sortColumnIndex, this.sortDirection);
                    }
                    this.insertData();
                    this.changePageLen();
                    const columns = this.getDataPoints();
                    window.app.rootVue.$broadcast('datapoints:set_points', columns, this.content.columns, this.sortColumn, this.sortDirection);
                    // Add a span element to attach the sort icon to
                    this.$refs.data_table.dataTable.columns().iterator('column', (ctx, idx) => {
                        const header = $(this.$refs.data_table.dataTable.column(idx).header());
                        if (!header.find('span').length) {
                            header.append('<span class="sort-icon"/>');
                        }
                    });
                    this.$refs.data_table.dataTable.scroller.measure();
                    this.$refs.data_table.dataTable.columns.adjust().draw();
                });
            },

            expandSelf() {
                this.inModal = !this.inModal;
                setTimeout(() => {
                    this.update(this.content)
                }, "200");
            },

            getDataPoints() {
                return this.content.columns.slice(0);
            },

            renderLinkColumn(data, type, row) {
                if (type === 'display' && this.$parent.showLinks) {
                    if (data.acl) {
                        if (!this.$parent.userCanView(data.acl)){
                            return data.title;
                        }
                    }
                    return `<a href="${data.url}" title="${data.title}" target="_blank">${data.title}</a>`;
                } else {
                    let retVal = data.title;
                    if (typeof retVal === 'undefined') {
                        retVal = '';
                    }
                    return retVal;
                }
            },

            renderMetricColumn(data, type, row) {
                if (type === 'display') {
                    if ((!data.metrics || !data.metrics.length) && row[0] && row[0].isCloud) {
                        const serverId = row[0].serverId;
                        return `
                            <p-install-agent-link-menu
                                link-text="Install Agent"
                                instance-id="${serverId}"
                                :show-cancel="false"
                            >
                            </p-install-agent-link-menu>
                        `;
                    }
                    const category = data.category;
                    if (category === 'Bandwidth') {
                        const inOutPairs = {};
                        for (const metric of data.metrics) {
                            if (typeof inOutPairs[metric.option] === 'undefined') {
                                inOutPairs[metric.option] = {};
                            }
                            if (['bandwidth.kbytes.in', 'bytes_received_per_sec'].includes(metric.resource_textkey)) {
                                inOutPairs[metric.option].in = metric.value;
                            } else {
                                inOutPairs[metric.option].out = metric.value;
                            }
                        }
                        let unit = '';
                        if (data.unit) {
                            unit = data.unit;
                        }
                        let content = `
                            <table class="net-io-table">
                                <thead></thead>
                                <tbody>
                        `;
                        for (const interf in inOutPairs) {
                            if (inOutPairs.hasOwnProperty(interf)) {
                                const metrics = inOutPairs[interf];
                                content += `
                                    <tr>
                                        <td>
                                            <svg class="pa-icon"><use xlink:href="#arrow-up"></use></svg>
                                `;
                                if (typeof metrics.out === 'number') {
                                    const val = metrics.in.toFixed(1);
                                    content += `<span>${val}${unit}</span>`;
                                } else {
                                    content += '<span>—</span>';
                                }
                                content += '</td>';

                                content += '<td style="text-align: right;">';
                                if (typeof metrics.in === 'number') {
                                    const val = metrics.in.toFixed(1);
                                    content += `<span>${val}${unit}</span>`;
                                } else {
                                    content += '<span>—</span>';
                                }
                                content += `
                                    <svg class="pa-icon"><use xlink:href="#arrow-down"></use></svg>
                                </td>
                                `;
                                content += `
                                    <td>
                                        <span>${interf}</span>
                                    </td>
                                </tr>
                                `;
                            }
                        }
                        content += `
                            </tbody>
                        </table>
                        `;
                        return content;
                    } else {
                        let unit = '';
                        if (data.unit) {
                            unit = data.unit;
                        }
                        const metrics = data.metrics ? data.metrics.filter(m => {
                            return m.value !== null;
                        }) : [];
                        metrics.sort((a, b) => {
                            return a.value - b.value;
                        }).reverse();
                        let content = `
                            <table class="net-io-table">
                                <thead></thead>
                                <tbody>`;
                        metrics.forEach((m, index, arr) => {
                            const isLastItem = index === arr.length - 1;
                            const value = m.value.toFixed(1);
                            let tooltip = '';
                            let extraClasses = '';
                            if (m.option) {
                                tooltip = ` - ${m.option}`;
                                extraClasses = 'option-tooltip';
                            }
                            content += `<tr><td>`;
                            content += `<span><p class="scorecard-metric ">${value}${unit}${tooltip}</p></span>`;
                            content += `</td></tr>`;
                        });
                        content += `
                            </tbody>
                        </table>`;
                        return content;
                    }
                } else {
                    const values = data.metrics.map(m => {
                        return m.value;
                    });
                    return Math.max.apply(null, values);
                }
            },

            renderStatusColumn: function(data, type, row) {
                if (type !== 'display') {
                    return data.text;
                }
                if (data.text === 'UP') {
                    return '<span class="pa-badge_green pa-p-2">up</span>';
                }
                const badge = `<span class="pa-badge ${data.class}">${data.text}</span>`;
                let result = badge;
                if (data.template) {
                    result = `<a href="#" v-p-flyout-open template="${data.template}" :width="375">${badge}</a>`; // eslint-disable-line max-len

                    if (data.text === 'WARN' || data.text === 'CRITICAL') {
                        const iconClassName = data.text === 'WARN' ? 'warning' : 'critical';
                        return (
                            `<a href="#" v-p-flyout-open template="${data.template}" :width="375">
                                <svg class="pa-icon outage-icon ${iconClassName}">
                                    <use xlink:href="#${iconClassName}"></use>
                                </svg>
                            </a>`
                        );
                    }
                }
                return result;
            },

            setSort(column, direction) {
                return {
                    columns: this.content.columns,
                    column_order: this.content.column_order,
                };
            },

            insertData() {
                this.$refs.data_table.reloadTableData(this.content.rows);
            },

            changePageLen() {
                this.$refs.data_table.changePageLen(this.pageLength);
            },

            update(content) {
                this.content = content;
                if (!this.content.success) {
                    return;
                }
                this.$refs.data_table.reloadTableData(this.content.rows);
            },

            reconfig() {
                if (!this.content.success) {
                    return;
                }
                this.$refs.data_table.changePageLen(this.pageLength);
                this.$refs.data_table.dataTable.scroller.measure();
            },

            calculateTableRows() {
                if (this.localConfig.height <= 1) {
                    return 1;
                } else {
                    return (Math.round(this.localConfig.height * 1.7));
                }
            },

            getConfigModules() {
                return [
                    { type: 'p-overview-module' },
                    { type: 'p-instance-module', open: true },
                    ...(this.$parent.isScoping() ? [{ type: "p-ignore-scope-module" }] : []),
                    {
                        type: 'p-custom-module',
                        options: {
                            title: '',
                            controls: [
                                {
                                    id: 'backgroundColor',
                                    label: 'Background Color',
                                    key: 'backgroundColor',
                                    type: 'color-picker',
                                    default: '#ffffff'
                                },
                            ]
                        }
                    },
                ];
            },

            getOptionModules () {
                return [
                        // TODO: Disabled for now
                        /* { type: 'p-metric-module', options: { multiple: true } },*/
                        { type: 'p-datapoints-module', options: {
                            hideReorder: true,
                            hideToggle: true,
                        } },
                ];
            },

            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this);

                // Configuration events need to be sent on next tick, to give
                // time to Vue to update the DOM
                Vue.nextTick(() => {
                    const columns = this.getDataPoints();
                    if (columns) {
                        window.app.rootVue.$broadcast('datapoints:set_points', columns, this.content.columns, this.sortColumn, this.sortDirection);
                    }
                });
            },

            dumpState() {
                const obj = Object();
                obj.data = this.$data;
                obj.config = {...this.localConfig};
                console.log(JSON.stringify(obj));
            },

        },

        props: {
            // Mixin: id:Number, config:Object, title:String
        },

        mounted() {
            // Mixin: dispatch('widget:domready')
            this.pendNewConfig();
            if (this.id < 0) {
                this.configure();
            };
            // TODO: Remove this once non-limited
            this.localConfig.metric_textkeys = {
                agent: {
                    'system.linux': [
                        'cpu_usage:usage_percentage',
                        'memory:ram.percent',
                        'disk:usage.percent_used',
                        'bandwidth:bandwidth.kbytes.in',
                    ],
                },
            };
        },
    });

    export default ScorecardWidget;
</script>
