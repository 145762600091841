<style>
    .metric-widget {
        flex-grow: 1;
        align-self: center;
        overflow: hidden;
    }
    .metric-unit,
    .metric-availability {
        font-size: 13px;
        display: block;
        color: white;
    }
    .metric-availability {
        display: block;
        padding-top: 20px;
        color: white;
    }
    .fitty {
        display: inline-block;
        white-space: nowrap;
        line-height: 1;
        margin: 0;
    }
    .white-resizeable-icon .ui-resizable-se, .white-resizeable-icon .ui-resizable-sw {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgIDxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiLz4gICAgPHBhdGggZD0iTTIuMjUgOC4zMjV2LTUuODVjMC0uMTUuMDc1LS4yMjUuMjI1LS4yMjVIOC40Yy4wNzUgMCAuMjI1LjA3NS4yMjUuMjI1VjMuNmMwIC4wNzUtLjA3NS4yMjUtLjIyNS4yMjVMMy43NSAzLjc1djQuNTc1YzAgLjA3NS0uMDc1LjIyNS0uMjI1LjIyNWgtMS4wNWMtLjE1IDAtLjIyNS0uMDc1LS4yMjUtLjIyNXptMTcuOTI3IDEydi0xLjM1TDUuMTEgMy43NzIgMy43NSAzLjc1VjUuMWwxNS4yMjUgMTUuMjI1aDEuMjAyem0xLjE4My00LjhoLS45NmMtLjA3NSAwLS4yMjUuMDc1LS4yMjUuMjI1djQuNTc1aC00LjY1Yy0uMDc1IDAtLjIyNS4wNzUtLjIyNS4yMjV2MS4xMjVjMCAuMDc1LjA3NS4yMjUuMjI1LjIyNWg1LjkyNWMuMTUgMCAuMjI1LS4wNzUuMjI1LS4yMjVWMTUuNzVjMC0uMjI2LS4xNjUtLjIyNS0uMzE1LS4yMjV6IiBmaWxsPSIjZmZmZmZmIi8+ICA8L2c+PC9zdmc+) !important;
        margin: 6px !important;
        transform: scale(.75) !important;    
    }
</style>

<template>
    <div :id="id"
        class="grid-stack-item white-resizeable-icon"
        :data-gs-x="localConfig.col.toString()"
        :data-gs-y="localConfig.row.toString()"
        :data-gs-width="localConfig.width.toString()"
        :data-gs-height="localConfig.height.toString()"
        :data-gs-auto-position="false"
        :data-gs-locked="true">
        <div
            class="grid-stack-item-content"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div
                style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;"
                :style="hasSelectedBackgroundColor ? null : bodyStyle"
            >
                <div class="pa-m-0" :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered}">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_9of12" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <p-icon
                                    icon="drag-vertical"
                                    class="drag-icon"
                                    color="light"
                                >
                                </p-icon>
                            </span>
                            <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{ fill: (hasSelectedBackgroundColor || this.isEditingAndHovered) ? textColor : bodyStyle.color }">
                                <use xlink:href="#spinner-spin-naked"></use>
                            </svg>
                            <p-tooltip2 :normal-white-space="true"> 
                                <span slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: hasSelectedBackgroundColor ? textColor : null }">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }}</span>
                            </p-tooltip2>                            
                        </div>
                        <div class="pa-grid-col pa-grid-col_3of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>   
                            <p-tooltip2>
                                <button
                                    v-show="showWidgetControls" 
                                    type="button"
                                    slot="trigger"
                                    @click="cloneWidget" 
                                    class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mr-6 pa-mt-6"
                                >
                                    <p-icon icon="copy" ></p-icon>
                                </button>
                                <span> Clone this Widget</span>
                            </p-tooltip2>
                        </div>
                    </div>
                </div>
                <div
                    v-if="valid"
                    class="widget-body pa-mx-10 pa-flex"
                    style="overflow-y: hidden; height: 100%; background-color: inherit;"
                    ref="body"
                >
                    <div v-if="content.success" class="metric-widget">
                        <div class="metric-value-wrapper block" :style="{ color: hasSelectedBackgroundColor ? textColor : null }">
                            <span ref="metric_value" class="fitty pa-txt_medium">
                                {{ metricDisplayValue }}
                            </span>
                        </div>
                        <div v-show="displayUnitsSeparately" class="metric-unit-wrapper block" :style="{ color: hasSelectedBackgroundColor ? textColor : null }">
                            <span ref="metric_unit" class="fitty pa-txt_medium">
                                {{ content.unit }}
                            </span>
                        </div>
                        <div v-if="localConfig.custom_controls.availability" class="metric-avail-wrapper block" :style="{ color: hasSelectedBackgroundColor ? textColor : null }">
                            <span ref="metric_avail" class="fitty pa-txt_medium">
                                Availability: {{content.availability}}%
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="needsConfig" class="widget-body" style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center;" ref="temp_body">
                    <div class="vertical-center">
                        <button v-if="$parent.allowEditing" @click="configure" class="pa-btn pa-btn_naked_light_grey_no_fill pa-btn_lg" style="padding: 12px 35px" type="button">
                            <svg class="pa-icon">
                                <use xlink:href="#plus-circle-outline"></use>
                            </svg>
                            <span>Configure</span>
                        </button>
                        <p class="widget-unconfigured centered">
                            Please select a metric filter.
                        </p>
                        <p v-if="accessError" class="widget-unconfigured centered">
                            You do not have access to this metric.
                        </p>
                    </div>
                </div>
                <div v-if="!valid" class="widget-body widget-body_error" :title="error_message" ref="body">
                    <p class="pa-message pa-message_error">
                        <svg class="pa-icon pa-icon_xl"><use xlink:href="#alert-circle"></use></svg>
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import fitty from "fitty";
    import widgetMixin from './../widgetMixin';

    const SingleMetricWidget = Vue.extend({
        mixins: [widgetMixin],

        data() {
            return {
                fitty: null,
                fitty2: null,
                fitty3: null,
                content: {
                    success: true,
                },
                localConfig: this.config,
            };
        },

        events: {
        },

        watch: {
            value() {
                Vue.nextTick(() => {
                    this.initFitty();
                });
            },
        },

        props: {
            config: {
                type: Object,
                default() {
                    return {
                        data_source: 'monitor_point',
                        custom_controls: {},
                    };
                },
            },
        },

        computed: {
            displayUnitsSeparately() {
                return this.content.unit && this.content.unit.length >= 4;
            },
            metricDisplayValue() {
                let metricDisplayValue = this.content.value || 0;

                if (this.content.unit && this.content.unit.length < 4) {
                    metricDisplayValue = `${metricDisplayValue} ${this.content.unit}`;
                }

                return metricDisplayValue;
            },
            value: function() {
                return this.content.value;
            },
            bodyStyle: function() {
                if (
                    !this.content ||
                    !this.content.value ||
                    !this.content.success
                ) {
                    return {
                        backgroundColor: 'white',
                        color: 'black',
                    };
                }

                if (this.content.hasIncident) {
                    return {
                        backgroundColor: '#e31212',
                        color: 'white',
                    };
                }

                return {
                    backgroundColor: '#3cb588',
                    color: 'white',
                };
            },
        },

        methods: {
            initFitty() {
                if (!this.$refs.body || !this.$refs.metric_value) {
                    return;
                }

                if (this.localConfig.height === 1) {
                    this.fitty = null;
                    this.fitty = fitty(this.$refs.metric_value, { maxSize: 12 });
                    if (this.displayUnitsSeparately) {
                        this.fitty2 = null;
                        this.fitty2 = fitty(this.$refs.metric_unit, { maxSize: 12 });
                    }
                    if (this.localConfig.custom_controls.availability) {
                        this.fitty3 = null;
                        this.fitty3 = fitty(this.$refs.metric_avail, { maxSize: 12 });
                    }
                } else {
                    const metricWidget = this.$refs.body;

                    let maxSize = Math.min(Math.min(metricWidget.clientHeight, metricWidget.clientWidth), 512);

                    if (this.displayUnitsSeparately && this.localConfig.custom_controls.availability) {
                        maxSize = maxSize / 3;
                    } else if (this.displayUnitsSeparately || this.localConfig.custom_controls.availability) {
                        maxSize = maxSize / 2;
                    }

                    this.fitty = null;
                    this.fitty = fitty(this.$refs.metric_value, { maxSize });

                    if (this.displayUnitsSeparately) {
                        this.fitty2 = null;
                        this.fitty2 = fitty(this.$refs.metric_unit, { maxSize });
                    }
                    if (this.localConfig.custom_controls.availability) {
                        this.fitty3 = null;
                        this.fitty3 = fitty(this.$refs.metric_avail, { maxSize });
                    }
                }

                Vue.nextTick(() => {
                    this.fitText();
                });
            },
            fitText() {
                // Need to force reset the font size so it calculates
                // the size better when we call .fit()
                const resetFontSize = "font-size: 16px;";

                if (this.$el) {
                    if (this.fitty && this.fitty.element) {
                        this.fitty.element.style = resetFontSize;
                        this.fitty.fit();
                    }
                    if (this.fitty2 && this.fitty2.element &&this.displayUnitsSeparately) {
                        this.fitty2.element.style = resetFontSize;
                        this.fitty2.fit();
                    }
                    if (this.fitty3 && this.fitty3.element && this.localConfig.custom_controls.availability) {
                        this.fitty3.element.style = resetFontSize;
                        this.fitty3.fit();
                    }
                }
            },
            getConfigModules() {
                return [
                    { type: 'p-overview-module' },
                    { type: 'p-instance-metric-module' },
                ];
            },

            getOptionModules() {
                const custom_controls = [
                    {
                        label: 'Show Availability',
                        key: 'availability',
                        type: 'p-switch',
                        default: false,
                    },
                    {
                        label: 'Aggregation',
                        key: 'aggregation',
                        type: 'p-select',
                        options: [
                            {value: 'average', label: 'Average (Default)'},
                            {value: 'minimum', label: 'Min'},
                            {value: 'maximum', label: 'Max'},
                            {value: 'sum', label: 'Sum'},
                        ],
                        default: 'average',
                        timescale: this.localConfig.timescale,
                    },
                    {
                        id: 'backgroundColor',
                        label: 'Background Color',
                        key: 'backgroundColor',
                        type: 'color-picker',
                        default: '#ffffff'
                    },
                ];
                return [
                    {
                        type: 'p-custom-module',
                        options: {
                            ...(this.$parent.isScoping() ? {"ignore-scope-module": {}} : {}),
                            title: 'Metric',
                            controls: custom_controls
                        }
                    },
                    {
                        type: 'p-timerange-module',
                        options: {
                            liveOption: true,
                        }
                    },
                ];
            },

            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this);
            },

            initialize(config, content) {
                if (this.$refs && this.$refs.body) {
                    this.$refs.body.style.filter = '';
                }
                this.content = {...content};
                this.localConfig = {...config};
                this.reconfig();
            },
            reconfig() {
                if (this.content.timescale &&
                    this.content.timescale !== this.localConfig.timescale) {
                    $(this.$refs.timeOverride).show();
                } else {
                    $(this.$refs.timeOverride).hide();
                }
                Vue.nextTick(() => {
                    this.initFitty();
                });
            },
            update(content) {
                this.content = content;
                Vue.nextTick(() => {
                    this.initFitty();
                });
            },
            dumpState() {
                const obj = Object();
                obj.data = this.$data;
                obj.config = this.config;
                console.log(JSON.stringify(obj));
            },
        },
        mounted() {
            Vue.nextTick(() => {
                this.initFitty();
            });
            this.pendNewConfig();
            if (this.id < 0) { this.configure(); };
        },
    });

    export default SingleMetricWidget;
</script>
