<template>
    <div class="module">
        <div class="module-header">
            <span class="pa-txt">Instance Types</span>
        </div>
        <div ref="body" v-show="ready && open" transition="module-expand" class="module-body">
            <ul class="pa-vList pa-vList_x2">
                <li>
                    <div class="pa-field pa-field_vertical">
                        <div class="pa-field-hd">
                            <label class="pa-label">Type selection</label>
                        </div>
                        <div class="pa-field-bd">
                            <p-select
                                :model.sync="selected"
                                :multiple="true"
                                :options="resourceTypeOptions"
                                searchable
                            >
                            </p-select>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({

        data() {
            return {
                ready: true,
                resourceTypeOptions: [{
                    value: 'basic_instances',
                    label: 'Basic Instances',
                }, {
                    value: 'advanced_instances',
                    label: 'Agent Instances',
                }, {
                    value: 'network_devices',
                    label: 'Network Devices',
                }, {
                    value: 'containers',
                    label: 'Containers',
                }, {
                    value: 'clusters',
                    label: 'Kubernetes Clusters',
                }, {
                    value: 'namespaces',
                    label: 'Kubernetes Namespaces',
                }, {
                    value: 'pods',
                    label: 'Kubernetes Pods',
                }, {
                    value: 'nodes',
                    label: 'Kubernetes Nodes',
                }, {
                    value: 'persistentvolumeclaim',
                    label: 'Kubernetes Volumes',
                }, {
                    value: 'vmware_hosts',
                    label: 'VMWare Hosts',
                }, {
                    value: 'vmware_instances',
                    label: 'VMWare VMs',
                }, {
                    value: 'vmware_datastores',
                    label: 'VMWare Datastores',
                }, {
                    value: 'aws_instances',
                    label: 'AWS Instances',
                }, {
                    value: 'azure_instances',
                    label: 'Azure Instances',
                },
                ],
                selected: '',
            };
        },

        props: {
            options: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            open: {
                type: Boolean,
                default: true
            },
            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        },

        events: {},

        computed: {},

        watch: {
            'selected': function(val, oldVal) {
                if (!this.ready) { return; }
                this.sendConfig();
            },
        },

        methods: {
            noop() {},

            sendConfig() {
                if (!this.ready) { return; }

                $.ajax({
                    url: '/dashboardv2/setResourceTypes',
                    method: 'POST',
                    data: {
                        widget_id: this.editingWidget.id,
                        resource_types: this.selected,
                    },
                });
                this.$parent.updatePending(this.editingWidget.id);
                this.editingWidget.pendNewConfig();
            },

            getAvailableResourceTypes() {
                $.ajax({
                    url: '/dashboardv2/getAvailableResourceTypes',
                    method: 'GET',
                    context: this,
                    data: {
                        widgetId: this.editingWidget.id,
                    },
                })
                .done((response) => {
                    if (!response.success) { return; }

                    this.resourceTypeOptions = [...response.data];
                    this.selected = this.editingWidget.config.custom_controls.resource_types;

                    Vue.nextTick(() => {
                        this.ready = true;
                    });
                });
            },
        },

        mounted() {
            this.selected = this.editingWidget.config.custom_controls.resource_types;
        },
    });
</script>
