import 'babel-polyfill';
import App from './app';
import $ from 'jquery';
import _ from 'lodash';
import Vue from 'vue';
import modalRemove from './shared/utils/modalRemove';
import helpers from './global/helpers.js';
import polyfills from './global/polyfills.js';
import Clipboard from 'clipboard';
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import 'leaflet/dist/leaflet.css';

window.$ = $;
window._ = _;
window.Vue = Vue;
// eslint-disable-next-line camelcase
window.modal_remove = modalRemove;
window.clipboard = Clipboard;
window.helpers = helpers;

window.app = new App();
