<template>
    <ul v-if="options.length > minOptions" class="pa-hList pa-hList_x2">
        <li v-for="option in options">
            <label class="pa-pill">
                <input
                    type="checkbox"
                    :name="name"
                    :value="option.value"
                    v-model="model"
                    v-on:change="onChange($event)"
                    class="pa-pill-input" />
                <span class="pa-pill-txt">{{ option.text }}</span>
            </label>
        </li>
    </ul>
</template>

<script>
    import Vue from 'vue';

    const UIFilter = Vue.extend({
        data() {
            return {
                model: ["all"],
                previous_model: ["all"]
            }
        },

        methods: {
            onChange($event) {

                // If nothing is selected, force "All" to be selected
                if (this.model.length == 0) { this.model = ["all"]; }

                // If "all" wasn't in the previous list and it is now, force
                // the list to be only "all"
                if (this.previous_model.indexOf("all") == -1 && this.model.indexOf("all") > -1) {
                    this.model = ["all"];
                } else if (this.model.length > 1 && this.model.indexOf("all") != -1) {
                    // If they've selected "All" and something else, unselect "All"
                    this.model.splice(this.model.indexOf("all"), 1);
                }

                window.app.rootVue.$emit('uiFilter:changed', {
                    model: this.model
                });

                // Store a snapshot of the current values so we can see what changes next time around
                this.previous_model = this.model.slice(0);
            }
        },

        props: {
            name: String,

            options: {
                type: Array,
                default: () => []
            },

            // Hide the filter if there are this many options or less
            minOptions: {
                type: Number,
                default: 0
            }
        }
    });

    export default UIFilter;
</script>
