<template>
    <th
        :data-search="searchText"
        :colspan="model.colspan"
        :class="{
            'pa-table-row-col_grouped': model.colspan
        }">

        <div
            v-if="!model.type"
            v-html="model.text"></div>

        <label
            v-if="model.type === 'checkbox'"
            class="pa-option">
            <input
                :name="model.name"
                type="checkbox"
                class="pa-option-input"
                v-model="model.isChecked"
                v-on:change="onChange()" />
            <span class="pa-option-icon"></span>
            <span class="pa-option-txt">{{ model.text }}</span>
        </label>
    </th>
</template>

<script>
    import Vue from 'vue';

    var TableHead = Vue.extend({
        created() {
            this.searchText += `${this.model.alias || this.model.text} `;
        },

        data() {
            return {
                searchText: ''
            }
        },

        events: {
            'table:tcell:option:changed': function(details) {
                if (this.model.type !== 'checkbox' || details.isChecked || !this.model.isChecked) {
                    return;
                }

                this.model.isChecked = false;
            }
        },

        props: {
            id: {
                type: String,
                default: ''
            },

            inThead: {
                type: Boolean,
                default: false
            },

            model: {
                type: Object,
                default: () => {}
            }
        },

        methods: {
            onChange() {
                this.eventHub.$emit('thead:option:changed', {
                    model: this.model,
                    inThead: this.inThead
                });
            }
        }
    });

    export default TableHead;
</script>
