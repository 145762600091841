<template>
    <ul id="chip-list" class="pa-hList pa-mt-0">
        <li
            :key="index"
            v-for="(chip, index) in filteredChips"
            class="pa-pr-4 pa-mt-0 pa-pt-8"
        >
            <template v-if="chip.hover_options">
                <p-flyout
                    scroll-lock
                    hover
                    :hover-timeout="0"
                    rounded
                    positioning="popper"
                    popper-direction="top"
                    variant="black"
                    no-arrow
                    :width="300"
                >
                    <p-chip
                        slot="trigger"
                        :chip="chip"
                        :deleteCallback="deleteCallback">
                    </p-chip>
                    <div slot="content">
                        {{ chip.hover_options }}
                    </div>
                </p-flyout>
            </template>
            <template v-else>
                <p-chip-grey-box
                    :chip="chip"
                    v-if="variant == 'grey-box'"
                    :deleteCallback="deleteCallback"
                >
                </p-chip-grey-box>
                <p-chip
                    v-else
                    :chip="chip"
                    :deleteCallback="deleteCallback">
                </p-chip>
            </template>
        </li>
        <li v-if="showOverflowTooltip && chips.length > maxChips">
            <p-flyout :no-arrow="true">
                <p-link
                    :add-pointer="true"
                    slot="trigger"
                    class="pa-txt_sm pa-pb-6"
                >

                    <span>
                        + {{ chips.length - maxChips }} more
                    </span>                    
                </p-link>
                <ul id="chip-list" class="pa-hList pa-mt-0" slot="content">
                    <li
                        :key="index"
                        v-for="(chip, index) in overflowChips"
                        class="pa-pr-4 pa-mt-0 pa-pt-8"
                    >
                        <p-chip-grey-box
                            :chip="chip"
                            v-if="variant == 'grey-box'"
                            :deleteCallback="deleteCallback"
                        >
                        </p-chip-grey-box>
                        <p-chip
                            v-else
                            :chip="chip"
                            :deleteCallback="deleteCallback">
                        </p-chip>
                    </li>
                </ul>
            </p-flyout>
        </li>
    </ul>
</template>

<script>
import Vue from 'vue';
import ChipGreyBox from './ChipGreyBox.vue'

export default Vue.extend({
    props: {
        chips: Array,
        maxChips: Number,
        deleteCallback: Function,
        variant: {
            type: String,
            default: null,
        },
        showOverflowTooltip: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        'p-chip-grey-box': ChipGreyBox,
    },

    computed: {
        filteredChips() {
            if (this.maxChips) {
                return this.chips.slice(0, this.maxChips);
            } else {
                return this.chips
            }
        },

        overflowChips() {
            return this.chips.slice(this.maxChips);
        },
    }
});
</script>