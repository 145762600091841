<style lang="scss">

</style>

<template>
    <div>
        <div v-for="alert in alert_items" style="margin-bottom: 0px; position: relative;" class="alert-block">
            <p-tooltip v-if="alert[4].length"
                       :advanced="true"
                       direction="right"
                       :hover="true"
                       :hover-timeout="300"
                       :hover-on-click="$parent.showCountermeasure.bind(null, m, alert[4][0][0], alert[4][0][1][0][0])">
                <span slot="trigger">
                    <p-icon icon="beaker" middle color="green"></p-icon>
                </span>
                <div class="countermeasure-timeline">
                    <div class="countermeasure">
                        <div class="dot"></div>
                        <span>Incident Detected</span>
                    </div>
                    <div class="line"></div>
                    <template v-for="countermeasure in alert[4]">
                        <div class="countermeasure">
                            <div class="dot"></div>
                            <span class="delay">{{ countermeasure[0] / 60 }}min</span>
                            <div class="names" :class="{ 'intermediate': $index < alert[4].length - 1 }">
                                <p v-for="cm in countermeasure[1]" class="name"
                                   :style="{ color: cm[2] === 1 ? 'red': 'rgba(0, 0, 0, 0.86)' }">
                                    {{ cm[1] }}
                                </p>
                            </div>
                        </div>
                    </template>
                </div>
            </p-tooltip>
            <span></span>
            <span :class="`pa-badge pa-badge_${alert[0]}_naked pa-badge_sm`">
                {{alert[1]}}
            </span>
            <template v-if="alert[2].length">
                to <span v-html="alert[2]"></span>
            </template>
            when <span v-html="alert[3]"></span>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        iAlertItems: String,
    },

    data() {
        return {
            alert_items: [],
        };
    },

    mounted() {
        this.alert_items = JSON.parse(this.iAlertItems);
    }
});
</script>
