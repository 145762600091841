<style lang="scss">
    $prepend-select-color: #E4E6EB;
    $prepend-border-color: #d0d3db;

    .pa-field {
        p:not(:last-child) {
            margin-bottom: 0;
        }

        .pa-field-prepend-select {
            display: flex;

            .pa-select:first-child {
                padding-left: 8px;
                background-color: $prepend-select-color;
                border-color: $prepend-border-color;
                border-radius: 4px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }

            .pa-input {
                border-color: $prepend-border-color;
                border-radius: 4px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-left: none;
            }
        }

        .pa-field-has-header-actions {
            display: flex;
            justify-content: space-between;
        }
    }
</style>

<template>
    <div
        class="pa-field pa-mb-12"
        :class="{
            'pa-flex': inline,
            'pa-flex-row': inline,
            'pa-align-start': inline,
        }"
    >
        <div v-if="label" :class="computedHeaderClass">
            <label :for="name" class="pa-label">
                <p-icon
                    v-if="icon"
                    :icon="icon"
                    :color="iconColor"
                >
                </p-icon>
                {{ label }}
                <p-tooltip2 v-if="showLabelTooltip" :width="350" class="inline">
                    <p-icon
                        slot="trigger"
                        icon="question-circle-outline"
                        size="xsm"
                        :color="iconColor"
                    >
                    </p-icon>
                    <p class="pa-txt">
                        {{ labelTooltip }}
                    </p>
                </p-tooltip2>
            </label>
            <div class="pa-field-header-actions">
                <slot name="header-actions"></slot>
            </div>
        </div>
        <div v-if="showHint('top')" class="pa-my-8">
            <p-hint :hint="hint"></p-hint>
        </div>
        <div :class="computedSlotClass" :style="computedSlotStyle">
            <slot></slot>
            <p v-if="error" class="pa-mt-8 pa-txt_13 pa-txt_crimson">
                {{ renderError }}
            </p>
        </div>
        <div v-if="showHint('bottom')" class="pa-field-ft pa-pb-0">
            <p-hint :hint="hint"></p-hint>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';

    const HINT_POSITIONS = ['top', 'bottom'];

    const FieldGroup = Vue.extend({
        props: {
            error: [String, Array],
            name: String,
            label: String,
            hint: String,
            slotClass: String,
            slotStyle: String,
            showLabelTooltip: Boolean,
            labelTooltip: String,
            icon: String,
            iconColor: {
                type: String,
                default: 'black'
            },
            hintPosition: {
                type: String,
                default: 'bottom',
                validator: position => {
                    return HINT_POSITIONS.includes(position);
                },
            },
            prependSelect: Boolean,
            inline: Boolean,
        },
        computed: {
            computedHeaderClass() {
                const classNames = {
                    "pa-field-hd": true,
                    "pa-field-has-header-actions": !!this.$slots["header-actions"],
                    "pa-pt-6": this.inline,
                    "pa-pr-8": this.inline,
                    "pa-grid-col pa-grid-col_4of12": this.inline,
                    "pa-align-center": !this.inline,
                    "pa-align-start": this.inline,
                };

                return classNames;
            },
            renderError: function() {
                if (_.isArray(this.error)) {
                    return this.error.join(' ');
                } else {
                    return this.error;
                }
            },
            computedSlotClass: function() {
                const classNames = {
                    "pa-field-bd": true,
                    "pa-field-error": this.hasErrors(),
                    "pa-field-prepend-select": this.prependSelect,
                    "pa-flex-grow-1": this.inline,
                };

                if (this.slotClass) {
                    classNames[this.slotClass] = true;
                }

                return classNames;
            },
            computedSlotStyle: function() {
                return this.slotStyle;
            },
        },
        methods: {
            hasErrors: function() {
                let err = null;

                if (_.isArray(this.error)) {
                    // Filter empty results
                    err = this.error.filter(_error => _error);
                } else if (_.isString(this.error)) {
                    err = this.error.trim();
                }

                if (err && err.length > 0) {
                    return true;
                }

                return false;
            },
            showHint: function(hintPosition) {
                return this.hint && this.hintPosition === hintPosition;
            },
        },
    });

    export default FieldGroup;
</script>
