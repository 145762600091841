<template>
    <li class="incident-box">
        <div class="header">
            <div>
                <svg class="pa-icon severity-icon" :class="incident.severity">
                    <use :xlink:href="`#${incident.severity}`"></use>
                </svg>
                <div class="inline-block" style="vertical-align: middle;">
                    <p class="severity">{{ title }}</p>
                    <a target="_blank" :href="`/outage/Outage?outage_id=${incident.alert_id}`">
                        <p class="id">ID: {{ incidentId }}</p>
                        <svg class="pa-icon open-in-new">
                            <use xlink:href="#open-in-new"></use>
                        </svg>
                    </a>
                </div>
                <p-menu>
                    <button slot="trigger" class="pa-btn pa-btn_sm naked_blue take-action">
                        <p-icon icon="lightning">
                        </p-icon>
                        Take Action
                    </button>
                    <a @click="openAcknowledgeDrawer(incident.alert_id)">
                        Acknowledge
                    </a>
                </p-menu>
            </div>
        </div>

        <div class="incident-row">
            <div>
                <p class="label">Service:</p>
                <p>{{ incident.description }}</p>
            </div>
        </div>

        <div class="incident-row">
            <div style="display: inline-block;">
                <p class="label">Start time:</p>
                <p>{{ startTime }}</p>
            </div>

            <div style="display: inline-block;">
                <p class="label">Duration:</p>
                <p>{{ moment.utc(incident.start_time*1000).toNow(true) }}</p>
            </div>
        </div>

        <div class="incident-row">
            <div style="display: inline-block;">
                <p class="label">Next Alert</p>
                <p>{{ incident.next_alert_time || 'None' }}</p>
            </div>

            <div style="display: inline-block;">
                <p class="label">Next Countermeasure</p>
                <p>
                    {{
                        incident.next_countermeasure
                        ? `${incident.next_countermeasure.name} @ ${incident.next_countermeasure.execute_time}`
                        : 'None'
                    }}
                </p>
            </div>
        </div>

        <p-expando title="Incident Log">
            <div class="incident-log">
                <template v-for="(log, i) in logs" class="log-entry">
                    <div v-if="showDate" class="date">
                        <span>{{ log.date }}</span>
                    </div>

                    <div class="log-entry">
                        <span>{{ log.time }}</span>
                        <span><p v-html="logEntry.message"></p></span>
                    </div>
                </template>
            </div>
        </p-expando>
    </li>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';
    import moment from 'moment';

    export default Vue.extend({
        props: {
            incident: Object,
        },
        computed: {
            logs() {
                return this.incident.logs.map((log, i, arr) => {
                    const isFirst = i === 0;
                    const currentDate = moment.utc(log.timestamp).tz(log.timezone).format('D');
                    const previousDate = moment.utc(arr[i-1].timestamp).tz(log.timezone).format('D');

                    return {
                        ...log,
                        showDate: isFirst || currentDate !== previousDate,
                        date: moment.utc(log.timestamp).tz(log.timezone).format('M-D-YYYY (z)'),
                        time: moment.utc(log.timestamp).tz(log.timezone).format('hh:mm'),
                    };
                });
            },
            title() {
                return `${_.capitalize(this.incident.severity)} Incident`;
            },
            incidentId() {
                const prefix = this.incident.alert_id < 0 ? 'SRA' : 'SO';
                const id = Math.abs(this.incident.alert_id);

                return `${prefix}-${id}`;
            },
            startTime() {
                return moment.utc(this.incident.start_time*1000).tz($parent.timezone).format('YYYY-M-D hh:mm z');
            },
        },
        methods: {
            openAcknowledgeDrawer(id) {
                window.app.rootVue.$broadcast(
                    'acknowledge-incident-drawer:open',
                    { incidentIds: id }
                );
            },
        },
    });
</script>
