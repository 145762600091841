var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pa-copy-script",class:{
        'pa-copy-script--secondary': _vm.variant === 'secondary',
        'pa-copy-script--no-title': !_vm.title,
    }},[(!!_vm.title || _vm.hasTitleSlot)?_c('p-flex',{staticClass:"pa-copy-script-header pa-py-8",class:{
            'pa-copy-script-header--secondary': _vm.variant === 'secondary',
            'pa-px-24': _vm.variant === 'primary',
            'pa-px-0': _vm.variant === 'secondary',
        },attrs:{"space-between":"","align-center":""}},[(!_vm.hasTitleSlot)?_c('h5',{staticClass:"pa-txt_medium pa-txt_13"},[_vm._v("\n            "+_vm._s(_vm.title)+"\n        ")]):_vm._e(),_vm._v(" "),_vm._t("script-title"),_vm._v(" "),(_vm.uniqueId)?_c('p-button',{ref:"copyBtn",staticClass:"pa-ml-8",attrs:{"variant":"blue-secondary","data-clipboard-target":`#${_vm.uniqueId}`},on:{"click":_vm.toggleCopied}},[_c('p-flex',{attrs:{"align-center":""}},[(_vm.variant === 'secondary')?_c('p-icon',{staticClass:"pa-mr-8",attrs:{"icon":"copy"}}):_vm._e(),_vm._v("\n                "+_vm._s(_vm.isCopied ? "Copied" : "Copy")+"\n            ")],1)],1):_vm._e()],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pa-copy-script-body pa-py-16 pa-px-24",style:(_vm.computedBodyStyle)},[_c('pre',{style:(_vm.computedPreStyle)},[_c('code',{staticClass:"pa-txt_13",attrs:{"id":_vm.uniqueId},domProps:{"innerHTML":_vm._s(_vm.script)}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }