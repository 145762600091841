<template>
    <p-menu :close-box-on-click-item="false">
        <button
            type="button"
            slot="trigger"
            class="pa-filterItem"
            :disabled="disabled">
            {{ text }}
            <span class="pa-filterItem-icon">
                <svg class="pa-icon pa-icon_xxxl pa-icon_block">
                    <use xlink:href="#menu-down"></use>
                </svg>
            </span>
        </button>
        <div class="pa-filter">
            <div v-if="searchable" class="pa-filter-box">
                <input
                    type="search"
                    placeholder="search"
                    v-model="filterText"
                    class="pa-input pa-input_stretch" />
            </div>
            <template v-for="optgroup in _filterOptgroupsByText(optgroups, filterText)">
                <span class="pa-filter-hdg">{{ optgroup.title }}</span>
                <ul class="pa-filter-list">
                    <li v-for="option in _filterOptionsByText(optgroup.options, filterText)">
                        <label class="pa-option">
                            <input
                                type="checkbox"
                                class="pa-option-input"
                                :name="option.name"
                                :value="option.value" />
                            <span class="pa-option-icon pa-option-icon_sm"></span>
                            <span class="pa-option-txt">{{ option.text }}</span>
                        </label>
                    </li>
                </ul>
            </template>
        </div>
    </p-menu>
</template>

<script>
    import Vue from 'vue';
    import { stringContains } from './../utils/filterUtils';

    const menuMixin = require('../mixins/menuMixin');

    const FilterMenu = Vue.extend({
        mixins: [menuMixin],

        data() {
            return {
                filterText: '',
            }
        },

        methods: {
            _filterOptgroupsByText(optgroups = [], text = '') {
                if (text.trim() === '') {
                    return optgroups;
                }

                return optgroups.filter(optgroup => {
                    return this._optgroupContainsText(optgroup, text);
                });
            },

            _filterOptionsByText(options = [], text = '') {
                if (text.trim() === '') {
                    return options;
                }

                return options.filter(option => {
                    return this._optionContainsText(option, text);
                });
            },

            _optionContainsText(option = {}, text = '') {
                if (text.trim() === '') {
                    return true;
                }

                return stringContains(option.text, text);
            },

            _optgroupContainsText(optgroup = [], text = '') {
                if (text.trim() === '') {
                    return true;
                }

                return optgroup.options.filter((option) => {
                    return this._optionContainsText(option, text);
                }).length > 0;
            },
        },

        props: {
            optgroups: {
                type: Array,
                default: () => [],
            },

            searchable: Boolean,
        },
    });

    export default FilterMenu;
</script>
