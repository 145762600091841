<template>
   <div class="webhook-sidebar pa-p-24">
        <p class="parameters-title pa-txt_bold">Parameters</p>
        <p class="pa-txt" style="margin-bottom: 12px;">The following template parameters may be used in the "value" fields below.</p>
        <p-expando
            id="server-attr-list"
            title="Server Attributes"
            body-class="pa-px-0 pa-expando_naked"
            header-class="pa-mx-0 pa-txt pa-txt_bold"
            class="pa-mb-12"
            chevronColor="grey-700"
        >
            <template v-for="attr in serverAttrs">
                <p class="pa-txt pa-txt_bold" >${{attr}}</p>
            </template>
        </p-expando>
        <p class="pa-txt"><span class="pa-txt_bold">$alert_label</span> — Alert label of the incident/anomaly.</p>
        <p class="pa-txt" v-if="webhook"><span class="pa-txt_bold">$alert_timeline</span> - The name of the alert timeline used for the incident.</p>
        <p class="pa-txt" v-if="webhook"><span class="pa-txt_bold">$alert_timeline_id</span> - The id of the alert timeline used for the incident.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$compound_service_id</span> — The ID number of the compound metric affected.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$custom_attribute</span> — You can pass custom server attributes that are set on your servers. Use the attribute type as the key.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$duration</span> — The duration of incidents/anomalies which will be filled in on-clear.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$event</span> — The type of event, either "incident event" or "clear event".</p>
        <p class="pa-txt"><span class="pa-txt_bold">$fqdn</span> — Fully qualified domain name of the server experiencing the incident/clear.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$short_fqdn</span> — First element of the fully qualified domain name of the server experiencing the incident, split on "<code>.</code>".</p>
        <p class="pa-txt" v-if="salesforce"><span class="pa-txt_bold">$incident_end_time</span> — UTC timestamp of when the incident was cleared.</p>
        <p class="pa-txt" v-if="webhook"><span class="pa-txt_bold">$incident_lead</span> - The incident lead's email address, if set.</p>
        <p class="pa-txt" v-if="salesforce"><span class="pa-txt_bold">$incident_start_time</span> — UTC timestamp of when the incident occured.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$incident_summary</span> — The summary of the incident.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$incident_tags</span> — The tags for the incident.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$incident_timeline</span> — The entire timeline output for the incident.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$item_type</span> — The service type textkeys of the services experiencing the incident/clear, or plugin_textkey/resource_textkey combinations of the resources the experiencing the anomaly/clear, or the OID name of SNMP resources experiencing the outage/clear.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$items</span> — Services experiencing the incident/clear or resources experiencing the anomaly/clear.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$location</span> — Comma-separated list of the primary monitoring probe names for all network services affected.</p>
        <p class="pa-txt" v-if="webhook"><span class="pa-txt_bold">$message</span> — For ack and broadcast payloads, the message that was sent.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$metric_tags</span> — The tags for all of the metrics involved in the outage.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$metric_category</span> — Name of the Metric Category involved in the outage.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$metric_sub_category</span> — Name of the Metric Sub Category  involved in the outage.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$name</span> — Name of the server experiencing the incident/clear.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$short_name</span> — First element of name of the server experiencing the incident, split on "<code>.</code>".</p>
        <p class="pa-txt"><span class="pa-txt_bold">$network_service_id</span> — The ID number of the network service affected.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$outage_id</span> — The ID number of the associated incident.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$partner_server_id</span> — The partner server id for the server.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$reasons</span> — The reasons for network service incidents or the details for anomalies.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$resource</span> — For resource anomalies: resources experiencing the anomaly/clear.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$server_id</span> — The ID number of the server experiencing the incident/clear.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$server_key</span> — The server key for the server.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$server_group</span> — The group in which the server belongs to.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$complete_server_group</span> — The full path of the group in which the server belongs to.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$server_resource_id</span> — The ID number corresponding to the resource affected.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$services</span> — For service incident: services experiencing the incident/clear.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$severity</span> — Severity of the outage/anomaly, either "critical" or "warning".</p>
        <p class="pa-txt"><span class="pa-txt_bold">$severity_number</span> — Severity of the outage/anomaly, either "1" for a critical incident or "2" for a warning.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$tags</span> — The tags for the server.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$timestamp</span> — Timestamp (in your default timezone) of when the incident/clear occurred.</p>
        <p class="pa-txt"><span class="pa-txt_bold">$trigger</span> — The type of event that triggered this payload (outage, ack, broadcast, clear)</p>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            serverAttrs: {
                type: Array,
                default: [],
            },
            webhook: {
                type: Boolean,
                default: false,
            },
            salesforce: {
                type: Boolean,
                default: false,
            },
        },
    });
</script>

