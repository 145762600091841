<template>
    <div class="pa-tabbedPanels-tabs">
        <div class="pa-tabs">
            <slot />
        </div>
    </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({});
</script>
