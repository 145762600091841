<template>
    <div :id="id" class="pa-input pa-input_taggle" :class="[`pa-input_taggle-${ inputClass }`, { disabled: disabled }]">
        <div v-if="caseControl" class="case-control" :class="{ 'case-sensitive': iCaseSensitive }"  @click.stop="toggleCaseSensitive">
            <p-tooltip2>
                <svg slot="trigger" class="pa-icon pa-icon_lg">
                    <use xlink:href="#letter-case"></use>
                </svg>
                <p class="pa-txt" style="white-space: nowrap;">{{ iCaseSensitive ? 'Case Sensitive' : 'Case Insensitive' }}</p>
            </p-tooltip2>
        </div>
    </div>
</template>

<script>
    import Awesomplete from './../vendor/awesomplete/awesomplete.js';
    import Vue from 'vue';
    import Taggle from 'taggle';
    import _ from 'lodash';

    let COUNT = -1;

    // TODO: move this to an application constant
    const EVENTS = {
        AWESOMPLETE_OPEN: 'awesomplete-open',
        AWESOMPLETE_CLOSE: 'awesomplete-close',
        AWESOMPLETE_HIGHLIGHT: 'awesomplete-highlight',
        AWESOMPLETE_SELECT_COMPLETE: 'awesomplete-select'
    };

    let TagsInput = Vue.extend({
        beforeDestroy() {
            this.disable();
        },

        data() {
            return {
                iModel: _.clone(this.model),
                iCaseSensitive: this.caseSensitive,
                iSuggestions: _.clone(this.suggestions),
                fireUpdates: true,
                localAutocomplete: this.autocomplete,
            };
        },

        props: {
            autocomplete: Boolean,

            id: {
                type: String,
                default: function() {
                    COUNT++;

                    return 'taggle-' + COUNT;
                }
            },

            model: {
                type: Array,
                default: () => []
            },

            name: {
                type: String,
                default: 'taggles[]'
            },

            suggestions: {
                type: Array,
                default: () => []
            },

            inputClass: {
                type: String,
                default: () => ""
            },

            placeholder: {
                type: String,
                default: () => "Hit enter or tab after each tag"
            },

            repositionOnOpen: {
                type: Boolean,
                default: false,
            },

            onChangeCallback: {
                type: Function,
                default: () => { },
            },

            caseControl: Boolean,

            caseSensitive: Boolean,

            disabled: Boolean,

            tagClasses: {
                type: String,
                default: '',
            },

            saveOnBlur: {
                type: Boolean,
                default: true,
            },
        },

        methods: {
            addTag(tag) {
                this.taggle.add(tag);
            },

            enable() {
                if (this.repositionOnOpen) {
                    this.$el.addEventListener(
                        EVENTS.AWESOMPLETE_OPEN,
                        this.onAwesompleteOpen
                    );
                }

                this.$el.addEventListener(
                    EVENTS.AWESOMPLETE_CLOSE,
                    this.onAwesompleteClose
                );

                this.$el.addEventListener(
                    EVENTS.AWESOMPLETE_HIGHLIGHT,
                    this.onAwesompleteHighlight
                );

                this.$el.addEventListener(
                    EVENTS.AWESOMPLETE_SELECT_COMPLETE,
                    this.onAwesompleteSelectComplete
                );
            },

            deleteTag(tag) {
                this.taggle.remove(tag);
            },

            deleteAllTags() {
                this.taggle.removeAll();
            },

            refreshContent: function() {
                for (let i = 0; i < this.iModel.length; i++) {
                    const tag = this.iModel[i];
                    if (!this.model.includes(tag)) {
                        this.deleteTag(tag);
                    }
                }

                for (let i = 0; i < this.model.length; i++) {
                    const tag = this.model[i];
                    if (this.iModel.includes(tag)) {
                        continue;
                    }
                    this.addTag(tag);
                }
            },

            toggleCaseSensitive() {
                this.iCaseSensitive = !this.iCaseSensitive;
                this.$emit('update:case-sensitive', this.iCaseSensitive);
            },

            disable() {
                if (!this.localAutocomplete) {
                    return;
                }

                if (this.reposititionOnOpen) {
                    this.$el.removeEventListener(
                        EVENTS.AWESOMPLETE_OPEN,
                        this.onAwesompleteOpen
                    );
                }

                this.$el.removeEventListener(
                    EVENTS.AWESOMPLETE_CLOSE,
                    this.onAwesompleteClose
                );

                this.$el.removeEventListener(
                    EVENTS.AWESOMPLETE_HIGHLIGHT,
                    this.onAwesompleteHighlight
                );

                this.$el.removeEventListener(
                    EVENTS.AWESOMPLETE_SELECT_COMPLETE,
                    this.onAwesompleteSelectComplete
                );
            },

            setAwesompleteList(suggestionToExclude) {
                // filter suggestions by whether or not they appear in the model
                // as to avoid from showing previously added suggestions
                const filteredSuggestions = this.iSuggestions.filter((suggestion) => {
                    // lowercase the suggestion, because taggle does the same with its values
                    return this.iModel.indexOf(suggestion.toLowerCase()) === -1;
                });

                const indexToDelete = filteredSuggestions.indexOf(suggestionToExclude);

                if (indexToDelete !== -1) {
                    // remove the provided suggestion
                    filteredSuggestions.splice(indexToDelete, 1);
                }

                if (this.localAutocomplete) {
                    this.awesomplete.list = filteredSuggestions;
                }
            },

            onAwesompleteOpen(event) {
                // Used when positionOnOpen is true
                // We want to manually reposition awesomplete
                if (!this.awesomplete) { return; }
                const inputRect = this.awesomplete.input.getBoundingClientRect();
                const ul = this.awesomplete.ul;
                ul.style.top = `${inputRect.y + inputRect.height}px`;
            },

            onAwesompleteClose(event) {
                this.suggestion = '';
            },

            onAwesompleteHighlight(event) {
                this.suggestion = event.text.value;
                this.taggleInput.value = this.suggestion;
            },

            onAwesompleteSelectComplete(event) {
                const suggestion = event.text.value;

                // clear suggestion so taggle adds our suggestion
                this.suggestion = '';
                this.taggle.add(suggestion);
                this.setAwesompleteList(suggestion);

                // timeout necessary to wait for taggle to add the above suggestion
                setTimeout(() => {
                    this.taggleInput.value = '';
                });
            },

            onBeforeTagAdd(event, tag) {
                if (!this.suggestion) {
                    return true;
                }

                // don't add tags that are suggested
                // because then two tags will be added
                return !tag.includes(this.suggestion.toLowerCase());
            },

            onTagAdd(event, tag) {
                if (!this.iModel.includes(tag)) {
                    this.iModel.push(tag);
                }

                if (this.fireUpdates) {
                    // Make sure not to expose our actual internal array
                    this.$emit('update:model', _.clone(this.iModel));
                    this.onChangeCallback(this.iModel);
                }
            },

            onTagRemove(event, tag) {
                var index = this.iModel.indexOf(tag);

                this.iModel.splice(index, 1);
                this.setAwesompleteList();

                if (this.fireUpdates) {
                    // Make sure not to expose our actual internal array
                    this.$emit('update:model', _.clone(this.iModel));
                    this.onChangeCallback(this.iModel);
                }
            },

            initializeAwesomeplete() {
                if (this.awesomplete) {
                    return;
                }
                this.awesomplete = new Awesomplete(this.taggleInput, {
                    list: this.iSuggestions
                });
            },
        },

        watch: {
            model(val) {
                if (this.taggle) {
                    this.fireUpdates = false;
                    this.refreshContent();
                    this.fireUpdates = true;
                }
            },

            disabled(val) {
                if (!this.taggle) {
                    return;
                }

                if (val) {
                    this.taggle.disable();
                } else {
                    this.taggle.enable();
                }
            },

            iSuggestions(val) {
                if (this.awesomplete) {
                    this.awesomplete.list = val;
                }
            },
        },

        vueReady() {
            this.taggle = new Taggle(this.$el, {
                tags: this.iModel,
                hiddenInputName: this.name,
                onBeforeTagAdd: this.onBeforeTagAdd,
                onTagAdd: this.onTagAdd,
                onTagRemove: this.onTagRemove,
                duplicateTagClass: 'bounce',
                placeholder: this.placeholder,
                preserveCase: true,
                additionalTagClasses: this.tagClasses,
                delimeter: null,
                submitKeys: [9, 13],
                saveOnBlur: this.saveOnBlur,
            });
            if (this.disabled) {
                this.taggle.disable();
            }

            this.taggleInput = this.taggle.getInput();


            // Taggle sets the input's tabindex to 1
            // Allow the user to override
            if (this.tabIndex !== 1 && this.taggleInput) {
                this.taggleInput.tabIndex = this.tabIndex;
            }


            if (this.localAutocomplete) {
                this.initializeAwesomeplete();

                this.enable();
            }


            if (!this.iSuggestions.length
                    && this.$root.customerConfig) {
                this.iSuggestions = this.$root.customerConfig.allTags;
                this.eventHub.$on('customerConfig:update', () => {
                    this.iSuggestions = this.$root.customerConfig.allTags;
                });
                this.localAutocomplete = true;
                this.initializeAwesomeplete();
            }
        }
    });

    export default TagsInput;
</script>
