<style lang="scss" scoped>
    .pa-badge_chip {
        padding: 4px 7px;

        .close {
            display: none;
        }

        &:hover {
            cursor: pointer;
            .close {
                display: initial;
            }
        }
    }
    span {
        font-size: 13px;
    }
</style>

<template>
    <div 
        class="pa-grid pa-align-center pa-badge pa-badge_filter pa-badge_chip"
        @click="deleteCallback(chip)"
    >

        <p-icon
            v-if="chip.icon"
            :icon="chip.icon.name"
            :size="chip.icon.size ? chip.icon.size : null"
            class="pa-mr-4"
        >
        </p-icon>

        <span>
            {{ chip.label }}
        </span>

        <p-button
            v-if="!noRemove"
            class="pa-ml-8"
            unstyled
            no-base-class
        >
            <p-icon icon="critical-circle" size="sm" middle></p-icon>
        </p-button>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        chip: Object,
        noRemove: Boolean,
        deleteCallback: Function,
    },


});
</script>