const documentElement = document.documentElement;

export default function isComponentInModal(component) {
    const $el = component.$el;
    let parentNode = $el.parentNode;
    let inModal = false;
    let inDrawer = false;

    while (parentNode && parentNode !== documentElement
            && inModal === false && inDrawer === false) {
        inModal = parentNode.classList.contains('pa-modal') ? true : false;
        inDrawer = parentNode.classList.contains('pa-drawer') ? true : false;
        parentNode = parentNode.parentNode;
    }

    return inModal || inDrawer;
}
