<template>
    <ul class="template-list pa-hList pa-mt-4">
        <slot></slot>
        <li v-if="!templates || templates.length === 0" class="pa-txt_13 pa-ml-0 pa-mt-0">
            None
        </li>
        <li :key="template.id" v-for="template in filteredTemplates" class="pa-pr-12 pa-mt-0 pa-mb-8">
            <p-template-list-item
                :template="template"
                :instance-id="instanceId"
                :can-edit-instance="canEditInstance"
            >
            </p-template-list-item>
        </li>
    </ul>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        templates: Array,
        maxTemplates: Number,
        instanceId: [String, Number],
        canEditInstance: Boolean,
    },
    computed: {
        filteredTemplates: function() {
            if (this.maxTemplates && this.templates && this.templates.length) {
                return this.templates.slice(0, this.maxTemplates);
            } else {
                return this.templates;
            }
        },
    },
});
</script>