<style>
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(-360deg);
        }
    }
    .loading-spinner {
        animation: spin infinite 3s linear;
    }
    .fortinet .loading-spinner {
        color: #DA291C;
        fill: #DA291C;
    }
</style>

<template>
    <p-icon class="loading-spinner" icon="spinner-spin" :size="size"></p-icon>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        size: {
            type: String,
            default: 'xxl',
        },
    },
});
</script>
