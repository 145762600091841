<style lang="scss">
 .widget-table {
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
 }
 .widget-body.summary-table {
     overflow: hidden;
     height: 100%;
     margin-bottom: 0px;
 }
 .widget-body.summary-table > div {
     height: 100%;
 }
 .widget-body.summary-table #table-filters, .widget-body.summary-table #table-buttons {
     margin-bottom: 0px !important;
 }
 .widget-body.summary-table .dataTables_wrapper {
     height: 100%;
 }
 .widget-body.summary-table .dataTables_scroll {
     height: 100%;
 }
 .widget-body.summary-table .dataTables_wrapper .dataTables_scrollBody {
    height: auto !important;
 }
 .widget-body.summary-table .dataTables_wrapper .dataTables_paginate {
     padding-top: 10px !important;
     padding-bottom: 0px !important;
     border-top: .5px solid #edeff0;
     display: none;
 }
 .widget-body.summary-table td {
     padding-top: 5px;
     padding-bottom: 5px;
 }
 th.hidden, td.hidden {
     padding: 0;
     width: 0px !important;
 }
.summary-table {
    margin-left: 20px;
    margin-right: 20px;
}
.summary-table .dataTables_filter {
    display: none;
}
.summary-table-actions .pa-search {
    height: 2rem;
}
.summary-table-actions .pa-search-box {
    border: none;
    border-radius: 0;
    height: 2rem;
}
.summary-table-actions .pa-search-box-input {
    background-color: white;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    height: 32px;
    max-width: 224px;
    width: 100%;
}
.summary-table-actions .pa-search-btn {
    border: none;
}
.summary-table-actions .pa-search-btn-icon {
    fill: #848e99;
}
.summary-table .dts_label {
    display: none !important;
}
.summary-table table.dataTable {
    border-collapse: collapse;
}
.summary-table table.dataTable thead th.hidden-column {
    border: none;
}
.summary-table table.dataTable thead th,
.summary-table table.dataTable tfoot th {
    border-top: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    padding: 7px;
}
.summary-table table.dataTable tbody tr td {
    border-left: 1px solid #e6e6e6;
    padding: 8px;
}

.summary-table table.dataTable tbody tr:first-of-type td {
    border-top: 1px solid #e6e6e6;
}

.summary-table table.dataTable thead tr th:last-of-type,
.summary-table table.dataTable tbody tr td:last-of-type {
    border-right: 1px solid #e6e6e6;
}

.summary-table table.dataTable .hidden {
    display: none;
}
.summary-table .status-column,
.summary-table .short-width-column{
    /*
        This width keeps these cells as small as possible
        with a little extra padding.
    */
    text-align: center;
}

.summary-table {
    .table-metric-value {
        word-break: break-word;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding-left: 10px;
    }
}

</style>
<template>
    <div :id="id"
         :class="!inModal ? 'grid-stack-item' : 'modal-view'"
         :data-gs-x="localConfig.col.toString()"
         :data-gs-y="localConfig.row.toString()"
         :data-gs-width="localConfig.width.toString()"
         :data-gs-height="localConfig.height.toString()"
         :data-gs-auto-position="false"
         :data-gs-locked="true">
        <div
            :class="!inModal ? 'grid-stack-item-content' : 'modal-view-content'"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;">
                <div :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered, 'expand-mode' : !$parent.editing && isHovered && !inModal, 'in-modal': inModal}">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_7of12" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                            </span>
                            <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: textColor}"><use xlink:href="#spinner-spin-naked"></use></svg>
                            <p-tooltip2 :normal-white-space="true">
                                <span v-if="!isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: textColor }">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span v-if="isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }}</span>
                            </p-tooltip2>
                        </div>
                        <div class="pa-grid-col pa-grid-col_5of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger"  v-show="!$parent.editing && isHovered && !inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#arrow-expand"></use>
                                    </svg>
                                </button>
                                <span> Expand </span>
                            </p-tooltip2>
                            <button slot="trigger"  v-show="!$parent.editing && inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                <svg class="pa-icon remove-icon">
                                    <use xlink:href="#close"></use>
                                </svg>
                            </button>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon" >
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button 
                                    v-show="showWidgetControls && !inModal" 
                                    type="button"
                                    slot="trigger"
                                    @click="cloneWidget" 
                                    class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mr-6 pa-mt-6"
                                >
                                    <p-icon icon="copy"></p-icon>
                                </button>
                                <span> Clone this Widget </span>
                            </p-tooltip2>
                            <p-search
                                style="width:100%;margin-right:8px;"
                                :model="searchValue"
                                :on-change="handleSearchChange"
                                :is-active="true"
                                placeholder="">
                            </p-search>
                        </div>
                    </div>
                </div>
                <div
                    v-show="valid && !needsConfig"
                    id="body"
                    class="widget-body summary-table"
                    style="flex-grow: 1; margin-bottom: 20px;"
                    :style="{'height': inModal && '74vh'}"
                    ref="body"
                >
                    <div :style="`height: ${bodyHeight}px;`">
                        <p-table2
                            :column-renderers="columnRenderers"
                            :column-classes="{
                                0: 'hidden',
                            }"
                            :column-callbacks="columnCallbacks"
                            empty-message="No ports match your filters"
                            :paging="true"
                            :avoid-first-table="true"
                            :scroll-y="`${bodyHeight}px`"
                            ref="data_table"
                            :ordering="true"
                            :sort-column="sortColumnIndex"
                            :sort-direction="sortDirection"
                            :scroller="true"
                            :defer-render="true"
                            :scroll-collapse="false"
                            :filtering="true"
                        >
                            <table slot="table" class="pa-table widget-table">
                                <thead id="table_headers">
                                    <tr>
                                        <th :key="column" v-for="column in columns" :class="classify(column) + '-column'">
                                            {{column}}
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </p-table2>
                    </div>
                </div>
                <div v-if="needsConfig" class="widget-body" :style="{'height': inModal && '85vh'}" style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center;" ref="temp_body">
                    <div class="vertical-center">
                        <button v-if="$parent.allowEditing" @click="configure" class="pa-btn pa-btn_naked_light_grey_no_fill pa-btn_lg" style="padding: 12px 35px" type="button">
                            <svg class="pa-icon">
                                <use xlink:href="#plus-circle-outline"></use>
                            </svg>
                            <span>Configure</span>
                        </button>
                        <p class="widget-unconfigured centered">
                            Please select an instance filter.
                        </p>
                    </div>
                </div>
                <div v-if="!valid" class="widget-body widget-body_error" :style="{'height': inModal && '85vh'}" :title="error_message" ref="body">
                    <p class="pa-message pa-message_error">
                        <svg class="pa-icon pa-icon_xl"><use xlink:href="#alert-circle"></use></svg>
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Vue from 'vue';
    import $ from 'jquery';
    import _ from 'lodash';
    import widgetMixin from './../widgetMixin';

    const NetworkInterfaceWidget = Vue.extend({
        mixins: [widgetMixin],

        data() {
            return {
                searchValue: '',
                content: {
                    columns: {},
                    column_order: [],
                    rows: {},
                },
                columns: [],
                columnCallbacks: {
                    'errorsin-column': (td) => {
                        this.compileEl(td);
                    },
                    'errorsout-column': (td) => {
                        this.compileEl(td);
                    },
                    'discardsin-column': (td) => {
                        this.compileEl(td);
                    },
                    'discardsout-column': (td) => {
                        this.compileEl(td);
                    },
                    'bandwidthin-column': (td) => {
                        this.compileEl(td);
                    },
                    'bandwidthout-column': (td) => {
                        this.compileEl(td);
                    },
                    'bandwidthinutil-column': (td) => {
                        this.compileEl(td);
                    },
                    'bandwidthoututil-column': (td) => {
                        this.compileEl(td);
                    },
                },
                sortColumn: '',
                sortColumnIndex: -1,
                sortDirection: '',
                localConfig: this.config,
                bodyHeight: 0,
                inModal: false,
            };
        },

        computed: {
            columnRenderers() {
                const result = {
                    0: 'hidden',
                    'device-column': this.renderLinkColumn,
                    'server-column': this.renderLinkColumn,
                    'fqdn-column': this.renderLinkColumn,
                    'instance-column': this.renderLinkColumn,
                    'bandwidthinutil-column': this.renderPercentColumn,
                    'bandwidthoututil-column': this.renderPercentColumn,
                    'bandwidthin-column':'bandwidth',
                    'bandwidthout-column':'bandwidth'
                }

                return result;
            },

            showTimeRange() {

                if(this.localConfig.custom_controls.realtimeOrAvg == 'avg') {
                    return true;
                }

                return false;
            },

            colCallbacks() {
                let colMap = {
                    "Discards In": 'discardsin-column',
                    "Discards Out": 'discardsout-column',
                    "Errors In": 'errorsin-column',
                    "Errors Out": 'errorsout-column',
                    "Bandwidth In": 'bandwidthin-column',
                    "Bandwidth Out": 'bandwidthout-column',
                    "Bandwidth In Util": 'bandwidthinutil-column',
                    "Bandwidth Out Util": 'bandwidthoututil-column',
                };

                let visibleColCallbacks = {}
                for (const colName in this.content.columns) {
                    if(this.content.columns[colName].visible == true) {
                        let colClassName = colMap[colName];
                        visibleColCallbacks[colClassName] = function(td) {window.dashboard.$compile(td)};
                    }
                }

                return visibleColCallbacks;
            },
        },

        watch: {          
            showTimeRange: function(val) {
                this.eventHub.$emit(
                    'timeRangeModule:show', 
                    this.id, 
                    this.showTimeRange
                );
            },
            'localConfig.height'() {
                this.getBodyHeight();
            },
            bodyHeight(curr) {
                this.$nextTick(() => {
                    if (this.$refs.data_table.dataTable) {
                        const scrollBody = this.$el.querySelector(".dataTables_scrollBody");
                        if (scrollBody) {
                            scrollBody.style.maxHeight = this.inModal ? '75vh' : `${curr}px`;
                        }
                    }
                });
            },
        },

        events: {
            // Mixin: 'widget:new_data' -> this.initialize(config, content)
            // Mixin: 'widget:updated_data' -> this.update(content)
            // Mixin: 'widget:pend_config' -> this.pendNewConfig()
        },

        methods: {
            expandSelf() {
                this.inModal = !this.inModal;
                setTimeout(() => {
                    this.getBodyHeight();
                    this.update(this.content)
                }, "400");
                
            },
            getBodyHeight() {
                if (this.$refs.body) {
                    const tableHeader = document.querySelector(".dataTables_scrollHead");
                    const tableHeaderHeight = tableHeader ? tableHeader.clientHeight: 0;
                    const offset = 20;
                    const bodyHeight = (this.inModal ?  400 : this.$refs.body.clientHeight) - tableHeaderHeight - offset;
                    this.bodyHeight = this.inModal ? '74vh' : bodyHeight;
                }
            },

            // Mixin: configure() -- opens configure modal
            initialize(config, content) {
                this.$refs.body.style.filter = '';
                this.showAllColumns();
                this.localConfig = {...config};
                this.content = content;

                if (!this.content.success) {
                    return;
                }

                this.columns = content.column_order.slice(0);
                if (config.custom_controls.sort_column && config.custom_controls.sort_direction) {
                    this.sortColumn = config.custom_controls.sort_column;
                    this.sortDirection = config.custom_controls.sort_direction;
                    for (var i = 0; i < this.columns.length; i++) {
                        var col = this.columns[i];
                        if (col == config.custom_controls.sort_column) {
                            this.sortColumnIndex = i;
                            break
                        }
                    }
                }
                this.$nextTick(function() {
                    this.$refs.data_table.createTable();
                    this.hideColumns();
                    if (this.sortColumnIndex >= 0 && this.sortDirection) {
                        this.$refs.data_table.setSort(this.sortColumnIndex, this.sortDirection);
                    }
                    this.insertData();
                    this.changePageLen();
                    const data_points = this.getDataPoints();
                    window.app.rootVue.$broadcast('datapoints:set_points', data_points, this.content.columns, this.sortColumn, this.sortDirection);
                    window.app.rootVue.$broadcast('columngroups:set_groups', data_points, this.localConfig.custom_controls.data_point_config, this.sortColumn, this.sortDirection, this.id);
                    // Add a span element to attach the sort icon to
                    this.$refs.data_table.dataTable.columns().iterator('column', function(ctx, idx) {
                        if (idx != 0) {
                            let header = $(this.$refs.data_table.dataTable.column(idx).header());
                            if (!header.find("span").length) {
                                header.append('<span class="sort-icon"/>')
                            }
                        }
                    }.bind(this));
                    this.$refs.data_table.dataTable.scroller.measure();

                    if (config.custom_controls.item_limit_count) {
                        this.setPageLen(config.custom_controls.item_limit_count);
                    }


                    this.$nextTick(function() {
                        this.getBodyHeight();
                    });
                });
            },

            handleSearchChange: function(value) {
                this.searchValue = value;
                this.$refs.data_table.search(value);
            },

            classify: function(column) {
                if (column === '') {
                    return 'hidden';
                }
                let retVal = column;
                retVal = retVal.replace(/ /g, '');
                retVal = retVal.replace(/:/, '');
                retVal = retVal.toLocaleLowerCase();
                return retVal;
            },

            renderLinkColumn(data, type, row) {
                if (_.isNil(data) || data === 0) {
                    return "";
                }

                if (type === 'display' && this.$parent.showLinks) {
                    if (Array.isArray(data)) {
                        return data.map(
                            ({ url, title = "" }) => url
                                ? `<a href="${url}" target="_blank">${title}</a>`
                                : title
                        ).join(', ');
                    }

                    if (!data.title) {
                        return data || "";
                    }

                    if (!data.url) {
                        return data.title;
                    }

                    return `<a href="${data.url}" target="_blank">${data.title}</a>`;
                } else {
                    let retVal = data.title;
                    if (typeof retVal === 'undefined') {
                        retVal = '';
                    }
                    return retVal;
                }
            },

            getMetricPopupMarkup(metricId, value, unit) {
                let href = `/report/MetricPopup?&metric_ids[]=-${metricId}&timescale=${this.config.timescale}`

                if (unit == 'percent') {
                    href = `${href}&selected_unit=%`
                }

                return `
                    <span class="table-metric-value">${value}
                        <a
                            v-p-dialog-open 
                            target="dynamic_modal"
                            style="margin-left: auto;"
                            href="${href}"
                        >
                            <p-icon
                                icon="arrow-expand"
                            >
                            </p-icon>
                        </a>
                    </span>`;
            },

            showAllColumns() {
                for (var col in this.content.columns) {
                    const column_id = this.content.column_order.indexOf(col);
                    this.$refs.data_table.showColumn(column_id);
                }
            },

            hideColumns() {
                for (var col in this.content.columns) {
                    const column_id = this.content.column_order.indexOf(col);
                    if (this.content.columns[col].visible) {
                        this.$refs.data_table.showColumn(column_id);
                    } else {
                        this.$refs.data_table.hideColumn(column_id);
                    }
                }
            },

            toggleColumn(column) {
                const visible = this.content.columns[column].visible;
                this.content.columns[column].visible = !visible;
                return {
                    columns: this.content.columns,
                    column_order: this.content.column_order,
                };
            },

            setSort(column, direction) {
                this.sortColumn = column;
                this.sortDirection = direction;
                return {
                    columns: this.content.columns,
                    column_order: this.content.column_order,
                    sort_column: column,
                    sort_direction: direction,
                };
            },

            moveColumn(inActualIndex, inDesiredIndex) {
                // Take into account the two static columns.
                let staticOffset = 1;
                const actualIndex = inActualIndex + staticOffset;
                const desiredIndex = inDesiredIndex + staticOffset;
                const temp = this.content.column_order[desiredIndex];
                this.content.column_order[desiredIndex] = this.content.column_order[actualIndex];
                this.content.column_order[actualIndex] = temp;

                // Also move the column within datatables
                // Otherwise it doesn't recompute renderers
                this.$refs.data_table.moveColumn(actualIndex, desiredIndex);

                return {
                    columns: this.content.columns,
                    column_order: this.content.column_order,
                };
            },

            setColumnOrder({ labels, indexes }) {
                this.$refs.data_table.setColumnOrder([0, ...indexes.map(i => i + 1)]);
                this.content.column_order = [...['', ...labels]];

                return {
                    columns: this.content.columns,
                    column_order: this.content.column_order,
                };
            },

            getDataPoints() {
                return this.content.column_order.slice(1);
            },

            insertData() {
                this.$refs.data_table.reloadTableData(this.content.rows);
            },

            changePageLen() {
                this.$refs.data_table.changePageLen(this.calculateTableRows());
            },

            setPageLen(length) {
                this.$refs.data_table.changePageLen(length);
            },

            update(content) {
                this.content = content;
                if (!this.content.success) {
                    return;
                }
                this.$refs.data_table.reloadTableData(this.content.rows);
            },
            getColumns() {
                return this.content.data_points;
            },

            reconfig() {
                if (!this.content.success) {
                    return;
                }
                this.$refs.data_table.changePageLen(this.calculateTableRows());
                this.$refs.data_table.dataTable.scroller.measure();
            },

            calculateTableRows() {
                const tableArea = this.$el.querySelectorAll('.dataTables_scrollBody')[0];
                if (!tableArea) { return this.localConfig.height * 2; }
                const tableHeight = tableArea.offsetHeight;
                const rowHeight = 38; // TODO: reliable?

                return Math.floor(tableHeight / rowHeight);
            },

            _getOpenWidgetId() {
                return this.$parent.$refs.configdrawer.editingWidget.id;
            },

            getConfigModules() {
                return [
                    { type: 'p-overview-module' },
                    { type: 'p-network-interface-module', options: { }, open: true },
                    { type: 'p-instance-module', open: true },
                    ...(this.$parent.isScoping() ? [{ type: "p-ignore-scope-module" }] : []),
                    {
                        type: 'p-custom-module',
                        options: {
                            title: '',
                            controls: [
                                {
                                    id: 'backgroundColor',
                                    label: 'Background Color',
                                    key: 'backgroundColor',
                                    type: 'color-picker',
                                    default: '#ffffff'
                                },
                                {
                                    label: 'Realtime or Average Value',
                                    key: 'realtimeOrAvg',
                                    type: 'p-radio-group',
                                    default: 'real',
                                    options: [
                                        { value: 'real', label: 'Real Time' }, 
                                        { value: 'avg', label: 'Average' },
                                    ],
                                },
                            ]
                        }
                    },
                ];
            },

            getOptionModules() {
                return [
                    {type: 'p-timerange-module', options: {'show': this.showTimeRange, 'includeYear': false}},
                    {type: 'p-datapoints-module', options: {}},
                ];
            },

            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this);

                // Configuration events need to be sent on next tick, to give
                // time to Vue to update the DOM
                Vue.nextTick(() => {
                    const data_points = this.getDataPoints();
                    if (data_points) {
                        window.app.rootVue.$broadcast('datapoints:set_points', data_points, this.content.columns, this.sortColumn, this.sortDirection);
                    }
                });
            },

            dumpState() {
                const obj = Object();
                obj.data = this.$data;
                obj.config = {...this.localConfig};
                console.log(JSON.stringify(obj));
            },

        },

        props: {
            // Mixin: id:Number, config:Object, title:String
        },

        mounted() {
            // Mixin: dispatch('widget:domready')
            this.pendNewConfig();
            if (this.id < 0) {
                this.configure();
            };
        },
    });

    export default NetworkInterfaceWidget;
</script>
