<template>
    <tbody>
        <tr
            class="category-row added"
            :class="[isOpened ? 'cat-row-collapse' : 'cat-row-expand']"
            @click="handleToggle"
            transition="category"
        >
            <td :colspan="isTemplate ? 3 : 4">
                <div class="pa-flex pa-align-center">
                    <label v-if="showCheckboxes" class="pa-option pa-ml-4 pa-mt-2 pa-mr-8">
                        <input @click="handleCategoryCheck" type="checkbox" class="pa-option-input" />
                        <span class="pa-option-icon"></span>
                    </label>
                    <span class="category-title pa-txt_medium">
                        {{ category.name }}
                    </span>
                    <div v-if="showTooltip" style="display: inline-block;">
                        <p-tooltip2 
                            :hover="false" 
                            :advanced="true"
                            variant="steel" 
                            :wait-time="300">
                            <p-icon
                                slot="trigger"
                                icon="alert-circle-outline"
                                block
                                size="sm"
                                @click="dismissCategory"
                                style="margin-left: 5px; position: relative; top: 2px;"
                            >
                            </p-icon>
                            <template v-if="hasAgent">
                                <p v-if="category.errors.length">
                                    We've not been able to find this application yet. Please click
                                    <p-icon
                                        icon="docs"
                                        @click="showDocs"
                                        size="sm"
                                    >
                                    </p-icon>
                                    to read the installation docs.
                                </p>
                            </template>
                            <template v-if="!hasAgent">
                                <p v-if="!isNetOnly && !category.is_snmp && !isContainer">
                                    The Panopta agent was not found on this instance. Please install it to monitor these metrics.
                                </p>
                            </template>
                        </p-tooltip2>
                    </div>
                    <template v-if="category.tooltips && category.tooltips.length">
                        <div
                            :key="tooltipIndex"
                            v-for="(tooltip, tooltipIndex) in category.tooltips"
                            style="margin-left: 3px; display: inline-block;"
                        >
                            <p-tooltip2 
                                :hover="tooltip[0] == 'content-copy' ? false : true" 
                                variant="steel"
                                :normalWhiteSpace=true
                                :html-class="tooltip[0] == 'question-circle-outline' ? 'snmp-description' : ''"
                            >
                                <p-icon
                                    slot="trigger"
                                    :icon="tooltip[0]"
                                    size="sm"
                                    block
                                >
                                </p-icon>
                                <div v-html="tooltip[1]"></div>
                            </p-tooltip2>
                        </div>
                    </template>
                    <p-icon
                        :icon="isOpened
                            ? 'chevron-down'
                            : 'chevron-right'"
                        :color="isOpened
                            ? 'tundora'
                            : ''"
                    >
                    </p-icon>
                </div>
            </td>
            <td style="text-align: right; padding-right: 15px; border-right: 1px solid #e6e6e6;" colspan="2">
                <button
                    v-if="showAddMetrics"
                    class="pa-btn_link compact add-metric-cat-row pa-txt_medium pa-txt_sm"
                    @click="openAddMetricDrawer"
                >
                    <p-icon
                        icon="plus-circle-outline"
                        color="blue"
                        class="plus-circle-outline"
                    >
                    </p-icon>
                    Add Metric
                </button>
                <a
                    v-if="showSnmpCatalog"
                    class="pa-btn pa-btn_secondary compact"
                    :href="`/config/SNMPCatalog?server_id=${serverId}`"
                >
                    SNMP Catalog
                </a>
            </td>
        </tr>
        <tr transition="category">
            <td style="padding: 0; vertical-align: top;" :colspan="isTemplate ? 5 : 6">
                <div v-show="isOpened" class="metric-box" transition="metric-box">
                    <p-metric-list-2
                        :category.sync="category"
                        :checked-model="checked"
                        @on-checked="handleChecked"
                        :search-model.sync="searchQuery"
                        :can-add-metrics="canAddMetrics"
                        :show-metric-checkboxes="showCheckboxes"
                        :is-template="isTemplate"
                        :is-outbound="isOutbound"
                        :is-inbound="isInbound"
                    >
                    </p-metric-list-2>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Vue from 'vue';
import MetricList2 from './MetricList2.vue';

export default Vue.extend({
    components: {
        'p-metric-list-2': MetricList2,
    },
    props: {
        category: {
            type: Object,
            default: () => {},
        },
        isTemplate: {
            type: Boolean,
            default: false,
        },
        hasAgent: {
            type: Boolean,
            default: false,
        },
        isContainer: {
            type: Boolean,
            default: false,
        },
        canAddMetrics: {
            type: Boolean,
            default: false,
        },
        openedCategories: {
            type: Array,
            default: () => [],
        },
        checked: {
            type: Array,
            default: () => [],
        },
        serverId: {
            type: Number,
            default: null,
        },
        applications: {
            type: Object,
            default: () => {},
        },
        check: {
            type: Function,
            default: () => {},
        },
        searchQuery: {
            type: String,
            default: '',
        },
        showCheckboxes: {
            type: Boolean,
            default: false,
        },
        isOutbound: {
            type: Boolean,
            default: false,
        },
        isInbound: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isOpened() {
            return this.openedCategories.includes(this.category.name)
        },
        isNetOnly() {
            const app = this.applications.detected.find((a) => {
                return a.textkey === this.category.textkey;
            });

            if (app) {
                return Boolean(app.net_only);
            }

            return false;
        },
        showTooltip() {
            return (
                !this.isTemplate &&
                !this.category.metrics &&
                !this.category.metrics.length &&
                (
                    (
                        this.hasAgent &&
                        this.category.errors &&
                        this.category.errors.length
                    ) ||
                    (
                        !this.hasAgent &&
                        !this.isNetOnly &&
                        !this.category.is_snmp &&
                        !this.isContainer
                    )
                )
            );
        },
        showAddMetrics() {
            return this.canAddMetrics && this.category.addable && !this.category.is_snmp;
        },
        showSnmpCatalog() {
            return this.canAddMetrics && this.category.addable && this.category.is_snmp
        },
    },
    methods: {
        handleChecked(value) {
            this.$emit('checked', value);
        },
        handleToggle(event) {
            this.$emit('toggle-category', event, this.category.name);
        },
        handleCategoryCheck(event) {
            this.$emit('category-check', event, this.category);
        },
        showDocs() {
            this.$emit('show-docs', this.category);
        },
        openAddMetricDrawer(event) {
            if (typeof event !== 'undefined' && event) {
                event.stopPropagation();
            }

            let subCategoryName = null;

            if (this.category.name && this.category.name.includes(':')) {
                const parts = this.category.name.split(':');
                subCategoryName = parts[parts.length - 1].trim();
            }

            this.$broadcast(
                'metric-drawer:open',
                this.serverId,
                this.category.textkey,
                this.category.name,
                false,
                this.isInbound,
            );
        },
        dismissCategory() {
            this.$emit('dismiss-category', this.category.textkey);
        },
    },
});
</script>