<style lang="scss">
    .value-label-inline {
        display: inline-flex;
    }

    #statistics-bar {
        .statistic {
            border-right: 1px solid #e6e6e6;
        }
    }

    #statistics-bar {
        .statistic {
            &.small {
                font-size: 20px;

                span {
                    font-weight: 500;
                }
            }
            &.medium {
                font-size: 28px;
            }
            &.large {
                font-size: 36px;
            }
            &.pa-grid-col:last-of-type {
                border-right: none;
            }
        }

        .pa-grid-col:last-of-type {
            .statistic {
                border-right: none;
            }
        }

    }

    @media (max-width: 540px) {
        #statistics-bar {
            .pa-grid {
                display: block;
            }

            .pa-grid-col {
                display: block;
                padding-top: 0;
                width: 100%;
            }

            .statistic {
                border-right: none;
                border-bottom: 1px solid #e6e6e6;
            }

            .pa-grid-col:last-of-type {
                .statistic {
                    border-right: none;
                }
            }
        }
    }
</style>

<template>
    <section id="statistics-bar">
        <p-card no-header no-footer :style="cardStyle" class="pa-mb-16">
            <p-row>
                <p-column
                    :key="index"
                    v-for="(key, index) in columnOrder"
                    :cols="columnWidth"
                >
                    <div
                        :class="fontSize"
                        class="statistic pa-py-16 pa-txt_centered"
                    >
                        <div :class="{'value-label-inline': inline}">
                            <span v-if="!statistics[key].loading" :class="valueClass">{{ statistics[key].value }}</span>
                            <div v-else>
                                <p-loading-spinner></p-loading-spinner>
                            </div>
                            <p :class="labelClass">
                                {{ statistics[key].label }}
                                <p-flyout
                                    ref="flyout"
                                    v-if="statistics[key].info"
                                    direction="bottom"
                                    :scroll-lock="true"
                                    :hover="true"
                                    :hover-timeout="0"
                                    :arrow-percent="0"
                                >
                                    <p-icon
                                        slot="trigger"
                                        icon="question-circle-outline"
                                        middle
                                        color="cloudy-blue"
                                        size="sm"
                                    >
                                    </p-icon>
                                    <p slot="content">Applies only to active incidents</p>
                                </p-flyout>
                            </p>
                        </div>
                    </div>
                </p-column>
            </p-row>
        </p-card>
    </section>
</template>

<script>
import Vue from 'vue';


const sizes = [
    "sm",
    "md",
    "lg",
];

export default Vue.extend({
    computed: {
        columnWidth() {
            return 12 / Object.keys(this.columnOrder).length;
        },

        cardStyle() {
            let style = {}

            if (this.noBorder) {
                style['border'] = 'unset';
            }

            return style;
        },

        fontSize() {
            return {
                'large': this.valueSize == "lg",
                'medium': this.valueSize == "md",
                'small': this.valueSize == "sm",
            };
        },

        valueClass() {
            return {
                'pa-txt_medium': this.valueSize == "lg",
                'pa-txt_small': this.valueSize == "sm",
            };
        },

        labelClass() {
            return {
                'pa-pt-4': true,
                'pa-txt_lg': this.labelSize == "lg",
                'pa-txt_md': this.labelSize == "md",
                'pa-txt_sm': this.labelSize == "sm",
                'pa-pl-8': this.inline,
            };
        },

    },

    props: {
        statistics: {
            type: Object,
            'default': () => {}
        },

        columnOrder: {
            type: Object,
            'default': () => {}
        },

        noBorder: Boolean,

        valueSize: {
            type: String,
            validator: (size) => isValidOption(size, sizes),
            'default': 'md'
        },

        labelSize: {
            type: String,
            validator: (size) => isValidOption(size, sizes),
            'default': 'sm'
        },

        inline: Boolean
    }
});


const isValidOption = (option, options) => {
    const isValid = options.indexOf(option) !== -1;

    if (!isValid) {
        console.error(
            "'" +
            option +
            "' is not a valid option. Available options: " +
            options.join(", ")
        );
    }

    return isValid;
};


</script>