<template>
    <p-menu :html-id="htmlId" :force-in-modal="forceInModal" :max-height="maxHeight" :min-height="minHeight">
        <button
            slot="trigger"
            type="button"
            class="pa-btn pa-btn_secondary" :class="cssclass"
            :disabled="disabled">
            <svg v-if="icon" class="pa-icon pa-icon_light pa-icon_r">
                <use :xlink:href="`#${icon}`"></use>
            </svg>
            {{ text }}
            <svg v-if="icon" class="pa-icon pa-icon_light pa-icon_lg">
                <use xlink:href="#menu-down"></use>
            </svg>
        </button>
        <slot></slot>
    </p-menu>
</template>

<script>
    import Vue from 'vue';

    const menuMixin = require('../mixins/menuMixin');

    const SecondaryNav = Vue.extend({
        mixins: [menuMixin],
        props: {
            htmlId: String,

            forceInModal: Boolean,

            maxHeight: Number,

            minHeight: Number,
        },
    });

    export default SecondaryNav;
</script>
