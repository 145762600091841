import ConfigDBManager from '../utils/configDBManager.js';
import MetricManager from '../utils/metricManager.js';

const rootVueMixin = {
    data() {
        return {
            navItems: [],
            userImage: {},
            userItems: [],
            nav_items: [],
            user_nav_items: [],
            show_instance_drawer: true,
            instance_drawer_open: false,
            customerConfig: {
                allTags: [],
            },
        };
    },

    methods: {
        open_instance_drawer() {
            this.instance_drawer_open = true;
        },

        close_instance_drawer() {
            this.instance_drawer_open = false;
        },

        enableMetricManager() {
            this.metricManager = new MetricManager();
        },
    },

    mounted() {
        window.app.eventHub.$emit('rootVue:ready', this);

        this.configDBManager = new ConfigDBManager({
            parent: this,
        });
        this.configDBManager.$on('configdb:update', config => {
            for (const key in config) {
                if (config.hasOwnProperty(key)) {
                    Vue.set(this.customerConfig, key, config[key]);
                }
            }
            // Notify eventHub of the update
            this.eventHub.$emit('customerConfig:update');
        });

        if (window.openMyAccount) {
            // Open the My Account drawer on load
            window.setTimeout(() => {
                this.$broadcast('drawer:load', {
                    id: 'dynamic-drawer',
                    url: '/userconfig/EditUser?user_id=-1&show_email_prefs=1',
                    anonymous: false,
                    width: 450,
                });
            }, 500);
        }

        document.addEventListener('DOMContentLoaded', () => {
            if (!this.nav_items.length && window.navItems) {
                this.nav_items = window.navItems;
            }
            if (!this.user_nav_items.length && window.userItems) {
                this.user_nav_items = window.userItems;
            }
        });

        $.ajax({
            url: '/config/getTopNav',
            type: 'GET',
        }).done(data => {
            if (data.success && data.menu) {
                if (data.menu.length) {
                    const userItems = data.menu.pop().items;
                    this.user_nav_items = userItems;
                }
                this.nav_items = data.menu;
            }
        });
    },
};

export default rootVueMixin;
