<style lang="scss">
    #sd-wan-performance-header {

    }
    @media (max-width: 720px) {
    	#instance-performance-header {
    		>.pa-grid-col_6of12 {
    			width: 100%;
    		}
    	}
    }
</style>

<template>
    <div class="pa-row pa-px-20 pa-pb-14">
        <div id="sd-wan-performance-header" class="pa-grid">
            <div class="pa-grid-col pa-grid-col_3of12 pa-justify-center">
                <ul class="graph-controls-list pa-hList pa-hList_x3  pa-grid pa-align-center">
                    <li>
                        <p-select
                            :options="timescaleOptions"
                            :model.sync="localTimescale"
                            class="pa-justify-start"
                        >
                            <p-icon
                                icon="icons-clock-outlined"
                                size="xxxl"
                                class="pa-pr-6"
                                color="grey-700"
                                slot="lead-icon"
                            >
                            </p-icon>
                        </p-select>
                    </li>
                </ul>
            </div>
            <div class="pa-grid-col pa-grid-col_9of12">
                <ul class="graph-controls-list pa-hList pa-hList_x2 pa-justify-end">
                    <li>
                        <p-select
                            size="xs"
                            :options="groupByOptions"
                            :model.sync="selectedGroupBy"
                            :searchable="true"
                        >
                        <div slot="display-text">
                            Group By: {{ selectedGroupByLabel }}                                
                        </div>
                        </p-select>
                    </li>
                    <li>
                        <p-select
                            size="xs"
                            :options="sdWanTargetOptions"
                            :model.sync="selectedSdWanTargetFilter"
                            :searchable="true"
                            :multiple="true"
                            :list-width="300"
                        >
                        <div slot="display-text">
                           Target: {{ selectedSdWanTargetLabel }}                                
                        </div>
                        </p-select>
                    </li>
                    <li>
                        <p-select
                            size="xs"
                            :options="wanConnectionOptions"
                            :model.sync="selectedWanConnectionFilter"
                            :searchable="true"
                            :multiple="true"
                        >
                        <div slot="display-text">
                            Connections: {{ selectedWanConnectionLabel }}                                
                        </div>
                        </p-select>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    props: {
        instance: Object,
        timescaleOptions: Array,
        timescale: String,
    },
    
    data: function() {
        return {
            localTimescale: this.timescale,
            selectedGroupBy: 'target',
            selectedSdWanTargetFilter: [],
            selectedWanConnectionFilter: [],
            groupByOptions: [
                {label: "Connection", value: 'connection'},
                {label: "Target", value: 'target'}
            ],
        };
    },

    computed: {
        selectedGroupByLabel() {
            for(const opt of this.groupByOptions) {
                if (opt.value == this.selectedGroupBy) {
                    return opt.label;
                }
            }

            return "";
        },

        sdWanTargetOptions() {
            const opts = [];
            for (const app of this.instance.sdWanApplications) {
                for (const target of app.targets) {
                    let label = `(${app.name}) ${target.name}`;
                    opts.push({value: target.id, label: label});
                }
            }

            return opts;
        },

        selectedSdWanTargetLabel() {
            if (this.selectedSdWanTargetFilter.length == 0) {
                return 'All';
            }

            if (this.selectedSdWanTargetFilter.length < 3) {
                const labels = [];
                for(const filter of this.selectedSdWanTargetFilter) {
                    for(const opt of this.sdWanTargetOptions) {
                        if (opt.value == filter) {
                            labels.push(opt.label);
                        }
                    }
                }

                return labels.join(', ');
            }

            if (this.selectedSdWanTargetFilter.length > 2) {
                return `${this.selectedSdWanTargetFilter.length} Selected`;
            }

        },

        wanConnectionOptions() {
            const opts = [];
            for (const conn of this.instance.wanConnections) {
                opts.push({value: conn.id, label: conn.name})
            }

            return opts;
        },


        selectedWanConnectionLabel() {
            if (this.selectedWanConnectionFilter.length == 0) {
                return 'All';
            }

            if (this.selectedWanConnectionFilter.length < 3) {
                const labels = [];
                for(const filter of this.selectedWanConnectionFilter) {
                    for(const opt of this.wanConnectionOptions) {
                        if (opt.value == filter) {
                            labels.push(opt.label);
                        }
                    }
                }

                return labels.join(', ');
            }

            if (this.selectedWanConnectionFilter.length > 2) {
                return `${this.selectedWanConnectionFilter.length} Selected`;
            }

        },
       
    },

    watch: {
        timescale(curr, prev) {
            this.localTimescale = curr;
        },
        localTimescale(curr, prev) {
            this.$emit('update:timescale', curr, prev);
        },
        selectedSdWanTargetFilter(curr, prev) {
            this.filterSdWanPerformanceTable();
        },
        selectedWanConnectionFilter(curr, prev) {
            this.filterSdWanPerformanceTable();
        },
        selectedGroupBy(curr, prev) {
            this.eventHub.$emit(
                'sdwan-groupby:update',
                curr,
                this.selectedSdWanTargetFilter,
                this.selectedWanConnectionFilter
            );
        },
    },

    methods: {
        
        filterSdWanPerformanceTable() {
            this.eventHub.$emit(
                'sdwan-filters:update', 
                this.selectedSdWanTargetFilter, 
                this.selectedWanConnectionFilter
            );
        }
    },

    mounted() {
        if(this.localTimescale == "year") {
            this.localTimescale = "month"
        }
    },

});
</script>