<style lang="scss">
    @import "~styles/core/helpers/_variables.scss";

    #trial-signup {
        margin-top: 15px;
    }

    #trial-signup-body {
        margin-top: 40px;
    }
</style>

<template>
    <div id="trial-signup">
        <div class="auth-header">
            <img class="logo" src="/static/images/Panopta-Logo-Square.png" />
            <h2 class="title pa-mt-24">Sign up for a free 30-day trial</h2>
        </div>

        <div id="trial-signup-body">
            <p-alert
                v-if="message"
                variant="red"
                icon="alert-circle-outline"
                style="margin-bottom: 16px;">
                {{ message }}
            </p-alert>

            <form ref="form" action="/trial/signup" method="POST">
                <p-field-group
                    name="name"
                    label="Name"
                    class="pa-field_error-icon"
                    :error="errors.name">
                    <p-input
                        v-model="name"
                        name="name"
                        theme="naked">
                    </p-input>
                </p-field-group>

                <p-field-group
                    name="phone_number"
                    label="Phone"
                    class="pa-field_error-icon pa-mt-24"
                    :error="errors.phone">
                    <p-input
                        v-model="phone"
                        name="phone"
                        theme="naked">
                    </p-input>
                </p-field-group>

                <p-field-group
                    name="company"
                    label="Company"
                    class="pa-field_error-icon pa-mt-24"
                    :error="errors.company">
                    <p-input
                        v-model="company"
                        name="company"
                        theme="naked">
                    </p-input>
                </p-field-group>

                <p-field-group
                    name="email_address"
                    label="Email"
                    class="pa-field_error-icon pa-mt-24"
                    :error="errors.email">
                    <p-input
                        v-model="email"
                        name="email_addresss"
                        theme="naked">
                    </p-input>
                </p-field-group>

                <p-field-group
                    name="password1"
                    label="Password"
                    class="pa-field_error-icon pa-mt-24"
                    :error="errors.password1">
                    <p-input
                        v-model="password1"
                        name="password1"
                        theme="naked">
                    </p-input>
                </p-field-group>

                <p-field-group
                    name="password2"
                    label="Confirm Password"
                    class="pa-field_error-icon pa-mt-24"
                    :error="errors.password2">
                    <p-input
                        v-model="password2"
                        name="password2"
                        theme="naked">
                    </p-input>
                </p-field-group>
            </form>

            <button
                @click="submit"
                :disabled="!filledIn"
                class="pa-btn pa-btn_block pa-btn_blue submit-button pa-mt-32">
                <span class="pa-txt_xxl">Sign up</span>
            </button>

            <hr class="pa-mt-16" style="border-top: solid 1.5px #e1e2e3;" />

            <p class="pa-txt_lg">Already have an account? <a @click="signIn" class="pa-clickable">Sign In</a></p>
            <p class="pa-txt_lg"><a href="https://docs.fortimonitor.forticloud.com/user-guide/46227/get-support">Contact our Team</a> for questions</p>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';

    export default Vue.extend({
        props: {
        },

        data() {
            return {
                // Fields
                name: '',
                phone: '',
                company: '',
                email: '',
                password1: '',
                password2: '',

                errors: {},
                message: '',
            };
        },

        computed: {
            filledIn() {
                if (!(this.name
                        && this.phone
                        && this.company
                        && this.email
                        && this.password1
                        && this.password2)) {
                    return false;
                }

                if (this.password1 !== this.password2) {
                    return false;
                }

                return true;
            },
        },

        methods: {
            submit() {
                this.message = '';
                for (const member in this.errors) {
                    if (this.errors.hasOwnProperty(member)) {
                        this.$delete(this.errors, member);
                    }
                }
            },

            signIn() {
                this.eventHub.$emit('auth:switchType', 'login');
            },
        },
    });
</script>
