<style lang="scss">
    
   #ftnt-user-menu-wrapper{ 
        #user_info{
            .dname {
                color: #a0a9b8;
                display: block;
                font-size: 9px;
                font-weight: 400;
                letter-spacing: .065em;
                text-transform: uppercase;
            }
            .dvalue {
                color: #3d5379;
                display: block;
                font-size: 13px;
                line-height: 100%;
                word-break: break-all;
            }
            .left-column {
                div:last-of-type {
                    border-bottom: none !important;            
                }
            }
        }
    }

    #org_dd {
        .org_base{
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            width: 100%
        }
        
        .tree {
            width: 98% !important ;
            max-height: 500px;
            overflow-y: auto;
        }

        .tree-children{
            max-height: none !important;
        }
             
        .tree-anchor {
            display: inline;
            align-items: baseline;
            flex-grow: 1;
            position: relative;
            height: auto !important;
        }
        
    }
    
    
</style>
<template>
    <div id="user_info" :style="computedDivStyle">
        <p-icon v-if="model.leftIcons" v-for="icon in model.leftIcons" :key="icon" :icon="icon" color="silver" :size="(!isHovered && isCollapsed) ? 'xxl' : ''"></p-icon>
        <span class="dname" v-if="model.superscript"> {{model.superscript}} </span>
        <a
            v-if="model.action === 'link'"
            :href="model.href"
            :target="model.target"
            :class="htmlClass"
            :title="model.title"
            style="text-overflow: ellipsis; white-space: nowrap;">
            <span
                v-if="model.badge"
                class="pa-badge pa-badge_info_inverse"
                style="margin-right: 5px;">
                {{ model.badge }}
            </span>
            <slot></slot>
        </a>
        <span
            v-else-if="model.action === 'span'"
            class="dvalue"
            :title="model.title"
            style="margin-bottom: 0px;">
            <slot></slot>
        </span>

        <button
            v-else-if="model.action === 'event'"
            v-on:click="onClick(model)"
            type="button"
            :title="model.title"
            :class="htmlClass">
            <slot></slot>
        </button>
    <span
        v-else-if="model.action === 'span'"
        :class="model.class"
        :title="model.title"
        style="margin-bottom: 0px; word-break: break-word;">
        <slot></slot>
    </span>

        <button
            v-else-if="model.action === 'modal'"
            v-p-dialog-open
            :target="model.target"
            :template="model.template"
            type="button"
            :class="htmlClass">
            <slot></slot>
        </button>

        <button
            v-else-if="model.action === 'click'"
            v-on:click="broadcast(model.event)"
            type="button"
            :class="htmlClass">
            <slot></slot>
        </button>

        <button
            v-else-if="model.action === 'drawer'"
            v-p-drawer-open
            :target="model.target"
            :template="model.template"
            :width="model.width"
            :anonymous="model.anonymous"
            type="button"
            :class="htmlClass">
            <slot></slot>
        </button>

        <div id="org_dd" class="org_base" v-else-if="model.action === 'org_dd'">                      
            <p-tree-2
                v-show="true"
                :show-search="true"
                :custom-tree="true"
                :data="model.tree"
                text-field-name="name"
                :whole-row="true"
                :opened="true"
                :collapse="true"
                :allow-transition="false">
                <slot></slot>            
            </p-tree-2>
            
        </div>
    
        <p-nav-menu
            :text="model.text"
            :icon="model.icon"
            :cssclass="model.cssclass"
            :is-absolute="true"
            :max-height="model.stickyItems && model.stickyItems.length ? 0 : model.maxHeight"
            :menu-class="model.menuClass"
            :searchable="model.searchable"
            :new-dot="model.newDot"
            v-else-if="model.action === 'nav-menu'">
            <template v-if="model.stickyItems && model.stickyItems.length">
                <div class="menu-scrollable" :style="{ maxHeight: model.maxHeight + 'px' }">
                    <template v-for="subItem in model.items">
                        <p-action
                            v-if="!subItem.items"
                            :model="subItem">
                            {{ subItem.text }}
                        </p-action>
                        <p-sub-menu
                            :text="subItem.text"
                            v-if="subItem.items">
                            <p-action
                                v-for="subSubItem in subItem.items" :key="subSubItem.id"
                                :model="subSubItem">
                                {{ subSubItem.text }}
                            </p-action>
                        </p-sub-menu>
                    </template>
                </div>
                <div class="menu-sticky">
                    <template v-for="subItem in model.stickyItems">
                        <p-action
                            v-if="!subItem.items"
                            :model="subItem">
                            <svg v-if="subItem.icon" class="pa-icon">
                                <use :xlink:href="`#${subItem.icon}`"></use>
                            </svg>
                            {{ subItem.text }}
                        </p-action>
                    </template>
                </div>
            </template>
            <template v-if="!model.stickyItems || !model.stickyItems.length">
                <template v-for="subItem in model.items">
                    <p-action
                        v-if="!subItem.items"
                        :model="subItem">
                        {{ subItem.text }}
                    </p-action>
                    <p-sub-menu
                        :text="subItem.text"
                        v-if="subItem.items">
                        <p-action
                            v-for="subSubItem in subItem.items" :key="subSubItem.id"
                            :model="subSubItem">
                            {{ subSubItem.text }}
                        </p-action>
                    </p-sub-menu>
                </template>
            </template>
        </p-nav-menu>
</div>
</template>

<script>    
    import Vue from 'vue';

    const Action = Vue.extend({
        methods: {
            broadcast(eventId) {
                window.app.rootVue.eventHub.$emit(eventId);
            }
        },

        computed:{
            computedDivStyle: function() {
                if(this.model.alignment === 'right'){
                    return {
                        'position': "relative",
                        'align-items': "center",
                        'border-left': "2px solid transparent",
                        'padding-left': "14px",
                        'padding-bottom':"6px",
                        'display': "flex",
                    };
                }else if (this.model.alignment === 'left'){
                    return {
                        'padding': "8px 0 11px",
                        "cursor": "default",
                        "border-bottom": "1px dashed #d7dbe4",
                    };
                    }
                    else return {};
                }                
            },

        props: {
            htmlClass: {
                type: String,
                default: ''
            },

            model: {
                type: Object,
                default: () => {}
            },

            onClick: {
                type: Function,
                default: function() {} // noop
            }
        }
    });

    export default Action;
</script>
