<style lang="scss">
@import "~styles/core/helpers/_variables.scss";

.vuetable {
    width: 100%;
}

#incident-overview-table {
        margin-top: 8px;

        &.loading {
            tbody {
                min-height: 75px;
            }
            tbody tr td {
                background: #eee;
            }
        }

        table {
            .pa-icon {
                &.pa-icon_maintenance_md {
                    height: 0.9411764706rem;
                }
            }
        }

        tr {
            > * {
                text-align: left;
            }

            td {
                font-size: 13px;
                padding: 0.4705882353rem;
                background-color: white;
                border-bottom: none;
                border-top-width: 1px;
                border-top-style: solid;
                border-top-color: var(--p-grey-200, #d5d8df);
                vertical-align: top;
            }
        }

        thead {
            th {
                background: transparent !important;
                white-space: nowrap;
                font-size: 0.6470588235rem;
                font-weight: 500;
                text-transform: uppercase;
                color: var(--p-grey-500, #7f899c);
                font-family: "Inter", sans-serif;
                padding: 0.4705882353rem;
            }
        }

        .vuetable-empty-result {
            height: 75px;
            text-align: center;
        }

        .dataTables_filter {
            margin-top: -10px;
        }

        .pa-table {
            tbody {
                .pa-select-text-highlight {
                    background-color: transparent;
                }
            }
        }
        .incident-column {
            font-size: 14px;
            font-weight: 500;
            color: #3cb588;

            &.error {
                color: #e31212;
            }
        }

        .services-affected {
            ul {
                list-style: disc;
            }
            li {
                font-weight: 500;
            }
        }

        .is-empty-icon {
            opacity: .25;
            &:hover {
                opacity: 1;
            }
        }

        .incident-summary-text {
            width: 800px;
            font-size: 13px;
            line-height: 1.08;
            text-decoration: underline;
            cursor: pointer;
            text-align: left;
        }
        .incident-summary-text-tooltip {
            .content {
                max-width: 800px;
                font-size: 13px;
                @include colorVar(color, "white");
                padding: 10px 19px;
                border-radius: 5px;
                box-shadow: 0 2px 5px 1px rgba(86, 89, 90, 0.4);
                background-color: #333333;
            }
        }

        .incident-solution-text-tooltip {
            .content {
                max-width: 800px;
                min-width: 175px;
                font-size: 13px;
                @include colorVar(color, "white");
                padding: 10px 19px;
                border-radius: 5px;
                box-shadow: 0 2px 5px 1px rgba(86, 89, 90, 0.4);
                background-color: #333333;

                a {
                    @include colorVar(color, "blue", 300);
                }
            }
        }

        .html-editor-content-black {
            .content {
                @include colorVar(color, "white");
                background-color: #333333;
                a {
                    @include colorVar(color, "blue", 300);
                }
                h1 {
                    @include colorVar(color, "white");
                }

                h2 {
                    @include colorVar(color, "white");
                }

                p, li {
                    @include colorVar(color, "white");
                }

                ul {
                    @include colorVar(color, "white");
                }

                ol {
                    @include colorVar(color, "white");
                }
            }
        }

        .checkbox {
            padding-top: 18px;
        }

        .incident-solution-tooltip {
            margin-left: 0px;
        }

        .pa-input_phony_search input{
          background-color: #ffffff;
        }
    }
</style>

<template>
    <div style="position: relative;">
        <p-loading-spinner
            v-if="tableLoading"
            style="width: 50px; height: 50px; position: absolute; top: 50%; left: calc(50% - 25px);"
        >
        </p-loading-spinner>

        <vuetable
            id="incident-overview-table"
            ref="incidentTable"
            :api-mode="false"
            :load-on-start="true"
            :fields="incidentTableFields"
            :query-params="{
                sort: 'sort',
                page: 'page',
                perPage: 'count',
            }"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:checkbox-toggled="checkboxToggled"
            @vuetable:checkbox-toggled-all="checkboxToggledAll"
            pagination-path="pagination"
            :per-page="perPage"
            :no-data-template="tableLoading ? '   ' : 'No Incidents found'"
            :class="{ loading: tableLoading }"
            :data="tableData"
        >
            <div slot="incident" slot-scope="props">
                <template v-if="!hasEscalation">
                    <ul class="pa-hList" style="flex-wrap:nowrap; min-width:130px;">
                        <li>
                            <span
                                class="block"
                                :class="props.rowData.incident.className"
                                style="text-decoration: none !important"
                            >
                                {{ props.rowData.incident.title }}
                            </span>
                        </li>
                    </ul>
                    <p-badge
                        :class="props.rowData.severity.additionalClassName"
                        :variant="props.rowData.severity.badge_type"
                    >
                        {{ props.rowData.severity.text }}
                    </p-badge>
                </template>
                <template v-if="hasEscalation">
                    <ul class="pa-hList" style="flex-wrap:nowrap; min-width:130px;">
                        <li v-if="props.rowData.incident.summary">
                            <p-tooltip2
                                :prevent-overflow="true"
                                html-class="html-editor-content html-editor-content-black"
                                placement="bottom"
                            >
                                <span slot="trigger">
                                    <span class="block">
                                        <a
                                            class="pa-txt_medium pa-hover-underline pa-m-0"
                                            :href="props.rowData.incident.link"
                                            target="_blank"
                                        >
                                            {{ props.rowData.incident.title }}
                                        </a>
                                        <p-icon
                                            icon="description"
                                            size="xl"
                                            color="grey-800"
                                            class="pa-p-0"
                                            style="height: 17px;"
                                        >
                                        </p-icon>
                                    </span>
                                </span>
                                <span
                                    style="white-space: normal;"
                                    v-html="props.rowData.incident.summary"
                                >
                                </span>
                            </p-tooltip2>
                        </li>
                        <li v-else>
                            <a
                                :class="[
                                    'block',
                                    props.rowData.incident.className
                                ]"
                                :href="props.rowData.incident.link"
                                target="_blank"
                            >
                                {{ props.rowData.incident.title }}
                            </a>
                        </li>
                        <li class="incident-solution-tooltip"> 
                            <p-tooltip2
                                v-if="props.rowData.incident.relatedSolutionCount > 0 || props.rowData.incident.hasIncidentSolution"
                                :prevent-overflow="true"
                                html-class="incident-solution-text-tooltip"
                                placement="bottom"
                                :hover="props.rowData.incident.hasIncidentSolution || props.rowData.incident.relatedSolutionCount < 1"
                            >
                                <span slot="trigger">
                                    <span class="block">
                                        <p-icon
                                            icon="lit-lightbulb-outline"
                                            size="xl"
                                            :color="props.rowData.incident.hasIncidentSolution ? 'yellow' : 'grey-800'"
                                            class="pa-p-0"
                                            style="height: 17px;"
                                        >
                                        </p-icon>
                                    </span>
                                </span>
                                <template v-if="props.rowData.incident.hasIncidentSolution">
                                    <span style="white-space: normal;">
                                        {{ `Solution(s): ${props.rowData.incident.incidentSolutionNames}` }}
                                    </span>
                                </template>
                                <template v-else>
                                    <a 
                                        :href="`/outage/Outage?outage_id=${props.rowData.id}&open_incident_solution=true`" 
                                        class="pa-txt_medium" 
                                        target="_blank"
                                        v-if="canCollab"
                                    >
                                        {{ `${props.rowData.incident.relatedSolutionCount} possible solutions identified` }}
                                    </a>
                                    <span v-else>
                                        {{ `${props.rowData.incident.relatedSolutionCount} possible solutions identified` }}
                                    </span>
                                </template>
                            </p-tooltip2>
                        </li>
                    </ul>
                    <p-badge
                        :class="props.rowData.severity.additionalClassName"
                        :variant="props.rowData.severity.badge_type"
                    >
                        {{ props.rowData.severity.text }}
                    </p-badge>
                    <br />
                    <p-badge
                        :class="props.rowData.excluded.additionalClassName"
                        :variant="props.rowData.excluded.badge_type"
                    >
                        {{ props.rowData.excluded.text }}
                    </p-badge>
                </template>
            </div>
            <div slot="incident-time" slot-scope="props" style="min-width: 150px;">
                <p class="pa-txt_medium pa-m-0">
                    {{ props.rowData.startTime }}
                    <div class="block">
                        <p-badge v-if="!props.rowData.incident.resolved"
                            :class="props.rowData.status.additionalClassName"
                            :variant="props.rowData.status.badge_type">
                            {{ props.rowData.status.text }}
                        </p-badge>
                        <span class="pa-txt_xs pa-txt_normal pa-text-grey-500">
                            {{ props.rowData.duration }}
                        </span>
                    </div>
                </p>
            </div>
            <div slot="instance" slot-scope="props">
                <a v-if="props.rowData.instance.link && props.rowData.instance.status !== 'disabled' && !props.rowData.instance.disabled"
                    :href="props.rowData.instance.link"
                    :class="['pa-anchor', props.rowData.instance.className || null]"
                    target="_blank">
                    {{ props.rowData.instance.title }}
                </a>
                <p v-else
                    class='pa-txt'>
                    {{ props.rowData.instance.title }}
                </p>
            </div>
            <div slot="services_affected" slot-scope="props">
                <span v-for="service in props.rowData.services_affected">
                    <ul v-if="service.title">
                        <li>{{ service.title }}</li>
                    </ul>
                    <p>{{ service.description }}</p>
                </span>
            </div>
            <div slot="icons" slot-scope="props">
                <ul class="pa-flex pa-align-center pa-justify-center">
                    <!-- Lead -->
                    <li
                        v-if="canCollab && hasEscalation"
                        class="lead-icon pa-m-0"
                        :class="{ 'is-empty-icon': !props.rowData.icons.hasLead }"
                    >
                        <template v-if="props.rowData.icons.hasLead">
                            <p-flyout
                                    ref="leadFlyout"
                                    scroll-lock
                                    hover
                                    :hover-timeout="0"
                                    rounded
                                    positioning="popper"
                                    popper-direction="top"
                                    variant="black"
                                    no-arrow
                                    width="auto"
                                >
                                    <p-avatar
                                        slot="trigger"
                                        :src="props.rowData.icons.incidentLead.avatarUrl"
                                        rounded-full
                                        gravatar-size="24"
                                        :initials="props.rowData.icons.incidentLead.initials"
                                    >
                                    </p-avatar>
                                    <div slot="content">
                                        <p class="no-wrap">
                                            {{ props.rowData.icons.incidentLead.displayName }}
                                        </p>
                                    </div>
                            </p-flyout>
                        </template>
                        <template v-else>
                            <p-flyout
                                scroll-lock
                                hover
                                :hover-timeout="0"
                                rounded
                                positioning="popper"
                                popper-direction="top"
                                variant="black"
                                no-arrow
                                width="auto"
                            >
                                <p-select
                                    slot="trigger"
                                    defaulttext="No Incident Lead has been assigned"
                                    defaultemptytext="No Incident Lead has been assigned"
                                    :searchable="true"
                                    search-label="Select Lead"
                                    html-class="pa-select-text-highlight pa-p-0"
                                    :list-width="250"
                                    :options="userOptions"
                                    :on-change-callback="updateIncidentLead.bind(null, props.rowData)">
                                    <p-icon slot="display-text"
                                        icon="avatar"
                                        size="xxxl"
                                        color="blue">
                                    </p-icon>
                                </p-select>
                                <div slot="content">
                                    <p class="no-wrap">Incident has no lead assigned yet</p>
                                </div>
                            </p-flyout>
                        </template>
                    </li>

                    <!-- CounterMeasures -->
                    <li
                        v-if="canCollab && hasEscalation"
                        class="cm-icon pa-m-0"
                        :class="{ 'is-empty-icon': !props.rowData.icons.hasCm }"
                    >
                        <template v-if="props.rowData.icons.hasCm">
                            <a
                                v-if="props.rowData.icons.cm.drawer"
                                href="#"
                                v-p-drawer-open
                                target="cm-drawer"
                                :template="props.rowData.icons.cm.drawer.link"
                            >
                                <p-icon
                                    :style="{ fill: props.rowData.icons.cm.fill }"
                                    :icon="props.rowData.icons.cm.icon"
                                    size="xxxl"
                                >
                                </p-icon>
                            </a>
                            <a
                                v-else
                                href="#"
                                v-p-dialog-open
                                target="dynamic_modal"
                                template="props.rowData.icons.cm.modal.link"
                            >
                                <p-icon
                                    :style="{ fill: props.rowData.icons.cm.fill }"
                                    :icon="props.rowData.icons.cm.icon"
                                    size="xxxl"
                                >
                                </p-icon>
                            </a>
                        </template>
                        <template v-else>
                            <p-flyout
                                ref="cmFlyout"
                                scroll-lock
                                hover
                                :hover-timeout="0"
                                rounded
                                positioning="popper"
                                popper-direction="top"
                                variant="black"
                                no-arrow
                                width="auto"
                            >
                                <p-icon
                                    slot="trigger"
                                    color="blue"
                                    icon="beaker"
                                    size="xxxl"
                                >
                                </p-icon>
                                <div slot="content">
                                    <p class="no-wrap">
                                        Incident has no CounterMeasures
                                    </p>
                                </div>
                            </p-flyout>
                        </template>
                    </li>

                    <!-- Maintenance -->
                    <li
                        v-if="canAddMaint"
                        class="maint-icon pa-m-0"
                        :class="{
                            'is-empty-icon': !props.rowData.icons.hasMaint
                        }"
                    >
                        <template v-if="props.rowData.icons.hasMaint">
                            <a
                                href="#"
                                @click="eventHub.$emit('drawer:load', {
                                    id: 'custom-maint-drawer',
                                    url: `/config/EditMaintenanceSchedule?maintenance_schedule_id=${props.rowData.icons.maintBadge.flyout.trigger_schedule}`
                                })"
                                v-p-flyout-open
                                :template="props.rowData.icons.maintBadge.flyout.url"
                                :width="props.rowData.icons.maintBadge.flyout.width"
                                direction="left"
                                style="position: relative;"
                            >
                                <p-icon
                                    color="blue"
                                    icon="maintenance"
                                    size="xxxl"
                                    class="pa-clickable"
                                >
                                </p-icon>
                            </a>
                        </template>
                        <template v-else>
                            <p-flyout
                                ref="maintflyout"
                                scroll-lock
                                hover
                                :hover-timeout="0"
                                rounded
                                positioning="popper"
                                popper-direction="top"
                                variant="black"
                                no-arrow
                                width="auto"
                            >
                                <template slot="trigger">
                                    <p-menu>
                                        <p-icon
                                            slot="trigger"
                                            color="blue"
                                            icon="maintenance"
                                            size="xxxl"
                                            class="pa-clickable"
                                        >
                                        </p-icon>
                                        <template v-for="minute in [5, 15, 30, 60]">
                                            <a
                                                @click="eventHub.$emit('immediate-maint', {
                                                    minute: minute,
                                                    instance: props.rowData.instance.id
                                                })"
                                            >
                                                Immediate: {{minute}} min
                                            </a>
                                        </template>
                                        <button
                                            @click="eventHub.$emit('drawer:load', {
                                                id: 'custom-maint-drawer',
                                                url: `/config/EditMaintenanceSchedule?server_ids=s-${props.rowData.instance.id}`
                                            })"
                                        >
                                            Custom
                                        </button>
                                    </p-menu>
                                </template>
                                <div slot="content">
                                    <p class="no-wrap">Instance has no scheduled maintenance</p>
                                </div>
                            </p-flyout>
                        </template>
                    </li>

                    <!-- Ack -->
                    <li
                        v-if="canCollab"
                        class="ack-icon pa-m-0"
                        :class="{
                            'is-empty-icon': !props.rowData.icons.isAcked
                        }"
                    >
                        <template v-if="hasEscalation">
                            <p-flyout
                                ref="ackflyout"
                                scroll-lock
                                hover
                                :hover-timeout="0"
                                rounded
                                positioning="popper"
                                popper-direction="top"
                                variant="black"
                                no-arrow
                                width="auto"
                            >
                                <button
                                    slot="trigger"
                                    class="pa-btn--unstyled"
                                    @click="openAcknowledgeDrawer(props.rowData.id)"
                                >
                                    <p-icon
                                        color="blue"
                                        icon="acknowledge"
                                        size="xxxl"
                                        class="ack-icon-button"
                                    >
                                    </p-icon>
                                </button>
                                <div slot="content">
                                    <p class="no-wrap">{{ackFlyoutText(props.rowData.icons)}}</p>
                                </div>
                            </p-flyout>
                        </template>
                    </li>
                </ul>
            </div>
        </vuetable>

        <p-drawer id="custom-maint-drawer" :width="550">
            <div slot="trigger"></div>
        </p-drawer>

        <p-drawer id="cm-drawer" :width="550">
            <div slot="trigger"></div>
        </p-drawer>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';
    import Vuetable from 'vuetable-2';
    import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
    import { getOutagesReportData } from "../../api/services/IncidentService";
    Vue.component('vuetable-field-checkbox', VuetableFieldCheckbox);

    import renderIncidentTableColumnsMixin from '../../outage/shared_table/RenderIncidentTableColumnsMixin.js';

    export default Vue.extend({
        components: {
            Vuetable,
        },
        mixins: [renderIncidentTableColumnsMixin],
        data: function() {
            return {
                interval: null,
                tableLoading: true,
                tableData: {},
                pendingRequest: null,
            };
        },
        props: {
            filterStore: {
                type: Object,
                default: () => {},
            },
            urlParams: {
                type: Object,
                default: () => {},
            },
            refreshInterval: {
                type: Number,
                default: 300,
            },
            tenantViewMode: {
                type: String,
                default: "",
            },
            perPage: {
                type: Number,
                default: 25,
            },
            canCollab: {
                type: Boolean,
                default: true,
            },
            hasEscalation: {
                type: Boolean,
                default: true,
            },
            canAddMaint: {
                type: Boolean,
                default: true,
            },
            users: {
                type: Array,
                default: () => [],
            },
            checkboxToggled: {
                type: Function,
                default: () => {},
            },
            checkboxToggledAll: {
                type: Function,
                default: () => {},
            },
            checkboxes: {
                type: Boolean,
                default: true,
            },
        },
        events: {
            'saved-schedule'() {
                this.reloadTable();
            },
            'outage-ackd'() {
                this.reloadTable();
            },
        },
        watch: {
            urlParams: {
                deep: true,
                immediate: true,
                handler: async function() {
                    await this.fetchTableData();
                },
            },
        },
        computed: {
            userOptions() {
                const users = this.users.map(user => ({
                    label: user.displayName,
                    value: user.id,
                }));

                return _.sortBy(users, "label");
            },
            isCrossTenant() {
                return this.tenantViewMode === 'cross';
            },
            incidentTableFields() {
                if (this.isCrossTenant) {
                    return [{
                        name: 'vuetable-field-checkbox',
                        key: 'id',
                        title: '',
                        width: '2%',
                        visible: this.checkboxes,
                    }, {
                        name: 'incident',
                        title: 'INCIDENT',
                        width: '10%',
                    }, {
                        name: 'incident-time',
                        title: 'START TIME',
                        width: '13%',
                    }, {
                        name: 'instance',
                        title: 'INSTANCE',
                        width: '25%',
                    }, {
                        name: 'tenant',
                        title: 'TENANT',
                        width: '25%',
                    }, {
                        name: 'services_affected',
                        title: 'SERVICES AFFECTED',
                        width: '40%',
                    }, {
                        name: 'icons',
                        title: '',
                        width: '10%',
                    }];
                } else {
                    return [{
                        name: 'vuetable-field-checkbox',
                        key: 'id',
                        title: '',
                        width: '2%',
                        visible: this.checkboxes,
                    }, {
                        name: 'incident',
                        title: 'INCIDENT',
                        width: '10%',
                    }, {
                        name: 'incident-time',
                        title: 'START TIME',
                        width: '13%',
                    }, {
                        name: 'instance',
                        title: 'INSTANCE',
                        width: '25%',
                    }, {
                        name: 'services_affected',
                        title: 'SERVICES AFFECTED',
                        width: '40%',
                    }, {
                        name: 'icons',
                        title: '',
                        width: '10%',
                    }];
                }
            },
        },
        methods: {
            async fetchTableData() {
                this.tableLoading = true;

                if (this.pendingRequest) {
                    this.pendingRequest.abort();
                }

                const controller = new AbortController();

                this.pendingRequest = controller;

                try {
                    const response = await getOutagesReportData(this.urlParams, controller.signal);
                    this.tableData = {...response.data};
                    this.pendingRequest = null;
                    this.tableLoading = false;
                } catch (error) {
                    console.error(error);

                    if (error.message !== "canceled") {
                        this.tableLoading = false;
                    }
                }
            },
            changePage(page) {
                if (!this.$refs.incidentTable) {
                    return;
                }

                this.$refs.incidentTable.changePage(page);
            },
            getCheckedRows() {
                if (!this.$refs.incidentTable) {
                    return null;
                }

                return this.$refs.incidentTable.selectedTo;
            },
            getTableData() {
                if (!this.$refs.incidentTable) {
                    return [];
                }

                return this.$refs.incidentTable.tableData;
            },
            reloadTable() {
                if (this.$refs.incidentTable) {
                    this.$refs.incidentTable.reload();
                    this.restartRefreshInterval();
                    this.$refs.incidentTable.selectedTo = [];
                    this.checkboxToggled();
                }
            },
            reloadAndResetTable() {
                if (this.$refs.incidentTable) {
                    // Refresh goes back to page 1 and refreshes data
                    this.$refs.incidentTable.refresh();
                    this.restartRefreshInterval();
                    this.$refs.incidentTable.selectedTo = [];
                    this.checkboxToggled();
                }
            },
            restartRefreshInterval() {
                if (this.interval) {
                    window.clearInterval(this.interval);
                    this.interval = null;
                }

                this.interval = window.setInterval(this.reloadTable, this.refreshInterval * 1000);
            },
            onPaginationData(paginationData) {
                this.$emit('on-pagination-data', paginationData)
            },
            openAcknowledgeDrawer(ids) {
                window.app.rootVue.$broadcast(
                    'acknowledge-incident-drawer:open',
                    {
                        incidentIds: ids,
                        userOptions: [...this.userOptions]
                    }
                );
            },
            ackFlyoutText(data) {
                if (!data.ackedList || !data.ackedList.length) {
                    return 'Incident has not yet been acked';
                }

                const ackedCount = data.ackedList.length;
                const [firstAck, secondAck] = data.ackedList;

                if (ackedCount === 1) {
                    return `Acked by ${firstAck[0]}`;
                } else if (ackedCount === 2) {
                    return `Acked by ${firstAck[0]} & ${secondAck[0]}`;
                }

                return `Acked by ${ackedCount}+ teammates`;
            },
            updateIncidentLead(rowData, incidentLeadId, cell) {
                const incidentId = rowData.id;
                const matchedUser = this.users.find(
                    user => user.id === incidentLeadId
                );
                if (!matchedUser) {
                    return;
                }
                $.ajax({
                    url: '/outage/update_incident_lead',
                    method: 'POST',
                    context: this,
                    data: {
                        outage_id: incidentId,
                        user: matchedUser,
                    },
                }).done(data => {
                    if (data.success && data.incidentLead) {
                        rowData.icons.hasLead = true;
                        rowData.icons.incidentLead = data.incidentLead;
                    }
                });
            },
        },
        vueReady() {
            if (this.refreshInterval) {
                this.restartRefreshInterval();
            }
        },
    });
</script>
