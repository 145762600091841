import escapeStringRegExp from 'escape-string-regexp';

export function stringContains(text = '', filterText = '') {
    const pattern = new RegExp(`${escapeStringRegExp(filterText)}`, 'i');
    if (!text) {
        return false;
    }
    const match = text.match(pattern);

    return match === null ? false : true;
}
