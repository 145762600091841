<style>
    .stoplight {
        width: 40%;
        height: 70%;
        border-radius: 50%;
        top: 20%;
        left: 25%;
        margin: 0 auto;
    }
    .yellow{
        background-image: linear-gradient(to bottom, #FFBB00, #F2A200);
    }

    .green{
        background-image: linear-gradient(to bottom, #5fedc7, #2bd9b0);
        border: solid 1px #45e6cd;
    }

    .red {
        background-image: linear-gradient(to top, #d92121, #f53131);
        border: solid 1px rgba(0, 0, 0, 0.08);
    }

    .stoplight-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
    }

    .stoplight-container .pa-vList {
        padding-bottom: 6px;
        padding-top: 4px;
    }

    .stoplight-circle-container {
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        margin-bottom: 12px;
        padding: 12px 0;
        width: calc(100% - 40px);
    }

    .stoplight-outage-id {
        
        padding-bottom: 6px;
    }
</style>

<template>
    <div :id="id"
         class="grid-stack-item"
         :data-gs-x="localConfig.col.toString()"
         :data-gs-y="localConfig.row.toString()"
         :data-gs-width="localConfig.width.toString()"
         :data-gs-height="localConfig.height.toString()"
         :data-gs-auto-position="false"
         :data-gs-locked="true">
        <div
            class="grid-stack-item-content"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;">
                <div :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered}" :style="{ 'padding-bottom': localConfig.height === 1 ? 0 : 8 }">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_9of12" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                            </span>
                            <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: textColor}"><use xlink:href="#spinner-spin-naked"></use></svg>
                            <p-tooltip2 :normal-white-space="true"> 
                                <span slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: textColor }">
                                    <span v-if="localConfig.title">{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }}</span>
                            </p-tooltip2>
                        </div>
                        <div class="pa-grid-col pa-grid-col_3of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button                                
                                    v-show="showWidgetControls" 
                                    type="button"
                                    slot="trigger"
                                    @click="cloneWidget" 
                                    class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mr-6 pa-mt-6"
                                >
                                    <p-icon icon="copy"></p-icon>
                                </button>
                                <span> Clone this Widget </span>
                            </p-tooltip2>
                        </div>
                    </div>
                </div>
                <div v-if="valid" id="body" class="widget-body" style="overflow-y: auto; flex-grow: 1; margin-bottom: 10px; margin-left: 0; margin-right: 0;" ref="body">
                    <div :style="{ position: accessError ? '' : '' }" :class="{ 'stoplight-container': accessError !== '' }">
                        <div v-if="!needsConfig" class="stoplight-circle-container">
                            <div v-if="content.down" class="stoplight" v-bind:class="[content.critical ? 'red' : '', content.warning ? 'yellow' : '']"></div>
                            <div v-if="content.up" class="stoplight green"></div>
                        </div>
                        <ul class="pa-vList">
                            <div class="pa-txt pa-txt_sm">
                                 {{ content.name }}
                            </div>
                            <div v-if="content.down || content.up" class="pa-txt pa-txt_xs">
                                <a
                                    v-if="content.last_outage_id"
                                    :href="`/outage/Outage?outage_id=${content.last_outage_id}`"
                                    target="_blank"
                                    class="stoplight-outage-id"
                                    :class="{ 'pa-message_error': content.down }"
                                >
                                    Incident ID: {{ content.last_outage_id }}
                                </a>
                                <span v-else style="color: #69717d;">
                                    No Prior Incidents
                                </span>
                            </div>
                        </ul>
                    </div>
                </div>
                <div v-if="needsConfig" class="widget-body" style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center;" ref="temp_body">
                    <div class="vertical-center">
                        <button v-if="$parent.allowEditing" @click="configure" class="pa-btn pa-btn_naked_light_grey_no_fill pa-btn_lg" style="padding: 12px 35px" type="button">
                            <svg class="pa-icon">
                                <use xlink:href="#plus-circle-outline"></use>
                            </svg>
                            <span>Configure</span>
                        </button>
                        <p class="widget-unconfigured centered">
                            Please configure the widget to only track a single metric.
                        </p>
                        <p v-if="accessError" class="widget-unconfigured centered">
                            You do not have access to this metric.
                        </p>
                    </div>
                </div>
                <div v-if="!valid" class="widget-body widget-body_error" :title="error_message" ref="body">
                    <p class="pa-message pa-message_error">
                        <svg class="pa-icon pa-icon_xl"><use xlink:href="#alert-circle"></use></svg>
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import $ from 'jquery';
    import widgetMixin from './../widgetMixin';
   
    const StoplightWidget = Vue.extend({
        mixins: [widgetMixin],
        data() {
            return {
                content: {
                    down: false,
                    critical: false,
                    warning: false,
                    success: true,
                },
                localConfig: this.config,
            };
        },
   
        methods: {
            initialize(config, content) {
                this.contentReady();
                this.localConfig = {...config};
                this.content = content;
                this.$nextTick(() => {
                   this.reconfig();
                });
            },

            getConfigModules() {
                return [
                    { type: 'p-overview-module' },
                    { type: 'p-instance-metric-module' },
                    {
                        type: 'p-custom-module',
                        options: {
                            title: '',
                            controls: [
                                {
                                    id: 'backgroundColor',
                                    label: 'Background Color',
                                    key: 'backgroundColor',
                                    type: 'color-picker',
                                    default: '#ffffff'
                                },
                            ]
                        }
                    },
                ]
            },

            getOptionModules() {
                return [];
            },

            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this);
            },
   
            update(content) {
                this.content = content;
            },
   
            reconfig() {
                const body = this.$refs.body;
                const height = body.clientHeight * .333;
                const width = body.clientWidth * .333;
                const length = Math.min(height, width);
                $(body).find(".stoplight").css({
                    "width": `${length}px`,
                    "height": `${length}px`,
                });
            },
   
            dumpState() {
                const obj = Object();
                obj.data = this.$data;
                obj.config = {...this.localConfig};
                console.log(JSON.stringify(obj));
            },
   
        },
   
        mounted() {
            this.pendNewConfig();
            if (this.id < 0) {
                this.configure();
            }
        },
    });
   
    export default StoplightWidget;
</script>
