<style lang="scss">
    .topology-widget-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .topology-widget-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        vertical-align: middle;
        width: 100%;

        .rerun-btn {
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }

    .topology-widget-player {
        flex-basis: 96%;
    }
</style>

<template>
    <div :id="id"
         :class="!inModal ? 'grid-stack-item' : 'modal-view'"
         :data-gs-x="localConfig.col.toString()"
         :data-gs-y="localConfig.row.toString()"
         :data-gs-width="localConfig.width.toString()"
         :data-gs-height="localConfig.height.toString()"
         :data-gs-auto-position="false"
         :data-gs-locked="true">
        <div
            :class="!inModal ? 'grid-stack-item-content' : 'modal-view-content'"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;">
                <div :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered, 'expand-mode' : !$parent.editing && isHovered && !inModal, 'in-modal': inModal}">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_10of12" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                            </span>
                            <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: textColor}"><use xlink:href="#spinner-spin-naked"></use></svg>
                            <p-tooltip2 :normal-white-space="true">
                                <span v-if="!isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: textColor }">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span v-if="isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }}</span>
                            </p-tooltip2>
                        </div>
                        <div class="pa-grid-col pa-grid-col_2of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger"  v-show="!$parent.editing && isHovered && !inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#arrow-expand"></use>
                                    </svg>
                                </button>
                                <span> Expand </span>
                            </p-tooltip2>
                            <button slot="trigger"  v-show="!$parent.editing && inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                <svg class="pa-icon remove-icon">
                                    <use xlink:href="#close"></use>
                                </svg>
                            </button>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon" >
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>
                        </div>
                    </div>
                </div>
                <div v-show="valid && !needsConfig" id="body" class="widget-body" :style="{'height': inModal && '85vh'}" style="height: inherit; overflow-y: auto; flex-grow: 1; margin-bottom: 10px;" ref="body">
                    <div class="topology-widget-container">
                        <div class="topology-widget-header">
                            <div>
                                <p-button
                                    class="rerun-btn pa-btn_naked_light_blue"
                                    :disabled="pendingNewTopology"
                                    @click="runReport"
                                >
                                    Refresh
                                </p-button>
                                <span v-if="pendingNewTopology" class="pa-txt pa-txt_light pa-txt_sm pa-ml-8">Topology Report pending...</span>
                                <span v-if="runReportError" class="pa-txt pa-txt_crimson pa-txt_sm pa-ml-8">Error requesting report</span>
                            </div>
                            <div>
                                <p v-if="topologyTimestamp" class="pa-txt pa-txt_sm">
                                    <span class="pa-txt pa-txt_light">Last Generated:</span>
                                    <span>{{ topologyDateString }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="topology-widget-player" ref="player-container"></div>
                    </div>
                </div>
                <div v-if="needsConfig" class="widget-body" :style="{'height': inModal && '85vh'}" style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center;" ref="temp_body">
                    <div :class="!inModal && 'vertical-center'">
                        <button v-if="$parent.allowEditing|| allowedEditing" @click="configure" class="pa-btn pa-btn_naked_light_grey_no_fill pa-btn_lg" style="padding: 12px 35px" type="button">
                            <svg class="pa-icon">
                                <use xlink:href="#plus-circle-outline"></use>
                            </svg>
                            <span>Configure</span>
                        </button>
                    </div>
                </div>
                <div v-if="!valid" class="widget-body" :style="{'height': inModal && '85vh'}">
                    <p class="pa-message pa-message_error">
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import Vue from 'vue';
    import $ from 'jquery';
    import widgetMixin from './../widgetMixin';
    import loadCSS from './../../shared/utils/loadCSS.js';
    import loadScript from './../../shared/utils/loadScript.js';

    const topologySetupScript = `
        require(['ftnt_shared', 'd3'], function () {
            require(['app', 'services'], function (app) {
                var $html = angular.element(document.documentElement);
                angular.element(document).ready(function () {
                    var injector = angular.bootstrap($html, [app.name]);
                });
            });
        });
    `;
    window.bar = topologySetupScript;

    const topologyPlayerHTML = `
        <div ng-controller="FFabricTopologySampleController" class="outer">
            <div class='sample-container'>
                <f-fabric-topology
                    settings="sampleTopologySettings"
                    topology-state="sampleTopologyState"
                    search-handler="sampleTopologySearch(query, result)"
                    graph="sampleTopology.graph"
                    topology-description="sampleTopology.description"
                    search-state="sampleTopology.searchState"
                    reload-request-event="sampleTopology.eventIDs.reloadRequest"
                    shrink-execute-event="sampleTopology.eventIDs.shrinkExecute"
                    shrink-request-event="sampleTopology.eventIDs.shrinkRequest"
                    style="width: 100%;">
                    <topology-menu>
                    </topology-menu>
                    <topology-content class="flex-grow flex-row-stretch">
                        <f-topology-renderer
                            class="flex-grow flex-row-stretch"
                            graph="sampleTopology.graph"
                            interact-setup="interactSetup"
                            interact-clean-up="interactCleanUp()"
                            search-state="sampleTopology.searchState"
                            shrink-execute-event="sampleTopology.eventIDs.shrinkExecute">
                        </f-topology-renderer>
                    </topology-content>
                </f-fabric-topology>
            </div>
        </div>
        <style>
            .outer.ng-scope,
            .sample-container {
                height: 100%;
            }
        </style>

    `;

    const DATE_FMT = 'MM/DD/YYYY HH:mm:ss zz';

    const HTMLWidget = Vue.extend({
        mixins: [widgetMixin],

        data() {
            return {
                topologyTimestamp: null,
                topologyData: '',
                shadow: null,
                dependenciesLoaded: false,
                pendingNewTopology: false,
                runReportError: false,
                asyncTaskId: null,
                numAsyncTaskPolls: 0,
                timeoutId: null,
                localConfig: this.config,
                allowedEditing: this.$parent.allowEditing || this.$parent.$parent.allowEditing,
                inModal: false,
            };
        },

        events: {
        },

        props: {
            config: {
                type: Object,
                default() {
                    return {
                        custom_controls: {},
                    };
                },
            }
        },

        watch: {
            dependenciesLoaded(val, oldVal) {
                this.reloadTopologyPlayer();
            },

            topologyData(val, oldVal) {
                this.reloadTopologyPlayer();
            },
        },

        computed: {
            topologyDateString() {
                if (!this.topologyTimestamp) {
                    return null;
                }
                const tstamp = window.moment.unix(this.topologyTimestamp).utc();
                if (window.userTZ) {
                    tstamp.tz(window.userTZ);
                } else {
                    tstamp.tz(window.moment.tz.guess());
                }
                return tstamp.format(DATE_FMT);
            },

            // Overloaded from widgetMixin
            needsConfig: function() {
                if (typeof this.content === 'undefined' ||
                        typeof this.content.success === 'undefined') {
                    return false;
                }

                if (this.localConfig &&
                        this.localConfig.custom_controls &&
                        this.localConfig.custom_controls.fabric_connection_id) {
                    return false;
                }

                return true;
            },
        },

        methods: {
            expandSelf() {
                this.inModal = !this.inModal
            },
            getAngularScope() {
                const root = this.shadow.children[0];
                if (!root) {
                    console.error('Can\'t find div.outer.ng-scope');
                    return null;
                }
                return window.angular.element(root).scope();
            },

            reloadTopologyPlayer() {
                if (!this.dependenciesLoaded || !this.topologyData) {
                    return;
                }
                const scope = this.getAngularScope();
                scope.jsonData = this.topologyData;
                scope.doReload();
            },

            runReport() {
                this.asyncTaskId = null;
                this.numAsyncTaskPolls = 0;
                this.runReportError = false;
                $.ajax('/dashboardv2/run_topology_report', {
                    method: 'POST',
                    context: this,
                    data: {
                        fabric_connection_id: this.localConfig.custom_controls.fabric_connection_id,
                    },
                })
                .done(function(data) {
                    if (data.success) {
                        this.pendingNewTopology = true;
                        this.asyncTaskId = data.async_task_id;
                        this.timeoutId = window.setTimeout(this.pollAsyncTask, 5000);
                    } else {
                        console.error(data);
                        this.pendingNewTopology = false;
                        this.runReportError = true;
                    }
                })
                .fail(function(data) {
                    console.error(data);
                });
            },

            pollAsyncTask() {
                $.ajax('/util/get_async_task_status', {
                    method: 'GET',
                    context: this,
                    data: {
                        async_task_id: this.asyncTaskId,
                    },
                })
                .done(function(data) {
                    this.numAsyncTaskPolls += 1;
                    if (data.success) {
                        if (data.status === 'complete') {
                            this.numAsyncTaskPolls = 0;
                            this.pendingNewTopology = false;
                            this.timeoutId = null;
                        } else if (data.status === 'error') {
                            this.numAsyncTaskPolls = 0;
                            console.error(data);
                            this.pendingNewTopology = false;
                            this.runReportError = true;
                            this.timeoutId = null;
                        } else if (this.numAsyncTaskPolls < 30) {
                            this.timeoutId = window.setTimeout(this.pollAsyncTask, 5000);
                        } else {
                            this.numAsyncTaskPolls = 0;
                            console.error('Timed out polling AsyncTask');
                            this.pendingNewTopology = false;
                            this.runReportError = true;
                            this.timeoutId = null;
                        }
                    } else {
                        console.error(data);
                        this.runReportError = true;
                    }
                })
                .fail(function(data) {
                    console.error(data);
                    this.pendingNewTopology = false;
                    this.runReportError = true;
                });
            },

            getConfigModules() {
                return [
                    { type: 'p-overview-module' },
                    { type: 'p-fabric-module' },
                ];
            },

            getOptionModules() {
                return [
                ];
            },

            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this);
            },

            loadSetupScript() {
                if (!this.shadow || !this.shadow.children) {
                    return;
                }

                const root = this.shadow.children[0];

                window.require(['ftnt_shared', 'd3'], () => {
                    window.require(['app', 'services'], app => {
                        const $html = window.angular.element(root);
                        window.angular.element(root).ready(() => {
                            const injector = window.angular.bootstrap($html, [app.name]);
                            window._topologyDependenciesLoaded = true;
                            this.dependenciesLoaded = true;
                        });
                    });
                });
            },

            loadDependencies() {
                if (window._topologyDependenciesLoaded) {
                    return;
                }

                const jsDependencies = [
                    '/static/libs/fortinet/require.js',
                    '/static/libs/fortinet/require.config.js',
                    '/static/libs/fortinet/angular.js',
                    '/static/libs/fortinet/angular-sanitize.js',
                    '/static/libs/fortinet/angular-cookies.js',
                ];

                const load = i => {
                    if (i < jsDependencies.length) {
                        loadScript(jsDependencies[i], () => {
                            // eslint-disable-next-line no-param-reassign
                            load(++i);
                        });
                    } else {
                        this.loadSetupScript();
                    }
                };

                load(0);
            },

            // widgetMixin methods
            initialize(config, content) {
                this.content = content;
                this.localConfig = {...config};

                if (content.success && content.topology_timestamp) {
                    this.topologyTimestamp = content.topology_timestamp;
                    this.topologyData = JSON.stringify(content.topology_json);
                }
            },

            update(content) {
                if (!content.success || !content.new_topology_timestamp) {
                    return;
                }
                this.topologyTimestamp = content.new_topology_timestamp;
                this.topologyData = JSON.stringify(content.new_topology_json);
                this.pendingNewTopology = false;
                clearTimeout(this.timeoutId);
            },

            reconfig() {},

            attachShadow() {
                this.shadow = this.$refs['player-container'].attachShadow({
                    mode: 'open',
                });
                this.shadow.innerHTML = topologyPlayerHTML;

                const cssDependencies = [
                    '/static/libs/fortinet/topology/ftnt_shared-green.css',
                ];

                for (const url of cssDependencies) {
                    const linkEl = document.createElement('link');
                    linkEl.rel = 'stylesheet';
                    linkEl.href = url;

                    this.shadow.appendChild(linkEl);
                }
            },
        },

        created() {
            this.loadDependencies();
        },

        mounted() {
            if (this.id < 0) {
                this.configure();
            }

            this.$nextTick(() => {
                this.attachShadow();
            });
        },
    });

    export default HTMLWidget;
</script>
