<style>
 #metric_tags {
     width: 100%;
 }
</style>

<template>
    <div class="module">
        <div ref="body" v-show="open" transition="module-expand" class="module-body">
            <div v-if="!ready || metricOptions.length">
                <div class="pa-field pa-field_vertical">
                    <div class="pa-field-hd">
                        <label class="pa-label">Metrics</label>
                    </div>
                    <div class="pa-field-bd">
                        <p-typeahead-select
                            ref="metricselect"
                            :force-in-modal="true"
                            :disabled="!ready"
                            :model="selectedMetrics"
                            :optgroups="sameUnit"
                            placeholder="Search metrics"
                            :on-change-callback="metricsChanged">
                        </p-typeahead-select>
                    </div>
                    <div class="pa-field-ft pa-mt-0">
                        <p class="pa-hint">
                            You can search by metric (e.g., CPU % Used) or catgeory (CPU)
                        </p>
                    </div>
                </div>
                <div class="pa-field pa-field_vertical" v-if="options.multiple">
                    <div class="pa-field-bd" :class="{ 'pa-pb-6 module-border': secondaryFilter !== 'none' }">
                        <p-switch
                            label="Additional Metric Filters"
                            label-position="right"
                            :model.sync="displaySecondaryFilter"
                            :on-change="toggleIsSecondaryFilter"
                            class="pa-pb-10 pa-pt-24"
                            variant="blue-500"
                        >
                        </p-switch>
                        <label class="pa-option pa-mr-16 pa-pb-10" :key="option.value" v-for="option in filteredSecondaryFilterOptions">
                            <input class="pa-option-input" type="radio" :value="option.value" name="secondaryFilter" v-model="secondaryFilter">
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt pa-txt-grey-700">{{ option.label }}</span>
                        </label>
                    </div>
                    <div class="pa-field-ft">
                    </div>
                </div>
                <div class="pa-field pa-field_vertical pa-pt-16" v-if="filterTags">
                    <div class="pa-field-hd">
                        <label for="template" class="pa-label">Match Tags by</label>
                    </div>
                    <div class="pa-field-bd" style="text-align: left;">
                        <label class="pa-option" style="padding-bottom: 10px; padding-right: 10px;">
                            <input v-model="tagsType" value="any" type="radio" class="pa-option-input"/>
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt">
                                Match Any
                            </span>
                        </label>
                        <label class="pa-option" style="padding-bottom: 10px">
                            <input v-model="tagsType" value="all" type="radio" class="pa-option-input" />
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt">
                                Match All
                            </span>
                        </label>
                        <p-tags-input
                            id="metric_tags"
                            ref="tags_input"
                            name="tags[]"
                            :model.sync="tags"
                            :autocomplete="true"
                            :suggestions="allTags"
                            placeholder="Enter tags"
                        >
                        </p-tags-input>
                    </div>
                    <div class="pa-field-ft pa-mt-0 pa-pb-24">
                        <p class="pa-hint">Hit enter or tab after each tag</p>
                    </div>
                </div>
                <div v-if="filterOptionString" style="margin-top: 5px;">
                    <input
                        v-model="optionString"
                        debounce="1000"
                        type="text"
                        class="pa-input"
                        placeholder="Enter option string"
                    />
                </div>
            </div>
            <div v-else>
                <p class="pa-txt pa-txt_light" style="padding: 15px 0;">You're not monitoring any metrics yet. Head over to one of your instances to add monitoring.</p>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const InstanceModule = Vue.extend({
        events: {
        },

        data() {
            return {
                ready: false,
                available_metrics: {},
                metricOptions: [],
                selectedMetrics: [],
                app_textkeys: [],
                metrics: {},
                unit: null,
                tagsType: 'any',
                tags: [],
                optionString: '',
                displaySecondaryFilter: false,
                secondaryFilter: 'none',
                secondaryFilterOptions: [
                    {
                        label: 'By Tags',
                        value: 'tags',
                    },
                    {
                        label: 'By Option String',
                        value: 'optionString',
                    },
                ],
            };
        },

        props: {
            options: {
                type: Object,
                default: function() {
                    return {};
                },
            },
            open: {
                type: Boolean,
                default: true,
            },
            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        },

        computed: {
            filteredSecondaryFilterOptions: function() {
                if (this.displaySecondaryFilter) {
                    return this.secondaryFilterOptions;
                }

                return [];
            },
            filterTags: function() {
                return this.secondaryFilter === 'tags';
            },
            filterOptionString: function() {
                return this.secondaryFilter === 'optionString';
            },
            sameUnit: function() {
                if (!this.options.sameUnit || this.unit === null) { 
                    return this.metricOptions; 
                }
                
                let metricOptionsCopy = _.cloneDeep(this.metricOptions)
                for(let cat of metricOptionsCopy) {
                    const newSubOpts = [];
                    for(let subCat of cat.options) {
                        subCat.disabled = false;
                        if (this.unit == 'network' && subCat.check_method == 'network') {                          
                            newSubOpts.push(subCat);
                        } else if (subCat.unit == this.unit) {
                            newSubOpts.push(subCat);
                        } else {
                            subCat.disabled = true;
                            newSubOpts.push(subCat);
                        }
                    }
                    cat.options = newSubOpts;
                }

                return metricOptionsCopy;
            },
            headerText: function() {
                if (!this.metricOptions.length) {
                    return '';
                }
                let metricCategories = [];
                this.$refs.metricselect.modelLabels.forEach(l => {
                    if (!l.label) {
                        return;
                    }
                    const newLabel = l.label.split(':')[0];
                    if (!metricCategories.includes(newLabel)) {
                        metricCategories.push(newLabel);
                    }
                });
                if (metricCategories.length) {
                    metricCategories = metricCategories.join(', ');
                    return `(${metricCategories})`;
                } else {
                    return '';
                }
            },
            allTags: function() {
                if (window.dashboard && window.dashboard.resellerDashboard) {
                    return [];
                } else if (this.$root.customerConfig) {
                    return this.$root.customerConfig.allTags;
                } else {
                    return [];
                }
            },
        },

        events: {
            'config:update_metrics': function() {
                this.getAvailableMetrics(() => {
                    Vue.nextTick(() => {
                        this.ready = true;
                    });
                });
            },
            'config:wait_for_metrics': function() {
                // const button = this.$refs.app_select.$els.button;
                // button.disabled = true;
            },
        },

        methods: {
            toggleIsSecondaryFilter() {
                this.displaySecondaryFilter = !this.displaySecondaryFilter;
            },
            toggle() {
                this.open = !this.open;
            },
            sendConfig() {
                if (!this.ready) { return; }

                this.$parent.updatePending(this.editingWidget.id);

                const payload = {
                    widget_id: this.editingWidget.id,
                    metrics: this.selectedMetrics,
                    tags_match: this.tagsType,
                    tags: this.tags,
                    filter_tags: this.filterTags,
                    filter_option_string: this.filterOptionString,
                    option_string: this.optionString,
                };
                $.ajax({
                    url: '/dashboardv2/setWidgetMetrics',
                    method: 'POST',
                    data: payload,
                })
                    .done(data => {
                        if (data.success && data.newConfig) {
                            this.editingWidget.localConfig = data.newConfig;
                        }
                    });
                this.editingWidget.pendNewConfig();
                
            },

            metricsChanged(model) {
                this.sendConfig();
            },

            getAvailableMetrics(callback) {
                const widgetId = this.editingWidget.id;
                if (widgetId < 0) { return; }

                // First grab our copy from local storage (if exists)
                const localAvailableMetrics =
                    window.app.localStorageManager.getObject('metrics.available', {});

                const payload = {
                    widgetId: widgetId,
                    resellerDashboard: window.dashboard.resellerDashboard,
                };
                // Include all the keys from our local copy, minus the actual options
                Object.entries(localAvailableMetrics).forEach(e => {
                    const key = e[0];
                    const val = e[1];
                    if (e[0] !== 'options') {
                        payload[key] = val;
                    }
                });

                $.ajax({
                    url: '/dashboardv2/getAvailableMetrics',
                    method: 'GET',
                    context: this,
                    data: payload,
                })
                 .done(function(data) {
                     if (!data.success) { return; }

                     if (data.new_value) {
                         this.metricOptions = data.metric_options.options;
                         // Store the new copy
                         window.app.localStorageManager
                             .setObject('metrics.available', data.metric_options);
                     } else if (localAvailableMetrics) {
                         this.metricOptions = localAvailableMetrics.options;
                     } else {
                         console.error('Cannot get metrics');
                         this.metricOptions = [];
                     }

                     // Call the callback first
                     if (typeof callback !== 'undefined') {
                         callback();
                     }
                 });
            },

            sortOptions(opts) {
                // sort() works in place
                // we don't want infinite loop
                const sorted = opts.slice();
                sorted.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
                return sorted;
            },

            setUnit() {
                if(!this.selectedMetrics) {
                    return;
                }

                let selectedMetricKey = this.selectedMetrics[0]

                for(let cat of this.metricOptions) {
                    for(let subCat of cat.options) {
                        if(subCat.value == selectedMetricKey) {
                            if(subCat.check_method == 'network') {
                                this.unit = 'network'
                            }
                            else {
                                this.unit = subCat.unit;
                            }

                            return;
                        }

                    }
                }
            },
        },

        watch: {
            'selectedMetrics': {
                handler: function(value) {
                    if (!this.metricOptions) {
                        return; 
                    }
                    if (this.unit === null) {
                       this.setUnit();
                    } 
                    if(!this.selectedMetrics.length) {
                        this.unit = null;
                    }
                },
                deep: true,
            },
            'tags': function(value, prev) {
                this.sendConfig();
            },
            'tagsType': function(value) {
                this.sendConfig();
            },
            'optionString': function(value) {
                this.sendConfig();
            },
            'secondaryFilter': function(value) {
                if (!this.ready) { return; }
                // Changing filter type
                // Clear out the models
                this.ready = false;
                this.tags = [];
                this.optionString = '';
                if (value !== 'none') {
                    this.displaySecondaryFilter = true;
                }
                Vue.nextTick(() => {
                    this.ready = true;
                    this.sendConfig();
                });
            },
            'displaySecondaryFilter': function(value) {
                if (!this.ready || value) { return; }

                this.ready = false;
                this.secondaryFilter = 'none';
                this.tags = [];
                this.optionString = '';
                Vue.nextTick(() => {
                    this.ready = true;
                    this.sendConfig();
                });
            },
        },

        vueReady() {
            this.getAvailableMetrics(() => {
                const selectedMetrics = this.editingWidget.localConfig.metric_textkeys;
                if (typeof selectedMetrics !== 'undefined') {
                    const agentMetrics = selectedMetrics.agent;
                    const networkMetrics = selectedMetrics.network;
                    if (typeof agentMetrics !== 'undefined') {
                        Object.entries(agentMetrics).forEach(entry => {
                            const category = entry[0];
                            const metrics = entry[1];
                            metrics.forEach(m => {
                                this.selectedMetrics.push(`${category}#${m}`);
                            });
                        });
                    }
                    if (typeof networkMetrics !== 'undefined') {
                        Object.entries(networkMetrics).forEach(entry => {
                            const category = entry[0];
                            const metrics = entry[1];
                            metrics.forEach(m => {
                                this.selectedMetrics.push(`${category}#${m}`);
                            });
                        });
                    }
                    const w = this.editingWidget;
                    if (typeof w.localConfig.custom_controls !== 'undefined') {
                        if (w.localConfig.custom_controls.metrics_filter_tags) {
                            this.secondaryFilter = 'tags';
                            this.tagsType = w.localConfig.custom_controls.metrics_tag_match;
                            this.tags = w.localConfig.custom_controls.metrics_tags;
                        } else if (w.localConfig.custom_controls.filter_option_string) {
                            this.secondaryFilter = 'optionString';
                            this.optionString = w.localConfig.custom_controls.option_string;
                        }
                    }

                    if (this.secondaryFilter !== 'none') {
                        this.displaySecondaryFilter = true;
                    }
                }
                Vue.nextTick(() => {
                    this.ready = true;
                    this.setUnit();
                });
            });
        },
    });

    export default InstanceModule;
</script>
