<template>
    <td :data-search="searchText" v-bind:class="{'pa-table-no-right-padding': model.type === 'menu'}">

        <div
            v-if="model.type === 'basic'"
            v-html="model.text"></div>

        <div v-if="model.type === 'avatar'">
            <div class="pa-media pa-media_center">
                <div class="pa-media-hd">
                    <img
                        :src="model.img.src"
                        :alt="model.img.alt"
                        class="pa-img pa-img_avatar" />
                </div>
                <div class="pa-media-bd">
                    {{ model.text }}
                </div>
            </div>
        </div>

        <ul
            class="pa-vList"
            v-if="model.type === 'list'">
            <li v-for="item in model.items">
                <div class="pa-media pa-media_center">
                    <div
                        v-if="item.icon"
                        class="pa-media-hd">
                        <svg class="pa-icon"><use :xlink:href="`#${item.icon}`"></use></svg>
                    </div>
                    <div
                        v-html="item.text"
                        class="pa-media-bd"></div>
                </div>
            </li>
        </ul>

        <ul
            class="pa-hList pa-hList_x2"
            v-if="model.type === 'buttons'">
            <li v-for="button in model.buttons">
                <button
                    type="button"
                    class="pa-phonyButton"
                    v-if="button.action === 'modal'"
                    v-p-dialog-open
                    :target="button.target"
                    :template="button.template">
                    <svg class="pa-icon"><use :xlink:href="`#${button.icon}`"></use></svg>
                    <span class="pa-isVisuallyHidden">{{ button.text }}</span>
                </button>
                <a
                    :href="button.href"
                    v-if="button.action === 'link'">
                    <svg class="pa-icon"><use :xlink:href="`#${button.icon}`"></use></svg>
                    <span class="pa-isVisuallyHidden">{{ button.text }}</span>
                </a>
            </li>
        </ul>

        <p-material-menu v-if="model.type === 'menu'">
            <template v-for="item in model.items">
            <button v-if="item.action === 'prompt'"
                v-p-prompt-open
                :title="item.title"
                :body="item.body"
                :target="item.target"
                :href="item.template">
                {{ item.text }}
            </button>
            <button v-if="item.action === 'modal'"
                :href="item.href"
                v-p-dialog-open
                :target="item.target"
                :template="item.template">
                {{ item.text }}
            </button>
            </template>
        </p-material-menu>

        <label
            v-if="model.type === 'checkbox'"
            class="pa-option">
            <input
                :name="model.name"
                type="checkbox"
                class="pa-option-input"
                v-model="model.isChecked"
                v-on:change="_onchange()" />
            <span class="pa-option-icon"></span>
            <span class="pa-option-txt">{{ model.text }}</span>
        </label>

        <span
            v-if="model.type === 'icon'">
            <svg class="pa-icon"><use :xlink:href="`#${model.icon}`"></use></svg>
            <span class="pa-isVisuallyHidden">{{ model.alias }}</span>
        </span>

    </td>
</template>

<script>
    import Vue from 'vue';

    const TableCell = Vue.extend({
        created() {
            if (this.model.type === 'list') {
                this.model.items.forEach((item) => {
                    this.searchText += `${item.text} `;
                });
            } else {
                this.searchText += `${this.model.alias || this.model.text || ''} `;
            }
        },

        data() {
            return {
                searchText: ''
            };
        },

        props: {
            id: {
                type: String,
                default: ''
            },

            model: {
                type: Object,
                default: () => {}
            }
        },

        methods: {
            _onchange() {
                this.eventHub.$emit('tcell:option:changed', Object.assign({}, this.model));
            }
        }
    });

    export default TableCell;
</script>
