<template>
    <p class="pa-hint">
        {{ hint }}
        <slot></slot>
    </p>
</template>

<script>
    import Vue from 'vue';

    const Hint = Vue.extend({
        props: {
            hint: {
                type: String,
            },
        },
    });

    export default Hint;
</script>
