<style>
.calc-row {
    display: flex;
}
.calc-button {
    display: flex;
    flex-direction: column;
    width: 55px;
    height: 55px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 4px;
    background: #f1f1f1;
    border: 1px solid #989898;
    cursor: pointer;
}
.calc-button svg {
    align-self: center;
}
</style>

<template>
    <div style="">
        <div ref="arrowbox" class="arrow_box animated fadeIn" style="display:none;">
            <div class="calc-row">
                <div class="calc-button">
                    <svg class="pa-icon pa-icon_xxxxl"><use xlink:href="#sigma"></use></svg>
                    <span>Sum</span>
                </div>
                <div class="calc-button">
                    <svg class="pa-icon pa-icon_xxxxl"><use xlink:href="#arrow-down"></use></svg>
                    <span>Min</span>
                </div>
                <div class="calc-button">
                    <svg class="pa-icon pa-icon_xxxxl"><use xlink:href="#arrow-up"></use></svg>
                    <span>Max</span>
                </div>
                <div class="calc-button">
                    <svg class="pa-icon pa-icon_xxxxl"><use xlink:href="#average"></use></svg>
                    <span>Average</span>
                </div>
            </div>
            <div @click="close" class="pa-modal-overlay"></div>
        </div>
        <a ref="icon" @click="show"><svg class="pa-icon"><use xlink:href="#sigma"></use></svg></a>
    </div>
</template>

<script>
    import Vue from 'vue';
    import $ from 'jquery';

    const CalculationControl = Vue.extend({

        data() {
            return {
            }
        },

        events: {
        },

        methods: {
            show: function() {
                this.$refs.arrowbox.style.display = "";
                var left = (this.$refs.arrowbox.getBoundingClientRect().width/-2 + 8) + "px";
                this.$refs.arrowbox.style.left = left;
                $(this.$refs.arrowbox).find("select").one("change", this.close);
            },
            close: function(e) {
                this.$refs.arrowbox.style.display = "none";
            },
            value_change: function() {
                this.timescale = $(this.$refs.arrowbox).find("select").val();
                var override = {type: "timescale", value: this.timescale};
                window.app.rootVue.$broadcast("widget:override_change", this.$parent.id, override);
            }
        },

        props: {
            timescale: {
                type: String,
                default: ""
            }
        },

        computed: {
        },

        vueReady() {
            $(this.$refs.arrowbox).find("select").on("change", this.value_change);
        }
    });

    export default CalculationControl;
</script>
