const renderIncidentTableColumnsMixin = {
    data: function() {
        return {
        };
    },

    methods: {
        getIdColumnMarkup(data, type, row, historyTable) {

            return `
                <ul class="pa-hList" style="flex-wrap: nowrap;">
                    <li>
                        <a class="${data.className} block" href='${data.link}'>${data.title}</a>
                    </li>
                </ul>
                <p-badge class="${row.severity.additionalClassName}" variant="${row.severity.badge_type}">${row.severity.text}</p-badge>
            `;
        },

        getTimeColumnMarkup(data, type, row) {
            const statusBadge = `<p-badge class="${row.status.additionalClassName}" variant="${row.status.badge_type}">${row.status.text}</p-badge>`;

            return `
                <p class="pa-txt_medium pa-m-0">
                    ${data}
                    <div class="block">
                        ${!row.incident.resolved ? statusBadge : ''}
                        <span class="pa-txt_xs pa-txt_normal pa-text-grey-500">
                            ${row.duration}
                        </span>
                    </div>
                </p>
            `;
        },

        getActionsColumnMarkup(data, type, row, customerHasIncidentSolution, historyTable) {
            let cm = this.getCmIconMarkup(data, type, row, historyTable)
            let incidentSolution = '';
            let incidentSummary = '';

            if(customerHasIncidentSolution) {
                let incidentSolutionIconColor = 'grey-800';
                let incidentSolutionTooltipText = "";
                let hover = true;

                if(data.hasIncidentSolution) {
                    incidentSolutionTooltipText = 'Solution: ' + data.incidentSolutionNames
                } else {
                    incidentSolutionTooltipText =  
                        data.relatedSolutionCount + ' possible solutions identified';
                }

                incidentSolutionTooltipText = 
                    '<span style="white-space: normal;">' + 
                    incidentSolutionTooltipText + 
                    '</span>';

                if(data.hasIncidentSolution || data.relatedSolutionCount < 1) {
                    incidentSolutionIconColor = 'yellow'
                } else {
                    incidentSolutionTooltipText = 
                        '<a href="' + data.link + 
                        '&open_incident_solution=true" class="pa-txt_medium">'+ 
                        incidentSolutionTooltipText +
                        '</a>'
                    hover = false;
                }

                if(data.relatedSolutionCount > 0 || data.hasIncidentSolution) {
                    incidentSolution = `
                        <p-tooltip2
                            :prevent-overflow="true"
                            html-class="incident-solution-text-tooltip"
                            placement="bottom"
                            :hover="${hover}"
                        >
                            <span slot="trigger">
                                <span class="block">
                                    <p-icon
                                        icon="lit-lightbulb-outline"
                                        size="xl"
                                        color="${incidentSolutionIconColor}"
                                        class="pa-p-0"
                                        style="height: 17px;"
                                    >
                                    </p-icon>
                                </span>
                            </span>
                            ${incidentSolutionTooltipText}
                        </p-tooltip2>`;
                }
            }
            if (data.summary) {
                incidentSummary = `
                    <p-tooltip2
                        :prevent-overflow="true"
                        html-class="html-editor-content"
                        placement="bottom"
                    >
                        <span slot="trigger">
                            <span class="block">
                                <p-icon
                                    icon="description"
                                    size="xl"
                                    color="grey-800"
                                    class="pa-p-0"
                                    style="height: 17px;"
                                >
                                </p-icon>
                            </span>
                        </span>
                        <span style="white-space: normal;">${data.summary}</span>
                    </p-tooltip2>
                `
            }

            return  `
                <ul class="pa-hList" style="flex-wrap: nowrap;">
                    <li>
                        ${incidentSummary}
                    </li>
                    <li class="incident-solution-tooltip"> 
                        ${incidentSolution}
                    </li>
                    <li>
                        ${cm}
                    </li>
                </ul>`
        },

        getCmIconMarkup(data, type, row, historyTable) {
            let color = historyTable === true ? 'grey-800' : 'blue';
            let size = historyTable === true ? 'md' : 'xxxl';
            let variant = historyTable === true ? '' : 'black';
            let direction = historyTable === true ? '' : 'top';

            const icon = (isTrigger = false) => `
                <p-icon
                    ${isTrigger ? 'slot="trigger"' : ''}
                    color=${color}
                    icon="beaker"
                    size=${size}
                    class="pa-pt-2"
                >
                </p-icon>
            `;

            if (data.hasCm) {
                const cmIcon = () => `
                    <p-icon
                        style="fill: ${data.cm.fill};"
                        icon="${data.cm.icon}"
                        size="${size}"
                        class="pa-pt-2"
                    >
                    </p-icon>
                `;
 
                if (data.cm.drawer) {
                    return `
                        <a
                            href="#"
                            v-p-drawer-open
                            target="cm-drawer"
                            template="${data.cm.drawer.link}"
                        >
                            ${cmIcon()}
                        </a>
                    `; 
                } else {
                    return `
                        <a
                            href="#"
                            v-p-dialog-open
                            target="dynamic_modal"
                            template="${data.cm.modal.link}"
                        >
                            ${cmIcon()}
                        </a>
                    `;
                }
            }
            return `
                <p-flyout
                    ref="cmFlyout"
                    scroll-lock
                    hover
                    :hover-timeout="0"
                    rounded
                    positioning="popper"
                    no-arrow
                    width="auto"
                    popper-direction="${direction}"
                    variant="${variant}"
                >
                    ${icon(true)}
                    <div slot="content">
                        <p class="no-wrap">Incident has no CounterMeasures</p>
                    </div>
                </p-flyout>
            `;
        },
    }
}

export default renderIncidentTableColumnsMixin;