<style>
    .editmetric-drawer-wrapper {
        height: 100%;
        max-height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        right: 0;
    }
    .editmetric-drawer > div {
        height: 100%;
    }
    .editmetric-drawer .pa-drawer-bd {
        /* -180px for header and footer */
        height: calc(100% - 180px);
    }
    .editmetric-title {
        
        font-size: 18px;
        color:white;
    }
    .editmetric-category {
        margin-top: 5px;
        
        font-size: 13px;
    }
    .editmetric-drawer-footer {
        position: absolute;
        bottom: 0px;
        left: 0px;
        height: 80px;
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
        background: #f2f3f5;
        box-shadow: 0 -2px 0 0 rgba(132, 142, 153, 0.2);
        text-align: center;
        z-index: 10;
    }
    .editmetric-drawer-body {
        height: 100%;
        overflow-y: auto;
    }

    .editmetric-drawer-body .locked {
        overflow-y: hidden;
    }

    .counter-measure-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 10px 0;
    }

    .add-countermeasure a {
        font-size: 12px;
    }

    .counter-measure-remove {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 18px;
        height: 19px;
        cursor: pointer;
    }
    .threshold-remove {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 18px;
        height: 19px;
        cursor: pointer;
    }

    .threshold-box {
        background-color: #f2f3f5;
        position: relative;
        padding: 10px;
        border: 1px solid #dcdcdc;
        border-radius: 2px;
    }
    .counter-measure-box {
        position: relative;
        padding: 10px;
        border: 1px solid #dcdcdc;
        border-radius: 2px;
        background: #FCFCFC;
    }

    .editmetric-drawer .pa-input_taggle {
        min-height: 100px;
        cursor: text;
    }

    /* Transitions */
    .slide-transition {
        transition: transform .2s ease;
        transform: none;
    }
    .slide-enter {
        transform: translateX(-100%);
    }
    .slide-leave {
        transform: translateX(100%);
    }

    #edit-metric-drawer .pa-tabbedPanels {
        padding: 20px;
    }

    #edit-metric-drawer .pa-expando-hd-wrapper {
        background: white;
        border: none;
        border-bottom: 1px solid #e1e4e6;
        margin-top: 2px;
        margin-bottom: 0;
        padding: 0.625rem 0 0.625rem 0;
    }
    #edit-metric-drawer .pa-expando-box-bd {
        padding-left: 0;
        padding-right: 0;
    }

    #edit-metric-drawer .pa-field-bd .pa-input_taggle {
        width: 100%;
    }

    #edit-metric-drawer .pa-drawer-bd .pa-tabbedPanels {
        border: none !important;
    }
</style>

<template lang="nunjucks">
    <p-drawer ref="drawer" id="edit-metric-drawer"
            side="right" :width="700" :additional-classes="['editmetric-drawer']"
            :loading-spinner="true">
        <div slot="trigger" style="display: none;">
        </div>
    </p-drawer>
</template>

<script>
    import Vue from 'vue';

    const EditMetricDrawer = Vue.extend({
        components: {
        },

        events: {
            'drawer-close': function(drawerId) {
                if (this.$refs.drawer && drawerId === this.$refs.drawer.id) {
                    // Do our cleanup
                    this.cleanup();
                }
            },
            'scroll-lock:on': function(comp, flag) {
                const body = document.querySelector('.editmetric-drawer-body');                
                if(body) {
                    body.classList.add('locked');
                }  
            },
            'scroll-lock:off': function(comp, flag) {                
                const body = document.querySelector('.editmetric-drawer-body');
                if(flag && body && body.classList) {
                    body.classList.remove('locked');
                }
            },
            'edit-metric-drawer-close': function(){
                this.close();
            },
            'open-dem-edit-metric-drawer': function(metric_id, instance_id) {
                let metric = {
                    "id": metric_id,
                    "type": 'monitor_point'
                };
                this.openWithMetric(metric, true, instance_id);
            },
            'open-dem-edit-agent-drawer': function(metric_id, instance_id) {
                let metric = {
                    "id": metric_id,
                    "type": 'server_resource'
                };
                this.openWithMetric(metric, true, instance_id);
            },

            'open-sdwan-edit-metric-drawer': function(metric_id, instance_id) {
                let metric = {
                    "id": metric_id,
                    "type": 'monitor_point'
                };
                this.openWithMetric(metric, false, instance_id);
            },
            'open-sdwan-edit-agent-drawer': function(metric_id, instance_id) {
                let metric = {
                    "id": metric_id,
                    "type": 'server_resource'
                };
                this.openWithMetric(metric, false, instance_id);
            },
        },

        data() {
            return {
                all_tags: (this.$root.customerConfig && this.$root.customerConfig.allTags) || [],
            };
        },

        computed: {
            actionType: function() {
                if (this.config === 'edit') {
                    return 'Edit';
                } else if (this.config === 'add') {
                    return 'Add';
                }
                return '';
            },
        },

        props: {
        },

        methods: {
            openWithMetric: function(metric, demFlow, instance_id) {
                let endpoint = null;
                const payload = {
                    server_id: instance_id ? instance_id : this.$parent.serverId,
                    send_new: true,
                };
                if (metric.type === 'server_resource') {
                    endpoint = '/config/monitoring/EditAgentMetric';
                    payload.server_resource_id = -metric.id;
                    payload.dem_flow = demFlow;
                } else if (metric.type === 'monitor_point') {
                    if(demFlow !== true) {
                        demFlow = false;
                    }
                    endpoint = '/config/monitoring/EditNetworkServiceMetric';
                    payload.monitor_point_id = metric.id;
                    payload.dem_flow = demFlow;
                } else if (metric.type === 'snmp') {
                    endpoint = '/config/monitoring/EditSNMPMetric';
                    payload.server_resource_id = -metric.id;
                } else if (metric.type === 'snmp_template') {
                    endpoint = '/config/monitoring/EditSNMPTemplate';
                    payload.server_resource_id = -metric.id;
                    payload.parent_resource_textkey = metric.parent_textkey;
                } else {
                    console.error('Unknown metric type:');
                    console.error(metric);
                    return;
                }
                this.$refs.drawer.loadWithData(endpoint, payload, false, () => {
                    Vue.nextTick(() => {
                        this.$emit('metric-loaded');
                    });
                });
                if (document.body.scrollHeight > window.innerHeight) {
                    document.documentElement.classList.add('server-report-locked');
                }
                this.$refs.drawer.open();
            },
            openWithMetricType: function(metricType, demFlow, isInbound) {
                if(demFlow !== true) {
                    demFlow = false;
                }
                const endpoint = `${metricType.add_url}&send_new=true&dem_flow=${demFlow}&is_inbound=${isInbound}`;
                this.$refs.drawer.load(endpoint, false, () => {
                    Vue.nextTick(() => {
                        this.$emit('metric-loaded');
                    });
                });

                this.$refs.drawer.open();
            },
            showCountermeasure: function(metric, delay, countermeasureId) {
                this.openWithMetric(metric);
                this.$once('metric-loaded', () => {
                    setTimeout(() => {
                        let cmEl = null;
                        if (metric.type === 'monitor_point') {
                            // Find the countermeasure box
                            const selector = `.editmetric-drawer-body #countermeasure-${countermeasureId}`; // eslint-disable-line max-len
                            cmEl = this.$el.parentElement.querySelector(selector);
                            if (!cmEl) {
                                return;
                            }
                            this.$broadcast('expando:close', 'config-pane');
                            // Open both expandos, could be in either
                            this.$broadcast('expando:open', 'outage-alert-pane');
                            this.$broadcast('expando:open', 'response-time-alerts-pane');
                        } else if (metric.type === 'server_resource') {
                            // Find the countermeasure box
                            const selector = `.editmetric-drawer-body #countermeasure-${countermeasureId}`; // eslint-disable-line max-len
                            cmEl = this.$el.parentElement.querySelector(selector);
                            if (!cmEl) {
                                return;
                            }
                            this.$broadcast('expando:close', 'config-pane');
                            this.$broadcast('expando:open', 'alert-pane');
                        }
                        setTimeout(() => {
                            if (cmEl) {
                                // Get the scroll offset
                                let scrollOffset = cmEl.getBoundingClientRect().y;
                                // Subtract some for the drawer header + countermeasure title
                                scrollOffset -= 100;

                                // Finally animate to the top of the box
                                const selector = '.editmetric-drawer-body';
                                const scrollPx = `${scrollOffset}px`;
                                $(selector).animate({ scrollTop: scrollPx }, 700);
                            }
                        }, 200);
                    }, 100);
                });
            },
            close: function() {
                this.$refs.drawer.close();
                Vue.nextTick(() => {
                    this.cleanup();
                });
            },
            cleanup: function() {
                this.$children.forEach(c => {
                    if (c !== this.$refs.drawer) {
                        c.$destroy(self);
                    }
                });
                if (this.$refs.drawer.$els.asyncBody) {
                    this.$refs.drawer.$els.asyncBody.innerHTML = '';
                }

                // Remove the scroll lock class if we're the last drawer
                if (!this.$parent.$refs["metric-drawer"].$refs.drawer.isOpen) {
                    document.documentElement.classList.remove('server-report-locked');
                }
            },
            resizeExpandos: function() {
                Vue.nextTick(() => {
                    this.$broadcast('expando:resize');
                });
            },
            resetExpandos: function() {
                Vue.nextTick(() => {
                    this.$broadcast('expando:reset');
                });
            },

            // General helper methods
            showSuccessToast: function() {
                if (!this.config || !this.metric) { return; }

                let metricType = '';
                if (this.metric.type === 'server_resource') {
                    metricType = 'Metric';
                } else if (this.metric.type === 'monitor_point') {
                    metricType = 'External Check';
                }

                if (this.config.action === 'add') {
                    window.app.toastManager.clearToasts();
                    window.app.toastManager.showToast(`${metricType} successfully added`);
                } else if (this.config.action === 'edit') {
                    window.app.toastManager.clearToasts();
                    window.app.toastManager.showToast(`${metricType} successfully updated`);
                }
            },
        },

        vueReady() {
            window.app.editMetricDrawer = this;
        },
    });

    export default EditMetricDrawer;
</script>
