<template>
    <div class='pa-graph-container' :id="`p-graph-container-${_uid}`">
        <div class='pa-graph-title'>
            <slot name='title'></slot>
            <span style="padding: 0 10px;">
                <button v-on:click="time_start" class="pa-btn naked_blue graph-time-button">
                    <svg class="pa-icon pa-icon_light"><use xlink:href="#skip-backward"></use></svg>
                </button>
                <button v-on:click="time_back" class="pa-btn naked_blue graph-time-button">
                    <svg class="pa-icon pa-icon_light"><use xlink:href="#skip-previous"></use></svg>
                </button>
                <button v-on:click="time_forward" class="pa-btn naked_blue graph-time-button">
                    <svg class="pa-icon pa-icon_light"><use xlink:href="#skip-next"></use></svg>
                </button>
                <button v-on:click="time_latest" class="pa-btn naked_blue graph-time-button">
                    <svg class="pa-icon pa-icon_light"><use xlink:href="#skip-forward"></use></svg>
                </button>
            </span>
            <select v-model="timescale" v-on:change="change_timescale" class='pa-select'>
                <option value='15min'>15 minutes</option>
                <option value='hour'>Hour</option>
                <option value='day'>Day</option>
                <option value='week'>Week</option>
                <option value='month'>Month</option>
                <option value='year'>Year</option>
                <option value='custom'>Custom</option>
            </select>
        </div>
        <div class='pa-graph-body'>
            <slot name='graphs'></slot>
        </div>
    </div>
</template>
    

<script>
    import Vue from 'vue';
    const GraphContainerComponent = Vue.extend({
        data: function() {
            return {
                timescale_increments: {"15min": 15*60, hour: 60*60, day: 24*60*60, week: 7*24*60*60, month: 31*24*60*68, year: 365*24*60*60}
            }
        },
        props: {
            timescale: {
                type: String,
                default: "day"
            },
            maxOffset: {
                type: Number,
                default: 999999999
            }
        },
        methods: {
            fetch_graph_data: function() {
                this.$children.forEach(function(graph, i) { graph.fetch_graph_data(); });
            },
            time_start: function(event) {
                // Set offset to extremely large value, will get picked up as the start of monitoring for the server
                var offset = this.maxOffset;
                this.$children.forEach(function(graph, i) { graph.set_offset(offset); })
            },
            time_back: function(event) {
                // Change the offset for all of the graphs in this container
                var timescale = this.timescale;
                var increment = this.timescale_increments[timescale];
                this.$children.forEach(function(graph, i) { graph.increment_offset(increment); })
            },
            time_forward: function(event) {
                // Change the offset for all of the graphs in this container
                var timescale = this.timescale;
                var increment = this.timescale_increments[timescale];
                this.$children.forEach(function(graph, i) { graph.decrement_offset(increment); })
            },
            time_latest: function(event) {
                this.$children.forEach(function(graph, i) { graph.set_offset(0); })
            },
            change_timescale: function(event) {
                var timescale = this.timescale;

                // If we're a custom timescale, disable the buttons to adjust time
                if (timescale == "custom") {
                    $(".graph-time-button", this.$el).prop("disabled", true);
                } else {
                    $(".graph-time-button", this.$el).prop("disabled", false);
                }

                // Change the timescale for all of the graphs in this container
                this.$children.forEach(function(graph, i) { graph.change_timescale(timescale); })
            }
        }
    })

export default GraphContainerComponent;
</script>
