<style lang="scss">
.p-tree-icon-custom {
    vertical-align: middle !important;
    width: 18px !important;
    height: 18px !important;
    line-height: 1 !important;
    background-repeat: no-repeat !important;
}
.p-tree-icon-server {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cpath d='M1.691 6.719V3.152c0-.109.055-.164.164-.164H15.84c.055 0 .11.055.11.164V6.72c0 .11-.055.164-.165.164H1.855c-.109 0-.164-.055-.164-.164zm0 1.699V15c0 .11.055.164.164.164H15.84c.11 0 .164-.055.164-.164V8.418c0-.11-.055-.164-.164-.164H1.855c-.109 0-.164.11-.164.164zm1.973-4.387a.865.865 0 00-.875.88c0 .491.383.874.875.874a.865.865 0 00.879-.875.866.866 0 00-.879-.879zm2.633 0a.868.868 0 00-.879.88c0 .491.387.874.879.874a.867.867 0 00.879-.875.868.868 0 00-.88-.879zm0 0'/%3E%3C/svg%3E") !important;
}
.p-tree-icon-fortigate {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' width='18' height='18'%3E%3Cpath d='M6.05 7.98h4.67v2H6.05zm0 6h4.67v2H6.05zm6 .02h-.67v2h1.4a2.51 2.51 0 01-.73-2zm-6-3.35h2.67v2.67H6.05zm3.33 2.66h2.8a5.28 5.28 0 01.67-1.2 3.29 3.29 0 00.67-1.46H9.38zm2-5.31v2h2.27V8.91l1.07.8.28.29h.4V8z'/%3E%3Cpath d='M16.65 13.58a7.26 7.26 0 00-2.4-3.33c.07 1.6-1.33 2.4-1.53 3.6a1.85 1.85 0 001.33 2.06 1.49 1.49 0 01-.53-1.13c0-.53.46-.8.53-1.4a1.14 1.14 0 01.47.87 2.22 2.22 0 00.46-2c2.27 2.13.34 3.73.34 3.73a1.9 1.9 0 001.33-2.4z'/%3E%3Cpath d='M3.13 13h-.07A1.05 1.05 0 012 11.94V4.06A1.05 1.05 0 013.06 3h7.88A1.06 1.06 0 0112 4h1a2.05 2.05 0 00-2.06-2H3.06A2.06 2.06 0 001 4.06v7.88A2.06 2.06 0 003.06 14h.07z'/%3E%3Cpath d='M12.94 4H5.06A2.06 2.06 0 003 6.06v7.88A2.06 2.06 0 005 16v-1a1.06 1.06 0 01-1-1.06V6.06A1.05 1.05 0 015.06 5h7.88A1.05 1.05 0 0114 6.06V7h1v-.94A2.06 2.06 0 0012.94 4z'/%3E%3C/svg%3E%0A") !important;
}
.p-tree-icon-fortiswitch {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' height='18' width='18'%3E%3Cpath d='M16 11H2c-.5 0-1 .5-1 1v2c0 .5.5 1 1 1h14c.5 0 1-.5 1-1v-2c0-.5-.5-1-1-1zM5 14H3v-2h2v2zm3 0H6v-2h2v2zm3 0H9v-2h2v2zm3 0h-2v-2h2v2zM2.3 10h13.3c.7 0 1.1-.6 1-1.3l-1.4-5c-.1-.4-.5-.7-1-.7H3.8c-.4 0-.8.3-1 .7l-1.4 5c-.2.7.3 1.3.9 1.3zM6 4v1h7.5c.3 0 .5.2.5.5s-.2.5-.5.5H4l2-2zM4.5 7H14l-2 2V8H4.5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5z'/%3E%3C/svg%3E") !important;
}
.p-tree-icon-fortiap {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' width='18' height='18'%3E%3Cpath d='M11.5 14H10L9 8c.5 0 .9-.5.9-1 0-.6-.5-1-1-1s-1 .5-1 1 .5 1 1 1H9l-1 6H6.5c-.3 0-.5.2-.5.5s.2.5.5.5h5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z'/%3E%3Cpath d='M6.9 9.5c.1 0 .3-.1.4-.2.2-.2.2-.5 0-.7-.4-.4-.7-1-.7-1.6 0-.6.2-1.2.6-1.6.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0-.5.6-.9 1.4-.9 2.3s.4 1.7 1 2.3c.1.1.2.2.3.2zM5.2 4.2c-.2-.3-.5-.3-.7 0-.7.7-1.1 1.7-1.1 2.8s.4 2.1 1.1 2.8c.1.1.2.2.4.2s.3-.1.4-.2c.2-.2.2-.5 0-.7-.6-.6-.9-1.3-.9-2.1s.3-1.5.8-2.1c.2-.2.2-.5 0-.7z'/%3E%3Cpath d='M2 7c0-1.2.4-2.3 1.2-3.1.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0C1.5 4.2 1 5.5 1 7s.5 2.8 1.5 3.8c.1.1.3.2.4.2.1 0 .3-.1.4-.2.2-.2.2-.5 0-.7C2.5 9.3 2 8.2 2 7zm8.7-2.3c-.2.2-.2.5 0 .7.4.4.6 1 .6 1.6 0 .6-.2 1.2-.6 1.6-.2.2-.2.5 0 .7.1.1.2.2.4.2.1 0 .3-.1.4-.2.5-.6.9-1.4.9-2.3s-.4-1.7-1-2.3c-.2-.2-.5-.2-.7 0zm2.8-.5c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7.5.6.8 1.3.8 2.1s-.3 1.5-.8 2.1c-.2.2-.2.5 0 .7.1.1.2.2.3.2s.3-.1.4-.2C14.2 9 14.6 8 14.6 7s-.4-2.1-1.1-2.8zm2-1c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7.7.8 1.2 1.9 1.2 3.1 0 1.2-.4 2.3-1.2 3.1-.2.2-.2.5 0 .7.1.1.2.2.4.2.1 0 .3-.1.4-.2 1-1 1.5-2.4 1.5-3.8-.1-1.5-.6-2.8-1.6-3.8z'/%3E%3C/svg%3E") !important;
}
.p-tree-icon-fortiextender {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cpath d='M14.2 1H3.8c-.5 0-.8.3-.8.8 0 .4.3.8.8.8h10.5c.4 0 .8-.3.8-.8-.1-.5-.4-.8-.9-.8zm-2 3H5.8c-.5 0-.8.3-.8.8 0 .4.3.8.8.8h6.5c.4 0 .8-.3.8-.8-.1-.5-.4-.8-.9-.8zm-2 4.5c.4 0 .8-.3.8-.8 0-.4-.3-.7-.8-.7H7.8c-.5 0-.8.3-.8.8 0 .4.3.8.8.8h2.4zm1.9 1.5H5.9c-.5 0-.9.4-.9.9V16c0 .6.4 1 .9 1H12c.5 0 .9-.4.9-.9V11c.1-.6-.3-1-.8-1zm-.6 5.5h-5v-4h5v4z'/%3E%3C/svg%3E") !important;
}
.p-tree-icon-fortimanager {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'%3e%3cg data-name='Group 427'%3e%3cpath data-name='Path 3687' d='M5.684 8.416h6.632v-.753H5.684v.753Z'/%3e%3cpath data-name='Path 3688' d='M5.684 10.902h6.632v-.753H5.684v.753Z'/%3e%3cpath data-name='Path 3689' d='M5.684 13.386h6.632v-.753H5.684v.753Z'/%3e%3cpath data-name='Path 3690' d='M13.824 1.94h-3.316A1.68 1.68 0 0 0 9.001 1a1.68 1.68 0 0 0-1.508.94H4.177a1.262 1.262 0 0 0-1.243 1.281v12.497a1.238 1.238 0 0 0 1.243 1.28h9.647a1.262 1.262 0 0 0 1.242-1.28V3.22a1.287 1.287 0 0 0-1.242-1.28ZM9 1.603a1.175 1.175 0 0 1 .79.339H8.21A1.175 1.175 0 0 1 9 1.602Zm2.787 1.469-.188.83H6.4l-.188-.83Zm2.186 12.647a.172.172 0 0 1-.15.189H4.177a.172.172 0 0 1-.153-.19V3.222a.161.161 0 0 1 .15-.15h1.171l.416 1.657h6.48l.415-1.657h1.169a.161.161 0 0 1 .15.15Z'/%3e%3c/g%3e%3c/svg%3e") !important;
}
.tree-default {
    &.tree-checkbox-selection {
        .tree-selected {
            & > .tree-checkbox {
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath stroke='%23FFFFFF' stroke-width='2.5' d='M2.81 7.473l4.67 4.482 5.71-10.012' fill='none'/%3E%3C/svg%3E");
                background-color: #3954bf;
                background-position: center !important;
                background-repeat: no-repeat;
                height: 15px;
                width: 15px;
                margin: 4px;
            }
        }
    }

    .tree-checked {
        & > .tree-checkbox {
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath stroke='%23FFFFFF' stroke-width='2.5' d='M2.81 7.473l4.67 4.482 5.71-10.012' fill='none'/%3E%3C/svg%3E");
            background-color: #3954bf;
            background-position: center !important;
            background-repeat: no-repeat;
            height: 15px;
            width: 15px;
            margin: 4px;
        }
    }

    .tree-selected {
        background: transparent !important;
    }

    .tree-context {
        background: #F2F3F5 !important;
    }

    .tree-hovered {
        background: #F2F3F5 !important;
    }

    .tree-disabled {
        color: #a6a6a6 !important;

        & > .tree-icon {
            opacity: 0.3 !important;
        }
    }
}

#org_dd{
    .org_tree{
        .tree {
            display: flex !important;
            position: relative;
            align-items: stretch;
            flex-wrap: wrap;
            width: 98%;
        }        
    }
    .ou-content{
            box-sizing: border-box !important;
            display: inline-block;
            border-bottom: 1px solid #dee2eb;
            width: inherit;
            height: auto !important;
    }
    .ou-name{
        color: #212121;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .ou-desc{
        color: rgba(35,55,70,.53);
        font-size: 10px;
        line-height: 18px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .tree-anchor{
            position: relative;
            align-items: stretch;
            width: 100% !important;
            pointer-events: none !important;
    }
    li .tree-leaf:hover{
        div #member_account  {        
            background-color: rgb(179, 218, 240) !important;
            pointer-events: auto;              
        }
    }
    .pa-input-org-search {
            background: url(/static/assets/newux/media/svgs/magnifyingGlass.svg) no-repeat left;
            background-size: 20px;
            background-color: white;
            background-position: right 5px center;
            padding-left: 6px;
            margin-top: 15px;
            width: 98%;
        }
}
</style>

<template>
    <div v-if="true" @click="addActiveClass($event)">        
        <p style="text-align: left; padding-top: 10px; width: 98%;">
            <input v-if="showSearch" class="pa-input-org-search" placeholder="Search an OU or Account" type="text" v-model="searchText" style="height: 32px;"/>
        </p>
        <v-jstree
            ref="jstree"
            :data="_treeData"
            :size="size"
            :show-checkbox="showCheckbox"
            :allow-transition="allowTransition"
            :whole-row="wholeRow"
            :no-dots="noDots"
            :collapse="collapse"
            :multiple="multiple"
            :allow-batch="allowBatch"
            :text-field-name="textFieldName"
            :value-field-name="valueFieldName"
            :children-field-name="childrenFieldName"
            :item-events="itemEvents"
            :async="async"
            :loading-text="loadingText"
            :draggable="draggable"
            :drag-over-background-color="dragOverBackgroundColor"
            :klass="className"
            @item-click="handleItemClick"
            @item-toggle="handleItemToggle"
            :opened="opened"
            :custom-tree="customTree"   
            :height="height"  
        >
        <!-- To Do: Figure out a way to move pass the custom tree stuff as dynamic slot content -->
        <template slot-scope="_" v-if="customTree">
            <svg v-if="_.model.type === 'member_account'" class="pa-icon">
                <use v-bind="{ 'xlink:href': '#member-account' }"></use>
            </svg>
            <svg v-else-if="_.model.type === 'ou_unit'" class="pa-icon">
                <use v-bind="{ 'xlink:href': '#ou-unit' }"></use>
            </svg>
            <svg v-else-if="_.model.type === 'root'" class="pa-icon">
                <use v-bind="{ 'xlink:href': '#root-org' }"></use>
            </svg>
            <i v-else :class="_.vm.themeIconClasses" role="presentation"></i>                
                <div v-if="_.model.type === 'member_account'" style="cursor: pointer !important;" id="member_account" class="org_tree tree ou-content" @click="switchFtntAccount(_.model)">
                    <div class="ou-name">
                        {{_.model.name}}
                    </div>
                    <div class="ou-desc">
                        {{ _.model.description }}
                    </div>                
                </div>
                <div v-else class="org_tree tree ou-content" >
                    <div class="ou-name">
                        {{_.model.name}}
                    </div>
                    <div class="ou-desc" v-if="_.model.description">
                        {{ _.model.description }}
                    </div>
                    <div class="ou-desc" v-else>
                        No Account Associated
                    </div>
                </div>   
            </template>
        </v-jstree>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VJstree from 'vue-jstree';
    import axios from 'axios';

    export default Vue.extend({
        components: {
            VJstree,
        },
        data() {
            return { 
                searchText: '',
            };
        },
        props: {
            data: {
                type: Array,
                default: () => []
            },            
            size: {
                type: String,
                default: 'small',
            },
            showCheckbox: {
                type: Boolean,
                default: false,
            },
            customTree: {
                type: Boolean,
                default: false,
            },
            showSearch: {
                type: Boolean,
                default: false,
            },
            allowTransition: {
                type: Boolean,
                default: true,
            },
            wholeRow: {
                type: Boolean,
                default: false,
            },
            noDots: {
                type: Boolean,
                default: false,
            },
            collapse: {
                type: Boolean,
                default: false,
            },
            opened: {
                type: Boolean,
                default: false,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            allowBatch: {
                type: Boolean,
                default: false,
            },
            textFieldName: {
                type: String,
                default: "text",
            },
            valueFieldName: {
                type: String,
                default: "value",
            },
            childrenFieldName: {
                type: String,
                default: "children",
            },
            itemEvents: {
                type: Object,
                default: () => {},
            },
            async: {
                type: Function,
            },
            loadingText: {
                type: String,
                default: "Loading...",
            },
            draggable: {
                type: Boolean,
                default: false,
            },
            dragOverBackgroundColor: {
                type: String,
                default: "#C9FDC9",
            },
            className: {
                type: String,
            },
            height: {                
                type: Number,
                default: 24,
            },
        },
        watch: {
            _treeData() {
                this.$refs.jstree.initializeData(this._treeData);
            },
            searchText() {
                const asyncData = [this.$refs.jstree.initializeLoading()];
                this.$refs.jstree.handleAsyncLoad(asyncData, this.$refs.jstree);
            }
        },
        computed: {
            _treeData() {
                if(this.searchText){
                    const patt = new RegExp(this.searchText);
                    return this.filterTreeData([...this.data], patt)
                }else{
                    return [...this.data];
                }
            },            
        },
        methods: {
            filterTreeData(treeData, name) {
                return treeData.reduce((r, { children = [], ...o }) => {
                    if (o.name.match(name)) {
                        r.push(o);
                        return r;
                    }
                    children = this.filterTreeData(children, name);
                    if (children.length) {
                        r.push(Object.assign(o, { children }));
                    }
                    return r;
                }, []);
            },
            handleItemClick(node, item, e) {
                this.$emit("item-click", node, item, e);
            },
            handleItemToggle(node, item, e) {
                this.$emit("item-toggle", node, item, e);
            },            
            addActiveClass(event) {
                event.preventDefault()
                event.stopPropagation()
                let bd = document.querySelector('.pa-menu')                
                bd.classList.add('isActive')                            
            },
            async switchFtntAccount(user_data) {
                try {                            
                    await axios.get(
                        `/setFortinetOrgUser?account_description=${user_data['description']}&account_name=${user_data['name']}`).then(response => {
                        if (response.data.success) {
                            window.location.reload();
                        } else {
                            console.log(response.data);
                            this.errors = {
                                'Customer switch server error: ': response.data
                            };
                        }
                    })

                } catch (e) {
                    console.log(e);
                    this.errors = {
                        'message': e,
                        'source': 'Customer'
                    };
                }
            },
        }        
    });
</script>
