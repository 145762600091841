<template>
    <div class="pa-tabbedPanels-panels">
        <ul class="pa-panels">
            <slot />
        </ul>
    </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({});
</script>
