const onFocusout = (() => {
    // params: ['callback'],
    const state = new WeakMap();

    return {
        bind(el, binding, vnode) {
            const self =  {};
            state.set(el, self);

            const callback = vnode.data.attrs.callback;

            self._onFocus = function(event) {
                const target = event.target;
                const menuContainsTarget = el.contains(target);

                if (menuContainsTarget) {
                    return;
                }

                // If we're swapping the select's model, it might not
                // actually be in the dom yet
                if (target.classList.contains('pa-select-list-item')) {
                    return;
                }

                if (typeof callback !== 'function') {
                    return;
                }

                callback();
            };

            if (binding.value) {
                document.body.addEventListener('click', self._onFocus);
                document.body.addEventListener('focus', self._onFocus, true);
                document.body.addEventListener('touchend', self._onFocus);
            }
        },

        unbind(el) {
            const self = state.get(el);

            document.body.removeEventListener('click', self._onFocus);
            document.body.removeEventListener('focus', self._onFocus, true);
            document.body.removeEventListener('touchend', self._onFocus);
        },


        update(el, binding) {
            const self = state.get(el);
            if (binding.value) {
                document.body.addEventListener('click', self._onFocus);
                document.body.addEventListener('focus', self._onFocus, true);
                document.body.addEventListener('touchend', self._onFocus);
            } else {
                document.body.removeEventListener('click', el._onFocus);
                document.body.removeEventListener('focus', el._onFocus, true);
                document.body.removeEventListener('touchend', el._onFocus);
            }
        },
    };
})();

export default onFocusout;
