import { requests } from "../requests";

export const getDEMAsyncTasks = ({ application_id }) => {
    return requests.get('/util/get_dem_application_async_tasks', { params: { application_id } });
};

export const postMonitoringTreeDeferred = (url) => {
    return requests.post(url);
};

export const dismissDEMAsyncTask = ({async_task_id}) => {
    return requests.get('/util/dismiss_async_task', { params : { async_task_id } });
};

export const getFabricRootsCount = () => {
    return requests.get('/util/get_fabric_roots_count');
};

export const getFabricRoots = ({ search_query, ids }) => {
    return requests.get('/util/get_fabric_roots', { params: { search_query, ids } });
};
