<template>
    <div class="pa-edittext" @mouseover="_mouseOver"
                             @mouseleave="_mouseOut"
                             @click.stop="">
        <slot v-if="!editing" ref="textSlot">
            <span ref="textSpan"
                  class="pa-edittext-span pa-txt" 
                  :style="spanStyles">
                {{ iText }}
            </span>
        </slot>
        <input ref="input"
               v-if="editing" 
               v-model="iText"
               type="text"
               @blur="_focusOut"
               @keydown.enter="_focusOut"
               class="pa-edittext-input"
               :style="iInputStyles" />
    </div>
</template>

<script>
    import Vue from 'vue';

    const EditText = Vue.extend({
        props: {
            text: String,
            spanStyles: Object,
            inputStyles: Object,
            onChange: {
                type: Function,
                'default': () => {},
            },
        },

        data() {
            return {
                iText: this.text,
                editing: false,
                iInputStyles: Object,
            };
        },

        watch: {
            text(val, oldVal) {
                this.iText = val;
            },
        },

        methods: {
            _mouseOver() {
                this.startEdit();
            },

            _mouseOut() {
                if (document.activeElement !== this.$refs.input) {
                    this.stopEdit();
                }
            },

            _focusOut() {
                this.stopEdit();
                this.update();
            },

            startEdit() {
                this.iInputStyles = this.getInputStyles();
                this.editing = true;
            },

            stopEdit() {
                this.editing = false;
            },

            update() {
                if (this.iText !== this.text) {
                    this.$emit('update:text', this.iText);
                    this.onChange(this.iText);
                }
            },

            getInputStyles() {
                let styles = {};

                try {
                    let el = null;
                    const firstChild = this.$el.firstElementChild;
                    if (typeof firstChild !== 'undefined') {
                        el = firstChild;
                    } else {
                        el = this.$refs.textSpan;
                    }

                    const textStyles = window.getComputedStyle(el);

                    styles['font-family'] = textStyles.fontFamily;
                    styles['font-size'] = textStyles.fontSize;
                    styles.height = textStyles.height;
                } catch (e) {
                    console.error(e);
                    return Object.assign({}, this.inputStyles);
                }

                styles = Object.assign(styles, this.inputStyles);

                return styles;
            },
        },

        vueReady() {
        },
    });

    export default EditText;
</script>
