<style lang="scss">
    .tree-wrapper {
        overflow-y: auto;
        max-height: 100%;
    }
    
    .instance-metric-module-tree {
        .tree-search {
            padding: 0;
        }
        .tree-wrapper {
            overflow-y: auto;
            max-height: 100%;
            border: 1px solid #d5d8df;
            border-radius: 2px;
            padding: 10px 20px;
        }
    }

    #metic-port-select-wrapper{
        .scrollable {
            max-height: 300px;
        }
    }

</style>

<template>
    <div class="module">
        <div ref="body" v-show="ready && open" transition="module-expand" class="module-body">
            <p-field-group
                label="Instance"
                name="instance"
            >
                <div v-if="options.instance_types == 'network_device'" class="pa-select pa-select_stretch">
                    <select v-model="type" class="pa-select-menu">
                        <option v-for="item in typeOptions" :value="item.value">
                            {{item.label}}
                        </option>
                    </select>
                    <span class="pa-select-icon"></span>
                </div>
                <div v-if="type === 'none'" class="pa-py-6">
                    <p-tree :model.sync="tree_models"
                            :preselected.sync="tree_models"
                            :url="getMonitoringTreeDeferredURL()"
                            :deferred="true"
                            :select_nodes="true"
                            :select-callback="getSelectOptions"
                            :multiselect="false"
                            :show-search="true"
                            :include-link="false"
                            class="instance-metric-module-tree"
                            css_class="tree-wrapper"
                    >
                    </p-tree>
                </div>
            </p-field-group>
            <div v-if="type === 'tags'" class="module-border pa-pb-12" style="text-align: left;">
                <label class="pa-option" style="padding: 10px;">
                    <input v-model="instanceTagsType" value="any" type="radio" class="pa-option-input" />
                    <span class="pa-option-icon pa-option-icon_radio"></span>
                    <span class="pa-option-txt">
                        Match Any
                    </span>
                </label>
                <label class="pa-option">
                    <input v-model="instanceTagsType" value="all" type="radio" class="pa-option-input" />
                    <span class="pa-option-icon pa-option-icon_radio"></span>
                    <span class="pa-option-txt">
                        Match All
                    </span>
                </label>
                <p-tags-input
                    id="instance_tags"
                    ref="tags_input"
                    name="tags[]"
                    :model.sync="instanceTags"
                    :autocomplete="true"
                    :suggestions="allTags"
                    placeholder="Enter tags"
                >
                </p-tags-input>
                <p-hint>Hit enter or tab after each tag</p-hint>
            </div>
            <p-field-group
                v-if="type === 'none'"
                :label="getSelectLabel()"
                name="metric"
                id="metic-port-select-wrapper"
            >
                <p-select
                    v-if="showSelect"
                    class="pa-select_stretch"
                    :searchable="true"
                    :model.sync="metric"
                    :options="selectOptions()"
                >
                </p-select>
                <div v-else class="pa-message pa-message_info">
                    {{noSelectOptionsMessage}}
                </div>
            </p-field-group>
            <template v-else>
                <div class="pa-field pa-field_vertical pa-pt-32">
                    <div class="pa-field-hd">
                        <label for="template" class="pa-label">Metrics</label>
                    </div>
                    <div class="pa-field-bd" :class="{ 'pa-pb-6 module-border': secondaryFilter !== 'none' }">
                        <label class="pa-option pa-mr-16 pa-pb-10" :key="option.value" v-for="option in secondaryFilterOptions">
                            <input class="pa-option-input" type="radio" :value="option.value" name="secondaryFilter" v-model="secondaryFilter">
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt pa-txt-grey-700">{{ option.label }}</span>
                        </label>
                    </div>
                </div>
                <div v-if="filterTags" class="pa-field pa-field_vertical pa-pt-12">
                    <div class="pa-field-bd" style="text-align: left;">
                        <label class="pa-option" style="padding-bottom: 10px; padding-right: 10px;">
                            <input v-model="metricTagsType" value="any" type="radio" class="pa-option-input"/>
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt">
                                Match Any
                            </span>
                        </label>
                        <label class="pa-option" style="padding-bottom: 10px">
                            <input v-model="metricTagsType" value="all" type="radio" class="pa-option-input" />
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt">
                                Match All
                            </span>
                        </label>
                        <p-tags-input
                            id="metric_tags"
                            ref="tags_input"
                            name="tags[]"
                            :model.sync="metricTags"
                            :autocomplete="true"
                            :suggestions="allTags"
                            placeholder="Enter tags"
                        >
                        </p-tags-input>
                    </div>
                    <div class="pa-field-ft pa-mt-0 pa-pb-24">
                        <p class="pa-hint">Hit enter or tab after each tag</p>
                    </div>
                </div>
                <div v-if="filterOptionString" style="margin-top: 5px;">
                    <input
                        v-model="optionString"
                        debounce="1000"
                        type="text"
                        class="pa-input"
                        placeholder="Enter option string"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                ready: false,
                preselects: [],
                tree_models: [],
                metricOptions: [],
                portOptions: [],
                metric: null,
                type: 'none',
                instanceTags: [],
                metricTags: [],
                instanceTagsType: 'any',
                metricTagsType: 'any',
                optionString: '',
                secondaryFilter: 'none',
                secondaryFilterOptions: [
                    {
                        label: 'By Tags',
                        value: 'tags',
                    },
                    {
                        label: 'By Option String',
                        value: 'optionString',
                    },
                ],
            }
        },

        props: {
            options: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            typeOptions: {
                type: Array,
                'default': function() {
                    return [
                        { value: 'none', label: 'No Filter' },
                        { value: 'tags', label: 'By Tag(s)' },
                    ];
                },
            },
            open: {
                type: Boolean,
                default: true
            },
            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        },

        events: {},

        watch: {
            type: function(val) {
                if (!this.ready) { return; }
                this.instanceTags = [];
                this.metricTags = [];
                this.instanceTagsType = 'any';
                this.metricTagsType = 'any';
                this.optionString = '';
                this.secondaryFilter = 'none';
                this.setWidgetInstances();
                this.setWidgetMetrics();
                this.sendConfig();
            },
            instanceTags: function() {
                this.setWidgetInstances();
            },
            instanceTagsType: function() {
                this.setWidgetInstances();
            },
            metricTags: function() {
                this.setWidgetMetrics();
            },
            metricTagsType: function() {
                this.setWidgetMetrics();
            },
            optionString: function() {
                this.setWidgetMetrics();
            },
            metric(curr, prev) {
                this.eventHub.$emit('config-drawer:set_no_scroll', false);
                this.$nextTick(() => {
                    this.sendConfig();
                })
            },
            'secondaryFilter': function(value) {
                if (!this.ready) { return; }
                // Changing filter type
                // Clear out the models
                this.metricTags = [];
                this.optionString = '';
                Vue.nextTick(() => {
                    this.setWidgetMetrics();
                });
            },
        },

        computed: {
            filterTags: function() {
                return this.secondaryFilter === 'tags';
            },

            filterOptionString: function() {
                return this.secondaryFilter === 'optionString';
            },

            selectedInstance() {
                return this.tree_models.length
                    && (this.tree_models[0].startsWith('s-')
                    || this.tree_models[0].startsWith('ap-')
                    || this.tree_models[0].startsWith('cs-'));
            },

            showSelect() {
                if (this.options.instance_types == "network_device") {
                    return this.portOptions.length && this.selectedInstance;
                }
                return this.metricOptions.length && this.selectedInstance;
            },

            noSelectOptionsMessage() {
                if (this.options.instance_types == "network_device") {
                    return "Please select an instance above with ports."
                }
                return "Please select an instance above with metrics.";
            },

            allTags: function() {
                if (window.dashboard && window.dashboard.resellerDashboard) {
                    return [];
                } else if (this.$root.customerConfig) {
                    return this.$root.customerConfig.allTags;
                } else {
                    return [];
                }
            },
        },

        methods: {
            setWidgetMetrics() {
                if (!this.ready) { return; }

                this.$parent.updatePending(this.editingWidget.id);

                const payload = {
                    widget_id: this.editingWidget.id,
                    metrics: this.selectedMetrics,
                    tags_match: this.metricTagsType,
                    tags: this.metricTags,
                    filter_tags: this.filterTags,
                    filter_option_string: this.filterOptionString,
                    option_string: this.optionString,
                };

                $.ajax({
                    url: '/dashboardv2/setWidgetMetrics',
                    method: 'POST',
                    data: payload,
                })
                .done(data => {
                    if (data.success && data.newConfig) {
                        this.editingWidget.config = data.newConfig;
                    }
                });

                this.editingWidget.pendNewConfig();
            },

            setWidgetInstances() {
                if (!this.ready) { return; }

                this.$parent.$broadcast('config:wait_for_metrics');

                if (this.type === 'tags') {
                    const payload = {
                        widget_id: this.editingWidget.id,
                        select_type: 'tags',
                        selection: this.instanceTags,
                        tag_match: this.instanceTagsType,
                    };

                    $.ajax({
                        url: '/dashboardv2/setWidgetInstances',
                        method: 'GET',
                        data: payload,
                    }).done(data => {
                        if (data.newConfig) {
                            this.editingWidget.config = data.newConfig;
                        }
                        this.$parent.$broadcast('config:update_metrics');
                    });
                } else if (this.type === 'none') {
                    const payload = {
                        widget_id: this.editingWidget.id,
                        select_type: 'none',
                    };

                    $.ajax({
                        url: '/dashboardv2/setWidgetInstances',
                        method: 'GET',
                        data: payload,
                    }).done(data => {
                        if (data.newConfig) {
                            this.editingWidget.config = data.newConfig;
                        }
                        this.$parent.$broadcast('config:update_metrics');
                    });
                }

                this.$parent.updatePending(this.editingWidget.id);
                this.editingWidget.pendNewConfig();
            },

            toggle() {
                this.open = !this.open;
            },

            sendConfig() {
                if(!this.ready) { return; }

                this.$parent.updatePending(this.editingWidget.id);

                var controls = {
                    'metric': [this.metric],
                    'tree_models': this.tree_models,
                };

                const payload = {
                    widget_id: this.editingWidget.id,
                    controls: JSON.stringify(controls),
                };

                $.ajax({
                    url: '/dashboardv2/setWidgetCustomControls',
                    method: 'POST',
                    data: payload,
                }).done(data => {
                    if (data.success && data.newConfig) {
                        this.editingWidget.config = data.newConfig;
                        this.editingWidget.pendNewConfig();
                    }
                });
            },

            getMetricOptions() {
                if (!this.selectedInstance) {
                    this.metricOptions = [];
                    return;
                }

                let payload = {};
                let endpoint = '/util/get_metrics_from_instance';
                if (this.tree_models[0].startsWith('s-')) {
                    payload.instance_id = this.tree_models[0].replace('s-', '');
                } else if (this.tree_models[0].startsWith('ap-')) {
                    payload.instance_id = this.tree_models[0].replace('ap-', '');
                    payload.device_type = 'appliance';
                } else if (this.tree_models[0].startsWith('cs-')) {
                    payload.compound_service_id = this.tree_models[0].replace('cs-', '');
                    endpoint = '/util/get_metrics_from_compound_service';
                } else {
                    this.metricOptions = [];
                    return;
                }

                $.ajax({
                    url: endpoint,
                    method: 'GET',
                    data: payload,
                }).done(data => {
                    if (data.success) {
                        this.metricOptions = data.metrics;
                    } else {
                        this.metricOptions = [];
                    }
                });
            },

            getPortOptions() {
                if (!this.selectedInstance || !this.tree_models[0].startsWith('s-')) {
                    this.portOptions = [];
                    return;
                }

                const payload = {
                    instance_id: this.tree_models[0].replace('s-', '')
                };

                $.ajax({
                    url: '/util/get_network_ports_from_instance',
                    method: 'GET',
                    data: payload,
                }).done(data => {
                    if (data.success) {
                        this.portOptions = data.ports;
                    } else {
                        this.portOptions = []
                    }
                });
            },

            getMonitoringTreeDeferredURL() {
                if (this.options.instance_types == "network_device") {
                    return "/util/monitoring_tree_deferred?include_templates=1&instance_types=network_devices"
                }
                return "/util/monitoring_tree_deferred?include_compound_services=1&include_href=0"
            },

            getSelectLabel() {
                if (this.options.instance_types == "network_device") {
                    return "Port"
                }
                return "Metric"
            },

            getSelectOptions() {
                if (this.options.instance_types == "network_device") {
                    return this.getPortOptions();
                }
                return this.getMetricOptions();
            },

            selectOptions() {
                if (this.options.instance_types == "network_device") {
                    return this.portOptions;
                }
                return this.metricOptions;
            }
        },

        created() {
            var preset = this.editingWidget.config.custom_controls;

            if ('metric' in preset && preset['metric'].length) {
                this.metric = preset['metric'][0];
            }

            if ('server_tags' in preset && preset['server_tags'].length) {
                this.instanceTags = preset['server_tags'].slice(0);
            }

            if ('server_filter_type' in preset) {
                this.type = preset['server_filter_type'];
            }

            if ('server_tag_match' in preset) {
                this.instanceTagsType = preset['server_tag_match'];
            }

            if ('metrics' in preset) {
                this.selectedMetrics = preset['metrics'];
            }

            if ('metrics_tag_match' in preset) {
                this.secondaryFilter = 'tags';
                this.metricTagsType = preset['metrics_tag_match'];
            }

            if ('metrics_tags' in preset && preset['metrics_tags'].length) {
                this.metricTags = preset['metrics_tags'].slice(0);
            }

            if ('option_string' in preset) {
                this.secondaryFilter = 'optionString';
                this.optionString = preset['option_string'];
            }

            if ('tree_models' in preset && preset['tree_models'].length) {
                this.tree_models = preset['tree_models'];
                this.getSelectOptions();
            }

            Vue.nextTick(() => {
                this.ready = true;
            });
        }
    });
</script>
