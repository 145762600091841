<template>
    <div
        class="pa-toast"
        aria-live="polite"
        :style="{ display: localIsActive ? '' : 'none' }"
        :class="toastClass">
        <div class="pa-toast-hd"><slot name="icon"></slot></div>
        <div ref="body" class="pa-toast-bd">
            <slot></slot>
        </div>
        <div class="pa-toast-ft">
            <button
                type="button"
                class="pa-phonyButton"
                v-on:click="close()">
                <span class="pa-isVisuallyHidden">close dialog</span>
                <svg class="pa-icon pa-icon_block pa-icon_lg">
                    <use xlink:href="#close"></use>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
    import Vue  from 'vue';

    var dialogMixin = require('./../mixins/dialogMixin');

    var Toast = Vue.extend({
        mixins: [dialogMixin],

        data() {
            return {
                timeout: null,
                localIsActive: this.isActive
            };
        },

        props: {
            type: {
                type: String,
                default: ''
            },

            autoOpen: {
                type: Boolean,
                default: false
            },

            options: {
                type: Object,
                default: function() {
                    return {
                        duration: 5000,
                        stubborn: false
                    }
                }
            }
        },

        computed: {
            'toastClass': function() {
                let retVal = [];
                if (this.localIsActive) {
                    retVal.push('isActive');
                }
                if (this.type) {
                    retVal.push(`pa-toast_${this.type}`);
                }
                return retVal;
            },
        },
        vueReady() {
                if (this.autoOpen) {
                    this.open();
                }
        },

        methods: {
            open() {
                this.localIsActive = true;

                if (this.options.stubborn) {
                    return;
                }

                if (this.type === 'error'){
                    this.options.duration = 30000
                }
                this.timeout = setTimeout(this.close, this.options.duration);
            },

            close() {
                this.localIsActive = false;

                clearTimeout(this.timeout);
            }
        },

        events: {
            'toast:load': function(details) {
                if (details.id !== this.id || !details.message) {
                    return true;
                }
                $(this.$refs.body).html(details.message);
                this.open();
                return true;
            }
        }
    });

    export default Toast;
</script>
