<style lang="scss" scoped>
    .pa-tip-card {
        .gray-dot {        
            & > li {
                padding-left: 8px;
                &:before {
                    top: 12px;
                    border: 1px solid var(--p-grey-200);
                    background-color: var(--p-grey-200);
                }
            }
        }
    }

</style>


<template>
    <section class="pa-tip-card pa-card-shadow">
        <header class="pa-pt-8 pa-pb-4 pa-pl-12 pa-txt_bold">
            {{header}}
        </header>
        <p-divider></p-divider>
        <div>
            <ul class="pa-pl-12 pa-pb-16 pa-uList--styled gray-dot">
                <li v-for="tip in tips" class="pa-leading-5 pa-pt-4">
                    {{ tip }}
                </li>
            </ul>
        </div>
       
    </section>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        header: String,
        tips: Array,
    },
});
</script>