<style lang="scss">
    .pa-progress-bar {
        display: flex;
        flex-wrap: nowrap;
        background-color: grey;
        position: relative;
        height: 10px;
        width: 100%;
    }
</style>

<template>
    <div :data-total="total" class="pa-progress-bar">
        <div
            :key="index"
            v-for="(section, index) in sections"
            :style="{
                border: 'none',
                backgroundColor: section.color,
                width: section.width,
            }"
            :data-value="section.value"
        >
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            data: {
                type: Array,
                default: () => [],
            },
        },
        computed: {
            total() {
                return this.data.reduce((sum, item) => sum + item.value, 0);
            },
            sections() {
                return this.data.map((item) => ({
                    value: item.value,
                    width: `${this.convertToPercentage(item.value)}%`,
                    color: item.color,
                }));
            },
        },
        methods: {
            convertToPercentage(value) {
                return (value / this.total) * 100;
            }
        },
    });
</script>
