const dialogOpen = (() => {
    // params: ['target', 'template'],
    const state = new WeakMap();

    function _onClick(event) {
        event.preventDefault();

        const href = event.currentTarget.getAttribute('href');

        if (this.params.template || href && href !== '#') {
            window.app.eventHub.$emit('modal:load', {
                id: this.params.target,
                url: this.params.template || href,
                open: true,
            });

            return;
        }

        window.app.eventHub.$emit('dialog:open', this.params.target);
    };

    return {
        bind(el, binding, vnode) {
            const self = {};

            self.el = el;
            self.params = vnode.data.attrs;

            if (typeof self.params.target === 'undefined') {
                return;
            }

            self._onClick = _onClick.bind(self);

            el.addEventListener('click', self._onClick);
        },

        unbind(el, binding, vnode) {
            const self = state.get(el);

            if (self && self._onClick) {
                el.removeEventListener('click', self._onClick);
            }
        },
    };
})();

export default dialogOpen;
