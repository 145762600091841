<style>
    .template-snmp-rule .pa-drawer-bd {
        margin: 2px 24px 0;
        padding: 0.625rem 0 0.625rem 0;
    }
</style>
<template>
    <p-drawer ref="drawer" side="right" id="snmp-custom-rule-drawer" :width="484" sticky-footer>
        <div slot="trigger" style="display: none;"></div>
        <div class="pa-txt_lg pa-grid pa-align-center pa-txt_medium pa-pt-8" slot="head">
            <p-row slot="title" align-center>
                <span class="pa-pl-6">Edit Template SNMP Rule</span>
            </p-row>
        </div>
        <div slot="body" class="pa-drawer-bd">
            <div class="pa-mx-16 pa-pt-24">
                <p-field-group
                    name="name"
                    label="Name *"
                    :error="errors.error__name"
                    hint="The name for this rule. If we cant find a value for the label, this will be used as the metric label."
                >
                    <p-input :value="name" v-model="name"></p-input>
                </p-field-group>
                <p-field-group
                    name="base_oid"
                    label="Base Oid *"
                    hint="Numeric oid for the start of our tree where the data oid is located."
                    :error="errors.error__base_oid"
                >
                    <p-input :value="baseOid" v-model="baseOid"></p-input>
                </p-field-group>
                <p-field-group
                    name="data_oid"
                    label="Data Oid *"
                    :error="errors.error__data_oid"
                    hint="Numeric oid for the of the tree where metric data is going to be extracted."
                >
                    <p-input :value="dataOid" v-model="dataOid"></p-input>
                </p-field-group>
                <p-field-group
                    name="label_oid"
                    label="Label Oid"
                    hint="Optional numeric oid for the tree position where the label information is located."
                >
                    <p-input :value="labelOid" v-model="labelOid"></p-input>
                </p-field-group>
                <p-field-group
                    name="max_val_oid"
                    label="Max Value Oid"
                    hint="Optional numeric oid for the tree position where the  information is located."
                >
                    <p-input :value="maxValOid" v-model="maxValOid"></p-input>
                </p-field-group>
            </div>
            <div class="edit-instance-footer">
                <div class="edit-instance-footer-wrapper pa-grid pa-justify-end pa-py-12">
                    <button @click="close" type="button" class="pa-btn pa-btn_blue-secondary pa-mr-12">
                        Cancel
                    </button>
                    <button
                        class="pa-btn pa-btn_blue pa-mr-24"
                        type="button"
                        @click="saveRule"
                    >
                        Save rule
                    </button>
                </div>
            </div>
        </div>
    </p-drawer>
</template>
<script>
import Vue from 'vue';

const DRAWER_ID = 'snmp-custom-rule-drawer';
const LOCKED_BODY_CLASS = 'snmp-custom-rule-locked';

export default Vue.extend({
    events: {
        [DRAWER_ID + ':open']: function(ruleId, reload) {
            // Reset form before opening to erase any unsaved changes
            // or to set new data from saved changes
            this.getDrawerData(ruleId, reload);
            if(this.$refs.drawer) {
                this.$refs.drawer.open();
            }
        },
        [DRAWER_ID + ':close']: function() {
            this.$refs.drawer.close();
        },
        'drawer-open': function(drawerId, ruleId) {
            if (drawerId !== DRAWER_ID) {
                return;
            }

            if (document.body.scrollHeight > window.innerHeight) {
                document.documentElement.classList.add(LOCKED_BODY_CLASS);
            }

            if (ruleId) {
                this.getDrawerData(ruleId);
            }

        },
        'drawer-close': function(drawerId) {
            if (drawerId !== DRAWER_ID) {
                return;
            }

            if (document.documentElement.classList.contains(LOCKED_BODY_CLASS)) {
                document.documentElement.classList.remove(LOCKED_BODY_CLASS);
            }

        },
    },
    data: function() {
        return {
            loading: true,
            errors: {},
            refresh: true,
            name: null,
            baseOid: null,
            dataOid: null,
            labelOid: null,
            maxValOid: null,
        }
    },

    props: {},

    methods: {
        close() {
            this.$refs.drawer.close();
        },

        saveRule() {
            let data = {
                base_oid: this.baseOid,
                data_oid: this.dataOid,
                name: this.name,
                max_val_oid: this.maxValOid,
                label_oid: this.labelOid,
                rule_id: this.ruleId
            }
            $.ajax({
                url: '/config/editCustomSNMPRule',
                method: 'POST',
                data: data,
                context: this
            }).done(function(response) {
                if (response.success) {
                    window.app.toastManager.showToast({
                        text: 'Rule saved correctly', icon: 'check'
                    })
                    this.refresh = false;
                    this.$nextTick(() => {
                        this.refresh = true;
                        this.$refs.drawer.close();
                        this.$root.$broadcast('table:reload_ajax');
                    });
                } else {
                    if (!_.isEmpty(response.errors)) {
                        this.errors = Object.assign({}, response.errors);
                    }
                }
            }).fail(function(response) {
                console.log(response)
                window.app.toastManager.showToast({
                    text: 'Error when saving the rule', type: 'error', icon: 'alert-circle'
                });
                this.$refs.drawer.close();
            })
        },

        resetValues() {
            this.errors = {};
            this.name = null;
            this.baseOid = null;
            this.dataOid = null;
            this.labelOid = null;
            this.maxValOid = null;
        },

        getDrawerData(ruleId) {
            this.resetValues();

            if(ruleId === null) {
                return;
            }

            this.ruleId = ruleId;

            $.ajax({
                url: `/config/EditCustomSNMPRule?rule_id=${ruleId}`,
                method: 'GET',
                context: this
            }).done(function(response) {
                if (response.success) {
                    this.name = response.name;
                    this.baseOid = response.base_oid;
                    this.dataOid = response.data_oid;
                    this.labelOid = response.label_oid;
                    this.maxValOid = response.max_val_oid;
                }
            })
        }
    }
});
</script>
