<template>
    <p-flex class="pa-side-nav__footer pa-px-8 pa-pt-8 pa-pb-16 pa-space-between"  :class="footerClassName">
        <a v-if="showAddBtn" href="/onboarding/v2/" class="pa-side-nav__footer_add" :class="[isCollapsed && 'large_icon pa-mb-10 pa-ml-4']">
            <p-icon 
                icon='add_circle'
                :size="!isCollapsed ? 'xsm': 'lg'" color="silver">
            </p-icon>
            <span v-if="!isCollapsed">Add</span>
        </a>
        <button class="pa-side-nav__footer_collapse" @click="toggleCollapse">
            <p-icon 
                :icon="isCollapsed ? 'leftnav_expand_24dp' : 'leftnav_collapse_24dp'"
                size="xxxl" color="silver">
            </p-icon>
        </button>
       
    </p-flex>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    isCollapsed: Boolean,
    showAddBtn: [Boolean, true],
});

const emit = defineEmits(['toggle-collapse']);

const toggleCollapse = () => {
    emit('toggle-collapse');
};

const footerClassName = computed(() => ({
    'collapsed_state pa-flex-column' : props.isCollapsed,
    'no_add_btn': !props.showAddBtn && !props.isCollapsed
}));
</script>
