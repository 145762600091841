<style>
    .global-datetime-btn {
        padding: 7px 11px 7px 8px;
        background: #fff;
        cursor: pointer;
        border: 1px solid #eaeaea;

        font-size: 13px;
        line-height: 14.25px;
    }
    .global-datetime-btn.selected {
        background-color: #2c4163;
        color: #fff;
        border: 1px solid #2c4163
    }
    .global-datetime-btn:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .global-datetime-btn:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .global-datetime-btn input {
        display: none;
    }
    .custom-slide-transition {
        transition: all .6s ease;
        margin-left: 15px;
        left: 0px;
    }
    .custom-slide-enter, .custom-slide-leave {
        margin-left: -350px;
        left: 400px;
    }
    #global_start, #global_end {
        height: 31px;
    }
    .spacer-widget .ui-resizable-handle {
        display: none !important;
    }
    .pa-icon.pa-icon_blue {
        fill: #396ABF;
        color: #396ABF;
    }
    #dashboard-actions .hr {
        width: 48px;
        height: 15px;

        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #848e99;
    }
    #dashboard-actions .selected .hr {
        color: #fff;
    }
    #dashboard-actions .selected .pa-icon {
        fill: #fff;
    }
    #dashboard-actions li.separator {
        height: 32px;
        border-right: solid 1px #e6e6e6;
    }
    #default-dash-menu {
        margin-top: 0.5rem;
        margin-left: -0.2rem;

        .pa-menu-box {
            top: 40px !important;
        }
    }
    .dashboard-header li.separator {
        height: 32px;
        border-right: solid 1px #e6e6e6;
    }
    #add-widget .pa-btn.naked.blue {
        color: #396abf;
    }
    .screenshot-button:hover .pa-icon {
        fill: #396abf;
    }
    .pa-icon_blue {
        fill: #396abf;
    }
    .pa-page.pa-page_dashboard.empty-dashboard {
        background-image: url('/static/images/dash-empty-illo.png');
        background-repeat: no-repeat;
        background-position: center;
        background-attachment: fixed;
        background-size: 322px 247px;
    }
    .widget-header.edit-mode .pa-graph-unit-select .pa-select-hd,
    .widget-header.edit-mode .pa-graph-unit-select.isActive .pa-select-hd {
        color: white;
    }
    .grid-stack > .grid-stack-item {
        scroll-margin-top: 150px;
    }
    .grid-stack-wrapper {
        overflow-y: auto;
    }
    .dashboard-switcher {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 250px;
    }
</style>

<template>
    <div>
        <div v-show="!isReloading && notupdating" class="pa-message pa-message_error4 pa-border-none">
            <svg class="pa-icon pa-icon_md middle not-updating-icon" style="fill: #D73C2C; vertical-align: middle; padding-bottom: 5px;">
                <use xlink:href="#alert-circle"></use>
            </svg> Some widgets are not updated. <a href="javascript:window.location.reload(true)">Refresh</a> page to update.
        </div>
        <div class="dashboard-header pa-page-header--no-breadcrumbs pa-py-12 pa-px-16" :style="computedHeaderStyle">
            <div class="dashboard-switcher">
                <slot name="dashboard-switcher"></slot>
            </div>
            <div>
                <ul class="pa-hList pa-hList_x3" id="dashboard-actions">
                    <li>
                        <p-dashboard-scoping
                            :scope-type="scopeType"
                            :attr-types="scopeAttrTypesData"
                            :tags="scopeTagsData"
                            ref="dashboard_scoping_component"
                        >
                        </p-dashboard-scoping>
                    </li>

                    <li style="margin-left: 0;">
                        <p class="pa-txt_xs pa-text-grey-500" style="padding-top:10px">
                           Timezone: {{ this.timezone }}
                        </p>
                    </li>

                    <li v-if="!editing">
                        <label class="global-datetime-btn" :class="{selected: timescale_override === '15min'}">
                            <input type="radio" v-model.sync="timescale_override" value="15min">
                            <span class="hr">15m</span>
                        </label>
                        <label style="border-left: 0px;" class="global-datetime-btn" :class="{selected: timescale_override === 'hour'}">
                            <input type="radio" v-model.sync="timescale_override" value="hour">
                            <span class="hr">1hr</span>
                        </label>
                        <label style="border-left: 0px;" class="global-datetime-btn" :class="{selected: timescale_override === '6hr'}">
                            <input type="radio" v-model.sync="timescale_override" value="6hr">
                            <span class="hr">6hr</span>
                        </label>
                        <label style="border-left: 0px;" class="global-datetime-btn" :class="{selected: timescale_override === '12hr'}">
                            <input type="radio" v-model.sync="timescale_override" value="12hr">
                            <span class="hr">12hr</span>
                        </label>
                        <label style="border-left: 0px;" class="global-datetime-btn" :class="{selected: timescale_override === 'day'}">
                            <input type="radio" v-model.sync="timescale_override" value="day">
                            <span class="hr">1d</span>
                        </label>
                        <label style="border-left: 0px;" class="global-datetime-btn" :class="{selected: timescale_override === 'week'}">
                            <input type="radio" v-model.sync="timescale_override" value="week">
                            <span class="hr">1w</span>
                        </label>
                        <label style="border-left: 0px;" class="global-datetime-btn" :class="{selected: timescale_override === 'custom'}">
                            <input type="radio" v-model.sync="timescale_override" value="custom">
                            <svg class="pa-icon" fill="#848e99" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M19 19H5V8h14m-3-7v2H8V1H6v2H5c-1.11 0-2 .89-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2h-1V1m-1 11h-5v5h5v-5z"/>
                            </svg>
                        </label>
                    </li>
                    <li v-show="timescale_override === 'custom'" transition="custom-slide" style="padding-top:25px">
                    <p-datetime name="global_start"
                                placeholder="Start"
                                css-class="pa-input_xs"
                                autocomplete="off"
                                :date.sync="customStartDate"                               
                    ></p-datetime>
                    <p-datetime name="global_end"
                                placeholder="End"
                                css-class="pa-input_xs"
                                autocomplete="off"
                                :date.sync="customEndDate"
                    ></p-datetime>
                    </li>
                    <li v-if="!editing && canScreenshotDashboard" style="display: grid;">
                        <p-button
                            @click="screenshotDashboard"
                            unstyled
                            no-base-class
                            size="sm"
                            :disabled="loadingScreenshot"
                            class="screenshot-button pa-mt-1"
                        >
                            <svg v-if="!loadingScreenshot" class="pa-icon pa-icon_blue-grey pa-mt-8" fill="#848e99" style="transform: scale(1.1);" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M20 4h-3.17L15 2H9L7.17 4H4a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2m0 14H4V6h4.05l1.83-2h4.24l1.83 2H20v12M12 7a5 5 0 00-5 5 5 5 0 005 5 5 5 0 005-5 5 5 0 00-5-5m0 8a3 3 0 01-3-3 3 3 0 013-3 3 3 0 013 3 3 3 0 01-3 3z"/>
                            </svg>
                            <p-loading-spinner-2 v-else></p-loading-spinner-2>
                        </p-button>
                    </li>
                    <li class="separator left"></li>
                    <li v-if="allowEditing && !editing">
                        <button type="button" class="pa-btn"
                            @click="toggleEditDashboardMode">
                            Edit
                        </button>
                    </li>
                    <li v-if="!editing" class="separator left"></li>
                    <li>
                        <p-material-menu
                            id="default-dash-menu"
                            btn-color="blue"
                            btn-variant="ghost"
                        >
                            <button
                                @click="setDefaultDashboard"
                                class="checkbox_input"
                            >
                                <span>Set as Default Dashboard</span>
                            </button>
                        </p-material-menu>
                    </li>
                    <li v-if="editing && userDeleteAccess">
                        <p-tooltip2 placement="bottom">
                            <button slot="trigger" type="button" class="pa-btn pa-btn_sm naked"
                                v-p-prompt-open title="Delete Dashboard?"
                                body="Are you sure you want to delete this dashboard?"
                                :href="`../dashboardv2/deleteDashboard?dashboard_id=${id}`" target="dynamic_prompt">
                                <svg style="height:16px;width:16px;" class="pa-icon pa-icon_blue"><use xlink:href="#trashcan"></use></svg>
                            </button>
                            <span>Delete</span>
                        </p-tooltip2>
                    </li>
                    <li v-if="editing && userEditPropertiesAccess">
                        <p-tooltip2 placement="bottom">
                            <button slot="trigger" type="button" class="pa-btn pa-btn_sm naked" v-p-dialog-open
                                :disabled="!userEditPropertiesAccess" @click="toggleEditDashboard(id)">
                                <svg class="pa-icon pa-icon_blue"><use xlink:href="#cog-outline"></use></svg>
                            </button>
                            <span>Setting</span>
                        </p-tooltip2>
                    </li>
                    <li v-if="editing" class="separator"></li>
                    <li v-if="editing">
                        <button ref="editbtn"
                                type="button" class="pa-btn"
                                @click="toggleEditDashboardMode">
                            <svg class="pa-icon"><use xlink:href="#check"></use></svg>
                            Save & Close
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="grid-stack-wrapper pa-pt-20" :style="gridStackWrapperStyle">
            <div class="grid-stack grid-stack_custom pa-pb-20">
                <component :is="widget.type" ref="widgets" v-for="widget in widgets"
                    :key="widget.id"
                    :id="widget.id"
                    :config="widget.config"
                    :editing="editing">
                </component>
                <div v-if="!widgets.length && !editing" class="empty-message">
                    <p>There are currently no widgets configured for this dashboard.</p>
                    <p>Click Edit to get started.</p>
                </div>
            </div>
        </div>
        <p-config-drawer ref="configdrawer" :features="features"></p-config-drawer>
        <p-drawer id="alert-recipients-drawer" :width="500">
            <div slot="trigger" style="display: none;">
            </div>
        </p-drawer>
    </div>
</template>

<script>
    let $ = null;

    import Vue from 'vue';
    import Visibility from 'visibilityjs';
    import _ from "lodash";

    import getUrlParams from '../shared/utils/getUrlParams.js';

    import ConfigDrawer from './ConfigDrawer.vue';
    import WidgetDrawer from './WidgetDrawer.vue';
    import Scoping from './Scoping.vue';

    import WidgetBlock from './WidgetBlock.vue';
    import WidgetPlaceholder from './WidgetPlaceholder.vue';

    import OutageCounterWidget from './widgets/OutageCounterWidget.vue';
    import BarChartWidget from './widgets/BarChartWidget.vue';
    import GaugeWidget from './widgets/GaugeWidget.vue';
    import IncidentDetailsWidget from './widgets/IncidentDetailsWidget.vue';
    import HeatmapWidget from './widgets/HeatmapWidget.vue';
    import HTMLWidget from './widgets/HTMLWidget.vue';
    import GeomapWidget from './widgets/GeomapWidget.vue';
    import LineGraphWidget from './widgets/LineGraphWidget.vue';
    import AggregatedMetricWidget from './widgets/AggregatedMetricWidget.vue';
    import SingleMetricWidget from './widgets/SingleMetricWidget.vue';
    import ScorecardWidget from './widgets/ScorecardWidget.vue';
    import SummaryTableWidget from './widgets/SummaryTableWidget.vue';
    import UpDownWidget from './widgets/UpDownWidget.vue';
    import StoplightWidget from './widgets/Stoplight.vue';
    import OnCallWidget from './widgets/OnCallWidget.vue';
    import InOutWidget from './widgets/InOutWidget.vue';
    import NetworkInterfacesWidget from './widgets/NetworkInterfacesWidget.vue';
    import ResourceTypeCounterWidget from './widgets/ResourceTypeCounterWidget.vue';
    import KubernetesContainerCountWidget from './widgets/KubernetesContainerCountWidget.vue';
    import KubernetesPodCountWidget from './widgets/KubernetesPodCountWidget.vue';
    import KubernetesNodeCountWidget from './widgets/KubernetesNodeCountWidget.vue';
    import K8sTableWidget from './widgets/K8sTableWidget.vue';
    import FabricTopologyWidget from './widgets/FabricTopologyWidget.vue';
    import { screenshotDashboard } from '../api/services/DashboardService';

    const MAX_LINE_SERIES = 50;
    const WEBSOCKET_TOAST_TIMEOUT = 60 * 1000;

    const MINUTE = 1000 * 60;
    const WINDOW_TIMEOUT_MINUTES = 5;

    const READY_STATE_TYPE = {
        CONNECTING: "CONNECTING",
        OPEN: "OPEN",
        CLOSING: "CLOSING",
        CLOSED: "CLOSED",
    };

    const READY_STATE_VALUES = {
        [READY_STATE_TYPE.CONNECTING]: 0,
        [READY_STATE_TYPE.OPEN]: 1,
        [READY_STATE_TYPE.CLOSING]: 2,
        [READY_STATE_TYPE.CLOSED]: 3,
    };

    const WEBSOCKET_STATUS_CODE_TYPE = {
        WINDOW_VISIBILITY_TIMEOUT: "WINDOW_VISIBILITY_TIMEOUT",
    };

    const WEBSOCKET_STATUS_CODES = {
        [WEBSOCKET_STATUS_CODE_TYPE.WINDOW_VISIBILITY_TIMEOUT]: 4000,
    };

    const Dashboard = Vue.extend({
        components: {
            'p-config-drawer': ConfigDrawer,
            'p-widget-drawer': WidgetDrawer,
            'p-dashboard-scoping': Scoping,

            'p-widget-block': WidgetBlock,
            'p-widget-placeholder': WidgetPlaceholder,

            'p-activeoutage-widget': OutageCounterWidget,
            'p-active-incidentdetails-widget': IncidentDetailsWidget,
            'p-resolvedoutage-widget': OutageCounterWidget,
            'p-barchart-widget': BarChartWidget,
            'p-gauge-widget': GaugeWidget,
            'p-html-widget': HTMLWidget,
            'p-geomap-widget': GeomapWidget,
            'p-linegraph-widget': LineGraphWidget,
            'p-linegraph-table-widget': LineGraphWidget,
            'p-metricheatmap-widget': HeatmapWidget,
            'p-serverheatmap-widget': HeatmapWidget,
            'p-serversummary-widget': SummaryTableWidget,
            'p-metricsummary-widget': SummaryTableWidget,
            'p-oncall-widget': OnCallWidget,
            'p-rollup-widget': SummaryTableWidget,
            'p-metricupdown-widget': UpDownWidget,
            'p-resolved-incidentdetails-widget': IncidentDetailsWidget,
            'p-aggregatedmetric-widget': AggregatedMetricWidget,
            'p-aggregatedavail-widget': AggregatedMetricWidget,
            'p-serverscorecard-widget': ScorecardWidget,
            'p-singlemetric-widget': SingleMetricWidget,
            'p-stoplight-widget': StoplightWidget,
            'p-bandwidth-io-widget': InOutWidget,
            'p-network-errors-io-widget': InOutWidget,
            'p-packets-io-widget': InOutWidget,
            'p-network-interfaces-widget': NetworkInterfacesWidget,
            'p-resource-type-counter-widget': ResourceTypeCounterWidget,
            'p-kubernetes-container-count-widget': KubernetesContainerCountWidget,
            'p-kubernetes-pod-count-widget': KubernetesPodCountWidget,
            'p-kubernetes-node-count-widget': KubernetesNodeCountWidget,
            'p-pod-phase-table-widget': K8sTableWidget,
            'p-container-termination-table-widget': K8sTableWidget,
            'p-container-waiting-table-widget': K8sTableWidget,
            'p-worst-offenders-table-widget': SummaryTableWidget,
            'p-incidents-by-user-table-widget': SummaryTableWidget,
            'p-fabric-topology-widget': FabricTopologyWidget,
        },

        events: {
            // Sent from a widget when its dom has finished loading; add to gridstack
            'widget:domready': function(widget) {
                this.grid.makeWidget(`#${widget.id}`);
                // TODO: Fix for gridstack rendering widgets with 0 height
                this.grid._updateStyles();
                $(`#${widget.id}`).on('resizestop', $.proxy(function(event, ui) {
                    this.$broadcast('widget:reinit', event.target.id);
                }, this));
            },
            // Sent from a modal when the user commits a new config for a widget
            'widget:config_changed': function(widget_id) {
                this.$broadcast('widget:pend_config', widget_id);
                const message = { message_type: 'reconfig', widget_id: widget_id };
                this.websocket.send(JSON.stringify(message));
            },
            // Sent from a control when an override changes
            'widget:override_change': function(widget_id, override) {
                const message = { message_type: 'override', widget_id: widget_id, override: override };
                this.websocket.send(JSON.stringify(message));
            },
            'widget:add_widget': function(type, id, config) {
                this.addWidget(type, id, config);
            },
            'widget:clone_widget': function(data) {
                this.cloneWidget(data);
            },
            'dashboard:confirm_delete': function(widget_id, close_drawer=true) {
                window.app.rootVue.$broadcast('dialog:close', 'dynamic_prompt');
                const payload = { widget_id: widget_id };
                $.ajax({
                    url: '/dashboardv2/deleteWidget',
                    method: 'GET',
                    data: payload,
                });

                // Close the drawer
                const drawer = this.$refs.configdrawer;
                if (drawer.isOpen && close_drawer) {
                    drawer.close();
                }

                const widgetIndex = this.widgets.findIndex(w => {
                    return w.id === widget_id;
                });
                if (widgetIndex > -1) {
                    const w = this.widgets[widgetIndex];
                    this.widgets.splice(widgetIndex, 1);
                    const gsIndex = this.grid.grid.nodes.findIndex(n => {
                        return n.y === w.config.row && n.x === w.config.col;
                    });
                    if (gsIndex > -1) {
                        // Delete node from gridstack's memory
                        this.grid.grid.nodes.splice(gsIndex, 1);
                    }
                }

                // Clear out the widget's seriesCount if present
                if (this.seriesCounts[widget_id]) {
                    // Vue.set(`seriesCounts[${widget_id}]`, 0);
                    this.seriesCounts = {
                        ...this.seriesCounts,
                        [widget_id]: 0
                    };
                }

                if (widget_id > -1) {
                    this.eventHub.$emit('dashboard:confirm_delete', -1, false);
                    this.eventHub.$emit('dashboard:add_placeholder');
                }

            },
            'dashboard:add_placeholder': function() {
                this.$nextTick(() => {
                    this.addPlaceholder();
                });
            },
            'dashboard:update_chosen_editing_widget': function(widget_id, status=null) {
                const widgetIndex = this.widgets.findIndex(w => {
                    return w.id === widget_id;
                });
                const w = this.widgets[widgetIndex];

                window.app.rootVue.$broadcast('dashboard:open_config', w, status);    
    
            },
            'dashboard:update_editing_widget': function(widget_id) {
                const widgetIndex = this.widgets.findIndex(w => {
                    return w.id === widget_id;
                });
                const w = this.widgets[widgetIndex];

                window.app.rootVue.$broadcast('dashboard:open_widget_select', w);
            },
            'widget-content:ready': function(widgetId) {
                if (this.$refs && this.$refs.configdrawer) {
                    this.$refs.configdrawer.contentReloadComplete(widgetId);
                }
                return true;
            },
        },

        data() {
            return {
                websocket_url: '',
                websocket: null,
                readySent: false,
                widgets: [],
                grid: null,
                newWidgetCount: 0,
                spacerWidget: null,
                toasts: [],
                seriesCounts: {},
                timeout: 2,
                timescale_override: 'none',
                custom_timescale_override: '',
                customStartDate: '',
                customEndDate: '',
                timescale_override_options: [
                    { value: 'none', label: 'Widget Defaults' },
                    { value: '15min', label: 'Last 15 Minutes' },
                    { value: 'hour', label: 'Last Hour' },
                    { value: 'day', label: 'Last 24 Hours' },
                    { value: 'week', label: 'Last Week' },
                    { value: 'month', label: 'Last Month' },
                    { value: 'year', label: 'Last Year' },
                    { value: 'custom', label: 'Custom' },
                ],
                gridMask: null,
                defaultPlaceholderWidth: 5,
                defaultPlaceholderHeight: 4,
                loadingScreenshot: false,
                announcementHeight: 41,
                scopeUuid: null,
                windowVisibilityTimer: null,
                windowVisibility: Visibility.isSupported() ? (Visibility.state() || "visible") : "visible",
                isReloading: false,
                scopeTypeData: this.scopeType,
                scopeAttrTypesData: this.scopeAttrTypes,
                scopeTagsData: this.scopeTags,
                customStartDate: null,
                customEndDate: null,
                editing: false,
                gridStackWrapperStyle: null,
                notupdating:false,
            };
        },

        computed: {
            customDateRange() {
                if (!this.customStartDate || !this.customEndDate) {
                    return null;
                }

                const now = new Date();

                let start_date = new Date(this.customStartDate);
                let end_date = new Date(this.customEndDate);

                if (start_date > now || end_date < start_date) {
                    return null;
                }

                start_date = start_date.toUTCString();
                end_date = end_date.toUTCString();

                return `${start_date}-${end_date}`;
            },
            currentDashboard: function() {
                return this.dashboards.find(d => d.id === this.id);
            },
            computedHeaderStyle: function() {
                return { 'z-index': this.public ? '20' : '1' };
            },
            allowEditing: function() {
                if (!this.userEditAccess || this.public) {
                    return false;
                }
                return true;
            },
            showLinks: function() {
                return !this.public;
            },
            totalLineSeries: function() {
                const reducer = (accumulator, currentValue) => accumulator + currentValue;
                const total = Object.values(this.seriesCounts).reduce(reducer, 0);
                return total;
            },
            disableCrosshairSync: function() {
                const total = this.totalLineSeries;
                return total > MAX_LINE_SERIES;
            }
        },

        props: {
            dashboards: Array,
            id: {
                type: Number,
                'default': 0,
            },
            cId: {
                type: String,
                'default': '',
            },
            aclHash: {
                type: String,
                'default': '',
            },
            debug: {
                type: Boolean,
                'default': false,
            },
            debugWebsocketMessages: {
                type: Boolean,
                'default': false,
            },
            newTour: {
                type: Boolean,
                'default': false,
            },
            startEditing: {
                type: Boolean,
                'default': false,
            },
            resellerDashboard: Boolean,
            userEditAccess: Boolean,
            userEditPropertiesAccess: Boolean,
            userAddAccess: Boolean,
            userDeleteAccess: Boolean,
            userIncidentCollab: Boolean,
            userServerView: Boolean,
            userNetworkView: Boolean,
            userCompoundView: Boolean,
            userOnsightView: Boolean,
            userGroupView: Boolean,
            userOutageView: Boolean,
            publicUrl: String,
            'public': {
                type: Boolean,
                'default': false,
            },
            canScreenshotDashboard: Boolean,
            canSeeLimitByThreshold: {
                type: Boolean,
                default: false
            },
            canSeeDeletedInstancesFilter: {
                type: Boolean,
                default: false
            },
            scopeType: String,
            scopeAttrTypes: {
                type: Array,
                default: () => [],
            },
            timezone: {
                type: String,
                default: "UTC/UTC"
            },
            scopeTags: {
                type: Array,
                default: () => [],
            },
            customerAddons: {
                type: Object,
                'default': () => ({}),
            },
            features: {
                type: Array,
                'default': () => [],
            },
        },

        methods: {
            setDefaultDashboard() {
                const payload = {
                    dashboard_id: this.id,
                };
                $.ajax('/dashboardv2/setDefaultDashboard', {
                    method: 'POST',
                    data: payload,
                    context: this,
                }).done(function (data) {
                    let toast = {}
                    if (!data.success) {
                        console.log(data.msg);
                        toast = {
                            type: 'error',
                            icon: 'x',
                            text: data.toast
                        }
                    } else {
                        toast = {
                            type: 'success',
                            icon: 'check',
                            text: data.toast
                        }
                    } 
                    window.app.toastManager.showToast(toast);
                });

            },
            onResize() {
                this.setGridStackWrapperStyle();
            },
            debounceOnResize: _.debounce(function () {
                this.onResize();
            }, 100),
            setGridStackWrapperStyle() {
                const height = this.getGridStackHeightOffset();

                this.gridStackWrapperStyle = {
                    ...this.gridStackWrapperStyle,
                    height: `calc(100vh - ${height}px)`
                };
            },
            isScoping() {
                return ["tags", "attributes"].includes(this.scopeType);
            },
            userCanView(textkey) {
                let accessLevel = {
                    'configuration.server.view': this.userServerView,
                    'configuration.network_device.view': this.userNetworkView,
                    'configuration.compound_service.view': this.userCompoundView,
                    'configuration.group.view': this.userGroupView,
                    'configuration.server_group.view': this.userGroupView,
                    'configuration.onsight.view': this.userOnsightView,
                    'outage.view': this.userOutageView
                }
                if (Object.keys(accessLevel).includes(textkey)){
                    return accessLevel[textkey]
                } else {
                    return true;
                }
            },
            applyQueryParams: function() {
                const urlParams = getUrlParams(window.location.search);

                if (this.scopeType == 'tags' && urlParams[this.scopeType]) {
                    this.setScopingData("tags", null, this.getQueryParmValues(urlParams[this.scopeType]));
                    this.setSessionScope();
                    return true;
                }

                if (this.scopeType == 'attributes') {
                    for (const param in urlParams) {
                        for(const i in this.scopeAttrTypesData) {
                            if(encodeURIComponent(this.scopeAttrTypesData[i]['name']) ==
                                param) {
                                let paramsToBeSet = [];
                                let wildCardFoundInParams = false;
                                let selectedParams = 
                                    this.getQueryParmValues(urlParams[param]);
                                for(const selectedParam of selectedParams) {
                                    if(selectedParam == '*') {
                                        wildCardFoundInParams = true;
                                        break;
                                    }
                                    
                                    paramsToBeSet.push(selectedParam)
                                    
                                }

                                this.scopeAttrTypesData[i]['selected'] = paramsToBeSet;

                                if(wildCardFoundInParams) {
                                    this.scopeAttrTypesData[i]["selected"] = ['*'];
                                }
                            }
                        }
                    }
                    this.setSessionScope();
                }
            },
            getQueryParmValues(paramList) {
                let queryParams = [];
                let params = paramList.split(',');
                
                for (const param of params) {
                    queryParams.push(param);
                }

                return queryParams;
            },
            async screenshotDashboard() {
                this.loadingScreenshot = true;

                try {
                    const response = await screenshotDashboard({ dashboardId: this.id });

                    if (response.headers["content-type"].includes("application/json")) {
                        window.app.toastManager.showToast({
                            text: response.data.error,
                            type: 'error',
                            icon: 'alert-circle'
                        });
                    } else {
                        const blob = new Blob([response.data], { type: "image/png" });
                        const url = window.URL.createObjectURL(blob);

                        const dateTime = new Date().toISOString();
                        const dashboardName = this.currentDashboard.name.replace(/ /g, "_");
                        const date = dateTime.slice(0, 10);
                        const time = dateTime.slice(11, 19);
                        const fileName = `${dashboardName}_${date}_${time}.png`;

                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();

                        link.remove();
                        window.URL.revokeObjectURL(url);
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    this.loadingScreenshot = false;
                }
            },
            connect(websocket_url) {
                this.websocket_url = websocket_url;
                console.log('Attempting websocket connection...');
                try {
                    this.websocket = new WebSocket(websocket_url);
                    this.websocket.onopen = this.onOpen;
                    this.websocket.onclose = this.onClose;
                    this.websocket.onmessage = this.onMessage;
                    this.websocket.onerror = this.onError;
                } catch (error) {
                    console.log(error);
                }
            },

            onOpen(evt) {
                console.log('Websocket opened');
                this.timeout = 2;
                let readyURL = '/dashboardv2/dashboardReady';
                if (this.public) {
                    readyURL = '/dashv2/dashboardReady';
                }
                if (!this.readySent) {
                    const payload = {
                        dashboard_id: this.id,
                        connection_id: this.cId,
                    };
                    $.ajax({ url: readyURL,
                        method: 'GET',
                        data: payload,
                    }).done(data => {
                        this.applyQueryParams();
                    });
                    this.readySent = true;
                }

                if (this.wsCloseTimeout) {
                    clearTimeout(this.wsCloseTimeout);
                    this.wsCloseTimeout = null;
                }
            },

            onClose(evt) {
                console.log(`Websocket closed with code: ${evt.code}`);
                if (
                    evt.code === 1001 ||
                    // Dont try to reconnect if websocket is closed due to window visibility timeout, we will
                    // reconnect once the window is visible again.
                    evt.code === WEBSOCKET_STATUS_CODES[WEBSOCKET_STATUS_CODE_TYPE.WINDOW_VISIBILITY_TIMEOUT]
                ) {
                    return;
                }

                console.log(`Reconnecting in ${this.timeout} seconds...`);
                if (!this.websocket_url.includes('reconnect')) {
                    this.websocket_url = `${this.websocket_url}&try=reconnect`;
                }
                window.setTimeout(() => {
                    this.connect(this.websocket_url);
                }, this.timeout * 1000);
                this.timeout *= 2;
            },

            onMessage(evt) {
                const data = JSON.parse(evt.data);
                if (this.debugWebsocketMessages) {
                    console.log(`N:"${data.config && data.config.title}" W:${data.id} E:${data.entry_id} A:${data.state} T:${data.render_time}`);
                    console.log(data);
                }
                if (data.state === 'force-reload') {
                    this.isReloading = true;
                    window.location.reload();
                }
                const config_data = data.config || {};
                config_data.tags = data.tags;
                config_data.servers = data.servers;
                config_data.compound_services = data.compound_services;
                config_data.server_groups = data.server_groups;
                config_data.metric_textkeys = data.metric_textkeys;
                config_data.vue_type = data.vue_type;
                config_data.scope_uuid = data.scope_uuid;
                const metadata = Object();
                metadata.entry_id = data.entry_id;
                metadata.state = data.state;
                metadata.next_update = data.next_update;
                metadata.pending_widgets = data.pending_widgets;
                metadata.render_time = data.render_time;
                metadata.update_delta = data.update_delta;
                if (!data.success) {
                    this.$broadcast('widget:display_error',
                        data.id, config_data, data.content, metadata);
                } else if (data.state === 'create') {
                    // Set data attribute for tracking widget creation for automation
                    const widgetEl = document.getElementById(String(data.id));
                    if (widgetEl) {
                        widgetEl.dataset.widgetCreated = "true";
                    }
                    this.$broadcast('widget:new_data',
                        data.id, config_data, data.content, metadata);
                } else if (data.state === 'attach') {
                    if (data.textkey === 'line_graph' || data.textkey === 'bar_chart' ||
                            data.textkey === 'gauge') {
                        this.$broadcast('widget:attach_data',
                            data.id, config_data, data.content, metadata);
                    } else {
                        this.$broadcast('widget:new_data',
                            data.id, config_data, data.content, metadata);
                    }
                } else {
                    this.$broadcast('widget:updated_data', data.id, data.content, metadata);
                }
            },

            onError(evt) {
                console.log(evt.data);
            },

            addWidget(type, id, config) {
                const newWidget = {
                    type: type,
                    id: id,
                    config: config,
                };

                this.widgets.push(newWidget);
            },

            scrollWidgetIntoView(widgetId) {
                const $widgetEl = $(`#${widgetId}`);

                if (!$widgetEl || !$widgetEl.length) {
                    return;
                }

                $widgetEl.get(0).scrollIntoView();
            },

            cloneWidget({  widgetId, config }) {
                const [col, row] = this.nextPlaceholder();
                const payload = {
                    dashboard_id: this.id,
                    connection_id: this.cId,
                    widget_id: widgetId,
                    row: row,
                    col: col,
                    timescale_override: this.timescale_override,
                    scope_uuid: this.scopeUuid,
                };

                this.eventHub.$emit('dashboard:confirm_delete', -1, false);

                $.ajax('/dashboardv2/cloneWidget', {
                    method: 'GET',
                    data: payload,
                    context: this,
                }).done(function (data) {
                    if (!data.success) {
                        console.log(data.msg);
                        return;
                    }

                    this.eventHub.$emit(
                        'widget:add_widget',
                        config.vue_type,
                        data.new_id,
                        {
                            ...config,
                            title: data.new_title,
                            row,
                            col,
                        }
                    );

                    this.$nextTick(() => {
                        this.scrollWidgetIntoView(data.new_id);
                    });

                    this.eventHub.$emit('dashboard:add_placeholder');

                    Vue.nextTick(() => {
                        window.app.rootVue.$broadcast('dashboard:update_chosen_editing_widget', data.new_id, 'clone');
                    });
                });
            },

            widgetResized(event) {
                this.widgetsMoved(event);
                // Give widget .5sec to animate to new size
                setTimeout(() => {
                    this.$broadcast('widget:reconfig', Number(event.target.id));
                }, 500);
            },

            widgetMoving(event) {
                this.togglePlaceholderWidget(event);
            },

            widgetResizing(event) {
                this.togglePlaceholderWidget(event);
            },

            togglePlaceholderWidget(event) {
                const isPlaceholderWidget = (
                    event &&
                    event.target &&
                    event.target.id &&
                    event.target.id === "-1"
                );

                if (isPlaceholderWidget) {
                    return;
                }

                const $placeholder = $('.grid-stack #-1')[0];

                if ($placeholder) {
                    this.grid.removeWidget($placeholder);
                    this.widgets = this.widgets.filter(w => w.id !== -1);
                } else {
                    this.addPlaceholder();
                }
            },

            widgetsMoved(event) {
                for (let i = 0; i < this.widgets.length; i++) {
                    const w = this.widgets[i];
                    const c = w.config;
                    const gsw = $(`.grid-stack-item#${w.id}`).data('_gridstack_node');
                    if (!gsw) { // TODO This is a hack fix.
                        return 1;
                    }
                    if (!(c.row === gsw.y && c.col === gsw.x
                            && c.width === gsw.width && c.height === gsw.height)) {
                        this.updateLayout();
                        return;
                    }
                }
               this.togglePlaceholderWidget(event);
            },

            widgetDropped(event, nodes) {
                for (let i = 0; i < nodes.length; i++) {
                    const node = nodes[i];
                    if (!node._temporary || !node.el.length) { continue; }
                    const el = node.el.get(0);
                    const type = el.id;
                    this.grid.placeholder.hide();
                    this.grid.grid.removeNode(node);
                    this.widgets.push({
                        type: type,
                        id: -1 - this.newWidgetCount,
                        config: {
                            title: 'New Widget',
                            width: node.width,
                            height: node.height,
                            row: node.y,
                            col: node.x,
                            custom_controls: {},
                        },
                    });
                    this.newWidgetCount += 1;
                }
            },

            updateLayout(event) {
                for (let i = 0; i < this.widgets.length; i++) {
                    const w = this.widgets[i];
                    const c = w.config;
                    const gsw = $(`.grid-stack-item#${w.id}`).data('_gridstack_node');
                    if (!gsw) {
                        console.log("Error undefined _gridstack_node:" + w.id)
                        continue;
                    }
                    if (!(c.row === gsw.y && c.col === gsw.x
                            && c.width === gsw.width && c.height === gsw.height)) {
                        const payload = {
                            dashboard_id: this.id, widget_id: w.id,
                            width: gsw.width, height: gsw.height,
                            row: gsw.y, col: gsw.x,
                        };
                        $.ajax('/dashboardv2/resizeMoveWidget', {
                            method: 'GET',
                            data: payload,
                        });
                        c.row = gsw.y; c.col = gsw.x;
                        c.height = gsw.height; c.width = gsw.width;
                    }
                }
                const $placeholder = $('.grid-stack #-1')[0];

                if (!$placeholder) {
                    this.addPlaceholder();
                }
            },

            toggleEditDashboardMode() {
                this.editing = !this.editing;
                if (this.editing) {
                    this.grid.enableMove(true, true);
                    this.grid.enableResize(true, true);
                    // Add a zero-width spacer widget to stretch the grid vertically
                    const div = $('<div class="grid-stack-item spacer-widget"></div>');
                    const curHeight = Math.max(this.grid.grid.getGridHeight(), 5);
                    this.spacerWidget = this.grid.addWidget(div, 0, curHeight + 2, 0, 2, false);
                    this.grid._updateStyles();
                } else {
                    this.grid.enableMove(false, true);
                    this.grid.enableResize(false, true);
                    this.grid.removeWidget(this.spacerWidget);
                    this.grid.commit();
                }
            },

            toggleEditDashboard(dashboard_id) {
                window.app.rootVue.$broadcast('dashboard-properties-drawer:open', dashboard_id);
            },

            pendAllConfigs() {
                this.widgets.forEach(w => {
                    if (w.config && w.config.widget_type === 'html_block') {
                        return;
                    }
                    this.$broadcast('widget:pend_config', w.id);
                });
            },

            setScopingData(scopeType, strippedAttrs, tags) {
                this.scopeTypeData = scopeType;
                this.scopeAttrTypesData = strippedAttrs;
                this.scopeTagsData = tags;
            },

            setSessionScope() {
                const uuid = this.uuidv4();
                const timescale = this.timescale_override;
                let range = null;
                let scopeAttrTypesData = "";

                if (typeof this.scopeAttrTypesData === 'string' || 
                    this.scopeAttrTypesData instanceof String) {
                    scopeAttrTypesData = this.scopeAttrTypesData;
                } else {
                    scopeAttrTypesData = JSON.stringify(this.scopeAttrTypesData);
                }

                let data = {
                        dashboard_id: this.id,
                        connection_id: this.cId,
                        scope_type: this.scopeTypeData,
                        scope_attrs: scopeAttrTypesData,
                        scope_tags: JSON.stringify(this.scopeTagsData),
                        scope_uuid: uuid,
                    };

                if (timescale && timescale != 'none') {
                    data.timescale = timescale;
                }

                if (timescale === 'custom') {
                    if (!this.customDateRange) {
                        return;
                    }

                    data.range = this.customDateRange;
                }
                
                var url;
                if (this.public) {
                    url = '/dashv2/set_session_scope';
                } else {
                    url = '/dashboardv2/set_session_scope';
                }
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: data,
                    traditional: true,
                }).done(data => {
                    if (data.success) {
                        this.scopeUuid = uuid;
                        this.pendAllConfigs();
                        this.$refs.dashboard_scoping_component.setClean();
                    } else {
                        console.error(data);
                    }
                });
            },

            uuidv4() {
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
                    const r = Math.random() * 16 | 0;
                    const v = c === 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
            },

            /* Queue maintenance */
            requestRecreate(widgetId) {
                console.log(`Requesting recreate for Widget ${widgetId}`);
                const payload = {
                    message_type: 'request_recreate',
                    dashboard_id: this.id,
                    connection_id: this.cId,
                    acl_hash: this.aclHash,
                    widget_id: widgetId,
                };
                this.websocket.send(JSON.stringify(payload));
            },

            reportUpdateBeforeCreate(widgetId) {
                const message = `Widget ${this.id}:${widgetId} received update before create. Force recreate.`;
                console.log(message);

                if (this.websocket && this.websocket.readyState !== 1) {
                    return;
                }

                const payload = {
                    message_type: 'update_before_create',
                    dashboard_id: this.id,
                    connection_id: this.cId,
                    acl_hash: this.aclHash,
                    widget_id: widgetId,
                };
                this.websocket.send(JSON.stringify(payload));
            },

            reportStoppedUpdating(widgetId, metadata) {
                let message = `Widget ${this.id}:${widgetId} stopped updating.`;
                console.log(message);
                this.notupdating = true;
                const extra = metadata || {};
                if (metadata.next_update) {
                    const additionalInfo = `Expected an update at: ${metadata.next_update}`;
                    console.log(additionalInfo);
                    message = `${message}\n${additionalInfo}`;
                }

                if (this.websocket && this.websocket.readyState !== 1) {
                    return;
                }
                const payload = {
                    message_type: 'stopped_updating',
                    dashboard_id: this.id,
                    connection_id: this.cId,
                    acl_hash: this.aclHash,
                    widget_id: widgetId,
                };
                this.websocket.send(JSON.stringify(payload));
            },

            // -- Series counts --
            setSeriesCount(widgetId, count) {
                // Vue.set(`seriesCounts[${widgetId}]`, count);
                this.seriesCounts = {
                    ...this.seriesCounts,
                    [widgetId]: count
                };
            },

            initializeGridMask(width, height) {
                // This method will create the 2D representation of our gridstack grid

                // Create a grid with 500 rows and 500 columns
                this.gridMask = new Array(width);

                for (let i = 0; i < this.gridMask.length; i++) {
                  this.gridMask[i] = new Array(height);
                }
            },

            allocateWidget(x, y, width, height) {
                // For the current widget's dimensions - we need to set the correct grid indices to true
                for (let c = x; c < width; c++) {
                    for (let r = y; r < height; r++) {
                        // Set the correct grid indices to true
                        this.gridMask[c][r] = true;
                    }
                }
            },

            findAvailableSpace(width, height) {
                const widgetPlaceholderArea = this.defaultPlaceholderWidth * this.defaultPlaceholderHeight;

                // Loop through the grid to find a placeholder
                for (let y = 0; y < height; y++) {

                    // Loop through all x coordinates of the first y coordinate first (we want to move from left to right)
                    for (let x = 0; x < width; x++) {

                        if (!this.gridMask[x][y]) {
                            let areaCount = 0;

                            // Check the area from this empty space to see if a widget placeholder will
                            // fit using the widget width and height
                            for (let xx = x; xx < x + this.defaultPlaceholderWidth; xx++) {
                                for (let yy = y; yy < y + this.defaultPlaceholderHeight; yy++) {
                                    if (!this.gridMask[xx][yy]) {

                                        areaCount++;

                                        if (areaCount === widgetPlaceholderArea) {
                                            return [x, y]
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                return [0, 0];
            },

            nextPlaceholder() {
                // This function is supposed to find the coordinates on the graph
                // to the next space to place the placeholder given its dimension 5 x 4

                const MAX_GRID_MASK_WIDTH = 500;
                const MAX_GRID_MASK_HEIGHT = 500;

                let GRID_WIDTH_BOUNDARY = 20;
                let GRID_HEIGHT_BOUNDARY = 0;

                this.initializeGridMask(MAX_GRID_MASK_WIDTH, MAX_GRID_MASK_HEIGHT);

                // Loop through all the widgets to set up a 2D array of values describing
                // where a widget is placed on the grid
                for (let i in this.widgets) {
                    const w = this.widgets[i];

                    // Ignore widgets that are placeholder widgets (this may be unnecessary)
                    if (w.type === 'p-widget-placeholder') {
                        continue;
                    }

                    // Collect the bottom right corner of the current widget
                    const width = w.config.col + w.config.width,
                          height = w.config.row + w.config.height;

                    // Save the y coordinate for the bottom right corner of the furthest widget
                    if (height > GRID_HEIGHT_BOUNDARY) {
                        GRID_HEIGHT_BOUNDARY = height
                    }

                    this.allocateWidget(w.config.col, w.config.row, width, height);

                }

                return this.findAvailableSpace(GRID_WIDTH_BOUNDARY, GRID_HEIGHT_BOUNDARY + 1)
            },

            addPlaceholder() {
                let placeholder = this.nextPlaceholder();
                const widgetPlaceholderX = placeholder[0];
                const widgetPlaceholderY = placeholder[1];

                const widgetPlaceHolder = {
                    "title": "New Widget",
                    "height": this.defaultPlaceholderHeight,
                    "format_options": [],
                    "width": this.defaultPlaceholderWidth,
                    "col": widgetPlaceholderX,
                    "row": widgetPlaceholderY,
                };

                this.$nextTick(() => {
                    this.addWidget('p-widget-placeholder', -1, widgetPlaceHolder);
                });
            },

            hasFeature(feature) {
                return this.features.includes(feature);
            },

            handleWindowVisibilityChange() {
                const VISIBLE = "visible";
                const HIDDEN = "hidden";
                const CLOSE_REASON = `No window visibility for ${WINDOW_TIMEOUT_MINUTES} minute(s)`;

                if (this.windowVisibility === HIDDEN) {
                    this.windowVisibilityTimer = setTimeout(() => {
                        console.log(CLOSE_REASON);
                        this.websocket.close(
                            WEBSOCKET_STATUS_CODES[WEBSOCKET_STATUS_CODE_TYPE.WINDOW_VISIBILITY_TIMEOUT],
                            CLOSE_REASON
                        );
                    }, MINUTE * WINDOW_TIMEOUT_MINUTES);
                } else if (this.windowVisibility === VISIBLE) {
                    if (this.windowVisibilityTimer) {
                        clearTimeout(this.windowVisibilityTimer);
                        this.windowVisibilityTimer = null;
                    }

                    if (this.websocket && READY_STATE_VALUES[READY_STATE_TYPE.CLOSED] === this.websocket.readyState) {
                        let websocket_url = this.websocket_url;
                        if (!this.websocket_url.includes('reconnect')) {
                            websocket_url = `${websocket_url}&try=reconnect`;
                        }
                        this.connect(websocket_url);
                    }
                }
            },

            watchWindowVisibility() {
                Visibility.change((event, state) => {
                    this.windowVisibility = state;
                });
            },

            getGridStackHeightOffset() {
                const pageHeaderEl = document.querySelector('.pa-page-hd');
                const dashboardHeaderEl = document.querySelector('.dashboard-header');
                const subAccountInfoEl = document.querySelector('.sub-account-info');
                const pageHeaderElHeight = pageHeaderEl ? pageHeaderEl.offsetHeight : 0;
                const dashboardHeaderElHeight = dashboardHeaderEl ? dashboardHeaderEl.offsetHeight : 0;
                const subAccountInfoElHeight = subAccountInfoEl ? subAccountInfoEl.offsetHeight : 0;
                const verticalPadding = 40;
                return pageHeaderElHeight + dashboardHeaderElHeight + verticalPadding + subAccountInfoElHeight;
            },
        },

        watch: {
            windowVisibility() {
                this.handleWindowVisibilityChange();
            },

            timescale_override: function(val, oldVal) {
                this.setSessionScope();
            },
            customDateRange() {
                this.setSessionScope();
            },
            widgets: function(val, oldVal) {
                if (val.length === 1) {
                    $('.pa-page').addClass('empty-dashboard');
                } else {
                    $('.pa-page').removeClass('empty-dashboard');
                }
            },
            notupdating() {
                this.setGridStackWrapperStyle();
            },
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.debounceOnResize);
        },

        vueReady() {
            $ = window.$;

            window.addEventListener('resize', this.debounceOnResize);

            if (Visibility.isSupported()) {
                this.watchWindowVisibility();
            }

            document.querySelector('.pa-main').style.overflowY = "hidden";

            const options = {
                cellHeight: 80,
                width: 24,
                'float': true,
                animate: true,
                acceptWidgets: '.grid-stack-item',
                disableDrag: true,
                disableResize: true,
                disableOneColumnMode: true,
            };
            $('.grid-stack').gridstack(options);
            this.grid = $('.grid-stack').data('gridstack');
            this.eventHub.$emit('dashboard:ready');
            $('.grid-stack').on('resizestop', this.widgetResized);
            $('.grid-stack').on('resizestart', this.widgetResizing);
            $('.grid-stack').on('dragstart', this.widgetMoving);
            $('.grid-stack').on('dragstop', this.widgetsMoved);
            $('.grid-stack').on('added', this.widgetDropped);
            $('.pa-page').addClass('pa-page_dashboard');

            const calendarIcon = $(`
                <svg class="pa-icon pa-icon_lg" style="fill: #b9b9b9; margin-right: 10px;">
                    <use xlink:href="#calendar-blank"></use>
                </svg>`);
            $('#global-time-switch').siblings('.pa-select-hd')
                .prepend(calendarIcon);

            this.setGridStackWrapperStyle();

            $(document).ready(() => {
                $('#pending-server-count-badge').css({
                    "bottom": "20px",
                    "right": "-30px",
                    "transform": "rotate(0)",
                });

                window.setTimeout(() => {
                    const e = document.createEvent('Event');
                    e.initEvent('resize', true, true);
                    this.grid.opts.disableOneColumnMode = false;
                    window.dispatchEvent(e);
                });
            });

            if (this.allowEditing && this.newTour) {
                this.newTour = false;
                this.toggleEditDashboardMode();
                this.$nextTick(() => {
                    this.grid._updateStyles();
                });
            } else if (this.allowEditing && this.startEditing) {
                this.toggleEditDashboardMode();
                this.$nextTick(() => {
                    this.grid._updateStyles();
                });
            }

            // Add tooltip to our blue time overridden icons
            $(document).on('mouseenter', '.date-override-icon', function() {
                $(this).tooltip({
                    position: {
                        my: 'center bottom-20',
                        at: 'center top',
                        using: function(position, feedback) {
                            $(this).css(position);
                            $('<div>')
                              .addClass('tooltip-arrow')
                              .addClass(feedback.vertical)
                              .addClass(feedback.horizontal)
                              .appendTo(this);
                        },
                    },
                });
            });

            $(document).on('click', '.global-datetime-btn.selected', () => {
                event.preventDefault();
                event.stopPropagation();
                this.timescale_override = '';
            });

            $(document).on('click', '.widget-header .pa-menu', event => {
                $('.grid-stack-item').css('z-index', 0);
                // Bump the z-index of the widget so it's flyout doesn't get overlapped
                $(event.target).closest('.grid-stack-item')
                    .css('z-index', 1);
                // Reset all on next clicr
                $(document).one('click', event => {
                    $('.grid-stack-item').css('z-index', 0);
                });
            });

            $(document).on('mouseover', '.metric-column .pa-tooltip', event => {
                $('.grid-stack-item').css('z-index', 0);
                // Bump the z-index of the widget so it's tooltip doesn't get overlapped
                $(event.target).closest('.grid-stack-item')
                    .css('z-index', 1);
                // Once mouse leaves this widget, reset
                $(event.target).closest('.grid-stack-item')
                    .one('mouseleave', event => {
                        $('.grid-stack-item').css('z-index', 0);
                    });
            });

            this.$nextTick(() => {
                this.addPlaceholder();
            });
        },
    });

    export default Dashboard;
</script>
