<template>
    <div :class="className" :data-nav-id="id">
        <p-flex :align-start="!!badge" :align-center="!badge" class="p-width-full">
            <component
                :is="dynamicComponent"
                v-bind="dynamicComponentProps"
                @menu:toggled="handleMenuToggled"
                @item:active="handleItemActive"
            />      
            
        </p-flex>
        <div v-if="button" :id="button.id" class="pa-navigation-action-item__button pa-mx-8 pa-mt-10">
            <p-button @click="onClick" unstyled :title="button.title">
                <p-icon :icon="button.icon"></p-icon>
            </p-button>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";

import PNavigationActionMenu from "./NavigationActionMenu.vue";
import PNavigationActionAppLink from "./NavigationActionAppLink.vue";
import PNavigationActionLink from "./NavigationActionLink.vue";

import PNavigationActionTab from "./NavigationActionTab.vue";

const ACTIONS = {
    "link": {
        component: PNavigationActionLink,
        props: [
            "text",
            "leftIcons",
            "rightIcons",
            "href",
            "badge",
        ],
    },
    "app-link": {
        component: PNavigationActionAppLink,
        props: [
            "text",
            "leftIcons",
            "rightIcons",
            "to",
            "exactpath",
            "badge",
        ],
    },
    "nav-menu": {
        component: PNavigationActionMenu,
        props: [
            "text",
            "title",
            "leftIcons",
            "rightIcons",
            "items",
            "isCollapsed",
            "badge",
            "open",
            "trigger",
            "to",
            "exactPath",
            "hasActiveChild",
        ],
    },
    "tab": {
        component: PNavigationActionTab,
        props: [
            "id",
            "text",
            "leftIcons",
            "rightIcons",
            "badge",
        ],
    },
};

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    action: {
        type: String,
        validator(value) {
            return [
                "link",
                "app-link",
                "nav-menu",
                "tab",
            ].includes(value);
        },
    },
    text: String,
    title: String,
    href: String,
    to: String,
    leftIcons: Array,
    rightIcons: Array,
    items: Array,
    isCollapsed: Boolean,
    badge: Object,
    open: Boolean,
    exactPath: {
        type: Boolean,
        default: undefined
    },
    trigger: String,
    active: Boolean,
    hasActiveChild: Boolean,
    secondaryActive: Boolean,
    button: Object,
});

const emit = defineEmits(['item:active', 'menu:toggled']);

const dynamicComponent = computed(() => {
    return ACTIONS[props.action].component;
});

const dynamicComponentProps = computed(() => {
    return ACTIONS[props.action].props.reduce((a, propName) => ({
        ...a,
        [propName]: props[propName]
    }), {});
});

const className = computed(() => ([
    "pa-navigation-action-item",
    ...((props.active || props.secondaryActive) ? ["pa-navigation-action-item--active"] : []),
]));

const onClick = () => {
    window.app.rootVue.$broadcast("drawer:toggle", {id: props.button.target});
};

const handleMenuToggled = () => {
    emit("menu:toggled", { id: props.id });
};

const handleItemActive = (data) => {
    emit("item:active", data);
};
</script>
