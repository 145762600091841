const bitMultiplierMap = new Map([
    ["bytes", 1],
    ["kB", 8000],
    ["MB", 8000000],
    ["GB", 8000000000],
    ["TB", 8000000000000]
]);

export default function bandwidthCapacitySort(a, b, high) {    
    let [valueA, bitKeyA] = matchValueAndBit(a);
    let [valueB, bitKeyB] = matchValueAndBit(b);

    valueA = valueA || high;
    valueB = valueB || high;

    const convertedValueA = parseFloat(valueA * bitMultiplierMap.get(bitKeyA));
    const convertedValueB = parseFloat(valueB * bitMultiplierMap.get(bitKeyB));

	return (
        convertedValueA < convertedValueB
        ? -1
        : convertedValueA > convertedValueB
        ? 1
        : 0
    );
}

const matchValueAndBit = (val) => {
    const result = String(val).match(/(\d+.?\d+ \w+)/);
    

    if (result && result.length) {
        return result[0].split(" ");
    }

    return [null, "bytes"];
};
