<template>
    <div class="pa-tabbedPanels">
        <slot />
    </div>
</template>

<script>
import Vue from "vue";

const TAB_SELECTOR = ".pa-tab";
const TAB_PANEL_SELECTOR = ".pa-panels-item";

export default Vue.extend({
    provide() {
        return {
            tabs: this,
        };
    },
    props: {
        defaultIndex: {
            type: Number,
            default: 0,
        },
        selectedIndex: {
            type: Number,
            default: null,
        },
        lazy: {
            type: Boolean,
            default: false,
        },
        lazyOnce: {
            type: Boolean,
            default: true,
        },
    },
    data: function() {
        return {
            domTabs: [],
            domPanels: [],
            registeredTabs: [],
            registeredPanels: [],
            selectedTabIndex: this.selectedIndex,
        };
    },
    watch: {
        $route(to, from) {
            this.onRouteChange({ to, from });
        },
        selectedIndex(selectedIndex) {
            this.selectedTabIndex = selectedIndex;
        },
        selectedTabIndex(selectedTabIndex) {
            this.$emit("change", selectedTabIndex);
        },
        registeredTabs() {
            this.updateDomTabs();
        },
        registeredPanels() {
            this.updateDomPanels();
        },
    },
    computed: {
        actualSelectedIndex() {
            if (this.selectedTabIndex !== null) {
                return this.selectedTabIndex;
            }

            return this.defaultIndex;
        },
    },
    methods: {
        nodeListToArray(nodeList) {
            // Convert from NodeList to Array so we have access
            // to array function properties.
            // e.g. map(), filter(), findIndex(), etc.
            return [...nodeList];
        },
        selectorAll(selector) {
            return this.$el.querySelectorAll(selector);
        },
        getDomTabs() {
            return this.selectorAll(TAB_SELECTOR);
        },
        getDomPanels() {
            return this.selectorAll(TAB_PANEL_SELECTOR);
        },
        updateDomTabs() {
            const tabs = this.getDomTabs();
            this.domTabs = this.nodeListToArray(tabs);
        },
        updateDomPanels() {
            const panels = this.getDomPanels();
            this.domPanels = this.nodeListToArray(panels);
        },
        registerTab($tab) {
            if (!this.registeredTabs.includes($tab)) {
                this.registeredTabs.push($tab);
            }
        },
        unregisterTab($tab) {
            this.registeredTabs = [...this.registeredTabs].filter(
                (_$tab) => _$tab !== $tab
            );
        },
        registerPanel($panel) {
            if (!this.registeredPanels.includes($panel)) {
                this.registeredPanels.push($panel);
            }
        },
        unregisterPanel($panel) {
            this.registeredPanels = [...this.registeredPanels].filter(
                (_$panel) => _$panel !== $panel
            );
        },
        setSelectedIndex(index) {
            if (index < 0) {
                return;
            }

            const registeredTab = this.registeredTabs.find(
                (tab) => tab.$el === this.domTabs[index]
            );

            if (registeredTab.disabled) {
                return;
            }

            this.selectedTabIndex = index;
        },
        onHashChange() {
            this.registeredTabs.forEach((tab, index) => {
                if (tab.href && window.location.hash === tab.href) {
                    this.setSelectedIndex(index);
                }
            });
        },
        onRouteChange({ to }) {
            this.registeredTabs.forEach((tab, index) => {
                if (tab.to && to.fullPath.includes(tab.to)) {
                    this.setSelectedIndex(index);
                }
            });
        },
    },
    mounted() {
        window.addEventListener('hashchange', this.onHashChange);
    },
    beforeDestroy() {
        this.domTabs = [];
        this.domPanels = [];
        window.removeEventListener('hashchange', this.onHashChange);
    },
});
</script>
