<style lang="scss">
    .traceroute {
        position: relative;

        .g6-component-timebar {
            border-bottom: 1px solid #e6e6e6;
            canvas {
                margin-right: 12px;
            }
        }

        .g6-legend-container {
            padding-bottom: 8px;
            top: 20px !important;
        }

        .g6-component-toolbar {
            border: none;
            left: auto;
            right: 10px;
            top: 15px !important;
        }

        .g6-component-toolbar li {
            border: 1px solid #B3B3B3;
            height: 32px;
            width: 32px;
            margin-left: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #3954BF;
            fill: #3954BF;

            &:first-of-type {
                margin-left: 0;
            }

            &.zoomPercent {
                font-weight: bold;
                width: 40px;
            }
        }

        
        .traceroute-container {
            background-color: white;
        }
        
        .g6-tooltip {
            border: none;
            border-radius: 0px;
            font-family: Inter, sans-serif;
            color: #394449;
            background-color: #FFFFFF;
            box-shadow: 0 2px 4px 0 #B3B3B3;
            .pa-hr {
                height: 1px;
                border: none;
                background-color: #e6e6e6;
                color: #e6e6e6;
                width: 100%;
            }
        }

        .unidentify-text {
            height: 100%;
            position: relative;
            display: inline-block;
            z-index: 10;
            padding-left: 17px;
            padding-top: 22px;
        }

        @media only screen and (max-width: 1275px) {
            .g6-component-toolbar {
                top: 35px;
            }
        }
        
    }
</style>

<template>
    <div ref="$el" class="traceroute" @mouseleave="mouseLeaveHandler()" @click="tooltipReset()">
        <div v-if="legend && hasData" style="height: 50px;">
            <span class="unidentify-text">?
            </span>
        </div>
        <p-text v-if="!hasData" class="pa-txt_centered" style="margin-top: 200px;">
            No data to display.
        </p-text>
        <div
            ref="$container"
            v-else
            class="traceroute-container"
        >
        </div>
        <p-traceroute-tooltip
            v-if="tooltip.visible"
            :node="tooltip.data"
            :x="tooltip.x"
            :y="tooltip.y"
            :tooltip-arrow="tooltipArrow"
            :type="tooltip.type"
            :graph-type="props.graphType"
            @reset-tooltip="mouseLeaveTooltip()"
            ref="tooltipComp"
            :traceroute-data="tracerouteData"
            :show-traceroutes-historical-data="props.showTraceroutesHistoricalData"
        >
        </p-traceroute-tooltip>
        
    </div>
</template>

<script setup>
import {
    ref,
    computed,
    onMounted,
    watch,
    reactive,
    onBeforeUnmount,
} from "vue";
import G6 from "@antv/g6";
import _ from "lodash";

import PTracerouteTooltip from "../components/TracerouteTooltip.vue";

// Utilities
import { Node, Edge } from "../utils/traceroute";
import { LEGEND_DATA, LEGEND_TYPES } from "../constants/traceroute";
import {
    ZOOM_IN_ICON,
    ZOOM_OUT_ICON,
    FIT_VIEW_ICON
} from "../constants/g6";

const $el = ref(null);
const tooltipComp = ref()
const $container = ref(null);
const graph = ref(null);
const graphStatus = ref("idle");
const selectedStartValue = ref(null);
const selectedEndValue = ref(null);
const tooltip = reactive({
    data: {},
    visible: false,
    x: 0,
    y: 0,
});


const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    tracerouteData: {
        type: Object,
        default: () => {},
    },
    timebarData: {
        type: Array,
        default: () => [],
    },
    height: {
        type: Number,
        default: 400,
    },
    timebar: {
        type: Boolean,
        default: false,
    },
    activateRelations: {
        type: Boolean,
        default: true,
    },
    zoomCanvas: {
        type: Boolean,
        default: false,
    },
    dragCanvas: {
        type: Boolean,
        default: true,
    },
    tooltip: {
        type: Boolean,
        default: true,
    },
    tooltipArrow: {
        type: Boolean,
        default: false,
    },
    tooltipDirection: {
        type: String,
        default: "bottom",
    },
    tooltipFormatter: {
        type: Function,
        default: (date) => date,
    },
    startTick: {
        type: Number,
        default: 0,
    },
    endTick: {
        type: Number,
        default: 0,
    },
    legend: {
        type: Boolean,
        default: true,
    },
    graphType: {
        type: String,
        default: "incident"
    },
    width: {
        type: Number,
        default: null,
    },
    optimizeZoom: {
        type: Number,
        default: undefined,
    },
    toolbar: {
        type: Boolean,
        default: false,
    },
    stickyTooltip: {
        type: Boolean,
        default: false,
    },
    showTraceroutesHistoricalData: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['value-change']);
const zoomRatio = ref();
const zoomPercentInputEl = ref();

const hasData = computed(() => {
    return (
        !_.isEmpty(props.data) &&
        !_.isEmpty(props.data.nodes)
    );
});

const zoomPercent = computed(() => {
    return Math.floor(zoomRatio.value * 100);
});

const mouseLeaveHandler = () => {
    if(props.stickyTooltip && tooltipComp.value) {
        setTimeout(function(){
            if(tooltipComp.value && !tooltipComp.value.isMouseOver) {
                tooltipReset();
            }
        }, 5);
    }
}

const mouseLeaveTooltip = () => {
    if(props.stickyTooltip) {
        tooltipReset();
    }
}

const computedModes = computed(() => {
    let modes = [];

    if (props.dragCanvas) {
        modes.push({
            type: "drag-canvas",
        });
        // This doesn't work when paired with zooming on scroll
        // since we use the onWheel event for the double finger drag
        if (!props.zoomCanvas) {
            modes.push({
                type: 'double-finger-drag-canvas'
            });
        }
    }

    if (props.activateRelations) {
        modes.push({
            type: "activate-relations",
        });
    }

    if (props.zoomCanvas) {
        modes.push({
            type: "zoom-canvas",
            enableOptimize: props.optimizeZoom !== undefined,
            optimizeZoom: props.optimizeZoom,
        });
    }

    return modes;
});

const computedPlugins = computed(() => {
    const plugins = [];
    
    if (props.timebar) {
        plugins.push(createTimebar());
    }

    if (props.legend) {
        plugins.push(createLegend());
    }

    if (props.toolbar) {
        plugins.push(createToolbar());
    }


    return plugins;
});

const computedTimebarOptions = computed(() => {
    return {
        x: 0,
        y: 0,
        width: $el.value ? $el.value.scrollWidth : undefined,
        height: 48,
        padding: 3,
        type: 'trend',
        filterItemTypes: [],
        trend: {
            data: props.timebarData,
            smooth: false,
            lineStyle: {
                stroke: '#3954BF'
            },
        },
        tick: {
            tickLabelStyle: {
                fill: 'black',
                fontWeight: 'normal',
                y: 33,
            },
            tickLineStyle: {
                opacity: 0,
            },
            tickLabelFormatter: (d) => d ? d.label : "",
            tooltipFomatter: props.tooltipFormatter,
        },
        slider: {
            start: props.startTick,
            end: props.endTick,
            backgroundStyle: {
                fill: '#FFFFFF',
            },
            handlerStyle: {
                width: 1,
                stroke: '#3954BF',
                fill: '#3954BF',
            },
        },
        controllerCfg: {
            height: 0,
            width: 0,
            defaultTimeType: 'single',
            hideTimeTypeController: true,
        },
    };
});

const computedLegendOptions = computed(() => {
    return {
        data: LEGEND_DATA,
        align: 'center',
        layout: 'horizontal',
        position: 'top-left',
        padding: [12, 10, -18, 4],
        offsetY: 30,
        containerStyle: {
            fill: '#FFFFFF',
            lineWidth:  0,
        },
        filter: {
            enable: true,
            multiple: true,
            trigger: 'click',
            graphActiveState: 'activeByLegend',
            graphInactiveState: 'inactiveByLegend',
            legendStateStyles: {
                // The empty object clears out styles
                active: {},
            },
            filterFunctions: {
                [LEGEND_TYPES.SOURCES]: (data) => {
                    return data.is_source;
                },
                [LEGEND_TYPES.IDENTIFIED]: (data) => {
                    return data.host || data.ip;
                },
                [LEGEND_TYPES.UNIDENTIFIED]: (data) => {
                    return !data.host && !data.ip;
                },
                [LEGEND_TYPES.INCIDENTS]: (data) => {
                    return data.has_incident;
                },
                [LEGEND_TYPES.TARGETS]: (data) => {
                    return data.is_target;
                },
                [LEGEND_TYPES.LOSS]: (data) => {
                    return data.loss > 0;
                },
            }
        },
    };
});

const computedToolbarOptions = computed(() => {
    return {
        getContent: () => {
            return `
                <ul>
                    <li code='zoomOut' title='Zoom out'>${ZOOM_OUT_ICON}</li>
                    <li code='zoomIn' title='Zoom in'>${ZOOM_IN_ICON}</li>
                    <li code='autoZoom' title='Fit view'>${FIT_VIEW_ICON}</li>
                </ul>
            `;
        },
        handleClick: (code, graph) => {
            let toolbar = null;

            graph.cfg.plugins.forEach((plugin) => {
                if (plugin instanceof G6.ToolBar) {
                    toolbar = plugin;
                }
            });

            if (!toolbar) {
                return;
            }

            if (code === 'autoZoom') {
                graph.fitView();
            } else {
                toolbar.handleDefaultOperator(code);
            }

            if (["zoomIn", "zoomOut", "autoZoom"].includes(code)) {
                getZoomRatio();
            }
        },
    };
});

const updateData = (newData) => {
    if (!graph.value) {
        return;
    }

    graph.value.read(newData);
};

const onZoomPercentInputBlur = (e) => {
    let value = e.target.value;

    if (value > 500) {
        value = 500;
    } else if (value < 0) {
        value = 100;
    }

    e.target.value = value;

    graph.zoomTo(value / 100);
    graph.fitCenter();
};


const updateZoomPercentInputValue = (value) => {
    if (zoomPercentInputEl.value) {
        zoomPercentInputEl.value = value;
    }
};

const getZoom = () => {
    return graph.value.getZoom();
};

const getZoomRatio = () => {
    if (!graph) {
        return;
    }

    zoomRatio.value = getZoom();
};

const onWheelZoom = () => {
    getZoomRatio();
};

const hasFilteredState = ref(false)
const setHasFilteredState = (val) => {
    hasFilteredState.value = val;
};

watch(() => props.data, (newData) => {
    updateData(newData);
});

const onValueChange = ({ value: [start, end] }) => {
    selectedStartValue.value = start;
    selectedEndValue.value = end;
};

watch([selectedStartValue, selectedEndValue], (currValue) => {
    emit('value-change', currValue);
});

watch(zoomPercent, (currValue) => {
    updateZoomPercentInputValue(currValue);
});

const onNodeMouseLeave = (e) => {
    if(props.stickyTooltip === false) {
        tooltipReset();
    }
    hoveredNode.value = null;
    setTimeout(function(){
        if(hoveredNode.value === null) {
            customStateReset()
        }
    }, 200);
    
};

const customStateReset = () => {
    //reset all custom states
    if(props.graphType == 'continuous') {
        const nodes = graph.value.getNodes();
        const edges = graph.value.getEdges();
        for(const node of nodes) {
            graph.value.clearItemStates(node, ['active', 'inactive'])
        }
        for(const edge of edges) {
            graph.value.clearItemStates(edge, ['active', 'inactive'])
        }
    }
}

const dynamicHeight = computed(() => {
    let sourceNodeCount = 0;
    let graphHeight = 0;
    let width = $el.value.scrollWidth;
    let height = props.height;

    if(props.data.nodes) {
        for(const node of props.data.nodes) {
            if(node.is_source) {
                sourceNodeCount += 1;
            }
        }

        if(width > 2500) {
            graphHeight = sourceNodeCount * 230;
        } else if (width > 2250) {
            graphHeight = sourceNodeCount * 195;
        } else if (width > 2000) {
            graphHeight = sourceNodeCount * 175;
        } else if (width > 1750) {
            graphHeight = sourceNodeCount * 160;
        } else if (width > 1500) {
            graphHeight = sourceNodeCount * 145;
        } else if (width > 1250) {
            graphHeight = sourceNodeCount * 120;
        } else if (width > 1000) {
            graphHeight = sourceNodeCount * 105;
        } else {
            graphHeight = sourceNodeCount * 75;
        }

    }

    if(graphHeight < height || height > width) {
        return height;
    }

    return graphHeight;
});

const hoveredNode = ref();

const onNodeMouseEnter = (e) => {
    tooltipReset();
    
    const model = e.item.getModel();
    const { x, y } = model;
    const point = graph.value.getClientByPoint(x, y);

    hoveredNode.value = e;

    setTimeout(function(){
        if(hoveredNode.value && hoveredNode.value == e) {
            setNodeStatesOnHover(hoveredNode.value);
            hoveredNode.value = null;
        }
    }, 200);

    tooltip.data = {...model};
    tooltip.visible = true;
    tooltip.type = "node"
    tooltip.x = point.x - 75;
    if(props.tooltipDirection == "bottom") {
        tooltip.y = point.y + 20;
    } else {
        if(props.graphType == 'continuous') {
            tooltip.y = point.y - 210;
        } else {
            tooltip.y = point.y - 300;
        }
    }
};

const setNodeStatesOnHover = (e) => {
    if(props.graphType == 'continuous') {
        // change default behavior.  We'll be highlighting
        // the entire pagth in front of the node and dimming everything
        // behind the selected node
        let prevNodes = e.item.getNeighbors('source');
        if(prevNodes.length) {
            for(const prevNode of prevNodes) {
                graph.value.setItemState(prevNode, 'inactive', true);
                graph.value.setItemState(prevNode, 'active', false);
            }
        }

        let edges = e.item.getEdges('source');

        if(edges.length) {
            for(const edge of edges) {
                if(edge.getTarget().getModel().id == e.item.getModel().id) {
                    graph.value.setItemState(edge, 'inactive', true);
                    graph.value.setItemState(edge, 'active', false);
                }
            }
        }

        setPathStateActive(e.item);
    }
}

const onEdgeMouseEnter = (e) => {
    tooltipReset();
    const model = e.item.getModel();
    const clientX = e.clientX;
    const clientY = e.clientY;

    tooltip.data = {...model};
    tooltip.type = "edge"
    tooltip.visible = true;
    tooltip.x = clientX - 75;
    tooltip.y = clientY - 120;
};

const onEdgeMouseLeave = () => {
    if(props.stickyTooltip === false) {
        tooltipReset();
    }
};

const setPathStateActive = (node, prevNode) => {
    // recurse thru all the forward paths and set them to active
    graph.value.setItemState(node, 'active', true);
    let edges = node.getEdges();
    if(edges.length) {
        for(const edge of edges) {
            if(edge.getSource().getModel().id == node.getModel().id) {
                graph.value.setItemState(edge, 'active', true);
            }
        }
    }

    let nextNodes = node.getNeighbors('target');
    if(nextNodes.length) {
        for(const nextNode of nextNodes) {
            if(prevNode && prevNode.getModel().id == nextNode.getModel().id) {
                continue;
            }
            if(nextNode.getModel().id != node.getModel().id) {
                setPathStateActive(nextNode, node);
            }
        }
    }

}

const tooltipReset = () => {
    tooltip.data = {};
    tooltip.visible = false;
    tooltip.x = 0;
    tooltip.y = 0;
};

const createTimebar = () => {
    const timebar = new G6.TimeBar(computedTimebarOptions.value);

    return timebar;
};

const createLegend = () => {
    const legend = new G6.Legend(computedLegendOptions.value);
    return legend;
};

const createToolbar = () => {
    const toolbar = new G6.ToolBar(computedToolbarOptions.value);

    return toolbar;
};

const registerCustomElements = () => {
    G6.registerNode("custom-node", customCircleNode(), 'circle');
    G6.registerEdge("custom-edge", customLineEdge(), 'polyline');
};

const customLineEdge = () => {
    return {
        setState: function setState(eventName, value, item) {
            const group = item.getContainer();
            const children = group.get('children');
            const active = item.hasState('active');
            const inactive = item.hasState('inactive');

            let shouldFadeOutNode = (
                inactive
            );

            if (active) {
                shouldFadeOutNode = false;
            }

            if (shouldFadeOutNode) {
                if(!hasFilteredState.value) {
                    children.forEach((shape) => {
                        shape.attr('opacity', 0.18);
                    });
                }
            } else {
                children.forEach((shape) => {
                    if(!hasFilteredState.value) {
                        shape.attr('opacity', 1);
                    }

                    if (
                        typeof shape === G6.Shape.Edge &&
                        shape.attrs.stroke !== '#D43527'
                    ) {
                        if (active) {
                            shape.attr('stroke', '#666666');
                        } else {
                            shape.attr('stroke', '#B8B8B8');
                        }
                    }
                });
            }
        },
    };
};

const customCircleNode = () => {
    return {
        setState: function setState(eventName, value, item) {
            
            const group = item.getContainer();
            const children = group.get('children');
            const active = item.hasState('active');
            const inactive = item.hasState('inactive');
            const inactiveByLegend = item.hasState('inactiveByLegend');
            const activeByLegend = item.hasState('activeByLegend');
            const bgColor = item.getModel().bgColor;

            let shouldFadeOutNode = (
                inactive ||
                inactiveByLegend
            );

            if (active || activeByLegend) {
                shouldFadeOutNode = false;
            }

            if(!hasFilteredState.value) {
                children.forEach((shape) => {
                    shape.attr('opacity', 0.18);
                });
                if (shouldFadeOutNode) {
                    children.forEach((shape) => {
                        shape.attr('opacity', 0.18);
                    });
                } else {
                    children.forEach((shape) => {
                        shape.attr('opacity', 1);
                    });
                }
            }
        },
        drawShape: function draw(cfg, group) {
            const node = new Node(cfg);
            const style = this.getShapeStyle(cfg);

            const keyShape = group.addShape("circle", {
                attrs: {
                    ...style,
                    ...node.style,
                },
                name: "custom-node",
            });
            
            if (node.hasIcon) {
                group.addShape("image", {
                    attrs: node.iconCfg,
                });
            } else {
                group.addShape("text", {
                    attrs: node.textIconCfg,
                    name: "text-icon",
                });
            }

            return keyShape;
        },
    };
};

const handleAfterLayout = () => {
    graphStatus.value = "ready";
};

const configureEdge = (edge) => {
    const { style, labelCfg } = new Edge(edge);

    edge.style = style;
    edge.labelCfg = labelCfg;

    return edge;
};

const configureNode = (node) => {
    const { style, label, labelCfg, size, radius } = new Node(node);

    node.style = style;
    node.label = label;
    node.labelCfg = labelCfg;
    node.size = size;
    node.radius = radius;
    return node;
};

const getLargestTargetNodeXVal = (graph) => {
    let largestX = 0;
    for (const node of props.data.nodes) {
        if(node.is_target === true) {
            const item = graph.findById(node.id);
            let model = item.getModel()
            if(model.x > largestX) {
                largestX = model.x
            }
        }
    }
    return largestX
}

const initGraph = () => {

    const _graph = new G6.Graph({
        container: $container.value,
        width: props.width != null ? props.width : $el.value.scrollWidth,
        height: props.legend ? dynamicHeight.value - 25 : props.height,
        fitView: true,
        plugins: computedPlugins.value,
        maxZoom: 5,
        modes: {
            default: computedModes.value,
        },
        layout: {
            type: 'dagre',
            rankdir: 'LR',
            align: 'UL',
            controlPoints: true,
            ranksep: 20,
            nodesep: 20,
        },
        defaultNode: {
            type: 'custom-node',
        },
        defaultEdge: {
            type: 'custom-edge',
        },
    });

    _graph.data(props.data);

    _graph.on("afterlayout", handleAfterLayout);
    _graph.on("node:mouseenter", onNodeMouseEnter);
    _graph.on("node:mouseleave", onNodeMouseLeave);
    if(props.graphType == 'continuous') {
        _graph.on("edge:mouseenter", onEdgeMouseEnter);
        _graph.on("edge:mouseleave", onEdgeMouseLeave);
    }
    _graph.on("valuechange", onValueChange);
    _graph.on("wheelzoom", onWheelZoom);

    _graph.node(configureNode);
    _graph.edge(configureEdge);

    _graph.render();

    _graph.on('afterlayout', evt => {
        if(props.graphType == 'continuous') {
            // Make sure all the source and target nodes line up
            let yList = []
            const targetNodeXVal = getLargestTargetNodeXVal(_graph)
            for (const node of props.data.nodes) {
                if (node.is_source === true || node.is_target === true) {
                    const item = _graph.findById(node.id);
                    let model = item.getModel()

                    if(node.is_source === true) {
                        // On rare occasion 2 nodes could be in the same level
                        // and moving them will cause an overlap.  We'll need to also
                        // make sure all the nodes are vertically spaced.
                        for(const y of yList) {
                            if(model.y > y - 50 && model.y < y + 50) {
                                model.y = y + 115;
                            }
                        }
                        yList.push(model.y)
                        model.x = 50;
                    } else {
                        model.x = targetNodeXVal;
                    }
                    _graph.updateItem(item, model)
                }
            }

            _graph.refreshPositions()
        }

        if (props.timebar) {
            const timebarEl = $el.value.querySelector('.g6-component-timebar');
            $el.value.prepend(timebarEl);
        }

    });

    graph.value = _graph;

    zoomPercentInputEl.value = document.getElementById("zoom-percent-input");

    if (zoomPercentInputEl.value) {
        zoomPercentInputEl.value.addEventListener("blur", onZoomPercentInputBlur);
    }
};

onBeforeUnmount(() => {
    if (graph.value) {
        graph.value.destroy();
    }
});

onMounted(() => {
    registerCustomElements();
    if(hasData.value == true) {
        initGraph();
    }
    
});

defineExpose({setHasFilteredState});
</script>
