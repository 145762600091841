<style lang="scss">
    /* Config Drawer */

    .config-drawer .pa-panels-item-bd {
        padding: 0 0 0 0;
    }

    .config-drawer .pa-tabbedPanels {
        box-shadow: none;
    }

    .pa-drawer-close {
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
        position: absolute;
        top: 23px;
        right: 27px;
    }

    .config-drawer {
        position: fixed;
        right: -600px;
        top: 0px;
        background: #ffffff;
        width: 600px;
        height: 100%;
        overflow-y: auto;
        z-index: 900;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
        -webkit-transition: all 0.9s ease;
        -moz-transition: all 0.9s ease;
        -o-transition: all 0.9s ease;
        transition: all 0.9s ease;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        &.open {
            right: 0px !important;
        }
        &.no-scroll {
            overflow-y: hidden;

            #drawer-close {
                right: 25px;
            }
        }

        .module-border {
            border-bottom: 1px solid #d5d8df;
        }

        .config-drawer-header {
            position: relative;
            box-shadow: 0 2px 0 0 rgba(132, 142, 153, 0.2);
            position: relative;
            z-index: 1;

            /* Config drawer header buttons */
            #drawer-close {
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }

        .pa-drawer-ft {
            padding-right: 15px;
            padding-bottom: 18px;
            padding-top: 18px;
            border-top: solid 1px #e6e6e6;
        }
    }

    /* Modules */
    .module {
        position: relative;
        background-color: #ffffff;

        .pa-hr {
            color: #d5d8df;
            background-color: #d5d8df;
            width: 555px;
            margin: 14px auto 0 0;
        }

        .module-header {
            width: 100%;
            display: block;
            margin: 0;
            padding: 15px 35px 5px 15px;
            position: relative;
            box-sizing: border-box;
            color: rgba(0,0,0,0.86);
            font-size: 16px;
            text-align: left;
            line-height: normal;
            outline: none;

            span {
                font-size: 16px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #22262d;
            }
        }

        .module-body {
            padding: 15px;
            -webkit-transition: max-height 0.2s ease;
            -moz-transition: max-height 0.2s ease;
            -o-transition: max-height 0.2s ease;
            transition: max-height 0.2s ease;

            &.collapsed {
                /*max-height: 0px;*/
                height: 0px;
                padding-bottom: 0px;
                padding-top: 0px;
            }

            hr {
                border-top: solid 0.5px #dddddd;
                border-bottom: none;
                border-left: none;
                border-right: none;
            }

            /* Overrides for elements that appear in modules */
            .pa-field-hd {
                display: flex;
                justify-content: flex-start;
                color: rgba(32, 32, 32, 0.6);
            }
            .pa-field input {
                color: rgba(32, 32, 32, 0.6);
            }
            .pa-field-bd .pa-select {
                width: 80%
            }
            .pa-select-hd, .module .pa-select-list-item {
                font-size: 12px;
            }
            .pa-select-list {
                max-height: 50vh;
            }
            .pa-select-list .scrollable {
                max-height: 50vh;
            }
            .pa-select button:disabled {
                color: #dcdcdc;
            }

            .inspire-tree {
                max-height: 250px;
            }
        }

        .module-footer {
            padding: 10px;

            &.right {
                text-align: right;
            }
        }
    }
    .config-drawer.no-scroll .module {
        margin-right: 35px;
    }
    
    .module-header-configured{
        font-size: 13px;
        
        padding-left: 5px;
    }
    

    /* Editing widget highlight */
    .widgetHighlight .grid-stack-item-content {
        border: solid 1px #2c4163;
    }
    
    .config-drawer.open .config-drawer-close {
        width: 40px;
        height: 41px;
        border: solid 1px #e6e6e6;
        border-bottom: 0;
        background-color: #fafafa;
        display: block;
        cursor: pointer;
    }
    
    .config-drawer-close {
        display: none;
    }
    
    .pa-tabs {
        border-bottom: solid 1px #e6e6e6;
    }
    
    .config-drawer-icon-wrapper {
        border: 1px solid #3954bf;
        border-radius: 2px;
    }

    .config-drawer-body {
        overflow-y: auto;
    }
    
    .dashboard-drawer-content-wrapper {
        position: relative;
        height: 100%;

        .pa-drawer-overlay {
            padding-top: 85%;
        }
    }

    #widget_select_modal {
        display: flex;
        justify-content: center;
    }
</style>

<template>
    <div
        ref="drawer"
        class="config-drawer"
        :class="{'no-scroll': noScroll}"
        v-on:scroll="scrollFunction"
    >
        <div class="dashboard-drawer-content-wrapper">
            <div class="pa-drawer-overlay" v-if="updateInProgress.status">
                <div class="pa-loader"></div>
            </div>
            <div class="config-drawer-header pa-p-24">
                <p-row align-center no-wrap>
                    <div class="config-drawer-icon-wrapper pa-p-4 pa-mr-12">
                        <p-icon
                            :icon="paneIcon"
                            size="xxxl"
                            color="blue-500"
                        >
                        </p-icon>
                    </div>
                    <span class="pa-txt pa-txt_19">{{ paneTitle }}</span>
                    <button @click="close" type="button" class="pa-drawer-close">
                        <p-icon icon="x" size="xxxxl" color="blue-500">
                        </p-icon>
                    </button>
                </p-row>
            </div>
            <div
                class="config-drawer-body"
                :style="{
                    'height': `calc(100% - ${bodyHeight}px)`
                }"
            >
                <component
                    :editing-widget="editingWidget"
                    :is="type.type"
                    :options="type.options"
                    :class="{'open': open}"
                    v-for="(type, index) in configModules"
                    :key="`${type.type}-${index}`"
                    ref="widgets"
                    :features="features"
                    @title:update="onTitleUpdate"
                >
                </component>
                <component
                    :editing-widget="editingWidget"
                    :is="type.type"
                    :options="type.options"
                    :class="{'open': open}"
                    v-for="(type, index) in optionModules"
                    :key="`${type.type}-${index}`"
                    ref="widgets"
                    :hidden-options="hiddenOptions"
                >
                </component>
            </div>
        </div>

        <p-modal
            v-if="widgetSelectModalOpen && !destroy_widget"
            id="widget_select_modal"
            :on-close="onWidgetSelectClose"
        >
            <p-widget-select
                :editing-widget="editingWidget"
                :features="features"
                @widgetselect:destroy="destroyWidgetModal"
            >
            </p-widget-select>
        </p-modal>

    </div>
</template>

<script>
    import Vue from 'vue';
    import CustomModule from './modules/CustomModule.vue';
    import FormattingModule from './modules/FormattingModule.vue';
    import InstanceModule from './modules/InstanceModule.vue';
    import MetricModule from './modules/MetricModule.vue';
    import TimeRangeModule from './modules/TimeRangeModule.vue';
    import BandwidthUnitModule from './modules/BandwidthUnitModule.vue';
    import DataPointsModule from './modules/DataPointsModule.vue';
    import RollupModule from './modules/RollupModule.vue';
    import OnCallModule from './modules/OnCallModule.vue';
    import UsersModule from './modules/UsersModule.vue';
    import ColumnGroupsModule from './modules/ColumnGroupsModule.vue';
    import OverviewModule from './modules/OverviewModule.vue';
    import HTMLModule from './modules/HTMLModule.vue';
    import SortingModule from './modules/SortingModule.vue';
    import InstanceMetricModule from './modules/InstanceMetricModule.vue';
    import NetworkInterfaceModule from './modules/NetworkInterfaceModule.vue';
    import ResourceTypeModule from './modules/ResourceTypeModule.vue';
    import IgnoreScopeModule from './modules/IgnoreScopeModule.vue';
    import IncidentModule from './modules/IncidentModule.vue';
    import FabricModule from './modules/FabricModule.vue';
    // import AdvancedOptionsModule from './modules/AdvancedOptionsModule.vue';
    import widgetModalOptions from "../shared/constants/widgetModalOptions.json";
    import WidgetSelect from './modules/WidgetSelect.vue';

    const DEFAULT_WIDGET_TITLE = "Widget Details";

    const ConfigDrawer = Vue.extend({
        components: {
            'p-custom-module': CustomModule,
            'p-instance-module': InstanceModule,
            'p-formatting-module': FormattingModule,
            'p-metric-module': MetricModule,
            'p-timerange-module': TimeRangeModule,
            'p-bandwidth-unit-module': BandwidthUnitModule,
            'p-datapoints-module': DataPointsModule,
            'p-rollup-module': RollupModule,
            'p-oncall-module': OnCallModule,
            'p-users-module': UsersModule,
            'p-columngroups-module': ColumnGroupsModule,
            'p-overview-module': OverviewModule,
            'p-html-module': HTMLModule,
            'p-sorting-module': SortingModule,
            'p-instance-metric-module': InstanceMetricModule,
            // 'p-advanced-options-module': AdvancedOptionsModule,
            'p-network-interface-module': NetworkInterfaceModule,
            'p-resource-type-module': ResourceTypeModule,
            'p-ignore-scope-module': IgnoreScopeModule,
            'p-incident-module': IncidentModule,
            'p-fabric-module': FabricModule,
            'p-widget-select': WidgetSelect,
        },

        watch: {
        },

        events: {
            'dashboard:open_config': function(widget, status=null) {
                const matchedWidget = this.$parent.$refs.widgets.find(
                    (ref) => ref && widget && (ref.id === widget.id)
                );
                this.editingWidget = matchedWidget;

                this.status = status;

                Vue.nextTick(function() {
                    this.open();
                }.bind(this))

            },
            'dashboard:close_config': function() {
                if (this.isOpen) {
                    this.close();
                }
            },
            'dashboard:open_widget_select': function(widgetFake) {
                const matchedWidget = this.$parent.$refs.widgets.find(
                    (ref) => ref && widgetFake && (ref.id === widgetFake.id)
                );
                this.editingWidget = matchedWidget;

                // open widget modal when making initial widget choice
                this.destroy_widget = false;
                this.widgetSelectModalOpen = true;
                Vue.nextTick(function() {
                    this.$broadcast('dialog:open', 'widget_select_modal');
                }.bind(this))
            },
            'config-drawer:update_editing_widget': function() {
                // open actual config drawer after widget choice is made,
                // proceed to choose name & other configs for widget as normal

                Vue.nextTick(function() {
                    this.open();
                }.bind(this))
            },
            'config-drawer:set_no_scroll': function(noScroll) {
                this.noScroll = noScroll;
            },
        },

        data() {
            return {
                isOpen: false,
                configModules: [],
                optionModules: [],
                editingWidget: null,
                highlight: "",
                widgetHighlight: "widgetHighlight",
                isValid: false,
                noScroll: false,
                validators: [],
                keyListener: null,
                lastWidgetId: null,
                status: "",
                updateInProgress: {
                    'status': false,
                    'widgetId': null,
                },
                paneTitle: DEFAULT_WIDGET_TITLE,
                destroy_widget: false,
                widgetSelectModalOpen: false,
            }
        },

        props: {
            features: {
                type: Array,
                'default': () => [],
            },
        },

        computed: {
            bodyHeight: function() {
                const headerHeight = 80;

                return headerHeight;

            },
            paneIcon: function() {
                const defaultIcon = "widget-details";

                if (!this.editingWidget) {
                    return defaultIcon;
                }

                const widget_options = [];

                widgetModalOptions.base.forEach(option => widget_options.push(...option.widgets));

                const matchedWidgetOption = widget_options.find(
                    option => option.vuetype === this.editingWidget.$options._componentTag
                );

                if (matchedWidgetOption && matchedWidgetOption.icon) {
                    return matchedWidgetOption.icon;
                }

                return defaultIcon;
            },
            hiddenOptions: function() {
                if (
                    !this.editingWidget ||
                    !this.editingWidget.hiddenOptions
                ) {
                    return [];
                }

                return this.editingWidget.hiddenOptions;
            },
        },

        methods: {
            onWidgetSelectClose() {
                this.widgetSelectModalOpen = false;
            },
            onTitleUpdate(newTitle) {
                this.paneTitle = newTitle;
            },
            checkForCloneConfirmation() {
                if (this.status === "clone") {
                    window.app.toastManager.showToast({
                        text: 'Widget cloned successfully',
                        icon: 'check',
                    });
                }
            },
            handleBeforeUnload() {
                this.checkForCloneConfirmation();
            },
            open() {
                if (this.editingWidget) {
                    this.paneTitle = this.editingWidget.localConfig.title;
                } else {
                    this.paneTitle = DEFAULT_WIDGET_TITLE;
                }

                this.$broadcast('panels:set_active_panel', 0);
                if (this.editingWidget.id < 0) {
                    this.$parent.$broadcast('config:wait_for_metrics');
                    var payload = {
                        dashboard_id: this.$parent.id,
                        connection_id: this.$parent.cId,
                        widget_type: this.editingWidget.$options._componentTag,
                        width: this.editingWidget.config.width,
                        height: this.editingWidget.config.height,
                        row: this.editingWidget.config.row,
                        col: this.editingWidget.config.col,
                        timescale_override: this.$parent.timescale_override,
                        scope_uuid: this.$parent.scopeUuid,
                    };
                    $.ajax("/dashboardv2/addWidget", {
                        method: 'GET',
                        data: payload,
                        context: this
                    })
                    .done(data =>{
                        if (!data.success) {
                            console.log(data.msg)
                            return;
                        }
                        this.editingWidget.id = data.new_id;
                        this.$parent.widgets.slice(-1)[0].id = data.new_id;
                        this.$parent.$broadcast('config:update_metrics');
                    });
                }

                this.validators = [];

                this.keyListener = $(document).on('keydown', function(e) {
                    if (e.key === 'Escape') { this.close(); }
                }.bind(this));

                var sT = $(".pa-page").scrollTop();
                $(".pa-page").scrollTop(sT);
                $(this.$refs.drawer).addClass("open");

                if (this.lastWidgetId) {
                    $("#" + this.lastWidgetId).removeClass(this.widgetHighlight);
                }

                $("#" + this.editingWidget.id).addClass(this.widgetHighlight);
                // Ignore overflow on body when the drawer is open
                $("body").css("overflow-y", "hidden");
                // Stops firefox scrollbar from appearing ontop of config drawer
                $(".pa-page_dashboard").css("overflow-y", "hidden");
                this.configModules = this.editingWidget.getConfigModules();
                this.optionModules = this.editingWidget.getOptionModules();
                this.isOpen = true;
                this.lastWidgetId = this.editingWidget.id
            },
            close() {
                this.checkForCloneConfirmation();

                this.status = "";

                $(document).off('keydown');

                var sT = $(".pa-page").scrollTop();
                $(".pa-page").css("width", "");
                $(".pa-page").css("height", "");
                $(".pa-page").css("overflow-y", "");
                $("body").scrollTop(sT);
                $(this.$refs.drawer).removeClass("open");
                setTimeout(() => {
                    $(".grid-stack").css("width", "");
                    $(".dashboard-header").css("width", "");
                    this.editingWidget = null;
                    this.configModules = [];
                    this.optionModules = [];
                    this.isOpen = false;
                }, 1000);

                $("#" + this.editingWidget.id).removeClass(this.widgetHighlight);
                $("body").css("overflow-y", "");
            },

            destroyWidgetModal() {
                this.destroy_widget = true;
            },

            updatePending(widgetId) {
                // The HTML widget block has no data that gets updated when
                // the config is updated so it does not get a WidgetQueue entry
                // to trigger this to stop loading.
                if (this.editingWidget.localConfig.widget_type === "html_block") {
                    return;
                }

                this.updateInProgress.status = true;
                this.updateInProgress.widgetId = widgetId;
            },

            contentReloadComplete(widgetId) {
                if(this.updateInProgress.widgetId == widgetId) {
                    this.updateInProgress.status = false;
                    this.updateInProgress.widgetId = null;
                }
            },

            validate() {
                for (let i = 0; i < this.validators.length; i++) {
                    var validator = this.validators[i];

                    if (!validator()){
                        this.isValid = false;
                        return false;
                    }
                }

                this.isValid = true;

                return true;
            },

            addValidator(validator) {
                this.validators.push(validator);
            },

            dumpWidget() {
                this.$parent.$broadcast("widget:dump_state", this.editingWidget.id);
            },

            scrollFunction() {
                this.$parent.$broadcast("type-ahead-dropdown:deactivate");
            }
        },

        vueReady() {
            window.addEventListener('beforeunload', this.handleBeforeUnload);

            this.validate();
            $(document).on('click', '.module-body .pa-select-hd', e => {
                if (this.$refs.drawer.scrollHeight <= this.$refs.drawer.clientHeight) {
                    return;
                }
                this.noScroll = !this.noScroll;
                if (this.noScroll) {
                    $(document).on('click.configdrawer', 'body', e => {
                        let closest = e.target.closest('.pa-select');
                        if (!closest && this.noScroll) {
                            this.noScroll = false;
                        }
                    });
                } else {
                    $(document).off('click.configdrawer', 'body');
                }
            });
        }
    });

    // Vue.transition('module-expand', {
    //     css: false,
    //     beforeEnter: function(el) {
    //         $(el).css('height', '0px');
    //     },
    //     enter: function(el, done) {
    //         let totalHeight = 0;
    //         $(el).children()
    //             .each(function() {
    //                 if (!$(this).is(':visible')) { return; }
    //                 totalHeight += $(this).outerHeight(true);
    //             });
    //         $(el).css('overflow', 'hidden')
    //             .animate({
    //                 height: `${totalHeight}px`,
    //             }, 200, done);
    //     },
    //     afterEnter: function(el) {
    //         $(el).css('height', '');
    //         $(el).css('overflow', 'auto');
    //     },
    //     enterCancelled: function(el) {
    //       // handle cancellation
    //     },
    //     beforeLeave: function(el) {
    //     },
    //     leave: function(el, done) {
    //         $(el).css('overflow', 'hidden')
    //             .animate({
    //                 height: '0px',
    //             }, 200, done);
    //     },
    //     afterLeave: function(el) {
    //     },
    //     leaveCancelled: function(el) {
    //       // handle cancellation
    //     },
    // });

    export default ConfigDrawer;
</script>
