<style lang="scss">
    #sso-login {
    }
</style>

<template>
    <div id="sso-login">
        <form ref="form" action="/SSORedirect" method="POST">
            <p-field-group
                name="username"
                label="Username/Email">
                <p-input
                    v-model="username"
                    :enter="submit"
                    name="user_name"
                    placeholder="Username/Email"
                    theme="naked">
                </p-input>
            </p-field-group>
        </form>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            previousUrl: String,
        },

        data() {
            return {
                username: '',
            };
        },

        watch: {
            username(val) {
                if (val) {
                    this.eventHub.$emit('login:filledIn', true);
                } else {
                    this.eventHub.$emit('login:filledIn', false);
                }
            },
        },

        methods: {
            submit() {
                this.$refs.form.submit();
            },
        },
    });
</script>
