<script setup>

import { ref, nextTick, watch, computed, onMounted } from 'vue';


const props = defineProps({
    pageData:{
        type: Object,
        required: true
    },
    action:{
        type:Boolean,
        required:false
    }
});

const selectedActivities = ref(props.pageData.role_activities);
const selectedGroups = ref({});
const selectAll = ref(false);
const selectedGroupsModel = ref([])
const proccesSelectAllClick = ref(false);

const checkAllActivitiesInGroup = (groupId) => {
    selectAll.value = false;
    let selectedGroup = null;
    if(selectedGroups.value[groupId] == true) {
        selectedGroups.value[groupId] = false;
    } else {
        selectedGroups.value[groupId] = true;
    }

    for(const group of props.pageData.activity_groups) {
        if(group.id == groupId) {
            selectedGroup = group;
        }
    }

    if(selectedGroups.value[groupId] == true) {
        for(const activity of selectedGroup.activities) {
            if(!selectedActivities.value.includes(activity.id)) {
                selectedActivities.value.push(activity.id);
            }
        }
    } else {
        let tempActivities = [];
        let tempGroups = [];
        for(const activity of selectedGroup.activities) {
            tempGroups.push(activity.id);
        }
        for(const role of selectedActivities.value) {
            if(!tempGroups.includes(role)) {
                tempActivities.push(role);
            }
        }
        selectedActivities.value = tempActivities;
    }
};

const selectAllActivities = () => {
    proccesSelectAllClick.value = true;
}

const checkIndividualActivity = (groupId) => {
    selectAll.value = false;
    selectedGroups.value[groupId] = false;
    selectedGroupsModel.value = 
        selectedGroupsModel.value.filter(item => item !== groupId);
}

watch(selectAll, (newValue, oldValue) => {
    if(proccesSelectAllClick.value == true){
        if(selectAll.value == true) {
            let tempActivities = [];
            let tempGroups = [];
            for(const group of props.pageData.activity_groups) {
                tempGroups.push(group.id);
                selectedGroups.value[group.id] = true;
                for(const activity of group.activities) {
                    tempActivities.push(activity.id);
                }
            }
            selectedGroupsModel.value = tempGroups;
            selectedActivities.value = tempActivities;

        } else {
            selectedActivities.value = [];
            selectedGroupsModel.value = [];
            selectedGroups.value = {};
        }
    }

    proccesSelectAllClick.value = false;
});




const groupsWithAllSelected = computed(() => {
    const group_ids = [];
    for(const activity_group of props.pageData.activity_groups) {
        let allSelected = true;
        if(activity_group.activities.length == 0) {
            continue;
        }
        for(const act of activity_group.activities) {
            if(!selectedActivities.value.includes(act.id)) {
                allSelected = false;
                break;
            }
        }

        if(allSelected) {
            group_ids.push(activity_group.id);
        }
    }

    return group_ids;
});

const groupsWithActivities = computed(() => {
    const group_ids = [];
    for(const activity_group of props.pageData.activity_groups) {
        if(activity_group.activities.length != 0) {
            group_ids.push(activity_group.id);
        }
    }

    return group_ids;
});

watch(groupsWithAllSelected, (newValue, oldValue) => {
    for(const val of newValue) {
        selectedGroups.value[val] = true;
        if(!selectedGroupsModel.value.includes(val)) {
            selectedGroupsModel.value.push(val);
        }
    }
});

onMounted(async () => {
    for(const val of groupsWithAllSelected.value) {
        selectedGroups.value[val] = true;
        if(!selectedGroupsModel.value.includes(val)) {
            selectedGroupsModel.value.push(val);
        }
    }

    if(groupsWithAllSelected.value.length == groupsWithActivities.value.length) {
        selectAll.value = true;
    }
});

const getSelectedActivities = () => {
    return selectedActivities.value;
}

defineExpose({ getSelectedActivities });

</script>

<template>
    <div id="role-activities-wrapper">
        <p class="pa-txt" v-if="action">
            <label class='pa-option'>
                <input
                    name='check_all_activities'
                    type='checkbox'
                    class="pa-option-input"
                    @click="selectAllActivities"
                    v-model="selectAll"
                />
                <span class="pa-option-icon"></span>
                <span class="pa-option-txt">
                    <em>Select All Activities</em>
                </span>
            </label>
        </p>
        <div id='activities' class="pa-vList pa-vList_x3">
            <div v-for="(activity_group, idx) in pageData.activity_groups">
                <h4 class="pa-hdg pa-hdg_4 pa-hdg_bold pa-vr pa-vr_x3">{{ activity_group.name }}</h4>
                <p v-if="activity_group.description">{{ activity_group.description }}</p>

                <p v-if="action">
                    <label class="pa-option">
                        <input 
                            :name='`check_all_activities_in_group_${activity_group.id}`'
                            type='checkbox'
                            class="pa-option-input"
                            :value="activity_group.id"
                            @click="checkAllActivitiesInGroup(activity_group.id)"
                            v-model="selectedGroupsModel"
                        />
                        <span class="pa-option-icon"></span>
                        <span class="pa-option-txt">
                            <em>Select All in {{ activity_group.name }}</em>
                        </span>
                    </label>
                </p>

                <div class="pa-grid pa-vr pa-vr_x3">
                    <div
                        v-for="activity in activity_group.activities"
                        class='pa-grid-col pa-grid-col_4of12'
                    >
                        <label class="pa-option">
                            <input
                                :name="`activity_${activity.id }`"
                                :class="`check_all_activities check_all_activities_in_group_${activity_group.id} pa-option-input`"
                                type='checkbox'
                                :value="activity.id"
                                :disabled="action ? false : true"
                                @click="checkIndividualActivity(activity_group.id)"
                                v-model="selectedActivities"
                            />
                            <span class="pa-option-icon"></span>
                            <span class="pa-option-txt">
                                {{ activity.name }}
                                <p-tooltip
                                    v-if="activity.description != '' && activity.description != activity.name"
                                >
                                    <span slot="trigger">
                                        <span class="fa fa-question-circle" aria-hidden="true"></span>
                                    </span>
                                    <span>{{ activity.description }}</span>
                                </p-tooltip>
                            </span>
                        </label>
                    </div>
                    <p-divider
                        v-if="idx + 1 !== pageData.activity_groups.length"
                    ></p-divider>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    #role-activities-wrapper {
        
    }
</style>