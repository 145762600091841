export default function loadScript(url, callback) {
    // Inserts a script URL at runtime, calling provided
    // callback after it is successfully loaded

    const scriptEl = document.createElement('script');
    scriptEl.type = 'text/javascript';
    scriptEl.src = url;

    if (callback) {
        scriptEl.onload = callback;
    }

    document.head.appendChild(scriptEl);
}
