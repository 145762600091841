const DEFAULT_DETAILS = {
    source: 'next'
};

const wizardMixin = {
    data() {
        return {
            activePaneIndex: 0,
            panes: []
        };
    },

    methods: {
        goTo(index, details) {
            const newIndex = this.validatePaneIndex(index);
            const isNewPaneHidden = newIndex < this.panes.length && this.panes[newIndex].showIf === false || false;
            const wizardIsAdvancing = newIndex > this.activePaneIndex;

            if (isNewPaneHidden) {
                this.goTo(wizardIsAdvancing ? newIndex + 1 : newIndex - 1);

                return;
            }

            const currentPane = this.getCurrentPane();

            if (!this.linear) {
                this.activatePane(newIndex, details);
                return true;
            }

            if (wizardIsAdvancing) {
                if (!this.isPaneActivateable(newIndex)) {
                    return false;
                }
                const onNextDetails = Object.assign({}, DEFAULT_DETAILS, details);

                if (currentPane.onNext(function() {
                    this.activatePane(newIndex, onNextDetails);
                }.bind(this), onNextDetails) === false) {
                    this._heapTrack("Clicked Submit " +  this.name);
                    return false;
                }
            }

            this.activatePane(newIndex, details);

            return true;
        },

        getCurrentPane() {
            let currentPane = this.panes[this.activePaneIndex];

            if (!currentPane) {
                return this.defaultPane;
            }

            return currentPane;
        },

        isPaneValid(pane) {
            const isValid = pane.validate();
            const isRequired = pane.isRequired;

            if (isRequired && !isValid) {
                return false;
            }

            return true;
        },

        validatePaneIndex(index) {
            const maxIndex = (this.panes.length);

            if (index < 0) {
                index = 0;
            } else if (index >= maxIndex) {
                index = maxIndex;
            }

            return index;
        }
    },
};

export default wizardMixin;
