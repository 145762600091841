
var SearchManager;

var URI = require('urijs');

SearchManager = {

    cookieKey: 'searchParams',

    constructSearch: function(url, parameters){
        var uri = URI(url);
        uri.query(parameters)
        return uri.href()
    },

    constructCookie: function(params, actual_value) {
        var params_array = [];
        var existant_cookies = this.extractCookies(actual_value);

        for (var name in params) {
            var value = name + "=" + params[name]
            if (existant_cookies[name]){
                if (params[name]) {
                    existant_cookies[name] = params[name]
                }
                else {
                    delete existant_cookies[name]
                }
            }
            else {
                if (params[name]) {
                    params_array.push(value)
                }
            }
        }
        for (var name in existant_cookies) {
            var value = name + "=" + existant_cookies[name]
            params_array.push(value)
        }
        var cookie_value = params_array.join(';')
        return cookie_value
    },

    extractCookies: function(cookies) {
        if (cookies) {
            var entries = cookies.split(";");
            var result = {}
            for (var index in entries) {
                var param = entries[index].split('=');
                result[param[0]] = param[1]
            }
        }
        else {
            result = {}
        }
        return result
    },

};

module.exports = SearchManager;
