<template>
    <div class="node-map" ref="container" :style="{ height: this.loading ? 'unset' : `${24 * this.numRows}px` }">
        <p-loading-spinner-2 v-if="loadingIndicator && loading"></p-loading-spinner-2>
        <div v-for="(node, index) in iNodes" :key="node.id" class="map-node" :class="nodeClass(node, index)" :style="nodeStyle(node, index)" @click="onClick(node)">
            <svg width="100%" height="100%" v-html="squareSvg"></svg>
            <div class="flyout-trigger"
                 v-p-flyout-trigger="_uid + '-flyout'"
                 direction="right"
                 :hover="true"
                 :on-open="flyoutOpen.bind(null, node, index)"
                 style="position: absolute; top: 0; left: 0; width: 20px; height: 20px; top: 0;">
            </div>
        </div>

        <p-flyout :hover="true" :hover-timeout="0" :wait-time="1000" direction="right" :width="375" :arrow-percent="25" :id="_uid + '-flyout'" positioning="popper">
            <div slot="trigger" class="flyout-trigger">
            </div>

            <div slot="content">
                <slot name="flyout"></slot>
            </div>
        </p-flyout>
    </div>
</template>

<script>
    import Vue from 'vue';

    import _ from 'lodash';
    import squareSvg from '../../infra_map/components/square.svg';

    export default Vue.extend({
        data() {
            return {
                squareSvg,
                iNodes: [],
                totalNodes: null,
                numHidden: null,  // Number of nodes not shown
                containerWidth: 0,
                loading: false,
            };
        },

        props: {
            nodes: {
                type: Array,
                'default': () => [],
            },

            url: String,
            refreshInterval: Number,

            flyoutOpen: {
                type: Function,
                'default': () => {},
            },

            onClick: {
                type: Function,
                'default': () => {},
            },

            loadingIndicator: {
                type: Boolean,
                'default': true,
            },
        },

        computed: {
            numCols() {
                return Math.floor(this.containerWidth / 24);
            },

            numRows() {
                return Math.ceil(this.iNodes.length / this.numCols);
            },
        },

        methods: {
            fetchNodes() {
                $.ajax({
                    url: this.url,
                }).done(data => {
                    if (data.success) {
                        this.iNodes = data.data;
                        if (typeof data.total_nodes !== 'undefined') {
                            this.totalNodes = data.total_nodes;
                            this.numHidden = this.totalNodes - this.iNodes.length;
                            this.$emit('update:num-hidden', this.numHidden);
                        }
                        this.loading = false;
                    } else {
                        console.error(data);
                    }
                });
            },

            nodeClass(node, index) {
                const classes = [];

                if (node.color && !node.color.startsWith('#')) {
                    classes.push(`map-node_${node.color}`);
                } else if (!node.color) {
                    classes.push('map-node_green');
                }

                return classes;
            },

            nodeStyle(node, index) {
                const style = {};

                style.cursor = 'pointer';

                const row = Math.floor(index / this.numCols);
                const col = index % this.numCols;

                const x = 24 * col;
                const y = 25 * row;

                style.left = `${x}px`;
                style.top = `${y}px`;

                if (node.color && node.color.startsWith('#')) {
                    style.fill = node.color;
                }

                return style;
            },
        },

        mounted() {
            this.iNodes = _.cloneDeep(this.nodes);

            this.containerWidth = this.$refs.container.offsetWidth;
        },

        vueReady() {
            if (this.url) {
                this.loading = true;
                this.fetchNodes();
            }

            if (this.refreshInterval) {
                window.setInterval(this.fetchNodes, 1000 * this.refreshInterval);
            }
        },
    });
</script>
