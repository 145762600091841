<style>
</style>

<template>
    <div class="module">
        <div ref="body" v-show="open && show" transition="module-expand" class="module-body">
            <div class="pa-field pa-field_vertical">
                <div class="pa-field-hd">
                    <label for="template" class="pa-label">Time Range</label>
                </div>
                <div class="pa-field-bd">
                    <p-select
                        ref="select"
                        :model.sync="time"
                        :on-change-callback="selectChanged"
                        :options="options.includeYear == false ? time_options_no_year : time_options"
                    >
                    </p-select>
                </div>
                <div class="pa-field-ft">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const TimeRangeModule = Vue.extend({
        events: {
            'timeRangeModule:show'(id, value) {
                if(this.editingWidget.id == id) {
                    this.show = value;
                }
            },
        },

        data() {
            return {
                time_options: [
                    { value: '15min', label: 'Last 15 Minutes' },
                    { value: 'hour', label: 'Last Hour' },
                    { value: '6hr', label: 'Last 6 Hours'},
                    { value: '12hr', label: 'Last 12 Hours'},
                    { value: 'day', label: 'Last 24 Hours' },
                    { value: 'week', label: 'Last Week' },
                    { value: 'month', label: 'Last Month' },
                    { value: 'year', label: 'Last Year' },
                ],
                time_options_no_year: [
                    { value: '15min', label: 'Last 15 Minutes' },
                    { value: 'hour', label: 'Last Hour' },
                    { value: '6hr', label: 'Last 6 Hours'},
                    { value: '12hr', label: 'Last 12 Hours'},
                    { value: 'day', label: 'Last 24 Hours' },
                    { value: 'week', label: 'Last Week' },
                    { value: 'month', label: 'Last Month' },
                ],
                time: 'hour',
                show: true,
            }
        },

        props: {
            options: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            open: {
                type: Boolean,
                default: true
            },
            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        },

        methods: {
            toggle() {
                this.open = !this.open;
            },
            sendConfig(is_unit_percentage) {
                this.$parent.updatePending(this.editingWidget.id);

                var payload = {
                                widget_id: this.editingWidget.id,
                                timescale: this.time
                              }
                $.ajax({
                    url: '/dashboardv2/setWidgetTimescale',
                    method: 'POST',
                    data: payload,
                })
                    .done(data => {
                        if (data.success && data.newConfig) {
                            this.editingWidget.config = data.newConfig;
                            if (is_unit_percentage) {
                                this.$parent.editingWidget.selectedGraphUnit = "%"
                            }
                        }
                    });
                this.editingWidget.pendNewConfig();
            },
            selectChanged(model) {
                this.changeAggregation(model);
                if (this.editingWidget.selectedGraphUnit == "%") {                    
                    this.sendConfig(true);
                } else {
                    this.sendConfig(false);    
                }                
            },

            changeAggregation(model) {
                this.$parent.$broadcast('customControl:showControl', 'aggregation');
            }
        },

        watch: {
        },

        computed: {
            headerText: function() {
                let timeText = '';
                let matching = this.time_options.find(t => {
                    return t.value === this.time;
                });
                if (matching) {
                    timeText = matching.label;
                    return `(${timeText})`;
                } else {
                    return '';
                }
            },
        },

        created() {
            let defaultValue = this.options.defaultValue || 'hour';
            let preset = this.editingWidget.config.timescale;
            if (this.options.time_options) {
                this.time_options = this.options.time_options
            }
            if (this.options.liveOption) {
                this.time_options.unshift({ value: 'live', label: 'Latest Value' });
                defaultValue = this.options.defaultValue || 'live';
                if (typeof preset === 'undefined' || preset === null) { preset = defaultValue };
            } else if (typeof preset === 'undefined' || preset === null) {
                preset = defaultValue;
            }
            this.time = preset;
            this.ready = true;
            this.show = this.options && this.options.show === false ? false : true;
        }
    });

    export default TimeRangeModule;
</script>
