<template>
    <div
        class="pa-prompt"
        :class="{
            isActive: isActive
        }"
	style="display: none;"
        role="dialog"
        v-on:keydown.27="close()"
        v-on:keydown.enter="close()">
        <div
            ref="box"
            class="pa-prompt-box"
            style="width:33%;"
            tabindex="-1">
            <div class="pa-prompt-box-bd">
              <div class="pa-panel">
                <div class="pa-panel-hd">
                    <h2 class="pa-hdg pa-hdg_3" ref="title"><slot></slot></h2>
                    <button
                        type="button"
                        class="pa-prompt-box-close"
                        v-on:click="close()">
                        <svg class="pa-icon pa-icon_block pa-icon_xl pa-icon_light">
                            <use xlink:href="#close"></use>
                        </svg>
                    </button>
                </div>
                <div class="pa-panel-bd">
                  <center ref="body">
                    <slot></slot>
                  </center>
                  <br/>
                  <center>
                    <slot name="prompt_body"></slot>
                  </center>
                </div>
                <div class="pa-panel-ft">
                  <ul class="pa-split">
                    <li>
                    </li>
                    <li>
                      <a
                          type="button"
                          class="pa-btn pa-modal-close pa-mr-8"
                          v-on:click="close()">
                          Cancel
                      </a>
                      <button
                          v-if="justClose"
                          type="button"
                          class="pa-btn"
                          v-on:click="close()">{{button}}</button>
                      <a
                          v-else
                          href="#"
                          ref="callback">
                          <button type="button" class="pa-btn" :disabled="showSpinner">
                            <svg v-show="showSpinner" class="pa-icon spin-8step" style="margin-right: 5px;"><use xlink:href="#spinner-spin-light"></use></svg>
                            {{buttonText}}
                          </button>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
        <div
            v-on:click="close()"
            class="pa-prompt-overlay">
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import dialogMixin from './../mixins/dialogMixin';
    import serialize from './../utils/serialize';
    import focusTrap from 'focus-trap';
    import $ from 'jquery';
    import DOMPurify from 'dompurify';

    const Prompt = Vue.extend({
        mixins: [dialogMixin],

        data() {
            return {
                activeElement: null,
                showSpinner: false,
                buttonText: this.button,
            };
        },

        props: {
            button: {
                type: String,
                'default': 'I\'m sure',
            },
            justClose: {
                type: Boolean,
                'default': false,
            },
        },

        events: {
            'prompt:load': function(details) {
                const id = details.id;

                if (this.id !== id || !details.title || !details.body) {
                    return true;
                }

                if (!details.callback && !details.page && !details.href) {
                    return true;
                }
                this.load(DOMPurify.sanitize(details.title),
                          DOMPurify.sanitize(details.body),
                          details.callback,
                          details.page,
                          details.href,
                          details.args);
                this.open();

                return true;
            },
            'prompt:close': function() {
                this.close();
            },
        },

        methods: {
            open() {
                this.showSpinner = false;
                this.buttonText = this.button;
                if (this.isActive) {
                    return;
                }

                this.isActive = true;
                $('.pa-prompt').css('display', '');

                setTimeout(() => {
                    this.activeElement = document.activeElement;

                    focusTrap.activate(this.$refs.box);
                });
            },

            close() {
                if (!this.isActive) {
                    return;
                }

                this.isActive = false;
                $('.pa-prompt').css('display', 'none');

                focusTrap.deactivate();

                if (!this.activeElement || !this.activeElement.focus) {
                    return;
                }

                setTimeout(() => this.activeElement.focus());
            },

            // eslint-disable-next-line max-params
            load(title, body, callback, page, href, args) {
                // this.$children.forEach($child => $child.$destroy(true));
                this.$title.html(title);
                this.$body.html(body);
                if (callback && typeof callback === 'string') {
                    this.$callback.attr('onclick', callback);
                    this.$callback.on('click', () => {
                        this.showSpinner = true;
                    });
                } else if (callback && typeof callback === 'function') {
                    this.$callback.off('click');
                    if (args) {
                        this.$callback.on('click', args, callback);
                    } else {
                        this.$callback.on('click', callback);
                    }
                    this.$callback.on('click', () => {
                        this.showSpinner = true;
                        this.buttonText = 'Submitting...';
                    });
                } else {
                    const url = href || page;
                    this.$callback.attr('href', url);
                    this.$callback.on('click', () => {
                        this.showSpinner = true;
                        this.buttonText = 'Submitting...';
                    });
                }
            },

            _onDrag(event) {
                const width = (this.dragabble.startWidth
                               + event.clientX
                               - this.dragabble.startX);
                this.$el.style.width = `${width}px`;
            },

            _onStopDrag() {
                document.documentElement.removeEventListener('mousemove', this._onDrag);
                document.documentElement.removeEventListener('mouseup', this._onStopDrag);
            },
        },

        vueReady() {
            this.dragabble = {
                startX: null,
                startWidth: null,
            };

            this.$title = $(this.$refs.title);
            this.$body = $(this.$refs.body);
            this.$callback = $(this.$refs.callback);
        },
    });

    export default Prompt;
</script>
