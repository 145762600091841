<template>
    <div v-show="isOpen" @click.self="close" class="service-card-container flip-container" :class="{'flipped': isFlipped}" transition="service-card">
        <div class="service-card flipper">
            <div class="front">
                <div class="service-card-hd">
                    <span style="font-size: 20px; line-height: 40px;">Add Services and Applications to Monitor</span>
                    <form class="pa-input pa-input_phony pa-input_xxs"
                          style="position: absolute; top: 20px; right: 64px;"
                          onsubmit="event.preventDefault();return;">
                      <label for="search" class="pa-isVisuallyHidden">Search</label>
                      <input v-model="applicationSearch" type="search" id="search" placeholder="Search Applications"
                          style="height: 32px; font-size: 13px;">
                      <div class="pa-input_phony-icon">
                        <svg class="pa-icon pa-icon_block pa-icon_xl">
                          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#magnify"></use>
                        </svg>
                      </div>
                    </form>
                    <button
                        type="button"
                        class="pa-modal-box-close"
                        @click="close()">
                        <svg class="pa-icon pa-icon_block pa-icon_xl">
                            <use xlink:href="#close"></use>
                        </svg>
                    </button>
                </div>
                <div class="shadow-container">&nbsp;</div>
                <div class="service-card-bd">
                    <span class="pa-txt pa-txt_bold pa-txt_light">Detected or Installed</span>
                    <div class="service-list">
                        <div v-for="app in computedDetectedApps" class="app-box">
                            <svg v-if="app.doc_name" class="doc-icon" @click="showDocs(app)">
                                <use xlink:href="#docs"></use>
                            </svg>
                            <div v-if="app.status === 'added' && isServer && app.error" class="error-icon">
                                <p-tooltip :hover="true" :advanced="true" :hover-timeout="300">
                                    <svg slot="trigger"><use xlink:href="#alert-circle-outline"></use></svg>
                                    <span>
                                        <p v-if="app.error === 'no-metrics'">You haven't added any metrics yet. Click "Configure Metrics" to get started.</p>
                                        <p v-if="app.error === 'misconfigured'">
                                            We've not been able to find this application yet. Please click 
                                            <svg @click="showDocs(app)" style="width: 12px; height: 12px;">
                                                <use xlink:href="#docs"></use>
                                            </svg>
                                            to read the installation docs.
                                        </p>
                                    </span>
                                </p-tooltip>
                            </div>
                            <div class="app-logo-container" v-if="app.logo_url">
                                <img :src="'/static/assets/newux/media/logos/' + app.logo_url"
                                     :class="app.logo_url.endsWith('svg') ? 'svg' : 'png'"
                                     :title="app.name"
                                     v-if="app.status === 'added'"
                                     style="cursor: pointer;"
                                     @click="configureService($event, app)"/>
                                <img :src="'/static/assets/newux/media/logos/' + app.logo_url"
                                     :class="app.logo_url.endsWith('svg') ? 'svg' : 'png'"
                                     :title="app.name"
                                     v-else />
                            </div>
                            <div v-else class="app-logo-container"></div>
                            <div class="app-title-container">
                                <h3>{{ app.name }}</h3>
                            </div>
                            <template v-if="app.status === 'added'">
                                <button class="pa-btn pa-btn_primary added-button" @click="configureService($event, app)">
                                    Configure
                                </button>
                            </template>
                            <button v-else @click="addService(app.textkey, 'detected')" class="pa-btn pa-btn_secondary add-button">Add</button>
                        </div>
                    </div>
                    <p v-if="!detected.length" class="pa-txt pa-txt_secondary" style="margin-top: -10px;">No applications have been discovered or added.</p>
                    <p v-if="detected.length && !numDetected" class="pa-txt pa-txt_secondary" style="margin-top: -10px;">All applications filtered.</p>
                    <span v-if="isServer" class="pa-txt pa-txt_bold pa-txt_light">Not Detected or Installed</span>
                    <div class="service-list">
                        <div v-for="app in computedNotDetectedApps" class="app-box">
                            <svg v-if="app.doc_name" class="doc-icon" @click="showDocs(app)">
                                <use xlink:href="#docs"></use>
                            </svg>
                            <div class="app-logo-container" v-if="app.logo_url">
                                <img :src="'/static/assets/newux/media/logos/' + app.logo_url"
                                     :class="app.logo_url.endsWith('svg') ? 'svg' : 'png'"
                                     :title="app.name" />
                            </div>
                            <div v-else class="app-logo-container"></div>
                            <div class="app-title-container">
                                <h3>{{ app.name }}</h3>
                            </div>
                            <button :disabled="app.textkey === 'network.snmp' && !canAddSNMP"
                                @click="addService(app.textkey, 'not-detected')" class="pa-btn pa-btn_secondary add-button">Add</button>
                        </div>
                    </div>
                    <p v-if="!not_detected.length" class="pa-txt pa-txt_secondary" style="margin-top: -10px;">No available applications.</p>
                    <p v-if="not_detected.length && !numNotDetected" class="pa-txt pa-txt_secondary" style="margin-top: -10px;">All applications filtered.</p>
                </div>
            </div>
            <div class="back">
                <div class="service-card-hd">
                    <span style="font-size: 20px; line-height: 40px;">{{categoryName}} Monitoring Configuration Instructions</span>
                    <button class="back-btn" @click="goToFront()">
                        <svg class="pa-icon pa-icon_xxxl"><use xlink:href="#chevron-left"></use></svg>
                    </button>
                    <button
                        type="button"
                        class="pa-modal-box-close"
                        @click="close()">
                        <svg class="pa-icon pa-icon_block pa-icon_xl">
                            <use xlink:href="#close"></use>
                        </svg>
                    </button>
                </div>
                <div class="shadow-container" style="border-bottom: .5px solid #d0d0d0;">&nbsp;</div>
                <div class="service-card-bd">
                    <div style="margin: 0px 10% 15px 10%;">
                        <div v-if="messages.length" class="pa-message pa-message_info" style="margin: 0 auto 20px auto; width: 50%;">
                            <p v-for="msg in messages" class="pa-txt">{{ msg }}</p>
                        </div>
                        <div style="text-align: center;">
                            <template v-if="!queuedRebuild">
                                <a @click="rebuildMetadata"  class="pa-btn">
                                    Rebuild agent metadata
                                </a>
                                <p class="pa-txt pa-txt_bold" style="margin-top: 15px; font-size: 12px; color: grey; ">Once you've followed the steps below, rebuild your metadata. The application should appear on the instance details page in a minute or two.</p>
                            </template>
                            <p v-else class="pa-txt pa-txt_bold">Metadata rebuild command sent to agent. Please wait a few minutes and check again.</p>
                        </div>
                    </div>
                    <div ref="instructions">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    import createScrollLocker from '../shared/utils/scrollLocker';

    export default Vue.extend({
        components: {
        },

        events: {
            'service-card:open': function() {
                this.open();
            },
        },

        data() {
            return {
                isOpen: false,
                applicationSearch: '',
                categoryName: '',
                isFlipped: false,
                messages: [],
                queuedRebuild: false,
                searchTimer: null,
                numDetected: 0,
                numNotDetected: 0,
            };
        },

        computed: {
            computedDetectedApps() {
                if (!this.detected) {
                    return [];
                }

                return this.detected.filter(this.containsSearch);
            },
            computedNotDetectedApps() {
                if (!this.not_detected) {
                    return [];
                }

                return this.not_detected.filter(this.containsSearch);
            },
            'isServer': function() {
                return this.$parent.isServer;
            },
            'detected': function() {
                let retVal = this.$parent.localApplications.detected || [];
                for (var i = 0; i < retVal.length; i++) {
                    let app = retVal[i];
                    if (app.status !== 'added') {
                        continue;
                    }
                    let newVal = Object.assign({}, app);
                    let found = this.$parent.categories.added.find((c) => {
                        return c.textkey === app.textkey;
                    });
                    if (found) {
                        newVal.numMetrics = found.metrics.length;
                        newVal.messages = found.errors;
                        if (found.errors.length && !found.has_supported_metric) {
                            newVal.error = 'misconfigured';
                        } else if (!found.metrics.length) {
                            newVal.error = 'no-metrics';
                        } else {
                            newVal.error = '';
                        }
                    } else {
                        newVal.numMetrics = 0;
                        newVal.messages = [];
                        newVal.error = 'no-metrics';
                    }
                    retVal.splice(i, 1, newVal);
                }
                return retVal;
            },
            'not_detected': function() {
                return this.$parent.localApplications.not_detected || [];
            },
            'fromAppliance': function() {
                return this.$parent.fromAppliance;
            },
            'canAddSNMP': function() {
                return this.$parent.isTemplate || this.fromAppliance;
            },
        },

        props: {
        },

        methods: {
            open: function() {
                this.isFlipped = false;
                this.queuedRebuild = false;
                this.isOpen = true;
                this.scrollLocker.on();
                window.addEventListener('keydown', event => {
                    if (event.key === 'Escape' && this.isOpen) {
                        this.close();
                    }
                });
            },
            close: function() {
                this.isOpen = false;
                this.scrollLocker.off();
            },
            addService: function(textkey, from) {
                var payload = {
                    server_id: this.$parent.serverId,
                    category_textkey: textkey,
                };
                $.ajax("/report/get_metric_category_data_for_add", {
                    method: 'GET',
                    data: payload,
                    context: this
                })
                .done(data =>{
                    if (!data.success) {
                        console.log(data.msg)
                        return;
                    }
                    if (textkey === 'network.snmp') {
                        window.location.href=`/config/SNMPCatalog?server_id=${this.$parent.serverId}`;
                    }
                    if (from === 'detected') {
                        let service = this.detected.find((a) => {
                            return a.textkey === textkey;
                        });
                        if (service) {
                            service.status = 'added';
                            service.messages = data.messages;
                            service.has_supported_metric = data.has_supported_metric;
                            this.eventHub.$emit('category-added', service);
                            if (!service.has_supported_metric) {
                                this.showDocs(service);
                            } else {
                                this.close();
                                this.$parent.$broadcast('metric-drawer:open', this.$parent.serverId, textkey);
                            }
                        }
                    } else if (from === 'not-detected') {
                        let index = this.not_detected.findIndex((a) => {
                            return a.textkey === textkey;
                        });
                        if (index > -1) {
                            let service = this.not_detected.splice(index, 1);
                            if (service.length) {
                                service = service[0];
                            } else {
                                return;
                            }
                            service.status = 'added';
                            service.messages = data.messages;
                            service.has_supported_metric = data.has_supported_metric;
                            if (!service.has_supported_metric
                                    && service.doc_name
                                    && service.textkey !== 'network.snmp'
                                    && !this.$parent.isTemplate) {
                                this.showDocs(service);
                            } else {
                                this.close();
                                this.$parent.$broadcast('metric-drawer:open', this.$parent.serverId, textkey);
                            }
                        }
                    }
                });
            },
            showDocs: function(service) {
                this.queuedRebuild = false;
                this.categoryName = service.name;
                this.messages = service.messages;
                var payload = {
                    doc_name: service.doc_name,
                    category_textkey: service.textkey,
                };
                $.ajax("readme_doc", {
                    method: 'GET',
                    data: payload,
                    context: this
                })
                .done(data =>{
                    if (typeof data.success !== 'undefined' && !data.success) {
                        return;
                    }
                    this.$refs.instructions.innerHTML = data;
                    this.isFlipped = true;
                });
                var payload = {
                    category_textkey: service.textkey,
                };
                //$.ajax("get_status_messages", {
                //    method: 'GET',
                //    data: payload,
                //    context: this
                //})
                //.done(data =>{
                //    if (data.messages) {
                //        this.messages = data.messages;
                //    }
                //});
            },
            rebuildMetadata: function() {
                this.queuedRebuild = true;
                var payload = {
                    server_id: this.$parent.serverId,
                };
                $.ajax("/config/rebuildMetadata", {
                    method: 'GET',
                    data: payload,
                    context: this
                })
            },
            configureService: function(event, service) {
                if (service.textkey === 'network.snmp') {
                    window.location.href=`/config/SNMPCatalog?server_id=${this.$parent.serverId}`;
                }
                this.$parent.openDrawer(event, service.textkey);
                this.close();
            },
            goToFront: function() {
                this.isFlipped = false;
            },
            containsSearch: function(app) {
                const inName = app.name.toLowerCase().includes(this.applicationSearch.toLowerCase());
                const inKeywords = app.keywords && app.keywords.some((k) => {
                    return k.toLowerCase().includes(this.applicationSearch.toLowerCase());
                });
                return inName || inKeywords;
            },
        },

        watch: {
            'applicationSearch': function(val, oldVal) {
                if (typeof heap !== 'undefined') {
                    if (this.searchTimer) { return; }
                    this.searchTimer = setTimeout(() => {
                        heap.track('performed-catalog-search', {value: val});
                        this.searchTimer = null;
                    }, 1000);
                }
            },
        },

        vueReady() {
            this.scrollLocker = createScrollLocker();

            // Scroll shadow
            $('.service-card-bd').scroll(() => {
                if (this.isFlipped) {
                    var scrollArea = $('.back .service-card-bd');
                    var shadowContainer = $('.back .shadow-container');
                } else {
                    var scrollArea = $('.front .service-card-bd');
                    var shadowContainer = $('.front .shadow-container');
                }
                if (scrollArea.scrollTop() >= 50) {
                    shadowContainer.addClass('scroll-shadow');
                } else {
                    shadowContainer.removeClass('scroll-shadow');
                }
            });
        },
    });
</script>
