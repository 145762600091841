<template>
    <div
        class="pa-menu"
        v-p-on-focusout="isActive"
        :callback="close"
        :class="{
            isActive: isActive,
            'is-disabled': disabled,
            'pa-menu_inversed': inversed,
            'pa-menu_isMaterial': isMaterial,
            'pa-menu_pinned': isPinned,
            'pa-menu_profile': isProfile,
            'pa-menu_absolute': isAbsolute
            }">
        <div
            class="pa-menu-hd"
            :id="htmlId"
            ref="hd"
            v-on:click.stop="_toggle($event)"
            v-on:mouseover="_onMouseover($event)"
            v-on:mouseout="_onMouseout($event)"
            v-if="numChildren > 0">
            <slot name="trigger"></slot>
        </div>
        <div
            ref="menu"
            v-on:click="_onClickItem($event)"
            v-on:keydown.27="close()"
            tabindex="-1"
            class="pa-menu-box"
            :class="{ isActive: isActive }"
            :style="[styles]">
            <div
                class="pa-select-list-box"
                @click.stop=""
                v-if="searchable">
                <input
                    ref="search"
                    id="searchBox"
                    type="search"
                    class="pa-input"
                    v-model="filterText"
                    placeholder="Search" />
            </div>
            <slot>
            </slot>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Popper from 'popper.js';
    import menuMixin from '../mixins/menuMixin';
    import createScrollLocker from '../utils/scrollLocker';
    import popupPositioner from '../utils/popupPositioner';
    import isComponentInModal from '../utils/isComponentInModal';

    const Menu = {
        mixins: [menuMixin],

        data() {
            return {
                calculatedSizing: false,
                isActive: false,
                inModal: false,
                styles: {},
                overflow: {},
                filterText: '',
                numChildren: 0
            };
        },

        watch: {
            'filterText': function(val, oldVal) {
                let filter = val.toLowerCase();
                let elems = $(this.$refs.menu).find("button, a").toArray();
                elems.forEach(e => {
                    if (e.innerText.toLowerCase().includes(filter)) {
                        $(e).show();
                    } else {
                        $(e).hide();
                    }
                });
            },
        },

        methods: {
            _toggle($event) {
                $event.preventDefault();

                if (this.isSubMenu) {
                    $event.stopPropagation();
                }

                if (this.isActive) {
                    this.close();

                    return;
                }

                this.open();
            },

            open() {
                if (this.disabled) {
                    return;
                }

                if (this.maxHeight) {
                    this.styles = {
                        'max-height': `${this.maxHeight}px`,
                        overflow: 'auto',
                    };
                } else {
                    this.styles = {};
                }

                this.isActive = true;

                Vue.nextTick(() => {
                    if (!this.isSubMenu) {
                        if (!this.scrollLocker) {
                            const inModal = document.querySelector('.pa-modal.isActive');
                            this.scrollLocker = createScrollLocker({ inModal });
                        }

                        this.popper = new Popper(this.$refs.hd, this.$refs.menu, {
                            placement: this.placement,
                            modifiers: {
                                computeStyle: {
                                    // Can't use `transform` due to submenus
                                    gpuAcceleration: false,
                                },
                            },
                        });

                        this.scrollLocker.on();
                        this.eventHub.$emit('scroll-lock:on', this);
                    } else {
                        let placement = 'right-start';
                        const parentMenu = this.$el.closest('.pa-menu-box');
                        if (parentMenu && parentMenu.scrollHeight > parentMenu.clientHeight) {
                            placement = 'left-start';
                        }
                        this.popper = new Popper(this.$refs.hd, this.$refs.menu, {
                            placement: placement,
                            positionFixed: true,
                            modifiers: {
                                hide: {
                                    enabled: false,
                                },
                                preventOverflow: {
                                    enabled: false,
                                },
                            },
                        });
                    }

                    if (this.searchable && this.focusSearch) {
                        this.$refs.search.focus();
                    }
                });
            },

            close() {
                this.isActive = false;

                if (!this.isSubMenu) {
                    this.scrollLocker.off();
                    this.eventHub.$emit('scroll-lock:off', this);
                }
            },

            _onClickItem() {
                if (!this.closeBoxOnClickItem) {
                    return;
                }

                this.close();
            },

            _onMouseover(event) {
                if (!this.isSubMenu || this.isAbsolute) {
                    return;
                }

                this.open();
            },

            _onMouseout(event) {
                if (this.isSubMenu) {
                    this.close();
                }
            },
        },

        props: {
            closeBoxOnClickItem: {
                type: Boolean,
                default: true,
            },

            isProfile: Boolean,

            isSubMenu: Boolean,

            forceInModal: Boolean,

            maxHeight: Number,

            minHeight: Number,

            htmlId: String,

            searchable: Boolean,

            focusSearch: Boolean,

            placement: {
                type: String,
                default: "bottom-start",
            },
        },

        mounted() {
            this.numChildren = this.$refs.menu.childElementCount;
        },

        vueReady() {
            const inModal = this.forceInModal || isComponentInModal(this);
            this.scrollLocker = createScrollLocker({ inModal: inModal });

            if (this.isActive) {
                this.open();
            }
        },
    };

    export default Menu;
</script>
