<style>
    .widget-drawer {
        position: fixed;
        bottom: -230px;
        left: 0px;
        width: 100%;
        -webkit-transition: bottom 0.7s ease;
        -moz-transition: bottom 0.7s ease;
        -o-transition: bottom 0.7s ease;
        transition: bottom 0.7s ease;

    }
    .widget-drawer.open {
        bottom: 0px !important;
    }
    .widget-drawer.webkit {
        /* Scrollbar doesn't seem to affect height in webkit */
        bottom: -215px;
    }
    .drawer-container {
        overflow-x: scroll;
        overflow-y: hidden;
        text-align: center;
        background: #445963;
        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.6);
    }
    .drawer {
        display: flex;
        position: relative;
        padding: 30px 20px 55px 20px;
        /* Needed for background. Not sure why. */
        float: left;
    }
    .drawer:after {
        content: "";
        display: inline-block;
        width: 40px;
        min-width: 40px;
    }
    .drawer .grid-stack-item {
        width: 180px;
        height: 150px;
        text-align: center;
        line-height: 150px;
        z-index: 10;
        cursor: default;
        display: inline-block;
        background-color: #ffffff;
        margin-left: 20px;
        margin-right: 20px;
    }
    .drawer .grid-stack-item .grid-stack-item-content {
        width: 180px;
        height: 150px;
        color: #000000;
        text-align: center;
        background-size: 70%;
        background-position: center center;
        box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.4);
        background-repeat: no-repeat;
    }
    .drawer-button-container {
        position: fixed;
        left: 30px;
        bottom: 20px;
        -webkit-transition: bottom 0.7s ease;
        -moz-transition: bottom 0.7s ease;
        -o-transition: bottom 0.7s ease;
        transition: bottom 0.7s ease;
    }
    .widget-drawer.open .drawer-button-container {
        bottom: 250px;
    }
    .widget-drawer.webkit.open .drawer-button-container {
        bottom: 235px;
    }
    .drawer-button-container button, .drawer-button-container button:hover {
        background: #445963;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.6);
    }
    .widget-type-label {
        line-height: 25px;
        color: #fefefe;
        text-shadow: 1px 1px 4px #000;
    }
</style>

<template>
    <div class="widget-drawer" v-show="$parent.editing" ref="drawer">
        <div class="drawer-button-container">
            <button ref="toggle" @click="toggle" class="pa-btn drawer-button">Widgets</button>
        </div>
        <div class="drawer-container">
            <div class="drawer" id="widget-drawer">
                <div @mousedown="toggle" v-for="type in types" :id="type.vuetype" class="grid-stack-item new-widget">
                    <div class="grid-stack-item-content"
                         :style="'background-image: url(../static/assets/newux/media/dashboard/' + type.image + ')'"></div>
                     <span class="widget-type-label">{{type.title}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const WidgetDrawer = Vue.extend({
        events: {
        },

        data() {
            return {
                isOpen: false,
                types: [
                    { vuetype: 'p-activeoutage-widget', title: 'Active Incident Counter', image: 'active-incident-counter.svg' },
                    { vuetype: 'p-active-incidentdetails-widget', title: 'Active Incident Details', image: 'active-incident-details.svg' },
                    { vuetype: 'p-aggregatedmetric-widget', title: 'Aggregated Metric', image: 'single-metric_color.svg' },
                    { vuetype: 'p-aggregatedavail-widget', title: 'Aggregated Availability', image: 'metric-summary_color.svg' },
                    { vuetype: 'p-barchart-widget', title: 'Bar Chart', image: 'bar-chart_color.svg' },
                    { vuetype: 'p-gauge-widget', title: 'Gauge', image: 'gauge_color.svg' },
                    { vuetype: 'p-html-widget', title: 'HTML Block', image: 'html-block_color.svg' },
                    { vuetype: 'p-linegraph-widget', title: 'Line Graph', image: 'line-graph_color.svg' },
                    { vuetype: 'p-linegraph-table-widget', title: 'Advanced Line Graph', image: 'line-graph_color.svg' },
                    { vuetype: 'p-metricheatmap-widget', title: 'Metric Heatmap', image: 'metric-heatmap_color.svg' },
                    { vuetype: 'p-rollup-widget', title: 'Metric Rollup', image: 'server-summary_color.svg' },
                    { vuetype: 'p-metricsummary-widget', title: 'Metric Summary', image: 'metric-summary_color.svg' },
                    { vuetype: 'p-oncall-widget', title: 'On-Call', image: 'metric-summary_color.svg' },
                    { vuetype: 'p-resolvedoutage-widget', title: 'Resolved Incident Counter', image: 'resolved-incident-counter.svg' },
                    { vuetype: 'p-resolved-incidentdetails-widget', title: 'Resolved Incident Details', image: 'resolved-incident-details.svg' },
                    { vuetype: 'p-serverscorecard-widget', title: 'Server Scorecard', image: 'server-scorecard.svg' },
                    { vuetype: 'p-serverheatmap-widget', title: 'Server Heatmap', image: 'server-heatmap_color.svg' },
                    { vuetype: 'p-serversummary-widget', title: 'Server Summary', image: 'server-summary_color.svg' },
                    { vuetype: 'p-singlemetric-widget', title: 'Single Metric', image: 'single-metric_color.svg' },
                    { vuetype: 'p-stoplight-widget', title: 'Stoplight', image: 'stoplight_color.svg' },
                    { vuetype: 'p-metricupdown-widget', title: 'Up Down', image: 'up-down_color.svg' },
                ],
            };
        },

        props: {
        },

        methods: {
            toggle() {
                if (!this.isOpen) {
                    this.open();
                } else {
                    this.close();
                }
            },
            open() {
                this.$refs.drawer.classList.add('open');
                this.$refs.toggle.textContent = 'Close';
                this.isOpen = true;
                $('#body').on('keydown', e => {
                    if (e.key === 'Escape') { this.toggle(); }
                });
                // Close config drawer if it's open to avoid mixups
                if (this.$parent.$refs.configdrawer.isOpen) {
                    this.$parent.$refs.configdrawer.close();
                }
            },
            close() {
                this.$refs.drawer.classList.remove('open');
                this.$refs.toggle.textContent = 'Widgets';
                this.isOpen = false;
                $('#body').off('keydown');
                if (typeof this.calloutMgr !== 'undefined') {
                    this.calloutMgr.removeAllCallouts();
                }
            },
            newTourOpen() {
                setTimeout(() => {
                    this.$refs.drawer.classList.add('open');
                    this.$refs.toggle.textContent = 'Close';
                    this.isOpen = true;
                    $('#body').on('keydown', e => {
                        if (e.key === 'Escape') { this.toggle(); }
                    });
                    setTimeout(() => {
                        this.calloutMgr = hopscotch.getCalloutManager();
                        this.calloutMgr.createCallout({
                            id: 'drawer-highlight',
                            target: 'widget-drawer',
                            placement: 'top',
                            title: 'Widget Drawer',
                            content: 'Drag out a widget to start visualizing your data.',
                            xOffset: 200,
                            yOffset: 0,
                            width: 280,
                        });
                    }, 700);
                }, 700);
            },
        },

        created() {
            if (this.$parent.resellerDashboard) {
                // Only allow certain widgets for reseller dashboards
                /* eslint-disable max-len */
                this.types = [...[
                    { vuetype: 'p-activeoutage-widget', title: 'Active Incident Counter', image: 'active-incident-counter.svg' },
                    { vuetype: 'p-active-incidentdetails-widget', title: 'Active Incident Details', image: 'active-incident-details.svg' },
                    { vuetype: 'p-aggregatedmetric-widget', title: 'Aggregated Metric', image: 'single-metric_color.svg' },
                    { vuetype: 'p-metricheatmap-widget', title: 'Metric Heatmap', image: 'metric-heatmap_color.svg' },
                    { vuetype: 'p-metricsummary-widget', title: 'Metric Summary', image: 'metric-summary_color.svg' },
                    { vuetype: 'p-resolvedoutage-widget', title: 'Resolved Incident Counter', image: 'resolved-incident-counter.svg' },
                    { vuetype: 'p-resolved-incidentdetails-widget', title: 'Resolved Incident Details', image: 'resolved-incident-details.svg' },
                    { vuetype: 'p-serverheatmap-widget', title: 'Server Heatmap', image: 'server-heatmap_color.svg' },
                    { vuetype: 'p-serversummary-widget', title: 'Server Summary', image: 'server-summary_color.svg' },
                ]];
                /* eslint-enable max-len */
            }
        },

        vueReady() {
            $('.drawer .grid-stack-item').draggable({
                revert: true,
                revertDuration: 1,
                scroll: false,
                appendTo: 'body',
                helper(e) {
                    const original = $(e.target).hasClass('ui-draggable') ? $(e.target) :  $(e.target).closest('.ui-draggable');
                    return original.clone(true).css({
                        width: '180px',
                        height: '150px',
                        'line-height': '150px',
                    });
                },
            });
            // if(navigator.vendor.indexOf('Apple Computer') != -1){
            //     this.$refs.drawer.classList.add("webkit");
            // }
        },
    });

    export default WidgetDrawer;
</script>
