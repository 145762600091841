<template>
    <div class="pa-tabbedPanels" :class="{'pa-tabbedPanels--vertical': vertical }">
        <slot name="header"></slot>
        <div v-if="sortedPanels.length" class="pa-tabbedPanels-tabs">
            <div>
                <div class="pa-tabs">
                    <button
                        :key="panel.id"
                        v-for="(panel, index) in sortedPanels"
                        class="pa-tab"
                        :class="{ isActive: panel.localActive }"
                        v-on:click="setActivePanel(index, panel.id)"
                        type="button"
                        :disabled="panel.disabled"
                        :style="disabledStyle(panel)"
                        :title="panel.title"
                    >
                        <p-badge v-if="panel.badge" :variant="panel.badge.variant">
                            {{ panel.badge.text }}
                        </p-badge>
                        <custom-title
                            v-if="panel.$slots.title"
                            :title="panel.$slots.title"
                        >
                        </custom-title>
                        <template v-else>
                            {{ panel.title }}
                        </template>
                        <span
                            v-if="panel.hasError || panel.notification"
                            class="pa-table-item-icon"
                        >
                            <template v-if="panel.notification">
                                {{ panel.notification }}
                            </template>
                            <p-icon
                                v-if="!panel.notification"
                                block
                                size="xl"
                                icon="alert-circle"
                            >
                            </p-icon>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="pa-tabbedPanels-panels">
            <ul class="pa-panels">
                <slot></slot>
            </ul>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';

    const Panels = Vue.extend({
        components: {
            // For rendering a "title" slot
            CustomTitle: {
                functional: true,
                render: (h, ctx) => ctx.props.title
            }
        },

        beforeDestroy() {
            window.removeEventListener('hashchange', this._onHashChange);
        },

        data() {
            return {
                panels: [],
                isActiveFromClick: false,
            };
        },

        events: {
            'panels:switchPanels': function(panels) {
                this.panels = panels;
            },
            'panels:set_active_panel': function(id) {
                this.setActivePanel(id);
            },
            'panels:set_active_panel_by_id': function(id) {
                this.setActivePanelById(id, true);
            },
            'panels:set_active_panel_by_id_page_load': function(id) {
                this.setActivePanelById(id, false);
            },
            'panels:set_panel_change_from_click': function(clicked) {
                this.isActiveFromClick = clicked;
            }
        },

        computed: {
            sortedPanels: function() {
                if (this.sortPanels) {
                    return _.sortBy(this.panels, 'sort_id');
                } else {
                    return this.panels;
                }
            }
        },

        methods: {
            close: function() {
                this.$dispatch('dashboard:close_config');
            },
            getActivePanel: function() {
                for (let i = 0; i < this.panels.length; i++) {
                    const panel = this.panels[i];
                    if (panel.localActive) {
                        return panel;
                    }
                }
            },
            setActivePanel(index, id) {
                if(window.app.rootVue) {
                    window.app.rootVue.$broadcast('panels:set_panel_change_from_click', !!id);
                    this.isActiveFromClick = !!id
                }
                this.panels.forEach((panel) => {
                    panel.localActive = false;
                });

                if (!_.isEmpty(this.panels)) {
                    if (this.sortPanels && this.isActiveFromClick) {
                        const sortedPanels = _.sortBy(this.panels, 'sort_id');
                        const matchedPanel = sortedPanels[index];

                        if (matchedPanel && matchedPanel.id === id) {
                            const matchedIndex = _.findIndex(this.panels,
                                { 'id': matchedPanel.id }
                            );

                            if (matchedIndex !== -1) {
                                index = matchedIndex;
                            }
                        }
                    }

                    this.panels[index].localActive = true;
                    this.panels[index].onActivate();
                }
            },
            setActivePanelById(id, overrideActiveFromClick) {
                //Don't perform this step if the user clicked a panel
                //We're going to assume the panel exists if it was clicked
                if (this.isActiveFromClick && overrideActiveFromClick !== true) {
                    return;
                }

                const length = this.panels.length;

                for (let i = 0; i < length; i++) {
                    if (this.panels[i].id === id) {
                        this.setActivePanel(i);

                        return;
                    }
                }

                let index = 0;
                // Because if we open a drawer with tabs, while a tab is already active
                // in the background, it will use the already active tab hash which
                // doesnt match so it will revert to the first one, but if we are
                // sorting the panels grabbing the first panel might be the wrong one.
                // So we should perform the same logic as if no id was passed at all.
                const noMatchingPanelId = this.panels.some(panel => panel.id !== id);

                if (this.sortPanels && (!id || noMatchingPanelId)) {
                    const matchedPanel = this.sortedPanels[0];
                    const matchedIndex = _.findIndex(this.panels,
                        { 'id': matchedPanel.id }
                    );

                    if (matchedIndex !== -1) {
                        index = matchedIndex;
                    }
                }

                this.setActivePanel(index);
            },

            titleStyle: function(panel) {
                if (!panel.disabled) {
                    return {};
                }
                return {
                    visibility: 'hidden',
                };
            },

            disabledStyle: function(panel) {
                if (!panel.disabled) {
                    return {};
                }
                return {
                    cursor: 'default',
                };
            },

            _addPanel(panel) {
                this.panels.push(panel);
                if (!this.rememberActivePanel
                        && this.panels.length > 0
                        && !this.getActivePanel()) {
                    this.setActivePanel(0);
                }
            },

            _removePanel(panel) {
                const index = this.panels.findIndex(p => {
                    return p === panel;
                });
                if (index > -1) {
                    this.panels.splice(index, 1);
                    if (!this.rememberActivePanel
                            && this.panels.length > 0
                            && !this.getActivePanel()) {
                        this.setActivePanel(0);
                    }
                }
            },

            _onHashChange(event) {
                const id = window.location.hash.replace(/#/, '').split('&')[0];
                this.setActivePanelById(id);
            },
        },

        props: {
            rememberActivePanel: {
                type: Boolean,
                default: true,
            },

            // TODO: document this
            useBackground: {
                type: Boolean,
                default: true
            },

            id: {
                type: String,
                default: null,
            },

            sortPanels: Boolean,

            newTabsUi: {
                type: Boolean,
                default: true,
            },

            vertical: Boolean,

            lazy: Boolean,
        },

        vueReady() {
            if (!this.rememberActivePanel) {
                let index = 0;

                if (this.sortPanels
                        && this.sortedPanels
                        && this.sortedPanels.length) {
                    const matchedPanel = this.sortedPanels[0];
                    const matchedIndex = _.findIndex(this.panels,
                        { 'id': matchedPanel.id }
                    );

                    if (matchedIndex !== -1) {
                        index = matchedIndex;
                    }
                }

                this.setActivePanel(index);
                return;
            }

            const id = window.location.hash.replace(/#/, '');

            this.setActivePanelById(id);

            window.addEventListener('hashchange', this._onHashChange);
        },
    });

    export default Panels;
</script>
