var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pa-modal",class:{
        'isActive': _vm.active,
        'pa-modal_hasDrawer': _vm.isDrawer
    },attrs:{"id":_vm.id,"role":"dialog"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.closeable && _vm.close()}}},[_c('div',{ref:"box",staticClass:"pa-modal-box",class:{
            'pa-modal-box_drawer': _vm.isDrawer
        },attrs:{"tabindex":"-1"}},[_c('div',{ref:"body",staticClass:"pa-modal-box-bd"},[_vm._t("default")],2),_vm._v(" "),(_vm.isDrawer)?_c('div',{ref:"knob",staticClass:"pa-modal-box-knob"}):_vm._e(),_vm._v(" "),(_vm.closeable && !_vm.isDrawer)?_c('button',{staticClass:"pa-modal-box-close",attrs:{"type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('svg',{staticClass:"pa-icon pa-icon_block pa-icon_xl",class:_vm.headerIconColor},[_c('use',{attrs:{"xlink:href":"#close"}})])]):_vm._e(),_vm._v(" "),(_vm.isDrawer)?_c('div',{staticClass:"pa-modal-box-menu"},[_c('button',{staticClass:"pa-modal-box-menu-item",style:({
                    width: _vm.titleWidth,
                    marginBottom: _vm.titleWidth
                }),attrs:{"type":"button"},on:{"click":function($event){return _vm.toggle()}}},[_vm._v("\n                "+_vm._s(_vm.title)+"\n            ")]),_vm._v(" "),_c('p-menu',{attrs:{"is-pinned":true,"is-absolute":true}},[_c('button',{staticClass:"pa-menu-hd-btn",attrs:{"slot":"trigger"},slot:"trigger"},[_c('svg',{staticClass:"pa-icon"},[_c('use',{attrs:{"xlink:href":"#plus"}})])]),_vm._v(" "),_vm._t("menu")],2)],1):_vm._e()]),_vm._v(" "),(!_vm.isDrawer)?_c('div',{staticClass:"pa-modal-overlay",on:{"click":function($event){_vm.closeable && _vm.close()}}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }