<template>
    <p-menu
        :max-height="maxHeight"
        :min-height="minHeight"
        :is-sub-menu="true"
        :disabled="disabled">
        <div
            slot="trigger"
            class="pa-menu-hd-btn"
            :disabled="disabled"
            type="button">
            <p-icon
                v-if="icon"
                :icon="icon"
                :color="iconColor"
                class="pa-icon_r"
            >
            </p-icon>
            <span
                class="pa-txt_medium"
                style="font-size: 0.8235294118rem; line-height: 1.2; color:#494c4e;"
            >
                {{ text }}
            </span>
            <span class="pa-menu-hd-btn-icon">
                <svg class="pa-icon pa-icon_lg pa-pt-2">
                    <use xlink:href="#chevron-right"></use>
                </svg>
            </span>
        </div>
        <slot></slot>
    </p-menu>
</template>

<script>
    import Vue from 'vue';

    const menuMixin = require('../mixins/menuMixin');

    const SubMenu = Vue.extend({
        mixins: [menuMixin],

        props: {
            maxHeight: Number,

            minHeight: Number,
        },
    });

    export default SubMenu;
</script>
