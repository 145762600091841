import Dexie from 'dexie';
import Vue from 'vue';
import _ from 'lodash';

const SCHEMA_VERSIONS = [
    [1, {
        Customer: 'id, name, last_config_change',
        Tag: 'id, name, customer_id',
    }],
];

export default Vue.extend({
    name: 'ConfigDBManager',

    render() {
        return [];
    },

    data() {
        return {
            customerId: null,
            lastConfigChange: null,
            config: {
                allTags: [],
            },
            newTags: [],
            tags: [],
            customerChanged: false,
        };
    },

    computed: {
        latestTagId() {
            const latestTag = _.maxBy(this.tags, "id");
            return latestTag ? latestTag.id : null;
        },
    },

    methods: {
        async openDatabase() {
            let newDB = true;

            if (this.db && this.db.isOpen()) {
                this.db.close();
            }

            this.db = new Dexie('Config', {
                addons: [],
                autoOpen: false,
            });

            const exists = await Dexie.exists('Config');
            let outdated = false;
            if (exists) {
                newDB = false;

                await this.db.open();
                const verno = this.db.verno;

                const latest = SCHEMA_VERSIONS.slice(-1)[0];
                if (verno < latest) {
                    console.log(`Infrastructure DB Version ${verno} < ${latest}}. Rebuilding.`);
                    outdated = true;
                }

                this.db.close();
            }

            for (const [index, schema] of SCHEMA_VERSIONS) {
                this.db.version(index).stores(schema);
            }

            await this.db.open();

            if (outdated) {
                await this.truncateDatabase();
                newDB = true;
            }

            this.db.Customer.toArray().then(customers => {
                if (customers.length) {
                    this.customerId = customers[0].id;
                    this.lastConfigChange = customers[0].last_config_change;
                }
            });

            const tags = await this.db.Tag.toArray();

            this.tags = [...tags];
            this.config.allTags = tags.map(tag => tag.name);

            return newDB;
        },

        async truncateDatabase() {
            const promises = [];
            for (const table of this.db.tables) {
                // Dont clear Tag table because we are going to append the new tags
                // to it rather than update the entire table
                if (this.customerChanged || table.name !== "Tag") {
                    promises.push(table.clear());
                }
            }
            return Promise.all(promises);
        },

        async updateConfig() {
            if (this.customerChanged) {
                this.config.allTags = [...this.newTags];
            } else {
                this.config.allTags = [
                    ...this.config.allTags,
                    ...this.newTags,
                ];
            }


            this.$emit('configdb:update', this.config);
        },

        insertChanges(config) {
            this.truncateDatabase().then(() => {
                _.forOwn(config, (value, key) => {
                    try {
                        value.forEach(v => {
                            if (key === "Tag") {
                                this.db[key].add(v);
                                this.newTags.push(v.name);
                            } else {
                                this.db[key].put(v);
                            }
                        });
                    } catch (e) {
                        console.error(`Error inserting a ${key} record: ${e}`);
                    }
                });

                this.updateConfig();
            });
        },

        fetch() {
            $.ajax({
                url: '/config/getCustomerConfig',
                data: {
                    customer_id: this.customerId,
                    last_config_change: this.lastConfigChange,
                    latest_tag_id: this.latestTagId,
                },
            }).done(data => {
                if (data.success) {
                    if (data.config) {
                        this.customerChanged = this.customerId !== data.config.Customer[0].id;
                        this.insertChanges(data.config);
                    }
                } else {
                    console.error(data);
                }
            });
        },
    },

    created() {
        this.openDatabase()
            .then(() => this.updateConfig())
            .then(() => this.fetch())
            .catch(err => {
                console.error(err);
            });

        if (window.app) {
            window.app.configDBManager = this;
        }
    },
});
