const WAIT_TIME = 500;
const SHOW_TIME = 200;
const DIRECTION = 'top';
const HOVER = true;

import Flyout from '../components/Flyout.vue';

const flyoutTrigger = (() => {
    // params: ['direction', 'hover', 'hoverOnClick', 'waitTime', 'hoverTimeout', 'onOpen'],
    const state = new WeakMap();

    function _onHover() {
        if (this.waitTime > 0) {
            this.waitTimer = setTimeout(this._open,this.waitTime);
            this.el.addEventListener('mouseleave', event => {
                clearTimeout(this.waitTimer);
            });
        } else {
            this._open();
        }
    };

    function _onClick() {
        this._open();
    };

    function _onMouseLeave(event) {
        window.app.eventHub.$emit('flyout:maybeClose',this.flyoutId, event);
    };

    function _open(event) {
        if (!this.flyoutId) { return; }
        window.app.eventHub.$emit('flyout:trigger',this.flyoutId,this.el,this.onOpen);
        this.el.addEventListener('mouseleave',this._onMouseLeave);
    };

    return {
        bind(el, binding, vnode) {
            const self = {};
            state.set(el, self);

            self.el = el;
            self._onHover = _onHover.bind(self);
            self._onClick = _onClick.bind(self);
            self._onMouseLeave = _onMouseLeave.bind(self);
            self._open = _open.bind(self);
            self.params = vnode.data.attrs;

            self.flyoutId = binding.value;
            self.direction = self.params.direction || DIRECTION;
            self.hover = self.params.hover || HOVER;
            self.hoverOnClick = self.params['hover-on-click'] || null;
            self.waitTime = self.params['wait-time'] || WAIT_TIME;
            self.hoverTimeout = self.params['hover-timeout'] || SHOW_TIME;
            self.onOpen = self.params['on-open'] || null;

            if (self.hover) {
                self.el.addEventListener('mouseenter', self._onHover);
                if (self.hoverOnClick) {
                    self.el.addEventListener('click', self.hoverOnClick);
                }
            } else {
                self.el.addEventListener('click', self._onClick);
            }
        },

        update(el, binding) {
            const self = state.get(el);
            self.flyoutId = binding.value;
        },

        unbind(el) {
            const self = state.get(el);

            if (self.hover) {
                self.el.removeEventListener('mouseenter', self._onHover);
                if (self.hoverOnClick) {
                    self.el.removeEventListener('click', self.hoverOnClick);
                }
            } else {
                self.el.removeEventListener('click', self._onClick);
            }
        },
    };
})();

export default flyoutTrigger;
