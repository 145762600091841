<template>
    <p-badge v-bind="$attrs" :variant="computedVariant">
        {{ computedText }}
    </p-badge>
</template>

<script>
import Vue from "vue";
import _ from "lodash";

const SEVERITIES = new Set(["critical", "warning"]);

export default Vue.extend({
    props: {
        severity: {
            type: String,
            validator: (severity) => SEVERITIES.has(severity.toLowerCase().trim()),
            default: "",
        },
        uppercase: {
            type: Boolean,
            default: false
        },
        capitalize: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        computedText() {
            if (this.capitalize) {
                return _.capitalize(this.severity);
            }

            if (this.uppercase) {
                return _.toUpper(this.severity);
            }

            return this.severity;
        },
        computedVariant() {
            return this.severity.toLowerCase() === "warning"
                ? "yellow"
                : "red"
        },
    },
});
</script>