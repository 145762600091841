<style lang="scss" scoped>
    .date-icon {
        margin-left: -32px;
    }
</style>


<template>
    <div class="pa-flex" style="align-items: center;">
        <input 
            :id="name"
            :name="name" 
            :class="cssClass" 
            class="pa-input"
            v-if="iDate === '' || iDate === null"
            :value="iDate" 
            v-on:input="changeFromInput($event.target.value)"
            :placeholder="placeholder" 
            :required="required" 
            :autocomplete="autocomplete"/>
        <input 
            :id="name"
            :name="name" 
            :class="cssClass"
            v-else 
            class="pa-input" 
            :value="`${iDate}${tz}`"
            v-on:input="changeFromInput($event.target.value)"
            :placeholder="placeholder" 
            :required="required" 

            :autocomplete="autocomplete"/>
        <span 
            class="date-icon"
        >
            <p-icon
                class=""
                icon="calendar"
                color="blue-500"
            >
            </p-icon>
        </span>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Flatpickr from 'flatpickr';
    import _ from 'lodash';
    import moment from "moment";
    import DatetimeManager from './../utils/datetimeManager';

    function Datepicker(selector, config, l10n) {
        this.l10n = Object.assign({}, Flatpickr.prototype.l10n, l10n);
        return Flatpickr.call(this, selector, config);
    }

    Datepicker.prototype = Flatpickr.prototype;

    const DatetimePicker = Vue.extend({
        data() {
            return {
                iDate: this.date,
                datepicker: null,
                options: {},
            };
        },

        props:{
            date: {
                type: String,
                'default': '',
            },
            enableTime: {
                type: Boolean,
                'default': true,
            },
            cssClass: {
                type: String,
                'default': '',
            },
            name: {
                type: String,
                'default': '',
            },
            dateFormat: {
                type: String,
                'default': 'm/d/Y H:i',
            },
            placeholder: {
                type: String,
                'default': 'Date',
            },
            required: {
                type: Boolean,
                'default': false,
            },
            defaultDate: {
                type: String,
                'default': '',
            },
            noCalendar: {
                type: Boolean,
                'default': false,
            },
            disableFuture: {
                type: Boolean,
                'default': false,
            },
            position: {
                type: String,
                'default': 'auto',
            },
            autocomplete: {
                type: String,
                default: 'off',
            },
            static: Boolean,
            onMonthChange: {
                type: Function,
                default: () => {}
            },
            timezone: {
                type: String,
                default: () => null,
            },
        },

        methods: {
            format_date: function(dateString) {
                const dateObj = this.datepicker.parseDate(dateString, this.dateFormat);
                const dateStr = this.datepicker.formatDate(dateObj, this.dateFormat);
                return { dateObj: dateObj,  dateStr: dateStr };
            },

            changeFromInput: _.debounce(function(value) {
                this.iDate = value;
                this.datepicker.setDate(value, true);
            }, 1500),

            clear() {
                this.datepicker.clear();
            },

        },

        computed: {
            tz() {
                if(this.iDate != '' && this.iDate != null && this.timezone) {
                    return ` ${this.timezone}`;
                }

                return '';
            },
        },

        vueReady() {
            this.options = {
                static: this.static,
                enableTime: this.enableTime,
                noCalendar: this.noCalendar,
                allowInput: true,
                dateFormat: this.dateFormat,
                time_24hr: true,
                position: this.position,
                onChange: (dateObject, dateString) => {
                    this.iDate = dateString;
                    this.$emit('update:date', this.iDate);
                    const event = new Event('change');
                    this.$el.dispatchEvent(event);
                },
                onMonthChange: (dateObject, dateString) => {
                   this.onMonthChange();
                },
                onClose: (dateObject, dateString) => {
                    if (dateString === '') {
                        this.iDate = dateString;
                        this.$emit('update:date', this.iDate);
                        return;
                    }
                    const dates = this.format_date(dateString);
                    dateObject = dates.dateObj;
                    this.iDate = dates.dateStr;
                    this.$emit('update:date', this.iDate);
                    this.datepicker.setDate(dateObject, true);
                    const event = new Event('change');
                    this.$el.dispatchEvent(event);
                },
            };
            if (this.defaultDate) {
                this.options.defaultDate = this.defaultDate;
            }

            if (this.disableFuture) {
                this.options.disable=[
                    function(date) {
                        return (date > moment());

                    }
                ];
            }

            if (!this.datepicker) {
                this.datepicker = new Datepicker(this.$el, this.options, this.l10n);
            }

            DatetimeManager.addInstance(this);
        },
    });
    export default DatetimePicker;
</script>
