<template>
    <p-flex align-center justify-center :style="computedStyle">
        <p-icon
            v-if="$attrs.icon"
            v-bind="$attrs"
            :color="theme == 'outline' ? 'grey-700' : 'white'"
            :size="size"
            :class="theme == 'outline' ? 'outline-icon' : ''">
        </p-icon>
        <div
            v-if="theme == 'outline'"
            class="status-dot"
            :style="`background:${statusColor};`">
        </div>
    </p-flex>
</template>

<script>
    import Vue from 'vue';

    const Icon = Vue.extend({
        props: {
            status: String,
            size: String,
            theme: String,
        },
        computed: {
            statusColor() {
                const statusColors = {
                    "positive": "#008B10",
                    "negative": "#D43527",
                    "caution": "#F3BB2D",
                    "default": "grey",
                };

                return (
                    statusColors[this.status || "default"] ||
                    statusColors.default
                );
            },
            sizeDimension() {
                const sizeDimensions = {
                    "md": [36, 36],
                    "sm": [24, 24],
                    "xsm": [16, 16],
                    "xxsm": [16, 16],
                    "default": [36, 36],
                };

                const [height, width] = (
                    sizeDimensions[this.size || "default"] ||
                    sizeDimensions.default
                );

                return { height: `${height}px`, width: `${width}px` };
            },
            computedStyle() {
                if(this.theme == 'outline') {
                    return {
                        border: '1px solid #d5d8df',
                       'border-radius': '100px',
                        height:'20px',
                        width:'20px',
                        backgroundColor: 'white',
                    };
                } else {
                    return {
                        backgroundColor: this.statusColor,
                        ...this.sizeDimension,
                        borderRadius: "999999px",
                    };
                }
            },
        },
    });

    export default Icon;
</script>

<style lang="scss" scoped>

    .status-dot {
        height: 8px;
        width: 8px;
        border-radius: 999999px;
        position: relative;
        top: 11px;
    }

    .outline-icon {
        position: relative;
        left: 4px;
    }
</style>
