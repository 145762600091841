<style lang="scss">
#instance-performance-header {
	.global-datetime-btn {
        padding: 7px 15px;
        background: #fff;
        border: 1px solid #dcdcdc;
        font-size: 13px;
        line-height: 14.25px;
        &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
        input {
            display: none;
        }
		&+.global-datetime-btn {
			border-left: none;
		}
		&:hover {
			background-color: #2c4163;
			border-color: #2c4163;
			color: white;
        }
        .selected {
            background-color: #2c4163;
            color: #fff;
            border: 1px solid #2c4163
        }
	}
	.global-datetime-btn.selected {
		background-color: #2c4163;
		border-color: #2c4163;
		color: white;
	}
}
@media (max-width: 720px) {
	#instance-performance-header {
		>.pa-grid-col_6of12 {
			width: 100%;
		}
	}
}
</style>

<template>
    <div class="pa-row pa-px-20 pa-py-16">
        <div id="instance-performance-header" class="pa-grid">
            <div class="pa-grid-col pa-grid-col_6of12 pa-justify-center">
                <ul class="graph-controls-list pa-hList pa-hList_x3  pa-grid pa-align-center">
                    <li>
                        <p-select
                            :options="timescaleOptions"
                            :model.sync="localTimescale"
                            class="pa-justify-start"
                        >
                            <p-icon
                                icon="icons-clock-outlined"
                                size="xxxl"
                                class="pa-pr-6"
                                color="grey-700"
                                slot="lead-icon"
                            >
                            </p-icon>
                        </p-select>
                    </li>
                    <li class="pa-flex">
                        <p-tooltip2
                            :prevent-overflow="true"
                            html-class="step-tooltip"
                        >
                            <p-button 
                                class="pa-btn naked_blue pa-mr-4"
                                v-on:click="timeStart" 
                                :disabled="isGraphTimeDisabled || isGraphTimeDisabledRewind"
                                slot="trigger"
                            >
                                <p-icon icon="step-rewind" class="step-button-icon">
                                </p-icon>
                            </p-button>
                            <span style="white-space: nowrap;">Jump to beginning of monitoring</span>
                        </p-tooltip2>
                            <p-tooltip2
                            :prevent-overflow="true"
                            html-class="step-tooltip"
                        >
                            <p-button 
                                class="pa-btn naked_blue pa-mr-4"
                                v-on:click="timeBack"
                                :disabled="isGraphTimeDisabled || isGraphTimeDisabledRewind"
                                slot="trigger"
                            >
                                <p-icon icon="step-backward" class="step-button-icon">
                                </p-icon>
                            </p-button>
                            <span style="white-space: nowrap;">Scroll backwards</span>
                        </p-tooltip2>
                            <p-tooltip2
                            :prevent-overflow="true"
                            html-class="step-tooltip"
                        >
                            <p-button 
                                class="pa-btn naked_blue pa-mr-4"
                                v-on:click="timeForward" 
                                :disabled="isGraphTimeDisabled"
                                slot="trigger"
                            >
                                <p-icon icon="step-forward" class="step-button-icon">
                                </p-icon>
                            </p-button>
                            <span style="white-space: nowrap;">Scroll forwards</span>
                        </p-tooltip2>
                        <p-tooltip2
                            :prevent-overflow="true"
                            html-class="step-tooltip"
                        >
                            <p-button 
                                class="pa-btn naked_blue"
                                v-on:click="timeLatest" 
                                :disabled="isGraphTimeDisabled"
                                slot="trigger"
                            >
                                <p-icon icon="step-fast-forward" class="step-button-icon">
                                </p-icon>
                            </p-button>
                    
                            <span style="white-space: nowrap;">Jump to current time</span>
                        </p-tooltip2>
                    </li>
                    <li v-if="preOutageGraph">
                        <p-button @click="setPreOutageState">
                            Incident Duration
                        </p-button>
                    </li>
                </ul>
            </div>
            <div class="pa-grid-col pa-grid-col_6of12">
                <ul class="graph-controls-list pa-hList pa-hList_x3 pa-justify-end">
                    <li>
                        <p-select
                            size="xs"
                            :options="graphCategoryOptions"
                            :multiple="true"
                            :model.sync="graphFilteredCategories"
                            :searchable="true"
                        >
                        <div slot="display-text">
                            Metrics: {{ selectedMetricsFilter }}                                
                        </div>
                        </p-select>
                    </li>
                    <li class="graph-control-list-item">
                        <p-input
                            placeholder="Search graph metrics"
                            style="height: 32px;"
                            theme="search"
                            @input="val => $emit('update:search-term', val)"
                            :value="localSearhTerm"
                        >
                        </p-input>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    data: function() {
        return {
            localTimescale: this.timescale,
            graphFilteredCategories: [],
            localSearhTerm: this.searchTerm,
        };
    },
    props: {
        timescaleOptions: Array,
        timeStart: Function,
        timeBack: Function,
        timeForward: Function,
        timeLatest: Function,
        isGraphTimeDisabled: Boolean,
        isGraphTimeDisabledRewind: Boolean,
        preOutageGraph: Object,
        setPreOutageState: Function,
        graphCategoryOptions: Array,
        selectedMetricsFilter: String,
        searchTerm: String,
        timescale: String,
    },
    watch: {
        searchTerm(curr, prev) {
            this.localSearhTerm = curr;
        },
        timescale(curr, prev) {
            this.localTimescale = curr;
        },
        localTimescale(curr, prev) {
            this.$emit('update:timescale', curr, prev);
        },
        graphFilteredCategories: {
            handler: function(curr, prev) {
                this.$emit('update:filter-categories', curr, prev);
            },
            deep: true
        },
    },
});
</script>