<style lang="scss">
.pa-page-header {
    --page-header-bottom-spacing: 16px;

    margin-bottom: var(--page-header-bottom-spacing);

    &--no-breadcrumbs {
        background-color: white;
        border: 1px solid #E6E6E6;
        font-size: 28px;
        color: #394449;
    }
}
</style>

<template>
    <section :class="className">
        <p-portal-target name="page-header">
            <h1 v-if="!displayBreadcrumbs">{{ headerText }}</h1>
            <p-breadcrumb v-else :items="breadcrumbs">
            </p-breadcrumb>
            <slot></slot>
        </p-portal-target>
    </section>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    breadcrumbs: Array,
});

const hasBreadcrumbs = computed(() => props.breadcrumbs.length > 0);

const displayBreadcrumbs = computed(() => props.breadcrumbs.length > 1);

const headerText = computed(() => !displayBreadcrumbs.value && hasBreadcrumbs.value ? props.breadcrumbs[0].text : document.title);

const className = computed(() => ([
    "pa-page-header",
    displayBreadcrumbs.value
        ? "pa-page-header--breadcrumbs pa-txt_sm"
        : "pa-page-header--no-breadcrumbs pa-py-12 pa-px-16",
].join(" ")));
</script>