<style lang="scss">
    @import "~styles/core/helpers/_variables.scss";
    
    #monitoring-catalog-sd-wan {
        .pa-card2 {
            border: 0px;
            border-radius: 0px;
        }

        .target-link {
            .pa-icon {
                color:  var(--p-grey-400);
                fill:  var(--p-grey-400);
            }
        }

        .sidebar {
            
            border-right: 1px solid var(--p-grey-200);

            .active {
                @include colorVar(border-left-color, "blue", 500);
                background-color: #CCE5FF;
            }

            li {
                border-left: 2px solid transparent;
                cursor: pointer;
            }
        }

        .zero-state {
            p:not(:last-child) {
                margin-bottom: 0.22rem;
            }
        }

        .targets-sidebar {
            .pa-card-body {
                height: 100%;
                
            }

            nav {
                height: 90%;
            }
        }

        .targets-wrapper {
            max-height: 500px;
            overflow: auto;
        }


        #sd-wan-targets-table {
            table.dataTable {
                border-bottom: none;
            }

            .pa-table {
                thead tr th, 
                tbody tr td {
                    border-left: 0px;
                    border-right: 0px;
                }

                thead tr th {
                    border-top: 0px;
                }
                
                tbody tr:last-child td {
                    border-bottom: none;
                }

                .tags-column {
                    padding-top: 2px;
                    padding-bottom: 2px;
                }

            }
        }
    }
</style>

<template>
    <div class="new-instance-details-theme">
        <div id="monitoring-catalog-sd-wan">
            <p-side-bar
                id="instance-monitor-panel"
                :options="monitorPanelOptions"
                defaultOption="sdWanSynthetics"
                width="15"
            >
                <div slot="rhs-content">
                    <div v-if="currentTab == 'deviceMetrics' && displayMonitoringConfig">
                        <div class="pa-m-12">
                            <p-monitoring-catalog-2
                                :is-server="instance.deviceModel === 'appliance_id' ? false : true"
                                :has-agent="instance.deviceModel === 'appliance_id' ? true : instance.agentInstalled"
                                :from-appliance="instance.deviceModel === 'appliance_id' ? true : !!instance.appliance"
                                :appliance-id="instance.deviceModel === 'appliance_id' ? instance.appliance.id : null"
                                :is-template="instance.deviceModel === 'appliance_id' ? false : instance.isTemplate"
                                :is-container="instance.deviceModel === 'appliance_id' ? false : instance.isContainerType"
                                :network-device-first-scan-done="instance.firstPortScanDone"
                                :start-open="addMonitoring"
                                :all-tags="customer.allTags"
                                :seen-metric-drawer="seenMetricDrawer"
                                :server-id="instance.id"
                                :can-add-metrics="canPerform(instance.deviceType + '.add_metric')"
                                :can-tag-metrics="canEditInstance"
                                :can-pause-metrics="canPerform(instance.deviceType + '.pause_metric')"
                                :can-delete-metrics="canPerform(instance.deviceType + '.delete_metric')"
                                :applications='applications'
                                :is-network-device="instance.isNetworkDevice"
                                :instance-name="instance.formattedName"
                                :operating-system="instance.operatingSystem"
                                :agent-has-dem="instance.agentHasDEM"
                            >
                            </p-monitoring-catalog-2>
                        </div>
                    </div>
                    <div v-if="currentTab == 'sdWanSynthetics'">
                        <div v-if="isZeroState" class="zero-state">
                            <p-row style="padding-top: 100px;">
                                <p-column :cols="3"></p-column>
                                <p-column :cols="6" align-center>
                                    <div
                                        align="center"
                                        class="pa-leading-4"
                                    >
                                        <p class="pa-txt_medium pa-txt_lg">Nothing is being monitored here yet</p>
                                        <p class="pa-txt_xs pa-text-grey-500">
                                            Start by adding basic synthetic checks HTTP, Jitter, Latency and Packet Loss
                                        </p>
                                        <p class="pa-txt_xs pa-txt_medium pa-py-8">
                                            <p-link
                                                :href="`addSdWanTarget?server_id=${instance.id}`"
                                            >
                                                Add Synthetic Monitoring
                                            </p-link>
                                        </p>

                                    </div>
                                </p-column>
                                <p-column :cols="3"></p-column>
                            </p-row>
                            <p></p>
                        </div>

                        <div v-else>
                            <p-side-bar
                                id="targets"
                                :menu-items="['Remove']"
                                :options="sdWanApplicationOptions"
                                width="18"
                            >
                                <p class="pa-txt_xs pa-txt_medium" slot="above-nav">
                                    <ul class="pa-split">
                                        <li class="pa-ml-16">
                                            <span class="pa-txt_bold">Target</span>
                                        </li>
                                        <li class="pa-mr-8">
                                            <p-link
                                                :href="`addSdWanTarget?server_id=${instance.id}`"
                                            >
                                                <span class="pa-pl-8">+ Add</span>
                                            </p-link>
                                        </li>
                                    </ul>
                                    
                                </p>
                                <div slot="rhs-content">
                                    <template v-if="currentApplicationData">
                                        <div class="pa-m-8 pa-my-4">
                                            <ul class="pa-split pa-ml-8" v-if="showTableControls">
                                                <li>
                                                    <div class="pa-flex pa-pb-16 pa-pt-4">
                                                        <span class="pa-txt_bold pa-txt_sm">
                                                            {{ currentApplicationData.name }}
                                                        </span>
                                                        <p-link 
                                                            :href="`/application/${currentApplicationData.id}/overview`"
                                                            :is-external="true"
                                                            class="pa-pl-6 target-link"
                                                        >
                                                            <p-icon 
                                                                color="grey-800"
                                                                icon="external-link"
                                                                size="lg"></p-icon>
                                                        </p-link>
                                                    </div>
                                                    
                                                </li>
                                                <li class="pa-flex" v-if="!isApplicationZeroState">
                                                    <p-select
                                                        size="xs"
                                                        :options="metricFilterOptions"
                                                        :multiple="true"
                                                        :model.sync="selectedMetricFilter"
                                                        :searchable="true"
                                                        class="pa-mr-8"
                                                    >
                                                        <div slot="display-text">
                                                            Metrics: {{ selectedMetricFilterLabel }}                                
                                                        </div>
                                                    </p-select>
                                                    <p-custom-table-search
                                                        style="max-width: 220px;"
                                                        :broadcast="true"
                                                    >
                                                    </p-custom-table-search>
                                                </li>
                                            </ul>
                                        </div>
                                        <p-divider style="margin: 0px;"></p-divider>
                                        <div v-if="isApplicationZeroState">
                                            <p-row style="padding-top: 100px;">
                                                <p-column :cols="3"></p-column>
                                                <p-column :cols="6" align-center>
                                                    <div
                                                        align="center"
                                                        class="pa-leading-4"
                                                    >
                                                        <p class="pa-txt_medium pa-txt_lg">Nothing is being monitored here yet</p>
                                                        <p class="pa-txt_xs pa-text-grey-500">
                                                            Start monitoring by completing the setup on the target application.
                                                        </p>
                                                        <p class="pa-txt_xs pa-txt_medium pa-py-8">
                                                            <p-link
                                                                :href="`/application/${currentApplicationData.id}/overview`"
                                                            >
                                                                Go to {{ currentApplicationData.name }}
                                                            </p-link>
                                                        </p>

                                                    </div>
                                                </p-column>
                                                <p-column :cols="3"></p-column>
                                            </p-row>
                                        
                                        </div>
                                        <div class="targets-wrapper pa-my-10" v-else>
                                            <div v-for="target in currentApplicationData.targets">

                                                <div class="pa-px-12 pa-mb-16 pa-flex">
                                                    <p-label-divider style="width: 100%" :label="target.name"></p-label-divider>
                                                    <div
                                                        style="white-space: nowrap;"
                                                        class="pa-flex"
                                                    >   
                                                        <p-tooltip2
                                                            :hover="false"
                                                            :click-only="true"
                                                        >
                                                            <p-link slot="trigger" :add-pointer="true">
                                                                <p-icon
                                                                    icon="dots-vertical"
                                                                    color="blue"
                                                                    size="xl"
                                                                    middle
                                                                    class="pa-mr-2"
                                                                />
                                                            </p-link>
                                                            <span>
                                                                <p>These checks can be edited only from target instances.</p>
                                                                 <p-link
                                                                    :href="`/application/${currentApplicationData.id}/monitoring/checks`"
                                                                >
                                                                    Go to {{ currentApplicationData.name }}/checks
                                                                </p-link>
                                                            </span>
                                                        </p-tooltip2>
                                                    </div>
                                                </div>

                                                <p-table2
                                                    source="server"
                                                    :filtering="true"
                                                    :hide-default-search="true"
                                                    paging-type="simple"
                                                    :length-change="false"
                                                    v-if="showTable"
                                                    :ref="`sdWanTargetsTable-${target.id}`"
                                                    :column-renderers="{
                                                        2: 'vue',
                                                        3: 'tags',
                                                    }"
                                                    :column-classes="{3: 'tags-column'}"
                                                    id="sd-wan-targets-table"
                                                    :data-url="sdwanTableDataUrl(target.id, instance.id)"
                                                    class="pa-px-8"
                                                >
                                                    <table slot="table" class="pa-table pa-table_outage">
                                                        <thead>
                                                            <tr>
                                                                <th>METRIC</th>
                                                                <th>FREQUENCY</th>
                                                                <th style="min-width: 40%;">ALERT</th>
                                                                <th>TAGS</th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </p-table2>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </p-side-bar>
                        </div>
                    </div>
                </div>
            </p-side-bar>
            <p-editmetric-drawer ref="edit-metric-drawer"></p-editmetric-drawer>
        </div> 
    </div>
</template>

<script>
    import Vue from 'vue';
    import BulkMetricActions from './components/BulkMetricActions.vue'
    import EditMetricDrawer from './EditMetricDrawer.vue';

    const MonitoringCatalogSdWan = Vue.extend({
        components: {
            'p-bulk-metric-actions': BulkMetricActions,
            'p-editmetric-drawer': EditMetricDrawer,
        },

        props: {
            instance: Object,
            allTags: Array,
            displayMonitoringConfig: Boolean,
            addMonitoring: Boolean,
            customer: Object,
            seenMetricDrawer: Boolean,
            applications: Object
        },

        data() {
            return {
                currentTab: "sdWanSynthetics",
                currentApplication: null,
                showTable: true,
                selectedMetricFilter: [],
                showTableControls: null,
                showCheckboxes: true,
                monitorPanelOptions :[
                    {value:"deviceMetrics", label:"Device Metrics"},
                    {value:"sdWanSynthetics", label:"SD-WAN Synthetics"},
                ]
                
            };
        }, 

        events: {
            'finished-bulk-action': function() {
                this.$refs.bulkMetricActions.setChecked([])
                this.refreshTable();
            },

            'side-bar:updated': function(option, sideBarId) {
                if(sideBarId == 'instance-monitor-panel') {
                    this.currentTab = option;
                }

                if(sideBarId == 'targets') {
                    this.currentApplication = option;
                }
            },

            'side-bar:menu': function(menuItem, option) {
                this.$root.$broadcast('prompt:load', {
                    id: 'dynamic_prompt',
                    title: 'Remove Application',
                    body: 'Are you sure you want to remove the application?',
                    callback: () => { window.app.rootVue.$broadcast('remove-application', option.value); },
                })

            },

            'remove-application': function(optionId) {
                let toastMsg = 'Removed application succesfully';

                $.ajax({
                    url: '/application/removeLocations',
                    data: {
                        application_id: optionId,
                        location_ids: [`wan-${this.instance.id}`]
                    },
                    context: this,
                    method: 'POST'
                }).done((response) => {
                    if (response.success) {
                        window.app.toastManager.showToast({
                            text: toastMsg,
                            icon: 'check'
                        });
                    } else {
                        window.app.toastManager.showToast({
                            text: 'Error removing the location',
                            type: 'error',
                            icon: 'alert-circle'
                        });
                    }
                    window.app.rootVue.$broadcast('sdwan-application:refresh');
                    window.app.rootVue.$broadcast('prompt:close', 'dynamic_prompt');
                });
            },

            'custom-search-changed': function(searchVal) {
                for(let target of this.currentApplicationData.targets) {
                    this.$refs[`sdWanTargetsTable-${target.id}`][0].search(searchVal);
                }
            },

            'sdwan-application:refresh': function() {
                $.ajax({
                    url: `/report/get_sdwan_page_data?instance_id=${this.instance.id}`,
                    context: this,
                    method: 'GET',
                }).done(response => {
                    if (response.success) {
                        this.instance['isSdWan'] = response.instance_data.isSdWan;
                        this.instance['sdWanApplications'] = response.instance_data.sdWanApplications;
                        this.instance['wanConnections'] = response.instance_data.wanConnections;
                        this.currentApplication = this.instance['sdWanApplications'][0].id
                    }
                });
            },
        },

        computed: {
            sdWanApplicationOptions: function() {
                let options = [];
                for(const target of this.instance.sdWanApplications) {
                    options.push({value: target.id, label: target.name});
                }

                return options;
            },

            isZeroState: function() {
                if(this.instance.sdWanApplications && this.instance.sdWanApplications.length > 0) {
                    return false;
                }

                return true;
            },

            isApplicationZeroState: function() {
                if(this.instance.sdWanApplications && 
                    this.instance.sdWanApplications.length > 0 && 
                    this.currentApplicationData.targets.length > 0) {
                    
                    return false;
                }

                return true;
            },

            currentApplicationData: function() {
                let apps = this.instance.sdWanApplications;
                for(let app of apps) {
                    if( this.currentApplication == app.id) {
                        return app;
                    }
                }
            },

            selectedMetricFilterLabel: function() {
                if(this.selectedMetricFilter.length == 0) {
                    return 'All';
                } else if (this.selectedMetricFilter.length > 2) {
                    return `${this.selectedMetricFilter.length} Selected`
                } else {
                    let selectedMetricsNames = []
                    for(let i of this.selectedMetricFilter) {
                        for(let j of this.metricFilterOptions) {
                            if(j.value == i) {
                                selectedMetricsNames.push(j.label);
                            }
                        }
                    }
                    return selectedMetricsNames.join(', ');
                }
            },

            canEditInstance: function() {
                return this.canPerform(this.instance.deviceType + '.edit');
            },

            metricFilterOptions: function(){
                let options = [
                    {label:"HTTP", value:"HTTP"},
                    {label:"Latency", value:"icmp.latency"},
                    {label:"Packet Loss", value:"icmp.packet_loss"},
                    {label:"Jitter", value:"icmp.jitter"},
                ];

                if (this.currentApplicationData.has_ssl_metric) {
                    options[0].label = 'HTTPS'
                    options[0].value = 'HTTPS'
                }

                return options
            },
        },

        watch: {
            currentApplication: function(oldVal, newVal) {
                this.refreshTable();
            },

            selectedMetricFilter: function(oldVal, newVal) {
                for(let target of this.currentApplicationData.targets) {
                    let payload = {
                        server_id: target.id,
                        wan_device_id: this.instance.id,
                        metrics: newVal,
                    };
                    let ref = `sdWanTargetsTable-${target.id}`;
                    this.$refs[ref][0].retrieveTableDataExternal(payload);
                }
            },
        },

        methods: {
            refreshTable() {
                this.showTable = false;
                this.showTableControls = false;
                Vue.nextTick(() => {
                    this.showTable = true;
                    Vue.nextTick(() => {
                        this.showTableControls = true;
                    });
                });
            },
            
            canPerform: function(rule) {
                return this.instance.canPerform.includes(rule);
            },

            sdwanTableDataUrl: function(target_id, instance_id) {
                let url = `/report/getSdWanTableData?server_id=${target_id}&wan_device_id=${instance_id}`;

                for(const metric of this.selectedMetricFilter) {
                    url = `${url}&metrics=${metric}`
                }

                return url;
            }
        },

        vueReady() {
            if(this.instance.sdWanTargets && this.instance.sdWanTargets.length > 0) {
                this.currentApplication = this.instance.sdWanTargets[0].id
            }
        },
    });

    export default MonitoringCatalogSdWan;
</script>
