<style lang="scss">
    .tree-wrapper {
        overflow-y: auto;
        max-height: 100%;
    }

    .custom-module {
        & + & {
            & > .module-body {
                & > .pa-grid {
                    padding-top: 0 !important;
                }
            }
        }
    }
    .custom-module-tree {
        .tree-search {
            padding: 0;
        }
        .tree-wrapper {
            overflow-y: auto;
            max-height: 100%;
            border: 1px solid #d5d8df;
            border-radius: 2px;
            padding: 10px 20px;
        }
    }
</style>

<template>
    <div class="module custom-module">
        <div v-if="!options.hideHeader" class="module-header">
            <span class="pa-txt">Advanced Options</span>
            <p-divider></p-divider>
        </div>
        <div ref="body" v-show="ready && open" transition="module-expand" class="module-body pa-py-0">
            <div v-if="options['ignore-scope-module']" class="pa-field pa-field_vertical pa-pt-24">
                <div class="pa-field-hd">
                    <label class="pa-label">Dashboard Scoping Adherence</label>
                </div>
                <div class="pa-field-bd">
                    <label class="pa-option">
                        <input type="checkbox" class="pa-option-input" v-model="ignore_dashboard_scope" @change="checkboxClick" />
                        <span class="pa-option-icon"></span>
                        <span class="pa-option-txt">Ignore dashboard scoping filters</span>
                    </label>
                </div>
                <div class="pa-field-ft">
                </div>
            </div>
            <div v-if="options['timerange-module']" class="pa-field pa-field_vertical pa-pt-24">
                <div class="pa-field-hd">
                    <label for="template" class="pa-label">Time Range</label>
                </div>
                <div class="pa-field-bd">
                    <p-select
                        ref="select"
                        :model.sync="time"
                        :on-change-callback="timerangeChanged"
                        :options="time_options"
                    >
                    </p-select>
                </div>
                <div class="pa-field-ft">
                </div>
            </div>
            <p-row
                v-if="options['formatting-module'] || graphOptionsCheckboxes.length"
                class="pa-pt-24"
            >
                <p-column v-if="options['formatting-module']" cols="6">
                    <span class="pa-label pa-pb-10">Widget Formatting</span>
                    <ul class="pa-vList">
                        <li :key="opt.value" v-for="opt in serverFormatOptions">
                            <label class="pa-option" style="width:100%;">
                                <input type="checkbox" class="pa-option-input" :value="opt.value" v-model="widgetFormatting[opt.value]"/>
                                <span class="pa-option-icon"></span>
                                <span class="pa-option-txt">{{ opt.label }}</span>
                            </label>
                        </li>
                        <li :key="opt.value" v-for="(opt, index) in metricFormatOptions" :class="{ 'pa-pt-8': index === 0 }">
                            <label class="pa-option" style="width:100%;">
                                <input type="checkbox" class="pa-option-input" :value="opt.value" v-model="widgetFormatting[opt.value]"/>
                                <span class="pa-option-icon"></span>
                                <span class="pa-option-txt">{{ opt.label }}</span>
                            </label>
                        </li>
                        <li :key="opt.value" v-for="(opt, index) in otherFormatOptions" :class="{ 'pa-pt-8': index === 0 }">
                            <label class="pa-option" style="width:100%;">
                                <input type="checkbox" class="pa-option-input" :value="opt.value" v-model="widgetFormatting[opt.value]"/>
                                <span class="pa-option-icon"></span>
                                <span class="pa-option-txt">{{ opt.label }}</span>
                            </label>
                        </li>
                    </ul>
                </p-column>
                <p-column cols="6" v-if="graphOptionsCheckboxes.length">
                    <span class="pa-label pa-pb-10">{{ options.title }}</span>
                    <ul class="pa-vList">
                        <li :key="control.id" :id="control.id" v-for="control in graphOptionsCheckboxes">
                            <label v-if="control.type == 'p-switch'" class="pa-option" v-show="visibles[control.key]">
                                <input type="checkbox" class="pa-option-input" v-model.sync="models[control.key]" @change="checkboxClick"/>
                                <span class="pa-option-icon"></span>
                                <span class="pa-option-txt">{{ control.label }}</span>
                            </label>
                        </li>
                    </ul>
                </p-column>
            </p-row>
            <p-row v-if="nonGraphOptionsControls.length" class="pa-m-0 pa-pt-24">
                <ul class="pa-vList" style="width: 100%;">
                    <li :key="control.id" :id="control.id" v-for="control in nonGraphOptionsControls">
                        <div
                            class="pa-field pa-field_vertical pa-pb-24"
                            v-show="visibles[control.key]"
                        >
                            <div class="pa-field-hd">
                                <label class="pa-label">{{ control.label }}</label>
                            </div>
                            <div class="pa-field-bd">
                                <label v-if="control.type == 'p-switch'" class="pa-option">
                                    <input type="checkbox" class="pa-option-input" v-model.sync="models[control.key]" @change="checkboxClick"/>
                                    <span class="pa-option-icon"></span>
                                    <span class="pa-option-txt">{{ control.label }}</span>
                                </label>
                                <template v-if="control && control.type == 'p-radio-group'">
                                    <template v-for="option in control.options">
                                        <label :key="option.value" :for="control.key + '_' + option.value" class="pa-option pa-mr-16">
                                            <input
                                                :id="control.key + '_' + option.value"
                                                type="radio"
                                                class="pa-option-input"
                                                :value="option.value"
                                                v-model="models[control.key]"
                                                :checked="models[control.key] === option.value"
                                                @change="onRadioChange(control.key, option.value)"
                                            />
                                            <span class="pa-option-icon pa-option-icon_radio"></span>
                                            <span class="pa-option-txt">
                                                {{ option.label }}
                                            </span>
                                        </label>
                                    </template>
                                </template>
                                <input v-if="control.type == 'input'"
                                            type="text"
                                            class="pa-input"
                                            :value="models[control.key]"
                                            @input="update(control.key, $event.target.value)"
                                            @keyup="debounceSendConfig" />
                                <p-select v-if="control.type == 'p-select' && control.optionsFromWidget"
                                        :model.sync="models[control.key]"
                                        :multiple="control.multiple"
                                        :options="editingWidget[control.optionsFromWidget]"
                                        :all-option="control.all_option"
                                        :on-change-callback="sendConfig">
                                </p-select>
                                <p-select v-if="control.type == 'p-select' && !control.optionsFromWidget"
                                        :model.sync="models[control.key]"
                                        :multiple="control.multiple"
                                        :options="control.options"
                                        :all-option="control.all_option"
                                        :on-change-callback="sendConfig">
                                </p-select>
                                <div v-if="control.type == 'p-tree'">
                                    <p-tree 
                                            :model.sync="tree_models"
                                            :preselected.sync="preselects"
                                            :url="control.url"
                                            :deferred="true"
                                            :select_nodes="true"
                                            :select-callback="validateAndSend"
                                            :multiselect="control.multiple"
                                            :show-search="true"
                                            :include-link="false"
                                            class="custom-module-tree"
                                            css_class="tree-wrapper"
                                    ></p-tree>
                                </div>
                                <p-color-picker
                                    v-if="control.type === 'color-picker'"
                                    :value="models[control.key]"
                                    @input="debouncedHandleColorPickerChange(control.key, $event.target.value)"
                                >
                                </p-color-picker>
                            </div>
                            <div class="pa-field-ft">
                                <p v-if="control.hint" class="pa-hint">
                                    {{ control.hint }}
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </p-row>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';

    const CustomModule = Vue.extend({
        events: {
        },

        data() {
            return {
                ready: false,
                models: {},
                preselects: [],
                tree_models: [],
                visibles: {},
                format_options: [
                    {value: "hide_server_name", label: "Show Server Name", group: "server"},
                    {value: "hide_server_fqdn", label: "Show Server FQDN", group: "server"},
                    {value: "hide_metric_type", label: "Show Metric Type", group: "metric"},
                    {value: "hide_option_name", label: "Show Option Name", group: "metric"},
                    {value: "hide_units", label: "Show Units", group: "metric"},
                    {value: "float_legend_right", label: "Float Legend Right", group: "other"},
                ],
                widgetFormatting: {
                    "hide_server_name": false,
                    "hide_units": false,
                    "hide_server_fqdn": false,
                    "hide_metric_type": false,
                    "hide_option_name": false,
                    "float_legend_right": false,
                },
                time_options: [
                    { value: '15min', label: 'Last 15 Minutes' },
                    { value: 'hour', label: 'Last Hour' },
                    { value: '6hr', label: 'Last 6 Hours'},
                    { value: '12hr', label: 'Last 12 Hours'},
                    { value: 'day', label: 'Last 24 Hours' },
                    { value: 'week', label: 'Last Week' },
                    { value: 'month', label: 'Last Month' },
                    { value: 'year', label: 'Last Year' },
                ],
                time: 'hour',
                graphOptionsKeys: ["pointLabels", "crosshairs"],
                ignore_dashboard_scope: false,
            }
        },

        props: {
            hiddenOptions: Array,
            options: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            open: {
                type: Boolean,
                default: true
            },
            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        },

        events: {
            'customControl:hideControl': function(key) {
                Vue.set(this.visibles, key, false);
            },

            'customControl:showControl': function(key) {
                Vue.set(this.visibles, key, true);
            }
        },

        computed: {
            graphOptionsCheckboxes() {
                return this.options.controls.filter(
                    option => option.type === "p-switch" && this.graphOptionsKeys.includes(option.key)
                );
            },
            nonGraphOptionsControls() {
                return this.options.controls.filter(
                    option => !this.graphOptionsKeys.includes(option.key) || option.type !== "p-switch"
                );
            },
            filteredFormatOptions: function() {
                if (!this.hiddenOptions) {
                    return this.format_options;
                }

                return this.format_options.filter(
                    option => !this.hiddenOptions.includes(option.value)
                );
            },
            serverFormatOptions: function() {
                return this.filteredFormatOptions.filter(
                    option => option.group === "server"
                );
            },
            metricFormatOptions: function() {
                return this.filteredFormatOptions.filter(
                    option => option.group === "metric"
                );
            },
            otherFormatOptions: function() {
                return this.filteredFormatOptions.filter(
                    option => option.group === "other"
                );
            },
        },

        methods: {
            debounceSendConfig: _.debounce(function () {
                this.sendConfig();
            }, 1000),
            debouncedHandleColorPickerChange: _.debounce(function(key, value) {
                this.handleColorPickerChange(key, value);
            }, 250),
            handleColorPickerChange(key, value) {
                this.update(key, value);
                this.$nextTick(() => {
                    this.sendConfig();
                });
            },
            onRadioChange(key, value) {
                this.update(key, value);
                this.$nextTick(() => {
                    this.sendConfig();
                });
            },
            update(key, value) {
                this.models = {
                    ...this.models,
                    [key]: value
                };
                this.$emit('input', this.models);
            },

            timerangeChanged(model) {
                this.changeAggregation(model);
                this.sendTimeRangeConfig();
            },

            changeAggregation(model) {
                this.$parent.$broadcast('customControl:showControl', 'aggregation');
            },

            toggle() {
                this.open = !this.open;
            },

            checkboxClick() {
                Vue.nextTick(function() { this.sendConfig() }.bind(this));
            },

            sendFormattingConfig() {
                this.$parent.updatePending(this.editingWidget.id);

                if (!this.ready) { return; }

                const format_options = [];

                for (let index = 0; index < Object.keys(this.widgetFormatting).length; index++) {
                    const option = Object.keys(this.widgetFormatting)[index];
                    const value = this.widgetFormatting[option];

                    if (option.includes("hide") && !value) {
                        format_options.push(option);
                    } else if (!option.includes("hide") && value) {
                        format_options.push(option);
                    }
                }

                const payload = {
                    widget_id: this.editingWidget.id,
                    format_options
                };

                $.ajax({
                    url: '/dashboardv2/setWidgetFormatting',
                    method: 'POST',
                    data: payload,
                })
                .done(data => {
                    if (data.success && data.newConfig) {
                        this.editingWidget.config = data.newConfig;
                    }
                });

                this.editingWidget.pendNewConfig();
            },

            sendTimeRangeConfig() {
                this.$parent.updatePending(this.editingWidget.id);

                const payload = {
                    widget_id: this.editingWidget.id,
                    timescale: this.time
                };

                $.ajax({
                    url: '/dashboardv2/setWidgetTimescale',
                    method: 'POST',
                    data: payload,
                })
                .done(data => {
                    if (data.success && data.newConfig) {
                        this.editingWidget.config = data.newConfig;
                    }
                });

                // The HTML widget block has no data that gets updated when
                // the config is updated so it does not get a WidgetQueue entry
                // to trigger this to stop loading.
                if (this.editingWidget.localConfig.widget_type !== "html_block") {
                    this.editingWidget.pendNewConfig();
                }
            },

            sendConfig() {
                if(!this.ready) { return; }

                this.$parent.updatePending(this.editingWidget.id);

                var controls = {};

                if (this.options['ignore-scope-module']) {
                    controls.ignore_dashboard_scope = this.ignore_dashboard_scope;
                }

                for(var i = 0; i < this.options.controls.length; i++) {
                    var c = this.options.controls[i];
                    if(c.type === 'p-select') {
                        controls[c.key] = this.models[c.key];
                    } else if(c.type === 'p-switch') {
                        controls[c.key] = this.models[c.key];
                    } else if(c.type === 'p-tree') {
                        controls[c.key] = this.tree_models;
                    } else if(c.type === 'input') {
                        if(c.default && this.models[c.key] === "") {
                            controls[c.key] = c.default;
                        } else {
                            controls[c.key] = this.models[c.key];
                        }
                    } else if (c.type === 'p-radio-group') {
                        controls[c.key] = this.models[c.key];
                    } else if (c.type === 'color-picker') {
                        controls[c.key] = this.models[c.key];
                    }
                }

                const payload = {
                    widget_id: this.editingWidget.id,
                    controls: JSON.stringify(controls),
                };

                $.ajax({
                    url: '/dashboardv2/setWidgetCustomControls',
                    method: 'POST',
                    data: payload,
                })
                    .done(data => {
                        if (data.success && data.newConfig) {
                            this.editingWidget.localConfig = {...data.newConfig};

                            if (_.has(this.editingWidget, 'toggleControls')) {
                                this.editingWidget.toggleControls();
                            }
                        }
                    });
                if (this.editingWidget.localConfig.widget_type !== "html_block") {
                    this.editingWidget.pendNewConfig();
                }
                this.toggleOptions();
            },

            toggleOptions() {
                for (var i = 0; i < this.options.controls.length; i++) {
                    const c = this.options.controls[i];
                    const visible_options = {
                        'group_series': 'aggregation',
                        'should_limit_items': 'item_limit_count',
                    };

                    if (visible_options[c.key]) {
                        if (this.models[c.key]) {
                            this.$broadcast('customControl:showControl', visible_options[c.key]);
                        } else {
                            this.$broadcast('customControl:hideControl', visible_options[c.key]);
                        }
                    }
                }
            },

            validateTree() {
                if(_.isEmpty(this.tree_models)){
                    return false;
                }
                else if(!this.tree_models[0].startsWith('sr') && !this.tree_models[0].startsWith('mp')){
                    return false;
                }
                else {
                    return true;
                }
            },

            validateAndSend() {
                if (this.$parent.$parent.$parent.validate()) {
                    this.sendConfig();
                }
            },

            updateChosen() {
                var preset = this.editingWidget.config.format_options;
                if (preset === null || typeof preset === 'undefined') {
                    preset = [];
                }

                const widgetFormatting = {};

                for (let index = 0; index < Object.keys(this.widgetFormatting).length; index++) {
                    const option = Object.keys(this.widgetFormatting)[index];

                    if (option.includes("hide")) {
                        widgetFormatting[option] = !preset.includes(option);
                    } else {
                        widgetFormatting[option] = preset.includes(option);
                    }
                }

                this.widgetFormatting = {...widgetFormatting};
            },
        },

        created() {
            const timerangeModule = this.options["timerange-module"];

            if (timerangeModule) {
                let defaultTimeValue = 'hour';

                if (
                    timerangeModule.options && timerangeModule.options.defaultValue
                ) {
                    defaultTimeValue = timerangeModule.options.defaultValue;
                }

                let timescalePreset = this.editingWidget.config.timescale;

                if (timerangeModule.options && timerangeModule.options.time_options) {
                    this.time_options = timerangeModule.options.time_options
                }

                if (timerangeModule.options && timerangeModule.options.liveOption) {
                    this.time_options.unshift({ value: 'live', label: 'Latest Value' });

                    defaultTimeValue = timerangeModule.options.defaultTimeValue || 'live';

                    if (typeof timescalePreset === 'undefined' || timescalePreset === null) {
                        timescalePreset = defaultTimeValue
                    }
                } else if (typeof timescalePreset === 'undefined' || timescalePreset === null) {
                    timescalePreset = defaultTimeValue;
                }

                this.time = timescalePreset;
            }

            if (this.options['ignore-scope-module']) {
                this.ignore_dashboard_scope = this.editingWidget.config.custom_controls.ignore_dashboard_scope;
            }

            this.updateChosen();
            this.$watch('widgetFormatting', this.sendFormattingConfig, { deep: true });
            this.$once('dashboard:new_config', this.updateChosen);

            var preset = this.editingWidget.localConfig.custom_controls;

            if (typeof(preset) === "string") {
                preset = JSON.parse(preset);
            }

            if (typeof(preset) === "undefined") {
                preset = {};
            }

            for(var i = 0; i < this.options.controls.length; i++) {
                var c = this.options.controls[i];
                var isVisible = _.has(c, 'visible') ? c.visible : true;
                if(c.type === 'p-select') {
                    if(c.key in preset) {
                        this.models[c.key] = preset[c.key];
                    } else if(c.default) {
                        this.models[c.key] = c.default;
                    } else if(c.multiple) {
                        this.models[c.key] = [];
                    } else {
                        this.models[c.key] = "";
                    }
                    Vue.set(this.visibles, c.key, isVisible);
                } else if(c.type === 'p-switch') {
                    if(c.key in preset) {
                        this.models[c.key] = preset[c.key];
                    } else if(c.default) {
                        this.models[c.key] = true;
                    } else {
                        this.models[c.key] = false;
                    }
                    Vue.set(this.visibles, c.key, isVisible);
                } else if(c.type === 'input') {
                    if(c.key in preset) {
                        this.models[c.key] = preset[c.key];
                    } else if(c.default) {
                        this.models[c.key] = c.default;
                    } else {
                        this.models[c.key] = "";
                    }
                    Vue.set(this.visibles, c.key, isVisible);
                } else if(c.type === 'p-tree') {
                    this.models[c.key] = null;
                    this.open = true;
                    if(c.key in preset) {
                        this.preselects = [...preset[c.key]];
                    }
                    this.tree_models = this.preselects.slice();
                    this.$parent.addValidator(this.validateTree);
                    Vue.set(this.visibles, c.key, isVisible);
                } else if (c.type === 'p-radio-group') {
                    if(c.key in preset) {
                        this.models[c.key] = preset[c.key];
                    } else if(c.default) {
                        this.models[c.key] = c.default;
                    } else {
                        this.models[c.key] = "";
                    }
                    Vue.set(this.visibles, c.key, isVisible);
                } else if (c.type === 'color-picker') {
                    if(c.key in preset) {
                        this.models[c.key] = preset[c.key];
                    } else if(c.default) {
                        this.models[c.key] = c.default;
                    } else {
                        this.models[c.key] = "";
                    }
                    Vue.set(this.visibles, c.key, isVisible);
                }
            }
            this.ready = true;
            this.toggleOptions();
            for (var i = 0; i < this.nonGraphOptionsControls.length; i++) {
                var control = this.nonGraphOptionsControls[i];
                this.$watch('models.' + control.key, function(newVal, oldVal) {
                    console.log(newVal);
                    console.log(oldVal);
                }, {
                    deep: true
                })
            }
        }
    });

    export default CustomModule;
</script>
