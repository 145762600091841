<style lang="scss">
    @import "~styles/core/helpers/_variables.scss";
    
    .pa-rowcard .pa-menu-box {
        z-index: 90;
    }

    .maintenance-name { 
        font-size: 14px;
    }

    .maintenance-sub {  
        font-size: 12px;
    }

    .editmetric-drawer {
        margin-top: 0;
    }

</style>

<template>
<div>
    <div style="display: flex; margin-bottom: 10px;">
        <div style="align-self: flex-end;">
            <h2 class="pa-hdg pa-hdg_3 pa-vr report" style="margin-bottom: 5px;">Maintenance</h2>
        </div>
        <div style="margin-left: 10px;">
          <p-menu>
            <template v-if="pageData.can_maintenance_add">
            <button slot="trigger" class="pa-btn pa-btn_sm naked_blue" style="margin-left: 5px;">
                Add Maintenance
            </button>

            <a @click="addMaintenance(min)" v-for="min in [5, 15, 30, 60]">
                Immediate: {{ min }} min
            </a>

            <button @click="loadSchedule(null, $event)">
                <i class="fa fa-pushR fa-gears"></i> Custom
            </button>
            </template>
          </p-menu>
        </div>
    </div>

    <div class="pa-vList ">
    <div v-if="loading" class="pa-section">
        <p class="pa-txt">Loading...</p>
    </div>
    <div v-if="schedules.length === 0 && !loading" class="pa-section">
        <p>No maintenance scheduled.</p>
    </div>
    <div class="pa-rowcard" v-bind:class="{'pa-rowcard_green': ms.running}" v-for="ms in schedules">
        <div class="pa-rowcard-hd" style="position: relative">
            <div style="position: absolute; right: 5px; top: 14px;">
                <p-material-menu>
                    <button type="button" slot="trigger" class="pa-btn pa-btn_naked pa-btn_narrow">
                        <span class="pa-icon pa-icon_dots"></span>
                    </button>
                    <button @click="loadSchedule(ms.id, $event)">
                        <svg class="pa-icon"><use xlink:href="#pencil"></use></svg> Edit
                    </button>
                    <a v-p-prompt-open
                       v-for="min in minutes"
                       target="dynamic_prompt"
                       title="Extend Maintenance?"
                       @click="setScheduleAndMinutes(ms.id, min)"
                       v-bind:callback="extendMaintenance"
                       v-bind:body="getExtendMaintenanceBody(min)">
                        <svg class="pa-icon"><use xlink:href="#clock-start"></use></svg>
                        {{ getExtendedMaintenanceDescription(min) }}
                    </a>
                    <a v-p-prompt-open
                       v-if="ms.total_affected_count == 1"
                       target="dynamic_prompt"
                       @click="setSchedule(ms.id)"
                       v-bind:callback="terminateSchedule"
                       title="End Maintenance?"
                       body="This is the only resource affected by this maintenance schedule. Are you sure you want to end this maintenance schedule?">
                        <svg class="pa-icon"><use xlink:href="#traffic-light"></use></svg>
                        End Maintenance
                    </a>
                    <a v-p-prompt-open
                       v-if="!ms.inherited && ms.total_affected_count != 1 && ms.impact_type != 'tags'"
                       target="dynamic_prompt"
                       title="Remove from schedule?"
                       v-bind:callback="removeMaintenance"
                       v-bind:args="ms.id"
                       v-bind:body="getRemoveFromBody(ms.id)">
                        <svg class="pa-icon"><use xlink:href="#delete"></use></svg>
                        Remove from Schedule
                    </a>
                </p-material-menu>
            </div>
            <ul class="pa-vList">
                <li>
                    <div class="pa-rowcard-primary-text">
                        <span v-if="ms.inherited">
                            <a href="#" @click="loadSchedule(ms.id, $event)" class="pa-link maintenance-name">{{ms.name}}</a>
                            <p-tooltip :hover="true" :hover-timeout="300">
                                <span slot="trigger" class="pa-badge pa-badge_info" style="margin-left: 5px;">Inherited</span>
                                <span>
                                Inherited from
                                <a 
                                    :href="'../report/ServerGroupReport?server_group_id=' + ms.inherited.id" 
                                    target="_blank"
                                >
                                    {{ms.inherited.name}}
                                </a>
                                </span>
                            </p-tooltip>
                        </span>
                        <span v-else>
                            <a href="#" @click="loadSchedule(ms.id, $event)" class="pa-link maintenance-name">{{ms.name}}</a>
                        </span>
                        <span v-if="ms.extended_duration > 0" class="pa-badge pa-badge_info" style="margin-left: 5px">Extended</span>
                        <i class="fa fa-pushR fa-refresh" v-if="ms.recurring"></i>
                    </div>
                </li>
                <li>
                    <p class="pa-rowcard-secondary-text">
                        <span v-if="ms.running">
                            <span v-if="ms.expired">
                                Expired
                            </span>
                            <span v-else>
                                <span v-if="ms.extended_duration > 0" class="maintenance-sub">
                                Ends @ {{ ms.end_time }}, originally @ {{ ms.original_end_time }} | {{ ms.relative_end_time }} remaining | <a href="#" @click="loadPeriodImpacted(ms.id, $event)" class="pa-link">{{ ms.affected['num_servers'] }} {{ ms.affected['num_servers'] > 1 && 'Instances' || 'Instance' }}</a>
                                | {{ ms.affected['num_compound_services'] }} {{ ms.affected['num_compound_services'] > 1 && 'Compound Services' || 'Compound Service' }} | {{ ms.affected['num_server_groups'] }} {{ 'num_server_groups' in ms.affected && 'Server Groups' || '0 Server Groups'}}
                                | {{ ms.affected['num_server_resource_thresholds'] }} {{ 'num_server_resource_thresholds' in ms.affected &&  'Server Resource Thresholds' || '0 Server Resource Thresholds' }}
                                </span>
                                <span v-else class="maintenance-sub">
                                Ends @ {{ ms.end_time }} | {{ ms.relative_end_time }} remaining | <a href="#" @click="loadPeriodImpacted(ms.id, $event)" class="pa-link">{{ ms.affected['num_servers'] }} {{ ms.affected['num_servers'] > 1 && 'Instances' || 'Instance' }}</a>
                                | {{ ms.affected['num_compound_services'] }} {{ ms.affected['num_compound_services'] > 1 && 'Compound Services' || 'Compound Service' }} | {{ ms.affected['num_server_groups'] }} {{ 'num_server_groups' in ms.affected && 'Server Groups' || '0 Server Groups'}}
                                | {{ ms.affected['num_server_resource_thresholds'] }} {{ 'num_server_resource_thresholds' in ms.affected &&  'Server Resource Thresholds' || '0 Server Resource Thresholds'}}
                                </span>
                            </span>
                        </span>
                        <span v-else>
                            Starts @ {{ ms.next_start_time }} | <a href="#" @click="loadImpacted(ms.id, $event)" class="pa-link">{{ ms.affected['num_servers'] }} {{ ms.affected['num_servers'] > 1 && 'Instances' || 'Instance' }}</a>
                            | {{ ms.affected['num_compound_services'] }} {{ ms.affected['num_compound_services'] > 1 && 'Compound Services' || 'Compound Service' }} | {{ ms.affected['num_server_groups'] }} {{ 'num_server_groups' in ms.affected && 'Server Groups' || '0 Server Groups' }}
                            | {{ ms.affected['num_server_resource_thresholds'] }} {{ 'num_server_resource_thresholds' in ms.affected &&  'Server Resource Thresholds' || '0 Server Resource Thresholds' }}
                        </span>
                    </p>
                </li>
            </ul>
        </div>
    </div>
    </div>
</div>
</template>

<script>
import Vue from "vue";
import _ from 'lodash';

export default Vue.extend({
    props: {
        deviceId: Number,
        deviceType: String,
        pageData: Object,
    },
    
    data: () => ({
        schedules: [],
        minutes: [5, 10, 15, 60, 60*6, 60*12],
        loading: false,
        selectedScheduleId: null,
        selectedMinutes: null,
    }),
    events: {
        'load-schedule': function(msg) {
            if (window.event) {
                window.event.preventDefault();
            }
            var schedule_id = msg.schedule_id;
            this.loadSchedule(schedule_id);
        }
    },

    methods: {
        loadPeriodImpacted(schedule_id, event) {
            window.app.rootVue.$broadcast('drawer:load', {
                id: 'dynamic-drawer',
                url: '/config/MaintenancePeriodImpact?maintenance_schedule_id=' + schedule_id,
            })
        },

        setScheduleAndMinutes(schedule_id, minutes) {
            this.selectedScheduleId = schedule_id;
            this.selectedMinutes = minutes;
        },

        setSchedule(schedule_id) {
            this.selectedScheduleId = schedule_id;
        },

        loadImpacted(schedule_id, event){
            window.app.rootVue.$broadcast('drawer:load', {
                id: 'dynamic-drawer',
                url: '/config/MaintenanceImpact?maintenance_schedule_id=' + schedule_id,
            })
        },

        getRemoveFromBody: function () {
            return "Are you sure you want to remove this " + this.deviceType + " from this maintenance schedule?";
        },

        getExtendMaintenanceBody: function (minutes) {
            if (minutes > 59) {
                return "Extend this maintenance by " + minutes / 60 + " hours?";
            } else {
                return "Extend this maintenance by " + minutes + " minutes?";
            }

        },

        getExtendedMaintenanceDescription: function(minutes) {
            if (minutes > 59) {
                return "Extend " + minutes / 60 + " hour" + (minutes / 60 === 1 ? "": "s");
            } else {
                return "Extend " + minutes + " minutes";
            }
        },

        addMaintenance: function (minutes) {
            var deviceType = this.deviceType.toLocaleLowerCase() + '_id';
            var data = {};
            data[deviceType] = this.deviceId;
            data['mins'] = minutes;
            $.ajax({
                url: '/config/immediateMaintenance',
                context: this,
                method: 'POST',
                data: data,
            }).done(data => {
                this.$root.$broadcast('prompt: close');
                if (data.success) {
                    this.getMaintenances();
                    window.app.toastManager.showToast({ text: 'Added immediate maintenance', icon: 'check' });
                } else {
                    window.app.toastManager.showToast({ text: 'Error adding maintenance', type: 'error', icon: 'alert-circle' });
                }
            })
        },

        terminateSchedule: function () {
            $.ajax({
                url: '/config/terminateMaintenanceSchedule',
                method: 'POST',
                context: this,
                data: { maintenance_schedule_id: this.selectedScheduleId, no_redirect: true }
            }).done(data => {
                this.$root.$broadcast('prompt:close');
                if (data.success) {
                    this.getMaintenances();
                    window.app.toastManager.showToast({ text: 'Maintenance terminated successfully', icon: 'check' });
                } else {
                    window.app.toastManager.showToast({ text: 'Error terminating maintenance', type: 'error', icon: 'alert-circle' });
                }
            })
        },

        extendMaintenance: function () {
            $.ajax({
                url: '/config/extendMaintenanceSchedule',
                method: 'POST',
                context: this,
                data: {
                    maintenance_schedule_id: this.selectedScheduleId,
                    minutes: this.selectedMinutes
                }
            }).done(data => {
                this.$root.$broadcast('prompt:close');
                if (data.success) {
                    this.getMaintenances();
                    window.app.toastManager.showToast({ text: 'Maintenance extended successfully', icon: 'check' });
                } else {
                    window.app.toastManager.showToast({ text: 'Error terminating maintenance', type: 'error', icon: 'alert-circle' });
                }
            });
        },

        removeMaintenance: function (event) {
            event.preventDefault();
            var schedule_id = event.data;
            let data = { maintenance_schedule_id: schedule_id};
            data[this.deviceType.toLocaleLowerCase() + '_id'] = this.deviceId;
            $.ajax({
                url: "/config/remove" + this.deviceType + "FromMaintenanceSchedule",
                method: "POST",
                context: this,
                data: data
            }).done(function(data) {
                this.$root.$broadcast('prompt:close');
                if (data.success) {
                    this.getMaintenances();
                    window.app.toastManager.showToast({ text: this.deviceType + ' removed from maintenance', icon: 'check' });
                } else {
                    window.app.toastManager.showToast({ text: 'Error removing ' + this.deviceType + ' from maintenance', type: 'error', icon: 'alert-circle' });
                }
            });
        },

        getMaintenances: function () {
            this.loading = true;
            $.ajax({
                url: '/config/getMaintenances',
                method: 'GET',
                context: this,
                data: { device_id: this.deviceId, device_type: this.deviceType.toLocaleLowerCase() }
            }).done(data => {
                this.loading = false;
                if (data.success) {
                    this.schedules = data.schedules;
                } else {
                    console.log(data.message);
                }
            })
        },

        editCallback: function () {
            this.getMaintenances();
        },

        loadSchedule: function (schedule_id, event) {
            if (event) {
                event.preventDefault();
            };
            var url;
            var element_id = '';
            if(this.deviceType == 'serverGroup') {
                element_id = `grp-${this.deviceId}`;
            }

            if (schedule_id) {
                url = '/config/EditMaintenanceSchedule?maintenance_schedule_id=' + schedule_id;
            } else {
                if (element_id.startsWith('grp')) {
                    url = '/config/EditMaintenanceSchedule?server_groups=' + element_id + '&impact_type=server_groups';
                } else{
                    url = '/config/EditMaintenanceSchedule?server_ids=' + element_id;
                }
            }
            window.app.rootVue.$broadcast('drawer:load', {
                id: 'dynamic-drawer',
                url: url
            })
        },
    },
    mounted() {
        this.getMaintenances();
    },
});
</script>
