<template>
    <div>
        <div v-if="payloadType == 'json'">
            <p-field-group label="JSON Payload" class="pa-pt-32">
                <div class="pa-tabs pa-mb-8">
                    <div class="pa-tab"
                         :class="{ isActive: selectedPayload === 'outage' }"
                         @click="selectedPayload = 'outage'">
                        Incident Creation
                    </div>
                    <div class="pa-tab"
                        v-if="hasClearPayload"
                         :class="{ isActive: selectedPayload === 'clear' }"
                         @click="selectedPayload = 'clear'">
                        Incident Closure
                    </div>
                </div>
                <textarea v-model="currentPayload" style="width: 100%;" :rows="rows" />
                <div v-if="jsonError" class="pa-mt-8">
                    <p class="pa-txt pa-txt_sm" style="color: #e31212;">Warning: Invalid JSON payload:</p>
                    <p class="pa-txt pa-txt_sm" style="color: #e31212;">{{ jsonError }}</p>
                </div>
            </p-field-group>
            <p-field-group label="Payload Example">
                <textarea
                    v-model="examplePayload"
                    readonly
                    style="width: 100%; word-break: break-all;"
                    :rows="rows" />
            </p-field-group>
        </div>
        <div v-if="payloadType == 'raw'">
            <textarea v-model="webhook.raw_payload" style="width: 100%;" rows="8"></textarea>
            <p-field-group label="Payload Example">
                <textarea
                    v-model="examplePayload"
                    readonly
                    style="width: 100%; word-break: break-all;"
                    :rows="rows" />
            </p-field-group>
        </div>
    </div>              
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';
    
    export default Vue.extend({
        props: {
            outagePayload: String,
            clearPayload: String,
            webhook: Object,
            exampleEvent: Object,
            hasClearPayload: {
                type: Boolean,
                default: true,
            },
            rows: {
                type: String,
                default: '8'
            },
            payloadType: {
                type: String,
                default: 'json'
            }
        },

        data() {
            return {
                selectedPayload: 'outage',
                iOutagePayload: this.outagePayload,
                iClearPayload: this.clearPayload,
            };
        },

        computed: {
            currentPayload: {
                get() {
                    if(this.payloadType == 'raw') {
                        return this.webhook.raw_payload;
                    }
                    else if (this.selectedPayload === 'outage') {
                        return this.iOutagePayload;
                    } else {
                        return this.iClearPayload;
                    }
                },

                set(newVal) {
                    if(this.payloadType == 'raw') {
                        this.$set(this, 'webhook.raw_payload', newVal);
                    }
                    else if (this.selectedPayload === 'outage') {
                        this.$set(this, 'iOutagePayload', newVal);
                    } else {
                        this.$set(this, 'iClearPayload', newVal);
                    }
                },
            },

            jsonError() {
                if (this.selectedPayload === 'outage') {
                    if (!this.iOutagePayload || !this.iOutagePayload) {
                        return '';
                    }
                    try {
                        JSON.parse(this.iOutagePayload);
                    } catch (e) {
                        return e.message;
                    }
                } else {
                    if (!this.iClearPayload || !this.iClearPayload) {
                        return '';
                    }
                    try {
                        JSON.parse(this.iClearPayload);
                    } catch (e) {
                        return e.message;
                    }
                }
               
                return '';
            },


            examplePayload() {
                if (!this.exampleEvent) {
                    return '';
                }
                let payload = this.currentPayload;
                if(payload) {
                    for (const key in this.exampleEvent) {
                        if (!this.exampleEvent.hasOwnProperty(key)) {
                            continue;
                        }
                        const re = new RegExp(`\\$${key}`, 'g');
                        const val = this.exampleEvent[key];
                        payload = payload.replace(re, val);
                    }
                    payload = payload.replace(/\$trigger/, this.selectedPayload);
                    payload = payload.replace(/\$event/, `${this.selectedPayload} event`);
                    payload = payload.replace(/\$timestamp/, moment.utc().format('YYYY-MM-DD HH:mm:ss UTC'));
                }
                
                return payload;
            },
        },
        watch: {
            iOutagePayload() {
                this.$emit('update:outagePayload', this.iOutagePayload);
            },

            iClearPayload() {
                this.$emit('update:clearPayload', this.iClearPayload);
            },

            iRawPayload() {
                this.$emit('update:webhook', this.webhook);
            },

            hasClearPayload() {
                this.selectedPayload = 'outage';
                this.iClearPayload = "";
            }
        },
    });
</script>
