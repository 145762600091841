<template>
    <button
        :type="type"
        :disabled="disabled || loading"
        :class="computedClass"
        @click="handleClick($event)"
    >
        <p-loading-spinner v-if="loading" size="sm"></p-loading-spinner>
        <slot v-else>Button</slot>
    </button>
</template>

<script>
import Vue from "vue";

const VARIANTS = [
    "",
    "secondary",
    "secondary_inverse",
    "light",
    "cancel",
    "info",
    "naked",
    "naked_blue",
    "white",
    "blue",
    "link-blue-300",
    "blue-secondary",
    "link",
    "link-purple-300",
    "dataPoint",
    "border-dark-grey",
    "blue-500",
    "ghost",
    "outline",
    "outline-border",
    "dot-menu",
    "step-menu",
    "grey",
];

const COLORS = [
    "",
    "blue",
];

const TYPES = [
    "button",
    "submit",
    "reset",
];

const BASE_CLASSNAME = "pa-btn";

export default Vue.extend({
    props: {
        variant: {
            type: String,
            validator: (variant) => isValidOption(variant, VARIANTS),
            default: "",
        },
        color: {
            type: String,
            validator: (color) => isValidOption(color, COLORS),
            default: "",
        },
        type: {
            type: String,
            default: "button",
            validator: (type) => isValidOption(type, TYPES),
        },
        disabled: Boolean,
        unstyled: Boolean,
        noBaseClass: Boolean,
        loading: Boolean,
        fullWidth: Boolean,
        noDisabledClass: Boolean,
        narrow: Boolean,
    },
    computed: {
        computedClass() {
            const classNames = {
                [BASE_CLASSNAME]: !this.noBaseClass,
                [`${BASE_CLASSNAME}--unstyled`]: this.unstyled,
                disabled: (this.disabled || this.loading) && !this.noDisabledClass,
                [`${BASE_CLASSNAME}_full`]: this.fullWidth,
                [`${BASE_CLASSNAME}_narrow`]: this.narrow,
            };

            if (this.variant) {
                const BASE_VARIANT_CLASSNAME = `${BASE_CLASSNAME}_${this.variant}`;

                if ((this.variant === "ghost" || this.variant === "outline") && this.color) {
                    classNames[`${BASE_VARIANT_CLASSNAME}-${this.color}`] = true;
                } else {
                    classNames[BASE_VARIANT_CLASSNAME] = true;
                }
            }

            return classNames;
        },
    },
    methods: {
        handleClick(event) {
            this.$emit('click', event);
        },
    },
});

const isValidOption = (option, options) => {
    const isValid = options.indexOf(option) !== -1;

    if (!isValid) {
        console.error(
            "'" +
            option +
            "' is not a valid option. Available options: " +
            options.join(", ")
        );
    }

    return isValid;
};
</script>
