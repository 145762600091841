<style>
.fortinet .pa-loader {
    color: #DA291C;
    fill: #DA291C;
}

.incident_section { 
    display : inline;
    margin: 0px 0px 0px 70px;
    vertical-align: top; 
    height:100px !important; 
    background-color: '#ffffff';
}
.list_inc_details {
    display:none; 
    height:auto;
    margin-left:3px;
    float: left;
}
.show_inc_details {
    display: none; 
}
.hide_inc_details:target + .show_inc_details {
    display: inline; 
    float:right;
}
.hide_inc_details:target {
    display: none; 
}
.hide_inc_details:target ~ .list_inc_details {
    display:inline; 
}

.hide_inc_details, .show_inc_details {
	width: 2px;
	height: 2px;
	border-radius: 0px;
	font-size: 20px;
	color: blue;
	text-shadow: 0 1px 0 #666;
	text-align: center;
	text-decoration: none;		
	opacity: 1;
	margin-right: 10px;
	float: left;
	margin-bottom: 25px;
}

.list_inc_details p{
    height:auto;
    margin:0;
}

.inc_info {
	float: left;
	height: auto;
	width: 90%;
	line-height: 20px;
	font-style: inherit;
}

</style>

<template>
    <div :style="{ width: width, 'min-height': `${minHeight}px` }">
        <template v-if="loadingSpinner">
            <div v-show="loading && !graph_data_loaded" class="pa-loader" style="position: absolute; left: 50%;">Loading...</div>
        </template>
        <template v-else>
            <span v-show="loading" class="loading-text" style="margin-bottom: 0;">Loading...</span>
        </template>
        <div ref="container" class="pa-graph" :style="{ height: height }" :id="`p-graph-${_uid}`" v-once></div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';
    import moment from 'moment-timezone';

    var Highcharts = require('highcharts');
    Highcharts.seriesTypes.line.prototype.drawLegendSymbol = Highcharts.seriesTypes.column.prototype.drawLegendSymbol;
    Highcharts.hasTouch = false;
    require('highcharts/modules/exporting')(Highcharts);
    require('highcharts/modules/no-data-to-display')(Highcharts);

    import HighchartsConfigs from '../../lib/highcharts-configs';

    const MAX_DATA_TRIES = 3;

const GraphComponent = Vue.extend({

    data() {
        return {
            graph_data_loaded: false,
            loading: true,
            series: [],
            iTimescale: this.timescale,
            iDate_range: this.date_range,
            iPreOutageGraph: this.preOutageGraph,
            zoomed: false,
            prezoom_extreme: 0,
            tries: 0,
            debouncedScroll: null,
            debouncedHashChange: null,
            hashChangeDebounceWait: 300,
            iOffset: this.offset,
            retryTimeout: 10000,
            shouldStopPolling: false,
            selectedGraphUnit: null,
            isMountingGraph: false,
            isStale: false,
            visible: false,
            observer: null,
            isScrollSetup: false,
            incident_count: 0,
            needsRefresh: false,
        }
    },
    props: {
        id: {
            type: String,
            default: ""
        },

        monitors: {
            type: Array,
            default: function() { return []; }
        },

        timescale: {
            type: String,
            default: "day"
        },

        offset: {
            type: Number,
            default: 0
        },

        server_id: {
            type: Number,
            default: 0
        },

        date_range: {
            type: String,
            default: ""
        },

        autoLoad: {
            type: Boolean,
            default: true
        },

        crosshairs: {
            type: Boolean,
            default: false
        },

        exporting: {
            type: Boolean,
            default: false,
        },

        hideGraphOnNoData: {
            type: Boolean,
            default: false,
        },

        width: {
            type: String,
            default: '100%',
        },

        minHeight: {
            type: Number,
            default: 300,
        },

        height: {
            type: String,
            default: '300px',
        },

        syncCrosshairs: {
            type: Boolean,
            'default': true,
        },

        syncTooltips: {
            type: Boolean,
            'default': false,
        },

        loadTimeout: {
            type: Number,
            'default': 0,
        },

        networkPort: {
            type: Boolean,
            default: false
        },

        backgroundColor: {
            type: String,
            default: 'white'
        },

        resize: {
            type: Boolean,
            default: true,
        },

        loadingSpinner: {
            type: Boolean,
            default: true,
        },

        useRootTimescale: {
            type: Boolean,
            'default': true,
        },

        displayYAxisTitle: {
            type: Boolean,
            default: true,
        },

        debounceWait: {
            type: Number,
            default: 300,
        },

        debounceOffset: {
            type: Number,
            default: 900,
        },

        highchartsConfig: Object,
        highchartsPreset: String,

        loadOnScroll: Boolean,
        shortLegend: Boolean,
        includeDeviceName: Boolean,
        maxLegendHeight: {
            type: Number,
            default: undefined,
        },
        latestValue: null,
        batchLoads: Boolean,
        opposing: Boolean,
        polling: {
            type: Boolean,
            default: false,
        },
        unit: String,
        // chart: Object,
        showLegend: {
            type: Boolean,
            default: true,
        },
        alignLegend: {
            type: String,
            default: 'center',
        },
        setLegendItemWidth: {
            type: Boolean,
            default: true,
        },
        shouldConnectNulls: {
            type: Boolean,
            default: false
        },
        maxValue: Number,
        preOutageGraph: {
            type: Boolean,
            default: false,
        },
        locationLegend: {
            type: Boolean,
            default: false
        },
        ignoreTimescaleEvent: {
            type: Boolean,
            default: false
        },
        graphType: {
            type: String,
            default: null
        },
        containerName: {
            type: String,
            default: null,
        },
        extraData: {
            type: String,
            default: null,
        },
        dataUrl: {
            type:String,
            default: null,
        },
        showIncidentInfo: {
            type: Boolean,
            default: true,
        },
    },

    mounted() {
        this.setupObserver();
        // Event handlers to catch the "load-graph-data" and "load-initial-graph-data" events.
        // The latter will only kick off a request for graph data a single time on a page.
        let autoLoad = this.autoLoad
        if (this.loadOnScroll) {
            autoLoad = false;
        }
        if (autoLoad) {
            this.fetch_graph_data();
            this.isMountingGraph = true;
        }
        this.eventHub.$on('load-graph-data', function(msg) {
            this.fetch_graph_data();
            this.isMountingGraph = true;
        });

        this.eventHub.$on('load-initial-graph-data', function(msg) {
            if (!this.graph_data_loaded) {
                this.fetch_graph_data();
                this.isMountingGraph = true;
            }
        });
        // Dispatch message to inform container of it's existence
        this.eventHub.$emit('graph:ready', this);
    },


    beforeDestroy() {
        window.removeEventListener('scroll', this.debouncedScroll);
        window.removeEventListener('hashchange', this.debouncedHashChange);

        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    },

    watch: {
        'monitors': function(val, oldVal) {
            if (!_.isEqual(val, oldVal)) {
                this.graph_data_loaded = false;
                this.fetch_graph_data();
            }
        },
        loading(isLoading, wasLoading) {
            this.$emit('loading', isLoading, wasLoading);
        },
            },

    methods: {
        changeUnit(unit) {
            this.selectedGraphUnit = unit;

            this.$nextTick(() => {
                this.fetch_graph_data();
            });
        },

        onHashChange: function() {
            this.fitWidth();
        },

        handleScroll: function() {
            if (this.visible && (!this.graph_data_loaded || this.needsRefresh)) {
                this.fetch_graph_data();
            }

        },

        setupObserver() {
            const observer = new IntersectionObserver(this.onObserved, {
                threshold: .25,
            });

            observer.observe(this.$el);

            this.observer = observer;
        },
        onObserved(entries) {
            entries.forEach(({ isIntersecting }) => {
                this.visible = isIntersecting;
                if(!this.isScrollSetup) {
                    this.setupScroll();
                    this.isScrollSetup == true;
                }
            });
        },

        setupScroll() {
            this.debouncedScroll = _.debounce(this.handleScroll, this.debounceWait);
            window.addEventListener('scroll', this.debouncedScroll);
            this.debouncedHashChange = _.debounce(this.onHashChange, this.hashChangeDebounceWait);
            window.addEventListener('hashchange', this.debouncedHashChange);
            this.handleScroll();
        },

        disable_loader: function() {
            this.loading = false;
        },

        change_timescale: function(timescale) {
            this.needsRefresh = true;
            this.iTimescale = timescale;
            this.fetch_graph_data();
        },

        change_date_range: function(date_range) {
            this.iDate_range = date_range;
        },

        change_preoutage: function(value) {
            this.iPreOutageGraph = value;
        },

        showErrorText(msg) {
            if (!this.chart) {
                return;
            }
            this.chart.hideNoData();
            if(!msg) {
                msg = 'There was an error fetching the graph data';
            }
            this.chart.showNoData(msg);
        },

        _fetch_graph_data: function(shouldRenderGraph) {
            //considering any graph without a container as
            //orphaned. These could be graphs that were filtered.
            if(typeof this.$els.container == 'undefined') {
                this.shouldStopPolling = true;
                return;
            }
            if (this.shouldStopPolling || (this.polling && !this.visible)) {
                return;
            }

            this.needsRefresh = false;
            this.loading = true;
            this.$emit('update:latestValue', null);
            // Make an Ajax call to get the data needed to render the graph
            // Fix for mismatch timescale between the graph and root
            if (this.$root && this.$root.timescale && this.$root.timescale !== this.timescale) {
                this.iTimescale = this.$root.timescale;
            }
            const data = {
                timescale: this.iTimescale,
                monitors: this.monitors,
                offset: this.iOffset,
                server_id: this.server_id,
                date_range: this.iDate_range,
                short_legend: this.shortLegend,
                include_device_name: this.includeDeviceName,
                network_port: this.networkPort,
                opposing: this.opposing,
                pre_outage_graph: this.iPreOutageGraph,
                location_legend: this.locationLegend,
                selected_graph_unit: this.selectedGraphUnit,
                graph_type: this.graphType,
                extra_data: this.extraData,
            };
            
            if (this.batchLoads) {
                window.app.graphManager.fetchData(data, graphData => {
                    this.loading = false;
                    this.render_graph(graphData);
                });
                return;
            }
            let successCallback = this.render_graph;

            if (
                this.polling &&
                this.graph_data_loaded &&
                !shouldRenderGraph
            ) {
                successCallback = this.updateGraphPoints;
            }

            let url = '/report/graph_data';

            if(this.graphType == "traceRouteEdge") {
                url = this.dataUrl;
            }
            $.ajax({
                url: url,
                data: data,
                success: successCallback,
            })
                .done((data) => {
                    this.loading = false;
                    this.retryTimeout = 10000;
                    this.isMountingGraph = false;  
                    this.incident_count = data.incident_count;                  
                    
                    if ((
                            typeof data !== 'object' ||
                            ("success" in data && !data.success)
                        )
                    ) {
                        this.shouldStopPolling = true;
                        this.showErrorText(data.msg);
                    }
                })
                .fail((response) => {
                    console.log('Failed fetching graph data');
                    if ((response.status === 0 || response.status === 403)) {
                        this.shouldStopPolling = true;
                        this.showErrorText(data.msg);
                    } else if (response.status !== 403) {
                        if (this.tries < MAX_DATA_TRIES) {
                            this.tries += 1;
                            setTimeout(() => {
                                this._fetch_graph_data();
                            }, 800);
                        } else {
                            console.log('Initial data fetch tries exhausted');

                            // Switch to exponential backoff
                            setTimeout(() => {
                                this._fetch_graph_data();
                            }, this.retryTimeout);
                            this.retryTimeout *= 2;

                            if (window.app.Sentry) {
                                window.app.Sentry.captureMessage(`All ${MAX_DATA_TRIES} attempts at graph_data failed`, {
                                    extra: data,
                                    fingerprint: 'graph-fail',
                                    tags: {
                                        url: window.location.href,
                                    },
                                });
                            }
                        }
                    }
                });
        },

        updateGraphPoints: function(data) {
            const _series = this.getSeriesFromData(data);
            // Update the parent unit if it differs from graph
            if (this.$parent && this.$parent.graphUnit != data.units && data.units) {
                this.$parent.graphUnit = data.units;
            }

            const redraw = false;
            const animation = false;
            const updatePoints = true;
            const tooltipIsHidden = this.chart ? this.chart.tooltip.isHidden : false

            for (let i = 0; i < _series.length; i++) {
                if (_.isNull(_.last(_series[i].data))) {
                    _series[i].data.pop();
                }

                if (this.selectedGraphUnit && this.selectedGraphUnit === "%") {
                    _series[i].data = [..._series[i].data].map(
                        ([timestamp, value]) => ([
                            timestamp,
                            Boolean(value)
                                ? (value / this.maxValue) * 100
                                : value
                        ])
                    );
                }

                if (this.chart.series[i]) {
                    this.chart.series[i].setData(
                        _series[i].data,
                        redraw,
                        animation,
                        updatePoints
                    );
                }
            }


            this.series = [..._series];
            //this will make sure the background color stays consistant
            //with the latest data from the server.
            this.chart.xAxis[0].update({plotBands: this.getupdatedZones(data.zones)}, false);
            this.chart.redraw(true);
            if(tooltipIsHidden) {
                this.chart.tooltip.hide(5000);
            }
        },

        fetch_graph_data: function(shouldRenderGraph) {
            if(this.isMountingGraph || this.isStale) {
                return;
            }
            this.tries = 0;
            if (this.loadTimeout > 0) {
                window.setTimeout(() => {
                    this._fetch_graph_data(shouldRenderGraph);
                }, this.loadTimeout * 1000);
                return;
            }
            this._fetch_graph_data(shouldRenderGraph);
        },

        addPoints: function(points) {
            if (!this.chart || !this.chart.series ||  !this.chart.series.length) {
                return;
            }
            for (const point of points) {
                let time = point[0];
                const val = point[1];
                if (time < 1000000000000) {
                    time *= 1000;
                }
                this.chart.series[0].addPoint([time, val], true, true, false);
            }
        },

        increment_offset: function(delta, fetch) {
            const shouldRenderGraph = true;
            this.iOffset += delta;
            if(fetch !== false) {
                this.needsRefresh = true;
                this.fetch_graph_data(shouldRenderGraph);
            }
        },

        decrement_offset: function(delta, fetch) {
            const shouldRenderGraph = true;
            this.iOffset -= delta;
            if(fetch !== false) {
                this.needsRefresh = true;
                this.fetch_graph_data(shouldRenderGraph);
            }
        },

        set_offset: function(offset, fetch) {
            const shouldRenderGraph = true;
            this.iOffset = offset;
            if(fetch !== false) {
                this.needsRefresh = true;
                this.fetch_graph_data(shouldRenderGraph);
            }
        },

        zoomIn(event) {
            event.preventDefault();
            const utcstart = (event.xAxis[0].min / 1000);
            const utcend = (event.xAxis[0].max/ 1000);

            let url = '/util/getGraphZoomData';

            const payload = {
                point_ids: this.monitors,
                start_time: utcstart,
                end_time: utcend,
                network_port: this.networkPort,
                opposing: this.opposing,
            };

            if(this.graphType == "traceRouteEdge") {
                url = this.dataUrl;
                payload["zoom"] = true;
            }

            $.ajax(url, {
                method: 'GET',
                data: payload,
                context: this,
            })
                .done(function(data) {
                    if (!data.success) {
                        if (!data.nodata) {
                            console.log(data.msg);
                        }
                        return;
                    }
                    // Do 2 renders here to make it flow right: one to change the series, one to change the bounds
                    const yMax = this.selectedGraphUnit === "%" ? 100 : this.chart.yAxis[0].max;
                    const yMin = this.selectedGraphUnit === "%" ? 0 : this.chart.yAxis[0].min;

                    this.chart.xAxis[0].setExtremes(this.chart.xAxis[0].min, this.chart.xAxis[0].max, false, false);
                    this.chart.yAxis[0].setExtremes(yMin, yMax, false, false);
                    for (var i = 0; i < data.series.length; i++) {
                        let chartData = [];

                        if (this.selectedGraphUnit && this.selectedGraphUnit === "%") {
                            chartData = [...data.series[i].data].map(
                                ([timestamp, value]) => ([
                                    timestamp,
                                    Boolean(value)
                                        ? (value / this.maxValue) * 100
                                        : value
                                ])
                            );
                        } else {
                            chartData = [...data.series[i].data];
                        }

                        if (this.chart.series[i]) {
                            this.chart.series[i].setData(chartData, false);
                        }
                    }
                    this.chart.redraw(true);

                    // If an opposing or network port graph, force positive and negative scales to be the same
                    if (this.opposing || this.networkPort) {
                        if(this.selectedGraphUnit == '%') {
                            this.chart.yAxis[0].setExtremes(-100, 100);
                        } else {
                            var dExt;
                            var ext = this.chart.yAxis[0].getExtremes();
                            var dMax = Math.abs(ext.dataMax);
                            var dMin = Math.abs(ext.dataMin);
                            dMax >= dMin ? dExt = dMax : dExt = dMin;
                            var min = 0 - dExt;
                            this.chart.yAxis[0].setExtremes(min, dExt);
                        }
                        this.chart.xAxis[0].setExtremes(data.xmin, data.xmax, false, false);
                    } else {
                        const _yMax = this.selectedGraphUnit === "%" ? 100 : data.max;
                        const _yMin = this.selectedGraphUnit === "%" ? 0 : data.min;
                        this.chart.xAxis[0].setExtremes(data.xmin, data.xmax, false, false);
                        this.chart.yAxis[0].setExtremes(_yMin, _yMax, false, false);
                    }
                    this.chart.redraw(true);
                    if (!this.zoomed) {
                        this.chart.showResetZoom();
                        this.zoomed = true;
                    }
                });
        },

        getSeriesFromData: function(data) {
            const series = [];

            let i;

            for (i in data.monitors) {
                const monitor_id = data.monitors[i];
                series.push({
                    name: data.legends[monitor_id],
                    data: data.data[monitor_id]
                });


                let y;

                if (data.data[monitor_id] !== null) {
                    y = data.data[monitor_id].length - 1;
                }

                while (this.latestValue === null && y > 0) {
                    const point = data.data[monitor_id][y];

                    if (point[1] !== null) {
                        this.latestValue = point[1];
                    }

                    if (typeof y === 'number') {
                        y -= 1;
                    }
                }

                this.$emit('update:latestValue', this.latestValue);
            }

            return series;
        },        
        getupdatedZones: function(data_zones){
            if(data_zones) {
                data_zones.forEach((zone, i) => {
                    zone["events"] = {
                        mousemove: e => {
                            var con_id = '#incident_details_' + this.chart.container.id;
                            var incident_id_link = '#incident_id_link_' + this.chart.container.id;
                            var incident_id = '#incident_id_' + this.chart.container.id;
                            var incident_desc = '#incident_description_' + this.chart.container.id;
                            var incident_desc_element = document.querySelector(incident_desc);
                            var incident_id_link_element = document.querySelector(incident_id_link);
                            if (incident_id_link_element) {
                                incident_id_link_element.href = "/outage/IncidentDetails?incident_id=" + zone.outage_id;
                            }
                            var incident_count_text = '1 Incident ';
                            if (this.incident_count > 1) {
                                incident_count_text = this.incident_count + ' Incidents: ';
                            }
                            if (incident_desc_element) {
                                incident_desc_element.innerHTML = incident_count_text;
                            }
                            var element = document.querySelector(con_id);
                            if (element) {
                                element.style.visibility = '';
                            }
                        },
                    }
                });
            }
            return data_zones
        },

        render_graph: function(data) {
            if (this.hideGraphOnNoData && data.all_data_none) {
                this.eventHub.$emit('graph:hide', data);
                return;
            }

            if (this.selectedGraphUnit && this.selectedGraphUnit === "%") {
                const updatedData = {};

                Object.keys(data.data).forEach((key) => {
                    updatedData[key] = data.data[key].map(
                        ([timestamp, value]) => ([
                            timestamp,
                            Boolean(value)
                                ? (value / this.maxValue) * 100
                                : value
                        ])
                    );
                });

                data = {
                    ...data,
                    originalUnits: data.units,
                    units: "%",
                    originalUnitLabel: data.unit_label,
                    unit_label: "%",
                    originalData: {...data.data},
                    data: {...updatedData},
                };
            }

            this.graph_data_loaded = true;

            var container_id = "p-graph-" + this._uid;
            var container = $("#" + container_id);

            if (container.length === 0) {
                return false;
            }

            this.$emit('update:unit', data.units);

            this.series = this.getSeriesFromData(data);
            // Update the parent unit if it differs from graph
            if (this.$parent && this.$parent.graphUnit != data.units && data.units) {
                this.$parent.graphUnit = data.units;
            }

            var longestSeries = 0;
            for (var dataSet in data.data) {
                var len = data.data[dataSet].length;
                if (len > longestSeries) {
                    longestSeries = len;
                }
            }

            if (typeof highcharts_export_url == "undefined") {
                var highcharts_export_url = "http://export.highcharts.com";
            }

            let timezone = this.$parent.timezone; //window.userTZ;
            if (!timezone) {
                const guessedTimezone = moment.tz.guess();

                if (guessedTimezone) {
                    timezone = guessedTimezone;
                }
            }
            if (typeof timezone !== 'undefined') {
                Highcharts.setOptions({
                    global: {
                        timezone: timezone == 'UTC/UTC' ? null : timezone,
                    },
                    lang: {
                        noData: '- No Data -',
                    },
                });
            }

            let pointObj = {};
            let eventsObj = {};
            if (this.syncCrosshairs) {
                let self = this;
                pointObj = {
                    events: {
                        mouseOver: function (e) {
                            let index = e.target['colorIndex'];                            
                            for (var i = 0; i < Highcharts.charts.length; i++) {
                                var mChart = Highcharts.charts[i];
                                    if (typeof mChart === 'undefined') {
                                    // This happens if chart is reconstructed
                                    continue;
                                }
                                if (!mChart.xAxis.length || mChart.xAxis[0].min > e.target.x || mChart.xAxis[0].max < e.target.x) {
                                    continue;
                                }
                                if(self.syncTooltips && mChart.series[index]) {
                                    let series = mChart.series[index];
                                    const data = series.data;
                                    if (!data || !data.length) { continue; }
                                    let point = series.points[e.target.index]                                    
                                    if (point && !point.isNull) {  
                                        mChart.tooltip.refresh(point);
                                    }
                                    mChart.xAxis[0].drawCrosshair(null, point);
                                }
                                for (var j = 0; j < mChart.series.length; j++) {
                                    let series = mChart.series[index];
                                    if(!series) {
                                        continue;
                                    }

                                    let point = series.points[e.target.index]
                                    if(!point || point.y == null) {
                                        continue;
                                    }                                 
                                    mChart.xAxis[0].drawCrosshair(null, point);
                                    break;
                                }
                            }
                        }
                    }
                };
                eventsObj = {
                    mouseOut: function (e) {
                        for (var i = 0; i < Highcharts.charts.length; i++) {
                            var mChart = Highcharts.charts[i];
                            if (typeof mChart === 'undefined') {
                                // This happens if chart is reconstructed
                                continue;
                             } 
                            //If multiple tooltips are shown we should remove
                            //all of them
                            if(self.syncTooltips) {
                                mChart.xAxis[0].hideCrosshair();
                                mChart.yAxis[0].hideCrosshair();
                                if (typeof mChart.tooltip !== 'undefined') {
                                    mChart.tooltip.hide(5000);
                                }
                            }
                            else {
                                if (mChart === this.chart) { continue; }
                                if(mChart.xAxis[0].hasVisibleSeries) {
                                    mChart.xAxis[0].hideCrosshair();
                                    mChart.yAxis[0].hideCrosshair();
                                    if (typeof mChart.tooltip !== 'undefined') {
                                        mChart.tooltip.hide(5000);
                                    }
                                }
                            }
                            mChart.hoverPoints = null;
                        }
                    }
                };
            } else {
                pointObj = {
                    events: {
                        mouseOver: function(e) {
                            if(e.target.highlight) {
                                e.target.highlight();
                            }
                        },
                    },
                };
            }

            let itemWidth = null;
            if (this.setLegendItemWidth && this.series.length > 1) {
                itemWidth = Math.floor(container.width() * .4);
            }

            if (this.opposing || this.networkPort) {
                // Special format function to hide negative values for opposing/network port graphs
                var label_format_function = function() {
                    const yData = this.yData;
                    const yDataLength = this.yData ? this.yData.length : 0;
                    let name = this.name;
                    let y = 0.0;

                    if (yData && yDataLength) {
                        const latestValue = yData[yDataLength - 1];
                        y = latestValue;
                        try {
                            y = Math.abs(y.toFixed(2));
                            if(y == 0.00 && latestValue > 0) {
                                y = Number.parseFloat(Math.abs(latestValue)).toExponential(2);
                            }
                        } catch (error) {
                            y = 0.0;
                        }
                    }

                    return `<span>${name}:</span> <b style="font-weight: bold">${y}</b>`;
                }
            } else {
                var label_format_function = function () {                    
                    const yData = this.yData;
                    const yDataLength = this.yData ? this.yData.length : 0;
                    let name = this.name;
                    let y = 0.0;

                    if (yData && yDataLength) {
                        const latestValue = yData[yDataLength - 1];
                        y = latestValue;
                        try {
                            y = Number(y.toFixed(2));
                            if(y == 0.00 && latestValue > 0) {
                                y = Number.parseFloat(latestValue).toExponential(2);
                            }
                        } catch (error) {
                            y = 0.0;
                        }
                    }

                    return `<span>${name}:</span> <b style="font-weight: bold">${y}</b>`;
                }
            }

            const chartConfig = {
                colors: [
                    "#307FE2","#48D597","#10BCE8","#F25959","#F5AA0D",
                    "#B1B0AF","#39CA89","#5C98E3","#EF9F71","#79869F"
                ],
                chart: {
                    animation: true,
                    backgroundColor: this.backgroundColor,
                    borderColor: null,
                    defaultSeriesType: (this.opposing || this.networkPort) && "area" || "line",
                    height: container.height() || this.height,
                    plotShadow: false,
                    renderTo: container_id,
                    showAxes: true,
                    width: container.width(),
                    zoomType: 'x',
                    events: {
                        load: function(){
                            this.disable_loader()
                        }.bind(this),
                        selection: function(event) {
                            if (event.resetSelection) {
                                for (var i = 0; i < this.series.length; i++) {
                                    if (this.chart.series[i]) {
                                        this.chart.series[i].setData(this.series[i].data, false);
                                    }
                                }

                                // Remove the zoom button, even if we're zoomed in multiple times
                                this.zoomed = false;
                                this.chart.zoom();

                                if (this.opposing || this.networkPort) {
                                    this.chart.yAxis[0].setExtremes(-this.prezoomed_extreme, this.prezoomed_extreme, true, true);
                                }

                            } else {
                                this.zoomIn(event);
                            }
                        }.bind(this),
                    },
                },
                title: { text: null },
                xAxis: {
                    type: 'datetime',
                    endOnTick: false,
                    gridLineWidth: 0,
                    lineWidth: 1,
                    lineColor: '#ddd',
                    maxPadding: 0,
                    minPadding: 0,
                    plotBands: this.getupdatedZones(data.zones),
                    startOnTick: false,
                    tickLength: 0,
                    labels: {
                        align: 'center',
                        style: {
                            fontSize: '10px',
                            color: 'black',
                            fontWeight: 'none'
                        },
                    },
                    crosshair: {
                        dashStyle: 'shortdot',
                        width: this.crosshairs && 1.5 || 0,
                        color: "#999999",
                        snap: true
                    },
                },
                yAxis: {
                    gridLineColor: '#BDBDBD',
                    gridLineDashStyle : 'Dot',
                    lineWidth: 0,
                    lineColor: '#ddd',
                    max: data.units == '%' ? 100 : null,
                    softMin: 0,
                    minorGridLineWidth: 1,
                    title: {
                        enabled: this.displayYAxisTitle,
                        text: data.unit_label,
                        style: {
                            color: 'black'
                        }
                    },
                    labels: {
                        style: {
                            fontSize: '10px',
                            color: 'black',
                            fontWeight: 'none'
                        }
                    },
                },
                tooltip: {
                    shadow: {
                        color: 'rgb(62, 64, 65)',
                        opacity: 0.1,
                        offsetX: 0,
                        offsetY: 1,
                        width: 4,
                    },
                    stickOnContact: true,
                    followPointer: false,
                    enabled: true,
                    animation: true,
                    useHTML: true,
                    split: false,
                    shared: false,
                    backgroundColor: '#ffffff',
                    borderWidth: 0,
                    borderRadius: 6,
                    hideDelay: 1000,
                    style: {
                        padding: 0,
                    },                    
                    // distance: 30,
                    headerFormat: '',
                    formatter: function() {
                        const hasDec = (this.y % 1) !== 0;
                        let y = this.y;
                        if (hasDec) {
                            y = y.toFixed(2);
                        }  
                        var inc_det_id = this.series.chart.container.id;                          
                        const ret = `
                        <div style="height:auto;">
                            <div class='pa-flex' id="tooltip">                            
                                <span style='font-size: 22px; color: ${this.point.series.color}; line-height: 11px;'>
                                    \u25CF
                                </span>
                                <div style='padding-left: 6px; padding-right: 20px;'>
                                    <p class='pa-txt_medium' style='color: #3c3d3e; margin-bottom: 4px;'>
                                        ${this.point.series.name.replace("\n", " ")}: ${y}
                                    </p>
                                    <p class='pa-txt_normal' style='color: #95999b; font-size: 11px;'>
                                        ${Highcharts.dateFormat("%Y-%m-%d %H:%M", new Date(this.x))}
                                    </p>                                                               
                                </div>                                                                             
                            </div>
                            <div class='incident_section' id = "incident_details_${inc_det_id}" style="visibility:hidden;">
                                <div class="inc_info" style="padding-top:4px; white-space:normal;" >
                                    <div class="pa-flex">
                                        <span style='font-size: 22px; color: #eb919b;line-height: 11px;padding:1px;'>
                                        \u25CF
                                        </span>
                                        <span style="padding-left:2px;" id="incident_description_${inc_det_id}"></span>
                                        <a id="incident_id_link_${inc_det_id}" href="" target="_blank" style="padding-left:2px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18"><path d="M5.551 2.25v1.501h7.643L5.821 11.124l1.059 1.059 7.368 -7.377v2.693H15.75V2.25m-1.501 11.999H3.751V3.751H9V2.25H3.751A1.501 1.501 0 0 0 2.25 3.751v10.501a1.501 1.501 0 0 0 1.501 1.501h10.501a1.501 1.501 0 0 0 1.501 -1.501v-5.25h-1.501v5.249Z" fill="blue"/></svg></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        `;   
                        return ret;
                    },
                    dateTimeLabelFormats: {
                        millisecond: '%Y-%m-%d %H:%M',
                        second: '%Y-%m-%d %H:%M',
                        minute: '%Y-%m-%d %H:%M',
                        hour: '%Y-%m-%d %H:%M',
                        day: '%Y-%m-%d %Y',
                        week: 'Week from %A, %b %e, %Y',
                        month: '%B %Y',
                        year: '%Y',
                    },
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                            },
                        },
                        states: {
                            hover: {
                                lineWidthPlus: 0,
                            },
                        },
                        animation: true,
                        point: pointObj,
                        events: eventsObj

                    },
                    areaspline: {
                        fillOpacity: 0.3,
                    },
                    area: {
                        fillOpacity: 0.3,
                    },
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                        stacking: 'normal'
                    },
                    line: {
                        lineWidth: 1,
                        shadow: false,
                        connectNulls: this.shouldConnectNulls
                    },
                    area: {step: "center"},
                },
                legend: {
                    symbolHeight: 8,
                    symbolWidth: 8,
                    symbolRadius: 8,
                    align: this.alignLegend,
                    enabled: this.showLegend,
                    layout: 'horizontal',
                    verticalAlign: 'bottom',
                    borderWidth: 0,
                    itemStyle: {
                        fontWeight: 'normal',
                        textOverflow: null,
                        width: itemWidth && `${itemWidth}px` || '',
                    },
                    itemHiddenStyle: {
                        color: '#888888',
                    },
                    padding: 0,
                    // 20px for series marker
                    itemWidth: itemWidth && itemWidth + 20 || null,
                    useHTML: false,
                    labelFormatter: label_format_function,
                    maxHeight: this.maxLegendHeight,
                    navigation: {
                        animation: false,
                    },
                },
                credits: {
                    enabled: false,
                },
                navigation: {
                    buttonOptions: {
                        x: -20
                    }
                },
                // Deep copy the series so we keep the original
                series: JSON.parse(JSON.stringify(this.series)),
                exporting: {
                    enabled: this.exporting,
                    url: highcharts_export_url
                },
            };

            if (this.highchartsConfig) {
                _.merge(chartConfig, this.highchartsConfig);
            } else if (this.highchartsPreset) {
                const presetConfig = HighchartsConfigs[this.highchartsPreset];
                _.merge(chartConfig, presetConfig);
            } else if (this.graphType == 'stackedArea') {
                let stackedAreaConfig = HighchartsConfigs.stackedArea;
                _.merge(chartConfig, stackedAreaConfig);
                chartConfig.colors = stackedAreaConfig.colors
            }

            this.chart = new Highcharts.Chart(chartConfig);

            // Used to stop overflow when resizing
            const paddingOffset = 10;

            $(window).resize(function() {
                if (this.resize) {
                    this.resizeChart($(this.$el).width() - paddingOffset, this.minHeight);
                }
            }.bind(this))

            if (this.resize) {
                this.resizeChart($(this.$el).width() - paddingOffset, this.minHeight);
            }

            // If an opposing or network port graph, force positive and negative scales to be the same
            if (this.opposing || this.networkPort) {
                if(this.selectedGraphUnit == '%') {
                    this.chart.yAxis[0].setExtremes(-100, 100);
                    // Store the fully zoomed out maximum, to use when restoring from a zoom
                    this.prezoomed_extreme = this.chart.yAxis[0].getExtremes().max;
                } else {
                    var dExt;
                    var ext = this.chart.yAxis[0].getExtremes();
                    var dMax = Math.abs(ext.dataMax);
                    var dMin = Math.abs(ext.dataMin);
                    dMax >= dMin ? dExt = dMax : dExt = dMin;
                    var min = 0 - dExt;
                    this.chart.yAxis[0].setExtremes(min, dExt);

                    // Store the fully zoomed out maximum, to use when restoring from a zoom
                    this.prezoomed_extreme = this.chart.yAxis[0].getExtremes().max;
                }
            }

            if (window.app.rootVue && window.app.rootVue.metricManager) {
                for (const monitor of this.monitors) {
                    window.app.rootVue.metricManager.registerMetric(monitor);
                }
            }

        
            },

            resizeChart(width, height){
                this.chart.setSize(width, height);
                this.chart.reflow();
            },

            fitWidth() {
                if (!this.chart) {
                    return;
                }

                const container = $(`#p-graph-${this._uid}`);
                const width = container.clientWidth;
                this.chart.update({
                    chart: {
                        width: width,
                    },
                });
        }, 
                            },

        events: {
            'graph:changeTimescale': function(timescale) {
                if(this.ignoreTimescaleEvent) {
                    return;
                }
                this.change_timescale(timescale);
            },

            'graph:changeDateRange': function(date_range) {
                this.change_date_range(date_range);
            },

            'graph:setOffset': function(offset) {
                this.set_offset(offset);
            },

            'graph:incrementOffset': function(delta) {
                this.increment_offset(delta);
            },

            'graph:decrementOffset': function(delta) {
                this.decrement_offset(delta);
            },

            'graph:resizeWidthEase': function() {
                let paddingOffset = 15;
                setTimeout(() => {
                    if (!this.chart) {
                        return;
                    }

                    const width = $(this.$el).width();

                    this.chart.update({
                        chart: {
                            width: width - paddingOffset
                        },
                    });
                    this.fetch_graph_data();
                },320);

            },

            'graph:refresh': function() {
                this.fitWidth()
                this.fetch_graph_data();
            },


            'metric-values:new': function(metricId, values) {
                if (this.monitors.includes(metricId)) {
                    this.addPoints(values);
                }
            },

            'metric:popup-closed': function() {
                if(this.id == 'metric-popup-graph') {
                    this.isStale = true;
                }
            },

            'graph:changeUnit': function(data) {
                if (data.id === this.id) {
                    this.changeUnit(data.unit);
                }
            },
        }

});



 export default GraphComponent;
</script>
