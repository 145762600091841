<template>
    <span>
        <p-text v-if="state === 'loading'" as="span">
            Loading...
        </p-text>
        <p-flex v-if="state === 'error'" align-center>
            <p-text as="span" color="crimson" style="line-height: 1;">
                Error
            </p-text>
            <p-text as="span" class="pa-mx-6">&mdash;</p-text>
            <p-button @click="getData" variant="link">
                Retry
                <p-icon icon="refresh" size="sm"></p-icon>
            </p-button>
        </p-flex>
        <p-application-status-flyout
            v-if="state === 'done'"
            :status-availabilities-string="statusAvailabilities"
            day="1"
            :type="type"
            show-icon-in-status-trigger
            :is-zero-state="isZeroState"
            style="width: 30%"
            show-inactive-icon
        >
        </p-application-status-flyout>
    </span>
</template>

<script>
    import Vue from 'vue';
    import axios from 'axios';

    const LOCATION_TYPES = ["dem", "appliance", "node", "sdwan_device"];

    export default Vue.extend({
        data() {
            return {
                statusAvailabilities: "",
                state: "loading",
            };
        },
        props: {
            applicationId: {
                type: Number,
                default: null,
            },
            locationType: {
                type: String,
                default: null,
                validator: (val) => LOCATION_TYPES.includes(val)
            },
            isZeroState: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            type() {
                return {
                    "dem": "endpointAgents",
                    "appliance": "onSights",
                    "node": "publicAgents",
                    "sdwan_device": "sdwanDevices",
                }[this.locationType];
            },
        },
        methods: {
            async getData() {
                if (!this.applicationId) {
                    return;
                }

                if (this.state !== "loading") {
                    this.state = "loading";
                }

                try {
                    const { data } = await axios.get("/application/get_single_application_status_availability", {
                        params: {
                            application_id: this.applicationId,
                            location_type: this.locationType,
                        },
                    });

                    if (data.success) {
                        this.statusAvailabilities = JSON.stringify(data.statusAvailabilities);
                        this.state = 'done';
                    } else {
                        this.state = 'error';
                    }
                } catch (error) {
                    console.log(error);
                    this.state = 'error';
                }
            },
        },
        async mounted() {
            await this.getData();
        },
    });
</script>
