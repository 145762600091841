<script setup>
import { computed } from "vue";
import { FILTER_CONDITIONS } from "../constants/filterConditions"

const emit = defineEmits(["change"]);

const props = defineProps({
    conditions: {
        type: Array,
        default: () => FILTER_CONDITIONS,
        validator: (propValue) => {
            return propValue.every((operator) => {
                return FILTER_CONDITIONS.includes(operator);
            });
        },
    },
    labelFormatter: {
        type: Function,
        default: (value) => {
            return value.split(/(?=[A-Z])/).map((word) => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }).join(' ');
        },
    },
    size: {
        type: String,
        default: "xxxs",
    },
    condition: {
        type: String,
        value: null,
    },
});

const condition = computed({
    get() {
        return props.condition;
    },
    set(value) {
        emit('update:condition', value);
    },
});

const conditionOptions = computed(() => {
    return props.conditions.map((operator) => {
        return {
            label: props.labelFormatter(operator),
            value: operator,
        };
    });
});
</script>

<template>
    <p-select
        :model.sync="condition"
        :options="conditionOptions"
        :size="size"
    >
    </p-select> 
</template>