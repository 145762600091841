<style lang="scss">
    .oncall-table {
        width: 100%;
        text-align: left;
        color: rgba(0,0,0,0.86);
        font-size: 14px;

        thead th {
            color: rgba(0, 0, 0, 0.6);

            font-size: 0.8125rem;
            font-weight: bold;
            padding: 5px 10px;
        }

        tbody tr {
            border-top: 1px solid #eceaea;

            td {
                white-space: nowrap;
                padding: 10px 10px;
                vertical-align: middle;

                p {
                    margin-bottom: 5px;
                }

                .avatar {
                }

                .contact-channel {
                    font-size: 12px;
                    color: rgba(32, 32, 32, 0.6);
                }

                .status {
                    color: rgba(32, 32, 32, 0.6);
                }
            }
        }
    }
</style>

<template>
    <div :id="id"
         :class="!inModal ? 'grid-stack-item' : 'modal-view'"
         :data-gs-x="localConfig.col.toString()"
         :data-gs-y="localConfig.row.toString()"
         :data-gs-width="localConfig.width.toString()"
         :data-gs-height="localConfig.height.toString()"
         :data-gs-auto-position="false"
         :data-gs-locked="true">
        <div
            :class="!inModal ? 'grid-stack-item-content' : 'modal-view-content'"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;">
                <div :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered, 'expand-mode' : !$parent.editing && isHovered && !inModal, 'in-modal': inModal}">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_9of12" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                            </span>
                            <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: textColor}"><use xlink:href="#spinner-spin-naked"></use></svg>
                            <p-tooltip2 :normal-white-space="true"> 
                                <span v-if="!isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: textColor }">
                                    <span v-if="localConfig.title">{{ localConfig.title }}</span>
                                </span>
                                <span v-if="isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate">
                                    <span v-if="localConfig.title">{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }}</span>
                            </p-tooltip2>
                        </div>
                        <div class="pa-grid-col pa-grid-col_3of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger"  v-show="!$parent.editing && isHovered && !inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#arrow-expand"></use>
                                    </svg>
                                </button>
                                <span> Expand </span>
                            </p-tooltip2>
                            <button slot="trigger"  v-show="!$parent.editing && inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                <svg class="pa-icon remove-icon">
                                    <use xlink:href="#close"></use>
                                </svg>
                            </button>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls && !inModal" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button 
                                    v-show="showWidgetControls && !inModal" 
                                    type="button"
                                    slot="trigger"
                                    @click="cloneWidget" 
                                    class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mr-6 pa-mt-6"
                                >
                                    <p-icon icon="copy"></p-icon>
                                </button>
                                <span> Clone this Widget </span>
                            </p-tooltip2>
                        </div>
                    </div>
                </div>
                <div v-show="valid" id="body" class="widget-body on-call" :style="{'height': inModal && '74vh'}" style="overflow-y: auto; flex-grow: 1; margin-bottom: 0px;margin-top:13px;" ref="body">
                    <div v-if="content && content.rows && content.rows.length > 0">
                        <table class="oncall-table">
                            <thead>
                                <th></th>
                                <th>Currently On-Call</th>
                                <th>Schedule</th>
                                <th>Period</th>
                            </thead>
                            <tbody>
                                <tr v-for="row in content.rows">
                                    <td>
                                        <svg v-if="row.icon.startsWith('#')" class="pa-icon" style="width: 2.1875rem; height: 2.1875rem;">
                                            <use :href="row.icon"></use>
                                        </svg>
                                        <img v-if="!row.icon.startsWith('#') && row.icon" class="pa-img pa-img_sm pa-img_avatar" :src="row.icon" />
                                    </td>
                                    <td>
                                        <p>{{ row.name }} <span class="status">({{ row.status }})</span></p>
                                        <p v-for="channel in row.channels" class="contact-channel">{{ channel }}</p>
                                    </td>
                                    <td>
                                        <p v-for="schedule in row.schedules"><a target="_blank" :href="`/users/on_call/EditRotatingSchedule?contact_id=${schedule.id}`">{{ schedule.name }}</a></p>
                                    </td>
                                    <td>
                                        <p v-for="schedule in row.schedules">{{ renderTimePeriod(schedule.start, schedule.end) }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="content && content.rows && content.rows.length === 0" style="flex-grow: 1; align-self: center;">
                        <div 
                            :style="[!inModal ? {'width': 'calc(100% - 20px)', 'position': 'absolute', 'top': '50%', 'transform': 'translateY(-50%)'} : 
                            { 'height': '85vh', 'text-align': 'center','line-height': '70vh'}]">
                            <p>
                                No current or upcoming on-call events
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="!valid" class="widget-body widget-body_error" :style="{'height': inModal && '85vh'}" :title="error_message" ref="body">
                    <p class="pa-message pa-message_error">
                        <svg class="pa-icon pa-icon_xl"><use xlink:href="#alert-circle"></use></svg>
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Vue from 'vue';
    import $ from 'jquery';
    import widgetMixin from './../widgetMixin';

    const OnCallWidget = Vue.extend({
        mixins: [widgetMixin],

        data() {
            return {
                content: {
                },
                localConfig: this.config,
                inModal: false,
            };
        },

        computed: {
        },

        events: {
            // Mixin: 'widget:new_data' -> this.initialize(config, content)
            // Mixin: 'widget:updated_data' -> this.update(content)
            // Mixin: 'widget:pend_config' -> this.pendNewConfig()
        },

        methods: {
            expandSelf() {
                this.inModal = !this.inModal
            },
            renderTimePeriod(start, end) {
                let result = '';

                const startTime = window.moment.utc(start);
                startTime.tz(window.moment.tz.guess());
                if (startTime.minute()) {
                    result += startTime.format('h:mm A');
                } else {
                    result += startTime.format('h A');
                }

                const endTime = window.moment.utc(end);
                endTime.tz(window.moment.tz.guess());
                if (startTime.minute()) {
                    result += endTime.format(' - h:mm A z');
                } else {
                    result += endTime.format(' - h A z');
                }

                return result;
            },

            // Mixin: configure() -- opens configure modal
            initialize(config, content) {
                this.isLoading = false;
                this.$refs.body.style.filter = '';
                this.localConfig = {...config};
                this.content = content;

                if (!this.content.success) {
                    return;
                }
            },

            update(content) {
                this.content = content;
                if (!this.content.success) {
                    return;
                }
            },

            reconfig() {
                if (!this.content.success) {
                    return;
                }
            },

            getConfigModules() {
                return [
                    { type: 'p-overview-module' },
                    {
                        type: 'p-custom-module',
                        options: {
                            title: '',
                            controls: [
                                {
                                    id: 'backgroundColor',
                                    label: 'Background Color',
                                    key: 'backgroundColor',
                                    type: 'color-picker',
                                    default: '#ffffff'
                                },
                            ]
                        }
                    },
                ]
            },

            getOptionModules() {
                return [
                    { type: 'p-oncall-module', options: {} },
                ]
            },

            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this);
            },

            dumpState() {
                const obj = Object();
                obj.data = this.$data;
                obj.config = {...this.localConfig};
                console.log(JSON.stringify(obj));
            },

        },

        props: {
            // Mixin: id:Number, config:Object, title:String
        },

        mounted() {
            // Mixin: dispatch('widget:domready')
            this.pendNewConfig();
            if (this.id < 0) {
                this.configure();
            };
        },
    });

    export default OnCallWidget;
</script>
