<style>
    .widget-unconfigured {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #69717d;
        padding-top: 13px;
    }
    .vertical-center {
          position: absolute;
          top: 50%;
          left: 20px;
          right: 20px;
          transform: translateY(-50%);
    }
</style>

<template>
    <div :id="id"
        class="grid-stack-item"
        :data-gs-x="localConfig.col.toString()"
        :data-gs-y="localConfig.row.toString()"
        :data-gs-width="localConfig.width.toString()"
        :data-gs-height="localConfig.height.toString()"
        :data-gs-auto-position="false"
        :data-gs-locked="true">
        <div class="grid-stack-item-content"
             @mouseover="handleContentHover(true)"
             @mouseleave="handleContentHover(false)">
            <div style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;">
                <div :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered}">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_10of12" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                            </span>
                            <p-tooltip2 :normal-white-space="true">
                                <span slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }} </span>
                            </p-tooltip2>
                        </div>
                        <div class="pa-grid-col pa-grid-col_2of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mr-6 pa-mt-6">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#trashcan" ></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mt-6">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>
                        </div>
                    </div>
                </div>
                <div style="flex-grow: 1;">
                    <div class="vertical-center">
                        <button v-if="$parent.allowEditing" @click="configure" class="pa-btn pa-btn_naked_light_grey_no_fill pa-btn_lg" style="padding: 12px 35px" type="button">
                            <svg class="pa-icon">
                                <use xlink:href="#plus-circle-outline"></use>
                            </svg>
                            <span>Configure</span>
                        </button>
                        <p class="widget-unconfigured centered">
                            Widget configuration is currently incomplete.
                        </p>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
    import Vue from 'vue';
    import widgetMixin from './widgetMixin';

    const Highcharts = require('highcharts');
    require('highcharts/modules/no-data-to-display')(Highcharts);

    const WidgetBlock = Vue.extend({
        mixins: [widgetMixin],

        data() {
            return {
                localConfig: this.config,
            };
        },

        computed: {
            widgetBodyStyle: function() {
                let styles = {
                    height: "100%",
                    width: "100%",
                };

                if (!this.displayChart) {
                    styles = {
                        ...styles,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    };
                }

                return styles;
            }
        },

        methods: {
            initialize(config, content) {
                this.content = content;
                this.localConfig = {...config};
                // Give DOM a chance to swap in/out configure messages
                Vue.nextTick(this.reconfig);
                this.rendered = this.content.success;
            },

            getConfigModules() {
                return [{ type: 'p-overview-module' }];
            },

            getOptionModules() {
                return []
            },

            // Open configure drawer
            configure() {
                window.app.rootVue.$broadcast('dashboard:open_widget_select', this);
            },
            update(content) {
                this.content = content;
            },
            dumpState() {
                const obj = Object();
                obj.data = this.$data;
                obj.config = {...this.config};
                console.log(JSON.stringify(obj));
            },
        },
    });

    export default WidgetBlock;
</script>
