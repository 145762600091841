<style lang="scss">
	@import "~styles/core/helpers/_variables.scss";
	.parent {
		position: relative;
	}

	.flyout {
		position: absolute;
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: var(--p-grey-200, #d5d8df);
		color: var(--p-grey-700, #4f5766);
		box-shadow: 0 3px 9px 0 rgba(57, 84, 191, 0.1), 0 2px 4px 0 rgba(33, 49, 111, 0.2);
		background: white;
		border-radius: 2px;
		height: 60px;
		z-index: 999;
	}

	.outside {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0px;
		left: 0px;
		z-index: 998
	}

	.operator-wrapper {
		.pa-select {
			width: 170px;
		}
	}
	
</style>


<template>
	<div 
	class="parent"
	>
		<div class="pa-select filter-select lead-filter-select pa-mb-8">
			<button 
				:class="{'pa-select-hd pa-btn pa-filter-button pa-btn_ghost-blue': true,
						'isActive': iSelectModelIs.length || iSelectModelIsNot.length || operatorModel == 'is_assigned' || operatorModel == 'is_unassigned'}"
				@click.prevent="openFlyout"
			>
	            <slot name="button" class=""></slot>
	        </button>
	    </div>
		<div
            v-if="isOpen"
            class="flyout pa-px-12 pa-py-12"
            :style="`width: ${flyoutWidth}px;`"
            
        >
        	<p-row>
        		<p-column class="pa-p-0 operator-wrapper" cols="6">
	        		<p-select
		                :model.sync="operatorModel"
		                :options="operatorOptions"
		            >
		            </p-select>
		        </p-column>
		        <p-column class="pa-p-0" cols="6" align-end>
	        		<p-select
	        			v-if="operatorModel == 'is'"
		                :model="iSelectModelIs"
		                searchable
		                :options="selectOptions"
		                multiple
		            >
		            </p-select>
		            <p-select
	        			v-if="operatorModel == 'is_not'"
		                :model="iSelectModelIsNot"
		                searchable
		                :options="selectOptions"
		                multiple
		                :list-width="250"
		            >
		            </p-select>
		        </p-column>
	        </p-row>
        </div>
        <div v-if="isOpen" class="outside" @click="close()"></div>
	</div>
</template>


<script>
import Vue from 'vue';

export default Vue.extend({
	props: {
		operatorOptionsLabels: Object,
        selectModelIs: Array,
        selectModelIsNot: Array,
        selectOptions: Array,
        modelType: String,
    },
    data() {
        return {
            isOpen: false,
            inputValue: null,
            iSelectModelIs: _.clone(this.selectModelIs),
            iSelectModelIsNot: _.clone(this.selectModelIsNot),
            isAssigned: false,
            isUnassigned: false,
            deleteChipEvent: false,
            operatorModel: 'is',
            operatorOptions: [
            	{
            		'value': 'is', 
            		'label': this.operatorOptionsLabels['is'], 
            		'disabled': false
            	},
            	{
            		'value': 'is_not', 
            		'label': this.operatorOptionsLabels['is_not'], 
            		'disabled': false
            	},
            	{
            		'value': 'is_assigned', 
            		'label': this.operatorOptionsLabels['is_assigned'], 
            		'disabled': false
            	},
            	{
            		'value': 'is_unassigned', 
            		'label': this.operatorOptionsLabels['is_unassigned'], 
            		'disabled': false
            	},
            ],

        };
    },

    events: {
        'multi-select-filter:reset'() {
        	this.deleteChipEvent = true;
        	this.iSelectModelIs = [];
        	this.deleteChipEvent = true;
        	this.iSelectModelIs = [];
        	this.operatorModel = 'is';
        	this.isAssigned = false;
        	this.isUnassigned = false;
        },

        'multi-select-filter:query_params'(data) {

        	if(this.modelType == data['modelType']) {
        		this.operatorModel = data['operator'];
        		switch(this.operatorModel) {
        			case 'is':
	                    operatorModel = 'is';
	                    this.iSelectModelIs = data['value'];
	                    break;
	                case 'is_not':
	                    operatorModel = 'is_not';
	                    this.iSelectModelIs = data['value'];
	                    break;
	                case 'is_assigned':
	                    operatorModel = 'is_assigned';
	                    break;
	                case 'is_unassigned':
	                    operatorModel = 'is_unassigned';
	                    break;
        		}
        	}
        },
    },

    computed: {
        flyoutWidth() {
            if(this.operatorModel == 'is_assigned' || this.operatorModel == 'is_unassigned') {
            	return 197;	
            }

            return 462;
        },
    },

    watch: {
        iSelectModelIs(val, oldVal) {
        	if(this.deleteChipEvent) {
        		//If this was updated from a delete chip,
        		//the filters were already updated.
        		this.deleteChipEvent = false;
        	} else {
        		this.eventHub.$emit(`multi-select-filter:is-${this.modelType}`, this.iSelectModelIs);
        	}
        },

        iSelectModelIsNot(val, oldVal) {
        	if(this.deleteChipEvent) {
        		//If this was updated from a delete chip,
        		//the filters were already updated.
        		this.deleteChipEvent = false;
        	} else {
            this.eventHub.$emit(`multi-select-filter:is-not-${this.modelType}`, this.iSelectModelIsNot);
        	}
        },

        operatorModel(val, oldVal) {
			this.iSelectModelIs = [];
			this.iSelectModelIsNot = [];

        	if(val == 'is_assigned') {
        		this.isAssigned = true;
        		this.isUnassigned = false;
        		this.close();
        	}
        	else if(val == 'is_unassigned') {
        		this.isAssigned = false;
        		this.isUnassigned = true;
        		this.eventHub.$emit(
        			`multi-select-filter:is-unassigned-${this.modelType}`, 
        			this.iSelectModelIsNot
        		);
        		this.close();
        	}
        	else {
				this.isAssigned = false;
				this.isUnassigned = false;
				this.eventHub.$emit(
					`multi-select-filter:assigned-${this.modelType}`, {
						'is_assigned': false,
						'is_unassigned': false
					}
				);
        	}

        	if(val == 'is_assigned' || val == 'is_unassigned') {
	        	this.eventHub.$emit(
	    			`multi-select-filter:assigned-${this.modelType}`, {
	    				'is_assigned': this.isAssigned,
	    				'is_unassigned': this.isUnassigned
	    			}
	    		);
	        }
        }
    },
    
    methods: {
    	openFlyout() {
    		this.isOpen = true;
    	},
    	close() {
    		this.isOpen = false;
    		//this is needed if the scrollbar disappeared because
    		//of small number of table results
    		window.dispatchEvent(new Event('resize'));
    	},
    }
});
</script>