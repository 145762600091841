<style lang="scss">
    .instance-module-tree {
        .tree-search {
            padding: 0;
        }
        .tree-wrapper {
            overflow-y: auto;
            max-height: 100%;
            border: 1px solid #d5d8df;
            border-radius: 2px;
            padding: 10px 20px;
        }
    }
</style>

<template>
    <div class="module">
        <div ref="body" v-show="open" transition="module-expand" class="module-body pa-pb-24">
            <div class="pa-field pa-field_vertical">
                <div class="pa-field-hd">
                    <label class="pa-label">
                        Instances
                    </label>
                </div>
                <div class="pa-field-bd">
                    <div class="pa-select pa-select_stretch">
                        <select v-model="type" class="pa-select-menu">
                            <option v-for="item in typeOptions" :value="item.name">{{item.label}}</option>
                        </select>
                        <span class="pa-select-icon"></span>
                    </div>
                </div>
                <div class="pa-field-ft">
                </div>
            </div>
            <div v-if="type == 'tags'" class="module-border pa-pb-6" style="text-align: left;">
                <label class="pa-option" style="padding: 10px;">
                    <input v-model="tagsType" value="any" type="radio" class="pa-option-input" />
                    <span class="pa-option-icon pa-option-icon_radio"></span>
                    <span class="pa-option-txt">
                        Match Any
                    </span>
                </label>
                <label class="pa-option">
                    <input v-model="tagsType" value="all" type="radio" class="pa-option-input" />
                    <span class="pa-option-icon pa-option-icon_radio"></span>
                    <span class="pa-option-txt">
                        Match All
                    </span>
                </label>
                <p-tags-input
                    id="instance_tags"
                    ref="tags_input"
                    name="tags[]"
                    :model.sync="tags"
                    :autocomplete="true"
                    :suggestions="allTags"
                    placeholder="Enter tags"
                >
                </p-tags-input>
                <p-hint>Hit enter or tab after each tag</p-hint>
            </div>
            <div v-if="type == 'groups'" class="pa-pb-6">
                <p-tree :key=0
                        ref="group_tree"
                        :model="groups"
                        :preselected="preselected_groups"
                        :deferred="true"
                        url="/util/monitoring_tree_deferred?only_groups=1"
                        :select_nodes="true"
                        :select-callback="onSelect"
                        :multiselect="true"
                        :show-search="true"
                        :include-link="false"
                        class="instance-module-tree"
                        css_class="tree-wrapper"
                ></p-tree>
            </div>
            <div v-if="type == 'names'" class="pa-pb-6">
                <p-tree :key=1
                        ref="server_tree"
                        :model="names"
                        :preselected="preselected_names"
                        :deferred="true"
                        url="/util/monitoring_tree_deferred"
                        :select_nodes="true"
                        :select-callback="onSelect"
                        :multiselect="true"
                        :show-search="true"
                        :include-link="false"
                        class="instance-module-tree"
                        css_class="tree-wrapper"
                ></p-tree>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const MetricModule = Vue.extend({
        events: {
        },

        data() {
            return {
                ready: false,
                type: '',
                tags: [],
                tagsType: 'any',
                groups: [],
                names: [],
                preselected_groups: [],
                preselected_names: [],
            };
        },

        props: {
            options: {
                type: Object,
                'default': function() {
                    return {};
                },
            },
            typeOptions: {
                type: Array,
                'default': function() {
                    return [
                        { name: 'none', label: 'No Filter' },
                        { name: 'tags', label: 'By Tag(s)' },
                        { name: 'groups', label: 'By Group' },
                        { name: 'names', label: 'By Name' },
                    ];
                },
            },
            open: {
                type: Boolean,
                'default': true,
            },
            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        },

        methods: {            
            toggle() {
                this.open = !this.open;
            },
            sendConfig() {
                if (!this.ready) { return; }                
                this.$parent.$broadcast('config:wait_for_metrics');
                if (this.type === 'groups') {
                    const payload = {
                        widget_id: this.editingWidget.id,
                        select_type: 'groups',
                        selection: this.groups,
                    };
                    $.ajax({
                        url: '/dashboardv2/setWidgetInstances',
                        method: 'GET',
                        data: payload,
                    }).done(data => {
                        if (data.newConfig) {
                            this.editingWidget.config = data.newConfig;
                        }
                        this.preselected_groups = this.groups;
                        this.$parent.$broadcast('config:update_metrics');
                    });
                } else if (this.type === 'names') {                    
                    const payload = {
                        widget_id: this.editingWidget.id,
                        select_type: 'names',
                        selection: this.names,
                    };
                    $.ajax({
                        url: '/dashboardv2/setWidgetInstances',
                        method: 'GET',
                        data: payload,
                    }).done(data => {
                        if (data.newConfig) {
                            this.editingWidget.config = data.newConfig;
                        }
                        this.preselected_names = this.names;
                        this.$parent.$broadcast('config:update_metrics');
                    });
                } else if (this.type === 'tags') {
                    const payload = {
                        widget_id: this.editingWidget.id,
                        select_type: 'tags',
                        selection: this.tags,
                        tag_match: this.tagsType,
                    };
                    $.ajax({
                        url: '/dashboardv2/setWidgetInstances',
                        method: 'GET',
                        data: payload,
                    }).done(data => {
                        if (data.newConfig) {
                            this.editingWidget.config = data.newConfig;
                        }
                        this.$parent.$broadcast('config:update_metrics');
                    });
                } else if (this.type === 'none') {
                    const payload = {
                        widget_id: this.editingWidget.id,
                        select_type: 'none',
                    };
                    $.ajax({
                        url: '/dashboardv2/setWidgetInstances',
                        method: 'GET',
                        data: payload,
                    }).done(data => {
                        if (data.newConfig) {
                            this.editingWidget.config = data.newConfig;
                        }
                        this.$parent.$broadcast('config:update_metrics');
                    });
                }
                this.$parent.updatePending(this.editingWidget.id);
                this.editingWidget.pendNewConfig();
            },

            onSelect(model) {
                this.sendConfig();
            },
        },

        watch: {
            type: function(val) {
                if (!this.ready) { return; } 
                this.sendConfig();                
                Vue.nextTick(() => {
                    if (val === 'groups') {                                                
                        this.preselected_names = [];
                        this.names = [];
                    } else if (val === 'names') {                        
                        this.preselected_groups = [];
                        this.groups = [];                        
                    } else {
                        this.names = [];
                        this.groups = [];
                        this.preselected_names = [];
                        this.preselected_groups = [];
                    }
                });                
            },
            groups: function(val) {
                this.preselected_names = [];
                this.ready = true;
            },
            names: function(val) {
                this.preselected_groups = [];
                this.ready = true;
            },
            tags: function(val) {
                this.preselected_groups = [];
                this.preselected_names = [];
                this.sendConfig();
                this.ready = true;
            },
            tagsType: function(val) {
                this.sendConfig();
            },
        },

        computed: {
            headerText: function() {
                if (this.type === 'none') {
                    return '(All)';
                }
                let model = '';
                let number = 0;
                if (this.type === 'tags') {
                    model = 'Tags';
                    number = this.tags.length;
                    if (number === 1) {
                        model = 'Tag';
                    }
                } else if (this.type === 'names') {
                    model = 'Instances';
                    number = this.names.length;
                    if (number === 0 && this.preselected_names.length) {
                        number = this.preselected_names.length;
                    }
                    if (number === 1) {
                        model = 'Instance';
                    }
                } else if (this.type === 'groups') {
                    model = 'Groups';
                    number = this.groups.length;
                    if (number === 0 && this.preselected_groups.length) {
                        number = this.preselected_groups.length;
                    }
                    if (number === 1) {
                        model = 'Group';
                    }
                }
                return `(${number} ${model} selected)`;
            },
            allTags: function() {
                if (window.dashboard && window.dashboard.resellerDashboard) {
                    return [];
                } else if (this.$root.customerConfig) {
                    return this.$root.customerConfig.allTags;
                } else {
                    return [];
                }
            },
        },

        vueReady() {
            if (this.options.defaultType) {
                this.type = this.options.defaultType;
            } else {
                this.type = 'none';
            }

            if (this.options.typeOptions) {
                this.typeOptions = this.options.typeOptions;
            }

            if (window.dashboard.resellerDashboard) {
                // Remove all options except No Filter and Tags
                // Default to tags
                this.typeOptions = this.typeOptions.filter(o => {
                    return o.name === 'none' || o.name === 'tags';
                });
                this.type = 'tags';
            }

            const w = this.editingWidget;
            if (w.id < 0) { this.ready = true; return; }
            const cc = w.config.custom_controls;
            if (cc && cc.server_tags && cc.server_tags.length) {
                this.type = 'tags';
                this.tagsType = w.config.custom_controls.server_tag_match;
                this.tags = w.config.custom_controls.server_tags;
            } else if ((w.config.servers && w.config.servers.length)
                    || (w.config.compound_services && w.config.compound_services.length)) {
                this.type = 'names';
                const selected = [];
                if (w.config.servers) {
                    w.config.servers.forEach(item => {
                        selected.push(`s-${item}`);
                    });
                }
                if (w.config.compound_services) {
                    w.config.compound_services.forEach(item => {
                        selected.push(`cs-${item}`);
                    });
                }
                this.preselected_names = selected;
            } else if (w.config.server_groups && w.config.server_groups.length) {
                this.type = 'groups';
                const selected = [];
                w.config.server_groups.forEach(item => {
                    selected.push(`grp-${item}`);
                });
                this.preselected_groups = selected;
            } else if (cc && cc.server_filter_type === 'none') {
                this.type = 'none';
            }
            Vue.nextTick(() => {
                this.ready = true;
            });
        },
    });

    export default MetricModule;
</script>
