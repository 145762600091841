<style lang="scss">
.kubernetes-pod-count-widget {
    .metric-widget {
        flex-grow: 1;
        align-self: center;
        overflow: hidden;
    }
    .fitty {
        display: inline-block;
        white-space: nowrap;
        line-height: 1;
        margin: 0;
    }
}
</style>

<template>
    <p-widget-shell
        :id="id"
        :editing="editing"
        :config="localConfig"
        :content="content"
        :loading="isLoading"
        :configuration-modules="getConfigModules()"
        :option-modules="getOptionModules()"
        :valid="valid"
        :error-message="error_message"
        class="kubernetes-pod-count-widget"
        :background-color="backgroundColor"
    >
        <div
            v-if="valid"
            class="widget-body pa-mb-10 pa-flex"
            style="overflow-y: hidden; height: 100%; background-color: inherit;"
            ref="body"
            slot="body"
        >
            <div class="metric-widget">
                <div class="block">
                    <span ref="container_count" class="fitty pa-txt_medium" @click="openObjectListDrawer">
                        {{ count }}
                    </span>
                </div>
            </div>
        </div>
        <template slot="unconfigured-message">
            Please configure your widget.
        </template>
    </p-widget-shell>
</template>


<script>
    import Vue from 'vue';
    import fitty from "fitty";
    import widgetMixin from './../widgetMixin';
    import WidgetShell from './WidgetShell.vue'

    export default Vue.extend({
        mixins: [widgetMixin],

        components: {
            'p-widget-shell': WidgetShell,
        },

        data() {
            return {
                localConfig: this.config,
            };
        },

        events: {
            // Mixin: 'widget:new_data' -> this.initialize(config, content)
            // Mixin: 'widget:updated_data' -> this.update(content)
            // Mixin: 'widget:pend_config' -> this.pendNewConfig()
        },

        watch: {
            number() {
                Vue.nextTick(() => {
                    this.initFitty();
                });
            },
        },

        props: {
            editing: {
                type: Boolean,
                'default': false,
            },
            config: {
                type: Object,
                default() {
                    return {
                        custom_controls: {},
                    };
                },
            },
        },

        computed: {
            count: function() {
                return this.content.count || 0;
            },
        },

        methods: {
            openObjectListDrawer() {
                window.app.rootVue.$broadcast('drawer:load', {
                    id: 'dynamic-drawer',
                    url: '/report/KubernetesObjectListDrawer?widget_id=' + this.id + '&kind=pod',
                });
            },
            initFitty() {
                if (!this.$el || !this.$refs.container_count) {
                    return;
                }

                if (this.localConfig.height === 1) {
                    this.fitty = null;
                    this.fitty = fitty(this.$refs.container_count, {
                        maxSize: 12
                    });
                } else {
                    const metricWidget = this.$refs.body;
                    let maxSize = Math.min(Math.min(metricWidget.clientHeight, metricWidget.clientWidth), 512);

                    this.fitty = null;
                    this.fitty = fitty(this.$refs.container_count, { maxSize });
                }

                Vue.nextTick(() => {
                    this.fitText();
                });
            },

            fitText() {
                // Need to force reset the font size so it calculates
                // the size better when we call .fit()
                const resetFontSize = "font-size: 16px;";

                if (this.$el) {
                    if (this.fitty) {
                        this.fitty.element.style = resetFontSize;
                        this.fitty.fit();
                    }
                }
            },

            getConfigModules() {
                return [
                    { type: 'p-overview-module' },
                    { type: 'p-instance-module', options: { defaultType: 'none' } },
                ];
            },

            getOptionModules() {
                return [
                    {
                        type: 'p-custom-module',
                        options: {
                            "ignore-scope-module": {},
                            title: '',
                            controls: [
                                {
                                    label: 'Pod Phase',
                                    key: 'pod_phases',
                                    type: 'p-select',
                                    multiple: true,
                                    options: [
                                        { label: "Pending", value: "Pending" },
                                        { label: "Running", value: "Running" },
                                        { label: "Succeeded", value: "Succeeded" },
                                        { label: "Failed", value: "Failed" },
                                        { label: "Unknown", value: "Unknown" },
                                    ],
                                },
                                {
                                    id: 'backgroundColor',
                                    label: 'Background Color',
                                    key: 'backgroundColor',
                                    type: 'color-picker',
                                    default: '#ffffff'
                                },
                            ]
                        },
                    }
                ];
            },

            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this);
            },

            initialize(config, content) {
                this.$refs.body.style.filter = '';
                this.content = content;
                this.localConfig = {...config};
                this.reconfig();
            },

            reconfig() {
                Vue.nextTick(() => {
                    this.initFitty();
                });
            },

            update(content) {
                this.content = content;
                Vue.nextTick(() => {
                    this.initFitty();
                });
            },

            dumpState() {
                const obj = Object();
                obj.data = this.$data;
                obj.config = {...this.localConfig};
                console.log(JSON.stringify(obj));
            },
        },
        beforeDestroy() {
            this.fitty.unsubscribe();
            this.fitty = null;
        },
        mounted() {
            Vue.nextTick(() => {
                this.initFitty();
            });
            this.pendNewConfig();
            // Mixin: dispatch('widget:domready')
            if (this.id < 0) { this.configure(); };
        },
    });
</script>
