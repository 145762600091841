<template>
    <li>
        <a
            v-if="item.href"
            :href="item.href"
            class="pa-txt_dark"
        >
            {{ item.text }}
            <p-icon
                v-if="!isLast"
                icon="chevron-right"
                size="lg"
                color="cloudy-blue"
            >
            </p-icon>
        </a>
        <p-app-link
            v-else-if="item.to"
            :to="item.to"
        >
            {{ item.text }}
            <p-icon
                v-if="!isLast"
                icon="chevron-right"
                size="lg"
                color="cloudy-blue"
            >
            </p-icon>
        </p-app-link>
        <span v-else class="pa-txt_secondary">
            {{ item.text }}
            <p-icon
                v-if="!isLast"
                icon="chevron-right"
                size="lg"
                color="cloudy-blue"
            >
            </p-icon>
        </span>
    </li>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    props: {
        item: Object,
        isLast: Boolean,
    },
});
</script>
