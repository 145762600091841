<template>
    <div>
        <svg v-if="status === 'up'" viewBox="0 0 85 104" xmlns="http://www.w3.org/2000/svg" style="width: 19px; height: 24px;">
            <defs>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="up-gradient">
                    <stop stop-color="#5FEDC7" offset="0%"/>
                    <stop stop-color="#2BD9B0" offset="100%"/>
                </linearGradient>
            </defs>
            <path
                d="M89 27.337V97a7 7 0 0 1-7 7h-2a7 7 0 0 1-7-7V28.018l-20.65 20.65a6.993 6.993 0 0 1-9.89 0l-1.412-1.412a6.993 6.993 0 0 1 0-9.89L76.366 2.048a6.993 6.993 0 0 1 9.89 0l.029.03.03-.03 35.318 35.318a6.993 6.993 0 0 1 0 9.89l-1.413 1.412a6.993 6.993 0 0 1-9.89 0L89 27.338z"
                transform="translate(-39)"
                fill="url(#up-gradient)"
                fill-rule="evenodd"
            />
        </svg>
        <svg v-if="status === 'warning'" viewBox="0 0 85 104" xmlns="http://www.w3.org/2000/svg" style="width: 19px; height: 24px;">
            <defs>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="down-gradient">
                    <stop stop-color="#ffbb00" offset="0%"/>
                    <stop stop-color="#ffbb00" offset="100%"/>
                </linearGradient>
            </defs>
            <path
                d="M89 27.337V97a7 7 0 0 1-7 7h-2a7 7 0 0 1-7-7V28.018l-20.65 20.65a6.993 6.993 0 0 1-9.89 0l-1.412-1.412a6.993 6.993 0 0 1 0-9.89L76.366 2.048a6.993 6.993 0 0 1 9.89 0l.029.03.03-.03 35.318 35.318a6.993 6.993 0 0 1 0 9.89l-1.413 1.412a6.993 6.993 0 0 1-9.89 0L89 27.338z"
                transform="translate(-87, -26) rotate(180, 105, 65)"
                fill="url(#down-gradient)"
                fill-rule="evenodd"
            />
        </svg>
        <svg v-if="status === 'down'" viewBox="0 0 85 104" xmlns="http://www.w3.org/2000/svg" style="width: 19px; height: 24px;">
            <defs>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="down-gradient">
                    <stop stop-color="#d92121" offset="0%"/>
                    <stop stop-color="#f53131" offset="100%"/>
                </linearGradient>
            </defs>
            <path
                d="M89 27.337V97a7 7 0 0 1-7 7h-2a7 7 0 0 1-7-7V28.018l-20.65 20.65a6.993 6.993 0 0 1-9.89 0l-1.412-1.412a6.993 6.993 0 0 1 0-9.89L76.366 2.048a6.993 6.993 0 0 1 9.89 0l.029.03.03-.03 35.318 35.318a6.993 6.993 0 0 1 0 9.89l-1.413 1.412a6.993 6.993 0 0 1-9.89 0L89 27.338z"
                transform="translate(-87, -26) rotate(180, 105, 65)"
                fill="url(#down-gradient)"
                fill-rule="evenodd"
            />
        </svg>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
            };
        },

        props: {
            status: String,
        },
    });
</script>
