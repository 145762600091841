<style>
@-webkit-keyframes kf_el_1WXz_nKSPb_an_6YWUuGNdQ {
    0%,
    to {
        -webkit-transform: translate(134.63182067871094px, 30.67386245727539px)
            translate(-134.63182067871094px, -30.67386245727539px)
            translate(3px, 16px);
        transform: translate(134.63182067871094px, 30.67386245727539px)
            translate(-134.63182067871094px, -30.67386245727539px)
            translate(3px, 16px);
    }
}
@keyframes kf_el_1WXz_nKSPb_an_6YWUuGNdQ {
    0%,
    to {
        -webkit-transform: translate(134.63182067871094px, 30.67386245727539px)
            translate(-134.63182067871094px, -30.67386245727539px)
            translate(3px, 16px);
        transform: translate(134.63182067871094px, 30.67386245727539px)
            translate(-134.63182067871094px, -30.67386245727539px)
            translate(3px, 16px);
    }
}
@-webkit-keyframes kf_el_C9mxDIpIj5_an_m5-ABNKQU {
    0%,
    to {
        -webkit-transform: translate(134.63182067871094px, 30.67386245727539px)
            translate(-134.63182067871094px, -30.67386245727539px)
            translate(3px, 16px);
        transform: translate(134.63182067871094px, 30.67386245727539px)
            translate(-134.63182067871094px, -30.67386245727539px)
            translate(3px, 16px);
    }
}
@keyframes kf_el_C9mxDIpIj5_an_m5-ABNKQU {
    0%,
    to {
        -webkit-transform: translate(134.63182067871094px, 30.67386245727539px)
            translate(-134.63182067871094px, -30.67386245727539px)
            translate(3px, 16px);
        transform: translate(134.63182067871094px, 30.67386245727539px)
            translate(-134.63182067871094px, -30.67386245727539px)
            translate(3px, 16px);
    }
}
@-webkit-keyframes kf_el_qy50rYUQTi_an_0oxeB-sgh {
    0%,
    to {
        -webkit-transform: translate(128.75px, 25.5px)
            translate(-128.75px, -25.5px) translate(3px, 16px);
        transform: translate(128.75px, 25.5px) translate(-128.75px, -25.5px)
            translate(3px, 16px);
    }
}
@keyframes kf_el_qy50rYUQTi_an_0oxeB-sgh {
    0%,
    to {
        -webkit-transform: translate(128.75px, 25.5px)
            translate(-128.75px, -25.5px) translate(3px, 16px);
        transform: translate(128.75px, 25.5px) translate(-128.75px, -25.5px)
            translate(3px, 16px);
    }
}
@-webkit-keyframes kf_el_1WXz_nKSPb_an_zhficVJfUd {
    0%,
    5% {
        stroke-dasharray: 3, 10;
    }
    25%,
    to {
        stroke-dasharray: 14.1;
    }
}
@keyframes kf_el_1WXz_nKSPb_an_zhficVJfUd {
    0%,
    5% {
        stroke-dasharray: 3, 10;
    }
    25%,
    to {
        stroke-dasharray: 14.1;
    }
}
@-webkit-keyframes kf_el_1WXz_nKSPb_an_GbBsXtygK {
    0%,
    5% {
        opacity: 0;
    }
    25%,
    to {
        opacity: 1;
    }
}
@keyframes kf_el_1WXz_nKSPb_an_GbBsXtygK {
    0%,
    5% {
        opacity: 0;
    }
    25%,
    to {
        opacity: 1;
    }
}
@-webkit-keyframes kf_el_C9mxDIpIj5_an_9YMrGVH3R3 {
    0%,
    5% {
        stroke-dasharray: 3, 10;
    }
    25%,
    to {
        stroke-dasharray: 14.1;
    }
}
@keyframes kf_el_C9mxDIpIj5_an_9YMrGVH3R3 {
    0%,
    5% {
        stroke-dasharray: 3, 10;
    }
    25%,
    to {
        stroke-dasharray: 14.1;
    }
}
@-webkit-keyframes kf_el_C9mxDIpIj5_an_cJazlI_R7 {
    0%,
    5% {
        opacity: 0;
    }
    25%,
    to {
        opacity: 1;
    }
}
@keyframes kf_el_C9mxDIpIj5_an_cJazlI_R7 {
    0%,
    5% {
        opacity: 0;
    }
    25%,
    to {
        opacity: 1;
    }
}
@-webkit-keyframes kf_el_qy50rYUQTi_an_ZqNZ0eRZr {
    0% {
        opacity: 0;
    }
    20%,
    to {
        opacity: 1;
    }
}
@keyframes kf_el_qy50rYUQTi_an_ZqNZ0eRZr {
    0% {
        opacity: 0;
    }
    20%,
    to {
        opacity: 1;
    }
}
#el_sKGR_4fE2 * {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
}
#el_qy50rYUQTi {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: kf_el_qy50rYUQTi_an_ZqNZ0eRZr;
    animation-name: kf_el_qy50rYUQTi_an_ZqNZ0eRZr;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
#el_1WXz_nKSPb,
#el_C9mxDIpIj5 {
    -webkit-animation-fill-mode: forwards, forwards;
    animation-fill-mode: forwards, forwards;
    -webkit-animation-name: kf_el_C9mxDIpIj5_an_cJazlI_R7,
        kf_el_C9mxDIpIj5_an_9YMrGVH3R3;
    animation-name: kf_el_C9mxDIpIj5_an_cJazlI_R7,
        kf_el_C9mxDIpIj5_an_9YMrGVH3R3;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1),
        cubic-bezier(0, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1),
        cubic-bezier(0, 0, 1, 1);
}
#el_1WXz_nKSPb {
    -webkit-transform: translate(278.5px, 0) scale(-1, 1);
    transform: translate(278.5px, 0) scale(-1, 1);
    -webkit-animation-name: kf_el_1WXz_nKSPb_an_GbBsXtygK,
        kf_el_1WXz_nKSPb_an_zhficVJfUd;
    animation-name: kf_el_1WXz_nKSPb_an_GbBsXtygK,
        kf_el_1WXz_nKSPb_an_zhficVJfUd;
}
#el_1WXz_nKSPb_an_6YWUuGNdQ,
#el_C9mxDIpIj5_an_m5-ABNKQU,
#el_qy50rYUQTi_an_0oxeB-sgh {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: kf_el_qy50rYUQTi_an_0oxeB-sgh;
    animation-name: kf_el_qy50rYUQTi_an_0oxeB-sgh;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
}
#el_1WXz_nKSPb_an_6YWUuGNdQ,
#el_C9mxDIpIj5_an_m5-ABNKQU {
    -webkit-animation-name: kf_el_C9mxDIpIj5_an_m5-ABNKQU;
    animation-name: kf_el_C9mxDIpIj5_an_m5-ABNKQU;
}
#el_1WXz_nKSPb_an_6YWUuGNdQ {
    -webkit-animation-name: kf_el_1WXz_nKSPb_an_6YWUuGNdQ;
    animation-name: kf_el_1WXz_nKSPb_an_6YWUuGNdQ;
}
</style>
<template>
    <svg
        :width="width"
        :height="height"
        viewBox="130 40 25 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        id="el_sKGR_4fE2"
    >
        <g fill-rule="evenodd" fill="none">
            <g
                id="el_qy50rYUQTi_an_0oxeB-sgh"
                data-animator-group="true"
                data-animator-type="0"
            >
                <circle
                    id="el_qy50rYUQTi"
                    cx="139.25"
                    cy="36"
                    r="10.5"
                    :stroke="color"
                    stroke-width="3"
                />
            </g>
            <g
                id="el_C9mxDIpIj5_an_m5-ABNKQU"
                data-animator-group="true"
                data-animator-type="0"
            >
                <path
                    id="el_C9mxDIpIj5"
                    d="M134.632 30.674l9.236 10.652"
                    :stroke="color"
                    stroke-width="2.5"
                />
            </g>
            <g
                id="el_1WXz_nKSPb_an_6YWUuGNdQ"
                data-animator-group="true"
                data-animator-type="0"
            >
                <path
                    id="el_1WXz_nKSPb"
                    d="M134.632 30.674l9.236 10.652"
                    :stroke="color"
                    stroke-width="2.5"
                />
            </g>
        </g>
    </svg>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    props: {
        height: {
            type: String,
            default: "25"
        },
        width: {
            type: String,
            default: "25"
        },
        color: {
            type: String,
            default: "#e31212",
        },
    },
});
</script>
