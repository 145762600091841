<template>
    <component :is="computedDataPoint" @click="handleClick">
        <template slot="body">
            <slot></slot>
        </template>
        <template slot="hover">
            <slot name="hover"></slot>
        </template>
    </component>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        canEdit: Boolean,
    },
    computed: {
        computedDataPoint() {
            return this.canEdit ? 'p-data-point-btn' : 'p-data-point-info';
        },
    },
    methods: {
        handleClick(event) {
            this.$emit('click', event);
        },
    },
});
</script>