<style>
    .vertical-center {
          position: absolute;
          top: 50%;
          left: 20px;
          right: 20px;
          transform: translateY(-50%);
    }

    .grid-stack-item-content.placeholder {
        background-color: unset;
        background: unset;
        border: dashed 2px #396abf;
        border-radius: 2px;
    }
    .edit-mode .grid-stack-item-content.placeholder {
        box-shadow: unset;
        cursor: move;
    }
    .pa-btn_naked_light_grey_no_fill.white {
        background-color: #fff;
    }
</style>

<template>
    <div v-show="$parent.editing"
        :id="id"
         class="grid-stack-item"
         :data-gs-x="localConfig.col.toString()"
         :data-gs-y="localConfig.row.toString()"
         :data-gs-width="localConfig.width.toString()"
         :data-gs-height="localConfig.height.toString()"
         :data-gs-auto-position="false"
         :data-gs-locked="false">
        <div class="grid-stack-item-content placeholder">
            <div style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;">
                <div style="flex-grow: 1;">
                    <div class="vertical-center">
                        <button @click="placeWidgetBlock" class="pa-btn pa-btn_naked_light_grey_no_fill white pa-btn_lg" style="padding: 12px 35px" type="button">
                            <svg class="pa-icon pa-icon_blue"><use xlink:href="#plus-circle-outline"></use></svg>
                            Add Widget
                        </button>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>


<script>
    import Vue from 'vue';
    import widgetMixin from './widgetMixin';

    const Highcharts = require('highcharts');
    require('highcharts/modules/no-data-to-display')(Highcharts);

    const WidgetPlaceholder = Vue.extend({
        mixins: [widgetMixin],

        data() {
            return {
                localConfig: this.config,
            };
        },

        computed: {
            widgetBodyStyle: function() {
                let styles = {
                    height: "100%",
                    width: "100%",
                };

                if (!this.displayChart) {
                    styles = {
                        ...styles,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    };
                }

                return styles;
            }
        },

        methods: {
            initialize(config, content) {
                this.content = {...content};
                this.localConfig = {...config};
                // Give DOM a chance to swap in/out configure messages
                Vue.nextTick(this.reconfig);
                this.rendered = this.content.success;
            },

            getConfigModules() {
                return [];
            },

            getOptionModules() {
                return []
            },

            // Open configure drawer
            configure() {
                console.log('opening config drawer from placeholder');
                window.app.rootVue.$broadcast('dashboard:open_config', this);
            },
            update(content) {
                this.content = {...content};
            },
            dumpState() {
                const obj = Object();
                obj.data = this.$data;
                obj.config = this.localConfig;
                console.log(JSON.stringify(obj));
            },
            reconfig() {},
            placeWidgetBlock() {
                const payload = {
                    dashboard_id: this.$parent.id, // Change this to a dispatch?
                    connection_id: this.$parent.cId,  // Change this to a dispatch?
                    widget_type: 'p-widget-block',
                    width: this.localConfig.width,
                    height: this.localConfig.height,
                    row: this.localConfig.row,
                    col: this.localConfig.col,
                    title: this.localConfig.title,
                    scope_uuid: this.$parent.scopeUuid,
                };

                $.ajax('/dashboardv2/addWidget', {
                    method: 'GET',
                    data: payload,
                    context: this,
                }).done(function (data) {
                    if (!data.success) {
                        console.log(data.msg);
                        return;
                    }

                    window.app.rootVue.$broadcast('dashboard:confirm_delete', this.id, false);
                    window.app.rootVue.$broadcast('widget:add_widget', 'p-widget-block', data.new_id, this.localConfig);
                    window.app.rootVue.$broadcast('dashboard:add_placeholder');

                    Vue.nextTick(() => {
                        window.app.rootVue.$broadcast('dashboard:update_editing_widget', data.new_id);
                    });
                });

            },
        },

        props: {
            editing: {
                type: Boolean,
                default: false,
            }
        },
    });

    export default WidgetPlaceholder;
</script>
