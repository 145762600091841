<style>
</style>

<template>
    <div class="module">
        <div ref="body" v-show="open && show" transition="module-expand" class="module-body">
            <div class="pa-field pa-field_vertical">
                <div class="pa-field-hd">
                    <label for="template" class="pa-label">Unit</label>
                </div>
                <div class="pa-field-bd">
                    <p-select
                        ref="select"
                        :model.sync="unit"
                        :on-change-callback="selectChanged"
                        :options="unit_options"
                    >
                    </p-select>
                </div>
                <div class="pa-field-ft">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const BandwidthUnitModule = Vue.extend({
        events: {
            'bandwidthUnitModule:show'(id, value) {
                if(this.editingWidget.id == id) {
                    this.show = value;
                }
            },
        },

        data() {
            return {
                unit_options: [
                    { value: 'auto_scale', label: 'Auto Scale' },
                    { value: 'bytes/s', label: 'Bytes/s' },
                    { value: 'bits/s', label: 'Bits/s' },
                    { value: 'Kbit/s', label: 'Kbit/s' },
                    { value: 'Mbit/s', label: 'Mbit/s' },
                    { value: 'Gbit/s', label: 'Gbits/s' },
                    {value: 'Tbit/s', label: 'Tbit/s'},
                    {value: '%', label: 'Percentage'},                                    
                ],
                unit: 'auto_scale',
                show: true,
            }
        },

        props: {
            options: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            open: {
                type: Boolean,
                default: true
            },
            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        },

        methods: {
            toggle() {
                this.open = !this.open;
            },
            sendConfig() {
                this.$parent.updatePending(this.editingWidget.id);

                var payload = {
                                widget_id: this.editingWidget.id,
                                unit: this.unit
                              }
                $.ajax({
                    url: '/dashboardv2/setWidgetUnit',
                    method: 'POST',
                    data: payload,
                })
                    .done(data => {
                        if (data.success && data.newConfig) {
                            this.editingWidget.config = data.newConfig;
                        }
                        this.$parent.$broadcast('config:update_unit', { id: this.editingWidget.id, data_unit:this.unit });
                    });
                this.editingWidget.pendNewConfig();
            },
            selectChanged(model) {
                if (this.unit == "%") {
                    this.$parent.$broadcast('config:update_unit', { id: this.editingWidget.id, data_unit: this.unit });
                    this.sendConfig();              
                } else {
                    this.sendConfig();
                }
            },
        },

        watch: {
        },

        computed: {
            headerText: function() {
                let timeText = '';
                let matching = this.unit_options.find(t => {
                    return t.value === this.unit;
                });
                if (matching) {
                    timeText = matching.label;
                    return `(${timeText})`;
                } else {
                    return '';
                }
            },
        },

        created() {
            let defaultValue = this.options.defaultValue || 'auto_scale';
            let preset = this.editingWidget.selectedGraphUnit;
            if (this.options.unit_options) {
                this.unit_options = this.options.unit_options
            }
            else if (typeof preset === 'undefined' || preset === null) {
                preset = defaultValue;
            }
            this.unit = preset;
            this.ready = true;
            this.show = this.options && this.options.show === false ? false : true;
        }
    });

    export default BandwidthUnitModule;
</script>
