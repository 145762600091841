<style lang="scss">
    .avatar-circle {
        border-radius:50%;
        font-size:14px;
        color:#3954bf;
        text-align:center;
        background:#f1f3fb;
        border: 1px solid #3954bf;

        &.invert {
            color:#fff;
            background:#3954bf;
        }
    }
</style>

<template>
    <p-button
        v-if="showCircle && action"
        :onclick="action"
        unstyled
    >
        <div
            :class="circleClass"
            :style="circleStyle"
        >
            {{circleText}}
        </div>
    </p-button>

    <div
        v-else-if="showCircle && !action"
        :class="circleClass"
        :style="circleStyle"
    >
        {{circleText}}
    </div>

    <img
        v-else
        :src="computedSrc"
        :class="computedClass"
        :alt="computedAlt"
        @error="onImageError"
    />
</template>

<script>
import Vue from "vue";

// Do not load any image if none is associated with the email hash,
// instead return an HTTP 404 (File Not Found) response.
// This way we can fallback to the initials.
const DEFAULT_GRAVATAR = "404";
const DEFAULT_SRC = `http://www.gravatar.com/avatar/?d=${DEFAULT_GRAVATAR}`;

export default Vue.extend({
    data() {
        return {
            error: false,
        };
    },
    props: {
        src: String,
        rounded: Boolean,
        roundedFull: Boolean,
        alt: String,
        gravatarSize: {
            type: [String, Number],
            'default': 32,
        },
        initials: String,
        invert: Boolean,
        numAvatars: {
            type: [String, Number],
            'default': 1
        },
        noAvatar: Boolean,
        action: String,
    },
    methods: {
        onImageError() {
            this.error = true;
        },
        isGravatarSrc(src) {
            return src && src.includes("gravatar.com");
        },
    },
    computed: {
        computedClass() {
            const className = {
                "pa-rounded": this.rounded,
                "pa-rounded-full": this.roundedFull,
            };

            return className;
        },
        computedSrc() {
            let src = DEFAULT_SRC;

            if (this.src) {
                src = this.src;
            }

            if (this.isGravatarSrc(src)) {
                src = src.replace("?d=mm", "?d=404");

                if (this.gravatarSize) {
                    src += `&s=${this.gravatarSize}`;
                }
            }

            return src;
        },
        computedAlt() {
            if (this.alt) {
                return this.alt;
            }

            if (this.isGravatarSrc(this.src)) {
                return "Custom image generated by Gravatar.";
            }

            return "Avatar";
        },
        showCircle() {
            if (this.initials && this.error) {
                return true;
            }

            return (!this.src || this.src === '' || this.usingDefaultGravatar) &&
                (this.noAvatar || this.numAvatars || this.initials);
        },
        circleClass() {
            return {
                'avatar-circle': !this.invert,
                'avatar-circle invert': this.invert,
                'pa-txt_medium': this.circleText !== "+",
            }
        },
        circleStyle() {
            let fontSize = 12;
            let cursor = 'unset';
            if (this.gravatarSize >= 40 && this.gravatarSize < 50) {
                fontSize = 18;
            } else if (this.gravatarSize >= 50 && this.gravatarSize < 60) {
                fontSize = 20;
            } else if (this.gravatarSize >= 60) {
                fontSize = 24;
            }

            if (this.circleText === "+") {
                fontSize = 24;
                cursor = 'pointer';
            }

            return {
                'width': `${this.gravatarSize}px`,
                'height': `${this.gravatarSize}px`,
                'line-height': `${this.gravatarSize}px`,
                'font-size': `${fontSize}px`,
                'cursor': cursor,
            }
        },
        circleText() {
            if (this.numAvatars > 1) {
                return `+${this.numAvatars}`;
            } else if (this.noAvatar) {
                return '+';
            }
            return this.initials;
        },
        usingDefaultGravatar() {
            if (this.src == 'https://secure.gravatar.com/avatar/?d=mm') {
                return true;
            }
            return false;
        }
    },
});
</script>
