<template>
    <div class="pa-tableWrapper">
        <table class="pa-table">
            <thead>
                <tr class="pa-table-row pa-table-row_hd">
                    <th
                        :key="th"
                        is="p-table-head"
                        v-for="th in head"
                        :in-thead="true"
                        scope="col"
                        :model="th">
                        {{ th.text }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="pa-table-row" :key="rowIndex" v-for="(rowIndex, row) in body">
                    <template v-for="(tdIndex, td) in row">
                        <th
                            is="p-table-head"
                            v-if="td.isHead"
                            :id="makeTableCellID(td)"
                            :model="td"></th>
                        <th
                            v-if="td.isHead"
                            v-for="cell in hiddenCells"
                            style="display:none;">
                        </th>
                        <td
                            is="p-table-cell"
                            v-if="!td.isHead"
                            :id="makeTableCellID(td)"
                            :model="td"></td>
                    </template>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import Vue from 'vue';

    const Table = Vue.extend({
        created() {
            this.hiddenCells = [...this.head.slice(1)];
        },

        mounted() {
            let ths = [];
            let tds = [];

            this.$children.forEach((child) => {
                if (child.model.isHead) {
                    ths.push(Object.assign({}, {
                        id: child.id,
                        searchText: child.searchText
                    }));
                } else {
                    tds.push(Object.assign({}, {
                        id: child.id,
                        searchText: child.searchText
                    }));
                }
            });

            this.$children.forEach((child) => {
                if (child.model.isHead) {
                    tds.forEach((td) => {
                        if (td.id.charAt(0) !== child.id.charAt(0)) {
                            return;
                        }

                        child.searchText += `${td.searchText} `;
                    });
                } else {
                    ths.forEach((th) => {
                        if (th.id.charAt(0) !== child.id.charAt(0)) {
                            return;
                        }

                        child.searchText += `${th.searchText} `;
                    });
                }
            });
        },

        data() {
            return {
                // coordinates used to assign IDs to table cells
                coords: [0, 0],
                hiddenCells: []
            }
        },

        events: {
            ['tcell:option:changed'](details) {
                if (details.isHead) {
                    this.toggleCheckboxesAfter(details);
                }

                this.eventHub.$emit('table:tcell:option:changed', details);
            },

            ['thead:option:changed'](details) {
                const inThead = details.inThead;
                const model = details.model;

                if (inThead) {
                    this.toggleAllCheckboxes(model.isChecked);

                    return;
                }

                this.toggleCheckboxesAfter(model);
            }
        },

        methods: {
            makeTableCellID(cell) {
                if (cell.isHead) {
                    this.coords[0]++;
                }

                this.coords[1]++;

                return `${this.coords[0]}${this.coords[1]}-cell`;
            },

            toggleAllCheckboxes(isChecked) {
                this.body.forEach((row) => {
                    row.forEach((td) => {
                        td.isChecked = isChecked;
                    });
                });
            },

            toggleCheckboxesAfter(model) {
                let start = false;
                let stop = false;

                this.body.some((row) => {
                    row.some((td) => {
                        stop = start ? !!td.isHead : false;
                        start = start ? start : td === model;

                        if (start && stop === false) {
                            td.isChecked = model.isChecked;
                        }

                        return stop;
                    });

                    return stop;
                });
            }
        },

        props: {
            body: {
                type: Array,
                default: () => []
            },

            head: {
                type: Array,
                default: () => []
            }
        }
    });

    export default Table;
</script>
