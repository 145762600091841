<style lang="scss" scoped>
    .attribute-key-value {
        .pa-btn {
            .pa-icon {
                color: var(--p-blue-500, #3954bf) !important;
                fill: var(--p-blue-500, #3954bf) !important;
            }
        }
    }
</style>
<template>
    <div class="attribute-key-value pa-pb-12 pa-txt_13">
        <div v-show="!isEditing" class="attribute-key-value-initial pa-grid">
            <div class="pa-grid-col pa-grid-col_4of12">
                <span class="pa-break-words">{{ initialName || "-" }}</span>
            </div>
            <div class="pa-grid-col pa-grid-col_4of12">
                <span class="pa-break-words">{{ initialValue || "-" }}</span>
            </div>
            <div class="pa-grid-col pa-grid-col_4of12" style="align-items: start;">
                <div class="pa-grid">
                    <p-button v-on:click="toggleEdit" unstyled class="pa-ml-auto pa-mr-32" :disabled="disabled">
                        <p-icon
                            class="edit-attribute-button"
                            icon="pencil"
                        >
                        </p-icon>
                    </p-button>
                    <p-button v-on:click="_remove" unstyled :disabled="disabled">
                        <p-icon
                            class="remove-attribute-button"
                            size="xl"
                            icon="close"
                        >
                        </p-icon>
                    </p-button>
                </div>
            </div>
        </div>
        <div v-show="isEditing" class="attribute-key-value-edit">
            <div class="pa-grid">
                <div class="pa-grid-col pa-grid-col_4of12">
                    <p-input
                        :value="name"
                        v-model="name"
                        ref="nameInput"
                        placeholder="Key"
                    >
                    </p-input>
                </div>
                <div class="pa-grid-col pa-grid-col_4of12">
                    <p-input
                        placeholder="Value"
                        :value="value"
                        v-model="value"
                        ref="valueInput"
                        class="pa-txt_13"
                    >
                    </p-input>
                </div>
                <div class="pa-grid-col pa-grid-col_4of12 pa-justify-center">
                    <div class="pa-grid" style="color: black;">
                        <div class="pa-grid-col pa-grid-col_6of12">
                            <p-button
                                unstyled
                                no-base-class
                                class="pa-txt_13"
                                v-on:click="_cancel"
                            >
                                Cancel
                            </p-button>
                        </div>
                        <div class="pa-grid-col pa-grid-col_6of12">
                            <p-button
                                unstyled
                                no-base-class
                                class="pa-txt_13"
                                v-on:click="_save"
                            >
                                <p-icon icon="check" color="blue">
                                </p-icon>
                                Save
                            </p-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import axios from 'axios';
import Awesomplete from './../vendor/awesomplete/awesomplete.js';

const NEW_INPUT_VALUE = "new";

const AttributeKeyValue = Vue.extend({
    props: {
        attributeTypeId: [String, Number],
        id: [String, Number],
        index: Number,
        initialName: String,
        initialValue: String,
        save: Function,
        cancel: Function,
        remove: Function,
        isNew: Boolean,
        shouldFocus: Boolean,
        autoComplete: {
            type: Boolean,
            default: false,
        },
        disabled: Boolean,
    },
    data: function() {
        return {
            isEditing: false,
            name: "",
            value: "",
            attributeKeySuggestions: [],
        };
    },
    methods: {
        toggleEdit: function() {
            this.isEditing = !this.isEditing;
        },
        setEditFields: function() {
            this.name = this.initialName;
            this.value = this.initialValue;
        },
        resetFields: function() {
            this.name = "";
            this.value = "";
        },
        _cancel: function() {
            this.cancel(this.index);
            this.toggleEdit();
        },
        focusInput: function() {
            this.$nextTick(() => {
                this.$refs.nameInput.$el.focus();
            });
        },
        _remove: function() {
            this.remove(this.index);
        },
        _save: function() {
            const attribute = {
                id: this.id,
                serverAttributeType: {
                    id: this.attributeTypeId,
                    name: this.name,
                },
                value: this.value,
                isNew: this.isNew,
                shouldFocus: false,
            };
            this.save(this.index, attribute);
            this.toggleEdit();
        },

        initializeAwesomplete: function() {
            axios.get('/report/get_server_attribute_keys', {
                params: {
                    "search_type":"keys",
                },
            }).then(response => {
                if (!response.data.success) { return; }

                if (response.data.keys) {
                    this.attributeKeySuggestions = response.data.keys;
                    if (this.awesomplete) {
                        return;
                    }
                    this.awesomplete = new Awesomplete(this.$refs.nameInput.$el, {
                        list: this.attributeKeySuggestions,
                    });
                    this.$refs.nameInput.$el.addEventListener('awesomplete-selectcomplete', data => {
                        this.addAttrKey(data.text.value);
                    });
                } else {
                    console.error('Cannot retrieve attribute keys');
                }
            });
        },

        addAttrKey(val) {
            if (this.awesomplete.selected) {
                return;
            }
            this.name = val;
            this.newTag = '';
            if (this.awesomplete) {
                this.awesomplete.close();
            }
        },
    },
    watch: {
        isEditing: function(curr, prev) {
            if (curr) {
                this.setEditFields();
                this.focusInput();
            } else {
                this.resetFields();
            }
        },
    },
    vueReady: function() {
        if (this.shouldFocus) {
            this.isEditing = true;
        }

        this.initializeAwesomplete();
    },
});

export default AttributeKeyValue;
</script>
