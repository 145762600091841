<style scoped>
</style>

<template>
    <p-widget-shell
        :id="id"
        :editing="editing"
        :config="localConfig"
        :content="content"
        :loading="isLoading"
        :configuration-modules="getConfigModules()"
        :option-modules="getOptionModules()"
        :valid="valid"
        :error-message="error_message"
        :background-color="backgroundColor"
        :allowedExpand="true"
    >
        <div slot="widget-header-action" v-if="graphUnitOptions.length > 0" class="pa-pl-4">
            <p-graph-unit-select
                :graph-id="id"
                :graph-unit="getSelectedUnit()"
                :options="graphUnitOptions"
                :key="graphUnitComponentKey"
            >
            </p-graph-unit-select>
        </div>
        <div
            slot="body"
            class="widget-body"
            :style="(inModalData && !needsConfig) ? {'height': '74vh'}  : {'flex': '1', 'margin-bottom': '10px','height':' 0'}"
        >
            <div>
                <div ref="body"></div>
            </div>
        </div>
        <div slot="unconfigured-message">
            Please configure your widget.
            <div class="pa-hint" v-if="content.message">
                {{ content.message }}
            </div>
        </div>
    </p-widget-shell>
</template>

<script>
import Vue from 'vue';
import widgetMixin from '.././widgetMixin'
import WidgetShell from './WidgetShell.vue'
const Highcharts = require('highcharts');

export default Vue.extend({
    mixins: [widgetMixin],

    components: {
        'p-widget-shell': WidgetShell,
    },

    data() {
        return {
            content: {
                success: true,
            },
            chart: null,
            series: null,
            configModules: [
                {type: 'p-overview-module'},
                {
                    type: 'p-instance-metric-module',
                    options: {
                        instance_types: [
                            'network_device'
                        ]
                    }
                },
                ...((!this.inModalData ? this.$parent.isScoping() : this.$parent.$parent.isScoping()) ? [{ type: "p-ignore-scope-module" }] : []),

                {
                    type: 'p-custom-module',
                    options: {
                        title: '',
                        controls: [
                            {
                                id: 'backgroundColor',
                                label: 'Background Color',
                                key: 'backgroundColor',
                                type: 'color-picker',
                                default: '#ffffff'
                            },
                        ]
                    }
                },
            ],
            optionModules: [{type: 'p-timerange-module', options: {}}, {type: 'p-bandwidth-unit-module', options: {}}],
            unit: '',
            selectedGraphUnit: null,
            graphUnitOptions: [],
            localConfig: this.config,
            graphUnitComponentKey:0,
            inModalData: false,
            needsConfigData: false,
        }
    },

    props: {
        editing: {
            type: Boolean,
            'default': false,
        }
    },

    events: {
        'graph:changeUnit'(data) {
            if (data.id === this.id) {
                this.selectedGraphUnit = data.unit;

                Vue.nextTick(() => {
                    this.reconfig();
                });
            }
        },
        'config:update_unit'(data) {
            if (data.id === this.id && data.data_unit) {
                this.selectedGraphUnit = data.data_unit;

                Vue.nextTick(() => {
                    this.reconfig();
                });
            }

        },
        'dashboardmodal:close'() {
            this.inModalData = false
            setTimeout(() => {
                this.update(this.content);
                this.reconfig();
            },400);
        },
        'dashboardmodal:open'(idData, needsConfig) {
            if (this.id === idData) {
                this.inModalData = true;
                this.needsConfigData = needsConfig;

                setTimeout(() => {
                    this.update(this.content);
                    this.reconfig();
                },400);
            }
        },
    },

    methods: {
        initialize(config, content) {
            this.content = content;
            this.localConfig = { ...config };
            if (Boolean(content.converted_max_value) && content.unit !== "%" && content.unit.toLowerCase() !== "percent") {
                    this.graphUnitOptions = [...[{ label: content.unit, value: content.unit }]];
                }
            if (this.localConfig.custom_controls.widget_unit == "auto_scale") {
                this.unit = content.unit;
                this.selectedGraphUnit = content.unit;
            } else if (this.localConfig.custom_controls.widget_unit == "%") {
                this.unit = "%";
                this.selectedGraphUnit = "%";
            }
            else {
                this.unit = this.localConfig.custom_controls.widget_unit;
                this.selectedGraphUnit = this.localConfig.custom_controls.widget_unit;
            } 
            if (!this.selectedGraphUnit) {
                this.selectedGraphUnit = content.unit;
                if (Boolean(content.converted_max_value) && content.unit !== "%" && content.unit.toLowerCase() !== "percent") {
                    this.graphUnitOptions = [...[{ label: content.unit, value: content.unit }]];
                }
            }            
            // Give DOM a chance to swap in/out configure messages
            this.reconfig()
            this.rendered = this.content.success;
        },

        getSelectedUnit() {
            if (this.selectedGraphUnit) {
                return this.selectedGraphUnit;
            }
            if (this.localConfig) {
                if (this.localConfig.custom_controls.widget_unit == "%") {
                    this.selectedGraphUnit = "%";
                    return this.selectedGraphUnit;
                }else {
                    this.selectedGraphUnit = this.content.unit;
                    return this.selectedGraphUnit;
                }
            }            
        },
        
        configure() {
            window.app.rootVue.$broadcast('dashboard:open_config', this);
        },

        disable_loader: function() {
            this.isLoading = false;
        },

        getSeriesFromData: function(data) {
            const series = [];

            let i;

            if(!data.name.includes(`(${this.selectedGraphUnit})`)) {
                let nameWithoutUnits = data.name.split('(');
                nameWithoutUnits.pop();
                nameWithoutUnits = nameWithoutUnits.join('(');
                data.name = `${nameWithoutUnits} (${this.selectedGraphUnit})`;
            }

            for (i in data.monitors) {
                const monitor_id = data.monitors[i];

                series.push({
                    name: `${data.name} ${data.legends[monitor_id]}`,
                    data: data.series[monitor_id],
                    fillOpacity: 0.9,
                });

                let y = data.series[monitor_id].length - 1;

                while (this.latestValue === null && y > 0) {
                    const point = data.series[monitor_id][y];

                    if (point[1] !== null) {
                        this.latestValue = point[1];
                    }

                    y -= 1;
                }
            }

            return series;
        },

        reconfig() {
            window.app.rootVue.$broadcast('widget-content:ready', this.id);
            
            if (!this.content.success) {
                this.isLoading = false;
                return false;
            }

            var container = $("#" + this.id);

            let timezone = this.$parent.timezone; //window.userTZ;
            if(timezone == "UTC/UTC"){
                    timezone = "UTC";
            }
            if (!timezone) {
                const guessedTimezone = moment.tz.guess();

                if (guessedTimezone) {
                    timezone = guessedTimezone;
                }
            }
            if (typeof timezone !== 'undefined') {
                Highcharts.setOptions({
                    global: {
                        timezone: timezone,
                    },
                });
            }


            let series = this.getSeriesFromData(this.content);
            
            if (this.selectedGraphUnit === "%" && this.content.converted_max_value) {
                series = series.map(
                    (item) => ({
                        ...item,
                        data: item.data.map(
                            ([timestamp, value]) => ([
                                timestamp,
                                (Boolean(value))
                                    ? (value / this.content.converted_max_value) * 100
                                    : value
                            ])
                        )
                    })
                );
            }

            this.series = series;

            const chartConfig = {
                colors: ['#307FE2', '#48D597', '#e77f5b', '#63736f',
                    '#006db0', '#c5c7cf', '#ffb612', '#238ca3',
                    '#4c3430', '#753c2c', '#e97e3b', '#fdea6f',
                    '#2c0e40', '#742796', '#e65586', '#581d7f',
                    '#162065', '#8acdce', '#cee6ca', '#5ebcd2',
                    '#1e3082', '#c6e3cb', '#3984b6'],
                chart: {
                    spacingLeft: 0,
                    animation: true,
                    backgroundColor: this.backgroundColor,
                    borderColor: null,
                    type: "area",
                    height: container.height() - (this.inModalData ? 250 : 42),
                    plotShadow: false,
                    renderTo: this.$refs.body,
                    showAxes: true,
                    zoomType: 'x',
                    events: {
                        load: function(){
                            this.disable_loader()
                        }.bind(this),
                        selection: function(event) {
                            if (event.resetSelection) {
                                for (var i = 0; i < this.series.length; i++) {
                                this.chart.series[i].setData(this.series[i].data, false);
                                }

                                // Remove the zoom button, even if we're zoomed in multiple times
                                this.zoomed = false;
                                this.chart.zoom();

                                if (this.opposing || this.networkPort) {
                                this.chart.yAxis[0].setExtremes(-this.prezoomed_extreme, this.prezoomed_extreme, true, true);
                                }

                            } else {
                                this.zoomIn(event);
                            }
                        }.bind(this),
                    },
                },
                title: { text: null },
                xAxis: {
                    type: 'datetime',
                    endOnTick: false,
                    gridLineWidth: 0,
                    lineWidth: 0,
                    lineColor: '#ddd',
                    maxPadding: 0,
                    minPadding: 0,
                    plotBands: this.content.zones,
                    startOnTick: false,
                    tickLength: 0,
                    labels: {
                        align: 'center',
                        style: {
                            fontSize: '10px',
                            color: 'black',
                            fontWeight: 'none'
                        },
                    },
                    crosshair: {
                        dashStyle: 'shortdot',
                        width: this.crosshairs && 1.5 || 0,
                        color: "#999999",
                        snap: true
                    },
                },
                yAxis: {
                    gridLineColor: '#e6e6e6',
                    gridLineWidth: 1,
                    lineWidth: 0,
                    lineColor: '#ddd',
                    softMin: 0,
                    minorGridLineWidth: 1,
                    title: {
                        enabled: this.displayYAxisTitle,
                        text: this.selectedGraphUnit,
                        style: {
                            color: 'black'
                        }
                    },
                    plotLines: [{
                        value: 0,
                        width: 1,
                        color: '#808080'
                    }],
                    labels: {
                        style: {
                            fontSize: '10px',
                            color: 'black',
                            fontWeight: 'none'
                        }
                    },
                },
                tooltip: {
                    shadow: {
                        color: 'rgb(62, 64, 65)',
                        opacity: 0.1,
                        offsetX: 0,
                        offsetY: 1,
                        width: 4,
                    },
                    enabled: true,
                    useHTML: true,
                    split: false,
                    shared: false,
                    backgroundColor: '#ffffff',
                    borderWidth: 0,
                    borderRadius: 5,
                    padding: 6,
                    headerFormat: '',
                    formatter: function() {
                        const hasDec = (this.y % 1) !== 0;
                        let y = this.y;
                        if (hasDec) {
                            y = y.toFixed(2);
                        }
                        const ret = `
                        <div class='pa-flex'>
                            <span style='font-size: 22px; color: ${this.series.color}; line-height: 11px;'>
                                \u25CF
                            </span>
                            <div style='padding-left: 6px; padding-right: 20px;'>
                                <p class='pa-txt_medium' style='color: #3c3d3e; margin-bottom: 4px;'>
                                    ${this.series.name.replace("\n", " ")}: ${y}
                                </p>
                                <p class='pa-txt_normal' style='color: #95999b; font-size: 11px;'>
                                    ${Highcharts.dateFormat("%m/%d/%Y %H:%M", new Date(this.x))}
                                </p>
                            </div>
                        </div>
                        `;
                        return ret;
                    },
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M',
                        second: '%m/%d/%Y %H:%M',
                        minute: '%m/%d/%Y %H:%M',
                        hour: '%m/%d/%Y %H:%M',
                        day: '%m/%d/%Y %Y',
                        week: 'Week from %A, %b %e, %Y',
                        month: '%B %Y',
                        year: '%Y',
                    },
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                            },
                        },
                        states: {
                            hover: {
                                lineWidthPlus: 0,
                            },
                        },
                        animation: true,

                    },
                    area: {
                        fillOpacity: 0.3,
                    },
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    },
                    line: {
                        lineWidth: 1,
                        shadow: false,
                        connectNulls: false
                    },
                },
                legend: {
                    symbolHeight: 8,
                    symbolWidth: 8,
                    symbolRadius: 8,
                    align: 'left',
                    enabled: true,
                    layout: 'horizontal',
                    verticalAlign: 'bottom',
                    borderWidth: 0,
                    itemStyle: {
                        fontWeight: 'normal',
                        textOverflow: null,
                        width: '',
                    },
                    itemHiddenStyle: {
                        color: '#888888',
                    },
                    itemWidth: null,
                    useHTML: false,
                    maxHeight: this.maxLegendHeight,
                    navigation: {
                        animation: false,
                    },
                },
                credits: {
                    enabled: false,
                },
                navigation: {
                    buttonOptions: {
                        x: -20
                    }
                },
                // Deep copy the series so we keep the original
                series: JSON.parse(JSON.stringify(this.series)),
                exporting: {
                    enabled: false
                },
            };

             if(this.selectedGraphUnit == '%') {
                chartConfig.yAxis.min = -100;
                chartConfig.yAxis.max = 100;
            }

            this.chart = new Highcharts.Chart(chartConfig);
           

            this.isLoading = false;
        },

        update(content) {
            if (!content.success) {
                return;
            }
            let oldestRemoved = Infinity;
            let newMin = Infinity;

            for (let i = 0; i < content.monitors.length; i++) {
                // Check if this is a series we already have
                let matchingSeries = this.content.series[content.monitors[i]];

                

                // Gather some data on the existing series
                let lastTime;
                let lastVal;
                let closestRange;
                let highchartsSeries;
                if (matchingSeries) {
                    // Get the matching series that Highcharts has
                    highchartsSeries = this.chart.series.find(s => {
                        return s._i === i;
                    });
                    if (highchartsSeries && highchartsSeries.data.length) {
                        lastTime = highchartsSeries.data[highchartsSeries.data.length - 1].x;
                        lastVal = highchartsSeries.data[highchartsSeries.data.length - 1].y;
                        closestRange = highchartsSeries.closestPointRange;
                        if (!closestRange) {
                            closestRange = 0;
                        }
                    } else if (highchartsSeries) {
                        // No data in this series yet
                        lastTime = 0;
                        lastVal = 0;
                        closestRange = 0;
                    } else {
                        console.log('ERROR: Series ${matchingSeries.name} not in Highcharts');
                        // Treat as new
                        matchingSeries = null;
                    }

                    if (this.$parent.debug) {
                        const lastTimeDate = new Date(lastTime);
                        console.log(`\tLast point time: ${lastTimeDate}`);
                        console.log(`\tClosest point range: ${closestRange}`);
                        console.log(`\tNew data length: ${content.series[content.monitors[i]].length}`);
                    }
                } else {
                    lastTime = 0;
                    lastVal = 0;
                    closestRange = 0;
                }

                for (let j = 0; j < content.series[content.monitors[i]].length; j++) {
                    const newTime = content.series[content.monitors[i]][j][0];
                    const newVal = content.series[content.monitors[i]][j][1];
                    // Only add a point if it's sufficiently newer than our last known point
                    if (newTime > lastTime && newTime - lastTime > closestRange - 30000) {
                        if (newVal === null) {
                            continue;
                        }

                        if (matchingSeries) {
                            // Remove any trailing nulls
                            // Otherwise it'll leave a gap
                            if (lastVal === null) {
                                let removedCount = 0;
                                let k = matchingSeries.length - 1;
                                while (matchingSeries[k][1] === null) {
                                    matchingSeries.splice(k, 1);
                                    highchartsSeries.removePoint(k);
                                    removedCount += 1;
                                    k -= 1;
                                }
                            }

                            const removedPoint = matchingSeries[0];
                            let possibleMin = Infinity;
                            if (matchingSeries.length > 1) {
                                possibleMin = matchingSeries[1][0];
                            }
                            if (possibleMin < newMin) {
                                newMin = possibleMin;
                            }
                            if (this.$parent.debug) {
                                const newTimeDate = new Date(newTime);
                                console.log(`\tAdding point: ${newTimeDate}\t\t${newVal}`);
                                console.log(`\tRemoving point: ${new Date(removedPoint[0])}\t\t${removedPoint[1]}\n`);
                            }

                            // Check if our new update would change the unit completely. If it does, redraw the
                            // whole graph.
                            if (content.unit != this.selectedGraphUnit) {
                                this.$parent.requestRecreate(this.id);
                                return
                            }

                            // Update our local copy
                            matchingSeries.push(content.series[content.monitors[i]][j]);

                            matchingSeries.shift();

                            // Finally add point to Highcharts
                            if (!this.zoomed) {
                                highchartsSeries.addPoint(content.series[content.monitors[i]][j], false, false);
                            }
                        }
                    } else if (this.$parent.debug) {
                        console.log(`Discarding point with timestamp ${newTime}. Last time was ${lastTime}.`);
                    }
                }
            }

            if (newMin !== Infinity) {
                if (this.$parent.debug) {
                    const minTimeDate = new Date(newMin);
                    console.log(`Setting new min to ${minTimeDate}`);
                }
                if (!this.zoomed) {
                    this.chart.xAxis[0].setExtremes(newMin);
                }
                this.chart.series.forEach(s => {
                    const data = s.data;
                    if (!data.length) {
                        return;
                    }
                    let x = data[0].x;
                    while (x < newMin) {
                        if (this.$parent.debug) {
                            console.log(`Removed point from ${s.name}`);
                        }
                        s.removePoint(0, false);
                        x = s.data[0];
                    }
                });
                for (let i = 0; i < content.monitors.length; i++) {
                    // Check if this is a series we already have
                    const data = this.content.series[content.monitors[i]];
                    if (!data.length) {
                        return;
                    }
                    let x = data[0][0];
                    while (x < newMin) {
                        data.splice(0, 1);
                        if (data[0]) {
                            x = data[0][0];
                        } else {
                            x = newMin + 1;
                        }
                    }
                }
            }


            if (content.zones.length) {
                const bands = this.chart.xAxis[0].plotLinesAndBands;
                bands.forEach(e => {
                    e.destroy();
                });
                content.zones.forEach($.proxy(function(e) {
                    this.chart.xAxis[0].addPlotBand(e);
                }, this));
            }

            this.chart.redraw();

        },

        getConfigModules() {
            return this.configModules;
        },

        getOptionModules() {
            return this.optionModules;
        },

        zoomIn(event) {
            event.preventDefault();
            const utcstart = (event.xAxis[0].min / 1000);
            const utcend = (event.xAxis[0].max/ 1000);
            const payload = {
                widget_id: this.id,
                start_time: utcstart,
                end_time: utcend,
                server_resources: this.content.monitors,
            };
            $.ajax('/dashboardv2/getZoomData', {
                method: 'GET',
                data: payload,
                context: this,
            })
            .done(function(data) {
                if (!data.success) {
                    console.log(data.msg);
                    return;
                }
                // Do 2 renders here to make it flow right: one to change the series, one to change the bounds
                this.chart.xAxis[0].setExtremes(this.chart.xAxis[0].min, this.chart.xAxis[0].max, false, false);
                this.chart.yAxis[0].setExtremes(this.chart.yAxis[0].min, this.chart.yAxis[0].max, false, false);
                for (var i = 0; i < data.series.length; i++) {
                    this.chart.series[i].setData(data.series[i].data, false);
                }
                this.chart.redraw(true);

                // If an opposing or network port graph, force positive and negative scales to be the same
                if (this.opposing || this.networkPort) {
                    var dExt;
                    var ext = this.chart.yAxis[0].getExtremes();
                    var dMax = Math.abs(ext.dataMax);
                    var dMin = Math.abs(ext.dataMin);
                    dMax >= dMin ? dExt = dMax : dExt = dMin;
                    var min = 0 - dExt;
                    this.chart.yAxis[0].setExtremes(min, dExt);
                    this.chart.xAxis[0].setExtremes(data.xmin, data.xmax, false, false);
                } else {
                    this.chart.xAxis[0].setExtremes(data.xmin, data.xmax, false, false);
                    this.chart.yAxis[0].setExtremes(data.ymin, data.ymax, false, false);
                }

                this.chart.redraw(true);
                if (!this.zoomed) {
                    this.chart.showResetZoom();
                    this.zoomed = true;
                }

            });
        },
    },
    watch: {
        selectedGraphUnit() {
            this.graphUnitComponentKey += 1;            
        }
    },

    mounted() {
        this.pendNewConfig();
        if (this.id < 0) { this.configure(); };
    },
})
</script>
