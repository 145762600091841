<style>
    .port-scan-message {
        display: flex;
        justify-content: center;
        padding: 40px 0 40px 0;
    }
    /* Fixes tags input overflow */
    #bulk_tag .pa-panel-bd {
        overflow: visible;
    }
</style>

<template>
    <div>
    <div class="monitoring-catalog">
        <div class="catalog-sticky-header-bd">
            <div class="pa-grid" :style="{ padding: showCheckboxes ? '10px 30px 10px 23px' : '0 30px' }">
              <div v-if="showCheckboxes" class="pa-grid-col pa-grid-col_8of12" style="display: block;">
                <button v-if="canAddMetrics"
                        @click="openServiceCard"
                        id="add-monitoring"
                        class="pa-btn pa-btn_blue"
                        style="width: 150px; height: 35px; margin-right: 5px;">
                  Add Monitoring
                </button>
                <button v-if="canTagMetrics"
                        id="tag"
                        class="pa-btn pa-btn_secondary checkbox_input"
                        style="width: 110px; height: 35px; margin-right: 5px;"
                        :disabled="!checked.length"
                        v-p-dialog-open
                        target="bulk_tag">
                  <svg class="pa-icon pa-icon_sm pa-icon_light pa-icon_r">
                    <use xlink:href="#tag-outline"></use></svg> Tag
                </button>
                <button v-if="canPauseMetrics"
                        id="pause"
                        class="pa-btn pa-btn_secondary checkbox_input"
                        style="width: 110px; height: 35px; margin-right: 5px;"
                        :disabled="!checked.length"
                        v-p-prompt-open
                        target="dynamic_prompt"
                        title="Pause Metrics?"
                        body="Are you sure you wish to pause these metrics on the Server?"
                        :callback="bulkPause">
                  <svg class="pa-icon pa-icon_sm pa-icon_light pa-icon_r">
                    <use xlink:href="#pause-circle-outline"></use></svg> Pause
                </button>
                <button v-if="canPauseMetrics"
                        id="resume"
                        class="pa-btn pa-btn_secondary checkbox_input"
                        style="width: 110px; height: 35px; margin-right: 5px;"
                        :disabled="!checked.length"
                        v-p-prompt-open
                        target="dynamic_prompt"
                        title="Resume Metrics?"
                        body="Are you sure you wish to resume these metrics on the Server?"
                        :callback="bulkResume">
                  <svg class="pa-icon pa-icon_sm pa-icon_light pa-icon_r">
                    <use xlink:href="#play-circle-outline"></use></svg> Resume
                </button>
                <button v-if="canDeleteMetrics"
                        id="remove"
                        class="pa-btn pa-btn_secondary checkbox_input"
                        style="width: 110px; height: 35px;"
                        :disabled="!checked.length"
                        v-p-prompt-open
                        target="dynamic_prompt"
                        title="Remove Metrics?"
                        body="Are you sure you wish to remove these metrics from the Server?"
                        :callback="bulkDelete">
                  <svg class="pa-icon pa-icon_sm pa-icon_light pa-icon_r"><use xlink:href="#delete"></use></svg> Remove
                </button>
              </div>
              <!-- No-config space holder -->
              <div v-else class="pa-grid-col pa-grid-col_8of12" style="display: block;">
              </div>
              <div class="pa-grid-col pa-grid-col_2of12">
              </div>
              <div class="pa-grid-col pa-grid-col_2of12">
                <ul class="pa-hList" style="text-align: right; justify-content: flex-end;">
                  <li style="padding-right: 8px; padding-top: 3px;">
                    <form class="pa-input pa-input_phony pa-input_sm" onsubmit="event.preventDefault();return;">
                      <label for="search" class="pa-isVisuallyHidden">Search</label>
                      <input data-v-e1ce633e=""  v-model="tableSearch" class="pa-input pa-input_search" placeholder="Search Metrics"
                        style="height: 32px; width: 250px;">
                    </form>
                  </li>
                </ul>
              </div>
            </div>
        </div>
        <div v-if="loading" class="catalog-body-loading">
            <div class="pa-loader"></div>
        </div>
        <div v-else class="catalog-body">
            <div v-if="!networkDeviceFirstScanDone" class="port-scan-message">
                <h3 class="pa-hdg pa-hdg_3">Initial SNMP port discovery is in progress. </h3>
            </div>
            <table v-else class="catalog-table">
                <tr>
                    <th style="width: 5%;">
                        <label v-if="showCheckboxes" class="pa-option" style="padding: 0 37.5%; margin-left: -2px;">
                            <input @click="checkAll($event)" type="checkbox" class="pa-option-input"></input>
                            <span class="pa-option-icon" style=""></span>
                        </label>
                    </th>
                    <th style="width: 0px;"></th>
                    <th style="width: 30%;">Metric</th>
                    <th style="width: 40%;">Alerts</th>
                    <th style="width: 7%;">Frequency</th>
                    <th style="width: 13%;">Tags</th>
                    <th style="width: 5%;"></th>
                </tr>
                <tr v-if="!localCategories.detected.length && !localCategories.added.length && canAddMetrics">
                    <td colspan="7" style="border-top: solid 1px #dbdee0;">
                        <p class="pa-txt pa-txt_light" style="text-align: center; margin: 10px 0;">Click <button style="margin:0px 7px 0px 7px;" class="pa-btn pa-btn_secondary pa-btn_secondary_inverse" @click="openServiceCard">Add Monitoring</button> to get started with monitoring this instance.</p>
                    </td>
                </tr>
                <tr v-for="c in localCategories.detected" class="category-row detected">
                    <td colspan="6" style="position: relative;">
                        <span class="category-title">Discovered: {{c.name}}</span>
                        <button @click="addService(c.textkey)" class="pa-btn monitor-btn" :id="'add-cat-'+classify(c.name)">Monitor</button>
                        <a class="pa-txt pa-txt_xs" style="cursor: pointer;" @click="openOptOut(c)">Don't show me this</a>
                    </td>
                    <td style="text-align: right; padding-right: 20px;">
                        <svg class="pa-icon" style="cursor: pointer;" @click="dismissCategory(c.textkey)"><use xlink:href="#close"></use></svg>
                    </td>
                </tr>
                <tbody v-for="c in localCategories.added">
                    <tr class="category-row added" :class="[openedCategories.includes(c.name) ? 'cat-row-collapse' : 'cat-row-expand']"
                        @click="toggleCategory($event, c.name)">
                        <td>
                            <label v-if="showCheckboxes" class="pa-option" style="padding: 0 37.5%; margin-left: 1px;">
                                <input @click="checkCategory($event, c)" type="checkbox" class="pa-option-input"></input>
                                <span class="pa-option-icon"></span>
                            </label>
                        </td>
                        <td colspan="3">
                            <div style="display: inline-block;">
                                <span class="category-title">{{c.name}}</span>
                                <div v-if="!isTemplate && !c.metrics.length" style="position:relative; display: inline-block;">
                                    <p-tooltip :hover="true" :advanced="true" :hover-timeout="300" :wait-time="300">
                                        <svg slot="trigger" class="pa-icon pa-icon_sm pa-icon_block" style="margin-left: 5px; fill: #f06313; position: relative; top: 2px;">
                                            <use xlink:href="#alert-circle-outline"></use>
                                        </svg>
                                        <template v-if="hasAgent">
                                            <p v-if="c.errors.length">
                                                We've not been able to find this application yet. Please click
                                                <svg @click="showDocs(c)" style="width: 12px; height: 12px;">
                                                    <use xlink:href="#docs"></use>
                                                </svg>
                                                to read the installation docs.
                                            </p>
                                            <p v-else>You haven't added any metrics yet. Click <a @click="openDrawer($event, c.textkey, c.name)">Add Metric</a> to get started.</p>
                                        </template>
                                        <template v-if="!hasAgent">
                                            <p v-if="!isNetOnly(c) && !c.is_snmp && !isContainer">
                                                The Panopta agent was not found on this instance. Please install it to monitor these metrics.
                                            </p>
                                            <p v-else>You haven't added any metrics yet. Click <a @click="openDrawer($event, c.textkey, c.name)">Add Metric</a> to get started.</p>
                                        </template>
                                    </p-tooltip>
                                </div>
                            </div>
                            <span v-if="!c.numFiltered"
                                class="metric-count" style="margin-left: 5px; margin-right: 10px;">
                                ({{c.metrics.length}} metrics{{checkedCounts[c.textkey] && ', ' + checkedCounts[c.textkey] + ' selected' || ''}})
                            </span>
                            <span v-else
                                class="metric-count" style="margin-left: 5px; margin-right: 10px;">
                                ({{c.metrics.length-c.numFiltered}} of {{c.metrics.length}} metrics{{checkedCounts[c.textkey] && ', ' + checkedCounts[c.textkey] + ' selected' || ''}})
                            </span>
                            <svg v-if="openedCategories.includes(c.name)" class="pa-icon" style="fill: #4a4a4a">
                                <use xlink:href="#chevron-down"></use>
                            </svg>
                            <svg v-else class="pa-icon" style="fill: #4a4a4a">
                                <use xlink:href="#chevron-right"></use>
                            </svg>
                        </td>
                        <td style="text-align: right; padding-right: 15px;" colspan="3">
                            <button v-if="canAddMetrics && c.addable && !c.is_snmp" class="pa-btn pa-btn_secondary compact add-metric-cat-row" @click="openDrawer($event, c.textkey, c.name)">Add Metric</button>
                            <a v-if="canAddMetrics && c.addable && c.is_snmp && !isTemplate" class="pa-btn pa-btn_secondary compact" :href="`/config/SNMPCatalog?server_id=${serverId}`">SNMP Catalog</a>
                            <div v-if="canAddMetrics && c.addable && c.is_snmp && isTemplate && c.name !== 'SNMP'">
                                <a class="pa-btn pa-btn_secondary compact" href="#" @click="openMetricDrawer($event, {server_id: serverId, send_new: true, type: 'snmp_template', parent_textkey: c.base_oid})">Add Filter</a>
                                <a class="pa-btn pa-btn_secondary compact" :href="`/config/SNMPCatalog?server_id=${serverId}`">SNMP Catalog</a>
                            </div>
                            <div v-if="canAddMetrics && c.addable && c.is_snmp && isTemplate && c.name === 'SNMP'">
                                <a class="pa-btn pa-btn_secondary compact" :href="`/config/SNMPCatalog?server_id=${serverId}`">SNMP Catalog</a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 0; vertical-align: top;" colspan="7">
                            <div v-show="openedCategories.includes(c.name)" class="metric-box">
                                <p-metric-list
                                  @on-checked="handleChecked"
                                  :category.sync="c"
                                  :checked-model.sync="checked"
                                  :search-model.sync="tableSearch"
                                  :can-add-metrics="canAddMetrics"
                                  :show-metric-checkboxes="showCheckboxes"
                                  >
                                </p-metric-list>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <p-metric-drawer ref="metric-drawer"></p-metric-drawer>
    <p-editmetric-drawer ref="edit-metric-drawer"></p-editmetric-drawer>
    <p-service-card ref="service-card"></p-service-card>
    <p-modal id="opt_out">
        <div class="pa-panel">
            <div class="pa-panel-hd">
                <h2 class="pa-hdg pa-hdg_3" slot="header">Ignore discovered application</h2>
            </div>
            <div class="pa-panel-bd" style="text-align: center;">
                <p class="pa-txt">Do you want to always ignore {{optOutCategory && optOutCategory.name}} when discovered on your account?</p>
                <label class="pa-option">
                    <input type="checkbox" v-model="optOutAll" class="pa-option-input" />
                    <span class="pa-option-icon"></span>
                    <span class="pa-option-txt pa-txt_secondary">
                        Hide all discovered applications on my account (not advised)
                    </span>
                </label>
            </div>
            <div class="pa-panel-ft">
                <ul class="pa-split">
                    <li></li>
                    <li>
                        <button class="pa-btn pa-btn_cancel" type="button" v-p-dialog-close target="opt_out">Cancel</button>
                        <button @click="optOutDiscovery" class="pa-btn" value="Confirm">Confirm</button>
                    </li>
                </ul>
            </div>
        </div>
    </p-modal>
    <p-modal id="bulk_tag">
        <div class="pa-panel">
            <div class="pa-panel-hd">
                <h2 class="pa-hdg pa-hdg_3" slot="header">Bulk tag metrics</h2>
            </div>
            <div class="pa-panel-bd" style="text-align: center;">
                <div class="pa-field">
                    <div class="pa-field-hd">
                        <label for="bulk-tags" class="pa-label">Tags to add</label>
                    </div>
                    <div class="pa-field-bd" style="text-align: left;">
                        <p-tags-input ref="bulktaggle" name="tags" :model.sync="bulkTags" :autocomplete="true" :suggestions="allTags"></p-tags-input>
                    </div>
                </div>
            </div>
            <div class="pa-panel-ft">
                <ul class="pa-split">
                    <li></li>
                    <li>
                        <button class="pa-btn pa-btn_cancel" type="button" v-p-dialog-close target="bulk_tag">Cancel</button>
                        <button @click="bulkTag" class="pa-btn" value="Confirm" :disabled="bulkTagSpinner">
                            <svg v-show="bulkTagSpinner" class="pa-icon spin-8step" style="margin-right: 5px"><use xlink:href="#spinner-spin-light"></use></svg>
                            {{bulkTagSpinner ? 'Saving...' : 'Confirm'}}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </p-modal>
    </div>
</template>

<script>
    import Vue from 'vue';

    import EditMetricDrawer from './EditMetricDrawer.vue';
    import MetricDrawer from './MetricDrawer.vue';
    import MetricList from './MetricList.vue';
    import ServiceCard from './ServiceCard.vue';

    export default Vue.extend({
        components: {
            'p-editmetric-drawer': EditMetricDrawer,
            'p-metric-drawer': MetricDrawer,
            'p-metric-list': MetricList,
            'p-service-card': ServiceCard,
        },

        events: {
            'category-added': function(category) {
                let newCategory = Object();
                Object.assign(newCategory, category);
                newCategory.metrics = [];
                newCategory.errors = [];
                if (newCategory.messages) {
                    newCategory.errors = newCategory.messages;
                }
                newCategory.addable = true;
                newCategory.numFiltered = 0;
                this.openedCategories.push(newCategory.name);
                this.localCategories.added.push(newCategory);
                this.localCategories = {
                    ...this.localCategories,
                    added: [...this.localCategories.added]
                };
                this.localCategories.added.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
                let removeIndex = this.localCategories.detected.findIndex((c) => {
                    return c.textkey === newCategory.textkey;
                });
                if (removeIndex > -1) {
                    this.localCategories.detected.splice(removeIndex, 1);
                }
            },
            'metric-added': function(categoryTextkey, categoryName, instance) {
                let index = this.localCategories.added.findIndex((c) => {
                    return c.name === categoryName;
                });
                if (index > -1) {
                    let category = this.localCategories.added[index];
                    let metricIndex = category.metrics.findIndex((m) => {
                        return m.id === instance.id || -m.id === instance.id;
                    });
                    if (metricIndex > -1) {
                        Object.assign(category.metrics[metricIndex], instance);
                    } else {
                        category.metrics.push(instance);
                    }
                    category.metrics.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });
                    this.$broadcast('highlight-metric', categoryName, instance);
                } else {
                    // Need to create a new faux-category
                    this.localCategories.added.push({
                        addable: true,
                        errors: [],
                        metrics: [instance],
                        name: categoryName,
                        numFiltered: 0,
                        status: 'added',
                        textkey: categoryTextkey,
                    });
                    this.localCategories.added.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });
                    Vue.nextTick(() => {
                        this.$broadcast('highlight-metric', categoryName, instance);
                    });
                }
                this.eventHub.$emit('reload_graph_config');
            },
            'highlight-metric': function(categoryName, instance) {
                if (!this.openedCategories.includes(categoryName)) {
                    this.openedCategories.push(categoryName);
                }
            },
        },

        data() {
            return {
                localCategories: this.categories,
                openedCategories: [],
                checked: [],
                tableSearch: '',
                loading: true,
                optOutCategory: null,
                optOutAll: false,
                bulkTagSpinner: false,
                bulkTags: [],
                localApplications: this.applications,
            };
        },

        computed: {
            checkedCounts: function() {
                let returnObj = {};
                this.localCategories.added.forEach((c) => {
                    returnObj[c.textkey] = 0;
                });
                this.checked.forEach((checked) => {
                    let containingCategory = this.localCategories.added.find((category) => {
                        let index = category.metrics.findIndex((m) => {
                            return m.id === checked;
                        });
                        return index > -1;
                    });
                    if (containingCategory) {
                        returnObj[containingCategory.textkey] += 1;
                    }
                });
                return returnObj;
            },
            showCheckboxes: function() {
                return this.canAddMetrics || this.canTagMetrics || this.canPauseMetrics || this.canDeleteMetrics;
            }
        },

        props: {
            categories: {
                type: Object,
                default: function() {
                    return {
                        detected: [],
                        added: [],
                    };
                },
            },
            applications: {
                type: Object,
                default: function() {
                    return {
                        detected: [],
                        not_detected: [],
                    };
                }
            },
            isServer: {
                type: Boolean,
                default: true,
            },
            isTemplate: {
                type: Boolean,
                default: false,
            },
            isContainer: {
                type: Boolean,
                default: false,
            },
            serverId: {
                type: Number,
                default: null,
            },
            hasAgent: {
                type: Boolean,
                default: false,
            },
            fromAppliance: {
                type: Boolean,
                default: false,
            },
            applianceId: {
                type: Number,
                default: null,
            },
            netflowApplianceId: {
                type: Number,
                default: null
            },
            startOpen: {
                type: Boolean,
                default: false,
            },
            allTags: Array,
            seenMetricDrawer: {
                type: Boolean,
                default: true,
            },
            canAddMetrics: {
                type: Boolean,
                default: false,
            },
            canTagMetrics: {
                type: Boolean,
                default: false,
            },
            canPauseMetrics: {
                type: Boolean,
                default: false,
            },
            canDeleteMetrics: {
                type: Boolean,
                default: false,
            },
            snmpScanInProgress: {
                type: Boolean,
                default: false
            },
            networkDeviceFirstScanDone: {
                type: Boolean,
                default: true,
            }
        },

        methods: {
            handleChecked(value) {
                this.checked = _.xor(this.checked, [value]);
            },
            categoryRowClass: function(category) {
                if (category.status === 'added') {
                    return 'added';
                } else if (category.status === 'discovered') {
                    style['background-color'] = '#b2ebf2';
                }
            },
            toggleCategory: function(event, name) {
                if (event.target.classList.contains('pa-option-icon')) {
                    return;
                }
                const index = this.openedCategories.indexOf(name);
                if (index > -1) {
                    this.openedCategories.splice(index, 1);
                } else {
                    this.openedCategories.push(name);
                }
            },
            dismissCategory: function(textkey) {
                var payload = {
                    server_id: this.serverId,
                    category_textkey: textkey,
                };
                $.ajax("dismiss_category", {
                    method: 'GET',
                    data: payload,
                    context: this
                })
                .done(data =>{
                    if (!data.success) {
                        console.log(data.msg)
                        return;
                    }
                    let newCategories = this.localCategories.detected.filter((c) => {
                        return c.textkey !== textkey;
                    });
                    this.localCategories = {
                        ...this.localCategories,
                        detected: [...newCategories]
                    };
                });
            },
            addService: function(textkey) {
                var payload = {
                    server_id: this.serverId,
                    category_textkey: textkey,
                };
                $.ajax("get_metric_category_data_for_add", {
                    method: 'GET',
                    data: payload,
                    context: this
                })
                .done(data =>{
                    if (!data.success) {
                        console.log(data.msg)
                        return;
                    }
                    let index = this.localCategories.detected.findIndex((c) => {
                        return c.textkey === textkey;
                    });
                    if (index > -1) {
                        let category = this.localCategories.detected.splice(index, 1);
                        if (category.length) {
                            category = category[0];
                        } else {
                            return;
                        }
                        category.addable = true;
                        category.numFiltered = 0;
                        category.errors = data.messages;
                        this.openedCategories.push(category.name);
                        this.localCategories.added.push(category);
                        this.localCategories.added.sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });
                        if (data.has_supported_metric || this.isTemplate) {
                            this.openDrawer(null, textkey, null);
                        } else {
                            const showedDocs = this.showDocs(category);
                            if (!showedDocs) {
                                // We don't have docs for this category
                                // Open the drawer instead
                                this.openDrawer(null, textkey);
                            }
                        }
                    }
                });
            },
            deleteCategory: function(category) {
                var payload = {
                    server_id: this.serverId,
                    category_textkey: category.textkey,
                };
                if (category.is_snmp) {
                    payload.is_snmp = true;
                }
                $.ajax("/report/remove_metric_category", {
                    method: 'GET',
                    data: payload,
                    context: this
                })
                .done(data =>{
                    if (!data.success) {
                        console.log(data.msg)
                        return;
                    }
                    let index = this.localCategories.added.findIndex((c) => {
                        return c.textkey === category.textkey;
                    });
                    if (index > -1) {
                        this.localCategories.added.splice(index, 1);
                    }
                    index = this.localApplications.detected.findIndex((c) => {
                        return c.textkey === category.textkey;
                    });
                    if (index > -1) {
                        let app = this.localApplications.detected[index];
                        this.localApplications.detected.splice(index, 1);
                        app.status = "not-detected";
                        this.localApplications.not_detected.push(app);
                    }
                });
            },
            openDrawer: function(event, textkey, categoryName) {
                if (typeof event !== 'undefined' && event) {
                    event.stopPropagation();
                }
                let subCategoryName = null;
                if (categoryName && categoryName.includes(':')) {
                    const parts = categoryName.split(':');
                    subCategoryName = parts[parts.length - 1].trim();
                }
                this.$broadcast('metric-drawer:open', this.serverId, textkey, subCategoryName);
            },

            openMetricDrawer: function(event, metric) {
                if (typeof event !== 'undefined' && event) {
                    event.stopPropagation();
                }
                this.$refs['edit-metric-drawer'].openWithMetric(metric);
            },

            openServiceCard: function(textkey) {
                this.$broadcast('service-card:open');
            },
            checkCategory: function(event, c) {
                if (typeof event !== 'undefined' && event) {
                    event.stopPropagation();
                }
                if (event.target.checked) {
                    c.metrics.forEach((m) => {
                        if (!this.checked.includes(m.id)) {
                            if (this.tableSearch.length) {
                                const matches = m.name.toLowerCase().includes(this.tableSearch.toLowerCase());
                                if (matches) {
                                    this.checked.push(m.id);
                                }
                            } else {
                                this.checked.push(m.id);
                            }
                        }
                    });
                } else {
                    let ids = [];
                    c.metrics.forEach((m) => {
                        ids.push(m.id);
                    });
                    let newChecked = this.checked.filter((c) => {
                        return !ids.includes(c);
                    });
                    this.checked = [...newChecked];
                }
            },
            checkAll: function(event) {
                Object.keys(this.localCategories.added).forEach((key) => {
                    let category = this.localCategories.added[key];
                    if (event.target.checked) {
                        category.metrics.forEach((m) => {
                            if (!this.checked.includes(m.id)) {
                                if (this.tableSearch.length) {
                                    const matches = m.name.toLowerCase().includes(this.tableSearch.toLowerCase());
                                    if (matches) {
                                        this.checked.push(m.id);
                                    }
                                } else {
                                    this.checked.push(m.id);
                                }
                            }
                        });
                        $('.category-row .pa-option-input').prop('checked', true);
                    } else {
                        let ids = [];
                        category.metrics.forEach((m) => {
                            ids.push(m.id);
                        });
                        let newChecked = this.checked.filter((c) => {
                            return !ids.includes(c);
                        });
                        this.checked = [...newChecked];
                        $('.category-row .pa-option-input').prop('checked', false);
                    }
                });
            },
            bulkTag: function() {
                this.bulkTagSpinner = true;
                var payload = {
                    server_id: this.serverId,
                    appliance_id: this.applianceId,
                    checked: this.checked.join(","),
                    new_tags: this.bulkTags.join(","),
                };
                $.ajax("/config/monitoring/bulk_tag_metrics", {
                    method: 'POST',
                    data: payload,
                    context: this
                })
                .done(data => {
                    if (!data.success) {
                        console.log(data.msg)
                        return;
                    }
                    for (var i = 0; i < this.localCategories.added.length; i++) {
                        this.localCategories.added[i].metrics.forEach(m => {
                            if (this.checked.includes(m.id)) {
                                this.bulkTags.forEach(t => {
                                    if (!m.tags.includes(t)) {
                                        m.tags.push(t);
                                    }
                                });
                            }
                        });
                    }

                    this.showToast(`Successfully tagged ${this.checked.length} metrics`);
                    this.checked = [];
                    this.bulkTags = [];
                    this.$refs.bulktaggle.deleteAllTags();
                    window.app.rootVue.$broadcast('dialog:close', 'bulk_tag');
                    this.bulkTagSpinner = false;
                });
            },
            bulkDelete: function() {
                const payload = {
                    server_id: this.serverId,
                    appliance_id: this.applianceId,
                    checked: this.checked.join(','),
                };
                $.ajax('/config/DeleteMultipleServices', {
                    method: 'POST',
                    data: payload,
                    context: this,
                })
                .done(data => {
                    for (var i = 0; i < this.localCategories.added.length; i++) {
                        const newMetrics = this.localCategories.added[i].metrics.filter(m => {
                            return !this.checked.includes(m.id);
                        });
                        if (this.localCategories.added[i].metrics.length !== newMetrics.length) {
                            this.localCategories = {
                                ...this.localCategories,
                                added: [...this.localCategories.added.map((category, index) => {
                                    if (i === index) {
                                        return {
                                            ...category,
                                            metrics: [...newMetrics]
                                        };
                                    }

                                    return category;
                                })]
                            };
                        }
                    }
                    this.showToast(`Successfully removed ${this.checked.length} metrics`);
                    this.checked = [];
                    window.app.rootVue.$broadcast('dialog:close', 'dynamic_prompt');
                    this.eventHub.$emit('reload_graph_config');
                    if (!data.success) {
                        console.log(data.msg);
                        return;
                    }
                });
            },
            bulkPause: function() {
                var payload = {
                    server_id: this.serverId,
                    appliance_id: this.applianceId,
                    checked: this.checked.join(","),
                };
                $.ajax("/config/PauseMultipleServices", {
                    method: 'POST',
                    data: payload,
                    context: this
                })
                .done(data =>{
                    window.app.rootVue.$broadcast('dialog:close', 'dynamic_prompt');
                    window.location.reload();
                });
            },
            bulkResume: function() {
                var payload = {
                    server_id: this.serverId,
                    appliance_id: this.applianceId,
                    checked: this.checked.join(","),
                };
                $.ajax("/config/ResumeMultipleServices", {
                    method: 'POST',
                    data: payload,
                    context: this
                })
                .done(data =>{
                    window.app.rootVue.$broadcast('dialog:close', 'dynamic_prompt');
                    window.location.reload();
                });
            },
            showDocs: function(category) {
                let found = this.$refs['service-card'].detected.find((a) => {
                    return a.textkey === category.textkey;
                });
                if (found && found.doc_name) {
                    found.messages = category.errors;
                    this.$refs['service-card'].showDocs(found);
                    Vue.nextTick(() => {
                        this.openServiceCard();
                    });
                    return true;
                }
                return false;
            },
            classify: function(string) {
                return string.replace(/ /g, '-');
            },
            isNetOnly: function(category) {
                let app = this.localApplications.detected.find((a) => {
                    return a.textkey === category.textkey;
                });
                if (app) {
                    return Boolean(app.net_only);
                }
                return false;
            },
            openOptOut: function(category) {
                this.optOutCategory = category;
                this.optOutAll = false;
                this.$broadcast('dialog:open', 'opt_out');
            },
            optOutDiscovery: function(category) {
                if (!this.optOutCategory) { return; }
                var payload = {
                    category_textkey: this.optOutCategory.textkey,
                    opt_out_all: this.optOutAll,
                };
                $.ajax("opt_out_metric_category", {
                    method: 'GET',
                    data: payload,
                    context: this
                })
                .done(data =>{
                    if (!data.success) {
                        console.log(data.msg)
                        return;
                    }
                    this.$broadcast('dialog:close', 'opt_out');
                    if (!this.optOutAll) {
                        let newCategories = this.localCategories.detected.filter((c) => {
                            return c.textkey !== this.optOutCategory.textkey;
                        });
                        this.localCategories = {
                            ...this.localCategories,
                            detected: [...newCategories]
                        };
                    } else {
                        this.localCategories = {
                            ...this.localCategories,
                            detected: [],
                        };
                    }
                });
            },
            showCountermeasure: function(metric, delay, countermeasureId) {
                this.$refs['edit-metric-drawer'].showCountermeasure(metric, delay, countermeasureId);
            },
            showToast: function(text) {
                window.app.toastManager.clearToasts();
                window.app.toastManager.showToast(text);
            },

            pollNetworkDeviceFirstScanDone: function() {
                $.ajax("/report/networkDeviceFirstScan", {
                    method: "GET",
                    data: {server_id: this.serverId},
                    context: this
                }).done(data => {
                    if (data.success) {
                        this.networkDeviceFirstScanDone = data.first_port_scan_done;
                        if (!this.networkDeviceFirstScanDone) {
                            setTimeout(() => {this.pollNetworkDeviceFirstScanDone()}, 20000)
                        } else {
                            this.getMonitoringConfigData();
                        }
                        return true;
                    } else {
                        return false;
                    }
                })
            },

            getMonitoringConfigData: function() {
                var payload = {
                    server_id: this.serverId,
                };
                if (this.applianceId !== null) {
                    payload.appliance_id = this.applianceId;
                }
                if (this.netflowApplianceId !== null) {
                    payload.netflow_appliance_id = this.netflowApplianceId;
                }
                $.ajax("/report/get_monitoring_config_data", {
                        method: 'GET',
                        data: payload,
                        context: this
                    })
                    .done(data => {
                        if (!data.success) {
                            console.log(data.msg)
                            return;
                        }
                        this.loading = false;
                        // Setup our counters so Vue can bind
                        Object.keys(data.categories.added).forEach((key) => {
                            data.categories.added[key].numFiltered = 0;
                        });
                        this.localCategories = {...data.categories};
                        Vue.nextTick(() => {
                            this.localCategories.added.forEach(c => {
                                this.openedCategories.push(c.name);
                            });
                            if (this.startOpen) {
                                window.setTimeout(() => {
                                    this.openServiceCard();
                                }, 700);
                            } else {
                                // Add an id to the first metric expand icon on the page
                                // used by hopscotch
                                const expandIcons = $('.pa-icon.expand-metric');
                                if (expandIcons.length) {
                                    expandIcons[0].id = 'first-metric-expand';
                                }
                            }
                        });
                    });
                }
        },

        vueReady() {
            this.getMonitoringConfigData();

            window.catalogVue = this;

            if (!this.networkDeviceFirstScanDone) {
                this.pollNetworkDeviceFirstScanDone();
            }
        },
    });
</script>
