<template>
    <th v-if="isHeader" class="chevron">
    </th>
    <td class="chevron" v-else>
        <div v-if="rowData.children === true || (rowData.children && rowData.children.length)" class="chevron-column-hitbox" @click="onClick">
            <svg v-if="expanded" class="pa-icon pa-icon_xxl chevron">
                <use xlink:href="#chevron-down"></use>
            </svg>
            <svg v-else class="pa-icon pa-icon_xxl chevron">
                <use xlink:href="#chevron-right"></use>
            </svg>
        </div>
    </td>
</template>

<script>
    import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin.vue';

    export default {
        name: 'chevron',

        mixins: [VuetableFieldMixin],

        data() {
            return {
            };
        },

        props: {
        },

        computed: {
            expanded() {
                // Strict
                return this.rowData.expanded === true;
            },
        },

        methods: {
            onClick() {
                this.$parent.$parent.chevronClicked(this.rowData);
            },
        },
    };
</script>
