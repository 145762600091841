<style lang="scss">
@import "~styles/core/helpers/_variables.scss";

#port-selection-drawer {
    .acknowledge-incident-footer {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        box-sizing: border-box;
        background: #f2f3f5;
        box-shadow: 0 -2px 0 0 rgba(132, 142, 153, 0.2);
        z-index: 10;
    }

    .port-selection-table {
        tr {
            th {
                vertical-align: bottom;

                &:first-child {
                    padding-left: 4px;
                }
            }

            td {
                font-size: 14px !important;
                vertical-align: middle;
            }
        }
    }
}
</style>

<template>
    <p-drawer ref="drawer" side="right" id="port-selection-drawer" :width="700" sticky-footer>
        <div slot="trigger" style="display: none;"></div>
        <div slot="head">
            <p-icon icon="port-selection" size="xxxl" class="pa-mr-8">
            </p-icon>
            <span class="pa-txt_medium">
                Port Selection ({{ selectedPorts.length }}/{{ ports.length }})
            </span>
        </div>
        <div slot="body" class="pa-txt_13 pa-px-24 pa-py-16" style="height: calc(100% - 87px); overflow-y: auto;">

            <p-loading-spinner v-if="loading">
            </p-loading-spinner>

            <form v-else id="port-selection" @submit.prevent="handleSubmit">

                <p-alert
                    v-if="hasInvalidRegex"
                    variant="red"
                    full-width
                    icon="critical-outline"
                    icon-position="left"
                    icon-size="lg"
                    bold
                >
                    <p>Invalid regex: Please fix before submitting.</p>
                    <ul class="pa-uList">
                        <li :key="regex" v-for="regex in invalidRegex">
                            {{ regex }}
                        </li>
                    </ul>
                </p-alert>

                <h4 class="pa-txt_medium pa-txt_16 pa-mb-16">
                    Select Ports to Monitor
                </h4>

                <div class="pa-field pa-mb-16">
                    <div class="pa-field-bd">
                        <label v-if="isTemplate" class="pa-option">
                            <input type="radio" class="pa-option-input" value="device" v-model="portSelectionType" />
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt">
                                Use Device's Settings
                            </span>
                        </label>
                        <label :class="{ 'pa-option': true, 'pa-ml-32': isTemplate }">
                            <input type="radio" class="pa-option-input" value="all" v-model="portSelectionType" />
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt">
                                All Ports
                            </span>
                        </label>
                        <label v-if="!isTemplate" class="pa-option pa-ml-32">
                            <input type="radio" class="pa-option-input" value="none" v-model="portSelectionType" />
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt">
                                Add No Ports
                            </span>
                        </label>
                        <label v-if="!isTemplate" class="pa-option pa-ml-32">
                            <input type="radio" class="pa-option-input" value="manual" v-model="portSelectionType" />
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt">
                                Manual Select
                            </span>
                        </label>
                        <label class="pa-option pa-ml-32">
                            <input type="radio" class="pa-option-input" value="name" v-model="portSelectionType" />
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt">
                                By Name Filter
                            </span>
                        </label>
                    </div>
                </div>

                <p-divider class="pa-mb-16"></p-divider>

                <p-input
                    v-if="portSelectionType === 'manual'"
                    placeholder="Search"
                    :value="searchTerm"
                    v-model="searchTerm"
                    class="pa-mb-24"
                >
                </p-input>

                <template v-if="portSelectionType === 'name'">

                    <h4 class="pa-txt_medium pa-txt_16 pa-mb-16">
                        Port Filter (OR)
                    </h4>

                    <p-row
                        :key="index"
                        v-for="(filter, index) in filters"
                        class="pa-pb-8"
                        align-center
                    >

                        <p-column cols="3">
                            <p-select
                                :options="matchTypes"
                                size="stretch"
                                :model.sync="filter.match"
                            >
                            </p-select>
                        </p-column>

                        <p-column cols="4">
                            <p-input
                                :value="filter.value"
                                v-model="filter.value"
                                type="text"
                            >
                            </p-input>
                        </p-column>

                        <p-column cols="1">
                            <p-button
                                @click="removeFilter(index)"
                                unstyled
                                no-base-class
                            >
                                <p-icon icon="x">
                                </p-icon>
                            </p-button>
                        </p-column>

                    </p-row>

                    <p-button
                        @click="addNewFilter"
                        variant="secondary"
                        class="pa-mt-8 pa-mb-16"
                    >
                        <p-icon icon="plus">
                        </p-icon>
                        Add Filter
                    </p-button>

                </template>

                <table v-if="!isTemplate" class="port-selection-table pa-table">
                    <thead>
                        <tr>
                            <th v-if="portSelectionType === 'manual'">
                                <label v-if="hasPorts" class="pa-option">
                                    <input
                                        type="checkbox"
                                        class="pa-option-input"
                                        v-model="allSelected"
                                        :disabled="portSelectionType === 'all'"
                                    />
                                    <span class="pa-option-icon"></span>
                                </label>
                            </th>
                            <th>Port Name</th>
                            <th v-if="portSelectionType === 'name'">
                                Filter Match
                            </th>
                            <th>Description</th>
                            <th>Admin Status</th>
                            <th>Operational Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="port.index" v-for="port in filteredTableData">
                            <td v-show="portSelectionType === 'manual'" style="width: 20px;">
                                <label class="pa-option">
                                    <input
                                        type="checkbox"
                                        class="pa-option-input"
                                        :value="port.index"
                                        v-model="selectedPorts"
                                        :disabled="port.templateName"
                                    />
                                    <span class="pa-option-icon"></span>
                                    <div v-if="port.templateName" style="position: relative; margin-left: 3px; display: inline-block;">
                                        <p-tooltip2 :hover="true" :hover-timeout="300">
                                            <p-icon slot="trigger" icon="content-copy" size="sm" block></p-icon>
                                            <span v-html="port.templateName"></span>
                                        </p-tooltip2>
                                    </div>
                                </label>
                            </td>
                            <td>
                                {{
                                    port.alias ||
                                    port.name ||
                                    ""
                                }}
                                <div v-if="port.templateName && portSelectionType !== 'manual'" style="position: relative; margin-left: 3px; display: inline-block;">
                                   <p-tooltip2 :hover="true" :hover-timeout="300">
                                        <p-icon slot="trigger" icon="content-copy" size="sm" block></p-icon>
                                        <span v-html="port.templateName"></span>
                                    </p-tooltip2>
                                </div>
                            </td>
                            <td v-show="portSelectionType === 'name'">
                                <p-flex align-center>
                                    <p-icon
                                        middle
                                        :icon="matchedFilterPorts[port.index] || port.templateName
                                        ? 'checkmark-solid'
                                        : 'cross-circle'"
                                        :color="matchedFilterPorts[port.index] || port.templateName
                                        ? 'green'
                                        : 'red'"
                                        class="pa-pr-8"
                                    >
                                    </p-icon>
                                    <p class="pa-m-0">
                                        {{
                                            matchedFilterPorts[port.index] || port.templateName
                                            ? "Yes"
                                            : "No"
                                        }}
                                    </p>
                                </p-flex>
                            </td>
                            <td>
                                {{ port.descr }}
                            </td>
                            <td>
                                {{ port.admin_status }}
                            </td>
                            <td>
                                {{ port.oper_status }}
                            </td>
                        </tr>
                        <tr v-show="!hasPorts">
                            <td colspan="3" class="pa-txt_centered">
                                No ports found
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div v-if="isOpen" class="acknowledge-incident-footer">
                    <div class="acknowledge-incident-footer-wrapper pa-grid pa-justify-end pa-py-12">
                        <button @click="close" type="button" class="pa-btn pa-btn_blue-secondary pa-mr-12">
                            Cancel
                        </button>
                        <button
                            :disabled="submitting || hasInvalidRegex"
                            type="submit"
                            class="pa-btn pa-btn_blue pa-mr-24"
                            :style="{ opacity: submitting ? 0.6 : 1 }"
                        >
                            {{ submitting ? 'Saving...' : 'Save' }}
                        </button>
                    </div>
                </div>

            </form>

        </div>

    </p-drawer>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import {
    getDevicePorts
} from "../../api/services/OnboardingService";

const DRAWER_ID = 'port-selection-drawer';
const LOCKED_BODY_CLASS = 'server-report-locked';

export default Vue.extend({
    events: {
        [DRAWER_ID + ':open']: function(data) {
            // Reset form before opening to erase any unsaved changes
            // or to set new data from saved changes
            this.getDevicePorts(data);
            this.$refs.drawer.open();
        },
        [DRAWER_ID + ':close']: function() {
            this.$refs.drawer.close();
        },
        'drawer-open': function(drawerId, data) {
            if (drawerId !== DRAWER_ID) {
                return;
            }


            if (document.body.scrollHeight > window.innerHeight) {
                document.documentElement.classList.add(LOCKED_BODY_CLASS);
            }

            if (data && data.serverId) {
                this.getDevicePorts(data);
            }

            this.isOpen = true;
        },
        'drawer-close': function(drawerId) {
            if (drawerId !== DRAWER_ID) {
                return;
            }

            if (document.documentElement.classList.contains(LOCKED_BODY_CLASS)) {
                document.documentElement.classList.remove(LOCKED_BODY_CLASS);
            }

            this.isOpen = false;
        },
    },
    data() {
        return {
            submitting: false,
            loading: true,
            isOpen: false,
            ports: [],
            portSelectionType: "",
            searchTerm: "",
            selectedPorts: [],
            allSelected: false,
            matchTypes: [
                { value: 'contains', label: 'Contains' },
                { value: '!contains', label: 'Does Not Contain' },
            ],
            filters: [{ match: "contains", value: "" }],
            serverId: null,
            isTemplate: false,
            validRegex: {},
            matchedFilterPorts: {},
        };
    },
    watch: {
        portSelectionType(curr, prev) {
            if (curr === "all") {
                this.allSelected = true;
            } else {
                this.allSelected = false;
            }

            if (curr === "name") {
                this.debouncedUpdateFilteredPorts();
            }

            if (prev === "name") {
                this.selectedPorts = [];
            }
        },
        allSelected(curr, prev) {
            if (curr && (this.portIndexes && this.portIndexes.length > 0)) {
                this.selectedPorts = [...this.portIndexes];
            } else {
                this.selectedPorts = [];
            }
        },
        filters: {
            deep: true,
            handler(curr) {
                if (this.portSelectionType === "name") {
                    this.debouncedUpdateFilteredPorts();
                }
            },
        },
    },
    computed: {
        hasInvalidRegex() {
            return (
                this.invalidRegex &&
                this.invalidRegex.length > 0
            );
        },
        invalidRegex() {
            const invalidRegex = [];

            Object.keys(this.validRegex).forEach((regex) => {
                if (!this.validRegex[regex]) {
                    invalidRegex.push(regex);
                }
            });

            return invalidRegex;
        },
        portIndexes() {
            return this.filteredTableData && this.filteredTableData.length
                ? this.filteredTableData.map((port) => port.index.toString())
                : [];
        },
        hasPorts() {
            return (
                this.filteredTableData &&
                this.filteredTableData.length !== 0
            );
        },
        filteredTableData() {
            const searchTerm = this.searchTerm.trim().toLowerCase();

            if (this.ports && this.ports.length > 0) {
                if (this.portSelectionType === "manual" && searchTerm) {
                    return this.ports.filter(
                        (port) => {
                            return port.searchValues.some(
                                (val) => val.includes(searchTerm)
                            );
                        }
                    );
                }
            }

            return [...this.ports];
        },
    },
    methods: {
        debouncedUpdateFilteredPorts() {
            const matchedFilterPorts = this.ports.reduce((acc, port) => ({
                ...acc,
                [port.index]: this.matchesSomeFilters(
                    port.searchValues.join(" ")
                )
            }), {});
            this.matchedFilterPorts = {...matchedFilterPorts};
            this.selectedPorts = Object.entries(matchedFilterPorts).
                filter((port) => port[1])
                .map((port) => port[0])
        },
        matchesSomeFilters(value) {
            const _value = value.trim();

            let matchesSomeFilters = false;

            if (this.filters.length > 0) {
                matchesSomeFilters = this.filters.some(filter => {
                    const filterValue = filter.value.trim();

                    let regex;

                    this.validRegex[filterValue] = false;

                    try {
                        regex = new RegExp(`${filterValue}`, 'i');
                    } catch(e) {
                        return false;
                    }

                    this.validRegex[filterValue] = true;

                    let doesMatch = false;

                    if (filter.match === "contains" && filterValue !== "") {
                        doesMatch = regex && regex.test(_value);
                    } else if (filter.match === "!contains" && filterValue !== "") {
                        doesMatch = regex && !regex.test(_value);
                    }

                    return doesMatch;
                });
            }

            return matchesSomeFilters;
        },
        removeFilter(index) {
            this.filters = [
                ...this.filters.slice(0, index),
                ...this.filters.slice(index + 1)
            ];
        },
        addNewFilter() {
            this.filters.push({ match: "contains", value: "" });
        },
        close() {
            this.$refs.drawer.close();
        },
        handleSubmit() {

            // Check to see if they have un-selected any ports and give a warning
            let removed = this.originalSelectedPorts.filter(x => !this.selectedPorts.includes(x));
            if (removed.length > 0) {
                let numRemoved = removed.length == 1 ? "One port has" : removed.length + " ports have";
                if (!confirm(numRemoved + " been unselected, which will remove metric history for these ports.  Are you sure you want to proceed?")) { return }
            }

            const formData = {
                serverId: this.serverId,
                filters: [],
                portSelectionType: this.portSelectionType,
                selectedPorts: this.selectedPorts,
                searchTerm: "",
                totalPortCount: this.ports.length,
            };

            if (this.portSelectionType === "name") {
                formData.filters = this.filters.filter(
                    (_filter) => !!_filter.value && _filter.value.trim() !== ""
                );
            }

            if (this.portSelectionType === "manual") {
                formData.searchTerm = this.searchTerm.trim();
            }

            window.app.rootVue.$broadcast(
                "port-selection-submitted",
                formData
            );

            this.close();
        },
        initData(data, savedData) {
            const ports = data.ports.map(
                (port) => ({
                    ...port,
                    index: port.index.toString()
                })
            );
            this.ports = [...ports];

            let selectedPorts = ports
                .filter(port => port.isActive)
                .map(port => port.index);
            let filters = [...data.portFilters.filters];
            let searchTerm = data.portFilters.searchTerm;
            let portSelectionType;
            if (data.filter_type) {
                portSelectionType = data.filter_type;
            } else {
                portSelectionType = "all";
            }

            if (savedData) {
                if (savedData.portSelectionType) {
                    portSelectionType = savedData.portSelectionType;
                }

                if (savedData.filters) {
                    filters = [...savedData.filters];
                }

                if (savedData.searchTerm) {
                    searchTerm = savedData.searchTerm;
                }

                if (savedData.selectedPorts && portSelectionType !== "all") {
                    selectedPorts = [...savedData.selectedPorts];
                }
            }

            if (portSelectionType === "all") {
                this.allSelected = true;
                selectedPorts = [...data.ports.map(port => port.index.toString())];
            }

            if (filters.length === 0) {
                filters = [{ match: "contains", value: "" }];
            }

            this.portSelectionType = portSelectionType;
            this.selectedPorts = [...selectedPorts];
            this.originalSelectedPorts = [...selectedPorts];
            this.searchTerm = searchTerm;
            this.filters = [...filters];

            this.$nextTick(() => {
                this.loading = false;
            });
        },
        async getDevicePorts(savedData) {
            let response;
            this.serverId = savedData.serverId;
            this.isTemplate = savedData.isTemplate;

            try {
                response = await getDevicePorts({ server_id: savedData.serverId });
            } catch (error) {
                console.log(error);
            }

            if (response.data['data']) {
                this.initData(response.data['data'], savedData);
            }
        },        
    },
    
    created() {
        window.addEventListener('keydown', event => {
            if (event.key === 'Escape' && this.$refs.drawer.isOpen) {
                this.close();
            }
        });

        this.debouncedUpdateFilteredPorts = _.debounce(this.debouncedUpdateFilteredPorts, 500);
    },
});
</script>
