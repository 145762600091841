<template>
    <div class="pa-wizard">
        <div class="pa-wizard-row pa-wizard-row_dominant">
            <div v-if="panes.length > 1" class="pa-wizard-row-col pa-wizard-row-col_hd">
                <div class="pa-wizardNav">
                    <ul class="pa-wizardList">
                        <template v-for="(pane, aIndex) in panes">
                            <li v-if="!pane.isTree && pane.showIf && pane.title">
                                <button
                                    type="button"
                                    v-on:click="onClickTab(aIndex)"
                                    :class="{
                                        'isActive': pane.localActive,
                                        'hasError':  pane.hasError
                                    }"
                                    class="pa-wizardNav-item"
                                    :disabled="pane.localDisabled">
                                    <span v-if="pane.titleBadge" class="pa-badge" :class="pane.titleBadgeClass">{{ pane.titleBadge }}</span>
                                    {{ pane.title }}
                                </button>
                            </li>
                            <li class="pa-wizardList pa-wizardList_tree" v-if="pane.isTree && pane.showIf">
                                <button
                                    type="button"
                                    v-if="pane.defaultPane"
                                    v-on:click="onClickTab(aIndex)"
                                    :class="{
                                        'isActive': pane.defaultPane.localActive,
                                        'hasError': pane.defaultPane.hasError
                                    }"
                                    class="pa-wizardNav-item">
                                    {{ pane.defaultPane.title }}
                                </button>
                                <div
                                    v-if="!pane.defaultPane"
                                    class="pa-wizardNav-item pa-wizardNav-item_tree">
                                    {{ pane.title }}
                                </div>
                                <ul class="pa-wizardList">
                                    <li
                                        v-for="(subPane, bIndex) in pane.panes"
                                        v-if="subPane.showIf">
                                        <button
                                            type="button"
                                            v-on:click="onClickSubTab(aIndex, pane, bIndex)"
                                            :class="{
                                                'isActive': subPane.localActive,
                                                'hasError': subPane.hasError
                                            }"
                                            class="pa-wizardNav-item">
                                            {{ subPane.title }}
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        </template>
                    </ul>
                </div>
                <slot name="nav-footer"></slot>
            </div>
            <div class="pa-wizard-row-col pa-wizard-row-col_bd">
                <div class="pa-wizardPane">
                    <div class="pa-wizardPane-bd">
                        <div class="pa-panels pa-panels_plain" style="position: static;">
                            <slot></slot>
                        </div>
                        <div v-if="!inModal && linear" style="padding-top: 10px; height: initial;" class="pa-wizardPane-ft">
                            <ul class="pa-split">
                              <li style="padding-left: 20px;">
                                <a v-if="showBackLink()" class="pa-anchor_breadcrumb" @click="backLink" href="#">
                                  <svg class="pa-icon pa-icon_link"><use xlink:href="#chevron-left"></use></svg>
                                  {{ getBackText() }}
                                </a>
                              </li>
                              <li>
                                <a v-if="cancelLinkText && cancelLinkUrl && showNextButton()"
                                   class="pa-btn pa-btn_cancel pa-mr-8"
                                   :href="cancelLinkUrl">
                                    {{ cancelLinkText}}
                                </a>
                                <button
                                    v-if="showNextButton()"
                                    type="button"
                                    class="pa-btn"
                                    v-on:click="next()"
                                    :disabled="nextButtonIsDisabled()">
                                    {{ getNextText() }}
                                </button>
                              </li>
                            </ul>
                        </div>
                        <div v-if="!inModal && !linear" style="padding-top: 10px; height: initial;" class="pa-wizardPane-ft">
                            <ul class="pa-split">
                                <li></li>
                                <li>
                                    <a v-if="cancelLinkText && cancelLinkUrl"
                                       class="pa-btn pa-btn_cancel pa-mr-8"
                                       :href="cancelLinkUrl">
                                        {{ cancelLinkText}}
                                    </a>
                                    <button
                                        type="button"
                                        class="pa-btn"
                                        v-on:click="finish()">
                                        {{ getFinishText() }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="inModal" class="pa-wizard-row">
            <div v-if="linear" class="pa-wizard-row-col pa-wizard-row-col_ft">
                <ul class="pa-split" style="width:100%;">
                  <li style="padding-left: 20px;">
                    <a v-if="showBackLink()" @click="backLink" class="pa-anchor_breadcrumb" href="#">
                      <svg class="pa-icon pa-icon_link"><use xlink:href="#chevron-left"></use></svg >
                      {{ getBackText() }}
                    </a>
                  </li>
                  <li>
                    <a v-if="inModal && showNextButton()" @click="close_modal"
                       class="pa-btn pa-modal-close pa-mr-8">Cancel</a>
                    <button
                        v-if="showNextButton()"
                        type="button"
                        class="pa-btn"
                        :class="{ 'pa-btn_blue': id === 'edit_dashboard_wizard' }"
                        v-on:click="next()"
                        :disabled="nextButtonIsDisabled()">
                        {{ getNextText() }}
                    </button>
                  </li>
                </ul>
            </div>
            <div v-else class="pa-wizard-row-col pa-wizard-row-col_ft">
                <ul class="pa-split" style="width: 100%;">
                    <li></li>
                    <li>
                        <a v-if="inModal" @click="close_modal" class="pa-btn pa-modal-close pa-mr-8">Cancel</a>
                        <button
                            type="button"
                            class="pa-btn"
                            v-on:click="finish()">
                            {{ getFinishText() }}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import WizardManager from './../utils/wizardManager';
    import wizardMixin from './../mixins/wizardMixin';

    const DEFAULT_NEXT_TEXT = 'Next';
    const DEFAULT_FINISH_TEXT = 'Finish';
    const HEADER_HEIGHT = 50;

    const Wizard = Vue.extend({
        mixins: [wizardMixin],

        events: {
            ['wizard:pane:ready'](pane) {
                this.panes.push(pane);
                if (this.sortPanes) {
                    this.panes.sort(function(a, b) { return a.sortOrder - b.sortOrder; })
                }
            },

            ['wizard:tree:ready'](tree) {
                this.panes.push(tree);
            },

            ['wizard:pane:validated'](pane) {
                this.disableUnreachablePanes();
            }
        },

        methods: {
            getNextText() {
                if (!this.panes.length) {
                    return DEFAULT_NEXT_TEXT;
                }

                const activePane = this.getCurrentPane();

                if (this.activePaneIndex === (this.panes.length - 1)) {
                    return activePane.nextText || DEFAULT_FINISH_TEXT;
                }

                return activePane.nextText || DEFAULT_NEXT_TEXT;
            },

            getBackText() {
                if (this.inModal) {
                    return;
                }
                if (!this.panes.length) {
                    return;
                }

                if (this.activePaneIndex === 0) {
                    return this.backLinkText;
                }

                try {
                    const prevPane = this.panes[this.activePaneIndex - 1];
                    return "Back to " + prevPane.title;
                } catch(err) {
                    console.log(err.message);
                    return "Back";
                }
            },

            getFinishText() {
                return this.finishText || DEFAULT_FINISH_TEXT;
            },

            goToSubPane(index, pane, subIndex, details) {
                const shouldAdvance = this.goTo(index, details);

                if (!shouldAdvance) {
                    return false;
                }

                return pane.goTo(subIndex, details);
            },

            _next() {
                this.goTo(this.activePaneIndex + 1);

                var currentPane = this.getCurrentPane();
                 
                if (currentPane) {
                    currentPane.validate();
                }

                if(this.activePaneIndex == this.panes.length) {
                    this.goTo(0);

                    this.finish();
                }

              
            },

            next() {
                this.scrollToTopOfWizard();

                const activePane = this.getCurrentPane();

                if (activePane.isTree) {
                    return activePane._next();
                }

                return this._next();
            },

            prev() {
                const activePane = this.getCurrentPane();

                if (activePane.isTree) {
                    return activePane._prev();
                }

                return this._prev();
            },

            finish() {
                if (this.name) {
                    this._heapTrack("Clicked Submit " + this.name);
                }
                return this.onFinish();
            },

            close_modal(event) {
                // If the wizard is in a modal, trigger it to close.  If a custom method has been passed
                // in, use that, otherwise default to close the currently active modal
                if (this.onClose) {
                    this.onClose();
                } else if (window.active_vue_modal) {
                    window.active_vue_modal.close();
                }
            },

            backLink(event) {
                if (!this.panes.length) {
                    return;
                }

                event.preventDefault();

                const activePane = this.getCurrentPane();

                if (this.activePaneIndex === 0) {
                    window.location.href = this.backLinkUrl;
                    return;
                }

                try {
                    this.prev()
                } catch(err) {
                    console.log(err.message);
                }
            },

            showNextButton() {
                if (!this.linear) {
                    return false;
                }

                if (!this.panes.length) {
                    return false;
                }

                var currentPane = this.getCurrentPane();
                if (currentPane) {
                    return this.getCurrentPane().showNext;
                } else {
                    return false;
                }

                return this.getCurrentPane().showNext;
            },

            showBackLink() {

                // JMA: Disabling back links for all wizards - #137169419
                return false;

                if (!this.linear) {
                    return false;
                }

                if (!this.panes.length) {
                    return false;
                }

                const activePane = this.getCurrentPane();

                if (this.activePaneIndex === 0 && (this.backLinkUrl === "" || this.backLinkText === "")) {
                    return false;
                }

                return this.getCurrentPane().showBack;
            },

            nextButtonIsDisabled() {
                if (!this.panes.length) {
                    return true;
                }
                if (!this.linear) { return false; }

                const activePane = this.getCurrentPane();

                if (activePane.isTree) {
                    return activePane.nextButtonIsDisabled();
                }

                const activePaneIsRequired = activePane.isRequired;
                const activePaneIsInvalid = !activePane.isValid;

                return activePaneIsRequired && activePaneIsInvalid;
            },

            _prev() {
                this.goTo(this.activePaneIndex - 1);
            },

            getIndexOfPaneById(id) {
                const defaultReturn = 0;

                if (!id) {
                    return defaultReturn;
                }

                const matrix = [];

                this.panes.some((pane, a) => {
                    let match = pane.id === id;

                    if (match) {
                        matrix.push(a);

                        return match;
                    } else if (pane.isTree) {
                        if (pane.defaultPane && pane.defaultPane.id === id) {
                            match = true;
                            matrix.push(a);

                            return match;
                        }

                        pane.panes.some((subPane, b) => {
                            match = subPane.id === id;

                            if (match) {
                                matrix.push(...[a, pane, b]);
                            }

                            return match;
                        })

                        return match;
                    }

                    return match;
                });

                switch(matrix.length) {
                    case 0:
                        return defaultReturn;
                        break;
                    case 1:
                        return matrix[0];
                        break;
                    case 3:
                        return matrix;
                        break;
                }
            },

            activatePane(index, details) {
                this.activePaneIndex = index;

                var source = typeof details !== 'undefined' && details.source || "next";
                var props = {"Source": source};

                this.panes.forEach(function(pane, i) {
                    if (pane.isTree) {
                        if (index === i) {
                            pane.activate();
                            props.pane = pane.title;
                            this._heapTrack("Viewed", props);
                        } else {
                            pane.deactivate();
                        }

                        return;
                    }

                    if (index === i) {
                      pane.localActive = true;
                      props.pane = pane.title;
                      this._heapTrack("Viewed", props);
                    } else {
                      pane.localActive = false;
                    }
                }, this);

                this.disableUnreachablePanes();
            },

            disableUnreachablePanes() {
                const curIndex = this.activePaneIndex;
                if (curIndex >= this.panes.length) { return; }
                const canProceed = !this.nextButtonIsDisabled();

                this.panes.forEach(function(pane, i) {
                    if (i <= curIndex || pane.isTree) {
                        return;
                    }

                    if (!canProceed) {
                        pane.localDisabled = true;
                    } else {
                        pane.localDisabled = false;
                    }

                }, this);
            },

            _heapTrack(type, props, onReady) {
                if (this.heapTrack && this.name && typeof heap !== 'undefined') {
                    if (typeof props !== 'undefined' && "pane" in props) {
                        type += " " + this.name + " - " + props.pane;
                        delete props.pane;
                    }
                    heap.track(type, props);
                } else if (this.heapTrack && this.name) {
                    if (typeof onReady !== undefined && onReady) {
                        //console.log("Heap library not available.");
                    } else {
                        $(document).ready(this._heapTrack.bind(this, type, props, true));
                    }
                }
            },

            goToPaneWithID(id, default_details) {
                const matrixOrIndex = this.getIndexOfPaneById(id);

                if (typeof matrixOrIndex === 'object') {
                    return this.goToSubPane(...matrixOrIndex, default_details) || this.goTo(0);
                }

                return this.goTo(matrixOrIndex, default_details) || this.goTo(0, default_details);
            },

            isPaneActivateable(index) {
                if (!this.linear) { return true; }

                let isPaneActivateable = true;

                this.panes.some((pane, i) => {
                    if (index === i) {
                        return true;
                    }

                    isPaneActivateable = this.isPaneValid(pane);

                    return !isPaneActivateable;
                });

                return isPaneActivateable;
            },

            scrollToTopOfWizard() {
                if (this.inModal) {
                    return;
                }

                window.scrollTo(0, this.$el.offsetTop - HEADER_HEIGHT);
            },

            onClickTab(index) {
                this.scrollToTopOfWizard();

                this.goTo(index, {
                    source: 'tab'
                });
            },

            onClickSubTab(aIndex, pane, bIndex) {
                this.scrollToTopOfWizard();

                this.goToSubPane(aIndex, pane, bIndex, {
                    source: 'tab'
                })
            }
        },

        props: {
            id: {
                type: String,
                default: null
            },

            name: {
                type: String,
                default: null
            },

            linear: {
                type: Boolean,
                default: true
            },

            finishText: {
                type: String,
                default: ''
            },

            onFinish: {
                type: Function,
                default: function() {}
            },

            inModal: {
                type: Boolean,
                default: false
            },

            onClose: {
                type: Function,
                default: null
            },

            heapTrack: {
                type: Boolean,
                default: true
            },

            backLinkUrl: {
                type: String,
                default: ""
            },

            backLinkText: {
                type: String,
                default: ""
            },

            cancelLinkUrl: {
                type: String,
                default: ""
            },

            cancelLinkText: {
                type: String,
                default: ""
            },

	    sortPanes: {
		type: Boolean,
		default: false
	    }

        },

        vueReady() {
            this.panes.forEach((pane) => {
                if (pane.isTree) {
                    pane.panes.forEach(() => {
                        pane.isModal = this.inModal;
                    });

                    return;
                }

                pane.isModal = this.inModal;
            });

            const hash = window.location.hash.replace(/#/, '');

            const default_details = {source: "start"}

            this.goToPaneWithID(hash, default_details);

            WizardManager.addInstance(this);
        }
    });

    export default Wizard;
</script>
