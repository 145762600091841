<template>
    <div class="pa-dualListbox">
        <ul class="pa-dualListbox-list">
            <li>
                <div class="pa-dualListbox-hdg">
                    <div class="pa-vr" style="text-align: left;">
                        <strong>{{ optionsLabel }}</strong> - <span class="pa-txt pa-txt_xs">Showing {{ options.length }}</span>
                    </div>
                </div>
                <div class="pa-vr pa-vr_x2">
                    <input
                        type="text"
                        class="pa-input"
                        :placeholder="optionsPlaceholder"
                        v-on:input="_onFilter($event, 'options')" />
                </div>
                <select multiple class="pa-select pa-select_listbox" ref="options">
                    <option
                        v-for="option in options"
                        :value="option.value"
                        :key="option.value"
                        :title="option.label">{{ option.label }}</option>
                </select>
            </li>
            <li class="pa-dualListbox-list-item pa-dualListbox-list_actions">
                <ul class="pa-dualListbox-actions">
                    <li>
                        <button class="pa-btn pa-btn_secondary pa-btn_sm pa-btn_block" v-on:click="_onAddAll" type="button">
                            Add all
                        </button>
                    </li>
                    <li>
                        <button class="pa-btn pa-btn_secondary pa-btn_sm pa-btn_block" v-on:click="_onAdd" type="button">
                            Add
                        </button>
                    </li>
                    <li>
                        <button class="pa-btn pa-btn_secondary pa-btn_sm pa-btn_block" v-on:click="_onRemove" type="button">
                            Remove
                        </button>
                    </li>
                    <li>
                        <button class="pa-btn pa-btn_secondary pa-btn_sm pa-btn_block" v-on:click="_onRemoveAll" type="button">
                            Remove All
                        </button>
                    </li>
                </ul>
            </li>
            <li>
                <div class="pa-dualListbox-hdg">
                    <div class="pa-vr" style="text-align: left;">
                        <strong>{{ selectedLabel }}</strong> - <span class="pa-txt pa-txt_xs">Showing {{ model.length }}</span>
                    </div>
                </div>
                <div class="pa-vr pa-vr_x2">
                    <input
                        type="text"
                        class="pa-input"
                        :placeholder="selectedPlaceholder"
                        v-on:input="_onFilter($event, 'selected')" />
                </div>
                <select multiple class="pa-select pa-select_listbox" ref="selected">
                    <option
                        v-for="option in model"
                        :value="option.value"
                        :key="option.value"
                        :title="option.label">{{ option.label }}</option>
                </select>
            </li>
        </ul>
    </div>
</template>

<script>
    import Vue from 'vue';
    import debounce from './../utils/debounce';
    import escapeStringRegex from 'escape-string-regexp';

    const DualListbox = Vue.extend({

        data() {
            return {
                model: [],
                localValue: this.value,
            };
        },

        props: {
            options: {
                type: Array,
                default: () => {
                    return [];
                }
            },

            optionsLabel: {
                type: String,
                default: 'Options'
            },
            
            optionsPlaceholder: {
                type: String,
                default: ''
            },
            
            selectedPlaceholder: {
                type: String,
                default: ''
            },

            selectedLabel: {
                type: String,
                default: 'Selected'
            },

            value: {
                type: Array,
                default: () => [],
            },

            sort: {
                type: Boolean,
                default: false,
            }
        },

        methods: {
            add(values) {
                const options = this._copyOptions(this.options, values);

                this._swap(this.options, this.model, options);
            },

            getValue() {
                return this.localValue;
            },

            remove(values) {
                const options = this._copyOptions(this.model, values);

                this._swap(this.model, this.options, options);
            },

            _copyOptions(options, values) {
                const _options = [];

                options.forEach(option => {
                    values.some(value => {
                        if (value == option.value) {
                            _options.push({
                                label: option.label,
                                value: option.value,
                            });
                        }
                    });
                });

                return _options;
            },

            _filterOptions(value, selectElement) {
                const options = [...selectElement.children];

                // if we don't have a value, show all options
                if (!value.trim()) {
                    options.forEach(option => {
                        option.style.display = 'block';
                    });

                    return;
                }

                const pattern = new RegExp(`${escapeStringRegex(value)}`);

                options.forEach(option => {
                    if (option.label.toLowerCase().match(pattern)) {
                        option.style.display = 'block';
                    } else {
                        option.style.display = 'none';
                    }
                });
            },

            _createOptions(selectElement, filter) {
                const optionElements = [...selectElement.children].filter(filter);

                return optionElements.map(optionElement => {
                    return {
                        label: optionElement.text,
                        value: optionElement.value,
                    };
                });
            },

            _onAdd() {
                const selectElement = this.$refs.options;
                const options = this._createOptions(selectElement, e => e.selected);

                this._add(options);
            },

            _onAddAll() {
                const options = this._createOptions(this.$refs.options, e => e.style.display !== 'none');

                this._add(options);
            },

            _onFilter($event, key) {
                const value = $event.currentTarget.value.toLowerCase();
                const selectElement = this.$refs[key];

                this._filterOptions(value, selectElement);
            },

            _onRemove() {
                const selectElement = this.$refs.selected;
                const options = this._createOptions(selectElement, e => e.selected);

                this._remove(options);
            },

            _onRemoveAll() {
                const options = this._createOptions(this.$refs.selected, e => e.style.display !== 'none');

                this._remove(options);
            },

            _sortOptions() {
                this.options.sort(this.sortOption);
                this.model.sort(this.sortOption);
            },

            sortOption(a, b) {
                if (a.label > b.label) {
                    return 1;
                } else if (b.label > a.label) {
                    return -1;
                } else {
                    return 0;
                }
            },

            _updateValue() {
                this.localValue = this.model.map(option => option.value);
            },

            _add(options) {
                this._swap(this.options, this.model, options);
                if (this.sort) {
                    this._sortOptions();
                }
                this._updateValue();
            },

            _remove(options) {
                this._swap(this.model, this.options, options);
                if (this.sort) {
                    this._sortOptions();
                }
                this._updateValue();
            },

            _swap(source, target, options) {
                let i = source.length - 1;

                while (i >= 0) {
                    options.some(option => {
                        if (option.value == source[i].value) {
                            source.splice(i, 1);
                            target.unshift(Object.assign({}, option));

                            return true;
                        }

                        return false;
                    });

                    i--;
                }
            },
        },

        watch: {
            localValue(val) {
                this.eventHub.$emit('update:dualListValue', val);
            },
        },

        vueReady() {
            // debounce filter function
            this._filterOptions = debounce(this._filterOptions, 400);

            // set initial model value
            this.add(this.localValue);
        },

    });

    export default DualListbox;
</script>
