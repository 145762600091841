<style scoped>
.sorting_asc {
    transform: rotateX(180deg);
}

#sort-button svg.pa-icon {
    width: 8px;
}
</style>

<template>
    <div class="module">
        <div class="module-body pa-pt-0">
            <p-field-group
                name="item_limit"
                v-if="!isWorstOffenders"
                :error="errors.error__item_limit"
            >
                <label class="pa-option">
                    <input
                        v-model="limitItems"
                        type="checkbox"
                        class="pa-option-input"
                    />
                    <span class="pa-option-icon"></span>
                    <span class="pa-option-txt">
                        Limit visible items by performance
                    </span>
                </label>
            </p-field-group>

            <div v-if="limitItems || isWorstOffenders" class="pa-pt-12">
                <p-row>
                    <p-field-group
                        name="max_items"
                        :error="errors.error__max_items"
                        label="Number of items to display"
                        style="width: 236px;"
                        class="pa-pl-4"
                    >
                        <p-select
                            :model.sync="maxItems"
                            :options="isWorstOffenders ? maxItemsOptionByFives : maxItemsOptions"
                            size="stretch"
                        >
                        </p-select>
                    </p-field-group>
                    <p-field-group
                        v-show="showOrderButton"
                        name="order"
                        label="Ordering"
                        :error="errors.error__order"
                        class="pa-ml-8"
                        style="width: 196px;"
                    >
                        <p-select
                            :model.sync="sortOrder"
                            :options="sortOrderOptions"
                            size="stretch"
                        >
                        </p-select>
                    </p-field-group>
                </p-row>

                <p-field-group
                    v-if="showAggregationSelector"
                    name="item_aggregation"
                    label="Item Aggregation"
                    :error="errors.error__item_aggregation"
                >
                    <p-select
                        :model.sync="itemAggregation"
                        :options="aggregationOptions"
                    >
                    </p-select>
                </p-field-group>
            </div>
            <p-field-group
                name="threshold_limit"
                v-if="showThresholdOption"
                :error="errors.error__item_limit"
            >
                <label class="pa-option">
                    <input
                        v-model="limitByThreshold"
                        type="checkbox"
                        class="pa-option-input"
                    />
                    <span class="pa-option-icon"></span>
                    <span class="pa-option-txt">
                        Only metrics exceeding thresholds
                    </span>
                </label>
            </p-field-group>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                ready: false,
                limitItems: false,
                limitByThreshold: false,
                maxItems: 10,
                itemAggregation: null,
                aggregationOptions: [
                    {label: 'By minimum', value: 'minimum'},
                    {label: 'By maximum', value: 'maximum'},
                    {label: 'By average', value: 'average'},
                ],
                sortOrder: 'asc',
                sortOrderOptions: [
                    {label: 'Display Highest first', value: 'desc'},
                    {label: 'Display Lowest first', value: 'asc'},        
                ],
                maxItemsOptions: [
                    {label: '1', value: 1},
                    {label: '2', value: 2},
                    {label: '3', value: 3},
                    {label: '4', value: 4},
                    {label: '5', value: 5},
                    {label: '6', value: 6},
                    {label: '7', value: 7},
                    {label: '8', value: 8},
                    {label: '9', value: 9},
                    {label: '10', value: 10},
                    {label: '11', value: 11},
                    {label: '12', value: 12},
                    {label: '13', value: 13},
                    {label: '14', value: 14},
                    {label: '15', value: 15},
                    {label: '16', value: 16},
                    {label: '17', value: 17},
                    {label: '18', value: 18},
                    {label: '19', value: 19},
                    {label: '20', value: 20},
                    {label: '21', value: 21},
                    {label: '22', value: 22},
                    {label: '23', value: 23},
                    {label: '24', value: 24},
                    {label: '25', value: 25},
                ],
                maxItemsOptionByFives: [
                    {label: '5', value: 5},
                    {label: '10', value: 10},
                    {label: '15', value: 15},
                    {label: '20', value: 20},
                    {label: '25', value: 25},
                    {label: '30', value: 30},
                ],
                isWorstOffenders: this.editingWidget.$options._componentTag == 
                    "p-worst-offenders-table-widget" ? true : false,
                isMetricSummaryTable: this.editingWidget.$options._componentTag ==
                    "p-metricsummary-widget" ? true : false,
                errors: {},

            }
        },

        props: {
            options: {
                type: Object,
                default: function () {
                    return {}
                }
            },

            editingWidget: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        },

        watch: {
            sortOrder(curr, prev) {
                this.sendConfig();
            },

            limitItems(curr, prev) {
                this.sendConfig();
            },

            maxItems(curr, prev) {
                this.sendConfig();
            },

            itemAggregation(curr, prev) {
                this.sendConfig();
            },

            limitByThreshold(curr, prev) {
                this.sendConfig(true);
            },
        },

        computed: {
            showAggregationSelector() {
                const allowed = [
                    'p-linegraph-widget',
                    'p-worst-offenders-table-widget'
                ];

                return allowed.indexOf(this.editingWidget.$options._componentTag) >= 0;
            },

            showOrderButton() {
                const allowed = [
                    'p-barchart-widget',
                    'p-linegraph-widget',
                    'p-worst-offenders-table-widget'
                ];

                return allowed.indexOf(this.editingWidget.$options._componentTag) >= 0;
            },

            showThresholdOption() {
                if (!this.$parent.$parent.canSeeLimitByThreshold) {
                    return false;
                }
                const allowed = [
                    'p-barchart-widget',
                    'p-linegraph-widget',
                    'p-linegraph-table-widget',
                    'p-metricsummary-widget'
                ];

                return allowed.indexOf(this.editingWidget.$options._componentTag) >= 0;
            },
        },

        methods: {
            sendConfig(updateThreshold) {
                if(!this.ready) { return; }
                if(!this.maxItems && !updateThreshold) { return; }

                this.$parent.updatePending(this.editingWidget.id);

                var controls = {
                    "should_limit_items": this.limitItems,
                    "item_limit_count": this.maxItems,
                    "item_sort_order": this.sortOrder,
                    "item_aggregation": this.itemAggregation,
                    "item_limit_by_threshold": this.limitByThreshold,
                };

                const payload = {
                    widget_id: this.editingWidget.id,
                    controls: JSON.stringify(controls),
                };

                $.ajax({
                    url: '/dashboardv2/setWidgetCustomControls',
                    method: 'POST',
                    data: payload,
                }).done(data => {
                    if (data.success && data.newConfig) {
                        this.editingWidget.config = data.newConfig;
                    }
                });

                this.editingWidget.pendNewConfig();
            },
        },

        created() {

        },

        vueReady() {
            const custom_controls = this.editingWidget.config.custom_controls;
            if (custom_controls) {
                if(this.isWorstOffenders) {

                    this.maxItems = 
                        custom_controls['item_limit_count'] ? 
                        custom_controls['item_limit_count'] : 
                        10;

                    this.sortOrder = 
                        custom_controls['item_sort_order'] ? 
                        custom_controls['item_sort_order'] : 
                        'desc';

                    this.itemAggregation = 
                        custom_controls['item_aggregation'] ? 
                        custom_controls['item_aggregation'] : 
                        'average';

                } else {
                    this.maxItems = custom_controls['item_limit_count'];
                    this.sortOrder = custom_controls['item_sort_order'];
                    this.limitItems = custom_controls['should_limit_items'];
                    this.itemAggregation = custom_controls['item_aggregation'];
                    this.limitByThreshold = custom_controls['item_limit_by_threshold'];
                }
            }
            this.ready = true;
        }

    });
</script>
