<template>
    <li class="drawer-active-maint-list-item">
        <div class="pa-txt_sm pa-pt-16">
            <p-text class="drawer-active-maint-list-item-name pa-mb-4 pa-txt_medium">
                {{ maint.name}} <p-badge variant="success" class="pa-txt_13">ACTIVE</p-badge>
            </p-text>
            <p-text class="pa-mb-0">
                <span class="pa-txt_secondary">Ends @ {{ maint.endTimeFormatted }}  | Duration {{ maint.duration }} — </span>
                <a @click="openDrawer" href="#" class="pa-hover-underline">
                    <template v-if="maint.num_affected.num_servers > 0">
                        {{ maint.num_affected.num_servers }} instance{{ maint.num_affected.num_servers > 1 ? 's' : '' }}
                    </template>
                    <template v-if="maint.num_affected.num_server_groups > 0">
                        {{ maint.num_affected.num_server_groups }} instance group{{ maint.num_affected.num_server_groups > 1 ? 's' : '' }}
                    </template>
                    <template v-if="maint.num_affected.num_server_resource_thresholds > 0">
                        {{ maint.num_affected.num_server_resource_thresholds }} server resource threshold{{ maint.num_affected.num_server_resource_thresholds > 1 ? 's' : '' }}
                    </template>
                    <template v-if="maint.num_affected.num_compound_services > 0">
                        {{ maint.num_affected.num_compound_services }} compound service{{ maint.num_affected.num_compound_services > 1 ? 's' : '' }}
                    </template>                                    
                </a>
            </p-text>
        </div>
    </li>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            maint: Object,
        },
        methods: {
            openDrawer() {
                this.eventHub.$emit(
                    'drawer:load',
                    {
                        id: 'secondary-drawer',
                        url: `/config/MaintenancePeriodImpact?maintenance_schedule_id=${this.maint.id}`
                    }
                );
            },
        },
    });
</script>
