<template>
    <nav aria-label="breadcrumb">
        <p-flex as="ol" align-center>
            <slot></slot>
            <p-breadcrumb-item
                :key="item.text"
                v-for="(item, index) in filteredItems"
                :item="item"
                :is-last="index === items.length - 1"
            >
            </p-breadcrumb-item>
            <template v-if="truncateList">
                <li>
                    <p-flyout
                        direction="bottom"
                        :scroll-lock="true"
                        :hover="true"
                        :hover-timeout="0"
                    >
                        <p-button slot="trigger" unstyled no-base-class>
                            ...
                        </p-button>
                        <ul slot="content" class="pa-vList">
                            <p-breadcrumb-item
                                :key="index"
                                v-for="(item, index) in items.slice(1, items.length - 1)"
                                :item="item"
                                :is-last="true"
                            >
                            </p-breadcrumb-item>
                        </ul>
                    </p-flyout>
                    <p-icon icon="chevron-right" size="xl" color="cloudy-blue"></p-icon>
                </li>
                <p-breadcrumb-item :item="lastItem" :is-last="true">
                </p-breadcrumb-item>
            </template>
        </p-flex>
    </nav>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    props: {
        items: Array,
        maxItems: {
            type: Number,
            default: null,
        },
    },
    computed: {
        truncateList: function() {
            if (this.maxItems && this.items.length > this.maxItems) {
                return true;
            }
            return false;
        },
        filteredItems: function() {
            if (this.truncateList) {
                // Only display the first item because we will display
                // a ellipsis button inbetween the first and the manually
                // placed last item
                const firstItem = this.items[0];
                return [firstItem];
            } else {
                return this.items;
            }
        },
        lastItem: function() {
            return this.items[this.items.length - 1];
        },
        truncatedItems: function() {
            // Returns the list of items with the first and last removed
            return this.items.slice(1, -1);
        },
    },
});
</script>
