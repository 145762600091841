
class WizardManager {
    constructor(wizard) {
        this.wizard = wizard;
    }

    goTo(index) {
        this.wizard.goTo(index);
    }

    next() {
        this.wizard.next();
    }

    prev() {
        this.wizard.prev();
    }

    goToPaneWithID(id) {
        this.wizard.goToPaneWithID(id, {
            source: 'wizardManager'
        });
    }

    getWizardPaneById(id) {
        let match = null;

        this.wizard.panes.some((pane) => {
            if (pane.id === id) {
                match = pane;

                return true;
            } else if (pane.panes) {
                if (pane.defaultPane && pane.defaultPane.id === id) {
                    match = pane.defaultPane;

                    return true;
                }

                pane.panes.some((subPane) => {
                    if (subPane.id === id) {
                        match = subPane;

                        return true;
                    }
                });
            }
        });

        return match;
    }

    getWizardPaneById(id) {
        let match = null;

        this.wizard.panes.some((pane) => {
            if (pane.id === id) {
                match = pane;

                return true;
            } else if (pane.panes) {
                if (pane.defaultPane && pane.defaultPane.id === id) {
                    match = pane.defaultPane;

                    return true;
                }

                pane.panes.some((subPane) => {
                    if (subPane.id === id) {
                        match = subPane;

                        return true;
                    }
                });
            }
        });

        return match;
    }
}


WizardManager.addInstance = function(wizardInstance) {
    // Clean the window wizards before pushing a new one, to
    // avoid ending with duplicated instances of a same component.
    // This way, you always work with the newly created one.
    window.wizards = [];
    window.wizards.push(wizardInstance);
}

WizardManager.getInstance = function(wizardId, callback) {
    const instance = window.wizards.filter((wizard) => {
        return wizard.id === wizardId;
    })[0];

    if (instance) {
        callback(new WizardManager(instance));
    }
}

export default WizardManager;
