<style lang="scss" scoped>
    .un-selected {
        padding: 4px 12px;
        border-radius: 12px;
        border: solid 1px #b8bec9;
        background-color: #f2f3f5;
    }

    .selected {
        padding: 4px 12px;
        border-radius: 12px;
        border: solid 1px #b8bec9;
        background-color: #4f5766;
        color: #fff;
    }


    .pa-badge_chip {

        &:hover {
            cursor: pointer;
        }
    }

    span {
        font-size: 13px;
    }

</style>

<template>
    <div 
        class="pa-grid pa-align-center pa-badge_chip pa-txt_sm pa-txt_medium"
        :class="isSelected ? 'selected' : 'un-selected'"
        @click="toggleSelection"
    >

        <p-icon
            v-if="chip.icon"
            :icon="chip.icon.name"
            class="pa-mr-4"
        >
        </p-icon>

        <span>
            {{ chip.label }}
        </span>

        <p-button
            v-if="isSelected"
            class="pa-ml-8"
            unstyled
            no-base-class
        >
            <p-icon 
                icon="critical-circle" 
                size="sm" 
                middle 
                color="white"
            >
            </p-icon>
        </p-button>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        chip: Object,
    },
    data: function() {
        return {
            isSelected: false,
        };
    },
    methods: {
        toggleSelection() {
            if(!this.isSelected) {
                this.$parent.addModelValue(this.chip.value);
            } else {
                this.$parent.removeModelValue(this.chip.value);
            }
            this.isSelected = !this.isSelected;
        }
    },
});
</script>