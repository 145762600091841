<style lang="scss">
.onboarding-path-header {
    height: 48px;

    .pa-card-body {
        height: 100%;
    }
}
</style>

<template>
    <article :id="id" class="pa-py-20">

        <p-card class="pa-txt_sm">
            <div slot="card-header" class="onboarding-path-header pa-px-24">
                <p-flex align-center style="height: 100%;">
                    <p-flex align-center>
                        <p-icon
                            v-if="logoType === 'svg'"
                            class="logo--svg"
                            :icon="logo"
                            color="grey-600"
                            no-base-class
                            height="40"
                            :width="logoWidth"
                        />
                        <img
                            v-else
                            class="logo pa-pl-12"
                            :src="logo"
                            height="28"
                        />
                        <h2 class="onboarding-path-header-title pa-pl-12 pa-txt_xl pa-txt_medium">
                            {{ title }}
                        </h2>
                    </p-flex>
                </p-flex>
            </div>
            <slot></slot>
        </p-card>

    </article>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    props: {
        id: String,
        title: String,
        description: String,
        logo: String,
        logoType: {
            type: String,
            default: "svg"
        },
        logoWidth: {
            type: String,
            default: "40"
        },
    },
});
</script>
