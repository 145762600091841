const drawerOpen = (() => {
    // params: ['target', 'template', 'anonymous', 'width', 'title', 'titleLink'],
    const state = new WeakMap();

    function _onClick(el, binding, vnode, event) {
        event.preventDefault();

        const href = event.currentTarget.getAttribute('href');

        let anonymous = (this.params.anonymous === 'true');
        if (typeof this.params.anonymous === 'undefined') {
            anonymous = true;
        }

        let width = this.params.width;
        if (typeof this.params.width === 'undefined') {
            width = null;
        }

        let title = this.params.title;
        if (typeof title === 'undefined') {
            title = '';
        }
        let titleLink = this.params['title-link'];
        if (typeof titleLink === 'undefined') {
            titleLink = '';
        }

        if (this.params.template || href && href !== '#') {
            window.app.eventHub.$emit('drawer:load', {
                id: this.params.target,
                url: this.params.template || href,
                anonymous: anonymous,
                width: width,
                title: title,
                titleLink: titleLink,
            });

            return;
        }

        let targetArgs;

        try {
            targetArgs = JSON.parse(this.params["target-args"]);
        } catch (error) {
            console.log(error);
        }

        if (typeof targetArgs === 'object' && targetArgs !== null) {
            window.app.eventHub.$emit('drawer:open', {
                id: this.params.target,
                ...targetArgs
            });
        } else {
            window.app.eventHub.$emit('drawer:open', this.params.target);
        }
    };

    return {
        bind(el, binding, vnode) {
            const self = {};
            state.set(el, self);

            self.el = el;
            self.params = Object.assign({}, el.attributes, vnode.data.attrs);

            if (typeof self.params.target === 'undefined') {
                return;
            }

            self._onClick = _onClick.bind(self, el, binding, vnode);

            el.addEventListener('click', self._onClick);
        },

        unbind(el, binding, vnode) {
            const self = state.get(el);
            self.el.removeEventListener('click', self._onClick);
        },
    };
})();

export default drawerOpen;
