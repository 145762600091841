<style lang="scss">
@import "~styles/core/helpers/_variables.scss";

#service-selection-drawer {
    .acknowledge-incident-footer {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        box-sizing: border-box;
        background: #f2f3f5;
        box-shadow: 0 -2px 0 0 rgba(132, 142, 153, 0.2);
        z-index: 10;
    }

    .service-selection-table {
        tr {
            th {
                vertical-align: bottom;

                &:first-child {
                    padding-left: 4px;
                }
            }

            td {
                font-size: 14px !important;
                vertical-align: middle;
            }
        }
    }
}
</style>

<template>
    <div class="pa-drawer_async" width="600">
        <div class="pa-drawer-hd pa-flex pa-align-center">
            <p-icon icon="discovered-services" class="pa-pr-8" size="xxxl">
            </p-icon>
            <h3 class="pa-hdg pa-hdg_3 pa-txt_medium pa-txt_truncate pa-pr-24">
                {{ serverName }} - Discovered Services
            </h3>
        </div>

        <div class="pa-drawer-bd pa-drawer-bd_padded">
            <p-loading-spinner v-if="loading">
            </p-loading-spinner>

            <form v-else id="service-selection" @submit.prevent="handleSubmit">

                <p class="pa-txt_medium pa-txt_sm">
                    Services listed below are what we initially discovered - select/deselect items based on what you’d like monitored. More monitoring services can be applied later.
                </p>

                <table class="detected-services-table pa-table pa-mb-24">
                    <thead>
                        <tr>
                            <th>Discovered Service</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="service.textkey" v-for="service in services">
                            <td>
                                <label class="pa-option">
                                    <input
                                        type="checkbox"
                                        class="pa-option-input"
                                        :value="service.textkey"
                                        v-model="selectedServices"
                                    />
                                    <span class="pa-option-icon"></span>
                                    <span class="pa-option-txt">
                                        {{ service.label }}
                                    </span>
                                </label> 
                            </td>
                        </tr>
                    </tbody>
                </table>

            </form>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        private_server_ids: String,
        public_server_ids: String,
        selected_services: String,
    },
    data() {
        return {
            loading: true,
            services: [],
            selectedServices: [],
            serverName: "",
            serverId: "",
        };
    },
    watch: {
        selectedServices(curr, prev) {
            if (!this.loading) {
                this.broadcastChange(curr);
            }
        },
    },
    computed: {},
    methods: {
        broadcastChange(selected) {
            window.app.rootVue.$broadcast(
                "service-selection-changed",
                {
                    serverId: this.serverId,
                    selectedServices: selected
                }
            );
        },
        initData(data) {
            const [
                serverName,
                labelString,
                textkeys,
                url,
                serverId
            ] = data.data[0];
            const labels = labelString.split(", ");
            const services = labels.map((label, i)  => ({
                label,
                textkey: textkeys[i],
            }));
            this.serverId = serverId;
            this.serverName = serverName;
            this.services = [...services];
            this.selectedServices = this.selected_services.split(",");
        },
        getDrawerData() {
            $.ajax({
                url: `/onboarding/get_discovered_services_data`,
                type: "GET",
                context: this,
                data: {
                    public_server_ids: this.public_server_ids,
                    private_server_ids: this.private_server_ids,
                },
            }).done((response) => {
                this.initData(response);
                // Set this on nextTick so our watch broadcast doesnt get set off
                this.$nextTick(() => {
                    this.loading = false;
                });
            });
        },
    },
    created() {
        this.getDrawerData();
    },
});
</script>
