<template>
    <div class="module">
        <div ref="body" v-show="open" transition="module-expand" class="module-body">
            <div class="pa-field pa-field_vertical">
                <div class="pa-field-hd">
                    <label for="template" class="pa-label">Data Points</label>
                </div>
                <div class="pa-field-bd">
                    <ol class="pa-vList">
                        <li :key="group" v-for="(group, index) in groups" class="pa-txt_13">
                            <div style="text-align: left;">
                                {{group}}
                                <svg v-if="!options.hideReorder" @click="indexDown(index)" class="pa-icon pa-clickable">
                                    <use xlink:href="#chevron-up"></use>
                                </svg>
                                <svg v-if="!options.hideReorder" @click="indexUp(index)" class="pa-icon pa-clickable">
                                    <use xlink:href="#chevron-down"></use>
                                </svg>
                                <svg
                                    v-if="!options.hideToggle"
                                    @click="togglePoint(group)"
                                    class="pa-icon pa-clickable"
                                    :style="`fill: ${config[group] && config[group].visible ? '#4f5766' : '#d5d8df'};`"
                                >
                                    <use xlink:href="#eye"></use>
                                </svg>
                                <img v-if="!options.hideSort" :src="'/static/assets/newux/media/icons/sort_desc' + (sortColumn === group && sortDirection === 'desc' ? '' : '_disabled') + '.png'"
                                    class="pa-icon pa-clickable" style="cursor: pointer; margin-bottom: 3px;"
                                    @click="setSort(group, 'desc')"/>
                                <img v-if="!options.hideSort" :src="'/static/assets/newux/media/icons/sort_asc' + (sortColumn === group && sortDirection === 'asc' ? '' : '_disabled') + '.png'"
                                    class="pa-icon pa-clickable" style="cursor: pointer; margin-bottom: -3px;"
                                    @click="setSort(group, 'asc')"/>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
 import Vue from 'vue';
 import moduleMixin from './../moduleMixin.js';

 const ColumnGroups = Vue.extend({
     mixins: [moduleMixin],

     data() {
         return {
             groups: [],
             config: {},
             sortColumn: '',
             sortDirection: '',
         }
     },

     props: {
        options: {
            type: Object,
            default: function() {
                return {};
            },
        },
        editingWidget: {
            type: Object,
            default: function() {
                return {};
            },
        },
     },

     events: {
         'columngroups:set_groups': function(groups, config, sortColumn, sortDirection, updateId) {
             if(updateId && updateId != this.editingWidget.id) {
                return
             }
             this.groups = [...groups];
             this.config = {...config};
             if (sortColumn.length && sortDirection.length) {
                 this.sortDirection = sortDirection;
                 for (var grp in config) {
                     if (config[grp].columns.includes(sortColumn)) {
                         this.sortColumn = grp;
                     }
                 }
             }
         }
     },

     methods: {
         togglePoint: function(column){
	     var visible = this.config[column]['visible'];
	     this.config[column].visible = !visible;
             for (var i in this.config[column].columns) {
                var col = this.config[column].columns[i];
                var actual_points = this.editingWidget.toggleColumn(col);
	     }
	     actual_points.data_point_config = this.config;
             this.sendConfig(actual_points);
         },

         indexDown: function(index){
             if (index === 0){
                 return undefined;
             }

             const temp = this.groups[index-1];

             Vue.set(this.groups, index-1, this.groups[index]);
             Vue.set(this.groups, index, temp);

             var result = this.setColumnOrder();
             result.data_point_order = this.groups;
             this.sendConfig(result);
         },

         indexUp: function(index){
             if (index + 1 === this.groups.length){
                 return undefined;
             }

             const temp = this.groups[index+1];
             Vue.set(this.groups, index+1, this.groups[index]);
             Vue.set(this.groups, index, temp);

             var result = this.setColumnOrder();
             result.data_point_order = this.groups;
             this.sendConfig(result);
         },

         setColumnOrder() {
             var new_order = [];
             for (var i in this.groups) {
                 var grp = this.groups[i];
                 for (var j in this.config[grp].columns) {
                     var col = this.config[grp].columns[j];
                     new_order.push(col);
                 }
             }
             return this.editingWidget.setColumnOrder(new_order);
         },

         setSort: function(group, direction) {
             this.sortColumn = group;
             this.sortDirection = direction;
             var col = this.config[group].columns[0];
             var result = this.editingWidget.setSort(col, direction);
             this.sendConfig(result);
         },

         sendConfig: function(dataPoints) {
             this.$parent.updatePending(this.editingWidget.id);

             var payload = {
                 widget_id: this.editingWidget.id,
                 data_points: JSON.stringify(dataPoints),
             }

             $.ajax({
                 url: '/dashboardv2/setDataPoints',
                 method: 'GET',
                 data: payload
             }).done(function(result){
                 if (result.success) {
                     window.app.rootVue.$broadcast('widget:pend_config', this.editingWidget.id);
                     if (result.newConfig) {
                         this.editingWidget.localConfig = {...result.newConfig};
                     }
                 }
             }.bind(this))

         }
     },
     vueReady() {
        const dataPoints = this.editingWidget.getDataPoints() || [];
        this.groups = [...dataPoints];
        const config = this.editingWidget.localConfig.custom_controls
            ? this.editingWidget.localConfig.custom_controls.data_point_config
            : null;
        if (config) {
            this.config = {...config};
        }
        if (this.editingWidget.sortColumn.length && this.editingWidget.sortDirection.length) {
            this.sortDirection = this.editingWidget.sortDirection;
            for (var grp in this.config) {
                if (this.config[grp].columns.includes(this.editingWidget.sortColumn)) {
                    this.sortColumn = grp;
                }
            }
        }
         Vue.nextTick(() => {
             this.ready = true;
         });
     }
 });
 export default ColumnGroups;
</script>
