<style>
    .port-scan-message {
        display: flex;
        justify-content: center;
        padding: 40px 0 40px 0;
    }
    #monitoring-config-filter {
        align-items: center;
        display: flex;
        border: 1px solid #dcdcdc;
        border-radius: 4px;
        height: 30px;
        padding: 0;
        position: relative;
        right: initial;
        top: initial;
        width: 30px;
    }
    #monitoring-config-filter:hover {
        border-color: #bcc5d1;
    }
    #monitoring-config-filter:hover .pa-icon {
        fill: #396abf;
    }
    /* Fixes tags input overflow */
    #bulk_tag .pa-panel-bd {
        overflow: visible;
    }
    .metric-box {
        overflow: visible;
    }
    #catalog-filter {
        align-items: center;
        display: flex;
        border: 1px solid #dcdcdc;
        border-radius: 4px;
        height: 30px;
        padding: 0;
        position: relative;
        right: initial;
        top: initial;
        width: 30px;
    }
    #catalog-filter:hover {
        border-color: #bcc5d1;
    }
    #catalog-filter:hover .pa-icon {
        fill: #396abf;
    }
    .catalog-table td {
        padding-left: 12px;
    }
</style>

<template>
    <div class="new-instance-details-theme">
        <div class="monitoring-catalog">
            <p-detected-applications
                v-if="detectedCategories.length > 0 && !isTemplate && serverEditAccess"
                :categories="detectedCategories"
                :add-service="addService"
                :open-opt-out="openOptOut"
                :dismiss-category="dismissCategory"
            >
            </p-detected-applications>
            <p-flex align-center space-between class="pa-pb-8">
                <div v-if="hasInboundOrOutboundCategories">
                    <p-toggle-chip-list
                        :chips="catalogFilterOptions"
                        :model="catalogFilteredOptions"
                        @change="onCatalogFilteredOptionsChange"
                        class="pa-pl-6 pa-pb-6"
                    >
                    </p-toggle-chip-list>
                </div>
                <div class="pa-flex-shrink-0">
                    <div>
                        <p-button
                            v-if="canAddMetrics"
                            @click="openServiceCard"
                            id="add-monitoring"
                            variant="blue"
                        >
                            Add Monitoring
                        </p-button>
                        <p-button
                            v-if="isNetworkDevice && canEditInstance"
                            @click="openEditPortTagsDrawer"
                            variant="blue-secondary"
                            class="pa-ml-4"
                        >
                            Tag Ports
                        </p-button>
                    </div>
                </div>
            </p-flex>
            <div class="catalog-sticky-header-bd">
                <div class="pa-grid">
                <div class="pa-grid-col pa-grid-col_7of12 pa-justify-end">
                    <p-bulk-metric-actions
                        :server-id="serverId"
                        :all-tags="allTags"
                        ref="bulkMetricActions"
                        :can-tag-metrics="canTagMetrics"
                        :can-pause-metrics="canPauseMetrics"
                        :can-delete-metrics="canDeleteMetrics"
                    ></p-bulk-metric-actions>
                </div>
                <div class="pa-grid-col pa-grid-col_3of12" style="margin-left: auto;">
                    <ul class="pa-hList" style="text-align: right; justify-content: flex-end;">
                      
                        <li>
                            <form class="pa-input pa-input_phony pa-input_sm" onsubmit="event.preventDefault();return;" style="height: 32px;">
                            <label for="search" class="pa-isVisuallyHidden">Search</label>
                            <input v-model="tableSearch" type="search" id="search"
                                style="height: 32px; font-size: 13px;">
                            <div class="pa-input_phony-icon">
                                <p-icon icon="magnify" size="xl" block>
                                </p-icon>
                            </div>
                            </form>
                        </li>
                    </ul>
                </div>

                </div>
            </div>

            <p-divider class="pa-mt-0 pa-mb-8"></p-divider>

            <div v-if="loading" class="catalog-body-loading">
                <div class="pa-loader"></div>
            </div>

            <template v-else>

                <template v-if="displayDeviceMetricsCategories">

                    <p-flex class="pa-py-12" space-between>
                        <h2>Device Metrics</h2>
                            <p-select
                                :options="deviceMetricsOptions"
                                :model="selectedDeviceMetricsOptions"
                                multiple
                                searchable
                            >
                                <div slot="display-text">
                                    <span class="pa-txt_medium">
                                       {{
                                       selectedDeviceMetricsOptions.length
                                       ? `Metrics: ${selectedDeviceMetricsOptions.length} Selected`
                                       : "Metrics: All"
                                       }}
                                    </span>
                                </div>
                            </p-select>
                    </p-flex>

                    <div class="catalog-body">
                        <div v-if="!networkDeviceFirstScanDone" class="port-scan-message">
                            <h3 class="pa-hdg pa-hdg_3">Initial SNMP port discovery is in progress. </h3>
                        </div>

                        <p-monitoring-catalog-table
                            v-else
                            id="device-metrics-catalog-table"
                            :server-id="serverId"
                            :filtered-categories="filteredCategories"
                            :is-template="isTemplate"
                            :hasAgent="hasAgent"
                            :is-container="isContainer"
                            :can-add-metrics="canAddMetrics"
                            :opened-categories="openedCategories"
                            :checked="checked"
                            :search-query="tableSearch"
                            :show-checkboxes="showCheckboxes"
                            @check-all="checkAll"
                            @checked="handleChecked"
                            @toggle-category="toggleCategory"
                            @category-check="checkCategory"
                            @show-docs="showDocs"
                            @dismiss-category="dismissCategory"
                        >
                        </p-monitoring-catalog-table>
                    </div>

                </template>

                <template v-if="agentHasDem">

                    <template v-if="displayInboundCategories">

                        <p-flex class="pa-py-12" space-between>
                            <p-flex align-center>
                                <p-text as="h2" class="pa-m-0 pa-mr-4">
                                    Inbound Synthetics
                                </p-text>
                                <p-flyout
                                    direction="bottom"
                                    scroll-lock
                                    hover
                                    :hover-timeout="0"
                                    :width="350"
                                    :arrow-percent="0"
                                >
                                    <p-icon
                                        slot="trigger"
                                        icon="question-circle-outline"
                                        color="cloudy-blue"
                                        size="sm"
                                    >
                                    </p-icon>
                                    <p-text slot="content">
                                        Checks running on this instance from other instances.
                                    </p-text>
                                </p-flyout>
                            </p-flex>
                            <p-select
                                :options="inboundMetricsOptions"
                                :model="selectedInboundMetricsOptions"
                                multiple
                            >
                            </p-select>
                        </p-flex>

                        <p-monitoring-catalog-table
                            id="inbound-catalog-table"
                            :server-id="serverId"
                            :filtered-categories="filteredInboundCategories"
                            :is-template="isTemplate"
                            :hasAgent="hasAgent"
                            :is-container="isContainer"
                            :can-add-metrics="canAddMetrics"
                            :opened-categories="openedCategories"
                            :checked="checked"
                            :search-query="tableSearch"
                            :show-checkboxes="showCheckboxes"
                            @check-all="checkAllInbound"
                            @checked="handleChecked"
                            @toggle-category="toggleCategory"
                            @category-check="checkCategory"
                            @show-docs="showDocs"
                            @dismiss-category="dismissCategory"
                            is-inbound
                        >
                        </p-monitoring-catalog-table>

                    </template>

                    <template v-if="displayOutboundCategories">

                        <p-flex class="pa-py-12" space-between>
                            <div>
                                <p-flex align-center>
                                    <p-text as="h2" class="pa-m-0 pa-mr-4">
                                        Outbound Synthetics
                                    </p-text>
                                    <p-flyout
                                        direction="bottom"
                                        scroll-lock
                                        hover
                                        :hover-timeout="0"
                                        :width="350"
                                        :arrow-percent="0"
                                    >
                                        <p-icon
                                            slot="trigger"
                                            icon="question-circle-outline"
                                            color="cloudy-blue"
                                            size="sm"
                                        >
                                        </p-icon>
                                        <p-text slot="content">
                                            Checks running from this instance to other instances.
                                        </p-text>
                                    </p-flyout>
                                </p-flex>
                                <p-text class="pa-txt_secondary pa-pt-4" font-size="xs">
                                    Can be edited only from the target instance
                                </p-text>
                            </div>
                            <p-select
                                :options="outboundMetricsOptions"
                                :model="selectedOutboundMetricsOptions"
                                multiple
                            >
                            </p-select>
                        </p-flex>

                        <p-monitoring-catalog-table
                            id="outbound-catalog-table"
                            :server-id="serverId"
                            :filtered-categories="filteredOutboundCategories"
                            :is-template="isTemplate"
                            :hasAgent="hasAgent"
                            :is-container="isContainer"
                            :opened-categories="openedCategories"
                            :search-query="tableSearch"
                            is-outbound
                            @toggle-category="toggleCategory"
                            @show-docs="showDocs"
                        >
                        </p-monitoring-catalog-table>

                    </template>

                </template>

            </template>

        </div>
        <p-edit-port-tags-drawer
            v-if="canEditInstance"
            ref="edit-port-tags-drawer"
            :instance-id="serverId"
            :instance-name="instanceName"
            :all-tags="allTags"
        >
        </p-edit-port-tags-drawer>
        <p-metric-drawer
            ref="metric-drawer"
            :operating-system="operatingSystem"
            :is-template="isTemplate"
        >
        </p-metric-drawer>
        <p-editmetric-drawer ref="edit-metric-drawer"></p-editmetric-drawer>
        <p-service-card ref="service-card"></p-service-card>
        <p-modal id="opt_out">
            <div class="pa-panel">
                <div class="pa-panel-hd">
                    <h2 class="pa-hdg pa-hdg_3" slot="header">Ignore discovered application</h2>
                </div>
                <div class="pa-panel-bd" style="text-align: center;">
                    <p class="pa-txt" v-if="detectedCategoriesString">
                        Do you want to always ignore {{ detectedCategoriesString }} when discovered on your account?
                    </p>
                    <label class="pa-option">
                        <input type="checkbox" v-model="optOutAll" class="pa-option-input" />
                        <span class="pa-option-icon"></span>
                        <span class="pa-option-txt pa-txt_secondary">
                            Hide all discovered applications on my account (not advised)
                        </span>
                    </label>
                </div>
                <div class="pa-panel-ft">
                    <ul class="pa-split">
                        <li></li>
                        <li>
                            <button class="pa-btn pa-btn_cancel" type="button" v-p-dialog-close target="opt_out">Cancel</button>
                            <button @click="optOutDiscovery" class="pa-btn" value="Confirm">Confirm</button>
                        </li>
                    </ul>
                </div>
            </div>
        </p-modal>
    </div>
</template>

<script>
    import Vue from 'vue';

    import EditMetricDrawer from './EditMetricDrawer.vue';
    import MetricDrawer from './MetricDrawer.vue';
    import EditPortTagsDrawer from './EditPortTagsDrawer.vue';
    import ServiceCard from './ServiceCard.vue';
    import DetectedApplications from './DetectedApplications.vue';
    import MonitoringCatalogTable from './MonitoringCatalogTable.vue';
    import BulkMetricActions from './components/BulkMetricActions.vue'

    const MonitoringCatalog2 = Vue.extend({
        components: {
            'p-editmetric-drawer': EditMetricDrawer,
            'p-metric-drawer': MetricDrawer,
            'p-edit-port-tags-drawer': EditPortTagsDrawer,
            'p-service-card': ServiceCard,
            'p-detected-applications': DetectedApplications,
            'p-monitoring-catalog-table': MonitoringCatalogTable,
            'p-bulk-metric-actions': BulkMetricActions,
        },

        events: {
            'category-added': function(category) {
                let newCategory = Object();
                Object.assign(newCategory, category);
                newCategory.metrics = [];
                newCategory.errors = [];
                if (newCategory.messages) {
                    newCategory.errors = newCategory.messages;
                }
                newCategory.addable = true;
                newCategory.numFiltered = 0;
                this.openedCategories.push(newCategory.name);
                this.categories = {
                    ...this.categories,
                    added: [
                        ...this.categories.added,
                        newCategory
                    ]
                };
                this.categories.added.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
                let removeIndex = this.categories.detected.findIndex((c) => {
                    return c.textkey === newCategory.textkey;
                });
                if (removeIndex > -1) {
                    this.categories.detected.splice(removeIndex, 1);
                }
            },
            'metric-added': function(categoryTextkey, categoryName, instance, isInbound) {
                const added = isInbound ? this.inboundCategories.added : this.categories.added;
                let index = added.findIndex((c) => {
                    return c.name === categoryName;
                });
                if (index > -1) {
                    let category = added[index];
                    let metricIndex = category.metrics.findIndex((m) => {
                        return m.id === instance.id || -m.id === instance.id;
                    });
                    if (metricIndex > -1) {
                        Object.assign(category.metrics[metricIndex], instance);
                    } else {
                        category.metrics.push(instance);
                    }
                    category.metrics.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });
                    this.$broadcast('highlight-metric', categoryName, instance);
                } else {
                    // Need to create a new faux-category
                    const fauxCategory = {
                        addable: true,
                        errors: [],
                        metrics: [instance],
                        name: categoryName,
                        numFiltered: 0,
                        status: 'added',
                        textkey: categoryTextkey,
                    };

                    if (isInbound) {
                        this.inboundCategories.added.push(fauxCategory);
                        this.inboundCategories.added.sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });
                    } else {
                        this.categories.added.push(fauxCategory);
                        this.categories.added.sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });
                    }
                    Vue.nextTick(() => {
                        this.$broadcast('highlight-metric', categoryName, instance);
                    });
                }
                this.eventHub.$emit('reload_graph_config');
            },
            'highlight-metric': function(categoryName, instance) {
                if (!this.openedCategories.includes(categoryName)) {
                    this.openedCategories.push(categoryName);
                }
            },

            'finished-bulk-action': function() {
                this.$refs.bulkMetricActions.setChecked([])
                this.getMonitoringConfigData();
            },
        },

        data() {
            return {
                localCategories: this.categories,
                openedCategories: [],
                checked: [],
                tableSearch: '',
                loading: true,
                optOutCategories: null,
                optOutAll: false,
                bulkTagSpinner: false,
                bulkTags: [],
                catalogFilteredOptions: [],
                serverEditAccess: false,
                categories: {
                    detected: [],
                    added: [],
                },
                inboundCategories: {
                    detected: [],
                    added: [],
                },
                outboundCategories: {
                    detected: [],
                    added: [],
                },
                localApplications: this.applications,
                deviceMetricsOptions: [],
                inboundMetricsOptions: [],
                outboundMetricsOptions: [],
                selectedDeviceMetricsOptions: [],
                selectedInboundMetricsOptions: [],
                selectedOutboundMetricsOptions: [],
            };
        },

        computed: {
            hasInboundOrOutboundCategories() {
                return this.inboundCategories.added.length || this.outboundCategories.added.length;
            },
            displayDeviceMetricsCategories() {
                if (
                    this.catalogFilteredOptions.length === 0 ||
                    this.catalogFilteredOptions.includes("Device Metrics")
                ) {
                    return true;
                }

                return false;
            },
            displayInboundCategories() {
                if (
                    this.inboundCategories.added.length &&
                    (
                        this.catalogFilteredOptions.length === 0 ||
                        this.catalogFilteredOptions.includes("Inbound Synthetics")
                    )
                ) {
                    return true;
                }

                return false;
            },
            displayOutboundCategories() {
                if (
                    this.outboundCategories.added.length &&
                    (
                        this.catalogFilteredOptions.length === 0 ||
                        this.catalogFilteredOptions.includes("Outbound Synthetics")
                    )
                ) {
                    return true;
                }

                return false;
            },
            detectedCategoriesString: function() {
                return this.detectedCategories.map(c => c.name).join(', ');
            },
            detectedCategories: function() {
                const categories = _.differenceBy(
                    this.categories.detected,
                    this.categories.added,
                    'textkey'
                ).filter(category => category);

                return categories;
            },
            detectedCategoriesLength: function() {
                return this.detectedCategories.length;
            },
            filteredCategories: function() {
                let categories = this.categories.added;

                if (this.selectedDeviceMetricsOptions.length) {
                    categories = categories.filter(
                        category => this.selectedDeviceMetricsOptions.includes(category.name)
                    );
                }

                if (this.catalogFilteredOptions.length === 0) {
                    return categories;
                }

                if (this.catalogFilteredOptions.includes("Device Metrics")) {
                    return categories;
                }

                return categories.filter(this.filterAddedCategories);
            },
            filteredInboundCategories: function() {
                let categories = this.inboundCategories.added;

                if (this.selectedInboundMetricsOptions.length) {
                    categories = categories.filter(
                        category => this.selectedInboundMetricsOptions.includes(category.name)
                    );
                }

                if (this.catalogFilteredOptions.length === 0) {
                    return categories;
                }

                if (this.catalogFilteredOptions.includes("Inbound Synthetics")) {
                    return categories;
                }

                return categories.filter(this.filterAddedCategories);
            },
            filteredOutboundCategories: function() {
                let categories = this.outboundCategories.added;

                if (this.selectedOutboundMetricsOptions.length) {
                    categories = categories.filter(
                        category => this.selectedOutboundMetricsOptions.includes(category.name)
                    );
                }

                if (this.catalogFilteredOptions.length === 0) {
                    return categories;
                }

                if (this.catalogFilteredOptions.includes("Outbound Synthetics")) {
                    return categories;
                }

                return categories.filter(this.filterAddedCategories);
            },
            catalogFilterOptions: function() {
                if (this.hasInboundOrOutboundCategories) {
                    let catalogFilterOptions = [
                        { label: "Device Metrics", value: "Device Metrics" },
                    ];

                    if (this.inboundCategories.added.length) {
                        catalogFilterOptions.push({
                            label: "Inbound Synthetics",
                            value: "Inbound Synthetics"
                        });
                    }

                    if (this.outboundCategories.added.length) {
                        catalogFilterOptions.push({
                            label: "Outbound Synthetics",
                            value: "Outbound Synthetics"
                        });
                    }

                    return catalogFilterOptions;
                }

                return this.categories.added.map((category) => ({
                    label: category.name,
                    value: category.name,
                }));
            },
            tableActionIconColor: function() {
                // return !this.checked.length ? '' : 'blue';
            },
            tableActionButtonStyles: function() {
                // const isDisabled = !this.checked.length;
                // const styles = {
                //     width: 150,
                //     height: 35,
                //     marginRight: 5,
                //     opacity: isDisabled ? 0.5 : 1,
                // };
                // return styles;
            },
            checkedCounts: function() {
                let returnObj = {};
                this.categories.added.forEach((c) => {
                    returnObj[c.textkey] = 0;
                });
                this.checked.forEach((checked) => {
                    let containingCategory = this.categories.added.find((category) => {
                        let index = category.metrics.findIndex((m) => {
                            return m.id === checked;
                        });
                        return index > -1;
                    });
                    if (containingCategory) {
                        returnObj[containingCategory.textkey] += 1;
                    }
                });
                return returnObj;
            },
            showCheckboxes: function() {
                return this.canAddMetrics || this.canTagMetrics || this.canPauseMetrics || this.canDeleteMetrics;
            }
        },

        props: {
            isNetworkDevice: {
                type: Boolean,
                default: false,
            },
            isFabric: {
                type: Boolean,
                default: false,
            },
            isServer: {
                type: Boolean,
                default: true,
            },
            isTemplate: {
                type: Boolean,
                default: false,
            },
            isContainer: {
                type: Boolean,
                default: false,
            },
            serverId: {
                type: Number,
                default: null,
            },
            hasAgent: {
                type: Boolean,
                default: false,
            },
            fromAppliance: {
                type: Boolean,
                default: false,
            },
            applianceId: {
                type: Number,
                default: null,
            },
            startOpen: {
                type: Boolean,
                default: false,
            },
            allTags: Array,
            seenMetricDrawer: {
                type: Boolean,
                default: true,
            },
            canAddMetrics: {
                type: Boolean,
                default: false,
            },
            canTagMetrics: {
                type: Boolean,
                default: false,
            },
            canPauseMetrics: {
                type: Boolean,
                default: false,
            },
            canDeleteMetrics: {
                type: Boolean,
                default: false,
            },
            networkDeviceFirstScanDone: {
                type: Boolean,
                default: true,
            },
            instanceName: String,
            operatingSystem: String,
            agentHasDem: {
                type: Boolean,
                default: false,
            },
            applications: {
                type: Object,
                default: function() {
                    return {
                        detected: [],
                        not_detected: [],
                    };
                }
            },
            canEditInstance: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            onCatalogFilteredOptionsChange(val) {
                this.catalogFilteredOptions = [...val];
            },
            filterAddedCategories(category) {
                return this.catalogFilteredOptions.includes(category.name);
            },
            handleChecked(value) {
                this.checked = _.xor(this.checked, [value]);
                this.$refs.bulkMetricActions.setChecked(this.checked)
            },
            openEditPortTagsDrawer: function() {
                this.$broadcast('edit-port-tags-drawer:open');
            },
            updateMonitoringConfigNotification: function() {
                const title = this.detectedCategoriesLength === 1
                    ? "1 metric detected to configure"
                    : `${this.detectedCategoriesLength} metrics detected to configure`;

                const notification = {
                    tab: 'monitoringCatalog',
                    value: this.detectedCategoriesLength || null,
                    title,
                };

                this.eventHub.$emit('tabNotification', notification);
            },
            renderPromptBody: function(promptType) {
                const checkedCount = this.checked && this.checked.length ? this.checked.length : 0;

                if (promptType === "resume" || promptType === "pause") {
                    let promptBody = "Would you like to " + promptType;

                    if (checkedCount === 1) {
                        return promptBody + " " + this.findCheckedMetric() + "?";
                    }

                    return promptBody + " these " + checkedCount + " metrics?";
                } else if (promptType === 'remove') {
                    let promptBody = "Are you sure you'd like to remove";

                    if (checkedCount === 1) {
                        promptBody += " this metric? It's ";
                    } else {
                        promptBody += " these metrics? Their ";
                    }

                    return promptBody + "performance data will also be removed.";
                }
            },
            findCheckedMetric: function() {
                let foundCheckedMetric = null;

                if (this.checked.length === 1) {
                    const added = [
                        ...this.categories.added,
                        ...this.inboundCategories.added,
                        ...this.outboundCategories.added,
                    ];
                    const checkedMetric = this.checked[0];

                    for (let i = 0; i < added.length; i++) {
                        const category = added[i];

                        for (let j = 0; j < category.metrics.length; j++) {
                            const metric = category.metrics[j];

                            if (metric.id === checkedMetric) {
                                foundCheckedMetric = metric.name;
                                break;
                            }
                        }

                        if (foundCheckedMetric) {
                            break;
                        }
                    }
                }

                return foundCheckedMetric;
            },
            toggleCategory: function(event, name) {
                if (event.target.classList.contains('pa-option-icon')) {
                    return;
                }

                const index = this.openedCategories.indexOf(name);

                if (index > -1) {
                    this.openedCategories.splice(index, 1);
                } else {
                    this.openedCategories.push(name);
                }
            },
            dismissCategory: function(textkey) {
                var payload = {
                    server_id: this.serverId,
                    category_textkey: textkey,
                };
                $.ajax("dismiss_category", {
                    method: 'GET',
                    data: payload,
                    context: this
                })
                .done(data =>{
                    if (!data.success) {
                        console.log(data.msg)
                        return;
                    }
                    let newCategories = this.categories.detected.filter((c) => {
                        return c.textkey !== textkey;
                    });
                    this.categories = {
                        ...this.categories,
                        detected: [...newCategories]
                    };
                });
            },
            addService: function(textkey) {
                var payload = {
                    server_id: this.serverId,
                    category_textkey: textkey,
                };
                $.ajax("get_metric_category_data_for_add", {
                    method: 'GET',
                    data: payload,
                    context: this
                })
                .done(data =>{
                    if (!data.success) {
                        console.log(data.msg)
                        return;
                    }
                    let index = this.categories.detected.findIndex((c) => {
                        return c.textkey === textkey;
                    });
                    if (index > -1) {
                        let category = this.categories.detected.splice(index, 1);
                        if (category.length) {
                            category = category[0];
                        } else {
                            return;
                        }
                        category.addable = true;
                        category.numFiltered = 0;
                        category.errors = data.messages;
                        this.openedCategories.push(category.name);
                        this.categories.added.push(category);
                        this.categories.added.sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });
                        if (data.has_supported_metric || this.isTemplate) {
                            this.openDrawer(null, textkey, null);
                        } else {
                            const showedDocs = this.showDocs(category);
                            if (!showedDocs) {
                                // We don't have docs for this category
                                // Open the drawer instead
                                this.openDrawer(null, textkey);
                            }
                        }
                    }
                });
            },
            deleteCategory: function(category) {
                var payload = {
                    server_id: this.serverId,
                    category_textkey: category.textkey,
                };
                if (category.is_snmp) {
                    payload.is_snmp = true;
                }
                $.ajax("remove_metric_category", {
                    method: 'GET',
                    data: payload,
                    context: this
                })
                .done(data =>{
                    if (!data.success) {
                        console.log(data.msg)
                        return;
                    }
                    this.removeCategoryFromLocalData(category)
                });
            },
            removeCategoryFromLocalData: function(category) {
                    let index = this.categories.added.findIndex((c) => {
                        return c.textkey === category.textkey;
                    });
                    if (index > -1) {
                        this.categories.added.splice(index, 1);
                    }
                    index = this.localApplications.detected.findIndex((c) => {
                        return c.textkey === category.textkey;
                    });
                    if (index > -1) {
                        let app = this.localApplications.detected[index];
                        this.localApplications.detected.splice(index, 1);
                        app.status = "not-detected";
                        this.localApplications.not_detected.push(app);
                    }
            },
            openDrawer: function(event, textkey, categoryName, isInbound) {
                if (typeof event !== 'undefined' && event) {
                    event.stopPropagation();
                }
                let subCategoryName = null;
                if (categoryName && categoryName.includes(':')) {
                    const parts = categoryName.split(':');
                    subCategoryName = parts[parts.length - 1].trim();
                }
                this.$broadcast('metric-drawer:open', this.serverId, textkey, subCategoryName, false, isInbound);
            },
            openServiceCard: function(textkey) {
                this.$broadcast('service-card:open');
            },
            checkCategory: function(event, c) {
                if (typeof event !== 'undefined' && event) {
                    event.stopPropagation();
                }
                if (event.target.checked) {
                    this.getChecked(c.metrics);
                    this.$refs.bulkMetricActions.setChecked(this.checked);
                } else {
                    this.checked = [...this.getUnchecked(c.metrics)];
                    this.$refs.bulkMetricActions.setChecked(this.checked);
                }
            },
            checkAll: function(event) {
                Object.keys(this.filteredCategories).forEach((key) => {
                    let category = this.categories.added[key];
                    if (event.target.checked) {
                        this.getChecked(category.metrics);
                        $('#device-metrics-catalog-table .category-row .pa-option-input').prop('checked', true);
                        this.$refs.bulkMetricActions.setChecked(this.checked);
                    } else {
                        this.checked = [...this.getUnchecked(category.metrics)];
                        $('#device-metrics-catalog-table .category-row .pa-option-input').prop('checked', false);
                        this.$refs.bulkMetricActions.setChecked(this.checked);
                    }
                });
            },
            checkAllInbound(event) {
                Object.keys(this.filteredInboundCategories).forEach((key) => {
                    let category = this.inboundCategories.added[key];
                    if (event.target.checked) {
                        this.getChecked(category.metrics);
                        $('#inbound-catalog-table .category-row .pa-option-input').prop('checked', true);
                    } else {
                        this.checked = [...this.getUnchecked(category.metrics)];
                        $('#inbound-catalog-table .category-row .pa-option-input').prop('checked', false);
                    }
                });
            },
            getChecked(metrics) {
                metrics.forEach((m) => {
                    if (!this.checked.includes(m.id)) {
                        if (this.tableSearch.length) {
                            const matches = m.name.toLowerCase().includes(this.tableSearch.toLowerCase());
                            if (matches) {
                                this.checked.push(m.id);
                            }
                        } else {
                            this.checked.push(m.id);
                        }
                    }
                });
            },
            getUnchecked(metrics) {
                let ids = [];
                metrics.forEach((m) => {
                    ids.push(m.id);
                });
                let newChecked = this.checked.filter((c) => {
                    return !ids.includes(c);
                });
                return newChecked;
            },
            showDocs: function(category) {
                let found = this.$refs.serviceCard.detected.find((a) => {
                    return a.textkey === category.textkey;
                });
                if (found && found.doc_name) {
                    found.messages = category.errors;
                    this.$refs.serviceCard.showDocs(found);
                    Vue.nextTick(() => {
                        this.openServiceCard();
                    });
                    return true;
                }
                return false;
            },
            classify: function(string) {
                return string.replace(/ /g, '-');
            },
            isNetOnly: function(category) {
                let app = this.localApplications.detected.find((a) => {
                    return a.textkey === category.textkey;
                });
                if (app) {
                    return Boolean(app.net_only);
                }
                return false;
            },
            openOptOut: function() {
                this.optOutCategories = [...this.detectedCategories];
                this.optOutAll = false;
                this.$broadcast('dialog:open', 'opt_out');
            },
            optOutDiscovery: function(category) {
                if (!this.optOutCategories) { return; }
                var optOutCategoriesTextkeys = this.optOutCategories.map(o => o.textkey).join(",");
                var payload = {
                    category_textkeys: optOutCategoriesTextkeys,
                    opt_out_all: this.optOutAll,
                };
                $.ajax("opt_out_metric_category", {
                    method: 'GET',
                    data: payload,
                    context: this
                })
                .done(data =>{
                    if (!data.success) {
                        console.log(data.msg)
                        return;
                    }
                    this.$broadcast('dialog:close', 'opt_out');
                    if (!this.optOutAll) {
                        let newCategories = this.categories.detected.filter((c) => {
                            return !optOutCategoriesTextkeys.includes(c.textkey);
                        });
                        this.categories = {
                            ...this.categories,
                            detected: [...newCategories],
                        };
                    } else {
                        this.categories = {
                            ...this.categories,
                            detected: [],
                        };
                    }
                });
            },
            showCountermeasure: function(metric, delay, countermeasureId) {
                this.$refs.editMetricDrawer.showCountermeasure(metric, delay, countermeasureId);
            },
            showToast: function(text) {
                window.app.toastManager.clearToasts();
                window.app.toastManager.showToast(text);
            },

            pollNetworkDeviceFirstScanDone: function() {
                $.ajax("/report/networkDeviceFirstScan", {
                    method: "GET",
                    data: {server_id: this.serverId},
                    context: this
                }).done(data => {
                    if (data.success) {
                        this.networkDeviceFirstScanDone = data.first_port_scan_done;
                        if (!this.networkDeviceFirstScanDone) {
                            setTimeout(() => {this.pollNetworkDeviceFirstScanDone()}, 20000)
                        } else {
                            this.getMonitoringConfigData();
                        }
                        return true;
                    } else {
                        return false;
                    }
                })
            },

            getMonitoringConfigData: function() {
                var payload = {
                    server_id: this.serverId,
                };
                if (this.applianceId !== null) {
                    payload.appliance_id = this.applianceId;
                }
                $.ajax("get_monitoring_config_data", {
                        method: 'GET',
                        data: payload,
                        context: this
                    })
                    .done(data => {
                        if (!data.success) {
                            console.log(data.msg)
                            return;
                        }
                        this.checked = [];
                        this.loading = false;
                        // Setup our counters so Vue can bind
                        Object.keys(data.categories.added).forEach((key) => {
                            data.categories.added[key].numFiltered = 0;
                        });

                        if (data.categories.added[0]) {
                            data.categories.detected.push(data.categories.added[0])
                        }
                        this.serverEditAccess = data.server_edit_access;
                        this.categories = {...data.categories};
                        this.inboundCategories = {...data.inbound_categories};
                        this.outboundCategories = {...data.outbound_categories};
                        this.deviceMetricsOptions = this.categories.added.map(c => ({
                            label: c.name,
                            value: c.name,
                        }));
                        this.inboundMetricsOptions = this.inboundCategories.added.map(c => ({
                            label: c.name,
                            value: c.name,
                        }));
                        this.outboundMetricsOptions = this.outboundCategories.added.map(c => ({
                            label: c.name,
                            value: c.name,
                        }));

                        Vue.nextTick(() => {
                            this.categories.added.forEach(c => {
                                this.openedCategories.push(c.name);
                            });
                            this.inboundCategories.added.forEach(c => {
                                this.openedCategories.push(c.name);
                            });
                            this.outboundCategories.added.forEach(c => {
                                this.openedCategories.push(c.name);
                            });
                            if (this.startOpen) {
                                window.setTimeout(() => {
                                    this.openServiceCard();
                                }, 700);
                            } else {
                                // Add an id to the first metric expand icon on the page
                                // used by hopscotch
                                const expandIcons = $('.pa-icon.expand-metric');
                                if (expandIcons.length) {
                                    expandIcons[0].id = 'first-metric-expand';
                                }
                            }
                        });
                    });
                }
        },

        vueReady() {
            this.$watch('detectedCategoriesLength', this.updateMonitoringConfigNotification);
            this.getMonitoringConfigData();

            window.catalogVue = this;

            if (!this.networkDeviceFirstScanDone) {
                this.pollNetworkDeviceFirstScanDone();
            }
        },
    });

    export default MonitoringCatalog2;
</script>
