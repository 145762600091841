
class TreeManager {
    constructor(tree) {
        this.tree = tree;
    }
}


TreeManager.addInstance = function(treeInstance) {
    window.trees = []
    window.trees.push(treeInstance);
}

TreeManager.getInstance = function(treeId) {
    const instance = window.trees.filter((tree) => {
        return tree.id === treeId
    })[0];

    return instance;

}

export default TreeManager;
