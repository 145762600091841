<style>
.rule-container li {
  border-bottom: solid 1px #dbdee0;
  padding-bottom: 20px;
}
.outer-list div:last-child li {
  border-bottom: none;
}
</style>

<template>
  <div>
    <ul class="pa-vList pa-vList_x2 outer-list">
      <li class="pa-pb-12" v-if="show_resource_filter">
        <p-row>
          <p-column cols="12">
            <label class="pa-option pa-label pa-txt_sm">
              <input type="checkbox" class="pa-option-input" @click="toggleFilter('resource')" :checked="selectedFilters['resource']" />
              <span class="pa-option-icon pa-mr-12" style="margin-left: -4px;"></span>
              Filter by {{ optionLabel || 'Resource' }}
            </label>
            <div class="pa-ml-24">
              <div v-if="selectedFilters['resource']" class="pa-p-12" style="background: #f2f3f5; border-radius: 4px;">
                <p-field-group prepend-select style="margin-bottom: 0;">
                  <p-select
                    :options="rule_types"
                    :model.sync="filters['resource']['match_type']"
                  >
                  </p-select>
                  <input
                    v-if="!isSelectMode"
                    type="text"
                    class="pa-input pa-input_xxl"
                    v-model="filters['resource']['value']"
                  />
                  <p-select
                    v-else
                    :options="metadata_options['resource']"
                    :model.sync="filters['resource']['value']"
                  >
                  </p-select>
                </p-field-group>
                <template v-if="!isSelectMode">
                  <p
                    class="pa-hint"
                    v-if="filters['resource']['match_type'] === 'positive_pattern'"
                  >
                    {{ optionLabel || 'Option' }}s matching this regex pattern will be added. Use * as a wildcard to match any character(s).
                    <a target="_blank" href="https://docs.fortimonitor.forticloud.com/user-guide/698542/template-pattern-matching">Learn more</a>.
                  </p>
                  <p
                    class="pa-hint"
                    v-if="filters['resource']['match_type'] === 'negative_pattern'"
                  >
                    {{ optionLabel || 'Option' }}s NOT matching this regex pattern will be added. Use * as a wildcard to match any character(s).
                    <a target="_blank" href="https://docs.fortimonitor.forticloud.com/user-guide/698542/template-pattern-matching">Learn more</a>.
                  </p>
                </template>
              </div>
            </div>
          </p-column>
        </p-row>
      </li>
      <template v-for="(type, option) in options_schema">
        <li
          :key="option"
          v-if="type === 'string' && option != 'resource' && !isSelectMode"
          class="pa-pb-12"
        >
          <p-row>
            <p-column cols="12">
              <label class="pa-option pa-label pa-txt_sm">
                <input type="checkbox" class="pa-option-input" @click="toggleFilter(option)" :checked="selectedFilters[option]" />
                <span class="pa-option-icon pa-mr-12" style="margin-left: -4px;"></span>
                {{ getOptionLabel(option) }}
              </label>
              <div class="pa-ml-24">
                <div v-if="selectedFilters[option]" class="pa-p-12" style="background: #f2f3f5; border-radius: 4px;">
                  <p-field-group prepend-select style="margin-bottom: 0;">
                    <p-select
                      :options="getRuleTypesForOption(option)"
                      :model.sync="filters[option]['match_type']"
                    >
                    </p-select>
                    <input
                      v-if="filters[option] && filters[option]['match_type'] != 'pick_list'"
                      type="text"
                      class="pa-input pa-input_xxl"
                      v-model="filters[option]['value']"
                    />
                    <p-select
                      v-else
                      :options="metadata_options[option]"
                      :model.sync="filters[option]['value']"
                    >
                    </p-select>
                  </p-field-group>
                  <template v-if="!isSelectMode">
                    <p
                      class="pa-hint"
                      v-if="filters[option] && filters[option]['match_type'] === 'positive_pattern'"
                    >
                      {{ getHelperTextLabel(option) }} matching this regex pattern will be added. Use * as a wildcard to match any character(s).
                      <a target="_blank" href="https://docs.fortimonitor.forticloud.com/user-guide/698542/template-pattern-matching">Learn more</a>.
                    </p>
                    <p
                      class="pa-hint"
                      v-if="filters[option] && filters[option]['match_type'] === 'negative_pattern'"
                    >
                      {{ getHelperTextLabel(option) }} NOT matching this regex pattern will be added. Use * as a wildcard to match any character(s).
                      <a target="_blank" href="https://docs.fortimonitor.forticloud.com/user-guide/698542/template-pattern-matching">Learn more</a>.
                    </p>
                  </template>
                </div>
              </div>
            </p-column>
          </p-row>
        </li>
        <li
          :key="option"
          v-if="type === 'boolean' && !isSelectMode"
          class="pa-pb-12"
        >
          <p-row>
            <p-column cols="12">
              <label class="pa-option pa-label pa-txt_sm">
                <input type="checkbox" class="pa-option-input" @click="toggleFilter(option)" :checked="selectedFilters[option]" />
                <span class="pa-option-icon pa-mr-12" style="margin-left: -4px;"></span>
                {{ getOptionLabel(option) }}
              </label>
              <div class="pa-ml-24">
                <div v-if="selectedFilters[option]" class="pa-p-12" style="background: #f2f3f5; border-radius: 4px;">
                  <p-field-group style="margin-bottom: 0;">
                    <p-select
                      :options="checkbox_options"
                      :model.sync="filters[option]['value']"
                    >
                    </p-select>
                  </p-field-group>
                </div>
              </div>
            </p-column>
          </p-row>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";

const ApplyRule = Vue.extend({
  props: {
    filters: {
      type: Object,
      default: {}
    },
    options_schema: {
      type: Object,
      default: {}
    },
    options: Object,
    selectedFilters: Object,
    toggleFilter: Function,
    optionLabel: String
  },

  data() {
    return {
      checkbox_options: [
        { value: "none", label: "Select from list" },
        { value: "true", label: "True" },
        { value: "false", label: "False" }
      ]
    };
  },

  watch: {
    isSelectMode: function(new_value, old_value) {
      if (new_value) {
        this.filters['resource']['value'] = this.options['resource'][0]
      } else {
        this.filters['resource']['value'] = '*'
      }
    }
  },

  computed: {
    show_resource_filter: function() {
      if (this.selectedFilters['resource']) { return true; }
      let option;
      for (option in this.options_schema) {
        if (option == 'resource') {
          return true;
        }
      }
      return false;
    },
    metadata_options: function() {
      let metadata_options = {};
      let option;
      for (option in this.options) {
        let option_values = this.options[option];
        let option_render = option_values.map(function(value, index, array) {
          return {value: value, label: value}
        })
        metadata_options[option] = option_render;
      }
      return metadata_options;
    },

    hasOptions: function() {
      return !_.isEmpty(this.metadata_options);
    },

    isSelectMode: function() {
      if (this.filters.resource) {
        return this.filters["resource"]["match_type"] === "pick_list";
      }
    },

    rule_types: function() {
      let rule_types = [];
      if (this.hasOptions) {
        rule_types.push({ value: "pick_list", label: "Select from list" });
      }
        rule_types.push({ value: "positive_pattern", label: "Positive Match" });
        rule_types.push({ value: "negative_pattern", label: "Negative Match" });
      return rule_types;
    }
  },

  methods: {
    getOptionLabel(option) {
      let option_name = _.capitalize(option).replace("_", " ");
      return `Filter by ${option_name}`;
    },

    getHelperTextLabel(option) {
      let option_name = _.capitalize(option).replace('_', ' ') + 's';
      return option_name;
    },

    getRuleTypesForOption: function(option) {
      let rule_types = [];
      if (!_.isEmpty(this.metadata_options[option])) {
        rule_types.push({ value: "pick_list", label: "Select from list" });
      }
        rule_types.push({ value: "positive_pattern", label: "Positive Match" });
        rule_types.push({ value: "negative_pattern", label: "Negative Match" });
      return rule_types;
    }
  },
});

export default ApplyRule;
</script>
