<style lang="scss">
.pa-widget-geomap {
    height: 100%;

    .pa-geomap-popup {
        a {
            color: #3954bf;
        }

        .leaflet-popup-content-wrapper {
            border-radius: 2px;
            box-shadow: 0 1px 2px 0 rgba(57, 62, 73, 0.3), 0 4px 11px 0 rgba(57, 62, 73, 0.15);
            background-color: #ffffff;
            font-size: 14px;
            padding: 0;

            .leaflet-popup-content {
                margin: 0;
            }
        }

        .leaflet-popup-tip-container {
            display: none;
        }
    }

    .marker-cluster {
        background-color: rgba(110, 204, 57, 0.6);

        div {
            background-color: rgba(110, 204, 57, 0.6);
        }
    }

    .marker-cluster-critical {
        background-color: rgba(241, 43, 26, 0.6);

        div {
            background-color: rgba(241, 43, 26, 0.6);
        }
    }

    .marker-cluster-warning {
        background-color: rgba(240, 194, 12, 0.6);

        div {
            background-color: rgba(240, 194, 12, 0.6);
        }
    }

    .geomap-popover {

        summary {
            border-top: 1px solid #e1e2e3;
            border-bottom: 1px solid #e1e2e3;
            font-weight: bold;
            font-family: Inter;
            padding: 8px;
        }

        details {
            div:first-of-type > ul > li {
                padding: 8px 0;
                margin: 0 8px;
                border-top: 1px solid #e1e2e3;
            }

            div:first-of-type > ul > li:first-child {
                border-top: none;
            }

            div:first-of-type > ul > li > *:not(:last-child){
                margin: 0;
                margin-bottom: 4px;
                display: block;
            }

            &:not([open]) + details summary {
                border-top: none;
            }
        }

        details + details summary {
            border-bottom: none;
        }
        details + details[open] summary {
            border-bottom: 1px solid #e1e2e3;
        }

        .popover-container {
            width: 350px;

            .popover-header {
                padding: 8px 14px;
            }

            .popover-body {
                details {
                    font-weight: normal;
                    font-family: Inter;

                    div:first-of-type {
                        max-height: 105px;
                        overflow-y: auto;
                    }
                }
            }
        }
    }
}
</style>

<template>
    <div
        class="pa-widget-geomap"
        ref="geomap-container"
        :id="mapId"
    >
    </div>
</template>

<script>
    import Vue from 'vue';
    import L from "leaflet";

    require("leaflet.markercluster");

    import generateID from '../utils/generateID';

    export default Vue.extend({
        data() {
            return {
                map: null,
                markerMapping: {},
                popups: {},
            };
        },

        props: {
            id: {
                type: String,
                default: () => generateID('geomap_'),
            },
            markers: {
                type: Array,
                default: () => [],
            },
        },

        computed: {
            mapId() {
                return `p-${this.id}`;
            },
            hasMap() {
                return Boolean(this.map);
            },
        },

        methods: {
            panMap(x, y) {
                if (this.map) {
                    this.map.panBy(new L.Point(x, y));
                }
            },
            resize() {
                if (!this.map) {
                    return;
                }

                this.map.invalidateSize();
            },
            handlePopupDetailToggle(e) {
                const popup =  document.querySelector("[data-geopopup-id]");
                const popupId = popup.dataset.geopopupId
                const detailsEl = e.target.parentElement;
                const isOpened = !detailsEl.open;
                const list =  detailsEl.querySelector("[data-geopopup-el='list-wrapper']");

                setTimeout(() => {
                    const listHeight = list.offsetHeight;

                    if (isOpened) {
                        this.popups = {
                            ...this.popups,
                            [popupId]: {
                                ...this.popups[popupId],
                                y: listHeight,
                            },
                        };

                        this.panMap(
                            this.popups[popupId].x,
                            -listHeight
                        );
                    } else {
                        this.panMap(
                            this.popups[popupId].x,
                            this.popups[popupId].y
                        );
                    }
                }, 0);
            },
            buildClusterPopup(a) {
                const { lat, lng } = a.latlng;
                const _lat = lat.toFixed(2);
                const _lng = lng.toFixed(2);
                const popupId = `${_lat},${_lng}`;

                if (!(popupId in this.popups)) {
                    this.popups = {
                        ...this.popups,
                        [popupId]: { x: 0, y: 0 },
                    };
                }

                let childrenMarkers;
                let childrenData = [];

                if (typeof a.layer.getAllChildMarkers === "function") {
                    childrenMarkers = a.layer.getAllChildMarkers();
                    childrenData = childrenMarkers.map(child => child.options.data);
                } else {
                    childrenData = [a.sourceTarget.options.data];
                }

                const totalInstances = childrenData.length;
                const incidentsCount = {
                    "good": 0,
                    "critical": 0,
                    "warning": 0,
                };
                let totalIncidents = 0;
                const instancesHtml = [];
                const incidentsHtml = [];
                const incidentsCountBadges = [];

                const badge = (color, text) => `
                    <span class="pa-badge pa-badge_${color} pa-p-2 pa-mb-0 pa-mr-8 pa-txt_medium">${text}</span>
                `;

                childrenData.forEach((instance) => {
                    let icon = {
                        color: 'green',
                        name: 'checkmark-solid',
                    };

                    if (instance.severity === 'critical') {
                        icon.color = 'red';
                        icon.name = instance.severity;
                    } else if (instance.severity === 'warning') {
                        icon.color = 'yellow';
                        icon.name = instance.severity;
                    }

                    instancesHtml.push(`
                        <li>
                            <a href="${instance.link}" title="${instance.name}" target="_blank" class="pa-anchor pa-txt_medium pa-flex pa-align-center pa-hover-underline">
                                <svg class="pa-icon pa-icon_${icon.color} pa-mr-4 pa-flex-shrink-0">
                                    <use xlink:href="#${icon.name}"></use>
                                </svg>
                                <span class="pa-txt_truncate">${instance.name}</span>
                            </a>
                        </li>
                    `);

                    instance.incidents.forEach((incident) => {
                        const severity = incident.severity || "good";
                        const badgeColor = severity === 'critical' ? 'red' : 'yellow';
                        const badgeText = severity === 'critical' ? 'Critical' : 'Warning';

                        incidentsCount[severity] += 1;
                        totalIncidents += 1;

                        incidentsHtml.push(`
                            <li>
                                <a href="${instance.link}" title="${incident.label}" target="_blank" class="pa-anchor pa-txt_medium pa-hover-underline">
                                    <span class="pa-txt_truncate block">${incident.label}</span>
                                </a>
                                <span class="block">${incident.description}</span>
                                <a href="${incident.link}" title="#${incident.display_id}" target="_blank" class="pa-anchor pa-txt_medium pa-hover-underline">
                                    <span class="pa-txt_truncate block">#${incident.display_id}</span>
                                </a>
                                <div>${badge(badgeColor, badgeText)}</div>
                            </li>
                        `);
                    });
                });

                Object.keys(incidentsCount).forEach((severity) => {
                    const value = incidentsCount[severity];

                    if (value > 0) {
                        if (severity === "good") {
                            incidentsCountBadges.push(
                                badge('green', `${value} Good`)
                            );
                        } else if (severity === "critical") {
                            incidentsCountBadges.push(
                                badge('red', `${value} Critical`)
                            );
                        } else if (severity === "warning") {
                            incidentsCountBadges.push(
                                badge('yellow', `${value} Warning`)
                            );
                        }
                    }
                });

                return `
                    <div class="geomap-popover" data-geopopup-id="${popupId}">
                        <div class="popover-container">
                            <div class="popover-header">
                                <p-flex align-center>
                                    <div>
                                        <span class="block pa-mb-4 pa-txt_bold pa-txt_truncate" title="${_lat}, ${_lng}">Coords: ${_lat}, ${_lng}</span>
                                        <div>${incidentsCountBadges.join('')}</div>
                                    </div>
                                </p-flex>
                            </div>
                            <div class="popover-body">
                                <details>
                                    <summary data-geopopup-el="summary">${totalInstances} Devices Total</summary>
                                    <div data-geopopup-el="list-wrapper">
                                        <ul class="unstyled" data-geopopup-el="list">${instancesHtml.join('')}</ul>
                                    </div>
                                </details>
                                <details>
                                    <summary data-geopopup-el="summary">${totalIncidents} Active Incidents</summary>
                                    <div data-geopopup-el="list-wrapper">
                                        <ul class="unstyled" data-geopopup-el="list">${incidentsHtml.join('')}</ul>
                                    </div>
                                </details>
                            </div>
                        </div>
                    </div>
                `;
            },
            onMarkerClick(a) {
                this.openPopup(a);
            },
            onMarkerClusterClick(a) {
                this.openPopup(a);
            },
            openPopup(a, options = {}) {
                const popupOptions = {
                    closeButton: false,
                    minWidth: 350,
                    className: 'pa-geomap-popup',
                    autoPanPaddingTopLeft: 0,
                    ...options,
                };

                a.sourceTarget.bindPopup(
                    this.buildClusterPopup(a),
                    popupOptions,
                ).openPopup();
            },
            createMarkerIcon({ incidentsCount = 0, severity = "" }) {
                const html = `<div><span title="${incidentsCount} incidents">${incidentsCount}</span></div>`;

                let className = 'marker-cluster';

                if (severity) {
                    className += ` marker-cluster-${severity}`;
                }

                return L.divIcon({ html, className, iconSize: L.point(40, 40) });
            },
            handleClickEvents(event) {
                if (event.target.matches('[data-geopopup-el="summary"]')) {
                    this.handlePopupDetailToggle(event);
                }
            },
            initMap() {
                if (this.hasMap) {
                    return;
                }

                const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 10,
                });
                const mapOptions = {
                    center: L.latLng(0, 0),
                    layers: [tiles],
                    attributionControl: false,
                };
                const map = L.map(this.mapId, mapOptions);
                const markers = L.markerClusterGroup({
                    spiderfyOnMaxZoom: false,
                    showCoverageOnHover: false,
                    zoomToBoundsOnClick: false,
                    iconCreateFunction: (cluster) => {
                        const markers = cluster.getAllChildMarkers();
                        let severity = '';
                        let incidentsCount = 0;

                        for (let i = 0; i < markers.length; i++) {
                            const marker = markers[i];
                            const _severity = marker.options.data.severity;
                            const _incidentsCount = marker.options.data.incidentsCount;

                            if (_severity === 'critical' && !severity) {
                                severity = _severity;
                            } else if (_severity === 'warning' && !severity) {
                                severity = _severity;
                            }

                            incidentsCount += _incidentsCount;
                        }

                        return this.createMarkerIcon({ incidentsCount, severity });
                    },
                });
                markers.on('click', this.onMarkerClick);
                markers.on('clusterclick', this.onMarkerClusterClick);

                const markerGroup = [];

                for (let i = 0; i < this.markers.length; i++) {
                    const _marker = this.markers[i];
                    const { id, lat, long, name, fqdn, incidentsCount, severity } = _marker;
                    const title = fqdn ? `${name} (${fqdn})` : name;
                    const marker = L.marker(new L.LatLng(lat, long), {
                        data: {..._marker},
                        title: title,
                        alt: title,
                        icon: this.createMarkerIcon({ incidentsCount, severity }),
                    });
                    this.markerMapping = {
                        ...this.markerMapping,
                        [id]: marker,
                    };
                    markers.addLayer(marker);
                    markerGroup.push(marker);
                }

                if (markerGroup.length > 0) {
                    const group = new L.featureGroup(markerGroup);
                    const bounds = group.getBounds();

                    map.fitBounds(bounds);
                }

                map.addLayer(markers);

                map.on('popupclose', (e) => {
                    const detailsEls = e.popup._container.querySelectorAll('details');

                    detailsEls.forEach((detailsEl) => {
                        detailsEl.removeEventListener("toggle", this.handlePopupDetailToggle);
                    });
                });

                document.addEventListener('click', this.handleClickEvents, false);
                this.map = map;
            },
        },

        beforeDestroy() {
            document.removeEventListener('click', this.handleClickEvents, false);
        },

        created() {
            setTimeout(() => {
                this.initMap();
            }, 500);
        },

        watch: {
            markers(curr, prev) {
                //markers changed rebuild the map
                this.map.remove();
                this.map = null;
                this.initMap();
            },
        },
    });
</script>
