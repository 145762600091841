<style lang="scss" >

    #header-nav-wrapper {

        #forticloud-menu {
            .pa-menu-box a,
            .pa-menu-box span,
            .pa-menu-box button {
                all: unset;
                font-family: "Lato", sans-serif;
                text-align: left;
                text-rendering: optimizeLegibility;
                font-weight: 400;
                color: #666666;
                letter-spacing: .02em;
                white-space: nowrap;

                &:hover {
                    cursor: pointer;
                }

                @media screen and (max-width: 1100px) {
                    white-space: wrap;
                }
            }
        }

        .btn-org-drop-down{
            align-items: center;
            background: transparent;
            border: none;
            display: flex;
            justify-content: space-between;
            line-height: 120%;
            min-width: 120px;
            padding-left: 0;
            padding-right: 10px;
        }

        .fn1-header {
            align-items: center;
            background-color: #fff;
            color: #666;
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            white-space: nowrap;
            margin-bottom: 0;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            border: 1px solid #e0e0e0;
            border-radius: 1px;
        }

        .fn1-logo {
            height: 36px;
            margin: 8px 128px 8px 0;
            width: 113px;
            vertical-align: middle;
            border-style: none;
        }

        .support-icon {
            width: 30px;
            height: 30px;
            vertical-align: middle;
        }

        .left-col {
            align-items: center;
            display: flex;
            flex-wrap: nowrap;
        }

        .dropdown-tooltip-group {
            display: flex;
            align-items: center;
        }

        .dropdown-tooltip {
            display: inline-block;
            position: relative;
            text-align: left;
        }

        .dropdown-tooltip .dropdown-content {
            background-color: #fff;
            color: #535353;
            display: block;
            font-size: 13px;
            font-weight: 400;
            opacity: 1;
            text-align: center;
            transition: all .2s ease 0s;
            white-space: nowrap;
            z-index: 9999999;
            border: 1px solid #ddd;
            border-radius: 4px;
            box-shadow: 0 2px 5px 1px rgba(86, 89, 90, 0.4);
            box-sizing: border-box;
            position: absolute;
        }

        .support-section {
            max-height: 770px;

            form {
                cursor: pointer;

                a:hover {
                    border-left: 0px;
                }
            }

            @media screen and (max-width: 1220px) {
               width: 230px;
               flex-wrap: wrap;
               overflow: auto;
            }

        }

        .portal-col {
            padding: 35px 30px;
            position: relative;
            text-align: left;
            vertical-align: top;
            white-space: nowrap;
            gap: 10px;
            overflow: auto;
        }

        .services-wrapper {
            width: 1160px;
            max-height: 770px;

            @media screen and (max-width: 1220px) {
               width: 230px;
               flex-wrap: wrap;
            }

        }

        .portal-col .left-col {
            display: block;
        }

        .portal-col .right-col {
            border-left: 1px dashed #dde1ea;
            margin-left: 20px;
            padding-left: 20px;
        }

        .account-container .cloud-management-container .downloads-container .resources-container .forticare-container{
            display: block;
        }

        .single-column {
            display: grid;
            grid-template-columns: repeat(1, 1fr);

            div {
                padding: 12px 12px 11px;
            }
        }

        .double-column {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        .header {
            color: #000;
            box-sizing: border-box;
            font-size: 8px;
            font-weight: 400;
            letter-spacing: .1rem;
            font-family: "Lato", sans-serif;
            text-align: left;
            text-transform: uppercase;
            text-rendering: optimizelegibility;
            white-space: nowrap;
            margin: 0 0 0 0;
            padding: 0 0 3px 0;
            position: relative;
            clear: both;
            border-bottom: 0.10625rem solid black;
            margin-bottom: 8px;
        }

        .list-control {
            display: flex;
            justify-content: end;
            color: #2764c1;
            cursor: pointer;
            font-size: smaller;
            font-weight: 600;
            align-items: center;
            span {
                cursor: pointer;
            }

            span:hover {
                text-decoration: underline;
            }
        }

        .resources-container {
            min-width: 230px;
            @media screen and (max-width: 1220px) {
               width: 100%;
            }
        }

        .forticare-container {
            min-width: 150px;
            @media screen and (max-width: 1220px) {
               width: 100%;
            }
        }

        .forticare-container-beta {
            min-width: 150px;
            @media screen and (max-width: 1220px) {
               width: 100%;
            }
        }

        .downloads-container {
            @media screen and (max-width: 1220px) {
               width: 100%;
            }
        }

        .cloud-services-container {
            width: 60%;

            @media screen and (max-width: 1220px) {
               width: 100%;

                .services-row {
                    flex-wrap: wrap;
                }
            }

            .menu-row {
                width: 33%;

                form {
                    padding: 12px 12px 11px;
                }

                @media screen and (max-width: 1220px) {
                    width: 100%;
                }

                &:hover {
                    background-color: #f5f5fa;
                    cursor: pointer;
                }
            }
        }

        .account-container {
            width: 20%;

            form {
                padding: 12px 12px 11px;
            }

            @media screen and (max-width: 1220px) {
               width: 100%;
            }
        }

        .cloud-management-container {
            width: 20%;

            form {
                padding: 12px 12px 11px;
            }

            @media screen and (max-width: 1220px) {
               width: 100%;
            }
        }


        .menu-row {
            border-bottom: 1px solid #f5f5fa;

            &:hover {
                background-color: #f5f5fa;
                cursor: pointer;

                .menu-name {
                    color: #535353 !important;
                    font-weight: bolder !important;
                }
            }
        }

        .list-item {
            align-items: center;
            border-radius: 0;
            box-shadow: none;
            color: #535353;
            display: flex;
            font-weight: 400;
            letter-spacing: .01em;
            //padding-bottom: 0;
            padding: unset;
            margin-bottom: 0;
            text-decoration: none;
            transition: all .2s;

            &:hover {
                border-left: #000 0px solid;
                cursor: pointer;
            }
        }

        .list-item-disabled {
            cursor: not-allowed;
            opacity: .5;
        }

        .list-item img {
            padding: 2px;
            border-radius: 50%;
            box-shadow: 1px 1px 1px #dee2eb;
            box-sizing: border-box;
            height: auto;
            margin-right: 10px;
            position: relative;
            vertical-align: middle;
            width: 24px;
        }

        .dropdown-tooltip .pa-menu-box {
            box-shadow: none;
        }

        .search-form {
            /* Hacks to undo autocomplete styles */
            #search:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 100px #222f45 inset;
                -webkit-text-fill-color: #fff;
            }
        }

        #org-menu-wrapper {
            .pa-menu{
                .pa-menu-box{
                    background: #fff;
                    border: 1px solid #dee2eb;
                    box-shadow: 2px 2px 4px rgb(0 0 0 / 5%), -2px -2px 4px rgb(0 0 0 / 5%);
                    min-width: 600px;
                    position: absolute;
                    right: 0;
                    top: 52px;
                    z-index: 999;
                    padding-left: 10px;
                    padding-bottom: 10px;
                }
            }
        }

        #user-menu-wrapper {
            .pa-navItem-box {
                padding-top: 3px;
            }
        }
        #ftnt-user-menu-wrapper {
            .pa-menu {
                    .pa-menu-box {
                        left: -300px !important;
                        width: 450px;
                        padding-left:15px;
                        padding-right:15px;
                        box-shadow: 0 4px 20px rgba(0,0,0,.2);

                        .left-column {
                            float: left;
                            width: 200px;
                            border-right: 1px solid #dee2eb;
                            padding-top:15px;
                            padding-bottom: 15px;
                            padding-right: 15px;
                        }

                        .right-column {
                            width: 199px;
                            float: left;
                            padding-top:15px;
                            padding-bottom: 15px;
                        }

                        .row {
                            display: flex;
                        }

                        .border-right {
                            border-right: 1px solid gainsboro;
                        }

                        .pa-icon {
                            margin-right: auto;
                        }

                    }
                }
        }

        .img-container {
            min-width: 300px;
        }
    }

</style>

<template>
    <div id="header-nav-wrapper">
        <div class="fn1-header">
            <div class="left-col">
                <div class="img-container">
                    <img v-if="!useBrandLogo && showFortinetMenu" class="fn1-logo" :src="forticloudLogo"/>
                    <img style="max-height: 35px;" v-if="!useBrandLogo && !showFortinetMenu" src="/static/assets/newux/media/logos/forticloud_logo.png"/>
                    <img style="max-height: 35px;" v-if="useBrandLogo" :src="brandLogo" />
                </div>
                <template v-if="showFortinetMenu">
                    <div id="forticloud-menu" class="dropdown-tooltip-group">
                        <div class="dropdown-tooltip">
                            <p-nav-menu text="Services" icon="fortinet-servicemenu" size="xxxl" inline-style="padding: 0px;" color="black" :close-box-on-click-item="false">
                                <div class="dropdown-content portal-row">
                                    <div v-if="portal_menu_loading" class="portal-col">
                                        <p-loading-spinner-2></p-loading-spinner-2>
                                    </div>
                                    <p-flex v-else class="services-wrapper portal-col">
                                        <div class="account-container">
                                            <h6 class="header">Assets &amp; Accounts</h6>
                                            <div class="menu-row" v-for="entry in portalMenuItems.assets_accounts">
                                                <form method="POST" :action="entry.url">
                                                    <input name="h_key" :value="getPayload()" type="hidden"/>
                                                    <a class="list-item" v-bind:class="{'list-item-disabled': entry.allowed === 0}" onclick="this.parentNode.submit()" target="_blank">
                                                        <span class="menu-name">
                                                            {{entry.display_name}}
                                                        </span>
                                                    </a>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="cloud-management-container">
                                            <h6 class="header">Cloud Management</h6>
                                            <div class="menu-row" v-for="entry in cloudManagementItems">
                                                <form method="POST" :action="entry.url">
                                                    <input name="h_key" :value="getPayload()" type="hidden"/>
                                                    <a v-bind:class="{'list-item-disabled': entry.allowed === 0}" class="list-item" onclick="this.parentNode.submit()" target="_blank">
                                                        <span class="menu-name">
                                                            {{entry.display_name}}
                                                        </span>
                                                    </a>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="cloud-services-container">
                                            <h6 class="header">Cloud Services</h6>
                                            <div class="services-row pa-flex" v-for="row in cloudServicesItems">
                                                <div class="menu-row" v-for="entry in row">
                                                    <form method="POST" :action="entry.url">
                                                        <input name="h_key" :value="getPayload()" type="hidden"/>
                                                        <a class="list-item" v-bind:class="{'list-item-disabled': entry.allowed === 0}" onclick="this.parentNode.submit()" target="_blank">
                                                            <span class="menu-name">
                                                            {{entry.display_name}}
                                                            </span>
                                                        </a>
                                                    </form>
                                                </div>
                                            </div>
                                            <div
                                                @click="listControlClick"
                                                class="list-control pa-pt-16"
                                            >
                                                {{showMore ? "Show More" : "Show Less"}}
                                                <p-icon
                                                    color="blue-500"
                                                    :icon="chevronName">
                                                </p-icon>
                                            </div>
                                        </div>

                                    </p-flex>

                                </div>
                            </p-nav-menu>
                        </div>
                        <div class="dropdown-tooltip">
                            <p-nav-menu text="Support" icon="fortinet-support" size="xxxxl" inline-style="padding: 0px;" color="black">
                                <div class="dropdown-content portal-row">
                                    <div v-if="support_menu_loading" class="portal-col">
                                        <p-loading-spinner-2></p-loading-spinner-2>
                                    </div>
                                    <div v-else class="support-section pa-flex portal-col">
                                        <div class="downloads-container" v-if="supportMenuHeaders.Downloads">
                                            <form :action="supportMenuHeaders.Downloads.url" method="POST" target="_blank">
                                                <input name="h_key" type="hidden" :value="appPayload"/>
                                                <a class="" href="#" onclick="this.parentNode.submit()">
                                                    <h6 class="header">Downloads</h6>
                                                </a>
                                            </form>
                                            <div class="single-column">
                                                <div class="menu-row" v-for="entry in supportMenuItems.downloads">
                                                    <form :action="entry.url" method="POST">
                                                        <input name="h_key" type="hidden" :value="appPayload"/>
                                                        <a class="list-item" href="#" onclick="this.parentNode.submit()">
                                                            <span class="menu-name">
                                                                {{entry.display_name}}
                                                            </span>
                                                        </a>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="resources-container" v-if="supportMenuHeaders.Resources">
                                            <form :action="supportMenuHeaders.Resources.url" method="POST" target="_blank">
                                                <input name="h_key" type="hidden" :value="appPayload"/>
                                                <a class="" href="#" onclick="this.parentNode.submit()">
                                                    <h6 class="header">Resources</h6>
                                                </a>
                                            </form>
                                            <div class="single-column">
                                                <div  class="menu-row" v-for="entry in supportMenuItems.resources">
                                                    <form :action="entry.url" method="POST">
                                                        <input name="h_key" type="hidden" :value="appPayload"/>
                                                        <a class="list-item" href="#" onclick="this.parentNode.submit()">
                                                            <span class="menu-name">
                                                            {{entry.display_name}}
                                                            </span>
                                                        </a>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="forticare-container" >
                                            <div v-if="supportMenuHeaders['FortiCare']">
                                                <form :action="supportMenuHeaders['FortiCare'].url" method="POST" target="_blank">
                                                    <input name="h_key" type="hidden" :value="appPayload"/>
                                                    <a class="" href="#" onclick="this.parentNode.submit()">
                                                        <h6 class="header">FortiCare</h6>
                                                    </a>
                                                </form>
                                                <div class="single-column">
                                                    <div class="menu-row" v-for="entry in supportMenuItems.forticare">
                                                        <form :action="entry.url" method="POST">
                                                            <input name="h_key" type="hidden" :value="appPayload"/>
                                                            <a class="list-item" href="#" onclick="this.parentNode.submit()">
                                                                <span class="menu-name">
                                                                    {{ entry.display_name }}
                                                                </span>
                                                            </a>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="forticare-container-beta" v-if="supportMenuHeaders['FortiCare Legacy']">
                                            <form :action="supportMenuHeaders['FortiCare Legacy'].url" method="POST" target="_blank">
                                                <input name="h_key" type="hidden" :value="appPayload"/>
                                                <a class="" href="#" onclick="this.parentNode.submit()">
                                                    <h6 class="header">FortiCare Legacy</h6>
                                                </a>
                                            </form>
                                            <div class="single-column">
                                                <div class="menu-row" v-for="entry in supportMenuItems.forticare_legacy">
                                                    <form :action="entry.url" method="POST">
                                                        <input name="h_key" type="hidden" :value="appPayload"/>
                                                        <a class="list-item" href="#" onclick="this.parentNode.submit()">
                                                            <span class="menu-name">
                                                                {{ entry.display_name }}
                                                            </span>
                                                        </a>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p-nav-menu>
                        </div>
                    </div>
                </template>
            </div>
            <div class="pa-pr-32">
                <ul class="pa-split">
                    <li class="pa-pr-16">
                        <div v-if="showSearch">
                            <form
                                :action="searchAction"
                                class="pa-input pa-input_phony pa-input_xxs search-form">
                                <p-input
                                    placeholder="Search Instances"
                                    style="height: 32px;"
                                    theme="search"
                                    v-model="searchTerm"
                                    name="q"
                                >
                                </p-input>
                            </form>
                        </div>
                    </li>
                    <li v-if="showFortinetMenu && regions_menu_loading">
                        <p-loading-spinner-2></p-loading-spinner-2>
                    </li>
                    <li v-if="showFortinetMenu && !regions_menu_loading" id="'user-region-wrapper">
                        <p-nav-menu
                            inline-style="padding: 0px;"
                            color="black"
                        >
                            <div slot="text">
                                <template v-if="this.currentRegion">
                                    Region: {{ this.currentRegion }}
                                    <br />
                                </template>
                            </div>
                            <button v-for="region in userRegions" class="pa-btn_sm" @click="switchRegion(region.cp_url)">{{region.name}}</button>
                            <button v-if="canManageRegions === true" class="pa-btn_sm" @click="listRegions()">Manage</button>
                        </p-nav-menu>
                    </li>
                    <li v-if="showFortinetMenu && orgs_menu_loading"><p-loading-spinner-2></p-loading-spinner-2></li>
                    <li v-if="showFortinetMenu && this.fcOrganizations.length > 0 && !orgs_menu_loading" id="org-menu-wrapper" class="pa-pl-16">
                        <p-nav-menu :text="ftntRootOrgName" inline-style="padding: 0px;" color="black">
                            <p-action :model="compute_org_tree">
                            </p-action>
                        </p-nav-menu>
                    </li>
                    <li v-if="showFortinetMenu && this.fcOrganizations.length > 0" id="ftnt-user-menu-wrapper" class="pa-pl-16">
                        <p-nav-menu :text="emailAddress" inline-style="padding: 0px;" color="black">
                            <div class="row">
                                <div class="left-column">
                                    <p-action
                                        v-for="item in ftntUserItems" :key="item.id"
                                        :model="item"
                                        :html-class="item.class">
                                        {{ item.text }}
                                        <span v-if="item.id == 'menu.avatar.product_updates' && new_product_updates" class="pa-badge pa-badge_info pa-badge_nav"
                                            style="position: absolute; width: 1px; height: 5px; bottom: 11px; right: 68px; z-index: 30;background:#3b78e7;" transition="expand">
                                        </span>
                                    </p-action>
                                </div>
                                <div class="right-column">
                                    <p-action
                                        v-for="item in userItems" :key="item.id"
                                        :model="item"
                                        :html-class="item.class">
                                        {{ item.text }}
                                        <span v-if="item.id == 'menu.avatar.product_updates' && new_product_updates" class="pa-badge pa-badge_info pa-badge_nav"
                                            style="position: absolute; width: 1px; height: 5px; bottom: 11px; right: 68px; z-index: 30;background:#3b78e7;" transition="expand">
                                        </span>
                                    </p-action>
                                </div>
                            </div>
                        </p-nav-menu>
                    </li>
                    <li v-else id="user-menu-wrapper">
                        <p-nav-menu
                            inline-style="padding: 0px;"
                            color="black"
                        >
                            <div slot="text">
                                <template v-if="hasSwitchedUser">
                                    Switched to: {{ userDisplayName }}
                                    <br />
                                </template>
                                {{ emailAddress }}
                            </div>
                            <p-action
                                v-for="item in userItems"
                                :key="item.id"
                                :model="item"
                                :html-class="item.class"
                            >
                                {{ item.text }}
                            </p-action>
                            <p-action :model="loggedInAsModel">
                                Logged in as:<br />{{ originalUserEmail }}
                            </p-action>
                        </p-nav-menu>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
const axios = require('axios');

const HeaderNav = {

    data() {
        return {
            searchTerm: null,
            base_update_delay: 30,
            new_product_updates: 0,
            product_update_delay: 600,
            base_product_update_delay: 600,
            showMore: true,
            // All of these are properties of the forticloud menu.
            portalMenuItems: {},
            supportMenuItems: {},
            supportMenuHeaders: {},
            currentRegion: '',
            userRegions: [],
            canManageRegions: false,
            ftntUserItems: {},
            fcOrganizations: [],
            // Loading controls
            portal_menu_loading: true,
            support_menu_loading: true,
            regions_menu_loading: true,
            orgs_menu_loading: true
        }
    },

    props: {

        accountId: {
            type: String,
            default: "",
        },

        userId: {
            type: Number,
            default: 0,
        },

        appPayload: {
          type: String,
          default: "",
        },

        logo: {
             type: String,
            default: '',
        },

        logoId: {
             type: String,
            default: '',
        },

        brandLogo: {
            type: String,
            default: '',
        },

        useBrandLogo: {
            type: Boolean,
            default: false,
        },

        search: {
            type: Object,
            default: () => {},
        },

        showSearch: {
            type: Boolean,
            default: true,
        },

        showFortinetMenu: {
            type: Boolean,
            default: false,
        },

        userItems: {
            type: Array,
            default: () => [],
        },

        emailAddress: {
            type: String,
        },

        originalUserEmail: {
            type: String,
            default: null,
        },

        userDisplayName: {
            type: String,
            default: null,
        },

        hasSwitchedUser: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        loggedInAsModel() {
            const text = `Logged in as: ${this.originalUserEmail}`;

            return {
                "id": "menu.profile.switch_user",
                "text": text,
                "action": "span",
                "title": text,
            };
        },

        chevronName() {
            return this.showMore ? "chevron-down" : "chevron-up";
        },
        compute_org_tree() {
            let tree_model = new Object();
            let tree = this.org_tree(this.fcOrganizations)
            tree_model.tree = tree;
            tree_model.action = 'org_dd';
            return tree_model;
        },
        ftntRootOrgName() {
            return this.fcOrganizations[0]['name']
        },

        forticloudLogo() {
            return 'data:image/svg+xml; base64, ' + this.logo;
        },

        searchAction () {
                return `${this.search.action}?q=${this.searchTerm}`;
        },
        cloudManagementItems() {
            if (Object.keys(this.portalMenuItems).length === 0) {
                return []
            }
            if (this.portalMenuItems.cloud_management === undefined) {
                return []
            }
            if (this.showMore) {
                return this.portalMenuItems.cloud_management.slice(0, 5);
            } else {
                return this.portalMenuItems.cloud_management;
            }
        },
        ftntLeftUserItems() {
            let left_items = []
            for (var i = 0; i < this.ftntUserItems.length; i++) {
                if(this.ftntUserItems[i]['alignment'] == 'left'){
                    left_items.push(this.ftntUserItems[i]);
                }
            }
            return left_items
        },

        ftntRightUserItems() {
            let right_items = []
            for (var i = 0; i < this.ftntUserItems.length; i++) {
                if(this.ftntUserItems[i]['alignment'] == 'right'){
                        right_items.push(this.ftntUserItems[i]);
                    }
            }
            return right_items
        },


        cloudServicesItems() {
            if (Object.keys(this.portalMenuItems).length === 0) {
                return []
            }
            if (this.portalMenuItems.cloud_services.length === 0) {
                return [];
            }

            let list = []
            if (this.showMore) {
                list = this.portalMenuItems.cloud_services.slice(0, 9);
            } else {
                list = this.portalMenuItems.cloud_services;
            }

            let triples = [];
            const rows  = []
            for(const i in list) {
                triples.push(list[i]);
                if((i + 1) % 3 == 0) {
                    rows.push(triples);
                    triples = [];
                }
            }

            rows.push(triples);

            return rows;
        },
    },

    methods: {

        switchRegion(region_url) {
            axios.get("/config/getRegionUrl?region_url=" + region_url).then(response => {
                if (response.data.success === true) {
                    window.location = response.data.url;
                } else {
                    console.log(response.msg);
                }

            })
        },

        listRegions() {
            axios.get("/config/getRegionManagerUrl").then(response => {
              if (response.data.success === true) {
                window.location = response.data.url;
              } else {
                console.log(response.msg);
              }
            })

        },

        listControlClick() {
            this.showMore = !this.showMore;
        },

        org_tree(arr) {
            let arrMap = new Map(arr.map(item => [item.nodeId, item]));
            let tree = [];

            for (let i = 0; i < arr.length; i++) {
                let item = arr[i];

                if (item.parentId) {
                    let parentItem = arrMap.get(item.parentId);

                    if (parentItem) {
                        let { children } = parentItem;

                        if (children) {
                            parentItem.children.push(item);
                        } else {
                            parentItem.children = [item];
                        }
                    }
                } else {
                    tree.push(item);
                }
            }
            return tree;
        },

        getImage(data) {
            return 'data:image/svg+xml; base64, ' + data;
        },

        getPayload() {
            const payload = {
                source_app: 'FortiMonitor',
                account_id: this.accountId,
                user_fullaccess: true,
            };
            if (this.userId) {
                payload.user_id = this.userId;
            }
            return JSON.stringify(payload);
        },

        updateProductUpdates() {
            $.ajax({url: "/util/new_product_updates", context: this})
                .done(function(data) {

                    // Update badges in the header
                    this.new_product_updates = data.new_product_updates;

                    // Successful response, so reset the update delay
                    this.product_update_delay = this.base_product_update_delay;
                })
                .fail(function() {

                    // Update call failed for some reason, scale back the delay to avoid DOSing
                    this.product_update_delay = this.product_update_delay * 1.5;

                })
                .always(function() {

                    // Schedule the next call
                    setTimeout(this.updateProductUpdates, this.product_update_delay*1000);

                });
        },

        getFortiCloudPortalMenuItems() {
            $.ajax({
                url: "/util/get_forticloud_portal_menu_items", context: this
            }).done(function(resp) {
                if (resp.success === true) {
                    this.$set(this, "portalMenuItems", resp.portal_menu_items);
                    this.portal_menu_loading = false;
                }
            })
        },

        getFortiCloudSupportMenuItems() {
            $.ajax({
                url: "/util/get_forticloud_support_menu_items", context: this
            }).done(function(resp) {
                if (resp.success === true) {
                    this.$set(this, "supportMenuItems", resp.support_menu_items);
                    this.$set(this, "supportMenuHeaders", resp.support_menu_headers);
                    this.support_menu_loading = false;
                }
            })
        },

        getRegionsData() {
            $.ajax({
                url: "/util/get_regions_information", context: this
            }).done(function(resp) {
                this.currentRegion = resp.current_region;
                this.$set(this, 'userRegions', resp.user_regions);
                this.canManageRegions = resp.can_manage_regions;
                this.regions_menu_loading = false;
            })
        },

        getFortiCloudOrgItems() {
            $.ajax({
                url: "/util/get_forticloud_org_items", context: this
            }).done(function(resp) {
                if (resp.success === true) {
                    this.$set(this, "ftntUserItems", resp.user_menu);
                    this.$set(this, "fcOrganizations", resp.org_menu);
                    this.orgs_menu_loading = false;
                }
            })
        },

    },

    vueReady() {
        if (this.showFortinetMenu === true) {
            this.getFortiCloudPortalMenuItems();
            this.getFortiCloudSupportMenuItems();
            this.getFortiCloudOrgItems();
            this.getRegionsData();
        }
    },

    mounted() {
        this.updateProductUpdates();
    },
};


export default HeaderNav;
</script>
