<style lang="scss">
.widget-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
</style>

<template>
    <div
        :id="id"
        :class="!inModal ? 'grid-stack-item' : 'modal-view'"
        :data-gs-x="config.col.toString()"
        :data-gs-y="config.row.toString()"
        :data-gs-width="config.width.toString()"
        :data-gs-height="config.height.toString()"
        :data-gs-auto-position="false"
        :data-gs-locked="true"
    >
        <div
            :class="!inModal ? 'grid-stack-item-content' : 'modal-view-content'"
            style="overflow:hidden;"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div class="widget-wrapper" :style="widgetWrapperStyle">
                <div
                    :class="{'widget-header': true, 'edit-mode': showWidgetControls, 'expand-mode' : !editing && isHovered && allowedExpand && !inModal, 'in-modal': inModal}"
                >
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_9of12 pa-align-center" style="flex-direction: row;">
                            <span v-if="editing">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                            </span>
                            <svg v-show="loading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: textColor ? textColor : '#000'}">
                                <use xlink:href="#spinner-spin-naked"></use>
                            </svg>  
                            <p-tooltip2 :normal-white-space="true"  placement="bottom">                     
                                <span v-if="!isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: textColor }">
                                    <span v-if="config.title" >{{ config.title }}</span>
                                </span>
                                <span v-if="isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate">
                                    <span v-if="config.title" >{{ config.title }}</span>
                                </span>
                                <span> {{ config.title }} </span>
                            </p-tooltip2>     
                            <slot name="widget-header-action"></slot>
                        </div>
                        <div class="pa-grid-col pa-grid-col_3of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger"  v-show="!$parent.editing && allowedExpand && isHovered && !inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#arrow-expand"></use>
                                    </svg>
                                </button>
                                <span> Expand </span>
                            </p-tooltip2>
                            <button slot="trigger"  v-show="!$parent.editing && inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                <svg class="pa-icon remove-icon">
                                    <use xlink:href="#close"></use>
                                </svg>
                            </button>
                            <p-tooltip2 placement="bottom">
                                <button
                                    slot="trigger"
                                    @click="deleteSelf"
                                    v-show="showWidgetControls && !inModal"
                                    type="button"
                                    class="pa-btn pa-btn--unstyled pa-btn_narrow"
                                >
                                    <svg class="pa-icon remove-icon" >
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2 placement="bottom">
                                <button
                                    @click="configure"
                                    slot="trigger"
                                    v-show="showWidgetControls && !inModal"
                                    type="button"
                                    class="pa-btn pa-btn--unstyled pa-btn_narrow"
                                >
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>
                            <p-tooltip2 :normal-white-space="true" placement="bottom">
                                <button 
                                    v-show="showWidgetControls && !inModal" 
                                    type="button"
                                    slot="trigger"
                                    @click="cloneWidget" 
                                    class="pa-btn pa-btn--unstyled pa-btn_narrow pa-mr-6 pa-mt-6"
                                >
                                    <p-icon icon="copy"></p-icon>
                                </button>
                                <span> Clone this Widget </span>
                            </p-tooltip2>
                        </div>
                    </div>
                </div>
                <slot name="body"></slot>
                <div
                    v-if="needsConfig"
                    class="widget-body"
                    style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center;"
                    :style="{'height': inModal && '85vh'}"
                    ref="temp_body"
                >
                    <div :class="inModal ? 'in-modal-centered' : 'vertical-center'">
                        <button
                            @click="configure"
                            class="pa-btn pa-btn_naked_light_grey_no_fill pa-btn_lg"
                            style="padding: 12px 35px"
                            type="button"
                        >
                            <svg class="pa-icon">
                                <use xlink:href="#plus-circle-outline"></use>
                            </svg>
                            <span>Configure</span>
                        </button>
                        <p :class="inModal ? 'in-modal-centered widget-unconfigured' : 'centered widget-unconfigured'">
                            <slot
                                name="unconfigured-message"
                            ></slot>
                        </p>
                    </div>
                </div>
                <div 
                    v-if="!valid"
                    class="widget-body widget-body_error"
                    :title="errorMessage"
                    :style="{'height': inModal && '85vh'}"
                >
                    <p class="pa-message pa-message_error">
                        <svg class="pa-icon pa-icon_xl"><use xlink:href="#alert-circle"></use></svg>
                        {{ errorMessage }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Vue from 'vue';
    import getContrast from '../../shared/utils/getContrast';

    export default Vue.extend({

        data() {
            return {
                isHovered: false,
                inModal: false,
            };
        },

        events: {
        },

        props: {
            id: {
                type: Number,
                'default': 0,
            },

            editing: {
                type: Boolean,
                'default': false,
            },

            config: {
                type: Object,
                'default': () => {},
            },
            
            content: {
                type: Object,
                'default': () => {},
            },

            loading: {
                type: Boolean,
                'default': false,
            },

            updating: {
                type: Boolean,
                'default': false,
            },

            configurationModules: {
                type: Array,
                'default': () => [],
            },

            optionModules: {
                type: Array,
                'default': () => [],
            },

            valid: {
                type: Boolean,
                'default': true,
            },

            errorMessage: {
                type: String,
                'default': null,
            },

            widgetWrapperStyle: {
                type: [String, Object, Array],
                default: null,
            },
            allowedExpand: {
                type: Boolean,
                default: false,
            },

            backgroundColor: String,
            textColor: String,
        },

        computed: {
            needsConfig: function() {
                if (this.loading) {
                    return false;
                }
                if (!this.content) {
                    return true;
                }
                if (typeof this.content === 'undefined' || typeof this.content.success === 'undefined') {
                    return false;
                }
                this.accessError = this.content.access_error || false;
                return !this.accessError && !this.content.success;
            },

            showWidgetControls() {
                return this.isHovered && this.editing;
            },

            textColor: function() {
                return (this.isHovered && this.$parent.editing) ? 'white' : getContrast(this.backgroundColor);
            },
        },

        methods: {
            expandSelf() {
                this.inModal = !this.inModal

                this.inModal ? this.eventHub.$emit('dashboardmodal:open', this.id, this.needsConfig) : this.eventHub.$emit('dashboardmodal:close')
            },

            cloneWidget() {
                const config = {...this.config};
                const parent = {...this.$parent.$parent};
                const widgetId = this.id;

                window.app.rootVue.$broadcast(
                    'widget:clone_widget',
                    { widgetId, parent, config }
                );
            },

            handleContentHover(isHovered) {
                this.isHovered = isHovered;
            },

            getConfigModules() {
                return this.configurationModules;
            },

            getOptionModules() {
                return this.optionModules;
            },

            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this.$parent);
            },

            deleteSelf() {
                window.app.rootVue.$broadcast('prompt:load', {
                    id: 'dynamic_prompt',
                    title: 'Delete widget?',
                    body: 'Are you sure you want to delete this widget?',
                    callback: `window.app.rootVue.$broadcast('dashboard:confirm_delete', ${this.$parent.id});`,
                });
            },
        },

        watch: {},

        mounted() {},
    });
</script>
