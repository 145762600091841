import G6 from "@antv/g6";

export const fitLabel = (str, maxWidth, fontSize) => {
    const ellipsis = '...';
    const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0];

    let currentWidth = 0;
    let label = str;

    str.split('').forEach((letter, i) => {
        if (currentWidth > maxWidth - ellipsisLength) {
            return;
        }

        currentWidth += G6.Util.getLetterWidth(letter, fontSize);

        if (currentWidth > maxWidth - ellipsisLength) {
            label = `${str.substr(0, i)}${ellipsis}`;
        }
    });

    return label;
};

export const doubleFingerDragCanvas = (graph) => {
    return {
        getEvents: function getEvents() {
            return {
                wheel: 'onWheel',
            };
        },
        onWheel: function onWheel(ev) {
            if (ev.ctrlKey) {
                const canvas = graph.get('canvas');
                const point = canvas.getPointByClient(ev.clientX, ev.clientY);
                let ratio = graph.getZoom();
                if (ev.wheelDelta > 0) {
                    ratio = ratio + ratio * 0.05;
                } else {
                    ratio = ratio - ratio * 0.05;
                }
                graph.zoomTo(ratio, {
                    x: point.x,
                    y: point.y,
                });
            } else {
                const x = ev.deltaX || ev.movementX;
                let y = ev.deltaY || ev.movementY;
                if (!y && navigator.userAgent.indexOf('Firefox') > -1) y = (-ev.wheelDelta * 125) / 3
                graph.translate(-x, -y);
            }
            ev.preventDefault();
        },
    };
};
