<template>
    <ul class="tag-list pa-hList pa-mt-0">
        <slot></slot>
        <li v-if="!tags || tags.length === 0" class="pa-txt_13 pa-mt-0">
            None
        </li>
        <li :key="tag" v-for="tag in filteredTags" class="pa-mt-0">
            <p-tag
                :tag="tag"
                :variant="variant"
                :on-remove="onRemove"
                :can-remove="canRemove"
            >
            </p-tag>
        </li>
    </ul>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        // An array of tag names
        tags: Array,
        maxTags: Number,
        variant: {
            type: String,
            default: '',
        },
        onRemove: Function,
        canRemove: Boolean,
    },
    computed: {
        filteredTags() {
            if (this.maxTags) {
                return this.tags.slice(0, this.maxTags);
            } else {
                return this.tags;
            }
        },
    },
});
</script>
