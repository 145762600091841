// Source: https://stackoverflow.com/a/54470906
// Find all results of key that match predicate
export const deepSearchItems = (object, key, predicate) => {
    let ret = [];

    if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) {
        ret = [...ret, object];
    }

    if (Object.keys(object).length) {
        for (let i = 0; i < Object.keys(object).length; i++) {
            let value = object[Object.keys(object)[i]];

            if (typeof value === "object" && value != null) {
                let o = deepSearchItems(
                    object[Object.keys(object)[i]],
                    key,
                    predicate
                );

                if (o != null && o instanceof Array) {
                    ret = [...ret, ...o];
                }
            }
        }
    }

    return ret;
}
