var Highcharts = require('highcharts');
const configs = {
    sparkline: {
        chart: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            height: 30,
            reflow: false,
            spacing: [0, 0, 0, 0],
            type: 'area',
            zoomType: null,
        },
        legend: {
            enabled: false,
            labelFormatter: () => {},
            symbolHeight: 0,
            symbolWidth: 0,
        },
        plotOptions: {
            series: {
                color: '#4e92df',
            },
            area: {
                lineWidth: 1.5,
                shadow: false,
                fillOpacity: 0.1,
            },
        },
        tooltip: {
            enabled: false,
        },
        xAxis: {
            title: {
                enabled: false,
            },
            labels: {
                enabled: false,
            },
            lineWidth: 0,
            plotLines: [],
            plotBands: null,
        },
        yAxis: {
            gridLineWidth: 0,
            title: {
                enabled: false,
                style: {
                    color: 'rgba(32, 32, 32, 0.6)',
                    fontSize: '10px',
                },
                y: -5,
            },
            labels: {
                enabled: false,
            },
            // lineWidth: 0,
            minorGridLineWidth: 0,
            // plotLines: null,
        },
        noData: {
            style: {
                color: '#2c3438',
                fontSize: '13px',
                fontWeight: '400',
            },
        },
    },


    sparkStackedArea: {
        colors: [
            "#A2ACBD","#133F66","#4FAFF3","#BAAEA4","#4C4245"
        ],
        chart: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            height: 30,
            reflow: false,
            spacing: [0, 0, 0, 0],
            type: 'area',
            zoomType: null,
        },
        legend: {
            enabled: false,
            labelFormatter: () => {},
            symbolHeight: 0,
            symbolWidth: 0,
        },
        plotOptions: {
            series: {
            },
            area: {
                stacking: 'normal',
            },
        },
        tooltip: {
            enabled: false,
        },
        xAxis: {
            title: {
                enabled: false,
            },
            labels: {
                enabled: false,
            },
            lineWidth: 0,
            plotLines: [],
            plotBands: null,
        },
        yAxis: {
            gridLineWidth: 0,
            title: {
                enabled: false,
                style: {
                    color: 'rgba(32, 32, 32, 0.6)',
                    fontSize: '10px',
                },
                y: -5,
            },
            labels: {
                enabled: false,
            },
            // lineWidth: 0,
            minorGridLineWidth: 0,
            // plotLines: null,
        },
        noData: {
            style: {
                color: '#2c3438',
                fontSize: '13px',
                fontWeight: '400',
            },
        },
    },

    stackedArea: {
        colors: [
            "#A2ACBD","#133F66","#4FAFF3","#BAAEA4","#4C4245"
        ],
        chart: {
            zoomType: null,
            type: 'area'
        },

        plotOptions: {
            area: {
                stacking: 'normal',
            }
        },
        legend: {
            enabled: false,
            labelFormatter: () => {},
            symbolHeight: 0,
            symbolWidth: 0,
        },
        tooltip: {
            shared: false,
            crosshairs: true,
            formatter: function() {
                let x = this.x
                let htmlParts = []
                let total = 0
                for(const i in this.point.series.chart.series) {
                    Highcharts.each(this.point.series.chart.series[i].points, function(point) {
                        if (point.x === x) {
                            const hasDec = (point.y % 1) !== 0;
                            let y = point.y;
                            if (hasDec) {
                                y = y.toFixed(2);
                            }

                            if(y === null) {
                                y = 0;
                            }

                            htmlParts.push(`<p class='pa-txt_medium' style='color: #3c3d3e; margin-bottom: 4px;'>
                                <span style='font-size: 12px; color: ${point.series.color}; line-height: 11px;'>
                                    \u25CF
                                </span>
                                ${point.series.name.replace("\n", " ")}: ${y}
                            </p>`);

                           total += parseFloat(y);
                        }
                    });
                }
                total = total.toFixed(2);
                htmlParts = htmlParts.join("")
                let temp= `
                    <div class='pa-flex'>
                        <div style='padding-left: 6px; padding-right: 20px;'>
                            ${htmlParts}
                            <span class="pa-txt_bold pa-pl-12">Total: ${total}</span>
                            <p class='pa-txt_normal pa-pl-12' style='color: #95999b; font-size: 11px;'>
                                ${Highcharts.dateFormat("%Y-%m-%d %H:%M", new Date(this.x))}
                            </p>
                        </div>
                    </div>`;
                
                return temp
            },
        },
    },


    
};

export default configs;
