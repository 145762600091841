import Vue from 'vue';

const ENDPOINT = '/report/get_metric_values';

export default Vue.extend({
    render() {
        return [];
    },

    data() {
        return {
            iMetrics: [],
            lastTimestamp: Math.floor(Number(new Date()) / 1000),
            fetchInterval: 60,
            interval: null,
        };
    },

    methods: {
        fetch() {
            if (!this.iMetrics.length) {
                return;
            }
            $.ajax({
                url: '/report/get_metric_values',
                data: {
                    metric_ids: this.iMetrics.join(','),
                    last_timestamp: this.lastTimestamp,
                },
            }).done(data => {
                if (data.success) {
                    this.lastTimestamp = data.timestamp;
                    this.emit(data.values);
                } else {
                    console.error(data);
                }
            });
        },

        emit(values) {
            for (const metric in values) {
                if (!values.hasOwnProperty(metric)) {
                    continue;
                }
                const metricId = Number(metric);
                this.$root.eventHub.$emit('metric-values:new', metricId, values[metric]);
            }
        },

        registerMetric(metric) {
            if (!this.iMetrics.includes(metric)) {
                this.iMetrics.push(metric);
            }
        },

        unregisterMetric(metric) {
            const index = this.iMetrics.indexOf(metric);
            if (index > -1) {
                this.iMetrics.splice(index, 1);
            }
        },
    },

    created() {
        this.interval = window.setInterval(this.fetch, this.fetchInterval * 1000);
    },
});
