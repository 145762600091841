<script setup>
import { ref, watch, onMounted } from 'vue';
import {
    getShowFmAgent,
} from "../../api/services/ConfigService";

const props = defineProps({
    linkText:{
        type: String,
        required: true
    },

    instanceId:{
        type: String|Number,
        required: true
    },

    showCancel:{
        type: Boolean,
        required: false,
        default: true
    }
});

const addAgentOptions = ref([
    {label: "Linux", value: "linux"},
    {label: "Windows", value: "windows"},
    {label: "Unix", value: "unix"}
]);

const addAgentSelection = ref();

const showFmAgent = ref();

watch(addAgentSelection, (newVal, oldVal) => {
    let linuxUrl = null;
    let winUrl = null;

    if(showFmAgent.value) {
        linuxUrl = `/onboarding/v2/linux-fm?instance_id=${props.instanceId}`;
    } else {
        linuxUrl =  `/onboarding/v2/linux-panopta?instance_id=${props.instanceId}`;
    }
    if(showFmAgent.value) {
        winUrl = `/onboarding/v2/windows-server-fm?instance_id=${props.instanceId}`;
    } else {
        winUrl = `/onboarding/v2/windows-server-panopta?instance_id=${props.instanceId}`;
    }

    if(props.showCancel === false) {
        linuxUrl = `${linuxUrl}&return_to_idp=false`;
        winUrl = `${winUrl}&return_to_idp=false`;
    }


    if(newVal ==  'windows') {
        window.location = winUrl;
    }
    if(newVal ==  'linux' || newVal == 'unix') {
        window.location = linuxUrl;
    }
});


onMounted(() => {
    (async () => {
        try {
            const response = await getShowFmAgent();
            showFmAgent.value = response.data.show_fm_agent
        } catch (error) {
            showFmAgent.value = true;
        }
    })();
})

</script>

<template>
    <div>
        <p-select
            html-class="pa-link add-agent-button"
            style="background: none; padding: 0px"
            :model.sync="addAgentSelection"
            :options="addAgentOptions"
            :show-icon="false"
            :hide-button-titles="true"
            :button-class="{
                'pa-filter-button pa-btn_naked pa-btn_naked': true
            }"
        >

            <div slot="display-text" class="">
                <span class="pa-txt_medium pa-txt_xs pa-txt_blue-500">
                    {{ linkText }}
                </span>
            </div>
        </p-select>
    </div>
</template>



<style lang="scss">
    .add-agent-button {
        .pa-select-hd {
            padding: 0px;
        }
        height: 1rem;
    }

</style>