<style lang="scss">
    .cloud-discovery-instances-table {
        tr {
            th {
                vertical-align: bottom;
                
                &:first-child {
                    padding-left: 4px;
                }
            }

            td {
                font-size: 14px !important;
                vertical-align: middle;
            }
        }
    }
</style>

<template>
    <p-drawer ref="drawer" side="right" id="cloud-discovery-server-drawer" :width="700" sticky-footer>
        <div slot="trigger" style="display: none;"></div>
        <p-flex slot="head" align-center>
            <p-icon
                v-if="cloudService.logo"
                :icon="cloudService.logo"
                class="pa-pr-8"
                size="xxxl"
            >
            </p-icon>
            <h3 class="pa-hdg pa-hdg_3 pa-txt_medium pa-txt_truncate pa-pr-24">
                {{ cloudService.name }}
            </h3>
        </p-flex>
        <div slot="body" class="pa-txt_13 pa-px-24 pa-py-16" style="height: calc(100% - 87px); overflow-y: auto;">
            <p-loading-spinner v-if="loading">
            </p-loading-spinner>
            <table v-else class="cloud-discovery-instances-table pa-table">
                <thead>
                    <tr>
                        <th>Instance</th>
                    </tr>
                </thead>
                <tbody>
                    <tr :key="server.id" v-for="server in mappedServers">
                        <td>
                            <a :href="server.url">
                                {{ server.name }}
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </p-drawer>
</template>

<script>
import Vue from "vue";

const DRAWER_ID = "cloud-discovery-server-drawer";
const LOCKED_BODY_CLASS = "server-report-locked";

export default Vue.extend({
    events: {
        [DRAWER_ID + ":open"]: function(data) {
            // Reset form before opening to erase any unsaved changes
            // or to set new data from saved changes
            this.getDrawerData(data);
            this.$refs.drawer.open();
        },
        [DRAWER_ID + ":close"]: function() {
            this.$refs.drawer.close();
        },
        'drawer-open': function(drawerId, data) {
            if (drawerId !== DRAWER_ID) {
                return;
            }


            if (document.body.scrollHeight > window.innerHeight) {
                document.documentElement.classList.add(LOCKED_BODY_CLASS);
            }

            if (data && data.serverId) {
                this.getDrawerData(data);
            }

            this.isOpen = true;
        },
        'drawer-close': function(drawerId) {
            if (drawerId !== DRAWER_ID) {
                return;
            }

            if (document.documentElement.classList.contains(LOCKED_BODY_CLASS)) {
                document.documentElement.classList.remove(LOCKED_BODY_CLASS);
            }

            this.isOpen = false;
        },
    },
    data() {
        return {
            submitting: false,
            loading: true,
            isOpen: false,
            servers: [],
            cloudService: {},
        };
    },
    computed: {
        mappedServers() {
            return this.servers.map((server) => ({
                ...server,
                url: `/report/InstanceDetails?server_id=${server.id}`,
            }));
        },
    },
    methods: {
        initData(data) {
            this.servers = [...data.servers];
            this.loading = false;
        },
        getDrawerData(data) {
            this.cloudService = {...data.cloudService};

            $.ajax({
                url: `/cloud/getCloudDiscoveryServers?discovery_id=${data.discovery_id}&cloud_service_id=${data.cloudService.id}`,
                type: "GET",
                context: this,
            }).done((response) => {
                this.initData(response.data);
            });
        },
    },
    created() {
        window.addEventListener("keydown", event => {
            if (event.key === 'Escape' && this.$refs.drawer.isOpen) {
                this.close();
            }
        });
    },
});
</script>
