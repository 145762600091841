
function getViewportHeight() {
    return (window.innerHeight || document.documentElement.clientHeight);
}

function getViewportWidth() {
    return (window.innerWidth || document.documentElement.clientWidth);
}

export default {
    getViewportHeight,
    getViewportWidth
};