<template>
    <component
        :is="component"
        :class="classes"
        @click="onClick"
        :disabled="disabled"
        :href="href"
        :type="type"
        :to="to"
    >
        <slot />
    </component>
</template>

<script>
import Vue from "vue";

const BUTTON = "button";
const LINK = "a";
const ROUTER_LINK = "router-link";

export default Vue.extend({
    inject: {
        tabs: {
            default: () => ({})
        },
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        // For hash or url changes
        href: {
            type: String,
            default: null,
        },
        // For VueRouter
        to: {
            type: String,
            default: null,
        },
    },
    computed: {
        classes() {
            return [
                'pa-tab',
                this.selected ? 'isActive' : '',
                this.disabled ? 'disabled' : ''
            ];
        },
        type() {
            return this.component === BUTTON ? BUTTON : null;
        },
        component() {
            if (this.href) {
                return LINK;
            }

            if (this.to) {
                return ROUTER_LINK;
            }

            return BUTTON;
        },
        selected() {
            return this.localIndex === this.tabs.actualSelectedIndex; 
        },
        localIndex() {
            return this.tabs.domTabs.findIndex((el) => el === this.$el);
        },
    },
    methods: {
        checkUrl() {
            if (
                this.href &&
                window.location.href.includes(this.href)
            ) {
                this.tabs.setSelectedIndex(this.localIndex);
            }

            if (
                this.to &&
                this.$route.fullPath.includes(this.to)
            ) {
                this.tabs.setSelectedIndex(this.localIndex);
            }
        },
        registerTab() {
            this.tabs.registerTab(this);
        },
        unregisterTab() {
            this.tabs.unregisterTab(this);
        },
        onClick(e) {
            if (this.disabled) {
                e.preventDefault();
                return false;
            }

            if (this.href && this.href.startsWith("#")) {
                e.preventDefault();

                // If we have an anchor tag with just a hash, clicking that
                // anchor tag will update the url, but wont trigger the onHashChange
                // event. So instead, lets manually update the hash ourselves to get it
                // to trigger.
                const [_, hash] = this.href.split("#");
                window.location.hash = hash;
            }

            this.$emit("click", e);

            this.tabs.setSelectedIndex(this.localIndex);
        },
    },
    beforeDestroy() {
        this.unregisterTab();
    },
    mounted() {
        this.registerTab();

        this.$nextTick(() => {
            this.checkUrl();
        });
    },
});
</script>
