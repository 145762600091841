<template>
    <th v-if="isHeader">
        {{ title }}
    </th>
    <td v-else class="drawer-open">
        <template v-if="rowField.chevron">
            <div v-if="rowData.children === true || (rowData.children && rowData.children.length)" class="chevron-column-hitbox" @click="chevronClick">
                <svg v-if="expanded" class="pa-icon pa-icon_xxl chevron">
                    <use xlink:href="#chevron-down"></use>
                </svg>
                <svg v-else class="pa-icon pa-icon_xxl chevron">
                    <use xlink:href="#chevron-right"></use>
                </svg>
            </div>
            <div v-else class="chevron-placeholder">
            </div>
        </template>

        <p class="drawer-text" :title="text">
            <a href="#" v-p-drawer-open target="dynamic-drawer" :title="text" :title-link="titleLink" :width="width" :template="link">{{ text }}</a>
        </p>
    </td>
</template>

<script>
    import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin.vue';

    export default {
        name: 'graph',

        mixins: [VuetableFieldMixin],

        data() {
            return {};
        },

        props: {
        },

        computed: {
            link() {
                return this.rowData ? this.rowData.drawer.link : '';
            },

            text() {
                return this.rowData ? this.rowData.drawer.title : '';
            },

            titleLink() {
                return this.rowData ? this.rowData.drawer.titleLink : '';
            },

            width() {
                return this.rowData ? this.rowData.drawer.width : null;
            },

            expanded() {
                // Strict
                return this.rowData.expanded === true;
            },
        },

        methods: {
            chevronClick() {
                this.$parent.$parent.chevronClicked(this.rowData);
            },
        },
    };
</script>
