<template>
    <div
        class="pa-expando"
        :id="id"
        v-on:keydown.esc="close()"
        :class="classes.concat([{
            isActive: active,
            noContent: noContent,
            hasError: this.indicateErrors && this.numErrors > 0,
            'left-chevron': chevronSide === 'left',
            disabled
        }])">
        <div
            class="pa-expando-hd"
            type="button"
            ref="trigger"
            v-on:click="toggle()"
            :tabindex="isFocusable ? 0 : -1">

            <div class="pa-expando-hd-wrapper" :class="headerClass">

                <template v-if="chevronSide === 'left' && !hideChevron">
                    <span v-if="iconType === 'chevron' && !noContent" class="pa-expando-hd-icon chevron">
                        <p-icon
                            size="xxl"
                            icon="chevron-up"
                            :color="chevronColor"
                        >
                        </p-icon>
                    </span>

                    <span v-if="iconType === 'expand' && !noContent" class="pa-expando-hd-icon">
                        <svg v-if="active" class="pa-icon pa-icon_xxl collapse">
                            <use xlink:href="#arrow-expand-vertical-collapsed"></use>
                        </svg>
                        <svg v-if="!active" class="pa-icon pa-icon_xxl expand">
                            <use xlink:href="#arrow-expand-vertical"></use>
                        </svg>
                    </span>
                </template>

                <span v-if="indicateErrors && numErrors > 0" class="pa-expando-error-icon">
                    <svg class="pa-icon">
                        <use xlink:href="#alert-circle-outline"></use>
                    </svg>
                </span>

                <slot name="header"></slot>

                <input v-model="title"
                    ref="title-input"
                    @click.stop=""
                    @keyup.enter="finishTitleEdit"
                    v-if="editingTitle"
                    class="pa-input"
                    style="width: 200px;" />
                <span v-else class="pa-txt_medium">{{ title }}</span>
                <template v-if="editableTitle">
                    <svg v-if="editingTitle"
                            @click.stop="finishTitleEdit"
                            class="pa-icon"
                            style="height: 19px; vertical-align: text-top; margin-left: 5px;">
                        <use xlink:href="#check"></use>
                    </svg>
                    <svg v-else
                            @click.stop="editingTitle=true"
                            class="pa-icon"
                            style="height: 19px; vertical-align: text-top; margin-left: 5px;">
                        <use xlink:href="#pencil"></use>
                    </svg>
                </template>

                <slot name="headerControls"></slot>

                <template v-if="chevronSide === 'right' && !hideChevron">
                    <span v-if="iconType === 'chevron' && !noContent" class="pa-expando-hd-icon chevron">
                        <p-icon
                            size="xxl"
                            icon="chevron-up"
                            :color="chevronColor"
                        >
                        </p-icon>
                    </span>

                    <span v-if="iconType === 'expand' && !noContent" class="pa-expando-hd-icon">
                        <svg v-if="active" class="pa-icon pa-icon_xxl collapse">
                            <use xlink:href="#arrow-expand-vertical-collapsed"></use>
                        </svg>
                        <svg v-if="!active" class="pa-icon pa-icon_xxl expand">
                            <use xlink:href="#arrow-expand-vertical"></use>
                        </svg>
                    </span>
                </template>

                <span class="pa-expando-remove-icon">
                    <svg v-if="removable" @click="removeSelf" class="pa-icon pa-icon_xxl">
                        <use xlink:href="#close"></use>
                    </svg>
                </span>

            </div>

        </div>
        <div v-if="!noContent" ref="box" class="pa-expando-box">
            <div ref="body" class="pa-expando-box-bd" :class="bodyClass">
                <slot v-if="hasLoaded"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const MAX_HEIGHT = "999999px";
    let COUNT = 0;

    const Expando = Vue.extend({
        data() {
            return {
                active: this.isActive,
                isFocusable: true,
                domObserver: null,
                numErrors: 0,
                editingTitle: false,
                height: 0,
                combinedHeight: 0,
                hasLoaded: !this.lazyLoad,
            };
        },

        events: {
            'expando:open': function(id) {
                if (this.id === id) {
                    this.open();
                }
            },
            'expando:close': function(id) {
                if (this.id === id) {
                    this.close();
                }
            },

            'expando:resize': function() {
                if (!this.active) { return; }
                this.fitHeight();
            },
            'expando:reset': function() {
                // Zero out expando height for removed elements
                if (!this.active) { return; }
                this.$refs.box.style.maxHeight = 0;
            },

            'expando:fit': function() {
                if (this.active) {
                    this.fitHeight();
                }
            }
        },

        watch: {
            height(curr, prev) {
                this.toggleNestedExpandos(curr, prev);
            },
            combinedHeight(curr, prev) {
                this.toggleNestedExpandos(curr, prev);
            },
        },

        props: {
            id: {
                type: String,
                'default': () => {
                    return `expando_${COUNT++}`;
                },
            },

            isActive: Boolean,

            noContent: Boolean,

            title: String,

            editableTitle: Boolean,

            focusTrigger: Boolean,

            titleChangeCallback: {
                type: Function,
                'default': () => { },
            },

            removable: Boolean,

            removeCallback: {
                type: Function,
                'default': () => { },
            },

            indicateErrors: {
                type: Boolean,
                'default': true,
            },

            classes: {
                type: Array,
                'default': () => {
                    return [];
                },
            },

            iconType: {
                type: String,
                'default': 'chevron',
            },

            chevronSide: {
                type: String,
                'default': 'right',
            },

            bodyClass: String,
            headerClass: String,
            chevronColor: String,
            hideChevron: Boolean,
            isNested: Boolean,
            disabled: Boolean,
            lazyLoad: Boolean,
        },

        methods: {
            close(shouldFocusTrigger) {
                if (this.$refs.box) {
                    this.$refs.box.style.maxHeight = 0;
                }
                this.height = 0;
                this.active = false;

                this.eventHub.$emit('expando:closed', {
                    id: this.id,
                });
                this.$emit('close', this.id);

                if (!shouldFocusTrigger) {
                    return;
                }

                if (this.focusTrigger) {
                    this.$refs.trigger.focus();
                }
            },

            open() {
                if (!this.hasLoaded) {
                    this.hasLoaded = true;
                }
                this.active = true;
                this.eventHub.$emit('expando:opened', {
                    id: this.id,
                });
                this.$emit('open', this.id);

                if (this.focusTrigger) {
                    this.$refs.trigger.focus();
                }

                Vue.nextTick(() => {
                    this.setupMutationObserver();

                    /**
                     * Wait for some small time after the expando to open
                     * to better calculate the height we need to fit.
                     */
                    setTimeout(() => {
                        this.fitHeight();
                    }, 50);
                });
            },

            findParentExpando() {
                let component = null;
                let parent = this.$parent;
                while (parent && !component) {
                    if (parent.$options._componentTag === "p-expando") {
                        component = parent;
                    }
                    parent = parent.$parent;
                }
                return component;
            },

            toggle() {
                if (
                    document.activeElement === this.$refs['title-input'] ||
                    this.disabled
                ) {
                    return;
                }

                if (!document.activeElement.isEqualNode(this.$refs.trigger)) {
                    return;
                }

                if (this.active) {
                    this.close();

                    return;
                }

                this.open();
            },

            getNumErrors() {
                if (!this.$el) { return 0; }
                const errorClasses = ['pa-hint_error', 'pa-input_error'];
                let numErrors = 0;
                errorClasses.forEach(ec => {
                    numErrors += this.$el.querySelectorAll(`.${ec}:not([style*="display: none"])`).length;
                });
                this.numErrors = numErrors;
                return numErrors;
            },

            setupMutationObserver() {
                const targetNode = this.$refs.box;

                if (!targetNode) {
                    return;
                }

                const config = {
                    childList: true,
                    subtree: true,
                    attributes: true,
                    attributeOldValue: true,
                    attributeFilter: ['style'],
                };

                const callback = mutationsList => {
                    this.getNumErrors();

                    // ON the INCDP, when you hover over the graph above the expando
                    // this callback would change the height of the parent expando causing
                    // the nested expandos to overflow the parent expando. So we check
                    // if we have nested expandos and then return early if we do, so the
                    // height doesnt change.
                    const hasNestedExpandos = !!this.combinedHeight;

                    if (!this.active || hasNestedExpandos) {
                        return;
                    }

                    let shouldResize = false;
                    for (const mutation of mutationsList) {
                        if (mutation.target === targetNode) {
                            continue;
                        }
                        if (mutation.type === 'attributes') {
                            const oldValue = mutation.oldValue;
                            if (oldValue && oldValue.includes('display')) {
                                shouldResize = true;
                                break;
                            }
                        } else if (mutation.addedNodes.length || mutation.removedNodes.length) {
                            shouldResize = true;
                            break;
                        }
                    }
                    if (shouldResize) {
                        this.fitHeight();
                    }
                };

                this.domObserver = new MutationObserver(callback);

                this.domObserver.observe(targetNode, config);
            },

            teardownMutationObserver() {
                if (!this.domObserver) { return; }
                this.domObserver.disconnect();
                this.domObserver = null;
            },

            fitHeight() {
                if (typeof this.$refs.box === 'undefined') {
                    return;
                }
                this.$refs.box.style.maxHeight = MAX_HEIGHT;
                this.height = this.$refs.body.offsetHeight;
            },

            toggleNestedExpandos(currHeight, prevHeight) {
                let toggle;

                if (prevHeight === 0) {
                    toggle = "open";
                } else if (currHeight === 0) {
                    toggle = "close";
                } else if (prevHeight < currHeight) {
                    toggle = "grow";
                } else if (prevHeight > currHeight) {
                    toggle = "shrink";
                }

                Vue.nextTick(() => {
                    if (this.isNested && this.parentExpando) {
                        let height = currHeight;

                        if (toggle === "close") {
                            height = -prevHeight;
                        }

                        const parentHeight = this.parentExpando.combinedHeight || this.parentExpando.height;
                        const combinedHeight = parentHeight + height;

                        this.parentExpando.fitNestedHeight(combinedHeight);
                    }
                });
            },

            fitNestedHeight(combinedHeight) {
                this.$refs.box.style.maxHeight = MAX_HEIGHT;
                this.combinedHeight = combinedHeight;
            },

            finishTitleEdit() {
                this.editingTitle = false;
                this.titleChangeCallback(this.title);
            },

            removeSelf() {
                this.removeCallback();
                this.$destroy(true);
            },
        },

        beforeDestroy() {
            this.teardownMutationObserver();
        },

        vueReady() {
            this.parentExpando = this.findParentExpando();

            if (!this.active) {
                return;
            }

            this.open();
        },
    });

    export default Expando;
</script>
