<style lang="scss">
#filter-metrics-select {
    align-items: center;
    display: flex;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    height: 30px;
    padding: 0;
    position: relative;
    right: initial;
    top: initial;
    width: 30px;
    &:hover {
        border-color: #bcc5d1;
        .pa-icon {
            fill: #396abf;
        }
    }
}
#instance-performance {
    .showchild {
        display: inline-block;
        min-width: 16px;
        width: 16px;
        height: 16px;
        background: url("/static/assets/newux/media/svgs/chevron-right-grey.svg") no-repeat center center;
        padding-right: 5px;
    }
    .shown {
        .showchild {
            background: url("/static/assets/newux/media/svgs/chevron-down-grey.svg") no-repeat center center;
        }
    }
    .loading {
        .showchild {
            background: url("/static/assets/newux/media/small-spinner.gif") no-repeat center center;
        }
    }
    #table-network-port {
        border-bottom: 1px solid #e6e6e6;
        td {
            vertical-align: middle;
            cursor: pointer;
        }
        thead {
            tr {
                th {
                    text-transform: uppercase;
                }
            }
        }
        th.pa-txt_centered {
            text-align: center;
        }
        .data-tables-child-row {
            background-color: #f7f7f7;
        }
    }

    .pa-section {
        padding: 0px;
    }

    .incident-details-graph-list {
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 40px;
    }

    .step-button {
        &.isActive,
        &:focus {
            .step-button-icon {
                fill: #4a4a4a;
            }
        }

        &:hover,
        &:active
        {
            .step-button-icon {
                fill: #fff;
            }
        }
    }

    .step-button-icon {
        fill: #4a4a4a;
    }

    .step-tooltip {
        .content {
            background-color: #4f5766;
            color: white;
            font-family: "Inter", sans-serif;
            font-size: 12px;
            padding: 4px 8px;
        }
    }

    .perf-header {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: -12px;
        background-color: white;
        z-index: 11;
    }

    .pa-tabs {
        padding-left: 24px
    }

    hr {
        margin: 0px;
    }
}
#deleted-alert {
    position: absolute;
    left: -1.4117647059rem;
    width: calc(100% + 2.8235294118rem);
}
@media (max-width: 720px) {
    .incident-details-graph-list {
        >.pa-grid-col_4of12 {
            width: 100%;
        }
    }
}
@media (max-width: 1100px) {
    .incident-details-graph-list {
        >.pa-grid-col_4of12 {
            width: 50%;
        }
    }
}

th div.network-port-header div {
    text-align: center;
    font-size: .6470588235rem !important;
}
th div.network-port-header div span.pa-tooltip-box { width: 100%; padding-top: 4px; }
#table-network-port th span.sort-icon { display: none; }
th .network-port-header .col-name {
    white-space: break-spaces;
}
#table-network-port tr td:first-child { width: 40%; }

.dynamicNetworkDeviceTable {
    th { text-transform: none }
    .dynamic-metric-value {
        word-break: break-word;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding-left: 10px;
    }
}
</style>

<template>
    <section id="instance-performance" class="pa-grid" :class="{ 'pa-mt-20': !isIncdp }">

        <div class="pa-grid-col pa-grid-col_12of12">
            <div v-if="instance.deleted" style="height: 40px;">
                <p-alert
                    id="deleted-alert"
                    variant="yellow"
                    :full-width="true"
                    style="width: 100%;"
                    class="pa-px-24"
                    icon="information-outline">
                    <p class="pa-txt_sm">Metric history is not available for deleted instances. If this data is critical, please <a href="https://docs.fortimonitor.forticloud.com/user-guide/46227/get-support">contact our support team</a></p>
                </p-alert>
            </div>
            <div v-else class="pa-section" :class="{ 'pa-p-0': isIncdp }">
                <div class="perf-header">
                <template v-if="!isIncdp">
                    <div class="pa-row pa-justify-center pa-p-20">
                        <h4 class="pa-txt_19 pa-txt_medium">Performance</h4>
                    </div>
                    <p-divider class="pa-mt-0"></p-divider>
                </template>

                <div v-if="showGraphFilterControls"  class="pa-row pa-pl-24 pa-pb-8 pa-pt-2 pa-justify-center">
                    <p-toggle-chip-list
                        :chips="graph_category_options"
                        :model="graph_filtered_categories"
                        @change="onGraphFilteredCategoriesChange"
                        class="pa-justify-center"
                    >
                    </p-toggle-chip-list>
                </div>
                <p-divider v-if="showGraphFilterControls" class="pa-mt-0"></p-divider>
            </div>

                <template v-if="isIncdp">
                    <div class="pa-vr pa-vr_x5"></div>

                    <p-instance-performance-header
                        :timescale-options="timescaleOptions"
                        :time-start="time_start"
                        :time-back="time_back"
                        :time-forward="time_forward"
                        :time-latest="time_latest"
                        :is-graph-time-disabled="isGraphTimeDisabled"
                        :is-graph-time-disabled-rewind="isGraphTimeDisabledRewind"
                        :pre-outage-graph="preOutageGraph"
                        :set-pre-outage-state="setPreOutageState"
                        :graph-category-options="graph_category_options"
                        :selected-metrics-filter="selectedMetricsFilter"
                        :search-term="graphSearchTerm"
                        :timescale="timescale"
                        @update:search-term="updateSearchTerm"
                        @update:timescale="updateTimescale"
                        @update:filter-categories="onGraphFilteredCategoriesChange"
                    >
                    </p-instance-performance-header>
                    <p-instance-performance-graphs
                        :instance="instance"
                        :filtered-graph-sub-categories="filteredGraphSubCategories"
                        :total-metrics="total_metrics"
                        :autoload-graphs="autoloadGraphs"
                        :graphs="graphs"
                        :is-loading="isLoadingGraphs"
                        :all-graphs-loaded="allGraphsLoaded"
                        :timescale="timescale"
                        :date_range="date_range"
                        class="pa-px-12"
                    >
                    </p-instance-performance-graphs>
                </template>

                <template v-else-if="instance.deviceModel === 'server_id' && instance.isNetworkDevice && !noHeader">
                    <p-tabs id="device-panels" lazy @change="onPerfTabChange">
                        <p-tab-list ref="perfTabsList">
                            <p-tab href="#device-metrics">
                                Device Metrics
                            </p-tab>
                            <p-tab v-if="instance.showNetworkPorts" href="#network-ports">
                                Network Ports
                            </p-tab>
                            <p-tab
                                href="#sdwan-metrics"
                                v-if="instance.isSdWan"
                            >
                                SD-WAN Synthetics
                            </p-tab>
                            <p-tab
                                :key="tab.name"
                                v-for="(tab, index) in instance.dynamicTabs"
                                :href="`#network_panel_${2 + (index + 1)}`"
                            >
                                {{ tab.name }}
                            </p-tab>
                        </p-tab-list>
                        <p-tab-panels>
                            <!-- Device Metrics -->
                            <p-tab-panel>
                                <p-instance-performance-header
                                    :timescale-options="timescaleOptions"
                                    :time-start="time_start"
                                    :time-back="time_back"
                                    :time-forward="time_forward"
                                    :time-latest="time_latest"
                                    :is-graph-time-disabled="isGraphTimeDisabled"
                                    :is-graph-time-disabled-rewind="isGraphTimeDisabledRewind"
                                    :pre-outage-graph="preOutageGraph"
                                    :set-pre-outage-state="setPreOutageState"
                                    :graph-category-options="graph_category_options"
                                    :selected-metrics-filter="selectedMetricsFilter"
                                    :search-term="graphSearchTerm"
                                    :timescale="timescale"
                                    @update:search-term="updateSearchTerm"
                                    @update:timescale="updateTimescale"
                                    @update:filter-categories="onGraphFilteredCategoriesChange"
                                >
                                </p-instance-performance-header>
                                <p-instance-performance-graphs
                                    ref="instancePerformanceGraphs"
                                    :instance="instance"
                                    :filtered-graph-sub-categories="filteredGraphSubCategories"
                                    :total-metrics="total_metrics"
                                    :autoload-graphs="autoloadGraphs"
                                    :graphs="graphs"
                                    :is-loading="isLoadingGraphs"
                                    :all-graphs-loaded="allGraphsLoaded"
                                    :timescale="timescale"
                                    :date_range="date_range"
                                    class="pa-px-12"
                                >
                                </p-instance-performance-graphs>
                            </p-tab-panel>

                            <!-- Network Ports -->
                            <p-tab-panel v-if="instance.showNetworkPorts">
                                <div class="pa-grid">
                                    <div class="pa-grid-col">
                                        <p-table2
                                            ref="network_port_table"
                                            source="server"
                                            :data-url="networkPortDataTableUrl"
                                            info
                                            paging
                                            :filtering="true"
                                            :page-length="20"
                                            :column-renderers="{}"
                                            :column-classes="networkPortOrderColumns"
                                            :child-row-column="1"
                                            child-row-type="div"
                                            child-trigger-selector="td"
                                            empty-message="No ports found."
                                            ordering
                                            :order-columns="networkPortOrderColumns"
                                            :on-init-complete="onNetworkPortTableComplete"
                                            @table-children-loaded="onNetworkPortChildTableComplete"
                                            class="pa-px-12"
                                        >
                                            <table id="table-network-port" slot="table" class="pa-table pa-table_top pa-table_truncate">
                                                <thead>
                                                    <tr class="pa-table-row pa-table-row_hd">
                                                        <th v-for="(col, index) in instance.portTableColumns || []">
                                                            <template v-if="index === 0">
                                                                <span style="padding-left: 30px; background: none;">{{ col.name }}</span>
                                                            </template>
                                                            <template v-else>
                                                                <div class="network-port-header">
                                                                    <div class="col-name" v-html="col.name.replace(' ', '&lt;br/&gt;')"></div>
                                                                    <p-tooltip v-if="col.tooltip" :fixed="true">
                                                                        <span slot="trigger">
                                                                            <p-icon icon="help-circle"></p-icon>
                                                                        </span>
                                                                        <span>
                                                                            <span class="pa-txt_capitalize pa-txt_normal">{{ col.tooltip }}</span>
                                                                        </span>
                                                                    </p-tooltip>
                                                                </div>
                                                            </template>
                                                        </th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </p-table2>
                                    </div>
                                </div>
                            </p-tab-panel>

                            <p-tab-panel v-if="instance.isSdWan">
                                <p-sd-wan-performance-header
                                    :instance="instance"
                                    :timescale="timescale"
                                    :timescale-options="sdWanTimescaleOptions"
                                    @update:timescale="updateTimescale"
                                ></p-sd-wan-performance-header>
                                <p-divider></p-divider>
                                <p-sd-wan-performance-graphs
                                    ref="sdWanPerformanceGraphs"
                                    :instance="instance"
                                    :timescale="timescale"
                                    class="pa-px-12"
                                >
                                </p-sd-wan-performance-graphs>
                            </p-tab-panel>

                            <!-- Dynamic Tabs -->
                            <template v-if="dynamicTabsReady">
                                <p-tab-panel
                                    :key="tab.name"
                                    v-for="tab in instance.dynamicTabs"
                                    ref="dynamicTabs"
                                >
                                    <div class="pa-grid">
                                        <div class="pa-grid-col pa-mx-20 pa-mb-20">
                                            <p-table2
                                                source="server"
                                                id="dynamicNetworkDeviceTabTable"
                                                class="dynamicNetworkDeviceTable"
                                                :column-classes="dynamicNetworkColumnClasses[tab.name]"
                                                :column-renderers="dynamicNetworkColumnRenderers[tab.name]"
                                                :column-callbacks="dynamicNetworkColumnCallbacks[tab.name]"
                                                :data-url="'/report/get_dynamic_network_device_table?server_id=' + instance.id + '&textkey=' + tab.textkey"
                                                :columns="tab.columns"
                                                :col-reorder="false"
                                                info
                                                paging
                                                :page-length="50"
                                            >
                                                <table slot="table" class="pa-table pa-table_top">
                                                    <thead>
                                                        <tr class="pa-table-row pa-table-row_hd">
                                                            <th :key="column.data" v-for="column in tab.columns">
                                                                {{ column.data }}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </p-table2>
                                        </div>
                                    </div>
                                </p-tab-panel>
                            </template>
                        </p-tab-panels>
                    </p-tabs>
                </template>

                <template v-else>
                    <div class="pa-vr pa-vr_x5"></div>

                    <p-tabs lazy @change="onPerfTabChange">
                        <p-tab-list ref="perfTabsList">
                            <p-tab href="#device-metrics">
                                Device Metrics
                            </p-tab>
                            <template v-if="instance.agentHasDEM">
                                <p-tab href="#inbound-synthetics">
                                    <p-flex align-center>
                                        <p-text class="pa-m-0 pa-mr-4">
                                            Inbound Synthetics
                                        </p-text>
                                        <p-flyout
                                            direction="bottom"
                                            scroll-lock
                                            hover
                                            :hover-timeout="0"
                                            :width="350"
                                            :arrow-percent="0"
                                        >
                                            <p-icon
                                                slot="trigger"
                                                icon="question-circle-outline"
                                                color="cloudy-blue"
                                                size="sm"
                                            >
                                            </p-icon>
                                            <p-text slot="content">
                                                Checks running on this instance from other instances.
                                            </p-text>
                                        </p-flyout>
                                    </p-flex>
                                </p-tab>
                                <p-tab href="#outbound-synthetics">
                                    <p-flex align-center>
                                        <p-text class="pa-m-0 pa-mr-4">
                                            Outbound Synthetics
                                        </p-text>
                                        <p-flyout
                                            direction="bottom"
                                            scroll-lock
                                            hover
                                            :hover-timeout="0"
                                            :width="350"
                                            :arrow-percent="0"
                                        >
                                            <p-icon
                                                slot="trigger"
                                                icon="question-circle-outline"
                                                color="cloudy-blue"
                                                size="sm"
                                            >
                                            </p-icon>
                                            <p-text slot="content">
                                                Checks running from this instance to other instances.
                                            </p-text>
                                        </p-flyout>
                                    </p-flex>
                                </p-tab>
                            </template>
                        </p-tab-list>
                        <p-tab-panels>
                            <!-- Device Metrics -->
                            <p-tab-panel>
                                <p-instance-performance-header
                                    :timescale-options="timescaleOptions"
                                    :time-start="time_start"
                                    :time-back="time_back"
                                    :time-forward="time_forward"
                                    :time-latest="time_latest"
                                    :is-graph-time-disabled="isGraphTimeDisabled"
                                    :is-graph-time-disabled-rewind="isGraphTimeDisabledRewind"
                                    :pre-outage-graph="preOutageGraph"
                                    :set-pre-outage-state="setPreOutageState"
                                    :graph-category-options="graph_category_options"
                                    :selected-metrics-filter="selectedMetricsFilter"
                                    :search-term="graphSearchTerm"
                                    :timescale="timescale"
                                    @update:search-term="updateSearchTerm"
                                    @update:timescale="updateTimescale"
                                    @update:filter-categories="onGraphFilteredCategoriesChange"
                                >
                                </p-instance-performance-header>
                                <p-instance-performance-graphs
                                    :instance="instance"
                                    :filtered-graph-sub-categories="filteredGraphSubCategories"
                                    :total-metrics="total_metrics"
                                    :autoload-graphs="autoloadGraphs"
                                    :graphs="graphs"
                                    :is-loading="isLoadingGraphs"
                                    :all-graphs-loaded="allGraphsLoaded"
                                    :timescale="timescale"
                                    :date_range="date_range"
                                    class="pa-px-12"
                                >
                                </p-instance-performance-graphs>
                            </p-tab-panel>

                            <template v-if="instance.agentHasDEM">
                                <!-- Inbound Synthetics -->
                                <p-tab-panel>
                                    <p-instance-performance-header
                                        :timescale-options="timescaleOptions"
                                        :time-start="time_start"
                                        :time-back="time_back"
                                        :time-forward="time_forward"
                                        :time-latest="time_latest"
                                        :is-graph-time-disabled="isGraphTimeDisabled"
                                        :is-graph-time-disabled-rewind="isGraphTimeDisabledRewind"
                                        :pre-outage-graph="preOutageGraph"
                                        :set-pre-outage-state="setPreOutageState"
                                        :graph-category-options="graph_category_options"
                                        :selected-metrics-filter="selectedMetricsFilter"
                                        :search-term="graphSearchTerm"
                                        :timescale="timescale"
                                        @update:search-term="updateSearchTerm"
                                        @update:timescale="updateTimescale"
                                        @update:filter-categories="onGraphFilteredCategoriesChange"
                                    >
                                    </p-instance-performance-header>
                                    <p-instance-performance-graphs
                                        :instance="instance"
                                        :filtered-graph-sub-categories="filteredGraphSubCategories"
                                        :total-metrics="total_metrics"
                                        :autoload-graphs="autoloadGraphs"
                                        :graphs="graphs"
                                        :is-loading="isLoadingGraphs"
                                        :all-graphs-loaded="allGraphsLoaded"
                                        :timescale="timescale"
                                        :date_range="date_range"
                                        class="pa-px-12"
                                    >
                                    </p-instance-performance-graphs>
                                </p-tab-panel>

                                <!-- Outbound Synthetics -->
                                <p-tab-panel>
                                    <p-instance-performance-header
                                        :timescale-options="timescaleOptions"
                                        :time-start="time_start"
                                        :time-back="time_back"
                                        :time-forward="time_forward"
                                        :time-latest="time_latest"
                                        :is-graph-time-disabled="isGraphTimeDisabled"
                                        :is-graph-time-disabled-rewind="isGraphTimeDisabledRewind"
                                        :pre-outage-graph="preOutageGraph"
                                        :set-pre-outage-state="setPreOutageState"
                                        :graph-category-options="graph_category_options"
                                        :selected-metrics-filter="selectedMetricsFilter"
                                        :search-term="graphSearchTerm"
                                        :timescale="timescale"
                                        @update:search-term="updateSearchTerm"
                                        @update:timescale="updateTimescale"
                                        @update:filter-categories="onGraphFilteredCategoriesChange"
                                    >
                                    </p-instance-performance-header>
                                    <p-instance-performance-graphs
                                        :instance="instance"
                                        :filtered-graph-sub-categories="filteredGraphSubCategories"
                                        :total-metrics="total_metrics"
                                        :autoload-graphs="autoloadGraphs"
                                        :graphs="graphs"
                                        :is-loading="isLoadingGraphs"
                                        :all-graphs-loaded="allGraphsLoaded"
                                        :timescale="timescale"
                                        :date_range="date_range"
                                        class="pa-px-12"
                                    >
                                    </p-instance-performance-graphs>
                                </p-tab-panel>
                            </template>
                        </p-tab-panels>
                    </p-tabs>
                </template>

            </div>
        </div>
    </section>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';

import getUrlParams from './../shared/utils/getUrlParams';
import generateKebabCaseID from './../shared/utils/generateKebabCaseID';
import InstancePerformanceHeader from './InstancePerformanceHeader.vue';
import InstanceNetworkPortGraphs from '../instance_details/InstanceNetworkPortGraphs.vue'
import SdWanPerformanceHeader from './SdWanPerformanceHeader.vue';
import SdWanPerformanceGraphs from './SdWanPerformanceGraphs.vue';


export default Vue.extend({
    components: {
        'p-instance-performance-header': InstancePerformanceHeader,
        'p-sd-wan-performance-header': SdWanPerformanceHeader,
        'p-sd-wan-performance-graphs': SdWanPerformanceGraphs,
    },
    data: function() {
        return {
            graphs_number: 1,
            total_metrics: 0,
            graph_config_loaded: false,
            graph_categories: [],
            graph_sub_categories: [],
            has_network_ports: false,
            noGraphsAvailable: false,
            graph_category_options: [],
            graphs: [],
            timescale: 'hour',
            timescale_increments: {
                "15min": 15 * 60,
                hour: 60 * 60,
                "6hr": 6 * 60 * 60,
                "12hr": 12 * 60 * 60,
                day: 24 * 60 * 60,
                week: 7 * 24 * 60 * 60,
                month: 31 * 24 * 60 * 60,
                year: 365 * 24 * 60 * 60,
            },
            graph_dir_commands: [],
            autoloadGraphs: false,
            graph_filtered_categories: [],
            interval: null,
            isLoadingGraphs: true,
            graphSearchTerm: "",
            allGraphsLoaded: false,
            matchingPortRow: null,
            date_range:"",
            max_offset: this.instance.ageInSeconds || 0,
            isGraphTimeDisabledRewind: false,
            showGraphFilterControls: false,
            timescaleOptions: [
                {'value': 'custom', 'label': 'Incident Duration'},
                {'value': '15min', 'label': 'Last 15 minutes'},
                {'value': 'hour', 'label': 'Last Hour'},
                {'value': '6hr', 'label': 'Last 6 Hours'},
                {'value': '12hr', 'label': 'Last 12 Hours'},
                {'value': 'day', 'label': 'Last Day'},
                {'value': 'week', 'label': 'Last Week'},
                {'value': 'month', 'label': 'Last Month'},
                {'value': 'year', 'label': 'Last Year'},
            ],
            sdWanTimescaleOptions: [
                {'value': 'custom', 'label': 'Incident Duration'},
                {'value': '15min', 'label': 'Last 15 minutes'},
                {'value': 'hour', 'label': 'Last Hour'},
                {'value': '6hr', 'label': 'Last 6 Hours'},
                {'value': '12hr', 'label': 'Last 12 Hours'},
                {'value': 'day', 'label': 'Last Day'},
                {'value': 'week', 'label': 'Last Week'},
                {'value': 'month', 'label': 'Last Month'},
            ],
            dynamicNetworkColumnRenderers: {},
            dynamicNetworkColumnClasses: {},
            dynamicNetworkColumnCallbacks: {},
            activePanelTitle: null,
            activePanelId: null,
            selectedPerfTabIndex: null,
            dynamicTabsReady: false,
        };
    },
    props: {
        instance: Object,
        preOutageGraph: Object,
        startPreOutage: Boolean,
        noHeader: Boolean,
    },
    computed: {
        isIncdp() {
            return this.pathname === "/outage/IncidentDetails";
        },
        pathname() {
            return window.location.pathname;
        },
        networkPortDataTableUrl: function() {
            return '/report/get_network_port_table_data?server_id='
                + this.instance.id
                + '&new_idp=true'
                + '&timescale='
                + this.timescale;
        },
        networkPortColumnClasses: function() {
            const columnConfigs = (this.instance && this.instance.portTableColumns) ?
                this.instance.portTableColumns : [];
            const orderColumns = {};
            for (let i = 2; i < columnConfigs.length; i++) {
                orderColumns[i] = 'pa-txt_centered';
            }
            return orderColumns;
        },
        networkPortOrderColumns: function() {
            const columnConfigs = (this.instance && this.instance.portTableColumns) ?
                this.instance.portTableColumns : [];
            const colClasses = {};
            for (let i = 2; i < columnConfigs.length; i++) {
                colClasses[i] = false;
            }
            return colClasses;
        },
        graphSeriesNames: function() {
            let seriesNames = [];

            for (let index = 0; index < this.graphs.length; index++) {
                const graph = this.graphs[index];
                const seriesName = graph.series.map(s => s.name).join("").trim().toLowerCase();

                seriesNames.push({
                    monitors: graph.monitors,
                    seriesNames: seriesName,
                });
            }

            return seriesNames;
        },
        selectedMetricsFilter: function() {
            if(this.graph_filtered_categories.length == 0) {
                return 'All';
            } else if (this.graph_filtered_categories.length > 2) {
                return `${this.graph_filtered_categories.length} Selected`
            } else {
                let selectedMetricsNames = []
                for(let i of this.graph_filtered_categories) {
                    for(let j of this.graph_category_options) {
                        if(j.value == i) {
                            selectedMetricsNames.push(j.label);
                        }
                    }
                }
                return selectedMetricsNames.join(', ');
            }
        },
        filteredGraphSubCategories: function() {
            // Filters the sub categories by the filter dropdown,
            // graph title and graph series names
            const searchTerm = this.graphSearchTerm.trim().toLowerCase();
            const hasSearchTerm = searchTerm && searchTerm !== "";
            const hasFilteredCategories = this.graph_filtered_categories.length !== 0;

            if (!hasFilteredCategories &&!hasSearchTerm) {
                const filteredGraphSubCategories = this.graph_sub_categories.map(
                    (category, index) => ({
                        ...category,
                        graphId: "p-graph-" + index,
                    })
                );

                return filteredGraphSubCategories;
            }

            const filteredGraphSubCategories = this.graph_sub_categories.filter((subCategory) => {
                const subCategoryName = subCategory.sub_category_name.toLowerCase();
                const isFiltered = this.graph_filtered_categories.includes(subCategory.category_textkey);
                let hasMatchingSeriesName = false;

                if (hasSearchTerm) {
                    const matchedSeries = this.matchSeriesName(searchTerm);

                    if (matchedSeries && matchedSeries.monitors) {
                        const monitorPoints = _.flatMap(subCategory.metrics, (metric) => metric[1]);

                        hasMatchingSeriesName = _.intersection(
                            matchedSeries.monitors,
                            monitorPoints
                        ).length > 0;
                    }
                }

                if (
                    (hasFilteredCategories && isFiltered) ||
                    !hasFilteredCategories
                ) {
                    if (hasSearchTerm) {
                        return (
                            hasMatchingSeriesName ||
                            subCategoryName.includes(searchTerm)
                        );
                    }

                    return true;
                }

                return false;
            });

            return filteredGraphSubCategories.map(
                (category, index) => ({
                    ...category,
                    graphId: "p-graph-" + index,
                })
            );
        },
        isGraphTimeDisabled: function() {
            return this.timescale === "custom";
        },
    },
    methods: {
        onPerfTabChange(selectedIndex) {
            this.selectedPerfTabIndex = selectedIndex;
        },
        updateSearchTerm(val) {
            this.graphSearchTerm = val;
        },
        updateTimescale(val) {
            this.timescale = val;
        },
        onGraphFilteredCategoriesChange(val) {
            this.graph_filtered_categories = [...val];
        },
        onNetworkPortChildTableComplete: function(data) {
            if (this.matchingPortRow >= 0) {
                this.scrollToMatchingTableRow();
            }

            const networkGraphComp =
                new InstanceNetworkPortGraphs({
                    propsData: {
                        data: data,
                        date_range: this.date_range,
                    },
                });
            networkGraphComp.$mount("#tables-child-row-containter")
        },
        onNetworkPortTableComplete: function(settings, json) {
            this.checkForNetworkDeviceDeepLink();
        },
        setPreOutageState() {
            this.timescale = "custom";
            this.date_range = `${this.preOutageGraph.startTime} - ${this.preOutageGraph.endTime}`;
        },
        matchSeriesName: function(searchTerm) {
            // Loops through the graphs on the page and matches the search term
            // with the name of the series. If we find a matching one return the
            // monitor point ids so we can use that to match with the subcategories
            // from the graph configuration
            const _searchTerm = searchTerm.trim().toLowerCase();

            let monitors = []

            for (let index = 0; index < this.graphSeriesNames.length; index++) {
                const item = this.graphSeriesNames[index];

                if (item.seriesNames.includes(_searchTerm)) {
                    monitors = [
                        ...monitors,
                        ...item.monitors
                    ];
                }
            }
            if (monitors.length) {
                return {monitors};
            }
            return false;
        },
        renderValueColumn(data, type, row) {
            if(!data){
                return "";
            }
            if(data.value === "" || data.value === null) {
                return "";
            }
            return `<p-server-tab-table-col
                        id="${data.id}"
                        title="${data.title}"
                        value="${data.value}"
                    ></p-server-tab-table-col>`;

        },
        valueColumnCallback(td) {
            this.$compile(td);
        },
        time_start: function() {
            // Set offset to extremely large value, will get picked up as the start of monitoring for the server
            const offset = this.max_offset;
            this.isGraphTimeDisabledRewind = true;
            this.eventHub.$emit('graph:setOffset', offset);
            this.graph_dir_commands.push("time_start");
        },
        time_back: function() {
            // Change the offset for all of the graphs in this container
            const timescale = this.timescale;
            const increment = this.timescale_increments[timescale];
            let self = this;
            this.graphs.forEach(function(graph) {
                if(graph.iOffset >= self.max_offset) {
                    self.isGraphTimeDisabledRewind = true;
                }
            });

            this.eventHub.$emit('graph:incrementOffset', increment);
            this.graph_dir_commands.push("time_back");
        },
        time_forward: function() {
            // Change the offset for all of the graphs in this container
            const timescale = this.timescale;
            const increment = this.timescale_increments[timescale];

            this.eventHub.$emit('graph:decrementOffset', increment);
            this.graph_dir_commands.push("time_forward");
            this.isGraphTimeDisabledRewind = false;
        },
        time_latest: function() {
            this.eventHub.$emit('graph:setOffset', 0);
            this.graph_dir_commands.push("time_latest");
            this.isGraphTimeDisabledRewind = false;
        },
        initializeGraphSort: function() {
            const sortableOptions = {
                handle: ".incident-details-graph-header",
                tolerance: "pointer",
            };

            $(".incident-details-graph-list").sortable(sortableOptions);
            $(".incident-details-graph-list").disableSelection();
        },
        fetch_graph_data: function() {
            this.graphs.forEach(function(graph) {
                graph.fetch_graph_data();
            });
        },
        load_graph_data: function() {
            // Broadcast to all graphs on the Performance tab to load their data
            // this.$broadcast("load-initial-graph-data");
            // this.$root.$broadcast("load-initial-graph-data");
            window.app.rootVue.eventHub.$emit("load-initial-graph-data");
        },
        change_date_range: function(preOutageGraph) {
            if(this.timescale == 'custom') {
                this.date_range = `${preOutageGraph.startTime} - ${preOutageGraph.endTime}`;
            }
        },
        resetGraphData() {
            this.isLoadingGraphs = true;
            this.timescale = "hour";
            this.graphSearchTerm = "";
            this.graph_filtered_categories = [];
            this.graphs_number = 1;
            this.total_metrics = 0;
            this.graph_config_loaded = false;
            this.graph_sub_categories = [];
            this.graph_categories = [];
            this.has_network_ports = false;
            this.noGraphsAvailable = false;
            this.graph_category_options = [];
            this.graphs = [];
        },
        load_graph_config: function(graphTypes) {
            const data = {
                [this.instance.deviceModel]: this.instance.id,
                graph_types: graphTypes
            };

            if (!this.graph_config_loaded) {
                const ajaxSettings = {
                    url: "/report/get_graph_configuration_data",
                    context: this,
                    data,
                };

                $.ajax(ajaxSettings).done(function(data) {
                    if (this.isLoadingGraphs) {
                        this.isLoadingGraphs = false;
                    }
                    this.graphs_number = data.graphs_number;
                    this.total_metrics = data.total_metrics;
                    this.graph_config_loaded = true;
                    this.graph_sub_categories = [...data.sub_categories];
                    this.graph_categories = [...data.categories];
                    this.has_network_ports = data.has_network_ports;

                    if (!data.sub_categories || !data.sub_categories.length) {
                        this.noGraphsAvailable = true;
                    }

                    let graph_category_options = [];

                    if (data.categories) {
                        graph_category_options = data.categories.map((category) => ({
                            value: category.category_textkey,
                            label: category.category_name,
                        }));
                    }

                    this.graph_category_options = [...graph_category_options];
                });
            }
        },
        pollGraphData: function() {
            // Fetch graph data every n seconds
            this.interval = setInterval(() => {
                this.fetch_graph_data({ updatePoints: true });
            }, 90000);
        },
        checkForNetworkDeviceDeepLink: function() {
            const urlParams = getUrlParams(window.location.search);

            if (urlParams.port) {
                if (window.location.hash !== 'network-ports') {

                    this.$broadcast('panels:set_active_panel_by_id','network-ports');
                }

                const tableData =  this.$refs.network_port_table.dataTable.rows().data();
                const self = this;

                for (let index = 0; index < tableData.length; index++) {
                    const column = tableData[index];
                    const hasMatchingPort = _.last(column) === urlParams.port;

                    if (hasMatchingPort) {
                        this.matchingPortRow = index;
                        self.openMatchingTableRow();
                        break;
                    }
                }
            }
        },
        openMatchingTableRow() {
            $(this.$refs.network_port_table.dataTable.cell(this.matchingPortRow, 0).node()).click();
        },
        scrollToMatchingTableRow() {
            const ROUGH_HEADER_HEIGHT = 100;
            const tableRow = this.$refs.network_port_table.dataTable.row(this.matchingPortRow).node();
            const yCoord = (tableRow.getBoundingClientRect().top + window.scrollY) - ROUGH_HEADER_HEIGHT;
            window.scrollTo(0, yCoord);
        },
    },
    watch: {
        selectedPerfTabIndex(curr) {
            if (curr === undefined || !this.$refs.perfTabsList) {
                return;
            }

            const id = this.$refs.perfTabsList.$children[curr].href.replace("#", "");

            if (["device-metrics", "inbound-synthetics", "outbound-synthetics"].includes(id)) {
                const graphTypes = id.split("-")[0];
                this.resetGraphData();
                this.$nextTick(() => {
                    this.load_graph_config(graphTypes !== "device" ? graphTypes : undefined);
                });
            }
        },
        "graphs": function(curr, prev) {
            if (curr.length >= this.graphs_number) {
                this.allGraphsLoaded = true;
            }
        },
        "date_range": function() {
            const date_range = this.date_range;

            // Change the date_range for all of the graphs in this container
            this.eventHub.$emit('graph:changeDateRange', date_range);
        },
        "timescale": function() {
            const timescale = this.timescale;

            // Change the timescale for all of the graphs in this container
            this.eventHub.$emit('graph:changeTimescale', timescale);

            if(timescale == 'custom') {
                this.date_range = `${this.preOutageGraph.startTime} - ${this.preOutageGraph.endTime}`;
            }
        },
    },
    events: {
        "graph:ready": function(graph) {
            if(graph.containerName === 'sdwan_perf') {
                return;
            }

            const hasSeenGraph = this.graphs.some(
                _graph => _.isEqual(_graph.monitors, graph.monitors)
            );
            if (!hasSeenGraph) {
                this.graphs.push(graph);
            }

            //Its possible graphs were filtered and then the
            //timescale and offsets were changed, and then the filter was removed.
            //Lets update the graphs once they're finished compiling.
            for(let command of this.graph_dir_commands) {
                const timescale = this.timescale;
                const increment = this.timescale_increments[timescale];
                if(command == "time_start") {
                    graph.set_offset(this.max_offset, false);
                }
                if(command == "time_back") {
                    graph.increment_offset(increment, false);
                }
                if(command == "time_forward") {
                    graph.decrement_offset(increment, false);
                }
                if(command == "time_latest", false) {
                    graph.set_offset(0);
                }
            }

            if(this.timescale == 'custom') {
                graph.change_date_range(this.date_range);
            }
            graph.change_timescale(this.timescale);
        },
        "reload_graph_config": function() {
            this.graph_config_loaded = false;
            this.load_graph_config();
        },
        "panel:activated": function(msg) {
            this.activePanelId = msg.id;

            if(msg.parent.id != 'devices-panels' && msg.parent.id != 'detail-panels') {
                return;
            }

            const filterControlPanels = [
                "Performance",
            ];

            if (filterControlPanels.includes(msg.title)) {
                this.showGraphFilterControls = true;
            } else {
                this.showGraphFilterControls = false;
            }

            this.activePanelTitle = msg.title;
        },
    },
    created: function() {
        if (this.startPreOutage) {
            this.date_range = `${this.preOutageGraph.startTime} - ${this.preOutageGraph.endTime}`;
            this.timescale = 'custom';
        }
    },
    vueReady: function() {
        const perfTabs = this.$refs.perfTabsList ? this.$refs.perfTabsList.$children : [];

        const deviceMetricsSelected = (
            !this.isIncdp &&
            perfTabs.length &&
            perfTabs[0].selected
        );

        if (this.isIncdp || !window.location.hash || deviceMetricsSelected) {
            this.load_graph_config();
        }

        this.pollGraphData();
        this.initializeGraphSort();

        //use the tab names to setup unique table renderers, col classes and callbacks
        //for every tab.
        for(let j in this.instance.dynamicTabs) {
            let tab = this.instance.dynamicTabs[j];
            this.dynamicNetworkColumnClasses[tab.name] = {};
            this.dynamicNetworkColumnRenderers[tab.name] = {};
            this.dynamicNetworkColumnCallbacks[tab.name] = {};

            for(let i in tab.columns) {
                if(tab.columns[i].display == 'value' ||
                    tab.columns[i].display == 'enum_value') {

                    let columnClass = `${generateKebabCaseID(tab.columns[i]['data'])}-column`
                    this.dynamicNetworkColumnClasses[tab.name][i] = columnClass;
                    this.dynamicNetworkColumnRenderers[tab.name][columnClass] = this.renderValueColumn;
                    this.dynamicNetworkColumnCallbacks[tab.name][columnClass] = this.valueColumnCallback;
                } else if (tab.columns[i].display == 'name') {
                    this.dynamicNetworkColumnClasses[tab.name][i] = 'name-col';
                } else if (tab.columns[i].display == 'link') {
                    this.dynamicNetworkColumnRenderers[tab.name][i] = 'link';
                }
            }
        }

        this.$nextTick(() => {
            this.dynamicTabsReady = true;
        });
    },
    beforeDestroy: function() {
        clearInterval(this.interval);
    },
    mounted() {
    const urlParams = getUrlParams(window.location.search);

    if (urlParams.port) {
        if (window.location.hash !== 'network-ports') {
            this.$broadcast('panels:set_active_panel_by_id', 'network-ports');
        }
    }
}
});
</script>
