const DEFAULT_HIDDEN_HTML_CLASS = 'pa-isHidden';

const dialogClose = {
    params: ['filterText', 'hiddenHtmlClass'],

    getHiddenHTMLClass() {
        return this.params.hiddenHtmlClass || DEFAULT_HIDDEN_HTML_CLASS;
    },

    bind() {
        this.onUIFilterChanged = this.onUIFilterChanged.bind(this);

        this.vm.$on('uiFilter:changed', this.onUIFilterChanged);
    },

    unbind() {
        this.vm.$off('uiFilter:changed', this.onUIFilterChanged);
    },

    show() {
        this.el.classList.remove(this.getHiddenHTMLClass());
    },

    hide() {
        this.el.classList.add(this.getHiddenHTMLClass());
    },

    onUIFilterChanged(details) {
        const model = details.model;

        // If nothing selected, show everything
        if (model.length === 0) {
            this.show();

            return;
        }

        // Iterate through filter text, looking to see if any are included in the model vars
    	var modelContainsFilterText = false;
    	for (var i=0; i<this.params.filterText.length; i++) {
    	    if (model.indexOf(this.params.filterText[i]) !== -1) {
        		modelContainsFilterText = true;
        		break;
    	    }
    	}

        if (modelContainsFilterText) {
            this.show();

            return;
        }

        this.hide();
    }
};

export default dialogClose;
