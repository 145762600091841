<style lang="scss">
    .typeahead-wrapper {
        height: initial;
        min-height: 33px;
        max-height: 140px;
        padding: 3px;
        overflow-x: hidden;
        overflow-y: auto;

        &.disabled {
            background: #eee;
        }
    }
    .real-input {
        line-height: inherit;
        height: 100%;
        border: none;
        outline: none;
        
        font-size: 15px;
        margin-left: 5px;
        margin-bottom: 5px;
    }
    .disabled .real-input {
        background: #eee;
        cursor: not-allowed;
    }
    .selection-block {
        align-items: center;
        display: inline-flex;
        padding: 5px;
        margin-left: 3px;
        margin-bottom: 5px;
        border-radius: 2px;
        background: #c8cfed;
        color: #21316f;
        font-size: 12px;
    }
    
        .selection-block svg.pa-icon {
            fill: #21316f;
            margin-left: 4px;
        }
    .greyedOut {
        color: #999999 !important;
    }
    .placeholder-text {
        color: #aaa;
        text-align: left;
        padding: 0 5px;
        cursor: text;
        user-select: none;
        padding-top: 4px;
        font-size: 13px;
    }
    .truncate-text {
        display: inline-block;
        font-size: 14px;
        color: #aaa;
        margin-left: 5px;
    }

    .v2 {
        height: 33px;
        min-width: 250px;

        .typeahead-wrapper {
            background: white;
            height: 33px;
            min-height: unset;
            padding-right: calc(2rem + 2px);
            position: relative;

            .chevron {
                height: 2rem;
                position: absolute;
                right: 2px;
                top: 50%;
                transform: translateY(-50%);
                width: 2rem;
            }

            .selection-block {
                margin-left: 10px;
                padding: 5px;
            }

            .real-input {
                min-width: 50px;
                width: 50px;
            }

            &:focus {
                .real-input {
                    width: auto;
                }
            }

            &.truncated .real-input {
                min-width: 0;
                width: 0;
            }
        }
        
        .pa-select-list {
            font-size: 0.8rem;
            
            .scrollable {
                max-height: 24rem;
            }

            .pa-select-list-hdg {
                border: none;
                color: #c5c4c6;
                
                font-size: 0.9em;
                height: unset;
                padding: 1em 0.8rem;
            }

            .pa-select-list-item {
                background: white;
                border: none;
                padding: .45em 1em;

                .pa-option {
                    margin-right: 1em;
                    vertical-align: text-bottom;
                }
            }
        }
    }
</style>

<template>
    <div class="typeahead-root" :class="version">
        <div ref="inputWrapper"
                class="pa-input typeahead-wrapper"
                :class="{ 'disabled': disabled, 'truncated': !isActive && truncateIndex !== null }"
                @click="focusInput">
            <template v-if="showSelection">
                <div v-for="model in modelLabels" class="selection-block">
                    {{ model.label }}
                    <svg @click.stop="removeModel(model.value)" class="pa-icon" style="cursor: pointer;">
                        <use xlink:href="#close"></use>
                    </svg>
                </div>
                <p v-if="!isActive && truncateIndex !== null" class="truncate-text" @click.stop="focusInput">
                    Plus {{ model.length - modelLabels.length }} more
                </p>
            </template>
            <div v-show="!showSelection || (!disabled && !model.length && !filterText && !inputFocused)" class="placeholder-text">
                <p>{{ placeholder }}</p>
            </div>
            <input v-model="filterText" ref="input"
                v-show="showSelection && (model.length || filterText || inputFocused)"
                @focus="inputFocused = true;"
                type="text" class="real-input" />
            <template v-if="version === 'v2'">
                <svg class="pa-icon chevron" :class="{ isActive: isActive }" @click.stop="toggle">
                    <use xlink:href="#chevron-down"></use>
                </svg>
            </template>
        </div>
        <div class="pa-select" multiple style="display: none;">
            <select multiple></select>
        </div>
        <div
            tabindex="-1"
            ref="popup"
            :class="{
                'isActive': isActive
            }"
            class="pa-select-list"
            :style="coords">
            <div class="scrollable">
                <template
                    v-for="(optgroup, optgroupIndex) in filteredOptGroups">
                    <div v-show="optgroup.show" class="pa-select-list-hdg">
                        {{ optgroup.label }}
                    </div>
                    <button
                        v-for="(option, optionIndex) in optgroup.options"
                        v-show="option.show"
                        v-on:click="select(option.value)"
                        class="pa-select-list-item"
                        type="button"
                        :title="option.label"
                        :disabled="version !== 'v2' && modelContains(option.value)"
                        :class="{
                            'greyedOut': modelContains(option.value),
                        }">
                        <label v-if="version === 'v2'" class="pa-option">
                            <input class="pa-option-input" type="checkbox" :checked="modelContains(option.value)"/>
                            <span @click.stop="" class="pa-option-icon"></span>
                        </label>
                        <template v-if="option.icon">
                            <svg class="pa-icon pa-icon_r"><use :xlink:href="`#${option.icon}`"></use></svg>
                        </template>
                        {{ option.label }}
                    </button>
                </template>
                <button
                    v-for="option in _filterOptionsByText(options, filterText)"
                    v-on:click="select(option.value)"
                    class="pa-select-list-item"
                    type="button"
                    :title="option.label"
                    :disabled="version !== 'v2' && modelContains(option.value)"
                    :class="{
                        'greyedOut': modelContains(option.value),
                    }">
                    <label v-if="version === 'v2'" class="pa-option">
                        <input class="pa-option-input" type="checkbox" :checked="modelContains(option.value)"/>
                        <span @click.stop="" class="pa-option-icon"></span>
                    </label>
                    <template v-if="option.icon">
                        <svg class="pa-icon pa-icon_r"><use :xlink:href="`#${option.icon}`"></use></svg>
                    </template>
                    {{ option.label }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import debounce from './../utils/debounce';
    import { stringContains } from './../utils/filterUtils';
    import createScrollLocker from '../utils/scrollLocker';
    import popupPositioner from '../utils/popupPositioner';
    import isComponentInModal from '../utils/isComponentInModal';

    const TypeAheadSelect = Vue.extend({
        beforeDestroy() {
            this.disable();
        },

        data() {
            return {
                calculatedSizing: false,
                coords: {},
                isActive: false,
                filterText: '',
                focusIndex: -1,
                inputFocused: false,
                truncateIndex: null,
            };
        },

        props: {
            options: {
                type: Array,
                'default': () => [],
            },

            optgroups: {
                type: Array,
                'default': () => [],
            },

            model: {
                type: Array,
                'default': function() {
                    return [];
                },
            },

            multiple: {
                type: Boolean,
                'default': true,
            },

            size: String,

            minHeight: Number,

            maxWidth: Number,

            required: Boolean,

            forceInModal: Boolean,

            id: String,

            placeholder: String,

            showSelection: {
                type: Boolean,
                'default': true,
            },

            onChangeCallback: {
                type: Function,
                'default': () => {},
            },

            disabled: {
                type: Boolean,
                'default': false,
            },

            version: {
                type: String,
                'default': 'v1',
            },

            includeGroupLabel: {
                type: Boolean,
                'default': true,
            },

            limit: {
                type: Number,
                default: null,
            },
        },

        events: {
            "type-ahead-dropdown:deactivate"() {
                this.isActive = false;
                this.inputFocused = false;
            }
        },

        computed: {
            /* eslint-disable no-param-reassign */
            filteredOptGroups: function() {
                // Filtering logic
                const groups = [];
                this.optgroups.forEach(og => {
                    const group = Object.assign({}, og);
                    group.options = [];
                    if (this.stringContains(group.label, this.filterText)) {
                        group.show = true;
                        og.options.forEach(o => {
                            const opt = Object.assign({}, o);
                            opt.show = true;
                            group.options.push(opt);
                        });
                        groups.push(group);
                        return;
                    }
                    let containsMatch = false;
                    og.options.forEach(o => {
                        const opt = Object.assign({}, o);
                        if (this.stringContains(o.label, this.filterText)) {
                            opt.show = true;
                            containsMatch = true;
                        } else {
                            opt.show = false;
                        }
                        group.options.push(opt);
                    });
                    if (containsMatch) {
                        group.show = true;
                    } else {
                        group.show = false;
                    }
                    groups.push(group);
                });
                return groups;
            },

            /* eslint-enable no-param-reassign */
            modelLabels: function() {
                const retVal = [];
                this.model.forEach(m => {
                    const val = { value: null };
                    if (this.options.length) {
                        const match = this.options.find(o => {
                            return o.value === m;
                        });
                        if (match) {
                            if (match.selected_label) {
                                val.label = match.selected_label;
                            } else {
                                val.label = match.label;
                            }
                            val.value = match.value;
                        } else {
                            // console.log(`Found model value "${m}" with no matching option`);
                            val.label = m;
                            val.value = m;
                        }
                        retVal.push(val);
                    } else if (this.optgroups.length) {
                        const found = this.optgroups.some(og => {
                            const match = og.options.find(o => {
                                return o.value === m;
                            });
                            if (match) {
                                if (match.selected_label) {
                                    val.label = `${match.selected_label}`;
                                } else {
                                    val.label = `${match.label}`;
                                }
                                if (this.includeGroupLabel) {
                                    val.label = `${og.label}: ${val.label}`;
                                }
                                val.value = match.value;
                                return true;
                            }
                            return false;
                        });
                        if (!found) {
                            // We didn't find a match in any optgroup
                            val.label = m;
                            val.value = m;
                        }
                        retVal.push(val);
                    } else {
                        val.label = m;
                        retVal.push(val);
                    }
                });

                if (!this.isActive && this.truncateIndex !== null) {
                    return retVal.slice(0, this.truncateIndex);
                }

                return retVal;
            },
        },

        watch: {
            filterText: function() {
                this.focusIndex = -1;
            },
            inputFocused: function(val, oldVal) {
                if (val) {
                    this.open();
                } else {
                    this.close();
                }
            },
            model() {
                requestAnimationFrame(this.checkMaxWidth);
            },
        },

        methods: {
            enable() {
                window.addEventListener('resize', this.debouncedOnResize);
            },

            disable() {
                window.removeEventListener('resize', this.debouncedOnResize);
            },

            toggle() {
                if (this.isActive) {
                    this.close();
                } else {
                    this.open();
                }
            },

            open() {
                if (this.isActive) {
                    this.close();

                    return;
                }

                this.getPopupSizing();
                const options = {
                    popupWidth: this.popupWidth,
                    popupHeight: this.popupHeight,
                };
                if (this.minHeight) {
                    options.minHeight = this.minHeight;
                }
                if (this.maxHeight) {
                    options.maxHeight = this.maxHeight;
                }
                let coords = popupPositioner.getPopupStyle(this.$el, options);

                // TODO: Can we do this from getPopupSizing()?
                if (this.stickySelected && coords.bottom) {
                    const stickyHeight = this.$refs.stickyBox.clientHeight;
                    const original = Number(coords.bottom.replace('px', ''));
                    coords.bottom = `${original + stickyHeight}px`;
                }

                this.isActive = true;
                this.coords = Object.assign({ width: `${this.$el.offsetWidth}px` }, coords);
                this.scrollLocker.on();
                this.eventHub.$emit('scroll-lock:on', this);

                document.addEventListener('click', this.maybeBlur);
                document.addEventListener('keydown', this.handleKeypress);
            },

            close() {
                this.filterText = '';
                this.inputFocused = false;
                this.isActive = false;
                this.scrollLocker.off();
                this.eventHub.$emit('scroll-lock:off', this);
                document.removeEventListener('click', this.maybeBlur);
                document.removeEventListener('keydown', this.handleKeypress);
            },

            handleKeypress: function(event) {
                if (event.which === 40) {
                    event.preventDefault();
                    this.focusNext();
                } else if (event.which === 38) {
                    event.preventDefault();
                    this.focusPrev();
                } else if (event.which === 27) {
                    event.stopImmediatePropagation();
                    this.close();
                } else if (event.which >= 65 && event.which <= 90) {
                    this.$refs.input.focus();
                } else if (event.which === 8) {
                    // Backspace
                    this.$refs.input.focus();
                }
            },

            select(value) {
                if (this.model.length === this.limit) {
                    // Remove the last one
                    this.model.splice(this.model.length - 1, 1);
                }

                if (this.version === 'v2' && this.model.includes(value)) {
                    const index = this.model.indexOf(value);
                    if (index > -1) {
                        this.model.splice(index, 1);
                        this.onChangeCallback(this.model);
                    }
                    return;
                }

                this.model.push(value);
                this.onChangeCallback(this.model);

                if (this.version !== 'v2') {
                    this.close();
                }
            },

            removeModel(value) {
                const index = this.model.indexOf(value);
                if (index > -1) {
                    this.model.splice(index, 1);
                }
                this.onChangeCallback(this.model);
            },

            stringContains(input, match) {
                return input.toLowerCase().includes(match.toLowerCase());
            },

            modelContains(value) {
                if (!this.multiple) {
                    // using the loose equality checker to
                    // account for numbers being compared to strings (e.g. 1 to '1')
                    return value == this.model;
                }

                return this.model.filter((item) => {
                    return item == value;
                }).length > 0;
            },

            optionContainsText(option = {}, text = '') {
                if (text.trim() === '') {
                    return true;
                }

                return stringContains(option.label, text);
            },

            optgroupContainsText(optgroup = [], text = '') {
                if (text.trim() === '') {
                    return true;
                }

                return optgroup.options.filter((option) => {
                    return this.optionContainsText(option, text);
                }).length > 0;
            },

            getIndexOf(value) {
                let index = -1;

                this.model.some((item, i) => {
                    const match = item == value;

                    if (match) {
                        index = i;
                    }

                    return match;
                });

                return index;
            },

            _filterOptgroupsByText(optgroups = [], text = '') {
                if (text.trim() === '') {
                    return optgroups;
                }

                return optgroups.filter(optgroup => {
                    return this.optgroupContainsText(optgroup, text);
                });
            },

            _filterOptionsByText(options = [], text = '') {
                if (text.trim() === '' && !this.stickySelected) {
                    return options;
                }

                if (this.stickySelected) {
                    return options.filter(option => {
                        return (this.optionContainsText(option, text) &&
                            !this.modelContains(option.value));
                    });
                } else {
                    return options.filter(option => {
                        return this.optionContainsText(option, text);
                    });
                }
            },

            checkMaxWidth() {
                if (!this.maxWidth) { return; }

                if (this.truncateIndex !== null && this.model.length <= this.truncateIndex) {
                    this.truncateIndex = null;
                } else if (this.$refs.inputWrapper.offsetWidth > this.maxWidth
                        && this.truncateIndex === null) {
                    this.truncateIndex = this.model.length - 1;
                }
            },

            getPopupSizing() {
                if (this.calculatedSizing) {
                    return;
                }

                const popupClientRect = this.$refs.popup.getBoundingClientRect();
                const popupWidth = popupClientRect.width;
                let popupHeight = popupClientRect.height;

                if (this.stickySelected) {
                    const stickyHeight = this.$refs.stickyBox.clientHeight;
                    popupHeight += stickyHeight;
                }

                this.popupHeight = popupHeight;
                this.popupWidth = popupWidth - this.$el.offsetWidth;
                this.calculatedSizing = true;
            },

            onResize(event) {
                this.close();
            },

            maybeBlur(event) {
                if (!this.$el.contains(event.target)) {
                    if (!this.inputFocused) {
                        this.close();
                        return;
                    }

                    this.inputFocused = false;
                }
            },

            focusInput() {
                // First make sure input is visible, then focus
                this.inputFocused = true;
                Vue.nextTick(() => {
                    this.$refs.input.focus();
                });
            },

            focusNext() {
                const buttons = this.$el.querySelectorAll('button.pa-select-list-item:visible');
                if (buttons.length >= this.focusIndex + 1) {
                    this.focusIndex += 1;
                    buttons[this.focusIndex].focus();
                }
            },

            focusPrev() {
                if (this.focusIndex > 0) {
                    this.focusIndex -= 1;
                    const buttons = this.$el.querySelectorAll('button.pa-select-list-item:visible');
                    if (buttons.length >= this.focusIndex) {
                        buttons[this.focusIndex].focus();
                    }
                }
            },
        },

        directives: {
            focus: {
                update: function(val, oldVal) {
                    if (Boolean(val) === Boolean(oldVal)) {
                        return;
                    }

                    if (Boolean(val)) {
                        this.el.focus();
                    } else {
                        this.el.blur();
                    }
                },
            },
        },

        vueReady() {
            const inModal = this.forceInModal || isComponentInModal(this);
            this.scrollLocker = createScrollLocker({ inModal: inModal });
            this.debouncedOnResize = debounce(this.onResize, 400, true);

            this.enable();

            if (this.maxWidth) {
                // We need to add each existing item
                // one by one until we find the break index
                const inModel = this.model.slice();
                this.$emit('update:model', []);
                for (const entry of inModel) {
                    if (this.truncateIndex !== null) {
                        // Already found, just add
                        this.model.push(entry);
                    } else {
                        // Vue.nextTick seems less reliable for this
                        window.setTimeout(() => {
                            this.model.push(entry);
                        }, 400);
                    }
                }
            }
        },
    });

    export default TypeAheadSelect;
</script>
