const BATCH_NUM = 5;
const CUTOFF = 2;

class GraphManager {
    constructor(batchNum, cutoff) {
        this.graphQueue = [];
        this.batchNum = batchNum || BATCH_NUM;
        this.cutoff = cutoff || CUTOFF;
        this.timeout = null;

        this._fetchData = this._fetchData.bind(this);
        this.fetchData = this.fetchData.bind(this);

        console.debug('GraphManager initialized');
    }

    _fetchData() {
        clearTimeout(this.timeout);
        this.timeout = null;

        const configs = [];
        const callbacks = [];
        this.graphQueue.forEach(g => {
            configs.push(g[0]);
            callbacks.push(g[1]);
        });
        this.graphQueue.length = 0;

        $.ajax({
            url: '/report/batched_graph_data',
            data: {
                configs: JSON.stringify(configs),
            },
        })
        .done(data => {
            for (let i = 0; i < data.data.length; i++) {
                const graphData = data.data[i];
                callbacks[i](graphData);
            }
        });
    }

    fetchData(config, callback) {
        this.graphQueue.push([config, callback]);
        if (this.graphQueue.length >= this.batchNum) {
            this._fetchData();
        } else if (!this.timeout) {
            this.timeout = setTimeout(this._fetchData, this.cutoff * 1000);
        }
    }
}

export default GraphManager;
