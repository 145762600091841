<style lang="scss">
@import "~styles/core/helpers/_variables.scss";

:root {
    --side-nav-expanded-width: 220px;
    --side-nav-collapsed-width: 65px;
}

.pa-side-nav {
    background-color: #243746;
    display: grid;
    grid-template-rows: auto 1fr auto;
    overflow: hidden;
    height: 96vh;
    width: var(--side-nav-expanded-width);
    transition: width .4s ease-in-out;
    white-space: nowrap;
    overflow-x: hidden;

    .pa-navigation-action {
        &__text {
            // Helps with layout shift when hovering text and changing to bold
            &:before {
                display: block;
                content: attr(title);
                @include font-bold;
                height: 0;
                overflow: hidden;
                visibility: hidden;
            }
        }

        &__menu {
            &--active-child {
                & > .pa-navigation-action__menu-items {
                    display: block;
                }
            }

            &-flyout-title {
                color: #b3b3b3;
                padding: 8px 0 4px 18px;
            }

            &-flyout-divider {
                & > .pa-hr {
                    color: #b3b3b3;
                    background-color: #b3b3b3;
                    margin: 0;
                }
            }

            .pa-navigation-action__menu-trigger {
                border-left: 4px solid transparent;
            }

            &.pa-navigation-action__menu--active-child {
                .pa-navigation-action__menu-trigger-button {
                    .pa-navigation-action__text {
                        color: white;
                    }

                    .pa-navigation-action__icons .pa-icon {
                        fill: white;
                    }
                }

                .pa-navigation-action__menu-trigger {
                    border-left-color: white;
                }
            }

            .pa-navigation-action__menu-trigger button {
                background: none;
                border: none;
                display: flex;
                align-items: center;
                gap: 4px;

                & .pa-navigation-action__text {
                    color: #b3b3b3;
                    font-size: 14px;
                    opacity: 1;
                    transition: opacity .4s ease-in-out;
                    display: inline-block;
                    text-align: left;
                    padding-left: 3px;
                }

                &:hover {
                    .pa-navigation-action__icons.pa-navigation-action__icons--left svg.pa-icon{
                        fill: #ffffff;
                        color: #ffffff;
                    }

                    .pa-navigation-action__text {
                        color: #ffffff;
                        cursor: pointer;

                    }
                }

                &.open {
                    .pa-navigation-action__icons.pa-navigation-action__icons--left svg.pa-icon{
                        fill: #ffffff;
                        color: #ffffff;
                    }
                    
                    .pa-navigation-action__text {
                        color: #ffffff;
                    }
                }
            }

            .pa-navigation-action.pa-navigation-action__link {
                display: block;
                margin: 10px 5px 0 15px;
                padding: 5px 15px 5px 5px;

                & a {
                    padding: 8px;
                }
            }
        }

        &-item {
            position: relative;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            .pa-navigation-action-item__button {
                background-color: #FFFFFF;
                padding: 5px;
                border-radius: 50%;
                
                .pa-btn {
                    color: #000000 !important;
                }

                &:hover {
                    background-color: #30475A;

                    .pa-btn {
                        color: #FFFFFF !important;
                    }
                }  
            }

            &:hover {
                .pa-navigation-action-item__button button .pa-icon {
                    fill: #ffffff;
                    color: #ffffff;
                }
            }

            & .pa-navigation-action__text {
                color: #b3b3b3;
                font-size: 14px;
                opacity: 1;
                transition: opacity .4s ease-in-out;
                display: inline-block;
                text-align: left;
                padding-left: 14px;
            }

            &--active {

                .pa-navigation-action.pa-navigation-action__link {
                    border-radius: 14px;
                    background-color: #30475A;
                    width: 100%;
                    text-align: left;
                }
                
                .pa-navigation-action__text {
                    color: #ffffff;
                    @include font-bold;
                }
            }

            &__badge {
                margin-left: 4px;
                vertical-align: top;

                &.collapsed {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }

        &_menu-item {
            & .pa-navigation-action-item div:hover {
                .pa-navigation-action.pa-navigation-action__link {
                    border-radius: 14px;
                    background-color: #30475A;
                    width: 100%;
                    text-align: left;

                    & .pa-navigation-action__text {
                        color: #ffffff;
                        @include font-bold;
                    }
                }
            } 
        }
    }

    .pa-side-nav__top-level-item {
        .pa-navigation-action__menu-items {
            display: none;
        }

        &--open {
            .pa-navigation-action__menu-items {
                display: block;
            }
        }
    }

    .pa-navigation-action__icons {
        display: inline;

        .pa-icon ~ .pa-icon {
            margin-left: 4px;
        }
    }

    &--collapsed {
        width: var(--side-nav-collapsed-width);

        .pa-side-nav__top-level-item {
            &--open {
                .pa-navigation-action__menu-items {
                    display: none;
                }
            }
        }

        .pa-navigation-action__menu-items {
            display: none;
        }

        .pa-navigation-action__menu {
            &-flyout-title {
                display: block;
            }

            &-flyout-divider {
                display: block;
            }

            &-items {
                background-color: #243746;

                .pa-navigation-action {
                    &.pa-navigation-action__link {
                        margin: 0 5px 0 5px;
                    }
                }

                .pa-navigation-action_menu-item {
                    padding-top: 5px;
                }

                .pa-navigation-action_menu-item ~ .pa-navigation-action_menu-item {
                    padding-top: 0;
                }

                .pa-navigation-action_menu-item:last-of-type {
                    padding-bottom: 5px;
                }
            }

            &:hover {
                .pa-navigation-action__menu-items {
                    display: block;
                    position: fixed;
                    left: 0;
                    top: 0;
                    z-index: 900;

                    &:after {
                        content: '';
                        display: block;
                        width: 0;
                        height: 0;
                        position: absolute;
                        border-top:  8px solid transparent;
                        border-bottom:  8px solid transparent;
                        border-right: 8px solid #243746;
                        left: -8px;
                        top: 7px;
                    }

                    &:before {
                        content: '';
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0px;
                        margin-left: -25px;
                        margin-top: -5px;
                        padding-right: 25px;
                        padding-bottom: 5px;
                    }
                }

                .pa-navigation-action__icons.pa-navigation-action__icons--left svg.pa-icon {
                    fill: #ffffff;
                    color: #ffffff;
                }

            }

            .pa-navigation-action__menu-trigger {
                .pa-navigation-action-item__badge {
                    position: absolute;
                    top: -8px;
                    right: 16px;
                }

                & .pa-navigation-action__menu-trigger-button {
                    & .pa-navigation-action__text {
                        width: 0;
                        opacity: 0;
                    }
                }
            }
        }
    }

    .pa-side-nav__footer {
        align-items: center;
        margin-left: 10px;

        &.no_add_btn {
            margin-left: 9.5rem;
        }

        &.collapsed_state {
            align-items: flex-start;
        }

        &_add {
            color: #b3b3b3;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 14px;

            &:hover {
                color: white;

                .pa-icon {
                    fill: white;
                }
            }

            &.large_icon {
                font-size: 24px;
            }
        }

        &_collapse {
            cursor: pointer;
            background: none;
            border: none;

            &:hover {
                .pa-icon {
                    fill: white;
                }
            }
        }
    }

    &__top-level-items {
        margin-left: 5px;

        .pa-side-nav__top-level-item {
            position: relative;
        }
    }
}

@media screen and (max-height: 900px) {
    .pa-side-nav__footer {
        margin-bottom: 10px;
    }
}

@media screen and (max-height: 749px) {
    .pa-side-nav__footer {
        margin-bottom: 15px;
    }
}

@media screen and (max-height: 1045px) {
    .pa-side-nav {
        height: 95vh;
    }
}
</style>


<template>
    <aside :class="className">
        <p-side-nav-header
            :logo-link="logoLink"
            :logo="logo"
            :logo-small="logoSmall"
            :is-collapsed="isCollapsed"
            :brand-name="brandName"
            :customer-name="customerName"
            :can-switch-customer="canSwitchCustomer"
        >
        </p-side-nav-header>

        <p-side-nav-top-level-items
            :nav-items="computedNavItems"
            :is-collapsed="isCollapsed"
            @menu:toggled="handleMenuToggled"
            @item:active="handleItemActive"
        >
        </p-side-nav-top-level-items>
        <p-divider></p-divider>
        <p-side-nav-footer
            :is-collapsed="isCollapsed"
            @toggle-collapse="toggleCollapse"
            :show-add-btn="showAddBtn"
        >
        </p-side-nav-footer>
    </aside>
</template>

<script setup>
import { computed, nextTick, onMounted, onUnmounted, ref, reactive, watch, onBeforeMount } from "vue";
import { matchesWindowUrl, getOpenNavItemMenus } from "../shared/navUtils";
import favicon from "../../shared/utils/favicon";
import { SIDE_NAV_COLLAPSED } from "../../shared/constants/localStorageKeys";
import PSideNavHeader from './SideNavHeader.vue';
import PSideNavFooter from './SideNavFooter.vue';
import PSideNavTopLevelItems from './SideNavTopLevelItems.vue';
import axios from 'axios';

const props = defineProps({
  navItems: Array,
  logoLink: String,
  logo: Object,
  logoSmall: Object,
  collapsed: Boolean,
  brandName: String,
  customerName: String,
  showAddBtn: [Boolean, true],
  canSwitchCustomer: Boolean,
});

const isCollapsed = ref(props.collapsed);
const openMenus = ref([]);
const navItems = ref(props.navItems || []);
const countsObject = reactive({
    maintenance_Count: 0,
    incident_Count: 0,
});

const instanceDrawerOpen = ref(false);

window.app.eventHub.$on("drawer-open", (id) => {
    if (id === "instance-drawer") {
        instanceDrawerOpen.value = true;
    }
});

window.app.eventHub.$on("drawer-close", (id) => {
    if (id === "instance-drawer") {
        instanceDrawerOpen.value = false;
    }
});

const saveCollapseStateLocalStorage = (value) => {
    window.app.localStorageManager.setNumber(SIDE_NAV_COLLAPSED, value);
};

const setCollapsedStateFromLocalStorage = () => {
    isCollapsed.value = Boolean(window.app.localStorageManager.getNumber(SIDE_NAV_COLLAPSED, false));
};

const updateBodyClassName = (value) => {
    if (value) {
        document.body.classList.add('side-nav--collapsed');
    } else {
        document.body.classList.remove('side-nav--collapsed');
    }
};

watch(isCollapsed, (curr) => {
    updateBodyClassName(curr);
    saveCollapseStateLocalStorage(+curr);
});

watch(() => countsObject.incident_Count, (incidentCount) => {
    if (!incidentCount) {
        favicon.reset();
    } else {
        favicon.badge(incidentCount);
    }
});

const handleItemActive = (data) => {
    activeNavId.value = data.id;
};

const handleMenuToggled = (data) => {
    openMenus.value = [data.id];
};

const toggleCollapse = () => {
    isCollapsed.value = !isCollapsed.value;
    window.app.rootVue.$broadcast("left-nav-shift");
};

const className = computed(() => ([
    "pa-side-nav",
    "pa-txt_sm",
    isCollapsed.value ? "pa-side-nav--collapsed" : "",
    instanceDrawerOpen.value ? "pa-side-nav--instance-drawer--open" : "",
].join(" ")));

const isItemActiveAndSetDefaultItem = (item) => {
    return matchesWindowUrl(item.href, item.to);
};

const isItemOpen = (item) => {
    return openMenus.value.includes(item.id);
};
const getNavItemBadge = (navItem, parentNavItem) => {
    const parentNavOpen = parentNavItem && parentNavItem.open;

    let badge = null;
    let incidentBadges = "";
    let maintenanceBadges = "";

    if (parentNavOpen) {
        incidentBadges = "menu.incidents.all";
    } else if (!navItem.open || isCollapsed.value) {
        incidentBadges = "menu.incidents";
    }

    if (parentNavOpen) {
        maintenanceBadges = "menu.maintenances.active_upcoming";
    } else if (!navItem.open || isCollapsed.value) {
        maintenanceBadges = "menu.maintenance";
    }

    if (incidentBadges === navItem.id && countsObject.incident_Count > 0) {
        badge = {
            text: countsObject.incident_Count,
            props: {
                title: `${countsObject.incident_Count} active incidents`,
                variant: 'nav--red',
            },
        };
    }

    if (maintenanceBadges === navItem.id && countsObject.maintenance_Count > 0) {
        badge = {
            text: countsObject.maintenance_Count,
            props: {
                title: `${countsObject.maintenance_Count} under maintenance`,
                variant: 'nav--yellow',
            },
        };
    }

    return badge;
};

const mapNavItem = (navItem, parentNavItem) => {
    const _navItem = {
        ...navItem,
        open: isItemOpen(navItem),
        items: [],
        badge: null,
    };

    _navItem.badge = getNavItemBadge(_navItem, parentNavItem);

    if (navItem.items) {
        _navItem.items = navItem.items.map((item) => mapNavItem(item, _navItem));
    }

    return _navItem;
};

const computedNavItems = computed(() => navItems.value.map((item) => mapNavItem(item)));

const setDefaultOpenMenus = () => {
    openMenus.value = getOpenNavItemMenus(navItems.value).map(navItem => navItem.id);
};

const prepareNavItems = () => {
    navItems.value = navItems.value.map((navItem) => {
        const isActive = isItemActiveAndSetDefaultItem(navItem);
        const items = navItem.items ? navItem.items.map((item) => {
            const secondaryNavItems = item.secondary_nav_items
                ? item.secondary_nav_items.map((secondaryNavItem) => ({
                    ...secondaryNavItem,
                    active: isItemActiveAndSetDefaultItem(secondaryNavItem),
                }))
                : [];
            const secondaryActive = secondaryNavItems.some(
                secondaryNavItem => secondaryNavItem.active
            );

            return {
                ...item,
                active: isItemActiveAndSetDefaultItem(item),
                secondaryActive,
                secondary_nav_items: secondaryNavItems,
            }
        }) : [];
        const hasActiveChild = items.some(item => item.active || item.secondaryActive);

        return {
            ...navItem,
            active: isActive,
            items,
            hasActiveChild,
        }
    });
};

const getOutageMaintCount = async () => {
    axios.get('/util/outage_maintenance_count',
        { params: this }).then(response => {
            //Even though its a login redirect the response.status is 200
            //Lets check if the response is a html string and contains the login
            //component, if so refresh the page.
            if ((typeof response.data === 'string' ||
                response.data instanceof String) &&
                response.data.includes('</p-auth>'))
            {
                    window.location.reload();
            }

            if (response.status == 200) {
                countsObject.incident_Count = response.data.num_outages;
                countsObject.maintenance_Count = response.data.num_maintenance;
            }
        });
};

let incidentInterval = null;

onBeforeMount(() => {
    setCollapsedStateFromLocalStorage();
});

onMounted(async () => {
    await getOutageMaintCount();

    prepareNavItems();

    nextTick(() => {
        setDefaultOpenMenus();
    });
    
    incidentInterval = setInterval(async () => {
        await getOutageMaintCount();
    }, 30000);
});

onUnmounted(() => {
  clearInterval(incidentInterval);
});
</script>
