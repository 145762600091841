<style lang="scss">
@mixin reset-button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    line-height: 1;
}

$defaultLinkColor: #394449;
$activeLinkColor: #3954BF;

.pa-secondary-nav {
    background-color: white;
    border: 1px solid #E6E6E6;
    margin-right: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;

    word-break: break-word;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    flex-grow: 0;
    flex-shrink: 0;

    height: 85vh;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
    width: 180px;

    a.pa-navigation-action__link {
        color: $defaultLinkColor;
    }

    .pa-navigation-action__app-link .app-link {
        color: $defaultLinkColor;
    }

    .pa-navigation-action__icons {
        display: inline;

        .pa-icon {
            color: #3954BF;
            fill: #3954BF;
        }

        .pa-icon ~ .pa-icon {
            margin-left: 4px;
        }
    }

    .pa-navigation-action__tab-button {
        @include reset-button;
    }

    .pa-navigation-action__menu-trigger-button {
        @include reset-button;
    }

    &__top-level-item {
        .pa-navigation-action-item {
            position: relative;
            display: flex;
            align-items: center;
            border-left: 2px solid transparent;
            padding-left: 14px;
            padding-top: 8px;
            padding-bottom: 6px;

            &--active {
                border-left-color: #3954BF;
                background-color: #CCE5FF;

                .pa-navigation-action__tab-button {
                    color: #3954BF;
                }

                a.pa-navigation-action__link {
                    color: $activeLinkColor;
                }

                .pa-navigation-action__app-link .app-link {
                    color: $activeLinkColor;
                }
            }
        }

        &--menu {
            & > .pa-navigation-action-item {
                padding-left: 0;
                border-left: none;
                padding-bottom: 0;
            }

            .pa-navigation-action {
                &__menu-trigger {
                    padding-left: 16px;
                    padding-bottom: 6px;
                }
            }

            .pa-navigation-action__menu-items .pa-navigation-action-item {
                padding-left: 19px;
            }
        }

        &--open {
            background-color: #F0F7FF;

            .pa-navigation-action__menu-trigger {
                .app-link {
                    color: $activeLinkColor;
                }
            }
        }
    }
}
</style>

<template>
    <aside class="pa-secondary-nav">
        <p-secondary-nav-context-header
            v-if="contextHeader"
            :icon="contextHeader.icon"
            :image="contextHeader.image"
            :title="contextHeader.title"
            :title-badge="contextHeader.titleBadge"
            :sub-title="contextHeader.subTitle"
            :tertiary-title="contextHeader.tertiaryTitle"
            :title-ext="titleExt"
        >
        </p-secondary-nav-context-header>

        <p-secondary-nav-top-level-items
            :nav-items="computedNavItems"
            @menu:toggled="handleMenuToggled"
            @item:active="handleItemActive"
        >
        </p-secondary-nav-top-level-items>
    </aside>
</template>

<script setup>
import { computed, watch, ref } from "vue";
import { matchesWindowUrl, getActiveNavItem } from "../shared/navUtils";
import PSecondaryNavContextHeader from "./SecondaryNavContextHeader.vue";
import PSecondaryNavTopLevelItems from "./SecondaryNavTopLevelItems.vue";

const props = defineProps({
  navItems: Array,
  contextHeader: Object,
  titleExt: Object || null,
});

const emit = defineEmits(["item:active", "menu:toggled"]);

const activeNavId = ref(null);

const handleItemActive = (data) => {
    activeNavId.value = data.id;
};

const handleMenuToggled = (data) => {
    emit("menu:toggled", data);
};

const isItemActiveAndSetDefaultItem = (item) => {
    if(item.default) {
        item.default = false;
        return true;
    }

    if (item.action === 'tab') {
        return activeNavId.value === item.id;
    }

    return matchesWindowUrl(item.href, item.to);
};

const mapNavItem = (navItem) => ({
    ...navItem,
    active: isItemActiveAndSetDefaultItem(navItem),
    items: navItem.items ? navItem.items.map(mapNavItem) : [],
});
const computedNavItems = computed(() => props.navItems.map(mapNavItem));

const activeItem = computed(() => getActiveNavItem(computedNavItems.value));
const activeItemId = computed(() => activeItem.value ? activeItem.value.id : null);

watch(activeItemId, () => {
    emit("item:active", activeItem.value);
});
</script>
