<style lang="scss" scoped>

   #bulk_tag {
        .pa-panel-bd {
            overflow: visible;
        }
    }

</style>

<template>
    <p-modal id="bulk_tag">
        <div class="pa-panel">
            <div class="pa-panel-hd">
                <h2 class="pa-hdg pa-hdg_3" slot="header">Add tags</h2>
            </div>
            <div class="pa-panel-bd" style="text-align: center;">
                <div class="pa-field">
                    <div class="pa-field-hd">
                        <label for="bulk-tags" class="pa-label">Entered tags will be applied to all selected instances</label>
                    </div>
                    <div class="pa-field-bd" style="text-align: left;">
                        <p-tags-input ref="bulktaggle" name="tags" :model.sync="bulkTags" :autocomplete="true" :suggestions="allTags"></p-tags-input>
                    </div>
                </div>
            </div>
            <div class="pa-panel-ft"  style="width: 100%">
                <ul class="pa-split">
                    <li></li>
                    <li>
                        <button class="pa-btn pa-btn_cancel" type="button" v-p-dialog-close target="bulk_tag">Cancel</button>
                        <button @click="bulkTag" class="pa-btn" value="Confirm" :disabled="bulkTagSpinner">
                            <p-icon
                                v-show="bulkTagSpinner"
                                icon="spinner-spin-light"
                                class="spin-8step"
                                style="margin-right: 5px"
                            >
                            </p-icon>
                            {{ bulkTagSpinner ? 'Saving...' : 'Confirm' }}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </p-modal>
</template>

<script>
import Vue from "vue";


export default Vue.extend({
    props: {
        serverId: Number,
        allTags: Array,
    },
    components: {},
    events: {},
    data: () => ({
        bulkTagSpinner: false,
        bulkTags: [],
        checked: null,
    }),
    watch: {
    },
    methods: {
        bulkTag: function() {
            this.bulkTagSpinner = true;
            var payload = {
                server_id: this.serverId,
                checked: this.checked.join(","),
                new_tags: this.bulkTags.join(","),
            };
            $.ajax("/config/monitoring/bulk_tag_metrics", {
                method: 'POST',
                data: payload,
                context: this
            })
            .done(data => {
                if (!data.success) {
                    console.log(data.msg)
                    return;
                }
                
                this.showToast(`Successfully tagged ${this.checked.length} metrics`);
                this.bulkTags = [];
                this.$refs.bulktaggle.deleteAllTags();
                window.app.rootVue.$broadcast('dialog:close', 'bulk_tag');
                this.bulkTagSpinner = false;
                window.app.rootVue.$broadcast('finished-bulk-action');
            });
        },

        setChecked: function(checked) {
            this.checked = checked;
        },

        showToast: function(text) {
            window.app.toastManager.clearToasts();
            window.app.toastManager.showToast(text);
        },
    },
    mounted() {},
    beforeDestroy() {},
});
</script>
