<style lang="scss">
    #standard-login {
        .remember-me {
            color: #494c4e;
            vertical-align: text-top;
        }

        .forgot-password {
            a:hover {
                text-decoration: underline;
            }
        }
    }
</style>

<template>
    <div id="standard-login">
        <form ref="form" action="/login_handler" method="POST">
            <input :value="previousUrl" name="came_from" type="hidden"/>
            <p-field-group name="username" label="Username/Email">
                <p-input
                    v-model="username"
                    theme="login"
                    name="login"
                    placeholder="Username/Email"
                    type="text">
                </p-input>
            </p-field-group>

            <p-field-group name="password" label="Password" class="pa-mt-40">
                <p-masked-input
                    v-model="password"
                    theme="login"
                    :enter="submit"
                    name="password">
                </p-masked-input>
            </p-field-group>

            <div class="pa-split" style="margin: 0;">
                <div>
                </div>
                <p class="pa-txt_16 forgot-password">
                    <a @click="forgotPassword" class="pa-clickable">Forgot Password</a>
                </p>
            </div>
        </form>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            previousUrl: String,
        },

        data() {
            return {
                username: '',
                password: '',
            };
        },

        computed: {
            filledIn() {
                return this.username && this.password;
            },
        },

        watch: {
            filledIn(val) {
                this.eventHub.$emit('login:filledIn', val);
            },
        },

        methods: {
            submit() {
                this.$refs.form.submit();
            },

            forgotPassword() {
                this.eventHub.$emit('auth:switchType', 'forgot_password');
            },
        },

        beforeDestroy() {
            this.eventHub.$emit('login:filledIn', false);
        },
    });
</script>
