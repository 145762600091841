<style>
@-webkit-keyframes kf_el_sFONSKqoZN_an_O7oGAKcqRG {
    53.89% {
        stroke-dasharray: 1, 64;
    }
    77.22% {
        stroke-dasharray: 66, 64;
    }
    0% {
        stroke-dasharray: 1, 64;
    }
    100% {
        stroke-dasharray: 66, 64;
    }
}
@keyframes kf_el_sFONSKqoZN_an_O7oGAKcqRG {
    53.89% {
        stroke-dasharray: 1, 64;
    }
    77.22% {
        stroke-dasharray: 66, 64;
    }
    0% {
        stroke-dasharray: 1, 64;
    }
    100% {
        stroke-dasharray: 66, 64;
    }
}
@-webkit-keyframes kf_el_sFONSKqoZN_an_SP19g__PD {
    52.78% {
        opacity: 0;
    }
    56.67% {
        opacity: 1;
    }
    77.22% {
        opacity: 1;
    }
    91.67% {
        opacity: 0;
    }
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@keyframes kf_el_sFONSKqoZN_an_SP19g__PD {
    52.78% {
        opacity: 0;
    }
    56.67% {
        opacity: 1;
    }
    77.22% {
        opacity: 1;
    }
    91.67% {
        opacity: 0;
    }
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes kf_el_sFONSKqoZN_an_RaZP_Mcup {
    0% {
        -webkit-transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
        transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
    }
    100% {
        -webkit-transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
        transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
    }
}
@keyframes kf_el_sFONSKqoZN_an_RaZP_Mcup {
    0% {
        -webkit-transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
        transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
    }
    100% {
        -webkit-transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
        transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
    }
}
@-webkit-keyframes kf_el_bLRiszdKqD_an_oRXwaEl47 {
    9.44% {
        opacity: 0;
    }
    13.33% {
        opacity: 1;
    }
    33.33% {
        opacity: 1;
    }
    47.78% {
        opacity: 0;
    }
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@keyframes kf_el_bLRiszdKqD_an_oRXwaEl47 {
    9.44% {
        opacity: 0;
    }
    13.33% {
        opacity: 1;
    }
    33.33% {
        opacity: 1;
    }
    47.78% {
        opacity: 0;
    }
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes kf_el_bLRiszdKqD_an_ThDNjY1ukH {
    10% {
        stroke-dasharray: 1, 64;
    }
    33.33% {
        stroke-dasharray: 66, 64;
    }
    0% {
        stroke-dasharray: 1, 64;
    }
    100% {
        stroke-dasharray: 66, 64;
    }
}
@keyframes kf_el_bLRiszdKqD_an_ThDNjY1ukH {
    10% {
        stroke-dasharray: 1, 64;
    }
    33.33% {
        stroke-dasharray: 66, 64;
    }
    0% {
        stroke-dasharray: 1, 64;
    }
    100% {
        stroke-dasharray: 66, 64;
    }
}
@-webkit-keyframes kf_el_bLRiszdKqD_an_E1dnV4UTG {
    0% {
        -webkit-transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
        transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
    }
    100% {
        -webkit-transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
        transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
    }
}
@keyframes kf_el_bLRiszdKqD_an_E1dnV4UTG {
    0% {
        -webkit-transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
        transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
    }
    100% {
        -webkit-transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
        transform: translate(119.25px, 36px) rotate(97470deg)
            translate(-119.25px, -36px);
    }
}
#el_0VuclV7Dc * {
    -webkit-animation-duration: 6s;
    animation-duration: 6s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
}
.infinite * {
    -webkit-animation-iteration-count: infinite !important;
    animation-iteration-count: infinite !important;
}
#el_bLRiszdKqD {
    -webkit-animation-fill-mode: forwards, forwards;
    animation-fill-mode: forwards, forwards;
    -webkit-animation-name: kf_el_bLRiszdKqD_an_ThDNjY1ukH,
        kf_el_bLRiszdKqD_an_oRXwaEl47;
    animation-name: kf_el_bLRiszdKqD_an_ThDNjY1ukH,
        kf_el_bLRiszdKqD_an_oRXwaEl47;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1),
        cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1),
        cubic-bezier(0.42, 0, 0.58, 1);
    stroke-dasharray: 1, 64;
    opacity: 0;
}
#el_sFONSKqoZN {
    -webkit-animation-fill-mode: forwards, forwards;
    animation-fill-mode: forwards, forwards;
    -webkit-animation-name: kf_el_sFONSKqoZN_an_SP19g__PD,
        kf_el_sFONSKqoZN_an_O7oGAKcqRG;
    animation-name: kf_el_sFONSKqoZN_an_SP19g__PD,
        kf_el_sFONSKqoZN_an_O7oGAKcqRG;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1),
        cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1),
        cubic-bezier(0.42, 0, 1, 1);
}
#el_bLRiszdKqD_an_E1dnV4UTG {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-transform: translate(119.25px, 36px) rotate(97470deg)
        translate(-119.25px, -36px);
    transform: translate(119.25px, 36px) rotate(97470deg)
        translate(-119.25px, -36px);
    -webkit-animation-name: kf_el_bLRiszdKqD_an_E1dnV4UTG;
    animation-name: kf_el_bLRiszdKqD_an_E1dnV4UTG;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
}
#el_sFONSKqoZN_an_RaZP_Mcup {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: kf_el_sFONSKqoZN_an_RaZP_Mcup;
    animation-name: kf_el_sFONSKqoZN_an_RaZP_Mcup;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
}
</style>

<template>
    <svg
        width="30"
        height="30"
        viewBox="106 22 30 30"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        id="el_0VuclV7Dc"
        :class="{ infinite }"
    >
        <g fill-rule="evenodd" fill="none">
            <g
                id="el_bLRiszdKqD_an_E1dnV4UTG"
                data-animator-group="true"
                data-animator-type="1"
            >
                <circle
                    id="el_bLRiszdKqD"
                    cx="119.25"
                    cy="36"
                    r="10.5"
                    :stroke="color"
                    stroke-width="3"
                />
            </g>
            <g
                id="el_sFONSKqoZN_an_RaZP_Mcup"
                data-animator-group="true"
                data-animator-type="1"
            >
                <circle
                    id="el_sFONSKqoZN"
                    cx="119.25"
                    cy="36"
                    r="10.5"
                    :stroke="color"
                    stroke-width="3"
                />
            </g>
        </g>
    </svg>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    props: {
        color: {
            type: String,
            default: "#3954bf"
        },

        infinite: {
            type: Boolean,
            default: true,
        },
    },
});
</script>
