<style lang="scss">
.linegraph-table-wrapper--right {
    .synthetics-table {
        div.dataTables_wrapper {
            max-width: 300px;
            margin: 0 10px;
        }
    }
}
.synthetics-table {
    overflow: hidden;

    table {
        thead {

            tr {
                height: 21px;
            }

            th {
                font-size: 13px !important;
                color: black !important;
                text-transform: capitalize !important;
                padding-top: 2px !important;
                padding-bottom: 2px !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
                border-top: 1px solid #e6e6e6;
                text-align: right;
            }

            .color-column {
                visibility: hidden;
                width: 25px;
                max-width: 25px;
            }

            .metric-column {
                text-align: left;
            }
        }

        tbody {
            tr {
                height: 18px;

                td {
                    border-bottom-width: 0 !important;
                    border-top-width: 0 !important;
                    padding-top: 0;
                    padding-bottom: 0;
                    text-align: right;

                    &:first-child {
                        vertical-align: middle;
                    }

                    &.metric-column {
                        text-align: left;
                        position: relative;
                        width: 50%;
                        min-width: 150px;

                        & > * {
                            display: inline;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

                &:first-child {
                    td {
                        border-top: 1px solid #e6e6e6 !important;
                    }
                }

                .line-graph-circle-cell {
                    display: flex;
                    justify-content: center;

                    .line-graph-circle {
                        height: 8px;
                        width: 8px;
                        border-radius: 8px;
                    }
                }
            }
        }
    }
}
</style>

<template>
    <div class="synthetics-table" :style="syntheticsTableStyle">
        <div ref="table_wrapper" class="synthetics-table-wrapper">
            <p-table2
                ref="data_table"
                :column-callbacks="columnCallbacks"
                :column-renderers="columnRenderers"
                :columns="mappedHeaders"
                :scroll-y="`${tableBodyHeight}px`"
                scroll-x
                scroll-collapse
                ordering
                :sort-column="computedSortColumn"
                :sort-direction="sortDir"
            >
                <table slot="table" class="pa-table">
                    <thead>
                        <tr class="pa-table-row pa-table-row_hd">
                            <th
                                :key="header.id"
                                v-for="header in filteredHeaders"
                                :title="header.name"
                                :class="header.className"
                            >
                            </th>
                        </tr>
                    </thead>
                </table>
            </p-table2>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';

export default Vue.extend({
    data() {
        return {
            columnCallbacks: {
                'metric-column': (td) => {
                    this.compileEl(td);
                },
            },
        };
    },
    props: {
        maxItems: {
            type: Number,
            default: 5,
        },
        rowHeight: {
            type: Number,
            default: 18,
        },
        data: {
            type: Array,
            default: null,
        },
        sortDir: {
            type: String,
            default: '',
        },
        sortColumn: {
            type: String,
            default: '',
        },
        headers: {
            type: Array,
            default: null,
        },
        floatRight: Boolean,
        parentHeight: {
            type: Number,
            default: 100,
        },
        columnOrder: {
            type: Array,
            default: null,
        },
        inModal: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        computedSortColumn() {
            return this.filteredHeaders.findIndex(
                (header) => header.name === this.sortColumn
            );
        },
        columnRenderers() {
            return {
                'color-column': this.renderColorColumn,
                'metric-column': this.renderMetricColumn,                
            };
        },
        filteredHeaders: function() {
            return this.mappedHeaders.filter(header => header.visible);
        },
        mappedHeaders: function() {
            if (!this.headers) return;

            return this.headers.map((header) => {
                const id = header.id.toLowerCase();
                if (id == 'metric') {
                    header.sortable = false
                }
                return {
                    ...header,
                    className: `${id}-column`,
                    data: id,
                    title: id,
                    isSorted: this.sortColumn === header.name,
                };
            });
        },
        mappedData: function() {
            if (!this.data) return;

            return this.data.map(column => {
                return _.mapValues(column, (val, key) => {
                    if (["min", "max", "value", "incidents"].includes(key)) {

                        if (_.isString(val)) {
                            if (val === 'N/A') {
                                return ""
                            } else {
                                return val;
                            }
                        }

                        return _.round(_.toNumber(val), 2) || 0;
                    }
                    return val;
                });
            });
        },
        tableBodyHeight: function() {
            if (this.floatRight) {
                // Initial height, will update from watcher
                return 50;
            }

            return this.maxItems * this.rowHeight;
        },
        syntheticsTableStyle: function() {
            const styles = {
                position: "relative",
            };

            if (this.floatRight) {
                return {
                    ...styles,
                    width: "100%",
                };
            }

            return {
                ...styles,
                height: `${this.tableBodyHeight + 31}px`,
            };
        },
    },
    methods: {
        renderMetricColumn(data, type, row) {
            if (row.serverId) {
                return `
                    <a
                        target="_blank"
                        href="${this.getReportHref(row)}"
                        title="${data}"
                        class="pa-txt_dark"
                    >
                        ${data}
                    </a>
                `;
            }
            return `<span title="${data}">${data}</span>`;
        },
        renderColorColumn(data) {
            return `
                <div class="line-graph-circle-cell">
                    <div
                        style="background-color: ${data}"
                        class="line-graph-circle"
                    >
                    </div>
                </div>
            `;
        },
        updateTableData() {
            this.$refs.data_table.reloadTableData(this.mappedData);
        },
        scrollToTop: function() {
            if (this.$refs.table_wrapper.scrollTop !== 0) {
                this.$refs.table_wrapper.scrollTop = 0;
            }
        },
        handleScroll: function(e) {
            const scrollTop = e.target.scrollTop;
            // This wouldnt work as a computed style for some reason. The scrollTop
            // value wouldnt update on state and reach the computed prop
            this.$refs.table_head.style.transform = 'translateY(' + scrollTop + 'px)';
        },
        getReportHref(column) {
            if (column.applianceId) {
                return '../report/Appliance/' + column.applianceId;
            }

            let link = '../report/InstanceDetails?server_id=' + column.serverId;

            if (
                column.resourceId &&
                (
                    column.resourceId.includes("sr-") ||
                    column.resourceId.includes("mp-")
                )
            ) {
                link = link + "&metric_id=" + column.resourceId.replace("sr-", "-").replace("mp-", "");
            }

            return link;
        },
    },
    watch: {
        inModal() {
            setTimeout(() => {
                this.updateTableData()
             }, "400");
        },
        headers(headers) {
            headers.forEach((header) => {
                const columnIndex = this.columnOrder.indexOf(header.name);
                if (header.visible) {
                    this.$refs.data_table.showColumn(columnIndex, true);
                } else {
                    this.$nextTick(() => {
                        this.$refs.data_table.hideColumn(columnIndex, true);
                    });
                }
            });
        },
        computedSortColumn(sortColumn) {
            if (!this.$refs.data_table || !this.$refs.data_table.dataTable) {
                return;
            }

            if (sortColumn >= 0 && this.sortDir) {
                this.$refs.data_table.setSort(sortColumn, this.sortDir);
            }
        },
        sortDir(sortDir) {
            if (!this.$refs.data_table || !this.$refs.data_table.dataTable) {
                return;
            }

            if (sortColumn >= 0 && this.sortDir) {
                this.$refs.data_table.setSort(this.computedSortColumn, sortDir);
            }
        },
        tableBodyHeight(tableBodyHeight) {
            if (!this.floatRight) {
                this.$refs.data_table.$el.querySelector('.dataTables_scrollBody').style.maxHeight = `${tableBodyHeight}px`;
            }
        },
        parentHeight(curr) {
            if (this.floatRight) {
                this.$refs.data_table.$el.querySelector('.dataTables_scrollBody').style.maxHeight = `${curr - 65}px`;
            }
        },
        mappedData: {
            deep: true,
            handler() {
                this.updateTableData();
            }
        },
        'sortDir': function() {
            this.scrollToTop();
        }
    },
    vueReady: function() {
        this.$refs.data_table.createTable();
        this.$refs.table_wrapper.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy: function() {
        this.$refs.data_table.dataTable.clear();
        this.$refs.data_table.dataTable.destroy();
        this.$refs.data_table.dataTable = null;
        this.$refs.table_wrapper.removeEventListener('scroll', this.handleScroll);
    },
});
</script>
