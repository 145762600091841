export const LEGEND_TYPES = {
    SOURCES: "sources",
    IDENTIFIED: "identified",
    UNIDENTIFIED: "unidentified",
    INCIDENTS: "incidents",
    TARGETS: "targets",
    LOSS: "loss",
    PROVIDER: "provider",
};

export const LEGEND_TYPES_CONFIG = {
    [LEGEND_TYPES.SOURCES]: {
        type: "circle",
        size: 18,
        style: {
            fill: "#CCE5FF",
            stroke: "#394449",
            lineWidth: 1,
        },
    },
    [LEGEND_TYPES.IDENTIFIED]: {
        type: "circle",
        size: 18,
        style: {
            fill: "#FFFFFF",
            stroke: "#394449",
            lineWidth: 2,
        },
    },
    [LEGEND_TYPES.UNIDENTIFIED]: {
        type: "circle",
        size: 18,
        style: {
            fill: "#FFFFFF",
            stroke: "#394449",
            lineWidth: 1,
        },
    },
    [LEGEND_TYPES.INCIDENTS]: {
        type: "circle",
        size: 18,
        style: {
            fill: "#FFFFFF",
            stroke: "#D43527",
            lineWidth: 1,
        },
    },
    [LEGEND_TYPES.TARGETS]: {
        type: "circle",
        size: 18,
        style: {
            fill: "#D4EDDA",
            stroke: "#008B10",
            lineWidth: 2,
        },
    },
    [LEGEND_TYPES.LOSS]: {
        type: "line",
        size: 18,
        style: {
            fill: "#D4EDDA",
            stroke: "#D43527",
            lineWidth: 2,
        },
    },
    [LEGEND_TYPES.PROVIDER]: {
        type: "circle",
        size: 18,
        style: {
            fill: "#59798a",
            stroke: "#394449",
            lineWidth: 1,
        },
    },
};

export const LEGEND_DATA = {
    nodes: [
        {
            id: LEGEND_TYPES.UNIDENTIFIED,
            label: "Unidentified Node",
            order: 0,
            ...LEGEND_TYPES_CONFIG[LEGEND_TYPES.UNIDENTIFIED],
        },
        {
            id: LEGEND_TYPES.INCIDENTS,
            label: "Node with Incidents",
            order: 1,
            ...LEGEND_TYPES_CONFIG[LEGEND_TYPES.INCIDENTS],
        },
        {
            id: LEGEND_TYPES.LOSS,
            label: "Link with packet loss",
            order: 3,
            ...LEGEND_TYPES_CONFIG[LEGEND_TYPES.LOSS],
        },
    ],
};
