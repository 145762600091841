<style lang="scss">
.tg  {border-collapse:collapse;border-spacing:0;border-style:solid;border-width:1px;}
.tg td{font-size:16px;padding:10px 5px;border-style:solid;border-width:1px;word-break:normal;}
.tg th{font-size:16px;font-weight:normal;padding:10px 5px;border-style:solid;border-width:1px;word-break:normal;}
.tg .tg-tnpk{border-color: black;background-color:#fafafa;color:black;vertical-align:top}
.tg .tg-yw4l{vertical-align:top}

.onsight-panels {
    .pa-tabbedPanels-tabs .pa-tabs button.pa-tab {
        margin-right: 0 !important;
    }

    .help-docs-link {
        font-size: 14px;
        position: absolute;
        top: -20px;
        right: 0;
    }

    pre {
        background-color: var(--p-grey-100);
        color: var(--p-grey-700);
        display: inline;
    }
}

.resource-requirements-details {
    summary {
        list-style: none;
        cursor: default;

        &::-webkit-details-marker {
            display: none;
        }
    }

    &[open] {
        summary {
            svg {
                transform: rotate(180deg) scaleX(-1);
            }
        }
    }
}
</style>

<template>
    <p-panels :use-background="false" :remember-active-panel="false" class="onsight-panels">
        <p-panel title="Standalone Installer" :wrapper="false">
            <details class="resource-requirements-details pa-pb-12">
                <summary class="pa-txt_medium pa-txt_sm pa-mb-8">
                    Resource Requirements
                    <p-icon class="pa-ml-8" icon="chevron-down" color="blue-500"></p-icon>
                </summary>
                <ul class="pa-uList--styled">
                    <li class="pa-mb-8">Ubuntu Server LTS (20.04 or newer), RHEL/CentOS 7.x or newer, Debian 9 and 10, Rocky Linux 9 and newer, Oracle Enterprise Linux 7.0, Amazon Linux 2 and 2018.03</li>
                    <li class="pa-mb-8">At least a 4 core CPU</li>
                    <li class="pa-mb-8">At least 8 GB of memory</li>
                    <li class="pa-mb-8">At least 50 GB of available disk space</li>
                    <li class="pa-mb-8">Root access with the ability to create users and install system packages</li>
                    <li class="pa-mb-8">Minimum <a href="https://docs.fortimonitor.forticloud.com/user-guide/410856/onsight-vcollector#Network_connectivity_requirements" target="_blank">network connectivity</a></li>
                </ul>
            </details>
            <p-copy-script
                variant="secondary"
                title="Run the following command as root"
                :script="`curl -fsSL https://repo.fortimonitor.com/install/onsight/onsight-install.sh > onsight-install.sh && bash onsight-install.sh ${customerKey}`"
            >
            </p-copy-script>
        </p-panel>
        <p-panel title="AWS" :wrapper="false">
            <details class="resource-requirements-details pa-pb-24">
                <summary class="pa-txt_medium pa-txt_sm pa-mb-8">
                    Resource Requirements
                    <p-icon class="pa-ml-8" icon="chevron-down" color="blue-500"></p-icon>
                </summary>
                <ul class="pa-uList--styled">
                    <li class="pa-mb-8">Minimum of a t3.medium instance - as your monitoring expands, we recommend 1 core + 1 GB Ram per 1,000 checks</li>
                    <li class="pa-mb-8">Minimum <a href="https://docs.fortimonitor.forticloud.com/user-guide/410856/onsight-vcollector#Network_connectivity_requirements" target="_blank">network connectivity</a></li>
                </ul>
            </details>
            <ul class="pa-uList--styled pa-mb-24">
                <li class="pa-mb-16">
                    Log into your AWS account and switch to the EC2 console by selecting EC2 from the splash screen or from under the Services navigation menu
                </li>
                <li class="pa-mb-16">
                    Switch to the AWS Marketplace by clicking "Launch Instance", and then selecting the AWS Marketplace tab         
                </li>
                <li class="pa-mb-16">
                    Search for FortiMonitor and select the FortiMonitor OnSight for AWS AMI
                </li>
                <li class="pa-mb-16">
                    Select your instance size - starting with a t3.medium is sufficient to start out - and move on to ‘Review and Launch’
                    <img style="height: 300px;" src="/static/assets/media/marketplace-search.png" />
                </li>
                <li class="pa-mb-16">
                    On the Review Instance Launch screen you can select ‘Edit Security Groups’
                </li>
                <li class="pa-mb-16">
                    Configure the security group to use the port rules listed below
                    <ul class="pa-vList pa-vList_x4 pa-pt-16">
                        <li>
                            <table class="tg" style="width: 100%;">
                                <tbody>
                                    <tr>
                                        <th class="tg-tnpk" style="width:25%;">Type</th>
                                        <th class="tg-tnpk">Protocol</th>
                                        <th class="tg-tnpk">Port Range</th>
                                        <th class="tg-tnpk">Source</th>
                                    </tr>
                                    <tr>
                                        <td class="tg-yw4l">SSH</td>
                                        <td class="tg-yw4l">TCP</td>
                                        <td class="tg-yw4l">22</td>
                                        <td class="tg-yw4l">This IP range should be limited to only clients where you'll be sshing to the OnSight from, such as a Jump Server</td>
                                    </tr>
                                    <tr>
                                        <td class="tg-yw4l">Custom TCP Rule</td>
                                        <td class="tg-yw4l">TCP</td>
                                        <td class="tg-yw4l">8443</td>
                                        <td class="tg-yw4l">This IP range should be limited to the other servers that will be using the OnSight to proxy agent data through (if any)</td>
                                    </tr>
                                    <tr>
                                        <td class="tg-yw4l">HTTPS</td>
                                        <td class="tg-yw4l">TCP</td>
                                        <td class="tg-yw4l">443</td>
                                        <td class="tg-yw4l">This IP range should be limited to only clients where you'll be sshing to the OnSight from, such as a Jump Server</td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                    </ul>
                </li>
                <li class="pa-mb-16">
                    Select Launch
                </li>
                <li class="pa-mb-16">
                    Select your key-pair from the drop down then launch the instance and wait for it to boot.
                </li>
                <li class="pa-mb-16">
                    Once booted you can SSH into the OnSight appliance using the user ‘ubuntu’ and the key-pair you selected previously.
                </li>
                <li class="pa-mb-16">
                    After SSHing in, run the command <pre class="pa-py-2 pa-px-4"><code>sudo onsight register</code></pre> to complete the setup.
                </li>
            </ul>
        </p-panel>
        <p-panel title="Virtual Appliance" :wrapper="false">
            <details class="resource-requirements-details pa-pb-24">
                <summary class="pa-txt_medium pa-txt_sm pa-mb-8">
                    Resource Requirements
                    <p-icon class="pa-ml-8" icon="chevron-down" color="blue-500"></p-icon>
                </summary>
                <ul class="pa-uList--styled">
                    <li class="pa-mb-8">At least 2 GB of memory (4 GB is recommended)</li>
                    <li class="pa-mb-8">At least 30 GB of available disk space</li>
                    <li class="pa-mb-8">Minimum <a href="https://docs.fortimonitor.forticloud.com/user-guide/410856/onsight-vcollector#Network_connectivity_requirements" target="_blank">network connectivity</a></li>
                </ul>
            </details>
            <ul class="pa-uList--styled pa-mb-24">
                <li class="pa-mb-16">
                    Download the appropriate OnSight vCollector image for your hypervisor.
                    <a href="https://repo.fortimonitor.com/appliance/FortimonitorOnsight-VMware-latest.zip" target="_blank">
                        <span class="pa-txt">VMware ESXi 6.5+</span>
                    </a>
                </li>
                <li class="pa-mb-16">
                    After downloading the OnSight image, import it as a virtual machine into your hypervisor. Once your OnSight is imported and booted, the VM will go through the normal Linux startup process, finishing with a login prompt.
                </li>
                <li class="pa-mb-16">
                    Log in with username <pre class="pa-py-2 pa-px-4"><code>fortimonitor</code></pre> and password <pre class="pa-py-2 pa-px-4"><code>fortimonitor</code></pre>. You will then be prompted to set a new password. <strong>Important</strong>: Do not lose this password. Without it, there is no way to access the OnSight for further updates.
                </li>
                <li class="pa-mb-16">
                    Configure the network by running <pre class="pa-py-2 pa-px-4"><code>onsight configure-network</code></pre> as root. See Network Configuration for more details.
                </li>
                <li class="pa-mb-16">
                    <span class="block pa-pb-8">
                        Register the OnSight by running <pre class="pa-py-2 pa-px-4"><code>onsight register --customer-key {{ customerKey }}</code></pre> as root. This will connect your OnSight to the FortiMonitor cloud and begin syncing data.
                    </span>
                </li>
            </ul>
        </p-panel>
        <p-panel title="Azure" :wrapper="false">
            <ul class="pa-uList--styled pa-mb-24">
                <li class="pa-mb-16">
                    Within your Azure Portal, navigate to the Azure Marketplace
                </li>
                <li class="pa-mb-16">
                    Search for `FortiMonitor OnSight` and select it from the search results
                </li>
                <li class="pa-mb-16">
                    Click Create from the details page
                </li>
                <li class="pa-mb-16">
                    On the Create a virtual machine page, fill out the fields appropriately
                    <ul class="pa-uList--styled pa-pt-16">
                        <li class="pa-mb-16">
                            Select an instance size. Standard B4ms or Standard D3v2 are good starting points, with larger sizes recommended for higher workloads.  
                        </li>
                    </ul>
                </li>
                <li class="pa-mb-16">
                    Use SSH for authentication type, either creating a new key pair or using an existing one
                </li>
                <li class="pa-mb-16">
                    Select Review and Create and confirm your selection. Then create the instance.
                </li>
                <li class="pa-mb-16">
                    Once the instance has been created, SSH in, and run the command <pre class="pa-py-2 pa-px-4"><code>sudo onsight register</code></pre> to complete the setup.
                </li>
            </ul>
        </p-panel>
        <p-panel v-if="hasHardwareOnsight" title="Hardware OnSight" :wrapper="false">
            <form @submit.prevent="handleSubmit">
                <p-field-group
                    name="serial-number"
                    label="Serial Number"
                    :error="errors.serialNumber"
                >
                    <p-input
                        v-model="serialNumber"
                        placeholder="Serial Number"
                        class="pa-input_xxxxl"
                        name="serial-number"
                        type="text"
                    />
                </p-field-group>
                <p-field-group
                    name="onsight-name"
                    label="OnSight Name"
                    :error="errors.name"
                >
                    <p-input
                        v-model="onSightName"
                        placeholder="Name"
                        class="pa-input_xxxxl"
                        name="onsight-name"
                        type="text"
                    />
                </p-field-group>
                <p-button type="submit" :disabled="isSubmitting">
                    {{ isSubmitting ? "Registering..." : "Register" }}
                </p-button>
            </form>
        </p-panel>
        <a
            href="https://docs.fortimonitor.forticloud.com"
            target="_blank"
            class="pa-link help-docs-link"
        >
            Help Docs
            <p-icon icon="external-link"></p-icon>
        </a>
    </p-panels>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    data: () => ({
        serialNumber: "",
        onSightName: "",
        isSubmitting: false,
        errors: {},
    }),
    props: {
        customerKey: {
            type: String,
            default: "",
        },
        onPanelChange: {
            type: Function,
            default: () => {},
        },
        hasHardwareOnsight: {
            type: Boolean,
            default: false,
        },
        urlParams: Object,
    },
    events: {
        "panel:activated"(pane) {
            this.onPanelChange(pane);
        },
    },
    methods: {
        handleSubmit() {
            this.isSubmitting = true;
            this.errors = {};

            $.ajax({
                url: "/onboarding/registerHardwareOnSight",
                method: "GET",
                data: {
                    serialNumber: this.serialNumber,
                    name: this.onSightName,
                },
                context: this,
            }).done((response) => {
                if (!response.success) {
                    this.errors = {...response.errors};
                } else {
                    window.location.href = "/report/Appliance/" + response.appliance_id;
                }
            }).always(() => {
                this.isSubmitting = false;
            });
        },
    },
    mounted() {
        if (this.urlParams["serial-number"]) {
            this.serialNumber = this.urlParams["serial-number"];
        }

        if (this.urlParams["onsight-name"]) {
            this.onSightName = this.urlParams["onsight-name"];
        }

        this.$nextTick(() => {
            if (window.location.hash.includes("hardware-onsight")) {
              this.$broadcast("panels:set_active_panel_by_id", "hardware-on-sight");
          }
      });
    },
});
</script>
