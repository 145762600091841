<style>
</style>

<template>
    <div class="module" :class="{'open': open}">
        <div class="module-header" @click="toggle">
            <span class="pa-txt">Formatting</span>
            <svg class="pa-icon"><use xlink:href="#chevron-down"></use></svg>
        </div>
        <div ref="body" v-show="open" transition="module-expand" class="module-body">
            <ul class="pa-vList">
                <li v-for="opt in filteredFormatOptions">
                    <label class="pa-option" style="width:100%;">
                        <input type="checkbox" class="pa-option-input" :value="opt.value" v-model="chosen"/>
                        <span class="pa-option-icon"></span>
                        <span class="pa-option-txt">
                            {{ opt.label }}
                        </span>
                    </label>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const FormattingModule = Vue.extend({
        events: {
        },

        data() {
            return {
                format_options: [
                    {value: "hide_server_name", label: "Hide Server Name"},
                    {value: "hide_units", label: "Hide Units"},
                    {value: "hide_server_fqdn", label: "Hide Server FQDN"},
                    {value: "hide_metric_type", label: "Hide Metric Type"},
                    {value: "hide_option_name", label: "Hide Option Name"},
                ],
                chosen: []
            }
        },

        props: {
            hiddenOptions: Array,
            options: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            open: {
                type: Boolean,
                default: false
            },
        },

        computed: {
            filteredFormatOptions: function() {
                if (!this.hiddenOptions) {
                    return this.format_options;
                }
                return this.format_options.filter(
                    option => !this.hiddenOptions.includes(option.value)
                );
            },
        },

        methods: {
            toggle() {
                this.open = !this.open;
            },
            sendConfig() {
                var payload = {
                                widget_id: this.$parent.editingWidget.id,
                                format_options: this.chosen
                              }
                $.ajax({
                    url: '/dashboardv2/setWidgetFormatting',
                    method: 'POST',
                    data: payload,
                })
                    .done(data => {
                        if (data.success && data.newConfig) {
                            this.$parent.editingWidget.config = data.newConfig;
                        }
                    });
                this.$parent.editingWidget.pendNewConfig();
            },
            updateChosen() {
                var preset = this.$parent.editingWidget.config.format_options;
                if (preset === null || typeof preset === 'undefined') {
                    preset = [];
                }
                this.chosen = [...preset];
            },
        },

        watch: {
            chosen() {
                this.sendConfig();
            },
        },

        vueReady() {
            this.updateChosen();
            this.$once('dashboard:new_config', this.updateChosen);
        }
    });

    export default FormattingModule;
</script>
