<template>
    <div>
        <div class="pa-vr" v-if="showSearch">
            <form v-on:submit="_onSubmit($event)" class="pa-input pa-input_phony pa-input_stretch">
                <label for="filter" class="pa-isVisuallyHidden">Filter</label>
                <input autocomplete="off" type="filter" id="filter" v-model="filter" placeholder="Search"/>                
                <p-filter v-if="filtering" :on-change-callback="filterInstanceTypes" :optgroups="instanceTypeOptions">
                </p-filter>
                <button class="pa-input_phony-icon">
                    <svg class="pa-icon pa-icon_block pa-icon_lg">
                        <use xlink:href="#magnify"></use>
                    </svg>
                </button>
            </form>
        </div>
        <div style="overflow-y: auto; height: 100%;">
            <div>
                <input v-if="required" type="text" id="element_ids" name="element_ids" :value="computedModel" hidden
                    :required="required">
                <input v-if="!required" type="text" id="element_ids" name="element_ids" :value="computedModel" hidden>
            </div>
            <template v-if="include_toggles">
                <div class="pa-vList pa-vList_x4">
                    <div class="pa-field">
                        <div class="pa-field-hd">
                        </div>
                        <div class="pa-field-bd">
                            <label for="show_servers" class="pa-label">
                                <input id="show_servers" type="checkbox" name="show_servers" v-model="show_servers"
                                    v-on:change="applyToggleFilters">
                                Show Servers</label>
                        </div>
                        <div class="pa-field-ft">
                            <p class="pa-hint">If checked (default), status pages will include server-level availability
                                info.</p>
                        </div>
                    </div>
                    <div class="pa-field">
                        <div class="pa-field-hd">
                        </div>
                        <div class="pa-field-bd">
                            <label for="show_compound_services" class="pa-label">
                                <input id="show_compound_services" type="checkbox" name="show_compound_services"
                                    v-model="show_compound_services" v-on:change="applyToggleFilters">
                                Show Compound Services</label>
                        </div>
                        <div class="pa-field-ft">
                            <p class="pa-hint">If checked, status pages will include compound service availability
                                detail.</p>
                        </div>
                    </div>
                </div>
                <br />
            </template>
<template v-if="type_switch">
                <div class="pa-vList pa-vList_x4">
                    <div class="pa-field">
                        <div class="pa-field-hd">
                            <label for="select-menu" class="pa-label">Select By</label>
                        </div>
                        <div class="pa-field-bd">
                            <div class="pa-select" id="select-menu">
                                <select class="pa-select-menu" v-model="display_type" v-on:change="onSelectLoading">
                                    <option value="server">Server</option>
                                    <option value="server_group">Server Group</option>
                                </select>
                                <span class="pa-select-icon"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
<p-flex v-show="loadingSpinner" justify-center>
    <p-loading-spinner-2></p-loading-spinner-2>
</p-flex>
<template v-if="display_label">
                <div v-show="!loadingSpinner" class="pa-field">
                    <div class="pa-field-hd">
                        <label for="elements-tree" class="pa-label" v-text="tree_label"></label>
                    </div>
                    <div :class="[multiselect ? 'multi' : 'single', css_class]">
                        <div id="elements-tree" ref="tree"></div>
                    </div>
                </div>
            </template>
<template v-else>
                <div v-show="!loadingSpinner" :class="[multiselect ? 'multi' : 'single', css_class]">
                    <div ref="tree"></div>
                </div>
            </template>
</div>
</div>
</template>

<script>
import URI from 'urijs';
import Vue from 'vue';
import _ from "lodash";
import InspireTree from 'inspire-tree';
import InspireTreeDOM from './../../lib/inspire-tree-dom.js';
import $ from 'jquery';
import TreeManager from './../utils/treeManager';
import { postMonitoringTreeDeferred } from "../../api/services/UtilService";

const DEFAULT_STATE = {
    rememberedTrees: [],
};

const Tree = Vue.extend({
    data() {
        return {
            filter: '',
            loadingSpinner: this.initialLoadSpinner,
            instanceTypeOptions: [],
            imodel: this.model,
        };
    },

    events: {
        ['tree:clearState']({ storageKey }) {
            if (storageKey !== this.storageKey) {
                return;
            }

            this.clearState();
        },
        'tree:reload': function (id, url) {
            if (this.id !== id) { return; }
            if (typeof url !== 'undefined') {
                this.url = url;
            }
            this.tree.reload();
        },
        'tree:selectFirstNode': function () {
            Vue.nextTick(() => {
                this.selectFirstNode();
            });
        },
    },

    props: {
        useDeferred: false,

        activeNode: String,

        activeNodeParents: {
            type: Array,
            'default': () => [],
        },

        model: {
            type: Array,
            'default': () => [],
        },

        deferred: {
            type: Boolean,
            'default': false,
        },

        data: {
            type: Array,
            'default': () => [],
        },

        showSearch: {
            type: Boolean,
            'default': false,
        },

        select_nodes: {
            type: Boolean,
            'default': false,
        },

        multiselect: {
            type: Boolean,
            'default': false,
        },

        target: {
            type: String,
        },

        url: {
            type: String,
        },

        preselected: {
            type: Array,
            'default': function () {
                return [];
            },
        },

        type_switch: {
            type: Boolean,
            'default': false,
        },

        display_type: {
            type: String,
            'default': 'server',
        },

        tree_label: {
            type: String,
            'default': 'Servers & Compound Services',
        },

        display_label: {
            type: Boolean,
            'default': false,
        },

        storageKey: String,

        required: {
            type: String,
            'default': '',
        },
        id: {
            type: String,
            'default': '',
        },

        css_class: {
            type: String,
            'default': '',
        },

        include_toggles: {
            type: Boolean,
            'default': false,
        },

        show_servers: {
            type: Number,
            'default': 1,
        },

        show_compound_services: {
            type: Number,
            'default': 0,
        },

        cached_server_selections: {
            type: Array,
            'default': function () {
                return [];
            },
        },

        cached_cs_selections: {
            type: Array,
            'default': function () {
                return [];
            },
        },

        selectCallback: {
            type: [Boolean, Function],
            'default': false,
        },

        preselectFirst: {
            type: Boolean,
            'default': false,
        },

        preselectRoot: {
            type: Boolean,
            'default': false,
        },

        titleNodes: {
            type: Boolean,
            'default': true,
        },

        cacheNodes: {
            type: Boolean,
            'default': true,
        },

        filtering: Boolean,

        numericIds: Boolean,

        initialLoadSpinner: {
            type: Boolean,
            default: true,
        },

        autoCheckChildren: {
            type: Boolean,
            default: true,
        },



    },

    computed: {
        computedModel() {
            if (typeof this.imodel === 'string') {
                return this.imodel;
            } else {
                return this.imodel.join(',');
            }
        },
        deferredURL() {
            this.deferred = true;
            if(this.url.includes('deferred')){
                return this.url;
            }else{
                return this.url.replace('monitoring_tree', 'monitoring_tree_deferred');
            }
        },
        nonDeferredURL(){
            this.deferred = false;
            if(this.url.includes('_deferred')){
                return this.url.replace('_deferred','');
            }else{
                return this.url;
            }
        }
    },

    methods: {
        debounceSearch:
            _.debounce(function () {                
                this.url = this.url.replace('search_string', '');
                const uri = URI(this.nonDeferredURL);
                this.$nextTick(()=> {
                    if (this.filter != "") {                    
                        uri.addQuery('search_string', this.filter);
                        this.imodel = [];
                        this.url = uri.href();
                        this.tree.opts.data = this.treeData;
                        this.loading = true;
                        this.tree.reload();
                    }else{
                        this.url = this.deferredURL;
                        this.tree.opts.data = this.treeDataDeferred;
                        this.laoding = true;
                        this.tree.reload();
                    }
                });                               
            }, 1000),



        selectFirstNode() {
            const root = this.tree.nodes()[0];
            this.preselected.push(root.getChildren()[0].id);
            this.preselect();
        },
        selectRootNode() {
            this.preselected.push('grp-0');
            this.preselect();
        },
        clearState() {
            if (!this.storageKey) {
                return;
            }

            window.sessionStorage.removeItem(this.storageKey);
        },

        disable() {
            this.tree.off('node.click', this._onNodeClick);
            this.tree.off('model.loaded', this._onModelLoaded);
            this.tree.off('children.loaded', this._onChildrenLoaded);
            this.tree.off('node.expanded', this._onNodeExpanded);
            this.tree.off('node.collapsed', this._onNodeCollapsed);
        },

        enable() {
            this.tree.on('node.click', this._onNodeClick);
            this.tree.on('model.loaded', this._onModelLoaded);
            this.tree.on('children.loaded', this._onChildrenLoaded);
            this.tree.on('node.expanded', this._onNodeExpanded);
            this.tree.on('node.collapsed', this._onNodeCollapsed);
        },

        deselect() {
            this.tree.on('node.deselected', this._nodeDeselected);
            this.tree.on('node.unchecked', this._nodeDeselected);
        },

        select() {
            this.tree.on('node.selected', this._nodeSelected);
        },

        preselect(e) {
            if (!this.select_nodes) {
                return;
            }

            const selected = this.selected || this.preselected;
            for (let i = 0; i < selected.length; i++) {
                const node_selector = selected[i];
                const node = this.tree.node(node_selector);
                if (node) {
                    node.expandParents();
                    node.select();
                } else {
                    const index = this.imodel.indexOf(node_selector);
                    if (index < 0) {
                        this.imodel.push(node_selector);
                    }
                }
            }
        },

        async load(url) {
            const response = await postMonitoringTreeDeferred(`${url._parts.path}?${url._parts.query}`);
            return response.data
        },

        applyState() {
            this.tree.each(node => {
                this.expandRememberedTrees(node);
            });
        },

        expandRememberedTrees(node) {
            const id = node.id;
            const children = node.children;

            if (this.isTreeRemembered(id)) {
                node.expand();
            }

            if (!children) {
                return;
            }

            children.forEach(node => {
                this.expandRememberedTrees(node);
            });
        },

        isTreeRemembered(id) {
            const rememberedTrees = this.state.rememberedTrees;

            return rememberedTrees.indexOf(id) !== -1;
        },

        getState() {
            return JSON.parse(window.sessionStorage.getItem(this.storageKey)) || DEFAULT_STATE;
        },

        saveState() {
            window.sessionStorage.setItem(this.storageKey, JSON.stringify(this.state));
        },

        _onModelLoaded() {
            this.loadingSpinner = false;

            if (this.titleNodes) {
                this.addTitleToNodes();
            }

            if (!this.storageKey && !this.activeNode) {
                return;
            }

            if (this.deferred) {
                try {
                    let index = 0;
                    const expandDown = children => {
                        // Base-case
                        if (index >= this.activeNodeParents.length) {
                            const node = children.find(c => {
                                return c.id === this.activeNode;
                            });

                            if (node) {
                                node.select();
                            }

                            return;
                        }

                        const sgId = this.activeNodeParents[index];

                        const child = children.find(c => {
                            return c.id === sgId;
                        });

                        if (child) {
                            child.expand().then(() => {
                                index += 1;
                                expandDown(child.children);
                            });
                        }
                    };

                    const children = this.tree.nodes()[0].children;
                    if (this.activeNodeParents.length) {
                        expandDown(children);
                    }
                } catch (e) {
                    console.error(e);
                }
            }

            this.tree.recurseDown(node => {
                const id = node.id;

                if (this.storageKey && this.isTreeRemembered(id)) {
                    node.expand();
                }

                if (this.activeNode && id === this.activeNode) {
                    node.select();
                    node.expandParents();
                }
            });
        },

        _onChildrenLoaded() {
            if (this.titleNodes) {
                this.addTitleToNodes();
            }
        },

        _onNodeCollapsed(node) {
            Vue.nextTick(() => {
                this.eventHub.$emit('expando:resize');
            });

            if (this.storageKey) {
                const id = node.id;
                const index = this.state.rememberedTrees.indexOf(id);

                this.state.rememberedTrees.splice(index, 1);
                this.saveState(id);
            }
        },

        _onNodeExpanded(node) {
            Vue.nextTick(() => {
                this.eventHub.$emit('expando:resize');
            });

            if (this.storageKey) {
                const id = node.id;

                if (this.isTreeRemembered(id)) {
                    return;
                }

                this.state.rememberedTrees.push(id);
                this.saveState();
            }
        },

        _nodeDeselected(node) {
            if (this.select_nodes) {
                const index = this.imodel.indexOf(node.id);
                if (index > -1) {
                    this.imodel.splice(index, 1);
                }
                return;
            }
        },

        _nodeSelected(node) {
            if (!this.select_nodes) {
                return;
            }
            if (this.imodel.indexOf(node.id) === -1) {
                this.imodel.push(node.id);
            }
            if (this.deferred && node.children === true) {
                node.loadChildren().then(() => {
                    node.children.forEach(c => {
                        if (c.children === true) {
                            c.select();
                        }
                    });
                });
            }
            return;
        },

        _loadDeep(node) {
            return new Promise((resolve, reject) => {
                if (node.children === false
                    || typeof node.children === 'object'
                    || typeof node.children === 'undefined') {
                    resolve();
                    return;
                }
                node.loadChildren().then(() => {
                    const promises = [];
                    node.children.forEach(c => {
                        if (c.children === true) {
                            promises.push(this._loadDeep(c));
                        }
                    });
                    Promise.all(promises).then(resolve);
                });
            });
        },

        _onNodeClick(event, node, handler) {
            event.preventTreeDefault();

            const href = node.href;

            if (this.selectCallback) {
                if (this.deferred) {
                    // If deferred, we'll use the callback after the
                    // children are loaded and checked
                    this._loadDeep(node).then(() => {
                        handler();
                        this.selectCallback();
                    });
                } else {
                    handler();
                    Vue.nextTick(() => {
                        this.selectCallback(this.imodel);
                    });
                }
                return;
            }

            if (!href) {
                return;
            }

            // Don't follow links if we are in selection mode
            if (this.select_nodes) {
                handler();
                return;
            }

            window.location = href;
        },

        _onSubmit(event) {
            event.preventDefault();
        },

        applyToggleFilters(event) {
            if (event) { event.preventDefault(); };
            const uri = URI(this.url);
            if (this.include_toggles && !uri.hasQuery('only_groups')) {
                let show_servers;
                let show_compound_services;
                uri.removeQuery('include_servers');
                uri.removeQuery('include_compound_services');

                if (this.show_servers) {
                    uri.addQuery('include_servers', '1');
                    show_servers = true;
                } else {
                    uri.addQuery('include_servers', '0');
                    if (!this.cached_server_selections.length) {
                        this.cached_server_selections = this.imodel.filter(e => {
                            return e.startsWith('s');
                        });
                    }
                    show_servers = false;
                }
                if (this.show_compound_services) {
                    uri.addQuery('include_compound_services', '1');
                    show_compound_services = true;
                } else {
                    uri.addQuery('include_compound_services', '0');
                    if (!this.cached_cs_selections.length) {
                        this.cached_cs_selections = this.imodel.filter(e => {
                            return e.startsWith('cs');
                        });
                    }
                    show_compound_services = false;
                }

                this.selected = this.imodel.filter(e => {
                    if (!show_servers && e.startsWith('s')) {
                        return false;
                    }
                    if (!show_compound_services && e.startsWith('cs')) {
                        return false;
                    }
                    return true;
                });

                // Add cached selections if appropriate
                if (show_servers && this.cached_server_selections.length) {
                    this.selected = this.selected.concat(this.cached_server_selections);
                    this.cached_server_selections = [];
                }
                if (show_compound_services && this.cached_cs_selections.length) {
                    this.selected = this.selected.concat(this.cached_cs_selections);
                    this.cached_cs_selections = [];
                }

                this.tree_label = 'Servers & Compound Services';
                this.imodel = [];
            }

            this.url = uri.href();
            this.tree.reload();
        },

        filterInstanceTypes(model) {
            if (this.deferred) {
                this.switchToFull();
            }

            const uri = new URI(this.url);

            const instanceTypes = model.join(',');
            uri.setQuery('instance_types', instanceTypes);

            this.imodel = [];

            this.url = uri.href();
            this.tree.reload();
        },

        onSelectLoading(event) {
            event.preventDefault();
            const uri = URI(this.url);
            if (this.display_type === 'server_group' && !uri.hasQuery('only_groups')) {
                uri.addQuery('only_groups', 'true');
                this.tree_label = 'Server Groups';
            } else {
                uri.removeQuery('only_groups');
                this.tree_label = 'Servers';
            }
            this.url = uri.href();
            this.tree.reload();
        },

        massageData(data) {
            return data.map(node => {
                // Delete empty children
                if (node.children) {
                    if (node.children.length === 0) {
                        delete node.children;
                    } else {
                        node.children = this.massageData(node.children);
                    }
                }

                if (this.numericIds) {
                    node.id = Number(node.id.match(/[0-9]+/)[0]);
                }

                return node;
            });
        },

        switchToFull() {
            // Switch tree from deferred to full
            this.deferred = false;
            this.tree.opts.data = this.treeData;
            this.url = this.url.replace('_deferred', '');
        },

        removeTitleNodeListeners() {
            if (!this.tree) {
                return;
            }

            this.tree.recurseDown((node) => {
                if (node.itree && node.itree.ref) {
                    const hoverEl = node.itree.ref.querySelector('.title-wrap');

                    if (hoverEl) {
                        hoverEl.removeEventListener('mouseenter', this.titleWrapMouseEvent);
                    }
                }
            });
        },

        titleWrapMouseEvent(event, node) {
            const hoverEl = event.currentTarget;
            const contentEl = hoverEl.querySelector(':scope > .title');
            let innerText = this.sanitize(contentEl.innerText);
            const nodeName = `<span title="${innerText}">${innerText}</span>`;
            const els = [];

            if (node.icon_title) {
                let iconTitleClassName = "pseudo-icon-title";

                if (
                    Boolean(contentEl.previousElementSibling) &&
                    contentEl.previousElementSibling.type === "checkbox"
                ) {
                    iconTitleClassName = `${iconTitleClassName} pseudo-icon-title--with-checkbox`;
                }
                const iconTitle = `<span title="${node.icon_title}" class="${iconTitleClassName}"></span>`;

                els.push(iconTitle);
            }

            els.push(nodeName);

            contentEl.innerHTML = els.join('');
        },

        addTitleToNodes() {
            this.$nextTick(() => {
                this.tree.recurseDown((node) => {
                    if (node.itree && node.itree.ref) {
                        const hoverEl = node.itree.ref.querySelector('.title-wrap');

                        if (hoverEl) {
                            hoverEl.addEventListener(
                                'mouseenter',
                                (event) => this.titleWrapMouseEvent(event, node),
                                { once: true }
                            );
                        }
                    }
                });
            });
        },

        sanitize(string) {
            const map = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#x27;',
                "/": '&#x2F;',
            };
            const reg = /[&<>"'/]/ig;
            return string.replace(reg, (match) => (map[match]));
        },

        _printNode(node, inTabs) {
            let tabs = inTabs;
            let sel = '';
            if (this.multiselect) {
                sel = '   ';
            }
            if (node.selected()) {
                sel = ' ✓ ';
            }
            if (typeof node.children === 'object') {
                console.log(`${'\t'.repeat(tabs)}[+]${sel}${node.text}`);
                tabs += 1;
                node.children.forEach(n => {
                    this._printNode(n, tabs);
                });
            } else if (node.children === true) {
                console.log(`${'\t'.repeat(tabs)}[+]${sel}${node.text}`);
            } else {
                console.log(`${'\t'.repeat(tabs)}   ${sel}${node.text}`);
            }
        },

        _printCurrentModel(data) {
            this.tree.nodes().forEach(n => {
                this._printNode(n, 0);
            });
        },

        // Insipre tree data functions
        treeData(node, resolve, reject) {
            if (!node) {
                if (this.url) {
                    const uri = URI(this.url);
                    if (this.include_toggles) {
                        if (this.show_servers) {
                            uri.addQuery('include_servers', '1');
                        } else {
                            uri.addQuery('include_servers', '0');
                        }

                        if (this.show_compound_services) {
                            uri.addQuery('include_compound_services', '1');
                        } else {
                            uri.addQuery('include_compound_services', '0');
                        }
                    }

                    const cacheKey = `instanceTree|${normalizeTreeUri(uri)}`;
                    let cachedNodes;
                    if (this.cacheNodes) {
                        // Fetch our local copy for this URI
                        const cache = window.app.localStorageManager.getObject(cacheKey, false);
                        if (cache) {
                            const localUserHash = cache.userHash;
                            cachedNodes = cache.nodes;
                            uri.addQuery('user_hash', localUserHash);
                        }
                    }

                    this.load(uri).then(json => {
                        const nodes = json.nodes;
                        const serverUserHash = json.userHash;

                        if (this.cacheNodes && nodes === null && serverUserHash === null) {
                            // Cache hit
                            resolve(this.massageData(cachedNodes));
                            return;
                        } else if (this.cacheNodes) {
                            // Cache miss
                            window.app.localStorageManager.setObject(cacheKey, json);
                        }

                        resolve(this.massageData(nodes));
                    });

                    return;
                }

                resolve(this.data);

                return;
            }

            this.load(node.url).then(json => {
                resolve(this.massageData(json));
            });
        },

        treeDataDeferred(node, resolve, reject) {
            if (!node) {
                if (this.url) {
                    const uri = URI(this.url);
                    const cacheKey = `instanceTreeDeferred|${normalizeTreeUri(uri)}`;
                    let cachedNodes;
                    uri.addQuery('node_id', 'root');
                    if (this.preselected.length) {
                        uri.addQuery('preselected', this.preselected.join(','));
                    }

                    if (this.cacheNodes) {
                        // Fetch our local copy for this URI
                        const cache = window.app.localStorageManager.getObject(cacheKey, false);
                        if (cache) {
                            const localUserHash = cache.userHash;
                            cachedNodes = cache;
                            uri.addQuery('user_hash', localUserHash);
                        }
                    }

                    this.load(uri).then(json => {
                        const deferredNodes = json.deferredNodes;
                        const serverUserHash = json.userHash; 
                        if (this.cacheNodes && deferredNodes === null && serverUserHash === null) {
                            // Cache hit
                            resolve(cachedNodes.deferredNodes);
                            return;
                        } else if (this.cacheNodes) {
                            // Cache miss
                            window.app.localStorageManager.setObject(cacheKey, json);
                        }
                        if (json.deferredNodes.length && this.select_nodes && !this.multiselect) {
                            // Need to change checks to selects..
                            json.deferredNodes[0].children.forEach(c => {
                                const i = c.itree;
                                if (!i) {
                                    return;
                                }
                                if (i.state && typeof i.state.checked !== 'undefined') {
                                    i.state.selected = i.state.checked;
                                    delete i.state.checked;
                                }
                            });
                        }
                        resolve(json.deferredNodes);
                        
                        // The server may send back preselected nodes
                        const selected = this.tree.selected();
                        for (let i = 0; i < selected.length; i++) {
                            const node = selected[i];
                            if (this.imodel.indexOf(node.id) < 0) {
                                this.imodel.push(node.id);
                            }
                        }
                        if (!this.multiselect) {
                            // Expand any indeterminate nodes
                            this.tree.recurseDown(node => {
                                if (node.indeterminate() && !node.expanded()) {
                                    node.expand();
                                }
                            });
                        }
                    });

                    return;
                }

                resolve(this.data);

                return;
            }

            if (node.id === 'grp-0') {
                // This might happen if the root is preselected
                // Ignore it, as we load the root explicitly
                return;
            }

            const uri = URI(this.url);
            uri.addQuery('node_id', node.id);
            if (this.preselected.length) {
                uri.addQuery('preselected', this.preselected.join(','));
            }
            this.load(uri).then(json => {
                if (json.length && this.select_nodes && !this.multiselect) {
                    // Need to change checks to selects..
                    json.forEach(c => {
                        const i = c.itree;
                        if (!i) {
                            return;
                        }
                        if (i.state && typeof i.state.checked !== 'undefined') {
                            i.state.selected = i.state.checked;
                            delete i.state.checked;
                        }
                    });
                }
                resolve(json.deferredNodes);
                // The server may send back preselected nodes
                const selected = this.tree.selected();
                for (let i = 0; i < selected.length; i++) {
                    const node = selected[i];
                    if (this.imodel.indexOf(node.id) < 0) {
                        this.imodel.push(node.id);
                    }
                }
                if (!this.multiselect) {
                    // Expand any indeterminate nodes
                    this.tree.recurseDown(node => {
                        if (node.indeterminate() && !node.expanded()) {
                            node.expand();
                        }
                    });
                }
                node.refreshIndeterminateState();
            });
        },
    },

    vueReady() {
        if (!this.$refs.tree) {
            return;
        }

        let tree_options;
        if (!this.deferred) {
            tree_options = {
                target: this.$refs.tree,
                data: this.treeData,
            };
        } else {
            tree_options = {
                target: this.$refs.tree,
                data: this.treeDataDeferred,
            };
        }

        // Setup selection options if we're in select mode
        if (this.select_nodes && !this.multiselect) {
            tree_options.selection = {
                mode: 'default',
            };
        } else if (this.select_nodes && this.multiselect) {
            tree_options.selection = {
                mode: 'checkbox',
                multiple: true,
            };
            tree_options.showCheckboxes = true;
        }

        if (!this.autoCheckChildren) {
            tree_options.checkbox = {
                ...tree_options.checkbox,
                autoCheckChildren: false,
            };
        }

        this.tree = new InspireTree(tree_options);
        this.treedom = new InspireTreeDOM(this.tree, {
            target: this.$refs.tree,
        });

        this.enable();
        this.deselect();
        this.select();
        if (this.preselectFirst) {
            this.tree.on('model.loaded', this.selectFirstNode);
        } else if (this.preselectRoot) {
            this.tree.on('model.loaded', this.selectRootNode);
        }
        else {
            this.tree.on('model.loaded', this.preselect);
        }

        this.state = this.getState();
        TreeManager.addInstance(this);

        if (this.filtering) {
            // First grab our copy from local storage (if exists)
            const localAvailableMetrics =
                window.app.localStorageManager.getObject('instanceTypes.options', {});

            const payload = Object();
            if (localAvailableMetrics) {
                payload.version = localAvailableMetrics.version;
                payload.cache_timestamp = localAvailableMetrics.timestamp;
                payload.cache_customer = localAvailableMetrics.customerId;
            }

            $.ajax({
                url: '/util/customer_instance_types',
                data: payload,
            }).done(data => {
                if (!data.success) {
                    return;
                }
                if (data.new_value) {
                    this.instanceTypeOptions = data.instance_type_options;
                    // Store the new copy
                    const cacheObj = {
                        version: data.endpoint_version,
                        options: data.instance_type_options,
                        timestamp: data.timestamp,
                        customerId: data.customer_id,
                    };
                    window.app.localStorageManager
                        .setObject('instanceTypes.options', cacheObj);
                } else {
                    this.instanceTypeOptions = localAvailableMetrics.options;
                }
            });
        }
    },

    beforeDestroy() {
        this.removeTitleNodeListeners();
    },

        watch: {
            filter(newValue) {
                if (!newValue && !this.useDeferred) {
                    this.tree.clearSearch();
                    return;
                }
                if (this.useDeferred) {                    
                    console.log('calling deb');
                    this.debounceSearch();
                    return;
                }

                this.tree.search(this.filter.trim());
            },
        },
    });

function normalizeTreeUri(uri) {
    // Returns a URI with query parameters sorted
    const queryArgs = uri.query(true);
    const keys = Object.keys(queryArgs).sort();

    let queryResult = '?';
    for (const key of keys) {
        if (queryArgs.hasOwnProperty(key)) {
            const val = queryArgs[key];
            if (queryResult !== '?') {
                queryResult += '&';
            }
            queryResult += `${key}=${val}`;
        }
    }

    return `${uri.path()}${queryResult}`;
}

export default Tree;
</script>