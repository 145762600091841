<template>
    <router-link
        :to="to"
        v-bind="$attrs"
        class="app-link"
        active-class="app-link__active"
    >
        <slot></slot>
    </router-link>
</template>

<script>
import Vue from "vue";
import { listen, push } from "../utils/history";

export default Vue.extend({
    props: {
        to: {
            type: String,
            default: '',
        },
        activeClassName: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        currentRoute: null,
        isActive: false,
    }),
    watch: {
        currentRoute(curr) {
            this.isActive = this.to === curr;
        },
    },
    computed: {
        computedClassName() {
            const className = {
                "app-link": true,
                "app-link__active": this.isActive,
            };

            if (this.activeClassName) {
                className[this.activeClassName] = true;
            }

            return className;
        },
    },
    methods: {
        goTo() {
            push(this.to);
        },
        setupRouteListening() {
            listen((route, previousRoute) => {
                this.currentRoute = route.split("?")[0];
            });

            // Listens for browsers back and forth buttons
            window.addEventListener("popstate", this.onPopState);
        },
        onPopState() {
            this.currentRoute = window.location.pathname;
        },
        removeListeners() {
            window.removeEventListener("popstate", this.onPopState);
        },
    },
    beforeDestroy() {
        this.removeListeners();
    },
    created() {
        this.currentRoute = window.location.pathname;
        this.setupRouteListening();
    },
});
</script>
