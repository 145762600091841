<style lang="scss">

.grey-disable .ql-editor {
    background-color: #d5d8df;
    opacity: 0.7;
}

.ql-blank {
    color: grey !important;
    font-size: 0.6470588235rem !important;
}

.grey-disable .ql-blank  {
    color: black !important;
    font-size: 1rem !important;
}


</style>

<template>
	<vue-editor 
        v-model="iModel" 
        :editorToolbar="toolbar" 
        :id="id" 
        :placeholder="placeholder"
        :disabled="disabled"
        class="html-editor-content"
        :class="disabled ? 'grey-disable': ''"
    >        
    </vue-editor>
</template>


<script>
import Vue from 'vue';
import { VueEditor } from "vue2-editor";


export default Vue.extend({
    components: {
        VueEditor
    },
    props: {
    	model: {
            type: String,
            default: () => ""
        },
        placeholder: {
        	type: String,
            default: () => ""
        },
        id: {
        	type: String,
            default: () => "html-editor"
        },
        toolbar: {
            type: Array,
            default: () => [ 
            	[{ header: [false, 1, 2] }], 
            	["bold", "italic", "underline", "strike"], 
            	[{ list: "ordered" }, { list: "bullet" }], 
            	["code-block"]
        	]
        },
        disabled: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            iModel: _.clone(this.model),
        };
    },
    watch:{
        iModel: function(newValue) {
            this.$emit('update:model', newValue)
        },
        model: function(newValue) {
            this.iModel = newValue
        }
    }
});
</script>
