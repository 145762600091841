<template>
    <ul class="attribute-list pa-hList">
        <slot></slot>
        <li v-if="!attributes || attributes.length === 0">
            None
        </li>
        <p-attribute-list-item
            :key="index"
            v-for="(attribute, index) in filteredAttributes"
            :attribute="attribute"
            :can-edit-instance="canEditInstance"
        >
        </p-attribute-list-item>
    </ul>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';

export default Vue.extend({
    props: {
        attributes: Array,
        maxAttributes: Number,
        instanceId: [String, Number],
        canEditInstance: Boolean,
    },
    computed: {
        filteredAttributes: function() {
            if (this.maxAttributes && this.attributes && this.attributes.length) {
                return this.sortAttributesCaseInsensitivelyOnKeys.slice(0, this.maxAttributes);
            } else {
                return this.sortAttributesCaseInsensitivelyOnKeys;
            }
        },
        sortAttributesCaseInsensitivelyOnKeys: function(){
          const attributesToSort = [... this.attributes]
          return _.sortBy(attributesToSort, function (attributeToSort) {
            return attributeToSort.serverAttributeType.name.toLowerCase();
          });
        },
    },
});
</script>
