<template>
    <p-modal id="create_server_template_modal">
        <div class="pa-panel" style="width:621px;" v-show="!fetching">
            <div ref="createTemplateTop"></div>
            <div class="pa-panel-hd pa-pl-12">
                <h2 class="pa-hdg pa-hdg_3" slot="header"> 
                    <template v-if="instanceId === null">Add</template>
                    <template v-else>Create</template>
                    Template
                </h2>
            </div>
            <div class="pa-panel-bd" ref="createTemplateBody">
                <p-alert
                    class="extended-alert pa-mb-16 pa-pt-16"
                    v-if="hasErrors"
                    variant="red"
                    full-width
                    icon="critical-outline"
                    icon-position="left"
                    icon-size="lg"
                    bold
                >
                    <p class="pa-m-0 pa-py-8">
                        Please check below for errors
                    </p>
                    <p class="pa-txt_14">
                    </p>
                </p-alert>
                <div class="fields-wrapper">
                    <p-field-group
                        name="template_name"
                        label="Template Name*"
                    >
                        <p-input
                            v-model="templateName"
                            name="template_name"
                        >
                        </p-input>
                        <p v-show="errors['error::template_name']" class="pa-hint pa-hint_error">
                            {{ errors['error::template_name'] }}
                        </p>
                    </p-field-group>
                    <p-field-group
                        name="template_type"
                        label="Template Type*"
                    >
                        <p-select
                            :model.sync="templateType"
                            :options="templateTypeOptions"
                        >
                        </p-select>
                        <p v-show="errors['error::template_type']" class="pa-hint pa-hint_error">
                            {{ errors['error::template_type'] }}
                        </p>
                    </p-field-group>
                    <p-field-group
                        name="instace_group"
                        label="Instance Group"
                    >
                        <label class="pa-option">
                            <input v-model="instanceGroup" type="radio" value="no" class="pa-option-input" />
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt">
                                Existing
                            </span>
                        </label>
                        <label class="pa-option pa-pl-8">
                            <input v-model="instanceGroup" type="radio" value="yes" class="pa-option-input" />
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt">
                                New
                            </span>
                        </label>
                    </p-field-group>
                    <template v-if="instanceGroup == 'yes'">
                        <p-field-group
                            name="instance_group_name"
                            label="Instance Group Name*"
                        >
                            <p-input
                                v-model="instanceGroupName"
                                name="instance_group_name"
                            >
                            </p-input>
                            <p v-show="errors['error::instance_grp_name']" class="pa-hint pa-hint_error">
                                {{ errors['error::instance_grp_name'] }}
                            </p>
                        </p-field-group>
                    
                        <p-field-group
                            label="Alert Timeline"
                            name="alertTimelines"
                            class="pa-pb-12"
                        >
                            <p-select
                                :options="alertTimelineOptions"
                                defaulttext="Select"
                                :model.sync="alertTimeline"
                            >
                            </p-select>
                        </p-field-group>
                    </template>
                    <p-field-group
                        label="Parent Group*"
                        class="pa-pb-24"
                    >
                        <p v-show="errors['error::server_group_id']" class="pa-hint pa-hint_error pa-pb-8">
                            {{ errors['error::server_group_id'] }}
                        </p>
                        <p-tree
                            v-if="treeReady"
                            ref="parent_group_id"
                            id="parent_group_id"
                            class="group-tree"
                            url='/util/monitoring_tree?only_groups=true&include_compound_services=false&selectable_root=0'
                            :model.sync="parentGroup"
                            :preselected="preselected"
                            :multiselect="false"
                            :deferred="false"
                            select_nodes
                            required="required"
                            show-search
                        >
                        </p-tree>
                        
                    </p-field-group>
                </div>
            </div>

            <div class="pa-panel-ft">
                <ul class="pa-split">
                    <li></li>
                    <li>
                        <p-flex
                            class="pa-align-center"
                        >
                            <button
                                class="pa-btn pa-btn_cancel pa-mr-2"
                                type="button"
                                @click="close()"
                            >
                                Close
                            </button>
                            <button
                                class="pa-btn"
                                style="height: 35px; min-width: 136px;"
                                type="submit"
                                @click.prevent="submit"
                                :disabled="submitting"
                                value="Create Template"
                            >
                            <p-loading-spinner-2 v-if="submitting" color="#fff" style="height: 20px; width: 20px;"></p-loading-spinner-2>
                                <template v-else>
                                    Create Template
                                </template>
                            </button>
                        </p-flex>
                    </li>
                </ul>
            </div>
        </div>
    </p-modal>
   
</template>

<script>
import Vue from "vue";
import axios from 'axios';

export default Vue.extend({
    props: {
        instanceId: {
            type: Number,
            default: () => null,
        },
        stayOnPage: {
            type: Boolean,
            default: () => false,
        },
        callback: {
            type: Function,
            default: () => null,
        },
        defaultTemplateType: {
            type: String,
            default: () => "generic_template",
        }
    },

    data: function() {
        return {
            fetching: true,
            instanceGroup: 'no',
            parentGroup: [],
            templateName: "",
            instanceGroupName: "",
            templateType: "generic_template",
            alertTimelineOptions: [],
            alertTimeline: 0,
            templateTypeOptions: [],
            preselected: null,
            submitting: false,
            hasErrors: false,
            errors: {},
            treeReady: false,
        }
    },
    
    
    methods: {

        close: function() {
            this.$broadcast('dialog:close', 'create_server_template_modal');
        },

        showMessage(template_name, template_url) {
            window.app.toastManager.showToast({
                icon: 'check',
                type: 'success',
                hreftext: "Template Successfully Added - ",
                hreflink: template_url,
                template_name: template_name
            })
        },

        initData() {
            axios.get('/config/get_create_server_template_data', {
                params: {
                    "instance_id": this.instanceId
                },
            }).then(response => {
                if (!response.data.success) {
                    if(response.data.toast_msg) {
                        window.app.toastManager.clearToasts();
                        window.app.toastManager.showToast({
                            text: response.data.toast_msg,
                            type: 'error',
                            icon: 'alert-circle'
                        });
                    }
                    this.close();
                }
                this.submitting = false;
                this.templateTypeOptions = [...response.data.template_type_options];
                if (this.templateTypeOptions.length === 1) {
                  this.templateType = this.templateTypeOptions[0].value;
                }
                this.templateName = response.data.template_name;
                this.instanceGroupName = response.data.template_name;
                this.alertTimelineOptions = [...response.data.alert_timeline_options];
                this.preselected = response.data.preselected;
                this.fetching = false;
                this.$nextTick(() => {
                    this.treeReady = true;
                });
            });
        },

        submit: function() {
            this.submitting = true;
            this.errors = {};
            this.hasErrors = false;

            axios.post('/config/createServerTemplate', {
                server_id: this.instanceId,
                template_name: this.templateName,
                template_type: this.templateType,
                select_options: this.instanceGroup,
                instance_grp_name: this.instanceGroupName,
                notification_schedule: this.alertTimeline,
                element_ids: this.parentGroup[0],
            }).then(response => {
                if(response.data.success) {
                    this.close();
                    window.app.toastManager.clearToasts();
                    this.showMessage(response.data.template_name, response.data.template_url);
                    if(response.data.template_url && this.stayOnPage !== true) {
                        window.location = response.data.template_url;
                    }
                    if(this.callback) {
                        this.callback();
                    }
                } else {
                    this.submitting = false;
                    if(response.data.validation_errors) {
                        this.hasErrors = true;
                        this.errors = response.data.validation_errors;
                        Vue.nextTick(() => {
                            var element = this.$refs.createTemplateTop;
                            const positionFromTopOfScrollableDiv = element.offsetTop;
                            const scrollableDivElement = this.$refs.createTemplateBody;
                            scrollableDivElement.scrollTop = positionFromTopOfScrollableDiv
                        });
                    }
                    if(response.data.reload === true) {
                        window.location.reload()
                    }
                }
            }).catch(function (error) {
                console.log(error);
            });

        }
    },

    mounted() {
        this.templateType = this.defaultTemplateType;
    },
});
</script>


