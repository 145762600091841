<style>
 .container {
     height: 100%;
     width: 100%;
 }
 .heatmap-cell {
    border: solid 2px rgba(0, 0, 0, 0.05);
 }
 .heatmap-cell:hover {
    border: solid 2px rgba(0, 0, 0, 0.01);
 }
 .heatmap-cell--normal:hover {
    fill: #5de8ae;
 }
 .heatmap-cell--warning:hover {
    fill: #ffc44c;
 }
 .heatmap-cell--critical:hover {
    fill: #ff334b;
 }
</style>

<template>
    <div ref="body" class="container">
    </div>
</template>

<script>
 import Vue from 'vue';
 import $ from 'jquery';

 const Highcharts = require('highcharts');
 require('highcharts/modules/heatmap')(Highcharts);

 let resizeTimer;

 const MAX_REFLOW_ATTEMPTS = 5000;

 const Heatmap = Vue.extend({

     data() {
         return {
             reflowAttempts: 0,
         }
     },

     props: {
         title: {
             type: String,
             default: ''
         },
         xAxisCategories: {
             type: Array,
             default: function() {
                 return []
             }
         },
         yAxisCategories: {
             type: Array,
             default: function(){
                 return []
             }
         },
         enableLegend: {
             type: Boolean,
             default: true
         },

         series: {
             type: Array,
             default: function() {
                 return []
             }
         },

         dataLabels: {
             type: Boolean,
             default: true
         }
     },

     computed: {
         hasReachedMaxReflowAttempts() {
             return this.reflowAttempts >= MAX_REFLOW_ATTEMPTS;
         },
     },

     methods: {

         createMap(options) {
            this.chart = Highcharts.chart(this.$refs.body, options);
         },

         insertData(data){
            this.chart.series[0].setData(data);
            this.reflowChart();
         },

         reflowChart(height, width) {            
            if (this.hasReachedMaxReflowAttempts) {
                console.error('Reached max reflow attempts')
                return;
            }

            const hasData = this.chart && this.chart.series[0].data.length;

            if (this.chart && hasData) {
                const dataLength = this.chart.series[0].data.length;
                const chartContainerHeight = $(this.chart.container).height();
                const chartContainerWidth = $(this.chart.container).width();
                const chartContainerArea = chartContainerWidth * chartContainerHeight;
                const maxCellArea = chartContainerArea / dataLength;
                const maxCellSize = Math.sqrt(maxCellArea);
                const maxCellCountHorizontal = Math.ceil(chartContainerWidth / maxCellSize);

                // Assign x, y values for formatting the heatmap
                const chartData = this.chart.series[0].data.map((item, index) => {
                    const row = Math.floor(index / maxCellCountHorizontal) + 1;
                    const column = (row > 1) ? ((index + 1) - ((row - 1) * maxCellCountHorizontal)) : (index + 1);
                    return {
                        ...item,
                        x: column,
                        y: row
                    }
                });

                this.chart.series[0].setData(chartData);

                this.chart.setSize(null, null, false);

                this.chart.reflow();

                // Post-reflow dimensions
                const newChartContainerHeight = $(this.chart.container).height();
                const newChartContainerWidth = $(this.chart.container).width();

                if (
                    newChartContainerHeight !== chartContainerHeight ||
                    newChartContainerWidth !== chartContainerWidth
                ) {
                    this.reflowChart();
                }
             }

             this.reflowAttempts += 1;
        },

        onResize() {
             this.reflowChart();
         },        
     },

     created() {
        // So we dont call this until the resizing is done
        window.addEventListener('resize', () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(this.onResize, 100);
        });
    },

    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },    

     vueReady() {
         const self = this;
         this.options = {
             chart: {
                 type: 'heatmap',
                 plotBorderWidth: 1,
                 plotBorderColor: '#FFFFFF',
                 spacing: [0, 0, 0, 0],
             },
             title: {
                 text: this.title,
             },
             credits: {
                 enabled: false
             },
             exporting: {
                 enabled: false
             },
             plotOptions: {
                series: {
                    states: {
                        hover: {
                            enabled: false
                        }
                    },
                    turboThreshold: 2000,
                    events: {
                        click: function(e) {
                            const server_id = e.point.server_id;
                            const name = e.point.name;
                            const type = e.point.type;
                            const appliance_id = e.point.appliance_id;
                            const compound_service_id = e.point.compound_service_id;
                            const metric_id = e.point.metric_id;
                            const port = e.point.port;
                            let url = null;

                            if (appliance_id) {
                                if (!self.$parent.userCanView('configuration.onsight.view')) {
                                    return;
                                }                                
                                window.app.rootVue.$broadcast(
                                    'instance-details-drawer:open',
                                    {
                                        id: appliance_id,
                                        name: name,
                                        instance_type: type,
                                        active_outages: [],
                                    }
                                );
                                
                            } else if (compound_service_id) {
                                if (!self.$parent.userCanView('configuration.compound_service.view')) {
                                    return;
                                }                                
                                window.app.rootVue.$broadcast(
                                    'instance-details-drawer:open',
                                    {
                                        id: compound_service_id,
                                        name: name,
                                        instance_type: type,
                                        active_outages: [],
                                    }
                                );
                            } else if (server_id) {
                                if (!self.$parent.userCanView('configuration.server.view')) {
                                    return;
                                }                                
                                window.app.rootVue.$broadcast(
                                    'instance-details-drawer:open',
                                    {
                                        id: server_id,
                                        name: name,
                                        instance_type: type,
                                        active_outages: [],
                                    }
                                );                            
                            }
                        },
                    },
                }
            },
             xAxis: {
                 categories: this.xAxisCategories,
                 lineColor: "#FFFFFF",
                 tickColor: "#FFFFFF",
                 gridLineColor: "#FFFFFF",
                 labels: {
                     enabled: false
                 },
                 tickLength: 0
             },
             yAxis: {
                 categories: this.yAxisCategories,
                 lineColor: "#FFFFFF",
                 gridLineColor: "#FFFFFF",
                 labels: {
                     enabled: false,
                 },
                 title: null,
                 reversed: {
                     enabled: true,
                 },
             },
             legend: {
                 enabled: this.enableLegend,
                 shadow: true,
             },
             series: [
                 {
                     pointPadding: 2,
                     borderColor: 'black',
                     borderWidth: 0.2,
                     dataLabels: {
                         enabled: this.dataLabels,
                         color: '#FFFFFF',
                         format: '{point.name}'
                     }
                 }
             ],
            tooltip: {
                 shadow: {
                    color: 'rgb(62, 64, 65)',
                    opacity: 0.1,
                    offsetX: 0,
                    offsetY: 1,
                    width: 4,
                },
                backgroundColor: '#ffffff',
                borderWidth: 0,
                borderRadius: 5,
                padding: 6,
                useHTML: true,
                headerFormat: '',
                formatter: function() {
                    const splitName = this.point.name.split("<br/>");
                    const ret = `
                    <div class='pa-flex'>
                        <span style='font-size: 22px; color: ${this.point.options.color}; line-height: 11px;'>
                            \u25CF
                        </span>
                        <div style='padding-left: 6px; padding-right: 20px;'>
                            <p class='pa-txt_medium' style='color: #3c3d3e; margin-bottom: 4px;'>
                                ${splitName[0]}
                            </p>
                            <p class='pa-txt_normal'>
                                ${splitName[1] ? splitName[1] : ''}
                            </p>
                        </div>
                    </div>
                    `;
                    return ret;
                },
             },
         };
         this.createMap(this.options);
     }
 });

 export default Heatmap;
</script>
