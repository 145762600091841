<style lang="scss">
    .traceroute-tooltip {
        border: none;
        border-radius: 0px;
        font-family: "Inter", sans-serif;
        color: #394449;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 #B3B3B3;
        position: absolute;
        // Covers the sticky metric perf header on the incident details page
        z-index: 11;

        .pa-hr {
            height: 1px;
            border: none;
            background-color: #e6e6e6;
            color: #e6e6e6;
            width: 100%;
        }
    }

    .edge-tooltip-wrapper {
        height: 120px;
    }

    .tooltip-arrow:after,
    .tooltip-arrow:before {
        top: 100%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-top: 0.600rem solid #fff;
        border-left: 0.600rem solid transparent;
        border-right: 0.600rem solid transparent;
    }

    .tooltip-arrow:after {
        border-top: 0.600rem solid #fff;
        border-left: 0.600rem solid transparent;
        border-right: 0.600rem solid transparent;
    }

    .tooltip-arrow:before {
        border-top: 0.630rem solid #d5d8df;
        border-left: 0.630rem solid transparent;
        border-right: 0.630rem solid transparent;
    }

    .tooltip-arrow-left:after {
        left: 26%;
        margin-left: -19px;
    }

    .tooltip-arrow-left:before {
        left: 26%;
        margin-left: -20px;
    }

    .tooltip-arrow-right:after {
        right: 50%;
        margin-right: -120px;
    }

    .tooltip-arrow-right:before {
        right: 50%;
        margin-right: -121px;
    }
</style>

<template>
    <div
        ref="$el"
        class="traceroute-tooltip pa-txt_sm"
        :class="computedClass"
        :style="computedStyle"
        @mouseover="handleMouseOver()"
        @mouseleave="handleMouseLeave()"
    >
        <div v-if="props.type == 'edge'" class="edge-tooltip-wrapper pa-mt-8">
            <p-row class="pa-pl-16" style="height: 50%; align-items: center;">
                <p-column :cols="props.showTraceroutesHistoricalData ? 4 : 6">
                    <div>Packet Loss</div>
                </p-column>
                <p-column :cols="props.showTraceroutesHistoricalData ? 3 : 6">
                    <template v-if="node.target_unidentifiable == true">
                        <span>Unknown</span>
                    </template>
                    <template v-else>
                        <template v-if="node.loss > 0">
                            <div class="" style="color: crimson;">{{ Math.round(node.loss) }}%</div>
                        </template>
                        <template v-else>
                            <div>{{ Math.round(node.loss) }}%</div>
                        </template>
                    </template>
                </p-column>
                <p-column cols="5" v-if="props.showTraceroutesHistoricalData">
                    <p-traceroute-tooltip-graph
                        type="loss"
                        :node="props.node"
                        :traceroute-data="tracerouteData"
                    ></p-traceroute-tooltip-graph>
                </p-column>
            </p-row>
            <p-row class="pa-pl-16" style="height: 50%; align-items: center;">
                <p-column :cols="props.showTraceroutesHistoricalData ? 4 : 6">
                    <div>Latency</div>
                </p-column>
                <p-column :cols="props.showTraceroutesHistoricalData ? 3 : 6">
                    <template v-if="node.target_unidentifiable == true">
                        <span>Unknown</span>
                    </template>
                    <template v-else>
                        <div>{{ Math.round(node.latency) }}ms</div>
                    </template>
                </p-column>
                <p-column cols="5" v-if="props.showTraceroutesHistoricalData">
                    <p-traceroute-tooltip-graph
                        type="latency"
                        :node="props.node"
                        :traceroute-data="tracerouteData"
                    ></p-traceroute-tooltip-graph>
                </p-column>
            </p-row>
        </div>
        <template v-else>
            <div class="pa-px-16 pa-pt-16">
                <p class="pa-txt_bold pa-mb-4" v-if="node.is_target">
                    {{ node.server_name }} ({{ node.host }})
                </p>
                <p class="pa-txt_bold pa-mb-4" v-else>
                    {{ node.host || node.ip || 'Unidentifiable' }}
                </p>
                <div class="pa-txt_xs">
                    <template v-if="node.ip && node.ip != node.host">
                        <span class="pa-txt_secondary">{{ node.ip }}</span>
                        <span class="pa-mx-4">&#9642;</span>
                    </template>
                    <span class="pa-txt_secondary">{{ description }}</span>
                </div>
            </div>
            <p-divider></p-divider>
            <div class="pa-px-4">
                <dl class="pa-flex pa-flex-wrap pa-p-6" v-if="props.graphType != 'continuous'">
                    <div class="pa-grid-col_6of12">
                        <dt class="pa-m-6 pa-txt_secondary">
                            Loss
                        </dt>
                    </div>
                    <div class="pa-grid-col_6of12">
                        <dd class="pa-m-6">
                            <p-text as="span" :color="node.has_incident ? 'crimson' : undefined">
                                {{ node.loss }}%
                            </p-text>
                        </dd>
                    </div>
                    <div class="pa-grid-col_6of12">
                        <dt class="pa-m-6 pa-txt_secondary">
                            Avg Response Time
                        </dt>
                    </div>
                    <div class="pa-grid-col_6of12">
                        <dd class="pa-m-6">
                            {{ node.avg }}ms
                        </dd>
                    </div>
                </dl>
                <p-divider v-if="props.graphType != 'continuous'"></p-divider>
                <dl class="pa-flex pa-flex-wrap pa-p-6">
                    <div class="pa-grid-col_4of12">
                        <dt class="pa-m-6 pa-txt_secondary">
                            Provider
                        </dt>
                    </div>
                    <div class="pa-grid-col_8of12">
                        <dd class="pa-m-6">
                            <template v-if="node.geoip && node.geoip.aso">
                                {{ node.geoip.aso }} <template v-if="node.geoip.asn">({{ node.geoip.asn }})</template>
                            </template>
                            <template v-else>
                                <template v-if="node.is_private_network">
                                    Private Network
                                </template>
                                <template v-else>
                                    &ndash;
                                </template>
                            </template>
                        </dd>
                    </div>
                    <div class="pa-grid-col_4of12">
                        <dt class="pa-m-6 pa-txt_secondary">
                            Location
                        </dt>
                    </div>
                    <div class="pa-grid-col_8of12">
                        <dd class="pa-m-6">
                            <template v-if="location">
                                {{ location }}
                            </template>
                            <template v-else>
                                <template v-if="node.is_private_network">
                                    Internal
                                </template>
                                <template v-else>
                                    &ndash;
                                </template>
                            </template>
                        </dd>
                    </div>
                </dl>
                <p-divider></p-divider>
                <div class="pa-p-6">
                    <div class="pa-grid-col_12of12">
                        <p v-if="node.is_source" class="pa-m-6">
                            No. of hops to destination &mdash; {{ node.hops_to_target }}
                        </p>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import PTracerouteTooltipGraph from "../components/TracerouteTooltipGraph.vue";


const $el = ref(null);
const width = ref(324);
const edgeWidth = ref(300)
const offscreen = ref(false);

const latencyDemoImage = ref("iVBORw0KGgoAAAANSUhEUgAAAGcAAAAeCAIAAAC+DpenAAAAA3NCSVQICAjb4U/gAAAAGXRFWHRTb2Z0d2FyZQBnbm9tZS1zY3JlZW5zaG907wO/PgAAAq5JREFUaIHtmb9rFEEUx7/veUXuTqJnzhB/BJugSUghxP/Axh8QQUEsgppC00hKBYVUkqtCbCQQ0giCTayChY0pLAyIotFGQVAw5NcliCargplnsbuzk71s1E1uzS3zaXZvd2aW+fJ9b+bNkYjAsiEiQkTmE67KZ6oxaOJoPxmSKfeyNaqFDEtR7WoKIqoIRF5z2fwHzJ9KqS0Z9r8TmpemGhGqiKsS+NsAzw2ZTQ5jZkoREKmvzs/JyVczc+VNjrwNYF8mJiLBr46O1s6Ow1irmnKtd+/Bo8cTzwA0FQtNTXsgDACkBLwzn7184XQ+n9d9Vr47D8cn5mYX9ZOnz6cOHWxubWtJYFrVRAGr/r1388Xx/EEiYvrl7ujYx+mFs+e68rlsuVyeXyj7SZEB9fLFVMPu7I2+S27jmYWl/tLwvgPN7a0tABikIJ3HjuazuQSnlxCuSvvrAVc1/WJufunazcHS7Vu5XDBtIZCxkgwNDe8t1F3v63n99l1/afRM14mTp44DcIcRUiRpTWoAoFVb9dYEwdj4kzfvP/Ve7QEgBPa1MHEcZ2Dgzq76us+zi71XLra3HdGhLQQSBXBI6DSxjteWnZUPMz+KjQ3u5KN6Os7yyMj97u7zxcYClF4KvDAXAlR4M127hAqDStUUwNPf1reJ2ZkICiAxlBL4LzeSu+aorKVc1RjBzp4B7FBBh6Az6f2eghG2po4+XqimA+boXS4hiDJFcFdVZhLy5k8CIbBA+8j3Y1ADaJXTlNSiTjYygFc3iu+dIOiMdiQ6g5kEwRj4Li2SbQCLrEKJiFBFEW6JIgPyk5ZEFquWEEz+gpCaFJ4A7J7qEJGN0L+HmdldNBF9nGQJkYEV699Jzz4+SaxqcbCqxcGqFgerWhysanGwqsWh8u9ly5+xXovDb2OXFAjBNGQTAAAAAElFTkSuQmCC")
const lossDemoImage = ref("iVBORw0KGgoAAAANSUhEUgAAAGQAAAAdCAIAAADTrV4KAAAAA3NCSVQICAjb4U/gAAAAGXRFWHRTb2Z0d2FyZQBnbm9tZS1zY3JlZW5zaG907wO/PgAAAyVJREFUaIHtmM1vVFUYh5/3tIn0jhrCMA0YP6LxCwkBStho0FiRThNLmCEk6gL9D1gXWIJx7V8gbPiIGoI0aIljg7EUE6NxqQkQoxKYtomBGcOC83Nx7tyZTgvhYiXczH0Wd+bMOXM/nnnPe94zJgmQhJmxFEKG4cEdOfYlzvAm88Cese2FwgD4z05P3Ww2ZGx+5aVNG15c8jTAZO3i1Xq99XVn8nca+bDxwXtjQH9omJkAkLRQmsccEuaAIyfOVitlwKzv7NffjA6/VigMgPviTG3z0MZLV/5wnkSWoPNE167Pf/zJ0d3VsjfJm+vuzwCxrMRROHY8h+vSV63sDG+mzk9LSnpHdrx5/rsZ0MLxXjIzk/Tp8YlqZbSy650H8lz/Cy68dOqQlDTU0SXdBkJfOJrFIuIBZjLan0jIhea16/M//vJbeWS4fRUDMjMNA/0LWgLzZi75oOUJIbM+IEzXeNKGMXEwOpMuX/lrsnah6xqSffXtdLVSjqKIVhSbSH6qrNCeht//8PNk7eKNRnNtqbhmzSoRJ3KHPVaIdo0NE8+x2JQTn5+uRY8+AjQatwyeeerJ+tzczE+/+jjwWoEjt3JV8fVtrxICygwtvpMMEMs6enLizLkLe9/fE0VRfW52dnaW+IEcMDU983fjnw/fHbVkIYCR8lvNZvPmLQ+8veONKFoxtGXj0NZNYYDMmxwgQ5LDPHIYtF37jOV3+oGr9fmTp6YOHxofLBUlvcwLXVVEeWT7/oMftVYAQkbqTEBtWiqDKcCEYUCnKcieKcJid/nS7+vXPV8aLALOWZcpGQOFFQfG902cm5Eh4bI5if47zsyefe7pSnUstNUtwgMmSqXi/vF9peJqshkUy4KFguDPGy7ONd1F6QLq9blSqfjAbu7h4YnHobV4OxOhOLqLKWD1YC+aSnBL1qVdhJ0gjsyVkctLMNVW0C7HO7JXvLR53KLxPYUDOrY37c3KkoHWEpixynu5cCzycve01cv0aIzcH7msFOSyUpDLSkEuKwW5rBTkslKQy0pBLisFuawU5LJSEG+bb/fo/wj3Sp+DRFbOvfAvgvVB3eXASVEAAAAASUVORK5CYII=")
const emptyDemoImage = ref("iVBORw0KGgoAAAANSUhEUgAAAG8AAAAaCAIAAAA2SJVFAAAAA3NCSVQICAjb4U/gAAAAGXRFWHRTb2Z0d2FyZQBnbm9tZS1zY3JlZW5zaG907wO/PgAAALhJREFUaIHt2LEJAkEQheF5s5eeFah9iIGVGAvXghhapXAVqIGanTtjYGAq+uBQ3lfB8LO7A4vMNCHxsQf4K6rJpJpMqsmkmkyqyaSaTKrJpJpMqsmkmkyqydRkJgD9fXzjGRDqyKWbztREhLsfT+f1pssIA8Ye6Wc4/HK7rpaL/W5bay2lICIADMP90PeWqZrvg1mtddK289lU7ybfa6cr6wfSDGburrPJp53OpJpMqsmkmkyqyfQAzjhFJ6lbD0AAAAAASUVORK5CYII=")

const isMouseOver = ref(false)
const emit = defineEmits(['reset-tooltip'])

const props = defineProps({
    node: {
        type: Object,
        default: () => {},
    },
    x: {
        type: Number,
        default: 0,
    },
    y: {
        type: Number,
        default: 0,
    },
    tooltipArrow: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: "node"
    },
    graphType: {
        type: String,
        default: "incident"
    },
    showTraceroutesHistoricalData: {
        type: Boolean,
        default: false,
    },
    tracerouteData: {
        type: Object,
        default: () => {},
    },
});

if(props.showTraceroutesHistoricalData == false) {
    edgeWidth.value = 250;
}

const handleMouseOver = () => {
    isMouseOver.value = true;
};

const handleMouseLeave = () => {
    isMouseOver.value = false;
    emit("reset-tooltip");
};

const description = computed(() => {
    if (props.node.is_source) {
        return "Source";
    } else if (props.node.is_target) {
        return "Target";
    } else if (props.node.has_incident) {
        return "Hop with Packet Loss";
    }

    return "Hop";
});

const computedStyle = computed(() => {
    let y = props.y;
    if(props.node.is_source) {
        y = y - 50;
    }
    if(props.type == 'edge') {
        y = y -25;
    }
    return {
        width: props.type == "edge" ? `${edgeWidth.value}px` : `${width.value}px`,
        top: `${y}px`,
        left: `${props.x}px`,
        ...offscreen.value ? { transform: 'translateX(-200px)' } : {}
    }
});


const computedClass = computed(() => {
    let classes = [];

    if(props.tooltipArrow) {
        classes.push('tooltip-arrow');

        if(offscreen.value) {
            classes.push('tooltip-arrow-right');
        } else {
            classes.push('tooltip-arrow-left');
        }
    }



    return classes.join(" ");
});


const location = computed(() => {
    let loc_list = [];

    if (props.node.geoip) {
        if (props.node.geoip.city !== "") {
            loc_list.push(props.node.geoip.city);
        }

        if (props.node.geoip.subdivision !== "") {
            loc_list.push(props.node.geoip.subdivision);
        }

        if (props.node.geoip.country !== "") {
            loc_list.push(props.node.geoip.country);
        }
    }

    let loc = loc_list.join(", ");

    return loc;
});

const checkIfOffscreen = () => {
    const { right } = $el.value.getBoundingClientRect();
    const windowWidth = window.outerWidth;
    if ((width.value + right) > (windowWidth)) {
        offscreen.value = true;
    }
};

const appendToBody = () => {
    document.querySelector("body").appendChild($el.value);
};

onMounted(() => {
    appendToBody();
    checkIfOffscreen();
});

defineExpose({
    isMouseOver,
})
</script>
