<template>
    <form 
        onsubmit="return false;"
        action="#"
        class="pa-search"
        :class="{
            'isActive': isActive
        }">
        <div class="pa-search-box">
            <input
                type="search"
                class="pa-search-box-input"
                :placeholder="placeholder"
                :tabindex="isActive ? 0 : -1"
                v-model="model"
                ref="input"
                v-on:input="_onInput($event)" />
        </div>
        <button
            class="pa-search-btn"
            type="button"
            ref="button"
            v-on:click="_onClick($event)">
            <svg class="pa-search-btn-icon"><use xlink:href="#magnify"></use></svg>
        </button>
    </form>
</template>

<script>
    import Vue from 'vue';
    import getTransitionendEventName from '../utils/getTransitionendEventName';

    const Search = Vue.extend({
        methods: {
            close() {
                this.isActive = false;
            },

            enable() {
                this.$el.addEventListener(getTransitionendEventName(), this._onTransitionend);
            },

            disable() {
                this.$el.removeEventListener(getTransitionendEventName(), this._onTransitionend);
            },

            open() {
                this.isActive = true;
            },

            _onClick($event) {
                if (this.isActive) {
                    this.close();

                    return;
                }

                this.open();
            },

            _onInput($event) {
                this.onChange(this.model);
            },

            _onTransitionend(event) {
                const propertyName = event.propertyName;

                if (!this.isActive || propertyName !== 'opacity') {
                    return;
                }

                this.$refs.input.focus();
            }
        },

        props: {
            isActive: Boolean,

            model: String,

            onChange: {
                type: Function,
                default: function() {}
            },

            placeholder: String
        },

        vueReady() {
            this.enable();
        }
    });

    export default Search;
</script>
