<script setup>
import { ref, computed } from 'vue';

const isActive = ref(false);

const computedClasses = computed(() => {
    return {
        'pa-select pa-select pa-mr-12 pa-ml-4 pa-select_xxxxxs': true,
        'isActive': isActive.value == true
    };
})

const props = defineProps({
    width: {
        type: Number,
        defualt: 265
    }
});
</script>

<template>
    <p-flyout
        scroll-lock
        positioning="popper"
        popper-direction="bottom"
        :on-open="() => { isActive = true; }"
        :on-close="() => { isActive = false; }"
        :width="props.width"
    >
        <div
            :class="computedClasses"
            slot="trigger"
        >
            <button
                ref="button"
                class="pa-select-hd pa-select-button"
                type="button"
            >
                <span><p-icon icon="dots-vertical" />More</span>
            </button>     
            <span class="pa-select-icon"></span>
        </div>
        <div slot="content">
            <slot name="content"></slot>
        </div>
    </p-flyout>
</template>

<style lang="scss" scoped>

</style>