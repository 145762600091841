import { render, staticRenderFns } from "./Table2.vue?vue&type=template&id=48a79880&"
import script from "./Table2.vue?vue&type=script&lang=js&"
export * from "./Table2.vue?vue&type=script&lang=js&"
import style0 from "./Table2.vue?vue&type=style&index=0&id=48a79880&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports