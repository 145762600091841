<style lang="scss">
    .global-datetime-btn {
        border-color: #dcdcdc;
    }
    .global-datetime-btn + .global-datetime-btn {
        border-left: none;
    }
    .global-datetime-btn:hover,
    .global-datetime-btn.selected {
        background-color: #2c4163;
        border-color: #2c4163;
        color: white;
    }

    .global-datetime-btn {
        padding: 7px 15px;
        background: #fff;
        cursor: pointer;
        border: 1px solid #eaeaea;
        
        font-size: 13px;
        line-height: 14.25px;
    }
    .global-datetime-btn.selected {
        background-color: #2c4163;
        color: #fff;
        border: 1px solid #2c4163
    }
    .global-datetime-btn:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .global-datetime-btn:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .global-datetime-btn input {
        display: none;
    }
    .custom-slide-transition {
        transition: all .6s ease;
        margin-left: 15px;
        left: 0px;
    }
    .custom-slide-enter, .custom-slide-leave {
        margin-left: -350px;
        left: 400px;
    }
    #global_start, #global_end {
        height: 31px;
    }
</style>

<template>
    <div>
        <div style="display: flex;">
            <label
                :key="index"
                v-for="(duration, index) in scale" 
                class="global-datetime-btn pa-txt_battleship-grey"
                :class="{ selected: timescale === duration.label }"
            >
                <input type="radio" v-model="timescale" :value="duration.label">
                <span>{{duration.label}}</span>
            </label>
            <label class="global-datetime-btn pa-txt_battleship-grey" :class="{selected: timescale === 'custom'}">
                <input type="radio" v-model="timescale" value="custom">
                <svg class="pa-icon" fill="#848e99" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M19 19H5V8h14m-3-7v2H8V1H6v2H5c-1.11 0-2 .89-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2h-1V1m-1 11h-5v5h5v-5z"/>
                </svg>
            </label>
        </div>
        <div v-show="timescale === 'custom'" transition="custom-slide">
            <p-datetime
                name="global_start"
                placeholder="Start"
                css-class="pa-input_xxs"
                autocomplete="off"
            ></p-datetime>
            <p-datetime
                name="global_end"
                placeholder="End"
                css-class="pa-input_xxs"
                autocomplete="off"
            ></p-datetime>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                timescale: null,
            }
        },

        props: {
            scale: Array,
        }
    });
</script>