<style lang="scss" >
    .html-widget-iframe {
        height: 100%;
        width: 100%;
    }

    #html-widget-wrapper {
        .widget-body {
            margin-top: 30px;
        }
    }

</style>

<template>
    <div :id="id"
         :class="!inModal ? 'grid-stack-item' : 'modal-view'"
         :data-gs-x="config.col.toString()"
         :data-gs-y="config.row.toString()"
         :data-gs-width="config.width.toString()"
         :data-gs-height="config.height.toString()"
         :data-gs-auto-position="false"
         :data-gs-locked="true">
        <div
            :class="!inModal ? 'grid-stack-item-content' : 'modal-view-content'"
            @mouseover="handleContentHover(true)"
            @mouseleave="handleContentHover(false)"
            :style="{
                backgroundColor: backgroundColor,
            }"
        >
            <div id="html-widget-wrapper" style="display: flex; flex-direction: column; justify-content: space-around; height: 100%;">
                <div :class="{'widget-header': true, 'edit-mode': $parent.editing && isHovered, 'expand-mode' : !$parent.editing && isHovered && !inModal, 'in-modal': inModal}" :style="inModal ? {} :  {'position':'absolute','top':'0','left':'0','right':'0'}">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_9of12" style="flex-direction: row;">
                            <span v-if="$parent.editing">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                            </span>
                            <svg v-show="isLoading" class="pa-icon pa-icon_xl spin-8step" :style="{fill: textColor}"><use xlink:href="#spinner-spin-naked"></use></svg>                            
                            <p-tooltip2 :normal-white-space="true">
                                <span v-if="!isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate" :style="{ color: textColor }">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span v-if="isHovered" slot="trigger" class="widget-title pa-txt_medium pa-txt_sm pa-txt_truncate">
                                    <span v-if="localConfig.title" >{{ localConfig.title }}</span>
                                </span>
                                <span> {{ localConfig.title }}</span>
                            </p-tooltip2>
                        </div>
                        <div class="pa-grid-col pa-grid-col_3of12" style="flex-direction: row-reverse;">
                            <p-tooltip2>
                                <button slot="trigger"  v-show="!$parent.editing && isHovered && !inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#arrow-expand"></use>
                                    </svg>
                                </button>
                                <span> Expand </span>
                            </p-tooltip2>
                            <button slot="trigger"  v-show="!$parent.editing && inModal" type="button" @click="expandSelf()" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                <svg class="pa-icon remove-icon">
                                    <use xlink:href="#close"></use>
                                </svg>
                            </button>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls  && !inModal" type="button" @click="deleteSelf" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon remove-icon">
                                        <use xlink:href="#trashcan"></use>
                                    </svg>
                                </button>
                                <span> Delete </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls  && !inModal" type="button" @click="configure" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <svg class="pa-icon edit-icon">
                                        <use xlink:href="#pencil"></use>
                                    </svg>
                                </button>
                                <span> Edit </span>
                            </p-tooltip2>
                            <p-tooltip2>
                                <button slot="trigger" v-show="showWidgetControls  && !inModal" type="button" @click="cloneWidget" class="pa-btn pa-btn--unstyled pa-btn_narrow">
                                    <p-icon icon="copy" ></p-icon>
                                </button>
                                <span> Clone this Widget</span>
                            </p-tooltip2>
                        </div>
                    </div>
                </div>
                <div v-if="valid" id="body" class="widget-body" :style="{'height': inModal && '85vh'}" style="height: inherit; overflow-y: auto; flex-grow: 1; margin-bottom: 10px;">
                    <p v-if="!markup">No HTML to display</p>                    
                    <iframe v-else :id="`html-widget-iframe-${id}`" class="html-widget-iframe">
                    </iframe>                    
                </div>
                <div v-else class="widget-body" :style="{'height': inModal && '85vh'}">
                    <p class="pa-message pa-message_error">
                        {{ error_message }}
                    </p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import Vue from 'vue';
    import $ from 'jquery';
    import widgetMixin from './../widgetMixin';

    const HTMLWidget = Vue.extend({
        mixins: [widgetMixin],

        data() {
            return {
                markup: this.config.custom_controls ? this.config.custom_controls.markup : '',
                localConfig: this.config,
                inModal: false,
            };
        },

        props: {
            config: {
                type: Object,
                default() {
                    return {
                        data_source: 'monitor_point',
                        custom_controls: {},
                    };
                },
            },
        },

        watch: {
            markup(val, oldVal) {
                this.updateIframe(val)
            }
        },

        events: {
            'html-module:update_markup': function (markup, id) {
                if (id === this.id) {
                    this.markup = markup;
                }
            },
        },

        computed: {},


        methods: {
            expandSelf() {
                this.inModal = !this.inModal
            },
            getConfigModules() {
                return [
                    {type: 'p-overview-module'},
                    {type: 'p-html-module'},
                    {
                        type: 'p-custom-module',
                        options: {
                            title: '',
                            controls: [
                                {
                                    id: 'backgroundColor',
                                    label: 'Background Color',
                                    key: 'backgroundColor',
                                    type: 'color-picker',
                                    default: '#ffffff'
                                },
                            ]
                        }
                    },
                ]
            },
            getOptionModules() {
                return [

                ]
            },
            configure() {
                window.app.rootVue.$broadcast('dashboard:open_config', this);
            },

            updateIframe(value) {
                if (this.markup) {
                    const el = document.getElementById('html-widget-iframe-' + this.id);
                    if (el) {
                        var doc = el.contentWindow.document;
                        doc.open();
                        doc.write(value);
                        doc.close();
                    } 
                }
            }
        },

        mounted() {
            if (this.id < 0) {
                this.configure();
            } else {
                this.updateIframe(this.markup)
            }
        },
    });

    export default HTMLWidget;
</script>
