<style lang="scss">
    @import "~styles/core/helpers/_variables.scss";

    #forgot-password-body {
        margin-top: 40px;
    }
</style>

<template>
    <div id="forgot-password">
        <div class="auth-header">
            <img src="/static/images/FortiMonitor-dark.png" class="logo" style="width: 60px; align: center; margin-top: 25px;"/>
            <h2 class="title pa-mt-24">Forgot your Password?</h2>
            <p class="pa-txt_lg pa-mt-12">Enter your email below and we'll send you instructions on how to reset your password</p>
        </div>

        <div id="forgot-password-body">
            <form ref="form" action="/ResetPassword" method="POST">
                <p-field-group
                    name="email_address"
                    label="Email"
                    class="pa-field_error-icon"
                    :error="errors.email_address || ''">
                    <p-input
                        name="email_address"
                        :value="fields.email_address"
                        type="text"
                        theme="naked"
                        error="errors.email_address">
                    </p-input>
                </p-field-group>

            </form>

            <button @click="submit" class="pa-btn pa-btn_block pa-btn_blue submit-button pa-mt-32">
                <span class="pa-txt_xxl">Send email</span>
            </button>

            <p class="pa-txt pa-txt_sm pa-mt-8">
                <a @click="back" class="pa-clickable">&lt; Back to login</a>
            </p>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';

    export default Vue.extend({
        props: {
            brand_name: String,
            brand_textkey: String,
            errors: {
                type: Object,
                'default': () => Object(),
            },
            fields: {
                type: Object,
                'default': () => Object(),
            },
        },

        data() {
            return {
                // Form inputs
                iFields: _.cloneDeep(this.fields),
            };
        },

        methods: {
            submit() {
                this.$refs.form.submit();
            },

            back() {
                this.eventHub.$emit('auth:switchType', 'login');
            },
        },
    });
</script>
