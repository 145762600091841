var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pa-side-nav__header pa-p-10 pa-pt-12",class:!_setup.isOpen ? 'pa-ml-6' : ''},[(_setup.hasLogo)?[(_vm.canSwitchCustomer)?_c('p-flex',{attrs:{"align-center":""}},[_c('div',[_c('img',{class:[
                        'pa-img',
                        'pa-side-nav__header-logo',
                        !_setup.isOpen ? 'pa-side-nav__header-logo--collapsed' : ''
                    ],style:({
                        width: _setup.logoObj.width,
                        height: _setup.logoObj.height,
                    }),attrs:{"src":_setup.logoObj.src}}),_vm._v(" "),_c('div',{staticClass:"pa-side-nav__header-customer-tooltip"},[_c('span',{staticClass:"pa-side-nav__header-brand pa-txt_truncate",attrs:{"title":_vm.brandName}},[_vm._v("\n                        "+_vm._s(_vm.brandName)+"\n                    ")]),_vm._v(" "),_c('span',{staticClass:"pa-side-nav__header-customer pa-txt_truncate",attrs:{"title":_vm.customerName}},[_vm._v("\n                        "+_vm._s(_vm.customerName)+"\n                    ")])])]),_vm._v(" "),_c('p-button',{staticClass:"pa-side-nav__switch-customer pa-flex pa-align-center",attrs:{"unstyled":"","no-base-class":""},on:{"click":_setup.switchCustomer}},[_c('div',{staticClass:"pa-txt_left"},[_c('span',{staticClass:"pa-side-nav__header-brand pa-txt_truncate",attrs:{"title":_vm.brandName}},[_vm._v("\n                        "+_vm._s(_vm.brandName)+"\n                    ")]),_vm._v(" "),_c('span',{staticClass:"pa-side-nav__header-customer pa-txt_truncate",attrs:{"title":_vm.customerName}},[_vm._v("\n                        "+_vm._s(_vm.customerName)+"\n                    ")])]),_vm._v(" "),_c('p-icon',{staticClass:"pa-ml-auto",attrs:{"icon":"chevron-down","size":"xxxl"}})],1)],1):_c('a',{staticClass:"pa-side-nav__header-logo-wrapper",attrs:{"href":_vm.logoLink,"title":_vm.brandName}},[_c('img',{class:[
                    'pa-img',
                    'pa-side-nav__header-logo',
                    !_setup.isOpen ? 'pa-side-nav__header-logo--collapsed' : ''
                ],style:({
                    width: _setup.logoObj.width,
                    height: _setup.logoObj.height,
                }),attrs:{"src":_setup.logoObj.src}}),_vm._v(" "),(_setup.isOpen)?_c('span',{staticClass:"pa-side-nav__header-brand pa-ml-8 pa-txt_truncate"},[_vm._v("\n                "+_vm._s(_vm.brandName)+"\n            ")]):_vm._e()])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }