<template>
    <p-menu>
        <p-button
            slot="trigger"
            class="pa-btn"
            :disabled="disabled"
            :variant="variant"
        >
            <p-icon
                v-if="icon"
                :icon="icon"
                color="light"
                class="pa-mr-4"
            >
            </p-icon>
            {{ text }}
            <p-icon
                v-if="icon"
                icon="chevron-down"
                color="light"
            >
            </p-icon>
        </p-button>
        <slot></slot>
    </p-menu>
</template>

<script>
import Vue from 'vue';

const menuMixin = require('../mixins/menuMixin');

export default Vue.extend({
    mixins: [menuMixin]
});
</script>
