<template>
    <ul class="drawer-active-maint-list pa-vList">
        <slot></slot>
        <li v-if="(!maintenance || maintenance.length === 0)  &&
                (!scheduledMaintenance || scheduledMaintenance.length === 0)"
            class="pa-txt_13 pa-txt_secondary pa-pt-16"
        >
            No active or scheduled maintenance
        </li>
        <!-- to avoid dupe issues on console updated key -->
        <p-drawer-active-maint-list-item
            :key="String(index)+String(maint.id)"
            v-for="(maint, index) in maintenance"
            :maint="maint"
        >
        </p-drawer-active-maint-list-item>
        <p-drawer-scheduled-maint-list-item
            :key="String(index)+String(maint.id)"
            v-for="(maint, index) in scheduledMaintenance"
            :maint="maint"
        >
        </p-drawer-scheduled-maint-list-item>
    </ul>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            maintenance: Array,
            scheduledMaintenance: Array,
        },
    });
</script>
