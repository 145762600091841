<style lang="scss">
.pa-hr {
    height: 1px;
    border: none;
    background-color: #e6e6e6;
    color: #e6e6e6;

    &--dark {
        color: var(--p-grey-400);
        background-color: var(--p-grey-400);
    }

    &--vertical {
        height: 100%;
        margin-block-start: auto;
        margin-block-end: auto;
        margin-inline-start: 0.5em;
        margin-inline-end: 0.5em;
    }
}

</style>

<template>
    <hr
        :class="computedClass"
        role="separator"
        :aria-orientation="verticalAlign ? 'vertical' : 'horizontal'"
        :style="computedStyles"
    />
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        variant: String,
        verticalAlign: {
            type: Boolean,
            default: false,
        },
        width: {
            type: Number,
            default: 100,
        }
    },
    computed: {
        computedClass() {
            return {
                "pa-hr": !this.variant,
                "pa-hr--vertical": this.verticalAlign,
                ...(this.variant && { [`pa-hr--${this.variant}`]: true }),
            };
        },
        computedStyles() {
            const width = this.verticalAlign
                ? `1px`
                : `${this.width}%`;

            return {
                width: width,
            }
        },
    },
});
</script>
