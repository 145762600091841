<style lang="scss">
.pa-card2 {
    background-color: white;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    box-shadow: none;

    & > .pa-card-header {
        display: flex;
        margin: 0;
        justify-content: space-between;
        align-items: center;

        & > .pa-card-header-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
    }
}
</style>


<template>
    <div class="pa-card2">
        <div
            v-if="hasHeaderSlot || hasActionsSlot"
            class="pa-card-header"
            :class="headerClass"
        >
            <header v-if="hasHeaderSlot" class="pa-card-header-wrapper">
                <slot name="card-header"></slot>
            </header>
            <div v-if="hasActionsSlot" class="pa-card-actions">
                <slot name="card-actions"></slot>
            </div>
        </div>
        <div class="pa-card-body" :class="bodyClass">
            <slot></slot>
        </div>
        <footer v-if="hasFooterSlot" class="pa-card-footer" :class="footerClass">
            <slot name="card-footer"></slot>
        </footer>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        headerClass: [String, Object, Array],
        bodyClass: [String, Object, Array],
        footerClass: [String, Object, Array],
    },
    computed: {
        hasFooterSlot() {
            return this.$slots['card-footer'];
        },
        hasActionsSlot() {
            return this.$slots['card-actions']
        },
        hasHeaderSlot() {
            return this.$slots['card-header'];
        },
    },
});
</script>