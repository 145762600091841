<template>
    <p-button
        v-if="listLength > maxListLength"
        variant="link"
        v-on:click="$emit('toggle-list')"
        :class="className"
        no-base-class
    >
        {{ toggleText }}
    </p-button>
</template>

<script>
import Vue from 'vue';

const DEFAULT_TEXT = 'Show less';

export default Vue.extend({
    props: {
        listLength: Number,
        maxListLength: Number,
        toggled: Boolean,
        className: [String, Object, Array],
    },
    computed: {
        toggleText: function() {
            if (this.toggled) {
                return DEFAULT_TEXT;
            } else {
                return `+ ${this.listLength - this.maxListLength} more...`;
            }
        },
    },
});
</script>