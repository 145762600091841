<style scoped>
    .pa-option-input-checkbox {
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
    }

    .unselect-all {
        background: #3954bf;
        height: 12px;
        width: 12px;
        color: white;
        left: 14px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<template>
    <label class="pa-option">
        <div class="unselect-all" :style="showUnselectAll ? 'z-index: 10' : 'z-index: -1'">
            <span>&dash;</span>
        </div>
        <input
            type="checkbox"
            :name="name"
            :value='value'
            v-model="iModel"
            @change="change($event.target.checked)"
            :class="checkboxClass"
        >
        <span class="pa-option-icon"></span>
        <span v-if="label" class="pa-option-txt">
            <span v-if="escape">{{ label }}</span>
            <span v-else v-html="label"></span>
        </span>
    </label>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            iModel: this.model,
        };
    },

    computed: {
        checkboxClass() {
            return {
                'pa-table-row-checkbox': this.rowCheck,
                'pa-option-input': true,
                'pa-table-check-all': this.checkAll,
            };
        },
    },

    watch: {
        model(val) {
            this.iModel = val;
        },
    },

    methods: {
        change(value) {
            this.$emit('update:model', value);
            this.onChangeCallback();
        }
    },

    props: {
        model: Boolean,
        checkAll: Boolean,
        rowCheck: Boolean,
        name: String,
        label: String,        
        value: String,
        showUnselectAll: Boolean,
        escape: {
            type: Boolean,
            default: true
        },
        onChangeCallback: {
            type: Function,
            'default': () => {},
        },
    },
});
</script>
