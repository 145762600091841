const bitMultiplierMap = new Map([
    ["b/s", 1],
    ["bits/s", 1],
    ["bytes/s", 8],
    ["Kbit/s", 1000],
    ["kB/s", 8000],
    ["Mbit/s", 1000000],
    ["MB/s", 8000000],
    ["Gbit/s", 1000000000],
    ["GB/s", 8000000000],
    ["Tbit/s", 1000000000000],
    ["TB/s", 8000000000000],
]);

export default function bandwidthSort(a, b, high) {
    let [valueA, bitKeyA] = matchValueAndBit(a);
    let [valueB, bitKeyB] = matchValueAndBit(b);

    valueA = valueA || high;
    valueB = valueB || high;

    const convertedValueA = parseFloat(valueA * bitMultiplierMap.get(bitKeyA));
    const convertedValueB = parseFloat(valueB * bitMultiplierMap.get(bitKeyB));

	return (
        convertedValueA < convertedValueB
        ? -1
        : convertedValueA > convertedValueB
        ? 1
        : 0
    );
}

const matchValueAndBit = (val) => {
    const result = String(val).match(/(\d+.?\d+ \w+\/s)/);

    if (result && result.length) {
        return result[0].split(" ");
    }

    return [null, "b/s"];
};
