<style lang="scss">

.line-behind {
    position: relative;
    z-index: 1;
    padding-bottom: 5px; 

    &:before {
        border-top: 1px solid #E6E6E6;
        content:"";
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;
    }

    & > span {
        background: white;
        padding-right: 8px;
    }
}

</style>

<template>
    <div class="line-behind">
        <p-text as="span" weight="bold" font-size="sm">
            {{ label }}
        </p-text>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        label: String
    },
});
</script>
