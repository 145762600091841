<template>
    <div
        class="pa-expando"
        :id="id"
        v-on:keydown.esc="close()"
        :class="classes.concat([{
            isActive: active,
        }])"
        :style="'width: ' + width + 'px; transition: width 0.30s ease-in;'">
        <div
            class="pa-expando-hd"
            type="button"
            ref="trigger"
            v-on:click="toggle()">

            <div class="pa-expando-hd-wrapper pa-py-16" :class="headerClass">
                <h2 class="pa-txt_sm pa-txt_medium pa-pl-12">
                {{ headerContent }}
            </h2>
                <template>
                    <span class="pa-expando-hd-icon chevron expando-x-icon">
                        <p-icon
                            size="xxl"
                            icon="chevron-left"
                            :color="chevronColor"
                        >
                        </p-icon>
                    </span>
                </template>
            </div>
        </div>
        <div ref="box">
            <div ref="body" :class="bodyClass">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    let COUNT = 0;

    const Expando = Vue.extend({
        data() {
            return {
                active: this.isActive,
                width: this.minWidth,
                diffWidth: this.maxWidth - this.minWidth,
            };
        },

        events: {
            'expando:open': function(id) {
                this.open();
                if (this.id === id) {
                    this.open();
                }
            },
            'expando:close': function(id) {
                if (this.id === id) {
                    this.close();
                }
            },
        },

        props: {
            id: {
                type: String,
                'default': () => {
                    return `expando_x_${COUNT++}`;
                },
            },

            pageContainsGraphs: {
                type: Boolean,
                'default': () => {
                    return false;
                },
            },

            isActive: Boolean,

            classes: {
                type: Array,
                'default': () => {
                    return [];
                },
            },

            bodyClass: String,
            headerClass: String,
            chevronColor: String,
            minWidth:String,
            maxWidth:String,
            headerContent:String,
        },

        methods: {
            close() {
                this.active = false;

                if (this.focusTrigger) {
                    this.$refs.trigger.focus();
                }

                if(this.pageContainsGraphs) {
                    this.eventHub.$emit('graph:resizeWidthEase');
                }

                this.width = this.minWidth;
            },

            open() {
                if (!this.hasLoaded) {
                    this.hasLoaded = true;
                }
                this.active = true;

                if (this.focusTrigger) {
                    this.$refs.trigger.focus();
                }

                if(this.pageContainsGraphs) {
                    this.eventHub.$emit('graph:resizeWidthEase');
                }

                this.width = this.maxWidth;
            },


            toggle() {

                if (this.active) {
                    this.close();

                    return;
                }

                this.open();
            },

            removeSelf() {
                this.removeCallback();
                this.$destroy(true);
            },
        },

        vueReady() {
            if (!this.active) {
                return;
            }

            /**
             * Wait for the next cycle to give use a
             * better chance of getting the height right
             */
            setTimeout(() => {
                this.open();
            });
        },
    });

    export default Expando;
</script>
