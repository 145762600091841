<style lang="scss">
    .group-column-hitbox {
        display: inline-block;
        cursor:pointer;
    }
</style>

<template>
    <th v-if="isHeader" @click="sortClick">
        <div v-html="title"></div>
    </th>
    <td v-else>
        <div v-if="rowData.children === true || (rowData.children && rowData.children.length)" class="group-column-hitbox" @click="chevronClick">
            {{text}} {{numChildren}}
            <p-tooltip2 v-if="icon" :hover="true" variant="steel" style="display: inline-block;">
                <p-icon slot="trigger" :icon="icon.image" size="sm" block/>
                {{icon.tooltip}}
            </p-tooltip2>

            <p-icon v-if="expanded" icon="chevron-down" size="xxl"/>
            <p-icon v-else icon="chevron-right" size="xxl"/>
        </div>
        <div v-else>
            <a :href="link"> {{ text }}</a> 
            <p-tooltip2 v-if="icon" :hover="true" variant="steel" style="display: inline-block;">
                <p-icon slot="trigger" :icon="icon.image" size="sm" block/>
                {{icon.tooltip}}
            </p-tooltip2>
        </div>
    </td>
</template>

<script>
    import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin.vue';

    export default {
        name: 'group',

        mixins: [VuetableFieldMixin],

         data() {
            return {
                expanded: false,
            };
        },

        props: {
        },

        computed: {
            link() {
                return this.rowData ? this.rowData.group.link : '';
            },

            text() {
                return this.rowData ? this.rowData.group.title : '';
            },

            numChildren() {
                if( this.rowData && this.rowData.children && this.rowData.children.length ) {
                    return "(" + this.rowData.children.length + ")";
                } else return "";
            },

            icon() {
                return this.rowData? this.rowData.icon: '';
            }
        },

        methods: {
            chevronClick() {
                if (this.expanded === true) {
                    this.expanded = false;
                    this.vuetable.hideDetailRow(this.rowData.id);
                } else {
                    this.expanded = true;
                    this.vuetable.showDetailRow(this.rowData.id);
                }
            },
            sortClick(event) {
                this.vuetable.orderBy(this.rowField);
            },
        },

        mounted: function() {
            if (this.rowData && this.rowData.children) {
                this.expanded = true;
                this.vuetable.showDetailRow(this.rowData.id);
            }
        }
    };
</script>
