<style lang="scss">
@import "~styles/core/helpers/_variables.scss";

.pa-list-selectbox {
    $self: &;
    counter-reset: selected-counter;

    &_search-column {
        border-radius: 2px;
        border: 1px solid #d5d8df;
        height: 232px;

        & #{$self}_search-box {
            border-bottom: 1px solid #d5d8df;
        }

        & #{$self}_list {
            height: 183px;
            overflow-y: auto;

            #{$self}_list-wrapper  {

                #{$self}_list-item {
                    margin: 0 8px;
                    border-bottom: 1px solid #d5d8df;

                    #{$self}_list-item-wrapper {

                        &.isSelected {
                            opacity: 0.5;
                        }

                        .pa-option {
                            padding: 8px 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &_selected-column {
        border-radius: 2px;
        border: 1px solid #d5d8df;
        height: 232px;
        overflow-y: auto;

        & #{$self}_list {

            #{$self}_list-wrapper  {

                #{$self}_list-item {
                    padding: 0 8px;

                    #{$self}_list-item-wrapper {
                        margin: 0 8px;
                        border-bottom: 1px solid #d5d8df;
                        padding: 8px 0;
                        display: flex;
                        align-items: center;

                        &::before {
                            @include font-medium;
                            counter-increment: selected-counter;
                            content: counter(selected-counter);
                            padding-right: 4px;
                        }
                    }

                    #{$self}_list-item-actions {
                        display: none;
                        margin-right: -4px;
                        cursor: pointer;
                    }

                    &:hover {
                        background-color: #f2f3f5;

                        #{$self}_list-item-actions {
                            display: inherit;
                        }
                    }
                }

                .ghost {
                    opacity: 0.5;
                    background: #efefef;
                }

                .flip-list-move {
                    transition: transform 0.5s;
                }

                .no-move {
                    transition: transform 0s;
                }
            }
        }
    }
}

.pa-list-selectbox.disabled {
    .pa-input, 
    .pa-list-selectbox_search-box, 
    .pa-list-selectbox_list, 
    .pa-list-selectbox_selected-column,
    .pa-btn {
        @include colorVar(background-color, "grey", 100);
        cursor: not-allowed;
    }

    .pa-list-selectbox_search-box, .pa-input {
        @include colorVar(border-color, "grey", 200);
    }

    .select-box-label {
        @include colorVar(color, "grey", 400);
    }
}
</style>

<template>
    <div class="pa-list-selectbox" :class="disabled ? 'disabled' : ''">
        <p-row align-center style="min-width: 617px;">
            <p-column style="width: 226px">
                <span class="pa-txt_medium pa-pb-8 select-box-label">Existing Templates</span>
                <div class="pa-list-selectbox_search-column">
                    <div class="pa-list-selectbox_search-box pa-p-8">
                        <input
                            type="text"
                            placeholder="Search"
                            v-model="searchTerm"
                            class="pa-input"
                        />
                    </div>
                    <div class="pa-list-selectbox_list">
                        <ul class="pa-list-selectbox_list-wrapper">
                            <li
                                :key="option[optionValue]"
                                v-for="option in filteredOptions"
                                class="pa-list-selectbox_list-item"
                            >
                                <div
                                    class="pa-list-selectbox_list-item-wrapper"
                                    :class="{
                                        'isSelected': option.isSelected
                                    }"
                                >
                                    <label :title="option[optionLabel]" class="pa-option">
                                        <input
                                            type="checkbox"
                                            class="pa-option-input"
                                            v-model="checked"
                                            :value="option[optionValue]"
                                        />
                                        <span class="pa-option-icon"></span>
                                        <span class="pa-option-txt pa-txt_truncate">
                                            {{ option[optionLabel] }}
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li style="padding:5px">
                                <a v-if="showCreateNew" slot="header-actions" href="#" class="pa-link pa-txt_xs" v-on:click="fireTemplateEvent" >Create New</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </p-column>
            <p-column class="pa-px-16" style="width: 161px">
                <p-button @click="selectChecked" variant="secondary" :disabled="disabled">
                    Add Template
                </p-button>
            </p-column>
            <p-column style="width: 226px">
                <span class="pa-txt_medium pa-pb-8 select-box-label">Selected Templates</span>
                <div class="pa-list-selectbox_selected-column">
                    <div class="pa-list-selectbox_list">
                        <div class="pa-list-selectbox_list-wrapper">
                            <draggable
                                v-model="selected"
                                v-bind="dragOptions"
                                @start="isDragging = true"
                                @end="isDragging = false"
                                tag="ul"
                            >
                            <transition-group type="transition" :name="!isDragging ? 'flip-list' : null">
                            <li
                                :key="item[optionValue]"
                                v-for="item in computedSelected"
                                class="pa-list-selectbox_list-item"
                            >
                                <div class="pa-list-selectbox_list-item-wrapper">
                                    <div class="pa-txt_truncate">
                                        <span class="pa-pl-4" :title="item[optionLabel]">
                                            {{ item[optionLabel] }}
                                        </span>
                                    </div>
                                    <div class="pa-list-selectbox_list-item-actions pa-ml-auto pa-flex pa-align-center">
                                        <p-button
                                            @click="removeSelected(item[optionValue])"
                                            unstyled
                                            no-base-class
                                            class="pa-ml-auto"
                                            title="Remove Template"
                                        >
                                            <p-icon icon="x" size="xl"></p-icon>
                                        </p-button>
                                        <p-icon
                                            class="drag-handle pa-ml-8"
                                            icon="drag-vertical"
                                        >
                                        </p-icon>
                                    </div>
                                </div>
                            </li>
                            </transition-group>
                            </draggable>
                        </div>
                    </div>
                </div>
            </p-column>
        </p-row>
        
    </div>
    
</template>

<script>
import Vue from 'vue';
import draggable from 'vuedraggable'

export default Vue.extend({
    components: {
        draggable,
    },
    data() {
        return {
            searchTerm: "",
            selected: this.defaultSelected,
            checked: this.defaultSelected,
            unchecked: "",
            isDragging: false,
        };
    },
    props: {
        options: {
            type: Array,
            default: () => []
        },
        optionLabel: {
            type: String,
            default: "label",
        },
        optionValue: {
            type: String,
            default: "value",
        },
        disabled: {
            type: Boolean,
            default: false
        },
        defaultSelected: {
            type: Array,
            default: () => []
        },
        showCreateNew:{
            type: Boolean,
            default: false
        }
    },
    watch: {
        unchecked() {
            this.$nextTick(() => {
                this.unselectUnchecked();
            });
        },
        checked(isChecked, wasChecked) {
            this.findUnchecked(isChecked, wasChecked);
        },
        selected(isSelected, wasSelected) {
            this.$emit("change", isSelected, wasSelected);
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                disabled: false,
                ghostClass: "ghost",
                handle: ".drag-handle",
            };
        },
        hasSearchTerm() {
            return this.computedSearchTerm.length > 0;
        },
        computedSearchTerm() {
            return this.searchTerm
                ? this.searchTerm.trim().toLowerCase()
                : "";
        },
        filteredOptions() {
            return this.mappedOptions.filter(this.isOptionSearched);
        },
        computedSelected() {
            return this.selected.map(
                (option) => {
                    const matchedOption = this.mappedOptions.find(
                        (mappedOption) => mappedOption[this.optionValue] === option
                    );

                    return matchedOption;
                }
            ).filter(this.isOptionSelected);
        },
        mappedOptions() {
            return this.options.map(this.mapOption);
        },
    },
    methods: {
        fireTemplateEvent(){
            this.$emit('addNewTemplate')
        },
         
        findUnchecked(isChecked, wasChecked) {
            this.unchecked = wasChecked.filter(
                (checked) => !isChecked.includes(checked)
            )[0] || "";
        },
        removeSelected(selectedValue) {
            this.checked = this.checked.filter(
                (value) => value !== selectedValue
            );
        },
        unselectUnchecked() {
            this.selected = this.selected.filter(this.isNotUnchecked);
        },
        isNotUnchecked(option) {
            return this.unchecked !== option;
        },
        isOptionSearched(option) {
            if (!this.hasSearchTerm) {
                return true; 
            }

            return option.searchValue.includes(this.computedSearchTerm);
        },
        isOptionSelected(option) {
            return this.selected.includes(option[this.optionValue]);
        },
        isOptionChecked(option) {
            return this.checked.includes(option[this.optionValue]);
        },
        mapOption(option) {
            const searchValue = option[this.optionLabel].trim().toLowerCase();
            const isSelected = this.isOptionSelected(option);

            return {
                ...option,
                searchValue,
                isSelected,
            };
        },
        selectChecked() {
            this.selected = [...this.checked];
        }
    } 
});
</script>
