<style lang="scss">
.pa-navigation-action__menu {
    width: 100%;
}
</style>

<template>
    <div :class="className">
        <p-navigation-action-menu-trigger
            :text="text"
            :left-icons="leftIcons"
            :right-icons="rightIcons"
            :badge="badge"
            :to="to"
            :open="open"
            :trigger="trigger"
            :exact-path="exactPath"
            @click="handleMenuToggled"
            :isCollapsed="isCollapsed"
        >
        </p-navigation-action-menu-trigger>

        <p-navigation-action-menu-items
            v-if="hasItems"
            :title="title"
            :items="items"
            @item:active="handleItemActive"
            :isCollapsed="isCollapsed"
        >
        </p-navigation-action-menu-items>
    </div>
</template>

<script setup>
import { computed } from "vue";

import PNavigationActionMenuItems from "./NavigationActionMenuItems.vue";
import PNavigationActionMenuTrigger from './NavigationActionMenuTrigger.vue';

const props = defineProps({
    title: String,
    text: String,
    leftIcons: Array,
    rightIcons: Array,
    items: Array,
    isCollapsed: Boolean,
    badge: Object,
    open: Boolean,
    trigger: String,
    to: String,
    exactPath: Boolean,
    hasActiveChild: Boolean,
});

const emit = defineEmits(["menu:toggled", "item:active"]);

const handleMenuToggled = () => {
    emit("menu:toggled");
};

const handleItemActive = (data) => {
    emit('item:active', data);
};

const hasItems = computed(() => props.items && props.items.length);

const className = computed(() => ([
    "pa-navigation-action",
    "pa-navigation-action__menu",
    open.value ? "pa-navigation-action__menu--open" : "",
    props.hasActiveChild ? "pa-navigation-action__menu--active-child": "",
].join(" ")));
</script>
