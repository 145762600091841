<template>
    <div class="threshold-box" transition="slide">
        <div class="threshold-remove" @click="removeAction">
            <svg class="pa-icon"><use xlink:href="#close"></use></svg>
        </div>

        <div v-if="isBoolean" class="pa-field">
            <div class="pa-field-hd">
                <label for="value" class="pa-label">Generate incident when {{ metricName }}</label>
            </div>
            <div class="pa-field-bd">
                <p-select :model.sync="threshold.condition" :options="boolean_conditions" />
            </div>
            <div class="pa-field-ft">
                <p v-if="errors.threshold" class="pa-hint pa-hint_error">
                    {{ errors.threshold }}
                </p>
                <p class="pa-hint">
                </p>
            </div>
        </div>

        <div v-else class="pa-field">
            <div class="pa-field-hd">
                <label for="value" class="pa-label">Generate incident when {{ metricName }} is</label>
            </div>
            <div class="pa-field-bd">
                <div class="pa-hList">
                    <div>
                        <p-select v-if="isEnum" :model.sync="threshold.condition" :options="enumConditions" size="xxs"></p-select>
                        <p-select v-else :model.sync="threshold.condition" :options="conditions"></p-select>
                    </div>
                    <div>
                        <p-select v-if="isEnum" :model.sync="threshold.threshold" :options="enumValues" size="xxs"></p-select>
                        <input v-else type="number" class="pa-input pa-input_xxxxxs" style="height: 2.5rem" v-model="threshold.threshold" />
                    </div>
                    <p class="pa-txt pa-txt_sm">{{ unit }}</p>
                </div>
            </div>
            <div class="pa-field-ft">
                <p class="pa-hint">
                </p>
                <p v-if="errors.threshold" class="pa-hint pa-hint_error">
                    {{ errors.threshold }}
                </p>
            </div>
        </div>

        <div class="pa-field">
            <div class="pa-field-hd">
            </div>
            <div class="pa-field-bd">
                <div class="pa-hList">
                    <div>
                        <label for="delay" class="pa-label" style="margin-bottom: 5px;">For more than</label>
                        <p-select size="xxxxs" :model.sync="threshold.delay" :options="delays" />
                    </div>
                    <div>
                        <label for="severity" class="pa-label" style="margin-bottom: 5px;">Using severity</label>
                        <p-select size="xxxxs" :model.sync="threshold.severity" :options="severities" />
                    </div>
                </div>
            </div>
            <div class="pa-field-ft">
                <p class="pa-hint">
                </p>
                <p v-if="errors.error__delay" class="pa-hint pa-hint_error">
                    {{ errors.error__delay || errors.error__severity }}
                </p>
            </div>
        </div>

        <div class="pa-field">
            <div class="pa-field-hd">
                <label for="notification_schedule" class="pa-label">Notify with alert timeline</label>
            </div>
            <div class="pa-field-bd">
                <p-select :model.sync="threshold.notification_schedule" :options="notificationSchedules" />
            </div>
            <div class="pa-field-ft">
                <p class="pa-hint">
                </p>
                <p v-if="errors.error__notification_schedule" class="pa-hint pa-hint_error">
                    {{ errors.error__notification_schedule }}
                </p>
            </div>
        </div>

        <div class="pa-field">
            <div class="pa-field-bd">
                <label class="pa-option">
                    <input type="checkbox" class="pa-option-input" v-model="threshold.include_in_availability" />
                    <span class="pa-option-icon"></span>
                    <span class="pa-option-txt">
                        Include in availability calculation
                    </span>
                </label>
            </div>
            <div class="pa-field-ft">
            </div>
        </div>

        <div v-if="allowCountermeasures">
            <ul class="counter-measure-title">
                <li style="width: 100%; border-bottom: 1px solid #dcdcdc;" class="pa-pb-4">
                    <p-icon icon="flag-filled"></p-icon>
                    <h4 style="display: inline-block; font-size: 13px;">CounterMeasures</h4>
                </li>
                <li class="pa-txt_xs pa-mt-12">
                    CounterMeasures are automated actions that range from simple debugging commands to complex actions such as API calls or restarting a service. They can be triggered using thresholds.
                </li>
            </ul>
            <div v-for="(counterMeasure, cmIndex) in threshold.countermeasures"
                    transition="slide"
                    :id="`countermeasure-${ Math.abs(counterMeasure.countermeasure_id) }`"
                    :style="{ marginTop: $index > 0 ? '20px' : ''}">
                <div class="counter-measure-box">
                    <div v-show="counterMeasure.countermeasure_id < 0" class="pa-message pa-message_error" style="margin-right: 30px; border-left-width: 1px;">
                        <p style="font-size: 11px; font-weight: bold;">Warning: Missing CounterMeasure</p>
                        <p style="font-size: 11px;">This Incident CounterMeasure has been configured to use a CounterMeasure plugin that is not present on this instance</p>
                    </div>
                    <div v-if="!customer_ackd_cm_pricing" transition="slide" class="pa-message pa-message_info" style="margin-right: 30px; border-left-width: 1px;">
                        <p style="font-size: 11px; font-weight: bold;">CounterMeasure Pricing</p>
                        <p style="font-size: 11px;">There is an additional cost for utilizing CounterMeasures. See our <a href="https://www.panopta.com/pricing/">pricing page</a> for more details or <a href="https://docs.fortimonitor.forticloud.com/user-guide/46227/get-support">contact our support team</a> with any questions</p>
                        <button @click="dismissCMPricing" class="pa-btn naked_blue">Got it</button>
                    </div>
                    <div class="counter-measure-remove" @click="removeCounterMeasure(threshold, $index)">
                        <svg class="pa-icon"><use xlink:href="#close"></use></svg>
                    </div>
                    <div class="pa-field">
                        <div class="pa-field-hd">
                            <label for="cm_delay" class="pa-label">Delay</label>
                        </div>
                        <div class="pa-field-bd">
                            <p-select :model.sync="counterMeasure.countermeasure_delay" :options="countermeasure_delays"></p-select>
                        </div>
                        <div class="pa-field-ft">
                            <p class="pa-hint">
                            </p>
                        </div>
                    </div>
                    <div class="pa-field" v-show="counterMeasure.countermeasure_delay === 'custom'">
                        <div class="pa-field-hd">
                            <label for="cm_delay" class="pa-label">Custom Delay (minutes)</label>
                        </div>
                        <div class="pa-field-bd">
                            <input v-model="counterMeasure.countermeasure_delay_custom"
                                    type="number"
                                    placeholder="Minutes"
                                    class="pa-input"
                                    style="width: 15.625rem;"/>
                        </div>
                        <div class="pa-field-ft">
                            <p class="pa-hint">
                            </p>
                        </div>
                    </div>
                    <div class="pa-field">
                        <div class="pa-field-hd">
                            <label for="cm_action" class="pa-label">Action</label>
                        </div>
                        <div class="pa-field-bd">
                            <p-select :model.sync="counterMeasure.countermeasure_id"
                                :options="countermeasure_options"
                                :descriptions="true">
                            </p-select>
                        </div>
                        <div class="pa-field-ft">
                            <p v-show="missingCMActions.includes(thresholdIndex.toString() + cmIndex)" class="pa-hint pa-hint_error">
                                Please specify an action.
                            </p>
                        </div>
                    </div>
                    <div class="pa-field">
                        <div class="pa-field-bd">
                            <label class="pa-option">
                                <input v-model="counterMeasure.needs_approval" type="checkbox" class="pa-option-input" />
                                <span class="pa-option-icon"></span>
                                <span class="pa-option-txt" style="" v-p-hover-text="'CounterMeasure will not run until it has been approved.'">
                                    Require Approval
                                </span>
                            </label>
                        </div>
                    </div>
                    <div v-if="slackApp" class="pa-field">
                        <div class="pa-field-bd">
                            <label class="pa-option">
                                <input v-model="counterMeasure.slack_output" type="checkbox" class="pa-option-input" />
                                <span class="pa-option-icon"></span>
                                <span class="pa-option-txt" style="" v-p-hover-text="'CounterMeasure output will be sent to previously notified Slack channels'">
                                    Send output to Slack
                                </span>
                            </label>
                        </div>
                    </div>
                    <button
                        v-show="$index === threshold.countermeasures.length - 1"
                        class="pa-btn_link pa-txt_medium pa-txt_sm pa-mb-4"
                        style="display: block;"
                        v-on:click="addCounterMeasure(threshold)"
                        :disabled="!canAddCountermeasures"
                    >
                        <p-icon icon="plus-circle-outline" color="blue"></p-icon>
                        Add CounterMeasure
                    </button>
                </div>
            </div>
            <div v-show="!threshold.countermeasures.length" class="add-countermeasure">
                <button
                    class="pa-btn_link pa-txt_medium pa-txt_sm pa-mb-8"
                    style="display: block;"
                    v-on:click="addCounterMeasure(threshold)"
                    :disabled="!canAddCountermeasures"
                >
                    <p-icon icon="plus-circle-outline" color="blue"></p-icon>
                    Add CounterMeasure
                </button>
                <p-tooltip v-if="!agent_supports_countermeasures" :hover="true" :hover-timeout="300">
                    <div slot="trigger">
                        <a target="_blank"
                            href="https://docs.fortimonitor.forticloud.com/user-guide/502032/update-the-fortimonitor-agent"
                            style="margin-left: 5px;">
                            Agent Update Required
                        </a>
                    </div>
                    <span>A newer version of the Panopta agent is required for Countermeasures</span>
                </p-tooltip>
                <p-tooltip v-if="agent_supports_countermeasures && !hasExistingCountermeasures" :hover="true" :hover-timeout="300">
                    <div slot="trigger">
                        <a target="_blank"
                            href="https://docs.fortimonitor.forticloud.com/user-guide/645678/countermeasures"
                            style="margin-left: 5px;">
                            Learn More
                        </a>
                    </div>
                    <span>Countermeasures have not been enabled on this instance</span>
                </p-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                hideErrors: false,
                conditions: [
                    { value: 'gt', label: 'greater than' },
                    { value: 'lt', label: 'less than' },
                    { value: 'eq', label: 'equal to' },
                    { value: 'ne', label: 'not equal to' },
                ],
                enumConditions: [
                    { value: 'eq', label: 'equal to' },
                    { value: 'ne', label: 'not equal to' },
                ],
                boolean_conditions: [
                    { value: 'eq', label: this.booleanTrueDesc },
                    { value: 'ne', label: this.booleanFalseDesc },
                ],
                delays: [
                    { value: '0', label: '0 minutes' },
                    { value: '60', label: '1 minute' },
                    { value: '120', label: '2 minutes' },
                    { value: '300', label: '5 minutes' },
                    { value: '900', label: '15 minutes' },
                    { value: '1800', label: '30 minutes' },
                    { value: '3600', label: '1 hour' },
                    { value: String(3600 * 2), label: '2 hours' },
                    { value: String(3600 * 4), label: '4 hours' },
                    { value: String(3600 * 8), label: '8 hours' },
                    { value: String(3600 * 12), label: '12 hours' },
                    { value: String(3600 * 24), label: '24 hours' },
                ],
                severities: [
                    { value: 'critical', label: 'Critical' },
                    { value: 'warning', label: 'Warning' },
                ],
            };
        },

        props: {
            threshold: Object,
            metricName: String,
            unit: String,

            // Boolean options
            isBoolean: Boolean,
            booleanFalseDesc: {
                type: String,
                'default': 'False',
            },
            booleanTrueDesc: {
                type: String,
                'default': 'True',
            },

            // Enum options
            isEnum: Boolean,
            enumValues: Array,

            // Function called when clicking x
            removeAction: {
                type: Function,
                'default': () => {},
            },

            // Show Countermeasure options
            allowCountermeasures: Boolean,

            // Show slack app
            slackApp: Boolean,

            // List of options for notification schedules
            notificationSchedules: {
                type: Array,
                'default': () => [],
            },

            errors: {
                type: Object,
                'default': () => Object(),
            },
        },
    });
</script>
