<template>
    <th v-if="isHeader">
        {{ title }}
    </th>
    <td v-else class="incidents">
            <p-flyout :hover="true" :hover-timeout="0" :wait-time="1000" direction="top" :width="600" :arrow-percent="25" :id="rowData.id + '-flyout'" positioning="popper">
                <div slot="trigger" class="flyout-trigger">
                    <div>
                        <ul class="pa-hList">
                            <li v-if="numCritical">
                                <svg class="pa-icon pa-icon_xl" style="fill: #f53131;">
                                    <use xlink:href="#critical"></use>
                                </svg>
                                <span>{{ numCritical }}{{ numWarning && ',' || '' }}</span>
                            </li>
                            <li v-if="numWarning">
                                <svg class="pa-icon pa-icon_xl" style="fill: #fec31e;">
                                    <use xlink:href="#warning"></use>
                                </svg>
                                <span>{{ numWarning }}</span>
                            </li>
                        </ul>
                        <p v-if="!numCritical && !numWarning">-</p>
                    </div>
                </div>

                <div slot="content">
                    <table class="incidents-table">
                        <thead>
                            <tr>
                                <th>Incident ID</th>
                                <th>Duration</th>
                                <th>Affected Resources</th>
                                <th>View</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="incident in incidents">
                                <td>
                                    <svg v-if="incident.severity === 'critical'" class="pa-icon" style="fill: #f53131;">
                                        <use xlink:href="#critical"></use>
                                    </svg>
                                    <svg v-else class="pa-icon" style="fill: #fec31e;">
                                        <use xlink:href="#warning"></use>
                                    </svg>
                                    <span>{{ incident.id }}</span>
                                </td>
                                <td>{{ incident.duration }}</td>
                                <td>{{ incident.description }}</td>
                                <td>
                                    <a target="_blank" :href="incident.link">
                                        <svg class="pa-icon">
                                            <use xlink:href="#open-in-new"></use>
                                        </svg>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </p-flyout>
    </td>
</template>

<script>
    import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin.vue';

    export default {
        name: 'incidents',

        mixins: [VuetableFieldMixin],

        data() {
            return {
            };
        },

        props: {
        },

        computed: {
            incidents() {
                let key = 'incidents';
                if (this.rowField.key) {
                    key = this.rowField.key;
                }
                const incidents = this.rowData[key] || [];
                return incidents;
            },

            numCritical() {
                let count = 0;
                this.incidents.forEach(i => {
                    if (i.severity === 'critical') {
                        count += 1;
                    }
                });
                return count;
            },

            numWarning() {
                return this.incidents.length - this.numCritical;
            },
        },
    };
</script>
