<template>
    <div class="drawer-graph-list">
        <p-expando
            :key="index"
            v-for="(metric, index) in metrics"
            :classes="['metric-box']"
            :is-active="true"
        >
            <div slot="header" class="header">
                <img
                    class="logo"
                    :src="`/static/assets/newux/media/logos/${metric.category_logo_url}`"
                />
                <span>{{ metric.category_name }}</span>
                <p-button 
                    unstyled 
                    no-base-class 
                    @click.stop="eventHub.$emit(
                        'openChart',
                        metric.metrics[0][1],
                        metric.category_name,
                        'detail-pane-modal'
                    );"
                    class="expand">
                    <p-icon
                        icon="arrow-expand"
                    >
                    </p-icon>
                </p-button>
                
            </div>

            <div class="chart">
                <p-graph
                    :key="index"
                    v-for="(imetric, index) in metric.metrics"
                    :highcharts-config="highchartsConfig"
                    :monitors="imetric[1]"
                    :server_id="instance.id"
                    :auto-load="true"
                    :crosshairs="true"
                    timescale="hour"
                    width="100%;"
                    :exporting="false"
                    height="150px;"
                >
                </p-graph>
            </div>
        </p-expando>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            metrics: Array,
            instance: Object,
        },
        data() {
            return {
                highchartsConfig: {
                    chart: {
                        zoomType: null,
                    },
                    xAxis: {
                        crosshair: {
                            dashStyle: 'shortdot',
                            width: 1.5,
                            color: "#999999",
                            snap: true
                        },
                    },
                },
            };
        },
    });
</script>
