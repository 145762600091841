import { render, staticRenderFns } from "./InOutWidget.vue?vue&type=template&id=63d97d45&scoped=true&"
import script from "./InOutWidget.vue?vue&type=script&lang=js&"
export * from "./InOutWidget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63d97d45",
  null
  
)

export default component.exports