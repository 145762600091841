<style lang="scss">
.circle {
    position: relative;
    height: 0;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
}
</style>

<template>
    <div
        class="circle"
        :style="computedStyle"
    >
        <slot></slot>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        width: {
            type: String,
            default: "22",
        },
        color: {
            type: String,
            default: "#333",
        },
        borderColor: {
            type: String,
            default: "transparent"
        },
        fontColor: {
            type: String,
            default: "#FFFFFF",
        },
    },
    computed: {
        computedStyle() {
            return {
                'color': this.fontColor,
                'border-style': "solid",
                'border-color': this.borderColor,
                'background-color': this.color,
                'padding': `${Number(this.width) / 2}px 0`,
                'width': this.width + 'px',
            };
        },
    },
});
</script>
