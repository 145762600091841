import CalculationControl from './controls/CalculationControl.vue';
import TimescaleControl from './controls/TimescaleControl.vue';

import getContrast from '../shared/utils/getContrast';

const DEFAULT_WIDGET_BACKGROUND_COLOR = "#ffffff";

const widgetMixin = {
    components: {
        'p-calculation-control': CalculationControl,
        'p-timescale-control': TimescaleControl,
    },

    data() {
        return {
            content: {},
            metadata: {},
            valid: true,
            error_message: '',
            accessError: false,
            updateTimer: null,
            receivedCreate: false,
            isHovered: false,
            isLoading: false,
            localConfig: this.config,
        };
    },

    computed: {
        textColor: function() {
            return this.isEditingAndHovered ? '' : getContrast(this.backgroundColor);
        },

        hasSelectedBackgroundColor: function() {
            return (
                Boolean(this.localConfig) &&
                Boolean(this.localConfig.custom_controls) &&
                Boolean(this.localConfig.custom_controls.backgroundColor) &&
                this.localConfig.custom_controls.backgroundColor !== DEFAULT_WIDGET_BACKGROUND_COLOR
            );
        },

        backgroundColor: function() {
            if (this.hasSelectedBackgroundColor) {
                return this.localConfig.custom_controls.backgroundColor || DEFAULT_WIDGET_BACKGROUND_COLOR;
            }

            return DEFAULT_WIDGET_BACKGROUND_COLOR;
        },

        isEditingAndHovered: function() {
            return this.isHovered && this.$parent.editing
        },

        computedMetricWidgetStyle: function() {
            const computedStyle = Object.assign(
                {},
                this.localConfig.height === 1
                    ? {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }
                    : null
            );

            return computedStyle;
        },

        computedSingleValueStyle: function() {
            let baseFontSize = 0.48;
            let fontSize = baseFontSize;

            if (this.localConfig.height === 1) {
                baseFontSize = baseFontSize * 2;

                if (this.localConfig.width === 2) {
                    baseFontSize = 1;
                }

                fontSize = this.localConfig.height * baseFontSize;
            } else {
                fontSize = this.localConfig.width * baseFontSize;
            }

            return {
                'font-size': `${fontSize}em`,
            };
        },

        showWidgetControls: function() {
            return this.isHovered && this.$parent.editing;
        },

        needsConfig: function() {
            if (typeof this.content === 'undefined' ||
                    typeof this.content.success === 'undefined') {
                return false;
            }
            this.accessError = this.content.access_error || false;
            return !this.accessError && !this.content.success;
        },
    },

    events: {
        // Sent from Dashboard when new init data is received
        'widget:new_data': function(widget_id, config, content, metadata) {
            if (this.id !== widget_id) { return; }
            this.receivedCreate = true;
            this.initialize(config, content);
            this.valid = true;
            this.metadata = metadata;
            this.contentReady();
        },
        // Sent from Dashboard when a widget should reconfigure using stored content
        'widget:reconfig': function(widget_id) {
            if (this.id !== widget_id) { return; }
            const gsw = $(`.grid-stack-item#${this.id}`).data('_gridstack_node');
            this.localConfig.width = gsw.width;
            this.localConfig.height = gsw.height;
            this.localConfig.row = gsw.y;
            this.localConfig.col = gsw.x;
            this.reconfig();
            this.valid = true;
            this.contentReady();
        },

        // Sent from the dashboard when a widget attaches itself to a queue entry.
        // Only applies to the line_graph and bar_chart.
        'widget:attach_data': function(widget_id, config, content, metadata) {
            if (this.id !== widget_id || this.rendered === true) {
                return;
            } else {
                this.receivedCreate = true;
                this.initialize(config, content);
                this.metadata = metadata;
                this.valid = true;
                this.contentReady();
            }
        },
        // Sent from Dashboard when updated data is received
        'widget:updated_data': function(widget_id, content, metadata) {
            if (this.id !== widget_id) { return; }
            if (!this.receivedCreate) {
                // We received an update before create
                // This should not be allowed, tell queue to force recreate
                this.pendNewConfig();
                this.$parent.reportUpdateBeforeCreate(widget_id);
                return;
            }
            this.metadata = metadata;
            this.update(content);
            this.valid = true;
            this.contentReady();
        },
        // Sent from Dashboard when this widget should consider itself stale
        'widget:pend_config': function(widget_id) {
            if (this.id !== widget_id) { return; }
            this.pendNewConfig();
        },
        // Method called when the data returned from the backend is an error message
        'widget:display_error': function(widget_id, config, content, metadata) {
            if (this.id !== widget_id) { return; }
            console.log(`Error from Widget Engine: ${content.error_log}`);
            this.valid = false;
            this.error_message = content.display_message;
            this.metadata = metadata;
            this.contentReady();
        },
        'widget:dump_state': function(widget_id) {
            if (this.id !== widget_id) { return; }
            if (typeof this.dumpState !== 'undefined') {
                console.log(`Dumping widget: ${widget_id}`);
                this.dumpState();
            } else {
                console.log('Widget type does not have a dumpState() defined.');
            }
        },
    },

    methods: {
        updateTitle(value) { 
            this.localConfig = {
                ...this.localConfig,
                title: value,
            };
        },
        deleteSelf: function() {
            window.app.rootVue.$broadcast('prompt:load', {
                id: 'dynamic_prompt',
                title: 'Delete widget?',
                body: 'Are you sure you want to delete this widget?',
                callback: `window.app.rootVue.$broadcast('dashboard:confirm_delete', ${this.id});`,
            });
        },

        cloneWidget() {
            const config = { ...this.localConfig };
            const widgetId = this.id;
            
            window.app.rootVue.$broadcast(
                'widget:clone_widget',
                { widgetId, config }
            );
        },

        pendNewConfig() {
            this.isLoading = true;
        },

        contentReady() {
            window.app.rootVue.$broadcast('widget-content:ready', this.id);
            if (typeof this.$refs.body === 'undefined' ||
                    this.$refs.body === null) {
                // Widget is showing the error body
                return;
            }
            if (this.$parent
                    && this.$parent.scopeUuid
                    && this.localConfig.scope_uuid !== this.$parent.scopeUuid) {
                console.debug(`Scope UUID ${this.localConfig.scope_uuid} doesn\'t match ${this.$parent.scopeUuid}`);
                return;
            }
            this.isLoading = false;
        },

        makePriority() {
            if (!this.id) { return; }

            const payload = {
                widget_id: this.id,
            };

            this.pendNewConfig();
            $.ajax({
                url: '/dashboardv2/makeWidgetPriority',
                method: 'POST',
                data: payload,
            });
        },
        handleContentHover: function(isHovered) {
                this.isHovered = isHovered;
        },
    },

    props: {
        id: {
            type: Number,
            'default': 1,
        },

        title: {
            type: String,
            'default': 'Widget',
        },

        config: {
            type: Object,
            'default': () => {},
        },

        editing: Boolean,
    },

    watch: {
        'metadata.next_update': function(val, oldVal) {                                
            if (this.updateTimer) {
                clearTimeout(this.updateTimer);
            }
            if (typeof this.metadata.update_delta === 'undefined') {
                return;
            }
            const cutoff = 5 * this.metadata.update_delta * 1000;
            this.updateTimer = setTimeout(() => {
                if (!this.$parent) {
                    // Widget was deleted
                    return;
                }
                this.$parent.reportStoppedUpdating(this.id, this.metadata);
            }, cutoff);
        },
    },

    vueReady() {
        window.app.rootVue.$broadcast('widget:domready', this);
    },
};

export default widgetMixin;
