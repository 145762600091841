<style lang="scss">
</style>

<template>
    <div>
        <p-label-divider label="Checks"></p-label-divider>
        <p-row class="pa-txt_normal pa-txt_xs pa-px-12">
            <p-column cols="2">
                <div class="pa-py-12">Metrics</div>
            </p-column>
            <p-column cols="4">
                <div class="pa-py-12">
                    HTTP Full Page Load, Jitter, Latency, Packet Loss                      
                </div>
            </p-column>
        </p-row>
        <p-row class="pa-txt_normal pa-txt_xs pa-px-12">
            <p-column cols="2">
                <div class="pa-py-12">Frequency *</div>
            </p-column>
            <p-column cols="4">
                <div class="pa-py-12 pa-flex" v-if="targetFrequencyEdit">
                    <div>
                        <p-select
                                :model.sync="targetFrequency"
                                :options="frequencyOptions"
                            >
                            </p-select>
                        <p v-show="errors['targetFrequency']" class="pa-hint pa-hint_error">
                            {{ errors['targetFrequency'] }}
                        </p>
                    </div>
                    <p-link
                        class="pa-pl-8 pa-pt-8"
                        :add-pointer="true"
                    >
                        <span @click.prevent="cancelTargetFrequencyEdit">
                            Cancel
                        </span>
                    </p-link>
                    
                </div>
                <div class="pa-py-12 pa-flex" v-else>
                    <span>{{ targetFrequency }} sec</span>
                    <p-link
                        class="pa-pl-8"
                        :add-pointer="true"
                    >
                        <span @click.prevent="targetFrequencyEdit = !targetFrequencyEdit">
                            Edit
                        </span>
                    </p-link>
                </div>
            </p-column>
        </p-row>
        <p-row class="pa-txt_normal pa-txt_xs pa-px-12">
            <p-column cols="2">
                <div class="pa-py-12">Alert Timeline *</div>
            </p-column>
            <p-column cols="4">
                <div class="pa-py-12 pa-flex" v-if="targetAlertTimelineEdit">
                    <div>
                        <p-select
                            :options="alertTimelineOptions"
                            defaulttext="Select"
                            size="sm"
                            :model.sync="targetAlertTimeline"
                        >
                        </p-select>
                        <p v-show="errors['targetAlertTimeline']" class="pa-hint pa-hint_error">
                            {{ errors['targetAlertTimeline'] }}
                        </p>
                    </div>
                    <p-link
                        class="pa-pl-8"
                        :add-pointer="true"
                    >
                        <span @click.prevent="cancelTargetAlertTimelineEdit">
                            Cancel
                        </span>
                    </p-link>
                    
                </div>
                <div class="pa-py-12 pa-flex" v-else>
                    <span>{{ targetAlertTimelineName }}</span>
                    <p-link
                        class="pa-pl-8"
                        :add-pointer="true"
                    >
                        <span @click.prevent="targetAlertTimelineEdit = !targetAlertTimelineEdit">
                            Edit
                        </span>
                    </p-link>
                </div>
            </p-column>
        </p-row>
        <p-row class="pa-txt_normal pa-txt_xs pa-px-12">
            <p-column cols="2">
                <div class="pa-py-12">Tags</div>
            </p-column>
            <p-column cols="4">
                <div class="pa-py-12">
                    <p-tags-input
                        name="tags"
                        :model.sync="targetTags"
                        autocomplete
                        placeholder="Enter Tags"
                        ref="tagsInput"
                        style="width: auto;"
                    >
                    </p-tags-input>
                </div>
            </p-column>
        </p-row>
    </div>
</template>

<script>
import Vue from "vue";
import _ from 'lodash';

export default Vue.extend({
    
    props: {
        alertTimelineOptions: Array,
    },
    
    events: {
       
    },
    
    data: () => ({
        targetFrequency: 60,
        targetFrequencyEdit: false,
        targetAlertTimeline: -1,
        targetAlertTimelineEdit: false,
        targetTags: [],
        frequencyOptions: [
            {"value":"60","label":"60 sec"},
            {"value":"120","label":"2 minutes"},
            {"value":"300","label":"5 minutes"},
            {"value":"600","label":"10 minutes"},
            {"value":"900","label":"15 minutes"},
            {"value":"1800","label":"30 minutes"},
            {"value":"3600","label":"60 minutes"},
            {"value":"86400","label":"1 day"}
        ],
        errors: {},
    }),

    computed: {
        targetAlertTimelineName() {
            let targetAlertTimeline = this.targetAlertTimeline;
            for(let item of this.alertTimelineOptions) {
                if( targetAlertTimeline == item.value) {
                    return item.label;
                }
            }
        },
    },

    watch: {
        'targetFrequency': function(val, oldVal) {
            this.eventHub.$emit('sd-wan-checks:updated', 'targetFrequency', val);
        },

        'targetAlertTimeline': function(val, oldVal) {
            this.eventHub.$emit('sd-wan-checks:updated', 'targetAlertTimeline', val);
        },

        'targetTags': function(val, oldVal) {
            this.eventHub.$emit('sd-wan-checks:updated', 'targetTags', val);
        },
    },
    
    methods: {
        cancelTargetFrequencyEdit: function() {
            this.targetFrequency = 60;
            this.targetFrequencyEdit = !this.targetFrequencyEdit;
        },

        cancelTargetAlertTimelineEdit: function() {
            this.targetAlertTimeline = -1;
            this.targetAlertTimelineEdit = !this.targetAlertTimelineEdit;
        },
    },
    
    mounted() {
        
    },
});
</script>
