<template>
    <li :class="classes">
        <div v-if="shouldRender" class="pa-panels-item-bd">
            <slot></slot>
        </div>
    </li>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    inject: {
        tabs: {
            default: () => ({})
        },
    },
    props: {
        lazy: {
            type: Boolean,
            default: false,
        },
        lazyOnce: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        classes() {
            return [
                'pa-panels-item',
                this.selected ? 'isActive' : ''
            ];
        },
        selected() {
            return this.localIndex === this.tabs.actualSelectedIndex; 
        },
        localIndex() {
            return this.tabs.domPanels.findIndex((el) => el === this.$el);
        },
        localLazy() {
            return this.lazy || this.tabs.lazy;
        },
        localLazyOnce() {
            return this.lazyOnce || this.tabs.lazyOnce;
        },
        shouldRender() {
            return (
                !this.localLazy ||
                (this.localLazy && this.selected)
            );
        },
    },
    methods: {
        registerPanel() {
            this.tabs.registerPanel(this);
        },
        unregisterPanel() {
            this.tabs.unregisterPanel(this);
        },
    },
    beforeDestroy() {
        this.unregisterPanel();
    },
    mounted() {
        this.registerPanel();
    },
});
</script>
