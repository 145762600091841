<template>
    <ul class="drawer-scheduled-maint-list pa-vList">
        <slot></slot>
        <li v-if="!maintenance || maintenance.length === 0" class="pa-txt_13">
            None
        </li>
        <p-drawer-scheduled-maint-list-item
            :key="index"
            v-for="(maint, index) in maintenance"
            :maint="maint"
        >
        </p-drawer-scheduled-maint-list-item>
    </ul>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            maintenance: Array,
        },
    });
</script>
