var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p-flex',{attrs:{"dir":"column","data-current-step":_vm.currentStep}},[(_vm.showSteps)?_c('div',[_c('p-divider',{staticClass:"pa-m-0"}),_vm._v(" "),_c('div',{staticClass:"pa-px-24",staticStyle:{"height":"48px"}},[_c('p-flex',{staticClass:"onboarding-steps",attrs:{"as":"ul","space-between":""}},_vm._l((_vm.stepNames),function(stepName,index){return _c('li',{key:stepName,staticClass:"onboarding-steps-item pa-flex pa-align-center",class:{
                        active: _vm.currentStep === index,
                        finished: _vm.currentStep > index,
                    },attrs:{"data-step":index,"data-active":_vm.currentStep === index ? 'active' : 'inactive',"data-finished":_vm.currentStep > index ? 'finished' : 'inprogress'}},[_c('p-button',{staticClass:"pa-text-grey-800 pa-txt_xs pa-txt_medium pa-py-12",class:{
                            'pa-clickable': !(_vm.currentStep === index || !(index < _vm.currentStep)),
                            'pa-cursor-default': (_vm.currentStep === index || !(index < _vm.currentStep)),
                        },attrs:{"unstyled":"","no-base-class":"","disabled":_vm.currentStep === index},on:{"click":function($event){return _vm.handleClick(index)}}},[_c('p-flex',{attrs:{"align-center":""}},[(false)?_c('div',{staticClass:"onboarding-steps-vertical-line"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"onboarding-steps-symbol-wrapper--circle onboarding-steps-symbol-wrapper"},[_c('div',{staticClass:"onboarding-steps-symbol"},[(_vm.currentStep <= index)?_c('div',{staticClass:"timeline-circle"}):_c('p-icon',{attrs:{"icon":"checkmark-solid","color":"green","size":"lg"}})],1)]),_vm._v(" "),_c('div',{staticClass:"onboarding-steps-name pa-ml-8"},[_c('p',{staticClass:"pa-m-0 pa-txt_medium pa-text-grey-800 pa-txt_sm"},[_vm._v("\n                                    "+_vm._s(stepName)+"\n                                ")])])])],1)],1)}),0)],1)],1):_vm._e(),_vm._v(" "),_c('p-divider',{staticClass:"pa-m-0"}),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }