<style lang="scss">
.incident-details-graph {
    margin: 10px;
    border-radius: 4px;
    background-color: white;
    height: 250px;
}
.incident-details-graph-container .pa-select {
    border-radius: 4px;
    height: 1.25rem;
}
.incident-details-graph-header {
    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 7px;
    margin-bottom: 4px;
    transition: all 0.25s ease-in-out;
}
.incident-details-graph-title {
    .trigger {
        cursor: pointer;
    }
}
.incident-details-graph-header:hover {
    background-color: #2c4163;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    cursor: move;

    .incident-details-graph-unit {
        color: white;
    }

    .pa-graph-unit-select,
    .pa-graph-unit-select.isActive {
        .pa-select-hd {
            color: white;
        }
    }
}
.fortinet .incident-details-graph-header:hover {
    background-color: #253746;
}

.incident-details-graph-header:hover .pa-tooltip-box-btn {
    color: white;
}

.incident-details-graph-header:hover .pa-icon {
    fill: white;
}
.incident-details-graph-header:hover .pa-btn .pa-icon {
    fill: white !important;
}
.incident-details-graph-header .drag-icon {
    display: none;
    margin-left: 0;
    height: 1.1rem;
    width: 1.1rem;
}
.incident-details-graph-header:hover .drag-icon {
    display: inline;
}
.incident-details-graph-header:hover .trigger {
    color: white;
}
.incident-details-graph-header .compare-icon {
    transform: scale(1.1);
}
.incident-details-graph-action .collapse-icon,
.incident-details-graph-header .expand-icon {
    vertical-align: -2px;
}
.incident-details-graph-action {
    display: flex;
    margin-left: 20px;
}
.incident-details-graph-header ul .incident-details-graph-action:first-of-type {
    margin-left: 0;
}
.incident-details-graph-header-2 {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 32px;
}
.incident-details-graph.is-highlighted {
    box-shadow: 0 0px 6px 0 #c2cff2, 0 0px 6px 0 #c2cff2;
}

.graph-loading-indicator {
    opacity: 0;
    transition: opacity .4s ease-in-out;

    &--loading {
        opacity: 1;
    }
}

@media (max-width: 1100px) {
    .incident-details-graph-header-2 > .pa-grid > .pa-grid-col {
        width: 100%;
    }
}
</style>

<template>
    <div
        ref="idp_graph"
        class="incident-details-graph pa-border-solid"
        :class="{ 'is-highlighted': isHighlighted }"
    >
        <div v-if="hasBeenSeen" class="incident-details-graph-container">
            <div class="incident-details-graph-header-container">
                <div class="incident-details-graph-header">
                    <div class="pa-grid pa-align-center">
                        <div class="pa-grid-col pa-grid-col_10of12">
                            <span class="incident-details-graph-title pa-txt_medium pa-txt_sm pa-flex pa-align-center">
                                <svg class="pa-icon drag-icon">
                                    <use xlink:href="#drag-vertical"></use>
                                </svg>
                                <p-tooltip2
                                    :prevent-overflow="true"
                                    html-class="graph-tooltip"
                                    variant="steel"
                                >
                                    <span slot="trigger">
                                        {{ truncatedGraphTitle }}
                                    </span>
                                    <span class="pa-txt_medium">
                                        {{ graphTitle }}
                                    </span>
                                </p-tooltip2>
                                <div class="pa-pl-4">
                                    <p-graph-unit-select
                                        v-if="displayUnitChanger"
                                        :graph-id="graphId"
                                        :graph-unit="graphUnit"
                                        :options="[{ label: initialGraphUnit, value: initialGraphUnit }]"
                                    >
                                    </p-graph-unit-select>
                                    <span v-if="!displayUnitChanger && !!graphUnit" class="incident-details-graph-unit pa-pr-4">
                                        ({{ graphUnit }})
                                    </span>
                                </div>
                                <p-tooltip2
                                    :prevent-overflow="true"
                                    html-class="graph-tooltip snmp-description"
                                    variant="steel"
                                    v-if="graphDescription != ''"
                                    :width="425"
                                >
                                    <p-icon
                                        slot="trigger"
                                        icon="question-circle-outline"
                                        middle
                                        color="grey-700"
                                        size="sm"
                                    >
                                    </p-icon>
                                    <span>{{ graphDescription }}</span>
                                </p-tooltip2>
                            </span>
                        </div>
                        <div class="pa-grid-col pa-grid-col_1of12 pa-align-end">
                            <p-loading-spinner
                                :class="[
                                    'graph-loading-indicator',
                                    displayLoadingIndicator ? 'graph-loading-indicator--loading' : ''
                                ]"
                                size="lg"
                            >
                            </p-loading-spinner>
                        </div>
                        <div class="pa-grid-col pa-grid-col_1of12 pa-align-end">
                            <p-button unstyled @click="popupPerformanceGraph" class="pa-p-4">
                                <svg class="pa-icon"><use xlink:href="#arrow-expand"></use></svg>
                            </p-button>
                        </div>
                    </div>
                </div>
            </div>
            <slot></slot>
            <slot name="graph"></slot>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';

    import getUrlParams from './../utils/getUrlParams';

    export default Vue.extend({
        props: {
            isExpanded: Boolean,
            graphTitle: String,
            graphUnit: String,
            graphId: String,
            maxValue: Number,
            monitors: Array,
            visibilityOffset: {
                type: Number,
                default: 900,
            },
            graphDescription: {
                type: String,
                default: '',
            },
            allGraphsLoaded: Boolean,
            loadOnce: {
                type: Boolean,
                default: true,
            },
            timescale: {
                type: String,
                default: null,
            },
            dateRange: String,
            locationLegend: {
                type: Boolean,
                default: false
            },
            extraData: {
                type: String,
                default: null
            },
            loading: Boolean,
        },
        data() {
            return {
                isDeepLinked: false,
                isHighlighted: false,
                hasBeenSeen: false,
                isVisible: false,
                isScrolling: null,
                initialGraphUnit: this.graphUnit,
                observer: null,
                displayLoadingIndicator: false,
            };
        },
        computed: {
            truncatedGraphTitle: function() {
                const truncateLength = this.selectedGraphUnit ? 55 : 65;
                return _.truncate(this.graphTitle, truncateLength);
            },
            displayUnitChanger() {
                return (
                    Boolean(this.maxValue) &&
                    Boolean(this.initialGraphUnit) &&
                    this.initialGraphUnit !== "%" &&
                    this.initialGraphUnit.toLowerCase() !== "percent"
                );
            },
        },
        methods: {
            setupObserver() {
                const observer = new IntersectionObserver(this.onObserved, {
                    threshold: .75,
                });

                observer.observe(this.$el);

                this.observer = observer;
            },
            onObserved(entries) {
                entries.forEach(({ target, isIntersecting }) => {
                    if (isIntersecting) {
                        this.isVisible = true;
                        this.observer.unobserve(target);
                    }
                });
            },
            popupPerformanceGraph: function() {
                 let query_string = '';
                 this.monitors.forEach(function(id) {
                     query_string += '&metric_ids[]=' + id;
                 });
                 query_string += '&title=' + this.graphTitle;

                 if (this.dateRange && this.timescale == 'custom') {
                     query_string += `&timescale=custom&date_range=${this.dateRange}&pre_outage_graph=true`;
                 }

                 if (this.timescale && this.timescale != 'custom') {
                    query_string += `&timescale=${this.timescale}`;
                 }

                 if (Boolean(this.graphUnit) && this.graphUnit !== this.initialGraphUnit) {
                    const queryGraphUnit = this.graphUnit === "%" ? "%25" : this.graphUnit;
                    query_string += `&selected_unit=${queryGraphUnit}`;
                 }

                 if (Boolean(this.locationLegend)) {
                     query_string += `&location_legend=true`
                 }

                 if (this.extraData) {
                     query_string += `&extra_data=${this.extraData}`
                 }

                 const url = '/report/MetricPopup?' + query_string;

                 this.$root.$broadcast('modal:load', {
                     id: 'dynamic_modal',
                     url: url,
                     open: true,
                     onCloseCallback: function() {this.$root.$broadcast('metric:popup-closed')},
                 });
            },
            checkForDeepLink: function() {
                const urlParams = getUrlParams(window.location.search);

                if (
                    urlParams.metric_id &&
                    this.monitors
                        .map(id => Math.abs(id))
                        .includes(_.toNumber(urlParams.metric_id))
                ) {
                    this.isDeepLinked = true;
                    this.isHighlighted = true;
                    this.scrollIntoView();
                }
            },
            scrollIntoView: function() {
                this.$nextTick(() => {

                    setTimeout(() => {
                        const ROUGH_HEADER_HEIGHT = 100;
                        const yCoord = (this.$el.getBoundingClientRect().top + window.scrollY) - ROUGH_HEADER_HEIGHT;
                        window.scrollTo(0, yCoord);
                    }, 1500);

                    if (this.isHighlighted) {
                        setTimeout(() => {
                            this.isHighlighted  = false;
                        }, 30000);
                    }
                });
            },
        },
        watch: {
            allGraphsLoaded: function(curr, prev) {
                const isLoaded = curr;
                const wasLoaded = prev;

                if (isLoaded && !wasLoaded) {
                    this.checkForDeepLink();
                }
            },
            isVisible(curr, prev) {
                if (curr && !this.hasBeenSeen && this.loadOnce) {
                    this.hasBeenSeen = true;
                }
            },
            loading(isLoading) {
                if (!isLoading) {
                    setTimeout(() => {
                        this.displayLoadingIndicator = false;                
                    }, 1000);
                } else {
                    this.displayLoadingIndicator = true;
                }
            }
        },
        beforeDestroy() {
            if (this.observer) {
                this.observer.disconnect();
                this.observer = null;
            }
        },
        mounted() {
            this.setupObserver();
        },
    });
</script>
