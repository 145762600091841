<style>
 .pa-header {
     transition: height, opacity .3s ease;
 }

 .header-expand-enter-active, .header-expand-leave-active {
     height: 0;
     opacity: 0;
 }
</style>

<template>
    <div>
        <transition 
                name="header-expand"
                v-on:enter="transitionEnter"
                v-on:leave="transitionLeave">
            <div v-show="showNav" class="pa-header" role="banner">
                <div class="pa-header-wrapper">
                    <div class="pa-header-wrapper-figure">
                        <a :href="logoLink" class="pa-logo">
                            <svg v-if="!logo" class="pa-logo-figure" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.5 24.5"><path fill="#F99F3D" d="M34.9 3.1l-2.2-2.2C32 0.3 31.2 0 30.3 0 29.5 0 28.7 0.3 28.1 0.8L22.3 6.6l4.6 4.6 2.2-2.2c0 0 0 0 0 0 0.3-0.3 0.8-0.5 1.2-0.5 0.5 0 0.9 0.2 1.2 0.5 0 0 0 0 0 0l2.1 2.1c0 0 0 0 0 0 0.3 0.3 0.5 0.8 0.5 1.2 0 0.5-0.2 0.9-0.5 1.2 0 0 0 0 0 0l-2.1 2.1c0 0 0 0 0 0 -0.3 0.3-0.8 0.5-1.2 0.5 -0.5 0-0.9-0.2-1.2-0.5L16.7 3.1l0 0 -2.3-2.3C13.8 0.3 13 0 12.2 0c-0.9 0-1.7 0.3-2.3 0.9L7.6 3.1l0 0L3.1 7.7l0 0L1 9.7c0 0 0 0 0 0C0.4 10.3 0 11.2 0 12.2c0 1 0.4 1.9 1 2.5 0 0 0 0 0 0l6.6 6.6 0 0 2 2c0 0 0 0 0 0 0.6 0.6 1.5 1 2.5 1 1 0 1.9-0.4 2.5-1 0 0 0 0 0 0l5.5-5.5 -4.5-4.6 -2.2 2.2c0 0 0 0 0 0 -0.3 0.3-0.8 0.5-1.2 0.5 -0.5 0-0.9-0.2-1.2-0.5 0 0 0 0 0 0l-2.1-2.1c0 0 0 0 0 0 -0.3-0.3-0.5-0.8-0.5-1.2 0-0.5 0.2-0.9 0.5-1.2 0 0 0 0 0 0l2.1-2.1c0 0 0 0 0 0 0.3-0.3 0.8-0.5 1.2-0.5 0.5 0 0.9 0.2 1.2 0.5l12.4 12.4 0 0 2 2c0 0 0 0 0 0 0.6 0.6 1.5 1 2.5 1 1 0 1.9-0.4 2.5-1 0 0 0 0 0 0l6.6-6.6 0 0 2.1-2.1c0 0 0 0 0 0 0.6-0.6 1-1.5 1-2.5 0-0.8-0.3-1.6-0.8-2.2l-6.8-6.8L34.9 3.1z"></path></svg>
                            <img
                                v-if="logo"
                                :src="logo.src"
                                :style="{
                                        width: logo.width,
                                        height: logo.height
                                        }"
                                class="pa-img" />
                        </a>
                    </div>
                    <div class="pa-header-wrapper-bd">
                        <p-header-nav
                           :nav-items="navItems"
                           :show-search="showSearch"
                            :search="search"
                            :user-image="userImage"
                            :user-items="userItems"
                            :beta-toggle="betaToggle"
                            :beta-on="betaOn"
                            :has-onsight="hasOnsight"
                            :has-templates="hasTemplates"
                            :add-button="addButton"
                            :show-outage-badge="showOutageBadge"
                            :show-maintenance-badge="showMaintenanceBadge"
                            :is-active.sync="navIsActive"
                            :completed-onboarding-todo="completedOnboardingTodo"
                            :has-selected-onboarding-infra="hasSelectedOnboardingInfra"
                            :display-onboarding-todo-drawer="displayOnboardingTodoDrawer"
                        ></p-header-nav>
                    </div>
                    <button v-on:click="toggleNav()" type="button" class="pa-header-wrapper-navicon">
                        <span class="pa-isVisuallyHidden">menu</span>
                        <svg class="pa-icon pa-icon_block pa-icon_xxxl pa-icon_light">
                            <use xlink:href="#dots-vertical"></use>
                        </svg>
                    </button>
                </div>
            </div>
        </transition>

    </div>
</template>

<script>
    import Vue from 'vue';
    import createScrollLocker from '../utils/scrollLocker';
    import breakpointManager from '../utils/breakpointManager';

    const Header = {
        data() {
            return {
                navIsActive: false,
                showNav: true,
                height: 0,
            };
        },

        destroy() {
            this.matchMedia.removeListener(this._onMediaChange);
        },

        props: {
            navItems: {
                type: Array,
                default: () => [],
            },

            logo: {
                type: Object,
                default: () => {},
            },

            logoLink: {
                type: String,
                default: "#",
            },

            search: {
                type: Object,
                default: () => {},
            },

            showSearch: {
                type: Boolean,
                default: true
            },

            userImage: {
                type: Object,
                default: () => {},
            },

            userItems: {
                type: Array,
                default: () => [],
            },

            addButton: {
                type: Boolean,
                default: false
            },

            showOutageBadge: {
                type: Boolean,
                default: false
            },

            showMaintenanceBadge: {
                type: Boolean,
                default: false
            },

            hasTemplates: {
                type: Boolean,
                default: true
            },

            hasOnsight: {
                type: Boolean,
                default: true
            },

            betaToggle: {
                type: Boolean,
                default: false,
            },
            betaOn: Boolean,
            completedOnboarding: {
                type: Boolean,
                default: false,
            },
            completedOnboardingTodo: {
                type: Boolean,
                default: false,
            },
            hasSelectedOnboardingInfra: {
                type: Boolean,
                default: false,
            },
            displayOnboardingTodoDrawer: {
                type: Boolean,
                default: false,
            },
        },

        events: {
            'header:hideNav': function() {
		$('.pa-announcement').css('display', 'none');
                this.showNav = false;
            },

            'header:showNav': function() {
		$('.pa-announcement').css('display', 'block')
                this.showNav = true;
            }
        },

        vueReady() {
            this.scrollLocker = createScrollLocker();
            this.matchMedia = breakpointManager.matchMedia('mobile');
            this.matchMedia.addListener(this._onMediaChange);
            this.setupMutationObserver();
        },

        methods: {
            toggleNav() {
                this.navIsActive = !this.navIsActive;

                if (this.navIsActive) {
                    this.scrollLocker.on();
                    this.eventHub.$emit('scroll-lock:on', this);
                } else {
                    this.scrollLocker.off();
                    this.eventHub.$emit('scroll-lock:off', this);
                }
            },

            _onMediaChange(event) {
                if (event.matches === false) {
                    this.scrollLocker.off();
                    this.eventHub.$emit('scroll-lock:off', this);

                    this.eventHub.$emit('header:tablet');

                    return;
                }

                this.eventHub.$emit('header:mobile');
            },

            switchCustomer() {
                this.eventHub.$emit('modal:load', {
                    id: 'dynamic_modal',
                    url: '/SelectCustomer',
                    open: true,
                });
            },

            getHeight() {
                let targetNode = document.getElementsByClassName('pa-page-hd');
                if (!targetNode.length) { return 0; }
                targetNode = targetNode[0];

                this.height = targetNode.clientHeight;
                return this.height;
            },

            setupMutationObserver() {
                let targetNode = document.getElementsByClassName('pa-page-hd');
                if (!targetNode.length) { return; }
                targetNode = targetNode[0];

                const config = {
                    childList: true,
                    subtree: true,
                    attributes: true,
                    attributeOldValue: false,
                    attributeFilter: ['style'],
                };

                const callback = this.getHeight;
                this.observer = new MutationObserver(callback);

                this.observer.observe(targetNode, config);
            },

            transitionEnter: function(el, done) {
                var header = $('.pa-page_dashboard').animate({'padding-top': '50px'}, 500, done);
            },

            transitionLeave: function(el, done){
                var header = $('.pa-page_dashboard').animate({'padding-top': '0px'}, 500, done);
            },
        },
    };

    export default Header;
</script>
