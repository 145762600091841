import { requests } from "../requests";

export const getOutagesReportData = (params, signal) => {
    return requests.get('/incidents/get_outages_report_data', { params, signal });
};

export const getOutagesReportCounts = (params, signal) => {
    return requests.get('/incidents/get_outages_report_counts', { params, signal });
};

export const getTenants = ({ query }) => {
    return requests.get("/incidents/get_tenants", { params: { query } });
};
