<template>
    <li :class="className">
        <p-navigation-action-item
            :id="id"
            :action="action"
            :text="text"
            :href="href"
            :to="to"
            :left-icons="leftIcons"
            :right-icons="rightIcons"
            :items="items"
            :badge="badge"
            :open="open"
            :exact-path="exactPath"
            :trigger="trigger"
            :active="active"
            @menu:toggled="handleMenuToggled"
            @item:active="handleItemActive"
        >
        </p-navigation-action-item>
    </li>
</template>

<script setup>
import { computed } from "vue";
import PNavigationActionItem from "../shared/NavigationActionItem.vue";

const props = defineProps({
    id: String,
    action: String,
    text: String,
    href: String,
    to: String,
    leftIcons: Array,
    rightIcons: Array,
    items: Array,
    badge: Object,
    open: Boolean,
    exactPath: Boolean,
    trigger: String,
    active: Boolean,
});

const className = computed(() => ([
    "pa-secondary-nav__top-level-item",
    props.active ? "pa-secondary-nav__top-level-item--active" : "",
    props.open ? "pa-secondary-nav__top-level-item--open" : "",
    props.action === "nav-menu" ? "pa-secondary-nav__top-level-item--menu" : "",
].join(" ")));

const emit = defineEmits(["menu:toggled", "item:active"]);

const handleMenuToggled = (data) => {
    emit('menu:toggled', data);
};

const handleItemActive = (data) => {
    emit('item:active', data);
};
</script>
