<template>
    <div class="pa-radiomenu" :class="classes.split(/\s+/)">
        <label v-for="opt in options" class="pa-radiomenu-btn" :class="{selected: model === opt.value}">
            <input type="radio" v-model="model" :value="opt.value">
            <span>{{ opt.label }}</span>
        </label>
    </div>
</template>

<script>
    import Vue from 'vue';

    const RadioBar = Vue.extend({
        data() {
            return {};
        },

        props: {
            options: {
                type: Array,
                'default': () => [],
            },

            model: '',

            classes: String,

            onChange: {
                type: Function,
                'default': function() {},
            },
        },

        methods: {
        },
    });

    export default RadioBar;
</script>
