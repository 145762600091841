import { requests } from "../requests";

export const getAllNodes = ({
    combo_size,
    include_unmonitored_nodes,
    viewId,
    includeHopCount,
    hopCount,
    filters,
}) => {
    return requests.get('/topology/get_all_topology_nodes', {
        params: {
            combo_size,
            include_unmonitored_nodes,
            viewId,
            includeHopCount,
            hopCount,
            filters,
        },
    });
};

export const createEdge = ({ source_node_id, target_node_id }) => {
    return requests.post('/topology/create_edge', { source_node_id, target_node_id });
};

export const saveDefaultView = ({ nodes, edges, combos }) => {
    return requests.post('/topology/save_default_view', { nodes, edges, combos });
};

export const saveView = ({
    nodes,
    edges,
    name,
    backgroundImageFile,
    backgroundImageConfig,
    teamViewable,
    zoomLevel,
    filters,
    cloneId,
}) => {
    const formData = new FormData();

    formData.append("nodes", JSON.stringify(nodes));
    formData.append("edges", JSON.stringify(edges));
    formData.append("name", name);

    if (backgroundImageFile instanceof File || !(backgroundImageFile instanceof Object)) {
        formData.append("backgroundImageFile", backgroundImageFile);
    } else {
        formData.append("backgroundImageFile", JSON.stringify(backgroundImageFile));
    }

    formData.append("backgroundImageConfig", backgroundImageConfig ? JSON.stringify(backgroundImageConfig) : null);
    formData.append("teamViewable", teamViewable);
    formData.append("zoomLevel", zoomLevel);
    formData.append("filters", filters ? JSON.stringify(filters) : null);
    formData.append("cloneId", cloneId);

    return requests.post('/topology/save_view', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const convertNodeMonitored = ({ nodeId, serverId }) => {
    return requests.post('/topology/convert_node_monitored', { nodeId, serverId });
};

export const updateView = ({
    viewId,
    nodes,
    edges,
    name,
    backgroundImageFile,
    backgroundImageConfig,
    teamViewable,
    zoomLevel,
    filters,
}) => {
    const formData = new FormData();

    formData.append("viewId", viewId);
    formData.append("nodes", JSON.stringify(nodes));
    formData.append("edges", JSON.stringify(edges));
    formData.append("name", name);

    if (backgroundImageFile) {
        formData.append("backgroundImageFile", backgroundImageFile);
    }

    formData.append("backgroundImageConfig", backgroundImageConfig ? JSON.stringify(backgroundImageConfig) : null);
    formData.append("teamViewable", teamViewable);
    formData.append("zoomLevel", zoomLevel);
    formData.append("filters", filters ? JSON.stringify(filters) : null);

    return requests.post('/topology/update_view', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const deleteView = ({ viewId }) => {
    return requests.post('/topology/delete_view', { viewId });
};

export const getViews = (params = { includeAllView: true }) => {
    return requests.get('/topology/get_views', { params });
};

export const createNode = ({
    sourceNodeId,
    serverId,
    comboId,
    nodeCoords,
    edgeCoords,
}) => {
    return requests.post('/topology/create_node', {
        sourceNodeId,
        serverId,
        comboId,
        nodeCoords,
        edgeCoords,
    });
};

export const setDefaultView = ({ viewId }) => {
    return requests.post('/topology/set_default_view', { viewId });
};

export const getFilterOptions = () => {
    return requests.get('/topology/get_filter_options');
};

export const getRefreshMetadata = () => {
    return requests.get('/topology/get_refresh_metadata');
};

export const triggerRefresh = () => {
    return requests.post('/topology/trigger_refresh');
};

export const getTopologyActivities = () => {
    return requests.get('/topology/get_topology_activies');
};

export const createTopology = () => {
    return requests.post('/topology/create_topology');
};

export const notifyOnCompletion = () => {
    return requests.post('/topology/notify_on_completion');
};

export const getBackgroundImageUrl = ({ viewId }) => {
    return requests.get('/topology/get_background_image_url', { params: { viewId } });
};
