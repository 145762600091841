<style lang="scss" scoped>
.vertical-divider {
    background-color: #b8bec9;
    color: #b8bec9;
    height: 20px;
}

</style>

<template>
    <div>
        <div class="pa-flex pa-align-center">
            <span 
                class="pa-mt-16" 
                style="height: 50px;" 
                v-if="application.icon == 'default-photo'"
            >
                <p-icon
                    icon="default-photo"
                    size="xxxxl"
                >
                </p-icon>
            </span>
            <img :src="fileValue" height="50" width="50" v-else>
            <p-link :add-pointer="true">
                <span 
                    @click="$refs.file.click()" 
                    class="pa-pl-8 pa-txt_medium"
                >
                    {{ fileUploadLinkText }}
                </span>
            </p-link>
            <template v-if="fileUploadLinkText != 'Upload'">
                <p-divider vertical-align class="vertical-divider"></p-divider>
                <p-link :add-pointer="true">
                    <span 
                        class="pa-txt_medium"
                        @click="removeSetting('icon')"
                    >
                        Remove
                    </span>
                </p-link>
            </template>
        </div>
        <p v-show="invalidFile" class="pa-hint pa-hint_error">
            Please use a jpg, jpeg, or png file.
        </p>
        <input 
            ref="file"
            slot='editibleField' 
            @change="processFile" 
            id="file-upload"
            style="display: none;" 
            type="file"/>
    </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    props: {
        application: Object,
        showConfirmOnDelete: {
            type: Boolean,
            default: () => true,
        },
    },
    data: () => ({
        fileValue: null,
        invalidFile: false,
    }),
    events: {
        'cancel-edit-page-field'(fieldName) {
            if(fieldName == 'image') {
                this.fileValue = null;
                this.invalidFile = false;
                this.errors = {};
            }
        },
        'edit-page-field-save-complete'(fieldName) {
            if(this.fileValue == null) {
                this.application['icon'] = 'default-photo';
            } else {
                this.application['icon'] = this.fileValue;
            }   
        },
    },
    watch: {},
    computed: {
        fileUploadLinkText() {
            if(this.application.icon == 'default-photo') {
                return "Upload";
            }

            return "Change";
        },
    },
    methods: {
        processFile() {
            var fileName = this.$refs.file.value,
                idxDot = fileName.lastIndexOf(".") + 1,
                extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (["jpg", "jpeg", "png"].includes(extFile)){
                this.invalidFile = false;
                var file = this.$refs.file['files'][0];
                if (file) {
                    var FR= new FileReader();
                    var self = this;
                    FR.addEventListener("load", function(e) {
                        self.fileValue = e.target.result;
                    }); 
                    FR.readAsDataURL( file );

                    setTimeout(function(){
                        self.eventHub.$emit('save-edit-page-icon', self.fileValue);
                    }, 200);
                }
            } else {
                this.invalidFile = true;
                input.value = ""
            }
        },
        removeSetting(fieldName) {
            if(this.showConfirmOnDelete) {
                this.eventHub.$emit('prompt:load', {
                    id: 'dynamic_prompt',
                    title: 'Remove Image',
                    body: 'Are you sure you want to remove the image?',
                    callback: () => {
                        this.fileValue = null;
                        this.eventHub.$emit('delete-edit-page-icon');
                    },
                });
            } else {
                this.fileValue = null;
                this.eventHub.$emit('delete-edit-page-icon');
            }
        },
    },
    beforeDestroy() {},
    mounted() {
        this.fileValue = this.application.icon;
    },
});
</script>
