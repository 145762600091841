<style>
    .pa-timerange-filter .pa-flyout-box-content {
        width: 335px !important;
    }

    .pa-filter-icon {
        display: inline-flex;
    }

    .pa-filter-box .pa-flyout-box-content {
        padding: 0;
    }

    .filter-label {
        padding-left: 8px;
        line-height: 18px;
    }

    .custom-timerange {
        padding: 7px 10px;
    }
</style>

<template>
    <div class="pa-timerange-filter pa-filter-box" :id="id">
        <p-flyout
            ref="flyout"
            direction="bottom"
            :width="216"
            :lock-scroll="lockScroll"
            :positioning="positioning"
            :popper-direction="popperDirection"
            :filter-selectors="['.arrowUp', '.arrowDown']"
            offset="-5%p, 5px"
            :is-active="flyoutActive"
        >
            <p-button
                color="blue"
                variant="ghost"
                slot="trigger"
                :disabled="disabled"
                class="pa-filter-button"
                :class="{ 'isActive': isFilterActive != -1 && isFilterActive != false }"
                ref="testbutton"
                @click="toggleFlyout"
            >
                <span class="pa-filter-icon">
                    <p-icon
                        v-if="filterIcon"
                        :icon="filterIcon"
                        size="lg"
                        block
                        color="blue"
                    >
                    </p-icon>
                    <span v-if="filterLabel" class="filter-label">
                        {{ filterLabel }}
                    </span>
                </span>
            </p-button>
            <div slot="content">
                <ul v-if="options.length" class="pa-filter-list">
                    <li v-for="option in options">
                        <label class="pa-option">
                            <input @click="select(option.value)"
                                type="radio"
                                class="pa-option-input"
                                :value="option.value"
                                v-model="model"/>
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt pa-txt_truncate">{{ option.label }}</span>
                        </label>
                    </li>
                </ul>
                <div v-if="model === 'custom'" class="custom-timerange">
                    <p-field-group label="Start Time">
                        <p-datetime
                            @click.stop="noop"
                            :date.sync="iStartDate"
                            id="start_time"
                        ></p-datetime>
                    </p-field-group>
                    <p-field-group
                            label="End Time"
                            hint="All incidents between selected time range will be shown">
                        <p-datetime
                            @click.stop="noop"
                            :date.sync="iEndDate" id="end_time"
                        ></p-datetime>
                    </p-field-group>
                </div>
            </div>
        </p-flyout>
    </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import isComponentInModal from '../utils/isComponentInModal';

const Filter = Vue.extend({
    data() {
        return {
            iStartDate: this.startDate,
            iEndDate: this.endDate,
            flyoutActive : false,
        };
    },

    props: {
        options: {
            type: Array,
            'default': () => [],
        },

        model: [String, Number],

        startDate: String,
        endDate: String,

        id: String,

        onChangeCallback: {
            type: Function,
            'default': () => {},
        },

        disabled: {
            type: Boolean,
            'default': false,
        },

        lockScroll: {
            type: Boolean,
            default: true,
        },

        positioning: {
            type: String,
            default: 'fixed',
        },

        popperDirection: String,

        filterIcon: {
            type: String,
            default: "filter-outline"
        },

        filterLabel: String,

        isButton: Boolean,

        filter: String,

        default: Boolean,

        parentName: {
            type: String,
            default: 'incident-overview',
        },

        eventFilterPrefix: {
            type: String,
            default: '',
        },

    },

    watch: {
        iStartDate(value) {
            let filter = 'start_date';

            if (this.eventFilterPrefix) {
                filter = `${this.eventFilterPrefix}__${filter}`;
            }

            this.eventHub.$emit(this.parentName + ':update_data', value, filter);
            this.$emit('update:startDate', value);
            this.onChangeCallback();
        },
        iEndDate(value) {
            let filter = 'end_date';

            if (this.eventFilterPrefix) {
                filter = `${this.eventFilterPrefix}__${filter}`;
            }

            this.eventHub.$emit(this.parentName + ':update_data', value, filter);
            this.$emit('update:endDate', value);
            this.onChangeCallback();
        },
        startDate(value) {
            this.iStartDate = value;
        },
        endDate(value) {
            this.iEndDate = value;
        },
    },

    methods: {
        noop() {},
        select(value) {
            let filter = this.filter;

            if (this.eventFilterPrefix) {
                filter = `${this.eventFilterPrefix}__${filter}`;
            }

            this.eventHub.$emit(this.parentName + ':update_data', value, filter);

            this.$emit('update:timescale', value);
            this.onChangeCallback();
        },

        toggleFlyout(){
            this.flyoutActive = !this.flyoutActive;
        },

    },

    computed: {
        isFilterActive() {
            if (this.default && this.options.length) {

                return this.model !== this.options[0].value
            }
            return this.model
        },
        isEmpty: function() {
            return _.isEmpty(this.options);
        },
    },
});

export default Filter;
</script>
