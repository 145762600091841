import { requests } from "../requests";

export const getRecentWidgets = () => {
    return requests.get('/dashboardv2/getRecentWidgets');
};

export const getRecommendedWidgets = () => {
    return requests.get('/dashboardv2/getRecommendedWidgets');
};

export const getActiveUpcomingMaintenances = ({ data }) => {
    return requests.post('/dashboard/getActiveUpcomingMaintenances', data);
};

export const screenshotDashboard = ({ dashboardId }) => {
    return requests.get('/dashboardv2/screenshot', {
        params: { dashboardId },
        responseType: "blob",
    });
};

export const setWidgetFabricRoot = ({ widgetId, fabricConnectionId }) => {
    return requests.post('/dashboardv2/setWidgetFabricRoot', null, {
        params: { widgetId, fabricConnectionId }
    });
};
