<script setup>
import { ref, computed, watch, reactive } from "vue";
import { FILTER_CONDITIONS } from "../constants/filterConditions"
import PFilterRule from './FilterRule.vue';
import eventHub from "../../eventHub.js";
import _ from "lodash";

const emit = defineEmits(["change"]);

const props = defineProps({
    filters: {
        type: Array,
        default: () => [],
    },
    value: {
        type: Object,
        default: () => ({
            operator: "and",
            rules: [],
        }),
    },
    conditions: {
        type: Array,
        default: () => FILTER_CONDITIONS,
        required: false,
    },
});

// For local development debugging only
const debug = reactive({
    enabled: false,
    collapsed: true,
});
const rules = ref(
    _.isEmpty(props.value)
    ? {
        operator: "and",
        rules: [],
    }
    : props.value
);

watch(() => props.value, (curr, prev) => {
    if (!_.isEqual(curr, prev)) {
        rules.value = _.cloneDeep(curr);
    }
}, { deep: true });

watch(rules, (curr, prev) => {
    if (!_.isEqual(curr, prev)) {
        emit('input', _.cloneDeep(curr));
    }
}, { deep: true });

const getTrackingKey = (rule) => {
    return rule._trackingId || _.uniqueId('filter-rule-');
};

const firstFilter = computed(() => props.filters[0]);

const addRule = () => {
    const defaultRule = {
        _trackingId: getTrackingKey({}),
        filter: firstFilter.value.id,
        condition: "is",
        isValid: true,
        error: null,
        value: getDefaultFilterValue(firstFilter.value.id),
    };

    rules.value = {
        ...rules.value,
        rules: [
            ...rules.value.rules,
            defaultRule
        ],
    };
};

const addPresetRule = (filter, value) => {
    const newRule = {
        _trackingId: getTrackingKey({}),
        filter: filter,
        condition: "is",
        isValid: true,
        error: null,
        value: value,
    };

    rules.value = {
        ...rules.value,
        rules: [
            ...rules.value.rules,
            newRule
        ],
    };
};

const getDefaultFilterValue = (filter) => {
    const matchedFilter = props.filters.find((_filter) => {
        return _filter.id === filter;
    });

    if (matchedFilter.input === 'tags') {
        return [];
    }

    if (matchedFilter.input === 'select') {
        if(matchedFilter.values) {
            return matchedFilter.values[0].value;
        }
        if(matchedFilter.optgroups) {
            return matchedFilter.optgroups[0].options[0].value
        }
    }

    return null;
};

const removeRule = (index) => {
    if (debug.enabled) {
        console.log('[DEBUG] removeRule', { index, ...rules.value.rules[index] });
    }

    rules.value = {
        ...rules.value,
        rules: rules.value.rules.filter((_, i) => {
            return index !== i;
        }),
    };

    // If we changed the operator to 'or' and then removed some rules so
    // that the operator switcher is no longer visible, set the operator
    // back to its default value of 'and'.
    if (rules.value.operator !== "and" && rules.value.rules.length === 1) {
        rules.value = {
            ...rules.value,
            operator: "and",
        };
    }
};

const onRuleRemove = (index) => {
    removeRule(index);
};

const updateRule = (index, field, value) => {
    const currentValue = rules.value.rules[index][field];

    // Because our select menu component doesnt properly update its internal
    // state, when we use :model.sync and watch the values for changes, it will
    // always show the current value for both current and previous. This causes
    // an infinite loop of reactive changes when we are replacing the current value
    // of the rule with the new value.
    if (_.isEqual(currentValue, value)) {
        if (debug.enabled) {
            console.log('[DEBUG] cancelling update, values are the same');
        }
        return;
    }

    if (debug.enabled) {
        console.log('[DEBUG] updateRule', { index, field, value });
    }

    rules.value = {
        ...rules.value,
        rules: rules.value.rules.map((rule, i) => {
            if (i === index) {
                return { ...rule, [field]: value };
            }

            return rule;
        })
    };
};

const onConditionChange = (index, val) => {
    updateRule(index, 'condition', val);
};

const onFilterChange = (index, val) => {
    updateRule(index, 'filter', val);
    updateRule(index, 'value', getDefaultFilterValue(val));
};

const onOperatorChange = (_, val) => {
    rules.value = {
        ...rules.value,
        operator: val,
    };
};

const onValueChange = (index, val) => {
    updateRule(index, 'value', val);
};

defineExpose({ addPresetRule });

</script>

<template>
    <div class="filter-builder">
        <ul class="filter-rules">
            <p-filter-rule
                :key="getTrackingKey(rule)"
                v-for="(rule, index) in rules.rules"
                :index="index"
                :filters="filters"
                :value="rule.value"
                :filter="rule.filter"
                :error="rule.error"
                :operator="rules.operator"
                :condition="rule.condition"
                :conditions="conditions"
                @remove="onRuleRemove"
                @update:value="onValueChange"
                @update:condition="onConditionChange"
                @update:filter="onFilterChange"
                @update:operator="onOperatorChange"
            >
            </p-filter-rule>
            <li class="pa-txt_sm">
                <p-button variant="link" @click="addRule">+ Add Condition</p-button>
            </li>
        </ul>

        <div
            v-if="debug.enabled"
            :class="{
                'filter-builder-debug-output': true,
                'filter-builder-debug-output--collapsed': debug.collapsed
            }"
        >
            <button @click="debug.collapsed = !debug.collapsed">{{ debug.collapsed ? '+' : '-' }}</button>
            <pre>{{ JSON.stringify(rules, null, 6) }}</pre>
        </div>
    </div>
</template>

<style lang="scss">
.filter-builder {
    max-width: 800px;
    background-color: white;
    padding: 12px 16px;
}

.filter-builder-debug-output {
    position: relative;
    margin-top: 12px;

    & > button {
        position: absolute;
        right: 20px;
        top: 8px;
    }

    & > pre {
        outline: lightcoral dashed 1px;
        height: 250px;
        white-space: pre-wrap;
        overflow-y: auto;
        font-size: 12px;
        font-family: monospace;
        padding: 12px;
    }

    &--collapsed {
        & > pre {
            height: 25px;
        }
    }
}
</style>