<template>
    <div class="pa-navigation-action__menu-trigger">
        <button v-if="trigger === 'button'" @click="handleClick" :class="[open && 'open']"  class="pa-navigation-action__menu-trigger-button">
            <div v-if="leftIcons" class="pa-navigation-action__icons pa-navigation-action__icons--left">
                <p-icon
                    v-for="icon in leftIcons"
                    :key="icon"
                    :icon="icon"
                    color="silver"
                    :size="isCollapsed ? 'xxl' : ''"
                >
                </p-icon>
            </div>
            <span :class="[open && 'open']" class="pa-navigation-action__text" :title="text">
                {{ text }}
            </span>
            <div v-if="rightIcons" class="pa-navigation-action__icons pa-navigation-action__icons--right">
                <p-icon
                    v-for="icon in rightIcons"
                    :key="icon"
                    :icon="icon"
                    color="silver"
                >
                </p-icon>
            </div>
            <span v-if="badge && badge.text" class="pa-navigation-action-item__badge">
                <p-badge
                    v-bind="badge.props"
                >
                    {{ badge.text }}
                </p-badge>
            </span>
        </button>
        <p-navigation-action-app-link v-else-if="trigger === 'app-link'"
            :text="text"
            :left-icons="leftIcons"
            :right-icons="rightIcons"
            :to="to"
            :exact-path="exactPath"
        >
        </p-navigation-action-app-link>
    </div>
</template>

<script setup>
import PNavigationActionAppLink from "./NavigationActionAppLink.vue";

defineProps({
    text: String,
    leftIcons: Array,
    rightIcons: String,
    to: String,
    open: Boolean,
    exactPath: Boolean,
    trigger: {
        type: String,
        default: 'button',
    },
    badge: Object,
    isCollapsed: Boolean,
});

const emit = defineEmits(['click']);

const handleClick = () => {
    emit('click');
};
</script>
