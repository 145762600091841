var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"p-on-focusout",rawName:"v-p-on-focusout",value:(_vm.isActive),expression:"isActive"}],staticClass:"pa-menu",class:{
        isActive: _vm.isActive,
        'is-disabled': _vm.disabled,
        'pa-menu_inversed': _vm.inversed,
        'pa-menu_isMaterial': _vm.isMaterial,
        'pa-menu_pinned': _vm.isPinned,
        'pa-menu_profile': _vm.isProfile,
        'pa-menu_absolute': _vm.isAbsolute
        },attrs:{"callback":_vm.close}},[(_vm.numChildren > 0)?_c('div',{ref:"hd",staticClass:"pa-menu-hd",attrs:{"id":_vm.htmlId},on:{"click":function($event){$event.stopPropagation();return _vm._toggle($event)},"mouseover":function($event){return _vm._onMouseover($event)},"mouseout":function($event){return _vm._onMouseout($event)}}},[_vm._t("trigger")],2):_vm._e(),_vm._v(" "),_c('div',{ref:"menu",staticClass:"pa-menu-box",class:{ isActive: _vm.isActive },style:([_vm.styles]),attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm._onClickItem($event)},"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==27)return null;return _vm.close()}}},[(_vm.searchable)?_c('div',{staticClass:"pa-select-list-box",on:{"click":function($event){$event.stopPropagation();}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterText),expression:"filterText"}],ref:"search",staticClass:"pa-input",attrs:{"id":"searchBox","type":"search","placeholder":"Search"},domProps:{"value":(_vm.filterText)},on:{"input":function($event){if($event.target.composing)return;_vm.filterText=$event.target.value}}})]):_vm._e(),_vm._v(" "),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }