<style lang="scss" scoped>

  

</style>

<template>
    <div class="catalog-header-button-container">
        <template v-if="showCheckboxes">
            <button
                id="tag"
                v-if="canTagMetrics"
                class="pa-btn pa-btn_ghost-blue pa-txt_sm"
                :disabled="!checked.length"
                @click.prevent="openBulkTagModal"
            >
                <p-icon
                    icon="tag-outline"
                    style="margin-right: 2px;"
                >
                </p-icon>
                Tag
            </button>
            <button
                id="pause"
                v-if="canPauseMetrics"
                class="pa-btn pa-btn_ghost-blue pa-txt_sm"
                :disabled="!checked.length"
                v-p-prompt-open
                target="dynamic_prompt"
                :title="checked.length === 1 ? 'Pause Metric' : 'Pause Metrics'"
                :body="renderPromptBody('pause')"
                :callback="bulkPause"
            >
                <p-icon
                    icon="pause-circle-outline"
                    style="margin-right: 2px;"
                >
                </p-icon>
                Pause
            </button>
            <button
                id="resume"
                class="pa-btn pa-btn_ghost-blue pa-txt_sm"
                v-if="canPauseMetrics"
                :disabled="!checked.length"
                v-p-prompt-open
                target="dynamic_prompt"
                title="Resume Monitoring"
                :body="renderPromptBody('resume')"
                :callback="bulkResume"
            >
                <p-icon
                    icon="play-circle-outline"
                    style="margin-right: 2px;"
                >
                </p-icon>
                Resume
            </button>
            <button
                v-if="showDelete && canDeleteMetrics"
                id="remove"
                class="pa-btn pa-btn_ghost-blue pa-txt_sm"
                :disabled="!checked.length"
                v-p-prompt-open
                target="dynamic_prompt"
                title="Remove metrics"
                :body="renderPromptBody('remove')"
                :callback="bulkDelete"
            >
                <p-icon
                    icon="close"
                    style="margin-right: 2px;"
                >
                </p-icon>
                Remove
                            </button>
            <p-add-metric-tag-modal 
                :server-id="serverId"
                ref="addMetricTagModal"
                :all-tags="allTags"
            ></p-add-metric-tag-modal>
        </template>
    </div>
</template>

<script>
import Vue from "vue";
import AddMetricTagModal from './AddMetricTagModal.vue'


export default Vue.extend({

    components: {
        'p-add-metric-tag-modal': AddMetricTagModal,
    },
    
    props: {
        serverId: Number,
        allTags: Array,
        showDelete: {
            type: Boolean,
            default: true,
        },
        canTagMetrics: {
            type: Boolean,
            default: false,
        },
        canPauseMetrics: {
            type: Boolean,
            default: false,
        },
        canDeleteMetrics: {
            type: Boolean,
            default: false,
        },
        checkedName: {
            type: String,
            default: ''
        }
    },
    
    events: {
        'checkbox-update': function(values) {
            if (values == '') {
                this.checked = []
            } else {
                this.checked = values.split(',');
            }
        },

    },
    
    data: () => ({
        checked: [],
    }),
    
    computed: {
        showCheckboxes: function() {
            return this.canAddMetrics || this.canTagMetrics || this.canPauseMetrics || this.canDeleteMetrics;
        }
    },
    
    methods: {

        openBulkTagModal: function() {
            this.eventHub.$emit('dialog:open', 'bulk_tag');
            this.$refs.addMetricTagModal.setChecked(this.checked);

        },

        setChecked(checked) {
            this.checked = checked;
        },

        bulkPause: function() {
            var payload = {
                server_id: this.serverId,
                checked: this.checked.join(","),
            };
            $.ajax("/config/PauseMultipleServices", {
                method: 'POST',
                data: payload,
                context: this
            })
            .done(data => {
                window.app.rootVue.$broadcast('dialog:close', 'dynamic_prompt');
                this.showToast(`Successfully paused ${this.checked.length} metric(s)`);
                window.app.rootVue.$broadcast('finished-bulk-action');
            });
        },

        bulkResume: function() {
            var payload = {
                server_id: this.serverId,
                checked: this.checked.join(","),
            };
            $.ajax("/config/ResumeMultipleServices", {
                method: 'POST',
                data: payload,
                context: this
            })
            .done(data => {
                window.app.rootVue.$broadcast('dialog:close', 'dynamic_prompt');
                this.showToast(`Successfully resumed ${this.checked.length} metric(s)`);
                window.app.rootVue.$broadcast('finished-bulk-action');
            });
        },

        bulkDelete: function() {
            const payload = {
                server_id: this.serverId,
                checked: this.checked.join(','),
            };
            $.ajax('/config/DeleteMultipleServices', {
                method: 'POST',
                data: payload,
                context: this,
            })
            .done(data => {
                for (var i = 0; i < this.$parent.categories.added.length; i++) {
                    const newMetrics = this.$parent.categories.added[i].metrics.filter(m => {
                        return !this.checked.includes(m.id);
                    });
                    if (this.$parent.categories.added[i].metrics.length !== newMetrics.length) {
                        this.$parent.categories = {
                            ...this.$parent.categories,
                            added: this.$parent.categories.added.map((a, index) => {
                                if (index === i) {
                                    return {
                                        ...a,
                                        metrics: [...newMetrics]
                                    };
                                }

                                return a;
                            })
                        };
                    }
                }
                this.showToast(`Successfully removed ${this.checked.length} metric(s)`);
                window.app.rootVue.$broadcast('dialog:close', 'dynamic_prompt');
                this.eventHub.$emit('reload_graph_config');
                window.app.rootVue.$broadcast('finished-bulk-action');
                if (!data.success) {
                    console.log(data.msg);
                    return;
                }
            });
        },

        renderPromptBody: function(promptType) {
            const checkedCount = this.checked && this.checked.length ? this.checked.length : 0;

            if (promptType === "resume" || promptType === "pause") {
                let promptBody = "Would you like to " + promptType;

                if (checkedCount === 1) {
                    let checkedName = this.checkedName
                    if(checkedName == '') {
                        checkedName = "this metric"
                    }

                    return promptBody + " " + checkedName + "?";
                }

                return promptBody + " these " + checkedCount + " metrics?";
            } else if (promptType === 'remove') {
                let promptBody = "Are you sure you'd like to remove";

                if (checkedCount === 1) {
                    promptBody += " this metric? It's ";
                } else {
                    promptBody += " these metrics? Their ";
                }

                return promptBody + "performance data will also be removed.";
            }
        },

        showToast: function(text) {
            window.app.toastManager.clearToasts();
            window.app.toastManager.showToast(text);
        },

    },
    
    mounted() {},
    
    beforeDestroy() {},
});
</script>
