<style lang="scss">
.application-list {
    .pa-tooltip-box-content {
        width: 5.5rem;
    }

    .app-logo {
        vertical-align: middle;
        max-height: 32px;
    }

    .app-name {
        margin-bottom: 0;
    }
}
</style>

<template>
    <ul class="application-list pa-hList pa-mt-0">
        <slot></slot>
        <li v-if="!applications || !applications.length" class="pa-mt-0 pa-ml-0">
            None
        </li>
        <li :key="app.name" v-for="app in applications" class="pa-mt-0 pa-ml-0">
            <p-tooltip class="pa-mr-20 pa-mt-4">
                <div slot="trigger">
                    <p-icon
                        v-if="app.logo && app.logo.includes('.svg')"
                        :icon="app.logo.replace('.svg', '')"
                        size="xxxxl"
                    >
                    </p-icon>
                    <img
                        v-if="app.logo && !app.logo.includes('.svg')"
                        class="app-logo"
                        :src="'/static/assets/newux/media/logos/' + app.logo"
                    />
                    <span class="app-logo" v-if="!app.logo">
                        {{ app.name }}
                    </span>
                </div>
                <p class="app-name">{{ app.name }}</p>
            </p-tooltip>
        </li>
    </ul>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        applications: Array,
    },
});
</script>
