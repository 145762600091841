<script setup>
import { computed } from "vue";
import HighchartsConfigs from '../../lib/highcharts-configs';

const props = defineProps({
    type: {
        type: String,
    },
    node: {
        type: Object,
    },
    tracerouteData: {
        type: Object,
        default: () => {},
    },
});

const title = computed(() => {
    if(props.type == "loss") {
        return "Packet Loss"
    }

    return "Latency"
});

const url = computed(() => {
    const timeStamps = Object.keys(props.tracerouteData.timebarData.data);
    const earliestTs = timeStamps[0];
    const latestTs = timeStamps[timeStamps.length -1];
    let checkPathToHop = null
    for(const node of props.tracerouteData.data.nodes) {
        if(node.id == props.node.target) {
            checkPathToHop = node.check_path_to_hop;
        }
    }
    checkPathToHop = JSON.stringify(checkPathToHop);

    let query_params = [
        `data_type=${props.type}`,
        `earliest_ts=${earliestTs}`,
        `latest_ts=${latestTs}`,
        `check_path_to_hop=${checkPathToHop}`,
    ];

    query_params = query_params.join("&");
    return `/traceroute/get_traceroute_hop_historical_data?${query_params}`
});

const lineConfig = JSON.stringify(HighchartsConfigs.sparkline);

const openGraphModal = () => {
    let sourceNode = null;
    let targetNode = null;
    for(const node of props.tracerouteData.data.nodes) {
        if(node.id == props.node.target) {
            targetNode = node;
        }
        if(node.id == props.node.source) {
            sourceNode = node;
        }

        if(targetNode && sourceNode) {
            break;
        }
    }
    const data = {"title": title,
                  "node": props.node,
                  "sourceNode": sourceNode,
                  "targetNode": targetNode,
                  "type": props.type}
    window.app.rootVue.$broadcast('traceRouteGraphData', data);
    window.app.rootVue.$broadcast('dialog:open', 'expanded_historical_data');
};

</script>

<template>
    <div class="tr-edge-graph-wrapper" @click="openGraphModal()">
        <p-graph
            :monitors="[props.type]"
            graphType="traceRouteEdge"
            :highcharts-config="HighchartsConfigs.sparkline"
            :min-height=35
            height="35px"
            :use-root-timescale="false"
            :opposing="false"
            :ignore-timescale-event="true"
            :polling="true"
            :load-timeout="1"
            :load-on-scroll="true"
            :sync-crosshairs="false"
            :loadingSpinner="false"
            :show-legend="false"
            :data-url="url"
        >
        </p-graph>
    </div>
</template>

<style lang="scss">
    .tr-edge-graph-wrapper {
        .loading-text {
            position: relative;
            top: 16px;
        }
    }
</style>