<template>
    <p-menu
        position="bottom-end"
        :is-absolute="isAbsolute"
        :searchable="searchable"
        :focus-search="searchable"
        :max-height="maxHeight"
        :menu-class="menuClass"
        :close-box-on-click-item="closeBoxOnClickItem"
    >
        <button
            slot="trigger"
            type="button"
            :disabled="disabled"
            :class="'pa-navItem ' + cssclass"
            :style="inlineStyle">
            <span class="pa-navItem-box">
                <p-icon
                    v-if="icon"
                    :icon="icon"
                    :color="color"
                    :size="size"
                    class="pa-navItem-box-icon pa-mr-4"
                >
                </p-icon>
                {{ text }}
                <slot name="text"></slot>
                <p-icon icon="chevron-down" size="xl"></p-icon>
            </span>
            <span v-if="newDot" class="pa-badge pa-badge_tiny pa-badge_info_inverse pa-badge_nav"
                  style="position: absolute; width: 1px; height: 5px; top: 8px; right: 2px; z-index: 30;" transition="expand">
            </span>
        </button>
        <slot></slot>
    </p-menu>
</template>

<script>
    import Vue from 'vue';

    const menuMixin = require('../mixins/menuMixin');

    const NavMenu = Vue.extend({
        mixins: [menuMixin],
        props: {
            searchable: {
                type: Boolean,
                default: false,
            },
            maxHeight: Number,
            newDot: Boolean,
            inlineStyle: {
                type: String,
                default: ''
            },
            closeBoxOnClickItem: {
                type: Boolean,
                default: true,
            }
        },
    });

    export default NavMenu;
</script>
