<style lang="scss">
    .option__image {
        max-height: 50px;
        max-width: 50px;
        padding-left: .625rem;
    }
    .option__desc, .option__image {
        display: inline-block;
        vertical-align: middle;
    }
    .option__desc {
        padding: .625rem;
        width: 300px;
    }
    .option__title {
        font-size: 1rem;
    }
    .option__small {
        margin-top: .625rem;
        display: block;
        font-size: .7rem;
    }

    .widget-select-nav {
        height: 50vh;
        border: none !important;
        margin-top: 1rem;
        margin-right: 0;
        width: 8rem;
    }

    .widget-select-list {
        max-height: 62.5vh;
        overflow-y: auto;
    }

    .widget-select-modal {
        background-color: white;
        width: 40vw;
        border-top-left-radius: .2rem;
        border-top-right-radius: .2rem;
        min-width: 18rem;
        max-width: 39rem;
        padding-bottom: 0.5rem;

        @media screen and (max-width: 1050px) {
            width: 60vw;
        }
    }

    .widget-card-div {
        width: 100%;
        padding-top: 1rem;
        min-width: 8rem;

        @media screen and (max-width: 1300px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @media screen and (max-width: 1300px) {
            margin-left: 0;
        }
    }

    .widget-select-widget-section {
        width: 100%;
    }

    .widget-card-category {
        position: absolute;

        @media screen and (min-width: 1300px) {
            margin-left: 2rem;
        }
    }

    #widget-select-search-break {
        display: none;

        @media screen and (max-width: 1300px) {
            display: block;
        }
    }

    .widget-search-div {
        position: relative;

        @media screen and (max-width: 1300px) {
            width: 100%;
        }

        @media screen and (max-width: 580px) {
            left: 1rem;
        }
    }

    .widget-select-search {
        width: 10rem;
        position: absolute;
        right: 2.1rem;
        padding-right: 2rem;

        @media screen and (max-width: 1300px) {
            width: 60%;
            min-width: 8rem;
        }
    }

    .widget-select-search-icon {
        position: absolute;
        right: 2.6rem;
        top: 0.4rem;
    }

    .widget-divider-top {
        margin: 0.1rem 1.5rem;
        position: absolute;
        top: 2.1rem;
        width: 90%;
        right: -0.1rem;
        height: 2px !important;
        background-color: rgba(230, 230, 230, 0.55);
    }

    .widget-card-flex {
        overflow-y: scroll;
        justify-content: center;
        height: 60vh;
        width: 100%;
        margin-top: 2.5rem;
    }

    .widget-select-card-btn {
        width: 12vw;
        height: 23vh;
        min-width: 6.5rem;
        max-width: 15rem;
        margin: 0 0.5rem 1rem;

        @media screen and (max-width: 1200px) {
            height: 25vh;
        }

        @media screen and (max-width: 1500px) {
            width: 40%;
        }
    }

    .widget-card {
        position: relative;
        cursor: pointer;
        height: 100%;
        width: 100%;
        border-top: none !important;
        box-shadow: -6px 0 white, 6px 0 white, 0 7px 5px -2px rgba(33, 49, 111, 0.2) !important;

        &.selected {
            background-color: #f1f3fb;
            cursor: pointer;

            .widget-select-icon {
                border-color: #3954bf;
                fill: #3954bf;
                color: #3954bf;
            }
        }
    }

    .widget-select-card-header {
        width: 9rem;
    }

    .pa-card2 > .pa-card-header {
        justify-content: center !important;
        height: 10vh;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .widget-select-card-icon {
        width: 70%;
        height: 8vh;
        margin: .25rem auto;
        fill: currentColor !important;
        color: currentColor !important;;
        background-color: white;
    }

    .widget-select-card-body {
        margin: auto 0.7rem;

        @media screen and (max-width: 1300px) {
            margin: 0;
        }
    }

    .widget-select-card-title {
        @media screen and (max-width: 1600px) {
            font-size: .7rem;
        }
    }

    .widget-select-card-desc {
        @media screen and (max-width: 1600px) {
            font-size: .65rem;
        }

    }

    .widget-select-card-footer {
        position: absolute;
        bottom: 0;
    }

    .widget-divider-bottom {
        margin-top: 0;
        margin-bottom: .4rem;
    }

</style>

<template>
    <div class="widget-select-modal">
        <div class="pa-panel-hd pa-pl-12">
            <h6 class="pa-hdg pa-hdg_3">Widgets Center: Select a Widget</h6>
        </div>
        
        <p-flex class="widget-select-flex">
            <p-secondary-nav 
            :nav-items="options"
            @item:active="handleActiveItem"
            class="widget-select-nav"
            >
            </p-secondary-nav>

            <div class="widget-card-div">
                <span class="widget-card-category pa-txt_16 pa-txt_bold">{{ widgetCardCategory }}</span>
                <br id="widget-select-search-break"/>
                <div class="widget-search-div">
                    <input
                        type="text"
                        placeholder="Search"
                        name="widget-select-search"
                        id="widget-select-search"
                        class="pa-input widget-select-search"
                        v-model="widgetSearchTerm"
                    />
                    <div class="widget-select-search-icon">
                        <p-icon icon="magnify" size="xl" block>
                        </p-icon>
                    </div>
                    <p-divider class="widget-divider-top"></p-divider>
                </div>
                
                <p-flex class="widget-card-flex" wrap>

                    <p-button 
                        :key="widget.id"
                        v-for="widget in filteredOptions" 
                        @click="handleClickCard(widget)" 
                        unstyled 
                        no-base-class
                        class="widget-select-card-btn"
                    >
                        <p-card
                        :class="widgetOptionLabelClass(widget)"
                        :event-id="widget"
                        :header-class="(
                            widget.background=='red'?'pa-card-widget-red':(widget.background=='green'?'pa-card-widget-green':'pa-card-widget-blue'))"
                        >   
                            <div slot="card-header" class="widget-select-card-header">
                                <p-icon :icon="widget.icon" class="widget-select-card-icon"></p-icon>    
                            </div>    
                            <div class="pa-mt-10 widget-select-card-body">
                                <p class="pa-txt_sm pa-txt_bold pa-mb-6 widget-select-card-title">{{ widget.title }}</p>
                                <p class="pa-txt_sm pa-text-grey-500 widget-select-card-desc">{{ widget.desc }}</p>
                                
                            </div>
                        </p-card>
                    </p-button>
                    
                </p-flex>
            </div>
        
        </p-flex>
        
        <p-divider class="widget-divider-bottom"></p-divider>
        
        <div class="pa-flex pa-justify-end pa-mr-10">
            <button
                @click="handleSubmit"
                type="submit"
                class="pa-btn pa-btn_sm"
                narrow
                :disabled="!selectedValue"
            >
                Continue to Configure
            </button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {
        getRecentWidgets,
        getRecommendedWidgets
    } from "../../api/services/DashboardService";
    import widgetModalOptions from "../../shared/constants/widgetModalOptions.json";

    const WidgetSelect = Vue.extend({
        data() {
            return {
                widgetSearchTerm: "",
                selectedValue: null,
                id_incr: 0,
                id: null,
                options: widgetModalOptions.base,
                active_tab: null,
                recent_widgets: [],
            }
        },

        watch: {},

        props: {
            editingWidget: {
                type: Object,
                default: function () {
                    return {};
                },
            },
            features: {
                type: Array,
                'default': () => [],
            },
        },

        events: {
            'dialog:close'() {
                this.options[0].default = true;
                this.$emit('widgetselect:destroy');
            },

        },

        computed: {
            widgetsComputed() {
                if (this.active_tab) {
                    let widget_options = [];
                    let all_widgets = [];
                    this.options.forEach(option => all_widgets.push(...option.widgets));
                    
                    if (this.active_tab.id === "all") {
                        widget_options = all_widgets;

                    } else if (this.active_tab.id === "recent") {
                        this.recent_widgets.forEach(
                            recent_id => widget_options.push(all_widgets.find(
                                widget => widget.vuetype == recent_id)));
                        widget_options = widget_options.filter( Boolean );

                    } else if (this.active_tab.id === "recommended") {
                        this.recommended_widgets.forEach(
                            recommended_id => widget_options.push(all_widgets.find(
                                widget => widget.vuetype == recommended_id)));
                        widget_options = widget_options.filter( Boolean );

                    } else {
                        widget_options = this.active_tab.widgets;
                    }

                    if (!this.features.includes('geomap')) {
                        widget_options = this.filterGeoMap(widget_options);
                    }

                    return widget_options;
                }
                return [];
                
            },
            widgetCardCategory() {
                if (this.active_tab) {

                    if (this.active_tab.id == "all") {
                        return "All Widgets";

                    } else if (this.active_tab.id == "recommended") {
                        return "Recommended";

                    } else if (this.active_tab.id == "recent") {
                        return "Recently Added";

                    } else {
                        return this.active_tab.text;
                    } 
                }
                
                return '';
            },
            filteredOptions() {
                return this.widgetsComputed.filter((widget) => {
                    const searchValues = [widget.title, widget.desc].map(
                        val => val.trim().toLowerCase()
                    );

                    return searchValues.some(val =>
                        val.includes(this.widgetSearchTerm.trim().toLowerCase())
                    );
                });
            },
            
        },

        methods: {
            handleClickCard(widget) {
                this.selectedValue = widget.vuetype;
            },
            
            async getRecentWidgets() {
                let response;
                try {
                    response = await getRecentWidgets();
                } catch (error) {
                    console.log(error);
                }

                if (response.data.success) {
                    this.recent_widgets = response.data.recent_widgets;
                }
            },

            async getRecommendedWidgets() {
                let response;
                try {
                    response = await getRecommendedWidgets();
                } catch (error) {
                    console.log(error);
                }

                if (response.data.success) {
                    this.recommended_widgets = response.data.recommended_widgets;
                }
            },

            mergeAtIndex(array1, array2, index) {
                return [...array1.slice(0, index), ...array2, ...array1.slice(index)]
            },

            filterGeoMap(widgets) {
                return widgets.filter(widget => !widget.vuetype.includes('p-geomap-widget'));
            },

            handleActiveItem(activeItem) {
                this.active_tab = activeItem;
            },

            widgetOptionLabelClass(widget) {
                return {
                    "widget-card pa-mr-14 pa-mt-10": true,
                    "selected": this.selectedValue === widget.vuetype,
                };
            },

            handleSubmit() {
                if (!this.selectedValue) {
                    return;
                }

                const payload = {
                    dashboard_id: this.$parent.$parent.$parent.id, // Change this to a dispatch?
                    connection_id: this.$parent.$parent.$parent.cId,  // Change this to a dispatch?
                    widget_type: this.selectedValue,
                    width: this.editingWidget.config.width,
                    height: this.editingWidget.config.height,
                    row: this.editingWidget.config.row,
                    col: this.editingWidget.config.col,
                    title: this.editingWidget.config.title,
                    scope_uuid: this.$parent.$parent.$parent.scopeUuid,
                };

                $.ajax('/dashboardv2/addWidget', {
                    method: 'GET',
                    data: payload,
                    context: this,
                })
                    .done(function (data) {
                        if (!data.success) {
                            return;
                        }

                        let config = this.editingWidget.config;
                        if (!config.custom_controls) {
                            config['custom_controls'] = {}
                        }
                        config.title = data.new_title;
                        config.vue_type = this.selectedValue;

                        if (this.selectedValue === 'p-linegraph-table-widget') {
                            // Set the selected defaults for this type
                            config.format_options = [
                                "hide_units",
                                "hide_server_fqdn",
                                "hide_metric_type",
                                "hide_option_name",
                            ];
                        }

                        this.eventHub.$emit('dashboard:confirm_delete', this.editingWidget.id, false);
                        this.eventHub.$emit('widget:add_widget', this.selectedValue, data.new_id, config);
                        Vue.nextTick(() => {
                            window.app.rootVue.$broadcast('dashboard:update_chosen_editing_widget', data.new_id);
                        });

                    });
            },
        },

        created() {
            if (window.dashboard && window.dashboard.hasFeature('kubernetes')) {
                const otherIndex = this.options.findIndex(option => option.text === "Other");
                if (otherIndex !== -1) {
                    this.options = [
                        ...this.mergeAtIndex(
                            this.options,
                            widgetModalOptions.kubernetes,
                            otherIndex
                        )
                    ];
                } else {
                    this.options = [
                        ...this.options,
                        ...widgetModalOptions.kubernetes,
                    ];
                }
            }
            this.active_tab = this.options[0];
            this.getRecentWidgets();
            this.getRecommendedWidgets();
        },
    });

    export default WidgetSelect;
</script>
