<style lang="scss">
    @import "~styles/core/helpers/_variables.scss";

    #sd-wan-performance-table-wrapper {

        min-height: 400px;

        table.dataTable {
            border-bottom: none;
        }

        .pa-table {
            thead tr th, 
            tbody tr td {
                border-left: 0px;
                border-right: 0px;
            }

            thead tr th {
                border-top: 0px;
            }
            
            tbody tr td:last-child {
                border-bottom: none;
                border-right: none;
            }

            thead tr:last-child th {
                border-right: none;
            }

            .tags-column {
                padding-top: 2px;
                padding-bottom: 2px;
            }


            .target-link {
                .pa-icon {
                    color:  var(--p-grey-400);
                    fill:  var(--p-grey-400);
                }
            }
            
        }

    }
    @media (max-width: 720px) {
    	#instance-performance-header {
    		>.pa-grid-col_6of12 {
    			width: 100%;
    		}
    	}
    }
</style>

<template>
    <div id="sd-wan-performance-table-wrapper" class="pa-row pa-px-20 pa-py-16">
        <p-table2
            source="server"
            class="sdWanPerformanceGraphsTable"
            ref="sdWanPerformanceGraphsTable"
            :column-renderers="columnRenderers"
            :column-callbacks="columnCallbacks"
            :column-classes="columnClasses"
            v-if="showTable && groupBy == 'connection'"
            :data-url="`/report/get_sd_wan_performance_table_data?server_id=${instance.id}&group_by=connection`"
        >
            <table slot="table" class="pa-table pa-table_top">
                <thead>
                    <tr class="pa-table-row pa-table-row_hd">
                        <th>Connection</th>
                        <th style="width: 25%">Target</th>
                        <th style="width: 15%">HTTP Full Page Load</th>
                        <th style="width: 15%;">Jitter</th>
                        <th style="width: 15%;">Latency</th>
                        <th style="width: 15%;">Packet Loss</th>
                    </tr>
                </thead>
            </table>
        </p-table2>
        <p-table2
            source="server"
            class="sdWanPerformanceGraphsTable"
            ref="sdWanPerformanceGraphsTable"
            :column-renderers="columnRenderers"
            :column-callbacks="columnCallbacks"
            :column-classes="columnClasses"
            v-if="showTable && groupBy == 'target'"
            :data-url="`/report/get_sd_wan_performance_table_data?server_id=${instance.id}&group_by=target`"
        >
            <table slot="table" class="pa-table pa-table_top">
                <thead>
                    <tr class="pa-table-row pa-table-row_hd">
                        
                        <th style="width: 25%">Target</th>
                        <th>Connection</th>
                        <th style="width: 15%">HTTP Full Page Load</th>
                        <th style="width: 15%;">Jitter</th>
                        <th style="width: 15%;">Latency</th>
                        <th style="width: 15%;">Packet Loss</th>
                    </tr>
                </thead>
            </table>
        </p-table2>
    </div>
</template>

<script>
import Vue from "vue";
import HighchartsConfigs from '../lib/highcharts-configs';

export default Vue.extend({
    props: {
        instance: Object,
        timescale: String,
    },
    
    data: function() {
        return {
            showTable: true,
            groupBy: 'target',
        };
    },

    computed: {
        columnRenderers: function() {
            return {
                0: 'vue',
                1: 'vue',
                'http-column': this.renderStackColSparkGraph,
                'jitter-column': this.renderMultiSparkGraph,
                'latency-column': this.renderMultiSparkGraph,
                'packect-loss-column': this.renderMultiSparkGraph,
            };
        },

        columnCallbacks() {
            return {
                'jitter-column': (td) => {
                    this.compileEl(td);
                },
                'latency-column': (td) => {
                    this.compileEl(td);
                },
                'packect-loss-column': (td) => {
                    this.compileEl(td);
                },
                'http-column': (td) => {
                    this.compileEl(td);
                },
            };
        },

        columnClasses() {
            return {
                2: 'http-column',
                3: 'jitter-column', 
                4: 'latency-column', 
                5: 'packect-loss-column',
            }
        },
    },

    events: {
        'sdwan-filters:update': function(sdWanTargetFilter, wanConnectionFilter) {
            Vue.nextTick(() => {
                let payload = {
                    target_ids: sdWanTargetFilter,
                    wan_conn_ids: wanConnectionFilter,
                    server_id: this.instance.id,
                    group_by: this.groupBy,
                };
                this.$refs.sdWanPerformanceGraphsTable.retrieveTableDataExternal(payload);
            });
        },

        'sdwan-groupby:update': function(groupBy, sdWanTargetFilter, wanConnectionFilter) {
            this.groupBy = groupBy;
            Vue.nextTick(() => {
                let payload = {
                    target_ids: sdWanTargetFilter,
                    wan_conn_ids: wanConnectionFilter,
                    server_id: this.instance.id,
                    group_by: groupBy,
                };
                this.$refs.sdWanPerformanceGraphsTable.retrieveTableDataExternal(payload);
            });
           
        },

        'sdwan-application:refresh': function() {
            this.reloadTable();
        }
    },

    watch: {
        'timescale':function() {
            this.reloadTable();
        }
    },

    methods: {
        renderMultiSparkGraph(data, type, row) {
            const lineConfig = JSON.stringify(HighchartsConfigs.sparkline);
            const timescale = this.timescale;

            let graphs = [];
            for(const metricData of data) {
                let queryString = '';
                for(const id of metricData['group']) {
                    queryString = `${queryString}&metric_ids[]=-${id}`;
                }

                queryString = `${queryString}&sdwan_server_id=${this.instance.id}`;
                queryString = `${queryString}&extra_data=sdwan_flow`;
                queryString = `${queryString}&timescale=${timescale}`;

                const url = `/report/MetricPopup?${queryString}&cluster=${metricData['cluster']}`;
                const params = `{ id: "dynamic_modal", url: "${url}", open: true }`;
                const onClick = `window.app.rootVue.$broadcast("modal:load", ${params})`;

                graphs.push(`
                    <div>
                        <p-graph
                            :monitors="[-${metricData.primary}]"
                            :highcharts-config='${lineConfig}'
                            :min-height=35
                            height="30px"
                            timescale="${timescale}"
                            :use-root-timescale="false"
                            :opposing="false"
                            :ignore-timescale-event="true"
                            :polling="true"
                            :load-timeout="1"
                            :load-on-scroll="true"
                            onclick='${onClick}'
                            extra-data="sdwan_flow"
                            :sync-crosshairs="false"
                            container-name="sdwan_perf"
                        >
                        </p-graph>
                    </div>
                `)
            }
            
            graphs = graphs.join("");
            
            return `<div style="width: 80%">${graphs}</div>`;

        },

        renderStackColSparkGraph(data, type, row) {
            const stackedAreaConfig = JSON.stringify(HighchartsConfigs.sparkStackedArea);
            const timescale = this.timescale;

            let graphs = [];
            for(const metricData of data) {
                let queryString = '';
                let metricGroup = metricData['group'];
                let metricOtherGroup = metricData['wan_groups'];

                for(const id of metricGroup) {
                    queryString = `${queryString}&metric_ids[]=${id}`;
                }

                for(const otherMetric of metricOtherGroup) {
                    queryString = `${queryString}&wan_groups[]=${otherMetric.mp_id}:${otherMetric.wan_name}`;
                }
                
                queryString = `${queryString}&sdwan_server_id=${this.instance.id}`;
                queryString = `${queryString}&extra_data=sdwan_flow`;
                queryString = `${queryString}&timescale=${timescale}`;
                
                const url = `/report/MetricPopup?${queryString}&cluster=${metricData['cluster']}&graph_type=stackedArea`;
                const params = `{ id: "dynamic_modal", url: "${url}", open: true}`;
                const onClick = `window.app.rootVue.$broadcast("modal:load", ${params})`;

                graphs.push(`
                    <div>
                        <p-graph
                            :monitors="[${metricData.group}]"
                            :highcharts-config='${stackedAreaConfig}'
                            :min-height=35
                            height="30px"
                            timescale="${timescale}"
                            :use-root-timescale="false"
                            :opposing="false"
                            :ignore-timescale-event="true"
                            :polling="true"
                            :load-timeout="1"
                            graph-type="stackedArea"
                            onclick='${onClick}'
                            extra-data="sdwan_flow"
                            :load-on-scroll="true"
                            :sync-crosshairs="false"
                            container-name="sdwan_perf"
                        >
                        </p-graph>
                    </div>
                `)
            }
            
            graphs = graphs.join("");
            
            return `<div style="width: 80%">${graphs}</div>`;

        },

        reloadTable() {
            this.showTable = false;
            Vue.nextTick(() => {
                this.showTable = true;
            });
        }
    },

    
});
</script>