<template>
    <div
        class="pa-panels-item"
        v-show="showIf"
        :class="{
            'isActive': isActive
        }">
        <slot></slot>
    </div>
</template>

<script>
    import Vue from 'vue';
    import wizardMixin from './../mixins/wizardMixin';

    const WizardTree = Vue.extend({
        mixins: [wizardMixin],

        data() {
            return {
                activePaneIndex: -1,
                defaultPane: null,
                isActive: false,
                isTree: true,
                nextText: ''
            };
        },

        events: {
            ['wizard:pane:validated']() {
                this.validate();
            },

            ['wizard:pane:ready'](pane) {
                if (pane.isDefault) {
                    this.defaultPane = pane;

                    return;
                }

                this.panes.push(pane);
            }
        },

        methods: {
            /*
            todoactivate() {
                this.isActive = true;

                if (this.defaultPane) {
                    this.activePaneIndex = -1;
                    this.defaultPane.isActive = true;
                    this.deactivateAllPanels();
                    this.nextText = this.defaultPane.nextText;

                    return;
                }

                this.activatePane(this.activePaneIndex);
            },

            tododeactivate() {
                this.activePaneIndex = -1;
                this.deactivateAllPanels();

                if (this.defaultPane) {
                    this.defaultPane.isActive = false;
                    this.nextText = this.defaultPane.nextText;
                }
            },
            */

            deactivateAllPanels() {
                this.panes.forEach(pane => pane.isActive = false);
            },

            activatePane(index) {
                const validatedIndex = this.validatePaneIndex(index);
                this.activePaneIndex = validatedIndex;

                this.panes.forEach((pane, i) => {
                    if (validatedIndex === i) {
                        this.nextText = pane.nextText;
                    }

                    pane.isActive = validatedIndex === i;
                });

                if (this.defaultPane) {
                    this.defaultPane.isActive = false;
                }
            },

            _next() {
                const nextIndex = this.activePaneIndex + 1;

                if (nextIndex >= this.panes.length) {
                    this.$parent._next();

                    return;
                }

                this.goTo(nextIndex);
            },

            nextButtonIsDisabled() {
                let activePaneIsRequired;
                let activePaneIsInvalid;

                if (this.defaultPane && this.defaultPane.isActive) {
                    activePaneIsRequired = this.defaultPane.isRequired;
                    activePaneIsInvalid = !this.defaultPane.isValid;
                } else {
                    const validatedIndex = this.validatePaneIndex(this.activePaneIndex);
                    const activePane = this.panes[validatedIndex];

                    activePaneIsRequired = activePane.isRequired;
                    activePaneIsInvalid = !activePane.isValid;
                }

                return activePaneIsRequired && activePaneIsInvalid;
            },

            _prev() {
                const prevIndex = this.activePaneIndex - 1;

                if (prevIndex < 0) {
                    this.$parent._prev();

                    return;
                }

                this.goTo(prevIndex);
            },

            validate() {
                this.isValid = this.panes.filter(
                    pane => this.isPaneValid(pane)
                ).length === this.panes.length;

                if (this.defaultPane && this.isValid) {
                    this.isValid = this.isPaneValid(this.defaultPane);
                }

                return this.isValid;
            },

            onNext() {
                return this.panes.filter(
                    pane => pane.onNext() === false
                ).length === 0;
            },

            isDefaultPaneValid() {
                if (!this.defaultPane) {
                    return true;
                }

                return this.isPaneValid(this.defaultPane);
            },

            isPaneActivateable(index) {
                if (!this.isDefaultPaneValid()) {
                    return false;
                }

                let isPaneActivateable = true;

                this.panes.some((pane, i) => {
                    if (index === i) {
                        return true;
                    }

                    isPaneActivateable = this.isPaneValid(pane);

                    return !isPaneActivateable;
                });

                return isPaneActivateable;
            }
        },

        props: {
            isRequired: {
                type: Boolean,
                default: true
            },

            isValid: {
                type: Boolean,
                default: false
            },

            title: {
                type: String,
                default: ''
            },

            showIf: {
                type: Boolean,
                default: true
            },

            showNext: {
                type: Boolean,
                default: true
            },
        },

        vueReady() {
            this.eventHub.$emit('wizard:tree:ready', this);
        }
    });

    export default WizardTree;
</script>
