<template>
    <li :class="className">
        <p-navigation-action-item
            :id="id"
            :action="action"
            :text="text"
            :title="text"
            :href="href"
            :to="to"
            :left-icons="leftIcons"
            :right-icons="rightIcons"
            :items="items"
            :is-collapsed="isCollapsed"
            :badge="badge"
            :open="open"
            :exact-path="exactPath"
            :trigger="trigger"
            :active="active"
            :has-active-child="hasActiveChild"
            :button="button"
            @menu:toggled="handleMenuToggled"
            @item:active="handleItemActive"
        >
        </p-navigation-action-item>
    </li>
</template>

<script setup>
import { computed } from "vue";
import PNavigationActionItem from "../shared/NavigationActionItem.vue";

const props = defineProps({
  id: String,
  action: String,
  text: String,
  href: String,
  to: String,
  leftIcons: Array,
  rightIcons: Array,
  items: Array,
  isCollapsed: Boolean,
  badge: Object,
  open: Boolean,
  exactPath: Boolean,
  trigger: String,
  active: Boolean,
  hasActiveChild: Boolean,
  button: Object,
});

const className = computed(() => ([
    "pa-side-nav__top-level-item",
    "pa-py-8",
    props.active ? "pa-side-nav__top-level-item--active" : "",
    props.open ? "pa-side-nav__top-level-item--open" : "",
    props.action === "nav-menu" ? "pa-side-nav__top-level-item--menu" : "",
].join(" ")));

const emit = defineEmits(["menu:toggled", "item:active"]);

const handleMenuToggled = (data) => {
    emit('menu:toggled', data);
};

const handleItemActive = (data) => {
    emit('item:active', data);
};
</script>
