<style lang="scss">
    // @import "~styles/core/helpers/_variables.scss";

    #login-header {
        text-align: center;

        .logo {
            height: 61px;
            width: 108.4px;
        }

        .title {
            height: 28px;
            color: #394449;
            font-size: 28px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 28px;
        }

    }

    #login-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .login-btn {
        width: 110px;
        height: 28px;
    }

    .login-txt {
        line-height: 50%;
    }

    .line-3 {
        box-sizing: border-box;
        height: 1px;
        width: 106px;
        border: 1px solid #E6E6E6;
        margin-left: 8px;
        margin-right: 8px;
    }

    .or-login-with {
        height: 14px;
        color: #868686;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
    }
</style>

<template>
    <div id="login">
        <div id="login-header">
            <div>
              <img src="/static/images/Fortinet.png" style="width: 300px;" />
              <br />
              <img src="/static/images/FortiMonitor-dark.png" class="logo" style="width: 60px; align: center; margin-top: 25px;">
            </div>
            <h2 class="title pa-mt-24">Welcome to {{brand_name}}</h2>

            <div style="display: flex; flex-direction: column; margin: 31px 0 16px 0;" v-if="type==='sso'">
                <div>
                    <p class="pa-txt">Login with SSO</p>
                </div>
            </div>

            <p-alert
                v-if="message"
                :variant="messageType === 'danger' ? 'red' : 'green'"
                :full-width="true"
                :short="true"
                :bold="true"
                :icon="messageType === 'danger' ? 'circle-error' : 'information'"
                style="margin: 10px 0;">
                <p v-html="message"></p>
            </p-alert>
        </div>

        <div id="login-body" class="pa-mt-20">
            <p-standard-login
                v-if="type==='standard'"
                ref="loginComponent"
                :previousUrl="previousUrl"
                class="pa-mt-48"
                ></p-standard-login>
            <p-sso-login
                v-if="type === 'sso'"
                ref="loginComponent"
                class="pa-mt-48"></p-sso-login>
        </div>

        <div id="login-footer">
            <div class="login-button">
                <p-button @click="submit"
                    class="login-btn"
                    style="margin-top: 8px; margin-bottom: 8px;"
                    :disabled="!filledIn">
                    <p v-if="type === 'standard'" class="pa-txt login-txt">Login</p>
                    <p v-if="type === 'sso'" class="pa-txt login-txt">Continue</p>
                </p-button>
            </div>
            <div style="display: flex; align-items: center;">
                <hr class="line-3">
                <p class="or-login-with" style="margin: 24px 0 24px 0;">or login with</p>
                <hr class="line-3">
            </div>
            <div class="login-button">
                <p-button @click="switchToSSO"
                    style="margin: 8px 0 16px 0;"
                    class="login-btn"
                    v-if="type==='standard'"
                    variant="border-dark-grey"
                    type="button">
                    <p class="pa-txt login-txt">SSO</p>
                </p-button>
            </div>
            <div class="login-button">
                <p-button @click="switchToEmail"
                    class="login-btn"
                    style="margin-top: 24px; margin-bottom: 16px;"
                    v-if="type==='sso'"
                    variant="border-dark-grey"
                    type="button">
                        <p class="pa-txt login-txt">Email</p>
                </p-button>
            </div>
            <div class="login-button">
                <p-button
                    @click="goForticloud"
                    class="login-btn"
                    variant="border-dark-grey">
                    <p class="pa-txt login-txt">FortiCloud</p>
                </p-button>
            </div>

            <div class="pa-flex" style="justify-content: center; margin-top: 30px;">
                <p class="pa-txt_16">
                    <a
                        :href="brand_textkey === 'fortinet.fortimonitor' ? 'https://www.fortinet.com/offers/fortimonitor-free-trial' : 'https://www.panopta.com/free-network-monitoring-trial/'"
                        class="pa-clickable underline"
                    >
                        Sign up for a free 30-day trial
                    </a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    import StandardLogin from './StandardLogin.vue';
    import SSOLogin from './SSOLogin.vue';

    export default Vue.extend({
        components: {
            'p-standard-login': StandardLogin,
            'p-sso-login': SSOLogin,
        },

        props: {
            brand_name: String,
            brand_textkey: String,
            message: String,
            messageType: String,
            previousUrl: String,
            forticloudUrl: String,
        },

        data() {
            return {
                type: 'standard',
                filledIn: false,
            };
        },

        computed: {
        },

        events: {
            'login:filledIn'(filledIn) {
                this.filledIn = filledIn;
            },
        },

        methods: {
            submit() {
                this.$refs.loginComponent.submit();
            },

            signUp() {
                this.eventHub.$emit('auth:switchType', 'signup');
            },

            switchToSSO() {
                this.type = 'sso';
            },

            switchToEmail() {
                this.type = 'standard'
            },

            goForticloud() {
                let url = location.origin + this.forticloudUrl;
                location.href = url;
            }
        },
    });
</script>
