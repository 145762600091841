<template>
    <div>
        <div
            class="pa-panels-item"
            style="position: static;"
            v-if="showIf"
            :class="{
                    'isActive': localActive
                    }">
            <div class="pa-panels-item-bd">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const WizardPane = Vue.extend({
        data() {
            return {
                inModal: false,
                localActive: this.isActive,
                validators: [],
            };
        },

        vueReady() {
            this.eventHub.$emit('wizard:pane:ready', this);
        },

        methods: {
            showError() {
                this.hasError = true;
            },

            validate() {
                if (this.showIf === false) {
                    return true;
                }

                this.validators.some(function(validator) {
                    let isValid = validator();
                    this.isValid = isValid;
                    //this.hasError = !isValid;

                    if (!isValid) {
                        return true;
                    }

                    return false;
                }.bind(this));

                return this.isValid;
            }
        },

        props: {
            id: {
                type: String,
                default: ''
            },

            disabled: {
                type: Boolean,
                default: false
            },

            isDefault: {
                type: Boolean,
                default: false
            },

            title: {
                type: String,
                default: ''
            },

            titleBadge: {
                type: String,
                default: ''
            },

            titleBadgeClass: {
                type: String,
                default: ''
            },

            onNext: {
                type: Function,
                default: function() {} // noop
            },

            nextText: {
                type: String,
                default: ''
            },

            showNext: {
                type: Boolean,
                default: true
            },

            showBack: {
                type: Boolean,
                default: true
            },

            isValid: {
                type: Boolean,
                default: false
            },

            isRequired: {
                type: Boolean,
                default: false
            },

            showIf: {
                type: Boolean,
                default: true
            },

            hasError: {
                type: Boolean,
                default: false
            },

	    sortOrder: {
		type: Number,
		default: 0
	    }
        },

        watch: {
            localActive(newValue) {
                if (!newValue) {
                    return;
                }

                if (this.isModal) {
                    return;
                }

                window.location.hash = this.id;
            },

            isValid(newValue) {
                this.eventHub.$emit('wizard:pane:validated');
            }
        }
    });

    export default WizardPane;
</script>
