<style lang="scss">
 table.dataTable thead .sorting,
 table.dataTable thead .sorting_asc,
 table.dataTable thead .sorting_desc,
 table.dataTable thead .sorting_asc_disabled,
 table.dataTable thead .sorting_desc_disabled {
     cursor: pointer;
     *cursor: hand;
 }
 table.dataTable thead .sorting,
 table.dataTable thead .sorting_asc,
 table.dataTable thead .sorting_desc,
 table.dataTable thead .sorting_asc_disabled,
 table.dataTable thead .sorting_desc_disabled {
     background-repeat: no-repeat;
     background-position: center right;
 }

 table.dataTable tbody tr td {
     font-size: 13px;
 }
 table.dataTable thead th {
     background: transparent !important;
     white-space: nowrap;
     font-size: 0.6470588235rem;
     font-weight: 500;
     padding: 10px;
     padding-left: 17px;
     text-transform: uppercase;
 }
 table.dataTable thead span.sort-icon {
     display: inline-block;
     padding-left: 8px;
     width: 8px;
     height: 16px;
     vertical-align: -4px;
 }
table.dataTable thead .sorting span {
     background: url('/static/assets/newux/media/svgs/sort.svg') no-repeat center right;
}
table.dataTable thead .sorting_asc span {
     background: url('/static/assets/newux/media/svgs/sort-active-asc.svg') no-repeat center right;
}
 table.dataTable thead .sorting_desc span {
     background: url('/static/assets/newux/media/svgs/sort-active.svg') no-repeat center right;
 }

 table.dataTable thead .sorting_asc_disabled span { background: url('/static/assets/newux/media/icons/sort_asc_disabled.png') no-repeat center right; }
 table.dataTable thead .sorting_desc_disabled span { background: url('/static/assets/newux/media/icons/sort_desc_disabled.png') no-repeat center right; }
 /* Until this point. */

    .page-length-width {
        visibility: hidden;
        height: 1px;
        position: absolute;
        padding: 0;
        border: 0;
        clip: rect(0 0 0 0);
        margin: -1px;
        overflow: hidden;
    }

    .table2-container .wrapper {
        height: 100%;
        width: 100%;
    }

    .fortinet .table2-container .pa-loader {
        color: #DA291C;
        fill: #DA291C;
    }

    .table2-container.refreshing .pa-loader {
        display: none;
    }

    .table2--bottom {
        label {
            select {
                width: 18px !important;
            }
        }
    }

    .pa-table-row-checkbox:disabled {
        & + .pa-option-icon:after {
                background-color: #EBEBE4;
                background-image: None;
        }
    }
    

</style>
<template>
    <div class="table2-container" :class="{'refreshing': isRefreshing}">
        <slot name="filters-full"></slot>
        <div class="table-controls">
          <div style="margin-left: 10px; margin-bottom: 5px; margin-top: 4px;" id="table-buttons">
            <slot name="buttons"></slot>
          </div>
            <div style="margin-left: 10px; margin-bottom: 5px;" id="table-filters">
              <slot name="filters"></slot>
            </div>
        </div>
        <div><input type="text" v-model="checked" name="checked" hidden/></div>
        <slot name="table"></slot>
        <!-- Use this to calculate the width for the page length dropdown -->
        <span class="page-length-width pa-txt_13">{{ pageLength }}</span>
    </div>
</template>

<script>
import Vue from 'vue';
import SearchManager from './../utils/searchManager.js';
import HighchartsConfigs from '../../lib/highcharts-configs';
import _ from 'lodash';

import $ from 'jquery';
import URI from 'urijs';
import Cookie from 'cookiejs';
import bandwidthSort from './../utils/BandwidthSort';
import bandwidthCapacitySort from './../utils/BandwidthCapacitySort';
import dataTables from 'datatables.net';
import dataTablesSelect from 'datatables.net-select';
import dataTablesColReorder from 'datatables.net-colreorder';
import dataTablesScroller from 'datatables.net-scroller';

dataTables(window, $);
dataTablesSelect(window, $);
dataTablesColReorder(window, $);
dataTablesScroller(window, $);

const MAXIMUM_ERROR_THRESHOLD = 5;

const Table2 = Vue.extend({

    data() {
        return {
            options: {},
            checked: '',
            unchecked:'',
            refreshTimeout: null,
            isRefreshing: false,
            errorCount: 0,
            dataTable: null,
            maxReached: false,
            checkedTableRows: [],
        };
    },

    props: {
        source: {
            type: String,
            default: "ajax"
        },
        colReorder: {
            type: Boolean,
            default: true,
        },
        dataUrl: {
            type: String,
            default: ""
        },
        tableName: {
            type: String,
            default: ""
        },
        paging: {
            type: Boolean,
            default: false,
        },
        scroller: {
            type: [Boolean, Object],
            default: false,
        },
        scrollCollapse: {
            type: Boolean,
            default: false,
        },
        info: {
            type: Boolean,
            default: false,
        },
        ordering: {
            type: Boolean,
            default: false,
        },
        filtering: {
            type: Boolean,
            default: false,
        },
        hideDefaultSearch: {
            type: Boolean,
            default: false,
        },
        groupColumn: {
            type: Number,
            default: null,
        },
        groupRowTemplate: {
            type: String,
            default: ""
        },
        groupRowColSpan: {
            type: String,
        },
        groupRowValIndex: {
            type: Number,
            default: null,
        },
        rowClassColumn: {
            type: Number,
            default: null,
        },
        childRowColumn: {
            type: Number,
            default: null,
        },
        childRowType: {
            type: String,
            default: "rows",
        },
        childTriggerSelector: {
            type: String,
            default: "a.showchild",
        },
        columnRenderers: {
            type: Object,
            default: function() {
                return {};
            },
        },
        columnCallbacks: {
            type: Object,
            'default': function() {
                return {};
            },
        },
        columnClasses: {
            type: Object,
            default: function() {
                return {};
            },
        },
        colReorder: {
            type: Boolean,
            default: true,
        },
        orderColumns: {
            type: Object,
            default: function() {
                return {};
            },
        },
        emptyMessage: {
            type: String,
            default: "No data available."
        },
        pageLength: {
            type: Number,
            default: 50,
        },
        pageLengthOptions: {
            type: Array,
            'default': function() {
                return [
                    [25, 50, 100, -1],
                    [25, 50, 100, "View All"]
                ];
            },
        },
        refreshEvents: {
            type: Array,
            default: function() {
                return [];
            },
        },
        filters: {
            type: Object,
            default: function() {
                return {};
            },
        },
        idCol: {
            type: Number,
            default: null,
        },
        selectEvent: {
            type: String,
            default: ""
        },
        datetimeSelector: {
            type: Array,
            default: () => []
        },
        storeParams: {
            type: Boolean,
            default: false,
        },
        cookieName: {
            type: String,
            default: "TableFilters"
        },
        tableSelector: {
            type: String,
            default: ""
        },
        avoidFirstTable: {
            type: Boolean,
            default: false,
        },
        checkAll: {
            type: Boolean,
            default: false,
        },
        sortColumn: {
            type: Number,
            default: -1,
        },
        sortDirection: {
            type: String,
            default: '',
        },
        toggleExpandedSearch: {
            type: Boolean,
            default: true,
        },
        pagingType: {
            type: String,
            default: 'simple_numbers',
        },
        maxAllowed: {
            type: Boolean,
            default: false,
        },
        maxCheckBoxAllowed: {
            type: Number,
            default: 0,
        },
        maxReachedText: {
            type: String,
            default: '',
        },
        many: Boolean,
        allowPaginationChange: Boolean,
        scrollY: String,
        scrollX: {
            type: Boolean,
            default: false,
        },
        searchPlaceholder: String,
        deferRender: Boolean,
        onInitComplete: Function,
        columns: Array,
        onRowDraw: Function,
        onDrawCallback: Function,
        onPaginateCallback: Function,
        refreshInterval: {
            type: Number,
            default: 0,
        },
        lengthChange: {
            type: Boolean,
            default: true,
        },
        groupRowColspan: {
            type: String,
            default: '',
        },
        autoWidth: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        "filters.dateRanges": function(curr, prev) {
            if (!_.isEqual(curr, prev)) {
                this.filters.dateRanges = curr;
                this.dataTable.draw();
            }
        },
        "filters.severities": function(curr, prev) {
            if (!_.isEqual(curr, prev)) {
                this.filters.severities = curr;
                this.dataTable.draw();
            }
        },
        "pagingType": function(curr, prev) {
            if (this.options) {
                this.options.pagingType = this.pagingType;
            }
        },
        "many": function(curr, prev) {
            if (this.options) {
                this.options.language.info = this.many ? "_START_ - _END_ of many" : "_START_ - _END_ of _TOTAL_";
            }
        },
        scrollY() {
            if (this.dataTable && this.scroller) {
                this.$nextTick(() => {
                    this.dataTable.scroller.measure();
                    this.dataTable.draw();
                });
            }
        },
    },
    events: {
        'table:reload_table_data': function(rows) {
            this.reloadTableData(rows);
        },
        'table:reload_ajax': function(rows) {
            this.reloadAjax();
        },
        'table:change_page_len': function(new_length) {
            this.changePageLen(new_length);
        },
        'table:create_table': function() {
            this.createTable();
        },
    },
    computed: {
        computedPageLengthOptions() {
            let [values = [], labels = []] = [...this.pageLengthOptions];
            const pageLength = parseInt(this.pageLength);
            const hasOption = values.includes(pageLength);

            if (this.pageLength && !hasOption) {
                values.push(pageLength);
                labels.push(pageLength);

                // Sort the values after weve added the new option in
                values = _.sortBy(values, this.sortPageLengthOptions);
                labels = _.sortBy(labels, this.sortPageLengthOptions);
            }

            return [values, labels];
        },
    },

    methods: {
        reloadTableData(rows) {
            if (this.dataTable) {
                this.dataTable.clear().draw();
                this.dataTable.rows.add(rows).draw(false);
            }
        },
        reloadAjax() {
            this.dataTable.ajax.reload();
        },
        changePageLen(new_length) {
            if (!this.dataTable) {
                return;
            }

            this.dataTable.page.len(parseInt(new_length)).draw();
        },
        onHashChange() {
            // Just in case it wasnt previously in view, because if it isnt in view
            // when its initially loaded the size will be incorrect
            this.resizePageLengthSelect();
            this.dataTable.columns.adjust();
        },
        resizePageLengthSelect() {
            // HTML Selects dont change width based on the length of the text content that is selected
            // So if we want it to have a variable width, append the content of the selected value into
            // its own elemenent with the same font and lettering properties. When the dropdown value
            // changes, calculate the width the select should be based on the width of the hidden element.
            const pageLengthWidthEl = this.$el.getElementsByClassName("page-length-width")[0];
            const pageLengthEl = this.$el.querySelector(".dataTables_length select");

            if (pageLengthEl && pageLengthWidthEl) {
                const dspText = pageLengthEl.options[pageLengthEl.selectedIndex].text;
                // Update the text of the hidden element
                pageLengthWidthEl.textContent = dspText;

                // Safeguard so we dont make it too small or too large
                if (_.inRange(pageLengthWidthEl.offsetWidth, 5, 200)) {
                    pageLengthEl.style.width = `${pageLengthWidthEl.offsetWidth}px`;
                }
            }
        },
        sortPageLengthOptions(value) {
            // So 'View All' can be last
            return _.isString(value) || value < 0
                ? Infinity
                : value;
        },
        showColumn: function(column_id, redraw) {
            this.dataTable.column(column_id).visible(true, redraw);
        },

        hideColumn: function(column_id, redraw) {
            this.dataTable.column(column_id).visible(false, redraw);
        },

        moveColumn: function(fromIndex, toIndex) {
            this.dataTable.colReorder.move(fromIndex, toIndex);
        },

        setColumnOrder: function(order) {
            return this.dataTable.colReorder.order(order);
        },

        setSort: function(column_id, direction) {
            this.dataTable.order([[column_id, direction]]);
        },

        render_plain: function(data, type, row) {
            return data;
        },

        render_image: function(data, type, row) {
            if (type === 'display') {
                return `<img class='pa-img' src='${data}'/>`;
            } else {
                return data;
            }
        },

        render_avatar: function(data, type, row) {
            if (type === 'display') {
                return `<div class='pa-media pa-media_center'><div class='pa-media-hd'><img class='pa-img pa-img_avatar' src='${data[1]}'/></div><div class='pa-media-bd'>${data[0]}</div></div`;
            } else {
                return data[0];
            }
        },

        render_avatar_mini_list: function(data, type, row) {
            if (type === 'display') {
                if (data && data[0] === '') {
                    return [];
                };
                const pieces = [];
                for (let i = 0; i < data.length; i++) {
                    const user = data[i];
                    let piece = `<img class='pa-image pa-img_avatar pa-img_xxxs' src='${user[1]}' title='${user[0]}' />`; // eslint-disable-line max-len
                    if (user.length > 2 && user[2]) {
                        piece = `<a ${user[2]}>${piece}</a>`;
                    }
                    pieces.push(piece);
                }
                return pieces.join('');
            } else {
                if (data.length) {
                    return data[0];
                } else {
                    return [];
                }
            }
        },

        render_avatar_list: function(data, type, row) {
            const cleanId = row.id

            let ackBroadcast = `window.app.rootVue.$broadcast('acknowledge-incident-drawer:open', { "incidentIds": ${cleanId} })`;
            if (type === 'display') {
                if (!data) {
                    return [];
                };

                let piece = '';

                if (data.length > 1) {
                    piece = `<p-avatar rounded-full num-avatars="${data.length}"></p-avatar>`;
                } else if (data.length === 0) {
                    piece = `<p-avatar action="${ackBroadcast}" rounded-full no-avatar></p-avatar>`;
                } else {
                    let invert = ''
                    if (data[0].invert) {
                        invert = 'invert'
                    }
                    piece = `<p-avatar rounded-full ${invert} initials="${data[0].initials}"></p-avatar>`;
                }

                return piece;
            } else {
                if (data.length) {
                    return data[0];
                } else {
                    return [];
                }
            }
        },

        render_menu: function(data, type, row) {
            if (type === 'display') {
                if (!data.length) {
                    return '';
                }
                const pieces = ['<p-material-menu><button type=\'button\' slot=\'trigger\' class=\'pa-btn pa-btn_dot-menu pa-btn_narrow\'><span class=\'pa-icon pa-icon_dots\'></span></button>'];
                const render_menu_button = this.render_menu_button;
                $.each(data, (i, v) => {
                    const text = v[0];
                    const type = v[1];
                    const href = v[2];
                    const icon = v[3];

                    if (type === 'submenu') {
                        pieces.push(`<p-sub-menu icon='${icon}' text='${text}'>`);
                        $.each(href, (i, v) => {
                            const [_title, _type, _href] = v;
                            pieces.push(render_menu_button(_title, _type, _href, icon));
                        });
                        pieces.push('</p-sub-menu>');
                    } else {
                        pieces.push(render_menu_button(text, type, href, icon));
                    }
                });
                pieces.push('</p-material-menu>');

                return pieces.join('');
            } else {
                // For all other purposes, return empty string so it doesn't get picked up for search/sort
                return '';
            }
        },

        render_menu_button: function(text, type, href, icon) {
            // Render one button that appears in a manu
            if (icon) {
                text = `<svg class='pa-icon'><use xlink:href='#${icon}'></use></svg> ${text}`;
            }
            if (type === 'modal') {
                return `<button v-p-dialog-open template='${href}' target='dynamic_modal'>${text}</button>`;
            } else if (type === 'drawer') {
                return `<button v-p-drawer-open template='${href}' target='dynamic-drawer'>${text}</button>`;
            } else if (type === 'prompt') {
                const title = href[0].replaceAll('\'', '&#39');
                const body = href[1].replaceAll('\'', '&#39');
                const page = href[2].replaceAll('\'', '&#39');
                let callback;

                if (href[3]) {
                    callback = `callback='${href[3].replace('\'', '&#39')}'`;
                }

                return `<button v-p-prompt-open target='dynamic_prompt' ${callback} title='${title}' body='${body}' page='${page}'>${text}</button>`;
            } else if (type === 'onclick') {
                href = href.replaceAll('\'', '&#39');
                return `<button onclick='${href}'>${text}</button>`;
            } else {
                return `<a href='${href}'>${text}</a>`;
            }
        },

        render_list: function(data, type, row) {
            if (type === 'display') {
                let content = '<div class=\'pa-vList\'>';
                for (let i = 0; i < data.length; i++) { content += `<div>${data[i]}</div>`; }
                content += '</div>';
                return content;
            } else {
                return data;
            }
        },

        render_checkbox: function(data, type, row) {
            if (type === 'display') {
                // For display, create a checkbox and set it's class so we can pick it up later to process click events
                let class_name = 'pa-option-input pa-table-row-checkbox';
                if (this.groupColumn != null) {
                    class_name += ` pa-table-row-group-${row[parseInt(this.groupColumn)]}`;
                }
                if (data == -1) {
                    return '';
                }

                if (this.maxAllowed && this.maxReached && !this.checkedTableRows.includes(data.toString())) {
                    return `<label title='${this.maxReachedText}' class="pa-option"><input type='checkbox' disabled class='${class_name}' value='${data}'/><span class='pa-option-icon'></span></label>`;
                }

                return `<label class="pa-option"><input type='checkbox' class='${class_name}' value='${data}'/><span class='pa-option-icon'></span></label>`;
            } else {
                // For all other purposes, return empty string so it doesn't get picked up for search/sort
                return '';
            }
        },

        render_truncated: function(data, type, row) {
            if (type === 'display') {
                // For truncated text show a portion of the text with an ellipsis to expand.
                if (data.length >= 25) {
                    return `<p class='p-text' title='${data}'>${data.substring(0, 25)}...</p>`;
                } else {
                    return `<p class='pa-txt'>${data}</p>`;
                }
            } else {
                return data;
            }
        },

        render_tags: function(data, type, row) {
            if (type === 'display') {
                let tags = data[0].match(/<span class='pa-badge pa-badge_tag'>.*?<\/span>/g);

                if (tags && tags.length) {
                    tags = tags.filter(tag => tag);
                }

                if (tags && tags.length > 2) {
                    const [first, second, ...rest] = tags;
                    const flyout = `
                    <p-flyout
                        hover
                        :wait-time="0"
                        :hover-timeout="100"
                        positioning="popper"
                        popper-direction="bottom-start"
                    >
                        <span slot="trigger" class="pa-text-blue-500 pa-txt_sm inline-block pa-pl-4">
                            + ${tags.length  - 2} more
                        </span>
                        <p-flex slot="content" class="pa-text_left" dir="column" align-start>
                            ${rest.join(' ')}
                        </p-flex>
                    </p-flyout>
                    `;
                    return `${first} ${second} ${flyout}`;
                } else {
                    return data;
                }
            } else {
                return data;
            }
        },

        render_link: function(data, type, row) {
            let class_name = 'pa-anchor';

            if (data.className) {
                class_name = `${class_name} ${data.className}`;
            }

            if (data.link && data.target) {
                return `<a href='${data.link}' class='${class_name}' target='${data.target}'>${data.title}</a>`;
            } else if (data.link) {
                let titleAttr = '';
                if (data.tooltip) {
                    titleAttr = `title='${data.tooltip}'`;
                }
                return `<a href='${data.link}' class='${class_name}' ${titleAttr}>${data.title}</a>`;
            } else if (data.title) {
                return `<p class='pa-txt'>${data.title}</p>`;
            } else {
                return '';
            }
        },

        render_badge: function(data, type, row) {
            let idata = data;
            if (typeof data === 'string') {
                return data;
            }
            if (!Array.isArray(data)) {
                idata = [data];
            }
            let index;
            let result = '';

            let extra_icon = '';
            for (let i = 0; i < idata.length; i++) {
                const badge = idata[i];
                if (!badge) {
                    continue;
                }
                if (type !== 'display' && badge.text) {
                    return badge.text;
                }
                if (badge.badge_type) {
                    const class_name = `pa-txt_capitalize pa-m-0 pa-px-4 pa-badge pa-badge_${badge.badge_type == 'critical' ? 'red' : 'orange'}`;
                    result = `${result}<span class='${class_name} ${badge.additionalClassName}'>${badge.icon ? badge.icon : ''}${badge.text}</span>`;
                }
                if (badge.link) {
                    result = `<a href="${badge.link}">${result}</a>`;
                }
                if (badge.flyout) {
                    let width = '';
                    let href = '#';
                    if (badge.flyout.width) {
                        width = `width="${badge.flyout.width}"`;
                    }
                    if (badge.flyout.trigger_url) {
                        href = badge.flyout.trigger_url;
                    }
                    result = `<a href="${href}" v-p-flyout-open template="${badge.flyout.url}" ${width}>${result}</a>`; // eslint-disable-line max-len
                }

                if (badge.extra_icon) {
                    let icon_data = badge.extra_icon;
                    let icon = '#wrench';
                    if (icon_data.icon) {
                        icon = `#${icon_data.icon}`;
                    }
                    if (icon_data.flyout) {
                        let flyout = icon_data.flyout;
                        let direction='';
                        let schedule='';
                        let width = '';
                        if (flyout.width) {
                            width = `width="${flyout.width}"`;
                        }
                        if (flyout.trigger_schedule) {
                            // schedule = `onclick="window.app.eventHub.$emit('load-schedule', {schedule_id: ${flyout.trigger_schedule}})"`;
                            schedule = `onclick="window.app.eventHub.$emit('drawer:load', {id: 'dynamic-drawer',
                            url: '/config/EditMaintenanceSchedule?maintenance_schedule_id=${flyout.trigger_schedule}'})"`;
                        }
                        if (flyout.direction) {
                            direction = `direction="${flyout.direction}"`;
                        }
                        let left_margin = ""
                        if(result != ""){left_margin = "pa-ml-4"}
                        extra_icon = `<a href="#" ${schedule} v-p-flyout-open template="${flyout.url}" ${width} ${direction} style="position: relative;"><span class="pa-badge pa-badge_teal ${left_margin}">Maint</span></a>`;
                    }
                }
                if (extra_icon) {
                    result += extra_icon;
                }
            }
            return result;
        },

        render_icon: function(data, type, row) {
            const icon = data.icon;
            if (!icon) {
                return '';
            }

            if (type === 'display') {
                let fill = '';
                let addClass = '';
                if (data.fill) {
                    fill = `style="fill: ${data.fill};"`;
                }
                if (data.class) {
                    addClass = data.class;
                }
                let markup = `<p-icon class="${addClass}" ${fill} icon="${icon}" icon-title="${data.title}"></p-icon>`
                if (data.link) {
                    markup = `<a href="${data.link}">${markup}</a>`;
                } else if (data.drawer) {
                    const drawer = data.drawer;
                    let anonymous = '';
                    if (drawer.anonymous === false) {
                        anonymous = 'anonymous="false"';
                    }
                    let drawerWidth = '';
                    if (drawer.width) {
                        drawerWidth = `:width="${drawer.width}"`;
                    }
                    markup = `<a href="#" v-p-drawer-open target="${drawer.id}" template="${drawer.link}" ${anonymous} ${drawerWidth}>${markup}</a>`; // eslint-disable-line max-len
                } else if (data.modal) {
                    const modal = data.modal;
                    markup = `<a href="#" v-p-dialog-open target="${modal.id}" template="${modal.link}">${markup}</a>`; // eslint-disable-line max-len
                }
                return markup;
            } else {
                return icon;
            }
        },

        render_icons: function(data, type, row) {
            if (!data) {
                return '';
            }

            let markup = ''

            for (var icon of data) {
                if (type === 'display') {
                    let fill = '';
                    let addClass = '';
                    let hoverText = '';
                    if (icon.fill) {
                        fill = `style="fill: ${icon.fill};"`;
                    }
                    if (icon.class) {
                        addClass = icon.class;
                    }
                    if (icon.hover_text) {
                        hoverText = `v-p-hover-text="'${icon.hover_text}'" mode="tooltip2"`;
                    }
                    markup += `<svg class="pa-icon ${addClass}" ${fill} ${hoverText}><use xlink:href="#${icon.icon}"></use></svg>`;
                }
            }

            return markup
        },

        render_graph: function(data, type, row) {
            if (!data) {
                return '&mdash;';
            }

            const monitors = data.monitors;
            if (!monitors || monitors.length == 0) {
                return '&mdash;';
            }

            if (type === 'display') {
                const chartConfig = HighchartsConfigs.sparkline;

                const timescale = data.timescale || 'hour';
                const opposing = data.opposing ? 'true' : 'false';

                let queryString = '';
                monitors.forEach(m_id => {
                    queryString = `${queryString}&metric_ids[]=${m_id}`;
                });
                const url = `/report/MetricPopup?${queryString}`;
                const params = `{ id: 'dynamic_modal', url: '${url}', open: true }`;
                const onClick = `window.app.rootVue.$broadcast("modal:load", ${params})`;

                const pGraph = document.createElement('p-graph');
                pGraph.setAttribute(':monitors', `[${monitors}]`);
                pGraph.setAttribute(':highcharts-config', JSON.stringify(chartConfig));
                pGraph.setAttribute(':min-height', 15);
                pGraph.setAttribute('height', '15px');
                pGraph.setAttribute('timescale', timescale);
                pGraph.setAttribute(':use-root-timescale', 'false');
                pGraph.setAttribute(':opposing', opposing);
                pGraph.setAttribute('onclick', onClick);
                pGraph.setAttribute('style', 'cursor: pointer;');

                return pGraph.outerHTML;
            } else {
                return data.monitors;
            }
        },

        render_vue: function(data, type, row) {
            if (type === 'display') {
                return data;
            } else {
                return '';
            }
        },

        render_hidden: function(data, type, row) {
            return `<span style='display:none;'>${data}</span>`;
        },

        render_availability: function(data, type, row) {
            let rank = 0;
            if (data === 'N/A') {
                rank = -1;
            } else {
                rank = parseFloat(data.replace(' %', ''));
            }
            return `<span data-order="${rank}">${data}</span>`;
        },

        render_compound: function(data, type, row) {
            if (type !== 'display') {
                return data;
            } else if (!data) {
                return '';
            }

            let markup = '';
            for (const part of data) {
                const colType = part.col_type;
                const colData = part.data;
                if (colType && colData && typeof this[`render_${colType}`] === 'function') {
                    markup += this[`render_${colType}`](colData, type, row);
                }
            }
            return markup;
        },

        compile_cell: function(td, cellData, rowData, row, col) {
            // Need to compile the Vue component once the table cell is created
            window.app.rootVue.$compile(td);
        },

        selectAll: function() {
            let nodes = this.dataTable.column(0).nodes();
            var checked_vals = [];
            $('input.pa-table-row-checkbox', nodes).each(function() {
                const $row = $(this).closest('tr');
                $row.addClass('selected');
                $(this).prop('checked', true);
                checked_vals.push(this.value);
            });
            this.checked = checked_vals.join(",");
        },

        unSelectAll: function() {
            let nodes = this.dataTable.column(0).nodes();
            var checked_vals = [];
            $('input.pa-table-row-checkbox', nodes).each(function() {
                const $row = $(this).closest('tr');
                $row.removeClass('selected');
                $(this).prop('checked',false)
            });
            this.checked = checked_vals.join(",");
        },
        update_checkboxes: function() {
            // Helper method called when a checkbox is toggled, used to update the "checked" model variable
            var checked_vals = [];
            let added = [];
            let removed = [];
            var unchecked_vals = [];
            let nodes = null;
            const vue_obj = this;
            this.maxReached = false;

            if (this.checkAll && !this.dataTable) {
                nodes = this.$el;
            } else {
                nodes = this.dataTable.column(0).nodes();
            }

            $('input.pa-table-row-checkbox', nodes).each(function() {
                $(this).removeAttr('disabled')

                const $row = $(this).closest('tr');
                const $label = $(this).closest('label');
                $label.removeAttr('title');

                if ($(this).prop('checked')) {
                    $row.addClass('selected');
                    checked_vals.push($(this).val());
                    added.push($(this).val());
                } else {
                    unchecked_vals.push($(this).val())
                    $row.removeClass('selected');
                    removed.push($(this).val());
                } 
            });

            if (this.maxAllowed) {
                for(const addedId of added) {
                    if(!this.checkedTableRows.includes(addedId)){
                        this.checkedTableRows.push(addedId)
                    }
                }
            
                for(const removedId of removed) {
                    this.checkedTableRows = _.remove(this.checkedTableRows, (id) => id != removedId);
                }

                if(this.checkedTableRows.length >= this.maxCheckBoxAllowed) {
                    this.maxReached = true;
                    $('input.pa-table-row-checkbox', nodes).each(function() {
                    const $label = $(this).closest('label');
                    if (!vue_obj.checkedTableRows.includes($(this).val())) {
                        $(this).attr('disabled', '');
                        $label.attr('title', vue_obj.maxReachedText);
                    } 
                });
                }
            } 

            this.eventHub.$emit('checkbox-update-added-removed', added, removed);
            this.checked = checked_vals.join(",");
            this.unchecked = unchecked_vals.join(",");

            // Toggle any buttons that are dependent on at least one checkbox being checked
            // Update all inputs that have a certain class with the value passed to it.
            // Also update modal loading templates to include the values in their urls.
            const uris = $('button.checkbox_input', this.$el);
            if (this.checked) {
                $('button.pa-disable-until-checked', this.$el).prop('disabled', false);
                $('input.checkbox_input', this.$el).prop('value', this.checked);
                for (var i = 0; i < uris.length; i++) {
                    var element = uris[i];
                    this.add_query(element, 'checked', this.checked);
                }
                // this.eventHub.$emit('checkbox-update', this.checked, this.tableName);
                this.eventHub.$emit('checkbox-update', this.checked, this.unchecked, this.tableName);
            } else {
                $('button.pa-disable-until-checked', this.$el).prop('disabled', true);
                $('input.checkbox_input', this.$el).prop('value', this.checked);
                var element;
                for (var i = 0; i < uris.length; i++) {
                    var element = uris[i];
                    this.remove_query(element, 'checked');
                }
                // this.eventHub.$emit('checkbox-update', this.checked, this.tableName);
                this.eventHub.$emit('checkbox-update', this.checked, this.unchecked, this.tableName);
            }
        },

        check_all: function(event) {
            let checkboxColumn = 0;
            if (this.groupColumn == 0) {
                checkboxColumn = 1;
            }
            const cells = this.dataTable.column(checkboxColumn).nodes();
            for (let i = 0; i < cells.length;i++) {
                let value = cells[i].querySelector('input[type=\'checkbox\']');
                if (value) {
                    value = value.checked;
                    cells[i].querySelector('input[type=\'checkbox\']').checked = event.target.checked;
                }
            }
            
            this.update_checkboxes();
        },

        add_query: function(element, attribute, value) {
            const elHref = element.getAttribute('href');
            if (elHref) {
                const href = URI(elHref);
                href.removeQuery(attribute);
                href.addQuery(attribute, value);
                element.setAttribute('href', href.href());
            }
        },

        remove_query: function(element, attribute) {
            const elHref = element.getAttribute('href');
            if (elHref) {
                const href = URI(elHref);
                href.removeQuery(attribute);
                element.setAttribute('href', href.href());
            }
        },

        updateTableData: function(name, value, store) {
            const uri = URI(this.dataUrl);
            let params = URI.parseQuery(uri.query());
            if (name) {
                params[name] = value;
            }

            if (this.storeParams && store) {
                params = this.updateStoredParams(params);
            }

            this.retrieveTableData(params);

            this.updateFiltersValue(params);
        },

        updateStoredParams: function(params) {
            const stored_params = Cookie.get(this.cookieName);
            const cookie_value = SearchManager.constructCookie(params, stored_params);
            Cookie.set(this.cookieName, cookie_value);

            return SearchManager.extractCookies(cookie_value);
        },

        retrieveTableData: function(params, callback) {
            this.dataUrl = SearchManager.constructSearch(this.dataUrl, params);
            this.dataTable.ajax.url(this.dataUrl).load();
        },

        retrieveTableDataExternal: function(params) {
            const dataUrl = SearchManager.constructSearch(this.dataUrl, params);
            this.dataTable.ajax.url(dataUrl).load();
        },

        getRecordsFiltered: function() {
            return this.dataTable.ajax.json().recordsFiltered;
        },

        updateFiltersValue: function(params) {
            const filters = this.getTableFilters();
            for (const index in filters) {
                if (index != 'length') {
                    const filter = filters[index];
                    const updated_value = params[filter.id];
                    if (filter.type === 'checkbox') {
                        filter.checked = updated_value;
                    } else {
                        if (updated_value || updated_value === '') {
                            filter.value = updated_value;
                        }
                    }
                }
            }
            this.eventHub.$emit('table2-filters:updated');
        },

        getTableFilters: function() {
            const filters = this.$el.querySelectorAll('.pa-filter-value');
            return filters;
        },

        clearFiltersStorage: function() {
            const params = SearchManager.extractCookies(Cookie.get(this.cookieName));
            Cookie.remove(this.cookieName);

            let index;
            for (index in params) {
                params[index] = '';
            }

            this.retrieveTableData(params);

            this.updateFiltersValue(params);

            this.eventHub.$emit('table2-filters:cleared');
        },

        selectRow: function(id) {
            const indexes = this._getIndexes(id);
            if (indexes.length) {
                const row = indexes[0];
                const rowsOnOnePage = dataTable.page.len();
                const nodePosition = dataTable.rows({ order: 'current' }).eq(0)
                    .indexOf(row);
                const pageNumber = Math.floor(nodePosition / rowsOnOnePage);
                this.dataTable.page(pageNumber).draw(false); // move to page with the element
                this.dataTable.row(row).select();
                setTimeout(this.dataTable.rows().deselect, 3000);
            }
        },

        getRow: function(id) {
            const indexes = this._getIndexes(id);
            if (indexes.length) {
                return indexes[0];
            }
        },

        _getIndexes: function(id) {
            const dataTable = this.dataTable;
            const indexes = dataTable.rows().eq(0)
                .filter(rowIdx => {
                    return dataTable.cell(rowIdx, 0).data() === parseInt(id) ? true : false;
                });
            return indexes;
        },

        createTable: function() {
            // Method to explicitly control the table creation time.
            if (!this.dataTable) {
                this.dataTable = this.$table.DataTable(this.options);
                this.dataTable.draw(false);
            }
        },

        createTableWithParams: function(params) {
            if (!this.dataTable) {
                const dataUrl = SearchManager.constructSearch(this.dataUrl, params);
                const opts = Object.assign({}, this.options, { ajax: dataUrl });
                this.dataTable = this.$table.DataTable(opts);
            }
        },

        destroyTable: function() {
            if (this.dataTable) {
                this.dataTable.destroy();
                this.dataTable = null;
            }
        },

        _shouldOrderFirstColumn: function() {
            if (this.columnRenderers && this.columnRenderers[0] === 'checkbox') {
                return false;
            } else {
                return true;
            }
        },

        pageLengthChanged: function(model) {
            this.eventHub.$emit('table:change_page_len', model);
        },

        search: function(value) {
            this.dataTable.search(value).draw();

            // Recalculate height of table when we filter with a search term.
            // Without this, the table body scroll will overlap the table rows and
            // become unreadable
            if (this.dataTable.scroller) {
                this.dataTable.scroller.measure();
            }
        },
        handlePaginate(data) {
            if (!this.$el) return;

            const lengthEl = this.$el.querySelector(".dataTables_length");
            const paginateEl = this.$el.querySelector(".dataTables_paginate");
            const pagingElements = [lengthEl, paginateEl];

            for (const index in pagingElements) {
                const el = pagingElements[index];

                if (!el) continue;

                if (!data.length || this.scroller) {
                    el.style.display = "none";
                } else {
                    el.style.display = "flex";
                }
            }

            // Unselect the check all on page turn.
            const checkAll = this.$el.querySelectorAll('.pa-table-check-all');
            if(checkAll && checkAll.length) {
                checkAll[0].checked = false;
            }

            if (this.onPaginateCallback) {
                this.onPaginateCallback();
            }
        },
        startRefreshInterval() {
            this.isRefreshing = false;
            if (this.refreshTimeout) {
                window.clearTimeout(this.refreshTimeout);
                this.refreshTimeout = null;
            }
            this.refreshTimeout = window.setTimeout(() => {
                if (this.dataTable) {
                    this.isRefreshing = true;
                    this.dataTable.ajax.reload();
                }
            }, this.refreshInterval * 1000);
        },
    },

    beforeDestroy() {
        this.destroyTable();
        window.removeEventListener('hashchange', this.onHashChange);
    },

    vueReady() {
        window.addEventListener('hashchange', this.onHashChange);
        const self = this;

        let domSettings = 'frtS';
        if (this.hideDefaultSearch) {
            domSettings = 'rtS';
        }

        $.fn.dataTable.ext.errMode = function(settings, helpPage, message) {
            self.errorCount += 1;
            throw new Error(message)
        };

        // Set the base table options
        this.options = {
            autoWidth: this.autoWidth,
            scroller: this.scroller,
            scrollCollapse: this.scrollCollapse,
            paging: this.paging,
            pagingType: this.pagingType,
            ordering: this.ordering,
            info: this.info,
            searching: this.filtering,
            ajax: this.dataUrl,
            serverSide: this.source !== 'ajax',
            processing: this.source !== 'ajax',
            deferRender: this.deferRender,
            pageLength: parseInt(this.pageLength),
            language: {
                paginate: {
                    previous: "<svg class='pa-icon'><use xlink:href='#chevron-left'></use></svg>",
                    next: "<svg class='pa-icon'><use xlink:href='#chevron-right'></use></svg>",
                },
                emptyTable: this.emptyMessage,
                processing: '<div class=\'pa-loader\'></div>',
                info: this.many ? "_START_ - _END_ of many" : "_START_ - _END_ of _TOTAL_",
                lengthMenu: "Rows per page: _MENU_"
            },
            lengthMenu: this.computedPageLengthOptions,
            lengthChange: this.lengthChange,
            dom: `<"wrapper"${domSettings}>${this.scroller ? '' : '<"table2--bottom"lip>'}<"clear">`,
            columnDefs: [{
                targets: 0,
                orderable: this._shouldOrderFirstColumn(),
            }],
            select: {
                style: 'api',
                className: 'highlight',
                info: false,
            },
            preDrawCallback: function(settings) {
                if (self.errorCount >= MAXIMUM_ERROR_THRESHOLD) {
                    if (self.errorCount === MAXIMUM_ERROR_THRESHOLD) {
                        throw new Error('[Table] Maximum error threshold reached.');
                    }

                    return false;
                }

                return true;
            },
            initComplete: function(...args) {
                if (self.onInitComplete) {
                    self.onInitComplete(...args);
                }
            },
            drawCallback: function(args) {
                if (self.onDrawCallback) {
                    self.onDrawCallback(args);
                }
            },
            footerCallback: function(tfoot, data) {
                self.handlePaginate(data);
            },
            rowCallback: function(row, data) {
                if (self.onRowDraw) {
                    self.onRowDraw(row, data);
                }
            }
        };

        if (this.columns) {
            this.options.columns = this.columns;
        }

        if (this.colReorder) {
            this.options.colReorder = {
                // Don't actually allow dragging
                // We just want the API
                fixedColumnsLeft: 100,
                realtime: false,
            };
        }

        if (this.filters && this.options.serverSide) {
            const self = this;

            this.options.ajax = {
                url: self.dataUrl,
                data: function(d) {
                    const filters = Object.assign({}, self.filters);

                    d.filters = {};

                    Object.keys(filters).forEach(function(key) {
                        const value = filters[key];

                        switch (key) {
                            case "dateRanges":
                                d.filters.start_date = value[0];
                                d.filters.end_date = value[1];
                                break;
                            case "severities":
                                d.filters.severities = value.trim() || undefined;
                                break;
                            default:
                                if (_.isString(value)) {
                                    d.filters[key] = value.trim();
                                } else if (_.isArray(value)) {
                                    d.filters[key] = [...value];
                                } else if (_.isPlainObject(value)) {
                                    d.filters[key] = {...value};
                                } else {
                                    d.filters[key] = value;
                                }
                                break;
                        }
                    });
                }
            };
        }

        if (this.searchPlaceholder) {
            this.options.language.searchPlaceholder = this.searchPlaceholder;
        }

        if (this.scrollX) {
            this.options.scrollX = true;
        }

        if (this.scrollY) {
            this.options.scrollY = this.scrollY;
            this.options.scrollX = true;
        }

        if (this.checkAll) {
            const vue_obj = this;
            this.options.drawCallback = function(settings, json) {
                const cells = this.api().column(0)
                    .nodes();
                for (let i = 0; i < cells.length; i++) {
                    const value = cells[i].querySelector('input[type=\'checkbox\']').checked;
                    cells[i].querySelector('input[type=\'checkbox\']').checked = !value;
                }
                vue_obj.update_checkboxes();
            };
        }

        if (this.idCol !== null) {
            this.options.columnDefs = [{ targets: this.idCol, visible: false }];
        }

        // If we need to pull out query parameters and use them to load data, don't do the initial
        // pull, which will duplicate requestsa
        if (this.storeParams) {
            this.options.deferLoading = 0;
        }

        // If we're doing filtering and server-side data collection, add a delay to not search too frequently
        if (this.filtering && this.source !== 'ajax') {
            this.options.searchDelay = 1000;
        }

        // Set any custom column renderers, and build a mapping of column->renderer for any custom
        // renderers that we use, so we can easily refer to them later if we're building up
        // child rows
        this.renderers = {};
        for (let col in this.columnRenderers) {

            const column_type = this.columnRenderers[col];

            if (typeof column_type === 'function') {
                if (typeof col === 'string') {
                    const parsed = parseInt(col);
                    if (!isNaN(parsed)) {
                        col = parsed;
                    }
                }
                // TODO Seems we need to force it to numeric for now. Autodetection isn't working.
                this.options.columnDefs.push({ targets: col, render: column_type, type: 'numeric' });
                this.renderers[col] = column_type;
            } else if (column_type === 'plain') {
                this.options.columnDefs.push({ targets: parseInt(col), render: this.render_plain });
                this.renderers[col] = this.render_plain;
            } else if (column_type === 'avatar') {
                this.options.columnDefs.push({ targets: parseInt(col), render: this.render_avatar });
                this.renderers[col] = this.render_avatar;
            } else if (column_type === 'avatar-mini-list') {
                this.options.columnDefs.push({ targets: parseInt(col),
                    render: this.render_avatar_mini_list,
                    createdCell: this.compile_cell,
                });
                this.renderers[col] = this.render_avatar_mini_list;
            } else if (column_type === 'avatar-list') {
                this.options.columnDefs.push({ targets: parseInt(col),
                    render: this.render_avatar_list,
                    createdCell: this.compile_cell,
                });
                this.renderers[col] = this.render_avatar_list;
            } else if (column_type == 'checkbox') {
                this.options.columnDefs.push({ targets: parseInt(col),
                    render: this.render_checkbox,
                    className: 'pa-table-col-checkbox' });
                this.renderers[col] = this.render_checkbox;
            } else if (column_type == 'list') {
                this.options.columnDefs.push({ targets: parseInt(col), render: this.render_list });
                this.renderers[col] = this.render_list;
            } else if (column_type == 'menu') {
                this.options.columnDefs.push({ targets: parseInt(col),
                    render: this.render_menu,
                    className: 'pa-table-col-three-dots',
                    createdCell: this.compile_cell });
                this.renderers[col] = this.render_menu;
            } else if (column_type == 'link') {
                this.options.columnDefs.push({ targets: parseInt(col), render: this.render_link });
                this.renderers[col] = this.render_link;
            } else if (column_type == 'badge') {
                this.options.columnDefs.push({
                    targets: parseInt(col),
                    render: this.render_badge,
                    createdCell: this.compile_cell,
                });
                this.renderers[col] = this.render_badge;
            } else if (column_type == 'icon') {
                this.options.columnDefs.push({
                    targets: parseInt(col, 10),
                    render: this.render_icon,
                    createdCell: this.compile_cell,
                });
                this.renderers[col] = this.render_icon;
            } else if (column_type == 'icons') {
                this.options.columnDefs.push({
                    targets: parseInt(col, 10),
                    render: this.render_icons,
                    createdCell: this.compile_cell,
                });
                this.renderers[col] = this.render_icons;
            } else if (column_type == 'graph') {
                this.options.columnDefs.push({
                    targets: parseInt(col, 10),
                    render: this.render_graph,
                    createdCell: this.compile_cell,
                });
                this.renderers[col] = this.render_graph;
            } else if (column_type == 'vue') {
                this.options.columnDefs.push({ targets: parseInt(col),
                    render: this.render_vue,
                    createdCell: this.compile_cell });
                this.renderers[col] = this.render_vue;
            } else if (column_type == 'hidden') {
                this.options.columnDefs.push({
                    targets: parseInt(col),
                    render: this.render_hidden,
                });
                this.renderers[col] = this.render_hidden;
            } else if (column_type == 'truncated') {
                this.options.columnDefs.push({
                    targets: parseInt(col),
                    render: this.render_truncated,
                });
                this.renderers[col] = this.render_truncated;
            } else if (column_type == 'image') {
                this.options.columnDefs.push({
                    targets: parseInt(col),
                    render: this.render_image,
                });
                this.renderers[col] = this.render_image;
            } else if (column_type === 'tags') {
                this.options.columnDefs.push({
                    targets: parseInt(col),
                    render: this.render_tags,
                    createdCell: this.compile_cell,
                });
                this.renderers[col] = this.render_tags;
            } else if (column_type == 'availability') {                
                this.options.columnDefs.push({ targets: col, bSortable: true, type: 'availabilitySort' });
            }
            else if (column_type == 'bandwidth') {
                this.options.columnDefs.push({ targets: col, bSortable: true, type: 'bandwidthSort' });
            }
            else if (column_type == 'bandwidth_capacity') {
                this.options.columnDefs.push({ targets: col, bSortable: true, type: 'bandwidthCapacitySort' });
            }
            else if (column_type == 'datetime') {
                this.options.columnDefs.push({ targets: col, bSortable: true, type: 'datetimeSort' });
            } else if (column_type === 'compound') {
                this.options.columnDefs.push({
                    targets: parseInt(col),
                    render: this.render_compound,
                    createdCell: this.compile_cell,
                });
                this.renderers[col] = this.render_compound;
            }
        }

        for (let col in this.columnCallbacks) {
            const columnCallback = this.columnCallbacks[col];
            const colIndex = col;
            // See if we already added above
            let found = false;
            for (const addedCol in this.options.columnDefs) {
                const columnDef = this.options.columnDefs[addedCol];
                if (columnDef.targets === col) {
                    columnDef.createdCell = columnCallback;
                    found = true;
                    break;
                }
            }
            if (!found) {
                // Add a new column
                this.options.columnDefs.push({
                    targets: col,
                    createdCell: columnCallback,
                });
            }
        }

        for (let col in this.columnClasses) {
            const columnClass = this.columnClasses[col];
            const colIndex = col;
            const parsed = parseInt(col);
            if (!isNaN(parsed)) {
                col = parsed;
            }
            // See if we already added above
            let found = false;
            for (const addedCol in this.options.columnDefs) {
                const columnDef = this.options.columnDefs[addedCol];
                if (columnDef.targets === col) {
                    columnDef.className = columnClass;
                    found = true;
                    break;
                }
            }
            if (!found) {
                // Add a new column
                this.options.columnDefs.push({
                    targets: col,
                    'className': columnClass
                });
            }
        }

        for (const col in this.orderColumns) {
            const enabled = Boolean(this.orderColumns[col]);
            if (enabled) {
                // Orderable is already the default
                continue;
            }
            // See if we already added above
            let found = false;
            for (const addedCol in this.options.columnDefs) {
                const columnDef = this.options.columnDefs[addedCol];
                if (columnDef.targets === parseInt(col)) {
                    columnDef.orderable = false;
                    found = true;
                    break;
                }
            }
            if (!found) {
                // Add a new column
                this.options.columnDefs.push({ targets: parseInt(col),
                    orderable: false });
            }
        }

        // Set a row-style if there is a column specified for that
        if (this.rowClassColumn) {
            this.options.columnDefs.push({ targets: this.rowClassColumn, visible: false });
            const column = this.rowClassColumn;
            const row_class_column = this.rowClassColumn;
            this.options.createdRow = function(row, data, index) {
                if (data[row_class_column] !== '') {
                    $(row).addClass(data[row_class_column]);
                }
            };
            this.renderers[this.rowClassColumn] = null;
        }

        // If we have a child row column (which contains the URL to fetch child rows), hide that column
        if (this.childRowColumn) {
            this.options.columnDefs.push({ targets: this.childRowColumn, visible: false });
            this.renderers[this.childRowColumn] = null;
        }

        // If we have a group column, hide the group column and add a callback to draw the group rows
        const vue_obj = this;
        if (this.groupColumn != null) {
            const column = parseInt(this.groupColumn);
            this.options.columnDefs.push({ targets: column, visible: false });
            this.renderers[column] = null;

            this.options.drawCallback = function(settings) {
                const api = this.api();
                const rows = api.rows({ page:'current' }).nodes();
                let last = null;

                api.column(column, { page:'current' }).data()
                    .each((group, i) => {
                        let comp_group = group;
                        if (group instanceof Array) {
                            comp_group = group[0];
                        }

                        if (last !== comp_group) {
                        // Create two variables for use in the group template, need to push them up
                        // to the table component so it can be picked up by the interpolate method
                            vue_obj.group = group;

                            // const row_content = vue_obj.$interpolate(template);
                            let groupRowTemplate = '';

                            if(Array.isArray(group) && typeof self.groupRowValIndex !== 'undefined' && self.groupRowValIndex >= 0) {
                                groupRowTemplate = `<tr class='pa-table-row-col_grouped'><th colspan="${self.groupRowColSpan}">${group[self.groupRowValIndex]}</th></tr>`;
                            } else {
                                groupRowTemplate = `<tr class='pa-table-row-col_grouped'><th colspan="${self.groupRowColSpan}">${group}</th></tr>`;
                            }

                            $(rows).eq(i)
                                .before(groupRowTemplate);
                            last = comp_group;
                        }
                    });
            };
        }

        if (this.sortColumn >= 0 && this.sortDirection.length) {
            this.options.order = [[this.sortColumn, this.sortDirection]];
        }

        // Create table
        if (!this.tableSelector) {
            this.$table = $(this.$el.querySelector('table'));
        } else {
            this.$table = $(this.$parent.$el.querySelector(`#${this.tableSelector}`));
        }
        this.$table.css({ width: '100%' });
        if ($('div.slot-buttons button', this.$table).length) {
            this.$table.css({ paddingTop: '30px' });
        }

        this.$table.dataTableExt.oSort['availabilitySort-pre'] = function (a) {            
            var x = parseFloat(a);
            if (a == "N/A") {
                x = -Infinity;
            }
            return x;
        };

        this.$table.dataTableExt.oSort['availabilitySort-asc'] = function (a, b) {            
            return ((a < b) ? -1 : ((a > b) ? 1 : 0));
        };

        this.$table.dataTableExt.oSort['availabilitySort-desc'] = function(a, b) {
            return ((a < b) ? 1 : ((a > b) ? -1 : 0));
        };

        this.$table.dataTableExt.oSort['bandwidthSort-asc'] = function (a, b) {            
            return bandwidthSort(a, b, Number.POSITIVE_INFINITY);
        };

        this.$table.dataTableExt.oSort['bandwidthSort-desc'] = function(a, b) {
            return bandwidthSort(a, b, Number.NEGATIVE_INFINITY) * -1;
        };

        this.$table.dataTableExt.oSort['bandwidthCapacitySort-asc'] = function (a, b) {            
            return bandwidthCapacitySort(a, b, Number.POSITIVE_INFINITY);
        };

        this.$table.dataTableExt.oSort['bandwidthCapacitySort-desc'] = function(a, b) {
            return bandwidthCapacitySort(a, b, Number.NEGATIVE_INFINITY) * -1;
        };

        this.$table.dataTableExt.oSort['datetimeSort-pre'] = function(a) {
            return Date.parse(a);
        };

        this.$table.dataTableExt.oSort['datetimeSort-asc'] = function(a, b) {
            return a-b;
        };

        this.$table.dataTableExt.oSort['datetimeSort-desc'] = function(a, b) {
            return b-a;
        };


        // Click handler for the header checkbox - cascades down to all of the checkboxes on the page
        this.$table.on('click', 'input.pa-table-check-all[type=\'checkbox\']', e => {
            this.check_all(e);
        });

        // Click handler for group row checkboxes
        this.$table.on('click', 'input.pa-table-group-row-check[type=\'checkbox\']', function(e) {
            $(`.pa-table-row-group-${$(this).val()}`, this.$table).prop('checked', $(this).prop('checked'));
            vue_obj.update_checkboxes();
        });

        // Click handler for individual row checkboxes
        this.$table.on('click', 'input.pa-table-row-checkbox[type="checkbox"]', function(e) {
            const $row = $(this).closest('tr');

            if ($(this).prop('checked')) {
                $row.addClass('selected');
            } else {
                $row.removeClass('selected');
            }

            vue_obj.update_checkboxes();
        });

        this.$table.dataTableExt.oSort['availabilitySort-pre'] = function(a) {
            var x = parseFloat(a);
            if (a == "N/A") {
                x = -Infinity;
            }
            return x;
        };

        this.$table.dataTableExt.oSort['availabilitySort-asc'] = function(a, b) {
            return ((a < b) ? -1 : ((a > b) ? 1 : 0));
        };

        this.$table.dataTableExt.oSort['availabilitySort-desc'] = function(a, b) {
            return ((a < b) ? 1 : ((a > b) ? -1 : 0));
        };

        // Avoid creating the first datatable on ready. This is for dashboards dynamic columns.
        // Not really a good way to do this.
        if (this.avoidFirstTable) {
            return 0;
        }

        if (this.maxAllowed && this.maxCheckBoxAllowed === 0 ) {
            this.maxReached = true
        }

        // DataTables' default searchDelay only throttles, not debounces so it will try searching almost every keypress
        // This uses a modified https://www.datatables.net/plug-ins/api/fnSetFilteringDelay to wait until kepyresses stop instead
        this.$table.dataTableExt.oApi.fnSetFilteringDelay = function(oSettings, iDelay) {
            const _that = this;

            if (iDelay === undefined) {
                iDelay = 250;
            }

            this.each(function(i) {
                if (typeof _that.fnSettings().aanFeatures.f !== 'undefined') {
                    $.fn.dataTableExt.iApiIndex = i;
                    let oTimerId = null;
                    let sPreviousSearch = null;
                    const anControl = $('input', _that.fnSettings().aanFeatures.f);

                    anControl.off('keyup search input').on('keyup search input', () => {
                        if (sPreviousSearch === null || sPreviousSearch != anControl.val()) {
                            window.clearTimeout(oTimerId);
                            sPreviousSearch = anControl.val();
                            oTimerId = window.setTimeout(() => {
                                $.fn.dataTableExt.iApiIndex = i;
                                _that.fnFilter(anControl.val());
                            }, iDelay);
                        }
                    });

                    return this;
                }
            });
            return this;
        };

        this.dataTable = this.$table.DataTable(this.options);
        this.$table.wrap('<div class="pa-tableWrapper"></div>');

        Vue.nextTick(() => {
            this.resizePageLengthSelect();
        });

        this.dataTable.on('xhr', function(e, settings, json) {
            self.$emit("ajax-params:update", self.dataTable.ajax.params());

            if (json && json.data) {
                self.handlePaginate(json.data);
            }
        });

        this.$table.on('length.dt', function(e, settings, len) {
            self.resizePageLengthSelect();
        });

        if (this.filtering && this.source !== 'ajax') {
            this.$table.dataTable().fnSetFilteringDelay(400);
        }

        if (this.refreshInterval) {
            this.dataTable.on('draw', function(msg) {
                this.startRefreshInterval();
            }.bind(this));
        }

        // Add a span element to attach the sort icon to
        this.dataTable.columns().iterator('column', (ctx, idx) => {
            if (this._shouldOrderFirstColumn() || idx != 0) {
                $(this.dataTable.column(idx).header()).append('<span class="sort-icon"/>');
            }
        });

        // Click handler for child row show/hide actions.  If a child row column is set, that column
        // contains the URL to fetch the data for child rows.  It is triggered by an A tag with
        // a "showchild" class on it.
        if (this.childRowColumn) {
            const table = this.dataTable;
            const child_row_column = this.childRowColumn;
            const self = this;

            this.$table.on('click', this.childTriggerSelector, function(e) {
                const tr = $(this).closest('tr');
                const row = table.row(tr);

                if (row.child.isShown()) {
                    row.child.hide();
                    tr.removeClass('shown');
                } else {
                    tr.addClass("loading");
                    let url;

                    if (row.data()) {
                        url = row.data()[child_row_column];
                    }

                    $.ajax({ url: url,
                        context: this })
                        .done(data => {
                            var processedData = false;
                            if (vue_obj.childRowType == "rows") {
                                processedData = true;
                                // Response from the Ajax call will be a dict with a "rows" entry, which
                                // contains a list of lists, in the same format as was used to originally
                                // render the table
                                const row_content = [];
                                for (let i = 0; i < data.rows.length; i++) {
                                    let content = '<tr>';
                                    const new_row = $('<tr>');
                                    for (let j = 0; j < data.rows[i].length; j++) {
                                        if (j in vue_obj.renderers && vue_obj.renderers[j] != null) {
                                            // If we have a custom renderer for the column, use it.  A value
                                            // of null means don't show the column.  After rendering, need to
                                            // call the Vue $compile method to activate any embedded Vue components
                                            var cell = $(`<td>${vue_obj.renderers[j](data.rows[i][j], 'display', row)}</td>`);
                                            window.app.rootVue.$compile(cell.get(0));
                                            new_row.append(cell);
                                            content += `<td>${vue_obj.renderers[j](data.rows[i][j], 'display', row)}</td>`;
                                        } else if (!(j in vue_obj.renderers)) {
                                            // If no custom renderer, just insert the raw content
                                            var cell = $(`<td>${data.rows[i][j]}</td>`);
                                            new_row.append(cell);
                                            content += `<td>${data.rows[i][j]}</td>`;
                                        }
                                    }
                                    content += '</tr>';
                                    row_content.push(new_row);
                                }
                            } else if (vue_obj.childRowType == "div") {
                                //If you're here you might be wondering why your serverside
                                //HTML isn't showing.  As it turns out Vue2 can't compile components
                                //inside a string.  Instead return back JSON, create a template and
                                //mount it.  See InstancePerformance:onNetworkPortChildTableComplete
                                //for an example.
                                if(typeof data === 'object') {
                                    processedData = true;
                                    var row_content = '<div id="tables-child-row-containter"></div>';
                                }

                            }

                            if(processedData) {
                                row.child(row_content, "tables-child-row").show();
                                tr.removeClass('loading');
                                tr.addClass('shown');
                                if (data) {
                                    self.$emit("table-children-loaded", data);
                                }
                            }
                        });
                }
            });
        }

        // Setup event handlers for refresh events
        for (var i = 0; i < this.refreshEvents.length; i++) {
            const event = this.refreshEvents[i];
            this.$on(event, function(msg) {
                this.dataTable.ajax.reload();
            });
        }

        if (this.selectEvent) {
            this.$on(this.selectEvent, function(id) {
                const that = this;
                this.dataTable.ajax.reload(() => {
                    that.selectRow(id);
                });
            });
        }

        // Filters options
        this.$filters = $(this.$el.querySelectorAll('.pa-filter-value'));

        this.$filters.toArray().some(i => {
            if (i.value.length) {
                $(this.$el.querySelector('.clear-filters')).prop('disabled', false);
                return true;
            }
        });


        this.$filters.on('change', e => {
            const name = e.target.id;
            let value = '';
            if (e.target.type === 'checkbox') {
                value = e.target.checked;
            } else {
                value = e.target.value;
            }
            const store = !e.target.classList.contains('no-store');
            vue_obj.updateTableData(name, value, store);
            $(this.$el.querySelector('.clear-filters')).prop('disabled', false);
        });

        // Need to add the event listener explicitly to datetime inputs
        // so that they update the table on the fly.
        let index;
        for (index in this.datetimeSelector) {
            if (!this.datetimeSelector.length || !this.datetimeSelector.hasOwnProperty(index)) {
                continue;
            }
            const selector = this.datetimeSelector[index];
            $(`#${selector}`).on('change', e => {
                const name = e.target.id;
                const value = e.target.value;
                const store = !e.target.classList.contains('no-store');
                vue_obj.updateTableData(name, value, store);
            });
        }

        // If we enable table filters, add a blur handler to set the "expanded" class to keep
        // the text box from getting hidden when it's not empty
        //
        // Always display the search input expanded if filtering and toggleExpandedSearch is false
        if (this.filtering) {
            if (this.toggleExpandedSearch) {
                $('.dataTables_filter input').blur(function() {
                    if ($(this).val() != '') {
                        $(this).addClass('expanded');
                    } else {
                        $(this).removeClass('expanded');
                    }
                });
            } else {
                $('.dataTables_filter input').addClass('expanded');
            }
        }

        // Update the table just after loading it.
        if (this.storeParams) {
            const clear_btn = $(this.$el.querySelector('.clear-filters'));
            const vue_obj = this;
            clear_btn.on('click', e => {
                vue_obj.clearFiltersStorage();
                clear_btn.prop('disabled', true);
            });
            const values = this.$filters.filter(function() {
                if (this.id === 'mode') {
                    return true;
                } else if (this.id === 'start_date' || this.id === 'end_date') {
                    return true;
                } else {
                    return false;
                }
            });
            const updated_params = {};
            for (var i = 0; i < values.length; i++) {
                const filter = values[i];
                updated_params[filter.id] = filter.value;
            }
            this.updateStoredParams(updated_params);
            this.updateTableData(null, null, true);
        }
    },
});
export default Table2;
</script>
