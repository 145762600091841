<template>
    <div class="pa-descripton-list-item" :class="{ 'inline-list': inline }">
        <dt class="pa-descripton-list_label" :class="labelClass">
            <slot name="label"></slot>
            <template v-if="(label || (item && item.label)) && !$slots['label']">
                {{ label || item.label }}
            </template>
        </dt>
        <dd class="pa-descripton-list_detail" :class="detailClass">
            <slot name="detail"></slot>
            <template v-if="(detail || (item && item.detail))  && !$slots['detail']">
                {{ detail || item.detail }}
            </template>
        </dd>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        item: Object,
        label: String,
        detail: String,
        inline: Boolean,
        labelClass: String,
        detailClass: String,
    },
});
</script>
