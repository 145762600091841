<template>
    <div
        class="pa-modal"
        :id="id"
        :class="{
            'isActive': active,
            'pa-modal_hasDrawer': isDrawer
        }"
        role="dialog"
        v-on:keyup.esc="closeable && close()">
        <div
            ref="box"
            class="pa-modal-box"
            :class="{
                'pa-modal-box_drawer': isDrawer
            }"
            tabindex="-1">
            <div class="pa-modal-box-bd" ref="body">
                <slot></slot>
            </div>
            <div
                ref="knob"
                v-if="isDrawer"
                class="pa-modal-box-knob"></div>
            <button
                v-if="closeable && !isDrawer"
                type="button"
                class="pa-modal-box-close"
                v-on:click="close()">
                <svg class="pa-icon pa-icon_block pa-icon_xl" :class="headerIconColor">
                    <use xlink:href="#close"></use>
                </svg>
            </button>
            <div v-if="isDrawer" class="pa-modal-box-menu">
                <button
                    type="button"
                    v-on:click="toggle()"
                    class="pa-modal-box-menu-item"
                    :style="{
                        width: titleWidth,
                        marginBottom: titleWidth
                    }">
                    {{ title }}
                </button>
                <p-menu :is-pinned="true" :is-absolute="true">
                    <button slot="trigger" class="pa-menu-hd-btn">
                        <svg class="pa-icon"><use xlink:href="#plus"></use></svg>
                    </button>
                    <slot name="menu"></slot>
                </p-menu>
            </div>
        </div>
        <div
            v-on:click="closeable && close()"
            v-if="!isDrawer"
            class="pa-modal-overlay"></div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import dialogMixin from './../mixins/dialogMixin';
    import serialize from './../utils/serialize';
    import $ from 'jquery';
    import debounce from './../utils/debounce';
    import createScrollLocker from '../utils/scrollLocker';

    // if a response contains this key, then the modal
    // should close after it submits a form within it
    const CUSTOM_MODAL_HEADER = {
        CLOSE: 'X-CP-ModalAction-Close',
        LOAD: 'X-CP-ModalAction-Load',
        RELOAD: 'X-CP-ModalAction-Reload',
    };

    const Modal = Vue.extend({
        mixins: [dialogMixin],

        beforeDestroy() {
            this._disable();
        },

        data() {
            return {
                active: this.isActive,
                activeElement: null,
                titleWidth: '',
                failedLoad: false,
                onCloseCallback: null,
            };
        },

        events: {
            'modal:load'(details) {
                const id = details.id;

                if (this.id !== id || !details.url) {
                    return true;
                }

                this.load(details.url).then(() => {
                    if (details.closeable === false) {
                        this.closeable = false;
                    } else {
                        this.closeable = true;
                    }

                    if (details.open !== true) {
                        return;
                    }
                    if (this.failedLoad) {
                        // We decided not to open.
                        return;
                    }

                    this.open();
                });

                if(details.onCloseCallback) {
                    this.onCloseCallback = details.onCloseCallback;
                }

                return true;
            },
        },

        methods: {
            open() {
                if (this.active) {
                    return;
                }

                this.active = true;

                $(document.body).on('keyup', this._onKeyup);

                // Emit an event when all modals open.
                this.$emit('modalopen', this.id);
                this.eventHub.$emit('modalopen', this.id);
                if (window.app.rootVue) {
                    window.app.eventHub.$emit('modalopen', this.id);
                }

                if (this.isDrawer) {
                    return;
                }

                if (this.scrollLocker) {
                    this.scrollLocker.on();
                }
                

                // Store a reference to this modal so we can easily close it from code within the modal
                window.active_vue_modal = this;
            },

            close() {
                if (!this.active) {
                    return;
                }

                this.active = false;

                $(document.body).off('keyup', this._onKeyup);

                this.onClose();

                if(this.onCloseCallback) {
                    this.onCloseCallback();
                }

                if (this.isDrawer) {
                    return;
                }

                if (this.scrollLocker) {
                    this.scrollLocker.off();
                }

                // Remove the global reference to an open modal
                window.active_vue_modal = null;
            },

            load(url) {
                this.failedLoad = false;
                return $.get(url).then((html, textStatus, jqXHR) => {
                    const shouldContinue = this._processReturnHeaders(html, textStatus, jqXHR);
                    if (shouldContinue) {
                        this._setInnerHTML(html);
                    } else {
                        this.failedLoad = true;
                    }
                });
            },

            _processReturnHeaders(html, textStatus, jqXHR) {
                const shouldReloadPage = jqXHR.getResponseHeader(CUSTOM_MODAL_HEADER.RELOAD);
                let pathname = jqXHR.getResponseHeader(CUSTOM_MODAL_HEADER.LOAD);
                const shouldModalClose = jqXHR.getResponseHeader(CUSTOM_MODAL_HEADER.CLOSE);

                if ((typeof html === 'string' ||
                    html instanceof String) &&
                    html.includes('</p-auth>'))
                {
                    window.location.reload();
                }

                if (shouldReloadPage) {
                    console.debug('Reloading page!');
                    window.location.reload();
                    return false;
                }

                if (pathname) {
                    pathname = decodeURIComponent(pathname);
                    console.debug('Setting location to ', pathname);
                    window.location = pathname;
                    return false;
                }

                if (shouldModalClose) {
                    console.debug('Closing modal');
                    this.close();
                    return false;
                }

                return true;
            },

            _bindOnFormSubmitListener() {
                // the setTimeout gives the browser
                // a moment to render the modal's body
                setTimeout(() => {
                    this.$forms = this.$el.querySelectorAll('form');

                    if (this.$forms.length) {
                        this.$forms.forEach(form => {
                            form.addEventListener('submit', this._onFormSubmit);
                        });
                    }
                });
            },

            _disable() {
                if (this.$refs.knob) {
                    this.$refs.knob.removeEventListener('mousedown', this._onKnobMousedown);
                }
            },

            _enable() {
                if (this.$refs.knob) {
                    this.$refs.knob.addEventListener('mousedown', this._onKnobMousedown);
                }
            },

            _setInnerHTML(html) {
                this.$children.forEach($child => $child.$destroy(true));

                const div = document.createElement('div');

                div.innerHTML = html;

                if (Vue.version.startsWith('2')) {
                    const detached = [];

                    const scripts = div.querySelectorAll('SCRIPT');
                    for (const script of scripts) {
                        script.remove();
                        // detached.push(script);
                        const newScript = document.createElement('SCRIPT');
                        newScript.textContent = script.innerText;
                        detached.push(newScript);
                    }

                    const styles = div.querySelectorAll('STYLE');
                    for (const style of styles) {
                        style.remove();
                        const newStyle = document.createElement('STYLE');
                        newStyle.textContent = style.innerText;
                        detached.push(newStyle);
                    }

                    this.$body.html(div.outerHTML);

                    for (const el of detached) {
                        document.head.appendChild(el);
                    }

                    const contentWrapper = this.$body.children().get(0);
                    if (contentWrapper.children.length &&
                            contentWrapper.children[0].getAttribute('anonymous-vue') !== null) {
                        this.content = new Vue({
                            el: contentWrapper,
                            parent: this,
                        });
                    }
                } else {
                    this.$body.html(div.outerHTML);

                    this.content = new Vue({
                        el: this.$body.children().get(0),
                        parent: this,
                    });
                }

                // TODO: delete this when that modal is no longer in
                // charge of handling form submissions
                this._bindOnFormSubmitListener();
            },

            _onDrag(event) {
                this.$el.style.width = (this.dragabble.startWidth + event.clientX - this.dragabble.startX) + 'px';
            },

            _onFormSubmit(event) {
                event.preventDefault();

                const form = event.target;

                const submitText = form && form.getAttribute('submit-text');

                let originalText = null;
                let submitButton = null;
                if (submitText) {
                    submitButton = $(this.$el).find('.pa-panel-ft .pa-btn:not(.pa-btn_secondary):not(.pa-btn_cancel)');
                    if (submitButton.length) {
                        originalText = submitButton.text();
                        submitButton.text(submitText);
                        const spinnerMarkup = `<svg class="pa-icon spin-8step" style="margin-right: 5px;">
                                               <use xlink:href="#spinner-spin"></use>
                                               </svg>`;
                        const spinner = $(spinnerMarkup);
                        submitButton.prepend(spinner);
                    }
                }

                const url = form.getAttribute('action');

                $.ajax(url, {
                    method: 'POST',
                    data: $(form).serialize(),
                }).then((html, textStatus, jqXHR) => {
                    this._processReturnHeaders(html, textStatus, jqXHR);

                    if (submitButton && originalText) {
                        submitButton.innerHTML = originalText;
                    }

                    this._setInnerHTML(html);
                });
            },

            _onKnobMousedown(event) {
                this.dragabble.startX = event.clientX;
                this.dragabble.startWidth = parseInt(window.getComputedStyle(this.$el).width, 10);

                document.documentElement.addEventListener('mousemove', this._onDrag);
                document.documentElement.addEventListener('mouseup', this._onStopDrag);
            },

            _onStopDrag(event) {
                document.documentElement.removeEventListener('mousemove', this._onDrag);
                document.documentElement.removeEventListener('mouseup', this._onStopDrag);
            },

            _onKeyup(event) {
                if (event.key !== 'Escape' || !this.closeable) {
                    return;
                }

                this.close();
            },
        },

        props: {
            isDrawer: {
                type: Boolean,
                default: false,
            },

            title: {
                type: String,
                default: '',
            },

            closeable: {
                type: Boolean,
                default: true,
            },

            onClose: {
                type: Function,
                default: () => {},
            },

            headerIconColor: {
                type: String,
                default: "pa-icon_light",
            }, 
        },

        vueReady() {
            this.scrollLocker = createScrollLocker();

            this.dragabble = {
                startX: null,
                startWidth: null,
            };

            this.$body = $(this.$refs.body);
            this._enable();

            /**
             * If we have a title, define the dynamic width of the title
             * button, which also acts as the margin bottom property.
             */
            if (this.isDrawer && this.title) {
                this.titleWidth = `${this.title.length * 11}px`;
            } else if (this.title) {
                this.titleWidth = `${this.title.length * 11 + 20}px`;
            }
        },
    });

    export default Modal;
</script>
