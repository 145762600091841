import Favico from 'favico.js';

class Favicon {
    constructor() {
        this.favico = new Favico({
            animation: 'popFade'
        });
    }

    badge(number) {
        this.favico.badge(number);
    }

    reset() {
        this.favico.reset();
    }
}

export default new Favicon();
