<template>
    <input
        class="pa-input"
        :class="theme"
        v-bind:value="value"
        type="number"
        :min="min"
        :max="max"
        v-on:input="$emit('input', $event.target.value)"
    />
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
    	value: String,
    	theme: {
    		type: String,
    		default: ""
    	},
        max: {
            type: String,
            default: ""
        },
        min: {
            type: String,
            default: "0"
        },
    },
});
</script>
