<style lang="scss">
    @import "~styles/core/helpers/_variables.scss";

    .pass-badge, .fail-badge, .not-run-badge {
        border-radius: 2px;
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 4px;
    }
    .pass-badge {
        @include colorVar(background-color, "green", 200);
        @include colorVar(color, "green", 800);
    }
    .fail-badge {
        @include colorVar(background-color, "red", 200);
    }
    .not-run-badge {
        @include colorVar(background-color, "grey", 200);
    }
    .synthetic-checks {
        .vuetable td {
            border-left: none;
            border-right: none;

            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
          
            word-break: break-word;

            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;
        }
        .vuetable th {
            border-left: none;
            border-right: none;
            border-top: none;
        }
        .screenshot-thumb {
            width: 180px;
        }
        td.screenshot-cell {
            vertical-align: bottom;
        }

    }
</style>

<template>
    <div class="pa-synthetic-results">
        <div class="pa-vuetable synthetic-checks" v-if="currentCheck">
            <vuetable
                ref="vuetable"
                :api-mode="false"
                :data="currentCheck.steps"
                :fields="fields"
                pagination-path=""
                :no-data-template="loadedEmptyText"
                table-height="600px"
                v-if="!loading"
            >
                <div slot="pass-fail-slot" slot-scope="props">
                    <div v-if="props.rowData.success === true || props.rowData.result === true" class="pass-badge">Pass</div>
                    <div v-if="props.rowData.success === false" class="fail-badge">Fail</div>
                    <div v-if="props.rowData.success === null" class="not-run-badge">Not Run</div>
                </div>
                <div slot="step-slot" slot-scope="props">
                    <p class="pa-txt pa-txt_medium pa-mb-4">Step {{ props.rowIndex + 1 }}</p>
                    <p v-if="timestamps" class="pa-txt pa-mb-4">{{ renderTimestamp(props.rowData.timestamp) }}</p>
                    <p class="pa-txt">{{ props.rowData.duration ? props.rowData.duration.toFixed(2) + ' s' : '' }}</p>
                </div>
                <div slot="command-slot" slot-scope="props">
                    <div style="display: inline-block; background: #f2f3f5; padding: 5px; border-radius: 2px; font-family: monospace;">
                        <span>{{ props.rowData.command }}</span>
                    </div>
                </div>
                <div slot="name-slot" slot-scope="props">
                    <div style="display: inline-block; background: #f2f3f5; padding: 5px; border-radius: 2px; font-family: monospace;">
                        <span>{{ props.rowData.command }}</span>
                    </div>
                    <ul class="pa-pt-12">
                        <li v-for="entry in props.rowData.annotations">
                            <p class="pa-txt pa-txt_medium pa-mb-4">{{ entry }}</p>
                        </li>
                    </ul>
                </div>
                <div slot="screenshot-slot" slot-scope="props">
                    <img
                        v-if="props.rowData.screenshot"
                        @click="openScreenshot(props.rowIndex)"
                        class="screenshot-thumb pa-clickable"
                        :src="`${screenshotsUrl}/${props.rowData.screenshot}`"
                        @error="screenshotError" />
                    <img
                        v-else
                        @click="openScreenshot(props.rowIndex)"
                        class="screenshot-thumb pa-clickable"
                        src="/static/assets/newux/media/screenshot-placeholder.png" />
                </div>
            </vuetable>
        </div>
        <div v-if="loading" class="pa-vuetable_loading" style="text-align: center;">
            <p-loading-spinner-2
                height="91px"
                width="91px"
                style="display: block; margin: auto;"
            ></p-loading-spinner-2>
            <p class="pa-txt pa-txt_sm" style="color: #4f5766;">Please wait...</p>
        </div>
        <p-synthetic-screenshots
            v-if="currentCheck"
            ref="modal"
            :steps="currentCheck.steps"
            :screenshots-url="screenshotsUrl"
        >
        </p-synthetic-screenshots>
    </div>
</template>

<script>
import Vue from 'vue';
import _ from "lodash";
import Vuetable from 'vuetable-2';
import moment from 'moment';

export default Vue.extend({
    components: {
        Vuetable,
    },

    data() {
        return {
            imgTimeouts: {},
        };
    },
    props: {
        checks: Array,
        selectedNode: Number,
        screenshotsUrl: String,
        timestamps: {
            type: Boolean,
            'default': true,
        },
        checkType: {
            type: String,
            'default': 'l2'
        },
        loading:false,
    },
    computed: {
        fields() {
            if (this.checkType === 'l2') {
                return [{
                name: 'pass-fail-slot',
                title: 'STATUS',
                width: '10%',
            }, {
                name: 'step-slot',
                title: 'STEP',
                width: '15%',
            }, {
                name: 'command-slot',
                title: 'Command',
                width: '15%',
            }, {
                name: 'target',
                title: 'TARGET',
                width: '30%',
            }, {
                name: 'value',
                title: 'VALUE',
                width: '15%',
            }, {
                name: 'screenshot-slot',
                title: 'SCREENSHOT',
                dataClass: 'screenshot-cell',
                width: '15%',
            }]
        } else {
            return [{
                name: 'pass-fail-slot',
                title: 'STATUS',
                width: '10%'
            }, {
                name: 'step-slot',
                title: 'STEP',
                width: '15%',
            }, {
                name: 'name-slot',
                title: 'NAME',
                width: '60%'
            },
            {
                name: 'screenshot-slot',
                title: 'SCREENSHOT',
                dataClass: 'screenshot-cell',
                width: '15%',
            }]
        }
        },

        isBrowser() {
            return this.checkType === 'l2';
        },

        isJavascript() {
            return this.checkType === 'l3';
        },

        currentCheck() {
            if (_.isEmpty(this.checks)) {
                return {
                    steps: [],
                };
            }
            if (this.checks.length === 1) {
                return this.checks[0];
            }

            const selected = this.checks.filter(c => c.node_id === this.selectedNode);
            return selected.length ? selected[0] : null;
        },

        loadedEmptyText() {
            return this.loading ? '' : this.emptyText;
        },
    },
    methods: {
        renderTimestamp(timestamp) {
            if (!timestamp) {
                return '';
            }
            const tstamp = timestamp * 1000;
            const tz = moment.tz.guess();
            const utc = moment.tz(tstamp, 'UTC');
            const local = utc.clone().tz(tz);
            return local.format('MM-DD-YY - HH:MM (z)');
        },
        openScreenshot(index) {
            this.$refs.modal.openAtIndex(index);
        },
        screenshotError(evt) {
            const target = evt.target;
            const origSrc = target.src;
            let t = 2000;
            if (origSrc in this.imgTimeouts) {
                t = this.imgTimeouts[origSrc] * 2;
            }
            this.imgTimeouts[origSrc] = t;
            target.src = '/static/assets/newux/media/screenshot-placeholder.png';
            window.setTimeout(() => {
                target.src = origSrc;
            }, t);
        },
    },
});
</script>
