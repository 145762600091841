<style lang="scss">
    @import "~styles/core/helpers/_variables.scss";

    @mixin alert {
        position: relative;
        width: 100%;
    }

    .pa-alert {
        @include alert;
    }

    .pa-alert-wrapper {
        background-color: white;
        border: 1px solid black;
        border-radius: 4px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.35);
        padding: 9px 24px 9px 18px;
        display: flex;
        align-items: center;

        .pa-icon {
            @include colorVar(fill, "grey", 600);
            @include colorVar(color, "grey", 600);
        }
    }

    .pa-alert-body {
        font-size: 11px;
    }

    .pa-alert-green {
        @include alert;

        & > .pa-alert-wrapper {
            @include colorVar(border-color, "green", 400);
            @include colorVar(background-color, "green", 100);
            @include colorVar(color, "green", 800);
            box-shadow: 0 4px 8px 0 rgba(113, 201, 168, 0.35);

            .pa-icon {
                @include colorVar(fill, "green", 500);
                @include colorVar(color, "green", 500);
            }
        }
    }

    .pa-alert-yellow {
        @include alert;

        & > .pa-alert-wrapper {
            @include colorVar(border-color, "yellow", 400);
            @include colorVar(background-color, "yellow", 100);
            @include colorVar(color, "yellow", 800);
            box-shadow: 0 4px 8px 0 rgba(246, 187, 69, 0.35);

            .pa-icon {
                @include colorVar(fill, "yellow", 500);
                @include colorVar(color, "yellow", 500);
            }
        }
    }

    .pa-alert-red {
        @include alert;

        & > .pa-alert-wrapper {
            @include colorVar(border-color, "red", 400);
            background-color: #fdeeee;
            @include colorVar(color, "red", 800);
            box-shadow: 0 4px 8px 0 rgba(239, 118, 118, 0.35);

            .pa-icon {
                @include colorVar(fill, "red", 500);
                @include colorVar(color, "red", 500);
            }
        }
    }

    .pa-alert-blue {
        @include alert;

        & > .pa-alert-wrapper {
            @include colorVar(border-color, "blue", 400);
            background-color: #f1f3fb;
            @include colorVar(color, "blue", 800);
            box-shadow: 0 4px 8px 0 rgba(152, 166, 222, 0.35);

            .pa-icon {
                @include colorVar(fill, "blue", 500);
                @include colorVar(color, "blue", 500);
            }
        }
    }

    .pa-alert-white {
        @include alert;

        & > .pa-alert-wrapper {
            @include colorVar(border-color, "white");
            @include colorVar(background-color, "white");
        }
    }

    .pa-alert--full-width {
        & > .pa-alert-wrapper {
            border-radius: 0;
            border-left: none;
            border-right: none;
            box-shadow: none;
        }
    }

    .pa-alert--no-shadow {
        & > .pa-alert-wrapper {
            box-shadow: none;
        }
    }

    .pa-alert--short {
        .pa-icon {
            height: 20px;
            width: 20px;
        }
    }

    .pa-alert--bold {
        .pa-alert-body {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .pa-alert--sticky {
        position: sticky;
        top: 0px;
        z-index: 3;
    }
</style>

<template>
    <div :class="computedClass" :style="computedStyle">
        <div class="pa-alert-wrapper">
            <p-icon
                v-if="icon && iconPosition === 'left'"
                :icon="icon"
                :color="iconColor"
                :size="iconSize"
                class="pa-mr-8 pa-flex-shrink-0"
                :class="iconClass"
            >
            </p-icon>
            <slot name="alert-icon"></slot>
            <div class="pa-alert-content">
                <div v-if="title" class="pa-alert-title pa-flex">
                    <h4 class="pa-txt_sm pa-txt_medium">
                        {{ title }}
                    </h4>
                </div>
                <div class="pa-alert-body" :class="{ 'pa-mt-4': !!title }">
                    <slot></slot>
                </div>
            </div>
            <p-icon
                v-if="icon && iconPosition === 'right'"
                :icon="icon"
                :color="iconColor"
                :size="iconSize"
                class="pa-mr-8 pa-flex-shrink-0"
                :class="iconClass"
            >
            </p-icon>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        title: String,
        variant: String,
        width: String,
        icon: String,
        iconColor: String,
        iconSize: {
            type: String,
            default: "xxxxl"
        },
        iconPosition: {
            type: String,
            default: "right",
        },
        iconClass: {
            type: String,
            default: "",
        },
        fullWidth: Boolean,
        noShadow: Boolean,
        short: Boolean,
        bold: Boolean,
        center: Boolean,
        sticky: Boolean,
    },
    computed: {
        hasIconSlot() {
            return !!this.$slots["alert-icon"];
        },
        computedStyle: function() {
            const styles = {};

            if (this.width) {
                styles.width = this.width;
            }

            return styles;
        },
        computedClass: function() {
            let classNames = [];

            if (this.variant) {
                classNames.push("pa-alert-" + this.variant);
            } else {
                classNames.push("pa-alert");
            }

            if (this.noShadow) {
                classNames.push("pa-alert--no-shadow");
            }

            if (this.fullWidth) {
                classNames.push("pa-alert--full-width");
            }

            if (this.short) {
                classNames.push("pa-alert--short");
            }

            if (this.bold) {
                classNames.push("pa-alert--bold");
            }

            if (this.sticky) {
                classNames.push("pa-alert--sticky");
            }

            return classNames.join(" ");
        },
    },
});
</script>
