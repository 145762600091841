<template>
    <span :class="computedClass">
        <slot></slot>
    </span>
</template>

<script>
import Vue from 'vue';

const VARIANTS = [
    "",
    "dark",
    "error",
    "warning",
    "warning_naked",
    "warning_inverse",
    "simulated",
    "maintenance",
    "pending",
    "critical",
    "critical_naked",
    "critical_inverse",
    "info",
    "info_inverse",
    "iptype",
    "success",
    "logs",
    "logs_inverse",
    "teal",
    "tag",
    "green",
    "orange",
    "red",
    "yellow",
    "grey",
    "grey-200",
    "blue",
    "nav",
    "nav--red",
    "nav--yellow",
];

const BASE_CLASSNAME = "pa-badge";

export default Vue.extend({
    props: {
        variant: {
            type: String,
            validator: (variant) => isValidOption(variant, VARIANTS),
            default: "",
        },
        noRound: Boolean,
        noPointer: Boolean,
        circle: Boolean,
    },
    computed: {
        computedClass: function() {
             const classNames = {
                 [BASE_CLASSNAME]: true,
                 [`${BASE_CLASSNAME}_${this.variant}`]: Boolean(this.variant),
                 "no-round": this.noRound,
                 "pa-cursor-auto": this.noPointer,
                 [`${BASE_CLASSNAME}_circle`]: this.circle,
             };

            return classNames;
        },
    },
});

const isValidOption = (option, options) => {
    const isValid = options.indexOf(option) !== -1;

    if (!isValid) {
        console.error(
            "'" +
            option +
            "' is not a valid option. Available options: " +
            options.join(", ")
        );
    }
    return isValid;
};
</script>