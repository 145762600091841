const adminIcon = (() => {
    return {
        /*
            el- is the element.
            binding - It's the one that contains arguments, methods and a lot of information you need about what's going on in that instance.
        */
        bind(el, binding, vnode) {
            let adminIconPosition = 'left';
            let ICON_NAME = 'lock_person_black_24dp';
            let TOOLTIP_CONTENT = 'This functionality is exposed to internal admins only, not for customers.';
            let icon = document.createElement('p-icon');
            icon.setAttribute('icon', ICON_NAME);
            if (binding.arg =='right'){
                el.appendChild(icon,el.firstChild);
                el.setAttribute('title',TOOLTIP_CONTENT);
            }else{
                el.insertBefore(icon,el.firstChild);
                el.setAttribute('title',TOOLTIP_CONTENT);
            }
            vnode.context.$compile(icon);
        }
    }   
})();

export default adminIcon;