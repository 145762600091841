import { requests } from "../requests";

export const customerOptionsFilter = (brand_id) => {
    return requests.get('/customerOptionsFilter', { params: { brand_id }});
}

export const userOptionsFilter = (customer_id) => {
    return requests.get('/userOptionsFilter', { params: { customer_id }});
}

export const setAdminBrand = (brand_id) => {
    return requests.get('/setAdminBrand', { params: { brand_id }});
}

export const setSuperuserCustomer = (customer_id, hard_switch) => {
    return requests.get('/setSuperuserCustomer', { params: { customer_id, hard_switch }});
}

export const setSuperuserUser = (user_id, hard_switch) => {
    return requests.get('/setSuperuserUser', { params: { user_id, hard_switch }});
}

export const bigBrandSelectCustomer = (customer_id, brand_id) => {
    return requests.get('/bigBrandSelectCustomer', { params: { customer_id, brand_id } });
}

export const bigBrandServerSearch = (ip_fqdn) => {
    return requests.get('/bigBrandServerSearch', { params: { ip_fqdn } });
}

export const getMasterAccount = (brand_id) => {
    return requests.get('/getMasterAccount', { params: { brand_id } });
}