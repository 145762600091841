<style lang="scss">
.pa-copy-script {
    background-color: var(--p-grey-100);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &--no-title {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    &--secondary {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .pa-copy-script-header {
        background-color: var(--p-grey-200);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        &--secondary {
            background-color: white;
        }
    }

    .pa-copy-script-body {
        color: var(--p-grey-700);
    }
}
</style>

<template>
    <div
        class="pa-copy-script"
        :class="{
            'pa-copy-script--secondary': variant === 'secondary',
            'pa-copy-script--no-title': !title,
        }"
    >
        <p-flex
            v-if="!!title || hasTitleSlot"
            space-between
            align-center
            class="pa-copy-script-header pa-py-8"
            :class="{
                'pa-copy-script-header--secondary': variant === 'secondary',
                'pa-px-24': variant === 'primary',
                'pa-px-0': variant === 'secondary',
            }"
        >
            <h5 v-if="!hasTitleSlot" class="pa-txt_medium pa-txt_13">
                {{ title }}
            </h5>
            <slot name="script-title"></slot>
            <p-button
                v-if="uniqueId"
                ref="copyBtn"
                variant="blue-secondary"
                :data-clipboard-target="`#${uniqueId}`"
                class="pa-ml-8"
                @click="toggleCopied"
            >
                <p-flex align-center>
                    <p-icon v-if="variant === 'secondary'" icon="copy" class="pa-mr-8"></p-icon>
                    {{ isCopied ? "Copied" : "Copy" }}
                </p-flex>
            </p-button>
        </p-flex>
        <div class="pa-copy-script-body pa-py-16 pa-px-24" :style="computedBodyStyle">
            <!-- This needs to be on one line like this to preserve the correct whitespace indentation -->
            <pre :style="computedPreStyle"><code :id="uniqueId" class="pa-txt_13" v-html="script"></code></pre>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import Clipboard from "clipboard";

let CLIPBOARD_COUNT = 0;

export default Vue.extend({
    data: () => ({
        uniqueId: `pa-copy-script_id-${CLIPBOARD_COUNT}`,
        clipboard: null,
        isCopied: false,
    }),
    props: {
        title: String,
        script: String,
        onClick: Function,
        whiteSpace: {
            type: String,
            default: "pre-line",
        },
        maxHeight: [String, Number],
        variant: {
            type: String,
            default: "primary",
        },
    },
    computed: {
        hasTitleSlot() {
            return !!this.$slots["script-title"];
        },
        computedPreStyle() {
            const styles = {
                "white-space": this.whiteSpace,
            };

            return styles;
        },
        computedBodyStyle() {
            const styles = {};

            if (this.maxHeight) {
                styles.maxHeight = this.maxHeight;
                styles.overflowY = "auto";
            }

            return styles;
        },
    },
    methods: {
        toggleCopied() {
            this.isCopied = true;
            setTimeout(() => {
                this.isCopied = false;
            }, 1000);
        },
    },
    beforeDestroy() {
        if (this.clipboard) {
            this.clipboard.destroy();
        }
    },
    beforeCreate() {
        this.uniqueId = `pa-copy-script_id-${CLIPBOARD_COUNT}`;
        CLIPBOARD_COUNT += 1;
    },
    mounted() {
        if (this.$refs.copyBtn && this.$refs.copyBtn.$el) {
            this.clipboard = new Clipboard(this.$refs.copyBtn.$el);
        }
    },
});
</script>
